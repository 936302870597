import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Container,
  TablePagination, Button,
  Typography,
  TableContainer
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// import data from './data';
import Page from 'src/components/Page';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Grid from '@material-ui/core/Grid';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';
import { MESSAGE } from 'src/utils/message';
import APIKit from '../../../utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
// import './style.css';
import Loader from '../../loader';
import Address from '../Common/Address';
import AddressSingleLine from '../Common/Address_singleline';
import BankSingleline from '../Common/Bank_singleline';
import Bank from '../Common/Bank';
import { dateFormate } from '../Common/utilities';
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  centerText: {
    textAlign: 'left',
    color: '#394671',
    marginTop: '10px',
    fontSize: '17px',
    fontWeight: 'bold',

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginLeft: '80px !important',
      color: '#394671',
      marginTop: '10px',
      fontSize: '17px',
      fontWeight: 'bold',
    },
    '@media (max-width: 350px)': {
      textAlign: 'center',
      marginLeft: '60px !important',
      color: '#394671',
      // marginTop: '10px',
      fontSize: '17px',
      fontWeight: 'bold',
    }
  },
  container: {
    maxHeight: '100%',
    '@media (max-width: 500px)': {
      maxHeight: '300px',
      overflowY: 'auto',
    },

  },
  button: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '60px !important',
      marginBottom: '-50px !important',
    },
    '@media (max-width: 350px)': {
      marginLeft: '40px !important',
      marginBottom: '-50px !important',
    }
  }
}));

const ProjectList = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { addToast } = useToasts();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const projectData = useSelector((x) => x);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllProjects = () => {
    
    APIKit.post(RGCA_URLS.getProjects,
      {
        searchText: ''
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAllProjects(res.data.data);
          // console.log('setAllProjects', res.data.data);
          let finalData = JSON.parse(res.data.data.email)
          console.log("finalData",finalData);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  const projectDelete = (data) => {

    setIsLoading(true);

    APIKit.delete(`${RGCA_URLS.deleteProject}/${data.projectID}`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(MESSAGE.projectDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getAllProjects();
          // console.log('setAllProjects', res.data.data);
        } else {
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.projectNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const createProject = () => {
    navigate(`/app/AddProject`, { replace: true });
  }
  const gotoupdate = (data) => {
    navigate(`/app/updateproject/${data.projectID}`, { replace: true ,email:data});
  };

  return (
    <Page
      className={classes.root}
      title="Projectlist"
    >
      <ToastContainer />
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        <div>

          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <span className={classes.centerText}> Project List</span>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={createProject}
                  className={classes.button}
                >
                  Create Project
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} />
            </Grid>

          </Box>

          {/*
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={455}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search project"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box> */}
        </div>
        <Box mt={3}>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead>

                <TableRow>

                  <TableCell align="left">
                    S.No
                  </TableCell>

                  <TableCell align="left">
                    Project Name
                  </TableCell>
                  <TableCell align="left">
                    Phone Number
                  </TableCell>
                  {/* <TableCell align="left">
                  Description
                </TableCell> */}
                  <TableCell align="left">
                    Address
                  </TableCell>
                  <TableCell align="left">
                    Bank Details
                  </TableCell>
                  <TableCell align="left">
                    Created Date
                  </TableCell>
                  <TableCell align="left">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {allProjects.map((row, index) => ( */}
                {allProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) =>
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell align="left">
                      {((page) * 10) + index + 1}
                    </TableCell>
                    <TableCell align="left">
                      <span onClick={(e) => gotoupdate(row)} style={{ cursor: "pointer" }}>{row.projectName}</span>
                    </TableCell>
                    <TableCell align="left">
                      {row.mobile}
                    </TableCell>
                    {/* <TableCell align="left">
                    <Tooltip title={row.Description}>
                      <span>
                        {row.Description ? row.Description.length > 30 ? `${row.Description.slice(0, 30)} ...` : row.Description : ''}
                        {' '}
                      </span>
                    </Tooltip>
                  </TableCell> */}
                    <TableCell align="left">
                      <Tooltip title={
                        <Address details={row.address} />
                      }>
                        <Typography>
                          <AddressSingleLine details={row.address} />
                        </Typography>
                      </Tooltip>

                      {/* {row.address?JSON.parse(row.address)[0]:"-"} */}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.bankDetails} */}

                      <Tooltip title={
                        <Bank details={row.bankDetails} />
                      }>
                        <Typography>
                          <BankSingleline details={row.bankDetails} />
                        </Typography>
                      </Tooltip>






                      {/* {row.bankDetails?JSON.parse(row.bankDetails)[0]:"-"} */}
                    </TableCell>
                    <TableCell align="left">
                      {dateFormate(row.CreateDate)}
                    </TableCell>
                    <TableCell align="left">
                      <EditOutlinedIcon style={{ cursor: 'pointer' }} onClick={(e) => gotoupdate(row)} />
                      <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={(e) => {
                        confirm({ description: 'You want to delete the record ?' })
                          .then(() => {
                            // let temp = GINData;
                            // temp.splice(i, 1)
                            // setGINdata([...temp])
                            projectDelete(row);
                          })
                          .catch(() => console.log('Deletion cancelled.'));
                      }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TablePagination
                fullwidth
                rowsPerPageOptions={[10, 25, 100]}
                // component="div"
                count={allProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        </Box>

      </Container>
    </Page>
  );
};
export default ProjectList;
