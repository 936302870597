import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    InputAdornment,
    TextField,
    SvgIcon,
    Select,
    InputLabel,
    FormControl,
    MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
// import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import APIKit from 'APIKit';
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getNavigationData } from '../../Redux/Common/action';
import APIKit from "../../utils/APIKit"



const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

const useStyles1 = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px', marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    }
}));


const AddCategories = () => {
    const classes = useStyles();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [alertMeg, setAlertMeg] = React.useState('');
    const [errorMeg, setErrorMeg] = React.useState('');
    const [subCategoryList, setSubCategoryList] = useState([]);

    const [categoryForm, setCategoryForm] = React.useState({
        CategoryId: "", CategoryName: "", SubCategory: []
    });

    const [navparms, setNavparms] = React.useState(false);
    const dispatch = useDispatch();

    const navigationData = useSelector(x => x.NavigationData.navigationData);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const navigate = useNavigate();

    useEffect(() => {
        if (navigationData && navigationData.CategoryId) {
            setNavparms(true)
            let obj = categoryForm;
            obj.CategoryName = navigationData.CategoryName;
            obj.CategoryId = navigationData.CategoryId;
            setCategoryForm({
                ...obj
            })
            setSubCategoryList(navigationData.SubCategory)
        }
        else {
            setNavparms(false)
            handleSubCategoryAddClick();
        }


    }, []);

    const [DOB, setDOB] = React.useState(new Date('2014-08-18T21:11:54'));

    const [DOJ, setDOJ] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = (date) => {
        setDOB(date);
    };

    const handleDateChangeDOB = (date) => {
        setDOJ(datePickerDefaultProps)
    }

    const handleSubCategoryAddClick = () => {

        setSubCategoryList([...subCategoryList, {
            SubCategoryName: "", IsMovable: 0
        }]);
    }

    const handleSubCategoryInputChange = (e, index) => {
        
        const { name, value } = e.target;
        const list = [...subCategoryList];
        if(e.target.name=='IsMovable'){
            list[index]['IsMovable'] = value;
        }
        if(e.target.name=='subcategory'){
        list[index]['SubCategoryName'] = value;
        }
       
        setSubCategoryList(list);
    };
    const handleCategoryAddClick = () => {
        
        if (categoryForm.CategoryName == "") {
            setAlertMeg('success');
            setErrorMeg("please enter Category Name properly");
            return
        }
        let test = categoryForm;
        var payload = subCategoryList.map((x) => {
            if (x.SubCategoryName != "") {
                return {
                    SubCategoryName: x.SubCategoryName,
                    IsMovable:Number(x.IsMovable)
                }
            }
        })
        test.SubCategory = payload;

        APIKit.post('/' + APP_URLS.addLookupData,
            test,
            { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
            .then((res) => {
                if (res.data.status === 200) {
                    setAlertMeg('success');
                    setErrorMeg(res.data.message)
                    setSubCategoryList([]);
                    setCategoryForm({ CategoryId: "", CategoryName: "", SubCategory: [] })
                    let obj = {}
                    dispatch(getNavigationData(obj));
                    navigate('/app/Categories', { replace: true })
                }
                else {
                    setAlertMeg('error');
                    setErrorMeg(res.data.message)
                }
            })
            .catch(function (e) {
            })

    }

    const updateCategoryAddClick = () => {
        if (categoryForm.CategoryName == "") {
            setAlertMeg('success');
            setErrorMeg("please enter Category Name properly");
            return
        }
        let test = categoryForm;
        var payload = subCategoryList.map((x) => {
            if (x.SubCategoryName != "") {
                return {
                    SubCategoryName: x.SubCategoryName,
                    SubCategoryId: x.SubCategoryId,
                    IsMovable:Number(x.IsMovable)
                }
            }
        })
        test.SubCategory = payload;

        APIKit.post('/' + APP_URLS.updateLookupData,
            test,
            { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
            .then((res) => {
                if (res.data.status === 200) {
                    setAlertMeg('success');
                    setErrorMeg(res.data.message)
                    setSubCategoryList([]);
                    setCategoryForm({ CategoryId: "", CategoryName: "", SubCategory: [] })
                    let obj = {}
                    dispatch(getNavigationData(obj));
                    navigate('/app/Categories', { replace: true })
                }
                else {
                    setAlertMeg('error');
                    setErrorMeg(res.data.message)
                }
            })
            .catch(function (e) {
            })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [IsMovablevalue, setIsMovableValue] = React.useState(1);

    const handleChange = (event) => {
        setIsMovableValue(event.target.value);
    };

    return (
        <Page
            className={classes.root}
            title="Depositors">
            <Container maxWidth={false}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  >
                    <Alert onClose={handleClose} severity={alertMeg} >
                        {errorMeg}
                    </Alert>
                </Snackbar>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                            Category
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

                        </Grid>
                    </Grid>
                    <Box mt={3} style={{
                        backgroundColor: '#fff',
                        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                    }}>
                        <div>
                            <Grid
                                container
                                spacing={3}
                                style={{ "textAlign": "left" }}
                            >


                                <Grid item lg={2} md={4} sm={6} xs={12} style={{ "marginTop": "1%" }}>
                                    Category :
                                </Grid>
                                <Grid item lg={6} xs={10}>
                                    <TextField
                                        fullWidth
                                        onChange={(e) => {
                                            let p = categoryForm;
                                            p.CategoryName = e.target.value
                                            setCategoryForm({
                                                ...p
                                            })
                                        }}
                                        value={categoryForm.CategoryName}
                                        label="Category" variant="outlined"
                                        rows={2}
                                        rowsMax={4}
                                        disabled={navigationData && navigationData.CategoryId}
                                    />
                                </Grid>
                              

                            </Grid>
                        </div>



                    </Box>
                    <br></br>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                            Sub Category
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                            <Button
                                disabled={
                                    subCategoryList.length > 0 && (subCategoryList[subCategoryList.length - 1].SubCategoryName == '' || subCategoryList[subCategoryList.length - 1].SubCategoryName == undefined)
                                }
                                color="primary"
                                variant="contained"
                                onClick={handleSubCategoryAddClick}
                            >
                                New SubCategory
                            </Button>
                        </Grid>
                    </Grid>

                    <Box mt={3} style={{
                        backgroundColor: '#fff',
                        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                    }}>

                        <div>
                            {subCategoryList && subCategoryList.length > 0 && subCategoryList.map((x, row) => (
                                <>
                                    <Grid
                                        container
                                        spacing={3}
                                        // style={{ "textAlign": "left" }}
                                    >
                                        <Grid item lg={2} md={2} sm={6} xs={2} style={{ "marginTop": "1%" }}>
                                            SubCategory :
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <TextField
                                                fullWidth
                                                name="subcategory"
                                                onChange={e => handleSubCategoryInputChange(e, row)}
                                                value={x.SubCategoryName}
                                                label="SubCategory" variant="outlined"
                                                rows={2}
                                                rowsMax={4}

                                            />
                                        </Grid>



                                   

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        lg={4}
                                        sm={4}
                                    >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Is Movable</FormLabel>
                                            <RadioGroup row aria-label="position" name="IsMovable" defaultValue="top" value={x.IsMovable.toString()}
                                                onChange={e => handleSubCategoryInputChange(e, row)}>
                                                <FormControlLabel
                                                    value="1"
                                                    
                                                    control={<Radio color="primary" />}
                                                    label="YES"
                                                    labelPlacement="start"
                                                />

                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio color="primary" />}
                                                    label="NO"
                                                    labelPlacement="start"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    </Grid>
                                </>

                            ))}
                        </div>


                    </Box>
                    <br></br>
                    <Grid container spacing={3}>

                        <Grid item lg={12} sm={12} style={{ textAlign: 'right' }}>

                            {navparms ? <Button
                                disabled={
                                    categoryForm.CategoryName == ''
                                }
                                color="primary"
                                variant="contained"
                                onClick={updateCategoryAddClick}
                            >
                                Update
                            </Button> :
                                <Button
                                    disabled={
                                        categoryForm.CategoryName == ''
                                    }
                                    color="primary"
                                    variant="contained"
                                    onClick={handleCategoryAddClick}
                                >
                                    Save
                                </Button>}
                        </Grid>
                    </Grid>

                </div>
                <Box mt={3}>

                </Box>

                {/* dialog */}




            </Container>
        </Page>
    )

}
export default AddCategories;