import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment'
import { ETTypes, EEditable, ETaction } from './Types';
import { currencyFormat1, dateFormate, dateOnly } from './utilities';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShopIcon from '@mui/icons-material/Shop';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import { Icon } from '@iconify/react';
import { RGCA_URLS } from '../../../utils/url';


const CommonTable = ({ columns, data = [], action, isprint }) => {

  console.log("data", { columns, data, action });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const render = (head, row, index) => {
    if (head.field === 'Action' || head.field === 'action') {
      let onView = head.list.find(x => x === ETaction.onView)
      let onDelete = head.list.find(x => x === ETaction.onDelete)
      let trackApprove = head.list.find(x => x === ETaction.trackApprove)
      let onEdit = head.list.find(x => x === ETaction.onEdit)
      let onPrint = head.list.find(x => x === ETaction.onPrint)
      let onPayment = head.list.find(x => x === ETaction.onPayment)
      let onCancel = head.list.find(x => x === ETaction.onCancel)
      let moveOnPurchase = head.list.find(x => x == ETaction.moveOnPurchase)
      return <>
        {onView && (<VisibilityIcon style={{ margin: "5px", cursor: "pointer" }}
          onClick={(e) => {
            if (action && action.onView) {
              action.onView(index, row)
            }
          }} />)}
        {onEdit && (<EditIcon style={{ margin: "5px", cursor: "pointer" }} onClick={(e) => {
          if (action && action.onEdit) {
            action.onEdit(index, row)
          }
        }} />)}
        {trackApprove && (<Icon icon="wpf:approval" style={{ marginLeft: '24px', cursor: "pointer", fontSize: '20px' }} onClick={(e) => {
          if (action && action.trackApprove) {
            action.trackApprove(index, row)
          }
        }} />)}
        {onPrint && (
          // <Button style={{ margin: "5px", cursor: "pointer" }}
          //   color="primary"
          //   variant="contained"
          //   onClick={(e) => {
          //     if (action && action.onPrint) {
          //       action.onPrint(index, row)
          //     }
          //   }} >Print View</Button>
          <PrintIcon style={{ margin: "5px", cursor: "pointer" }}
            onClick={(e) => {
              if (action && action.onPrint) {
                action.onPrint(index, row)
              }
            }}
          />
        )}
        {onDelete && (<DeleteOutlineIcon style={{ margin: "5px", cursor: "pointer" }}
          onClick={(e) => {
            if (action && action.onDelete) {
              action.onDelete(index, row)
            }
          }} />)}
        {/* {onCancel && (<Button style={{
          margin: "5px", cursor: "pointer", border: "1px solid red",
          color: 'red'
        }}
          variant="outlined"
          onClick={(e) => {
            if (action && action.onCancel) {
              action.onCancel(index, row)
            }
          }} >Cancel</Button>)} */}
        {(onCancel && row?.POStatus && row?.POStatus === 'In progress') &&
          <Button
            style={{
              // margin: "5px",
              cursor: "pointer",
              border: "1px solid red",
              color: 'red'
            }}
            variant="outlined"
            onClick={(e) => {
              if (action && action.onCancel) {
                action.onCancel(index, row)
              }
            }}
          >
            Cancel
          </Button>
        }
        {onPayment && (<PaymentIcon style={{ margin: "5px", cursor: "pointer" }} onClick={(e) => {
          if (action && action.onPayment) {
            action.onPayment(index, row)
          }
        }} />)}
        {moveOnPurchase && (<ShopIcon style={{ marginLeft: '43px', cursor: "pointer", fontSize: '20px' }} onClick={(e) => {
          if (action && action.moveOnPurchase) {
            action.moveOnPurchase(index, row)
          }
        }} />)}
      </>

    }
    else if (head.editable && head.editable === EEditable.onEdit) {
      return (
        <TextField
          fullWidth
          helperText={head.helperText || ""}
          label={head.title}
          name={head.title}
          onChange={(e) => {
            if (action && action.editRow) {
              if (head.type === ETTypes.numeric) {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  action.editRow(index, head.field, e.target.value ? Number(e.target.value) : 0);
                }
              }
              else {
                action.editRow(index, head.field, e.target.value);
              }
            }

          }}
          required
          value={row[head.field]}
          variant="outlined"
        />
      )
    }
    else {
      if (head.type == ETTypes.string) {
        return (row[head.field] === undefined ? "" : row[head.field] ? row[head.field] : '-')
      }
      else if (head.type == ETTypes.numeric) {
        return row[head.field] ? currencyFormat1(row[head.field]) :''
      }
      else if (head.type == ETTypes.currency) {
        return Number(row[head.field]).toLocaleString('en-IN')
      }
      else if (head.type == ETTypes.date) {
        return row[head.field] ? dateOnly(row[head.field]) : ""
      }
      else if (head.type == ETTypes.dateOnly) {
        return row[head.field] ? dateOnly(row[head.field]) : ""
      }
      // else if (head.type === ETTypes.link) {
      //   return row[head.field] != 'undefined' &&
      //     row[head.field] != 'null' && row[head.field] != '' ?
      //     <a style={{ color: 'blue', cursor: 'pointer' }}
      //       href={row[head.field]} target="_blank"
      //     >{(row[head.field]).substring(4, 25)}
      //     </a> : '-'
      // }
      else if (head.type === ETTypes.link) {
        return (row[head.field] !== undefined && row[head.field] !== null && row[head.field] !== '' && row[head.field] !== 'null') ? (
          <a style={{ color: 'blue', cursor: 'pointer' }} href={row[head.field]} target="_blank">
            {(row[head.field] && row[head.field].substring(4, 25)) || ''}
          </a>
        ) : (
          '-'
        );
      }
      else if (head.type == ETTypes.boolean) {
        return row[head.field] ? 'Yes' : 'No'
      }

    }
  }

  return <> <TableContainer sx={{ maxHeight: 440 }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {columns.map((head, index) => <TableCell key={index} align={"left"}>{head.title}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {data.map((row, i) => <TableRow hover role="checkbox" key={i + 1}> */}
        {!isprint ? data?.length != 0 && data ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) =>
          <TableRow hover role="checkbox" key={i + 1}>
            {columns.map((head) => <TableCell key={i + head.field} align={"left"}>{render(head, row, i)}</TableCell>)}
          </TableRow>) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={columns.length} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow> : <>{data?.length != 0 && data.map((row, i) => <TableRow hover role="checkbox" key={i + 1}>
            {columns.map((head) => <TableCell key={i + head.field} align={"left"}>{render(head, row, i)}</TableCell>)}
          </TableRow>)}</>}
      </TableBody>
    </Table>
  </TableContainer>
    {!isprint && data?.length > 5 && <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={data ? data.length : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />}
  </>

}

export default CommonTable;
