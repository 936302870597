import { MESSAGE } from 'src/utils/message';
import { RGCA_URLS } from 'src/utils/url';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import APIKit from 'src/utils/APIKit';
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';

import {
    Box,
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    TextField,
    Divider,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import Loader from 'src/views/loader';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const EditSales = ({ setSalesItem, salesItem, setIsEdit, setIsLoading, isLoading,
    projectDetails, lookupData, categoryList, getItemsList }) => {
        console.log("salesItemllll",salesItem);
    const classes = useStyles();
    const [productionStock, setProductionStock] = useState(0)

    let regEx = {
        textOnly: /^[a-zA-Z\s]+$/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/,
        percentage: /^([0-9][0-9]?|100)%?$/
    };

    return <>
        <Loader isLoading={isLoading} />

        <Formik
            enableReinitialize={true}
            initialValues={{
                ...salesItem
            }}
            validationSchema={
                Yup.object().shape({
                    typeID: Yup.string().required(MESSAGE.type),
                    // categoryID: Yup.string().required(MESSAGE.category),
                    name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                    hssn: Yup.string().required(MESSAGE.hssn),
                    // batchNo: Yup.string().required('Batch No. is required'),
                    uom: Yup.string().required(MESSAGE.uom).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                    // rpu: Yup.number().required(MESSAGE.rpu),
                    gstTaxability: Yup.string().required(MESSAGE.gst),
                    // stock: Yup.number().required(MESSAGE.stocks),
                    description: Yup.string().required(MESSAGE.itemDescription).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                    rca: Yup.string().required(MESSAGE.rca),
                    productionDate: Yup.date().required("Date Of Joining is required")
                    // dateOfBirth: Yup.string().required(MESSAGE.dateOfBirth)
                })

            }
            onSubmit={(values, { resetForm }) => {
                console.log("valuesbbbbb", values)
                setIsLoading(true)
                //   if (btn) {
                const url = `${RGCA_URLS.updateSalesItem}`;
                const payload = {
                    typeID: salesItem?.typeID,
                    name: salesItem?.name,
                    gstTaxability: salesItem?.gstTaxability,
                    rpu: Number(salesItem?.rpu),
                    uom: salesItem?.uom,
                    hssn: salesItem?.hssn,
                    batchNo:salesItem?.batchNo,
                    productionStock: Number(productionStock),
                    gstRate: Number(salesItem?.gstRate),
                    description: salesItem?.description,
                    rca: salesItem?.rca,
                    categoryID: salesItem?.categoryID,
                    productionDate: salesItem?.productionDate,
                    itemID: salesItem?.itemID,
                    mortalityCount: salesItem?.mortalityCount ? salesItem?.mortalityCount : 0,
                    projectID: projectDetails?.projectID
                    // ...salesItem,
                    // stock: Number(salesItem.stock1 ?? 0),
                    // rpu: Number(salesItem.rpu),
                    // gstRate: String(salesItem.gstRate),
                    // projectID: projectDetails.projectID
                }
                // delete payload.stock1
                // console.log("pppppp",payload),
                APIKit.put(url, payload)
                    .then((res) => {
                        if (res.data.status === 200) {
                            setIsLoading(false)

                            getItemsList();
                            toast.success(MESSAGE.salesItemUpdate, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                            // setBtn(false);
                            // setOpenTest(false)
                            setSalesItem({
                                typeID: '',
                                name: '',
                                gstTaxability: '',
                                gstRate: '',
                                rpu: 0,
                                uom: '',
                                hssn: '',
                                batchNo:'',
                                stock: '',
                                description: '',
                                rca: '',
                                categoryID: null,
                                // stock1: ''
                            });
                            // handleCloseModal();
                            setIsEdit(false)
                        } else {
                            setIsLoading(false)

                            toast.error(res.data.error, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    })
                    .catch((e) => {
                    });
                //   } 
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
                touched,
                values,
                resetForm
            }) => (
                <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <DialogContent >
                        <Box mt={3} style={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                            borderRadius: '5px',
                            padding: '20px'
                        }}>

                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touched.typeID && errors.typeID)}
                                    >
                                        <InputLabel required id="demo-simple-select-outlined-label">Product Item</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={salesItem.typeID}
                                            disabled={true}
                                            name="typeID"
                                            label="Product Item"
                                            required
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.typeID = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    typeID: e.target.value
                                                });
                                                // getCategories(e.target.value);
                                            }}
                                        >
                                            {lookupData && lookupData.map((x) => {
                                                return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                                            })}
                                        </Select>
                                        <FormHelperText>{(errors.typeID && touched.typeID) && errors.typeID}</FormHelperText>
                                    </FormControl>

                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touched.categoryID && errors.categoryID)}
                                    >
                                        <InputLabel required id="demo-simple-select-outlined-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={salesItem.categoryID}
                                            label="Category"
                                            name="categoryID"
                                            required
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.categoryID = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    categoryID: e.target.value
                                                });
                                            }}
                                            disabled
                                        >
                                            {categoryList && categoryList.map((x) => {
                                                return <MenuItem value={x.categoryID} key={x.categoryID}>{x.name}</MenuItem>;
                                            })}
                                        </Select>
                                        <FormHelperText>{(errors.categoryID && touched.categoryID) && errors.categoryID}</FormHelperText>
                                    </FormControl>
                                </Grid> */}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Name                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         "
                                        name="name"
                                        value={salesItem.name}
                                        // disabled={true}
                                        inputProps={{ maxLength: 20 }}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            // if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.name = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    name: e.target.value
                                                });
                                            // }
                                        }
                                        }
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        required
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.hssn = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    hssn: e.target.value
                                                });
                                            }
                                        }}
                                        error={Boolean(touched.hssn && errors.hssn)}
                                        helperText={touched.hssn && errors.hssn}
                                        label="HSSN / SAC Code"
                                        name="hssn"
                                        value={salesItem.hssn}
                                        required
                                        variant="outlined"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              label="Batch No"
                              name="batchNo"
                            //   onChange={(e) => {
                            //       handleChange(e);
                            //       const p = salesItem;
                            //       p.batchNo = e.target.value;
                            //       setSalesItem({
                            //         ...p,
                            //         batchNo: e.target.value
                            //       });
                            //   }}
                              variant="outlined"
                              value={salesItem.batchNo}
                              disabled
                            />
                          </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        value={salesItem.uom}
                                        onChange={(e) => {
                                            if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.uom = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    uom: e.target.value
                                                });
                                            }
                                        }}
                                        error={Boolean(touched.uom && errors.uom)}
                                        helperText={touched.uom && errors.uom}
                                        label="Unit of Measurement"
                                        name="uom"
                                        required
                                        variant="outlined"
                                        disabled={true}
                                    />
                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        inputProps={{ maxLength: 9 }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            if (e.target.value === '' || /^[0-9.]*$/.test(e.target.value)) {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.rpu = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    rpu: e.target.value
                                                });
                                            }
                                        }}
                                        value={salesItem.rpu}
                                        error={Boolean(touched.rpu && errors.rpu)}
                                        helperText={touched.rpu && errors.rpu}
                                        label="Rate per Unit"
                                        name="rpu"
                                        required
                                        variant="outlined"
                                    />
                                </Grid> */}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touched.gstTaxability && errors.gstTaxability)}
                                    >
                                        <InputLabel required id="demo-simple-select-outlined-label">GST Taxability</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={salesItem.gstTaxability}
                                            name="gstTaxability"
                                            label="GST Taxability"
                                            onBlur={handleBlur}
                                            required
                                            onChange={(e) => {
                                                handleChange(e);
                                                if (e.target.value === 'true') {
                                                    console.log(salesItem);
                                                    const p = salesItem;
                                                    p.gstTaxability = true;
                                                    setSalesItem({
                                                        ...p,
                                                        gstTaxability: true
                                                    });
                                                } else {
                                                    const p = salesItem;
                                                    p.gstTaxability = false;
                                                    setSalesItem({
                                                        ...p,
                                                        gstTaxability: false
                                                    });
                                                }
                                            }}
                                        >
                                            <MenuItem value="true">YES</MenuItem>
                                            <MenuItem value="false">NO</MenuItem>
                                        </Select>
                                        <FormHelperText>{(errors.gstTaxability && touched.gstTaxability) && errors.gstTaxability}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                {salesItem.gstTaxability === true && (
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">GST Rate(%)</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                onBlur={handleBlur}
                                                value={salesItem.gstRate}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = salesItem;
                                                    p.gstRate = e.target.value;
                                                    setSalesItem({
                                                        ...p,
                                                        gstRate: e.target.value
                                                    });
                                                }}
                                                label="GST Rate(%)"
                                                name="gstRate"
                                                required
                                            >
                                                <MenuItem value={5}>5%</MenuItem>
                                                <MenuItem value={12}>12%</MenuItem>
                                                <MenuItem value={18}>18%</MenuItem>
                                                <MenuItem value={28}>28%</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touched.rca && errors.rca)}
                                    >
                                        <InputLabel required id="demo-simple-select-outlined-label">Reverse Charge Applicability</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={salesItem.rca}
                                            label="Reverse Charge Applicability"
                                            name="rca"
                                            required
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                if (e.target.value === 'true') {
                                                    const p = salesItem;
                                                    p.rca = true;
                                                    setSalesItem({
                                                        ...p,
                                                        rca: true
                                                    });
                                                } else {
                                                    const p = salesItem;
                                                    p.rca = false;
                                                    setSalesItem({
                                                        ...p,
                                                        rca: false
                                                    });
                                                }
                                            }}
                                        >
                                            <MenuItem value="true">YES</MenuItem>
                                            <MenuItem value="false">NO</MenuItem>
                                        </Select>
                                        <FormHelperText>{(errors.rca && touched.rca) && errors.rca}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                                        }}
                                        InputProps={{
                                            inputProps: { min: 1 }
                                        }}
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                                handleChange(e);
                                                const p = salesItem;
                                                p.stock = e.target.value;
                                                setSalesItem({
                                                    ...p,
                                                    stock: e.target.value
                                                });
                                            }
                                        }}
                                        disabled={true}
                                        value={salesItem.stock}
                                        // error={Boolean(touched.stock && errors.stock)}
                                        // helperText={touched.stock && errors.stock}
                                        label="Current Available Stock"
                                        name="stock"
                                        required
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        inputProps={{ maxLength: 200 }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const p = salesItem;
                                            p.description = e.target.value;
                                            setSalesItem({
                                                ...p,
                                                description: e.target.value
                                            });
                                        }}
                                        value={salesItem.description}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        label="Item Description"
                                        name="description"
                                        required
                                        variant="outlined"
                                    />
                                </Grid>
                                <Divider style={{ background: 'rgb(143 152 203)', width: "100%" }} />
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <Grid container justify="space-around">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                // margin="normal"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="Date of Production / Grading"
                                                format="dd/MM/yyyy"
                                                // format="yyyy/MM/dd"
                                                disableFuture={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="productionDate"
                                                onChange={(e) => {

                                                    console.log("ooo", e);
                                                    const p = salesItem;
                                                    p.productionDate = moment(e).format("YYYY-MM-DD");
                                                    setSalesItem({
                                                        ...p,
                                                        productionDate: moment(e).format("YYYY-MM-DD")
                                                    });
                                                }}
                                                value={salesItem.productionDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                // TextFieldComponent={TextFieldComponent}
                                                className={classes.calmar}
                                                required
                                                error ={Boolean(touched.productionDate && errors.productionDate)}
                                                helperText={touched.date && errors.date}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                {/* {btn ?  */}
                                <>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            inputProps={{ maxLength: 20 }}
                                            fullWidth
                                            // onBlur={handleBlur}
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                                            }}
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            onKeyPress={(event) => {
                                                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                                    handleChange(e);
                                                    // const p = salesItem;
                                                    // p.stock1 = e.target.value;
                                                    // setSalesItem({
                                                    //     ...p,
                                                    //     stock1: e.target.value
                                                    // });
                                                    setProductionStock(e.target.value)
                                                }
                                            }}
                                            value={productionStock}
                                            // error={Boolean(touched.stock && errors.stock)}
                                            // helperText={touched.stock && errors.stock}
                                            label="Produced Stock"
                                            name="Produced stock"
                                            // required
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            inputProps={{ maxLength: 20 }}
                                            fullWidth
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                                            }}
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            onKeyPress={(event) => {
                                                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                                    const p = salesItem;
                                                    p.mortalityCount = e.target.value;
                                                    setSalesItem({
                                                        ...p,
                                                        mortalityCount: e.target.value
                                                    });
                                                }
                                            }}
                                            value={salesItem.mortalityCount}
                                            label="Mortality Stock"
                                            name="Mortality stock"
                                            // required
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                                {/* : null} */}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                        <Button onClick={(e => {
                            setIsEdit(false);
                            setSalesItem('')
                            // cancelSalesItem(resetForm)
                        })} className={classes.button2}>
                            Cancel
                        </Button>

                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                className={classes.button2}
                            >
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    </>
};

export default EditSales;
