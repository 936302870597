import React, { useEffect } from 'react';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import Page from 'src/components/Page';
import Sales from './Sales';
import Revenue from './revenue';
import LineChart from './lineChart';
import Farmer from './Farmer';
import FisherMen from './FisherMen';
import Portfolio from './Portfolio';
import FingerLingsPieChart from './FingerLings';
import { ChartsData } from './data';
import './style.css'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [location, setLocaton] = React.useState({ location: 'Tanjavur', year: "2019" });
  const [chatdata, setChatdata] = React.useState([]);
  const [testdata, setTestdata] = React.useState([]);
  const [achiveddata, setAchiveddata] = React.useState([]);
  const [labeldata, setLabledata] = React.useState([]);
  const [useeffectstop, setUseEffect] = React.useState(true);
  const [finaldata, setFinaldata] = React.useState({})
  const [farmarData, setFarmarData] = React.useState([]);
  const [fisherMen, setFisherMen] = React.useState([]);


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    segrate(location)
  }, [])

  const segrate = (location) => {
    let data = []
    ChartsData.AchivementData.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setTestdata(x)

      }
    })

    ChartsData.FarmarData.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setFarmarData(x)

      }
    })
    ChartsData.FisherMen.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setFisherMen(x)

      }
    })

  }

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Box>
          <Card>
            <CardContent>

              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >

                  <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">ADF office</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={location.location}
                      onChange={(e) => {
                        let p = location
                        p.location = e.target.value
                        setLocaton({
                          ...p
                        });
                        segrate(p)
                      }}
                      label="ADF office"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Tanjavur">Thanjavur</MenuItem>
                      <MenuItem value="Thiruvallur">Thiruvallur</MenuItem>
                      <MenuItem value="Bhavanisagar">Bhavanisagar</MenuItem>
                    </Select>
                  </FormControl>

                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >

                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={location.year}
                      onChange={(e) => {
                        let p = location
                        p.year = e.target.value
                        setLocaton({
                          ...p
                        });
                        segrate(p)
                      }}
                      label="Year"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="2019">2019</MenuItem>
                      <MenuItem value="2018">2018</MenuItem>
                      <MenuItem value="2017">2017</MenuItem>
                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
              {/* </Box> */}
            </CardContent>
          </Card>
        </Box>
      </Container>
      &nbsp;
      <br></br><br></br> <br></br><br></br>
      <Container maxWidth={false}>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <Sales testdata={testdata} />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            {/* <Portfolio /> */}
            <Revenue testdata={testdata} />
          </Grid>



          &nbsp;
        </Grid>
        <Grid
          container
          spacing={2}
        >

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <Portfolio testdata={testdata} />
            {/* <LineChart /> */}
          </Grid>

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <FingerLingsPieChart testdata={testdata} />
          </Grid>


        </Grid>
        &nbsp;


        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <Farmer testdata={farmarData} />
          </Grid>

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <FisherMen testdata={fisherMen} />
          </Grid>
        </Grid>
        &nbsp;
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {/* <Portfolio /> */}
            {/* <Revenue /> */}
            <LineChart />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
