import React, { useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import '../../AddProject/style.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { Formik, Form } from 'formik';
import { MESSAGE } from 'src/utils/message';
import { useToasts } from 'react-toast-notifications';
import { RGCA_URLS } from 'src/utils/url';
import CommonTable from '../Common/CommonTable';
import { createTheme } from '@material-ui/core/styles';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import { useParams } from 'react-router-dom';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loader from 'src/views/loader';
import Address from '../Common/Address';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CommonTable1 from '../Common/commonTableIndent';
import StepperBox from '../Common/StepperBox.jsx';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApprovalWorkOrder from 'src/views/Project/workOrder/Approval.jsx';
import WorkOrderApprovalList from 'src/views/Project/workorderapprovallist/index.jsx';
import { checkPermission } from '../../../utils/permission.jsx';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  centerText: {
    textAlign: 'left',
    color: '#394671',
    [theme.breakpoints.down('xs')]: {
      
      textAlign: 'center',
      // marginLeft:'75px !important',
      color: '#394671',
    }
  },
    vendor:{
      [theme.breakpoints.down('xs')]: {
        width:'100%'
      }
    
    
  },
  centerbutton: {
    [theme.breakpoints.down('md')]: {
      marginBottom:'5px',
      },
    [theme.breakpoints.down('xs')]: {
    marginBottom:'5px',
    marginLeft:'30%',
    marginRight:'30%',
    
    },
  },
  txtfield:{

    [theme.breakpoints.down('md')]: {
      width:"100%",
      minWidth:'300px'
      },
      [theme.breakpoints.down('xs')]: {
        width:"100%",
        minWidth:'230px'
        },
  },
  gst:{
    [theme.breakpoints.down('md')]: {
      minWidth:'665px',
      width:"100%",
    },
    [theme.breakpoints.down('xs')]: {
      minWidth:"235px",
      width:"100%"
      },
  },
vend:{
  [theme.breakpoints.down('xs')]: {
    minWidth:"260px",
    width:"100%"
  }
},
radio:{
  [theme.breakpoints.down('xs')]: {
   marginLeft:'45px'
  }
},
item:{
  [theme.breakpoints.down('xs')]: {
    minWidth:"230px"
   }
}

}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const NewWorkOrder = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { project } = useParams();
  const navigate = useNavigate();
  const projectDetails = { ...getProjectDetails(project) };

  const [alertopen, setAlertOpen] = React.useState(false);
  const [openTest, setOpenTest] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openTest1, setOpenTest1] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMeg, setErrorMeg] = React.useState('');
  const [lookupData, setLookupData] = useState([]);
  const [scroll, setScroll] = useState('paper');
  const [page, setPage] = useState(0);
  const [edit, setEdit] = React.useState(false);
  const [editIcon, setEditIcon] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [woView, setWoView] = React.useState(false);
  const [allVendors, setAllVendors] = useState([]);
  const [btn, setBtn] = useState(false);
  const [arrayTableData, setArrayTableData] = useState([]);
  const [indexData, setIndexData] = useState(null);
  const [catOrRec, setCatOrRec] = useState([]);
  const [inter, setInter] = useState(false);
  const [intra, setIntra] = useState(false);
  const [union, setUnion] = useState(false);
  const [gstState, setGstState] = useState('');
  const [workOrderList, setWorkOrderList] = useState([]);
  const [singleWO, setSIngleWo] = useState([]);
  const [GetMaterialsList, setGetMaterialsList] = useState([]);
  const allData = [
    
    {
      value: "view_workOrder",
      name: 'View My Work Order'
    },
    {
      value: 'view&edit_workOrder',
      name: 'Edit My Work Order'
    },
    {
      value: 'approval_workOrder',
      name: 'Pending For Work Order'
    },
    {
      value: 'approved_workOrder',
      name: 'Approved Work Order list'
    },
    
  ];

  let userData = JSON.parse(localStorage.getItem('userData'));

  var initialValue = {};
  initialValue = allData.find(data => {
    if (checkPermission(data.value) || userData?.isAdmin == 1) return data;
  });
  const [value, setValue] = React.useState(initialValue?.value); // first tab
  
  const tabList = allData.filter(x => checkPermission(x.value) || userData?.isAdmin == 1);
 

  const [itemList, setItemList] = useState([
    {
      noe: '',
      qty: '',
      unit: '',
      rate: '',
      isGst: '',
      gstType: '',
      CGST: '',
      SGST: '',
      IGST: '',
      UGST: '',
      totalValue: '',
      itemDescription: ''
    }
  ]);

  const [payload, setPayload] = useState({
    projectID: null,
    vendorID: '',
    gstType: '',
    tcv: '',
    dsa: '',
    items: itemList
  });

  const [objAddData, setObjAddData] = useState({
    noe: '',
    qty: '',
    unit: '',
    rate: '',
    isGst: '',
    gstType: '',
    CGST: '',
    SGST: '',
    IGST: '',
    UGST: '',
    totalValue: '',
    itemDescription: ''
    
  });

  const [workOrder, setWorkOrder] = useState({
    projectID: 1,
    vendorID: '',
    name: '',
    email: '',
    mobile: '',
    description: '',
    noe: '',
    materialItem: '',
    dsa: '',
    qty: '',
    tcv: '',
    taxDetails: '',
    paymentTerms: '',
    otherConditions: '',
    address: {
      line_1: '',
      line_2: '',
      line_3: '',
      district: '',
      state: '',
      country: '',
      postalCode: ''
    }
  });

  const [columns, setColumns] = useState([
    {
      title: 'WorkOrder MasterID',
      field: 'workOrderMasterID',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Gst Type',
      field: 'gstType',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Attachment',
      field: 'dsa',
      align: 'center',
      type: ETTypes.link
    },
    {
      title: 'Total Contract Value',
      field: 'tcv',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Pending Amount',
      field: 'pendingAmountWO',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Status',
      field: 'status',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      list: [ETaction.onEdit]
      // list: value == 'view_workOrder' ? [ETaction.onView] : [ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  const [columns2, setColumns2] = useState([
    {
      title: 'WorkOrder MasterID',
      field: 'workOrderMasterID',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Gst Type',
      field: 'gstType',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Attachment',
      field: 'dsa',
      align: 'center',
      type: ETTypes.link
    },
    {
      title: 'Total Contract Value',
      field: 'tcv',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Pending Amount',
      field: 'pendingAmountWO',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Status',
      field: 'status',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      list: [ETaction.onView]
      // list: value == 'view_workOrder' ? [ETaction.onView] : [ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);
  const [deletePermmsion, setDeletePermmsion] = useState(false);
  const updateColumns = [
    {
      title: 'WorkOrder ID',
      field: 'workOrderID',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Nature',
      field: 'noename',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Item Description',
      field: 'itemDescription',
      align: 'center',
      type: ETTypes.string,
      editable: EEditable.onEdit
    },

    {
      title: 'Quantity',
      field: 'qty',
      align: 'center',
      type: ETTypes.numeric,
      editable: EEditable.onEdit
    },
    {
      title: 'Rate Per Unit',
      field: 'rate',
      align: 'center',
      type: ETTypes.numeric,
      editable: EEditable.onEdit
    },
    {
      title: 'Unit',
      field: 'unit',
      align: 'center',
      type: ETTypes.string,
      editable: EEditable.onEdit
    },
    {
      title: 'Total Value',
      field: 'totalValue',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'IsGST',
      field: 'isGst',
      align: 'center',
      type: ETTypes.boolean
    },
    {
      title: 'CGST',
      field: 'CGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'SGST',
      field: 'SGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'IGST',
      field: 'IGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'UGST',
      field: 'UGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      list: []
      //  list: value == 'view&edit_workOrder' ? [ETaction.onDelete] : []
      
    }
  ]

  

  const upFile = []
  useEffect(() => {
    getLookup()
  }, [navigate])

  useEffect(() => {
    let deleteOption =  checkPermission("Delete_workOrder");
    console.log("deleteOption",deleteOption);
    if(deleteOption){
      setDeletePermmsion(true)
    }
    // let permissionData = JSON.parse(localStorage.getItem('permissionData'));
    // console.log("kkkk",permissionData);
    // let deleteOption = permissionData.find(x => x.actionID == 31)
    // console.log("kkkk2",deleteOption);

    //  setDeletePermmsion(deleteOption? true : false);
    //  if(deleteOption.actionName= "Delete_workOrder"){
    //   let data = [...updateColumns];
    //   data[data.length - 1].list = [ETaction.onDelete]
    //   setUpdateColumns(data)
    // //  setUpdateColumns([...updateColumns,
    // //   updateColumns[-1].list = [ETaction.onDelete] ])
    //  }
  },[])

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data)
          console.error('catorrec', catOrRec)
          setLookupData([...res.data.data])
          console.error('lookup', lookupData)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  useEffect(() => {
    getWorkorder();
    getVendor();
  }, [navigate]);

  const handleWOView = () => {
    setWoView(!woView);
    getWorkorder();
  };

  const addItem = () => {

    if (!inter && !intra && !union) {
      toast.error('First select any one GST state', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return
    }
    setOpenTest1(!openTest1);
    setObjAddData({
      noe: '',
      qty: '',
      unit: '',
      rate: '',
      isGst: '',
      gstType: '',
      CGST: '',
      SGST: '',
      IGST: '',
      UGST: '',
      totalValue: '',
      itemDescription: ''
    });
  };

  const handleCloseModal = (resetForm = null) => {
    setOpenTest(false);
    
    setWorkOrder({
      projectID: 1,
      vendorID: '',
      name: '',
      email: '',
      mobile: '',
      description: '',
      noe: '',
      materialItem: '',
      dsa: '',
      qty: '',
      tcv: '',
      taxDetails: '',
      paymentTerms: '',
      otherConditions: '',
      address: {
        line_1: '',
        line_2: '',
        line_3: '',
        district: '',
        state: '',
        country: '',
        postalCode: ''
      }
    });
    if (resetForm) {
      setArrayTableData([])
      resetForm();

    }
  };

  const modelActions = {
    editRow: (index, field, value) => {
      let items = singleWO;
      items.workOrder[index][field] = value;
      setSIngleWo({ ...items });
      if (field == 'qty') {
        let items = singleWO;
        items.workOrder[index]['totalValue'] =
          value * items.workOrder[index]['rate'] +
          value *
          items.workOrder[index]['rate'] *
          ((items.workOrder[index]['inter'] >
            items.workOrder[index]['intra'] + items.workOrder[index]['union']
            ? items.workOrder[index]['inter']
            : items.workOrder[index]['intra'] >
              items.workOrder[index]['inter'] +
              items.workOrder[index]['union']
              ? items.workOrder[index]['intra']
              : items.workOrder[index]['union']) /
            100);
        setSIngleWo({ ...items });
      } else if (field == 'rate') {
        let items = singleWO;
        items.workOrder[index]['totalValue'] =
          value * items.workOrder[index]['qty'] +
          value *
          items.workOrder[index]['qty'] *
          ((items.workOrder[index]['inter'] >
            items.workOrder[index]['intra'] + items.workOrder[index]['union']
            ? items.workOrder[index]['inter']
            : items.workOrder[index]['intra'] >
              items.workOrder[index]['inter'] +
              items.workOrder[index]['union']
              ? items.workOrder[index]['intra']
              : items.workOrder[index]['union']) /
            100);
        setSIngleWo({ ...items });
      }
    },

    onDelete: (index, row) => {
      // console.log("delete:",index, row)
      removeWorkOrder(row);
    }
  };

  const actions = {
    onEdit: (index, row) => {
      setWoView(!woView);
      setScroll('paper');
      getMaterials(row.noe, projectDetails.projectID);
      setIsEdit(true);
      setBtn(true);
      setSIngleWo({ ...row });
      setWorkOrder({ ...row });
    },
    onView: (index, row) => {
      setWoView(!woView);
      setScroll('paper');
      getMaterials(row.noe, projectDetails.projectID);
      setIsEdit(false);
      setBtn(false);
      setSIngleWo({ ...row });
      setWorkOrder({ ...row });
    },
    onDelete: (index, row) => {
      removeWorkOrder(row);
    }
  };

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.qty;
    });
    return test;
    //  data['totalQty'] = test;
  };

  const getMaterials = (typeID, projectID) => {
    let payload = {
      projectID: projectID,
      typeID: Number(typeID)
    };

    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then(res => {
        if (res.data.status === 200) {
          setGetMaterialsList([...res.data.data]);
        } else {
        }
      })
      .catch(function (e) { });
    // console.log('text-text',payload)
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const updateWorkOrder = () => {

    const payload = {
      workOrderMasterID: singleWO.workOrderMasterID,
      tcv: singleWO?.workOrder?.reduce(
        (a, b) =>
          b.qty * b.rate +
          b.qty *
          b.rate *
          ((b.inter > b.intra + b.union
            ? b.inter
            : b.intra > b.inter + b.union
              ? b.intra
              : b.union) /
            100) +
          a,
        0
      ),
      items: singleWO?.workOrder?.map(e => {
        if (e.itemDescription == '' || (e.qty == null || e.qty == 0 || e.qty == "0") || (e.rate == null || e.rate == 0 || e.rate == "0") || (e.unit == null || e.unit == 0 || e.unit == "0")) {
          // addToast(MESSAGE.passwordChanged, { appearance: 'error' });
          toast.error("please fill the mandatory fields", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else {
          return {
            workOrderID: e.workOrderID,
            noe: e.noe,
            itemDescription: e.itemDescription,
            qty: e.qty,
            units: e.unit,
            rate: e.rate,
            totalValue:
              e.inter > e.intra + e.union
                ? e.qty * e.rate + (e.qty * e.rate * e.inter) / 100
                : e.intra > e.inter + e.union
                  ? e.qty * e.rate + (e.qty * e.rate * e.intra) / 100
                  : e.qty * e.rate + (e.qty * e.rate * e.union) / 100,
            isGST: e.isGst,
            CGST: e.CGST,
            SGST: e.SGST,
            IGST: e.IGST,
            UGST: e.UGST
          };
        }
      })
    };

    APIKit.put(RGCA_URLS.updateworkorder, payload)
      .then(res => {
        if (res.data.status === 200) {
          getWorkorder();
          // addToast(res.data.message, { appearance: 'error' });
          toast.success(MESSAGE.workorderUpdated, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });

          handleWOView();
        } else {
          // addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.workorderNotUpdated, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const [originalWorkOrderList, setOriginalWorkOrderList] = useState([])

  const getWorkorder = () => {
    
    const payload = {
      projectID: projectDetails.projectID
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.listworkOrder, payload)
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          console.log('listworkOrder',res.data);
          let data = res?.data?.data.map(x => {
           
            return {
              ...x,
              Approvals: x?.Approvals && parseData(x?.Approvals),
              // status : x.Approvals && parseData(x?.Approvals)[0]?.status,
              status:x?.statusName,
              workOrder: x?.workOrder && parseData(x.workOrder).length > 0 && parseData(x.workOrder)
                .map(e => {
                  return {
                    ...e,
                    inter: e?.IGST,
                    intra: e?.SGST + e?.CGST,
                    union: e?.UGST + e?.CGST
                  };
                })
            };
          
          });
          
          setOriginalWorkOrderList(data);
          let filterData = data.filter(x =>{
            // if(x?.Approvals && x?.Approvals.length > 0 && x?.Approvals[0]?.status == "OPEN")
            if(x?.statusName == "OPEN")
            {
              return x
            }
          })
          setWorkOrderList(value == 'view&edit_workOrder' ? filterData : data);
         
          } else {

        }
      })
      .catch(function (e) { });
  };


  useEffect(()=>{
    // alert(value)
    if(originalWorkOrderList.length > 0){
    let filterData = originalWorkOrderList.filter(x =>{
      // if(x?.Approvals && x?.Approvals.length > 0 && x?.Approvals[0]?.status == "OPEN"){
        if(x?.statusName == "OPEN"){
        return x
      }
    })
    console.log("work order filterData", filterData);
    setWorkOrderList(value == 'view&edit_workOrder' ? filterData : originalWorkOrderList);
  }
  },[value])

  const removeData = i => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        let item = arrayTableData;
        item.splice(i, 1);
        setArrayTableData([...item]);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const removeWorkOrder = data => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        remove(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] !== undefined) {
      if (event.target.files[0].size > 3145728) {
        toast.error(MESSAGE.lessthan3mb, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      // formData.append(
      //   'upload',
      //   event.target.files[0],
      //   event.target.files[0].name
      // );
      formData.append(
        'files',
        event.target.files[0],
        event.target.files[0].name
      );
      if (type == 'dsa') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then(res => {
            console.log("file upload res", res?.data?.data);
            if (res.data.status == 200) {
              setIsLoading(false);
              // addToast(res.data.message, { appearance: 'success' });
              toast.success(MESSAGE.fileUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              setPayload({
                ...payload,
                dsa: res.data.data
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
          })
          .catch(e => { });
      }
    }
  };

  const remove = data => {
    APIKit.delete(RGCA_URLS.deleteworkOrder + '/' + data.workOrderID)
      .then(res => {
        if (res.data.status === 200) {
          getWorkorder();
          // addToast(res.data.message, { appearance: 'success' });
         
          toast.success(MESSAGE.workorderDeleted, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          handleWOView();
        } else {
          // addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.workOrderNotDeleted, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const handleState = e => {
    if (e.target.value === 'Interstate') {
      setInter(true);
      setIntra(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Intrastate') {
      setIntra(true);
      setInter(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Union') {
      setUnion(true);
      setInter(false);
      setIntra(false);
      setGstState(e.target.value);
    }
  };

  const openWorkOrder = () => {
    setBtn(false);
    setWorkOrder({
      projectID: 1,
      vendorID: '',
      name: '',
      email: '',
      mobile: '',
      description: '',
      noe: '',
      materialItem: '',
      dsa: '',
      qty: '',
      tcv: '',
      taxDetails: '',
      paymentTerms: '',
      otherConditions: '',
      address: {
        line_1: '',
        line_2: '',
        line_3: '',
        district: '',
        state: '',
        country: '',
        postalCode: ''
      }
    });
    setOpenTest(false);
    setOpenTest(true);
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^.+[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  const editItem = (data, i) => {
    setIndexData(i);
    setOpenTest1(!openTest1);
    setObjAddData({ ...data });
    setEdit(!edit);
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

//   useEffect(()=>{
// alert(objAddData?.isGst);

//   },[objAddData?.isGst])

  return (
    <Page className={classes.root} title="Work Order">
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        <ToastContainer />
        <Snackbar
          open={alertopen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity={alertMeg}>
            {errorMeg}
          </Alert>
        </Snackbar>
        <Box>
          <Grid container>
            <Grid item xs={12}  sm={12}>
              <Typography >
                <h2  className={classes.centerText} >Work Order</h2>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
              {(checkPermission('create_workOrder') || userData?.isAdmin == 1) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openWorkOrder}
                  className={classes.centerbutton}
                >
                  Create Work Order 
                </Button>
              )}
             
            </Grid>
          </Grid>
        </Box>

        {/* Tab start */}
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                {tabList &&
                  tabList.length > 0 &&
                  tabList.map((data, index) => {
                    return (
                      <Tab
                        style={{ fontWeight: 'bold' }}
                        key={index}
                        label={data.name}
                        value={data.value}
                        onClick={() => a11yProps(data.value)}
                      />
                    );
                  })}
              </TabList>
            </Box>
            <>
            {value == 'view_workOrder' && (
                <TabPanel
                  key={4}
                  value="view_workOrder"
                  index="view_workOrder"
                >
                  <>
                    <Typography>
                      <h2 style={{ color: '#394671' }}>View My work order </h2>
                    </Typography>
                    <Grid
                      item
                      style={{ marginTop: '15px' }}
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <CommonTable
                          columns={columns2}
                          data={workOrderList}
                          action={actions}
                        />
                      </div>
                      {console.log("view work",workOrderList)}
                    </Grid>
                  </>
                </TabPanel>
              )}
              {value == 'view&edit_workOrder' && (
                <TabPanel
                  key={1}
                  value="view&edit_workOrder"
                  index="view&edit_workOrder"
                >
                  <>
                    <Typography>
                      <h2 style={{ color: '#394671' }}>Edit My work order </h2>
                    </Typography>
                    <Grid
                      item
                      style={{ marginTop: '15px' }}
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <CommonTable
                          columns={columns}
                          data={workOrderList}
                          action={actions}
                          isEdit = {value == 'view&edit_workOrder'}
                        />
                      </div>
                      {console.log("work order",columns,workOrderList,actions)}
                    </Grid>
                  </>
                </TabPanel>
              )}
              {value == 'approval_workOrder' && (
                <TabPanel
                  key={0}
                  value="approval_workOrder"
                  index="approval_workOrder"
                >
                  <ApprovalWorkOrder />
                </TabPanel>
              )}

              {value == 'approved_workOrder' && (
                <TabPanel
                  key={2}
                  value="approved_workOrder"
                  index="approved_workOrder"
                >
                  <WorkOrderApprovalList />
                </TabPanel>
              )}
            </>
          </TabContext>
        </Box>
        {/* Tabs End */}

        {/* <AppBar position="static" color="default" style={{ marginTop: '1em' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {checkPermission('view&edit_indent') && (
              <Tab label="My Work Order" onClick={() => a11yProps(0)} />
            )}
            {checkPermission('approval_workOrder') && (
              <Tab label="Approval Work Order" onClick={() => a11yProps(1)} />
            )}

            {checkPermission('view&edit_indent') && (
              <Tab
                label="Approved Work Order list"
                onClick={() => a11yProps(2)}
              />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
        
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ApprovalWorkOrder />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WorkOrderApprovalList />
        </TabPanel> */}
      </Container>
      <Formik
        enableReinitialize={true}
        initialValues={{
          vendorID: payload.vendorID
        }}
        onSubmit={values => {
          setIsLoading(true);
          let pay = {
            projectID: projectDetails.projectID,
            vendorID: payload.vendorID,
            tcv: arrayTableData.reduce(
              (a, b) => b.qty * b.rate + b.qty * b.rate * (b.gstType / 100) + a,
              0
            ),
            dsa: payload.dsa,
            gstType: gstState,
            items: arrayTableData.map(e => {
              return {
                noe: e.noe,
                itemDescription: e.itemDescription,
                qty: e.qty,
                units: e.unit,
                rate: e.rate,
                totalValue: e.qty * e.rate + e.qty * e.rate * (Number(e.gstType) / 100),
                isGST: e.isGst
              };
            })
          };
          if (inter) {
            pay = {
              ...pay,
              items: arrayTableData.map(e => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue:
                    e.qty * e.rate + e.qty * e.rate * (Number(e.gstType) / 100),
                  isGST: e.isGst,
                  SGST: 0,
                  CGST: 0,
                  IGST: Number(e.gstType),
                  UGST: 0
                };
              })
            };
          } else if (intra) {
            pay = {
              ...pay,
              items: arrayTableData.map(e => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue:
                    e.qty * e.rate + e.qty * e.rate * (Number(e.gstType) / 100),
                  isGST: e.isGst,
                  SGST: Number(e.gstType) / 2,
                  CGST: Number(e.gstType) / 2,
                  IGST: 0,
                  UGST: 0
                };
              })
            };
          } else if (union) {
            pay = {
              ...pay,

              items: arrayTableData.map(e => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue:
                    e.qty * e.rate + e.qty * e.rate * (Number(e.gstType) / 100),
                  isGST: e.isGst,
                  SGST: 0,
                  CGST: Number(e.gstType) / 2,
                  IGST: 0,
                  UGST: Number(e.gstType) / 2
                };
              })
            };
          }
          pay.projectID = projectDetails.projectID;
          APIKit.post(RGCA_URLS.addWorkOrder, pay)
            .then(res => {
              if (res.data.status === 200) {
                setIsLoading(false);
                handleCloseModal();
                getWorkorder();
                toast.success(MESSAGE.workorderSuccessfully, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
                setObjAddData({
                  noe: '',
                  qty: '',
                  unit: '',
                  rate: '',
                  isGst: '',
                  gstType: '',
                  CGST: '',
                  SGST: '',
                  IGST: '',
                  UGST: '',
                  totalValue: '',
                  itemDescription: ''
                });
                setArrayTableData([]);
                setPayload({
                  vendorID: '',
                  gstType: '',
                  tcv: '',
                  dsa: '',
                  items: []
                });
              } else {
                setIsLoading(false);
                toast.error(res.data.error, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              }
            })
            .catch(e => {
              setIsLoading(false);
            });
        }}
        validationSchema={Yup.object().shape({
          vendorID: Yup.string().required(MESSAGE.vendor),
          WO: Yup.array().of(
            Yup.object().shape({
              noe: Yup.string().required(MESSAGE.noe),
              qty: Yup.string().required(MESSAGE.qty).matches(/^[0-9]{5}$/),
              unit: Yup.string().required(MESSAGE.unit),
              rate: Yup.string().required(MESSAGE.rpu),
              isGst: Yup.string().required(MESSAGE.gstsno),

              gstType:objAddData?.isGst && Yup.number().required("GST VALUE is required"),

              itemDescription: Yup.string().required(MESSAGE.itemDescription)
            })
          )
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            open={openTest}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
          >
            <Loader isLoading={isLoading} />

            <DialogTitle
              onClose={e => {
                handleCloseModal(resetForm);
              }}
              id="customized-dialog-title"
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Work Order Creation 
            </DialogTitle>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <DialogContent dividers={scroll === 'paper'}>
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow:
                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12} sm={12} >
                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        error={Boolean(touched.vendorID && errors.vendorID)}
                        className={classes.vend}

                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Vendor*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Location"
                          value={payload.vendorID}
                          name="vendorID"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = payload;
                            p.vendorID = Number(e.target.value);

                            setPayload({
                              ...p
                            });
                          }}
                          // className={classes.vendor}
                        >
                          {allVendors &&
                            allVendors.map(x => {
                              return (
                                <MenuItem value={x.vendorID} key={x.vendorID}>
                                  {x.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>
                          {errors.vendorID &&
                            touched.vendorID &&
                            errors.vendorID}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      {payload.vendorID ? (
                        <>
                          {''}
                          <b> Vendor address : </b>
                          <Address
                            details={
                              allVendors?.filter(e => {
                                if (e.vendorID === payload?.vendorID) {
                                  { console.log(allVendors, "allVendors") }

                                  return {
                                    address: e.address

                                  };
                                }
                              })[0].address
                            }
                          />{''}
                        </>
                      ) : null}
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Button variant="contained" component="label">
                        Detailed Specification Attach{' '}
                        <div>
                          <input
                            type="file"
                            hidden
                            onChange={e => handleUploadClick(e, 'dsa')}
                          />
                          {payload.dsa && (
                            <a
                              style={{ color: 'blue', cursor: 'pointer' }}
                              href={payload.dsa}
                              target="_blank"
                            >
                              :{payload.dsa.substring(4, 20)}
                            </a>
                          )}
                        </div>
                      </Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <RadioGroup
                        aria-label="anonymous"
                        name="row-radio-buttons-group"
                        row
                        onChange={handleState}
                        className={classes.radio}
                      >
                        <FormControlLabel
                          value="Interstate"
                          control={<Radio />}
                          label="Interstate"
                        />
                        <FormControlLabel
                          value="Intrastate"
                          control={<Radio />}
                          label="Intrastate"
                        />
                        <FormControlLabel
                          value="Union"
                          control={<Radio />}
                          label="Union Territory"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlign: 'right' }}>


                      <Button className={classes.button}
                      
                        onClick={() => {
                          setEditIcon(false)
                          addItem()

                        }
                        }
                        
                      >
                        + Add Item 
                      </Button>

                      {/* <div style={{ textAlign: 'right' }}>
                        <Button
                         color="primary"
                  variant="contained"
                          style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            border: '1px solid'
                          }}
                          onClick={() => {
                            setEditIcon(false)
                            addItem()

                          }
                          }
                        >
                          + Add Item


                        </Button>
                      </div> */}
                    </Grid>

                    {/* <TextField
                                    fullWidth
                                    helperText=""
                                    label="Nature of the expenditure"
                                   
                                    
                                    variant="outlined"
                                /> */}

                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell>Nature</TableCell>
                                <TableCell>Item Description</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell style={{ width: '40px' }}>
                                  Rate
                                </TableCell>
                                <TableCell>GST</TableCell>
                                <TableCell>GST Rate</TableCell>
                                <TableCell>IGST</TableCell>
                                <TableCell>CGST</TableCell>
                                <TableCell>SGST</TableCell>
                                <TableCell>UGST</TableCell>
                                <TableCell>Total Value</TableCell>

                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {arrayTableData?.length > 0 && arrayTableData ? (
                                arrayTableData?.map((e, index) => (
                                  <TableRow
                                    hover
                                  // key={emplyoee.id}
                                  // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                                  >
                                    <TableCell>{page * 10 + index + 1}</TableCell>
                                    <TableCell>{e.noe}</TableCell>
                                    <TableCell>{e.itemDescription}</TableCell>
                                    <TableCell>{e.qty}</TableCell>
                                    <TableCell align="left">
                                      {e.rate}

                                      {/* {row.address?JSON.parse(row.address)[0]:"-"} */}
                                    </TableCell>
                                    <TableCell>
                                      {e.isGst ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell>{e.gstType}</TableCell>
                                    <TableCell>
                                      {inter
                                        ? (
                                          e.qty *
                                          e.rate *
                                          (Number(e.gstType) / 100)
                                        ).toFixed(2)
                                        : 0.0}
                                    </TableCell>
                                    <TableCell>
                                      {intra || union
                                        ? (
                                          e.qty *
                                          e.rate *
                                          (Number(e.gstType) / 2 / 100)
                                        ).toFixed(2)
                                        : 0.0}
                                    </TableCell>
                                    <TableCell>
                                      {intra
                                        ? (
                                          e.qty *
                                          e.rate *
                                          (Number(e.gstType) / 2 / 100)
                                        ).toFixed(2)
                                        : 0.0}
                                    </TableCell>
                                    <TableCell>
                                      {union
                                        ? (
                                          e.qty *
                                          e.rate *
                                          (Number(e.gstType) / 2 / 100)
                                        ).toFixed(2)
                                        : 0.0}
                                    </TableCell>
                                    <TableCell>
                                      {inter || intra || union
                                        ? e.qty * e.rate +
                                        e.qty * e.rate * (Number(e.gstType) / 100)
                                        : e.qty * e.rate}
                                    </TableCell>

                                    <TableCell>
                                      <EditOutlinedIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setEdit(null)
                                          editItem(e, index);
                                          setEditIcon(true)
                                          setEdit(true)
                                          // navigate('/app/AddEmplyoee', { state: emplyoee })
                                        }}
                                      />
                                      <DeleteForeverIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          removeData(index);
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow hover role="checkbox" key={1}>
                                  <TableCell colSpan={13} align="center" key={2}>
                                    {'No Data Found'}
                                  </TableCell>{' '}
                                </TableRow>
                              )}

                            </TableBody>
                          </Table>
                        </TableContainer>
                        {arrayTableData?.length > 0 && <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={arrayTableData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />}
                      </>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                {workOrder.editLock == 1 && (
                  <span style={{ color: 'red' }}>
                    Work Order is In Progress
                  </span>
                )}
                &nbsp;
                <Button
                  onClick={e => {
                    handleCloseModal(resetForm);
                  }}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                >
                  Cancel
                </Button>
                {btn == false ? (
                  arrayTableData.length != 0 && (
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Save
                    </Button>
                  )
                ) : (
                  <>
                    {workOrder.editLock == 1 ? (
                      <></>
                    ) : (
                      <Button
                        onClick={updateWorkOrder}
                        style={{
                          backgroundColor: '#394671',
                          color: '#fff',
                          border: '1px solid'
                        }}
                      >
                        Update
                      </Button>
                    )}{' '}
                  </>
                )}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...objAddData
        }}
        onSubmit={(values, { resetForm }) => {
      
          if (edit) {
            let item = arrayTableData;
            item[indexData] = objAddData;
            setArrayTableData(item);
            setOpenTest1(!openTest1);
            setEdit(false);

            ///////
          } else {
            let item = arrayTableData;
            console.log(objAddData);
            item.push(objAddData);
            setArrayTableData(item);
            setOpenTest1(!openTest1);
          }
        }}
        validationSchema={Yup.object().shape({
          noe: Yup.string().required(MESSAGE.noe),
          qty: Yup.string().required(MESSAGE.qty),
          unit: Yup.string().required(MESSAGE.unit),
          rate: Yup.string().required(MESSAGE.rpu),
          isGst: Yup.string().required(MESSAGE.gstsno),
          itemDescription: Yup.string().required(MESSAGE.itemDescription),

          gstType:objAddData?.isGst == true && Yup.number().min(1).required("GST VALUE is required") 

        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openTest1}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="md"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setOpenTest1(!openTest1);
                if (resetForm) {
                  resetForm();
                }
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              {edit ? 'Edit' : 'Add'} Item
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3} className={classes.field}>
                  <Grid item md={3} xs={12}>
                    <FormControl
                      // style={{ width: '100%' }}
                      fullWidth
                      error={Boolean(touched.noe && errors.noe)}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        required
                        id="demo-simple-select-outlined-label"
                      >
                        Nature
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={objAddData.noe}
                        name="noe"
                        required
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.noe = e.target.value;
                          setObjAddData({ ...item });
                        }}
                        // style={{ width: '222px' }}
                        // value={values.type}
                        label="Nature "
                        className={classes.txtfield}
                      >
                        {catOrRec.map(l => {
                          return (
                            <MenuItem value={l.lookupID} key={l.lookupID}>
                              {l.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {errors.noe && touched.noe && errors.noe}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      // type={'number'}
                      onBlur={handleBlur}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.qty = e.target.value;
                          setObjAddData({ ...item });
                        }
                        
                      }}
                      value={objAddData.qty}
                      label="Quantity "
                      required
                      
                      name="qty"
                      variant="outlined"
                      inputProps={{ maxLength: 5 }}
                      max={5}
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Unit"
                      name="unit"
                      error={Boolean(touched.unit && errors.unit)}
                      helperText={touched.unit && errors.unit}
                      onBlur={handleBlur}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)){
                          handleChange(e);
                          let item = { ...objAddData };
                          item.unit = e.target.value;
                          setObjAddData({ ...item });
                        }
                      }}
                      inputProps={{ maxLength: 9 }}
                      required
                      value={objAddData.unit}
                      variant="outlined"

                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Rate per unit"
                      name="rate"
                      error={Boolean(touched.rate && errors.rate)}
                      helperText={touched.rate && errors.rate}
                      onBlur={handleBlur}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)){
                          handleChange(e);
                          let item = { ...objAddData };
                          item.rate = e.target.value;
                          setObjAddData({ ...item });
                        }
                      }}
                      inputProps={{ maxLength: 9 }}
                      required
                      value={objAddData.rate}
                      variant="outlined"

                    />
                  </Grid>

                  <Grid item md={3} xs={12} className={classes.gst}>
                    <FormControl
                     fullWidth
                      variant="outlined"
                      // style={{ width: '100%' }}
                      // className={classes.formControl}
                      className={classes.gst}
                      error={Boolean(touched.isGst && errors.isGst)}
                      id="formadd"
                      // style={{width:'100vw'}}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        GST*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="GST"
                        required
                        name="isGst"
                        // className={classes.txtfield}
                        // style={{ width: '222px' }}
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          if (!e.target.value) {
                            let item = { ...objAddData };
                            item.gstType = '';
                            item.isGst = e.target.value;
                            setObjAddData({ ...item });
                          } else {
                            let item1 = { ...objAddData };
                            item1.isGst = e.target.value;
                            setObjAddData({ ...item1 });
                          }
                        }}
                        value={objAddData.isGst}
                        inputProps={{ maxLength: 5 }}
                      >
                        <MenuItem value={true}>YES</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.isGst && touched.isGst && errors.isGst}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {objAddData.isGst == true ? (
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 2 }}
                        onChange={e => {
                          if (
                            e.target.value === '' ||
                            e.target.value.match(regEx.numbersOnly)
                            // regEx.numbersOnly.test(e.target.value)
                          ) {
                            let item = { ...objAddData };
                            item.gstType = Number(e.target.value);

                            setObjAddData({ ...item });
                          }
                        }}
                        value={objAddData.gstType}
                        label="GST Rate (%)"
                        name="gstType"
                        variant="outlined"
                        required={objAddData.isGst == true ? true : false}
                        error={objAddData.isGst == true && Boolean(touched.gstType && errors.gstType)}
                        helperText={touched.gstType && errors.gstType}
                      />
                        
                    </Grid>
                  ) : null}
                  {inter && objAddData.gstType ? (
                    <>
                      <Grid item md={3} xs={12}>
                        <strong>IGST:</strong> {objAddData.gstType} &nbsp;
                        <strong>IGST Value:</strong>{' '}
                        {(
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 100)
                        ).toFixed(2)}
                      </Grid>
                    </>
                  ) : null}
                  {intra && objAddData.gstType ? (
                    <>
                      <Grid item md={3} xs={12}>
                        <strong>SGST:</strong> {objAddData.gstType / 2} &nbsp;
                        <strong>SGST Value:</strong>{' '}
                        {(
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 2 / 100)
                        ).toFixed(2)}
                        &nbsp;
                        <strong>CGST:</strong> {objAddData.gstType / 2} &nbsp;
                        <strong>CGST Value:</strong>{' '}
                        {(
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 2 / 100)
                        ).toFixed(2)}
                      </Grid>
                    </>
                  ) : null}
                  {union && objAddData.gstType ? (
                    <>
                      <Grid item md={3} xs={12}>
                        <strong>UGST:</strong> {objAddData.gstType / 2} &nbsp;
                        <strong>UGST Value:</strong>{' '}
                        {(
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 2 / 100)
                        ).toFixed(2)}
                        &nbsp;
                        <strong>CGST:</strong> {objAddData.gstType / 2} &nbsp;
                        <strong>CGST Value:</strong>{' '}
                        {(
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 2 / 100)
                        ).toFixed(2)}
                      </Grid>
                    </>
                  ) : null}
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.totalValue && errors.totalValue)}
                      helperText={touched.totalValue && errors.totalValue}
                      onBlur={handleBlur}
                      onChange={e => {
                        if (
                          e.target.value === '' ||
                          regEx.percentage.test(e.target.value)
                        ) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.totalValue = Number(e.target.value);
                          setObjAddData({ ...item });
                        }
                      }}
                      disabled={true}
                      required
                      value={
                        inter || intra || union
                          ? objAddData.qty * objAddData.rate +
                          objAddData.qty *
                          objAddData.rate *
                          (objAddData.gstType / 100)
                          : objAddData.qty * objAddData.rate
                      }
                      label="Total Value"
                      name="totalValue"
                      variant="outlined"
                      inputProps={{ maxLength: 56 }}

                    />
                  </Grid>
                  <Grid item md={12} lg={12} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        touched.itemDescription && errors.itemDescription
                      )}
                      helperText={
                        touched.itemDescription && errors.itemDescription
                      }
                      onBlur={handleBlur}
                      multiline
                      rows={5}
                      onChange={e => {
                        handleChange(e);
                        let item = { ...objAddData };
                        item.itemDescription = e.target.value;
                        setObjAddData({ ...item });
                        console.error(objAddData)
                      }}
                      required
                      value={objAddData.itemDescription}
                      label="Item Description"
                      name="itemDescription"
                      variant="outlined"

                      inputProps={{ maxLength: 500 }}
                      className={classes.item}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}></Grid>
                  <Grid item md={1} xs={12}>
                    {itemList.length > 1 && (
                      <DeleteOutlineIcon
                        onClick={e => {
                          confirm({
                            description: 'You want to delete the record ?'
                          })
                            .then(() => {
                              // let temp = GINData;
                              // temp.splice(i, 1)
                              // setGINdata([...temp])
                              // handleRemoveField(index)
                            })
                            .catch(() => console.log('Deletion cancelled.'));
                        }}
                        style={{ marginTop: '15px', textAlign: 'center' }}
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  onClick={e => {
                    setOpenTest1(!openTest1);
                    if (resetForm) {
                      resetForm();
                      setEdit(false)
                    }
                  }}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}

                >
                  Cancel
                </Button>


                {
                  (edit ?
                    null :
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Add
                    </Button>
                  )
                }

                {
                  (!editIcon ?

                    null : <Button
                      type="submit"
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    // onClick={()=>{
                    //   setEdit(false)
                    // }}

                    >
                      Update
                    </Button>
                  )
                }




                {/* {!edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                   + Add
                  </Button>
                )}
                {edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Update
                  </Button>
                )} */}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={woView}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleWOView}
          style={{
            backgroundColor: '#394671',
            color: '#fff',
            width: '900px !important'
          }}
        >
          Work Order
        </DialogTitle>
        <DialogContent dividers style={{ width: '900px !important' }}>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Indent ID : {singleWO.workOrderMasterID}
                  </h5>
                </Typography>
              </div>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Total Quantity: {totalQty(singleWO.workOrder || [])}
                  </h5>
                </Typography>
              </div>
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Date : {dateFormate(singleWO.CreateDate)}
                  </h5>
                </Typography>
              </div>
            </Grid>
            {/* ---------------------------------------------------------------------------------------- */}

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <CommonTable1
                  columns={updateColumns}
                  data={singleWO.workOrder}
                  action={modelActions}
                  editLock={singleWO.Approvals}
                  isEnabled = {value == 'view&edit_workOrder'}
                  isDeletedoption={ value == 'view&edit_workOrder'? deletePermmsion :""}
                />
              </div>
            </Grid>
          </Grid>

          <br />
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <StepperBox steps={singleWO.Approvals} />
            </Grid>
          </Grid>
          {/* <Box>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              lg={12}>
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >


                  <Typography className={classes.secondaryHeading}>WorkOrder Approved Item History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Item Name</TableCell>
                          <TableCell>Requested Quantity</TableCell>
                          <TableCell>Approval Quantity</TableCell>
                          <TableCell>Updated By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemHis && itemHis.length != 0 ? itemHis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (

                          <TableRow >
                            <TableCell>{item.itemName}</TableCell>
                            <TableCell>{item.requestQty}</TableCell>
                            <TableCell>{item.newValue}</TableCell>
                            <TableCell>{item.employeeName}</TableCell>
                          </TableRow>
                        )) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={4} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box> */}
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          <Button
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
            onClick={e => {
              updateWorkOrder();
            }}
            disabled={
             ( singleWO?.Approvals?.some(e => e.status != 'OPEN') &&
              singleWO?.Approvals?.every(e => e.status != 'Modification') || value == 'view_workOrder')
            }
          >
            Update
          </Button>

          <Button
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
            onClick={handleWOView}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default NewWorkOrder;
