import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";

// import data from './data';
import Page from "src/components/Page";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { ToastContainer, toast } from "react-toastify";
import AutoCompleteField from "../AutoCompleteField/index";
import AutoCompleteField2 from "../AutoCompleteField/autocomplete2";
import { RGCA_URLS } from "../../../utils/url";
import APIKit from "../../../utils/APIKit";
import { useToasts } from "react-toast-notifications";
import Loader from "../../loader";
import { createTheme } from "@material-ui/core/styles";
import "./style.css";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn } from "formik";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { MESSAGE } from "src/utils/message";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getProjectDetails } from "../Common/utilities";
import CommonSelect from "../Common/CommonSelect";
import CommonTable from "../Common/CommonTable";
import { ETTypes, ETaction } from "../Common/Types";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useConfirm } from "material-ui-confirm";
import ClearIcon from '@mui/icons-material/Clear';


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "@media(maxWidth: 480px)": {
      maxWidth: "400px",
      minWidth: "190px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: "Arial",
  },
  productCard: {
    height: "100%",
  },
  logostyle: {
    height: "100px",
    width: "100px",
  },
  textleftshadow: {
    textAlign: "left",
    boxShadow: "none",
  },
  textrightshadow: {
    textAlign: "right",
    boxShadow: "none",
  },
  table: {
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    borderRadius: "5px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(2),
    "@media (min-width: 320px)": {
      margin: theme.spacing(-1),
    },
  },
  com: {
    "@media (min-width: 450px)": {
      width: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const itemInitialValues = {
  typeList: [],
  materialList: [],
  itemID: "",
  subCategoryID: null,
  name: "",
  description: "",
  uom: "",
  brandOrCatalogue: "",
  availabilityQty: 0,
  lastPrice: 0,
  subCategoryName: "",
  categoryName: "",
  typeName: "",
  projectName: "",
  typeID: "",
  categoryID: 0,
  Qty: "",
  estimatedPrice: "",
  comment: "",
};

const NewIndent = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { project } = useParams();
  const [quoteUrl, setQuoteUrl] = useState([]);
  const [quoteUrlData, setQuoteUrlData] = useState("");
  const [indexData, setIndexData] = useState(null);
  const [lookupData, setLookupData] = useState([]);
  const projectData = useSelector((x) => x);
  const navigate = useNavigate();
  const confirm = useConfirm();
  const projectDetails = { ...getProjectDetails(project) };
  var proId = projectData.ProjectInfo.project.projectID;
  const [inventoryList, setinventoryList] = useState([]);
  const [farList, setFarList] = useState([]);
  const [catOrRec, setCatOrRec] = useState([]);
  const [objAddData, setObjAddData] = useState({
    val: {},
    materialList: [],
    typeID: "",
    itemID: "",
    itemName: "",
    uom: "",
    brandOrCatalogue: "",
    Qty: "",
    estimatedPrice: "",
    totalestimatedPrice: "",
    lastPrice: "",
    averagePrice: "",
    availabilityQty: "",
    comment: "",
    itemAttach: ''
  });
  const [openTest, setOpenTest] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [arrayTableData, setArrayTableData] = useState([]);

  const upFile = [];
  useEffect(() => {
    getLookup();
    getInventoryList();
    getListForFar();
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + "/" + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data);
          console.error("catorrec", catOrRec);
          setLookupData([...res.data.data]);
          console.error("lookup", lookupData);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID,
    })
      .then((res) => {
        if (res.data.status === 200) {
          console.log("getListForIR", res.data.data);
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch((e) => { });
  };

  const getListForFar = () => {
    APIKit.post(RGCA_URLS.getListForFar, {
      projectID: projectDetails.projectID,
    })
      .then((res) => {
        if (res.data.status === 200) {
          console.log("getListForFar", res.data.data);
          setFarList(res.data.data);
        } else {
        }
      })
      .catch((e) => { });
  };

  const handleUploadClick = (event) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event !== undefined) {
      if (event[0].size > 3145728) {
        toast.error(MESSAGE.lessthan3mb, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      setIsLoading(true);
      const uploadFile = [];
      for (let index = 0; index < event.length; index++) {
        const formData = new FormData();
        uploadFile[index] = event[index].name;
        formData.append("upload", event[index], uploadFile[index]);
        APIKit.post(RGCA_URLS.fileUpload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              //addToast(res.data.message, { appearance: 'success' });
              // setQuoteUrl(res.data.data.file)
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              upFile.push(res.data.data.file);
              setQuoteUrl(...quoteUrl, upFile);
              setQuoteUrlData(res.data.data.file);
            } else {
              setIsLoading(false);
              //addToast(res.data.message, { appearance: 'error' });
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
      return;
    }
  };

  const onChangeInput = (value, index) => {

    setObjAddData({
      val: {},
      materialList: [],
      typeID: "",
      itemID: "",
      itemName: "",
      uom: "",
      brandOrCatalogue: "",
      Qty: "",
      estimatedPrice: "",
      totalestimatedPrice: "",
      lastPrice: "0",
      averagePrice: "0",
      availabilityQty: "",
      comment: "",
    });
    console.log("valuesss", value);
    objAddData.val = value;
    setObjAddData({ ...objAddData });
    let item = { ...objAddData };
    item.itemID = value.value;
    item.itemName = value.label;
    let uo = objAddData?.materialList?.find((x) => x.itemID == value.value);
    let inv = inventoryList?.find((x) => x.itemID == value.value);
    let far = farList?.find((x) => x.itemID == value.value);

    console.log("uo", uo);
    console.log("inv", inv);
    console.log("far", far);

    if (inv != undefined) {
      item.uom = uo.uom ? uo.uom : '';
      item.lastPrice = inv.lastPrice ? inv.lastPrice : 0;
      item.averagePrice = inv.averageRate ? Number(inv.averageRate) : 0;
      item.brandOrCatalogue = uo.brandOrCatalogue ? uo.brandOrCatalogue : '';
      item.availabilityQty = String(inv.grnQty);
    } else {
      item.uom = uo?.uom ? uo?.uom : '';
      item.lastPrice = far?.lastPrice ? Number(far?.lastPrice) : 0;
      item.averagePrice = far?.averageRate ? Number(far?.averageRate) : 0;
      item.brandOrCatalogue = uo.brandOrCatalogue ? uo.brandOrCatalogue : '';
      item.availabilityQty = far?.grnQty ? far?.grnQty : 0;
    }
    setObjAddData({ ...item });
  };

  const getMaterials = (i, typeID, projectID) => {
    let payload = {
      // projectID: projectID,
      typeID: typeID,
    };
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then((res) => {
        if (res.data.status === 200) {
          objAddData.materialList = res.data.data;
          let result;
          // if (arrayTableData.length) {
          //   const isSameUser = (a, b) => a.itemID == b.itemID;

          //   // Get items that only occur in the left array,
          //   // using the compareFunction to determine equality.
          //   const onlyInLeft = (left, right, compareFunction) =>
          //     left.filter(leftValue =>
          //       !right.some(rightValue =>
          //         compareFunction(leftValue, rightValue)));

          //   const onlyInA = onlyInLeft(objAddData.materialList, arrayTableData, isSameUser);
          //   const onlyInB = onlyInLeft(arrayTableData, objAddData.materialList, isSameUser);

          //   result = [...onlyInA, ...onlyInB]
          // }

          setObjAddData({ ...objAddData });
        } else {
        }
      })
      .catch(function (e) { });
    // console.log('text-text',payload)
  };

  const [columns, setColumns] = useState([
    {
      title: "Item Name",
      field: "itemName",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Quantity",
      field: "Qty",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Uom",
      field: "uom",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Estimated Price",
      field: "estimatedPrice",
      align: "left",
      type: ETTypes.currency,
    },
    {
      title: "Total Estimated Price",
      field: "totalestimatedPrice",
      align: "left",
      type: ETTypes.currency,
    },
    {
      title: "Last Price",
      field: "lastPrice",
      align: "left",
      type: ETTypes.currency,
    },
    {
      title: "Stock Quantity",
      field: "availabilityQty",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Attachment",
      field: "itemAttach",
      align: "left",
      type: ETTypes.link,
    },
    {
      title: "Description",
      field: "comment",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Action",
      field: "action",
      align: "left",
      list: [ETaction.onEdit, ETaction.onDelete],
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    },
  ]);

  const submitIndent = () => {
    let items = arrayTableData.map((i) => {
      return {
        itemID: i.itemID,
        Qty: i.Qty,
        estimatedPrice: i.estimatedPrice,
        comment: i.comment,
        itemAttach: i.itemAttach
      };
    });
    let temp = checkForDuplicates(items);
    if (temp) {
      toast.error("Materials are same.If you needed increase the quantity", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let payload = {
      projectID: projectDetails.projectID,
      quoteUrl: quoteUrlData ? quoteUrlData : "",
      items,
    };
    setIsLoading(true);

    // if (payload.quoteUrl === '') {
    //   toast.error('Upload Quotation', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   })
    //   return
    // }
    console.log("payloadpayload", payload);
    APIKit.post(RGCA_URLS.addIndent, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(
            "(" + res.data.data[0].indentMasterID + ") " + MESSAGE.indentAdded,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setArrayTableData([]);
          setQuoteUrl([]);
          setQuoteUrlData("");
          getLookup();
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      });
  };

  const addItem = () => {
    setOpenTest(!openTest);
    setObjAddData({
      val: {},
      materialList: [],
      typeID: "",
      itemID: "",
      itemName: "",
      uom: "",
      brandOrCatalogue: "",
      Qty: "",
      estimatedPrice: "",
      totalestimatedPrice: "",
      lastPrice: "",
      availabilityQty: "",
      averagePrice: "",
      comment: "",
      itemAttach: ''
    });
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen.indexOf(value.itemID) !== -1) {
        return true;
      }
      valuesAlreadySeen.push(value.itemID);
    }
    return false;
  }

  const actions = {
    onEdit: (index, row) => {
      setIndexData(index);
      setOpenTest(!openTest);
      setObjAddData({ ...row });
      setEdit(true);
    },
    onDelete: (index, row) => {
      // console.log("delete:",index, row)
      remove(row, index);
    },
  };

  const remove = (data, i) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        let item = arrayTableData;
        item.splice(i, 1);
        setArrayTableData([...item]);
      })
      .catch(() => console.log("Deletion cancelled."));
  };

  const regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
  };

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  });

  const [type, setType] = useState(2);

  const handleUploadAttach = (event, type, wat) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("files", event.target.files[0], event.target.files[0].name);
    if (type == "technical") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setInsuranceAttach(res.data.data);
            // let p = POgrnAdd;
            // p[0].insuranceAttach = res?.data?.data;
            // setPOgrnAdd([...p]);
            let item = { ...objAddData };
            item.itemAttach = res?.data?.data
            setObjAddData({ ...item });
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

  };
  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <ToastContainer />
      <Loader isLoading={isLoading} />
      <Box display="flex" justifyContent="space-between">
        <Typography component="div">
          <h2 style={{ color: "#394671" }}> Rise A New Purchase Indent</h2>
        </Typography>
        <Button
          onClick={addItem}
          variant="outlined"
          style={{ color: "blue", cursor: "pointer" }}
        >
          + Add Item
        </Button>
      </Box>
      <Box>
        <Grid container spacing={3} className={classes.field}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ marginTop: "15px" }}>
              <CommonTable
                columns={columns}
                data={arrayTableData}
                action={actions}
              />
            </div>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: "right" }}>
              {arrayTableData.length != 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    background: "#394671",
                    color: "#fff",
                    marginTop: "20px",
                  }}
                  onClick={submitIndent}
                >
                  Submit
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Formik
        enableReinitialize={true}
        initialValues={{
          ...objAddData,
        }}
        onSubmit={(values, { resetForm }) => {
          if (Object.keys(objAddData.val).length === 0) {
            toast.error("Please select Material", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
          if (edit) {
            let item = arrayTableData;
            item[indexData] = objAddData;
            setArrayTableData(item);
            setOpenTest(!openTest);
            setEdit(true);
          } else {
            let item = arrayTableData;
            item.push(objAddData);
            setArrayTableData(item);
            setOpenTest(!openTest);
          }
        }}
        validationSchema={Yup.object().shape({
          typeID: Yup.string().required(MESSAGE.type),
          Qty: Yup.number()
            .required(MESSAGE.qty)
            .min(1, "mininum one Quantity need"),
          estimatedPrice: Yup.string()
            .notOneOf(["0"], MESSAGE.not0)
            .required(MESSAGE.estimatedPrice),
          // totalestimatedPrice:Yup.string().required("Total Estimated Price is required")
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openTest}
            id="income"
            contentStyle={{
              width: "200%",
              maxWidth: "none",
            }}
            maxWidth="md"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={(e) => {
                setEdit(false);
                setOpenTest(!openTest);
              }}
              style={{ backgroundColor: "#394671", color: "#fff" }}
            >
              {edit ? "Edit" : "Add"} Item
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3} className={classes.field}>
                  <Grid item md={4} xs={12} className={classes.com}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      error={Boolean(touched.typeID && errors.typeID)}
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Type *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="typeID"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          objAddData.val = {};
                          objAddData.lastPrice = 0;
                          objAddData.averagePrice = 0;
                          objAddData.uom = '';
                          objAddData.typeID = e.target.value;
                          setObjAddData({ ...objAddData });
                          getMaterials(
                            0,
                            objAddData.typeID,
                            projectDetails.projectID
                          ); // project Id hard coded0
                        }}
                        required
                        value={objAddData.typeID}
                        label="Type "
                      >
                        {catOrRec.map((l) => {
                          return (
                            <MenuItem value={l.lookupID} key={l.lookupID}>
                              {l.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {errors.typeID && touched.typeID && errors.typeID}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid className={classes.com} item md={4} xs={12}>
                    <CommonSelect
                      val={objAddData.val}
                      onChange={(e) => onChangeInput(e, 0)}
                      options={objAddData.materialList}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Uom"
                      name="uom"
                      onChange=""
                      value={objAddData.uom}
                      variant="outlined"
                    />{" "}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Brand/Catalogue"
                      name="brandOrCatalogue"
                      onChange=""
                      value={objAddData.brandOrCatalogue}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      inputProps={{ maxLength: 5 }}
                      fullWidth
                      label="Quantity"
                      name="Qty"
                      type="text"
                      required
                      onBlur={handleBlur}
                      error={Boolean(touched.Qty && errors.Qty)}
                      helperText={touched.Qty && errors.Qty}
                      onChange={(e) => {
                        if (e.target.value.match(regEx.numbersOnly)) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.Qty = e.target.value;
                          item.totalestimatedPrice =
                            Number(e.target.value) * item.estimatedPrice;
                          setObjAddData({ ...item });
                        }
                      }}
                      value={objAddData.Qty}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Estimated Price "
                      name="estimatedPrice"
                      required
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.estimatedPrice && errors.estimatedPrice
                      )}
                      helperText={
                        touched.estimatedPrice && errors.estimatedPrice
                      }
                      onChange={(e) => {
                        if (e.target.value.match(regEx.numbersOnly)) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.estimatedPrice = Number(e.target.value);
                          item.totalestimatedPrice =
                            Number(e.target.value) * item.Qty;
                          setObjAddData({ ...item });
                        }
                      }}
                      value={objAddData.estimatedPrice}
                      variant="outlined"
                      inputProps={{ maxLength: 8 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Total Estimated Price"
                      name="totalestimatedPrice"
                      // required
                      onBlur={handleBlur}
                      // error={Boolean(touched.totalestimatedPrice && errors.totalestimatedPrice)}
                      // helperText={touched.totalestimatedPrice && errors.totalestimatedPrice}
                      // onChange={(e) => {
                      // if (e.target.value.match(regEx.numbersOnly)) {
                      //   handleChange(e)
                      //   let item = { ...objAddData };
                      //   item.totalestimatedPrice = Number(e.target.value);
                      //   setObjAddData({ ...item })
                      // }
                      // }
                      // }
                      value={objAddData.totalestimatedPrice}
                      // value={Number(objAddData.Qty) * Number(objAddData.estimatedPrice)}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                      disabled
                    // disabled={objAddData.Qty=="" || objAddData.estimatedPrice=="" }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Last price"
                      name="lastPrice"
                      onChange={() => { }}
                      value={objAddData.lastPrice ? parseFloat(objAddData.lastPrice)?.toFixed(2) : '0'}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      label="Average  price"
                      name="averagePrice"
                      onChange={() => { }}
                      value={objAddData.averagePrice ? parseFloat(objAddData.averagePrice)?.toFixed(2) : '0'}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Stock Quantity"
                      name="price"
                      onChange=""
                      value={
                        objAddData.availabilityQty == null
                          ? 0
                          : objAddData.availabilityQty
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="comment"
                      onChange={(e) => {
                        let item = { ...objAddData };
                        item.comment = e.target.value;
                        setObjAddData({ ...item });
                      }}
                      value={objAddData.comment}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                  <Typography>
                        Technical Specification
                        </Typography>
                  
                    <Button
                      style={{ width: "85%", height: "55px" }}
                      variant="contained"
                      component="label"
                    >
                      {!objAddData?.itemAttach ? "Attach Here" : null}
                      <input
                        type="file"
                        hidden

                        inputProps={{ accept: "application/pdf" }}
                        onChange={(e) =>
                          handleUploadAttach(e, "technical", "add")
                        }
                      />
                      {objAddData?.itemAttach && (
                        <a
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            position: "absolute",
                            marginTop: "9px",
                          }}
                          href={
                            objAddData?.itemAttach
                          }
                          target="_blank"
                        >
                          {objAddData?.itemAttach?.substring(4, 25)}....
                          {objAddData?.itemAttach?.split(".").pop()}
                        </a>
                      )}
                    </Button>
                    {objAddData?.itemAttach &&
                      <ClearIcon onClick={() => {
                        let item = { ...objAddData };
                        item.itemAttach = ''
                        setObjAddData({ ...item });
                      }} style={{ cursor: 'pointer' }} />
                    }
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: "#394671", color: "#fff" }}
              >
                <Button
                  onClick={(e) => {
                    setEdit(false);
                    setOpenTest(!openTest);
                  }}
                  style={{
                    backgroundColor: "#394671",
                    color: "#fff",
                    border: "1px solid",
                  }}
                >
                  Cancel
                </Button>

                {!edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#394671",
                      color: "#fff",
                      border: "1px solid",
                    }}
                  >
                    Add
                  </Button>
                )}
                {edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#394671",
                      color: "#fff",
                      border: "1px solid",
                    }}
                  >
                    Update
                  </Button>
                )}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </Container>
  );
};
export default NewIndent;
