import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import Loader from '../../loader';
import { ToastContainer, toast } from 'react-toastify';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import StepperBox from '../Common/StepperBox';
import CommonTable from '../Common/CommonTable';
import { getloggedInData } from 'src/utils/helpers';
import { useParams } from 'react-router-dom';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import { MESSAGE } from 'src/utils/message';
import CommonTable1 from '../Common/commonTableIndent';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // icon: {
  //   fill: "green",
  // },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  button2: {
    backgroundColor: '#394671',
                     color: '#fff',
                     border: '1px solid',
                     '&:hover': {
                       backgroundColor: '#1b233d',
                       color: '#fff',
                   },
 }
}));

const viewIndent = [
  {
    title: 'Store Indent Item ID',
    field: 'storeIndentItemID',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Item / Asset Name',
    field: 'name',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Uom',
    field: 'uom',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Approved Quantity',
    field: 'approvedQty',
    align: 'center',
    type: ETTypes.numeric
  },
  {
    title: 'Location',
    field: 'location',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Description',
    field: 'comment',
    align: 'center',
    type: ETTypes.string
  }
];

const updateIndent = [
  {
    title: 'Store Indent Item ID',
    field: 'storeIndentItemID',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Item / Asset Name',
    field: 'name',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Uom',
    field: 'uom',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Quantity',
    field: 'Qty',
    align: 'center',
    type: ETTypes.numeric,
    editable: EEditable.onEdit
  },
  {
    title: 'Location',
    field: 'location',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Description',
    field: 'comment',
    align: 'center',
    type: ETTypes.string
  },
  {
    title: 'Action',
    field: 'action',
    align: 'center',
    list: [ETaction.onDelete]
  }
];

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const AllApprovedStoreIndent = () => {
  const classes = useStyles();
  const { project } = useParams();
  const { addToast } = useToasts();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const projectDetails = { ...getProjectDetails(project) };

  const [indentView, setIndentView] = React.useState(false);
  const [statusEdit, setStatusEdit] = React.useState(false);
  const [indentList, setIndentList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [updateColumns, setUpdateColumns] = useState([]);
  const [itemHis, setItemHis] = useState([]);

  useEffect(() => {
    getUserIndentLists();
  }, [navigate]);

  const [storeIndentItemList, setstoreIndentItemList] = useState({
    storeIndentMasterID: '',
    ProjectID: null,
    statusID: null,
    UpdatedBy: null,
    CreatedBy: null,
    quoteUrl: '',
    CreateDate: '',
    UpdatedDate: null,
    projectName: '',
    CreatedByName: '',
   IndentItem: [],
    Approvals: [],
    location:'',
    comment:'',
    
  });

  const [storeColumns, setStoreColumns] = useState([
    {
      title: 'Store Indent Master ID',
      field: 'storeIndentMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Store Indent Status',
      field: 'status',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Goods Issued Status',
      field: 'GINStatusName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Employee Name',
      field: 'empName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreateDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'View',
      field: 'action',
      align: 'left',
      list: [ETaction.onView]
    }
  ]);

  const handleIndentViewClose = () => {
    if (indentView) {
      setStatusEdit(false);
    }
    setIndentView(!indentView);
    getUserIndentLists();
  };

  const getUserIndentLists = () => {
    setIsLoading(true);
    APIKit.get(
      RGCA_URLS.getStoreIndentAllApprovedList + '/' + projectDetails.projectID ,storeIndentItemList
    )
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              storeIndentItem: parseData(x.storeIndentItem)
            };
          });
          setIndentList(data);
          if (statusEdit) {
            let row = data.find(
              x =>
                x.storeIndentMasterID ===
                storeIndentItemList.storeIndentMasterID
            );
            if (row) setstoreIndentItemList({ ...row });
      
          }
        } else {
        }
      })
      .catch(function(e) {});
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.Qty;
    });
    return test;
    //  data['totalQty'] = test;
  };

  const actions = {
    onView: (index, row) => {
      setUpdateColumns([...viewIndent]);
      setstoreIndentItemList({ ...row });
      console.error('store',storeIndentItemList.IndentItem);
      handleIndentViewClose();
    },

  };


  const modelActions = {
    editRow: (index, field, value) => {
      let items = storeIndentItemList;
      items.storeIndentItem[index][field] = value;
      setstoreIndentItemList({ ...items });
     
    },

    onDelete: (index, row) => {
      remove(row);
    }
  };

  const remove = data => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        deleteIndentItem(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const deleteIndentItem = row => {
    const payload = {
      storeIndentItemID: row.storeIndentItemID,
      storeIndentMasterID: row.storeIndentMasterID
    };
    APIKit.post(RGCA_URLS.deleteStoreIndentItem, payload)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.deleteIndentItem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          getUserIndentLists();
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function(e) {});
  };

  const updateIndentItems = () => {
    const payload = {
      storeIndentMasterID: storeIndentItemList.storeIndentMasterID,
      items: storeIndentItemList.storeIndentItem.map(x => {
        return {
          storeIndentItemID: x.storeIndentItemID,
          Qty: x.Qty,
          approvedQty: x.Qty
        };
      })
    };
    if (payload.items.some(e => e.Qty == 0)) {
      toast.error(MESSAGE.qty0, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }

    APIKit.post(RGCA_URLS.storeIndentItemUpdate, payload)
      .then(res => {
        if (res.data.status === 200) {
          //addToast(res.data.message, { appearance: 'success' });
          toast.success(MESSAGE.indentUpdate, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          getUserIndentLists();
          handleIndentViewClose();
        } else {
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function(e) {});
  };

  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <Loader isLoading={isLoading} />
      <Typography>
        <h2 style={{ color: '#394671' }}> Store Indent Already Approved</h2>
      </Typography>
      <Grid container spacing={3} className={classes.field}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <CommonTable
              columns={storeColumns}
              data={indentList}
              action={actions}
            />
          </div>
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={indentView}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleIndentViewClose}
          style={{
            backgroundColor: '#394671',
            color: '#fff',
            width: '900px !important'
          }}
        >
          Store Indent Items
        </DialogTitle>
        <DialogContent dividers style={{ width: '900px !important' }}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={3} xl={3} xs={3}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Store Indent ID : {storeIndentItemList.storeIndentMasterID}
                  </h5>
                </Typography>
              </div>
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={3}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Employee Name : {storeIndentItemList.empName}
                  </h5>
                </Typography>
              </div>
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={3}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Total Quantity:{' '}
                    {totalQty(storeIndentItemList.storeIndentItem || [])}
                  </h5>
                </Typography>
              </div>
            </Grid>

            <Grid item lg={3} sm={3} xl={3} xs={3}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Date : {dateFormate(storeIndentItemList.CreateDate)}
                  </h5>
                </Typography>
              </div>
            </Grid>
        
            {/* ---------------------------------------------------------------------------------------- */}

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <CommonTable1
                  columns={updateColumns}
                  data={storeIndentItemList.storeIndentItem}
                  action={modelActions}
                  editLock={storeIndentItemList.Approvals}
                />
              </div>
            </Grid>
          </Grid>

          {/* <br />
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <StepperBox
                steps={storeIndentItemList.Approvals}
                action={actions}
              />
            </Grid>
          </Grid>
   */}
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          {statusEdit && (
            <Button
            type="submit"
              // style={{
              //   backgroundColor: '#394671',
              //   color: '#fff',
              //   border: '1px solid'
              // }}
 className={classes.button2}

              disabled={
                storeIndentItemList?.Approvals?.some(
                  e => e.status != 'Awaiting'
                ) &&
                storeIndentItemList?.Approvals?.every(
                  e => e.status != 'Modification'
                )
              }
              onClick={(e) => {updateIndentItems();}}
            >
              Update
            </Button>
          )}

          <Button
 className={classes.button2}
            // style={{
            //   backgroundColor: '#394671',
            //   color: '#fff',
            //   border: '1px solid'
            // }}
            onClick={handleIndentViewClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default AllApprovedStoreIndent;
