import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles(() => ({

}));

const Farmer = ({ className, testdata, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  var data;


  if (testdata && testdata.ArrayData) {
    let obj = {
      lable: [],
      targets: [],
      achive: [],
      F_Target: []
    };


    testdata.ArrayData.forEach(y => {

      obj.lable.push(y.type)
      obj.targets.push(y.Target)
      obj.achive.push(y.Achived)
      obj.F_Target.push(y.Financial_Target)

    })

    var finaldata = obj


    data = {
      labels: finaldata.lable,
      datasets: [
        {
          label: 'Physical Target ',
          backgroundColor: '#FF6384',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: '#FF6384',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: finaldata.targets
        },
        {
          label: "Physical Achievement",
          backgroundColor: '#36A2EB',
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: '#36A2EB',
          hoverBorderColor: "rgba(255,99,132,1)",
          data: finaldata.achive
        },
        {
          label: "Financial Target",
          backgroundColor: '#FFCE56',
          borderColor: '#FFCE56',
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: '#FFCE56',
          hoverBorderColor: '#FFCE56',
          data: finaldata.F_Target
        }
      ]

    };

  }
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    type: "HorizontalBar"

  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="CSS/ FIDF/ NFDB /NADP/ TANII/ NABARD /SADP /TN-IAMP -> (SCHEME FOR FARMER)"
      />

      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          {data && (
            <HorizontalBar data={data}
              options={options}
            />
          )}
        </Box>
      </CardContent>

      {/* <Divider /> */}

    </Card>
  );



};

// addSymbols = (e) => {
//     var suffixes = ["", "K", "M", "B"];
//     var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
//     if(order > suffixes.length - 1)
//         order = suffixes.length - 1;
//     var suffix = suffixes[order];
//     return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
// }




Farmer.propTypes = {
  className: PropTypes.string
};


export default Farmer;
