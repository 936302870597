import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { useConfirm } from "material-ui-confirm";

import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';

import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
// import { RGCA_URLS } from '../../utils/apis';
import { RGCA_URLS } from 'src/utils/url';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper'; import Grid from '@material-ui/core/Grid';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }
}));

const useStyles1 = makeStyles((theme) => ({
  root: {},

  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }

}));


const Deportment = () => {
  const classes = useStyles();
  const [depositors, setDepositors] = useState([])
  // const [depositors] = useState(data);
  const confirm = useConfirm(); 
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');

  const [allDepartment, setAllDepartment] = useState([])
  const [departments, setDepartments] = useState({ "departmentName": "", "departmentID": null });
  const [btn, setBtn] = React.useState({ "name": "Save" });
  useEffect(() => {
    setDepositors(data);
    getAllDepartment();
  }, [])

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const getAllDepartment = () => {

    APIKit.get(RGCA_URLS.getDepartment)
      .then((res) => {

        if (res.data.status === 200) {
          setAllDepartment(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const addDepartment = () => {
    console.log('aaa', departments);
    APIKit.post(RGCA_URLS.addDepartment, {
      "departmentName": departments.departmentName
    })
      .then((res) => {

        if (res.data.status === 200) {
          getAllDepartment();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setDepartments({ "departmentName": "", "departmentID": null })
        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
      })
  }
  const editDepartment = (data) => {
    setBtn({ "name": "Update" })
    setDepartments({ "departmentName": data.departmentName, "departmentID": data.departmentID })

  }

  const updateDepartment = () => {
    APIKit.put(RGCA_URLS.updateDepartment, departments,
    )
      .then((res) => {

        if (res.data.status === 200) {
          getAllDepartment();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setDepartments({ "departmentName": "", "departmentID": null })
          setBtn({ "name": "Save" })

        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
      })
  }

  const deleteDepartment = (data) => {
    APIKit.post(RGCA_URLS.deleteDepartment+'/'+data.departmentID
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          getAllDepartment();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setDepartments({ "departmentName": "", "departmentID": null })
        }else if(res.data.status === 201){
          getAllDepartment();
          setAlertMeg('warning');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setDepartments({ "departmentName": "", "departmentID": null })
        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
      })
  }

  const searchAllDepartment = (quary) => {

    APIKit.post('/' + RGCA_URLS.searchDepartment, { 'departmentName': quary },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          setAllDepartment(res.data.data)
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  let regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/
  };

  const remove = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        deleteDepartment(data)
      })
      .catch(() => console.log("Deletion cancelled."));

  }
  return (
    <Page
      className={classes.root}
      title="Deportment">
      <Container maxWidth={false}>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
          <Alert onClose={handleAlertClose} severity={alertMeg} >
            {errorMeg}
          </Alert>
        </Snackbar>
        <div>

          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#394671 ', fontSize: '17px', fontWeight: 'bold' }}>
                DEPARTMENT
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

              </Grid>
            </Grid>

          </Box>

          <Box mt={3}>
            <Card>
              <CardContent>
                <Box >
                  <Grid container>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth

                        label="Department Name"
                        name="departmentName"
                        onChange={(e) => {
                          if (e.target.value.match(regEx.textOnly)) {
                            let p = departments;
                            p.departmentName = e.target.value
                            // .match(regEx.textOnly);
                            setDepartments({
                              ...p
                            })
                          } else {
                            let p = departments;
                            p.departmentName = '';
                            setDepartments({
                              ...p
                            })
                          }
                        }}
                        value={departments.departmentName}
                        variant="outlined"
                      />

                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    ></Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >


                        {btn.name == 'Save' ?
                          <Button
                            color="primary"
                            variant="contained" style={{ marginTop: '14px' }}
                            onClick={addDepartment}
                            disabled={departments.departmentName == ""}
                          >
                            Add
                          </Button>
                          : <Button
                            color="primary"
                            variant="contained" style={{ marginTop: '14px' }}
                            onClick={updateDepartment}
                            disabled={departments.departmentName == ""}
                          >
                            Update
                          </Button>}
                      </Box>
                    </Grid>


                  </Grid>

                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={455}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search"
                    variant="outlined"
                    onChange={(e) => {
                      searchAllDepartment(e.target.value)
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>

                <TableCell style={{ width: '45%' }}>
                  S.No
                </TableCell>
                <TableCell style={{ width: '35%' }}>
                  Dept. Name
                </TableCell>

                <TableCell style={{ textAlign: 'right', width: '20%' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDepartment.slice(0, limit).map((dep, index) => (
                <TableRow
                  hover
                  key={dep.departmentID}
                >


                  <TableCell style={{ width: '45%' }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ width: '35%' }}>
                    {dep.departmentName}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right', width: '20%' }}>
                    <EditOutlinedIcon onClick={() => editDepartment(dep)} />
                    <DeleteForeverIcon onClick={() => remove(dep)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>


      </Container>
    </Page>
  )

}
export default Deportment;