import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
// import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getData } from '../../Redux/Location/action';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },

}));

const useStyles1 = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }

}));


const AddLocation = ({ route, navigation, className }) => {
  const classes = useStyles();
  const [depositors, setDepositors] = useState([]);
  const [initialLoad, setInitialLoad] = useState(0);
  // const [depositors] = useState(data);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);

  const [age, setAge] = React.useState('');

  const [phone, setPhone] = useState('');
  const [locationname, setLocationname] = useState('');
  const [address, setAddress] = useState('');
  const [locationtype, setLocationtype] = useState('');
  // const [createdby, setCreatedby] = useState('');
  // const [updatedby, setUpdatedby] = useState('');
  const [payload, setPayload] = useState({ locationType: 'abc', locationName: '', locationAddress: '', phoneNumber: '' })
  const dispatch = useDispatch();



  const { state } = useLocation();

  const navigate = useNavigate();
  // const addLocation = () => {
  //   dispatch(getData('kkkkk'));
  // };


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // useEffect(() => {
  //   setDepositors(data);
  // }, depositors)

  const addLocation = () => {

    APIKit.post('/'+APP_URLS.addLocation, payload,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.status === 200) {
          setPayload({ locationType: '', locationName: '', locationAddress: '', phoneNumber: '' })
          setPayload({});
         
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  useEffect(() => {
    if (state) {
      setPhone(state.phone);
      setLocationname(state.name);
      setAddress(state.address);
      setLocationtype('office');
      setInitialLoad(1);
    }

  }, [!initialLoad]);


  const handleSelectAll = () => {

  }

  const handleSelectOne = () => {

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Page
      className={classes.root}
      title="Depositors">
      <Container maxWidth={false}>
        <div
          className={clsx(classes.root)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
              ADD LOCATION
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            
            </Grid>
          </Grid>
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}>
            <Grid
              container
              spacing={3}
            >

              <Grid
                item
                md={4}
                xs={12}
              >
                  <TextField
                    fullWidth
                    helperText=""
                    label="Location Name"
                    name="locationName"
                    required
                    onChange={(e) => {

                      let p = payload;
                      p.locationName = e.target.value
                      setPayload({
                        ...p
                      })
                    }}
                    required
                    value={payload.locationName}
                    variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="Location Address"
                  name="locationAddress"
                  onChange={(e) => {

                    let p = payload;
                    p.locationAddress = e.target.value
                    setPayload({
                      ...p
                    })
                  }}
                  required
                  value={payload.locationAddress} 
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={(e) => {

                    let p = payload;
                    p.phoneNumber = e.target.value
                    setPayload({
                      ...p
                    })
                  }}
                  required
                  value={payload.phoneNumber}
                  variant="outlined"
                />
              </Grid>

            
                 
                
            </Grid>

            <div style={{textAlign:"right"}}>
                  <Button
                    color="primary"
                    variant="contained" style={{ marginTop: '20px' }}
                    onClick={() => {
                      addLocation();
                    }}
                  >
                    Save
              </Button>
              </div>


          </Box>
        </div>
        <Box mt={3}>

        </Box>

      </Container>
    </Page>
  )

}
export default AddLocation;