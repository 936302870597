import React, { useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import { RGCA_URLS } from '../../../utils/url';
import '../GRN/style.css';
import { styled } from '@mui/material/styles';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  }
}));

const ExpiryRep = () => {
  const classes = useStyles();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [inventoryExpiryList, setinventoryExpiryList] = useState([]);
  const [lookupData, setLookupData] = useState([]);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getLookup();
    getInventoryExpiryList();
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const getInventoryExpiryList = () => {
    APIKit.get(RGCA_URLS.getExpiryListForIR + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          setinventoryExpiryList(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };
  return (
    <Page className={classes.root}>
      <>
        <>
          <div>
            <Box display="flex" justifyContent="flex-end">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    marginTop: '10px',
                    color: '#3f51b5',
                    fontSize: '17px',
                    fontWeight: 'bold'
                  }}
                ></Grid>
              </Grid>
            </Box>
          </div>
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S No</TableCell>
                    <TableCell align="left">Item Name</TableCell>
                    <TableCell align="left">Date Of Purchase</TableCell>
                    <TableCell align="left">Date of Expiry</TableCell>
                    <TableCell align="left">Quantity Expired</TableCell>

                    <TableCell align="left">Total Cost of Expiry</TableCell>
                    <TableCell align="left">Supplier Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryExpiryList && inventoryExpiryList.length != 0 ? (
                    inventoryExpiryList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((x, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">
                              {page * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">{x.ItemName}</TableCell>
                            <TableCell align="left">
                              {moment(x.dateOfPurchase).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell align="left">
                              {/* {x.dateofExpiry} */}
                              {moment(x.dateofExpiry).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell align="left">{x.qtyExpired}</TableCell>

                            <TableCell align="left">{x.totalPrice}</TableCell>
                            <TableCell align="left">
                              {x.supplierName}
                            </TableCell>
                          </TableRow>
                        );
                      })) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {'No Data Found'}
                      </TableCell>{' '}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {inventoryExpiryList && inventoryExpiryList?.length > 10 &&
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={inventoryExpiryList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            }
          </Box>
        </>
      </>
    </Page>
  );
};
export default ExpiryRep;
