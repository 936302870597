// const baseUrl = '/api/v1/';
// const baseUrl = 'http://localhost:8880/api/v1/';

// const baseUrl = 'https://www.rgca-demo.brugu.io/api/v1/'  

const baseUrl ='https://rgcadev-apis.brugu.io/api/v1/'
// const baseUrl ='http://192.168.7.58:8880/api/v1/'

export const RGCA_URLS = {
    login: `${baseUrl}login`,
    getEmployeeProjects: `${baseUrl}getEmployeeProjects`,
    getPermissions: `${baseUrl}getPermissions`,
    addProjects: `${baseUrl}addProject`,
    lookup: `${baseUrl}lookup`,
    addCategoryORsubCategory: `${baseUrl}addCategoryORsubCategory`,
    getCategoryORsubCategory: `${baseUrl}getCategoryORsubCategory`,
    addItemOrMaterial: `${baseUrl}addItemOrMaterial`,
    getItems: `${baseUrl}getItems`,
    addUOM: `${baseUrl}addUOM`,
    getUOM: `${baseUrl}getUOM`,
    updateItems: `${baseUrl}updateItems`,
    getEmployeeprojectHistory:baseUrl+'getEmployeeprojectHistory',
    addDepartment: baseUrl + 'addDepartment',
    getDepartment: baseUrl + 'getDepartment',
    updateDepartment: baseUrl + 'updateDepartment',
    deleteDepartment: baseUrl + 'deleteDepartment', 
    // designations
    addDesignation: `${baseUrl}addDesignation`,
    getDesignations: `${baseUrl}getDesignations`,
    getLookups: `${baseUrl}getLookups`,
    deleteDesignation: baseUrl + 'deleteDesignation',
    updateDesignation: baseUrl + 'updateDesignation',

    addRole: `${baseUrl}addRole`,
    deleteRole: `${baseUrl}deleteRole`,
    updateRole: `${baseUrl}updateRole`,
    getRoles: `${baseUrl}getRoles`,
    getProjectEmployee: `${baseUrl}getProjectEmployee`,
    getAccessRoles: `${baseUrl}getAccessRoles`,
    accessRolePermissionMange: `${baseUrl}accessRolePermissionMange`,
    addVendor: `${baseUrl}addVendor`,
    changePassword: `${baseUrl}changePassword`,
    changePasswordFromProfile: `${baseUrl}changePasswordFromProfile`,
    listVendor: `${baseUrl}listVendor`,
    updateVendor: `${baseUrl}updateVendor`,
    deleteVendor: `${baseUrl}deleteVendor`,
    vendorDetails: `${baseUrl}getVendorDetails`,
    fileUpload: baseUrl + 'fileUpload',
    bulkUploadVendor: baseUrl + 'bulkUploadVendor',
    bulkUploadcustomer: baseUrl + 'bulkUploadcustomer',


    addEmployee: baseUrl + 'addEmployee',
    getEmployee: baseUrl + 'getEmployee',
    getSingleEmployee: baseUrl + 'getSingleEmployee',
    bulkUploadEmployee: baseUrl + 'bulkUploadEmployee',
    getEmployeeBirthdayList: baseUrl + 'getEmployeeBirthdayList',

    // Projects
    getProjects: baseUrl + 'getProjects',
    updateProject: baseUrl + 'updateProject',
    deleteProject: baseUrl + 'deleteProject',
    getSellingReportData:baseUrl+"getSellingReportData",
    approvalMasterList: baseUrl + 'approvalMasterList/',
    getApprovalList: baseUrl + 'getApprovalList/',
    addApproval: baseUrl + 'addApproval',
    createSalesItem: baseUrl + 'addSalesItem',
    addSalesCategory: baseUrl + 'addSalesCategory',
    getSalesCategory: baseUrl + 'getSalesCategory',
    listSalesItem: baseUrl + 'listSalesItem',
    updateProductionHistory:baseUrl+'updateProductionHistory',
    updateSalesItem: baseUrl + 'updateSalesItem',
    deleteSalesItem: baseUrl + 'deleteSalesItem',
    filePath: `https://rgca.fra1.digitaloceanspaces.com/`,

    // Indents
    getProductsForIndent: baseUrl + 'getProductsForIndent',
    getProductsForIndentSelect: baseUrl + 'getProductsForIndentSelect',
    addIndent: baseUrl + 'addIndent',
    getUserIndentList: baseUrl + 'getUserIndentList',
    getIndentApprovalList: baseUrl + 'getIndentApprovalList',
    updateIndentApproval: baseUrl + 'updateApproval',
    deleteIndentItem: baseUrl + 'deleteIndentItem',
    indentItemUpdate: baseUrl + 'indentItemUpdate',
    getIndentAllApprovedList: baseUrl + 'getIndentAllApprovedList',
    addPurchaseOrderForIndent: baseUrl + 'addPurchaseOrderForIndent',
    getPurchaseOrderForIndent: baseUrl + 'getPurchaseOrderForIndent',
    updateApprovalForPurchaseOrder: baseUrl + 'updateApprovalForPurchaseOrder',


    // Customer
    createCustomer: baseUrl + 'addCustomer',
    listCustomer: baseUrl + 'listCustomer',
    updateCustomer: baseUrl + 'updateCustomer',
    deleteCustomer: baseUrl + 'deleteCustomer',

    // Profile
    getProjectProfile: baseUrl + 'getProjectProfile',
    updateEmployee: baseUrl + 'updateEmployee',
    updateProfile: baseUrl + 'updateProfile',
    deleteEmployee: baseUrl + 'deleteEmployee',


    // Workorder
    addWorkOrder: baseUrl + 'addWorkOrder',
    listworkOrder: baseUrl + 'listworkOrder',
    updateworkorder: baseUrl + 'updateworkorder',
    deleteworkOrder: baseUrl + 'deleteworkOrder',
    getWorkOrderApprovalList: baseUrl + 'getWorkOrderApprovalList',
    updateWorkOrderApproval: baseUrl + 'updateWorkOrderApproval',
    getWorkOrderAllApprovedList: baseUrl + 'getWorkOrderAllApprovedList',



    // GRN
    getPurchaseOrderForGRN: baseUrl + 'getPurchaseOrderForGRN',
    addGRN: baseUrl + 'addGRN',
    getGRN: baseUrl + 'getGRN',
    otherThanPOWO: baseUrl + 'otherThanPOWO',
    getReturnGoods: baseUrl + 'getReturnGoods',
    returnGoods: baseUrl + 'returnGoods',
    getIncompleteGrn: baseUrl + 'getIncompleteGrn',


    // FAR
    getFAR: baseUrl + 'getFAR',
    addFar: baseUrl + 'addFar',
    getSingleFar: baseUrl + 'getSingleFar',
    bulkUploadFar: baseUrl + 'bulkUploadFar',
    updateFar : baseUrl + 'updateFar',
    getEmployeeBasedProjects:baseUrl+"getEmployeeBasedProjects",

    //Receipts
    createReceipts: baseUrl + 'createReceipts',
    getReceipts: baseUrl + 'getReceipts',
    deleteReceipts: baseUrl + 'deleteReceipts',
    updateReceipts: baseUrl + 'updateReceipts',

    // Payment
    addPaymentSystem: baseUrl + 'addPaymentSystem',
    addPaymentForGRNWO: baseUrl + 'addPaymentForGRNWO',
    updatePaymentSystem: baseUrl + 'updatePaymentSystem',
    getUserPaymentSystemList: baseUrl + 'getUserPaymentSystemList',
    getPaymentApprovalSystemList: baseUrl + 'getPaymentApprovalSystemList',
    updateApprovalForPayment: baseUrl + 'updateApprovalForPayment',
    getPaymentAllApprovedList: baseUrl + 'getPaymentAllApprovedList',

    // GIN
    createGIN: baseUrl + 'createGIN',
    getGIN: baseUrl + 'getGIN',
    updateGIN: baseUrl + 'updateGIN',
    deleteGINItem: baseUrl + 'deleteGINItem',
    getIncompleteGin: baseUrl + 'getIncompleteGin',
    getStoreIndentForGIN: baseUrl + 'getStoreIndentForGIN',
    returnGoodsGin: baseUrl + 'returnGoodsGin',
    getReturnGoodsGin: baseUrl + 'getReturnGoodsGin',

    // inventory
    getExpiryListForIR: baseUrl + 'getExpiryListForIR',
    getListForIR: baseUrl + 'getListForIR',
    getBrokenHistory: baseUrl + 'getBrokenHistory',
    addBrokenQty: baseUrl + 'addBrokenQty',
    getProjectReport : baseUrl + 'getProjectReport',

    // get materials for sellling
    getMaterialsFromSalesItem: baseUrl + 'getMaterialsFromSalesItem',

    // Selling
    createSelling: baseUrl + 'createSelling',
    createLabTest:baseUrl+'createLabTest',
    getLabTestQuery:baseUrl+'getLabTestQuery',
    // getMaterialsForSelling: baseUrl + 'getMaterialsForSelling',
    getMaterialsForSelling: baseUrl + 'getSellingQuery',
    getMaterialsFromSalesItemSelect: baseUrl + 'getMaterialsFromSalesItemSelect',
    deleteSelling: baseUrl + 'deleteSelling',
    updateSelling: baseUrl + 'updateSelling',
    addprodAndSale:baseUrl+"addprodAndSale",
    getsalesForsaleStart:baseUrl+"getsalesForsaleStart",
    getsalesForsaleEnd:baseUrl+"getsalesForsaleEnd",
    addSaleOn:baseUrl+"addSaleOn",
    addSaleEnd:baseUrl+"addSaleEnd",

    // dashboard
    getCapitalProjectDashboardChart: baseUrl + 'capitalProjectDashboardChart',
    getRecurringProjectDashboardChart: baseUrl + 'recuringProjectDashboardChart',
    listProduction: baseUrl + 'listProduction',
    getIndentHistory: baseUrl + 'getIndentHistory',
    getProductionPiechart: baseUrl + 'getProductionPiechart',
    getSalesPieChart: baseUrl + 'getSalesPieChart',
    getCapitalExpenesesReport: baseUrl + 'getCapitalExpenesesReport',
    getRecurringExpensesReport: baseUrl + 'getRecurringExpensesReport',
    getProdctionReport: baseUrl + 'getProdctionReport',
    getNotification: baseUrl + 'getNotification',

    // STORE INDENT

    addStoreIndent: baseUrl + 'addStoreIndent',
    getUserStoreIndentList: baseUrl + 'getUserStoreIndentList',
    deleteStoreIndentItem: baseUrl + 'deleteStoreIndentItem',
    storeIndentItemUpdate: baseUrl + 'storeIndentItemUpdate',
    getStoreIndentApprovalList: baseUrl + 'getStoreIndentApprovalList',
    getStoreIndentHistory: baseUrl + 'getStoreIndentHistory',
    updateStoreApproval: baseUrl + 'updateStoreApproval',
    getStoreIndentAllApprovedList: baseUrl + 'getStoreIndentAllApprovedList',

    //notification
    addNotification: baseUrl + 'notification',
    getNotifications: baseUrl + 'getNotifications',
    updateNotification: baseUrl + 'updateNotification',
    deleteNotifications: baseUrl + 'deleteNotifications',
    getNotificationsData: baseUrl + 'getNotificationsData',

    // s3 bucket upload

    upload : baseUrl + 'upload',


    // http://localhost:3000/api/v1/updateReceipts
    // http://localhost:3000/api/v1/deleteReceipts/:receiptID'



    // role based permissions

    getRolePermissions : baseUrl + 'getRolePermissions',
    rolePermissionMange :  baseUrl + 'rolePermissionMange',

    getsalesHistoryData: baseUrl+ 'getsalesHistoryData',
    updateSelling:baseUrl+'updateSelling',
    getSalesReport:baseUrl+'getSalesReport',
    getSellingReportData:baseUrl+"getSellingReportData",
    getGRNInformationByQty:baseUrl+'getGRNInformationByQty',
    updateFarData:baseUrl+'updateFarData',
    getIndentApprovalListwithAge:baseUrl+'getIndentApprovalListwithAge',
    getPurchaseOrderBasedonEmpID:baseUrl+'getPurchaseOrderBasedonEmpID',
    getAssignedFarData:baseUrl+'getAssignedFarData',
    getSalesGraphData:baseUrl+'getSalesGraphData',
    getProductionGraphData:baseUrl+'getProductionGraphData',
    getNotificationsDataBasedonprojectID:baseUrl+'getNotificationsDataBasedonprojectID',
    notificationBasedonprojectID:baseUrl+'notificationBasedonprojectID',
    getEmployeeBirthdayListBasedonprojectID:baseUrl+'getEmployeeBirthdayListBasedonprojectID',
    getUserIndentListforDashBoard:baseUrl+"getUserIndentListforDashBoard",
    getProductionReport:baseUrl+'getProductionReport',
    getInventoryRecurringReport:baseUrl+'getInventoryRecurringReport',
    getUserStoreIndentListforDashBoard:baseUrl+'getUserStoreIndentListforDashBoard',
    addPurchaseOrderForIndentBasedonItems:baseUrl+'addPurchaseOrderForIndentBasedonItems',
    getListForFar:baseUrl+'getListForFar',
    addGRNforItems:baseUrl+'addGRNforItems',
    getGrnRejectedList:baseUrl+'getGrnRejectedList',
    getCurrentStockGraphData:baseUrl+"getCurrentStockGraphData",
    getRFQQuotation:baseUrl+'getRFQQuotation',
    addRFQQuotation:baseUrl+'addRFQQuotation',
    updatepostatus:baseUrl+'updatepostatus',
    listSalesData:baseUrl+'listSalesData',
    saleEnd:baseUrl+"saleEnd",
    saleStart:baseUrl+"saleStart",
    getSalesandProductReport:baseUrl+"getSalesandProductReport",
    getInventoryRecurringData:baseUrl+'getInventoryRecurringData',
    getAssignedHistory:baseUrl+'getAssignedHistory',
    getCurrentStockforAllprojects:baseUrl+'getCurrentStockforAllprojects',
    getHandsOnProgrammeDetails:baseUrl+'getHandsOnProgrammeDetails',
    createHandsOnProgramme:baseUrl+'createHandsOnProgramme',
    getTrainerDetails:baseUrl+'getTrainerDetails',
    createTrainer:baseUrl+'createTrainer',
    getTestNameServices:baseUrl+'getTestNameServices',
    getpackagesDetails:baseUrl+"getpackagesDetails",
    getCurrentStockforAllprojects:baseUrl+'getCurrentStockforAllprojects',
    getLabTestDataDetails:baseUrl+'getLabTestDataDetails',
    createLabTestData:baseUrl+'createLabTestData',
    createLabTestDataReceipts:baseUrl+'createLabTestDataReceipts',
    updateLabTestDataReceipts:baseUrl+'updateLabTestDataReceipts',
    getLabTestHistoryData:baseUrl+'getLabTestHistoryData',
    getAllBrokenHistoryData:baseUrl+'getAllBrokenHistoryData',
    addGRNforRecurring:baseUrl+'addGRNforRecurring',
    addorUpdateThresholdCount:baseUrl+'addorUpdateThresholdCount',
    assignRecurringItems:baseUrl+'assignRecurringItems',
    getRecurringTransferGrn:baseUrl+'getRecurringTransferGrn',
    raiseStoreIndentforRecurringTransfer:baseUrl+'raiseStoreIndentforRecurringTransfer',
    addGrnForRecurringTransferData:baseUrl+'addGrnForRecurringTransferData',
    getPackageData:baseUrl+'getPackageData',
    getProductionPriceData:baseUrl+"getProductionPriceData",
    addproductionPriceData:baseUrl+"addproductionPriceData",
    updateproductionPriceData:baseUrl+"updateproductionPriceData",
    getLowStockList:baseUrl+'getLowStockList',
    getDetailSalesReportForCurrentProject:baseUrl+'getDetailSalesReportForCurrentProject',
    getDetailSalesReportForAllProject:baseUrl+'getDetailSalesReportForAllProject',
    getDetailProductionReportForAllProject:baseUrl+'getDetailProductionReportForAllProject',
    getDetailProductionReportForCurrentProject:baseUrl+'getDetailProductionReportForCurrentProject',
    updateIsDisposed:baseUrl+'updateIsDisposed',
    getDisposedMaterails:baseUrl+'getDisposedMaterails',
    getArchivedHandsOnProgrammeDetails:baseUrl+'getArchivedHandsOnProgrammeDetails',
    getGRNForOutsideProject:baseUrl+'getGRNForOutsideProject',
    getAlertsData:baseUrl+'getAlertsData',
    addArchieveListData:baseUrl+'addArchieveListData',



};