import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { pink } from "@material-ui/core/colors";
import { useDescriptionStyles } from "./style";
import { RGCA_URLS } from '../../../utils/url';
import APIKit from "../../../utils/APIKit";
import Address from '../Common/Address';
import Bank from '../Common/Bank';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { capitalize, dateFormate, dateOnly, getProjectDetails } from '../Common/utilities';
import { useSelector } from 'react-redux';
import Loader from "src/views/loader";
/**
 * Flasher hook.
 */
export const useFlasher = () => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current.setAttribute(
      "style",
      `box-shadow: 0 0 8px 1px ${pink["600"]};
       background-color: ${pink["50"]};
       transition: box-shadow 50ms ease-out;`
    );
    setTimeout(() => ref.current.setAttribute("style", ""), 100);
  });
  return ref;
};

/**
 * App Description and usage.
 */
const Profile = () => {
  const classes = useDescriptionStyles();
  const [profileData, setProfileData] = useState({})
  const [projectId, setProjectId] = useState(null)
  const projectData = useSelector(x => x);
  const [isLoading, setIsLoading] = useState(false)
  var proId = projectData.ProjectInfo.project.projectID
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }

  const navigate = useNavigate();

  useEffect(() => {
    // if(projectData){
    //     setProjectId(projectData.ProjectInfo.project.projectID)
    // }
    getProfileData()
  }, [navigate])

  const getProfileData = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.getProjectProfile + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false)
          setProfileData(res.data.data[0])
        }
        else {
          setIsLoading(false)
        }
      })
      .catch(function (e) {
      })
    // console.log('text-text',payload)


  }
  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} />
      <Typography variant="h3" color="textPrimary" align="center">
        {profileData.projectName}
      </Typography>
      <Typography variant="h5" color="secondary" align="center">
        - {profileData.shortID}-
      </Typography>
      <br />
      <Typography variant="h6" color="textPrimary" align="left">
        <b> Description</b>
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        align="justify"
        paragraph={true}
      >
        {profileData.Description}
      </Typography>
      <Typography variant="body2" color="textPrimary" align="justify">
        <b>Total area of the project</b>: {profileData.projectArea}  Acres.
      </Typography>
      <Typography variant="body2" color="textPrimary" align="justify">
        <b>Ownership and details</b>: {profileData.projectName}
      </Typography>
      <Typography variant="body2" color="textPrimary" align="justify">
        <b>Phone Number</b>: {profileData.mobile}.
      </Typography>
      <Typography variant="body2" color="textPrimary" align="justify">
        <b>Address</b>: <Address details={profileData.address} />
      </Typography>


      <Typography variant="body2" color="textPrimary" align="justify">
        <b>Bank Details</b>: <Bank details={profileData.bankDetails} />
      </Typography>

    </div>
  );
};
export default Profile;
