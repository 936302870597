export const data = [
    {
        "id": "GIN-10052021-001",
        "projectName": "SBH",
        "receiver": "Reveiver 1",
        "createdBy": "User - Technician",
        'createdDate': "22/10/2021 10:20AM",
        'items': [
            {
                "material": 'Double Frosted microscopy',
                "UOM": 'Box',
                "units": '500',
                "Description": 'RJY1',
                "Location": 'rajamundary',
                "Cost": '4000',
                "Available": '20',

            },
            {
                "material": 'Double Frosted microscopy',
                "UOM": 'Box',
                "units": '23',
                "Description": 'gan1',
                "Location": 'gannavaram',
                "Cost": '5000',
                "Available": '30',

            },
            {
                "material": 'Double Frosted microscopy',
                "UOM": 'Box',
                "units": '200',
                "Description": 'amp1',
                "Location": 'amalapuram',
                "Cost": '6000',
                "Available": '40',

            }
        ],
        'uom': 'pisces'
    },
    {
        "id": "GIN-10052021-002",
        "projectName": "SBH",
        "receiver": "Reveiver 2",
        "createdBy": "User - Accountant",
        'createdDate': "22/10/2021 10:20AM",
        'uom': 'pisces',
        'items': [
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '100',
                "Description": 'des55',
                "Location": 'Nellore',
                "Cost": '4000',
                "Available": '20',

            },
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '9',
                "Description": 'des8',
                "Location": 'Nellore',
                "Cost": '5000',
                "Available": '30',

            },
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '80',
                "Description": 'des4',
                "Location": 'hyd',
                "Cost": '6000',
                "Available": '40',

            }
        ],
    },
    {
        "id": "GIN-10052021-003",
        "projectName": "SBH",
        "receiver": "Reveiver 3",
        "createdBy": "User - Technician",
        'createdDate': "22/10/2021 10:20AM",
        'uom': 'pisces'
    },
    {
        "id": "GIN-10052021-004",
        "projectName": "SBH",
        "receiver": "Reveiver 4",
        "createdBy": "User - Accountant",
        'createdDate': "22/10/2021 10:20AM",
        'uom': 'pisces',
        'items': [
            {
                "material": 'Tables',
                "UOM": 'Box',
                "units": '60',
                "Description": 'test1',
                "Location": 'chennai',
                "Cost": '4000',
                "Available": '20',

            },
            {
                "material": 'Tables',
                "UOM": 'Box',
                "units": '70',
                "Description": 'des2',
                "Location": 'hyd',
                "Cost": '5000',
                "Available": '30',

            },
            {
                "material": 'Tables',
                "UOM": 'Box',
                "units": '80',
                "Description": 'des 1',
                "Location": 'hyd',
                "Cost": '6000',
                "Available": '40',

            }
        ],
    },
    {
        "id": "GIN-10052021-005",
        "projectName": "SBH",
        "receiver": "Reveiver 5",
        "createdBy": "User - Technician",
        'createdDate': "22/10/2021 10:20AM",
        'uom': 'pisces',
        'items': [
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '90',
                "Description": 'test2',
                "Location": 'srikalli',
                "Cost": '4000',
                "Available": '20',

            },
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '10',
                "Description": 'test3',
                "Location": 'srikalli',
                "Cost": '5000',
                "Available": '30',

            },
            {
                "material": 'Double Frosted',
                "UOM": 'Box',
                "units": '20',
                "Description": 'test4',
                "Location": 'srikalli',
                "Cost": '6000',
                "Available": '40',

            }
        ],
    },

]