import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {}
}));

const LineChart = ({ className, ...rest }) => {
  const classes = useStyles();

  const data = {
    labels: ['Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    datasets: [
      {
        label: 'Month',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [10000, 7000, 9000, 15000, 6000, 600, 0, 987, 6660, 820, 3309, 25000
        ]
      }
    ]
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    type: "Line"

  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="KARANTHAI- Early fry -Monthly Progression based on  Achievement with respect to Target"
      />

      <CardContent>
        <Box
          height={700}
          position="relative"
        >
          <Line data={data}
            options={options}
          />
        </Box>
      </CardContent>
      &nbsp;  &nbsp;  &nbsp;<br></br><br></br>
      {/* <Divider /> */}

    </Card>
  );
};

LineChart.propTypes = {
  className: PropTypes.string
};

export default LineChart;
