// import React from 'react';

// const ProjectIndents = () => {
// return <h1>Indents</h1>

// }
// export default ProjectIndents;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText
} from '@material-ui/core';
import { ETTypes, ETaction } from '../Common/Types';
import Page from 'src/components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { statusColor } from 'src/utils/helpers';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';
import { useNavigate } from 'react-router-dom';
// import './style.css';
import APIKit from '../../../utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { parseData, dateFormate, getProjectDetails, currencyFormat1 } from '../Common/utilities';
import StepperBox from '../Common/StepperBox.jsx';
import { Constant } from '../Common/Constant';
import { getloggedInData } from 'src/utils/helpers';
import { useToasts } from 'react-toast-notifications';
import CommonTable from '../Common/CommonTable';
// import { ETTypes } from '../Common/Types';

const IndentInitialValues = {
  workOrderMasterID: '',
  ProjectID: null,
  statusID: null,
  UpdatedBy: null,
  CreatedBy: null,
  quoteUrl: '',
  CreateDate: '',
  UpdatedDate: null,
  projectName: '',
  CreatedByName: '',
  currentApprovals: {
    referOptionView: false,
    referSelected: false,
    name: '',
    workOrderApprovalSystemID: null,
    workOrderMasterID: '',
    orderID: null,
    accessRoleID: null,
    runningStatus: null,
    approvalID: null,
    editLock: true,
    CreatedBy: null,
    CreateDate: '',
    comment: '',
    approvalStatusID: null,
    status: ''
  },
  indentItem: [],
  workOrderItem: [],
  Approvals: []
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  }
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ApprovalWorkOrder = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [page, setPage] = React.useState(0);
  const userProfile = getloggedInData();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [workOrderApprovalView, setWorkOrderApprovalView] = React.useState(
    false
  );
  const [statusEdit, setStatusEdit] = React.useState('');
  const [indentItem, setIndentItem] = useState({ ...IndentInitialValues });
  const [direct, setDirect] = React.useState(false);
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const navigate = useNavigate();
  const updateColumns = [
    {
      title: 'WorkOrder ID',
      field: 'workOrderID',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Nature',
      field: 'noename',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Item Description',
      field: 'itemDescription',
      align: 'center',
      type: ETTypes.string
    },

    {
      title: 'Quantity',
      field: 'qty',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Rate',
      field: 'rate',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'Unit',
      field: 'unit',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Total Value',
      field: 'totalValue',
      align: 'center',
      type: ETTypes.numeric
    },
    {
      title: 'IsGST',
      field: 'isGst',
      align: 'center',
      type: ETTypes.boolean
    },
    {
      title: 'CGST',
      field: 'CGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'SGST',
      field: 'SGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'IGST',
      field: 'IGST',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'UGST',
      field: 'UGST',
      align: 'center',
      type: ETTypes.string
    }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleIndentViewClose = () => {
    setStatusEdit('');
    setWorkOrderApprovalView(!workOrderApprovalView);
  };

  useEffect(() => {
    getIndentApprovalList();
    getLookup();
  }, [navigate]);

  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [workOrderApprovalList, setWorkOrderApprovalList] = useState([]);

  const getWorkorderApprovalList = () => {
    APIKit.get(
      RGCA_URLS.getWorkOrderApprovalList + '/' + projectDetails.projectID
    )
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              workOrder: parseData(x.workOrder)
            };
          });
          setWorkOrderApprovalList(data);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const [workOrderStatus, setWorkOrderStatus] = useState([]);
  const [originalApprovalStatusList, setOriginalApprovalStatusList] = useState(
    []
  );
  const [indentListPayload, setIndentListPayload] = useState({
    storeIndentMasterID: '',
    ProjectID: null,
    indentItem: [
      {
        Qty: null
      }
    ]
  });
  const [indentList, setIndentList] = useState([]);

  const userData = getloggedInData();

  const [refer, setRefer] = useState(false);

  const getIndentApprovalList = () => {
    // setIsLoading(true);
    APIKit.get(RGCA_URLS.getIndentApprovalList + '/' + projectDetails.projectID)
      .then(res => {
        // setIsLoading(false);
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              indentItem: parseData(x.indentItem)
            };
          });
          setIndentListPayload(data);
          setIndentList(data);
        } else {
        }
      })
      .catch(function(e) {});
  };
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          // if (e.lookupID === row.statusID) {
          //   setWorkOrderStatus(e.name)
          // }
          setOriginalApprovalStatusList(res.data.data);
          let newData = [];
          const userProjects = JSON.parse(userData.project);
          let userAccessID = '';
          if (userProjects.length > 0) {
            userProjects.forEach(pro => {
              if (pro.projectName == project) {
                userAccessID = pro.accessRoleID;
              }
            });
          }

          if (indentListPayload && indentListPayload.length > 0) {
            indentListPayload.forEach(list => {
              if (list.Approvals && list.Approvals.length > 0) {
                const lastApproval = list.Approvals[list.Approvals.length - 1];
                console.log(
                  'accessRoleID',
                  lastApproval.accessRoleID,
                  userAccessID
                );
                if (lastApproval.accessRoleID == userAccessID) {
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 89 && x.lookupID != 90
                    )
                  );
                  console.error('3', approvalStatusList);
                } else {
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 24 && x.lookupID != 25
                    )
                  );
                  console.error('4', approvalStatusList);
                }
              }
            });
          }

          if (refer) {
            setApprovalStatusList(
              originalApprovalStatusList.filter(
                x => x.lookupID >= 89 && x.lookupID <= 91
              )
            );
            console.error('5', approvalStatusList);
          } else {
            setApprovalStatusList(
              originalApprovalStatusList.filter(
                x => x.lookupID != 89 && x.lookupID != 90
              )
            );
            console.error('6', approvalStatusList);
          }
        }
      })
      .catch(e => {});
  };
  // const getLookup = (row) => {
  //   APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentMaster.lookupID)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         res.data.data.find((e) => {
  //           if (e.lookupID === row.statusID) {
  //             setWorkOrderStatus(e.name)
  //           }
  //         })
  //       } else {
  //         // toast.error(res.data.message);
  //       }
  //     })
  //     .catch((e) => {
  //     });
  // };

  const getLookupStatus = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          setApprovalStatusList(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => {});
  };

  useEffect(() => {
    getWorkorderApprovalList();
    getLookupStatus();
  }, []);

  const [updateWorkOrderApproval, setUpdateWorkOrderApproval] = useState([]);
  const [approval, setApproval] = useState({ ...IndentInitialValues });
  const [editRow, setEditRow] = useState([]);

  const editIndentStatus = (data, row) => {
    setDirect(false);
    let rowData = { ...row };
    const projectList = JSON.parse(userProfile.project);
    let accessRoleID = 0;

    if (projectList.length > 0) {
      accessRoleID = projectList.find(x => project == x.projectName).accessRoleID;
      rowData.currentApprovals = row.Approvals.find(
        app => app.accessRoleID === accessRoleID
      );
    }

    // setEditRow([...row.workOrder]);

    row.Approvals.forEach(e => {
      if (e.accessRoleID === 3) {
        setDirect(true);
      }
    });

    console.log(rowData);
    setUpdateWorkOrderApproval({ ...row });
    setApproval({ ...rowData });
    getLookup(row);
    setStatusEdit(data);
    setWorkOrderApprovalView(!workOrderApprovalView);
    setIndentItem({ ...rowData });
  };

  const updateWorkOrderApprovalFunction = () => {
    let referOptionPayload = false;
    let referPayload = false;
    if (approval.currentApprovals.referOptionView == 0) {
      referOptionPayload = false;
    } else {
      referOptionPayload = true;
    }
    if (approval.currentApprovals.referSelected == 0) {
      referPayload = false;
    } else {
      referPayload = true;
    }
    const payload = {
      vendorID: updateWorkOrderApproval.vendorID,
      statusID: indentItem.currentApprovals.approvalStatusID,
      workOrderMasterID: approval.workOrderMasterID,
      comment: indentItem.currentApprovals.comment,
      referOption: referOptionPayload,
      refer: referPayload
    };

    APIKit.post(RGCA_URLS.updateWorkOrderApproval, payload)
      .then(res => {
        if (res.data.status === 200) {
          handleIndentViewClose();
          getWorkorderApprovalList();
          addToast(res.data.message, { appearance: 'success' });
        } else {
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function(e) {});
  };


  return (
    <Container maxWidth={true} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <Typography>
        <h2 style={{ color: '#394671' }}>Pending For work order</h2>
      </Typography>
      <Grid style={{ marginTop: '15px' }} item lg={12} sm={12} xl={12} xs={12}>
        <div style={{ textAlign: 'center' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Work Order ID</TableCell>
                  <TableCell align="center">Vendor Name</TableCell>
                  <TableCell align="center">Contract Value</TableCell>
                  <TableCell align="center">Created By</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workOrderApprovalList.length > 0 ? (
                  workOrderApprovalList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map(row => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.workOrderMasterID}>
                          <TableCell align="center"> {row.workOrderMasterID} </TableCell>
                          <TableCell align="center">{row.venName}</TableCell>
                          <TableCell align="center">{currencyFormat1(Math.round(row?.tcv))}</TableCell>
                          <TableCell align="center"> {row.CreatedByName} </TableCell>
                          <TableCell align="center"> {dateFormate(row.CreateDate)} </TableCell>
                          <TableCell align="center">
                            <EditIcon style={{ cursor: 'pointer' }}
                              onClick={e => {
                                editIndentStatus('Edit', row); //ProjectIndentCreateOrEdit("IND-12345")
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow hover role="checkbox" key={1}>
                    <TableCell align="center" colSpan={6} key={2}>
                      {'No Data Found'}
                    </TableCell>{' '}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {workOrderApprovalList.length > 0 && <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={workOrderApprovalList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </div>
      </Grid>
      <Formik
        enableReinitialize={true}
        initialValues={{
          Note:
            approval.currentApprovals &&
            approval.currentApprovals.comment == 'undefined'
              ? ''
              : approval.currentApprovals.comment,
          status:
            approval.currentApprovals.approvalStatusID == 0
              ? ''
              : approval.currentApprovals.approvalStatusID
        }}
        onSubmit={(values, { resetForm }) => {
          updateWorkOrderApprovalFunction();
          resetForm(values);
        }}
        validationSchema={Yup.object().shape({
          Note: Yup.string().required(MESSAGE.note),
          status: Yup.string().required(MESSAGE.status)
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={workOrderApprovalView}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleIndentViewClose}
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                width: '800px !important'
              }}
            >
              Work Order Approval View
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent dividers style={{ width: '800px !important' }}>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={3} xl={3} xs={3}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Work Order ID :{' '}
                          {updateWorkOrderApproval?.workOrderMasterID}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item lg={3} sm={3} xl={3} xs={3}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Date :{' '}
                          {dateFormate(updateWorkOrderApproval?.CreateDate)}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>
                  {/* ---------------------------------------------------------------------------------------- */}
                  <Grid item lg={3} sm={3} xl={3} xs={3}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Total Value: {updateWorkOrderApproval?.tcv}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item lg={3} sm={3} xl={3} xs={3}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: statusColor('PENDING') }}>
                          {' '}
                          GST Type: {updateWorkOrderApproval?.gstType}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <CommonTable
                        columns={updateColumns}
                        data={updateWorkOrderApproval.workOrder}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Box mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={2}>
                      {indentItem.currentApprovals.referOptionView==1 && !direct ? (
                        <Grid
                          item
                          md={ indentItem.currentApprovals.referOptionView ? 3 : 0 }
                          xs={ indentItem.currentApprovals.referOptionView ? 12 : 0 }
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    indentItem.currentApprovals.editLock
                                  }
                                  checked={
                                    indentItem.currentApprovals.referSelected
                                  }
                                  onChange={e => {
                                    let rowData = { ...indentItem };
                                    rowData.currentApprovals.referSelected = !rowData
                                      .currentApprovals.referSelected;
                                    setIndentItem({ ...rowData });
                                    setRefer(!refer)
                                    if (e.target.checked) {
                                      setApprovalStatusList(
                                        originalApprovalStatusList.filter(
                                          x =>
                                            x.lookupID >= 89 && x.lookupID <= 91
                                        )
                                      );
                                    } else {
                                      setApprovalStatusList(
                                        originalApprovalStatusList.filter(
                                          x =>
                                            x.lookupID != 89 && x.lookupID != 90
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                              label="Refer to director"
                            />
                          </FormGroup>
                        </Grid>
                      ) : ''}
                      <Grid
                        item
                        md={indentItem.currentApprovals.referOptionView ? 3 : 4}
                        xs={12}
                      >

                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(touched.status && errors.status)}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Status
                          </InputLabel>
                          <Select
                            disabled={indentItem.currentApprovals.editLock}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onBlur={handleBlur}
                            required
                            onChange={e => {
                              handleChange(e);
                              let rowData = { ...indentItem };
                              rowData.currentApprovals.comment = "";
                              rowData.currentApprovals.approvalStatusID =
                                e.target.value;
                              setIndentItem({ ...rowData });
                              console.error(indentItem);
                            }}
                            name="status"
                            label="Status"
                            value={
                              // indentItem.currentApprovals.status
                              // indentItem.currentApprovals.approvalStatusID == 0
                              //   ? ''
                              //   : 
                              indentItem.currentApprovals.approvalStatusID
                            }
                          >
                            {approvalStatusList.map(li => (
                              <MenuItem key={li.lookupID} value={li.lookupID}>
                                {li.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors.status && touched.status && errors.status}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {[25, 26, 28, 90].findIndex(
                        x => x == indentItem.currentApprovals.approvalStatusID
                      ) != -1 && (
                          <Grid
                            item
                            md={
                              indentItem.currentApprovals.referOptionView ? 6 : 8
                            }
                            xs={12}
                          >
                            <TextField
                              disabled={indentItem.currentApprovals.editLock}
                              fullWidth
                              error={Boolean(
                                [25, 26, 28, 90].some(
                                  x =>
                                    x ==
                                    indentItem.currentApprovals.approvalStatusID
                                )
                                  ? touched.Note && errors.Note
                                  : 'false'
                              )}
                              helperText={
                                [25, 26, 28, 90].some(
                                  x =>
                                    x ==
                                    indentItem.currentApprovals.approvalStatusID
                                )
                                  ? touched.Note && errors.Note
                                  : false
                              }
                              label="Note"
                              name="Note"
                              onBlur={handleBlur}
                              inputProps={{maxLength:20}}
                              value={
                                indentItem.currentApprovals.comment == 'undefined'
                                  ? ''
                                  : indentItem.currentApprovals.comment
                              }
                              onChange={e => {
                                handleChange(e);
                                let rowData = { ...indentItem };
                                rowData.currentApprovals.comment = e.target.value;
                                setIndentItem({ ...rowData });
                              }}
                              variant="outlined"

                            />
                          </Grid>
                        )}

                    </Grid>
                  </Box>
                <br />
                <Grid container spacing={3}>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Box>
                      <StepperBox steps={approval.Approvals} />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                {statusEdit == 'Edit' ? (
                  <Button
                    disabled={approval.currentApprovals.editLock}
                    // color="primary"
                    onClick={e => {
                      updateWorkOrderApprovalFunction();
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                    type="submit"
                    // variant="contained"
                  >
                    Update
                  </Button>
                ) : null}
                <Button
                  // color="primary"
                  onClick={handleIndentViewClose}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                  // variant="contained"
                >
                  Close
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </Container>
  );
};
export default ApprovalWorkOrder;
