import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    TextField,
    Divider,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { MESSAGE } from 'src/utils/message';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import { getloggedInData } from '../../../utils/helpers';
import Loader from 'src/views/loader';
import ClearIcon from '@mui/icons-material/Clear';



const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));



const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


const PaymentScreen = ({ setIsPayment, setIsLoading, getReceipts, getMaterialsForSelling, isLoading,
    setReceipt, receipt, allCustomer, lookData, projectDetails, receiptID, setReceiptID, isUpdate, setIsUpdate }) => {
    const classes = useStyles();
    const [morAttach, setMorAttach] = useState("");

    const handleCloseModal = (resetForm = null) => {
        setIsPayment(false);
        setIsUpdate(false)
        if (resetForm) {
            resetForm();
        }
    };
    let regEx = {
        textOnly: /[a-zA-Z\s]+/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/
    };



    const updateReceipt = () => {
        setIsLoading(true)
        const payload = {
            //  ...receipt
            // projectID: projectDetails.projectID,
            // receiptID: receiptID,
            // amount: receipt.amount,
            // mor: receipt.mor,
            // morAttach: receipt.morAttach,
            // customer: receipt.customer,
            // purpose: receipt.purpose,
            // others: receipt.others,
            // transferNo: receipt.transferNo,
            // bank: receipt.bank,
            // receiptDate: receipt.receiptDate,
            // receiptNotes: receipt.receiptNotes,
            // receiptFrom: receipt.receiptFrom
            receiptID: receipt?.receiptID,
            projectID: projectDetails.projectID,
            amount: receipt?.amount,
            mor: receipt.mor,
            morAttach: morAttach,
            customer: receipt.customer,
            purpose: receipt.purpose,
            transferNo: receipt.transferNo,
            receiptNotes: receipt.receiptNotes,
            upiType: receipt.upiType,
            totalAmountPaid: receipt.totalAmountPaid,
            sellingMasterID: receipt.sellingMasterID,
            bankAccount:receipt.bankAccount,
            bankName: receipt.bankName,
            ifscCode: receipt.ifscCode
        };

        console.log('payload', payload);
        APIKit.put(RGCA_URLS.updateReceipts, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + getloggedInData().accessToken
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    getReceipts();
                    getMaterialsForSelling();
                    setIsLoading(false)
                    toast.success(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    setReceipt({
                        sellingMasterID: '',
                        amount: '',
                        mor: '',
                        customer: '',
                        purpose: '',
                        others: '',
                        morAttach: '',
                        transferNo: '',
                        bank: '',
                        receiptDate: '',
                        receiptNotes: '',
                        receiptFrom: '',
                        upiType: '',
                        totalAmountPaid: '',
                        receiptID: ''
                    });
                    handleCloseModal();
                    setReceiptID(null);
                } else {
                    setIsLoading(false)
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };

    // const handleUploadClick = (event, type) => {
    //     
    //     if (event.target.files[0] === undefined) {
    //         return;
    //     }
    //     if (event.target.files[0].size > 3145728) {
    //         toast.error(MESSAGE.lessthan3mb, {
    //             position: 'top-right',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined
    //         });
    //         return;
    //     }
    //     setIsLoading(true);
    //     const formData = new FormData();
    //     formData.append(
    //         'upload',
    //         event.target.files[0],
    //         event.target.files[0].name
    //     );
    //     if (type == 'Modeofreceipt') {
    //         APIKit.post(RGCA_URLS.upload, formData)
    //             .then(res => {
    //                 if (res.data.status == 200) {
    //                     setIsLoading(false);
    //                     toast.success(MESSAGE.fileUpload, {
    //                         position: 'top-right',
    //                         autoClose: 5000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined
    //                     });
    //                     setMorAttach(res.data.data);
    //                     setReceipt({
    //                         ...receipt,
    //                         morAttach: res.data.data.file
    //                     });
    //                 } else {
    //                     setIsLoading(false);
    //                     toast.error(res.data.message, {
    //                         position: 'top-right',
    //                         autoClose: 5000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined
    //                     });
    //                 }
    //             })
    //             .catch(() => { });
    //     }
    // };
    const handleUploadClick = (event, type) => {
        const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        if (!isRightFormat) {
          toast.error("You can only upload pdf , csv,excel, and doc files");
          return;
        }
        if (event.target.files[0] === undefined) {
            return;
        }
        if (event.target.files[0].size > 3145728) {
            toast.error(MESSAGE.lessthan3mb, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append("files", event.target.files[0], event.target.files[0].name);
        if (type == "Modeofreceipt") {
            APIKit.post(RGCA_URLS.upload, formData)
                .then((res) => {
                    if (res.data.status == 200) {
                        setIsLoading(false);
                        toast.success(MESSAGE.fileUpload, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        setMorAttach(res.data.data);
                        console.log('ddddd', res.data.data);
                        setReceipt({
                            ...receipt,
                            morAttach: res.data.data.file
                        });
                    } else {
                        setIsLoading(false);
                        toast.error(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(() => { });
        }

    };
    return (
        <>
            <Loader isLoading={isLoading} />

            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...receipt
                }}
                validationSchema={Yup.object().shape({
                    amount: Yup.string().required(MESSAGE.amount),
                    mor: Yup.string().required(MESSAGE.mor),
                    customer: Yup.string().required(MESSAGE.customer),
                    purpose: Yup.string().required(MESSAGE.purpose),
                    totalAmountPaid: Yup.string().required(MESSAGE.amount)
                })}
                onSubmit={(values, { resetForm }) => {
                    if (isUpdate) {
                        updateReceipt()
                    } else {
                        setIsLoading(true)
                        const payload = {
                            projectID: projectDetails.projectID,
                            amount: receipt?.amount,
                            mor: receipt.mor,
                            morAttach: morAttach,
                            customer: receipt.customer,
                            purpose: receipt.purpose,
                            transferNo: receipt.transferNo,
                            receiptNotes: receipt.receiptNotes,
                            upiType: receipt.upiType,
                            totalAmountPaid: receipt.totalAmountPaid,
                            sellingMasterID: receipt.sellingMasterID,
                            bankAccount:receipt.bankAccount,
                            bankName: receipt.bankName,
                            ifscCode: receipt.ifscCode

                        };
                        console.log("payload", payload);
                        APIKit.post(RGCA_URLS.createReceipts, payload)
                            .then(res => {
                                if (res.data.status === 200) {
                                    setIsLoading(false);
                                    handleCloseModal();
                                    getReceipts();
                                    getMaterialsForSelling();
                                    resetForm(values);
                                    setReceipt({
                                        sellingMasterID: '',
                                        amount: '',
                                        mor: '',
                                        customer: '',
                                        purpose: '',
                                        others: '',
                                        morAttach: '',
                                        transferNo: '',
                                        bank: '',
                                        receiptDate: '',
                                        receiptNotes: '',
                                        receiptFrom: '',
                                        upiType: '',
                                        totalAmountPaid: '',
                                        receiptID: ''
                                    });
                                    toast.success(MESSAGE.receiptCreate, {
                                        position: 'top-right',
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                } else {
                                    setIsLoading(false);
                                    toast.error(res.data.error, {
                                        position: 'top-right',
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                }
                            })
                            .catch(e => {
                                setIsLoading(false);
                            });
                    }
                }
                }

            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogContent dividers>
                            <Loader isLoading={isLoading} />

                            <Box
                                mt={3}
                                style={{
                                    backgroundColor: '#fff',
                                    boxShadow:
                                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            // helperText=""
                                            label="Sales Id"
                                            name="sellingMasterID"
                                            onBlur={handleBlur}
                                            //  error={Boolean(touched.sellingId && errors.amount)}
                                            // helperText={touched.amount && errors.amount}
                                            onChange={e => {
                                                if (
                                                    e.target.value === '' ||
                                                    regEx.numbersOnly.test(e.target.value)
                                                ) {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.sellingMasterID = Number(e.target.value);
                                                    setReceipt({
                                                        ...p,
                                                        sellingMasterID: Number(e.target.value)
                                                    });
                                                }
                                            }}
                                            required
                                            value={receipt.sellingMasterID}
                                            variant="outlined"
                                        />
                                        {/* <span style={{ color: "red" }}>{amountError}</span> */}
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    disabled
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Date"
                                                    format="dd/MM/yyyy"
                                                    disableFuture={true}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="receiptDate"
                                                    onChange={e => {
                                                        const p = receipt;
                                                        p.receiptDate = e;
                                                        setReceipt({
                                                            ...p,
                                                            receiptDate: e
                                                        });
                                                    }}
                                                    value={
                                                        receipt.receiptDate == ''
                                                            ? null
                                                            : receipt.receiptDate
                                                    }
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider> */}
                                        <TextField
                                            fullWidth
                                            disabled
                                            // helperText=""
                                            label="Create Date"
                                            name="amount"
                                            onBlur={handleBlur}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                            required
                                            value={moment(receipt.receiptDate).format("DD/MM/YYYY")
                                            }

                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                            error={Boolean(touched.customer && errors.customer)}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Customer
                                            </InputLabel>
                                            <Select
                                                disabled
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={receipt.customer}
                                                name="customer"
                                                label="Customer"
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.customer = Number(e.target.value);
                                                    setReceipt({
                                                        ...p,
                                                        customer: Number(e.target.value)
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                            >
                                                {allCustomer &&
                                                    allCustomer.map((receipt, index) => {
                                                        return (
                                                            <MenuItem value={receipt.customerID}>
                                                                {receipt.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            <FormHelperText>
                                                {errors.customer &&
                                                    touched.customer &&
                                                    errors.customer}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            // helperText=""
                                            label="Total Amount"
                                            name="amount"
                                            onBlur={handleBlur}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                            required
                                            // value={receipt.amount}
                                            value={receipt?.amount}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            // helperText=""
                                            label="Remaning Balance"
                                            name="RemaningBalance"
                                            // onBlur={handleBlur}
                                            // error={Boolean(touched.amount && errors.amount)}
                                            // helperText={touched.amount && errors.amount}
                                            // required
                                            // value={receipt.amount}
                                            value={receipt?.remaningBalance}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            // helperText=""
                                            label="Enter Amount"
                                            name="totalAmountPaid"
                                            onBlur={handleBlur}

                                            onChange={e => {
                                                let item = { ...receipt };
                                                handleChange(e);
                                                if (
                                                    e.target.value === '' ||
                                                    regEx.numbersOnly.test(e.target.value)
                                                ) {
                                                    if (item.remaningBalance <= e.target.value) {
                                                        item.totalAmountPaid = receipt.remaningBalance;
                                                    } else {
                                                        item.totalAmountPaid = e.target.value;
                                                    }
                                                    setReceipt({ ...item });

                                                    // handleChange(e);
                                                    // const p = receipt;
                                                    // p.totalAmountPaid = Number(e.target.value);
                                                    // setReceipt({
                                                    //     ...p,
                                                    //     totalAmountPaid: Number(e.target.value)
                                                    // });
                                                }
                                            }}
                                            required
                                            error={Boolean(touched.totalAmountPaid && errors.totalAmountPaid)}
                                            helperText={touched.totalAmountPaid && errors.totalAmountPaid}
                                            value={receipt.totalAmountPaid}
                                            variant="outlined"
                                        />

                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                            error={Boolean(touched.mor && errors.mor)}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Mode Of Receipt
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={receipt.mor}
                                                name="mor"
                                                label="Mode Of Receipt"
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.mor = Number(e.target.value);
                                                    setReceipt({
                                                        ...p,
                                                        mor: Number(e.target.value)
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem value={'46'}>UPI</MenuItem>
                                                <MenuItem value={'42'}>CASH</MenuItem>
                                                <MenuItem value={'92'}>BANK ACCOUNT</MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                {errors.mor && touched.mor && errors.mor}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {receipt.mor == 46 &&
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                // helperText=""
                                                label="UPI Type"
                                                name="bank"
                                                inputProps={{ maxLength: 15 }}

                                                //  onBlur={handleBlur}
                                                // error={Boolean(touched.transferNo && errors.transferNo)}
                                                // helperText={touched.amount && errors.amount}
                                                onChange={e => {
                                                    if (
                                                        e.target.value === '' ||
                                                        regEx.textOnly.test(e.target.value)
                                                    ) {
                                                        handleChange(e);
                                                        const p = receipt;
                                                        p.upiType = e.target.value;
                                                        setReceipt({
                                                            ...p,
                                                            upiType: e.target.value
                                                        });
                                                    }
                                                }}
                                                value={receipt.upiType}
                                                variant="outlined"
                                            />
                                            {/* <span style={{ color: "red" }}>{amountError}</span> */}
                                        </Grid>
                                    }
                                    {receipt.mor == 92 &&
                                        <>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Bank Name"
                                                    name="bank"
                                                    onChange={e => {
                                                        if (
                                                            e.target.value === '' ||
                                                            regEx.textOnly.test(e.target.value)
                                                        ) {
                                                            handleChange(e);
                                                            const p = receipt;
                                                            p.bankName = e.target.value;
                                                            setReceipt({
                                                                ...p,
                                                                bankName: e.target.value
                                                            });
                                                        }
                                                    }}
                                                    value={receipt.bankName}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 20 }}

                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Bank Account Number"
                                                    name="bank"
                                                    onChange={e => {

                                                        handleChange(e);
                                                        const p = receipt;
                                                        p.bankAccount = e.target.value;
                                                        setReceipt({
                                                            ...p,
                                                            bankAccount: e.target.value
                                                        });

                                                    }}
                                                    value={receipt.bankAccount}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 15 }}

                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="IFSC Code"
                                                    name="bank"
                                                    inputProps={{ maxLength: 15 }}

                                                    onChange={e => {

                                                        handleChange(e);
                                                        const p = receipt;
                                                        p.ifscCode = e.target.value;
                                                        setReceipt({
                                                            ...p,
                                                            ifscCode: e.target.value
                                                        });

                                                    }}
                                                    value={receipt.ifscCode}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Transaction Id"
                                                    name="bank"
                                                    inputProps={{ maxLength: 20 }}

                                                    onChange={e => {

                                                        handleChange(e);
                                                        const p = receipt;
                                                        p.transferNo = e.target.value;
                                                        setReceipt({
                                                            ...p,
                                                            transferNo: e.target.value
                                                        });

                                                    }}
                                                    value={receipt.transferNo}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {receipt.mor != 92 &&
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                // helperText=""
                                                inputProps={{ maxLength: 20 }}

                                                label={receipt.mor == 46 ? "Transaction Number" : "Receipt Number"}
                                                name="transferNo"
                                                //  onBlur={handleBlur}
                                                // error={Boolean(touched.transferNo && errors.transferNo)}
                                                // helperText={touched.amount && errors.amount}
                                                onChange={e => {
                                                    if (
                                                        e.target.value === '' ||
                                                        regEx.numbersOnly.test(e.target.value)
                                                    ) {
                                                        handleChange(e);
                                                        const p = receipt;
                                                        p.transferNo = e.target.value;
                                                        setReceipt({
                                                            ...p,
                                                            transferNo: e.target.value
                                                        });
                                                    }
                                                }}
                                                value={receipt.transferNo}
                                                variant="outlined"
                                            />
                                            {/* <span style={{ color: "red" }}>{amountError}</span> */}
                                        </Grid>
                                    }
                                    <Grid item md={4} xs={12}>
                                        <Button
                                            style={{ width: '85%', height: '80%' }}
                                            variant="contained"
                                            component="label"
                                        >
                                            {!morAttach ? 'Mode Of Receipt Attach' : ''}
                                            <input
                                                type="file"
                                                hidden
                                                onChange={e =>
                                                    handleUploadClick(e, 'Modeofreceipt')
                                                }
                                            />
                                            {morAttach && (
                                                <a
                                                    style={{
                                                        color: 'blue',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        marginTop: '9px'
                                                    }}
                                                    href={morAttach}
                                                    target="_blank"
                                                >
                                                    {morAttach.substring(2, 25)}....
                                                    {morAttach.split('.').pop()}
                                                </a>
                                            )}
                                        </Button>
                                        {morAttach && (
                                            <ClearIcon onClick={() => {
                                                setMorAttach('');
                                            }} style={{ cursor: 'pointer' }} />
                                        )}
                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                            error={Boolean(touched.purpose && errors.purpose)}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Purpose
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={receipt.purpose}
                                                name="purpose"
                                                label="purpose"
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.purpose = Number(e.target.value);
                                                    setReceipt({
                                                        ...p,
                                                        purpose: Number(e.target.value)
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                            >
                                                {lookData &&
                                                    lookData.map((receipt, index) => {
                                                        return (
                                                            <MenuItem value={receipt.lookupID}>
                                                                {receipt.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>

                                            <FormHelperText>
                                                {errors.purpose && touched.purpose && errors.purpose}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {receipt.purpose == 53 && (
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                // helperText=""
                                                label="Others"
                                                name="others"
                                                inputProps={{ maxLength: 20 }}
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.others = e.target.value;
                                                    setReceipt({
                                                        ...p,
                                                        others: e.target.value
                                                    });
                                                }}
                                                value={receipt.others}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    )}

                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            // helperText=""
                                            label="Receipt Notes"
                                            name="receiptNotes"
                                            inputProps={{ maxLength: 30 }}

                                            //  onBlur={handleBlur}
                                            // error={Boolean(touched.transferNo && errors.transferNo)}
                                            // helperText={touched.amount && errors.amount}
                                            onChange={e => {
                                                if (
                                                    e.target.value === '' ||
                                                    regEx.textOnly.test(e.target.value)
                                                ) {
                                                    handleChange(e);
                                                    const p = receipt;
                                                    p.receiptNotes = e.target.value;
                                                    setReceipt({
                                                        ...p,
                                                        receiptNotes: e.target.value
                                                    });
                                                }
                                            }}
                                            value={receipt.receiptNotes}
                                            variant="outlined"
                                        />
                                        {/* <span style={{ color: "red" }}>{amountError}</span> */}
                                    </Grid>

                                    <Grid item md={12} xs={12}></Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions
                            style={{ backgroundColor: '#394671', color: '#fff' }}
                        >
                            <Button
                                onClick={e => {
                                    handleCloseModal(resetForm);
                                }}
                                className={classes.button2}
                            >
                                Cancel
                            </Button>
                            {isUpdate == false ? (
                                <Button type="submit" className={classes.button2}>
                                    Save
                                </Button>
                            ) : (
                                <Button /* onClick={updateReceipt} */ type="submit" className={classes.button2}>
                                    Update
                                </Button>
                            )}
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PaymentScreen