import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import {
    Table, TableBody, TableCell, Box, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination
} from '@material-ui/core';
import TableDataHistory from './payment.json';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import receiptLogo from '../../Project/Selling/receipt.png';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import moment from 'moment';
import { currencyFormat1, getProjectDetails } from '../Common/utilities';
import { useReactToPrint } from 'react-to-print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useParams } from 'react-router-dom';
import Address from '../Common/Address';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);


const PaymentHistory = ({ setIsHistory, projectDetails, receiptIDHistory, histryData }) => {
    const classes = useStyles();
    const [paymentHistry, setPaymentHistry] = useState(false)
    const [printValues, setPrintValues] = useState({})
    const { project } = useParams();
    // const projectDetails = { ...getProjectDetails(project) };
    const [allProjects, setAllProjects] = useState([]);


    const printOrder = () => {
        const printableElements = document.getElementById('printme').innerHTML; // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
        const oldPage = document.body.innerHTML;
        const html = `<html><head><title></title>
            <style>
            
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }
    
    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }
    
    .invoice-box table td {
        
        vertical-align: top;
    }
    
    
    
    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }
    
    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }
    
    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }
    
    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
    }
    
    .invoice-box table tr.item.last td {
        border-bottom: none;
    }
    
    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }
    
    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    
        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    
    /** RTL **/
    .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }
    
    .invoice-box.rtl table {
        text-align: right;
    }
    
    .invoice-box.rtl table tr td:nth-child(2) {
        /* text-align: left; */
    }
            </style>
            </head><body>'${printableElements}'</body></html>`;
        // console.log(html)
        // document.body.innerHTML = html
        window.print();
        // document.body.innerHTML = oldPage;
        //setIncPrintOpen(false);
        // window.location.reload()
    };

    const price_in_words = price => {
        var sglDigit = [
            'Zero',
            'One',
            'Two',
            'Three',
            'Four',
            'Five',
            'Six',
            'Seven',
            'Eight',
            'Nine'
        ],
            dblDigit = [
                'Ten',
                'Eleven',
                'Twelve',
                'Thirteen',
                'Fourteen',
                'Fifteen',
                'Sixteen',
                'Seventeen',
                'Eighteen',
                'Nineteen'
            ],
            tensPlace = [
                '',
                'Ten',
                'Twenty',
                'Thirty',
                'Forty',
                'Fifty',
                'Sixty',
                'Seventy',
                'Eighty',
                'Ninety'
            ],
            handle_tens = function (dgt, prevDgt) {
                return 0 == dgt
                    ? ''
                    : ' ' + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
            },
            handle_utlc = function (dgt, nxtDgt, denom) {
                return (
                    (0 != dgt && 1 != nxtDgt ? ' ' + sglDigit[dgt] : '') +
                    (0 != nxtDgt || dgt > 0 ? ' ' + denom : '')
                );
            };

        var str = '',
            digitIdx = 0,
            digit = 0,
            nxtDigit = 0,
            words = [];
        if (((price += ''), isNaN(parseInt(price)))) str = '';
        else if (parseInt(price) > 0 && price.length <= 10) {
            for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
                switch (
                ((digit = price[digitIdx] - 0),
                    (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
                    price.length - digitIdx - 1)
                ) {
                    case 0:
                        words.push(handle_utlc(digit, nxtDigit, ''));
                        break;
                    case 1:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 2:
                        words.push(
                            0 != digit
                                ? ' ' +
                                sglDigit[digit] +
                                ' Hundred' +
                                (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                                    ? ' and'
                                    : '')
                                : ''
                        );
                        break;
                    case 3:
                        words.push(handle_utlc(digit, nxtDigit, 'Thousand'));
                        break;
                    case 4:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 5:
                        words.push(handle_utlc(digit, nxtDigit, 'Lakh'));
                        break;
                    case 6:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 7:
                        words.push(handle_utlc(digit, nxtDigit, 'Crore'));
                        break;
                    case 8:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 9:
                        words.push(
                            0 != digit
                                ? ' ' +
                                sglDigit[digit] +
                                ' Hundred' +
                                (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                                    ? ' and'
                                    : ' Crore')
                                : ''
                        );
                }
            str = words.reverse().join('');
        } else str = '';
        return str;
    };

    function formatDateWithOrdinalSuffix(date) {
        if (!(date instanceof Date) || isNaN(date.getTime())) return ''; // Check if date is not a Date object or if it's invalid
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        const day = date.getDate();
        const suffix = getOrdinalSuffix(day);
        return formattedDate.replace(/\b\d+\b/, day + suffix);
    }



    function getOrdinalSuffix(number) {
        if (number >= 11 && number <= 13) {
            return 'th';
        }
        switch (number % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    useEffect(() => {
        getAllProjects()
    }, [])

    const htmlContentRef = useRef(null);
    const printOrderNew = useReactToPrint({
        content: () => htmlContentRef.current,
    });



    const getAllProjects = () => {
        APIKit.post(RGCA_URLS.getProjects)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('kkk', res.data.data);
                    setAllProjects(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(() => { });
    };

    console.log("projectDetails", projectDetails);
    return (
        <>
            <DialogContent dividers>
                <Box
                    mt={3}
                    style={{
                        backgroundColor: '#fff',
                        boxShadow:
                            '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                    }}
                >
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        <TableCell>Sale ID</TableCell>
                                        <TableCell>Payment Date</TableCell>
                                        <TableCell>Mode of Payment</TableCell>
                                        {/* <TableCell>Material</TableCell> */}
                                        <TableCell>Total</TableCell>
                                        <TableCell>Paid Amount</TableCell>
                                        <TableCell>Remaning Balance</TableCell>
                                        <TableCell>Transaction ID</TableCell>
                                        <TableCell>Receipt</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {histryData && histryData.length != 0 ? (
                                        histryData.map((x, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{x.sellingMasterID}</TableCell>
                                                    <TableCell>{moment(x?.createdDate).format('DD/MM/yyyy')}</TableCell>
                                                    <TableCell>{x.modeofReceipt == 46 ? "UPI" : x.modeofReceipt == 42 ? "CASH" : "BANK ACCOUNT"}</TableCell>
                                                    {/* <TableCell>{x.Productname}</TableCell> */}
                                                    <TableCell>₹ {currencyFormat1(Math.round(x?.amount))}</TableCell>
                                                    <TableCell>₹ {currencyFormat1(Math.round(x?.totalAmountPaid))}</TableCell>
                                                    <TableCell>₹ {currencyFormat1(Math.round(x?.remaningBalance))}</TableCell>
                                                    <TableCell>{x?.transferNo}</TableCell>
                                                    <TableCell>RGCA-PT-{x?.SalesHistoryDataID}</TableCell>
                                                    {/* <TableCell>
                                                       {x?.modeAttach && <a href={x?.modeAttach}>{x?.modeAttach.substring(4, 15)} ...</a> }
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <VisibilityIcon
                                                            onClick={() => {
                                                                setPrintValues(x);
                                                                setPaymentHistry(true);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                <Button
                    className={classes.button2}
                    onClick={() => setIsHistory(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
            <Dialog
                aria-labelledby="customized-dialog-title"
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="md"
                fullWidth
                open={paymentHistry}
                id="income"
            >
                <DialogContent dividers style={{ overflow: 'scroll' }}>
                    {/* <PictureAsPdfIcon onClick={exportPdf} /> &nbsp; */}
                    <PrintIcon onClick={printOrderNew} style={{ cursor: 'pointer' }} />

                    <div className="" ref={htmlContentRef} id="myHtmlContent" style={{
                        marginTop: '10px',
                        padding: '20px'
                    }}>
                        <div className="row container" >
                            <div className="col-sm-3" style={{
                                display: "flex",
                                justifyContent: "end"
                            }}>
                                <img
                                    src="/static/logo-rgca.jpg"
                                    alt="Company logo"
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div className="col-sm-9">
                                <p>
                                    <h3 style={{ textAlign: 'center' }}>
                                        RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)
                                    </h3>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        (MPEDA,Ministry of Commerce & Industry Government of India)
                                    </h5>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        {/* HEAD OFFICE / UNIT OFFICE */}
                                        {projectDetails?.projectName ? projectDetails?.projectName : ''}
                                    </h5>
                                </p>
                                <p style={{ textAlign: 'center' }}>
                                    {/* <h5 style={{ textAlign: 'center' }}>{projectDetails?.projectName} </h5> */}
                                    <Address
                                        details={
                                            allProjects?.filter(e => {
                                                if (e.projectID === projectDetails?.projectID) {
                                                    return {
                                                        address: e?.address
                                                    };
                                                }
                                            })[0]?.address
                                        }
                                    />
                                </p>
                                <div className="row d-flex justify-content-center" style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop:'10px'
                                }}>
                                    <div className="col-sm-5" >Receipt No : RGCA-PT-{printValues?.SalesHistoryDataID}</div>
                                    <div className="col-sm-5" >Sale Id : {printValues?.sellingMasterID}</div>
                                    <div className="col-sm-4" style={{ textAlign: "end" }}>Date : {moment(printValues?.createdDate).format('DD/MM/yyyy')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row container">
                            <div className="col-sm-3" style={{ textAlign: 'center' }}>
                                <img src={receiptLogo} alt="Company logo" />
                            </div>
                            <div className="col-sm-9" style={{ marginTop: '5%' }}>
                                {/* <p>
                                    Received with gratitude from <b> {printValues?.createdByName} </b>the sum of{' '}
                                    <b>{price_in_words(printValues?.totalAmountPaid)}{' '} Rupees {' '}(₹ {printValues?.totalAmountPaid})</b>
                                    via Cashless Transfer,  <b>{printValues?.transferNo}</b> Mode of Payment{' '}
                                    <b>{printValues?.modeofReceipt == 46 ? "UPI" : "CASH"}</b> dated <b>{moment(printValues?.createdDate).format('DD/MM/yyyy')}</b>
                                    {' '} being <b>{printValues?.receiptNotes} purposeName</b>
                                </p> */}

                                {printValues?.modeofReceipt == 46 &&
                                    <p>
                                        Received with gratitude from Mr/Mrs. <b>{printValues?.createdByName}</b> the sum of
                                        <b>{price_in_words(Math.round(printValues?.totalAmountPaid))} Rupees (₹ {printValues?.totalAmountPaid})</b>
                                        <span style={{ paddingLeft: "10px" }}>via Cashless Transfer,</span>
                                        Transaction ID: <b>{printValues?.transferNo}</b>, conducted through <b>{printValues.modeofReceipt == 46 ? "UPI" : printValues.modeofReceipt == 42 ? "CASH" : "BANK ACCOUNT"}

                                        </b>
                                        , on the <b>{moment(printValues?.createdDate).format('MMMM Do YYYY')}</b>,for the purpose of <b>{printValues?.purposeName}</b>.
                                    </p>
                                }
                                {printValues?.modeofReceipt == 42 &&
                                    <p>
                                        Received with gratitude from Mr/Mrs. <b>{printValues?.createdByName}</b> the sum of
                                        <b>{price_in_words(Math.round(printValues?.totalAmountPaid))} Rupees (₹ {printValues?.totalAmountPaid})</b>
                                        <span style={{ paddingLeft: "10px" }}> in <b>
                                            {printValues.modeofReceipt == 46 ? "UPI" : printValues.modeofReceipt == 42 ? "CASH" : "BANK ACCOUNT"}</b>,</span>
                                        Transaction ID: <b>{printValues?.transferNo}</b>
                                        , on the <b>{moment(printValues?.createdDate).format('MMMM Do YYYY')}</b>,for the purpose of <b>{printValues?.purposeName}</b>.
                                    </p>
                                }
                                {printValues?.modeofReceipt == 92 &&
                                    <p>
                                        Received with gratitude from Mr/Mrs. <b>{printValues?.createdByName}</b> the sum of
                                        <b>{price_in_words(Math.round(printValues?.totalAmountPaid))} Rupees (₹ {printValues?.totalAmountPaid})</b>
                                        <span style={{ paddingLeft: "10px" }}> in <b>
                                            {printValues.modeofReceipt == 46 ? "UPI" : printValues.modeofReceipt == 42 ? "CASH" : "BANK ACCOUNT"}</b>,</span>
                                        Bank Name: <b>{printValues?.bankName}</b>,
                                        Account Number: <b>{printValues?.bankAccount}</b>,
                                        IFSC: <b>{printValues?.ifscCode}</b>,
                                        Transaction ID: <b>{printValues?.transferNo}</b>
                                        , on the <b>{moment(printValues?.createdDate).format('MMMM Do YYYY')}</b>,for the purpose of <b>{printValues?.purposeName}</b>.
                                    </p>
                                }
                                <div className="row " style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                                        {/* <span>{printValues?.totalAmountPaid}</span> */}
                                        {/* <p>(Cheque Subject to Realisation)</p> */}
                                    </div>
                                    <div className="col-sm-6">Accountant</div>

                                    <div className="col-sm-6">
                                        For RAJIV GANDHI CENTRE FOR AQUACULTURE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button
                        onClick={() => setPaymentHistry(false)}
                        className={classes.button2}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default PaymentHistory