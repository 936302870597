export const ChartsData = {

  AchivementData: [
    //Thiruvallur 
    {
      Location: "Thiruvallur",
      year: "2019",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "60000",
          Achived: "10000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "50000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "20000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "40000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "200000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "7000"
        },

      ]
    },
    {
      Location: "Thiruvallur",
      year: "2018",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "70000",
          Achived: ""
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "40000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "10000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "50000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "306000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "10000"
        },

      ]
    },
    {
      Location: "Thiruvallur",
      year: "2017",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "60000",
          Achived: "10000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "50000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "20000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "40000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "200000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "7000"
        },

      ]
    },

    // Tanjavur
    {
      Location: "Tanjavur",
      year: "2019",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "60000",
          Achived: "10000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "50000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "20000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "40000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "200000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "7000"
        },

      ]
    },
    {
      Location: "Tanjavur",
      year: "2018",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "60000",
          Achived: "10000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "50000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "20000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "40000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "200000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "7000"
        },
      ]
    },
    {
      Location: "Tanjavur",
      year: "2017",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "70000",
          Achived: ""
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "40000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "10000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "50000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "306000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "10000"
        },

      ]
    },

    //Bhavanisagar
    {
      Location: "Bhavanisagar",
      year: "2019",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "40000",
          Achived: "18000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "48000",
          Achived: "35000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "10000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "200000",
          Achived: "80000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "609000",
          Achived: "196000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "19609",
          Achived: "10400"
        },
      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2018",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "60000",
          Achived: "10000"
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "50000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "20000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "40000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "200000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "7000"
        },

      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2017",
      ArrayData: [
        {
          type: "Karanthai- Early Fry",
          Target: "70000",
          Achived: ""
        },
        {
          type: "Karanthai- FingerLings",
          Target: "70000",
          Achived: "40000"
        },
        {
          type: "Thatankulam - Early fry",
          Target: "400000",
          Achived: "10000"
        },
        {
          type: "Neithalur - Fingerlings",
          Target: "2000",
          Achived: "50000"
        },
        {
          type: "Agarapettai - Fingerlings",
          Target: "600000",
          Achived: "306000"
        },
        {
          type: "Thirumangalakottai - Fingerlings",
          Target: "5609",
          Achived: "10000"
        },

      ]
    },

  ],

  //FarmarData
  FarmarData: [
    //Thiruvallur 
    {
      Location: "Thiruvallur",
      year: "2019",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "1000",
          Achived: "790",
          Financial_Target: "680"
        },
        {
          type: "EXISTING FARMS",
          Target: "500",
          Achived: "790",
          Financial_Target: "500"
        },
        {
          type: "CONSTRUCTION",
          Target: "6770",
          Achived: "7000",
          Financial_Target: "2000"
        },
      ]
    },
    {
      Location: "Thiruvallur",
      year: "2018",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "1000",
          Achived: "10000",
          Financial_Target: "8978"
        },
        {
          type: "EXISTING FARMS",
          Target: "1000",
          Achived: "10000",
          Financial_Target: "76765"
        },
        {
          type: "CONSTRUCTION",
          Target: "10009",
          Achived: "10000",
          Financial_Target: "23234"
        },
      ]
    },
    {
      Location: "Thiruvallur",
      year: "2017",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "1000",
          Achived: "10700",
          Financial_Target: "76765"
        },
        {
          type: "EXISTING FARMS",
          Target: "1000",
          Achived: "7000",
          Financial_Target: "23234"
        },
        {
          type: "CONSTRUCTION",
          Target: "790",
          Achived: "50000",
          Financial_Target: "43433"
        },
      ]
    },

    // Tanjavur
    {
      Location: "Tanjavur",
      year: "2019",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "5000",
          Achived: "10000",
          Financial_Target: "23234"
        },
        {
          type: "EXISTING FARMS",
          Target: "7970",
          Achived: "50000",
          Financial_Target: "43433"
        },
        {
          type: "CONSTRUCTION",
          Target: "1000",
          Achived: "10000",
          Financial_Target: "3456"
        },
      ]
    },
    {
      Location: "Tanjavur",
      year: "2018",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "9000",
          Achived: "12000",
          Financial_Target: "3456"
        },
        {
          type: "EXISTING FARMS",
          Target: "790",
          Achived: "30000",
          Financial_Target: "3434"
        },
        {
          type: "CONSTRUCTION",
          Target: "790",
          Achived: "75000",
          Financial_Target: "36363"
        },
      ]
    },
    {
      Location: "Tanjavur",
      year: "2017",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "1000",
          Achived: "10000",
          Financial_Target: "3456"
        },
        {
          type: "EXISTING FARMS",
          Target: "790",
          Achived: "50000",
          Financial_Target: "3434"
        },
        {
          type: "CONSTRUCTION",
          Target: "790",
          Achived: "50000",
          Financial_Target: "36363"
        },
      ]
    },

    //Bhavanisagar
    {
      Location: "Bhavanisagar",
      year: "2019",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "20000",
          Achived: "10000",
          Financial_Target: "54540"
        },
        {
          type: "EXISTING FARMS",
          Target: "7900",
          Achived: "50000",
          Financial_Target: "93456"
        },
        {
          type: "CONSTRUCTION",
          Target: "7900",
          Achived: "50000",
          Financial_Target: "76363"
        },
      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2018",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "1000",
          Achived: "76000",
          Financial_Target: "87833"
        },
        {
          type: "EXISTING FARMS",
          Target: "790",
          Achived: "60000",
          Financial_Target: "1622"
        },
        {
          type: "CONSTRUCTION",
          Target: "3000",
          Achived: "49000",
          Financial_Target: "3333"
        },
      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2017",
      ArrayData: [
        {
          type: "INPUT FEED",
          Target: "2000",
          Achived: "20000",
          Financial_Target: "333"
        },
        {
          type: "EXISTING FARMS",
          Target: "990",
          Achived: "20000",
          Financial_Target: "999"
        },
        {
          type: "CONSTRUCTION",
          Target: "980",
          Achived: "7000",
          Financial_Target: "1500"
        },
      ]
    },

  ],



  //Fishermen
  FisherMen: [
    //Thiruvallur 
    {
      Location: "Thiruvallur",
      year: "2019",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "6000",
          Achived: "49000",
          Financial_Target: "5333"
        },
        {
          type: "SUBSIDY",
          Target: "890",
          Achived: "2900",
          Financial_Target: "7279"
        },
      ]
    },
    {
      Location: "Thiruvallur",
      year: "2018",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "000",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },
    {
      Location: "Thiruvallur",
      year: "2017",
      ArrayData: [
        {
          type: "NET SUBSIDY",
          Target: "6000",
          Achived: "5000",
          Financial_Target: "5333"
        },
        {
          type: "SUBSIDY",
          Target: "890",
          Achived: "2900",
          Financial_Target: "7279"
        },
      ]
    },

    // Tanjavur
    {
      Location: "Tanjavur",
      year: "2019",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "4500",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },
    {
      Location: "Tanjavur",
      year: "2018",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "7000",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },
    {
      Location: "Tanjavur",
      year: "2017",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "5000",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },

    //Bhavanisagar
    {
      Location: "Bhavanisagar",
      year: "2019",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "2000",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2018",
      ArrayData: [

        {
          type: "NET SUBSIDY",
          Target: "3000",
          Achived: "4000",
          Financial_Target: "3333"
        },
        {
          type: "SUBSIDY",
          Target: "590",
          Achived: "2000",
          Financial_Target: "9279"
        },
      ]
    },
    {
      Location: "Bhavanisagar",
      year: "2017",
      ArrayData: [
        {
          type: "NET SUBSIDY",
          Target: "1000",
          Achived: "500",
          Financial_Target: "333"
        },
        {
          type: "SUBSIDY",
          Target: "990",
          Achived: "20000",
          Financial_Target: "999"
        },

      ]
    },

  ]
}


