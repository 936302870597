import React, { useEffect, useState, useRef } from 'react'
import {
    Box, Card, Avatar, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl, MenuItem, FormHelperText, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { currencyFormat1 } from '../../Common/utilities';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
    },
    root: {

    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    alignForInventory: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    },

    tablerow: {

    }
}));


const AllProjectSalesSummeryReport = ({ setTabName, projectDetails, allProjects }) => {
    const classes = useStyles();
    const currentDates = new Date();
    const [projectName, setProjectName] = useState('001');

    const getFinancialYearStartDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        if (month >= 3) { // April (3) or later
            return new Date(year, 3, 1); // April 1st of the current year
        } else {
            return new Date(year - 1, 3, 1); // April 1st of the previous year
        }
    };
    const [summaryPayload, setSummaryPayload] = useState({
        typeID: '',
        categoryID: '',
        // fromDate: currentDates.setDate(currentDates.getDate() - 30),
        fromDate: getFinancialYearStartDate(currentDates),
        toDate: new Date(),
        projectID: projectDetails.projectID,
        itemID: ''
    });
    const [summaryReportData, setSummaryReportData] = useState({
        resp: [],
        totalData: []
    })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    useEffect(() => {
        getDetailSalesReportForAll();
    }, [])

    const getDetailSalesReportForAll = () => {
        let payload = {
            projectID: projectName,
            FromDate: summaryPayload.fromDate ? moment(summaryPayload.fromDate).format("YYYY-MM-DD") : '',
            ToDate: summaryPayload.toDate ? moment(summaryPayload.toDate).format("YYYY-MM-DD") : ''
        };
        APIKit.post(RGCA_URLS.getDetailSalesReportForAllProject, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("getCurrentStockforAllprojects", res?.data?.data);
                    setSummaryReportData(res?.data?.data);

                } else {
                }
            })
            .catch((e) => {
            });
    };

    const exportToCSV = () => {
        try {
            const fileName = `${projectDetails?.projectName}`;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            if (!Array.isArray(summaryReportData?.resp) || summaryReportData?.resp?.length === 0) {
                toast.warning('Sales list is empty')
            }
            summaryReportData.resp.forEach(x => {
                const obj = {};
                obj['Product Name'] = x.Productname ? x?.Productname : '';
                obj['Quantity'] = x.totalSale ? x.totalSale : '';
                obj['Total Amount'] = x.totalAmount ? x.totalAmount : '';
                obj['Total No. of Beneficiaries'] = x.Beneficiaries ? x.Beneficiaries : '';
                newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 15 },
                { wch: 20 },
                { wch: 15 },
                { wch: 30 }
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };


    const htmlContentRef = useRef(null);
    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });

    const totalQty = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + Number(x.totalSale);
        });
        return test;
    };
    const totalAmount = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + Number(x.totalAmount);
        });
        return test;
    };
    const totalbenfi = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + Number(x?.Beneficiaries);
        });
        return test;
    };

    return (
        <>
            <Typography><h2 style={{ color: '#394671' }}>Sales Summary Report</h2></Typography>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center"  >
                            <Grid item md={3} xs={12} >
                                <FormControl
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        required
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Select Project
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Select Project"
                                        name="SelectProject"
                                        value={projectName}
                                        inputProps={{ maxLength: 200 }}
                                        onChange={e => {
                                            setProjectName(e.target.value)
                                        }}
                                    // disabled
                                    >
                                        <MenuItem value={"001"}>All</MenuItem>
                                        {allProjects &&
                                            allProjects.map((Project, index) => {
                                                return (
                                                    <MenuItem key={index} value={Project.projectID}>
                                                        {Project.projectName}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            autoOk
                                            disableFuture
                                            InputProps={{ disabled: true }}
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From Date"
                                            format="dd/MM/yyyy"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            name="fromDate"
                                            onChange={e => {
                                                const p = summaryPayload;
                                                p.fromDate = e;
                                                setSummaryPayload({ ...p });
                                                setTabName(`Sales_Summary_Report_${summaryPayload.fromDate}to${summaryPayload.toDate}`)
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            value={summaryPayload?.fromDate}
                                            className={classes.calmar}
                                            required
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            autoOk
                                            disableFuture
                                            // maxDate={new Date()}
                                            InputProps={{ disabled: true }}
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="To Date"
                                            format="dd/MM/yyyy"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            name="toDate"
                                            onChange={e => {
                                                const p = summaryPayload;
                                                p.toDate = e;
                                                setSummaryPayload({ ...p });
                                                setTabName(`Sales_Summary_Report_${moment(summaryPayload?.fromDate).format('DD/MM/YYYY')}to${moment(summaryPayload?.toDate).format('DD/MM/YYYY')}`)
                                            }}
                                            value={summaryPayload?.toDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            className={classes.calmar}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={2} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                {summaryPayload.fromDate && summaryPayload.toDate && summaryPayload.projectID ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            setTabName(`Sales_Summary_Report_${moment(summaryPayload?.fromDate).format('DD/MM/YYYY')}to${moment(summaryPayload?.toDate).format('DD/MM/YYYY')}`);
                                            getDetailSalesReportForAll();
                                        }}
                                        type="submit"
                                        size='large'
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled
                                        type="submit"
                                        size='large'
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>


            {summaryReportData && summaryReportData.resp && summaryReportData?.resp?.length > 0 &&
                <Box display="flex" justifyContent="flex-end">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={2}
                            style={{
                                marginTop: '10px',
                                // color: '#3f51b5',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                display: 'flex',
                                gap: '8px'

                            }}
                        >
                            <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => {
                                printOrder();
                                // setprint(true);

                            }} />
                            <PrintIcon style={{ cursor: 'pointer' }} onClick={() => {
                                printOrder();
                                // setprint(true);

                            }} />
                            <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                exportToCSV();
                            }} />
                        </Grid>
                        <Grid item lg={10} style={{ marginTop: '10px' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={4}>
                                    Total Quantity : {summaryReportData.totalData[0]?.OverallTotalSale}
                                </Grid>
                                <Grid item lg={4}>
                                    Total Amount :  {summaryReportData.totalData[0]?.OverallTotalAmount}
                                </Grid>
                                <Grid item lg={4}>
                                    Total Beneficiaries :  {summaryReportData.totalData[0]?.overallTotalBeneficiaries}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }

            <Box mt={3}>
                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">S.No</TableCell >
                                <TableCell align="left">Project Name</TableCell >
                                <TableCell align="left">Item Name</TableCell >
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left">Total Amount</TableCell>
                                <TableCell align="left">Total No. of Beneficiaries</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {summaryReportData && summaryReportData?.resp?.length != 0 ? (
                                summaryReportData?.resp?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x, index) => {
                                    return (
                                        <TableRow hover key={index + 1}>
                                            <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                            <TableCell align="left">{x?.projectName}</TableCell>
                                            <TableCell align="left">{x?.Productname}</TableCell>
                                            <TableCell align="left">{currencyFormat1(Math.round(x.totalSale))}</TableCell>
                                            <TableCell align="left">{currencyFormat1(Math.round(x.totalAmount))}</TableCell>
                                            <TableCell align="left">{x.Beneficiaries}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow hover role="checkbox" key={1}>
                                    <TableCell colSpan={5} align="center" key={2}>
                                        {'No Data Found'}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {summaryReportData && summaryReportData?.resp && summaryReportData?.resp?.length > 10 &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={summaryReportData?.resp?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Box>

            <div className="printViewBox" ref={htmlContentRef} id="myHtmlContent">
                <div class="grid-container">
                    <div class="logo-container">
                        <img src="/static/logo-rgca.jpg" alt="Company logo" />
                    </div>
                    <div class="text-container">
                        <div>
                            <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                            <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                            <h5>HEAD OFFICE / UNIT OFFICE</h5>
                        </div>
                    </div>
                </div>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Grid item>
                        <Typography>
                            Name :  Sales Summary Report
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            From Date : {moment(summaryReportData?.resp[0]?.fromDate).format("MM/DD/YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            To Date : {moment(summaryReportData?.resp[0]?.toDate).format("MM/DD/YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Date : {moment(new Date()).format("MM/DD/YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Project Name :  {projectDetails?.projectName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        Total Quantity : {summaryReportData.totalData[0]?.OverallTotalSale}
                    </Grid>
                    <Grid item lg={4}>
                        Total Amount :  {summaryReportData.totalData[0]?.OverallTotalAmount}
                    </Grid>
                    <Grid item lg={4}>
                        Total Beneficiaries :  {summaryReportData.totalData[0]?.overallTotalBeneficiaries}
                    </Grid>
                </Grid>
                <div class="">
                    <table class="table">
                        <thead class='tableHead'>
                            <tr class="table-rows">
                                <th align="left">S.No</th>
                                <th align="left">Project Name</th>
                                <th align="left">Product Name</th>
                                <th align="left">Quantity</th>
                                <th align="left">Total Amount</th>
                                <th align="left">Total No. of Beneficiaries</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryReportData && summaryReportData?.resp && summaryReportData?.resp?.length != 0 ? (
                                summaryReportData?.resp.map((x, index) => {
                                    return (
                                        <tr class="table-row">
                                            <td align="left">{index + 1}</td>
                                            <td align="left">{x?.projectName}</td>
                                            <td align="left">{x?.Productname}</td>
                                            <td align="left">{currencyFormat1(Math.round(x.totalSale))}</td>
                                            <td align="left">{currencyFormat1(Math.round(x.totalAmount))}</td>
                                            <td align="left">{currencyFormat1(Math.round(x.Beneficiaries))}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr class="table-row">
                                    <td align="left">
                                        {'No Data Found'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default AllProjectSalesSummeryReport