import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Capital = ({ className, CHART, ...rest }) => {
  const classes = useStyles();
  const state = CHART;
  //  {
  //   labels: ["Construction of Civil Works & Building", 'Equipments & Machineries', 'Cost of Land',
  //            'MACHINERIES & EQUIPMENTS', 'MACHINERY & EQUIPMENTS'],
  //   datasets: [
  //     {
  //       label: 'Rainfall',
  //       backgroundColor: 'rgba(75,192,192,1)',
  //       borderColor: 'rgba(0,0,0,1)',
  //       borderWidth: 2,
  //       data: [65000, 59000, 800000, 81000, 560000]
  //     }
  //   ]
  // }








  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >

      <CardContent>
        <div>
          <Bar
            data={state}
            options={{
              title: {
                display: true,
                text: 'Capital Investment',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'right'
              }
            }}
          />
        </div>
      </CardContent>


    </Card>
  );
};

Capital.propTypes = {
  className: PropTypes.string
};

export default Capital;




