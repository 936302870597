import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography, Card, CardContent, makeStyles, Grid, Button, Dialog, DialogTitle, DialogContent, TableContainer, Box, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import { RGCA_URLS } from './../../../utils/url';
import { getProjectDetails, parseData, dateFormate } from '../Common/utilities';
import APIKit from 'src/utils/APIKit';
import { useParams } from "react-router-dom";
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import CommonTable1 from '../Common/commonTableIndent';
import StepperBox from '../Common/StepperBox.jsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(() => ({
    root: {}
}));

const viewIndent = [
    {
        title: "indent Item ID",
        field: "indentItemID",
        align: 'left',
        type: ETTypes.string,
    },
    {
        title: "Item / Asset Name",
        field: "name",
        align: 'left',
        type: ETTypes.string,
    },
    {
        title: "Uom",
        field: "uom",
        align: 'left',
        type: ETTypes.string,
    },
    {
        title: "Quantity",
        field: "Qty",
        align: 'left',
        type: ETTypes.numeric,
    },
    {
        title: "Estimated Item Price",
        field: "estimatedPrice",
        align: 'left',
        type: ETTypes.numeric,
    },
    {
        title: 'total Estimated Price',
        field: 'totalEstimatedPrice',
        align: 'left',
        type: ETTypes.numeric,
    },
    {
        title: "Stock",
        field: "availabilityQty",
        align: 'left',
        type: ETTypes.string,
    },
    {
        title: "Note",
        field: "comment",
        align: 'left',
        type: ETTypes.string,
    },
]
const updateIndent = [
    {
        title: "Indent Item ID",
        field: "indentItemID",
        align: 'center',
        type: ETTypes.string,
    },
    {
        title: "Item / Asset Name",
        field: "name",
        align: 'center',
        type: ETTypes.string,
    },
    {
        title: "UOM",
        field: "uom",
        align: 'center',
        type: ETTypes.string,
    },
    {
        title: "Quantity",
        field: "Qty",
        align: 'center',
        type: ETTypes.numeric,
        editable: EEditable.onEdit

    },
    {
        title: "Estimated Price",
        field: "estimatedPrice",
        align: 'center',
        type: ETTypes.numeric,
        editable: EEditable.onEdit
    },
    {
        title: "Stock",
        field: "availabilityQty",
        align: 'center',
        type: ETTypes.string,
    },
    {
        title: "Note",
        field: "comment",
        align: 'center',
        type: ETTypes.string,
    },
    {
        title: "Action",
        field: "action",
        align: 'center',
        list: [ETaction.onDelete]
    }
]


const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const RisedIndent = ({ className }) => {
    const classes = useStyles();
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) }
    const [indentView, setIndentView] = React.useState(false);
    const [statusEdit, setStatusEdit] = React.useState(false)
    const [print, setprint] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: "Indent ID",
            field: "indentMasterID",
            align: 'left',
            type: ETTypes.string,
        },
        {
            title: "Indent Status",
            field: "indStatus",
            align: 'left',
            type: ETTypes.string,
        },
        {
            title: "Project Name",
            field: "projectName",
            align: 'left',
            type: ETTypes.string,
        },
        {
            title: "Created Date",
            field: "CreateDate",
            align: 'left',
            type: ETTypes.date,
        },
        {
            title: "Actions",
            field: "action",
            align: 'left',
            // list: props?.permission == 'view_indent' ? [ETaction.onView] : [ETaction.onEdit]
            list: [ETaction.onView]
        },
    ])
    const [updateColumns, setUpdateColumns] = useState([])
    const [itemHis, setItemHis] = useState([]);
    const [indentItemList, setIndentItemList] = useState(
        {
            "indentMasterID": "",
            "ProjectID": null,
            "statusID": null,
            "UpdatedBy": null,
            "CreatedBy": null,
            "quoteUrl": "",
            "CreateDate": "",
            "UpdatedDate": null,
            "projectName": "",
            "CreatedByName": "",
            "indentItem": [],
            "Approvals": [],
        }
    );
    const [timeHistroy, setTimeHistroy] = useState({
        totalAge: "",
        data: []
    })
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const actions = {
        onView: (index, row) => {
            setUpdateColumns([...viewIndent])
            setIndentItemList({ ...row })
            handleIndentViewClose()
            history(row);
            getIndentApprovalListwithAge(row)
        },
    }
    const [indentList, setIndentList] = useState([]);
    const [isColour, setIsColour] = useState(false);

    useEffect(() => {
        getUserIndentLists()
    }, [projectDetails?.projectID])

    const getUserIndentLists = () => {
        // setIsLoading(true);
        APIKit.get(RGCA_URLS.getUserIndentListforDashBoard + '/' + projectDetails.projectID)
            .then((res) => {
                // setIsLoading(false);
                if (res.data.status === 200) {
                    if (res?.data?.data?.length > 0) {
                        let data = res.data.data.map(x => {
                            let indentItemData = x.indentItem && parseData(x.indentItem).map(item => {
                                item['totalEstimatedPrice'] = item?.Qty * item?.estimatedPrice;
                                return item
                            });
                            return {
                                ...x,
                                Approvals: parseData(x.Approvals),
                                indentItem: indentItemData
                            }
                        })
                        setIndentList(data);
                        setIsColour(true)
                    } else {
                        setIsColour(false);
                        setIndentList([])
                    }
                }
                else {

                }
            })
            .catch(function (e) {
            })
    }
    const handleIndentViewClose = () => {
        // if (indentView) { setStatusEdit(false) }
        setIndentView(!indentView);
        getUserIndentLists();
    }
    const history = (data) => {
        const payload = {
            indentMasterID: data?.indentMasterID,
            projectID: projectDetails.projectID
        }
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getIndentHistory, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    console.log('IndentHistory', res.data);
                    setItemHis(res.data.data);
                    //   setIsLoading(false);

                } else {
                    //   setIsLoading(false);
                }
            })
            .catch((e) => {
                // setIsLoading(false);
            });
    }
    const htmlContentRef = useRef(null);

    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });

    const totalQty = (data = []) => {
        let test = 0
        data.map((x) => {
            test = test + x.Qty
        })
        return test;
        //  data['totalQty'] = test;
    }

    const getIndentApprovalListwithAge = (Data) => {
        console.log("fffffff", Data);

        // setIsLoading(true);
        let payload = {
            projectID: Data?.ProjectID,
            indentMasterID: Data?.indentMasterID,
        };
        APIKit.post(RGCA_URLS.getIndentApprovalListwithAge, payload)
            .then((res) => {
                // setIsLoading(false);
                if (res.data.status === 200) {
                    console.log("res.data", res.data);
                    setTimeHistroy(res?.data?.data)
                }
            })
            .catch(function (e) { });
    };

    const accordionStyle = {
        borderRadius: "12px",
        boxShadow: `${isColour ? "0 0 10px #46cc48" : "0 0 10px #3979c3"}`
    };

    return (
        <>
            <Accordion style={{
                borderRadius: "12px",
                boxShadow: `${isColour ? "0 0 10px #46cc48" : "0 0 10px #3979c3"}`
            }} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.secondaryHeading}>
                        <b>My Indent (All Projects)</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} className={classes.field}  >
                        <Grid item lg={12} sm={12} xl={12} xs={12} >
                            <div style={{ textAlign: "center" }}>
                                <CommonTable columns={columns} data={indentList} action={actions} />
                            </div>
                        </Grid>
                    </Grid>
                    <Dialog aria-labelledby="customized-dialog-title" open={indentView}
                        contentStyle={{ width: '200%', maxWidth: 'none', }}
                        maxWidth="lg"
                        fullWidth
                    >
                        <DialogTitle id="customized-dialog-title" onClose={handleIndentViewClose} style={{ backgroundColor: "#394671", color: "#fff", width: "900px !important" }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <h2>Indent Items</h2>
                                <h3 onClick={() => {
                                    setIndentView(false)
                                }} style={{ cursor: "pointer" }}>
                                    x
                                </h3>
                            </div>
                        </DialogTitle>
                        <Grid ref={htmlContentRef} id="myHtmlContent" >
                            <DialogContent dividers style={{ width: "900px !important" }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} >
                                        <div style={{ textAlign: "center" }}>
                                            <Typography>
                                                <h5 style={{ color: '#394671' }}>Indent ID : {indentItemList.indentMasterID}</h5>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} >
                                        <div style={{ textAlign: "center" }}>
                                            <Typography>
                                                <h5 style={{ color: '#394671' }}>Total Quantity: {totalQty(indentItemList.indentItem || [])}</h5>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} >
                                        <div style={{ textAlign: "center" }}>
                                            <Typography>
                                                <h5 style={{ color: '#394671' }}>Date : {dateFormate(indentItemList.CreateDate)}</h5>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} >
                                        <div style={{ textAlign: "center" }}>
                                            <Typography>
                                                <h5 style={{ color: '#394671' }}>Total Estimated Price : {(indentItemList.indentItem ? indentItemList.indentItem.reduce((a, b) => a + (b.estimatedPrice * b.Qty), 0) : 0).toLocaleString('en-IN')}</h5>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3}>
                                        <div style={{ textAlign: "center" }}>
                                            <Typography>
                                                <h5 style={{ color: '#394671' }}>Time Taken to Approval: {timeHistroy?.totalAge}</h5>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    {/* ---------------------------------------------------------------------------------------- */}
                                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                                        <div style={{ textAlign: "center" }}>
                                            <CommonTable1 columns={updateColumns} data={indentItemList.indentItem}
                                                editLock={indentItemList.Approvals}
                                                isprint={print}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} sm={12} xl={12} xs={12} >
                                        <StepperBox steps={indentItemList.Approvals} action={actions} />
                                    </Grid>
                                </Grid>


                                {/*  */}

                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography className={classes.secondaryHeading}>
                                                        <b>Indent Approved Time History</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table
                                                            className={classes.table}
                                                            aria-label="simple table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Indent Id</TableCell>
                                                                    <TableCell>Request By</TableCell>
                                                                    <TableCell>Request Date</TableCell>
                                                                    <TableCell>Approval By</TableCell>
                                                                    <TableCell>Approval Date</TableCell>
                                                                    <TableCell>Time Taken to Approval</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {timeHistroy?.data && timeHistroy?.data?.length != 0 ? (
                                                                    timeHistroy?.data.slice(
                                                                        page * rowsPerPage,
                                                                        page * rowsPerPage + rowsPerPage
                                                                    ).map((x, index) => (
                                                                        <TableRow>
                                                                            <TableCell> {x?.indentMasterID}</TableCell>
                                                                            <TableCell>{x?.empLoginID}</TableCell>
                                                                            <TableCell>{moment(x?.CreateDate).format(
                                                                                "DD/MM/yyyy"
                                                                            )}</TableCell>
                                                                            <TableCell>{x?.roleName}</TableCell>
                                                                            <TableCell>
                                                                                {moment(x?.UpdatedDate).format(
                                                                                    "DD/MM/yyyy"
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>{x?.hoursDifference}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                ) : (
                                                                    <TableRow hover role="checkbox" key={1}>
                                                                        <TableCell colSpan={6} align="center" key={2}>
                                                                            {"No Data Found"}
                                                                        </TableCell>{" "}
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12}>
                                            <Accordion >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header"  >
                                                    <Typography className={classes.secondaryHeading}>Indent Partial-Edit-Registry-Approval-Item-History</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table className={classes.table} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Item Name</TableCell>
                                                                    <TableCell>Requested Quantity</TableCell>
                                                                    <TableCell>Approval Quantity</TableCell>
                                                                    <TableCell>Updated By</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {itemHis && itemHis.length != 0 ? itemHis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                                                    <TableRow >
                                                                        <TableCell>{item.itemName}</TableCell>
                                                                        <TableCell>{item.requestQty}</TableCell>
                                                                        <TableCell>{item.newValue}</TableCell>
                                                                        <TableCell>{item.employeeName}</TableCell>
                                                                    </TableRow>
                                                                )) : <TableRow hover role="checkbox" key={1}>
                                                                    <TableCell colSpan={4} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </DialogContent>
                        </Grid>
                        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                            {statusEdit ? (
                                <Button
                                    style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                                    onClick={(e) => {
                                        // updateIndentItems()
                                    }}
                                    disabled={indentItemList?.Approvals?.some(e => e.status != "Awaiting") && indentItemList?.Approvals?.every(e => e.status != "Modification")}
                                >
                                    Update
                                </Button>
                            )
                                :
                                <Button
                                    style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                                    // onClick={ printOrder}
                                    onClick={() => {
                                        setprint(true);
                                        setTimeout(() => {
                                            printOrder()
                                        }, 2000)

                                    }}

                                // disabled={indentItemList?.Approvals?.some(e => e.status != "Awaiting") && indentItemList?.Approvals?.every(e => e.status != "Modification")}
                                >
                                    Print
                                </Button>
                            }
                            <Button
                                style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                                onClick={() => { setprint(false); handleIndentViewClose() }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default RisedIndent