import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Grid
} from '@material-ui/core';
import Logo from 'src/components/Logo';
const useStyles = makeStyles((theme)=>({
  root: {},
  toolbar: {
    // height: 64
    fontSize: '12px',
    Width: '500px',
    msOverflowX: 'block'
  },
  image: {
    [theme.breakpoints.down('xl')]: {
    width: '6%', // Default width for non-mobile devices
    borderRadius:'50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: 50, // Custom width for mobile devices (adjust as needed)
      borderRadius:50,
    },
    [theme.breakpoints.down('md')]: {
      width: 50, // Custom width for mobile devices (adjust as needed)
      borderRadius:50,
    },
  },
  txt:{
    [theme.breakpoints.down('xl')]: {
      fontSize:"24px",
      marginLeft:"-33%"
      },
      [theme.breakpoints.down('md')]: {
        fontSize:"20px",
        marginLeft:"100px",
        textAlign:"center"
      },
      [theme.breakpoints.down('xs')]: {
        fontSize:"20px",
        margin:"0px",
        textAlign:"center"
      },
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Grid container item>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Toolbar className={classes.toolbar}>
            <RouterLink to="/">
              {/* <Logo /> */}
              <img
                alt="Logo"
                // style={{width:60,borderRadius:30}}
                src="/static/logo-rgca.jpg"
                
                className={classes.image}
              />
            </RouterLink>
            {/* <h3 style={{"position": "absolute","left": "48%" }}>RGCA</h3> */}
            <h2 className={classes.txt}>Rajiv Gandhi Centre for Aquaculture</h2>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
