import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem, FormHelperText,
  Tooltip,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIKit from "../../../utils/APIKit"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from "material-ui-confirm";
import { RGCA_URLS } from 'src/utils/url';
import { useToasts } from 'react-toast-notifications';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import Address from 'src/views/Project/Common/Address';
import Loader from '../../loader';
import { useParams } from "react-router-dom";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import AddressSingleLine from '../Common/Address_singleline';
import { isFileSizeAllowed, isFileAllowed, validateEmail } from './../Common/fileUpload';
import * as XLSX from 'xlsx';
import StatesAndDis from "./states.json"


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px', marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : ""}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



const Customer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const classes = useStyles();
  const { project } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const projectDetails = { ...getProjectDetails(project) }
  const confirm = useConfirm();
  const [alertopen, setAlertOpen] = React.useState(false);
  const [openTest, setOpenTest] = React.useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [bulkOpen, setBulkOpen] = useState(false);
  const [bulk, setBulk] = useState('');
  const [bulkFIle, setBulkFile] = useState('');
  const [customer, setcustomer] = useState(
    {
      projectID: '1',
      name: '',
      companyType: '',
      companyName: '',
      gstn: '',
      gstnAttach: '',
      panAttach: '',
      pan: '',
      aadharAttach: '',
      aadhar: '',
      email: '',
      mobile: '',
      // customerID: "",
      address: {
        line_1: '',
        line_2: '',
        line_3: '',
        district: '',
        state: '',
        country: 'India',
        postalCode: ''
      },
    }

  )
  const [AllCustomer, setAllCustomer] = useState([])
  const [districts, setDistricts] = useState([]);

  const [btn, setBtn] = useState(false)
  const { state } = useLocation();
  const navigate = useNavigate();
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };
  const handleCloseModal = (resetForm = "") => {
    setOpenTest(false);
    if (resetForm) {
      resetForm();
    }
  };

  useEffect(() => {
    getcustomer()
    getLookup()
  }, [navigate]);
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/4`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return
    };
    if (event.target.files[0] !== undefined) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('files', event.target.files[0], event.target.files[0].name);
      if (type == 'aadhar') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              // setAadharAttach(res.data.data);
              setcustomer({
                ...customer,
                aadharAttach: res.data.data,
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {

          });
      }
      if (type == 'gstn') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              // setAppointmentAttach(res.data.data);
              setcustomer({
                ...customer,
                gstnAttach: res.data.data,
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {

          });
      }
      if (type == 'pan') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })              // setAppointmentAttach(res.data.data);
              setcustomer({
                ...customer,
                panAttach: res.data.data,
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {

          });
      }
      if (type == 'cheque') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })              // setAppointmentAttach(res.data.data);
              let bankDetails = { ...customer.bankDetails }
              bankDetails.cancelChequeAttach = res.data.data
              setcustomer({
                ...customer,
                bankDetails: { ...bankDetails }
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {

          });
      }
    }
  };


  const updatecustomer = () => {
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateCustomer, customer)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          getcustomer()
          // setAlertMeg('success');
          // setErrorMeg(MESSAGE.customerUpdated);
          toast.success(MESSAGE.customerUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // setAlertOpen(true);
          setcustomer({
            name: '',
            companyType: '',
            companyName: '',
            gstn: '',
            gstnAttach: '',
            panAttach: '',
            pan: '',
            aadharAttach: '',
            aadhar: '',
            email: '',
            mobile: '',
            // customerID: "",
            address: {
              line_1: '',
              line_2: '',
              district: '',
              state: '',
              country: 'India',
              postalCode: ''
            },
          })
          handleCloseModal()

        }
        else {
          setIsLoading(false);
          // setAlertMeg('error');
          // setErrorMeg(res.data.message);
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // setAlertOpen(true);
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      })
  }

  const getcustomer = () => {
    APIKit.post(RGCA_URLS.listCustomer,
      {
        "projectID": projectDetails.projectID
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAllCustomer(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const removecustomer = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        remove(data)
      })
      .catch(() => console.log("Deletion cancelled."));

  }

  const searchcustomer = (e) => {
    if (e) {
      APIKit.post(RGCA_URLS.listCustomer, {
        "projectID": projectDetails.projectID,
        "searchText": e
      })
        .then((res) => {
          if (res.data.status === 200) {
            setAllCustomer(res.data.data)
          }
          else {

          }
        })
        .catch(function (e) {
        })
    } else {
      getcustomer();
    }
  }

  const editcustomer = (data) => {
    let address = JSON.parse(data.address)
    setcustomer(
      {
        name: data.name,
        customerID: data.customerID,
        companyType: data.companyType,
        companyName: data.companyName,
        gstn: data.gstn,
        gstnAttach: data.gstnAttach,
        panAttach: data.panAttach,
        pan: data.pan,
        aadharAttach: data.aadharAttach,
        aadhar: data.aadhar,
        email: data.email,
        mobile: data.mobile,
        address: {
          line_1: address[0].line_1,
          line_2: address[0].line_2,
          line_3: address[0].line_3,
          district: address[0].district,
          state: address[0].state,
          country: address[0].country,
          postalCode: address[0].postalCode,
        },
      });
      const stateData = StatesAndDis.find((s) => s.state === address[0]?.state);
      setDistricts(stateData ? stateData.districts : []);

    // setcustomer(data)
    setBtn(true)
    setOpenTest(true);
  }

  const remove = (data) => {
    setIsLoading(true);
    APIKit.delete(RGCA_URLS.deleteCustomer + '/' + data.customerID)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          // setAlertMeg('success');
          // setErrorMeg(res.data.message);
          toast.success(MESSAGE.customerDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          //setAlertOpen(true);
          getcustomer()
        }
        else {
          setIsLoading(false);
          // setAlertMeg('error');
          // setErrorMeg(res.data.message);
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // setAlertOpen(true);
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      })
  }

  const openAddcustomer = () => {
    setcustomer({
      name: '',
      companyType: '',
      companyName: '',
      gstn: '',
      gstnAttach: '',
      panAttach: '',
      pan: '',
      aadharAttach: '',
      aadhar: '',
      email: '',
      mobile: '',
      // customerID: "",
      address: {
        line_1: '',
        line_2: '',
        line_3: '',
        district: '',
        state: '',
        country: 'India',
        postalCode: ''
      },
    })
    setBtn(false)
    setOpenTest(false);
    setOpenTest(true);
  }

  const bulkUpload = () => {
    setBulk('')
    setBulkOpen(!bulkOpen)
  }
  function checkForDuplicates(array) {
    let valuesAlreadySeen = []

    for (let i = 0; i < array.length; i++) {
      let value = array[i]
      if (valuesAlreadySeen.indexOf(value.email) !== -1) {
        return true
      }
      valuesAlreadySeen.push(value.email)
    }
    return false
  }
  const handleUpload = async (e) => {
    if (!isFileAllowed(e.target.files[0]?.name, 'bckUpload')) {
      toast.error(MESSAGE.fileFormat, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return;
    }
    if (!isFileSizeAllowed(e.target.files[0]?.size)) {
      toast.error(MESSAGE.fileSze, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return;
    }

    let arrayBuffer;
    let parseExcelData;
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      parseExcelData = await XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
      });
    };
    setTimeout(() => {
      if (parseExcelData?.length > 0) {
        let errorCount = 0;
        let errorNameCount = 0;
        parseExcelData.forEach((data) => {
          if (!validateEmail(data?.email)) {
            errorCount++;
          }
          if (!data?.name || data?.name === '') {
            errorNameCount++;
          }
        });
        let temp = checkForDuplicates(parseExcelData);
        if (temp) {
          toast.error('No Duplicate mail ID allowed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          return
        }
        if (errorCount > 0) {
          toast.error(errorCount + ' ' + MESSAGE.wrong_data_entered, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          return;
        }

        if (errorNameCount > 0) {
          toast.error(errorNameCount + ' ' + MESSAGE.wrong_name_data_entered, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          return;
        }
        setBulk(file.name)
        setBulkFile(file)
      } else {
        toast.error(MESSAGE.file_empty, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        return;
      }
    }, 1000);

  }
  const bulUploadDatas = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', bulkFIle);
    formData.append('projectID', projectDetails.projectID);
    APIKit.post(RGCA_URLS.bulkUploadcustomer, formData)
      .then((res) => {
        if (res.data.status == 200) {
          setIsLoading(false);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // setAppointmentAttach(res.data.data);
          getcustomer()
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {

      });
  }
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })
  return (
    <Page
      className={classes.root}
      title="customers">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Container maxWidth={false}>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
          <Alert onClose={handleAlertClose} severity={alertMeg} >
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                Customer / Former / Buyer
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} >
                {/* <Button
                mb={2}
                  color="primary"
                  variant="contained" onClick={bulkUpload}>Bulk Upload</Button> */}
                &nbsp;
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openAddcustomer}
                >
                  Create Customer / Former / Buyer
                </Button>
              </Grid>
            </Grid>

          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      if (e.target.value.match(regEx.noSpecialChar)) {
                        searchcustomer(e.target.value)
                      }
                      else {
                      }
                    }}
                    placeholder="Search for Customer / Former / Buyer Name"
                    variant="outlined"
                    onInput={(e) => {
                      e.target.value = (0, (e.target.value)).toString().slice(0, 20)
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>


                  <TableCell>
                    S.No
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Mobile Number
                  </TableCell>
                  <TableCell style={{ width: "40px" }}>
                    Address
                  </TableCell>
                  <TableCell>
                    GST Number
                  </TableCell>
                  <TableCell>
                    PAN Card
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AllCustomer.length > 0 && AllCustomer ? AllCustomer.map((customer, index) => (
                  <TableRow
                    hover
                  // key={emplyoee.id}
                  // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}

                  >


                    <TableCell>
                      {((page) * 10) + index + 1}
                    </TableCell>
                    <TableCell>
                      {customer.name}
                    </TableCell>
                    <TableCell>
                      {customer.email}
                    </TableCell>
                    <TableCell>
                      {customer.mobile}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title={
                        <Address details={customer.address} />
                      }>
                        <span>
                          <AddressSingleLine details={customer.address} />
                        </span>
                      </Tooltip>

                      {/* {row.address?JSON.parse(row.address)[0]:"-"} */}
                    </TableCell>
                    <TableCell>
                      {customer.gstn}
                    </TableCell>
                    <TableCell>
                      {customer.pan}
                    </TableCell>

                    <TableCell>
                      <EditOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          editcustomer(customer)
                          // navigate('/app/AddEmplyoee', { state: emplyoee })
                        }}
                      />
                      <DeleteForeverIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          removecustomer(customer)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  // component="div"
                  count={AllCustomer.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </Container>
      <Formik
        enableReinitialize={true}
        initialValues={
          {
            ...customer,
          }
        }
        onSubmit={(values, { resetForm }) => {
          const payload = {
            ...customer,
            projectID: projectDetails.projectID
          }

          setIsLoading(true)
          APIKit.post(RGCA_URLS.createCustomer, payload)
            .then((res) => {
              if (res.data.status === 200) {
                setIsLoading(false)
                getcustomer();
                toast.success(MESSAGE.customerAdded, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
                handleCloseModal();
                resetForm(values);
              } if (res.data.status == 400) {
                setIsLoading(false)
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
              else {
                setIsLoading(false)
                //addToast(res.data.error, { appearance: 'error' });
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            })
            .catch((e) => {
              setIsLoading(false)
            });
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
            companyType: Yup.string().required('CompanyType is required'),
            gstn: Yup.string().min(15, 'Must be 15 characters'),
            pan: Yup.string().min(10, 'Must be 10 characters').required(MESSAGE.panCardNumber),
            aadhar: Yup.string().min(12, 'Must be 12 characters').required(MESSAGE.aadhar),
            email: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email),
            mobile: Yup.string().min(10, 'Must be 10 characters').required(MESSAGE.mobile),
            address: Yup.object().shape({
              line_1: Yup.string().required(MESSAGE.address1).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              line_2: Yup.string().matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              district: Yup.string().required(MESSAGE.district).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              state: Yup.string().required(MESSAGE.state).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              country: Yup.string().required(MESSAGE.country).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              postalCode: Yup.string().min(6, 'Must be 6 characters').required(MESSAGE.pincode),
            }),
          })
        }
      >


        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (

          <Dialog aria-labelledby="customized-dialog-title" open={openTest} id="income"

            contentStyle={{
              width: '200%',
              maxWidth: 'none',
            }}
            maxWidth="lg" fullWidth>
            <Loader isLoading={isLoading} />
            <DialogTitle id="customized-dialog-title"
              onClose={(e => {
                handleCloseModal(resetForm)
              })} style={{ backgroundColor: "#394671", color: "#fff" }}>
              Customer / Former / Buyer Management
            </DialogTitle>
            <MuiThemeProvider theme={formLabelsTheme}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <DialogContent dividers>
                  <Box mt={3} style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}>
                    <Grid
                      container
                      spacing={3}
                    >

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          inputProps={{ maxLength: 20 }}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                              handleChange(e);
                              const p = customer;
                              p.name = e.target.value;
                              values.name = e.target.value;
                              setcustomer({
                                ...p,
                                name: e.target.value
                              })
                            }
                          }}
                          required
                          value={customer.name}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(touched.companyType && errors.companyType)}
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">Type *</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={customer.companyType}
                            name="companyType"
                            label="Location"
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => {
                              handleChange(e);
                              const p = customer;
                              p.companyType = e.target.value;
                              // values.companyType = e.target.value;
                              setcustomer({
                                ...p,
                                companyType: e.target.value
                              });
                            }}
                            onBlur={handleBlur}
                          >
                            {lookupData && lookupData.map((x) => {
                              return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                            })}

                          </Select>
                          <FormHelperText>{(errors.companyType && touched.companyType) && errors.companyType}</FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="companyName"
                          inputProps={{ maxLength: 20 }}
                          error={Boolean(touched.companyName && errors.companyName)}
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                              const p = customer;
                              p.companyName = e.target.value.match(regEx.textOnly);
                              values.companyName = e.target.value.match(regEx.textOnly);
                              setcustomer({
                                ...p,
                                companyName: e.target.value
                              });
                            }
                          }}
                          value={customer.companyName}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="GSTN"
                          name="gstn"
                          inputProps={{
                            maxLength: 15
                          }}
                          error={Boolean(touched.gstn && errors.gstn)}
                          helperText={touched.gstn && errors.gstn}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.noSpecialChar.test(e.target.value)) {
                              handleChange(e);
                              const p = customer;
                              p.gstn = e.target.value;
                              values.gstn = e.target.value;
                              setcustomer({
                                ...p,
                                gstn: e.target.value
                              });
                            }
                          }}
                          value={customer.gstn}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <Button style={{ width: "100%", height: "68%" }}
                          variant="contained"
                          component="label"
                        >
                          GSTN Attach Certificate
                          <input
                            type="file"
                            onChange={(e) => handleUploadClick(e, 'gstn')}
                            hidden
                          />
                        </Button>
                        {customer.gstnAttach && (<a style={{ color: 'blue', cursor: 'pointer' }}
                          href={customer.gstnAttach} target="_blank"
                        >{customer.gstnAttach.substring(4, 25)}
                        </a>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.pan && errors.pan)}
                          helperText={touched.pan && errors.pan}
                          label="PAN"
                          name="pan"
                          inputProps={{
                            maxLength: 10
                          }}
                          required
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.noSpecialChar.test(e.target.value)) {
                              handleChange(e);
                              const p = customer;
                              p.pan = e.target.value;
                              values.pan = e.target.value;
                              setcustomer({
                                ...p,
                                pan: e.target.value
                              });
                            }

                          }}
                          value={customer.pan}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <Button style={{ width: "100%", height: "68%" }}
                          variant="contained"
                          component="label"
                        >
                          PAN Attach
                          <input
                            type="file"
                            onChange={(e) => handleUploadClick(e, 'pan')}
                            hidden
                          />
                        </Button>
                        {customer.panAttach && (<a style={{ color: 'blue', cursor: 'pointer' }}
                          href={customer.panAttach} target="_blank"
                        >{customer.panAttach.substring(4, 25)}</a>)}
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.aadhar && errors.aadhar)}
                          helperText={touched.aadhar && errors.aadhar}
                          label="Aadhar Number"
                          name="aadhar"

                          required
                          inputProps={{
                            maxLength: 12
                          }}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                              handleChange(e);
                              const p = customer;
                              p.aadhar = e.target.value;
                              values.aadhar = e.target.value;
                              setcustomer({
                                ...p,
                                aadhar: e.target.value
                              });
                            }
                          }}
                          value={customer.aadhar}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <Button style={{ width: "100%", height: "68%" }}
                          variant="contained"
                          component="label"
                        >
                          Aadhar Attach
                          <input
                            type="file"
                            onChange={(e) => handleUploadClick(e, 'aadhar')}
                            hidden
                          />
                        </Button>
                        {customer.aadharAttach && (<a style={{ color: 'blue', cursor: 'pointer' }}
                          href={customer.aadharAttach} target="_blank"
                        >{customer.aadharAttach.substring(4, 25)}</a>)}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          inputProps={{ maxLength: 30 }}

                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const p = customer;
                            p.email = e.target.value;
                            values.email = e.target.value;
                            setcustomer({
                              ...p,
                              email: e.target.value
                            });
                          }}
                          required
                          value={customer.email}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.mobile && errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          label="Mobile Number"
                          name="mobile"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                              handleChange(e);
                              const p = customer;
                              p.mobile = e.target.value;
                              values.mobile = e.target.value;
                              setcustomer({
                                ...p,
                                mobile: e.target.value
                              });
                            }
                          }}
                          required
                          inputProps={{
                            maxLength: 10
                          }}
                          value={customer.mobile}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.address?.line_1 && errors.address?.line_1)}
                          helperText={touched.address?.line_1 && errors.address?.line_1}
                          label="Address Line1"
                          name="address.line_1"
                          inputProps={{ maxLength: 30 }}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            let address = { ...customer.address };
                            address.line_1 = e.target.value;
                            values.address.line_1 = e.target.value;
                            setcustomer({
                              ...customer,
                              address: { ...address }
                              //  line_1: e.target.value
                            });
                          }}

                          required
                          value={customer?.address?.line_1}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Address Line2"
                          inputProps={{ maxLength: 30 }}
                          name="line_2"
                          onChange={(e) => {
                            let address = { ...customer.address };
                            address.line_2 = e.target.value;
                            values.address.line_2 = e.target.value;
                            setcustomer({
                              ...customer,
                              address: { ...address }
                            });
                          }}
                          value={customer?.address?.line_2}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.address?.line_3 && errors.address?.line_3)}
                          helperText={touched.address?.line_3 && errors.address?.line_3}
                          label="Address Line3"
                          name="line_3"
                          inputProps={{ maxLength: 30 }}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            { console.log() }

                            let address = { ...customer.address };
                            address.line_3 = e.target.value;
                            values.address.line_3 = e.target.value;
                            setcustomer({
                              ...customer,
                              address: { ...address }

                            });
                          }}
                          value={customer?.address?.line_3}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item lg={4} sm={6} xl={6} xs={6}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(touched.address?.state && errors.address?.state)}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            required
                          >
                            State
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="State"
                            name="address.state"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              let address = { ...customer.address };
                              address.state = e.target.value;
                              values.address.state = e.target.value;
                              setcustomer({
                                ...customer,
                                address: { ...address }
                              });
                              const stateData = StatesAndDis.find((s) => s.state === e.target.value);
                              console.log("lllll", stateData);
                              setDistricts(stateData ? stateData.districts : []);
                            }}
                            required
                            value={customer?.address?.state}
                          >
                            {StatesAndDis &&
                              StatesAndDis.map(x => {
                                return (
                                  <MenuItem value={x.state} key={x.state}>
                                    {x.state}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.address?.state &&
                              touched.address?.state &&
                              errors.address?.state}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item lg={4} sm={6} xl={6} xs={6}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(touched.address?.district && errors.address?.district)}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            required
                          >
                            District
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="District"
                            name="address.district"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              let address = { ...customer.address };
                              address.district = e.target.value;
                              values.address.district = e.target.value;
                              setcustomer({
                                ...customer,
                                address: { ...address }
                              });
                            }}
                            required
                            value={customer?.address?.district}
                          >
                            {districts &&
                              districts.map(x => {
                                return (
                                  <MenuItem value={x} key={x}>
                                    {x}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.address?.district &&
                              touched.address?.district &&
                              errors.address?.district}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {/* <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.address?.district && errors.address?.district)}
                          helperText={touched.address?.district && errors.address?.district}
                          label="District"
                          inputProps={{ maxLength: 20 }}
                          name="address.district"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.match(regEx.noSpecialChar)) {

                              if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                handleChange(e);
                                let address = { ...customer.address };
                                address.district = e.target.value;
                                values.address.district = e.target.value;
                                setcustomer({
                                  ...customer,
                                  address: { ...address }

                                });
                              }
                            }
                          }}
                          required
                          value={customer?.address?.district}
                          variant="outlined"
                        />
                      </Grid> */}

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Pin code"
                          name="address.postalCode"
                          onBlur={handleBlur}
                          error={Boolean(touched.address?.postalCode && errors.address?.postalCode)}
                          helperText={touched.address?.postalCode && errors.address?.postalCode}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                              handleChange(e);
                              let address = { ...customer.address };
                              address.postalCode = e.target.value;
                              values.address.postalCode = e.target.value.match(regEx.numbersOnly);
                              setcustomer({
                                ...customer,
                                address: { ...address }

                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 6
                          }}
                          required
                          value={customer?.address?.postalCode}
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          error={Boolean(touched.address?.state && errors.address?.state)}
                          helperText={touched.address?.state && errors.address?.state}
                          label="State"
                          inputProps={{ maxLength: 25 }}
                          name="address.state"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.match(regEx.noSpecialChar)) {

                              if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                handleChange(e);
                                let address = { ...customer.address };
                                address.state = e.target.value;
                                values.address.state = e.target.value.match(regEx.textOnly);
                                setcustomer({
                                  ...customer,
                                  address: { ...address }
                                });
                              }
                            }
                          }}
                          required
                          value={customer?.address?.state}
                          variant="outlined"
                        />
                      </Grid> */}




                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 20 }}
                          error={Boolean(touched.address?.country && errors.address?.country)}
                          helperText={touched.address?.country && errors.address?.country}
                          label="Country"
                          name="address.country"
                          onBlur={handleBlur}
                          disabled
                          onChange={(e) => {
                            if (e.target.value.match(regEx.noSpecialChar)) {

                              if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                handleChange(e);
                                let address = { ...customer.address };
                                address.country = e.target.value;
                                values.address.country = e.target.value.match(regEx.textOnly);
                                setcustomer({
                                  ...customer,
                                  address: { ...address }

                                });
                              }
                            }
                          }}
                          required
                          value={customer?.address?.country}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                      </Grid>

                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

                  <Button onClick={(e) => {
                    handleCloseModal(resetForm)
                  }} className={classes.button2}>
                    Cancel
                  </Button>
                  {btn == false ?
                    <Button type="submit" className={classes.button2}>
                      Save
                    </Button>
                    : <Button onClick={updatecustomer} className={classes.button2}>
                      Update
                    </Button>}

                </DialogActions>
              </Form>
            </MuiThemeProvider>

          </Dialog>
        )}
      </Formik>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={bulkOpen}
        id="income"
        contentStyle={{
          width: '50%',
          maxWidth: 'none',
        }}
        maxWidth="sm"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle id="scroll-dialog-title" onClose={(e => {
          setBulkOpen(!bulkOpen)
        })} style={{ backgroundColor: '#394671', color: '#fff' }}>
          Upload File
        </DialogTitle>
        <DialogContent >
          <Grid
            item
            md={12}
            xs={12}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ width: '50%', height: '80%' }}
                variant="contained"
                component="label"
              >
                Bulk Upload
                <input
                  type="file"
                  onChange={(e) => handleUpload(e)}
                  hidden
                />
              </Button>
              <br />
              {bulk && (<><a style={{ color: 'blue' }}

              >{bulk.substring(0, 25)}....{bulk.substring(bulk.lastIndexOf('.') + 1)}</a> </>)}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>

          <Button onClick={(e) => {
            setBulkOpen(!bulkOpen)
          }} style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}>
            Cancel
          </Button>

          <Button onClick={bulUploadDatas} style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}>
            Add
          </Button>




        </DialogActions>
      </Dialog>

    </Page>
  )

}
export default Customer;
