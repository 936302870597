import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Container,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
// import data from './data';
import Page from 'src/components/Page';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },

}));




const AddMaterial = () => {
  const classes = useStyles();
  const [location, setLocation] = React.useState('');





  return (
    <Page
      className={classes.root}
      title="Depositors">
      <Container maxWidth={false}>
        <div
          className={clsx(classes.root)}

        >
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Button
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          </Box>
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}>
            <Grid
              container
              spacing={3}
            >

              <Grid
                item
                md={4}
                xs={12}
              >

                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value)
                    }}
                    label="Location"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>123</MenuItem>
                    <MenuItem value={20}>134</MenuItem>
                    <MenuItem value={30}>194</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="Material"
                  name="material"
                  onChange=""
                  required
                  value=""
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="brand"
                  name="Brand"
                  onChange=""
                  required
                  value=""
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="Price"
                  name="price"
                  onChange=""
                  required
                  value=""
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={8}
                xs={12}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >

                  <Button
                    color="primary"
                    variant="contained" style={{ marginTop: '20px' }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
        <Box mt={3}>

        </Box>

        {/* dialog */}




      </Container>
    </Page>
  )

}
export default AddMaterial;
