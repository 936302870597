import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { APP_URLS } from '../../utils/apis';
import { RGCA_URLS } from '../../utils/url'
import { setClientToken } from '../../utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from '../../utils/message';
import APIKit from 'src/utils/APIKit';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { VisibilityOff } from '@material-ui/icons';

const CryptoJS = require('crypto-js');


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [payload, setPayload] = useState({
    // employeeCode: "",
    empLoginID:"",
    password: "",

  });
  const getAllProjects = async (data) => {
    await APIKit.get(RGCA_URLS.getEmployeeProjects)
      .then((res) => {
        localStorage.setItem('projectList', JSON.stringify(res.data.data || []))
        let permission = JSON.parse(localStorage.getItem('projectList'))
        if (!data.data.isPasswordChanged) {
          setTimeout(() => {
            navigate('/changePassword');

          }, 100)
          return
        }
        if (data.data.accessRoleID == 11 || data.data.accessRoleID == 1) {
          navigate('/app/maindashboard');
        } else {
          navigate('/app/dashboard/' + permission[0]?.projectName);
        }
      })
      .catch(function (e) {
      })
  }
  //Show & Hide Password
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };
  //end
  return (
    <Page
      className={classes.root}
      id="bgimage"
      title="Login"
    >
      <ToastContainer />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"

      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              // employeeCode: "",
              empLoginID:"",
              password: ''
            }}
            validationSchema={
              Yup.object().shape({
                // employeeCode: Yup.string().trim().required('User ID is Required'),
                empLoginID: Yup.string().trim().required('User ID is Required'),

                password: Yup.string().max(255).required('Password is Required')
              })
            }
            onSubmit={() => {

              let obj = {...payload};
             
                const passphrase = 'Rgca_123@';
                obj.password = CryptoJS.AES.encrypt(payload.password, passphrase).toString();
          
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': "*",

                },
                body: JSON.stringify(obj)
              };

              return fetch(RGCA_URLS.login, requestOptions)
                .then(response => response.json())
                .then(res => {
                  if (res.status == 200) {
                    setClientToken(res.data.token)
                    if (res.data.project == null) {
                      addToast(MESSAGE.notAllocated, { appearance: 'error' });
                      return
                    }

                    localStorage.setItem('userData', JSON.stringify(res.data));

                    if (res.role == 'Employee') {
                      setTimeout(() => {
                        navigate('/app/Projects/' + res.ProjectsAndPermissions[0].ProjectID, { replace: true });
                        addToast(MESSAGE.loginSuccess, { appearance: 'success' });

                      }, 100)

                    } else {
                      setTimeout(() => {

                        getAllProjects(res)
                        // navigate('/app/dashboard', { replace: true });
                        addToast(MESSAGE.loginSuccess, { appearance: 'success' });

                      }, 100)

                    }
                  }
                  else {
                    addToast(res.message, { appearance: 'error' });
                  }

                })
                .catch(error => {
                  console.error('There was an error!', error.status);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: "center" }}
                  >
                    Sign in
                  </Typography>

                </Box>
                <br />

                {/* <TextField
                  error={Boolean(touched.employeeCode && errors.employeeCode)}
                  fullWidth
                  helperText={touched.employeeCode && errors.employeeCode}
                  label="User ID"
                  margin="normal"
                  name="employeeCode"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    let p = payload;
                    p.employeeCode = e.target.value.trim()
                    values.employeeCode = e.target.value.trim()
                    setPayload({
                      ...payload,
                      employeeCode: e.target.value.trim(),

                    })
                  }}
                  type="text"
                  value={values.employeeCode.trim()}
                  variant="outlined"
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                /> */}
                <TextField
                  error={Boolean(touched.empLoginID && errors.empLoginID)}
                  fullWidth
                  helperText={touched.empLoginID && errors.empLoginID}
                  label="User ID"
                  margin="normal"
                  name="empLoginID"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    let p = payload;
                    p.empLoginID = e.target.value.trim()
                    values.empLoginID = e.target.value.trim()
                    setPayload({
                      ...payload,
                      empLoginID: e.target.value.trim(),

                    })
                  }}
                  type="text"
                  value={values.empLoginID.trim()}
                  variant="outlined"
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,50)
                }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    values.password = e.target.value
                    setPayload({
                      ...payload,
                      password: e.target.value,

                    })
                  }}

                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,10)
                }}
                />
                <Box my={2}
                 style={{
                  "width":'100%'
                  // "right": "25%"
                }}
                >
                  <Button
                    color="primary"
                    //  disabled={isSubmitting}

                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      "width":'100%'

                     
                      // "right": "25%"
                    }}
                  >
                    Sign in now
                  </Button>
                </Box>

              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
