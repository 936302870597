import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
// import data from './data';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { getloggedInData } from '../../utils/helpers';
import APIKit from "../../utils/APIKit"

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';


import { APP_URLS } from '../../utils/apis';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getNavigationData } from '../../Redux/Common/action';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }

}));



const Categories = () => {
  const classes = useStyles();
  // const [depositors] = useState(data);
  const [expanded, setExpanded] = React.useState(false);
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    // setDepositors(data);
    getCategories()
  }, [])

  const getCategories = () => {
    APIKit.get('/' + APP_URLS.getCategories,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.status === 200) {
          setCategories(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function () {
      })
  }

  const addSubCategories = (x) => {
    setSubCategories([]);

    APIKit.get('/' + APP_URLS.getIncomeSubCategorydata + x.CategoryId)
      .then((res) => {
        if (res.data.status === 200) {
          setSubCategories(res.data.data)
          let obj = {
            CategoryId: x.CategoryId,
            CategoryName: x.CategoryName,
            SubCategory: res.data.data
          }
          dispatch(getNavigationData(obj));
          navigate('/app/AddCategories', { replace: true })
        }
        else {
        }
      })
      .catch(function () {
      })

  }

  const handleSearch = (e) => {
    APIKit.post('/' + APP_URLS.searchCategories, { Category: e },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          setCategories(res.data.data)
        }
        else {
          getCategories()
        }
      })
      .catch(function () {
      })
  }



  const handleChange1 = (panel, categoryID) => (isExpanded) => {
    getCategoryWiseInvestments(categoryID)
    setExpanded(isExpanded ? panel : false);
  };

  const getCategoryWiseInvestments = async (categoryID) => {
    setSubCategories([]);

    await APIKit.get('/' + APP_URLS.getIncomeSubCategorydata + categoryID)
      .then((res) => {
        if (res.data.status === 200) {
          setSubCategories(res.data.data)
        }
        else {
        }
      })
      .catch(function () {
      })
  }

  const openCategories = () => {
    navigate('/app/AddCategories', { replace: true })
  }


  const [value, setValue] = React.useState('upload');

  return (
    <Page
      className={classes.root}
      title="Categories">
      <Container maxWidth={false}>
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                CATEGORIES
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openCategories}
                >
                  Add Category
                </Button>
              </Grid>
            </Grid>

          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField

                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                    placeholder="Search Categories"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        {categories.map((x, i) => {
          return <Accordion key={i} expanded={expanded === x.CategoryName} onChange={handleChange1(x.CategoryName, x.CategoryId)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div style={{ width: "85%" }}>
                <Typography className={classes.heading}>{x.CategoryName}</Typography>
              </div>
              <div style={{ width: "15%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addSubCategories(x)}
                >
                  Edit Subcategory
                </Button>
              </div>

              {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Is Movable</TableCell>
                      <TableCell style={{ textAlign: 'end' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCategories.length != 0 && subCategories.map((row) => (
                      <TableRow >
                        <TableCell>{row.SubCategoryName}</TableCell>
                        <TableCell>{row.CreateDate}</TableCell>
                        {row.IsMovable == 0 ?
                          <TableCell>No</TableCell>
                          : <TableCell>Yes</TableCell>}
                        <TableCell style={{ textAlign: 'end' }}>
                          {/* <EditIcon onClick={() => handleClickEditOpen(row)} /> */}
                          <DeleteOutlineIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {subCategories.map((x,i)=>{
                           <p>
                           {x.SubCategoryName}
                            </p>
                          })
                          }  */}
            </AccordionDetails>
          </Accordion>
        })
        }
      </Container>
    </Page>
  )

}
export default Categories;
