import React, { useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import 'date-fns';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ClearIcon from '@mui/icons-material/Clear';
import { currencyFormat1 } from '../Common/utilities';



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const RegistrationView = ({ isRegView, setIsRegView, viewRegData }) => {
    const classes = useStyles();


    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
                open={isRegView}
                id="income"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setIsRegView(false)
                    }}
                    style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                    View
                </DialogTitle>
                <DialogContent dividers style={{ overflowX: 'hidden' }}>
                    <Box
                        mt={3}
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                            borderRadius: '5px',
                            padding: '20px'
                        }}
                    >
                        <Grid container spacing={3} style={{ fontSize: '18px' }}>
                            <Grid item lg={3} sm={4} xl={4} xs={12} >
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Program Name : {viewRegData?.NameoftheProgram ? viewRegData?.NameoftheProgram : ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Location : {viewRegData?.Location ? viewRegData?.Location : '-'}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Fee : ₹  {viewRegData?.Fee ? currencyFormat1(Math.round(viewRegData?.Fee)) : ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Full Name : {viewRegData?.TraineeName ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Institutional Affiliation : {viewRegData?.InstitueAffiliation ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Address : {`${viewRegData?.line_1 ? viewRegData.line_1 : ''}${viewRegData?.line_2 ? ', ' + viewRegData.line_2 : ''}
                                        ${viewRegData?.line_3 ? ', ' + viewRegData.line_3 : ''} ${viewRegData?.district ? ', ' + viewRegData.district : ''}
                                        ${viewRegData?.state ? ', ' + viewRegData.state : ''} ${viewRegData?.country ? ', ' + viewRegData.country : ''}`}

                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Phone/Mobile : {viewRegData?.phone ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        E-mail : {viewRegData?.email ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Date of Birth : {viewRegData?.dateOfBirth ? moment(viewRegData?.dateOfBirth).format('DD-MM-yyyy') : ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div>
                                    <h5 style={{ color: '#394671' }}>
                                        Trainee Type : {viewRegData?.tranierDetails ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Higher Qualification : {viewRegData?.HigherQualification ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Subjects : {viewRegData?.Subjects ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Class Rank : {viewRegData?.ClassRank ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Institution : {viewRegData?.Institution ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Year Of Passing : {viewRegData?.YOP ? moment(viewRegData?.YOP).format('DD-MM-yyyy') : ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Mode of payment : {viewRegData?.Modeofpayment ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Bank Name : {viewRegData?.BankName ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Transcation No : {viewRegData?.TranscationNo ?? ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Transcation Date : {viewRegData?.TranscationDate ? moment(viewRegData?.TranscationDate).format('DD-MM-yyyy') : ''}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Recent Photo Attachment : {viewRegData?.ImageAttach && <a href={viewRegData?.ImageAttach}>{viewRegData?.ImageAttach.substring(0, 25)} </a>}
                                    </h5>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={4} xl={4} xs={12}>
                                <div >
                                    <h5 style={{ color: '#394671' }}>
                                        Payment Receipt : {viewRegData?.paymentReceipt && <a href={viewRegData?.paymentReceipt}>{viewRegData?.paymentReceipt.substring(0, 25)} </a>}
                                    </h5>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                    <Button onClick={(e => {
                        setIsRegView(false)
                    })} className={classes.button2}>
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default RegistrationView