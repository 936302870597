import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { styled } from '@mui/material/styles';
import { Search as SearchIcon } from 'react-feather';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { categoryList, subCategoryList, itemsList, itemTypes } from '../data';
import AutoCompleteField from '../AutoCompleteField';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { data } from './data';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'react-toast-notifications';
import './style.css';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { useParams } from 'react-router-dom';
import { getProjectDetails, parseData } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import CommonTable from '../Common/CommonTable';
import { ETTypes } from '../Common/Types';
import { ETaction } from '../Common/Types';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';
import GINForm from './Form';
import GINList from './ginlist';
import { Stack } from '@mui/material';
import { checkPermission } from '../../../utils/permission.jsx';
import ReturnGoods from './returngoods';

//MUI TOGGLE
const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#394671',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%'
    // marginTop:"125px"
  }
}));


const itemInitialValues = {
  items: [
    {
      materialID: null,
      ginQty: null,
      description: '',
      location: '',
      cost: null
    }
  ]
};

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

const GIN = (props) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [value, setValue] = React.useState(0); // first tab
  const [moreView, setMoreView] = React.useState(false);
  const [ginList, setGinList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [form, setForm] = useState([]);
  const [emplyoees, setEmplyoees] = useState([]);
  const [inventoryList, setinventoryList] = useState([]);
  const [showBtn, setShowBtn] = useState(true);
  const [show, setShow] = useState(true);
  const [columns, setColumns] = useState([
    {
      title: 'GIN Master ID',
      field: 'ginMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Project Name',
      field: 'projectName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreatedDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Receiver Name',
      field: 'receiverName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onEdit]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);


  const [itemList, setItemList] = useState([
    {
      materialID: '',
      ginQty: '',
      description: '',
      location: '',
      cost: '',
      available: ''
    }
  ]);

  const [payload, setPayload] = useState({
    projectID: null,
    employeeID: '',
    items: itemList
  });

  const projectData = useSelector(x => x.ProjectInfo);
  const prevIsValid = () => {
    if (form.length === 0) {
      return true;
    }

    const someEmpty = form.some(
      item =>
        item.Material === '' ||
        item.Units === '' ||
        item.Description === '' ||
        item.Location === '' ||
        item.Cost === '' ||
        item.Available === ''
    );

    if (someEmpty) {
      form.map((item, index) => {
        const allPrev = [...form];

        if (form[index].Material === '') {
          allPrev[index].errors.Material = 'Material is required';
        }

        if (form[index].Units === '') {
          allPrev[index].errors.Units = 'Units is required';
        }
        if (form[index].Description === '') {
          allPrev[index].errors.Description = 'Description is required';
        }
        if (form[index].Location === '') {
          allPrev[index].errors.Location = 'Location is required';
        }
        if (form[index].Cost === '') {
          allPrev[index].errors.Cost = 'Cost is required';
        }
        if (form[index].Available === '') {
          allPrev[index].errors.Available = 'Available is required';
        }

        setForm(allPrev);
      });
    }

    return !someEmpty;
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRemoveField = index => {
    let items = itemList;
    items.splice(index, 1);
    setItemList([...items]);
  };

  const handleMoreViewClose = () => {
    setMoreView(!moreView);
  };
  const getAllEmplyoees = () => {
    APIKit.get(RGCA_URLS.getEmployee)
      .then(res => {
        if (res.data.status === 200) {
          setEmplyoees(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };

  React.useEffect(() => {
    getAllEmplyoees();
    getInventoryList();
  }, []);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen.indexOf(value.materialID) !== -1) {
        return true;
      }
      valuesAlreadySeen.push(value.materialID);
    }
    return false;
  }

  const handleSubmit1 = () => {
    let temp = checkForDuplicates(itemList);
    if (temp) {
      toast.error('Materials are same.If you needed increase the quantity', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (itemList.some(e => e.ginQty == 0)) {
      toast.error(MESSAGE.qt0, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    let payload1 = {
      employeeID: payload.employeeID,
      projectID: projectDetails.projectID,
      items: itemList.map(e => {
        return {
          materialID: e.materialID,
          location: e.location,
          ginQty: Number(e.ginQty),
          cost: Number(e.cost),
          description: e.description
        };
      })
    };

    APIKit.post(RGCA_URLS.createGIN, payload1)
      .then(res => {
        if (res.data.status === 200) {
          let temp = [];
          temp.push({
            materialID: '',
            ginQty: '',
            description: '',
            location: '',
            cost: '',
            available: ''
          });
          setItemList([...temp]);
          setPayload([]);
          getInventoryList();
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };
  return (
    <Page className={classes.root} title="GIN">
      <ToastContainer />
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>
            <h2 style={{ color: '#394671' }}>{props.permission == 'view_GIN' ? 'Goods Issued Note' : 'Edit Goods Issued Note'}</h2>
          </Typography>
          {props.permission == 'view&edit_GIN' && <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              className="toggleText"
              style={{ color: '#394671', fontWeight: 'bold' }}
            >
              GIN LIST
            </Typography>
            <FormControlLabel
              onChange={e => {
                setShow(!show);
              }}
              control={<IOSSwitch sx={{ m: 1 }} />}
            />
            <Typography style={{ color: '#394671', fontWeight: 'bold' }}>
              RETURN GOODS
            </Typography>
          </Stack>
}
        </Box>
        {/* {props?.permission = 'view&edit_GIN' && */}
        <>
        {show ? (
          <>
            <GINList {...props}/>
          </>
        ) : (
          <>
            <ReturnGoods />
          </>
        )}
        </>
      </>
      
    </Page>
  );
};

export default GIN;
