import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Checkbox,
  makeStyles,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  ListItemText,
  FormHelperText, Typography
} from '@material-ui/core';
// import data from './data';
import Page from 'src/components/Page';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { createTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';

import QuarterSelector from './test';

// import Checkbox from '@material-ui/core/Checkbox';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MESSAGE } from 'src/utils/message';
import { RGCA_URLS } from '../../utils/url';
import APIKit from '../../utils/APIKit';
import Loader from '../loader';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { useConfirm } from 'material-ui-confirm';
import DialogContentText from '@mui/material/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@mui/icons-material/Clear';
import './style.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '3px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  container: {
    tableContainer: {
      maxHeight: '100%',
      '@media (max-width: 500px)': {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    },
  },
  centertext: {
    marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
    '@media (max-width: 500px)': {
      marginLeft: "120px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "60px"
    },
  },
  button: {
    '@media (max-width: 800px)': {
      marginBottom: "5px"
    },
    '@media (max-width: 500px)': {
      marginLeft: "110px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "50px"
    },
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));
const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ position: 'absolute' }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const AddEmplyoee = props => {

  const employeeData = useSelector(x => x.NavigationData.navigationData);

  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { state } = useLocation();
  const confirm = useConfirm();
  const [aadharAttach, setAadharAttach] = useState({});
  const [appointmentAttach, setAppointmentAttach] = useState({});
  const [lookupData, setLookupData] = useState([]);
  const [empType, setEmpType] = useState([]);
  const [btn, setBtn] = useState(false);
  const [allDesignation, setAllDesignations] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [pureText, setPureText] = useState('');
  const [empPayload, setEmpPayload] = useState({
    firstName: '',
    lastName: '',
    empLoginID: '',
    fatherName: '',
    aadharNumber: '',
    aadharAttchement: '',
    employmentType: '',
    gender: '',
    dateOfBirth: '',
    statusOfPhysicallyChallenged: '',
    statusOfPhysicallyChallengedNote: '',
    dateOfJoining: '',
    dateofReleving: null,
    email: '',
    password: '',
    phone: '',
    appointmentLetter: '',
    designationID: '',
    projects: [
      {
        projectID: '',
        accessRoleID: '',
        remark: "",
        comment: "",
        isActive: true
      }
    ],
    statusComment: '',
    employeeCode: '',
    biometricImprints: '',
    showPassword: false,
    alternate_email: '',
    alternate_phone: '',
    telephone_number: ''
  });
  const [empIndexVal, setEmpIndexVal] = useState()
  const [isDeleteopen, setIsDeleteOpen] = useState(false);
  const [empProjectPayload, setEmpProjectPayload] = useState({
    status: '',
    others: ''
  })

  const [isRemoveProjectOne, setIsRemoveProjectOne] = useState(false)
  const [isRemoveProjectTwo, setIsRemoveProjectTwo] = useState(false)
  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };

  const handleClose = () => {
    setIsDeleteOpen(false);
    setIsRemoveProjectOne(false);
    setIsRemoveProjectTwo(false);
  };

  useEffect(() => {
    getAllAccessRoles();
    getAllDesignation();
    getLookup();
    getEmpType();
    getAllProjects();
    if (employeeData && employeeData?.firstName) {
      let project = JSON.parse(employeeData?.project) ?? [
        { projectID: '', accessRoleID: '', remark: '', comment: '', isActive: true }
      ];

      setBtn(true);
      console.log(typeof employeeData.dateOfBirth);
      setEmpPayload({
        employeeID: employeeData.employeeID,
        empLoginID: employeeData.empLoginID,
        firstName: employeeData.firstName,
        lastName: employeeData.lastName,
        fatherName: employeeData.fatherName,
        aadharNumber: employeeData.aadharNumber,
        aadharAttchement: employeeData?.aadharAttachment ?? '',
        gender: employeeData.gender,
        dateOfBirth:
          employeeData.dateOfBirth == '1900-01-01T00:00:00.000Z'
            ? null
            : employeeData.dateOfBirth,
        dateOfJoining:
          employeeData.dateOfJoining == '1900-01-01T00:00:00.000Z'
            ? null
            : employeeData.dateOfJoining,
        statusOfPhysicallyChallenged: employeeData.statusOfPhysicallyChallenged,
        statusOfPhysicallyChallengedNote:
          employeeData?.statusOfPhysicallyChallengedNote ?? '',
        email: employeeData.email,
        employmentType: employeeData?.employmentType ?? '',
        phone: employeeData.phone,
        appointmentLetter: employeeData?.appointmentLetter ?? '',
        designationID: employeeData.designationID,
        projects: project ?? [{ projectID: '', accessRoleID: '', remark: '', comment: '', isActive: true }],
        statusComment: employeeData?.statusComment ?? '',
        employeeCode: employeeData.employeeCode,
        biometricImprints: employeeData?.biometricImprints ?? '',
        showPassword: false,
        telephone_number: employeeData?.telephone_number ?? '',
        alternate_email: employeeData?.alternate_email ?? '',
        alternate_phone: employeeData?.alternate_phone ?? '',
        dateofReleving:employeeData?.dateOfRelieving ?? null
      });
    }
  }, []);
  const addProDes = () => {
    getAllAccessRoles();
    getAllDesignation();
    getLookup();
    getEmpType();
    getAllProjects();
    let item = { ...empPayload };
    item.projects.push({ projectID: '', accessRoleID: '', remark: "", comment: "", isActive: true });
    // setEmpPayload({ ...item })
  };
  const deletePRODES = index => {
    setEmpIndexVal(index)
    handleClickOpen()
  };
  const updateEmployee = () => {
    console.log('hi', empPayload);
    const payload = { ...empPayload };
    payload.projects = empPayload?.projects.map(e => {
      return { projectID: e.projectID, accessRoleID: e.accessRoleID, comment: e.comment ?? "", remark: e.remark ?? '', isActive: e.isActive ?? true };
    });
    var valueArr = payload?.projects.map(function (item) {
      return item.projectID;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
    if (isDuplicate) {
      toast.error(MESSAGE.duplicateProjects, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateEmployee, payload)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.employeeUpdated, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setIsLoading(false);
          setTimeout(() => {
            props.handleAddEmployeeClose();
            props.getAllEmplyoees();
          }, 3000);
        } else {
          setIsLoading(false);
          toast.error(MESSAGE.employeeUpdatedNOT, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx", ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', event.target.files[0], event.target.files[0].name);
    if (type == 'aadhar') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            // setAadharAttach(res.data.data);

            setEmpPayload({
              ...empPayload,
              aadharAttchement: res.data.data
            });
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'appointment') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            // setAppointmentAttach(res.data.data);
            setEmpPayload({
              ...empPayload,
              appointmentLetter: res.data.data
            });
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
  };
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/10`)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };
  const getEmpType = () => {
    APIKit.get(`${RGCA_URLS.lookup}/83`)
      .then(res => {
        if (res.data.status === 200) {
          setEmpType(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const getAllDesignation = () => {
    APIKit.get(RGCA_URLS.getDesignations)
      .then(res => {
        if (res.data.status === 200) {
          setAllDesignations(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const handleClickShowPassword = () => {
    setEmpPayload({
      ...empPayload,
      showPassword: !empPayload.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const getAllAccessRoles = () => {
    APIKit.get(RGCA_URLS.getRoles)
      .then(res => {
        if (res.data.status === 200) {
          setAccessRoles(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects)
      .then(res => {
        if (res.data.status === 200) {
          console.log('kkk', res.data.data);
          setAllProjects(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };
  const TextFieldComponent = props => {
    return <TextField {...props} disabled={true} />;
  };
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.-\s]*$/,
    noSpace: /^\S*$/,
    alternate_email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  };
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  });

  return (
    <Page className={classes.root} title="Employee" id="emp">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Box
        mt={3}
        style={{
          borderRadius: '5px',
          padding: '20px'
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...empPayload

          }}
          onSubmit={(values, errors) => {
            console.log('err', errors);

            let check = empPayload?.projects.filter(
              x => x.projectID == '' || x.accessRoleID == ''
            );

            console.log('check', check);
            if (check.length > 0) {
              toast.warning("Plese select Project Name and Access Role", {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              return;
            }

            if (btn) {
              updateEmployee();
            } else {
              setIsLoading(true);
              const payload = { ...empPayload };
              payload.projects = empPayload?.projects.map(e => {
                return {
                  projectID: e.projectID,
                  accessRoleID: e.accessRoleID, remark: e.remark ?? '', comment: e.comment ?? '', isActive: e.isActive ?? ''
                };
              });

              APIKit.post(RGCA_URLS.addEmployee, payload)
                .then(res => {
                  ;
                  if (res.data?.status === 200) {
                    setIsLoading(false);
                    toast.success(MESSAGE.employeeAdded, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                    });
                    props.handleAddEmployeeClose();
                  } else {
                    setIsLoading(false);
                    toast.error(res.data.error, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                    });
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required(MESSAGE.fName)
              .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
            lastName: Yup.string()
              .required(MESSAGE.lName)
              .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
            // projects: Yup.string().required(MESSAGE.projects),
            email: Yup.string()
              .email(MESSAGE.validEmail)
              .max(255)
              .required(MESSAGE.email),
            empLoginID: Yup.string().required(MESSAGE.empLoginID),
            // added validations
            dateOfJoining: Yup.date().required("Date Of Joining is required"),
            phone: Yup.number().required("Mobile Number is required"),
            dateOfBirth: Yup.date().required("Date Of Birth is required"),
            // alternate_email: Yup.string()
            //   .email(MESSAGE.validEmail),
            // password: Yup.string().required(MESSAGE.password),
            projects: Yup.array().of(
              Yup.object().shape({
                projectID: Yup.number().required('Select the project'),
                accessRoleID: Yup.number().required('Select the role'),
              })
            ),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <MuiThemeProvider theme={formLabelsTheme}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      label="First Name"
                      name="firstName"
                      value={empPayload?.firstName}
                      required
                      onBlur={handleBlur}
                      onChange={e => {
                        if (
                          e.target.value === '' ||
                          regEx.textOnly.test(e.target.value)
                        ) {
                          handleChange(e);
                          const p = empPayload;
                          p.firstName = e.target.value;
                          values.firstName = e.target.value;
                          setEmpPayload({
                            ...p,
                            firstName: e.target.value
                          });
                        }
                      }}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      label="Last Name"
                      variant="outlined"
                      required
                      name="lastName"
                      inputProps={{ maxLength: 50 }}
                      value={empPayload?.lastName}
                      onBlur={handleBlur}
                      onChange={e => {
                        if (
                          e.target.value === '' ||
                          regEx.textOnly.test(e.target.value)
                        ) {
                          handleChange(e);
                          const p = empPayload;
                          p.lastName = e.target.value;
                          values.lastName = e.target.value;
                          setEmpPayload({
                            ...p,
                            lastName: e.target.value
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      value={empPayload.email}
                      onBlur={handleBlur}
                      onChange={e => {
                        const p = empPayload;
                        p.email = e.target.value;
                        values.email = e.target.value;
                        setEmpPayload({
                          ...p,
                          email: e.target.value
                        });
                      }}
                      inputProps={{
                        maxLength: 30
                      }}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Alternative Email"
                      name="alternate_email"
                      error={Boolean(touched.alternate_email && errors.alternate_email)}
                      helperText={touched.alternate_email && errors.alternate_email}
                      value={empPayload.alternate_email}
                      onBlur={handleBlur}
                      onChange={e => {
                        const p = empPayload;
                        p.alternate_email = e.target.value;
                        values.alternate_email = e.target.value;
                        setEmpPayload({
                          ...p,
                          alternate_email: e.target.value
                        });
                      }}
                      inputProps={{
                        maxLength: 50
                      }}

                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.empLoginID && errors.empLoginID)}
                      helperText={touched.empLoginID && errors.empLoginID}
                      label="Employee ID"
                      name="empLoginID"
                      value={empPayload?.empLoginID}
                      required
                      onBlur={handleBlur}
                      onChange={e => {
                        if (regEx.noSpace.test(e.target.value)) {
                          handleChange(e);
                          const p = empPayload;
                          p.empLoginID = e.target.value;
                          values.empLoginID = e.target.value;
                          setEmpPayload({
                            ...p,
                            empLoginID: e.target.value
                          });
                        }
                      }}

                      disabled={btn}
                      variant="outlined"
                      inputProps={{ maxLength: 30 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      // error={Boolean(touched.gender && errors.gender)}
                      variant="outlined"
                      style={{ width: '100%' }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="gender"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          const p = empPayload;
                          p.gender = e.target.value;
                          // values.gender = e.target.value;
                          setEmpPayload({
                            ...p,
                            gender: e.target.value
                          });
                        }}
                        value={empPayload.gender}
                        label="Gender"
                      >
                        {/* <MenuItem value="0">Male</MenuItem>
                                <MenuItem value="1">Female</MenuItem> */}
                        {lookupData &&
                          lookupData.map(x => {
                            return (
                              <MenuItem value={x.lookupID} key={x.lookupID}>
                                {x.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {/* <FormHelperText>
                        {errors.gender && touched.gender && errors.gender}
                      </FormHelperText> */}
                    </FormControl>

                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Father Name"
                      name="fatherName"
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(touched.fatherName && errors.fatherName)}
                      helperText={touched.fatherName && errors.fatherName}
                      value={empPayload.fatherName}
                      onBlur={handleBlur}
                      onChange={e => {
                        if (
                          e.target.value === '' ||
                          regEx.textOnly.test(e.target.value)
                        ) {
                          handleChange(e);
                          const p = empPayload;
                          p.fatherName = e.target.value;
                          values.fatherName = e.target.value;
                          setEmpPayload({
                            ...p
                          });
                        }
                      }}
                      variant="outlined"
                    />

                  </Grid>


                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      value={empPayload.phone}
                      onBlur={handleBlur}
                      label="Mobile Number"
                      inputProps={{ maxLength: 10 }}
                      name="phone"
                      onChange={e => {
                        const re = /^[0-9\b]+$/;

                        // if value is not blank, then test the regex

                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                          const p = empPayload;
                          p.phone = e.target.value;
                          values.phone = e.target.value;
                          setEmpPayload({
                            ...p,
                            phone: e.target.value
                          });
                        }
                      }}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.alternate_phone && errors.alternate_phone)}
                      helperText={touched.alternate_phone && errors.alternate_phone}
                      value={empPayload.alternate_phone}
                      onBlur={handleBlur}
                      label=" Alternative Mobile Number"
                      inputProps={{ maxLength: 10 }}
                      name="alternate_phone"
                      onChange={e => {
                        const re = /^[0-9\b]+$/;

                        // if value is not blank, then test the regex

                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                          const p = empPayload;
                          p.alternate_phone = e.target.value;
                          values.alternate_phone = e.target.value;
                          setEmpPayload({
                            ...p,
                            alternate_phone: e.target.value
                          });
                        }
                      }}

                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.telephone_number && errors.telephone_number)}
                      helperText={touched.telephone_number && errors.telephone_number}
                      value={empPayload.telephone_number}
                      onBlur={handleBlur}
                      label=" Land Line  Number "
                      inputProps={{ maxLength: 15 }}
                      name="telephone_number"
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        // const re =  /^\d{2}-\d{4}-\d{4}$/
                        // if value is not blank, then test the regex

                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                          const p = empPayload;
                          p.telephone_number = e.target.value;
                          values.telephone_number = e.target.value;
                          setEmpPayload({
                            ...p,
                            telephone_number: e.target.value
                          });
                        }
                      }}

                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={empPayload.showPassword ? 'text' : 'password'}
                        value={empPayload.password}
                        autoComplete="new-password"
                        inputProps={{ maxLength: 10 }}
                        onChange={e => {
                          handleChange(e);
                          const p = empPayload;
                          p.password = e.target.value;
                          values.password = e.target.value;
                          setEmpPayload({
                            ...p,
                            password: e.target.value
                          });
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {empPayload.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      error={Boolean(
                        touched.employmentType && errors.employmentType
                      )}
                      variant="outlined"
                      style={{ width: '100%' }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Employment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="employmentType"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          const p = empPayload;
                          p.employmentType = e.target.value;
                          values.employmentType = e.target.value;
                          setEmpPayload({
                            ...p,
                            employmentType: e.target.value
                          });
                        }}
                        value={empPayload.employmentType}
                        label="employmentType"
                      >
                        {empType &&
                          empType.map(x => {
                            return (
                              <MenuItem value={x.lookupID} key={x.lookupID}>
                                {x.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {errors.employmentType &&
                          touched.employmentType &&
                          errors.employmentType}
                      </FormHelperText>
                    </FormControl>

                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Aadhar Number"
                      name="aadharNumber"
                      error={Boolean(
                        touched.aadharNumber && errors.aadharNumber
                      )}
                      helperText={touched.aadharNumber && errors.aadharNumber}
                      value={empPayload.aadharNumber}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 12
                      }}
                      onChange={e => {
                        if (
                          e.target.value === '' ||
                          regEx.numbersOnly.test(e.target.value)
                        ) {
                          handleChange(e);
                          const p = empPayload;
                          p.aadharNumber = e.target.value;
                          values.aadharNumber = e.target.value;
                          setEmpPayload({
                            ...p,
                            aadharNumber: e.target.value
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={4} style={{ marginTop: "10px" }}>
                    <Button
                      style={{ width: '100%', height: '55px' }}
                      variant="contained"
                      component="label"
                    >
                      {'Aadhar Attachment *'}
                      <input
                        type="file"
                        hidden
                        onChange={e =>
                          handleUploadClick(e, 'aadhar')
                        }
                      />
                      {(empPayload?.aadharAttchement || empPayload?.aadharAttchement) && (
                        <a
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                            position: 'absolute',
                            marginTop: '30px'
                          }}
                          href={
                            empPayload?.aadharAttchement
                          }
                          target="_blank"
                        >
                          {empPayload?.aadharAttchement.substring(4, 25)}....
                          {empPayload?.aadharAttchement.split('.').pop()}                                </a>
                      )}
                      {empPayload?.aadharAttchement &&
                        <ClearIcon onClick={() => {
                          let p = empPayload;
                          setEmpPayload({
                            ...p,
                            aadharAttchement: ""
                          });
                        }} className='left' style={{ cursor: 'pointer', position: "relative", left: "23%" }} />
                      }
                    </Button>


                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          // autoOk
                          InputProps={{ disabled: true }}
                          onBlur={handleBlur}
                          inputVariant="outlined"
                          margin="normal"
                          id="date-picker-dialog"
                          label=" Date of Birth"
                          format='dd/MM/yyyy'
                          disableFuture={true}
                          InputLabelProps={{
                            shrink: true
                          }}
                          maxDate={moment(new Date()).subtract(18, 'years')}
                          name="dateOfBirth"
                          onChange={e => {
                            const p = empPayload;
                            p.dateOfBirth = e;
                            setEmpPayload({
                              ...p,
                              dateOfBirth: e
                            });
                          }}
                          value={empPayload.dateOfBirth ?? null}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          // TextFieldComponent={TextFieldComponent}
                          className={classes.calmar}
                          error={Boolean(
                            touched.dateOfBirth && errors.dateOfBirth
                          )}
                          helperText={touched.dateOfBirth && errors.dateOfBirth}
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      error={Boolean(
                        touched.statusOfPhysicallyChallenged &&
                        errors.statusOfPhysicallyChallenged
                      )}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Status Of Physically Challenged
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="statusOfPhysicallyChallenged"
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={e => {
                          handleChange(e);
                          const p = empPayload;
                          p.statusOfPhysicallyChallenged = e.target.value;
                          values.statusOfPhysicallyChallenged = e.target.value;
                          setEmpPayload({
                            ...empPayload,
                            statusOfPhysicallyChallenged: e.target.value
                          });
                        }}
                        fullWidth
                        variant="outlined"
                        value={empPayload.statusOfPhysicallyChallenged}
                        label="Status Of Physically Challenged"
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.statusOfPhysicallyChallenged &&
                          touched.statusOfPhysicallyChallenged &&
                          errors.statusOfPhysicallyChallenged}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {empPayload.statusOfPhysicallyChallenged == 'Yes' && (
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.statusOfPhysicallyChallengedNote &&
                          errors.statusOfPhysicallyChallengedNote
                        )}
                        helperText={
                          touched.statusOfPhysicallyChallengedNote &&
                          errors.statusOfPhysicallyChallengedNote
                        }
                        value={empPayload?.statusOfPhysicallyChallengedNote}
                        onBlur={handleBlur}
                        label="Status Of Physically Challenged Note"
                        name="statusOfPhysicallyChallengedNote"
                        onChange={e => {
                          const p = empPayload;
                          p.statusOfPhysicallyChallengedNote = e.target.value;
                          values.statusOfPhysicallyChallengedNote =
                            e.target.value;
                          setEmpPayload({
                            ...p,
                            statusOfPhysicallyChallengedNote: e.target.value
                          });
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item md={4} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          // autoOk
                          inputVariant="outlined"
                          InputProps={{ disabled: true }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          margin="normal"
                          id="date-picker-dialog"
                          label="Date of Join"
                          format='dd/MM/yyyy'
                          disableFuture={true}
                          name="dateOfJoining"
                          onChange={e => {
                            const p = empPayload;
                            p.dateOfJoining = e;
                            setEmpPayload({
                              ...p
                            });
                          }}
                          value={empPayload.dateOfJoining}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          className={classes.calmar}
                          error={Boolean(
                            touched.dateOfJoining && errors.dateOfJoining
                          )}
                          helperText={
                            touched.dateOfJoining && errors.dateOfJoining
                          }
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          // autoOk
                          inputVariant="outlined"
                          InputProps={{ disabled: true }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          margin="normal"
                          id="date-picker-dialog"
                          label="Date of Relieving"
                          format='dd/MM/yyyy'
                          // disablePast={true}
                          name="dateOfReleving"
                          onChange={e => {
                            const p = empPayload;
                            p.dateofReleving = e;
                            setEmpPayload({
                              ...p
                            });
                          }}
                          value={empPayload.dateofReleving ?? null}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          // disabled={btn}
                          className={classes.calmar}
                          error={Boolean(
                            touched.dateofReleving && errors.dateofReleving
                          )}
                          helperText={
                            touched.dateofReleving && errors.dateofReleving
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item lg={4} sm={4} xl={4} xs={4} style={{ marginTop: "10px" }}>
                    <Button
                      style={{ width: '100%', height: '55px' }}
                      variant="contained"
                      component="label"
                    >
                      {'Appointment Letter Attach'}
                      <input
                        type="file"
                        hidden
                        onChange={e =>
                          handleUploadClick(e, 'appointment')
                        }
                      />
                      {(empPayload?.appointmentLetter || empPayload?.appointmentLetter) && (
                        <a
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                            position: 'absolute',
                            marginTop: '30px'
                          }}
                          href={
                            empPayload?.appointmentLetter
                          }
                          target="_blank"
                        >
                          {empPayload?.appointmentLetter.substring(4, 25)}....
                          {empPayload?.appointmentLetter.split('.').pop()}                                </a>
                      )}
                    </Button>

                    {empPayload?.appointmentLetter &&
                      <ClearIcon onClick={() => {
                        let p = empPayload;
                        setEmpPayload({
                          ...p,
                          appointmentLetter: ""
                        });
                      }} className='left' style={{ cursor: 'pointer' }} />
                    }
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      error={Boolean(
                        touched.designationID && errors.designationID
                      )}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Designation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="designationID"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          const p = empPayload;
                          p.designationID = e.target.value;
                          values.designationID = e.target.value;
                          setEmpPayload({
                            ...p,
                            designationID: e.target.value
                          });
                        }}
                        value={empPayload.designationID}
                        label="Access Role"
                      >
                        {allDesignation &&
                          allDesignation.map(x => {
                            return (
                              <MenuItem
                                value={x.designationID}
                                key={x.designationID}
                              >
                                {x.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {errors.designationID &&
                          touched.designationID &&
                          errors.designationID}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <div style={{ textAlign: 'end', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      <Button variant="outlined" onClick={addProDes} startIcon={<AddCircleOutlineIcon />}>
                        Add Project
                      </Button>
                      {/* <AddCircleOutlineIcon onClick={addProDes} /> */}
                    </div>
                  </Grid>
                  <FieldArray name="projects">
                    {({ push, remove }) => (
                      <>
                        {empPayload?.projects?.filter(x => x.isActive)?.map((item, index) => {
                          const projects = `projects[${index}].projectID`;
                          const accessRoleID = `projects[${index}].accessRoleID`;
                          const touchedprojects = getIn(touched, projects);
                          const errorprojects = getIn(errors, projects);
                          const touchedaccessRoleID = getIn(touched, accessRoleID);
                          const erroraccessRoleID = getIn(errors, accessRoleID);
                          return (
                            <>
                              <Grid item md={7} xs={12}
                              >
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  error={Boolean(
                                    touchedprojects && errorprojects
                                  )}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Projects*
                                  </InputLabel>

                                  <Select
                                    //  error={Boolean(touched.projects[index].projectID && errors.projects[index].projectID)}
                                    //  helperText={touched.projects[index].projectID && errors.projects[index].rojectID}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name={projects}
                                    required
                                    onBlur={handleBlur}
                                    // error={Boolean(errors.description)}
                                    onChange={e => {
                                      handleChange(e);
                                      const p = empPayload;
                                      p.projects[index].projectID =
                                        e.target.value;
                                      setEmpPayload({
                                        ...p
                                      });
                                    }}
                                    value={item?.projectID}
                                    label="Projects"
                                  >
                                    {allProjects &&
                                      allProjects.length > 0 &&
                                      allProjects.map(x => {
                                        // if(empPayload.projects.findIndex( y => y.projectID == x.projectID ) == -1){
                                        return (
                                          <MenuItem
                                            disabled={empPayload.projects.findIndex(y => y.projectID == x.projectID) != -1}
                                            value={x.projectID}
                                            key={x.projectID}
                                          >
                                            {x.projectName}
                                          </MenuItem>
                                        )
                                        // }
                                      })}
                                  </Select>
                                  <FormHelperText>
                                    {touchedprojects && errorprojects
                                      ? errorprojects
                                      : ''}
                                  </FormHelperText>
                                  {/* <FormHelperText>{errors.projects}</FormHelperText> */}
                                </FormControl>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  error={Boolean(
                                    touchedaccessRoleID && erroraccessRoleID
                                  )}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Access Role*
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name={accessRoleID}
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      handleChange(e);
                                      const p = empPayload;
                                      p.projects[index].accessRoleID =
                                        e.target.value;
                                      setEmpPayload({
                                        ...p
                                      });
                                    }}
                                    value={
                                      item?.accessRoleID
                                    }
                                    label="Designations"
                                  >
                                    {accessRoles &&
                                      accessRoles.map(x => {
                                        return (
                                          <MenuItem
                                            value={x.accessRoleID}
                                            key={x.accessRoleID}
                                          >
                                            {x.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>
                                    {touchedaccessRoleID && erroraccessRoleID
                                      ? erroraccessRoleID
                                      : ''}
                                  </FormHelperText>{' '}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                md={1}
                                xs={2}
                                style={{ textAlign: 'center' }}
                              >
                                {empPayload.projects.length > 1 && (
                                  <>
                                    {empPayload.projects[index].accessRoleID != "" &&
                                      <DeleteForever
                                        onClick={() => deletePRODES(index)}
                                      />
                                    }
                                    {empPayload.projects[index].accessRoleID == "" &&
                                      <DeleteForever
                                        onClick={() => {
                                          let items = { ...empPayload };
                                          items.projects.splice(index, 1);
                                          setEmpPayload({ ...items });

                                        }}
                                      />
                                    }
                                  </>
                                )}

                              </Grid>
                            </>
                          );
                        })}
                      </>
                    )}
                  </FieldArray>
                  <Grid item md={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={props.handleAddEmployeeClose}
                      style={{ marginRight: "5px" }}
                    >
                      Cancel
                    </Button>
                    {btn ? (

                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                      // onClick={updateEmployee}
                      >
                        Update
                      </Button>

                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        // onClick={addEmployee}
                        type="submit"
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </MuiThemeProvider>
          )}
        </Formik>
      </Box>



      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isDeleteopen}
        id="income"
        // contentStyle={{
        //   width: '50%',
        //   maxWidth: 'none'
        // }}
        maxWidth="sm"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={handleClose}
          style={{ backgroundColor: '#394671', color: '#fff', display: "flex" }}
        >
          Remove From Project
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={10} xs={12} style={{ marginTop: '10px' }}>
              {/* <h5>Are you sure remove from this project?</h5> */}
              <FormControl
                variant="outlined"
                style={{ width: '100%', marginTop: '10px' }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Remark*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name='remark'
                  onChange={(e) => {
                    let p = empPayload;
                    p.projects[empIndexVal].remark = e.target.value
                    setEmpPayload({ ...p })
                    setIsRemoveProjectOne(true)
                  }}
                  label="Remark"
                  required
                  value={empPayload.projects[empIndexVal]?.remark}
                >
                  <MenuItem value={10}>Transfer</MenuItem>
                  <MenuItem value={20}>Retire</MenuItem>
                  <MenuItem value={30}>Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Note "
                name='comment'
                onChange={(e) => {
                  let p = empPayload;
                  p.projects[empIndexVal].comment = e.target.value
                  setEmpPayload({ ...p })
                  setIsRemoveProjectTwo(true)
                }}
                required
                value={empPayload.projects[empIndexVal]?.comment}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          <Button
            onClick={e => {
              handleClose()
            }}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
          >
            Cancel
          </Button>

          {isRemoveProjectOne && isRemoveProjectTwo ?

            <Button
              onClick={() => {
                let p = empPayload;
                p.projects[empIndexVal].isActive = false
                setEmpPayload({ ...p })
                handleClose()
              }}
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
            >
              Add
            </Button>
            : <Button disabled={true} style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}>Add</Button>
          }
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default AddEmplyoee;
