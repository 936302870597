import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  makeStyles,
  FormHelperText,
  Typography
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { RGCA_URLS } from "src/utils/url";
import DeleteIcon from '@mui/icons-material/Delete';
import "./style.css";
import { MESSAGE } from "src/utils/message";
import APIKit from "src/utils/APIKit";
import { toast } from "react-toastify";
import ClearIcon from '@mui/icons-material/Clear';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
  },
  type: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "230px",
    },
  },
  Select: {
    '& .MuiFormHelperText-root': {
      color: 'red', // Change helper text color to red
    },
  },
}));

function AddItem({ POItemsNew, POgrnAdd, setPOgrnAdd, handleUploadClick = () => { }, warrantyAttachItem,
  setWarrantyAttachItem, errorMessage, setErrorMessage, setErrorStatus, errorStatus ,projectDetails}) {
  console.log('POItemsNew', POItemsNew);
  console.log('POItems', POgrnAdd[0].qty);
  // console.log('createdDate', POgrnAdd[0].createdDate);

  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(POItemsNew[0]);
  const [insuranceAttach, setInsuranceAttach] = useState('')

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9.]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/,
  };
  const handleItemChange = (event) => {

    let p = event.target.value;
    p['aprovedCount'] = null;
    p['rejectedCount'] = null
    setSelectedItem(event.target.value);
    setPOgrnAdd([p])
  };

  useEffect(() => {
    const pendingItems = POItemsNew.filter(item => item);
    setPOgrnAdd([pendingItems[0]])
    setSelectedItem(pendingItems[0])
  }, [])

  const handleUploadAttach = (event, type, wat) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("files", event.target.files[0], event.target.files[0].name);
    if (type == "Insurance") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            // setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setInsuranceAttach(res.data.data);
            let p = POgrnAdd;
            p[0].insuranceAttach = res?.data?.data;
            setPOgrnAdd([...p]);
            // console.log(res.data.data, "in");
          } else {
            // setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => { });
    }
    if (type == "Warranty") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            // setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setWarrantyAttach(res.data.data);
            // setAddSingleFAR({
            //   ...addSingleFAR,
            //   warrantyAttach: res.data.data,
            // });
          } else {
            // setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => { });
    }
  };

  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={4} xs={12}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            className={classes.formControl}
          >
            <InputLabel required id="demo-simple-select-outlined-label">PO-ITEM-ID</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedItem} // Set the value to the state variable
              onChange={handleItemChange}
              label="Sales Item"
              fullWidth
            >
              {POItemsNew &&
                POItemsNew.filter(item => item.isflag === 0).map((x) => {
                  return (
                    <MenuItem value={x} key={x.purchaseOrderItemID}>
                      PO-ITEM-{x.purchaseOrderItemID}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            className={classes.formControl}
          >
            <TextField
              fullWidth
              label="Type"
              name="Material"
              variant="outlined"
              disabled
              inputProps={{ maxLength: 8 }}
              value={POgrnAdd[0]?.typeName}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            className={classes.formControl}
          >
            {/* <InputLabel required id="demo-simple-select-outlined-label">Material/Asset/Item</InputLabel> */}
            <TextField
              fullWidth
              label="Material/Asset/Item"
              name="Material"
              variant="outlined"
              disabled
              inputProps={{ maxLength: 8 }}
              value={POgrnAdd[0]?.name}
            />
          </FormControl>
        </Grid>
        {POgrnAdd[0]?.typeID == 2 &&
          <Grid item md={4} xs={12}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.Select}
            >
              <InputLabel id="demo-simple-select-outlined-label">Acceptance Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Acceptance Status"
                name="grnStatus"
                onChange={(e) => {
                  setErrorStatus(false)
                  let temp = POgrnAdd;
                  temp[0].grnStatus = e.target.value;
                  setPOgrnAdd([...POgrnAdd]);
                }}
              >

                <MenuItem value={25}>Rejected</MenuItem>
                <MenuItem value={24}>Accepted</MenuItem>

              </Select>
              <FormHelperText>
                {errorStatus ? 'Please select Status date.' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        }
        {POgrnAdd[0].grnStatus === 25 && (
          <>
            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                className={classes.type}
              >
                <TextField
                  fullWidth
                  helperText=""
                  label="Reason for Rejection"
                  name="rejectedComment"
                  inputProps={{ maxLength: 250 }}
                  onChange={(e) => {
                    // if (
                    //   e.target.value === "" ||
                    //   regEx.textOnly.test(e.target.value)
                    // ) {
                    let p = POgrnAdd;
                    p[0].rejectedComment = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                    // }
                  }}
                  value={POgrnAdd[0]?.rejectedComment}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                className={classes.Select}
              >
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  minDate={POgrnAdd[0]?.createdDate}
                  disableFuture
                  label="Rejection Date"
                  format="dd-MM-yyyy"
                  required
                  name="dateofExpiry"
                  InputAdornmentProps={{ position: "end" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setErrorMessage(false)
                    let temp = POgrnAdd;
                    temp[0].dateofExpiry = e;
                    setPOgrnAdd([...POgrnAdd]);
                  }}
                  InputProps={{ disabled: true }}
                  value={POgrnAdd[0]?.dateofExpiry || null}
                // helperText={errorMessage ? 'Please select expired date.' : ''}

                />

              </FormControl>
            </Grid>

          </>

        )}
        {POgrnAdd[0]?.typeID == 3 &&
          <>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Total Requested Qty"
                name="qty"
                value={POgrnAdd[0]?.qty}
                disabled
                variant="outlined"
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
          </>
        }
        {POgrnAdd[0]?.typeID == 3 &&
          <>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Accepted Count"
                name="aprovedCount"
                onChange={(e) => {
                  if (e.target.value === "" || regEx.numbersOnly.test(e.target.value) && Number(e.target.value) <= POgrnAdd[0]?.qty) {
                    let temp = POgrnAdd;
                    temp[0].aprovedCount = Number(e.target.value);
                    temp[0].rejectedCount = POgrnAdd[0]?.qty - temp[0].aprovedCount;
                    setPOgrnAdd([...POgrnAdd]);
                  }
                }}
                value={POgrnAdd[0]?.aprovedCount}
                variant="outlined"
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Rejected Count"
                name="rejectedCount"
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.numbersOnly.test(e.target.value)
                  ) {
                    let temp = POgrnAdd;
                    temp[0].rejectedCount = POgrnAdd[0]?.qty - POgrnAdd[0]?.aprovedCount;
                    setPOgrnAdd([...POgrnAdd]);
                  }
                }}
                value={POgrnAdd[0]?.rejectedCount}
                variant="outlined"
                inputProps={{ maxLength: 8 }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>
            {POgrnAdd[0]?.rejectedCount > 0 &&
              <Grid item md={4} xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className={classes.type}
                >
                  <TextField
                    fullWidth
                    helperText=""
                    label="Reason for Rejection"
                    name="rejectedComment"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        regEx.textOnly.test(e.target.value)
                      ) {
                        let p = POgrnAdd;
                        p[0].rejectedComment = e.target.value;
                        setPOgrnAdd([
                          ...p
                        ]);
                      }
                    }}
                    value={POgrnAdd[0]?.rejectedComment}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            }
          </>
        }
        {(POgrnAdd[0].grnStatus == 24 || POgrnAdd[0]?.typeID == 3) &&
          <>
            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                className={classes.formControl}
              >
                {/* <InputLabel required id="demo-simple-select-outlined-label">UOM</InputLabel> */}
                <TextField
                  fullWidth
                  label="UOM"
                  name="UOM"
                  variant="outlined"
                  disabled
                  inputProps={{ maxLength: 8 }}
                  value={POgrnAdd[0]?.uom}
                />
              </FormControl>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Unit Price"
                name="unitPrice"
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.numbersOnly.test(e.target.value)
                  ) {
                    let temp = POgrnAdd;
                    temp[0].unitPrice = Number(e.target.value);
                    setPOgrnAdd([...POgrnAdd]);
                  }
                }}
                value={POgrnAdd[0]?.unitPrice}
                variant="outlined"
                inputProps={{ maxLength: 8 }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                className={classes.type}
              >
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Received Date"
                  format="dd-MM-yyyy"
                  disableFuture
                  name="receivedDate"
                  InputAdornmentProps={{ position: "end" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let temp = POgrnAdd;
                    temp[0].receivedDate = e;
                    setPOgrnAdd([...POgrnAdd]);
                  }}
                  InputProps={{ disabled: true }}
                  value={POgrnAdd[0]?.receivedDate}
                />
              </FormControl>
            </Grid>
          </>
        }
        {/* for for */}

        {(POgrnAdd[0]?.typeID == 2 && POgrnAdd[0].grnStatus == 24) &&
          <>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 20 }}
                label="Location"
                name="location"
                defaultValue={projectDetails?.location}
                type="text"
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.textOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].location = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.location}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Warranty / Guarantee details"
                name="warrantyGranteeDetails"
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.noSpecialChar.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].warrantyGranteeDetails = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.warrantyGranteeDetails}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Estimated Useful life in years"
                name="estimatedYears"
                inputProps={{ maxLength: 8 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.numbersOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].estimatedYears = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.estimatedYears}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Rate of Depreciation"
                name="purchaseValue"
                inputProps={{ maxLength: 8 }}
                onChange={(e) => {
                  let p = POgrnAdd;
                  if (
                    e.target.value === "" ||
                    regEx.numbersOnly.test(e.target.value)
                  ) {
                    p[0].rateOfDepreciation = Number(e.target.value);
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.rateOfDepreciation}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Method Of Depreciation "
                name="methodOfDepreciation"
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.textOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].methodOfDepreciation = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.methodOfDepreciation}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText="Estimated residual value at the end of the useful life"
                label="Estimated Residual Value"
                name="estimatedResidualValue"
                inputProps={{ maxLength: 8 }}
                onChange={(e) => {
                  // handleChange(e)
                  if (regEx.numbersOnly.test(e.target.value)) {
                    let p = POgrnAdd;
                    p[0].estimatedResidualValue = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.estimatedResidualValue}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText="Details of Depreciation charged ,year on year"
                label="Details of Depreciation "
                name="detailsOfDepreciation"
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.textOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].detailsOfDepreciation = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.detailsOfDepreciation}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Accumulated Depreciation"
                helperText="Details of Depreciation charged ,year on year"
                name="accumulatedDepreciation"
                inputProps={{ maxLength: 8 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.numbersOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].accumulatedDepreciation = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.accumulatedDepreciation}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid>
                  <KeyboardDatePicker
                    className={classes.calmar1}
                    fullWidth
                    InputProps={{ disabled: true }}
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label="Insurance renewal date"
                    format="dd-MM-yyyy"
                    disablePast
                    InputLabelProps={{
                      shrink: true,
                    }}

                    name="insuranceDate"
                    onChange={(e) => {
                      const p = POgrnAdd;
                      p[0].insuranceDate = e;
                      setPOgrnAdd([
                        ...p
                      ]);
                    }}
                    value={POgrnAdd[0]?.insuranceDate ?? null}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={4} xs={12}>
              <Typography>
                <strong>Insurance Attachment
                </strong>
              </Typography>
              <Button
                style={{ width: "70%", height: "55px" }}
                variant="contained"
                component="label"
              >
                {!insuranceAttach ? "Attach Here" : null}
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    handleUploadAttach(e, "Insurance", "add")
                  }
                />
                {insuranceAttach && (
                  <a
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      position: "absolute",
                      marginTop: "9px",
                    }}
                    href={
                      insuranceAttach
                    }
                    target="_blank"
                  >
                    {insuranceAttach?.substring(4, 25)}....
                    {insuranceAttach?.split(".").pop()}
                  </a>
                )}
              </Button>
              {insuranceAttach &&
                <ClearIcon onClick={() => {
                  let p = POgrnAdd;
                  p[0].insuranceAttach = ""
                  setPOgrnAdd([...p]);
                  setInsuranceAttach('');
                }} style={{ cursor: 'pointer' }} />
              }
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Note "
                name="note"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    regEx.textOnly.test(e.target.value)
                  ) {
                    let p = POgrnAdd;
                    p[0].note = e.target.value;
                    setPOgrnAdd([
                      ...p
                    ]);
                  }
                }}
                value={POgrnAdd[0]?.note}
                variant="outlined"
              />
            </Grid>
          </>
        }
        {/* for for end */}
        {/* {POgrnAdd[0]?.typeID == 3 && */}
        {(POgrnAdd[0]?.typeID == 3 || POgrnAdd[0].grnStatus == 24) &&
          <Grid item md={4} xs={12}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.Select}
            >
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                disablePast
                label="Expired Date"
                format="dd-MM-yyyy"
                required
                name="dateofExpiry"
                InputAdornmentProps={{ position: "end" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setErrorMessage(false)
                  let temp = POgrnAdd;
                  temp[0].dateofExpiry = e;
                  setPOgrnAdd([...POgrnAdd]);
                }}
                InputProps={{ disabled: true }}
                value={POgrnAdd[0]?.dateofExpiry || null}
              // helperText={errorMessage ? 'Please select expired date.' : ''}

              />

            </FormControl>
          </Grid>
        }
        {(POgrnAdd[0].grnStatus == 24 || POgrnAdd[0]?.typeID == 3) &&
          <Grid item md={4} xs={12}>
            <Typography>
              <strong>Warranty Attachment </strong>
            </Typography>
            <Button
              style={{ width: "70%", height: "55px" }}
              variant="contained"
              component="label"
            >
              {!warrantyAttachItem ? "Attach Here" : null}
              <input
                type="file"
                hidden
                onChange={(e) => handleUploadClick(e, 0, "itemInvoice")}
              />
              {(POgrnAdd[0].itemAttach || warrantyAttachItem) && (
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    position: "absolute",
                    marginTop: "9px",
                  }}
                  href={POgrnAdd[0].itemAttach ? POgrnAdd[0].itemAttach : warrantyAttachItem}
                  target="_blank"
                >
                  {POgrnAdd[0].itemAttach ? POgrnAdd[0].itemAttach?.substring(4, 10) : warrantyAttachItem?.substring(4, 10)}
                  ....
                  {POgrnAdd[0].itemAttach ? POgrnAdd[0].itemAttach.split(".").pop() : warrantyAttachItem?.split(".").pop()}
                </a>
              )}
            </Button>
            {/* <DeleteIcon onClick={()=>{
              let p = POgrnAdd[0];
              p.itemAttach = ""
              setPOgrnAdd({...p})
              setWarrantyAttachItem("")
            }}/> */}
            {(POgrnAdd[0].itemAttach || warrantyAttachItem) && (
              <ClearIcon onClick={() => {
                let p = POgrnAdd;
                p[0].itemAttach = "";
                setPOgrnAdd([...p]);
                setWarrantyAttachItem("");
              }} style={{ cursor: 'pointer' }} />
            )}

          </Grid>
        }


      </Grid>
    </>
  );
}

export default AddItem;
