import React, { useEffect, useState, useRef } from 'react';
import {
  Container, Grid, makeStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Stepper, Step, StepLabel, StepContent, Box, Checkbox, MenuItem, FormControl, InputLabel, Select, TextField, RadioGroup, FormControlLabel, Radio, Card, CardActions, CardContent, FormGroup, FormHelperText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import Page from 'src/components/Page';
import { statusColor } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './style.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RGCA_URLS } from 'src/utils/url';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { ETTypes, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import {
  parseData, dateFormate, getProjectDetails, capitalize, dateOnly, currencyFormat1
} from '../Common/utilities';
import StepperBox from '../Common/StepperBox.jsx';
import { useParams } from 'react-router-dom';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import { Constant } from '../Common/Constant';
import Loader from '../../loader/index';
import Address from '../Common/Address';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useConfirm } from 'material-ui-confirm';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  }
}));

const ListPurchaseOrder = () => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [openincPrint, setOpenincPrint] = useState(false);
  const [allPurchaseOrder, setAllPurchaseOrder] = useState([]);
  const [cpd, setCpd] = useState([]);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isPoRemove, setPoRemove] = useState(false);
  const [poRemoveData, setPoRemoveData] = useState({});
  const [rejectedCommentPo, setRejectedCommentPo] = useState('')

  const POApprovalView = [
    {
      title: 'PO Item ID',
      field: 'purchaseOrderItemID',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Material Name',
      field: 'name',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'GST',
      field: 'isGST',
      align: 'left',
      type: ETTypes.boolean
    },
    {
      title: 'IGST',
      field: 'IGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'CGST',
      field: 'CGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'SGST',
      field: 'SGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UGST',
      field: 'UGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'QTY',
      field: 'qty',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UOM',
      field: 'uom',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Total Price(Inclusive GST)',
      field: 'totalAmount',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Description',
      field: 'description',
      align: 'left',
      type: ETTypes.string
    }
  ];

  const viewIndent = [
    {
      title: 'Indent Master ID',
      field: 'indentMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'PO ID',
      field: 'purchaseOrderMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Total Quantity',
      field: 'totalQty',
      align: 'left',
      type: ETTypes.numeric
    },
    // {
    //   title: 'Value',
    //   field: 'totalValue',
    //   align: 'left',
    //   type: ETTypes.currency
    // },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreateDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Delivery End Date',
      field: 'deliveryDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'PO Status',
      field: 'POStatus',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Actions',
      field: 'action',
      align: 'left',
      list: [ETaction.onPrint, ETaction.onView, ETaction.onCancel]
      //  list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ];
  const [print, setprint] = useState(false)
  useEffect(() => {
    getListOfPurchaseOrder();
  }, []);
  var decimal = 'zero one two three four five six seven eight nine'.split(' ')
  var num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

  function number2words(n) {
    if (n < 20) return num[n];
    var digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
    if (n < 1000)
      return (
        num[~~(n / 100)] +
        ' hundred' +
        (n % 100 == 0 ? '' : ' and ' + number2words(n % 100))
      );
    return (
      number2words(~~(n / 1000)) +
      ' thousand' +
      (n % 1000 != 0 ? ' ' + number2words(n % 1000) : '')
    );
  }

  const getListOfPurchaseOrder = () => {
    setIsLoading(true)
    APIKit.get(
      RGCA_URLS.getPurchaseOrderForIndent + '/' + projectDetails.projectID
    )
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false)

          // setAllPurchaseOrder(res.data.data);
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              purchaseOrderItem: parseData(x.purchaseOrderItem),
              vendorDetails: parseData(x.vendorDetails),
              vendorAddress: parseData(parseData(x.vendorDetails)[0].address)
            };
          });
          console.log(data);
          setAllPurchaseOrder(data);
        } else {
          setIsLoading(false)
        }
      })
      .catch(e => {
        setIsLoading(false)

      });
  };

  const handleIncPrintClose = () => {
    setOpenincPrint(!openincPrint);
  };

  const modelActions = {
    onPrint: (index, field, value) => {
      setItem(field);
      console.log(field.purchaseOrderItem, "hello")
      if (field.purchaseOrderItem) {
        setCpd(
          field.purchaseOrderItem.map(e => {

            return {
              ...e,
              totalGST: e.IGST + e.CGST + e.SGST + e.UGST,
              totalAmount: Number(
                e.qty * e.unitPrice +
                e.qty *
                e.unitPrice *
                ((e.IGST || 0 + e.CGST || 0 + e.SGST || 0 + e.UGST || 0) /
                  100)
              ),
              baseAmount: e.qty * e.unitPrice
            };
          })
        )
      };
      setIsShow(false);
      handleIncPrintClose();
    },
    onCancel: (index, field, value) => {
      console.log("field", field);
      setPoRemoveData(field)
      setPoRemove(true)
      // confirm({ description: 'You Want To Cancel The Purchase-Order ?' })
      //   .then(() => {
      //     cancelpo(field)
      //   })
      //   .catch(() => console.log('Deletion cancelled.'));
    },
    onView: (index, field, value) => {
      setItem(field);
      console.log(field.purchaseOrderItem, "hello")
      if (field.purchaseOrderItem) {
        setCpd(
          field.purchaseOrderItem.map(e => {

            return {
              ...e,
              totalGST: e.IGST + e.CGST + e.SGST + e.UGST,
              totalAmount: Number(
                e.qty * e.unitPrice +
                e.qty *
                e.unitPrice *
                ((e.IGST || 0 + e.CGST || 0 + e.SGST || 0 + e.UGST || 0) /
                  100)
              ),
              baseAmount: e.qty * e.unitPrice
            };
          })
        )
      };
      setIsShow(true);
      handleIncPrintClose();
    },
  };

  const cancelpo = () => {
    setIsLoading(true);
    let payload = {
      purchaseOrderMasterID: poRemoveData?.purchaseOrderMasterID,
      isPOCancel: true,
      rejectedComment: rejectedCommentPo ? rejectedCommentPo : ''

    }
    APIKit.put(RGCA_URLS.updatepostatus, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPoRemove(false);
          toast.success(res.data.message);
          getListOfPurchaseOrder();
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };


  const exportPdf = async () => {

    await html2canvas(document.querySelector('#printme')).then(canvas => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('PO generate');
    });
    //   html2canvas(document.querySelector('#printme')).then(function(canvas) {

    //     const imgData = canvas.toDataURL('image/png')
    //       const pdf = new jsPDF('p', 'mm', 'a4');
    //     var width = pdf.internal.pageSize.getWidth();
    //     var height = pdf.internal.pageSize.getHeight();
    //     pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
    //     pdf.save('PO generate');
    // });
  };
  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });
  console.log(allPurchaseOrder, "allPurchaseOrder");

  console.log("cpd", cpd);
  return (
    <Page className={classes.root} title={`${projectDetails.projectName}-Purchase-Order-invoice`}>
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        <ToastContainer />
        <Typography>
          <h2 style={{ color: '#394671' }}>Central Purchase Department</h2>
        </Typography>

        {/* CPD Table */}
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <CommonTable
                columns={viewIndent}
                data={allPurchaseOrder}
                action={modelActions}
              />
            </div>
          </Grid>
        </Grid>

        {/* Print */}
        <Dialog
          aria-labelledby="customized-dialog-title"
          contentStyle={{
            width: '100%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
          open={openincPrint}
          id="income"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleIncPrintClose}
            style={{ backgroundColor: '#394671', color: '#fff' }}
          >
            {isShow ? "Purchase Order View" : "PO Generation Print View"}
          </DialogTitle>
          <DialogContent dividers style={{ overflowX: 'hidden' }}>
            {isShow ?
              <>
                <Grid container spacing={3}>
                  <Grid item lg={2} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <h5 style={{ color: '#394671' }}>
                        Date: {dateOnly(item?.CreateDate)}
                      </h5>
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <h5 style={{ color: '#394671' }}>
                        Purchase Order Id:
                        {item?.purchaseOrderMasterID && item?.purchaseOrderMasterID.length > 0 && item?.purchaseOrderMasterID}
                      </h5>
                    </div>
                  </Grid>
                  {item?.POStatus == "PO-Cancel" &&
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Po Rejected Comment:
                          {item?.rejectedComment ? item?.rejectedComment : '-'}
                        </h5>
                      </div>
                    </Grid>
                  }
                  <Grid item lg={6} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <h5 style={{ color: '#394671' }}>
                        Specification Attachment :
                        {item?.specificationAttach && <a href={item?.specificationAttach}>{item?.specificationAttach ? item?.specificationAttach.substring(0, 25) : '-'}</a>}
                      </h5>
                    </div>
                  </Grid>
                  {item?.gemAttach &&
                    <Grid item lg={6} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Gem Attachment : {item?.gemAttach && <a style={{ cursor: 'pointer' }} href={item?.gemAttach}>{item?.gemAttach ? item?.gemAttach.substring(0, 25) : '-'}</a>}
                        </h5>
                      </div>
                    </Grid>
                  }
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">PO ID</TableCell>
                              <TableCell align="left"> Material Name</TableCell>
                              <TableCell align="left">UOM</TableCell>
                              <TableCell align="left">QTY</TableCell>
                              <TableCell align="left">Unit Price</TableCell>
                              <TableCell align="left">Tax Amount</TableCell>
                              <TableCell align="left">Total Price</TableCell>
                              <TableCell align="left">Delivery End Date</TableCell>
                              <TableCell align="left">Description</TableCell>
                              <TableCell align="left">Technical Attach</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cpd && cpd.length != 0 ? (
                              cpd.map((z, index) => {
                                return (<TableRow key={index} hover>
                                  <TableCell align="left">{z?.purchaseOrderItemID}</TableCell>
                                  <TableCell align="left">{z?.name}</TableCell>
                                  <TableCell align="left">{z?.uom}</TableCell>
                                  <TableCell align="left">{z?.qty}</TableCell>
                                  <TableCell align="left">{currencyFormat1(Math.round(z?.unitPrice))}</TableCell>
                                  <TableCell align="left">{currencyFormat1(Math.round(z?.TotalTax))}</TableCell>
                                  <TableCell align="left">{currencyFormat1(Math.round(z?.totalAmount))}</TableCell>
                                  <TableCell align="left">{moment(z?.deliveryDate).format("DD-MM-YYYY")}</TableCell>
                                  <TableCell align="left">{z?.description}</TableCell>
                                  <TableCell align="left">
                                    <a style={{ cursor: 'pointer' }} href={z?.ItemAttach}>{z?.ItemAttach ? z?.ItemAttach.substring(0, 25) : ''}</a>
                                  </TableCell>
                                </TableRow>
                                );
                              })
                            ) : ('')
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              </> : <>

                <div>
                  <PictureAsPdfIcon
                    onClick={() => { setprint(true); printOrder() }}
                    style={{ cursor: 'pointer' }}
                  />{' '}
                  &nbsp;
                  <PrintIcon onClick={() => {
                    setprint(true);
                    setTimeout(() => {
                      printOrder()
                    }, 2000)

                  }} style={{ cursor: 'pointer' }} />
                </div>
                <div className="invoice-box " ref={htmlContentRef} id="myHtmlContent" style={{ marginTop: '8px' }}>
                  <div class="grid-container">
                    <div class="logo-container">
                      <img src="/static/logo-rgca.jpg" alt="Company logo" className='logo-containers' />
                    </div>
                    <div class="text-container">
                      <div>
                        <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                        <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                        <h5>HEAD OFFICE / UNIT OFFICE</h5>
                      </div>
                    </div>
                  </div>
                  <table cellpadding="0" cellspacing="0" style={{ marginTop: '20px' }} >
                    <tr className="top_rw">
                      <td colSpan="2">
                        <h2 style={{ marginBottom: '0px' }}>
                          Purchase Order
                        </h2>
                        <span> Date: {dateOnly(item?.CreateDate)} </span>
                      </td>
                      <td >
                        Purchase Order Id:{' '}
                        {/* {allPurchaseOrder[0]?.purchaseOrderMasterID} */}
                        {item?.purchaseOrderMasterID && item?.purchaseOrderMasterID.length > 0 && item?.purchaseOrderMasterID}<br />
                      </td>
                    </tr>
                    <tr className="top">
                      <td colSpan="3">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6} >
                            <b>Vendor Details : </b>  <br />
                            {item?.vendorDetails && item?.vendorDetails.length > 0 && item?.vendorDetails[0]?.name}<br />
                            {item?.vendorAddress && item?.vendorAddress.length > 0 && item?.vendorAddress[0]?.line_1 ? `${item.vendorAddress[0].line_1}, ` : ''}
                            {item?.vendorAddress && item?.vendorAddress.length > 0 && item?.vendorAddress[0]?.line_2 ? `${item.vendorAddress[0].line_2}, ` : ''}
                            {item?.vendorAddress && item?.vendorAddress.length > 0 && item?.vendorAddress[0]?.district ? `${item.vendorAddress[0].district}, ` : ''} <br />
                            {item?.vendorAddress && item?.vendorAddress.length > 0 && item?.vendorAddress[0]?.state ? `${item.vendorAddress[0].state}, ` : ''}
                            {item?.vendorAddress && item?.vendorAddress.length > 0 && item?.vendorAddress[0]?.postalCode ? `${item.vendorAddress[0].postalCode}.` : ''}
                            <br />
                            <b>PAN : </b>{item?.vendorDetails && item?.vendorDetails.length > 0 && item?.vendorDetails[0]?.pan} {', '}
                            <b>GSTIN : </b>{item?.vendorDetails && item?.vendorDetails.length > 0 && item?.vendorDetails[0]?.gstn}<br />
                            <div style={{ marginTop: '0rem' }}>
                              <p><b>Dear Sir / Madam,</b></p>
                              <p><b>Sub :</b><span style={{ marginLeft: '0.5rem', overflowWrap: 'break-word' }}>{item?.subject && item?.subject.length > 0 && item?.subject != 'undefined' ? item?.subject && item?.subject.length > 0 && item?.subject : '-'}</span></p>
                              <p><b>Ref :</b><span style={{ marginLeft: '0.5rem', overflowWrap: 'break-word' }}>{item?.reference && item?.reference.length > 0 && item?.reference != 'undefined' ? item?.reference && item?.reference.length > 0 && item?.reference : '-'}</span></p>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={6} style={{ textAlign: "start", paddingLeft: '20px' }}>
                            <b> Project Address : </b>
                            <br />
                            <p>{item?.deliveryProjectName}</p>
                            <Address details={item?.deliveryProjectAddress} />
                            <p> <b>GSTIN </b> : 39ATRGG1314R9Z6</p>
                          </Grid>
                          <Grid item xs={6} md={6}>
                          </Grid>
                        </Grid>
                      </td>
                    </tr>
                    <tr>

                    </tr>
                    <tr className="totalleft">
                      <td colspan="3">
                        <div class="" style={{ marginTop: '0em' }}>
                          <table class="table">
                            <thead class='tableHead'>
                              <tr class="table-rows">
                                <th style={{ textAlign: 'left' }}>Item ID</th>
                                <th style={{ textAlign: 'left' }}>Material Name</th>
                                <th style={{ textAlign: 'left' }}>QTY</th>
                                <th style={{ textAlign: 'left' }}>UOM</th>
                                <th style={{ textAlign: 'left' }}>Unit Price</th>
                                <th style={{ textAlign: 'left' }}>IGST(%)</th>
                                <th style={{ textAlign: 'left' }}>CGST(%)</th>
                                <th style={{ textAlign: 'left' }}>SGST(%)</th>
                                <th style={{ textAlign: 'left' }}>UGST(%)</th>
                                <th style={{ textAlign: 'left' }}>Tax Amount</th>
                                <th style={{ textAlign: 'left' }}>Total Price</th>
                                <th style={{ textAlign: 'left' }}>Delivery End Date</th>
                                <th style={{ textAlign: 'left' }}>Description</th>
                              </tr>
                            </thead>
                            {cpd && cpd.length != 0 ? (
                              cpd.map((z, index) => {
                                return (
                                  <tr class="table-row">
                                    <td style={{ textAlign: 'left' }}>{z?.purchaseOrderItemID}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.name}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.qty}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.uom}</td>
                                    <td style={{ textAlign: 'left' }}> {currencyFormat1(Math.round(z?.unitPrice))}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.IGST}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.CGST}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.SGST}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.UGST}</td>
                                    <td style={{ textAlign: 'left' }}>₹ {currencyFormat1(Math.round(z?.TotalTax))}</td>
                                    <td style={{ textAlign: 'left' }}>₹ {currencyFormat1(Math.round(z?.totalAmount))}</td>
                                    <td style={{ textAlign: 'left' }}>{dateOnly(z?.deliveryDate)}</td>
                                    <td style={{ textAlign: 'left' }}>{z?.description}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr class="table-row">
                                <td align="left">
                                  {'No Data Found'}
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </td>
                    </tr>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                      <Grid item xs={6}>
                        <tr>
                          <td colSpan="3">
                            <p style={{ marginLeft: '0.5rem' }}>TERMS & CONDITIONS:</p>
                            <ol style={{ marginLeft: '2rem' }}>
                              <li>
                                <p>
                                  <b>Taxes : </b>
                                  {/* <span style={{marginLeft: '0.5rem'}}>{allPurchaseOrder[0]?.taxes != 'undefined' ? allPurchaseOrder[0]?.taxes : '-' }</span> */}
                                  <span style={{ marginLeft: '0.5rem' }}>{item?.taxes != 'undefined' ? item?.taxes : '-'}</span>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Bills : </b>
                                  {/* <span style={{marginLeft: '0.5rem'}}>{allPurchaseOrder[0]?.bills != 'undefined' ? allPurchaseOrder[0]?.bills : '-' }</span> */}
                                  <span style={{ marginLeft: '0.5rem' }}>{item?.bills != 'undefined' ? item?.bills : '-'}</span>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Contact : </b>
                                  {/* <span style={{marginLeft: '0.5rem'}}>{allPurchaseOrder[0]?.contact != 'undefined' ? allPurchaseOrder[0]?.contact : '-' }</span> */}
                                  <span style={{ marginLeft: '0.5rem' }}>{item?.contact != 'undefined' ? item?.contact : '-'}</span>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Delivery Instructions : </b>
                                  {/* <span style={{marginLeft: '0.5rem'}}>{allPurchaseOrder[0]?.delivery != 'undefined' ? allPurchaseOrder[0]?.delivery : '-' }</span> */}
                                  <span style={{ marginLeft: '0.5rem' }}>{item?.delivery != 'undefined' ? item?.delivery : '-'}</span>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Payment : </b>
                                  {/* <span style={{marginLeft: '0.5rem'}}>{allPurchaseOrder[0]?.payment != 'undefined' ? allPurchaseOrder[0]?.payment : '-' }</span> */}
                                  <span style={{ marginLeft: '0.5rem' }}>{item?.payment != 'undefined' ? item?.payment : '-'}</span>
                                </p>
                              </li>
                            </ol>
                          </td>
                        </tr>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item lg={12}>
                            <span>Total Amount : </span>
                            <b>₹ {currencyFormat1(cpd?.reduce(function (acc, b) { return acc + b.totalAmount }, 0))}</b>
                          </Grid>
                          <Grid item lg={12} style={{ overflowWrap: 'break-word' }}>
                            <span> Total Amount in Words : nearly </span>
                            <b> {capitalize(number2words(cpd?.reduce(function (acc, b) { return acc + Math.round(b.totalAmount) }, 0)))} Rupees Only </b>
                          </Grid>
                          <Grid item lg={12}>
                            <span>Note : </span> <b>Total Amount in Words Value is Round Value</b>
                          </Grid>
                        </Grid>

                        {/* <tr>
                          <td colSpan="3" align="">Total Amount: <b>₹ {currencyFormat1(cpd?.reduce(function (acc, b) { return acc + b.totalAmount }, 0))}</b></td>
                        </tr>
                        <span style={{ padding: '5px' }}>Total Amount in Words : nearly <b> {capitalize(number2words(cpd?.reduce(function (acc, b) { return acc + Math.round(b.totalAmount) }, 0)))} Rupees Only </b> </span>
                        <tr>
                          <td ><b>Note : </b> Total Amount in Words Value is Round Value</td>
                        </tr> */}
                      </Grid>
                    </Grid>

                    <tr>
                      <td colSpan="3" className=''>
                        <table cellspacing="0px" cellpadding="2px">
                          <tr>
                            <td width="50%">
                              <b> Declaration: </b> <br />
                              We declare that this invoice shows the actual price of
                              the goods described above and that all particulars are
                              true and correct.The goods sold are intended for end
                              user consumption and not for resale.
                            </td>
                            <td>
                              * This is a computer generated invoice and does not
                              require a physical signature
                            </td>
                          </tr>
                          {/* <tr>
                            <td width="50%"></td>
                            <td>
                              <b> Authorized Signature </b>
                              <br />
                              <br />
                              ...................................
                              <br />
                              <br />
                              <br />
                            </td>
                          </tr> */}
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            }
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
            <Button
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
              onClick={handleIncPrintClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={isPoRemove}
          id="income"
          contentStyle={{
            width: '50%',
            maxWidth: 'none'
          }}
          maxWidth="sm"
          fullWidth
        >
          <Loader isLoading={isLoading} />
          <DialogTitle
            id="scroll-dialog-title"
            onClose={e => {
              setPoRemove(false)
            }}
            style={{ backgroundColor: '#394671', color: '#fff' }}
          >
            Purchase Order Rejection
          </DialogTitle>
          <Formik
            initialValues={{
              comment: '',
            }}
            onSubmit={(values, { resetForm }) => {
              cancelpo();
              resetForm()
            }}
            validationSchema={Yup.object().shape({
              comment: Yup.string().required('Comment is Required'),
            })}
          >
            {({
              errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
            }) => (
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid item md={12} xs={12}>
                    <TextField
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                      label="Comment"
                      name="comment"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e)
                        setRejectedCommentPo(e.target.value)
                        // setAddMail([e.target.value])
                      }}
                      required
                      error={Boolean(touched.comment && errors.comment)}
                      helperText={touched.comment && errors.comment}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                  <Button
                    onClick={e => {
                      setPoRemove(false)
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid',
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ListPurchaseOrder;
