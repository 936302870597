import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import "../../views/auth/style.css";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  ListItem,
  List,
  Select,
  Button,
  InputLabel,
  FormControl,
  makeStyles,
  Divider,
  Popover,
  Typography,
} from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Logo from "src/components/Logo";
import { getloggedInData } from "src/utils/helpers";
import APIKit, { setClientToken, deleteToken } from "src/utils/APIKit";
import { Menu, MenuItem } from "@material-ui/core";
import { RGCA_URLS } from "../../utils/url";
import { accessPermission } from "../../utils/helpers";
import { getProjectData } from "../../Redux/ProjectData/action";
import { useDispatch } from "react-redux";
//  import APIKit, { setClientToken } from '../../utils/APIKit';
import Tooltip from "@material-ui/core/Tooltip";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CreateIcon from "@mui/icons-material/Create";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InfoIcon from "@mui/icons-material/Info";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ReportIcon from "@mui/icons-material/Report";
import PaidIcon from "@mui/icons-material/Paid";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArchiveIcon from '@mui/icons-material/Archive';
import StorefrontIcon from "@mui/icons-material/Storefront";
import PeopleIcon from "@mui/icons-material/People";
import SellIcon from "@mui/icons-material/Sell";
import { Equalizer, Shop2Rounded, ShoppingCart } from "@mui/icons-material";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getProjectDetails } from "src/views/Project/Common/utilities";
import "./style.css";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: "",
    height: "",
    [theme.breakpoints.down("xs")]: {
      width: "25px !important",
      height: "25px !important",
      marginBottom: "20px !important",
    },
  },
  button: {
    backgroundColor: "#394671",
    color: "#fff",
    border: "1px solid",
    "&:hover": {
      backgroundColor: "#1b233d",
      color: "#fff",
    },
  },
  button2: {
    backgroundColor: "#394671",
    color: "#fff",
    border: "1px solid",
    "&:hover": {
      backgroundColor: "#1b233d",
      color: "#fff",
    },
  },
  active: {
    backgroundColor: "balck",
    border: "1px solid black",
  },
  text: {
    fontSize: "1.3rem", // Default width for non-mobile devices
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem", // Custom width for mobile devices (adjust as needed)
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem", // Custom width for mobile devices (adjust as needed)
      marginBottom: "18px",
    },
  },
  master: {
    marginRight: "5px", // Default width for non-mobile devices

    [theme.breakpoints.down("md")]: {
      marginRight: "15px", // Custom width for mobile devices (adjust as needed)
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "50px",
      marginBottom: "15px !important",
    },
  },
}));
const TopBar = ({ className, onMobileNavOpen, ...rest }) => {

  const menuItemRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project } = useParams();
  console.log("project", project);
  const projectDetails = { ...getProjectDetails(project) };
  console.log('ppp', projectDetails);
  const [notifications] = useState([]);
  const [payload, setPayload] = useState({});
  const [projectsData, setProjectsData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [menuPosition, setMenuPosition] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const id = openPopover ? "simple-popover" : undefined;
  const [menuPosition1, setMenuPosition1] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [allProject, setAllProject] = useState([]);
  const [projectN, setProjectN] = useState("");
  const [permissionData, setPermissionData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [masterData, setMasterData] = useState([]);
  const theme = useTheme();
  const [forIndentData, setForIndentData] = useState([]);
  const [newAlectData, setNewAlectData] = useState({
    AlertData: [],
    ArchievData: []
  })

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const classNameFunc = ({ isActive }) => (isActive ? "active_link" : "");

  // const [userData, setUserData] = useState({});

  let userData = JSON.parse(localStorage.getItem("userData"));
  console.log("user data", userData);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    deleteToken();
    localStorage.clear();
    // popupState.close()

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 2000);
    toast.success("Logged out successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    // Permissions()
    if (userData?.isAdmin == 1) {
      getAllProjects();
    } else {
      getEmpProjects();
    }

    getAllRoles();
    getNotification();
    console.log(
      "path",
      typeof `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
      }`
    );
  }, [window.location]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let projectFAR = [];
  const getInventoryList = (projectID) => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectID,
    })
      .then((res) => {
        if (res.data.status === 200 && res.data.data.length > 0) {
          res.data.data.forEach((el) => {
            if (el.grnQty <= 5) {
              projectFAR.push(el);
              setAllProject(projectFAR);
            }
          });
        } else {
          projectFAR = [];
          setAllProject([]);
        }
      })
      .catch((e) => { });
  };
  const [insuranceDate, setInsuranceDate] = useState([]);
  let InsuranceFAR = [];
  const getFAR = (projectID) => {
    let payload = {
      projectID: projectID,
      fromDate: "",
      toDate: "",
    };
    APIKit.post(RGCA_URLS.getFAR, payload)
      .then((res) => {
        if (res.data.status === 200 && res.data.data.length > 0) {
          const date = new Date();
          // const currentDate = moment(date).format();
          const currentDate = new Date();
          const fiveDaysAhead = new Date(
            currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
          );

          const InsuranceFAR = res?.data?.data?.filter(
            (data) => new Date(data?.insuranceDate) <= fiveDaysAhead
          );

          // InsuranceFAR = res.data.data.filter(
          //   (data) => data.insuranceDate <= currentDate
          // );
          setInsuranceDate(InsuranceFAR);
          console.log(res.data.data, projectFAR, "jkl");
        } else {
          // toast.error(res.data.message);
          InsuranceFAR = [];
          setInsuranceDate([]);
        }
      })
      .catch((e) => { });
  };

  console.log("insuranceDate", insuranceDate);
  const getAllProjects = async () => {
    await APIKit.post(RGCA_URLS.getProjects)
      .then((res) => {
        console.log("projectList res", res);
        if (res.status == 200) {
          localStorage.setItem(
            "projectList",
            JSON.stringify(res.data.data || [])
          );
          // let permission = JSON.parse(localStorage.getItem('projectList'));
          // navigate('/app/dashboard/' + permission[0]?.projectName);
          let list = res.data.data;
          setProjectsData(list);
          setAllProjects(list);
          Permissions({
            innerText: res.data.data[0].projectName,
            value: res.data.data[0].projectID,
          });
        }
      })
      .catch(function (e) { });
  };

  const getEmpProjects = async () => {
    await APIKit.get(RGCA_URLS.getEmployeeProjects)
      .then((res) => {
        // let projectList = []
        // if (localStorage.getItem('projectList')) {
        //   projectList = JSON.parse(localStorage.getItem('projectList'))
        // }
        // let list = res.data.data.filter(x => projectList.find(y => y == x.projectID))
        localStorage.setItem(
          "projectList",
          JSON.stringify(res.data.data || [])
        );
        let permission = JSON.parse(localStorage.getItem("projectList"));
        // navigate('/app/dashboard/' + permission[0]?.projectName);
        let list = res.data.data;
        setProjectsData(list);
        setAllProjects(list);
        Permissions({
          innerText: res.data.data[0].projectName,
          value: res.data.data[0].projectID,
        });
      })
      .catch(function (e) { });
  };

  const Permissions = async (e) => {
    setProjectN(e.innerText);
    navigate("/app/dashboard/" + e.innerText);
    if (userData?.isAdmin == 1) {
      return;
    }

    await APIKit.post(RGCA_URLS.getRolePermissions, {
      accessRoleID: JSON.parse(userData?.project).find(
        (end) => end.projectID == e.value
      ).accessRoleID,
    })
      .then((res) => {
        localStorage.setItem("permissionData", JSON.stringify(res.data.data));
        console.log("per", res.data.data);
        setPermissionData(res.data.data);
        getMasterData(res.data.data);
        getNewNotifications(e.value)
        getInventoryList(e.value);
        getFAR(e.value);
        getPurchaseOrderForIndentData(e.value);
      })
      .catch(function (e) { });

    // await APIKit.post(RGCA_URLS.getPermissions, {
    //   projectID: e.value,
    //   employeeID: JSON.parse(localStorage.getItem('userData')).employeeID,
    //   accessRoleID: JSON.parse((userData?.project)).find(
    //     end => end.projectID == e.value
    //   ).accessRoleID
    // })
    // .then(res => {
    //   localStorage.setItem('permissionData', JSON.stringify(res.data.data));
    //   setPermissionData(res.data.data);
    //   getInventoryList(res.data.data[0].projectID);
    //   getFAR(res.data.data[0].projectID);
    // })
    // .catch(function (e) { });
  };

  const getMasterData = (data) => {
    let result = data.filter(
      (x) =>
        x.actionName == "create_employee" ||
        x.actionName == "edit_employee" ||
        x.actionName == "view_employee" ||
        x.actionName == "Delete_employee" ||
        x.actionName == "create_vendor" ||
        x.actionName == "view_vendor" ||
        x.actionName == "edit_vendor" ||
        x.actionName == "delete_vendor" ||
        x.actionName == "create_notice" ||
        x.actionName == "view_notice" ||
        x.actionName == "edit_notice" ||
        x.actionName == "delete_notice"
    );

    console.log("result", result);
    setMasterData(result);
  };

  //  if([26,27,28,29,36,37,38,39,40,41,42,43]?.findIndex((p) => p == x.actionID) != -1 ){
  // ;
  //     }

  // static

  const accessList = {
    indent: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    indentApproval: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    purchaseOrder: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    RGN: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    invoice: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    inventory: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
    manage: {
      create: true,
      view: true,
      edit: true,
      delete: true,
    },
  };
  localStorage.setItem("access", JSON.stringify(accessList));

  //static

  const manageRoles = accessPermission("manage");
  // console.log("manageRoles:",manageRoles)

  const handleRightClick = (event) => {
    if (menuPosition) {
      return;
    }
    // event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };
  const handleRightClick1 = (event) => {
    if (menuPosition1) {
      return;
    }
    // event.preventDefault();
    setMenuPosition1({
      top: event.pageY,
      left: event.pageX,
    });
  };
  const handleItemClick = (event) => {
    setMenuPosition(null);
  };
  const handleItemMouse = (data) => {
    dispatch(getProjectData(data));
  };
  const handleItemClick1 = (event) => {
    setMenuPosition1(null);
  };

  const handleClosePopOver = (event) => {
    setOpenPopover(false);
  };
  const handleItemClickPop = (event) => {
    setOpenPopover(false);
  };

  const checkPermission = (action) => {
    return permissionData?.find((x) => x.actionName == action);
  };
  const [allRole, setAllRole] = useState([]);
  const [role, setRole] = useState("");

  // useEffect(()=>{
  //   let data = localStorage.getItem('userData');
  //   console.log("login data",JSON.parse(data));
  //   if(data)
  //   setUserData(JSON.parse(data))
  //     },[])

  const getAllRoles = () => {
    APIKit.get(RGCA_URLS.getRoles)
      .then((res) => {
        if (res.data.status === 200) {
          let role = [];
          role = res.data.data;
          setAllRole(res.data.data);
          role.map((element) => {
            if (element.accessRoleID == getloggedInData().accessRoleID) {
              setRole(element.name);
            }
          });
        } else {
        }
      })
      .catch(function (e) { });
  };
  const goToProfile = () => {
    navigate("/app/UserProfile");
  };
  const getNotification = () => {
    APIKit.get(RGCA_URLS.getNotification)
      .then((res) => {
        if (res.data.status === 200) {
          setNotificationData(res.data.data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const getPurchaseOrderForIndentData = (value) => {
    APIKit.get(RGCA_URLS.getPurchaseOrderForIndent + "/" + value)
      .then((res) => {
        if (res.data.status === 200) {
          let datas = [];
          console.log("getPurchaseOrderForIndentData", res.data.data);
          const filterData = res.data.data.map((x) => {
            JSON.parse(x.purchaseOrderItem).map((y) => {
              if (moment(y.deliveryDate).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")) {
                return datas.push({
                  itemName: y.name,
                  noti: "Delivery not yet today",
                });
              }
            });
          });
          setForIndentData(datas);
          console.log("datas", datas);
          // setNotificationData(res.data.data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleOpenClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  //Set item for Navigation
  useEffect(() => {
    window.sessionStorage.setItem("projectN", projectN);
  }, [projectN]);


  const getNewNotifications = (projectId) => {
    APIKit.get(RGCA_URLS.getAlertsData + "/" + projectId)
      .then((res) => {
        if (res.data.status === 200) {
          setNewAlectData(res?.data?.data)

        } else {
        }
      })
      .catch(function (e) { });
  };

  const addArchiveItem = (item) => {
    console.log("addArchiveItem", item);
    console.log("projectId", projectDetails.projectID);
    let payload = {
      projectID: projectDetails.projectID,
      MasterID: item?.MasterID
    }
    APIKit.put(RGCA_URLS.addArchieveListData, payload)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getNewNotifications(projectDetails.projectID);
      } else {
        toast.error(res.data.message)
      }
    })
    .catch((e) => {
    });
  }

  return (
    <>
      {/* <Toolbar style={{ backgroundColor: '#3f51b5' }}> */}

      <div className="main">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "#3f51b5", height: 72 }}
          >
            <Toolbar>
              <div className="lt-logo-header">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open == true ? { display: "none" } : ""),
                  }}
                  style={{ display: open == true ? "none" : "block" }}
                >
                  <MenuIcon />
                </IconButton>

                {getloggedInData()?.roleID == 1 ? (
                  <Tooltip title="Go To Dashboard">
                    <RouterLink to="/app/maindashboard">
                      <Logo />
                    </RouterLink>
                  </Tooltip>
                ) : (
                  <Logo />
                )}
                <div className="lt-logo-header1">
                  <h4>RAJIV GANDHI CENTRE FOR AQUACULTURE</h4>
                  <h5>राजीव गांधी जलकृषि केंद्र</h5>
                </div>
              </div>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <div className="header-rt-parent-div">
                      <div className="header-master">
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                          className={classes.button}
                          style={{ fontSize: '12px' }}
                        >
                          {projectN}
                        </Button>
                      </div>
                      <Menu {...bindMenu(popupState)}>
                        {allProjects &&
                          allProjects.map((x) => {
                            return (
                              <MenuItem
                                onClick={(e) => {
                                  Permissions(e.target);
                                  console.log("pro-id", e.target.value);
                                }}
                                value={x.projectID}
                                key={x.projectID}
                              >
                                {x.projectName}
                              </MenuItem>
                            );
                          })}
                      </Menu>

                      <div className="rt-header-cnt">
                        {/* {manageRoles.view &&
                          getloggedInData().designationID == 1 && ( */}
                        {userData?.isAdmin == 1 ? (
                          <IconButton
                            style={{
                              color: "#fff",
                              display: "contents",
                              justifyContent: "end",
                            }}
                            onClick={handleRightClick1}
                          >
                            <>
                              <div className="master-div-box">
                                <Box
                                  className="Master-MUI-Button"
                                  style={{
                                    marginTop: "0px",
                                    display: "flex",
                                    justifyContent: "end",
                                    marginRight: "5px",
                                  }}
                                >
                                  <Typography className={classes.master}>
                                    Master{" "}
                                    <ExpandMoreIcon
                                      style={{
                                        position: "absolute",
                                        marginTop: "1px",
                                        marginRight: "2px",
                                      }}
                                    />
                                  </Typography>
                                </Box>
                                <Menu
                                  open={!!menuPosition1}
                                  onClose={() => setMenuPosition1(null)}
                                  anchorReference="anchorPosition"
                                  anchorPosition={menuPosition1}
                                >
                                  <RouterLink to="/app/projectlist">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Projects</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to="/app/AddRoles">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Roles</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <RouterLink to="/app/Department">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Department</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to="/app/Designation">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Designation</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to="/app/Employee">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Employee</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to="/app/Role">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>
                                        Permission Management
                                      </Typography>
                                    </MenuItem>
                                  </RouterLink>

                                  <Divider />

                                  <RouterLink to="/app/Vendor">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Vendor</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to="/app/approvalconfig">
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography>Approval Config</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to={"/app/addnotice/" + projectN}>
                                    <MenuItem onClick={handleItemClick1}>
                                      <Typography> Notice</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                </Menu>
                              </div>
                            </>
                          </IconButton>
                        ) : (
                          <>
                            {masterData.length > 0 && (
                              <IconButton
                                style={{
                                  color: "#fff",
                                  display: "contents",
                                  justifyContent: "end",
                                }}
                                onClick={handleRightClick1}
                              >
                                <>
                                  <div className="master-div-box">
                                    <Box
                                      className="Master-MUI-Button"
                                      style={{
                                        marginTop: "0px",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <Typography className={classes.master}>
                                        Master{" "}
                                        <ExpandMoreIcon
                                          style={{
                                            position: "absolute",
                                            marginTop: "1px",
                                            marginRight: "2px",
                                          }}
                                        />
                                      </Typography>
                                    </Box>
                                    <Menu
                                      open={!!menuPosition1}
                                      onClose={() => setMenuPosition1(null)}
                                      anchorReference="anchorPosition"
                                      anchorPosition={menuPosition1}
                                    >
                                      {masterData.find(
                                        (x) =>
                                          x.actionName == "create_employee" ||
                                          x.actionName == "view_employee" ||
                                          x.actionName == "edit_employee" ||
                                          x.actionName == "Delete_employee"
                                      ) && (
                                          <RouterLink to="/app/Employee">
                                            <MenuItem onClick={handleItemClick1}>
                                              <Typography>Employee</Typography>
                                            </MenuItem>
                                          </RouterLink>
                                        )}

                                      <Divider />

                                      {masterData.find(
                                        (x) =>
                                          x.actionName == "create_vendor" ||
                                          x.actionName == "view_vendor" ||
                                          x.actionName == "edit_vendor" ||
                                          x.actionName == "delete_vendor"
                                      ) && (
                                          <RouterLink to="/app/Vendor">
                                            <MenuItem onClick={handleItemClick1}>
                                              <Typography>Vendor</Typography>
                                            </MenuItem>
                                          </RouterLink>
                                        )}
                                      <Divider />
                                      {masterData.find(
                                        (x) =>
                                          x.actionName == "create_notice" ||
                                          x.actionName == "view_notice" ||
                                          x.actionName == "edit_notice" ||
                                          x.actionName == "delete_notice"
                                      ) && (
                                          <RouterLink
                                            to={"/app/addnotice/" + projectN}
                                          >
                                            <MenuItem onClick={handleItemClick1}>
                                              <Typography>Notice</Typography>
                                            </MenuItem>
                                          </RouterLink>
                                        )}
                                    </Menu>
                                  </div>
                                </>
                              </IconButton>
                            )}
                          </>
                        )}
                        {/* <div style={{ display: 'contents' }}> */}
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <div className="rt-super-sec">
                                <Box
                                  className="super-txt"
                                  style={{ marginRight: "3px" }}
                                >

                                  <Typography
                                    variant="span"
                                    className={classes.text}
                                  >
                                    {getloggedInData()?.empLoginID}  <br />
                                    <Typography style={{ fontSize: '12px', color: "aqua" }}>
                                      {projectDetails?.accessRoleName}
                                    </Typography>
                                    <Typography style={{ fontSize: '12px', color: "blanchedalmond" }}>

                                      {getloggedInData() && getloggedInData()?.firstName && getloggedInData()?.firstName.length > 0 && getloggedInData()?.firstName[0].toUpperCase()
                                        +
                                        getloggedInData().firstName.slice(1)
                                        ||
                                        '' + ' ' +
                                        getloggedInData().lastName
                                      }

                                    </Typography>

                                  </Typography>




                                </Box>

                                <div>
                                  <Avatar
                                    {...bindTrigger(popupState)}
                                    // style={{ marginLeft: 'auto' }}
                                    sx={{ bgcolor: deepOrange[500], cursor: 'pointer' }}
                                    className={classes.avatar}
                                  >
                                    {getloggedInData().firstName &&
                                      getloggedInData()
                                        .firstName?.charAt(0)
                                        .toUpperCase()}
                                  </Avatar>
                                </div>
                                <Menu {...bindMenu(popupState)}>
                                  <RouterLink to={"/app/UserProfile"}>
                                    <MenuItem onClick={popupState.close}>
                                      My Profile
                                    </MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <RouterLink to={"/app/ProfileChangePassword"}>
                                    <MenuItem>Change Password</MenuItem>
                                  </RouterLink>
                                  <Divider />
                                  <MenuItem onClick={logout}>Logout</MenuItem>
                                </Menu>
                              </div>
                            </React.Fragment>
                          )}
                        </PopupState>

                        {/* {allProject.length > 0 || insuranceDate.length > 0 ? ( */}
                        {newAlectData.AlertData.length > 0 ? (
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "",
                                    justifyContent: "center",
                                  }}
                                >
                                  <NotificationsIcon
                                    style={{
                                      color: "azure",
                                      position: "relative",
                                      marginTop: "10px",
                                      cursor: 'pointer'
                                    }}
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  />
                                  <sup
                                    style={{
                                      color: "white",
                                      position: "absolute",
                                      backgroundColor: "red",
                                      borderRadius: "50px",
                                      width: "20px",
                                      marginRight: "-10px",
                                      marginBottom: "20px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {/* {allProject.length + insuranceDate.length + forIndentData.length} */}
                                    {newAlectData?.AlertData?.length}
                                  </sup>
                                </ListItemIcon>
                                {/* {console.log(allProject.length, "length")} */}
                                <Menu
                                  {...bindMenu(popupState)}
                                  style={{ width: "650px", height: "450px" }}
                                >

                                  {/* {allProject &&
                                    allProject.map((x) => {
                                      return (
                                        <MenuItem style={{ width: "650px" }} key={index + 1}>
                                          {x.itemName} is low in your inventory
                                          <Tooltip title="Archive">
                                            <IconButton>
                                              <ArchiveIcon
                                                style={{
                                                  color: "red",
                                                  cursor: 'pointer',
                                                }}
                                                variant="contained"
                                              />
                                            </IconButton>
                                          </Tooltip>

                                        </MenuItem>
                                      );
                                    })} */}

                                  {/* {insuranceDate &&
                                    insuranceDate.map((insurance) => {
                                      return (
                                        <MenuItem key={x?.MasterID}>
                                          {insurance?.name}
                                          {`(${insurance?.farMasterID})`}{" "}
                                          insurance is expiry soon. 
                                          <Tooltip title="Archive">
                                            <IconButton>
                                              <ArchiveIcon
                                                style={{
                                                  color: "red",
                                                  cursor: 'pointer',
                                                }}
                                                variant="contained"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </MenuItem>
                                      );
                                    })} */}
                                  {newAlectData && newAlectData.AlertData && newAlectData.AlertData.length > 0 &&
                                    newAlectData?.AlertData.map((item, i) => {
                                      return (
                                        <MenuItem key={i + 1}>
                                          {item?.ItemName}{' '} {`(${item.MasterID})`} {' '} {item?.Message}
                                          <Tooltip title="Archive">
                                            <IconButton>
                                              <ArchiveIcon
                                                style={{
                                                  color: "red",
                                                  cursor: 'pointer',
                                                }}
                                                variant="contained"
                                                onClick={() => {
                                                  addArchiveItem(item)
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </MenuItem>
                                      );
                                    })}
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        ) : (
                          ""
                        )}

                        {/* Archave notifications */}

                        {newAlectData && newAlectData?.ArchievData?.length > 0 ? (
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ArchiveIcon
                                    style={{
                                      color: "azure",
                                      position: "relative",
                                      marginTop: "10px",
                                      cursor: 'pointer'
                                    }}
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  />

                                </ListItemIcon>
                                <Menu
                                  {...bindMenu(popupState)}
                                  style={{ width: "650px", height: "450px" }}
                                >
                                  <MenuItem style={{
                                    width: "650px",
                                    backgroundColor: "#00bcd4",
                                    color: "red",
                                    fontWeight: "bold",
                                    marginTop: "-8px"
                                  }} value="0">Archive List</MenuItem>
                                  {newAlectData && newAlectData?.ArchievData && newAlectData.ArchievData.length > 0 &&
                                    newAlectData?.ArchievData.map((item, i) => {
                                      return (
                                        <MenuItem style={{ width: "650px" }}>
                                          {item?.ItemName}{' '} {`(${item.MasterID})`} {' '} {item?.Message}
                                        </MenuItem>
                                      );
                                    })}
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        ) : (
                          ""
                        )}

                        {/* end */}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </PopupState>{" "}
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <div>
                <Typography style={{ color: "azure" }}> RGCA </Typography>
              </div>
              <div>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon style={{ color: "azure" }} />
                  ) : (
                    <ChevronLeftIcon style={{ color: "azure" }} />
                  )}
                </IconButton>
              </div>
            </DrawerHeader>
            <Divider />

            <List>
              <div
                style={{ whiteSpace: open == true ? "break-spaces" : "nowrap" }}
              >
                <ListItemButton
                  className={
                    `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                      }` == "app/dashboard"
                      ? "active"
                      : "non-active-class"
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <RouterLink to={"/app/dashboard/" + projectN}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Equalizer style={{ color: "azure" }} />
                    </ListItemIcon>
                  </RouterLink>

                  <ListItemText
                    primary={
                      <NavLink to={"/app/dashboard/" + projectN}>
                        <ListItemText
                          primary={
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Dashboard{" "}
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </NavLink>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                      }` == "app/profile"
                      ? "active"
                      : "non-active-class"
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <RouterLink to={"/app/profile/" + projectN}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <InfoIcon style={{ color: "azure" }} />
                    </ListItemIcon>
                  </RouterLink>

                  <ListItemText
                    primary={
                      <NavLink to={"/app/profile/" + projectN}>
                        <Typography style={{ color: "azure" }}>
                          {" "}
                          Project Profile{" "}
                        </Typography>
                      </NavLink>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                {(checkPermission("view_currentProjectReports_inventory") ||
                 checkPermission("view_currentProjectReports_sales") ||
                 checkPermission("view_currentProjectReports_production") ||
                 checkPermission("view_currentProjectReports_payment") ||
                 checkPermission("view_currentProjectReports_productionandsales") ||
                 checkPermission("view_allProjectReports_inventory") ||
                 checkPermission("view_allProjectReports_sales") ||
                 checkPermission("view_allProjectReports_production") ||
                 checkPermission("view_allProjectReports_payment") ||
                 checkPermission("view_allProjectReports_productionandsales") ||
                  userData?.isAdmin == 1) && (
                <ListItemButton
                  className={
                    `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                      }` == "app/reports"
                      ? "active"
                      : "non-active-class"
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <RouterLink to={"/app/reports/" + projectN}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ReportIcon style={{ color: "azure" }} />
                    </ListItemIcon>
                  </RouterLink>

                  <ListItemText
                    primary={
                      <NavLink to={"/app/reports/" + projectN}>
                        <Typography style={{ color: "azure" }}>
                          {" "}
                          Reports
                        </Typography>
                      </NavLink>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                )}

                {(checkPermission("create_Materials") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/Assets"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/Assets/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <CreateIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>

                      <ListItemText
                        primary={
                          <NavLink to={"/app/Assets/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Material / Item / Asset Creation
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}

                {(checkPermission("view&edit_indent") ||
                  checkPermission("approval_indent") ||
                  checkPermission("create_indent") ||
                  checkPermission("create_purchaseOrder") ||
                  checkPermission("view_indent") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/IndentEdit"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/IndentEdit/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <ShoppingCart style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        primary={
                          <NavLink to={"/app/IndentEdit/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Purchase Indents
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}

                {(checkPermission("view_purchaseOrder") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/Purchases"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/Purchases/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Shop2Rounded style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        primary={
                          <NavLink to={"/app/Purchases/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Purchases{" "}
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}

                {/* inventory */}
                {(checkPermission("view_inventroyRegister") ||
                  checkPermission("create_inventroyRegister") ||
                  checkPermission("view_GRN") ||
                  checkPermission("create_GRN") ||
                  checkPermission("view_FAR") ||
                  checkPermission("create_FAR") ||
                  checkPermission("view&edit_GIN") ||
                  checkPermission("create_GIN") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/inventory"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/inventory/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <InventoryRoundedIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        primary={
                          <NavLink to={"/app/inventory/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Inventory
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                {/* end */}

                {(checkPermission("view&edit_workOrder") ||
                  checkPermission("create_workOrder") ||
                  checkPermission("approval_workOrder") ||
                  checkPermission("approved_workOrder") ||
                  checkPermission("view_workOrder") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/workorder"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/workorder/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <GroupWorkIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        primary={
                          <NavLink to={"/app/workorder/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Work Order
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}

                {(checkPermission("view&edit_storeIndent") ||
                  checkPermission("create_storeIndent") ||
                  checkPermission("approval_storeIndent") ||
                  checkPermission("approved_storeIndent") ||
                  checkPermission("view_storeIndent") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/StoreIndent"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/StoreIndent/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <StorefrontIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        // className={`${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]}`== "app/profile" ? "active": "non-active-class" }

                        primary={
                          <NavLink to={"/app/StoreIndent/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Store Indent{" "}
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                {(checkPermission("create_Sales") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/Sales"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/Sales/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <PaidIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        className={
                          `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                            }` == "app/Sales"
                            ? "active"
                            : "non-active-class"
                        }
                        primary={
                          <NavLink to={"/app/Sales/" + projectN} exact>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Production{" "}
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                {(checkPermission("create_Selling") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/Selling"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/Selling/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <SellIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        className={
                          `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                            }` == "app/Selling"
                            ? "active"
                            : "non-active-class"
                        }
                        primary={
                          <NavLink to={"/app/Selling/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Sales{" "}
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                {(checkPermission("create_customer") ||
                  userData?.isAdmin == 1) && (
                    <ListItemButton
                      className={
                        `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                          }` == "app/Customer"
                          ? "active"
                          : "non-active-class"
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <RouterLink to={"/app/Customer/" + projectN}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <PeopleIcon style={{ color: "azure" }} />
                        </ListItemIcon>
                      </RouterLink>
                      <ListItemText
                        className={
                          `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                            }` == "app/Customer"
                            ? "active"
                            : "non-active-class"
                        }
                        primary={
                          <NavLink to={"/app/Customer/" + projectN}>
                            <Typography style={{ color: "azure" }}>
                              {" "}
                              Customer{" "}
                            </Typography>
                          </NavLink>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                {/* {(checkPermission("create_customer") ||
                userData?.isAdmin == 1) && ( */}
                <ListItemButton
                  className={
                    `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                      }` == "app/Training"
                      ? "active"
                      : "non-active-class"
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <RouterLink to={"/app/Training/" + projectN}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <PeopleIcon style={{ color: "azure" }} />
                    </ListItemIcon>
                  </RouterLink>
                  <ListItemText
                    className={
                      `${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
                        }` == "app/Training"
                        ? "active"
                        : "non-active-class"
                    }
                    primary={
                      <NavLink to={"/app/Training/" + projectN}>
                        <Typography style={{ color: "azure" }}>
                          Training
                        </Typography>
                      </NavLink>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                {/* )} */}
              </div>
            </List>
            <Divider />
          </Drawer>
        </Box>
      </div >
      <Hidden lgUp>
        <IconButton style={{ color: "#fff" }} onClick={onMobileNavOpen}>
          <MenuIcon />
        </IconButton>
      </Hidden>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
