import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import { Pie } from 'react-chartjs-2';
import { CanvasJSChart } from 'canvasjs-react-charts'
import { RGCA_URLS } from 'src/utils/url';
import { useNavigate } from 'react-router-dom';
import APIKit from 'src/utils/APIKit';
import { getProjectDetails } from './../Project/Common/utilities';
import { useParams } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const Portfolio = ({ className, projectID, testdata, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  var data;
  const { project } = useParams();
  const navigate = useNavigate();
  const projectDetails = projectID || { ...getProjectDetails(project) }

  const [sales, setSales] = React.useState([])
  const getSalesPieChart = () => {
    APIKit.get(RGCA_URLS.getSalesPieChart + '/' + (projectDetails.projectID || projectID))
      .then((res) => {
        if (res.data.status === 200) {
          setSales([
            {
              type: "Karanthai- Early Fry",
              Target: "40000",
              Achived: "18000"
            },
            {
              type: "Karanthai- FingerLings",
              Target: "48000",
              Achived: "35000"
            },
            {
              type: "Thatankulam - Early fry",
              Target: "400000",
              Achived: "10000"
            },
            {
              type: "Neithalur - Fingerlings",
              Target: "200000",
              Achived: "80000"
            },
            {
              type: "Agarapettai - Fingerlings",
              Target: "609000",
              Achived: "196000"
            },
            {
              type: "Thirumangalakottai - Fingerlings",
              Target: "19609",
              Achived: "10400"
            },
          ]);

        } else {
        }
      })
      .catch((e) => {
      });
  };
  useEffect(() => {
    getSalesPieChart()
  }, [projectID, navigate])
  if (sales.length) {
    let obj = {
      type: [],
      qty: [],
      value: []
    };

    sales.forEach(y => {

      obj.type.push(y.type)
      obj.qty.push(y.qty)
      obj.value.push(y.value)
    })

    var finaldata = obj

    data = {
      labels: finaldata.type,
      datasets: [{
        data: finaldata.value,
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#DAF7A6',
          '#40E0D0',
          "#CCCCFF"
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#DAF7A6',
          '#40E0D0',
          "#CCCCFF"
        ]
      }]
    };

  }

  const options = {
    title: {
      display: true,
      text: 'Revenue',
      fontSize: 20
    },
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    type: "Pie"

  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      {/* <CardHeader

        title="Revenue"
      /> */}
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          {data && (<Pie data={data}
            options={options}
          />)}
        </Box>
      </CardContent>

    </Card>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string
};

export default Portfolio;
