import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Capital from './capital';
import Recurring from './recurring';
import NoticeBoard from './noticeboard';
import BirthdayNotifcation from './birthday';
import '../../AddProject/style.css'
import RisedIndent from './risedIndent';
import Purchase from './purchase';
import Portfolio from 'src/views/dashboard/Portfolio';
import FingerLingsPieChart from 'src/views/dashboard/FingerLings';
import AvailableStock from './stock';
import Sales from 'src/views/dashboard/Sales';
import { ChartsData } from 'src/views/dashboard/data';
import Revenue from 'src/views/dashboard/revenue';
import Assigned from './assigned';
import StoreIndentDashboard from './storeIndent';
import { RGCA_URLS } from 'src/utils/url';
import { useNavigate, useParams } from 'react-router-dom';

import { getProjectDetails } from '../Common/utilities';
import APIKit from 'src/utils/APIKit';
import { checkPermission } from 'src/utils/permission';
import AllProjectStock from './allProjectStock';
import LowStock from './lowstock';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProjectDashboard = () => {
  const classes = useStyles();
  const [testdata, setTestdata] = React.useState([]);
  const { project } = useParams();
  const navigate = useNavigate();
  const projectDetails = { ...getProjectDetails(project) };
  const [blist, setBlist] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [productionGraph, setProductionGraph] = useState([])
  const [salesGraph, setSalesGraph] = useState([]);
  const [lowStockData, setLowStockData] = useState([]);
  let userData = JSON.parse(localStorage.getItem('userData'));
  const [productionYear, setProductionYear] = useState('');
  const [saleYear, setSaleYear] = useState('');





  useEffect(() => {
    segrate("Thiruvallur")
    getBirthdayList();
    getNotificationsData();
    getAssignedFarData();

    // getRecurringProjectDashboardChart();
    // getCapitalProjectDashboardChart();
    getLowStockListData();
  }, [projectDetails.projectID, navigate])

  useEffect(() => {
    getProductionGraph();
    getSalesGraph();
  }, [projectDetails.projectID, navigate, saleYear, productionYear])

  const segrate = (location) => {
    let data = []
    ChartsData.AchivementData.forEach((x, i) => {
      if (x.Location == location && x.year == "2019") {
        setTestdata(x)

      }
    })

  }

  const getNotificationsData = async () => {
    const res = await APIKit.get(RGCA_URLS.getNotificationsDataBasedonprojectID + '/' + projectDetails.projectID);
    if (res.data.status === 200) {
      setNoticeData(res.data.data)
    }
  }

  const getBirthdayList = () => {
    //  getEmployeeBirthdayList
    APIKit.get(`${RGCA_URLS.getEmployeeBirthdayListBasedonprojectID + '/' + projectDetails.projectID}`)
      .then(res => {
        if (res.data.status === 200) {
          setBlist(res.data.data);
        } else {
          setBlist([]);
        }
      })
      .catch(e => { });
  };

  const getAssignedFarData = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    console.log("userData", userData);

    let payload = {
      projectID: projectDetails?.projectID,
      assignedTo: userData?.empLoginID
    }
    APIKit.post(
      RGCA_URLS.getAssignedFarData, payload
    )
      .then(res => {
        if (res.data.status === 200) {
          console.log("getAssignedFarData", res.data.data);
          setAssigned(res.data.data)
        } else {
        }
      })
      .catch(e => { });
  };

  const getProductionGraph = () => {

    let payload = {
      projectID: projectDetails.projectID,
      selectedYear: productionYear
    }

    APIKit.post(RGCA_URLS.getProductionGraphData, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setProductionGraph(res?.data?.data);
        } else {
          setProductionGraph([])
        }
      })
      .catch((e) => {
      });
  };

  const getSalesGraph = () => {

    let payload = {
      projectID: projectDetails.projectID,
      selectedYear: saleYear
    }

    APIKit.post(RGCA_URLS.getSalesGraphData, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setSalesGraph(res.data.data);

        } else {
        }
      })
      .catch((e) => {
      });
  };

  const [capData, setCapData] = useState([])
  const [recData, setRecData] = useState([])



  const getRecurringProjectDashboardChart = () => {
    APIKit.get(RGCA_URLS.getRecurringProjectDashboardChart + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          // setRecurringList(res.data.data);
          setRecData(res.data.data)
        } else {
        }
      })
      .catch((e) => {
      });
  };

  const getCapitalProjectDashboardChart = () => {
    APIKit.get(RGCA_URLS.getCapitalProjectDashboardChart + '/' + (projectDetails.projectID))
      .then((res) => {
        if (res.data.status === 200) {
          // setCapitalList(res.data.data);
          setCapData(res.data.data)
        } else {
        }
      })
      .catch((e) => {
      });
  };

  const getLowStockListData = () => {
    APIKit.get(RGCA_URLS.getLowStockList + '/' + (projectDetails.projectID))
      .then((res) => {
        if (res.data.status === 200) {
          setLowStockData(res?.data?.data)
        } else {
          setLowStockData([])
        }
      })
      .catch((e) => {
      });
  };


  return (

    <Page className={classes.root} title="Dashboard" >
      <Container maxWidth={false}>
        <Grid container spacing={3} >
          <Grid item lg={6} sm={12} xl={6} xs={12} >
            <NoticeBoard noticeData={noticeData} />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}   >
            <BirthdayNotifcation blist={blist} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}  >
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <RisedIndent />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}  >
            <Purchase />

          </Grid>
          <Grid item lg={6} sm={12} xl={12} xs={12} style={{ marginTop: '10px' }}>
            <StoreIndentDashboard />
          </Grid>
          <Grid item lg={6} sm={12} xl={12} xs={12} style={{ marginTop: '10px' }}>
            <Assigned assigned={assigned} />
          </Grid>
          {(checkPermission("view_dashBoardProductionAndSalesCurrentProject") ||
            userData?.isAdmin == 1) &&
            <Grid item lg={6} sm={12} xl={12} xs={12} style={{ marginTop: '10px' }}>
              <AvailableStock />
            </Grid>
          }

          {(checkPermission("view_dashBoardProductionAndSalesAllProjects") ||
            userData?.isAdmin == 1) &&
            <Grid item lg={6} sm={12} xl={12} xs={12} style={{ marginTop: '10px' }}>
              <AllProjectStock />
            </Grid>
          }

          {(checkPermission("view_dashBoardStockLow") ||
            userData?.isAdmin == 1) &&
            <Grid item lg={6} sm={12} xl={12} xs={12} style={{ marginTop: '10px' }}>
              <LowStock lowStockData={lowStockData} />
            </Grid>
          }

        </Grid>
        {/* <Typography>
          {(capData.length != 0 || recData.length != 0 || productionGraph.length != 0 || salesGraph.length != 0) &&
            <h2 style={{ color: '#394671', marginBottom: '5px', marginTop: '10px' }}>Project Dashboard</h2>
          }
        </Typography> */}
        <Grid container spacing={2} >
          {(checkPermission('view_dashBoardCapital') ||
            userData?.isAdmin == 1) &&
            <>
              {/* {capData && capData.length != 0 && */}
              <Grid item lg={6} sm={12} xl={6} xs={12}>
                <Capital setCapData={setCapData} />
              </Grid>
              {/* } */}
            </>

          }
          {(checkPermission('view_dashBoardRecurring') ||
            userData?.isAdmin == 1) &&
            <>
              {/* {recData && recData.length != 0 && */}
              <Grid item lg={6} sm={12} xl={6} xs={12}>
                <Recurring setRecData={setRecData} />
              </Grid>
              {/* } */}
            </>

          }
          {(checkPermission('view_dashBoardProduction') ||
            userData?.isAdmin == 1) &&
            <Grid item lg={6} sm={12} xl={6} xs={12}>
              {/* {productionGraph && productionGraph.length != 0 && */}
              <Revenue testdata={testdata} productionGraph={productionGraph}
                setProductionYear={setProductionYear} productionYear={productionYear} />
              {/* } */}
            </Grid>
          }
          {(checkPermission('view_dashBoardSales') ||
            userData?.isAdmin == 1) &&
            <Grid item lg={6} sm={12} xl={6} xs={12}>
              {/* {salesGraph && salesGraph.length != 0 && */}
              <Sales testdata={testdata} salesGraph={salesGraph}
                setSaleYear={setSaleYear} saleYear={saleYear} />
              {/* } */}
            </Grid>
          }
        </Grid>
      </Container>
    </Page>
  )

}
export default ProjectDashboard;