
export const itemTypes = [{
    itemTypeID: 1,
    name: "Capital"
},
{
    itemTypeID: 2,
    name: "Recurring"
}
]

//Category data

export const categoryList = [
    //capital
    {
        categoryID: 1,
        itemTypeID: 1,
        name: "Cost of Land"
    },
    {
        categoryID: 2,
        itemTypeID: 1,
        name: "Furniture & Fittings"
    },
    {
        categoryID: 3,
        itemTypeID: 1,
        name: "MACHINERIES & EQUIPMENTS"
    },
    {
        categoryID: 4,
        itemTypeID: 1,
        name: "Vehicles"
    },
    //Recurring
    {
        categoryID: 5,
        itemTypeID: 2,
        name: "Maintenance Expenditure"
    },
    {
        categoryID: 6,
        itemTypeID: 2,
        name: "Office & Administrative Expenditure"
    },
    // {
    //     categoryID: 7,
    //     itemTypeID: 2,
    //     name: "OFFICE & ADMINISTRATIVE EXPENSES"
    // },
    {
        categoryID: 8,
        itemTypeID: 2,
        name: "OPERATING EXPENSES"
    },
]


//sub Category data

export const subCategoryList = [
    // CAPITAL
    {
        subCategoryID: 1,
        categoryID: 1,
        name: 'Land'
    },
    {
        subCategoryID: 2,
        categoryID: 2,
        name: 'Tables'
    },
    // {
    //     subCategoryID: 22,
    //     categoryID: 2,
    //     name: 'Chair'
    // },
    {
        subCategoryID: 3,
        categoryID: 2,
        name: 'Chair'
    },
    {
        subCategoryID: 4,
        categoryID: 3,
        name: 'PUMPS'
    },
    {
        subCategoryID: 5,
        categoryID: 3,
        name: 'UV SYSTEM'
    },
    {
        subCategoryID: 6,
        categoryID: 4,
        name: 'Four Wheeler'
    },
    {
        subCategoryID: 7,
        categoryID: 4,
        name: 'Two Wheeler'
    },

    // Recurring

    {
        subCategoryID: 8,
        categoryID: 5,
        name: 'ELECTRICAL FITTINGS'
    },
    {
        subCategoryID: 9,
        categoryID: 5,
        name: 'PVC PIPES & FITTINGS'
    },

    {
        subCategoryID: 10,
        categoryID: 6,
        name: 'BANK CHARGES'
    },
    {
        subCategoryID: 11,
        categoryID: 6,
        name: 'OFFICE EXP/MISCELLANEOUS'
    },

    {
        subCategoryID: 12,
        categoryID: 8,
        name: 'LAB CONSUMABLES '
    },
    {
        subCategoryID: 13,
        categoryID: 8,
        name: 'FEED'
    },

]



export const itemsList = [
    {
        itemTypeID: 1,
        itemID:1,
        subCategoryID: 1,
        name: 'air conditionar/LG/1.0TON/M.NO 110 ',
        available:2
    },
    {
        itemTypeID: 1,
        itemID:1,
        subCategoryID: 2,
        name: 'Tables',
        available:10
    },
    {
        itemTypeID: 1,
        itemID:2,
        subCategoryID: 3,
        name: 'Chair',
        available:3
    },
    {
        itemTypeID: 1,
        itemID:3,
        subCategoryID: 4,
        name: '5 HP',
        available:32
    },
    {
        itemTypeID: 1,
        itemID:4,
        subCategoryID: 4,
        name: '6 HP',
        available:2
    },
    {
        itemTypeID: 1,
        itemID:5,
        subCategoryID: 5,
        name: 'UV system',
        available:24
    },
    {
        itemTypeID: 1,
        itemID:6,
        subCategoryID: 5,
        name: 'Ultraviolet',
        available:5
    },

    {
        itemTypeID: 1,
        itemID:7,
        subCategoryID: 6,
        name: 'Scorpio',
        available:0
    },
    {
        itemTypeID: 1,
        itemID:8,
        subCategoryID: 6,
        name: 'Omni',
        available:22
    },
    {
        itemTypeID: 1,
        itemID:9,
        subCategoryID: 7,
        name: 'Honda CD 110 Dream',
        available:6
    },
    {
        itemTypeID: 1,
        itemID:9,
        subCategoryID: 7,
        name: 'Hero Glamour',
        available:8
    },



    //recurring 
    {
        itemTypeID: 2,
        itemID:10,
        subCategoryID: 8,
        name: `3/4' 2 way Junction box`,
        available:5
    },
    {
        itemTypeID: 2,
        itemID:11,
        subCategoryID: 8,
        name: `3/4' 3 way Junction box`,
        available:5
    },
    {
        itemTypeID: 2,
        itemID:12,
        subCategoryID: 9,
        name: `20 mm PVC Elbow`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:13,
        subCategoryID: 9,
        name: `20 mm PVC Tee`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:14,
        subCategoryID: 10,
        name: `New cheque book charges`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:15,
        subCategoryID: 10,
        name: `Service/Commission charges`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:16,
        subCategoryID: 11,
        name: `Stationary charges`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:17,
        subCategoryID: 11,
        name: `Printing charges`,
        available:0
    },
    {
        itemTypeID: 2,
        itemID:17,
        subCategoryID: 12,
        name: `20 𝛍l tips`,
        available:5
    },
    {
        itemTypeID: 2,
        itemID:18,
        subCategoryID: 12,
        name: `200 𝛍l tips`,
        available:6
    },
    {
        itemTypeID: 2,
        itemID:19,
        subCategoryID: 13,
        name: `Yeast`,
        available:8
    },
    {
        itemTypeID: 2,
        itemID:20,
        subCategoryID: 13,
        name: `Yeast-2`,
        available:9
    }

]