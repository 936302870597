import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  Select,
  TextField,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import { MESSAGE } from 'src/utils/message';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { RGCA_URLS } from '../../utils/url';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Loader from '../loader'
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import { capitalLatter, capitalize } from '../Project/Common/utilities'
import { useConfirm } from "material-ui-confirm";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { APP_URLS } from 'src/utils/apis';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '300px',
      overflowY: 'auto',
    }
  },
  container: {

    maxHeight: '100%',
    '@media (max-width: 500px)': {
      maxHeight: '300px',
      overflowY: 'auto',
    },

  },
  centertext: {
    marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
    '@media (max-width: 500px)': {
      marginLeft: "120px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "80px"
    },
  }
}));

const Role = () => {
  const inputRef = useRef();
  const [allDepartment, setAllDepartment] = useState([])
  const { addToast } = useToasts();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [allRole, setAllRole] = useState([])
  const [roles, setRoles] = useState({ "name": "","departmentID":"" });
  const [editRoles, setEditRoles] = useState({ "name": "","departmentID":""  });
  const [btn, setBtn] = React.useState({ "name": "Save" });
  const [isLoading, setIsLoading] = useState(false)
  const [accessRoles, setAccessRoles] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  const confirm = useConfirm();

  const defaultLoad = () => {
    getAllRoles();
    getAccessRoles();
    getAllDepartment()
  }
  useEffect(() => {
    defaultLoad()
  }, [])




  const getAccessRoles = () => {
    APIKit.get(RGCA_URLS.getAccessRoles)
      .then((res) => {
        if (res.data.status === 200) {
          setAccessRoles(res.data.data)
          // console.log("getAccessRoles-res.data:",res.data)

        }
        else {
        }
      })
      .catch(function (e) {
      })

  }
  const getAllRoles = () => {
    APIKit.get(RGCA_URLS.getRoles)
      .then((res) => {
        if (res.data.status === 200) {
          setAllRole(res.data.data)
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }

  const addRoles = () => {
    const payload = {
      name: roles.name.trim(),
      departmentID:roles.departmentID
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addRole, payload)
      .then((res) => {
        if (res.data.status === 200) {
          //addToast(res.data.message, { appearance: 'success' });
          toast.success(MESSAGE.roleAdded, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          defaultLoad();
          setRoles({ "name": "" });
          setIsLoading(false);
        }
        else {
          // addToast(MESSAGE.RolealreadyExist, { appearance: 'error' });
          setIsLoading(false);
          toast.error(MESSAGE.RolealreadyExist, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      })
  }

  const updateRole = () => {
    APIKit.put(RGCA_URLS.updateRole, editRoles,)
      .then((res) => {

        if (res.data.status === 200) {
          toast.success(MESSAGE.roleupdate, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getAllRoles();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setIsEdit(false)
          setRoles({ "name": "", "accessRoleID": "" })
          setEditRoles({ "name": "", "accessRoleID": "" })
          // setBtn({ "name": "Save" })
        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
      })
  }
  const remove = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        deleteRole(data)
      })
      .catch(() => console.log("Deletion cancelled."));

  }
  const deleteRole = (data) => {
    setIsLoading(true);
    setRoles({
      "name": "",
      "fullName": "" //optional
    })
    console.error(data)
    URL = RGCA_URLS.deleteRole + '/' + data.accessRoleID
    console.error(URL)
    APIKit.delete(RGCA_URLS.deleteRole + '/' + data.accessRoleID)
      .then((res) => {
        console.error('hi')
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(MESSAGE.RoleDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setBtn({ "name": "Save" })
          getAllRoles()
        }
        else if (res.data.status === 201) {
          setIsLoading(false);
          toast.warning(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setBtn({ "name": "Save" })
          getAllRoles()
        }
        else {
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.RoleNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })

        }
      })
      .catch(function (e) {
      })
  }

  let regEx = {
    textOnly: /^[a-zA-Z0-9\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  const getAllDepartment = () => {

    APIKit.get(RGCA_URLS.getDepartment)
      .then((res) => {

        if (res.data.status === 200) {
          setAllDepartment(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }
  return (
    <Page
      className={classes.root}
      title="Role">
      <ToastContainer />
      <Container maxWidth={false}>
        <Loader isLoading={isLoading} />
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} className={classes.centertext}>
                Role
              </Grid>

            </Grid>

          </Box>

          <Box mt={3}>

            <Card>
              <CardContent>
                <Box >
                  <Grid container>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >

                      <TextField
                        fullWidth
                        helperText=""
                        label="Role Name"
                        name="roleName"
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                            let p = roles;
                            p.name = e.target.value;
                            setRoles({
                              ...p
                            });
                          }
                        }}
                        value={roles.name}
                        required
                        variant="outlined"
                        ref={inputRef}
                      />

                    </Grid>
                    &nbsp;
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={roles.departmentID}
                          onChange={(e) => {
                            let p = roles;
                            roles.departmentID = e.target.value;
                            setRoles({
                              ...p
                            });
                          }}
                          label="Department"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {allDepartment.map((x,index)=>{
                        return  <MenuItem value={x.departmentID} >{x.departmentName}</MenuItem>
                        })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          variant="contained" style={{ marginTop: '14px' }}
                          onClick={addRoles}
                          disabled={/^\s*\S[\s\S]*$/g.test(roles.name) == ""}
                        >
                          Add
                        </Button>
                      </Box>
                    </Grid>


                  </Grid>

                </Box>
              </CardContent>
            </Card>
          </Box>

        </div>


        <Box mt={3}>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead>


                <TableRow>

                  <TableCell >
                    S.No
                  </TableCell>
                  <TableCell >
                    Role
                  </TableCell>

                  <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allRole && allRole.length != 0 && allRole.sort((a, b) => a.name.localeCompare(b.name)) &&
                  allRole.map((rol, index) => {
                    return <TableRow
                      hover
                      key={rol.accessRoleID}
                    >

                      <TableCell >
                        {index + 1}
                      </TableCell>
                      <TableCell >
                        {/* {rol.name} ({rol.accessRoleID}) */}
                        {rol.name}
                      </TableCell>

                      <TableCell style={{ textAlign: 'center', width: '20%' }}>
                        <EditOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => {
                          // inputRef.current.focus();
                          // edit(rol)
                          setEditRoles({
                            "name": rol.name,
                            "accessRoleID": rol.accessRoleID,
                            "departmentID":rol.departmentID
                          })
                          setIsEdit(true)
                        }} />
                      </TableCell>
                      <TableCell >
                        <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={() => remove(rol)} />
                      </TableCell>

                      {/* <TableCell > <Checkbox/></TableCell> */}
                    </TableRow>
                  })}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {isEdit && (
          <Dialog
            open={isEdit}
            onClose={() => {
              setIsEdit(false);
            }}
            style={{ background: 'transparent' }}
          >
            {/* <DialogTitle>Are you sure you want to Delete </DialogTitle> */}
            <DialogActions sx={{ pr: 2.5 }}>
              <Box style={{ width: '50rem', padding: 20 }}>
                <Grid container>

                  <Grid
                    item
                    md={14}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      // style={{width:100}}
                      helperText=""
                      label="Role Name"
                      name="roleName"
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                          let p = editRoles;
                          p.name = e.target.value;
                          setEditRoles({
                            ...p
                          });
                        }
                      }}
                      value={editRoles.name}
                      required
                      variant="outlined"
                      ref={inputRef}
                    />

                  </Grid>
                  <br/> <br/> <br/>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={editRoles.departmentID}
                          onChange={(e) => {
                            let p = editRoles;
                            p.departmentID = e.target.value;
                            setEditRoles({
                              ...p
                            });
                          }}
                          label="Department"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {allDepartment.map((x,index)=>{
                        return  <MenuItem value={x.departmentID} >{x.departmentName}</MenuItem>
                        })}
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        variant="contained" style={{ marginTop: '14px' }}
                        onClick={updateRole}
                        disabled={/^\s*\S[\s\S]*$/g.test(editRoles.name) == ""}
                      >
                        Update
                      </Button>
                      <Button
                        color="primary"
                        variant="contained" style={{ marginTop: '14px', marginLeft: "3px" }}
                        onClick={() => {
                          setIsEdit(false);
                        }}

                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>


                </Grid>

              </Box>
            </DialogActions>
          </Dialog>
        )}


      </Container>
    </Page >
  )

}
export default Role;
