import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import ReturnGoods from './returngoods.jsx';
import Page from 'src/components/Page';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { useBarcode } from 'react-barcodes';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import PaymentIcon from '@material-ui/icons/Payment';
import { data } from './data';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import {
  capitalize,
  dateFormate,
  dateOnly,
  getProjectDetails
} from '../Common/utilities';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { parseData, dateOnlyRev } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import { MESSAGE } from 'src/utils/message';
import CommonTable from '../Common/CommonTable';
import { ETTypes, ETaction } from '../Common/Types';
import './style.css';
import { useConfirm } from 'material-ui-confirm';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GRNList from './grnlist';
//MUI TOGGLE
const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#394671',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%'
    // marginTop:"125px"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function App() {
  const { inputRef } = useBarcode({
    value: 'ITEM-10052021-0012',
    options: {
      text: 'ITEM-10052021-0012'
    }
  });

  return <svg ref={inputRef} />;
}

const GRN = (props) => {
  const classes = useStyles();
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0); // first tab
  const [page, setPage] = React.useState(0);
  const [grnList, setGrnList] = useState(data);
  const [arrayTableData, setArrayTableData] = useState([]);
  const [showContent, setShowContent] = useState(true);
  const [grnInvoiceID, setGrnInvoiceID] = useState({
    inVoiceID: ''
  });
  const [manuallyAddGRN, setManuallyAddGRN] = useState({
    vendorID: '',
    invoiceID: '',
    invoiceAttach: '',
    invoiceDate: ''
  });
  const [openincPrint, setOpenincPrint] = React.useState(false);
  const [addManually, setAddManually] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createView, setCreateView] = React.useState(false);
  const [moreView, setMoreView] = React.useState(false);
  const [returnView, setReturnView] = React.useState(false);
  const [singleReturnView, setSingleReturnVIew] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [paymentCompleted, setPaymentPaymentCompleted] = React.useState(false);
  const [POgrn, setPOgrn] = React.useState([]);
  const confirm = useConfirm();
  const [POaddress, setPOaddress] = React.useState([]);
  const [POBank, setPOBank] = React.useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataInvoiceID, setTotalDataInvoiceID] = React.useState({});
  const [allGRN, setAllGRN] = React.useState([]);
  const [singleReturn, setSingleReturn] = React.useState({});
  const [allReturn, setAllReturn] = React.useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const [GRNView, setGRNView] = React.useState([]);
  const [GRNItem, setGRNItem] = React.useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [GSTTDS, setGSTTDS] = React.useState('NO');
  const [edit, setEdit] = React.useState(false);
  const [catOrRec, setCatOrRec] = useState([]);
  const [indexData, setIndexData] = useState(null);
  const [TAXTDS, setTAXTDS] = React.useState('NO');
  const [lookupData, setLookupData] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [poGST, setPOGSt] = useState({});
  const [returnGoods, setReturnGoods] = useState({});
  const [scroll, setScroll] = useState('paper');
  const [show, setShow] = useState(true);
  const [objAddData, setObjAddData] = useState({
    val: {},
    materialList: [],
    typeID: '',
    itemID: '',
    itemName: '',
    dateofExpiry: '',
    itemAttach: '',
    grnQty: '',
    isGST: '',
    gstType: '',
    unitPrice: '',
    totalPrice: '',
    comment: ''
  });

  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    paymentMethod: '',
    projectID: null,
    vendorID: '',
    reference: '',
    value: '',
    isGstTds: '',
    gstTdsRate: '',
    gstTaxExemption: '',
    isItTaxTds: '',
    itTdsRate: '',
    itTaxExemption: '',
    purposeAdvance: '',
    balancePayable: ''
  });

  const paymentAdd = () => {
    if (Number(payload.value) == 0) {
      toast.error('Value must not be 0', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    payload.projectID = projectDetails.projectID;
    payload.GRNID = paymentData.grnMasterID;
    payload.GRNorWO = 1;
    let paymentUpdate = {
      projectID: payload.projectID,
      GRNID: payload.GRNID,
      workOrderID: payload.workOrderID,
      paymentMethod: payload.paymentMethod,
      vendorID: paymentData.vendorID,
      GRNorWO: 1,
      reference: Number(payload.reference),
      value: Number(payload.value),
      isGstTds: payload.isGstTds,
      gstTdsRate: payload.isGstTds ? Number(payload.gstTdsRate) : 0,
      gstTaxExemption: payload.isGstTds
        ? paymentData?.totalItemValueNoTax * (payload.gstTdsRate / 100)
        : 0,
      isItTaxTds: payload.isItTaxTds,
      itTdsRate: payload.isItTaxTds ? Number(payload.itTdsRate) : 0,
      itTaxExemption: payload.isItTaxTds
        ? paymentData?.totalItemValueNoTax * (payload.itTdsRate / 100)
        : 0,
      purposeAdvance: payload.purposeAdvance,
      balancePayable: Number(paymentData.pendingAmount) - Number(payload.value)
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addPaymentForGRNWO, paymentUpdate)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPayload({
            projectID: '',
            vendorID: '',
            reference: '',
            value: '',
            isGstTds: '',
            gstTdsRate: '',
            gstTaxExemption: '',
            isItTaxTds: '',
            itTdsRate: '',
            itTaxExemption: '',
            purposeAdvance: '',
            balancePayable: ''
          });
          getGRNList();
          setPayment(!payment);
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };

  const [GRN, setGRN] = useState({
    POID: '',
    gst: ''
  });

  const handlePayView = data => {
    setPaymentData(data);
    let gst = data?.grnItem?.map(e => {
      return {
        gstDetails: e.gstDetails
      };
    });
    setPOGSt(gst);
    if (data.pendingAmount == '0') {
      setPaymentPaymentCompleted(!paymentCompleted);
    } else {
      setPayment(!payment);
    }
  };

  const handlePayViewCompleted = () => {
    setPaymentPaymentCompleted(!paymentCompleted);
  };

  const handleMoreViewClose = () => {
    setMoreView(!moreView);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    a11yProps(0);
    getGRNList();
    getVendor();
    getReturnGoods();
    getPaymentMethod();
    getLookup();
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const a11yProps = index => {
    if (index == 0) {
      setCreateView(false);
    }
    if (index == 1) {
      getGRNList();
    }
    if (index == 2) {
      getReturnGoods();
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  const handleAddManually = () => {
    setAddManually(!addManually);
  };

  const POApprovalView = [
    {
      title: 'GRN Item ID',
      field: 'grnItemID',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Material Name',
      field: 'name',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Category',
      field: 'categoryName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Item Attach',
      field: 'itemAttach',
      align: 'left',
      type: ETTypes.link
    },
    {
      title: 'Quantity',
      field: 'grnQty',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Total Price',
      field: 'totalPrice',
      align: 'left',
      type: ETTypes.numeric
    }
  ];

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };

  const getPaymentMethod = () => {
    APIKit.get(`${RGCA_URLS.lookup}/55`)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleReturn = data => {
    if (data != 0) {
      setReturnGoods({
        ...data,
        grnItem: data.grnItem.map(e => {
          return {
            ...e,
            returnQty: '',
            grnQty: e.grnQty,
            returnPurpose: '',
            totalGST: e?.gstDetails?.reduce(function (acc, b) {
              return acc + b.CGST + b.IGST + b.SGST + b.UGST;
            }, 0)
          };
        })
      });
    }

    setReturnView(!returnView);
  };

  const handleViewGrn = () => {
    setOpenincPrint(!openincPrint);
  };

  const handleGRNView = data => {
    setGRNView({
      ...data,
      venName: data.vendorDetails[0].name,
      venAadhar: data.vendorDetails[0].aadhar,
      venGstIn: data.vendorDetails[0].gstn,
      venLine1: data.vendorDetails[0].address[0].line_1,
      venLine2: data.vendorDetails[0].address[0].line_2,
      vendistrict: data.vendorDetails[0].address[0].district,
      venstate: data.vendorDetails[0].address[0].state,
      vencountry: data.vendorDetails[0].address[0].country,
      venpostalCode: data.vendorDetails[0].address[0].postalCode,
      venbankName: data.vendorDetails[0].bankDetails[0].bankName,
      venbankAccountNo: data.vendorDetails[0].bankDetails[0].bankAccountNo,
      venbankAccountType: data.vendorDetails[0].bankDetails[0].bankAccountType,
      venbankBranch: data.vendorDetails[0].bankDetails[0].bankBranch,
      venbankIFSC: data.vendorDetails[0].bankDetails[0].bankIFSC,
      venbankAccountHolderName:
        data.vendorDetails[0].bankDetails[0].bankAccountHolderName,
      venpan: data.vendorDetails[0].pan,
      prol1: data.projectDetails[0].address[0].line_1,
      proLine2: data.projectDetails[0].address[0].line_2,
      prodistrict: data.projectDetails[0].address[0].district,
      prostate: data.projectDetails[0].address[0].state,
      procountry: data.projectDetails[0].address[0].country,
      propostalCode: data.projectDetails[0].address[0].postalCode
    });
    setGRNItem(data.grnItem);
  };

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
    <style>
    
.invoice-box {
max-width: 800px;
margin: auto;
padding: 30px;
border: 1px solid #eee;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
font-size: 16px;
line-height: 24px;
font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
color: #555;
}

.invoice-box table {
width: 100%;
line-height: inherit;
text-align: left;
}

.invoice-box table td {

vertical-align: top;
}



.invoice-box table tr.top table td {
padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
font-size: 45px;
line-height: 45px;
color: #333;
}

.invoice-box table tr.information table td {
padding-bottom: 40px;
}

.invoice-box table tr.heading td {
background: #eee;
border-bottom: 1px solid #ddd;
font-weight: bold;
}

.invoice-box table tr.details td {
padding-bottom: 20px;
}

.invoice-box table tr.item td {
border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
border-top: 2px solid #eee;
font-weight: bold;
}

@media only screen and (max-width: 600px) {
.invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
}

.invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
}
}

/** RTL **/
.invoice-box.rtl {
direction: rtl;
font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
/* text-align: left; */
}
    </style>
    </head><body>'${printableElements}'</body></html>`;
    document.body.innerHTML = html;
    window.print();
    document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    window.location.reload();
  };

  const exportPdf = () => {
    html2canvas(document.querySelector('#printme')).then(canvas => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('download.pdf');
    });
  };

  const [inter, setInter] = useState(false);
  const [intra, setIntra] = useState(false);
  const [union, setUnion] = useState(false);
  const [gstState, setGstState] = useState('');
  const handleState = e => {
    if (e.target.value === 'Interstate') {
      setInter(true);
      setIntra(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Intrastate') {
      setIntra(true);
      setInter(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Union') {
      setUnion(true);
      setInter(false);
      setIntra(false);
      setGstState(e.target.value);
    }
  };

  const getMaterials = (i, typeID, projectID) => {
    let payload = {
      projectID: projectID,
      typeID: typeID
    };
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then(res => {
        if (res.data.status === 200) {
          objAddData.materialList = res.data.data;
          setObjAddData({ ...objAddData });
        } else {
        }
      })
      .catch(function (e) { });
    // console.log('text-text',payload)
  };

  const onChangeInput = (value, index) => {
    objAddData.val = value;
    setObjAddData({ ...objAddData });
    let item = { ...objAddData };
    item.itemID = value.value;
    item.itemName = value.label;
    let uo = objAddData.materialList.find(x => x.itemID == value.value);

    item.uom = uo.uom;
    item.lastPrice = Number(uo.lastPrice);
    item.brandOrCatalogue = uo.brandOrCatalogue;
    item.availabilityQty = uo.availabilityQty;
    setObjAddData({ ...item });
  };

  const handleUploadClick = (event, i, type) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append(
      'upload',
      event.target.files[0],
      event.target.files[0].name
    );
    if (type == 'invoice') {
      APIKit.post(RGCA_URLS.fileUpload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            setTotalData({
              ...totalData,
              invoiceAttach: res.data.data.file
            });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'invoiceManual') {
      APIKit.post(RGCA_URLS.fileUpload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            let temp = manuallyAddGRN;
            temp.invoiceAttach = res.data.data.file;
            setManuallyAddGRN({ ...temp });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'itemAttachManual') {
      APIKit.post(RGCA_URLS.fileUpload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            let temp = objAddData;
            temp.itemAttach = res.data.data.file;
            setObjAddData({ ...temp });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'itemInvoice') {
      APIKit.post(RGCA_URLS.fileUpload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            let temp = POgrn;
            temp[i].itemAttach = res.data.data.file;
            setPOgrn([...temp]);
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
  };

  const actions = {
    onEdit: (index, row) => {
      setIndexData(index);
      setOpenTest(!openTest);
      setObjAddData({ ...row });
      setEdit(!edit);
    },
    onDelete: (index, row) => {
      console.log('delete:', index, row);
      remove(row, index);
    }
  };

  const remove = (data, i) => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        let item = arrayTableData;
        item.splice(i, 1);
        setArrayTableData([...item]);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const [columns, setColumns] = useState([
    {
      title: 'Item Name',
      field: 'itemName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Item Attach',
      field: 'itemAttach',
      align: 'left',
      type: ETTypes.link
    },
    {
      title: 'GRN Quantity',
      field: 'grnQty',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Total Price',
      field: 'totalPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Date of Expiry',
      field: 'dateofExpiry',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onEdit, ETaction.onDelete]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  const addItem = () => {
    setOpenTest(!openTest);
    setObjAddData({
      val: {},
      materialList: [],
      typeID: '',
      itemID: '',
      itemName: '',
      dateofExpiry: '',
      itemAttach: '',
      isGST: '',
      gstType: '',
      grnQty: '',
      unitPrice: '',
      totalPrice: '',
      comment: ''
    });
  };

  const [items, setItems] = React.useState([data.items]);
  const returnGoodsFn = () => {
    const payload = {
      projectID: projectDetails.projectID,
      purchaseOrderMasterID: returnGoods.purchaseOrderMasterID,
      invoiceID: returnGoods.invoiceID,
      vendorID: returnGoods.vendorID,
      grnMasterID: returnGoods.grnMasterID,
      totalItemValueNoTax: returnGoods?.grnItem?.reduce(
        (a, b) => (b.grnQty - (b.returnQty ?? 0)) * b.unitPrice + a,
        0
      ),
      totalItemValue: returnGoods?.grnItem?.reduce(
        (a, b) =>
          (b.grnQty - (b.returnQty ?? 0)) * b.unitPrice +
          (b.grnQty - (b.returnQty ?? 0)) *
          b.unitPrice *
          ((b.totalGST ?? 0) / 100) +
          a,
        0
      ),
      items: returnGoods?.grnItem?.reduce((ids, e) => {
        if (e.returnQty != '' && e.returnQty != 0) {
          const obj = {
            materialID: e.materialID,
            grnQty: e.grnQty,
            grnItemID: e.grnItemID,
            unitPrice: e.unitPrice,
            returnQty: e.returnQty,
            returnPurpose: e.returnPurpose,
            totalPrice: (e.grnQty - e.returnQty) * e.unitPrice
          };
          ids.push(obj);
        }
        return ids;
      }, [])
    };
    if (payload.items.some(e => e.returnPurpose == '')) {
      toast.error(MESSAGE.returnPurpose, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.returnGoods, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setReturnGoods({});
          setReturnView(false);
          getGRNList();
          toast.success(MESSAGE.returnGoods, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.eroor(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const handleReturns = data => {
    setSingleReturnVIew(true);
    setSingleReturn(data);
  };

  const TotalPrice = () => {
    return POgrn?.reduce(
      (a, b) =>
        b.qty * b.unitPrice +
        b.qty * b.unitPrice * ((b.IGST + b.CGST + b.SGST + b.UGST) / 100) +
        a,
      0
    ); // 20;
  };

  const submitGrn = () => {
    const payload = {
      projectID: projectDetails.projectID,
      purchaseOrderMasterID: GRN.POID,
      vendorID: totalData[0].vendorID,
      invoiceID: totalData.inVoiceID,
      invoiceAttach: totalData.invoiceAttach,
      invoiceDate: dateOnlyRev(totalData.invoiceDate),
      totalItemValueNoTax: POgrn?.reduce((a, b) => b.qty * b.unitPrice + a, 0),
      totalItemValue: POgrn?.reduce(
        (a, b) =>
          b.qty * b.unitPrice +
          b.qty * b.unitPrice * ((b.IGST + b.CGST + b.SGST + b.UGST) / 100) +
          a,
        0
      ),
      items: POgrn.map(e => {
        return {
          materialID: e.materialItem,
          grnQty: e.qty,
          unitPrice: e.unitPrice,
          dateofExpiry: dateOnlyRev(e.dateofExpiry),
          itemAttach: e.itemAttach,
          totalPrice: e.qty * e.unitPrice
        };
      })
    };
    if (payload.invoiceAttach === undefined) {
      toast.error(MESSAGE.attachment, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addGRN, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGRN({
            ...GRN,
            POID: ''
          });
          setCreateView(false);
          getGRNList();
          setPOgrn([]);
          setTotalDataInvoiceID({});
          toast.success(MESSAGE.grnAdded, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.eroor(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const addGRNWithoutPO = () => {
    const payload = {
      projectID: projectDetails.projectID,
      vendorID: manuallyAddGRN.vendorID,
      invoiceID: manuallyAddGRN.invoiceID,
      purchaseOrderMasterID: 'NO Purchase Order ID',
      invoiceAttach: manuallyAddGRN.invoiceAttach,
      invoiceDate: dateOnlyRev(manuallyAddGRN.invoiceDate),
      totalItemValueNoTax: arrayTableData?.reduce(
        (a, b) => b.grnQty * b.unitPrice + a,
        0
      ),
      totalItemValue: arrayTableData?.reduce(
        (a, b) =>
          b.grnQty * b.unitPrice +
          b.grnQty * b.unitPrice * (b.gstType / 100) +
          a,
        0
      ),
      items: arrayTableData.map(e => {
        return {
          materialID: e.itemID,
          grnQty: e.grnQty,
          unitPrice: e.unitPrice,
          dateofExpiry: dateOnlyRev(e.dateofExpiry),
          itemAttach: e.itemAttach,
          totalPrice: e.totalPrice
        };
      })
    };
    if (payload.invoiceAttach === undefined) {
      toast.error(MESSAGE.attachment, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addGRN, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGRN({
            ...GRN,
            POID: ''
          });
          setCreateView(false);
          getGRNList();
          setPOgrn([]);
          setTotalDataInvoiceID({});
          toast.success(MESSAGE.grnAdded, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.eroor(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const searchPOID = () => {
    const payload = {
      purchaseOrderMasterID: GRN.POID
    };
    payload.projectID = projectDetails.projectID;
    APIKit.post(RGCA_URLS.getPurchaseOrderForGRN, payload)
      .then(res => {
        if (res?.data?.data?.length) {
          let dataPurchaseItem = [];
          let dataaddress = [];
          let databankDetails = [];
          let data = res.data.data.map((x, i) => {
            dataPurchaseItem = parseData(x.purchaseOrderItem);
            dataPurchaseItem = dataPurchaseItem.map(element => {
              return {
                ...element,
                totalRate:
                  element.IGST + element.CGST + element.SGST + element.UGST,
                oQty: element.qty,
                qty: element.qty,
                dateofExpiry: ''
              };
            });
            dataaddress = parseData(x.address);
            databankDetails = parseData(x.bankDetails);
            return {
              ...x,
              address: parseData(x.address),
              bankDetails: parseData(x.bankDetails),
              purchaseOrderItem: parseData(x.purchaseOrderItem),
              inVoiceDate: ''
            };
          });
          setTotalData(data);
          setPOaddress(dataaddress);
          setPOBank(databankDetails);
          setPOgrn(dataPurchaseItem);
          setCreateView(true);
        } else {
          toast.error(MESSAGE.poiDmatch, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };

  var num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

  function number2words(n) {
    if (n < 20) return num[n];
    var digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
    if (n < 1000)
      return (
        num[~~(n / 100)] +
        ' hundred' +
        (n % 100 == 0 ? '' : ' and ' + number2words(n % 100))
      );
    return (
      number2words(~~(n / 1000)) +
      ' thousand' +
      (n % 1000 != 0 ? ' ' + number2words(n % 1000) : '')
    );
  }

  const getGRNList = () => {
    APIKit.get(RGCA_URLS.getGRN + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setAllGRN(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const getReturnGoods = () => {
    APIKit.get(RGCA_URLS.getReturnGoods + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              returnItem: parseData(x.returnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setAllReturn(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.grnQty;
    });
    return test;
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9.]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  return (
    <Page className={classes.root} title="GRN" id="grn">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>
            <h2 style={{ color: '#394671' }}>{props?.permission == "view_GRN" ? 'Goods Received / Rejected Note (GRN)' : 'Edit Goods Received Note'}</h2>
          </Typography>
          {props?.permission == "view&edit_GRN" && <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              className="toggleText"
              style={{ color: '#394671', fontWeight: 'bold' }}
            >
              GRN LIST 
            </Typography>
            <FormControlLabel
              onChange={e => {
                setShow(!show);
              }}
              control={<IOSSwitch sx={{ m: 1 }} />}
            />
            <Typography style={{ color: '#394671', fontWeight: 'bold' }}>
              RETURN GOODS
            </Typography>
          </Stack>
}
        </Box>
        {show ? (
          <>
            <GRNList {...props}/>
          </>
        ) : (
          <>
            <ReturnGoods />
          </>
        )}
      </>
    </Page>
  );
};
export default GRN;
