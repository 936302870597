import React, { useEffect, useState } from 'react';
import { Container, makeStyles, Typography, Box } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Page from 'src/components/Page';
import './style.css';
import Tab from '@material-ui/core/Tab';
import NewIndent from 'src/views/Project/Indents/newIndent.jsx';
import ProjectIndents from 'src/views/Project/Indents/index.jsx';
import RisePurchaseOrder from 'src/views/Project/Purchasing/RisePurchaseOrder.jsx';
import MyIndent from './MyIndent';
import { checkPermission } from '../../../utils/permission.jsx';
import { ar } from 'date-fns/locale';
import { flow, wrap } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // icon: {
  //   fill: "green",
  // },
  text: {
    fill: 'white'
  },
  centerText: {
    textAlign: 'center',
    paddingBottom: '2px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  scrol: {
    [theme.breakpoints.down('md')]: {
    //   width: '100%',
    //   overflowX: "scroll",
    //   scrollbarWidth: 'thin',
    //   scrollbarColor: 'gray lightgray',
    //   '&::-webkit-scrollbar': {
    //     height: 8,
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     backgroundColor: 'lightgray',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'gray',
    //     borderRadius: 4,
    //   }
    // },
    // [theme.breakpoints.down('xs')]: {}
    display:"flex",flexWrap:"wrap",justifyContent:"center"
    
    }
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  console.log("Purchase Indent permisiions",value);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const IndentEdit = () => {

  const classes = useStyles();
  
const [allData, setAllData] = useState([
  {
    value: 'approval_indent',
    name: 'Purchase Indent Approval'
  },

  {
    value: 'view_indent' ,
    name: 'View My Indent'
  }, 
  
  {
    value: 'view&edit_indent' ,
    name: 'Edit My Indent'
  }, 
  {
    value: 'create_indent',
    name: 'Rise A New Purchase Indent'
  },   
   
  {
    value: 'create_purchaseOrder',
    name: 'Rise A New Purchase Order'
  } 
])

// let permissionData = JSON.parse(localStorage.getItem('permissionData'));
// console.log("permissionData",permissionData);



  

  console.log("all data iss",allData);

  let userData = JSON.parse(localStorage.getItem('userData'));

  var initialValue = {};
  console.log("allData",allData);
  initialValue = allData.find(data => {
    console.log("checkPermission", checkPermission(data.value));
    if (checkPermission(data.value) || userData?.isAdmin == 1) return data;
  });
  const [value, setValue] = React.useState(initialValue.value); // first tab

  const tabList = allData.filter(x => checkPermission(x.value) || userData?.isAdmin == 1);

  

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    console.log(index);
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  return (
    <Page className={classes.root} title="Purchase Indent " id="Indent">
      <Container maxWidth={false}>
        <Typography className={classes.centerText}>
          <h2 style={{ color: '#394671' }}>Purchase Indent</h2>
        </Typography>
        <Box sx={{ width: '100%', typography: 'body1' }} >
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}   className={classes.scrol}  >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                {tabList &&
                  tabList.length > 0 &&
                  tabList.map((data, index) => {
                    return (
                      <Tab
                        style={{ fontWeight: 'bold' }}
                        key={index}
                        label={data.name}
                        value={data.value}
                        onClick={() => a11yProps(data.value)}
                        
                      />
                    );
                  })}
              </TabList>
            </Box>

            <>
              {value == 'approval_indent' && (
                <TabPanel
                  key={1}
                  value="approval_indent"
                  index="approval_indent"
                >
                  <ProjectIndents />
                </TabPanel>
              )}

              {value == 'view_indent' && (
                <TabPanel
                  key={4}
                  value="view_indent"
                  index="view_indent"
                >
                  <MyIndent permission= {"view_indent"}/>
                </TabPanel>
              )}

              {/* {(value == 'view&edit_indent'|| value == 'view_indent') && (
                <TabPanel
                  key={0}
                  value={value}
                  index={value}
                >
                  <MyIndent permission= {value}/>
                </TabPanel>
              )} */}

{value == 'view&edit_indent' && (
                <TabPanel
                  key={0}
                  value={'view&edit_indent'}
                  index={'view&edit_indent'}
                >
                  <MyIndent permission= {"view&edit_indent"} />
                </TabPanel>
              )}

              {value == 'create_indent' && (
                <TabPanel key={2} value="create_indent" index="create_indent">
                  <NewIndent />
                </TabPanel>
              )}
              {value == 'create_purchaseOrder' && (
                <TabPanel
                  key={3}
                  value="create_purchaseOrder"
                  index="create_purchaseOrder"
                >
                  <RisePurchaseOrder />
                </TabPanel>
              )}
            </>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
};
export default IndentEdit;
