import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    InputAdornment,
    TextField,
    SvgIcon,
    Select,
    InputLabel,
    FormControl,
    MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
// import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    // root: {
    //     "& .MuiFormLabel-root": {
    //      fontSize:"0.8rem !important"

    //     },

    //   },
    MuiFormLabelRoot: {
        fontSize: "0.8rem !important"

    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
        "& .MuiFormLabel-root": {
            fontSize: "0.8rem !important",
            "& fieldset": {
                borderRadius: `20px 0 0 20px`,
                color: theme.palette.primary.main,
            },
        },
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

const useStyles1 = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px', marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    }
}));


const Reservoir = () => {
    const classes = useStyles();
    const [depositors, setDepositors] = useState([])
    // const [depositors] = useState(data);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = React.useState(false);

    const [age, setAge] = React.useState('');

    const [emptype, setEmptype] = React.useState('');
    const [isActive, setIsActive] = React.useState('');
    const [updatedBy, setUpdatedBy] = React.useState('');
    const [designation, setDesignation] = React.useState('');
    const [department, setDepartment] = React.useState('');

    const [DOB1, setDOB1] = React.useState(new Date());
    const [lastname, setLastname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobilenumber, setMobilenumber] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [createdBy, setCreatedBy] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [updatedby, setUpdatedby] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleChangeDesignation = (event) => {
        setDesignation(event.target.value);
    };

    const { state } = useLocation();

    const navigate = useNavigate();

    //   useEffect(() => {
    //     setDepositors(data);
    //   }, depositors)

    useEffect(() => {
        if (state) {
            setEmptype("permanent");
            setIsActive(state.isactive);
            setDepartment(state.department);
            setDesignation(state.designation);
            setUpdatedby('admin');
            setAge(state.age)

        }

    });

    const [DOB, setDOB] = React.useState(new Date());

    const [DOJ, setDOJ] = React.useState(new Date());
    const [DOB2, setDOB2] = useState(new Date());
    // const [DOB2, setDOB2] = React.useState(new Date());
    const [DOB3, setDOB3] = React.useState(new Date());
    const [DOB4, setDOB4] = React.useState(new Date());
    const [DOB5, setDOB5] = React.useState(new Date());
    const [DOB6, setDOB6] = React.useState(new Date());
    const [DOB7, setDOB7] = React.useState(new Date());
    const [DOB8, setDOB8] = React.useState(new Date());
    const [DOB9, setDOB9] = React.useState(new Date());
    const [DOB10, setDOB10] = React.useState(new Date());
    const [DOB11, setDOB11] = React.useState(new Date());
    const [DOB12, setDOB12] = React.useState(new Date());

    const handleDateChange = (date) => {
        setDOB(date);
    };

    const handleDateChangeDOB = (date) => {
        setDOJ(datePickerDefaultProps)
    }

    const handleSelectAll = () => {

    }

    const handleSelectOne = () => {

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const Toolbar = ({ className, ...rest }) => {
        const classes = useStyles1();

        const [firstname, setFirstname] = React.useState('');

        return (
            <div
                className={clsx(classes.root, className)}
                {...rest}
            >
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                            Reservoir
                    </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3} style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                }}>
                    <Grid
                        container
                        spacing={3}
                    >

                        {/* <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Name of the Farm"
                                name="firstName"
                                onChange={(e) => {
                                    setFirstname(e.target.value)
                                }}

                                value={firstname}
                                variant="outlined"
                            />
                        </Grid> */}
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Species Stocked</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"

                                    label="Location"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Catla">Catla</MenuItem>
                                    <MenuItem value="Rohu">Rohu</MenuItem>
                                    <MenuItem value="Mrigal">Mrigal</MenuItem>
                                    <MenuItem value="Jayanthi Rohu">Jayanthi Rohu</MenuItem>
                                    <MenuItem value="Common Carp">Common Carp</MenuItem>
                                    <MenuItem value="Grass carp">Grass carp</MenuItem>
                                    <MenuItem value="Amur carp">Amur carp</MenuItem>
                                    <MenuItem value="Magur">Magur</MenuItem>
                                    <MenuItem value="Gift Tilapia">Gift Tilapia</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Stocking Achievement Till date  (in lakh) *"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Stocked variety Achievement Till date (kgs)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>



                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Un- stocked variety Achievement Till date (kgs)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>




                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <div className={classes.dateborder} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="dd/MM/yyyy"
                                            value={DOB2}
                                            onChange={date => setDOB2(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }} className={classes.calmar}
                                        />

                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Reservoir Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    required
                                    label="Location"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    {/* <MenuItem value={10}>Feed</MenuItem>
                                    <MenuItem value={20}>Fertilizer</MenuItem>
                                    <MenuItem value={10}>Feed</MenuItem>
                                    <MenuItem value={10}>Polythene bags</MenuItem>
                                    <MenuItem value={10}>Oxygen Cylinder filling</MenuItem>
                                    <MenuItem value={10}>Hormone</MenuItem>
                                    <MenuItem value={10}>Chemicals</MenuItem>
                                    <MenuItem value={10}>others - Specify</MenuItem> */}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Fasli Year</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    required
                                    label="Location"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>2020</MenuItem>
                                    <MenuItem value={10}>2019</MenuItem>
                                    <MenuItem value={20}>2018</MenuItem>
                                    <MenuItem value={10}>2017</MenuItem>
                                    

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Officer Incharge</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"

                                    label="Location"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    <MenuItem value={10}>Inspector</MenuItem>
                                    <MenuItem value={20}>Sub Inspector</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Name"
                                name="firstName"
                                onChange={(e) => { }}

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <div className={classes.dateborder} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Period"
                                            format="dd/MM/yyyy"
                                            value={DOB1}
                                            onChange={date => setDOB1(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }} className={classes.calmar}
                                        />

                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="FRL(feets)"
                                name="firstName"
                                onChange={(e) => { }}

                                value={moment(new Date()).format("dd/MM/yyyy")}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Present Water level(feets)"
                                name="firstName"

                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Dead storage level(feet)"
                                name="firstName"
                                onChange={(e) => { }}

                                value={moment(new Date()).format("dd/MM/yyyy")}
                                variant="outlined"
                            />
                        </Grid>
                       
                       
                            <Box>
                                <Card>
                                    <CardContent>
                                    <Grid
                        container
                        spacing={3}
                    >
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Type of fishing"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value={moment(new Date()).format("dd/MM/yyyy")}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Name"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Address"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Lease Period"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Lease Value"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Year 1"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Year 2"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Year 3"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                helperText=""
                                                label="Year 4"
                                                name="firstName"
                                                onChange={(e) => { }}

                                                value=""
                                                variant="outlined"
                                            />
                                        </Grid>
                                            </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                       
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Stocking Target (in lakh)"
                                name="firstName"

                                variant="outlined"
                            />
                        </Grid>





                        <Grid
                            item
                            md={4}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Stocking on selected date (Kgs)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"

                                    label="Location"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    <MenuItem value="Catla">Catla</MenuItem>
                                    <MenuItem value="Rohu">Rohu</MenuItem>
                                    <MenuItem value="Mrigal">Mrigal</MenuItem>
                                    <MenuItem value="Jayanthi Rohu">Jayanthi Rohu</MenuItem>
                                    <MenuItem value="Common Carp">Common Carp</MenuItem>
                                    <MenuItem value="Grass carp">Grass carp</MenuItem>
                                    <MenuItem value="Amur carp">Amur carp</MenuItem>
                                    <MenuItem value="Magur">Magur</MenuItem>
                                    <MenuItem value="Gift Tilapia">Gift Tilapia</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Fish Exploitation - Stocked Varieties Target (in tons)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>


                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Stocked achievement on selected date (Kgs)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Fish Exploitation - Un-Stocked Varieties Target (in tons)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>


                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Un -Stocked Achievement on selected date (Kgs)"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="overall fish exploitations till date"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="overall fish exploitations on selected date"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Revenue Generated till date"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Revenue Generated on selected date"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Remarks"
                                name="firstName"
                                onChange=""

                                value=""
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                variant="contained"

                            >
                                Submit
              </Button>
                        </Grid>

                    </Grid>




                </Box>
            </div>
        );
    };

    return (
        <Page
            className={classes.root}
            title="Depositors">
            <Container maxWidth={false}>
                <Toolbar />
                <Box mt={3}>

                </Box>

                {/* dialog */}




            </Container>
        </Page>
    )

}
export default Reservoir;