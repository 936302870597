import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Recurring = ({ className, testdata, CHART, ...rest }) => {
  const classes = useStyles();

  const state = CHART
  // {
  //   labels: ['January', 'February', 'March',
  //            'April', 'May'],
  //   datasets: [
  //     {
  //       label: 'Rainfall',
  //       backgroundColor: 'rgba(75,192,192,1)',
  //       borderColor: 'rgba(0,0,0,1)',
  //       borderWidth: 2,
  //       data: [65, 59, 80, 81, 56]
  //     }
  //   ]
  // }





  const options = {
    responsive: true,
    legend: {
      display: true
    },
    type: "bar"

  };


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >

      <CardContent>
        <div>
          <Bar
            data={state}
            options={{
              title: {
                display: true,
                text: 'Recurring Expenditure',
                fontSize: 20
              },
              legend: {
                display: true,
                position: 'right'
              }
            }}
          />
        </div>
      </CardContent>


    </Card>
  );
};

Recurring.propTypes = {
  className: PropTypes.string
};

export default Recurring;




