import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { currencyFormat1, dateFormate } from '../Common/utilities';
import CommonTable from '../Common/CommonTable';

function GrnPrint({ openincPrint,
  handleViewGrn = () => { },
  printOrder = () => { },
  GRNView,
  POApprovalView,
  print, htmlContentRef = () => { }
}) {
  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        contentStyle={{
          width: '100%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
        open={openincPrint}
        id="income"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleViewGrn}
          style={{ backgroundColor: '#394671', color: '#fff' }}
        >
          GRN Accepted View
        </DialogTitle>
        <DialogContent dividers style={{ overflowX: 'hidden' }}>
          <Grid container spacing={3} >
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Indent ID : {GRNView?.grnMasterID}
                </h5>
              </div>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  GRN : {dateFormate(GRNView?.CreateDate)}
                </h5>
              </div>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  GRN-ID : {GRNView?.grnMasterID}
                </h5>
              </div>
            </Grid>
            {/* ---------------------------------------------------------------------------------------- */}
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                Indent ID : {GRNView?.indentMasterID}

                </h5>
              </div>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                PO ID : {GRNView?.purchaseOrderMasterID}
                </h5>
              </div>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}> 
                <h5 style={{ color: '#394671' }}>
                Invoice Attachment :{GRNView?.invoiceAttach && <a href={GRNView?.invoiceAttach}>{GRNView?.invoiceAttach.substring(0, 25)}</a>}
                </h5>
              </div>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div class="">
                    <table class="table">
                      <thead class='tableHead'>
                        <tr class="table-rows">
                          <th align="left">GRN Item ID</th>
                          <th align="left">Material Name</th>
                          <th align="left">Category</th>
                          <th align="left">Accepted Count</th>
                          <th align="left">Unit Price</th>
                          <th align="left">GST %</th>
                          <th align="left">Tax Amount</th>
                          <th align="left">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GRNView?.grnItem && GRNView?.grnItem.length != 0 ? (
                          GRNView?.grnItem.map((x, index) => {
                            return (
                              <tr class="table-row">
                                <td align="left">{x?.grnItemID}</td>
                                <td align="left">{x?.name}</td>
                                <td align="left">{x.categoryName}</td>
                                <td align="left">{x.acceptedCount}</td>
                                <td align="left">₹ {currencyFormat1(Math.round(x?.unitPrice))}</td>
                                <td align="left">{x?.gstRate}</td>
                                <td align="left">₹ {currencyFormat1(Math.round(x?.TotalTax))}</td>
                                <td align="left">₹ {currencyFormat1(Math.round(x?.totalPrice))}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr class="table-row">
                            <td align="left">
                              {'No Data Found'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
            </Grid>
          </Grid>
          <div className="invoice-box hideShow" ref={htmlContentRef} id="myHtmlContent" style={{ marginTop: '15px' }}>
            <div className="row container" >
              <div className="col-sm-3" style={{ display: "flex", justifyContent: "end" }}>
                <img src="/static/logo-rgca.jpg" alt="Company logo" style={{ width: '30%' }} />
              </div>
              <div className="col-sm-9">
                <p>
                  <h3 style={{ textAlign: 'center' }}>
                    RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)
                  </h3>
                </p>
                <p>
                  <h5 style={{ textAlign: 'center' }}>
                    (MPEDA,Ministry of Commerce & Industry Government of India)
                  </h5>
                </p>
                <p>
                  <h5 style={{ textAlign: 'center' }}>
                    HEAD OFFICE / UNIT OFFICE
                  </h5>
                </p>
              </div>
            </div>
            <table cellpadding="0" cellspacing="0">
              <tr className="top_rw">
                <td colspan="2">
                  <h2 style={{ marginBottom: '0px' }}>
                    GRN - {dateFormate(GRNView?.CreateDate)}{' '}
                  </h2>
                  <b> GRN-ID: {GRNView?.grnMasterID} </b>
                </td>
                <td style={{ width: '30%', marginRight: '10px' }}>
                  <b> Invoice ID: {GRNView?.invoiceID}</b>
                </td>
              </tr>
              <tr className="top_rw">
                <td colspan="2">
                  <b> Indent ID: {GRNView?.indentMasterID}</b>
                </td>
                <td style={{ width: '30%', marginRight: '10px' }}>
                  <b> PO ID: {GRNView?.purchaseOrderMasterID}</b>
                </td>
              </tr>
              <tr className="top">
                <td colspan="3">
                  <table>
                    <tr>
                      <td>
                        <b> Supplier: {GRNView?.venName} </b> <br />
                        {GRNView?.venLine1} <br />
                        {GRNView?.venLine2} <br />
                        {GRNView?.vendistrict} <br />
                        Pin Code : {GRNView?.venpostalCode} <br />
                        {GRNView?.venstate} <br />
                        {GRNView?.vencountry} <br />
                        PAN : {GRNView?.venpan} <br />
                        GSTIN : {GRNView?.venGstIn} <br />
                      </td>
                      <td>
                        <b> Bank Account Details: </b> <br />
                        Bank Name : {GRNView?.venbankName} <br />
                        Branch Name : {GRNView?.venbankBranch} <br />
                        Account Holder Name :{' '}
                        {GRNView?.venbankAccountHolderName} <br />
                        Account No : {GRNView?.venbankAccountNo} <br />
                        Account Type : {GRNView?.venbankAccountType} <br />
                        IFSC : {GRNView?.venbankIFSC} <br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className="information">
                <td colspan="3">
                  <table>
                    <tr>
                      <td colspan="2">
                        <b> Shipping Address: </b> <br />
                        {GRNView?.prol1} <br />
                        {GRNView?.proLine2} <br />
                        {GRNView?.prodistrict} <br />
                        Pin Code : {GRNView?.propostalCode} <br />
                        {GRNView?.prostate} <br />
                        {GRNView?.procountry} <br />
                      </td>
                      <td>
                        {' '}
                        <b> Billing Address: </b>
                        <br />
                        {GRNView?.venLine1} <br />
                        {GRNView?.venLine2} <br />
                        {GRNView?.vendistrict} <br />
                        Pin Code : {GRNView?.venpostalCode} <br />
                        {GRNView?.venstate} <br />
                        {GRNView?.vencountry} <br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <td colspan="3">
                <div style={{ textAlign: 'center' }}>
                  {/* <CommonTable columns={POApprovalView} data={GRNView?.grnItem} /> */}
                </div>
              </td>
              <tr className="totalleft">
                <td colspan="3">
                  {/* <div >
                    {GRNView &&
                      GRNView?.grnItem &&
                      GRNView?.grnItem.length != 0 ? (
                      <CommonTable columns={POApprovalView} data={GRNView?.grnItem} isprint={print} />
                    ) : null}
                  </div> */}
                  <div class="">
                    <table class="table">
                      <thead class='tableHead'>
                        <tr class="table-rows">
                          <th align="left">GRN Item ID</th>
                          <th align="left">Material Name</th>
                          <th align="left">Category</th>
                          <th align="left">Quantity</th>
                          <th align="left">Unit Price</th>
                          <th align="left">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GRNView?.grnItem && GRNView?.grnItem.length != 0 ? (
                          GRNView?.grnItem.map((x, index) => {
                            return (
                              <tr class="table-row">
                                <td align="left">{x?.grnItemID}</td>
                                <td align="left">{x?.name}</td>
                                <td align="left">{x.categoryName}</td>
                                <td align="center">{x.grnQty}</td>
                                <td align="center">{x.unitPrice}</td>
                                <td align="center">{x.totalPrice}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr class="table-row">
                            <td align="left">
                              {'No Data Found'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>

              <tr className="total">
                {/* <td colspan="3" align="right">  Total Amount in Words :  <b>{GRNItem != null ? capitalize(number2words(GRNItem?.reduce(function (acc, b) { return (b.grnQty * b.unitPrice) }, 0))) : null} Rupees Only </b> </td> */}
              </tr>
              <tr>
                <td colspan="3">
                  <table cellspacing="0px" cellpadding="2px">
                    <tr>
                      <td width="50%">
                        <b> Declaration: </b> <br />
                        We declare that this invoice shows the actual price of
                        the goods described above and that all particulars are
                        true and correct.The goods sold are intended for end
                        user consumption and not for resale.
                      </td>
                      <td>
                        * This is a computer generated invoice and does not
                        require a physical signature
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"></td>
                      <td>
                        <b> Authorized Signature </b>
                        <br />
                        <br />
                        ...................................
                        <br />
                        <br />
                        <br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          <Button color="primary" variant="contained" onClick={handleViewGrn}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GrnPrint