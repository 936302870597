import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const FisherMen = ({ className, testdata, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  var data;


  if (testdata && testdata.ArrayData) {
    let obj = {
      lable: [],
      targets: [],
      achive: [],
      F_Target: []
    };


    testdata.ArrayData.forEach(y => {

      obj.lable.push(y.type)
      obj.targets.push(y.Target)
      obj.achive.push(y.Achived)
      obj.F_Target.push(y.Financial_Target)

    })

    var finaldata = obj


    data = {
      labels: finaldata.lable,
      datasets: [
        {
          label: 'Physical Target ',

          backgroundColor: '#36A2EB',
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: '#FF6384',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: finaldata.targets
        },
        {
          label: "Physical Achievement",
          backgroundColor: '#FFCE56',
          borderColor: '#FFCE56',
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: '#36A2EB',
          hoverBorderColor: "rgba(255,99,132,1)",
          data: finaldata.achive
        },

      ]

    };

  }
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    type: "HorizontalBar"

  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="CSS/ FIDF/ NFDB /NADP/ TANII/ NABARD /SADP /TN-IAMP ( SCHEME FOR FISHERMEN )"
      />

      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          {data && (
            <HorizontalBar data={data}
              options={options}
            />
          )}
        </Box>
      </CardContent>

      {/* <Divider /> */}

    </Card>
  );



};


FisherMen.propTypes = {
  className: PropTypes.string
};


export default FisherMen;
