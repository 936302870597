import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Typography, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import StoreIndentByProject from './dashboardIndents';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';

const StoreIndentDashboard = () => {
    const [isColour, setIsColour] = useState(false);


    return (
        <>
            <Accordion defaultExpanded={false}
                style={{
                    borderRadius: "12px",
                    boxShadow: `${isColour ? "0 0 10px #46cc48" : "0 0 10px #3979c3"}`
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >
                        <b>My Store Indent (All Projects)</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StoreIndentByProject permission={"view_storeIndent"} setIsColour={setIsColour}/>

                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default StoreIndentDashboard