import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  FormHelperText
} from '@material-ui/core';

import { statusColor } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import '../../AddProject/style.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RGCA_URLS } from 'src/utils/url';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { ETTypes, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import {
  parseData,
  dateFormate,
  getProjectDetails,
  capitalize
} from '../Common/utilities';
import StepperBox from '../Common/StepperBox.jsx';
import { useParams } from 'react-router-dom';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import { Constant } from '../Common/Constant';
import { getloggedInData } from 'src/utils/helpers';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import Loader from '../../loader/index';
import Address from '../Common/Address';
import DoneIcon from '@mui/icons-material/Done';

const IndentInitialValues = {
  storeIndentMasterID: '',
  ProjectID: null,
  statusID: null,
  UpdatedBy: null,
  CreatedBy: null,
  quoteUrl: '',
  CreateDate: '',
  UpdatedDate: null,
  projectName: '',
  CreatedByName: '',
  currentApprovals: {
    referOptionView: false,
    referSelected: false,
    name: '',
    indentApprovalSystemID: null,
    storeIndentMasterID: '',
    orderID: null,
    accessRoleID: null,
    runningStatus: null,
    approvalID: null,
    editLock: true,
    CreatedBy: null,
    CreateDate: '',
    comment: '',
    approvalStatusID: null,
    status: ''
  },
  indentItem: [],
  Approvals: []
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  }
}));

const GINReport = () => {
  const projectData = useSelector(x => x);
  var proId = projectData.ProjectInfo.project.projectID;

  const classes = useStyles();
  const navigate = useNavigate();
  //   const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [indentView, setIndentView] = React.useState(false);
  const [openincPrint, setOpenincPrint] = useState(false);
  const [generatepo, setGeneratePo] = React.useState(false);
  const [PoView, setPoView] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [allVendors, setAllVendors] = useState([]);
  const [allPurchaseOrder, setAllPurchaseOrder] = useState([]);
  const [allStoreIndentList, setAllStoreIndentList] = useState([]);
  const [cpd, setCpd] = useState([]);
  const [step, setStep] = useState([]);
  const [selectedIndent, setSelectedIndent] = useState([]);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [item, setItem] = useState({});
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [indentItem, setIndentItem] = useState({ ...IndentInitialValues });
  const { accessRoleID = null } = getloggedInData();
  const [direct, setDirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllStoreIndentApprovedList();
    // getVendor();
    // getLookup()
  }, [navigate]);

  const [indentItemList, setIndentItemList] = useState({
    storeIndentMasterID: '',
    ProjectID: null,
    statusID: null,
    UpdatedBy: null,
    CreatedBy: null,
    quoteUrl: '',
    CreateDate: '',
    UpdatedDate: null,
    projectName: '',
    CreatedByName: '',
    indentItem: [],
    Approvals: []
  });

  // const getVendor = () => {
  //     APIKit.get(RGCA_URLS.getStoreIndentAllApprovedList)
  //         .then((res) => {
  //             if (res.data.status === 200) {
  //                 setAllVendors(res.data.data);
  //             } else {
  //             }
  //         })
  //         .catch((e) => {
  //         });
  // };

  // const getListOfPurchaseOrder = () => {
  //     APIKit.get(RGCA_URLS.getPurchaseOrderForIndent + '/' + projectDetails.projectID)
  //         .then((res) => {
  //             if (res.data.status === 200) {
  //                 // setAllPurchaseOrder(res.data.data);
  //                 let data = res.data.data.map(x => {
  //                     return {
  //                         ...x,
  //                         Approvals: parseData(x.Approvals),
  //                         purchaseOrderItem: parseData(x.purchaseOrderItem),
  //                         vendorDetails: parseData(x.vendorDetails),
  //                         vendorAddress: parseData(x.vendorDetails)[0].address
  //                     }
  //                 });
  //                 setAllPurchaseOrder(data);
  //             } else {
  //             }
  //         })
  //         .catch((e) => {
  //         });
  // };

  const handleIndentViewClose = () => {
    setIndentView(!indentView);
  };

  const handlePOViewClose = () => {
    setPoView(!PoView);
  };

  const handleIncPrintClose = () => {
    setOpenincPrint(!openincPrint);
  };

  const [payloadIndent, setPayloadIndent] = useState({});
  const handleGeneratePo = () => {
    setIndentView(false);
    setGeneratePo(!generatepo);
  };

  var obj = {};
  const handleGeneratePO = () => {
    let sumQty = 0;
    let sumUP = 0;

    for (let i = 0; i < selectedIndent.length; i++) {
      sumQty += selectedIndent[i].Qty;
    }
    for (let i = 0; i < selectedIndent.length; i++) {
      sumUP += parseInt(selectedIndent[i].unitPrice);
    }

    let payload = {
      projectID: projectDetails.projectID,
      vendorID: payloadIndent.vendorID,
      storeIndentMasterID: indentItemList.storeIndentMasterID,
      stateType: gstState,
      totalQty: sumQty,
      totalValue: sumUP,
      items: selectedIndent.map(x => {
        return (obj = {
          materialItem: x.itemID,
          description: x.desc,
          isGST: x.isGST,
          SGST: x.gstType / 2,
          CGST: x.gstType / 2,
          IGST: x.gstType / 2,
          UGST: x.gstType / 2,
          unitPrice: x.unitPrice,
          qty: x.Qty
        });
      })
    };
    if (!inter && !intra && !union) {
      toast.error(MESSAGE.interIntraUNion, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (inter) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: 0,
            CGST: 0,
            IGST: e.gstType,
            UGST: 0,
            unitPrice: e.unitPrice,
            qty: e.Qty
          };
        })
      };
    } else if (intra) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: e.gstType / 2,
            CGST: e.gstType / 2,
            IGST: 0,
            UGST: 0,
            unitPrice: e.unitPrice,
            qty: e.Qty
          };
        })
      };
    } else if (union) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: 0,
            CGST: e.gstType / 2,
            IGST: 0,
            UGST: e.gstType / 2,
            unitPrice: e.unitPrice,
            qty: e.Qty
          };
        })
      };
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addPurchaseOrderForIndent, payload)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.purchaseOrderApporoved, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            getAllStoreIndentApprovedList();
            setIsLoading(false);
            setIndentView(false);
            setGeneratePo(!generatepo);
          }, 3000);
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function(e) {});
  };

  const [columns, setColumns] = useState([
    {
      title: 'Indent ID',
      field: 'storeIndentMasterID',
      align: 'left',
      type: ETTypes.string
    },
    // {
    //     title: "Project Name",
    //     field: "projectName",
    //     align: 'left',
    //     type: ETTypes.string,
    // },
    // {
    //     title: "Quote",
    //     field: "quoteUrl",
    //     align: 'left',
    //     type: ETTypes.link,
    // },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreateDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onView]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  var num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

  function number2words(n) {
    if (n < 20) return num[n];
    var digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
    if (n < 1000)
      return (
        num[~~(n / 100)] +
        ' hundred' +
        (n % 100 == 0 ? '' : ' and ' + number2words(n % 100))
      );
    return (
      number2words(~~(n / 1000)) +
      ' thousand' +
      (n % 1000 != 0 ? ' ' + number2words(n % 1000) : '')
    );
  }

  const viewIndent = [
    {
      title: 'Store Indent Master ID',
      field: 'storeIndentMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'PO ID',
      field: 'purchaseOrderMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Total Quantity',
      field: 'totalQty',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Value',
      field: 'totalValue',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreateDate',
      align: 'left',
      type: ETTypes.date
    },
    // {
    //   title: "Action",
    //   field: "action",
    //   align: 'left',
    //   list: [ETaction.onEdit]
    // },
    {
      title: 'Print View',
      field: 'action',
      align: 'left',
      list: [ETaction.onPrint]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ];

  const POApprovalView = [
    {
      title: 'PO Item ID',
      field: 'purchaseOrderItemID',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Material Name',
      field: 'name',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'GST',
      field: 'isGST',
      align: 'left',
      type: ETTypes.boolean
    },
    {
      title: 'IGST',
      field: 'IGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'CGST',
      field: 'CGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'SGST',
      field: 'SGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UGST',
      field: 'UGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UOM',
      field: 'uom',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Total Price(Inclusive GST)',
      field: 'totalAmount',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Description',
      field: 'description',
      align: 'left',
      type: ETTypes.string
    }
  ];

  const totalQty = (data = []) => {
    console.error(data);
    let test = 0;
    [].map(x => {
      test = test + x.Qty;
    });
    return test;
    //  data['totalQty'] = test;
  };

  const totalPrice = (data = []) => {
    let test = 0;
    [].map(x => {
      test = test + x.estimatedPrice;
    });
    return test.toLocaleString('en-IN');
  };


  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          setApprovalStatusList(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => {});
  };

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
        <style>
        
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    
    vertical-align: top;
}



.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
    text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
    /* text-align: left; */
}
        </style>
        </head><body>'${printableElements}'</body></html>`;
    // console.log(html)
    // document.body.innerHTML = html
    window.print();
    // document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    // window.location.reload()
  };

  const exportPdf = () => {
    html2canvas(document.querySelector('#printme')).then(canvas => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('PO generate');
    });
  };

  const getAllStoreIndentApprovedList = () => {
    APIKit.get(
      RGCA_URLS.getStoreIndentAllApprovedList + '/' + projectDetails.projectID
    )
      .then(res => {
        if (res.data.status === 200) {
          console.error(res.data.data);
          let data = res.data.data.map(x => {
            return {
              ...x,
              projectID: projectDetails.projectID
              // Approvals: parseData(x.Approvals),
              // indentItem: parseData(x.indentItem),
            };
          });
          setAllStoreIndentList(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => {});
  };
  const actions = {
    onView: (index, row) => {
      setIndentItemList({ ...row });
      console.error({indentItemList})
      handleIndentViewClose();
      setSelectedIndent([]);
      setPayloadIndent({});
      setInter(false);
      setIntra(false);
      setUnion(false);
    }
    // onEdit: (index, row) => {
    //     // setStatusEdit(true)
    //     setIndentItemList({ ...row })
    //     handleIndentViewClose()

    // }
  };

  const handleCheckBoxClick = (event, selectedRow) => {
    if (event.target.checked) {
      const newArr = [...selectedIndent];
      selectedRow.isGST = '';
      selectedRow.unitPrice = '';
      newArr.push(selectedRow);
      setSelectedIndent(newArr);
    } else {
      const tempArr = [...selectedIndent];
      tempArr.filter((e, index) => {
        if (e.indentItemID === selectedRow.indentItemID) {
          tempArr.splice(index, 1);
          setSelectedIndent(tempArr);
        }
      });
    }
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  const [inter, setInter] = useState(false);
  const [intra, setIntra] = useState(false);
  const [union, setUnion] = useState(false);
  const [gstState, setGstState] = useState('');
  const handleState = e => {
    if (e.target.value === 'Interstate') {
      setInter(true);
      setIntra(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Intrastate') {
      setIntra(true);
      setInter(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Union') {
      setUnion(true);
      setInter(false);
      setIntra(false);
      setGstState(e.target.value);
    }
  };

  // const updatePOApproval = (e) => {
  //     let referOptionPayload = false
  //     let referPayload = false
  //     if (indentItem.currentApprovals.referOptionView == 0) {
  //         referOptionPayload = false
  //     } else {
  //         referOptionPayload = true;
  //     }
  //     if (indentItem.currentApprovals.referSelected == 0) {
  //         referPayload = false
  //     } else {
  //         referPayload = true;
  //     }
  //     const payload = {
  //         "statusID": indentItem.currentApprovals.approvalStatusID,
  //         "purchaseOrderMasterID": indentItem.purchaseOrderMasterID,
  //         "comment": indentItem.currentApprovals.comment,
  //         "referOption": referOptionPayload,
  //         "refer": referPayload
  //     }
  //     setIsLoading(true);
  //     APIKit.put(RGCA_URLS.updateApprovalForPurchaseOrder, payload)
  //         .then((res) => {
  //             if (res.data.status === 200) {
  //                 // getIndentApprovalList()
  //                 toast.success(MESSAGE.cpdStatus, {
  //                     position: "top-right",
  //                     autoClose: 5000,
  //                     hideProgressBar: false,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                     progress: undefined,
  //                 })
  //                 setTimeout(() => {
  //                     // getListOfPurchaseOrder()
  //                     handlePOViewClose()
  //                     setIsLoading(false);
  //                 }, 3000);
  //             }
  //             else {
  //                 toast.error(res.data.message, {
  //                     position: "top-right",
  //                     autoClose: 5000,
  //                     hideProgressBar: false,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                     progress: undefined,
  //                 })
  //             }
  //         })
  //         .catch(function (e) {
  //         })
  // }

  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <ToastContainer />
      <Box>
        <Typography>
          <h2 style={{ color: '#394671' }}>GIN Report</h2>
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <CommonTable
                columns={columns}
                data={allStoreIndentList}
                action={actions}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={indentView}
          id="income"
          contentStyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleIndentViewClose}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '800px !important'
            }}
          >
            View
          </DialogTitle>
          <DialogContent dividers style={{ width: '800px !important' }}>
            <Grid container spacing={3}>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography>
                    <h5 style={{ color: '#394671' }}>
                      Indent Master Id : {indentItemList.storeIndentMasterID}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography>
                    <h5 style={{ color: '#394671' }}>
                      Total Quantity :{' '}
                      {totalQty(indentItemList.indentItem || [])}
                    </h5>
                  </Typography>
                </div>
              </Grid>

              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography>
                    <h5 style={{ color: '#394671' }}>
                      Date : {dateFormate(indentItemList.CreateDate)}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              {/* ---------------------------------------------------------------------------------------- */}

              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography>
                    <h5 style={{ color: '#394671' }}>
                      Estimated Price :{' '}
                      {totalPrice(indentItemList.indentItem || [])}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography>
                    <h5 style={{ color: statusColor('PENDING') }}>
                      {' '}
                      Status : {indentItemList.status}
                    </h5>
                  </Typography>
                </div>
              </Grid>

              <Grid item lg={4} sm={4} xl={4} xs={4}>
                {/* <div style={{ textAlign: "center" }}>
              <Typography><h5 style={{ color: '#394671' }}>Last Update : 10/05/2021 10:20AM</h5></Typography>
            </div> */}
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    {/* <CommonTable columns={updateColumns} data={indentItemList.indentItem} action={modelActions} /> */}

                    <TableContainer>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Material</TableCell>
                            <TableCell>UOM</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Estimated Price</TableCell>
                            <TableCell>Description</TableCell>
                            {/* <TableCell>
                          PO Status
                        </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {indentItemList.indentItem && indentItemList.indentItem.length > 0 ? indentItemList.indentItem.map((ind, index) => ( */}

                          <TableRow
                            hover
                            // key={emplyoee.id}
                            // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                          >
                            <TableCell>
                              {/* {ind.POGenerated == 1 ? <Checkbox
                                                                    onClick={e => {
                                                                        handleCheckBoxClick(e, ind)
                                                                    }}
                                                                ></Checkbox> : <DoneIcon />} */}
                            </TableCell>
                            <TableCell>{/* {ind.categoryName} */}</TableCell>
                            <TableCell>{/* {ind.name} */}</TableCell>
                            <TableCell>{/* {ind.uom} */}</TableCell>
                            <TableCell>{/* {ind.Qty} */}</TableCell>
                            <TableCell>
                              {/* {ind.estimatedPrice.toLocaleString('en-IN')} */}
                            </TableCell>
                            <TableCell>{/* {ind.comment} */}</TableCell>
                            {/* <TableCell>
                            {ind.postatusName == "OPEN" ? <div style={{ color: "blue" }}>{ind.postatusName}</div> : ''}
                            {ind.postatusName == "IN PROGRESS" ? <div style={{ color: "orange" }}>{ind.postatusName}</div> : ''}
                            {ind.postatusName == "COMPLETED" ? <div style={{ color: "green" }}>{ind.postatusName}</div> : ''}
                          </TableCell> */}
                          </TableRow>
                          {/* //   )) : */}
                          <TableRow hover role="checkbox" key={1}>
                            <TableCell colSpan={7} align="center" key={2}>
                              {'Indent item is deleted by the creator'}
                            </TableCell>{' '}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>

              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Box>
                  {/* <StepperBox steps={indentItemList.Approvals} /> */}
                </Box>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleGeneratePo}
                    variant="contained"
                    disabled={!selectedIndent.length}
                  >
                    GO TO PO REQUEST
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleIndentViewClose}
                    variant="contained"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/* GENERATE PO MODAL */}
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={generatepo}
          id="income"
          contentStyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
          //  style={{ maxWidth: "1100px !important", width: "1100px" }}
        >
          <Loader isLoading={isLoading} />
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleGeneratePo}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '800px !important'
            }}
          >
            PO Request
          </DialogTitle>
          <Formik
            initialValues={{
              // ...itemInitialValues,
              vendor: payloadIndent.vendorID,
              pogenerate: [...selectedIndent]
            }}
            onSubmit={(values, { resetForm }) => {
              handleGeneratePO();
            }}
            validationSchema={Yup.object().shape({
              pogenerate: Yup.array().of(
                Yup.object().shape({
                  isGST: Yup.string().required(MESSAGE.gstsno),
                  unitPrice: Yup.string().required(MESSAGE.unitPrice)
                })
              ),
              vendor: Yup.string().required(MESSAGE.vendor)
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              isInitialValid = false,
              isValid,
              touched,
              values,
              resetForm
            }) => (
              <DialogContent dividers style={{ width: '800px !important' }}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        {/* {selectedIndent.map} */}

                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(touched.vendor && errors.vendor)}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Vendor
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Vendor"
                            name="vendor"
                            onChange={e => {
                              handleChange(e);
                              let item = { ...payloadIndent };
                              item.vendorID = e.target.value;
                              setPayloadIndent({ ...item });
                            }}
                            onBlur={handleBlur}
                          >
                            {allVendors &&
                              allVendors.map((vendor, index) => {
                                return (
                                  <MenuItem value={vendor.vendorID}>
                                    {vendor.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.vendor && touched.vendor && errors.vendor}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <RadioGroup
                          aria-label="anonymous"
                          name="row-radio-buttons-group"
                          row
                          onChange={handleState}
                        >
                          <FormControlLabel
                            value="Interstate"
                            control={<Radio />}
                            label="Interstate"
                          />
                          <FormControlLabel
                            value="Intrastate"
                            control={<Radio />}
                            label="Intrastate"
                          />
                          <FormControlLabel
                            value="Union"
                            control={<Radio />}
                            label="Union Territory"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item md={2} xs={12}></Grid>
                    </Grid>
                    <FieldArray name="pogenerate">
                      {({ push, remove }) => (
                        <div>
                          {values.pogenerate.map((e, i) => {
                            const isGST = `pogenerate[${i}].isGST`;
                            const touchedGST = getIn(touched, isGST);
                            const errorGST = getIn(errors, isGST);
                            const unitPrice = `pogenerate[${i}].unitPrice`;
                            const touchedunitPrice = getIn(touched, unitPrice);
                            const errorunitPrice = getIn(errors, unitPrice);
                            return (
                              <div key={e.id}>
                                {/* {selectedIndent && selectedIndent.map((e, i) => { */}
                                {/* return <div> */}
                                <Box
                                  mt={3}
                                  style={{
                                    backgroundColor: '#fff',
                                    boxShadow:
                                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                  }}
                                >
                                  <Grid container spacing={3}>
                                    <Grid item md={3} xs={12}>
                                      <strong>Category Name:</strong>{' '}
                                      {e.categoryName}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <strong>Material Name:</strong> {e.name}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <strong>UOM:</strong> {e.uom}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <strong>Quantity:</strong> {e.Qty}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touchedGST && errorGST)}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          GST
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          label="GST"
                                          name={isGST}
                                          onBlur={handleBlur}
                                          onChange={e => {
                                            handleChange(e);
                                            let elem = [...selectedIndent];
                                            elem[i].isGST = e.target.value;
                                            setSelectedIndent([...elem]);
                                            if (!e.target.value) {
                                              let item = [...selectedIndent];
                                              item[i].gstType = 0;
                                              setSelectedIndent([...item]);
                                            }
                                          }}
                                          // value={e.GST}
                                        >
                                          <MenuItem value={true}>YES</MenuItem>
                                          <MenuItem value={false}>NO</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                          {touchedGST && errorGST
                                            ? errorGST
                                            : ''}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    {selectedIndent[i].isGST && (
                                      <Grid item md={3} xs={12}>
                                        <TextField
                                          fullWidth
                                          helperText=""
                                          label="GST Value"
                                          onChange={e => {
                                            if (
                                              e.target.value === '' ||
                                              regEx.numbersOnly.test(
                                                e.target.value
                                              )
                                            ) {
                                              let item = [...selectedIndent];
                                              item[i].gstType = Number(
                                                e.target.value
                                              );
                                              setSelectedIndent([...item]);
                                            }
                                          }}
                                          variant="outlined"
                                        />
                                      </Grid>
                                    )}
                                    {inter && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>IGST:</strong>{' '}
                                          {selectedIndent[i].gstType}
                                        </Grid>
                                      </>
                                    ) : null}
                                    {intra && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>SGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <strong>CGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                      </>
                                    ) : null}
                                    {union && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>UGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <strong>CGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                      </>
                                    ) : null}
                                    <Grid item md={3} xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Unit Price"
                                        name={unitPrice}
                                        onBlur={handleBlur}
                                        helperText={
                                          touchedunitPrice && errorunitPrice
                                            ? errorunitPrice
                                            : ''
                                        }
                                        error={Boolean(
                                          touchedunitPrice && errorunitPrice
                                        )}
                                        onChange={e => {
                                          const re = /^[0-9.\b]+$/;
                                          if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                          ) {
                                            handleChange(e);
                                            let item = [...selectedIndent];
                                            item[i].unitPrice = e.target.value;
                                            setSelectedIndent([...item]);
                                          }
                                        }}
                                        value={selectedIndent[i].unitPrice}
                                        required
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <TextField
                                        fullWidth
                                        helperText=""
                                        label="Description"
                                        name="price"
                                        onChange={e => {
                                          let item = [...selectedIndent];
                                          item[i].desc = e.target.value;
                                          setSelectedIndent([...item]);
                                        }}
                                        required
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                                {/* </div> */}
                                {/* })} */}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </Box>
                  &nbsp; &nbsp;
                  <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          color="primary"
                          // onClick={handleGeneratePO}
                          type="submit"
                          variant="contained"
                        >
                          Generate PO
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          onClick={handleGeneratePo}
                          variant="contained"
                        >
                          Close
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </DialogContent>
            )}
          </Formik>
        </Dialog>

        {/* po modal */}

        {/* <Dialog aria-labelledby="customized-dialog-title" open={PoView}
                    id="income"
                    contentStyle={{
                        width: '200%',
                        maxWidth: 'none',
                    }}
                    maxWidth="lg" fullWidth
                >
                    <Loader isLoading={isLoading} />
                    <Formik
                        enableReinitialize={true}
                        initialValues={
                            {
                                Note: indentItem.currentApprovals.comment == 'undefined' ? '' : indentItem.currentApprovals.comment,
                                status: indentItem.currentApprovals.approvalStatusID == 0 ? '' : indentItem.currentApprovals.approvalStatusID
                            }
                        }
                        onSubmit={(values, { resetForm }) => {
                            updatePOApproval()
                            resetForm(values);
                        }}
                        validationSchema={
                            Yup.object().shape({
                                Note: Yup.string().required(MESSAGE.note),
                                status: Yup.string().required(MESSAGE.status)
                            })
                        }
                    >


                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            resetForm,
                        }) => (
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <DialogTitle id="customized-dialog-title" onClose={handlePOViewClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
                                    Po Requested Items View
                                </DialogTitle>


                                <DialogContent dividers style={{ width: "800px !important" }}>
                                    <Grid



                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            lg={4}
                                            sm={4}
                                            xl={4}
                                            xs={4}
                                        >
                                            <div style={{ textAlign: "center" }}>
                                                <Typography><h5 style={{ color: '#394671' }}>PO Master ID : {indentItem.purchaseOrderMasterID}</h5></Typography>
                                            </div>

                                        </Grid>
                                        <Grid
                                            item
                                            lg={4}
                                            sm={4}
                                            xl={4}
                                            xs={4}
                                        >
                                            <div style={{ textAlign: "center" }}>
                                                <Typography><h5 style={{ color: '#394671' }}>Total Quantity: {indentItem.totalQty}</h5></Typography>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            lg={4}
                                            sm={4}
                                            xl={4}
                                            xs={4}
                                        >
                                            <div style={{ textAlign: "center" }}>
                                                <Typography><h5 style={{ color: '#394671' }}>Total Value : {indentItem.totalValue}</h5></Typography>
                                            </div>

                                        </Grid>
                                        <Grid item
                                            lg={12}
                                            sm={12}
                                            xl={12}
                                            xs={12}>
                                            <div style={{ textAlign: "center" }}>
                                                <CommonTable columns={POApprovalView} data={cpd} />
                                            </div>
                                        </Grid>
                                        <Grid item
                                            lg={12}
                                            sm={12}
                                            xl={12}
                                            xs={12}>
                                            <Box mt={3} style={{
                                                backgroundColor: '#fff',
                                                boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                                borderRadius: '5px',
                                                padding: '20px'
                                            }}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                >
                                                    <Grid
                                                        item
                                                        md={indentItem.currentApprovals.referOptionView ? 6 : 8}
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            disabled={indentItem.currentApprovals.editLock}
                                                            fullWidth
                                                            error={Boolean(touched.Note && errors.Note)}
                                                            helperText={touched.Note && errors.Note}
                                                            label="Note"
                                                            name="Note"
                                                            value={indentItem.currentApprovals.comment == 'undefined' ? '' : indentItem.currentApprovals.comment}
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                let rowData = { ...indentItem }
                                                                rowData.currentApprovals.comment = e.target.value;
                                                                setIndentItem({ ...rowData })
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={indentItem.currentApprovals.referOptionView ? 3 : 4}
                                                        xs={12}
                                                    >

                                                        <FormControl variant="outlined"
                                                            style={{ width: '100%' }}
                                                            className={classes.formControl}
                                                            error={Boolean(touched.status && errors.status)}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                                            <Select
                                                                disabled={indentItem.currentApprovals.editLock}
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    let rowData = { ...indentItem }
                                                                    rowData.currentApprovals.approvalStatusID = e.target.value;
                                                                    setIndentItem({ ...rowData })
                                                                }}
                                                                label="Status"
                                                                name="status"
                                                                value={indentItem.currentApprovals.approvalStatusID == 0 ? '' : indentItem.currentApprovals.approvalStatusID}
                                                            >
                                                                {approvalStatusList.map(li => <MenuItem key={li.lookupID} value={li.lookupID}>{li.name}</MenuItem>)}
                                                            </Select>
                                                            <FormHelperText>{(errors.status && touched.status) && errors.status}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    {indentItem.currentApprovals.referOptionView == 1 ? !direct && (<Grid
                                                        item
                                                        md={indentItem.currentApprovals.referOptionView ? 3 : 0}
                                                        xs={indentItem.currentApprovals.referOptionView ? 12 : 0}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox disabled={indentItem.currentApprovals.editLock} checked={indentItem.currentApprovals.referSelected}
                                                                onChange={e => {
                                                                    let rowData = { ...indentItem }
                                                                    rowData.currentApprovals.referSelected = !rowData.currentApprovals.referSelected;
                                                                    setIndentItem({ ...rowData })
                                                                }}
                                                            />} label="Refer to director" />
                                                        </FormGroup>
                                                    </Grid>) : null}
                                                </Grid>

                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={12}
                                            sm={12}
                                            xl={12}
                                            xs={12}
                                        >
                                            <StepperBox steps={step} />


                                        </Grid>


                                    </Grid>


                                </DialogContent>


                                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                                    <Button
                                        disabled={indentItem.currentApprovals.editLock}
                                        // onClick={(e) => {
                                        //   updatePOApproval();
                                        // }}
                                        type='submit'
                                        style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={handlePOViewClose}
                                        style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>

                </Dialog> */}

        {/* Print */}

        <Dialog
          aria-labelledby="customized-dialog-title"
          contentStyle={{
            width: '100%',
            maxWidth: 'none'
          }}
          maxWidth="md"
          fullWidth
          open={openincPrint}
          id="income"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleIncPrintClose}
            style={{ backgroundColor: '#394671', color: '#fff' }}
          >
            PO Generation Print View
          </DialogTitle>
          <DialogContent dividers style={{ overflowX: 'hidden' }}>
            <div>
              {/* <div style={{ marginLeft: "75%" }}> */}
              <PictureAsPdfIcon
                // onClick={exportPdf}
                onClick={printOrder}
                style={{ cursor: 'pointer' }}
              />{' '}
              &nbsp;
              <PrintIcon onClick={printOrder} style={{ cursor: 'pointer' }} />
            </div>
            <div className="invoice-box" id="printme">
              <table cellpadding="0" cellspacing="0">
                <tr className="top_rw">
                  <td colspan="2">
                    <h2 style={{ marginBottom: '0px' }}>
                      {' '}
                      Tax invoice / Bill of Supply / Cash memo{' '}
                    </h2>
                    <span> Date: {item?.CreateDate} </span>
                  </td>
                  <td style={{ width: '30%', marginRight: '10px' }}>
                    Purchase Order Master Id:{' '}
                    {allPurchaseOrder[0]?.purchaseOrderMasterID}
                  </td>
                </tr>
                <tr className="top">
                  <td colspan="3">
                    <table>
                      <tr>
                        <td>
                          {/* <Address details={allPurchaseOrder[0]?.vendorDetails[0].address} /> */}
                          <b>
                            {' '}
                            Sold By:{' '}
                            {allPurchaseOrder[0]?.vendorDetails[0]?.name}{' '}
                          </b>{' '}
                          <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.line_1} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.line_2} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.district}
                          Pin Code :{' '}
                          {
                            allPurchaseOrder[0]?.vendorAddress[0]?.postalCode
                          }{' '}
                          <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.state} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.country}{' '}
                          <br />
                          PAN : {
                            allPurchaseOrder[0]?.vendorDetails[0]?.pan
                          }{' '}
                          <br />
                          GSTIN : {
                            allPurchaseOrder[0]?.vendorDetails[0]?.gstn
                          }{' '}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className="information">
                  <td colspan="3">
                    <table>
                      <tr>
                        <td colspan="2">
                          <b> Shipping Address: </b> <br />
                          <Address
                            details={allPurchaseOrder[0]?.projectAddress}
                          />
                        </td>
                        <td>
                          {' '}
                          <b> Billing Address: </b>
                          <br />
                          <Address
                            details={allPurchaseOrder[0]?.projectAddress}
                          />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <td colspan="3">
                  {/* <div style={{ textAlign: "center" }}>
                <CommonTable columns={POApprovalView} data={cpd} />
              </div> */}
                </td>
                <tr className="totalleft">
                  <td colspan="3">
                    <div style={{ textAlign: 'center' }}>
                      <CommonTable columns={POApprovalView} data={cpd} />
                    </div>
                  </td>
                </tr>
                <tr className="total">
                  {/* <td colspan="3" align="right">  Total Amount in Words :  <b> {capitalize(number2words(cpd?.reduce(function (acc, b) { return acc + b.unitPrice }, 0)))} Rupees Only </b> </td> */}
                </tr>
                <tr>
                  <td colspan="3">
                    <table cellspacing="0px" cellpadding="2px">
                      <tr>
                        <td width="50%">
                          <b> Declaration: </b> <br />
                          We declare that this invoice shows the actual price of
                          the goods described above and that all particulars are
                          true and correct.The goods sold are intended for end
                          user consumption and not for resale.
                        </td>
                        <td>
                          * This is a computer generated invoice and does not
                          require a physical signature
                        </td>
                      </tr>
                      <tr>
                        <td width="50%"></td>
                        <td>
                          <b> Authorized Signature </b>
                          <br />
                          <br />
                          ...................................
                          <br />
                          <br />
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>

            {/* </div> */}
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
            <Button
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
              onClick={handleIncPrintClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};
export default GINReport;
