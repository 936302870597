import React, { useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
  MenuItem, FormControl, InputLabel, Select, TextField
} from '@material-ui/core';
import Page from 'src/components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { statusColor } from 'src/utils/helpers';
import { useParams } from "react-router-dom";
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
import Address from "../Common/Address";
import { parseData, dateFormate, getProjectDetails, dateForm } from '../Common/utilities';
import StepperBox from "../Common/StepperBox.jsx";
import { getloggedInData } from 'src/utils/helpers';
import { Constant } from "../Common/Constant";
import { ToastContainer, toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
  // tableRoot : { border: 1,borderStyle: 'solid'} 
}));
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PaymentGateway = () => {
  const classes = useStyles();
  const { project } = useParams();
  const { accessRoleID = null } = getloggedInData()
  const projectDetails = { ...getProjectDetails(project) }
  const [GSTTDS, setGSTTDS] = React.useState("NO");
  const [TAXTDS, setTAXTDS] = React.useState("NO");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [advancePayModel, setAdvancePayModel] = React.useState(false);
  const [paymentApprovalList, setpaymentApprovalList] = React.useState([]);
  const [paymentApproval, setPaymentApproval] = React.useState({});
  const [direct, setDirect] = React.useState(false);
  const [approvalStatusList, setApprovalStatusList] = React.useState([])
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then((res) => {
        if (res.data.status === 200) {
          setApprovalStatusList(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };
  const handleAdvancePayModel = (data) => {
    let rowData = { ...data };
    rowData.currentApprovals = data.approvals?.find(app => app.accessRoleID === accessRoleID)
    // data.approvals.forEach(e => {
    //   if (e.accessRoleID === 3) {
    //     setDirect(true)
    //   }
    // });
    setPaymentApproval({ ...rowData })
    setAdvancePayModel(!advancePayModel)
  }
  const updatePaymentApproval = () => {
    let referOptionPayload = false
    let referPayload = false
    if (paymentApproval.currentApprovals.referOptionView == 0) {
      referOptionPayload = false
    } else {
      referOptionPayload = true;
    }
    if (paymentApproval.currentApprovals.referSelected == 0) {
      referPayload = false
    } else {
      referPayload = true;
    }
    const payload = {
      "statusID": paymentApproval.currentApprovals.approvalStatusID,
      "paymentSystemMasterID": paymentApproval.paymentSystemMasterID,
      "comment": paymentApproval.currentApprovals.comment,
      "referOption": referOptionPayload,
      "refer": referPayload,
      "value": paymentApproval.value + paymentApproval.balancePayable
    }
    APIKit.put(RGCA_URLS.updateApprovalForPayment, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setAdvancePayModel(!advancePayModel)
          getPaymentApprovalList()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })

  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: 'Accountant',
      status: 'Created',
      description: 'Accountant comment'
    },
    {
      label: 'Project Head Approval',
      status: "AWAITING",
      description: 'Project Head comment'
    }

  ];

  useEffect(() => {
    getPaymentApprovalList()
    getLookup()
  }, [])

  const getPaymentApprovalList = () => {
    APIKit.get(RGCA_URLS.getPaymentAllApprovedList + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              approvals: parseData(x.Approvals),
            }
          })
          setpaymentApprovalList(data);
        } else {
        }
      })
      .catch((e) => {
      });
  };

  return <Page
    className={classes.root}
    title="Payment Approval"
  >
    <ToastContainer />
    <Container maxWidth={false}>
      <Typography><h2 style={{ color: '#394671' }}>Payment All Approved List</h2></Typography>
      <Grid
        container
        spacing={3}
      >


        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <div style={{ textAlign: "center" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Payment ID</TableCell>
                    <TableCell align="center">Project Name</TableCell>
                    <TableCell align="center">Payment Method</TableCell>
                    <TableCell align="center">GRN Master ID</TableCell>
                    <TableCell align="center">WorkOrder Master ID</TableCell>
                    <TableCell align="center">GST TDS</TableCell>
                    <TableCell align="center">IT TDS</TableCell>
                    <TableCell align="center">Created Date</TableCell>
                    <TableCell align="center">Vendor</TableCell>
                    <TableCell align="center">Amount To Be Paid</TableCell>
                    <TableCell align="center">Purpose</TableCell>

                    {/* <TableCell align="center">Action</TableCell> */}

                  </TableRow>
                </TableHead>
                <TableBody>

                  {paymentApprovalList && paymentApprovalList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((y) => {
                    return <TableRow hover role="checkbox" tabIndex={-1} >
                      <TableCell align="center">{y.paymentSystemMasterID}</TableCell>
                      <TableCell align="center">{y.projectName}</TableCell>
                      <TableCell align="center">{y.paymentMethod == 56 ? 'Advance Payment' : 'Full Payment'}</TableCell>
                      <TableCell align="center">{y.GRNID ?? '-'}</TableCell>
                      <TableCell align="center">{y.workOrderID ?? '-'}</TableCell>
                      <TableCell align="center">Rate({y.gstTdsRate}),Exemption({y.gstTaxExemption})</TableCell>
                      <TableCell align="center">Rate({y.itTdsRate}),Exemption({y.itTaxExemption})</TableCell>
                      <TableCell align="center">{dateForm(y.createdDate)}</TableCell>
                      <TableCell align="center">{y.vendorName}</TableCell>
                      <TableCell align="center">{y.value}</TableCell>
                      <TableCell align="center">{y.purposeAdvance}</TableCell>


                      {/* <TableCell align="center">

                        <EditIcon style={{ cursor: "pointer" }} onClick={() => handleAdvancePayModel(y)} />
                      </TableCell> */}
                    </TableRow>
                  })}

                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paymentApprovalList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </div>
        </Grid>

      </Grid>
    </Container>

    {/* More */}
    <Dialog aria-labelledby="customized-dialog-title" open={advancePayModel} id="income"
      //  style={{ maxWidth: "1100px !important", width: "1100px" }}
      contentStyle={{
        width: '200%',
        maxWidth: 'none',
      }}
      maxWidth="lg" fullWidth
    >
      <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff", width: "1200px !important" }}>
        Approval
      </DialogTitle>
      <DialogContent dividers style={{ width: "1200px !important" }}>
        <Grid

          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div style={{ textAlign: "center" }}>
              <b>Rajiv Gandhi Centre for Aquaculture </b> <br />
              (A Society under Marine Products Export Development Authority,<br />
              Ministry of Commerce & Industry, Govt. of India)<br />
              Technology Development Centre for Sustainable Aquaculture<br />
            </div>

          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div style={{ textAlign: "center" }}>
              <table style={{ width: "100%" }} dir="ltr" border="1" cellspacing="0" cellpadding="0"><colgroup><col width="100" /><col width="100" /><col width="100" /><col width="100" /><col width="100" /></colgroup>
                <tbody>
                  <tr>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Bill Ref.&quot;}"><b>Bill Ref.</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot; Bill date&quot;}"><b>Bill date</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount&quot;}"><b>Amount</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;GST&quot;}"><b>GST</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Gross Bill&quot;}"><b>Gross Bill</b></td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Total Bill&quot;}"><b>Total Bill</b></td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <br />

              <table style={{ width: "100%" }} dir="ltr" border="1" cellspacing="0" cellpadding="0"><colgroup><col width="100" /><col width="100" /><col width="100" /><col width="100" /><col width="100" /></colgroup>
                <tbody>
                  <tr>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Deductions&quot;}"><b>Deductions</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;base amount&quot;}"><b>base amount</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Rate&quot;}"><b>Rate</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Section Ref.&quot;}"><b>Section Ref.</b></td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Value&quot;}"><b>Value</b></td>
                  </tr>
                  <tr>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;TDS (IT)&quot;}"><b>TDS (IT)</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;TDS (GST)&quot;}"><b>TDS (GST)</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Advance paid&quot;}"><b>Advance paid</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Total deductions&quot;}"><b>Total deductions</b></td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </Grid>
          <Grid item
            lg={12}
            sm={12}
            xl={12}
            xs={12}>
            <Box mt={3} style={{
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
              borderRadius: '5px',
              padding: '20px'
            }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={paymentApproval?.currentApprovals?.referOptionView ? 6 : 8}
                  xs={12}
                >
                  <TextField
                    disabled={paymentApproval?.currentApprovals?.editLock}
                    fullWidth

                    label="Note"
                    name="Note"
                    value={paymentApproval?.currentApprovals?.comment == 'undefined' ? '' : paymentApproval?.currentApprovals?.comment}
                    onChange={(e) => {
                      let rowData = { ...paymentApproval }
                      rowData.currentApprovals.comment = e.target.value;
                      setPaymentApproval({ ...rowData })
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={paymentApproval?.currentApprovals?.referOptionView ? 3 : 4}
                  xs={12}
                >

                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select
                      disabled={paymentApproval?.currentApprovals?.editLock}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={(e) => {
                        let rowData = { ...paymentApproval }
                        rowData.currentApprovals.approvalStatusID = e.target.value;
                        setPaymentApproval({ ...rowData })
                      }}
                      name="status"
                      label="Status"
                      value={paymentApproval?.currentApprovals?.approvalStatusID == 0 ? '' : paymentApproval?.currentApprovals?.approvalStatusID}
                    >
                      {approvalStatusList.map(li => <MenuItem key={li.lookupID} value={li.lookupID}>{li.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {paymentApproval?.currentApprovals?.referOptionView == 1 ? !direct && (<Grid
                  item
                  md={paymentApproval?.currentApprovals?.referOptionView ? 3 : 0}
                  xs={paymentApproval?.currentApprovals?.referOptionView ? 12 : 0}
                >
                  <FormGroup>
                    <FormControlLabel control={<Checkbox disabled={paymentApproval?.currentApprovals?.editLock} checked={paymentApproval?.currentApprovals?.referSelected}
                      onChange={e => {
                        let rowData = { ...paymentApproval }
                        rowData.currentApprovals.referSelected = !rowData.currentApprovals.referSelected;
                        setPaymentApproval({ ...rowData })
                      }}
                    />} label="Refer to director" />
                  </FormGroup>
                </Grid>) : null}
              </Grid>

            </Box>
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Box >
              <StepperBox steps={paymentApproval.approvals} />
            </Box>

          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div style={{ textAlign: "right" }}>
              {/* <Button
                                color="primary"
                                onClick={handleAdvancePayModel}
                                variant="contained" >
                                Update
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                onClick={handleAdvancePayModel}
                                color="primary"
                                variant="contained" >
                                Close
                            </Button> */}
            </div>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
        <Button
          color="primary"
          onClick={updatePaymentApproval}
          className={classes.button2}
          variant="contained" >
          Update
        </Button>
        <Button
          onClick={handleAdvancePayModel}
          className={classes.button2}
          color="primary"
          variant="contained" >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </Page>

}

export default PaymentGateway
