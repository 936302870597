import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    InputAdornment,
    TextField,
    SvgIcon,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import './style.css';

const Roles = () => {

    return (
        <Page title="Roles">
            <Container maxWidth={false}>
                <Box mt={3}>
                    <TableContainer>
                        <Table>

                        <table>
                    <tr>
                        <th></th>
                        <th colspan="3">Indent 2</th>
                        <th colspan="3">Purchase Order</th>
                        <th colspan="3">Work Order</th>
                        <th colspan="3">GRN</th>
                        <th colspan="3">GIN</th>
                        <th colspan="3">Payment Advice</th>
                        <th colspan="3">FAR</th>
                        <th colspan="3">Inventroy Register</th>
                    </tr>
                    <tr>
                        <th rowspan="1">&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                        <td>Create/Edit</td>
                        <td>Edit/Approve</td>
                        <td>View</td>

                    </tr>

                    <tr>
                        <th rowspan="1">Chairman</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>


                    <tr>
                        <th rowspan="1">Director</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Project Director</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Administrative Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Junior Administrative Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Principal Scientific Officer </th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>


                    <tr>
                        <th rowspan="1">Project Account Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>


                    <tr>
                        <th rowspan="1">Project Accountant</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>


                    <tr>
                        <th rowspan="1">Project Senior Scientific Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Project Scientific Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Junior Technical Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Project Senior Field Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>


                    <tr>
                        <th rowspan="1">Project Field Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Project Electrician</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Project Engineer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Accounts Officer</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">HO Accountant</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Purchase / Store Assistant</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Driver</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>

                    <tr>
                        <th rowspan="1">Administrative Assistant</th>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '35px' }} /></td>
                        <td><input type="checkbox" style={{ position: 'relative', left: '15px' }} /></td>

                    </tr>
                </table>


                        </Table>
                    </TableContainer>
                </Box>


            </Container>
        </Page>
    )

}
export default Roles;