

import React from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { statusColor } from 'src/utils/helpers';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark, minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));



// const columns = [
//     { id: 'name', label: 'Name', minWidth: 170 },
//     { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//     {
//         id: 'population',
//         label: 'Population',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'size',
//         label: 'Size\u00a0(km\u00b2)',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'density',
//         label: 'Density',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toFixed(2),
//     },
// ];

// function createData(name, code, population, size) {
//     const density = population / size;
//     return { name, code, population, size, density };
// }

// const rows = [
//     createData('India', 'IN', 1324171354, 3287263),
//     createData('China', 'CN', 1403500365, 9596961),
//     createData('Italy', 'IT', 60483973, 301340),
//     createData('United States', 'US', 327167434, 9833520),
//     createData('Canada', 'CA', 37602103, 9984670),
//     createData('Australia', 'AU', 25475400, 7692024),
//     createData('Germany', 'DE', 83019200, 357578),
//     createData('Ireland', 'IE', 4857000, 70273),
//     createData('Mexico', 'MX', 126577691, 1972550),
//     createData('Japan', 'JP', 126317000, 377973),
//     createData('France', 'FR', 67022000, 640679),
//     createData('United Kingdom', 'GB', 67545757, 242495),
//     createData('Russia', 'RU', 146793744, 17098246),
//     createData('Nigeria', 'NG', 200962417, 923768),
//     createData('Brazil', 'BR', 210147125, 8515767),
// ];

const Recurring = () => {
    const classes = useStyles();
    // const history = useHistory();
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [indentView, setIndentView] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(2);
    
   

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleIndentViewClose = () => {
        setIndentView(!indentView)
    }

    const ProjectIndentCreateOrEdit = (indentID=null) => {
        if(indentID){
            navigate('/app/ProjectIndentCreateOrEdit/RecurringExpenditure?indent='+indentID)
        }
        else {
            navigate('/app/ProjectIndentCreateOrEdit/RecurringExpenditure') 
        }
        
    }


    const rows = [
        {
            IndentID: "IND-RE-10052021-001",
            quantity: 15,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "10/05/2021 10:20AM"

        },
        {
            IndentID: "IND-RE-10052021-002",
            quantity: 35,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Kiran-Technician",
            date: "12/05/2021 11:20AM"
        },
        {
            IndentID: "IND-RE-10052021-003",
            quantity: 25,
            estimated: 530000,
            value: 0,
            status: "APPROVED",
            createBy: "Kiran-Technician",
            date: "13/05/2021 9:20AM"
        },
        {
            IndentID: "IND-RE-10052021-004",
            quantity: 25,
            estimated: 540000,
            value: 0,
            status: "REJECTED",
            createBy: "Narasimha-Technician",
            date: "13/05/2021 12:00PM"
        },
        {
            IndentID: "IND-RE-10052021-005",
            quantity: 15,
            estimated: 50000,
            value: 0,
            status: "HOLD",
            createBy: "Narasimha-Technician",
            date: "14/05/2021 01:00PM"
        },
        {
            IndentID: "IND-RE-10052021-006",
            quantity: 5,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "14/05/2021 02:00PM"
        },
        {
            IndentID: "IND-RE-10052021-007",
            quantity: 15,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "10/05/2021 10:20AM"

        },
        {
            IndentID: "IND-RE-10052021-008",
            quantity: 35,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Kiran-Technician",
            date: "12/05/2021 11:20AM"
        },
        {
            IndentID: "IND-RE-10052021-009",
            quantity: 25,
            estimated: 530000,
            value: 0,
            status: "PENDING",
            createBy: "Kiran-Technician",
            date: "13/05/2021 9:20AM"
        },
        {
            IndentID: "IND-RE-10052021-0010",
            quantity: 25,
            estimated: 540000,
            value: 0,
            status: "PENDING",
            createBy: "Narasimha-Technician",
            date: "13/05/2021 12:00PM"
        },
        {
            IndentID: "IND-RE-10052021-0011",
            quantity: 15,
            estimated: 50000,
            value: 0,
            status: "PENDING",
            createBy: "Narasimha-Technician",
            date: "14/05/2021 01:00PM"
        },
        {
            IndentID: "IND-RE-10052021-0012",
            quantity: 5,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "14/05/2021 02:00PM"
        },
        {
            IndentID: "IND-RE-10052021-0013",
            quantity: 15,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "10/05/2021 10:20AM"

        },
        {
            IndentID: "IND-RE-10052021-0014",
            quantity: 35,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Kiran-Technician",
            date: "12/05/2021 11:20AM"
        },
        {
            IndentID: "IND-RE-10052021-0015",
            quantity: 25,
            estimated: 530000,
            value: 0,
            status: "PENDING",
            createBy: "Kiran-Technician",
            date: "13/05/2021 9:20AM"
        },
        {
            IndentID: "IND-RE-10052021-0016",
            quantity: 25,
            estimated: 540000,
            value: 0,
            status: "PENDING",
            createBy: "Narasimha-Technician",
            date: "13/05/2021 12:00PM"
        },
        {
            IndentID: "IND-RE-10052021-0017",
            quantity: 15,
            estimated: 50000,
            value: 0,
            status: "PENDING",
            createBy: "Narasimha-Technician",
            date: "14/05/2021 01:00PM"
        },
        {
            IndentID: "IND-RE-10052021-0018",
            quantity: 5,
            estimated: 450000,
            value: 0,
            status: "PENDING",
            createBy: "Ashok Reddy-Technician",
            date: "14/05/2021 02:00PM"
        }
    ]
    const steps = [
        {
          label: 'Accountant Approval',
          description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
          label: 'Project Head Approval',
          description:
            'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
          label: 'Admin Approval',
          description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Project Director Approval',
            description: `Try out different ad text to see what brings in the most customers,
                      and learn how to enhance your ads using features like ad extensions.
                      If you run into any problems with your ads, find out how to tell if
                      they're running and how to resolve approval issues.`,
          },
      ];
      
     

    return <Page
        className={classes.root}
        title="Dashboard"
    >
        <Container maxWidth={false}>
            <Typography><h2 style={{ color: '#394671' }}>Recurring Expenditure</h2></Typography>
            <Grid
                container
                spacing={3}
            >

                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <div style={{ textAlign: "right" }}>
                        <Button
                            color="primary"
                            onClick={(e)=>{
                                ProjectIndentCreateOrEdit("IDIDID") 
                            }}
                            variant="contained" >
                            Create  Indent
                        </Button>
                    </div>
                </Grid>

                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <div style={{ textAlign: "center" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Indent ID</TableCell>
                                        <TableCell align="right">Total Quantity</TableCell>
                                        <TableCell align="right">Estimated Value</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Created By</TableCell>
                                        <TableCell align="right">Created Date</TableCell>
                                        <TableCell align="right">Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.IndentID}>
                                                    <TableCell align="left">{row.IndentID}</TableCell>
                                                    <TableCell align="right">{row.quantity}</TableCell>
                                                    <TableCell align="right">{row.estimated}</TableCell>
                                                    <TableCell align="right">{row.value}</TableCell>
                                                    <TableCell align="right" style={{ color: statusColor(row.status) }}>{row.status}</TableCell>
                                                    <TableCell align="right">{row.createBy}</TableCell>
                                                    <TableCell align="right">{row.date}</TableCell>
                                                    <TableCell align="right">
                                                        <VisibilityIcon   onClick={handleIndentViewClose}  style={{ cursor: "pointer" }} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <EditIcon style={{ cursor: "pointer" }} />


                                                    </TableCell>
                                                </TableRow>)
                                        })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </div>
                </Grid>
            </Grid>
        </Container>

        <Dialog aria-labelledby="customized-dialog-title" open={indentView} id="income" style={{ maxWidth: "1100px !important", width: "1100px" }}>
            <DialogTitle id="customized-dialog-title" onClose={handleIndentViewClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
                View
            </DialogTitle>
            <DialogContent dividers style={{ width: "800px !important" }}>
                <Grid
                    container
                    spacing={3}
                >

                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Indent ID : IND-RE-10052021-0012</h5></Typography>
                        </div>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Total Quantity: 200</h5></Typography>
                        </div>
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Date : 10/05/2021 10:20AM</h5></Typography>
                        </div>

                    </Grid>
                    {/* ---------------------------------------------------------------------------------------- */}

                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Estimated Price: 16000</h5></Typography>
                        </div>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: statusColor('PENDING') }}> Status: PENDING</h5></Typography>
                        </div>
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Last Update : 10/05/2021 10:20AM</h5></Typography>
                        </div>

                    </Grid>
                    <Grid item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}>
                        <div style={{ textAlign: "center" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell align="center">Sub Category</TableCell>
                        <TableCell align="right">Material/Item</TableCell>
                        <TableCell align="right">UOM</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Estimated</TableCell>
                        <TableCell align="right">Description</TableCell>
                       

                        </TableRow>
                        </TableHead>
                        <TableBody>

                        <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">name-1</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell>
                        <TableCell align="right">4000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                        </TableRow> 
                        <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">name-2</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell> 
                        <TableCell align="right">4000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                        </TableRow> 
                        <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">name-3</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell>
                        <TableCell align="right">4000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                        </TableRow> 
                        <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell>
                        <TableCell align="right">4000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                        </TableRow> 
                        
                        </TableBody>
                        </Table>
                        </TableContainer>
                        </div>
                    </Grid>

                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}
                    >
                   <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length-1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            {/* <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                  
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}

                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent> */}
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>

                    </Grid>
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}
                    >
                          <div style={{ textAlign: "right" }}>
                        <Button
                            color="primary"
                            onClick={handleIndentViewClose}
                            variant="contained" >
                           Close
                        </Button>
                    </div>
                        </Grid>

                </Grid>

            </DialogContent>

        </Dialog>
    </Page>

}
export default Recurring;