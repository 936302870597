import React, { useEffect, useState, useRef } from 'react';
import {
    Grid, Typography, makeStyles, Button, Dialog, DialogContent, DialogActions, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import clsx from 'clsx';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { ETTypes, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import { parseData, dateFormate, getProjectDetails, capitalize } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import Loader from '../../loader/index';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { statusColor } from 'src/utils/helpers';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme => ({
    root: {
    },

}));
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Purchase = () => {
    const classes = useStyles();
    const [openincPrint, setOpenincPrint] = useState(false);
    const [allPurchaseOrder, setAllPurchaseOrder] = useState([]);
    const [cpd, setCpd] = useState([]);
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) };
    const [item, setItem] = useState({});
    const [allIndentList, setAllIndentList] = useState([]);
    let userData = JSON.parse(localStorage.getItem('userData'));

    const POApprovalView = [
        {
            title: 'PO Item ID',
            field: 'purchaseOrderItemID',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'Material Name',
            field: 'name',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'GST',
            field: 'isGST',
            align: 'left',
            type: ETTypes.boolean
        },
        {
            title: 'IGST',
            field: 'IGST',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'CGST',
            field: 'CGST',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'SGST',
            field: 'SGST',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'UGST',
            field: 'UGST',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'UOM',
            field: 'uom',
            align: 'left',
            type: ETTypes.numeric
        },
        {
            title: 'Unit Price',
            field: 'unitPrice',
            align: 'left',
            type: ETTypes.currency
        },
        {
            title: 'Total Price(Inclusive GST)',
            field: 'totalAmount',
            align: 'left',
            type: ETTypes.currency
        },
        {
            title: 'Description',
            field: 'description',
            align: 'left',
            type: ETTypes.string
        }
    ];
    const viewIndent = [
        {
            title: 'Indent ID',
            field: 'indentMasterID',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'PO ID',
            field: 'purchaseOrderMasterID',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: ' Quantity',
            field: 'totalQty',
            align: 'left',
            type: ETTypes.string
        },
        // {
        //     title: 'Value',
        //     field: 'totalValue',
        //     align: 'left',
        //     type: ETTypes.currency
        // },
        {
            title: 'Raised By',
            field: 'CreatedByName',
            align: 'left',
            type: ETTypes.string
        },
        // {
        //     title: 'Created Date & Time',
        //     field: 'CreateDate',
        //     align: 'left',
        //     type: ETTypes.date
        // },
        {
            title: 'Print View',
            field: 'action',
            align: 'left',
            list: [ETaction.onView]
            // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
        }
    ];

    useEffect(() => {
        // getListOfPurchaseOrder();
        getPurchaseOrderBasedonEmpIDData()
    }, [projectDetails?.projectID]);

    const [columns, setColumns] = useState([
        {
            title: 'Indent ID',
            field: 'indentMasterID',
            align: 'left',
            type: ETTypes.string
        },
        // {
        //     title: "Project Name",
        //     field: "projectName",
        //     align: 'left',
        //     type: ETTypes.string,
        // },
        // {
        //     title: "Quote",
        //     field: "quoteUrl",
        //     align: 'left',
        //     type: ETTypes.link,
        // },
        {
            title: 'Created By',
            field: 'CreatedByName',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'Created Date',
            field: 'CreateDate',
            align: 'left',
            type: ETTypes.date
        },
        {
            title: 'Action',
            field: 'action',
            align: 'left',
            list: [ETaction.onView]
            // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
        }
    ]);

    const [indentItemList, setIndentItemList] = useState({
        indentMasterID: '',
        ProjectID: null,
        statusID: null,
        UpdatedBy: null,
        CreatedBy: null,
        quoteUrl: '',
        CreateDate: '',
        UpdatedDate: null,
        projectName: '',
        CreatedByName: '',
        indentItem: [],
        Approvals: []
    });
    const [isColour, setIsColour] = useState(false);


    const getPurchaseOrderBasedonEmpIDData = () => {
        let payload = {
            projectID: projectDetails.projectID,
            empLoginID: userData?.empLoginID
        }
        APIKit.post(
            RGCA_URLS.getPurchaseOrderBasedonEmpID, payload
        )
            .then(res => {
                if (res.data.status === 200) {
                    if (res?.data?.data?.length > 0) {
                        let data = res.data.data.map(x => {
                            return {
                                ...x,
                                // Approvals: parseData(x.Approvals),
                                indentItem: parseData(x.indentItem)
                            };
                        });
                        setAllIndentList(data);
                        setIsColour(true)
                    }else{
                        setIsColour(false)
                        setAllIndentList([])
                    }
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(e => { });
    };

    const handleIncPrintClose = () => {
        setOpenincPrint(!openincPrint);
    };

    const modelActions = {
        onView: (index, field, value) => {
            setItem(field);
            console.log(field.purchaseOrderItem, "hello")
            if (field.purchaseOrderItem) {
                setCpd(
                    field.purchaseOrderItem.map(e => {

                        return {
                            ...e,
                            totalGST: e.IGST + e.CGST + e.SGST + e.UGST,
                            totalAmount: Number(
                                e.qty * e.unitPrice +
                                e.qty *
                                e.unitPrice *
                                ((e.IGST || 0 + e.CGST || 0 + e.SGST || 0 + e.UGST || 0) /
                                    100)
                            ),
                            baseAmount: e.qty * e.unitPrice
                        };
                    })
                )
            };
            handleIncPrintClose();
        }
    };

    const actions = {
        onView: (index, row) => {
            setIndentItemList({ ...row });
            setOpenincPrint(true)
        },
    };

    const totalQty = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + x.approvedQty;
        });
        return test;
        //  data['totalQty'] = test;
    };

    const totalPrice = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + (x.estimatedPrice * x.approvedQty);
        });
        return test.toLocaleString('en-IN');
    };


    return (
        <>
            <Accordion defaultExpanded={false} style={{
                borderRadius: "12px",
                boxShadow: `${isColour ? "0 0 10px #46cc48" : "0 0 10px #3979c3"}`
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.secondaryHeading}>
                        <b>My Po-Order</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <CommonTable
                                    columns={columns}
                                    data={allIndentList}
                                    action={actions}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        contentStyle={{ width: '100%', maxWidth: 'none' }} maxWidth="lg"
                        fullWidth
                        open={openincPrint}
                        id="income"
                    >
                        <DialogTitle id="customized-dialog-title"
                            onClose={handleIncPrintClose}
                            style={{ backgroundColor: '#394671', color: '#fff' }}
                        >
                            PO View
                        </DialogTitle>
                        <DialogContent dividers style={{ overflowX: 'hidden' }}>
                            <DialogContent dividers style={{ width: '800px !important' }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: '#394671' }}>
                                                <Typography>
                                                    Indent Id : {indentItemList.indentMasterID}
                                                </Typography>
                                            </h5>
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: '#394671' }}>
                                                <Typography>
                                                    Total Quantity:{' '}
                                                    {totalQty(indentItemList.indentItem || [])}
                                                </Typography>
                                            </h5>
                                        </div>
                                    </Grid>

                                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: '#394671' }}>
                                                <Typography>
                                                    Date : {dateFormate(indentItemList.CreateDate)}
                                                </Typography>
                                            </h5>
                                        </div>
                                    </Grid>

                                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: '#394671' }}>
                                                <Typography>
                                                    Total Estimated Price:{' '}
                                                    {totalPrice(indentItemList.indentItem || [])}
                                                </Typography>
                                            </h5>
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: statusColor('PENDING') }}>
                                                <Typography>
                                                    Status: {indentItemList.status}
                                                </Typography>
                                            </h5>
                                        </div>
                                    </Grid>

                                    <Grid item lg={4} sm={4} xl={4} xs={4}>

                                    </Grid>
                                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <TableContainer>
                                                    <Table className={classes.table}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Category</TableCell>
                                                                <TableCell>Material</TableCell>
                                                                <TableCell>UOM</TableCell>
                                                                <TableCell>Quantity</TableCell>
                                                                <TableCell>Estimated Item Price</TableCell>
                                                                <TableCell>Estimated Price for all Items</TableCell>
                                                                <TableCell>Attachment</TableCell>
                                                                <TableCell>Description</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {indentItemList.indentItem &&
                                                                indentItemList.indentItem.length > 0 ? (
                                                                indentItemList.indentItem.map((ind, index) => (
                                                                    <TableRow key={index} hover>
                                                                        <TableCell>{ind.categoryName}</TableCell>
                                                                        <TableCell>{ind.name}</TableCell>
                                                                        <TableCell>{ind.uom}</TableCell>
                                                                        <TableCell>{ind.approvedQty}</TableCell>
                                                                        <TableCell>{ind.estimatedPrice.toLocaleString('en-IN')}</TableCell>
                                                                        <TableCell>
                                                                            {(ind.approvedQty * ind.estimatedPrice).toLocaleString('en-IN')}
                                                                        </TableCell>
                                                                        <TableCell> <a style={{ color: 'blue', cursor: 'pointer' }}
                                                                            href={ind.itemAttach} target="_blank"
                                                                        >{ind.itemAttach ? ind.itemAttach.substring(4, 25) : ''}
                                                                        </a></TableCell>
                                                                        <TableCell>{ind.comment ? ind.comment : ' '}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow hover role="checkbox" key={1}>
                                                                    <TableCell colSpan={7} align="center" key={2}>
                                                                        {'Indent item is deleted by the creator'}
                                                                    </TableCell>{' '}
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </DialogContent>
                        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                            <Button
                                style={{
                                    backgroundColor: '#394671',
                                    color: '#fff',
                                    border: '1px solid'
                                }}
                                onClick={handleIncPrintClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default Purchase;
