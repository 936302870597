import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    makeStyles,
    Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Dialog, Box, TextField, Paper, InputLabel, FormControl, MenuItem, Select,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from '@material-ui/core/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "src/views/loader";
import moment from "moment";



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AssignHistory = ({ isAssignHistory, setIsAssignHistory, assignHistoryData, isLoading }) => {
    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isAssignHistory}
                id="assign"
                contentStyle={{ width: "50%", maxWidth: "none", }}
                maxWidth="md"
                fullWidth
            >
                <Loader isLoading={isLoading} />

                {/* <Loader isLoading={isLoading} /> */}
                <DialogTitle
                    id="scroll-dialog-title"
                    onClose={(e) => { setIsAssignHistory(false); }}
                    style={{ backgroundColor: "#394671", color: "#fff" }}
                >
                    Assign History
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <Box mt={3}>
                                <TableContainer >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">S.No</TableCell>
                                                <TableCell align="left">Date</TableCell>
                                                <TableCell align="left">Project Name</TableCell>
                                                <TableCell align="left">Item Name</TableCell>
                                                <TableCell align="left">Assigned To</TableCell>
                                                <TableCell align="left">Assigned Comments</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignHistoryData && assignHistoryData.length != 0 ? (
                                                assignHistoryData && assignHistoryData.map((x, index) => {
                                                    return (
                                                        <TableRow hover key={index + 1}>
                                                            <TableCell align="left">{index + 1}</TableCell>
                                                            <TableCell align="left">{moment(x.createdDate).format("DD-MM-YYYY")}</TableCell>
                                                            <TableCell align="left">{x.projectName}</TableCell>
                                                            <TableCell align="left">{x.itemName}</TableCell>
                                                            <TableCell align="left">{x.assignedTo}</TableCell>
                                                                {/* <Tooltip title={x?.comment }>
                                                                    {x?.comment.substring(0,10)}
                                                                </Tooltip> */}
                                                                <TableCell align="left">
                                                                    <Tooltip title={x?.comment}>
                                                                        <span>
                                                                            {x?.comment? x?.comment.length > 30 ? `${x?.comment.slice(0, 30)} ...` : x?.comment : ''}
                                                                            {' '}
                                                                        </span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow hover role="checkbox" key={1}>
                                                    <TableCell colSpan={10} align="center" key={2}>
                                                        {'No Data Found'}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                    <Button
                        onClick={(e) => {
                            setIsAssignHistory(false);
                        }}
                        style={{
                            backgroundColor: "#394671",
                            color: "#fff",
                            border: "1px solid",
                        }}
                    >
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssignHistory