
import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem, FormHelperText
} from '@material-ui/core';
import Page from 'src/components/Page';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { Search as SearchIcon } from 'react-feather';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { categoryList, subCategoryList, itemsList, itemTypes } from '../data';
import AutoCompleteField from '../AutoCompleteField';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { data } from './data';
import { useConfirm } from "material-ui-confirm";
import { useToasts } from 'react-toast-notifications';
import './style.css'
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { useParams } from "react-router-dom";
import { getProjectDetails, parseData } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import CommonTable from '../Common/CommonTable';
import { ETTypes } from '../Common/Types';
import { ETaction } from '../Common/Types';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const itemInitialValues = {
  "items": [{
    "materialID": null,
    "ginQty": null,
    "description": "",
    "location": "",
    "cost": null
  }],

}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

const GINForm = () => {
  const confirm = useConfirm();
  const classes = useStyles();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const [value, setValue] = React.useState(0); // first tab
  const [moreView, setMoreView] = React.useState(false);
  const [ginList, setGinList] = useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [form, setForm] = useState([]);
  const [emplyoees, setEmplyoees] = useState([]);
  const [inventoryList, setinventoryList] = useState([])
const [showBtn , setShowBtn] = useState(true);
  const [columns, setColumns] = useState([
    {
      title: "GIN Master ID",
      field: "ginMasterID",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Project Name",
      field: "projectName",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Created Date",
      field: "CreatedDate",
      align: 'left',
      type: ETTypes.date,
    },
    {
      title: "Created By",
      field: "CreatedByName",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Receiver Name",
      field: "receiverName",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Action",
      field: "action",
      align: 'left',
      list: [ETaction.onEdit]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }

  ]);

  const addItem = () => {
    //   const array = []
    let temp = itemList;
    temp.push({
      "materialID": '',
      "ginQty": '',
      "description": "",
      "location": "",
      "cost": '',
      "available": ''
    })
    setItemList([...temp]);
  }

  const [itemList, setItemList] = useState([
    {
      "materialID": '',
      "ginQty": '',
      "description": "",
      "location": "",
      "cost": '',
      "available": ''
    }
  ]);

  const [payload, setPayload] = useState({
    "projectID": null,
    "employeeID": '',
    "items": itemList
  });


  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  const handleRemoveField = (index) => {
    let items = itemList;
    items.splice(index, 1)
    setItemList([...items])
  };

  const getGINList = () => {
    APIKit.get(RGCA_URLS.getGIN + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              ginItem: parseData(x.ginItem),
              projectDetails: parseData(x.projectDetails),
              projectName: parseData(x.projectDetails)[0].projectName
            }
          })
          setGinList(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  const getAllEmplyoees = () => {
    APIKit.get(RGCA_URLS.getEmployee)
      .then((res) => {
        if (res.data.status === 200) {
          setEmplyoees(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  React.useEffect(() => {
    getAllEmplyoees()
    getGINList()
    getInventoryList()
  }, [])

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const [GINEditModelView, setGINEditModelView] = React.useState(false);
  const [selectedGin, setSelectedGin] = useState([])
  const handleGINEditModelView = (data) => {
    setSelectedGin(data.items)
    setGINEditModelView(!GINEditModelView)
  }

  function checkForDuplicates(array) {
    let valuesAlreadySeen = []

    for (let i = 0; i < array.length; i++) {
      let value = array[i]
      if (valuesAlreadySeen.indexOf(value.materialID) !== -1) {
        return true
      }
      valuesAlreadySeen.push(value.materialID)
    }
    return false
  }

  const handleSubmit1 = () => {
    let temp = checkForDuplicates(itemList);
    if (temp) {
      toast.error('Materials are same.If you needed increase the quantity', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return
    }
    if (itemList.some(e => e.ginQty == 0)) {
      toast.error(MESSAGE.qt0, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return
    }
    let payload1 = {
      employeeID: payload.employeeID,
      projectID: projectDetails.projectID,
      items: itemList.map((e) => {
        return {
          materialID: e.materialID,
          location: e.location,
          ginQty: Number(e.ginQty),
          cost: Number(e.cost),
          description: e.description
        }
      })
    }

    APIKit.post(RGCA_URLS.createGIN, payload1)
      .then((res) => {
        if (res.data.status === 200) {
          let temp = [];
          temp.push({
            "materialID": '',
            "ginQty": '',
            "description": "",
            "location": "",
            "cost": '',
            "available": ''
          })
          setItemList([...temp]);
          setPayload([])
          getInventoryList()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }

  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      "projectID": projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };

  const updateGIN = () => {
    let payload1 = {
      ginMasterID: selectedGin[0].ginMasterID,
      projectID: projectDetails.projectID,
      items: selectedGin.map((e) => {
        return {
          materialID: e.materialID,
          location: e.location,
          ginQty: Number(e.ginQty),
          description: e.description
        }
      })
    }
    APIKit.put(RGCA_URLS.updateGIN, payload1)
      .then((res) => {
        if (res.data.status === 200) {
          setGINEditModelView(!GINEditModelView)
          getGINList()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }

  const deleteGINItem = (data) => {
    let payload1 = {
      ginMasterID: data.ginMasterID,
      materialID: data.materialID,
    }
    APIKit.put(RGCA_URLS.deleteGINItem, payload1)
      .then((res) => {
        if (res.data.status === 200) {
          setGINEditModelView(!GINEditModelView)
          getGINList()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }
const hideContent = () => {
  setShowBtn(!showBtn)
}
  return <Page
    className={classes.root}
    title="GIN"
  >
        <>
          <Formik style={{marginTop:10}}
            enableReinitialize={true}
            initialValues={{
              employeeID: payload.employeeID,
              GIN: [
                ...itemList,
              ],

            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmit1()
            }}

            validationSchema={
              Yup.object().shape({
                GIN: Yup.array().of(
                  Yup.object().shape({
                    materialID: Yup.string().required(MESSAGE.materials),
                    ginQty: Yup.string().required(MESSAGE.nou),
                    location: Yup.string().required(MESSAGE.location1),
                  })
                ),
                employeeID: Yup.string().required(MESSAGE.employee),
              })
            }

          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              isInitialValid = false,
              isValid,
              touched,
              values,
              resetForm
            }) => (
              <>
                <Grid
                  item
                  lg={3}
                  sm={3}
                  xl={3}
                  xs={3}
                >
                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}
                    error={Boolean(touched.employeeID && errors.employeeID)}>
                    <InputLabel id="demo-simple-select-outlined-label">Employee</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Employee"
                      value={payload.employeeID}
                      name="employeeID"
                      onChange={(e) => {
                        handleChange(e)
                        const p = payload;
                        p.employeeID = Number(e.target.value);
                        setPayload({
                          ...p,
                        });
                      }}
                      onBlur={handleBlur}

                    >
                      {emplyoees && emplyoees.map((x) => {
                        return <MenuItem value={x.employeeID} key={x.employeeID}>{x.firstName}</MenuItem>;
                      })}
                    </Select>
                    <FormHelperText>{(errors.employeeID && touched.employeeID) && errors.employeeID}</FormHelperText>
                  </FormControl>

                </Grid>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <div style={{ textAlign: "right" }}>
                    <AddCircleIcon  onClick={addItem} style={{cursor:'pointer' , color:'#394671'}} />



                  </div>
                </Grid>
                <div>
                  <Grid
                    container
                    spacing={3}
                  >
                    <FieldArray name="GIN">
                      {({ push, remove }) => (
                        <>
                          {itemList.map((item, index) => {
                            const materialID = `GIN[${index}].materialID`;
                            const touchedmaterialID = getIn(touched, materialID);
                            const errormaterialID = getIn(errors, materialID);

                            const ginQty = `GIN[${index}].ginQty`;
                            const touchedginQty = getIn(touched, ginQty);
                            const errorginQty = getIn(errors, ginQty);

                            const location = `GIN[${index}].location`;
                            const touchedlocation = getIn(touched, location);
                            const errorlocation = getIn(errors, location);

                            return (

                              <>
                                <Grid
                                  item
                                  lg={2}
                                  sm={2}
                                  xl={2}
                                  xs={2}
                                >


                                  {/* <TextField
                                            fullWidth
                                            label="Material"
                                            name="Name"
                                            className={
                                                item.errors.Material
                                                    ? "form-control  is-invalid"
                                                    : "form-control"
                                            }
                                            name="Material"
                                            placeholder="Material"
                                            value={item.Material}
                                            onChange={(e) => onChange(index, e)}
                                            required
                                            variant="outlined"
                                        /> */}
                                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}
                                    error={Boolean(touchedmaterialID && errormaterialID)}>
                                    <InputLabel id="demo-simple-select-outlined-label">Materials</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      label="materials"
                                      value={item.materialID}
                                      name={materialID}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        handleChange(e)
                                        let item = [...itemList];
                                        item[index].materialID = e.target.value;
                                        let uo = inventoryList.find(x => x.itemID == e.target.value)
                                        item[index].available = uo.itemQty;
                                        setItemList([...item])
                                      }}

                                    >
                                      {inventoryList && inventoryList.map((x) => {
                                        return <MenuItem value={x.itemID} key={x.itemId}>{x.itemName}</MenuItem>;
                                      })}
                                    </Select>
                                    <FormHelperText>{touchedmaterialID && errormaterialID
                                      ? errormaterialID
                                      : ""}</FormHelperText>
                                  </FormControl>
                                  {/* {item.errors.Material && (
                      <div className="invalid-feedback">{item.errors.Material}</div>
                    )} */}
                                </Grid>

                                <Grid
                                  item
                                  lg={2}
                                  sm={2}
                                  xl={2}
                                  xs={2}
                                >


                                  <TextField
                                    fullWidth
                                    label="Number of units"
                                    name={ginQty}
                                    value={item.ginQty}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (e.target.value === '' || regEx.numbersOnly.test(e.target.value) && e.target.value <= item.available) {
                                        handleChange(e)
                                        let item = [...itemList];
                                        item[index].ginQty = e.target.value;
                                        setItemList([...item])
                                      }
                                    }}
                                    helperText={
                                      touchedginQty && errorginQty
                                        ? errorginQty
                                        : ""
                                    }
                                    error={Boolean(touchedginQty && errorginQty)}
                                    variant="outlined"
                                  />

                                </Grid>

                                <Grid
                                  item
                                  lg={2}
                                  sm={3}
                                  xl={3}
                                  xs={3}
                                >


                                  <TextField
                                    fullWidth
                                    helperText=""
                                    label="Description"
                                    name="Description"
                                    placeholder="Description"
                                    value={item.description}
                                    onChange={(e) => {
                                      let item = [...itemList];
                                      item[index].description = e.target.value;
                                      setItemList([...item])

                                    }}

                                    variant="outlined"
                                  />

                                </Grid>

                                <Grid
                                  item
                                  lg={2}
                                  sm={2}
                                  xl={2}
                                  xs={2}
                                >


                                  <TextField
                                    fullWidth
                                    label="Location"
                                    name={location}
                                    placeholder="Location"
                                    value={item.location}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e)
                                      let item = [...itemList];
                                      item[index].location = e.target.value;
                                      setItemList([...item])
                                    }}
                                    helperText={
                                      touchedlocation && errorlocation
                                        ? errorlocation
                                        : ""
                                    }
                                    error={Boolean(touchedlocation && errorlocation)}
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid
                                  item
                                  lg={2}
                                  sm={2}
                                  xl={2}
                                  xs={2}
                                >


                                  <TextField
                                    // disabled={true}
                                    fullWidth
                                    name="Available"
                                    placeholder="Available"
                                    value={item.available - itemList[index].ginQty}
                                    disabled={true}
                                    label="Available"
                                    required
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  xs={12}
                                >
                                  {itemList.length > 1 && <DeleteOutlineIcon  color="error" onClick={(e) => {
                                    confirm({ description: "You want to delete the record ?" })
                                      .then(() => {
                                        // let temp = GINData;
                                        // temp.splice(i, 1)
                                        // setGINdata([...temp])
                                        handleRemoveField(index)
                                      })
                                      .catch(() => console.log("Deletion cancelled."));

                                  }} style={{ marginTop: '15px', textAlign: "center" , cursor:'pointer'}} />}

                                </Grid>

                              </>
                            );
                          })}
                        </>
                      )}
                    </FieldArray>

                  </Grid>

                </div>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <div >
                    <Button

                      style={{ height: "35px", marginLeft: "87%", marginTop: '10px' }}
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained" >
                      Submit
                    </Button>

                  </div>
                </Grid>
              </>
            )}
          </Formik>
        </>
  </Page>


}

export default GINForm;
