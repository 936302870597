import React, { useState, useEffect } from 'react';

import {
    Box, Card, Typography, makeStyles, Container, Button, CardContent, Select, InputLabel, FormControl, MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import APIKit from '../../../utils/APIKit';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import { RGCA_URLS } from '../../../utils/url';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { dateOnlyRev } from '../Common/utilities';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import SalesReport from './SalesReport';
import ProductionReport from './productionReport';
import InventoryReport from './inventoryReport';
import PaymentReport from './paymentReport';
import ProductionSalesReport from './productionSaleReport';
import AllProjectInventoryReport from './AllProjects/allProjectInventoryReport';
import AllProjectSalesReport from './AllProjects/allProjectSalesReport';
import AllProjectProductionReport from './AllProjects/allProjectProductionReort';
import AllProjectPaymentReport from './AllProjects/allProjectPymentReport';
import AllProjectProductionSalesReport from './AllProjects/allProjectProductionSaleReport';
import SummaryReport from './AllProjects/summaryReport';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { checkPermission } from 'src/utils/permission';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        // maxWidth:"200px!important",
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start', // Default width for non-mobile devices
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center' // Custom width for mobile devices (adjust as needed)
        }
    },
    alignForInventory: {
        // Default width for non-mobile devices
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    }
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const AllProjectReports = props => {
    const classes = useStyles();
    const confirm = useConfirm();
    const { project } = useParams();
    const [isTabName, setTabName] = useState("")
    const projectDetails = props.projectID || { ...getProjectDetails(project) };
    const [lookupData, setLookupData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allProjects, setAllProjects] = useState([]);


    let userData = JSON.parse(localStorage.getItem('userData'));
    const allData = [
        {
            value: 'view_allProjectReports_inventory',
            name: 'Inventory Report'
        },
        {
            value: 'view_allProjectReports_sales',
            name: 'Sale Report'
        },
        {
            value: 'view_allProjectReports_production',
            name: 'Production Report'
        },
        {
            value: 'view_allProjectReports_payment',
            name: 'Payment Report'
        },
        {
            value: 'view_allProjectReports_productionandsales',
            name: 'Production & Sales'
        }
    ];

    var initialValue = {};
    initialValue = allData.find(data => {
        if (checkPermission(data.value) || userData?.isAdmin == 1) return data;
    });
    const [value, setValue] = React.useState(initialValue?.value); // first tab

    const tabList = allData.filter(x => checkPermission(x.value) || userData?.isAdmin == 1);

    useEffect(() => {
        getLookup();
        getAllProjects()
    }, [isTabName]);

    const getAllProjects = () => {
        setIsLoading(true)
        APIKit.post(RGCA_URLS.getProjects, {
            searchText: '' // optional
        })
            .then(res => {
                if (res?.data.status === 200) {
                    setIsLoading(false)
                    // console.log('allProjects', res.data);
                    setAllProjects(res?.data?.data);
                } else {
                    setIsLoading(false)
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };


    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };


    const getLookup = () => {
        APIKit.get(`${RGCA_URLS.lookup}/16`)
            .then(res => {
                if (res.data.status === 200) {
                    setLookupData(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(e => { });
    };

    const a11yProps = index => {
        console.log(index);
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };

    return (
        <Page className={classes.root} title={`${projectDetails.projectName}-${isTabName}`}>
            <Container maxWidth={false} style={{ padding: '0px' }}>
                <Loader isLoading={isLoading} />
                <ToastContainer />
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            variant="scrollable"
                        >
                            {tabList &&
                                tabList.length > 0 &&
                                tabList.map((data, index) => {
                                    return (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            key={index}
                                            label={data.name}
                                            value={data.value}
                                            onClick={() => a11yProps(data.value)}
                                        />
                                    );
                                })}
                        </TabList>
                    </Box>

                    <>
                        {value == 'view_allProjectReports_inventory' && (
                            <TabPanel
                                key={0}
                                value="view_allProjectReports_inventory"
                                index="view_allProjectReports_inventory"
                            >
                                <Typography><h2 style={{ color: '#394671' }}>Inventory</h2></Typography>
                                <AllProjectInventoryReport setTabName={setTabName} />
                            </TabPanel>
                        )}
                        {value == 'view_allProjectReports_sales' && (
                            <TabPanel key={1}
                                value="view_allProjectReports_sales"
                                index="view_allProjectReports_sales">
                                <Typography><h2 style={{ color: '#394671' }}>Sales</h2></Typography>
                                <AllProjectSalesReport lookupData={lookupData}
                                    projectDetails={projectDetails} allProjects={allProjects}
                                    setIsLoading={setIsLoading} setTabName={setTabName} isTabName={isTabName} />
                            </TabPanel>
                        )}
                        {value == 'view_allProjectReports_production' && (
                            <TabPanel key={2}
                                value="view_allProjectReports_production"
                                index="view_allProjectReports_production">
                                <Typography><h2 style={{ color: '#394671' }}>Production</h2></Typography>
                                <AllProjectProductionReport lookupData={lookupData}
                                    projectDetails={projectDetails} allProjects={allProjects}
                                    setIsLoading={setIsLoading} setTabName={setTabName} isTabName={isTabName} />
                            </TabPanel>
                        )}

                        {value == 'view_allProjectReports_payment' && (
                            <TabPanel key={3}
                                value="view_allProjectReports_payment"
                                index="view_allProjectReports_payment">
                                <Typography><h2 style={{ color: '#394671' }}>Payment</h2></Typography>
                                <AllProjectPaymentReport lookupData={lookupData}
                                    projectDetails={projectDetails} allProjects={allProjects}
                                    setIsLoading={setIsLoading} setTabName={setTabName} isTabName={isTabName} />
                            </TabPanel>
                        )}
                        {value == 'view_allProjectReports_productionandsales' && (
                            <TabPanel key={4}
                                value="view_allProjectReports_productionandsales"
                                index="view_allProjectReports_productionandsales">
                                <Typography><h2 style={{ color: '#394671' }}>Production & Sale</h2></Typography>
                                <AllProjectProductionSalesReport lookupData={lookupData}
                                    projectDetails={projectDetails} allProjects={allProjects}
                                    setIsLoading={setIsLoading} setTabName={setTabName} isTabName={isTabName} />
                            </TabPanel>
                        )}
                    </>
                </TabContext>
            </Container>
        </Page>
    );
};
export default AllProjectReports;
