import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles, List
} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
// import SubItems from './AppMenuItem';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));

export const AppMenuItemPropTypes = {
  title: PropTypes.string.isRequired,
  hrfe: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array
};

const NavItem = ({
  className,
  href,
  icon: Icon,
  onClick: test,
  title, items = [],
  onChildClick,
  ...rest
}) => {
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(true);
  function handleClick() {
    setOpen(!open);
    onChildClick("event.target.name");
  }

  const MenuItemRoot = (
    <>
      {href ?
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          // to={href}  component={RouterLink}
          {...rest}
          onClick={handleClick}>

          <Button
            activeClassName={classes.active}
            className={classes.button} 
            component={RouterLink}
            to={href}
          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              />
            )}
            <span className={classes.title}>
              {title}
            </span>
          </Button>

          {isExpandable && !open && <IconExpandMore />}
          {isExpandable && open && <IconExpandLess />}
        </ListItem>
        : <ListItem
          className={clsx(classes.item, className)}
          disableGutters

          {...rest}
          onClick={handleClick}>
          <Button
            activeClassName={classes.active}
            className={classes.button}

          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              >

              </Icon>
            )}
            <span className={classes.title}>
              {title}
            </span>
          </Button>
          {isExpandable && !open && <IconExpandMore />}
          {isExpandable && open && <IconExpandLess />}
        </ListItem>}
    </>
  )
  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding >

        {items.map((item, index) => (
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={item.href}
            style={{ marginLeft: "10%" }}
          >
            <span className={classes.title}>
              {item.title}
            </span>
          </Button>
        ))}
      </List>
    </Collapse>
  ) : null;
  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};
NavItem.propTypes = AppMenuItemPropTypes;

export default NavItem;



