import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import { CanvasJSChart } from 'canvasjs-react-charts'
import { Pie } from 'react-chartjs-2';
import { RGCA_URLS } from 'src/utils/url';
import { useNavigate } from 'react-router-dom';
import APIKit from 'src/utils/APIKit';
import { getProjectDetails } from './../Project/Common/utilities';
import { useParams } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const FingerLingsPieChart = ({ className, projectID, testdata, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { project } = useParams();
  const navigate = useNavigate();
  const projectDetails = projectID || { ...getProjectDetails(project) }

  const [production, setProduction] = React.useState([])
  const [productionStock, setProductionStock] = React.useState([])

  var data;
  const getProductionPiechart = () => {
    APIKit.get(RGCA_URLS.getCurrentStockGraphData + '/' + (projectDetails.projectID || projectID))
      .then((res) => {
        if (res.data.status === 200) {
          console.log('sss',res.data.data);
          setProduction(res.data.data);
          setProductionStock(res.data.data);

        } else {
        }
      })
      .catch((e) => {
      });
  };
  useEffect(() => {
    getProductionPiechart()
  }, [projectID, navigate])

  if (production.length) {
    let obj = {
      lable: [],
      targets: [],
      value: []
    };
   


    production.forEach(y => {

      obj.lable.push(y.Productname)
      obj.value.push(y.stock)
    })


    var finaldata = obj
    data = {
      labels: finaldata.lable,
      datasets: [{
        data: finaldata.value,
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#DAF7A6',
          '#40E0D0',
          "#CCCCFF"
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#DAF7A6',
          '#40E0D0',
          "#CCCCFF"
        ]
      }]
    };

  }
  const options = {
    title: {
      display: true,
      text: '',
      fontSize: 20
    },
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    datalabels: {
      display: true,
      color: "red",
    },
    type: "Pie"

  };

  const options2 = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: "<b>{label}</b>: {y}",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}",
      dataPoints: productionStock.map(item => ({
        y: item.stock,
        label: item.Productname
    }))
    }]
  }



  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader

        title="Available Stock"
      />
      <CardContent>
        {/* <Box
          height={400}
          position="relative"
        >
          {data && (<Pie data={data}
            options={options}
          />)}
        </Box> */}

        <CanvasJSChart options = {options2} />
      </CardContent>

    </Card>
  );
};

export default FingerLingsPieChart;
