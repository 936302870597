import React, { useState, useEffect } from 'react';
import {
    Box, Card, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, TextField, Divider, Select, InputLabel, FormControl, MenuItem, FormHelperText
} from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'react-toast-notifications';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from "react-router-dom";
import { MESSAGE } from 'src/utils/message';
import DateFnsUtils from '@date-io/date-fns';
import HistoryIcon from '@material-ui/icons/History';
import moment from 'moment';
import UpdateIcon from '@material-ui/icons/Update';
import AppBar from '@material-ui/core/AppBar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Loader from 'src/views/loader';
import Programs from './programs';
import Registration from './registration';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { getProjectDetails } from '../Common/utilities';
import CompletedPrograms from './completedPrograms';
import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Training = () => {
    const classes = useStyles();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [programData, setProgramData] = useState([]);
    const [registrationData, setRegistrationData] = useState([]);
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) }
    const [completedPrograms, setCompletedPrograms] = useState([])


    const handleChangeTab = (_event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = index => {
        if (index == 0) {
            getProgramsData();
        }
        if (index == 1) {
            getregistrationData();
        }
        if (index == 2) {
            getArchivedHandsOnProgrammeDetailsData();
        }

        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };



    useEffect(() => {
        getProgramsData();
    }, [])

    const getProgramsData = () => {
        setIsLoading(true)
        APIKit.get(RGCA_URLS.getHandsOnProgrammeDetails)
            .then((res) => {
                if (res.data.status === 200) {
                    setProgramData(res?.data?.data);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                setIsLoading(false)
            });
    };
    const getregistrationData = () => {
        setIsLoading(true)
        APIKit.get(RGCA_URLS.getTrainerDetails)
            .then((res) => {
                if (res.data.status === 200) {
                    setRegistrationData(res?.data?.data);
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                setIsLoading(false)
            });
    };

    const getArchivedHandsOnProgrammeDetailsData = () => {
        setIsLoading(true)
        APIKit.get(RGCA_URLS.getArchivedHandsOnProgrammeDetails)
            .then((res) => {
                if (res.data.status === 200) {
                    setCompletedPrograms(res?.data?.data);
                    setIsLoading(false)
                } else {
                    setCompletedPrograms([])
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                setIsLoading(false)
            });
    };





    return (
        <Page className={classes.root} title="Training">
            <Loader isLoading={isLoading} />
            <ToastContainer />
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}
                        style={{
                            marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                        }}
                    >
                        <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
                            <Tabs
                                value={value}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Programs" onClick={() => a11yProps(0)} />
                                <Tab label="Registration" onClick={() => a11yProps(1)} />
                                <Tab label="Completed Programs" onClick={() => a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>

                <TabPanel value={value} index={0}>
                    <Programs isLoading={isLoading} programData={programData} setIsLoading={setIsLoading}
                        getProgramsData={getProgramsData} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Registration isLoading={isLoading} registrationData={registrationData} programData={programData}
                        setIsLoading={setIsLoading} getregistrationData={getregistrationData} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CompletedPrograms completedPrograms={completedPrograms} />
                </TabPanel>
            </Container>
        </Page>
    )
}

export default Training