import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Loader from '../../loader';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'react-toast-notifications';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from "react-router-dom";
import { MESSAGE } from 'src/utils/message';
import { RGCA_URLS } from '../../../utils/url';
import { dateOnlyRev, getProjectDetails } from '../Common/utilities';
import DateFnsUtils from '@date-io/date-fns';
import HistoryIcon from '@material-ui/icons/History';
import { dateForm } from './../Common/utilities';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '0px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '0px'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ExpensesManagement = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const confirm = useConfirm();
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  const [productionHistory, setProductionHistory] = React.useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [getItemsData, setGetItemsData] = useState([]);
  const [prodHis, setProdHis] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [categoryList, setCategoryList] = useState([]);
  const [errorMeg, setErrorMeg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const navigate = useNavigate();
  const [openTest, setOpenTest] = React.useState(false);
  const [itemName, setItemName] = React.useState('');
  const [btn, setBtn] = useState(false);
  const openSales = () => {
    setBtn(false);
    // setOpenTest(false);
    setOpenTest(true);
  };
  const [salesItem, setSalesItem] = useState(
    {
      typeID: '',
      name: '',
      gstTaxability: '',
      gstRate: '',
      rpu: '',
      uom: '',
      hssn: '',
      stock: '',
      description: '',
      rca: '',
      categoryID: '',
      productionDate: null
    }

  );
  const [addCategory, setAddCategory] = useState({
    typeID: null,
    name: '',
    projectID: projectDetails.projectID,
    description: 'hi'
  });
  const [allVendors, setAllVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = (resetForm = null) => {
    setAddCategory({
      typeID: '',
      name: ''
    })
    setOpenSubCategory(false)
    if (resetForm) {
      resetForm();
    }
  };
  const closeSales = (resetForm = null) => {
    setOpenTest(false)
    if (resetForm) {
      resetForm();
    }
  }
  useEffect(() => {
    getItemsList();
    getLookup();
  }, [navigate]);
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/67`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };
  const getCategories = (type) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getSalesCategory, {

      typeID: type,
      projectID: projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryList(res.data.data);
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'success' });
        } else {
          // toast.error(res.data.message);
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch((e) => {
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const saveVendor = () => {
    APIKit.post(`/${APP_URLS.addVendor}`, salesItem,
      { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${getloggedInData()?.accessToken}` } })
      .then((res) => {
        if (res.data.status === 200) {
          getItemsList();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setSalesItem({
            typeID: '',
            name: '',
            gstTaxability: '',
            gstRate: '',
            rpu: '',
            uom: '',
            hssn: '',
            stock: '',
            description: '',
            rca: '',
            categoryID: ''
          });
          handleCloseModal();
        } else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch((e) => {
      });
  };
  const cancelSalesItem = (resetForm = null) => {
    setBtn(false);
    setOpenTest(false)
    setSalesItem({
      typeID: '',
      name: '',
      gstTaxability: '',
      gstRate: '',
      rpu: '',
      uom: '',
      hssn: '',
      stock: '',
      description: '',
      rca: '',
      categoryID: ''
    });
    if (resetForm) {
      resetForm();
    }
  }
  const updateSalesItem = () => {
    const url = `${RGCA_URLS.updateSalesItem}`;
    APIKit.put(url, salesItem)
      .then((res) => {
        if (res.data.status === 200) {
          getItemsList();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setBtn(false);
          setSalesItem({
            typeID: '',
            name: '',
            gstTaxability: '',
            gstRate: '',
            rpu: '',
            uom: '',
            hssn: '',
            stock: '',
            description: '',
            rca: '',
            categoryID: ''
          });
          handleCloseModal();
        } else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch((e) => {
      });
  };

  const getItemsList = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          setGetItemsData(res.data.data);
          setIsLoading(false)

        } else {
          // toast.error(res.data.message);
          setIsLoading(false)

        }
      })
      .catch((e) => {
      });
  };
  const removeVendor = (data) => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        remove(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const editItem = (data) => {
    getCategories(data.typeID);
    setBtn(true);
    setOpenTest(true)
    setSalesItem({
      ...salesItem,
      itemID: data.itemID,
      typeID: data.typeID,
      name: data.name,
      gstTaxability: data.gstTaxability,
      gstRate: data.gstRate.toString(),
      rpu: data.rpu,
      uom: data.uom,
      hssn: data.hssn,
      stock: data.stock,
      description: data.description,
      rca: data.rca,
      categoryID: data.categoryID

    });
  };
  const searchVendor = (e) => {
    if (e) {
      APIKit.post(`/${APP_URLS.searchVendor}`, { Name: e },
        { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${getloggedInData()?.accessToken}` } })
        .then((res) => {
          if (res.data.status === 200) {
            // setAlertMeg('success');
            // setErrorMeg(res.data.message);
            setAlertOpen(true);
            setAllVendors(res.data.data);
          } else {
            // setAlertMeg('error');
            // setErrorMeg(res.data.message);
            // setAlertOpen(true);
          }
        })
        .catch((e) => {
        });
    } else {
      getItemsList();
    }
  };

  // const editVendor = (data) => {
  //   setSalesItem(data);
  //   setBtn(true);
  //   setOpenTest(true);
  // };

  const remove = (data) => {
    APIKit.delete(`${RGCA_URLS.deleteSalesItem}/${data.itemID}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.salesItemDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getItemsList();
        } else {
          toast.error(MESSAGE.salesItemNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
      });
  };
  const openSubModal = () => {
    setOpenSubCategory(true);
  };
  const closeModal = () => {
    setOpenSubCategory(false);
  };
  const history = (data) => {
    const payload = {
      itemID: data.itemID,
      projectID: projectDetails.projectID
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.listProduction, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setItemName(data.name)
          setProductionHistory(true)
          //   setLookupData(res.data.data)
          setProdHis(res.data.data);
          // closeModal();
          setIsLoading(false);
          //addToast(MESSAGE.salesItemAdded, { appearance: 'success' });

        } else {
          setIsLoading(false);

        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })
  return (
    <Page
      className={classes.root}
      title="Expenses Management"
    >
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Container maxWidth={false}>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity={alertMeg}>
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                }}
              >
                Expenses Management
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openSales}
                >
                  Add Expenses
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openAddVendor}
                >
                  Create Sales Item
                </Button>
              </Grid> */}
            </Grid>

          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...salesItem
            }}
            validationSchema={
              Yup.object().shape({
                typeID: Yup.string().required(MESSAGE.type),
                categoryID: Yup.string().required(MESSAGE.category),
                name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                hssn: Yup.string().required(MESSAGE.hssn),
                uom: Yup.string().required(MESSAGE.uom).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                rpu: Yup.number().required(MESSAGE.rpu),
                gstTaxability: Yup.string().required(MESSAGE.gst),
                stock: Yup.number().required(MESSAGE.stocks),
                description: Yup.string().required(MESSAGE.itemDescription).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                rca: Yup.string().required(MESSAGE.rca),
              })

            }
            onSubmit={(values, { resetForm }) => {

              if (btn) {
                const url = `${RGCA_URLS.updateSalesItem}`;
                const payload = {
                  ...salesItem,
                  rpu: Number(salesItem.rpu),
                  gstRate: String(salesItem.gstRate),
                  projectID: projectDetails.projectID
                }
                APIKit.put(url, payload)
                  .then((res) => {
                    if (res.data.status === 200) {
                      getItemsList();
                      // setAlertMeg('success');
                      // setErrorMeg(res.data.message);
                      // setAlertOpen(true);
                      toast.success(MESSAGE.salesItemUpdate, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      setBtn(false);
                      setOpenTest(false)
                      setSalesItem({
                        typeID: '',
                        name: '',
                        gstTaxability: '',
                        gstRate: '',
                        rpu: '',
                        uom: '',
                        hssn: '',
                        stock: '',
                        description: '',
                        rca: '',
                        categoryID: ''
                      });
                      handleCloseModal();
                    } else {
                      // setAlertMeg('error');
                      // setErrorMeg(res.data.message);
                      // setAlertOpen(true);
                      toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    }
                  })
                  .catch((e) => {
                  });
              } else {
                const payload = {
                  ...salesItem,
                  projectID: projectDetails.projectID
                }
                setIsLoading(true);
                APIKit.post(RGCA_URLS.createSalesItem, payload)
                  .then((res) => {
                    if (res.data.status === 200) {
                      //   setLookupData(res.data.data)
                      // closeModal();
                      setTimeout(() => {
                        setIsLoading(false);
                        //addToast(MESSAGE.salesItemAdded, { appearance: 'success' });
                        toast.success(MESSAGE.salesItemAdded, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        })
                        setOpenTest(false)
                        getItemsList();
                        resetForm(values);
                        setSalesItem({
                          typeID: '',
                          name: '',
                          gstTaxability: '',
                          gstRate: '',
                          rpu: '',
                          uom: '',
                          hssn: '',
                          stock: '',
                          description: '',
                          rca: '',
                          categoryID: ''
                        });
                      }, 3000);
                    } else {
                      setIsLoading(false);
                      //addToast(res.data.message, { appearance: 'error' });
                      // toast.error(res.data.message);
                      toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                  });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              handleReset,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <MuiThemeProvider theme={formLabelsTheme}>
                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={openTest}
                  id="income"
                  contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                  }}
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogTitle id="scroll-dialog-title" onClose={(e => {
                    cancelSalesItem(resetForm)
                  })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                    Add
                  </DialogTitle>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogContent >
                      <Box mt={3}>
                        <Card>
                          <CardContent>
                            <Grid
                              container
                              spacing={3}
                            >

                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  className={classes.formControl}
                                  error={Boolean(touched.typeID && errors.typeID)}
                                >
                                  <InputLabel required id="demo-simple-select-outlined-label">Product Item</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={salesItem.typeID}
                                    name="typeID"
                                    label="Product Item"
                                    required
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      const p = salesItem;
                                      p.typeID = e.target.value;
                                      setSalesItem({
                                        ...p,
                                        typeID: e.target.value
                                      });
                                    }}
                                  >
                                    {lookupData && lookupData.map((x) => {
                                      return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                                    })}
                                  </Select>
                                  <FormHelperText>{(errors.typeID && touched.typeID) && errors.typeID}</FormHelperText>
                                </FormControl>

                              </Grid>


                            </Grid>

                          </CardContent>
                        </Card>
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                      <Button onClick={(e => {
                        cancelSalesItem(resetForm)
                      })} className={classes.button2}>
                        Cancel
                      </Button>
                      {btn
                        ? (
                          <div>
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              className={classes.button2}
                            >
                              Update
                            </Button>
                          </div>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.button2}
                          >
                            Save
                          </Button>
                        )}
                    </DialogActions>
                  </Form>

                </Dialog>
              </MuiThemeProvider>
            )}
          </Formik>
          {/* <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      searchVendor(e.target.value);
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box> */}
        </div>
        <Box mt={3}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>

                  <TableCell>
                    S.No
                  </TableCell>
                  <TableCell>
                    Type
                  </TableCell>
                  <TableCell>
                    Name of the item
                  </TableCell>
                  <TableCell>
                    HSSN / SAC Code
                  </TableCell>
                  <TableCell style={{ width: '40px' }}>
                    UOM
                  </TableCell>
                  <TableCell>
                    Rate per Unit
                  </TableCell>
                  <TableCell>
                    GST Taxability
                  </TableCell>
                  <TableCell>
                    Reverse Charge Applicability
                  </TableCell>
                  <TableCell>
                    GST Rate
                  </TableCell>
                  <TableCell>
                    Stocks
                  </TableCell>
                  <TableCell>
                    Description
                  </TableCell>
                  <TableCell>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {getItemsData && getItemsData.length != 0 ? getItemsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.itemID}>
                        <TableCell align="left">{((page) * 10) + index + 1}</TableCell>
                        <TableCell align="left">{row.typeID === 17 ? 'Product' : 'Seed'}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.hssn}</TableCell>
                        <TableCell align="left">{row.uom}</TableCell>
                        <TableCell align="left">{row.rpu}</TableCell>
                        <TableCell align="left">{row.gstTaxability ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="left">{row.rca ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="left">{row.gstRate}</TableCell>
                        <TableCell align="left">{row.stock}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>

                        <TableCell align="left">
                          <HistoryIcon style={{ cursor: 'pointer' }} onClick={() => history(row)} />
                          <EditIcon style={{ cursor: 'pointer' }} onClick={() => editItem(row)} />
                          <DeleteForeverIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              removeVendor(row);
                            }}
                          />
                        </TableCell>

                      </TableRow>
                    );
                  }) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

              </TableBody>

            </Table>
          </TableContainer>
          {getItemsData && getItemsData.length != 0 &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={getItemsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </Box>

      </Container>

      {/* categoryID */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...addCategory
        }}
        validationSchema={
          Yup.object().shape({
            typeID: Yup.string().nullable().required('Type is required'),
            name: Yup.string().required('Category Name is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
          })
        }
        onSubmit={(values) => {
          setIsLoading(true);
          addCategory.projectID = projectDetails.projectID
          APIKit.post(RGCA_URLS.addSalesCategory, addCategory)
            .then((res) => {
              if (res.data.status === 200) {
                //   setLookupData(res.data.data)
                setIsLoading(false);
                // addToast(res.data.message, { appearance: 'success' });
                toast.success(MESSAGE.salesCategory, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
                closeModal();
                setAddCategory({
                  typeID: '',
                  name: ''
                })
                getCategories(salesItem.typeID);
              } else {
                setIsLoading(false);
                //addToast(res.data.message, { appearance: 'error' });
                // toast.error(res.data.message);
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            })
            .catch((e) => {
              setIsLoading(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openSubCategory}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none',
            }}
            maxWidth="lg"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogTitle id="customized-dialog-title"
                onClose={(e => {
                  handleCloseModal(resetForm)
                })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                Create Category
              </DialogTitle>
              <DialogContent dividers>
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >

                  <Grid
                    container
                    spacing={3}
                  >

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >

                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        error={Boolean(touched.typeID && errors.typeID)}
                        className={classes.formControl}
                      >
                        <InputLabel id="typeID">Type</InputLabel>
                        <Select
                          labelId="typeID"
                          id="typeID"
                          name="typeID"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const p = addCategory;
                            p.typeID = e.target.value;
                            setAddCategory({
                              ...p,
                              typeID: e.target.value
                            });
                          }}
                          required
                          value={addCategory.typeID}
                          label="Location"
                        >
                          {lookupData && lookupData.map((x) => {
                            return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                          })}
                          {/* <MenuItem value="1">Recuring</MenuItem> */}
                        </Select>
                        <FormHelperText>{(errors.typeID && touched.typeID) && errors.typeID}</FormHelperText>
                      </FormControl>

                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Category"
                        name="name"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const p = addCategory;
                          p.name = e.target.value;
                          setAddCategory({
                            ...p,
                            name: e.target.value
                          });
                        }}
                        required
                        value={addCategory.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>

                <Button type="submit" className={classes.button2}>
                  Save
                </Button>

                <Button onClick={(e) => {
                  handleCloseModal(resetForm)
                }} className={classes.button2}>
                  Cancel
                </Button>
                {/* {btn == false ?
                    <Button onClick={saveVendor} className={classes.button2}>
                        Save
                    </Button>
                    : <Button onClick={updateVendor} className={classes.button2}>
                        Update
                    </Button>} */}

              </DialogActions>
            </Form>

          </Dialog>
        )}
      </Formik>
      <Dialog aria-labelledby="customized-dialog-title"
        contentStyle={{
          width: '100%',
          maxWidth: 'none',
        }}
        maxWidth="md" fullWidth
        open={productionHistory} id="income">
        <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
          Production History
        </DialogTitle>
        <DialogContent dividers style={{ overflowX: "hidden" }}>
          {itemName.toLocaleUpperCase()}
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>

                    <TableCell>
                      S.No
                    </TableCell>
                    <TableCell>
                      Production Count
                    </TableCell>
                    <TableCell>
                      Production Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {prodHis && prodHis.length != 0 ? prodHis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.itemID}>
                          <TableCell align="left">{((page) * 10) + index + 1}</TableCell>
                          <TableCell align="left">{row.productionCount}</TableCell>
                          <TableCell align="left">{dateForm(row.productionDate)}</TableCell>


                        </TableRow>
                      );
                    }) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

                </TableBody>

              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
            onClick={() => {
              setProductionHistory(false)
            }}

          >Close</Button>
        </DialogActions>

      </Dialog>
    </Page>
  );
};
export default ExpensesManagement;
