import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down('xl')]: {
    width: '10%', // Default width for non-mobile devices
    borderRadius:'50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40%', // Custom width for mobile devices (adjust as needed)
      borderRadius:'50%'
    },
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <img
      alt="Logo"
      // style={{width:60,borderRadius:30}}
      src="/static/logo-rgca.jpg"
      {...props}
      className={classes.image}
    />
  );
};

export default Logo;
