import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Paper
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import { MESSAGE } from 'src/utils/message';
import APIKit from '../../utils/APIKit';
import { getloggedInData } from '../../utils/helpers';
import { RGCA_URLS } from '../../utils/url';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Loader from '../loader';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import { capitalLatter, capitalize } from '../Project/Common/utilities';
import CommonSelect from '../Project/Common/CommonSelect';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  role: {
    [theme.breakpoints.up('md')]: {
      width: '500px'
    },
    '@media (max-width: 800px)': {
      width: '550px'
    },
    '@media (max-width: 500px)': {
      width: '300px'
    },
    '@media (max-width: 350px)': {
      width: '200px'
    }
  },
  container: {
    maxHeight: '100%',
    '@media (max-width: 500px)': {
      maxHeight: '300',
      overflowY: 'auto'
    }
  }
}));

const NewRole = () => {
  const classes = useStyles();
  const [allRole, setAllRole] = useState([]);
  const [emplyoees, setEmplyoees] = useState([]);
  const [payload, setPayload] = useState({
    projectID: '',
    employeeID: '',
    accessRoleID: '',
    accessRoleName: '',
    val: {}
  });
  const [allProjects, setAllProjects] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [accessRoles, setAccessRoles] = useState([]);
  const [projectEmployee, setProjectEmployee] = useState([]);
  const [permissionData, setPermissionData] = useState([]);

  const defaultLoad = () => {
    getAllRoles();
    getAccessRoles();
    getAllProjects();
    getAllEmplyoees();
  };

  useEffect(() => {
    defaultLoad();
  }, []);

  const getAllEmplyoees = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getEmployee)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          let data = res.data.data.map(e => {
            return {
              itemID: e.employeeID,
              name: e.firstName + ' ' + e.lastName
            };
          });
          setEmplyoees(data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects)
      .then(res => {
        if (res.data.status === 200) {
          setAllProjects(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {});
  };

  const getAccessRoles = () => {
    APIKit.get(RGCA_URLS.getAccessRoles)
      .then(res => {
        if (res.data.status === 200) {
          console.log('setAccessRoles', res.data.data);
          setAccessRoles(res.data.data);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const getAllRoles = () => {
    APIKit.get(RGCA_URLS.getRoles)
      .then(res => {
        if (res.data.status === 200) {
          console.log('get roles', res.data.data);
          setAllRole(res.data.data);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const getProjectEmployee = data => {
    APIKit.post(RGCA_URLS.getProjectEmployee, {
      employeeID: data
    })
      .then(res => {
        if (res.data.status === 200) {
          setProjectEmployee(res.data.data);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const Permissions = async accessRoleID => {
    
    await APIKit.post(RGCA_URLS.getRolePermissions, {
      accessRoleID: accessRoleID
    })
      .then(res => {
        setPermissionData(res.data.data);
        console.log('dit',res.data.data);
      })
      .catch(function(e) {});
  };

  const onChangeInput = value => {
    payload.val = value;
    payload.projectID = '';
    payload.employeeID = value.value;
    setPayload({ ...payload });
    getProjectEmployee(payload.employeeID);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const permissionCheck = action => {
    
    return permissionData.find(x => x.actionID === action.actionID)
      ? true
      : false;
  };

  const checkPermission = (e, y) => {
    console.log('00000',y);
    
    // alert(y.actionID)
    if (permissionData.findIndex(e => e.actionID == y.actionID) === -1) {
      const newArr = [];
      newArr.push(y);
      if (permissionData.length == 0) {
        setPermissionData(newArr);
      } else {
        setPermissionData(permissionData.concat(newArr));
      }
    } else {
      setPermissionData(permissionData.filter(e => e.actionID != y.actionID));
    }
  };

  const savePermision = () => {
    
    console.log('permission payload', permissionData);

    const isCreateIndent = permissionData.some(
      object => object['actionID'] === 1
    );
    // if (isCreateIndent) {
    //   const isViewIndent = permissionData.some(
    //     object => object['actionID'] === 44
    //   );
    //   if (!isViewIndent) {
    //     toast.warn('Please Select View Option for Indent', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }
    
    const isCreatePurchase = permissionData.some(
      object => object['actionID'] === 4
    );
    // if (isCreatePurchase) {
    //   const isViewPurchase = permissionData.some(
    //     object => object['actionID'] === 22
    //   );
    //   if (!isViewPurchase) {
    //     toast.warn('Please Select View Option for Purchase Order', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateWorkOrder = permissionData.some(
      object => object['actionID'] === 5
    );
    // if (isCreateWorkOrder) {
    //   const isViewWorkOrder = permissionData.some(
    //     object => object['actionID'] === 45
    //   );
    //   if (!isViewWorkOrder) {
    //     toast.warn('Please Select View Option for Work Order', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateGrn = permissionData.some(
      object => object['actionID'] === 8
    );
    // if (isCreateGrn) {
    //   const isViewGrn = permissionData.some(
    //     object => object['actionID'] === 9
    //   );
    //   if (!isViewGrn) {
    //     toast.warn('Please Select View Option for GRN', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateGin = permissionData.some(
      object => object['actionID'] === 10
    );
    // if (isCreateGin) {
    //   const isViewGin = permissionData.some(
    //     object => object['actionID'] === 46
    //   );
    //   // if (!isViewGin) {
    //   //   toast.warn('Please Select View Option for GIN', {
    //   //     position: 'top-right',
    //   //     autoClose: 5000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined
    //   //   });
    //   //   return;
    //   // }
    // }

    const isCreateFar = permissionData.some(
      object => object['actionID'] === 15
    );
    // if (isCreateFar) {
    //   const isViewFar = permissionData.some(
    //     object => object['actionID'] === 16
    //   );
    //   if (!isViewFar) {
    //     toast.warn('Please Select View Option for FAR', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateIr = permissionData.some(
      object => object['actionID'] === 17
    );
    // if (isCreateIr) {
    //   const isViewIr = permissionData.some(
    //     object => object['actionID'] === 18
    //   );
    //   if (!isViewIr) {
    //     toast.warn('Please Select View Option for Inventroy Register', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateSI = permissionData.some(
      object => object['actionID'] === 23
    );
    // if (isCreateSI) {
    //   const isViewSI = permissionData.some(
    //     object => object['actionID'] === 47
    //   );
    //   if (!isViewSI) {
    //     toast.warn('Please Select View Option for Store Indent', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //     return;
    //   }
    // }

    const isCreateEmployee = permissionData.some(
      object => object['actionID'] === 26
    );
    // if (isCreateEmployee) {
    //   const isViewEmployee = permissionData.some(
    //     object => object['actionID'] === 50
    //   );
    //   // if (!isViewEmployee) {
    //   //   toast.warn('Please Select View Option for Employee', {
    //   //     position: 'top-right',
    //   //     autoClose: 5000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined
    //   //   });
    //   //   return;
    //   // }
    // }

    const isCreateVendor = permissionData.some(
      object => object['actionID'] === 36
    );
    // if (isCreateVendor) {
    //   const isViewVendor = permissionData.some(
    //     object => object['actionID'] === 51
    //   );
    //   // if (!isViewVendor) {
    //   //   toast.warn('Please Select View Option for Vendor', {
    //   //     position: 'top-right',
    //   //     autoClose: 5000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined
    //   //   });
    //   //   return;
    //   // }
    // }

    const isCreateNotice = permissionData.some(
      object => object['actionID'] === 40
    );
    // if (isCreateNotice) {
    //   const isViewNotice = permissionData.some(object => object['actionID'] === 52);
    //   // if (!isViewNotice) {
    //   //   toast.warn('Please Select View Option for Notice', {
    //   //     position: 'top-right',
    //   //     autoClose: 5000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined
    //   //   });
    //   //   return;
    //   // }
    // }


    const pay = {
      //   projectID: payload.projectID,
      //   employeeID: payload.employeeID,
      //   accessRoleID: projectEmployee?.find(e => e.projectID == payload.projectID)
      //     .accessRoleID,
      accessRoleID: payload.accessRoleID,
      actionIDS: permissionData.map(e => {
        return { actionID: e.actionID };
      })
    };

    setIsLoading(true);
    APIKit.post(RGCA_URLS.rolePermissionMange, pay)
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          Permissions(payload.accessRoleID);
          //addToast(`${x.name} permission allocated`, { appearance: 'success' });
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          defaultLoad();
        } else {
          //addToast(res.data.message, { appearance: 'error' });
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function(e) {});
  };

  return (
    <Page className={classes.root} title="Role">
      <ToastContainer />
      <Container maxWidth={false}>
        <Loader isLoading={isLoading} />
        <div>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginTop: '10px',
                  color: '#394671',
                  fontSize: '17px',
                  fontWeight: 'bold'
                }}
              >
                Permission Management
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box>
                  <Grid container spacing={3} className={classes.field}>
                    <Grid item md={4} xs={12}>
                      <FormControl variant="outlined" className={classes.role}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          required
                        >
                          Roles
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="projectID"
                          disabled={!allRole.length}
                          onChange={e => {
                            const p = payload;
                            p.accessRoleID = e.target.value;
                            setPayload({
                              ...p
                            });
                            Permissions(e.target.value);
                          }}
                          required
                          value={payload.accessRoleID}
                          label="Projects"
                        >
                          {allRole &&
                            allRole.map(x => {
                              return (
                                <MenuItem
                                  value={x.accessRoleID}
                                  key={x.accessRoleID}
                                >
                                  {x.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                      <CommonSelect
                        val={payload.val}
                        onChange={e => onChangeInput(e)}
                        options={emplyoees}
                        labelName={'Search Employee'}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          required
                        >
                          Projects
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="projectID"
                          disabled={!projectEmployee.length}
                          onChange={e => {
                            const p = payload;
                            p.projectID = e.target.value;
                            setPayload({
                              ...p
                            });
                            Permissions(payload.employeeID, payload.projectID);
                          }}
                          required
                          value={payload.projectID}
                          label="Projects"
                        >
                          {projectEmployee &&
                            projectEmployee.map(x => {
                              return (
                                <MenuItem value={x.projectID} key={x.projectID}>
                                  {x.projectName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      {payload.projectID != '' && (
                        <Typography>
                          Acccess Role Name:{' '}
                          {
                            projectEmployee?.find(
                              e => e.projectID == payload.projectID
                            ).accessRoleName
                          }
                        </Typography>
                      )}
                    </Grid> */}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>

        {payload.accessRoleID != '' && (
          <Box mt={3}>
            <Card>
              <CardContent>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer className={classes.container}>
                    {accessRoles &&
                      accessRoles.length != 0 &&
                      accessRoles.find(z => z.isMaster == 0) && (
                        <Table fixedHeader aria-label="sticky table">
                          <TableHead
                            style={{
                              position: 'sticky',
                              top: 0,
                              width: 'auto',
                              background: 'white',
                              zIndex: 999
                            }}
                          >
                            <TableRow>
                              <TableCell>Project Permissions</TableCell>
                              <TableCell align="center">Create</TableCell>
                              <TableCell align="center">View</TableCell>
                              <TableCell align="center"> Edit</TableCell>
                              <TableCell align="center">Approval</TableCell>
                              <TableCell align="center">Delete</TableCell>
                              <TableCell align="center">
                                View Approved List
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accessRoles &&
                              accessRoles.length != 0 &&
                              accessRoles.map(x => {
                                if (x.isMaster == 0) {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        <b>
                                        {capitalize(capitalLatter(x.group=="Sales"?'Production':x.group=='Selling'?'Sales':x.group))}{' '}
                                        </b>{' '}
                                      </TableCell>
                                      <>
                                        {x.list.map(y => (
                                          <TableCell
                                            key={y.actionID}
                                            align="center"
                                          >
                                            <Checkbox
                                              {...label}
                                              onChange={e => {
                                                checkPermission(e, y);
                                              }}
                                              checked={permissionCheck(y)}
                                            />
                                          </TableCell>
                                        ))}
                                      </>
                                    </TableRow>
                                  );
                                }
                              })}
                          </TableBody>
                        </Table>
                      )}
                    {accessRoles &&
                      accessRoles.length != 0 &&
                      accessRoles.find(z => z.isMaster == 1) && (
                        <Table fixedHeader aria-label="sticky table">
                          <TableHead
                            style={{
                              position: 'sticky',
                              top: 0,
                              width: 'auto',
                              background: 'white',
                              zIndex: 999
                            }}
                          >
                            <TableRow>
                              <TableCell>Master Permissions</TableCell>
                              <TableCell align="center">Create</TableCell>
                              <TableCell align="center">View</TableCell>
                              <TableCell align="center">Edit</TableCell>
                              <TableCell align="center">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accessRoles &&
                              accessRoles.length != 0 &&
                              accessRoles.map(x => {
                                if (x.isMaster == 1) {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        <b>
                                          {capitalize(capitalLatter(x.group))}{' '}
                                        </b>{' '}
                                      </TableCell>
                                      <>
                                        {x.list.map(y => (
                                          <TableCell
                                            key={y.actionID}
                                            align="center"
                                          >
                                            <Checkbox
                                              {...label}
                                              onChange={e => {
                                                checkPermission(e, y);
                                              }}
                                              checked={permissionCheck(y)}
                                            />
                                          </TableCell>
                                        ))}
                                      </>
                                    </TableRow>
                                  );
                                }
                              })}
                          </TableBody>
                        </Table>
                      )}
                      {/* Dashboar Permissions */}
                      {accessRoles &&
                      accessRoles.length != 0 &&
                      accessRoles.find(z => z.isMaster == 2) && (
                        <Table fixedHeader aria-label="sticky table">
                          <TableHead
                            style={{
                              position: 'sticky',
                              top: 0,
                              width: 'auto',
                              background: 'white',
                              zIndex: 999
                            }}
                          >
                            <TableRow>
                              <TableCell>DashBoard Permissions</TableCell>
                              {/* <TableCell align="center">Create</TableCell> */}
                              <TableCell align="center">View</TableCell>
                              {/* <TableCell align="center">Edit</TableCell> */}
                              {/* <TableCell align="center">Delete</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accessRoles &&
                              accessRoles.length != 0 &&
                              accessRoles.map(x => {
                                if (x.isMaster == 2) {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        <b>
                                          {capitalize(capitalLatter(x.group))}{' '}
                                        </b>{' '}
                                      </TableCell>
                                      <>
                                        {x.list.map(y => (
                                          <TableCell
                                            key={y.actionID}
                                            align="center"
                                          >
                                            <Checkbox
                                              {...label}
                                              onChange={e => {
                                                
                                                checkPermission(e, y);
                                              }}
                                              checked={permissionCheck(y)}
                                            />
                                          </TableCell>
                                        ))}
                                      </>
                                    </TableRow>
                                  );
                                }
                              })}
                          </TableBody>
                        </Table>
                      )}
                      {accessRoles &&
                      accessRoles.length != 0 &&
                      accessRoles.find(z => z.isMaster == 3) && (
                        <Table fixedHeader aria-label="sticky table">
                          <TableHead
                            style={{
                              position: 'sticky',
                              top: 0,
                              width: 'auto',
                              background: 'white',
                              zIndex: 999
                            }}
                          >
                            <TableRow>
                              <TableCell>Reports Permissions</TableCell>
                              <TableCell align="center">Inventory</TableCell>
                              <TableCell align="center">Sales</TableCell>
                              <TableCell align="center">Production</TableCell>
                              <TableCell align="center">Payment</TableCell>
                              <TableCell align="center">Production & Sales</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accessRoles &&
                              accessRoles.length != 0 &&
                              accessRoles.map(x => {
                                if (x.isMaster == 3) {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        <b>
                                          {capitalize(capitalLatter(x.group))}{' '}
                                        </b>{' '}
                                      </TableCell>
                                      <>
                                        {x.list.map(y => (
                                          <TableCell
                                            key={y.actionID}
                                            align="center"
                                          >
                                            <Checkbox
                                              {...label}
                                              onChange={e => {
                                                
                                                checkPermission(e, y);
                                              }}
                                              checked={permissionCheck(y)}
                                            />
                                          </TableCell>
                                        ))}
                                      </>
                                    </TableRow>
                                  );
                                }
                              })}
                          </TableBody>
                        </Table>
                      )}
                  </TableContainer>
                </Paper>
              </CardContent>
            </Card>
            <Button
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid',
                float: 'right',
                marginTop: '1em'
              }}
              onClick={savePermision}
              disabled={permissionData.length == 0}
            >
              Save
            </Button>
          </Box>
        )}
      </Container>
    </Page>
  );
};
export default NewRole;
