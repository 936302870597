import React, { useState } from 'react';
import {
  Grid, Box, Dialog,
  DialogTitle, Button,
  DialogContent, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import PaymentIcon from '@material-ui/icons/Payment';
import { currencyFormat1, dateFormate, parseData } from '../Common/utilities';
import './style.css';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflow: 'auto'
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  }
}));
const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function GrnTable({ allGRN, permission, setIsLoading, projectDetails, handleViewGrn = () => { }, handleGRNView = () => { },
  handleReturn = () => { }, getGRNList = () => { }, handlePayView = () => { }, rowsPerPage, page, handleChangePage = () => { },
  handleChangeRowsPerPage = () => { } }) {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [grnRejectedList, setGrnRejectedList] = useState([])
  const [grnRejectedView, setGrnRejectedView] = useState()
  const [isView, setIsView] = useState(false)
  const [otherProjectGrn, setOtherProjectGrn] = useState([])

  const a11yProps = index => {
    if (index == 0) {
      getGRNList()
    }
    if (index == 1) {
      getGrnRejectedList()
    }
    if (index == 2) {
      getGRNForOutsideProjectData()
      // getGrnRejectedList()
    }
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.totalPrice;
    });
    return test;
  };


  const getGrnRejectedList = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.getGrnRejectedList + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setGrnRejectedList(data);
          setIsLoading(false)
        } else {
          // toast.error(res.data.message);
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      });
  };

  const getGRNForOutsideProjectData = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.getGRNForOutsideProject + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setOtherProjectGrn(data)
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      });
  };


  return (
    <>
      <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Goods Received List"
            onClick={() => {
              a11yProps(0)
            }}
          />
          <Tab label="Goods Rejected List"
            onClick={() => {
              a11yProps(1)
            }} />
          <Tab label="Out Side Project List"
            onClick={() => {
              a11yProps(2)
            }} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">GRN ID</TableCell>
                      <TableCell align="left">Indent ID</TableCell>
                      <TableCell align="left">Invoice ID</TableCell>
                      <TableCell align="left">PO ID</TableCell>
                      <TableCell align="left">Total Requested Quantity</TableCell>
                      <TableCell align="left">Accepted Count</TableCell>
                      {/* <TableCell align="center">Free Quantity</TableCell> */}
                      <TableCell align="left">Total Accepted QTY Price</TableCell>
                      <TableCell align="left">Received By</TableCell>
                      <TableCell align="left">Received Date</TableCell>
                      {/* <TableCell align="left">Invoice Attach</TableCell> */}
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allGRN && allGRN.length != 0 ? (
                      allGRN.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((x, index) => {
                        return (
                          <TableRow hover role="checkbox">
                            <TableCell align="left">
                              {x.grnMasterID}
                            </TableCell>
                            <TableCell align="left">
                              {x.indentMasterID ?? '-'}
                            </TableCell>
                            <TableCell align="left">
                              {x.invoiceID}
                            </TableCell>
                            <TableCell align="left">
                              {x.purchaseOrderMasterID ==
                                'NO Purchase Order ID'
                                ? '-'
                                : x.purchaseOrderMasterID}
                            </TableCell>
                            <TableCell align="left">
                              {/* { currencyFormat1(Math.round(totalQty(x.grnItem || [])))} */}
                              {currencyFormat1(Math.round(x?.totalRequestQty))}
                            </TableCell>
                            {/* <TableCell align="center">
                              {x.freeItem || 0}
                            </TableCell> */}
                            <TableCell align="left">
                              {currencyFormat1(Math.round(x?.aprovedCount))}
                            </TableCell>
                            <TableCell align="left">
                              {/* { currencyFormat1(Math.round(x?.totalItemValue))} */}
                              ₹ {currencyFormat1(Math.round(totalQty(x.grnItem || [])))}
                            </TableCell>
                            <TableCell align="left">
                              {x.CreatedByName}
                            </TableCell>
                            <TableCell align="left">
                              {dateFormate(x.CreateDate)}
                            </TableCell>
                            {/* <TableCell>
                              {x?.invoiceAttach && <a href={x?.invoiceAttach}>{x?.invoiceAttach.substring(0, 25)}</a>}
                            </TableCell> */}

                            <TableCell align="left">
                              {permission != "view_GRN" && permission != "view&edit_GRN" &&
                                <div>-</div>}
                              {permission == "view_GRN" &&
                                <VisibilityIcon
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '10px'
                                  }}
                                  onClick={() => {

                                    handleViewGrn();
                                    handleGRNView(x);
                                  }}
                                />
                              }
                              {permission == 'view&edit_GRN' && <>
                                <PaymentIcon
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '10px'
                                  }}
                                  onClick={() => {
                                    handlePayView(x);
                                  }}
                                />

                                <>
                                  {x?.totalItemValue == x?.pendingAmount && (
                                    <KeyboardReturnIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleReturn(x);
                                      }}
                                    />
                                  )}
                                </>
                              </>
                              }
                            </TableCell>


                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow hover role="checkbox" key={1}>
                        <TableCell colSpan={10} align="center" key={2}>
                          {'No Data Found'}
                        </TableCell>{' '}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {allGRN && allGRN.length != 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={allGRN.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">GRN ID</TableCell>
                      <TableCell align="left">Indent ID</TableCell>
                      <TableCell align="left">Invoice ID</TableCell>
                      <TableCell align="left">PO ID</TableCell>
                      <TableCell align="left">Total Requested Quantity</TableCell>
                      <TableCell align="left">Rejected Count</TableCell>
                      {/* <TableCell align="center">Free Quantity</TableCell> */}
                      {/* <TableCell align="left">Value</TableCell> */}
                      <TableCell align="left">Rejected By</TableCell>
                      <TableCell align="left">Rejected Date</TableCell>
                      <TableCell align="left">Invoice Attach</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {grnRejectedList && grnRejectedList.length != 0 ? (
                      grnRejectedList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((x, index) => {
                        return (
                          <TableRow hover role="checkbox">
                            <TableCell align="left">{x.grnMasterID}</TableCell>
                            <TableCell align="left">{x.indentMasterID ?? '-'}</TableCell>
                            <TableCell align="left">{x.invoiceID} </TableCell>
                            <TableCell align="left">
                              {x.purchaseOrderMasterID == 'NO Purchase Order ID' ? '-' : x.purchaseOrderMasterID}
                            </TableCell>
                            {/* <TableCell align="left">{totalQty(x.grnItem || [])}</TableCell> */}
                            <TableCell align="left">{currencyFormat1(x?.totalRequestQty)}</TableCell>
                            <TableCell align="left">{currencyFormat1(x?.rejectedCount)}</TableCell>
                            <TableCell align="left">{x.CreatedByName}</TableCell>
                            <TableCell align="left">{dateFormate(x.CreateDate)}</TableCell>
                            <TableCell>
                              {x?.invoiceAttach && <a href={x?.invoiceAttach}>{x?.invoiceAttach.substring(0, 25)}</a>}
                            </TableCell>
                            <TableCell align="left">
                              {permission == "view_GRN" &&
                                <VisibilityIcon
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '10px'
                                  }}
                                  onClick={() => {
                                    setIsView(true)
                                    setGrnRejectedView(x)
                                  }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow hover role="checkbox" key={1}>
                        <TableCell colSpan={10} align="center" key={2}>
                          {'No Data Found'}
                        </TableCell>{' '}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {grnRejectedList && grnRejectedList.length > 10 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={grnRejectedList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={isView}
          id="income"
          contentstyle={{ width: '200%', maxWidth: 'none' }}
          scroll={'paper'}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '800px !important'
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <h2> GRN Rejected View </h2>
            </div>
          </DialogTitle>
          <DialogContent dividers style={{ width: '800px !important' }}>
            <Grid container spacing={3}>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    Indent ID : {grnRejectedView?.grnMasterID}
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    GRN Rejected Date- {dateFormate(grnRejectedView?.CreateDate)}
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    GRN-ID: {grnRejectedView?.grnMasterID}
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    Invoice ID: {grnRejectedView?.invoiceID}

                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    Indent ID: {grnRejectedView?.indentMasterID}
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    PO ID: {grnRejectedView?.purchaseOrderMasterID}
                  </h5>
                </div>
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">GRN Item ID</TableCell>
                          <TableCell align="left"> Material Name</TableCell>
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left">Rejected Quantity</TableCell>
                          <TableCell align="left">Rejected Comment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {grnRejectedView?.grnItem ?
                          grnRejectedView?.grnItem
                            .map((item, index) => (
                              <TableRow key={index} hover>
                                <TableCell align="left">{item?.grnItemID}</TableCell>
                                <TableCell align="left">{item?.name}</TableCell>
                                <TableCell align="left">{item?.categoryName}</TableCell>
                                <TableCell align="left">{item?.rejectedCount}</TableCell>
                                <TableCell align="left">
                                  {item.rejectedComment == 'null' ? '-' : item.rejectedComment}
                                </TableCell>
                              </TableRow>
                            )) : 0}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }} >
            <Button className={classes.button2}
              onClick={() => {
                setIsView(false)
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">GRN Item ID</TableCell>
                    <TableCell align="left">Material Name</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Accepted Count</TableCell>
                    <TableCell align="left">Unit Price</TableCell>
                    <TableCell align="left">GST %</TableCell>
                    <TableCell align="left">Tax Amount</TableCell>
                    <TableCell align="left">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherProjectGrn && otherProjectGrn && otherProjectGrn?.length != 0 ? (
                    otherProjectGrn.map((x, index) => {
                      return (
                        <TableRow hover>
                          <TableCell align="left">{x?.grnItemID}</TableCell>
                          <TableCell align="left">{x?.name}</TableCell>
                          <TableCell align="left">{x.categoryName}</TableCell>
                          <TableCell align="left">{x.acceptedCount}</TableCell>
                          <TableCell align="left">₹ {currencyFormat1(Math.round(x?.unitPrice))}</TableCell>
                          <TableCell align="left">{x?.gstRate}</TableCell>
                          <TableCell align="left">₹ {currencyFormat1(Math.round(x?.TotalTax))}</TableCell>
                          <TableCell align="left">₹ {currencyFormat1(Math.round(x?.totalPrice))}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {'No Data Found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

      </TabPanel>

    </>
  )
}

export default GrnTable