import { combineReducers } from 'redux';
import { Location } from './Location';
import {Emplyoee} from './Employee';
import {NavigationData } from './Common';
import {NavData} from './Nav';
import {ProjectInfo} from './ProjectData'
export default combineReducers({
    Location,
    Emplyoee,
    NavigationData,
    NavData,
    ProjectInfo
});