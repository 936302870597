import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useConfirm } from "material-ui-confirm";
import './style.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Location = ({ props, className, ...rest }) => {
  const classes = useStyles();
  const [allLocations, setallLocations] = useState([])
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [openLoader, setOpenLoader] = React.useState(false);
   const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [headerTitle,setHeaderTitle] = useState({title:'Location',btn:"Save"})
  const locationList = useSelector(x => x.Location);
  const [payload, setPayload] = useState({ locationType: 'abc', locationName: '', locationAddress: '', Pincode: '' })

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const confirm = useConfirm();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const handleClickOpen = () => {
    setOpenModel(true);
    setHeaderTitle({title:'Location',btn:"Save"})
  };
  const handleModelClose = () => {
    setOpenModel(false);
    setPayload({ locationType: 'abc',
       locationName: '',
        locationAddress: '',
        Pincode: '' ,locationId:''});
         setPayload({})
  };
  const addLocation = () => {

    APIKit.post('/' + APP_URLS.addLocation, payload,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
          getLocations();
          setPayload({});
          setOpenModel(false);

        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
      })
      .catch(function (e) {
        setAlertMeg('error');
        setErrorMeg('error');
        setOpen(true);
      })
  }
  const updateLocation = () => {
    APIKit.post('/' + APP_URLS.updateLocation, payload,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
          setOpenModel(false);
          getLocations();
          setPayload({})

        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
      })
      .catch(function (e) {
        setAlertMeg('error');
        setErrorMeg('error');
        setOpen(true);
      })
  }
  const changeStatus = (status,id) =>{
      confirm({description:"you want to update the user status ?"})
      .then(() => 
      { 
        activeOrInactive(status,id)
      })
      .catch(() => console.log("Deletion cancelled."));
    
   
  }
  const activeOrInactive = (status,id) => {

    APIKit.post('/' + APP_URLS.updateLocationStatus, {
      "status":status,
      "locationId":id
    }
    ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
          getLocations();

        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
      })
      .catch(function (e) {
        setAlertMeg('error');
        setErrorMeg('error');
        setOpen(true);
      })
  }
  

  useEffect(() => {
    getLocations();
  }, [])
  const getLocations = () => {
    APIKit.get('/' + APP_URLS.getLocation,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.status === 200) {
          setallLocations(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const searchLocations = (quary) => {
    APIKit.post('/' + APP_URLS.search, { search: quary },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          setallLocations(res.data.data)
        }
        else {
          getLocations()
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

 

  const Edit = (data) => {
    setHeaderTitle({title:'Edit Location',btn:"Update"})
      setPayload({ locationType: 'abc',
       locationName: data.LocationName,
        locationAddress: data.LocationAddress,
        Pincode: data.Pincode ,locationId:data.LocationID});
      setOpenModel(true);
    
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };



  return (
    <Page
      className={classes.root}
      title="Locations">
      <Container maxWidth={false}>
        <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
          <CircularProgress style={{color:'#fff'}} />
        </Backdrop>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  >
          <Alert onClose={handleClose} severity={alertMeg} >
            {errorMeg}
          </Alert>
        </Snackbar>        <Box
          display="flex"
          justifyContent="flex-end"
        >

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#394671 ', fontSize: '17px', fontWeight: 'bold' }}>
              Address
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                variant="contained"

                // onClick={openAddLocation}
                // onClick={() => {
                //   navigate('/app/AddLocation')

                // }

                // }
                onClick={handleClickOpen}
              >
                Add Address
              </Button>
            </Grid>
          </Grid>

        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box maxWidth={500}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Location"
                  variant="outlined"
                  onChange={(e) => {
                    searchLocations(e.target.value)
                  }}
                // value={e.target.value}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box mt={3}>
        <TableContainer component={Paper}>
                                  
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                
                <TableCell>
                  S.No
                </TableCell>
                <TableCell style={{width:"20%"}}>
                Address Line1
                </TableCell>
                <TableCell style={{width:"30%"}}>
                Address Line2
                </TableCell>
                <TableCell>
                  Pincode
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allLocations && allLocations.length > 0 && allLocations.map((item, index) => (
                <TableRow
                  hover
                  key={item.LocationID}
                  selected={selectedCustomerIds.indexOf(item.LocationID) !== -1}
                >

                  <TableCell >
                    {index + 1}
                  </TableCell>
                  <TableCell  >
                    {item.LocationName}
                  </TableCell>
                  <TableCell >
                    {item.LocationAddress}
                  </TableCell>
                  
                  <TableCell >
                    {item.Pincode}
                  </TableCell>
                  <TableCell>
                    <EditOutlinedIcon onClick={() => {
                    Edit(item)
                     
                   }
                  
                  }/>
                  &nbsp; &nbsp;
                    {item.IsActive == true ?
                      <Button variant="outlined" color="primary"
                      style={{
                        "padding":"2px",
                        "top":"-6px"
                      }}
                      onClick={() => changeStatus(0,item.LocationID)}>
                        Active
                    </Button>
                      : null}
                    &nbsp;
                    {item.IsActive == false ?
                      <Button variant="outlined" color="secondary" style={{
                        "color": "#fb2150",
                        "border": "1px solid #f44336",
                        "padding":"2px",
                        "top":"-6px"
                      }} onClick={() => changeStatus(1,item.LocationID)}>
                        Inactive
                    </Button>
                      : null}
                    {/* <DeleteForeverIcon /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
        </Box>






      </Container>
      <Dialog aria-labelledby="customized-dialog-title" open={openModel}>

        <DialogTitle id="customized-dialog-title" onClose={handleModelClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          {headerTitle.title}
              </DialogTitle>
        <DialogContent dividers>
          {/* <div className={classes.root}> */}
          <div style={{ marginLeft: "0" }}>
            <Grid container spacing={3} style={{ "textAlign": "left"}} >

              <Grid item xs={3} style={{ "textAlign": "left", "marginTop": "12px" }}>
                Address Line1 :
                         </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  helperText=""
                  label="Address Line1"
                  name="locationName"
                  onChange={(e) => {
                    const p = payload
                    p.locationName = e.target.value
                    setPayload({
                      ...p
                    })
                  }}
                  required
                  value={payload.locationName}
                  variant="outlined" />
              </Grid>
              <Grid item xs={3}></Grid>

              <Grid item xs={3} style={{ "textAlign": "left", "marginTop": "12px" }}>
              Address Line2 :
                         </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  helperText=""
                  label="Address Line2"
                  name="locationAddress"
                  onChange={(e) => {

                    let p = payload;
                    p.locationAddress = e.target.value
                    setPayload({
                      ...p
                    })
                  }}
                  required
                  value={payload.locationAddress}
                  variant="outlined"
                  multiline
                  // rows={2}
                  // rowsMax={4}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3} style={{ "textAlign": "left", "marginTop": "12px" }}>
                Pincode :
                         </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  helperText=""
                  label="Pincode"
                  name="Pincode"
                  inputProps={{
                    maxLength:10,
                    minLength:10
                  }}
                  // minLength={10} 
                  onChange={(e) => {

                    let p = payload;
                    p.Pincode = e.target.value.replace(/\D/g, "")
                   
                    setPayload({
                      ...p
                    })
                  }}
                  required
                  value={payload.Pincode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff",padding:"5px" }}>
          {headerTitle.btn==="Update"?
          <Button autoFocus
            onClick={() => {
              updateLocation();
            }}
            className={classes.button2}>
           Update
                 </Button>
                 :
                 <Button autoFocus
            onClick={() => {
              addLocation();
            }}
            disabled={!payload.locationAddress || !payload.Pincode || !payload.locationName}
            className={classes.button2}>
            Save
            
                 </Button>
                 }
        </DialogActions>

      </Dialog>
    </Page>
  )

}
export default Location;