
import React from 'react';
import {
  makeStyles,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@material-ui/core';
import { statusColor } from 'src/utils/helpers';
// import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  succcessIcon: {
    fill: "green",
  },
  failureIcon: {
    fill: "red",
  },
  text: {
    fill: "white",
  },
  stepIcon: {
    color: "#fff"
  },
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
  step: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  },

}));
const StepperBox = ({ steps = [] ,currentApproveId}) => {
console.log('steps',steps);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(steps?.filter(x => x.runningStatus === 1).length - 1);
  const isStepFailed = (step) => {
    return [25, 26, 28, 90].some(x => x == step.approvalStatusID) ? true : false;
  };

  return <Box sx={{width:'100%',overflowX:'scroll'}}>
    <Stepper activeStep={activeStep} >
      {steps?.map((step, index) => {
        console.log('step.approvalStatusID',step.approvalStatusID);

        const labelProps = {};
        if (isStepFailed(step)) {
          labelProps.optional = (
            <>
              <Typography variant="caption" color="error" component='p' >
                {step.approvalStatusID === 25 ? 'Rejected' :
                  step.approvalStatusID === 26 ? 'Hold' :
                    step.approvalStatusID === 28 ? 'Dropped' : ''}
              </Typography>
              {currentApproveId!==24 && step.status !== "Approved" &&
              <Typography variant="caption" color="error">
              {step.comment == 'null' ?'': step.comment }
              </Typography>
        }
            </>
          );

          labelProps.StepIconProps = {
            classes: {
              active: classes.succcessIcon,
              text: classes.text,
              error: classes.failureIcon
            }
          };

          labelProps.error = true;
        } else {
          labelProps.optional = (
            <>
              <Typography variant="caption" component='p' >
                 {step.approvalStatusID === 24 ? 'Approved' : step.approvalStatusID === 89 ?'Recommend': 'Awaiting'}
              </Typography>
              {currentApproveId!=24 && step.status !== "Approved" &&
              <Typography variant="caption">
                {step.comment == 'null' ?'': step.comment }
              </Typography>
        }
            </>
          );

          labelProps.StepIconProps = {
            classes: {
              active: classes.succcessIcon,
              text: classes.text,
              error: classes.succcessIcon
            }
          };

          labelProps.error = false;
        }

        return (
          <Step key={step.name}
          // classes={{
          //   root: classes.step,
          //   completed: classes.completed,
          //   active: classes.active
          // }}
          >
            {/* <StepLabel
              optional={
                index < activeStep + 1 ? (
                  <>
                    <Typography style={{ color: statusColor(step.status) }} component='p' variant="caption">{step.status}</Typography>
                    <Typography component='p' variant="caption">{step.comment != 'undefined' ? step.comment : ''}</Typography>
                  </>
                ) : null
              }
              StepIconProps={{
                classes: {
                  active: classes.icon,
                  text: classes.text,
                  completed: classes.icon
                }
              }}
            >
              {step.name}
            </StepLabel> */}
            <StepLabel {...labelProps}>{step.name}</StepLabel>
          </Step>
        )
      }) ?? <Typography component='p' variant="caption">Approval Config is not defined for this indent.</Typography>}
    </Stepper>
  </Box>

}

export default StepperBox;
