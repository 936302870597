import React, { useState, useEffect } from 'react'
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow,
    Typography, makeStyles, Card, CardContent, Container, Button, TextField,
    Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem,
    FormHelperText, TablePagination
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useNavigate, useLocation } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import { getloggedInData } from '../../../utils/helpers';
import { dateOnlyRev, getProjectDetails } from '../Common/utilities';
import { RGCA_URLS } from '../../../utils/url';
import { dateForm } from './../Common/utilities';
import { useParams } from "react-router-dom";
import EditOutlined from '@material-ui/icons/EditOutlined';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const EditProductionHistory = ({ data }) => {
    const [prodHis, setProdHis] = useState([]);
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) }
    const [categoryItemId, setCategoryItemId] = useState(null)
    const [isOpen, setIsOpen] = React.useState(false);
    const [isSelected, setIsSelected] = useState({});
    const [isBatches, setIsBatches] = useState([]);
    const [category,setCategory] = useState([])
    const [isBatchId, setIsBatchId] = useState('');
    const [payload, setPayload] = useState({
        "projectID": null,
        "itemID": null,
        "productionStock": isSelected?.productionCount,
        "mortalityCount": 0,
        "itemProductionHistoryID": null
    })


    useEffect(() => {
        getItemsList()
    }, [])

    const getData = () => {
        const payload = {
            itemID: categoryItemId,
            projectID: projectDetails.projectID,
            batchNo: isBatchId
        }
        APIKit.post(RGCA_URLS.listProduction, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    setProdHis(res.data.data);
                    //   setIsLoading(false);

                } else {
                    //   setIsLoading(false);    
                }
            })
            .catch((e) => {
                // setIsLoading(false);    
            });
    }

    let seenBatchNumbers = new Set();
    const getItemsList = () => {
        APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {

                    const finalData = res.data.data?.filter(item => {
                        if (seenBatchNumbers.has(item.batchNo)) {
                            return false;
                        } else {
                            seenBatchNumbers.add(item.batchNo);
                            return true;
                        }
                    });
                    setIsBatches(finalData)
                    console.log('final', finalData);
                } else {

                }
            })
            .catch((e) => {
            });
    };

    const modifiedPayload = (row) => {
        setIsSelected(row)
        let p = payload;
        p.mortalityCount = row?.mortalityCount ? Number(row?.mortalityCount) : 0;
        p.productionStock = Number(row?.productionCount);
        setPayload({ ...p })
    }

    const updateProd = () => {
        payload.itemID = isSelected.itemID;
        payload.itemProductionHistoryID = isSelected.itemProductionHistoryID;
        payload.projectID = isSelected.projectID
        APIKit.put(RGCA_URLS.updateProductionHistory, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    toast.success("Successfully update.")
                    setIsOpen(false)
                } else {
                    toast.error("please try again.")
                }
            })
            .catch((e) => {
                // setIsLoading(false);    
            });
    }

    const getsaleForsaleStart = (batchid) => {
        let payload = {
            "projectID": projectDetails.projectID,
            "batchNo": batchid,
        }
        APIKit.post(RGCA_URLS.getsalesForsaleStart, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setCategory(res.data.data);

                } else {
                    toast.error("Error")
                }
            })
            .catch(e => { });
    }
    return (
        <div>
            <ToastContainer />
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={6}
                    xs={6}
                // className={classes.custmr}
                >
                    <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={`formControl`}
                    >
                        <InputLabel
                            id="demo-simple-select-outlined-label"
                            required
                        >
                            Batch No.
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Batch No."
                            name="isBatchId"
                            value={isBatchId}
                            onChange={(e) => {
                                setProdHis([])
                                getsaleForsaleStart(e.target.value)
                                setIsBatchId(e.target.value)
                            }}
                        >
                            {isBatches && isBatches.filter(y=>y.isSaleOn==false).map((x, index) => {
                                return <MenuItem value={x.batchNo} key={index + 1}>
                                    {x.batchNo}
                                </MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={6}
                    xs={6}
                // className={.custmr}
                >
                    <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={`formControl`}
                    >
                        <InputLabel
                            id="demo-simple-select-outlined-label"
                            required
                        >
                            Category
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Category"
                            name="categoryItemId"
                            value={categoryItemId}
                            onChange={(e) => {
                                setCategoryItemId(e.target.value)
                            }}
                        >
                            {category && category.map((x, index) => {
                                return <MenuItem value={x.itemID} key={x.itemID}>
                                    {x.categoryName}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={6}
                    xs={6}
                // className={.custmr}
                >
                    <Button color="primary"
                        variant="contained" onClick={() => {
                            getData()
                        }}>Submit</Button>
                </Grid>
            </Grid>


            <Box mt={3}>
                <TableContainer>
                    <Table className={`table`}>
                        <TableHead>
                            <TableRow>

                                <TableCell>
                                    S.No
                                </TableCell>
                                <TableCell>
                                    Production Date
                                </TableCell>
                                <TableCell>
                                    Production Count
                                </TableCell>
                                <TableCell>
                                    Mortality Count
                                </TableCell>
                                <TableCell>
                                    Batch No.
                                </TableCell>
                                <TableCell>
                                    GST Rate
                                </TableCell>
                                <TableCell>
                                    Rate per Unit
                                </TableCell>

                                <TableCell>
                                    Modified By
                                </TableCell>
                                <TableCell>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {prodHis && prodHis.length != 0 ? prodHis.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.itemID}>
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell align="left">{dateForm(row.productionDate)}</TableCell>
                                        <TableCell align="left">{row.productionCount}</TableCell>
                                        <TableCell align="left">{row.mortalityCount}</TableCell>
                                        <TableCell align="left">{row.batchNo}</TableCell>
                                        <TableCell align="left">{row.gstRate}</TableCell>
                                        <TableCell align="left">{row.rpu}</TableCell>
                                        <TableCell align="left">{row.createdByName}</TableCell>
                                        <TableCell><EditOutlined onClick={() => {
                                            modifiedPayload(row)
                                            // setIsSelected(row)
                                            setIsOpen(true)
                                        }} /></TableCell>
                                    </TableRow>
                                );
                            })
                                : <TableRow hover role="checkbox" key={1}>
                                    <TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

                        </TableBody>

                    </Table>
                </TableContainer>
                {/* {prodHis && prodHis.length != 0 && prodHis.length > 10 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={prodHis ? prodHis.length : 0}
                  rowsPerPage={rowsPerPage1}
                  page={page1}
                  onPageChange={handleChangePage1}
                  onRowsPerPageChange={handleChangeRowsPerPage1}
                />
              } */}
            </Box>

            {/* dialog */}
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                id="income"
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title" onClose={(e => {
                    // cancelSalesItem(resetForm)
                })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                    {/* {btn ? 'Edit' : 'Add'} */} Edit Production Item

                </DialogTitle>

                <DialogContent>
                    <Box mt={3} style={{
                        backgroundColor: '#fff',
                        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                    }}>

                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Production Date"
                                    variant="outlined"
                                    value={dateForm(isSelected.productionDate)}
                                    disabled
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Production Count"
                                    variant="outlined"
                                    name='productionCount'
                                    onChange={(e) => {
                                        let p = payload;
                                        p.productionStock = Number(e.target.value);
                                        isSelected.productionCount = e.target.value
                                        setPayload({ ...p })
                                    }}
                                    value={isSelected.productionCount}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Mortality Count"
                                    variant="outlined"
                                    name='productionCount'
                                    onChange={(e) => {
                                        let p = payload;
                                        p.mortalityCount = Number(e.target.value);
                                        isSelected.mortalityCount = e.target.value
                                        setPayload({ ...p })
                                    }}
                                    value={isSelected.mortalityCount}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="GST Rate	"
                                    variant="outlined"
                                    value={isSelected.gstRate}
                                    disabled
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Rate per Unit"
                                    variant="outlined"
                                    value={isSelected.rpu}
                                    disabled
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Modified By"
                                    variant="outlined"
                                    value={isSelected.createdByName}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                    <Button onClick={(e => {
                        setIsOpen(false)
                    })} >
                        Cancel
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={() => {
                            updateProd()
                        }}
                    // className={classes.button2}
                    >
                        Save
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default EditProductionHistory