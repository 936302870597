import React, { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './style.css'

export default function MultiSelectforSamples({setSelectedSample,selectedSample}) {
    const cities = [
        { name: 'Fish', code: '1' },
        { name: 'Shrimp', code: '2' },
        { name: 'Crab', code: '3' },
        { name: 'Artemia', code: '4' },
        { name: 'Freshwater Prawn', code: '5' },
        { name: 'Water', code: '6' },
        { name: 'Others', code: '7' },
    ];

    return (
        <div >
            <MultiSelect value={selectedSample}
                onChange={(e) => {
                    console.log("e", e.value);
                    setSelectedSample(e.value)
                }}
                options={cities} optionLabel="name"
                placeholder="Sample Details" maxSelectedLabels={3}
                className="w-full md:h-3.5rem " />
        </div>
    );
}
