import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import 'date-fns';
import * as Yup from 'yup';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'src/views/loader';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1 } from '../Common/utilities';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, textarea: {
        resize: "both"
    }, centertext: {
        marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
        '@media (max-width: 500px)': {
            marginLeft: "110px"
        },
        '@media (max-width: 350px)': {
            marginLeft: "60px"
        },
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



const CompletedPrograms = ({ completedPrograms }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>

            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>S.No</TableCell>
                                    <TableCell align='left' style={{ width: '200px' }}>Programe ID</TableCell>
                                    <TableCell align='left'>Program Name</TableCell>
                                    <TableCell align='left' style={{ width: '120px' }}>From Date</TableCell>
                                    <TableCell align='left' style={{ width: '120px' }}>To Date</TableCell>
                                    <TableCell align='left'>Location</TableCell>
                                    <TableCell align='left'>Fee</TableCell>
                                    <TableCell align='left'>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {completedPrograms && completedPrograms.length != 0 ? (
                                    completedPrograms.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell align='left'>{index + 1}</TableCell>
                                                <TableCell align='left'>{x?.HandsOnTrainingProgrammeID ? x?.HandsOnTrainingProgrammeID : ''}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.NameoftheProgram}>
                                                        <span>
                                                            {x?.NameoftheProgram ? x?.NameoftheProgram.length > 30 ? `${x?.NameoftheProgram.slice(0, 30)} ...` : x?.NameoftheProgram : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left' style={{ width: '120px' }}>{x?.FromDate ? moment(x?.FromDate).format('DD-MM-yyyy') : '-'}</TableCell>
                                                <TableCell align='left' style={{ width: '120px' }}>{x?.ToDate ? moment(x?.ToDate).format('DD-MM-yyyy') : '-'}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.Location}>
                                                        <span>
                                                            {x?.Location ? x?.Location.length > 20 ? `${x?.Location.slice(0, 20)} ...` : x?.Location : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left'> {x?.Fee ? currencyFormat1(Math.round(x?.Fee)) : ''}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.Description}>
                                                        <span>
                                                            {x?.Description ? x?.Description.length > 30 ? `${x?.Description.slice(0, 30)} ...` : x?.Description : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {completedPrograms && completedPrograms?.length != 0 && completedPrograms?.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={completedPrograms.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>

        </>
    )
}

export default CompletedPrograms