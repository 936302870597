export const Constant =
{
    approvalTypes: {
        CreateDate: "2021-11-17T07:48:20.640",
        CreatedBy: 1,
        description: "approval types",
        isActive: true,
        lookupID: 13,
        name: "approval types",
        parentID: 0
    },
    companyType: {
        CreateDate: "2021-11-16T07:34:19.780",
        CreatedBy: 1,
        description: "Company Type",
        isActive: true,
        lookupID: 4,
        name: "Company Type",
        parentID: 0
    },
    Gender: {
        CreateDate: "2021-11-16T07:37:33.783",
        CreatedBy: 1,
        description: "Gender",
        isActive: true,
        lookupID: 10,
        name: "Gender",
        parentID: 0
    },
    indentMaster: {
        CreateDate: "2021-11-22T13:10:44.640",
        CreatedBy: 1,
        description: "indent Master",
        isActive: true,
        lookupID: 19,
        name: "indent Master",
        parentID: 0
    },
    indentStatus: {
        CreateDate: "2021-11-22T13:13:04.077",
        CreatedBy: 1,
        description: "indent status",
        isActive: true,
        lookupID: 23,
        name: "indent status",
        parentID: 0
    },
    ItemsTypes: {
        CreateDate: "2021-11-12T06:25:16.353",
        CreatedBy: 1,
        description: "Items types",
        isActive: true,
        lookupID: 1,
        name: "Types",
        parentID: 0
    },
    SalesTypes: {
        CreateDate: "2021-11-18T05:55:16.767",
        CreatedBy: 1,
        description: "Sales types",
        isActive: true,
        lookupID: 16,
        name: "Sales types",
        parentID: 0
    }
}
