import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from '@material-ui/pickers';
// import 'date-fns';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import { RGCA_URLS } from 'src/utils/url';
import DialogContentText from '@material-ui/core/DialogContentText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIKit from "../../../utils/APIKit"
import { getloggedInData } from '../../../utils/helpers'
import { APP_URLS } from '../../../utils/apis';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from "material-ui-confirm";
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, validateYupSchema, FormikValues } from 'formik';
import { MESSAGE } from 'src/utils/message';
import receiptLogo from '../../Project/receipts/receipt.png'


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px', marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const data = [{ receiptNo: "1", receiptDate: '01-03-2021', amout: 110, modeOfPayment: ' Online Transfer', customerName: "babi", purpose: ' EMD' },
{ receiptNo: "1", receiptDate: '01-01-2021', amout: 110, modeOfPayment: ' Online Transfer', customerName: "babi", purpose: ' EMD' },
{ receiptNo: "2", receiptDate: '01-02-2021', amout: 180, modeOfPayment: ' Online Transfer', customerName: "ram", purpose: ' EMD' },
{ receiptNo: "3", receiptDate: '01-03-2021', amout: 140, modeOfPayment: ' Online Transfer', customerName: "laxman", purpose: ' EMD' },
{ receiptNo: "4", receiptDate: '01-04-2021', amout: 140, modeOfPayment: ' Online Transfer', customerName: "kiran", purpose: ' EMD' },
{ receiptNo: "5", receiptDate: '01-05-2021', amout: 180, modeOfPayment: ' Online Transfer', customerName: "prasad", purpose: ' EMD' },
{ receiptNo: "6", receiptDate: '01-06-2021', amout: 100, modeOfPayment: ' Online Transfer', customerName: "balu", purpose: ' EMD' },
{ receiptNo: "7", receiptDate: '01-07-2021', amout: 120, modeOfPayment: ' Online Transfer', customerName: "hari", purpose: ' EMD' }]




const Receipts = () => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [alertopen, setAlertOpen] = React.useState(false);
  const [openTest, setOpenTest] = React.useState(false);
  const [openTest1, setOpenTest1] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [amountError, setAmountError] = useState('');
  const [amount, setAmount] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modeOfReciept, setModeOfReciept] = useState(0);
  const [modeOfRecieptError, setModeOfRecieptError] = useState();
  const [customer, setCustomer] = useState(0);
  const [customerError, setCustomerError] = useState('')
  const [purpose, setPurpose] = useState(0)
  const [purposeError, setPurposeError] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [lookData, setLookData] = useState([]);
  const [allCustomer, setAllCustomer] = useState([]);
  const [morAttach, setMorAttach] = useState({});

  const [receiptList, setReceiptList] = useState([]);

  const [receipt, setReceipt] = useState({
    sellingMasterID: '',
    amount: null,
    mor: null,
    customer: null,
    purpose: null,
    others: '',
    morAttach: '',
    "transferNo": "",
    "bank": "",
    "receiptDate": "",
    "receiptNotes": "",
    "receiptFrom": ""

  })
  const [allVendors, setAllVendors] = useState([])
  const [btn, setBtn] = useState(false)
  const { state } = useLocation();

  const navigate = useNavigate();
  const handleCloseModal = (resetForm = null) => {
    setOpenTest(false);
    if (resetForm) {
      resetForm();
    }
  };
  const handleCloseModal1 = () => {
    setOpenTest1(!openTest1);
  };
  const [receiptPrintViewObj, setReceiptPrintViewObj] = React.useState({});
  const handleReceiptView = (data) => {
    setReceiptPrintViewObj(
      data
    )
  }

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
    <style>
    @page { size: landscape; }
    @media print {

.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
  float: left!important;
}
.col-sm-12 {
  width: 100%;
}
.col-sm-11 {
  width: 91.66666666666666%;
}
.col-sm-10 {
  width: 83.33333333333334%;
}
.col-sm-9 {
  width: 75%;
}
.col-sm-8 {
  width: 66.66666666666666%;
}
.col-sm-7 {
  width: 58.333333333333336%;
}
.col-sm-6 {
  width: 50%;
}
.col-sm-5 {
  width: 41.66666666666667%;
}
.col-sm-4 {
  width: 33.33333333333333%;
}
.col-sm-3 {
  width: 25%;
}
.col-sm-2 {
  width: 16.666666666666664%;
}
.col-sm-1 {
  width: 8.333333333333332%;
}

}
    </style>
    </head><body>'${printableElements}'</body></html>`;
    // document.body.innerHTML = html
    window.print();
    // document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    // window.location.reload()
  }


  const exportPdf = () => {

    html2canvas(document.querySelector("#printme")).then(canvas => {
      document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save("download.pdf");
    });

  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getLookup()
    getLookupData()
    getReceipts()
    getcustomer()
  }, []);

  const getcustomer = () => {
    APIKit.post(RGCA_URLS.listCustomer,
      {
        "projectID": projectDetails.projectID
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAllCustomer(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/41`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData([...res.data.data])
          //setIndentItemList([{ ...itemInitialValues, typeList: res.data.data }])
          console.log(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const getLookupData = () => {
    APIKit.get(`${RGCA_URLS.lookup}/47`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookData([...res.data.data])
          //setIndentItemList([{ ...itemInitialValues, typeList: res.data.data }])
          console.log(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const getReceipts = () => {

    const payload = {
      projectID: projectDetails.projectID
    }
    APIKit.post(RGCA_URLS.getReceipts, payload)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,

            }
          })
          console.log(data)
          setReceiptList(data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }

  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return
    };
    setIsLoading(true);
    const formData = new FormData();
    formData.append('upload', event.target.files[0], event.target.files[0].name);
    if (type == 'Modeofreceipt') {
      APIKit.post(RGCA_URLS.fileUpload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setMorAttach(res.data.data);
            setReceipt({
              ...receipt,
              morAttach: res.data.data.file,
            });
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
        .catch(() => {

        });
    }

  };
  const removeReceipt = (data) => {
    console.log('data:', data);
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        remove(data)
        // activeOrInactive(status,id)
      })
      .catch(() => console.log("Deletion cancelled."));

  }

  const remove = (data) => {
    APIKit.delete(RGCA_URLS.deleteReceipts + '/' + data.receiptID,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          getReceipts()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  const [receiptID, setReceiptID] = useState(null)
  const editReceipt = (data) => {
    setReceiptID(data.receiptID)
    setReceipt(data)
    setBtn(true)
    setOpenTest(true);
  }

  const updateReceipt = () => {
    const payload = {
      //  ...receipt
      "projectID": projectDetails.projectID,
      "receiptID": receiptID,
      "amount": receipt.amount,
      "mor": receipt.mor,
      "morAttach": receipt.morAttach,
      "customer": receipt.customer,
      "purpose": receipt.purpose,
      "others": receipt.others,
      "transferNo": receipt.transferNo,
      "bank": receipt.bank,
      "receiptDate": receipt.receiptDate,
      "receiptNotes": receipt.receiptNotes,
      "receiptFrom": receipt.receiptFrom
    }


    console.log(';;;;', payload);
    APIKit.put(RGCA_URLS.updateReceipts, payload,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          getReceipts()
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setReceipt({
            sellingMasterID: '',
            amount: '',
            mor: '',
            customer: '',
            purpose: '',
            others: '',
            morAttach: '',
            transferNo: '',
            bank: '',
            receiptDate: '',
            receiptNotes: '',
            receiptFrom: ''
          })
          handleCloseModal()
          setReceiptID(null)

        }
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }


  const openAddVendor = () => {
    setReceipt({
      sellingMasterID: '',
      amount: '',
      mor: '',
      customer: '',
      purpose: '',
      others: '',
      morAttach: '',
      transferNo: '',
      bank: '',
      receiptDate: '',
      receiptNotes: '',
      receiptFrom: ''
    })
    setOpenTest(false);
    setOpenTest(true);
    setBtn(false)
  }

  let regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  const price_in_words = (price) => {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (price += "", isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
      }
      str = words.reverse().join("")
    } else str = "";
    return str

  }

  return (
    <Page
      className={classes.root}
      title="Receipt">
      <ToastContainer />
      <Container maxWidth={false}>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
          <Alert onClose={handleAlertClose} severity={alertMeg} >
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                Receipts List
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openAddVendor}
                >
                  Create Receipt
                </Button>
              </Grid>
            </Grid>

          </Box>
          {/* <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      searchVendor(e.target.value)
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box> */}
        </div>
        <Box mt={3}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>


                  <TableCell align="center">
                    Receipt No
                  </TableCell >
                  <TableCell align="center">
                    Receipt Date
                  </TableCell>
                  <TableCell align="center">
                    Amount
                  </TableCell>
                  <TableCell align="center">
                    Mode of Receipt
                  </TableCell>
                  <TableCell align="center">
                    Customer Name
                  </TableCell>
                  <TableCell align="center">
                    Purpose
                  </TableCell>
                  <TableCell align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiptList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x =>
                  <TableRow
                    hover
                  // key={emplyoee.id}
                  // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}

                  >


                    <TableCell align="center">
                      {x.receiptID}
                    </TableCell>
                    <TableCell align="center">
                      {moment(x.createdDate).format('dd/MM/yyyy')}
                    </TableCell>
                    <TableCell align="center">
                      {x.amount}
                    </TableCell>
                    <TableCell align="center">
                      {x.morName}
                    </TableCell>
                    <TableCell align="center">
                      {x.customerName}
                    </TableCell>
                    <TableCell align="center">
                      {x.purposeName}
                    </TableCell>


                    <TableCell align="center">
                      <EditOutlinedIcon
                        onClick={() => {
                          editReceipt(x)
                          // navigate('/app/AddEmplyoee', { state: emplyoee })
                        }}
                      />
                      <VisibilityIcon style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCloseModal1()
                          handleReceiptView(x)
                        }
                        } />&nbsp;
                      <DeleteForeverIcon
                        onClick={() => {
                          removeReceipt(x)
                        }}
                      />
                    </TableCell>
                  </TableRow>)}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={receiptList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

      </Container>
      <Formik
        enableReinitialize={true}
        initialValues={
          {
            ...receipt
          }
        }
        onSubmit={(values, { resetForm }) => {

          setIsLoading(true);
          const payload = {
            ...receipt
          }
          payload.projectID = projectDetails.projectID;
          APIKit.post(RGCA_URLS.createReceipts, payload)
            .then((res) => {
              if (res.data.status === 200) {
                setIsLoading(false);
                handleCloseModal();
                getReceipts();
                resetForm(values);
                setReceipt({
                  sellingMasterID: '',
                  amount: '',
                  mor: '',
                  customer: '',
                  purpose: '',
                  others: '',
                  morAttach: '',
                  transferNo: '',
                  bank: '',
                  receiptDate: '',
                  receiptNotes: '',
                  receiptFrom: ''
                })
                toast.success(MESSAGE.receiptCreate, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              } else {
                setIsLoading(false);
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            })
            .catch((e) => {
            });
        }}
        validationSchema={
          Yup.object().shape({
            amount: Yup.string().required(MESSAGE.amount),
            mor: Yup.string().required(MESSAGE.mor),
            customer: Yup.string().required(MESSAGE.customer),
            purpose: Yup.string().required(MESSAGE.purpose),
          })
        }
      >


        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (

          <Dialog aria-labelledby="customized-dialog-title" open={openTest} id="income"

            contentStyle={{
              width: '200%',
              maxWidth: 'none',
            }}
            maxWidth="lg" fullWidth>

            <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
              Receipt Creation
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mt={3} style={{
                  backgroundColor: '#fff',
                  boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                  borderRadius: '5px',
                  padding: '20px'
                }}>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid item md={4} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            // autoOk
                            InputProps={{ disabled: true }}
                            // onBlur={handleBlur}
                            inputVariant="outlined"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date"
                            format="dd/MM/yyyy"
                            disableFuture={true}
                            InputLabelProps={{
                              shrink: true
                            }}
                            name="receiptDate"
                            onChange={(e) => {
                              const p = receipt;
                              p.receiptDate = e;
                              setReceipt({
                                ...p,
                                receiptDate: e
                              });
                            }}
                            value={receipt.receiptDate == '' ? null : receipt.receiptDate}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            // TextFieldComponent={TextFieldComponent}
                            className={classes.calmar}
                          //  error={Boolean(touched.receiptDate && errors.receiptDate)}
                          // helperText={touched.dateOfBirth && errors.dateOfBirth}

                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        // helperText=""
                        label="Amount"
                        name="amount"
                        onBlur={handleBlur}
                        error={Boolean(touched.amount && errors.amount)}
                        helperText={touched.amount && errors.amount}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                            handleChange(e);
                            const p = receipt;
                            p.amount = Number(e.target.value);
                            setReceipt({
                              ...p,
                              amount: Number(e.target.value)
                            });
                          }
                        }}
                        required
                        value={receipt.amount}
                        variant="outlined"
                      />
                      <span style={{ color: "red" }}>{amountError}</span>
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={classes.formControl}
                        error={Boolean(touched.mor && errors.mor)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">Mode Of Receipt</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={receipt.mor}
                          name="mor"
                          label="Mode Of Receipt"
                          onChange={(e) => {
                            handleChange(e);
                            const p = receipt;
                            p.mor = Number(e.target.value);
                            setReceipt({
                              ...p,
                              mor: Number(e.target.value)
                            });
                          }}
                          onBlur={handleBlur}
                        >
                          {lookupData && lookupData.map((receipt, index) => {
                            return <MenuItem value={receipt.lookupID}>{receipt.name}</MenuItem>
                          })}
                        </Select>
                        <FormHelperText>{(errors.mor && touched.mor) && errors.mor}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      {/* <Button style={{ width: "100%", height: "100%" }}
                        variant="contained"
                        component="label"
                      >
                        Mode Of Receipt Attach <input
                          type="file"
                          hidden
                        />
                      </Button> */}
                      <Button
                        style={{ width: '100%', height: '80%' }}
                        variant="contained"
                        component="label"
                      >
                        Mode Of Receipt Attach
                        {' '}
                        <div> <input
                          type="file"
                          hidden
                          onChange={(e) => handleUploadClick(e, 'Modeofreceipt')}
                        />
                        </div>
                      </Button>
                      &nbsp;
                      {/* {receipt.morAttach && (<a style={{
                        color: 'blue',
                        cursor: 'pointer', position: 'absolute', marginTop: '9px'
                      }}
                        href={receipt.morAttach} target="_blank"
                      >{receipt.morAttach.substring(4, 25)}....{receipt.morAttach.split('.').pop()}</a>)} */}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={classes.formControl}
                        error={Boolean(touched.customer && errors.customer)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">Customer</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={receipt.customer}
                          name="customer"
                          label="Customer"
                          onChange={(e) => {
                            handleChange(e);
                            const p = receipt;
                            p.customer = Number(e.target.value);
                            setReceipt({
                              ...p,
                              customer: Number(e.target.value)
                            });
                          }}
                          onBlur={handleBlur}
                        >
                          {allCustomer && allCustomer.map((receipt, index) => {
                            return <MenuItem value={receipt.customerID}>{receipt.name}</MenuItem>
                          })}

                        </Select>
                        <FormHelperText>{(errors.customer && touched.customer) && errors.customer}</FormHelperText>
                      </FormControl>

                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={classes.formControl}
                        error={Boolean(touched.purpose && errors.purpose)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">Purpose</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={receipt.purpose}
                          name="purpose"
                          label="Location"
                          onChange={(e) => {
                            handleChange(e);
                            const p = receipt;
                            p.purpose = Number(e.target.value);
                            setReceipt({
                              ...p,
                              purpose: Number(e.target.value)
                            });
                          }}
                          onBlur={handleBlur}
                        >
                          {lookData && lookData.map((receipt, index) => {
                            return <MenuItem value={receipt.lookupID}>{receipt.name}</MenuItem>
                          })}

                        </Select>

                        <FormHelperText>{(errors.purpose && touched.purpose) && errors.purpose}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {receipt.purpose == 53
                      && (
                        <Grid
                          item
                          md={4}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            // helperText=""
                            label="Others"
                            name="others"
                            onChange={(e) => {
                              handleChange(e);
                              const p = receipt;
                              p.others = e.target.value
                              setReceipt({
                                ...p,
                                others: e.target.value
                              });
                            }}
                            value={receipt.others}
                            variant="outlined"
                          />
                        </Grid>)}

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        // helperText=""
                        label="Transfer No"
                        name="transferNo"
                        //  onBlur={handleBlur}
                        // error={Boolean(touched.transferNo && errors.transferNo)}
                        // helperText={touched.amount && errors.amount}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                            handleChange(e);
                            const p = receipt;
                            p.transferNo = e.target.value;
                            setReceipt({
                              ...p,
                              transferNo: e.target.value
                            });
                          }
                        }}
                        value={receipt.transferNo}
                        variant="outlined"
                      />
                      {/* <span style={{ color: "red" }}>{amountError}</span> */}
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        // helperText=""
                        label="Bank Name"
                        name="bank"
                        //  onBlur={handleBlur}
                        // error={Boolean(touched.transferNo && errors.transferNo)}
                        // helperText={touched.amount && errors.amount}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                            handleChange(e);
                            const p = receipt;
                            p.bank = e.target.value;
                            setReceipt({
                              ...p,
                              bank: e.target.value
                            });
                          }
                        }}
                        value={receipt.bank}
                        variant="outlined"
                      />
                      {/* <span style={{ color: "red" }}>{amountError}</span> */}
                    </Grid>


                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        // helperText=""
                        label="Receipt Notes"
                        name="receiptNotes"
                        //  onBlur={handleBlur}
                        // error={Boolean(touched.transferNo && errors.transferNo)}
                        // helperText={touched.amount && errors.amount}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                            handleChange(e);
                            const p = receipt;
                            p.receiptNotes = e.target.value;
                            setReceipt({
                              ...p,
                              receiptNotes: e.target.value
                            });
                          }
                        }}
                        value={receipt.receiptNotes}
                        variant="outlined"
                      />
                      {/* <span style={{ color: "red" }}>{amountError}</span> */}
                    </Grid>


                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        // helperText=""
                        label="Receipt From"
                        name="receiptFrom"
                        //  onBlur={handleBlur}
                        // error={Boolean(touched.transferNo && errors.transferNo)}
                        // helperText={touched.amount && errors.amount}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                            handleChange(e);
                            const p = receipt;
                            p.receiptFrom = e.target.value;
                            setReceipt({
                              ...p,
                              receiptFrom: e.target.value
                            });
                          }
                        }}
                        value={receipt.receiptFrom}
                        variant="outlined"
                      />
                      {/* <span style={{ color: "red" }}>{amountError}</span> */}
                    </Grid>



                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                    </Grid>

                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

                <Button onClick={(e) => {
                  handleCloseModal(resetForm)
                }} className={classes.button2}>
                  Cancel
                </Button>
                {btn == false ?
                  <Button type='submit' className={classes.button2}>
                    Save
                  </Button>
                  : <Button onClick={updateReceipt} className={classes.button2}>
                    Update
                  </Button>}

              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>


      {/* receipt print view */}

      <Dialog aria-labelledby="customized-dialog-title" open={openTest1} id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none',
        }}
        maxWidth="md" fullWidth>

        <DialogContent dividers style={{ overflow: 'hidden' }}>

          {/* <PictureAsPdfIcon onClick={exportPdf} /> &nbsp; */}
          <PrintIcon onClick={printOrder} />

          <div id='printme'>
            {/* <Grid container spacing={2}>
              
            </Grid> */}
            <div className="row container">
              <div className="col-sm-3" style={{ textAlign: "end" }}>
                <img src="https://rgca.brugu.io/static/logo-rgca.jpg" alt="Company logo" style={{ width: "40%" }} />
              </div>
              <div className="col-sm-9">
                <p>
                  <h3 style={{ textAlign: "center" }}>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                </p>
                <p>
                  <h5 style={{ textAlign: "center" }}>(MPEDA,Ministry of Commerce & Industry Government of India)</h5>
                </p>
                <p>
                  <h5 style={{ textAlign: "center" }}>HEAD OFFICE / UNIT OFFICE</h5>
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    Receipt No :{receiptPrintViewObj?.receiptID}
                  </div>
                  <div className="col-sm-3">
                    {/* Book No : 306 */}
                  </div>
                  <div className="col-sm-3">
                    Date : {receiptPrintViewObj?.receiptDate}
                  </div>
                </div>
              </div>

            </div>
            <div className="row container">
              <div className="col-sm-3" >
                <img src={receiptLogo} alt="Company logo" />
              </div>
              <div className="col-sm-9" style={{ marginTop: "5%" }}>
                <p>
                  Received with thanks from <b>{receiptPrintViewObj.receiptFrom} </b>
                  a sum of Rupees <b>{price_in_words(receiptPrintViewObj?.amount)}</b> by Cash Transfer no. <b>{receiptPrintViewObj?.transferNo}</b> Bank <b>{receiptPrintViewObj?.bank}</b> dated <b>{receiptPrintViewObj?.receiptDate}</b>
                  being <b>{receiptPrintViewObj?.receiptNotes}</b>
                </p>

                <div className="row">
                  <div className="col-sm-12">
                    <i className="fa fa-inr" aria-hidden="true"></i> <span>{receiptPrintViewObj?.amount}</span>
                    <p>(Cheque Subject to Realisation)</p>

                  </div>
                  <div className="col-sm-6">
                    Accountant
                  </div>

                  <div className="col-sm-6">
                    For RAJIV GANDHI CENTRE FOR AQUACULTURE
                  </div>
                </div>
              </div>
            </div>
            {/* <br />
            <table>
              <tr className="top">
                <td colspan="1">
                  <table>
                    <tr>
                    
                      <td>
                        <img src="https://rgca.brugu.io/static/logo-rgca.png" alt="Company logo" style={{ width: "20%" }} />
                        <h3
                          style={{
                            position: "relative",
                            top: "-110px",
                            left: "30%"
                          }}
                        ><b>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</b></h3><br />
                        <h5
                          style={{
                            position: "relative",
                            top: "-136px",
                            left: "35%"
                          }}>(MPEDA,Ministry of Commerce & Industry Government of India)</h5>
                        <br />
                        <h5 style={{
                          position: "relative",
                          top: "-149px",
                          left: "48%"
                        }}>HEAD OFFICE / UNIT OFFICE</h5>
                        <div style={{ textAlign: 'end', marginTop: "-90px" }}>
                          <h4>Receipt No:{receiptPrintViewObj ?.receiptID}</h4>

                          <h4>Date:{receiptPrintViewObj ?.receiptDate}</h4>
                        </div>


                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <p style={{ textIndent: "50px", textAlign: 'start' }}>
              Received with thanks from <b>{receiptPrintViewObj.receiptFrom}</b> a sum of Rupees<b>{receiptPrintViewObj?.amount}</b> by Cash/PoS/Cheque/Draft/Online Transfer No
              <b>{receiptPrintViewObj?.transferNo}</b> Bank <b>{receiptPrintViewObj?.bank}</b> dated <b>{receiptPrintViewObj?.receiptDate}</b> being <b>{receiptPrintViewObj?.receiptNotes}</b>
            </p>
            <br /><br />
            <table>
              <tr className="top">
                <td colspan="2">
                  <table>
                    <tr>
                      <td>
                        <p><b>5,00,000</b></p> <br />
                        <p>Accountant</p>
                      </td>
                      
                    </tr>
                  </table>
                </td>
              </tr>
            </table> */}
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

          <Button onClick={handleCloseModal1} className={classes.button2}>
            Cancel
          </Button>

        </DialogActions>

      </Dialog>


    </Page>
  )

}
export default Receipts;
