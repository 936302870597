import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import Selectoption from 'react-select';
import Address from '../Common/Address';
import Page from 'src/components/Page';
import { checkPermission } from '../../../utils/permission.jsx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useConfirm } from 'material-ui-confirm';
import * as Yup from 'yup';
import './style.css';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { useParams } from 'react-router-dom';
import { getProjectDetails, parseData } from '../Common/utilities';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { ToastContainer, toast } from 'react-toastify';
import CommonTable from '../Common/CommonTable';
import { ETTypes } from '../Common/Types';
import { ETaction } from '../Common/Types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MESSAGE } from 'src/utils/message.js';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { useReactToPrint } from 'react-to-print';
import Loader from 'src/views/loader';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  select: {
    [theme.breakpoints.down('md')]: {
      marginTop: "20px"

    },
    [theme.breakpoints.down('xs')]: {
      marginTop: "20px",
      marginLeft: "40px"

    },
  },
  button: {
    height: '55px',
    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      height: '55px',
      textTransform: 'none',

    },
    [theme.breakpoints.down('xs')]: {
      marginTop: "",
      marginLeft: "50px",
      textAlign: "center ! important",
      height: '30px',
      textTransform: 'none',
    },
  }
}));

// const printOrder = () => {
// const printableElements = document.getElementById('income').innerHTML;
// var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
// const oldPage = document.body.innerHTML;
//   const html = `<html><head><title></title>
//       <style>

// .invoice-box {
//   max-width: 800px;
//   margin: auto;
//   padding: 30px;
//   border: 1px solid #eee;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
//   font-size: 16px;
//   line-height: 24px;
//   font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
//   color: #555;
// }

// .invoice-box table {
//   width: 100%;
//   line-height: inherit;
//   text-align: left;
// }

// .invoice-box table td {

//   vertical-align: top;
// }



// .invoice-box table tr.top table td {
//   padding-bottom: 20px;
// }

// .invoice-box table tr.top table td.title {
//   font-size: 45px;
//   line-height: 45px;
//   color: #333;
// }

// .invoice-box table tr.information table td {
//   padding-bottom: 40px;
// }

// .invoice-box table tr.heading td {
//   background: #eee;
//   border-bottom: 1px solid #ddd;
//   font-weight: bold;
// }

// .invoice-box table tr.details td {
//   padding-bottom: 20px;
// }

// .invoice-box table tr.item td {
//   border-bottom: 1px solid #eee;
// }

// .invoice-box table tr.item.last td {
//   border-bottom: none;
// }

// .invoice-box table tr.total td:nth-child(2) {
//   border-top: 2px solid #eee;
//   font-weight: bold;
// }

// @media only screen and (max-width: 600px) {
//   .invoice-box table tr.top table td {
//       width: 100%;
//       display: block;
//       text-align: center;
//   }

//   .invoice-box table tr.information table td {
//       width: 100%;
//       display: block;
//       text-align: center;
//   }
// }

// /** RTL **/
// .invoice-box.rtl {
//   direction: rtl;
//   font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
// }

// .invoice-box.rtl table {
//   text-align: right;
// }

// .invoice-box.rtl table tr td:nth-child(2) {
//   /* text-align: left; */
// }
//       </style>
//       </head><body>'${printableElements}'</body></html>`;
// console.log(html)
// document.body.innerHTML = html
// window.print();
// document.body.innerHTML = oldPage;
//setIncPrintOpen(false);
// window.location.reload()
// };

const itemInitialValues = {
  items: [
    {
      materialID: null,
      ginQty: null,
      description: '',
      location: '',
      cost: null
    }
  ]
};

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

const GINList = (props) => {
  var valuenumber;
  const confirm = useConfirm();
  const classes = useStyles();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [showContent, setShowContent] = useState(true);
  const [scroll, setScroll] = useState('paper');
  const [isLoading, setIsLoading] = useState(false);
  const [returnQty, setReturnQty] = useState('');
  const [returnPurpose, setReturnPurpose] = useState('')
  const [value, setValue] = React.useState(0); // first tab
  const [moreView, setMoreView] = React.useState(false);
  const [ginList, setGinList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [indentIdData, setIndentIdData] = useState([]);
  const [returnView, setReturnView] = React.useState(false);
  const [indentId, setIndentId] = useState('');
  const [page, setPage] = React.useState(0);
  const [form, setForm] = useState([]);
  const [storeIndentItem, setStoreIndentItem] = useState([]);
  const [createView, setCreateView] = React.useState(false);
  const [emplyoees, setEmplyoees] = useState([]);
  const [inventoryList, setinventoryList] = useState([]);
  const [editIndentView, setEditIndentView] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [openincPrint, setOpenincPrint] = React.useState(false);
  const [returnData, setReturnData] = useState();
  const [storeIndent, setStoreIndent] = useState();
  const [open, setOpen] = React.useState(false);
  const [update, setupdate] = React.useState(false);
  const handleOpen = (item, index) => {
    setCurrentItem({ ...item, index });
    setOpen(true);
  };


  const handleUpdate = () => {
    const updatedItems = [...storeIndentItem];
    console.log("currentItem?.index", currentItem?.index);
    updatedItems[currentItem?.index] = {
      ...currentItem,
      index: undefined // Remove the index property before updating the state
    };
    setStoreIndentItem(updatedItems);
    handleClose();
  };


  const handleClose = () => {
    setOpen(false)

    if (update) {
      toast.success('GIN updated', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

  };
  const [currentItem, setCurrentItem] = useState(null);

  let userData = JSON.parse(localStorage.getItem('userData'));

  const exportPdf = () => {
    html2canvas(document.querySelector('#printme')).then(canvas => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('download.pdf');
    });
  };
  const [columns, setColumns] = useState([
    {
      title: 'GIN Master ID',
      field: 'ginMasterID',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Project Name',
      field: 'projectName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreatedDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Receiver Name',
      field: 'receiverName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      // list: [ETaction.onEdit]
      list: [ETaction.onView, ETaction.onDelete, ETaction.onEdit]
    }
  ]);

  const addItem = () => {
    //   const array = []
    let temp = itemList;
    temp.push({
      materialID: '',
      ginQty: '',
      description: '',
      location: '',
      cost: '',
      available: ''
    });
    setItemList([...temp]);
  };

  const actions = {
    onEdit: (index, row) => {
      setGINEditModelView(!GINEditModelView);
      setSelectedGin(row.ginItem);
    }
  };

  const [itemList, setItemList] = useState([
    {
      materialID: '',
      ginQty: '',
      description: '',
      location: '',
      cost: '',
      available: ''
    }
  ]);

  const [payload, setPayload] = useState({
    projectID: null,
    employeeID: '',
    items: itemList
  });

  const projectData = useSelector(x => x.ProjectInfo);
  const prevIsValid = () => {
    if (form.length === 0) {
      return true;
    }

    const someEmpty = form.some(
      item =>
        item.Material === '' ||
        item.Units === '' ||
        item.Description === '' ||
        item.Location === '' ||
        item.Cost === '' ||
        item.Available === ''
    );

    if (someEmpty) {
      form.map((item, index) => {
        const allPrev = [...form];

        if (form[index].Material === '') {
          allPrev[index].errors.Material = 'Material is required';
        }

        if (form[index].Units === '') {
          allPrev[index].errors.Units = 'Units is required';
        }
        if (form[index].Description === '') {
          allPrev[index].errors.Description = 'Description is required';
        }
        if (form[index].Location === '') {
          allPrev[index].errors.Location = 'Location is required';
        }
        if (form[index].Cost === '') {
          allPrev[index].errors.Cost = 'Cost is required';
        }
        if (form[index].Available === '') {
          allPrev[index].errors.Available = 'Available is required';
        }

        setForm(allPrev);
      });
    }

    return !someEmpty;
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRemoveField = index => {
    let items = itemList;
    items.splice(index, 1);
    setItemList([...items]);
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    selectedGin(prev => prev.filter(item => item !== prev[index]));
  };

  const handleMoreViewClose = () => {
    setMoreView(!moreView);
  };

  const getGINList = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getGIN + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              ginItem: parseData(x.ginItem),
              projectDetails: parseData(x.projectDetails),
              projectName: parseData(x.projectDetails)[0].projectName
            };
          });
          console.log('kkkkkkk', data);
          setIsLoading(false);
          setGinList(data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const getAllEmplyoees = () => {
    APIKit.get(RGCA_URLS.getEmployee)
      .then(res => {
        if (res.data.status === 200) {
          setEmplyoees(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };

  const getMaterials = (i, searchText, typeID, projectID) => {
    let payload = {
      projectID: projectID,
      typeID: typeID,
      searchText: searchText
    };
    APIKit.post(RGCA_URLS.getProductsForIndent, payload)
      .then(res => {
        if (res.data.status === 200) {
          let item = [...form];
          item[i].materialList = [...res.data.data];
          setForm([...item]);
        } else {
        }
      })
      .catch(function (e) { });
  };

  React.useEffect(() => {
    a11yProps(0);
    getAllEmplyoees();
    getGINList();
    getInventoryList();
  }, []);

  const a11yProps = index => {
    if (index == 0) {
    }
    if (index == 1) {
      getGINList();
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const [GINEditModelView, setGINEditModelView] = React.useState(false);
  const [GINNoteModalView, setGINNoteModalView] = React.useState(false);
  const [selectedGin, setSelectedGin] = useState([]);
  const handleGINEditModelView = data => {
    setSelectedGin(data?.items);
    setGINEditModelView(!GINEditModelView);
  };

  const handleGINNoteModalView = data => {
    setSelectedGin(data.items);
    setGINNoteModalView(!GINNoteModalView)
  }
  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };

  const updateGIN = () => {
    let payload1 = {
      ginMasterID: selectedGin[0].ginMasterID,
      projectID: projectDetails.projectID,
      items: selectedGin.map(e => {
        return {
          materialID: e.materialID,
          location: e.location,
          ginQty: Number(e.ginQty),
          description: e.description
        };
      })
    };
    APIKit.put(RGCA_URLS.updateGIN, payload1)
      .then(res => {
        if (res.data.status === 200) {
          setGINEditModelView(!GINEditModelView);
          getGINList();
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const deleteGINItem = data => {
    let payload1 = {
      ginMasterID: data.ginMasterID,
      materialID: data.materialID
    };
    APIKit.put(RGCA_URLS.deleteGINItem, payload1)
      .then(res => {
        if (res.data.status === 200) {
          setGINEditModelView(!GINEditModelView);
          getGINList();
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      minHeight: 55
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(#0000ff, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(#0000ff, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: base => ({
      ...base,
      zIndex: 100
    })
  };

  const handleViewGrn = () => {
    setOpenincPrint(!openincPrint);
  };


  const getIndentId = async () => {
    const Id = projectDetails.projectID;
    const res = await APIKit.get(RGCA_URLS.getIncompleteGin + '/' + projectDetails.projectID);
    if (res.data.status === 200) {
      setIndentIdData(res.data.data)
    }
  }
  useEffect(() => {
    getIndentId();
  }, [])

  const getIndentdata = async () => {
    const Id = projectDetails.projectID;
    const getPayLoad = {
      projectID: Id,
      storeIndentMasterID: indentId
    }
    const res = await APIKit.post(RGCA_URLS.getStoreIndentForGIN, getPayLoad);
    if (res.data.status === 200) {
      setStoreIndent(res.data.data[0])
      setEditIndentView(true);
      const temp = JSON.parse(res.data.data[0].storeIndentItem);
      setStoreIndentItem(temp)
    }
  }

  const updateGinData = async () => {

    const payload = {
      projectID: storeIndent?.ProjectID,
      storeIndentMasterID: storeIndentItem[0]?.storeIndentMasterID,
      employeeID: storeIndent?.employeeID,
      items: storeIndentItem.map(e => {
        return {
          materialID: e.itemID,
          totalQty: e.Qty,
          ginQty: e.approvedQty,
          description: e.comment,
          location: e.location
        }
      }),

      // items: [
      //   {
      //     materialID: storeIndentItem[0].itemID,
      //     ginQty: storeIndentItem[0].approvedQty,
      //     description: storeIndentItem[0].comment,
      //     location: storeIndentItem[0].location
      //   }
      // ]
    }

    console.log("payload", payload);
    setIsLoading(true)

    await APIKit.post(RGCA_URLS.createGIN, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEditIndentView(!editIndentView);
          setShowContent(!showContent);
          toast.success(MESSAGE.ginPost, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getGINList()
          getIndentId()
        }
        else {
          setIsLoading(false)
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.error(e);
      });
  }

  const returnCreate = async () => {
    const payload = {
      projectID: returnData.projectID,
      storeIndentMasterID: returnData.storeIndentMasterID,
      employeeID: returnData.employeeID,
      items: [
        {
          materialID: returnData.ginItem[0].materialID,
          ginQty: returnData.ginItem[0].ginQty,
          ginItemID: returnData.ginItem[0].ginItemID,
          returnQty: returnQty,
          returnPurpose: returnPurpose
        }
      ]
    }
    await APIKit.post(RGCA_URLS.returnGoodsGin, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setReturnView(!returnView);
          getGINList();
          toast.success(MESSAGE.returnGoods, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        else {
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
        console.error(e)
      });

  }
  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });

  return (
    <Page className={classes.root} title="GIN">
      <Loader isLoading={isLoading} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        {props?.permission == 'view_GIN' && (checkPermission('create_GRN') || userData?.isAdmin == 1) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowContent(!showContent);
              setEditIndentView(false);
              setIndentId('');
            }}
          >
            {showContent ? 'Add GIN' : 'Back'}
          </Button>
        )}
      </Box>
      {editIndentView === false ?
        <>
          {showContent ?
            <>
              <Typography>
                <h2 style={{ color: '#394671' }}>GIN List</h2>
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <div style={{ textAlign: 'center' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">GIN Master ID</TableCell>
                            <TableCell align="left">Project Name</TableCell>
                            <TableCell align="left">Created Date</TableCell>
                            <TableCell align="left">Created By</TableCell>
                            <TableCell align="left">Receiver Name</TableCell>
                            <TableCell align="left">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ginList && ginList.length != 0 ? (ginList.slice(page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                            .map((x, i) => {
                              return (
                                <TableRow hover role="checkbox">
                                  <TableCell align="left">{x.ginMasterID}</TableCell>
                                  <TableCell align="left">{x.projectName}</TableCell>
                                  <TableCell align="left">{moment(x.CreatedDate).format('DD-MM-YYYY')} </TableCell>
                                  <TableCell align="left">{x.CreatedByName}</TableCell>
                                  <TableCell align="left">{x.receiverName}</TableCell>
                                  <TableCell align="left">
                                    {props?.permission == 'view_GIN' &&
                                      <VisibilityIcon style={{ cursor: 'pointer', marginRight: '10px' }}
                                        onClick={() => {
                                          setGINEditModelView(!GINEditModelView);
                                          setSelectedGin(x.ginItem);
                                        }}
                                      />}
                                    {props?.permission == 'view&edit_GIN' &&
                                      <KeyboardReturnIcon style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setReturnView(!returnView);
                                          setReturnData(x);
                                          console.log(x);
                                        }}
                                      />}
                                    {returnData ?
                                      <Dialog
                                        aria-labelledby="customized-dialog-title"
                                        contentStyle={{
                                          width: '100%',
                                          maxWidth: 'none'
                                        }}
                                        maxWidth="lg"
                                        fullWidth
                                        open={returnView}
                                        id="income"
                                      >
                                        <DialogTitle
                                          id="customized-dialog-title"
                                          onClose={handleViewGrn}
                                          style={{ backgroundColor: '#394671', color: '#fff' }}
                                        >
                                          Return Goods
                                        </DialogTitle>
                                        <DialogContent dividers style={{ overflowX: 'hidden' }}>
                                          <div
                                            className="invoice-box"
                                            style={{ maxWidth: 'none' }}
                                            id="printme"
                                          >
                                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                              <p style={{ fontWeight: "bold" }}>Store Indent Master ID : {returnData?.storeIndentMasterID}</p>
                                              <p style={{ fontWeight: "bold" }}>GIN Master ID : {returnData?.ginMasterID} </p>
                                              <p style={{ fontWeight: "bold" }}>Quantity :  {returnData?.ginItem?.length > 0 && returnData?.ginItem[0]?.ginQty}</p>
                                              <p style={{ fontWeight: "bold" }}>Location :  {returnData?.ginItem?.length > 0 && returnData?.ginItem[0]?.location} </p>
                                            </div>
                                            <br />
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly" }}>
                                              <div style={{ display: "flex", alignItems: 'center' }} >
                                                <p style={{ fontWeight: "bold" }}>Return Qty : </p>
                                                <TextField
                                                  helperText=""
                                                  label="Return Qty"
                                                  required
                                                  value={returnQty}
                                                  variant="outlined"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === '' ||
                                                      (regEx.numbersOnly.test(e.target.value) &&
                                                        e.target.value <= Number(returnData?.ginItem[0]?.ginQty))
                                                    ) {
                                                      setReturnQty(e.target.value);
                                                    }
                                                  }}
                                                />
                                              </div>
                                              <div style={{ display: "flex", alignItems: 'center' }}>
                                                <p style={{ fontWeight: "bold" }}>Return Purpose : </p>
                                                <TextField
                                                  helperText=""
                                                  label="Return Purpose"
                                                  required
                                                  variant="outlined"
                                                  onChange={(e) => { setReturnPurpose(e.target.value) }}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                        </DialogContent>
                                        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                                          <Button disabled={returnQty === ''} onClick={() => { returnCreate() }}>Submit</Button>
                                          <Button onClick={() => { setReturnView(!returnView); }}>Close</Button>
                                        </DialogActions>
                                      </Dialog>
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow hover role="checkbox" key={1}>
                              <TableCell colSpan={10} align="center" key={2}>
                                {'No Data Found'}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {ginList && ginList?.length != 0 && ginList?.length > 10 && (
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={ginList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              {/* LOCATION EDIT */}
              {/* MODAL */}
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={GINEditModelView}
                id="income"
                contentStyle={{ width: '200%', maxWidth: 'none' }}
                maxWidth="lg"
                fullWidth
              >
                <Grid ref={htmlContentRef} id="myHtmlContent" >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleGINEditModelView}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      width: '900px !important'
                    }}
                  >
                    GIN View
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={3}>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <TableContainer >
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Material</TableCell>
                                  <TableCell align="left">UOM</TableCell>
                                  <TableCell align="left">Total Quantity</TableCell>
                                  <TableCell align="left">Received Quantity</TableCell>
                                  <TableCell align="left">Description of usages</TableCell>
                                  <TableCell align="left">Location</TableCell>
                                  <TableCell align="left">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedGin &&
                                  selectedGin.map((x, index) => {
                                    return (
                                      <TableRow hover role="checkbox">
                                        <TableCell align="left">{x.itemName}</TableCell>
                                        <TableCell align="left">{x.uom}</TableCell>
                                        <TableCell align="left">{x.totalQty}</TableCell>
                                        <TableCell align="left">{x.ginQty}</TableCell>
                                        <TableCell align="left">{x.description}</TableCell>
                                        <TableCell align="left">{x.location}</TableCell>
                                        <TableCell align="left">
                                          {selectedGin.length > 1 ? (
                                            <DeleteOutlineIcon
                                              onClick={e => {
                                                confirm({
                                                  description:
                                                    'You want to delete the record ?'
                                                })
                                                  .then(() => {
                                                    deleteGINItem(x);
                                                  })
                                                  .catch(() =>
                                                    console.log('Deletion cancelled.')
                                                  );
                                              }}
                                              style={{ cursor: 'pointer' }}
                                            />
                                          ) : '-'}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                    </Grid>

                  </DialogContent>
                  <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button
                      onClick={() => { handleGINEditModelView() }}
                      className={classes.button2}
                    >
                      Cancel
                    </Button>
                    {/* <Button onClick={printOrder}>Print</Button> */}
                  </DialogActions>
                </Grid>
              </Dialog>
              {/* END LOCATION MODAL */}
            </>
            :
            <Grid container spacing={3}>
              <Grid container
                direction="row"
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Box sx={{ width: "" }}>
                  <Grid item className={classes.select}>
                    <Selectoption
                      isDisabled={!indentIdData?.length}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      placeholder="Search Store Indent ID"
                      onChange={e => {
                        setIndentId(e.value)
                        valuenumber = e.value;
                      }}
                      value={valuenumber}
                      options={indentIdData.map(e => {
                        return {
                          value: e.storeIndentMasterID,
                          label: e.storeIndentMasterID
                        };
                      })}
                    />
                  </Grid>
                </Box>
                <Grid item>
                  <Button
                    disabled={!indentId}
                    className={classes.button}
                    // style={{
                    //   height: '55px',
                    //   textTransform: 'none',
                    //   marginTop: '0px'
                    // }}
                    color="primary"
                    onClick={e => {
                      getIndentdata();
                    }}
                    variant="contained"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>}
        </>
        :
        storeIndentItem && storeIndentItem?.length > 0 ?
          <Grid container>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Item Name</TableCell>
                      <TableCell align="left">Total Request Quantity</TableCell>
                      <TableCell align="left">Total Approved Quantity</TableCell>
                      <TableCell align="left">UOM</TableCell>
                      <TableCell align="left">Location</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeIndentItem &&
                      storeIndentItem.map((x, index) => {
                        return (
                          <TableRow>
                            <TableCell align="left">{x.name}</TableCell>
                            <TableCell align="left">{x.Qty} </TableCell>
                            <TableCell align="left">{x.approvedQty} </TableCell>
                            <TableCell align="left">{x.uom}</TableCell>
                            <TableCell align="left">{x.location}</TableCell>
                            <TableCell align="left">{x.comment}</TableCell>
                            <TableCell align="left">
                              <EditOutlinedIcon onClick={() => {
                                handleOpen(x, index)
                              }} style={{ cursor: "pointer" }} />
                              {currentItem && (
                                <Dialog
                                  aria-labelledby="customized-dialog-title"
                                  open={open}
                                  id="income"
                                  contentStyle={{
                                    width: '200%',
                                    maxWidth: 'none'
                                  }}
                                  maxWidth="lg"
                                  fullWidth
                                >
                                  <DialogTitle
                                    id="customized-dialog-title"
                                    onClose={handleGINEditModelView}
                                    style={{
                                      backgroundColor: '#394671',
                                      color: '#fff',
                                      width: '900px !important'
                                    }}
                                  >
                                    GIN Edit
                                  </DialogTitle>
                                  <DialogContent dividers style={{ width: '900px !important' }}>
                                    <Grid container spacing={3}>
                                      {/* ---------------------------------------------------------------------------------------- */}
                                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                          <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="left">Material</TableCell>
                                                  <TableCell style={{ width: '8%' }} align="left">
                                                    UOM
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    Description of usages
                                                  </TableCell>
                                                  <TableCell align="left">Total Requested Quantiy</TableCell>
                                                  <TableCell align="left">Total Approved Quantiy</TableCell>
                                                  <TableCell align="left">Location</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow hover role="checkbox">
                                                  <TableCell align="left">{currentItem?.name}</TableCell>
                                                  <TableCell align="left">{currentItem?.uom}</TableCell>
                                                  <TableCell align="left">{currentItem?.comment}</TableCell>
                                                  <TableCell align="left">{currentItem?.Qty}</TableCell>
                                                  <TableCell align="left">
                                                    <TextField
                                                      helperText=""
                                                      label="Quantity"
                                                      name="material"
                                                      required
                                                      // onChange={e => {
                                                      //   if (regEx.noSpecialChar.test(e.target.value)) {
                                                      //     let item = storeIndentItem;
                                                      //     if (Number(item[0].approvedQty) != Number(e.target.value)) {
                                                      //       setupdate(true)

                                                      //     }
                                                      //     item[0].approvedQty = e.target.value;
                                                      //     setStoreIndentItem([...item]);
                                                      //   }
                                                      // }}

                                                      // onChange={(e) => setCurrentItem({ ...currentItem, approvedQty: e.target.value })}
                                                      onChange={(e) => {
                                                        const newValue = currentItem.Qty <= e.target.value ? currentItem.Qty : e.target.value;
                                                        setCurrentItem({ ...currentItem, approvedQty: newValue });
                                                      }}
                                                      value={currentItem?.approvedQty}
                                                      variant="outlined"
                                                    />
                                                  </TableCell>

                                                  <TableCell align="left">
                                                    <TextField
                                                      helperText=""
                                                      label="Location"
                                                      name="material"
                                                      disabled
                                                      // onChange={e => {
                                                      //   let item = storeIndentItem;
                                                      //   item[0].location = e.target.value;
                                                      //   setStoreIndentItem([...item]);
                                                      // }}
                                                      onChange={(e) => setCurrentItem({ ...currentItem, location: e.target.value })}
                                                      required
                                                      value={currentItem?.location}
                                                      variant="outlined"
                                                    />
                                                  </TableCell>
                                                  <TableCell align="center">
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </div>
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'end' }} >
                                          <Button
                                            color="primary"
                                            onClick={handleUpdate}
                                            variant="contained"
                                            style={{ marginRight: "20px" }}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            color="primary"
                                            onClick={() => { setOpen(false) }}
                                            variant="contained"
                                          >
                                            Close
                                          </Button>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </DialogContent>
                                </Dialog>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={updateGinData}
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid> : <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Material</TableCell>
                  <TableCell style={{ width: '8%' }} align="center">
                    UOM
                  </TableCell>
                  <TableCell align="center">
                    Description of usages
                  </TableCell>
                  <TableCell align="center">Number of units</TableCell>
                  <TableCell align="center">Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody><TableCell colSpan={4} style={{ textAlign: "center" }}>NO DATA FOUND</TableCell></TableBody></Table></TableContainer>}
    </Page>
  );
};

export default GINList;
