import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import './style.css'
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { getNavigationData } from '../../Redux/Common/action';
import { useDispatch } from 'react-redux';
import APIKit from "../../utils/APIKit";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));




const EditProject = (props) => {
  const { params } = props;
  const classes = useStyles();
  const [allLocations, setallLocations] = useState([])
  const [allLands, setallLands] = useState([])
  const [allSeedProduction, setallSeedProduction] = useState([])
  const [seedinputList, setSeedInputList] = useState([{ SeedProduction: 0, seedName: '', seedId: 0 }]);
  // const [seedinputList, setSeedInputList] = useState([]);
  const [landinputList, setLandInputList] = useState([{ LandType: 0, LandArea: 0 }]);
  const [projectForm, setProjectForm] = React.useState({
    ProjectId: null,
    ProjectName: '', ProjectInfo: '', MobileNumber: "", LocationInfo: 0, SeedProductionType: [], LandDetails: []
  });
  const [open, setOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [openLoader, setOpenLoader] = React.useState(false);
  const [inlineEdit, setInlineEdit] = React.useState(0);

  const navigationData = useSelector(x => x.NavigationData.navigationData);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...seedinputList];
    list[index][name] = value;
    setSeedInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (seed, index) => {
    const list = [...seedinputList];
    list.splice(index, 1);
    setSeedInputList(list);
    removeSeed(seed)
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSeedInputList([...seedinputList, { SeedProduction: 0, seedId: 0 }]);
  };


  //land details
  // handle input change
  const handleLandInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...landinputList];
    list[index][name] = value;
    setLandInputList(list);
  };

  // handle click event of the Remove button
  const handleLandRemoveClick = (index, land) => {
    const list = [...landinputList];
    list.splice(index, 1);
    setLandInputList(list);
    removeLand(land)
  };

  // handle click event of the Add button
  const handleLandAddClick = () => {
    setLandInputList([...landinputList, { LandType: 0, LandArea: 0 }]);
  };
  // end


  const getLocations = () => {

    APIKit.get('/' + APP_URLS.getAvailableLocations,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.status === 200) {
          let allLocation = [
            {
              LocationInfo: navigationData.LocationInfo,
              LocationName: navigationData.Address
            },
            ...res.data.data
          ]
          setallLocations(allLocation)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const getLands = () => {

    APIKit.get('/' + APP_URLS.LandType,
      // { headers: { 'Content-Type': 'application/json', 'Authorization':"bearer "+getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.status === 200) {
          setallLands(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }
  const getSeedProduction = () => {
    APIKit.get('/' + APP_URLS.seedProductionType,
      // { headers: { 'Content-Type': 'application/json', 'Authorization':"bearer "+getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.status === 200) {
          setallSeedProduction(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const removeLand = (data) => {
    var test = projectForm;
    test.LandDetails = landinputList;
    APIKit.post('/' + APP_URLS.removeLandDetails, {
      "ProjectId": test.ProjectId,
      "LandId": data.LandId

    }
    )
      .then((res) => {
        if (res.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const removeSeed = (data) => {
    var test = projectForm;
    test.LandDetails = landinputList;
    APIKit.post('/' + APP_URLS.removeSeedDetails, {
      "ProjectId": test.ProjectId,
      "seedId": data.seedId

    }
    )
      .then((res) => {
        if (res.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const { state } = useLocation();

  const navigate = useNavigate();
  let query = window.location.pathname.split("/");
  const dispatch = useDispatch();

  useEffect(() => {
    if (query) {
      if (navigationData && navigationData.SeedProduction) {

        // {
        //   LocationInfo: 1003
        //   LocationName: "hyderabad,secunderabad,785412369"
        // }
        setSeedInputList(navigationData.SeedProduction);
        setLandInputList(navigationData.LandDetails);
        setProjectForm({
          ProjectId: navigationData.ProjectId, ProjectName: navigationData.ProjectName,
          ProjectInfo: navigationData.ProjectInfo, LocationInfo: navigationData.LocationInfo,
          SeedProductionType: navigationData.SeedProductionType, LandDetails: navigationData.LandDetails, MobileNumber: navigationData.MobileNumber
        })

      }
    } else {
      // window.location.reload();
      //  dispatch(getNavigationData({}))
      //  setSeedInputList([]);
      //  setLandInputList([]); '/app/seabasehatchery/'+y.ProjectId
      //  setProjectForm({
      //    ProjectId:null,ProjectName: '', ProjectInfo: 0, LocationInfo: 0, SeedProductionType: [], LandDetails: []
      //  })
    }
    getLands()
    getSeedProduction()
    getLocations()

  }, []);
  const addProject = () => {
    handleToggleLoader()
    var test = projectForm;
    test.SeedProductionType = seedinputList;
    test.LandDetails = landinputList;
    setProjectForm(test);
    if (test.ProjectId == null) {
      var url = APP_URLS.addProject
    } else {
      var url = APP_URLS.updateProjectDetails
    }
    APIKit.post('/' + url, projectForm,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          props.handleEditProjectClose();
          handleCloseLoader()
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setOpen(true);
          setInlineEdit(0)
          navigate('/app/Projects/' + query[query.length - 1], { replace: true });
          // setProjectForm({ ProjectName: '', ProjectInfo: '', LocationInfo: 0, SeedProductionType: [{ SeedProduction: null }], LandDetails: [{ LandType: null, LandArea: 0 }] })
        }
        else {
          props.handleEditProjectClose();
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setOpen(true);
        }
      })
      .catch(function (e) {
        props.handleEditProjectClose();
        handleCloseLoader()
        setAlertMeg('error');
        setErrorMeg("Error");
        setOpen(true);
      })

  }
  const inlineedit = (dand, i) => {
    setInlineEdit(i + 1)
  }

  const Close = () => {
    props.handleEditProjectClose();
  }
  return (
    <>
      <DialogContent>

        <Page
          className={classes.root}
          title="Depositors">
          <Container maxWidth={false}>
            <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
              <CircularProgress style={{ color: '#fff' }} />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  >
              <Alert onClose={handleClose} severity={alertMeg} >
                {errorMeg}
              </Alert>
            </Snackbar>
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                  Edit Project
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>
            </Box>
            <Box mt={3} style={{
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
              borderRadius: '5px',
              padding: '20px'
            }}>

              <div >
                <Grid
                  container
                  spacing={3}
                  style={{ "textAlign": "left" }}
                >

                  <Grid item lg={6} xs={12} style={{ "marginTop": "0%" }}>
                    <TextField
                      fullWidth
                      label="Project Name"
                      name="projectName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        let p = projectForm;
                        p.ProjectName = e.target.value
                        setProjectForm({
                          ...p
                        })
                      }}
                      required
                      value={projectForm.ProjectName}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Address</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"

                        name="LocationInfo"
                        onChange={(e) => {
                          let p = projectForm;
                          p.LocationInfo = e.target.value
                          setProjectForm({
                            ...p
                          })
                        }}
                        required
                        value={projectForm.LocationInfo}
                        label="Address"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {allLocations && allLocations.length > 0 && allLocations.map((loc, index) => {
                          return <MenuItem value={loc.LocationInfo}>{loc.LocationName}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12} style={{ "marginTop": "0%" }}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      name="MobileNumber"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        let p = projectForm;
                        p.MobileNumber = e.target.value
                        setProjectForm({
                          ...p
                        })
                      }}
                      required
                      value={projectForm.MobileNumber}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        let p = projectForm;
                        p.ProjectInfo = e.target.value
                        setProjectForm({
                          ...p
                        })
                      }}
                      value={projectForm.ProjectInfo}
                      label="Project Info" variant="outlined"
                      multiline
                      rows={2}
                      rowsMax={4}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>
                  <Grid item lg={3} md={4} sm={6}></Grid>


                </Grid>
              </div>

            </Box>


            <Box mt={3} style={{
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
              borderRadius: '5px',
              padding: '20px'
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                  Land Details
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>


              <TableContainer component={Paper}>
                <Table id="table-to-xls" aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f1d2d2" }}>
                    <TableRow>

                      <TableCell>
                        S.No
                      </TableCell>
                      <TableCell >
                        Land Type
                      </TableCell>

                      <TableCell>
                        Land Area

                      </TableCell>

                      <TableCell>
                        Edit
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {landinputList && landinputList.map((land, index) => (
                      <TableRow
                        hover
                        key={land.LandType}
                      >
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {inlineEdit == index + 1 ?
                            <>
                              <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Land Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  // value={LandType}
                                  name="LandType"
                                  onChange={e => handleLandInputChange(e, index)}
                                  label="Land Type"
                                  value={land.LandType}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {allLands && allLands.length > 0 && allLands.map((land, index) => {
                                    return <MenuItem value={land.SubCategoryId} key={land.SubCategoryId}>{land.SubCategoryName}</MenuItem>
                                  })}

                                </Select>
                              </FormControl>
                            </>
                            : <>{land.LandTypeName}</>}
                        </TableCell>
                        <TableCell className={classes.wordbreak}>

                          {inlineEdit == (index + 1) ?
                            <>

                              <TextField style={{ width: '50%' }}
                                fullWidth
                                name="LandArea"
                                onChange={e => handleLandInputChange(e, index)}
                                value={land.LandArea}
                                label="Land Area" variant="outlined"

                              />
                            </>
                            : <>{land.LandArea}</>}
                          &nbsp;  &nbsp;
                          <span>Acres</span>
                        </TableCell>

                        <TableCell>
                          <EditIcon onClick={() => inlineedit(land, index)} />
                          {/* <DeleteForeverIcon onClick={()=>removeLand(land,index)}/> */}
                          <DeleteForeverIcon onClick={() => handleLandRemoveClick(index, land)} />

                          {inlineEdit == (index + 1) ?
                            <>
                              <SaveIcon onClick={() => addProject()} />

                            </>
                            : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>

            <Box mt={3} style={{
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
              borderRadius: '5px',
              padding: '20px'
            }}>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                  Seed Production
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>


                </Grid>
              </Grid>
              {seedinputList.map((x, i) => {
                return (
                  <div style={{ marginLeft: "20%" }}>
                    <Grid
                      container
                      spacing={3}
                      style={{ "textAlign": "left" }}
                    >
                      <Grid item xs={10} lg={4} style={{ "marginTop": "1%" }}>
                        Seed Production Info :
                      </Grid>
                      {/* <Grid item xs={2} lg={0}></Grid> */}
                      <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                          <InputLabel id="demo-simple-select-outlined-label">Seed Production Info</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="SeedProduction"
                            value={x.SeedProduction}
                            onChange={e => handleInputChange(e, i)}
                            label="Department"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {allSeedProduction && allSeedProduction.length > 0 && allSeedProduction.map((seed, index) => {
                              return <MenuItem value={Number(seed.SubCategoryId)} key={seed.SubCategoryId}>{seed.SubCategoryName}</MenuItem>
                            })}

                          </Select>
                        </FormControl>

                      </Grid>
                      <Grid item lg={2}>
                        {seedinputList.length !== 1 &&
                          <DeleteForeverIcon onClick={() => handleRemoveClick(x, i)} />
                        }
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Box>
            <Box mt={3}>

              <Grid
                item
                md={12}
                xs={12}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >

                  {/* <Button
                color="primary"
                variant="contained"
                onClick={addProject}
              >
                Update
              </Button> */}
                </Box>
              </Grid>
            </Box>

            {/* dialog */}




          </Container>
        </Page>
      </DialogContent>

      <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
        <Button onClick={Close} className={classes.button2}>
          Cancel
        </Button>
        <Button onClick={addProject} className={classes.button2} >
          Update
        </Button>
      </DialogActions>

    </>
  )

}
export default EditProject;
