import * as React from 'react';
import { Typography, Box } from '@material-ui/core';

const BankSingleline = ({ details = null }) => {
  if (!details) {
    return null;
  }

  const { bankName,
    bankAccountNo,
    bankBranch,
    bankIFSC,
    bankAccountType } = JSON.parse(details)[0];
  return (
    <Box sx={{ width: '100%', maxWidth: 500 }}>
      <Typography variant="body1" gutterBottom style={{ color: '#ba88c3' }}>
        <b> Bank </b> : {bankName}
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
      <b> Acc No  </b>: {bankAccountNo}
      </Typography>
      <Typography variant="body1" gutterBottom>
      <b> Branch  </b>: {bankBranch}
      </Typography>
      <Typography variant="body1" gutterBottom>
      <b> IFSC  </b>: {bankIFSC}
      </Typography>
      <Typography variant="body1" gutterBottom>
      <b> Account Type  </b>: {bankAccountType}
      </Typography> */}

    </Box>
  );
}

export default BankSingleline;
