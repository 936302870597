import React, { useState, useEffect, useRef } from "react";
import {
  Grid, makeStyles, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Dialog, DialogContent, Box, TextField, Paper, InputLabel, FormControl, MenuItem, Select,
} from "@material-ui/core";
import Page from "src/components/Page";
import * as XLSX from "xlsx";
import { useBarcode } from "react-barcodes";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ETTypes, ETaction } from "../Common/Types";
import APIKit from "../../../utils/APIKit";
import { RGCA_URLS } from "src/utils/url";
import { getProjectDetails } from "../Common/utilities";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "src/views/loader";
import { MESSAGE } from "src/utils/message";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  isFileAllowed,
  validateEmail,
  isFileSizeAllowed,
} from "src/views/Project/Common/fileUpload";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MuiDialogActions from "@material-ui/core/DialogActions";
import Tooltip from '@mui/material/Tooltip';
import { dateFormate } from "../Common/utilities";
import CommonSelect from "../Common/CommonSelect";
import { Stack } from "@mui/material";
import { checkPermission } from "../../../utils/permission.jsx";
import { saveAs } from "file-saver";
import xlx from "../../../Assets/SampleFar.xlsx";
import { useReactToPrint } from 'react-to-print';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ClearIcon from '@mui/icons-material/Clear';
import AssignHistory from "./assignHistory";
import { History } from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  icon: {
    fill: "green",
  },
  text: {
    fill: "white",
  },
  stepIcon: {
    color: "#fff",
  },
  field: {
    margin: theme.spacing(2),
    "@media (min-width: 320px)": {
      margin: theme.spacing(-1),
    },
  },
  calmar1: {
    [theme.breakpoints.up("md")]: {
      width: "380px",
    },
    [theme.breakpoints.down("md")]: {
      width: "645px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },
}));

function App(props) {
  console.log("proooo", props);
  const printData = props?.singleFAR?.farMasterID?.replace(/-/g, '');

  const { inputRef } = useBarcode({
    value: printData,
    options: {
      text: printData,
    },
  });

  return <svg ref={inputRef} />;
}

function App1(props) {
  console.log("props", props);

  const printData = props?.getsingleFAR?.farMasterID?.replace(/-/g, '');
  const { inputRef } = useBarcode({
    value: printData,
    options: {
      displayValue: true,
      background: "#fff",
      marginTop: "20px",
    },
  });

  return <svg ref={inputRef} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FAR = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [moreView, setMoreView] = React.useState(false);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  console.log('ppp', projectDetails.projectID);
  const [FARList, setFARList] = useState([]);
  const [singleFAR, setSingleFAR] = useState({});
  const [getsingleFAR, getsetSingleFAR] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [openTest1, setOpenTest1] = React.useState(false);
  const [addFarItem, setAddFarItem] = React.useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [emplyoees, setEmplyoees] = useState([]);
  const [bulk, setBulk] = useState("");
  const [bulkFIle, setBulkFile] = useState("");
  const [warrantyAttach, setWarrantyAttach] = useState("");
  const [insuranceAttach, setInsuranceAttach] = useState("");
  const [farData, setFarData] = useState("");
  const [openBulk, setOpenBulk] = React.useState(false);
  const [errRecords, setErrRecords] = useState([])
  const [addSingleFAR, setAddSingleFAR] = useState({
    materialList: [],
    val: {},
    materialID: "",
    categoryName: "",
    subCategoryName: "",
    name: "",
    uniqueID: "",
    location: "",
    projectID: null,
    purchaseValue: "",
    itemQty: "",
    warrantyGranteeDetails: "",
    warrantyAttach: "",
    estimatedYears: "",
    rateOfDepreciation: "",
    methodOfDepreciation: "",
    estimatedResidualValue: "",
    detailsOfDepreciation: "",
    accumulatedDepreciation: "",
    insuranceDate: "",
    insuranceAttach: "",
    note: "",
  });
  const [isAssign, setIsAssign] = useState(false);
  // const [assignedData, setAssignedData] = useState("");
  const [assignedPayload, setAssignedPayload] = useState({
    projectID: projectDetails.projectID,
    CategoryName: '',
    SubCategory: '',
    itemName: '',
    ReceivedBy: '',
    AssignedTo: "",
    AssignedDate: null,
    farMasterID: "",
    comment: ''
  });

  const [isAssignHistory, setIsAssignHistory] = useState(false)
  const [assignHistoryData, setAssignHistoryData] = useState([]);
  const [isDisposed, setIsdisposed] = useState(false)
  const [disposedData, setDisposedData] = useState({
    categoryName: '',
    subCategoryName: '',
    name: '',
    itemQuty: '',
    comment: '',
    status: '',
    farMasterID: ''
  })

  let userData = JSON.parse(localStorage.getItem("userData"));
  const handleCloseBulk = () => {
    setOpenBulk(false);
  };
  const [value, setValue] = React.useState(0); // first tab

  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });
  const getSingleData = (data) => {
    console.error(data);
    setIsLoading(true);
    APIKit.post(`${RGCA_URLS.getSingleFar}`, {
      projectID: projectDetails.projectID,
      grnItemID: 1,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          getsetSingleFAR(res.data.data[0]);
          setOpenTest1(!openTest1);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((e) => { });
  };

  useEffect(() => {
    getAllProjects()
    getMaterials();
    getFAR();
  }, [navigate]);
  // useEffect(()=>{
  //   if(projectDetails.projectID){
  //   getEmployeeBasedProjectsData(projectDetails.projectID)
  //   }
  // },[])

  const editFar = (index, row) => {
    console.log("row...", row);
    setSingleFAR(row);
    setWarrantyAttach(row.warrantyAttach);
    setInsuranceAttach(row.insuranceAttach)
    FARList.forEach((e, i) => {
      if (i == index) {
        console.log(e);
        // setSingleFAR(e);
      }
    });
    console.log(singleFAR.uniqueID, "uniqueID");
    setMoreView(!moreView);
  };

  const handleCloseModal1 = (data) => {
    setOpenTest1(!openTest1);
  };

  const bulkUpload = () => {
    setBulk("");
    setBulkOpen(!bulkOpen);
  };

  const handleClose = () => {
    setAddFarItem(!addFarItem);
  };

  const handleMoreViewClose = (resetForm = null) => {
    setMoreView(!moreView);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  console.log("projectDetails", projectDetails)
  console.log("userData", userData);

  const a11yProps = index => {
    if (index == 0) {
    }
    if (index == 1) {
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  const getFAR = () => {

    const payload = {
      "projectID": projectDetails.projectID,
      "fromDate": "",
      "toDate": ""
    }
    setIsLoading(true);
    APIKit.post(`${RGCA_URLS.getFAR}`, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          let data = res.data.data.filter((x) => {
            return {
              ...x,
            };
          });
          console.log("getFAR", data);
          // setWarrantyAttach(data.warrantyAttach);
          // setInsuranceAttach(data.insuranceAttach)
          setFARList(data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((e) => { });
  };

  const handleUpload = async (e) => {
    if (!isFileAllowed(e.target.files[0]?.name, "bckUpload")) {
      toast.error(MESSAGE.fileFormat, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!isFileSizeAllowed(e.target.files[0]?.size)) {
      toast.error(MESSAGE.fileSze, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let arrayBuffer;
    let parseExcelData;
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      parseExcelData = await XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
      });
    };
    setTimeout(() => {
      if (parseExcelData?.length > 0) {
        setBulk(file.name);
        setBulkFile(file);
      } else {
        toast.error(MESSAGE.file_empty, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }, 1000);
  };

  const bulUploadDatas = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", bulkFIle);
    APIKit.post(RGCA_URLS.bulkUploadFar, formData)
      .then((res) => {
        if (res.data.status == 200) {
          setIsLoading(false);
          setBulkOpen(!bulkOpen);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (res.data.status == 401) {
          setIsLoading(false);
          setErrRecords(res.data.error);
          setOpenBulk(true);
        }
        else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onChangeInput = (value, index) => {
    console.log(value);
    let item = { ...addSingleFAR };
    item.val = value;
    item.materialID = value.value;
    item.name = value.label;
    item.categoryName = addSingleFAR.materialList.find(
      (x) => x.itemID == value.value
    ).categoryName;
    item.subCategoryName = addSingleFAR.materialList.find(
      (x) => x.itemID == value.value
    ).subCategoryName;
    setAddSingleFAR({ ...item });
  };

  const getMaterials = (i, typeID, projectID) => {
    let payload = {
      // projectID: projectDetails.projectID,
      typeID: 2,
    };
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then((res) => {
        if (res.data.status === 200) {
          addSingleFAR.materialList = res.data.data;
          setAddSingleFAR({ ...addSingleFAR });
        } else {
        }
      })
      .catch(function (e) { });
  };

  const handleUploadClick = (event, type, wat) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("files", event.target.files[0], event.target.files[0].name);
    if (type == "Insurance") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setInsuranceAttach(res.data.data);
            setAddSingleFAR({
              ...addSingleFAR,
              insuranceAttach: res.data.data,
            });
            console.log(res.data.data, "in");
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => { });
    }
    if (type == "Warranty") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setWarrantyAttach(res.data.data);
            setAddSingleFAR({
              ...addSingleFAR,
              warrantyAttach: res.data.data,
            });
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => { });
    }
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
  };

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  });

  const saveFile = () => {
    saveAs(xlx, "Far Template.xlsx");
  };

  const [pages, setPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getDisposedDataFar, setGetDisposedDataFar] = useState([])

  const handleChangePage = (event, newPage) => {
    setPages(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPages(0);
  };

  const handleChangeTab = (_event, newValue) => {
    setValue(newValue);
  };

  const OpenAssignModel = (data) => {

    console.log("data", data);
    setAssignedPayload({
      projectID: data?.assignedProjectID || projectDetails.projectID,
      CategoryName: data?.categoryName,
      SubCategory: data?.subCategoryName,
      itemName: data?.name,
      ReceivedBy: data?.receivedBy,
      AssignedTo: data?.assignedTo,
      farMasterID: data?.farMasterID,
      comment: data?.comment

      // AssignedDate: null,
    });
    getEmployeeBasedProjectsData(data?.assignedProjectID || projectDetails.projectID)
    setIsAssign(true);
  };

  // console.log("assignedData", assignedData);
  // console.log("assignedPayload", assignedPayload);
  // const getAllEmplyoees = () => {
  //   setIsLoading(true);
  //   APIKit.get(RGCA_URLS.getEmployee)
  //     .then(res => {
  //       if (res.data.status === 200) {
  //         setIsLoading(false);
  //         setEmplyoees(res.data.data);
  //       } else {
  //         setIsLoading(false);
  //         // toast.error(res.data.message);
  //       }
  //     })
  //     .catch(e => {
  //       setIsLoading(false);
  //     });
  // };
  const [allProjects, setAllProjects] = useState([]);
  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects)
      .then(res => {
        if (res.data.status === 200) {
          console.log('kkk', res.data.data);
          setAllProjects(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };



  const getEmployeeBasedProjectsData = (id) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getEmployeeBasedProjects, {
      "projectID": id
    })
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEmplyoees(res.data.data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const historyTableOpen = (z) => {
    let farMasterID = z?.farMasterID
    getAssignedHistoryData(farMasterID)
    setIsAssignHistory(true)
  }


  const getAssignedHistoryData = (farMaster) => {
    setIsLoading(true)
    let payload = {
      farMasterID: farMaster
    }
    APIKit.post(RGCA_URLS.getAssignedHistory, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false)
          setAssignHistoryData(res?.data?.data);
        } else {
          // toast.error(res.data.message);
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      });
  };

  const openDisposedModel = (z) => {
    setDisposedData({
      categoryName: z?.categoryName,
      subCategoryName: z?.subCategoryName,
      name: z?.name,
      itemQuty: z?.itemQuty,
      comment: z?.disposedComment,
      farMasterID: z?.farMasterID,
      status:z?.isDisposed
    });
    setIsdisposed(true)
  }

  const addDisposeFar = () => {
    setIsLoading(true);
    let payload = {
      farMasterID: disposedData?.farMasterID,
      isDisposed: disposedData?.status,
      disposedComment: disposedData?.comment
    }
    APIKit.post(RGCA_URLS.updateIsDisposed, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(res.data.message);
          setIsdisposed(false);
          getFAR();

        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  }

  const getDisposeFarData = () => {
    setIsLoading(true);
    let payload = {
      projectID: projectDetails?.projectID,
      fromDate: "",
      toDate: ""
    }
    APIKit.post(RGCA_URLS.getDisposedMaterails, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setGetDisposedDataFar(res.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  }


  return (
    <Page className={classes.root} title="FAR">
      <ToastContainer />
      {/* Far Table */}
      <>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>
            <h2 style={{ color: "#394671" }}>
              {props.permission == "view_FAR"
                ? "FAR (Fixed Asset Register)"
                : "Edit FAR (Fixed Asset Register)"}
            </h2>
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {props.permission == "view_FAR" &&
              (checkPermission("create_FAR") || userData?.isAdmin == 1) && (
                <>
                  <Button color="primary" variant="contained" onClick={bulkUpload}>
                    BULK UPLOAD
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveFile}
                  >
                    download template
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setAddFarItem(!addFarItem);
                      setSingleFAR({});
                    }}
                  >
                    Add FAR
                  </Button>
                </>
              )}
          </Stack>
        </Box>

        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Far List" onClick={() => a11yProps(0)} />
          <Tab label="Disposed Far List" onClick={() => {
            getDisposeFarData();
            a11yProps(1);
          }} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlignLast: "end" }}></Grid>
            {/* for new change i'm comment this table and guidance--hareesh sir */}

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Box mt={3}>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">FAR Master ID</TableCell>
                          <TableCell align="left">PO Master ID</TableCell>
                          <TableCell align="left">Category Name</TableCell>
                          <TableCell align="left">Sub-Category</TableCell>
                          <TableCell align="left">Item Name</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          {/* <TableCell align="left">Received by</TableCell> */}
                          <TableCell align="left">Assigned To</TableCell>
                          <TableCell align="left">Status</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {FARList && FARList.length != 0 ? (
                          FARList.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1
                          ).map((z, index) => {
                            return (
                              <TableRow hover>
                                <TableCell align="left">{z.farMasterID}</TableCell>
                                <TableCell align="left">{z.purchaseOrderMasterID}</TableCell>
                                <TableCell align="left">{z.categoryName}</TableCell>
                                <TableCell align="left">{z.subCategoryName}</TableCell>
                                <TableCell align="left">{z.name}</TableCell>
                                <TableCell align="left">{z.itemQuty}</TableCell>
                                <TableCell align="left">{z.isDisposed && z.isDisposed == 1 ? "Disposed" : "In Use"}</TableCell>
                                <TableCell align="left">
                                  {z.assignedName ?
                                    <>
                                      {z.assignedTo} <br /> ({z.assignedName})
                                    </>
                                    : ""}
                                </TableCell>
                                <TableCell align="left" style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                  {props.permission == 'view&edit_FAR' &&
                                    <>
                                      <EditOutlinedIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { editFar(index, z); }}
                                      />
                                      <AssignmentIndIcon
                                        style={{ cursor: "pointer", }}
                                        onClick={(e) => { OpenAssignModel(z); }}
                                      />
                                      <History
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => { historyTableOpen(z) }}
                                      />
                                      <RemoveCircleIcon
                                        style={{ cursor: "pointer", }}
                                        onClick={(e) => { openDisposedModel(z) }}
                                      />
                                    </>
                                  }

                                  {props.permission == 'view_FAR' && <>
                                    <VisibilityIcon
                                      style={{ margin: '5px', cursor: 'pointer' }}
                                      onClick={e => {
                                        getSingleData(z);
                                        setFarData(z);
                                      }}
                                    />
                                    {/* } */}
                                    {/* {z.isUpdated == 0 &&
                                  <Tooltip title="Disabled">
                                    <VisibilityOffIcon
                                      style={{ margin: '5px', cursor: 'pointer' }}
                                    />
                                    </Tooltip>
                                  } */}
                                    <History style={{ cursor: "pointer", }}
                                      onClick={(e) => { historyTableOpen(z) }}
                                    />
                                  </>
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow hover role="checkbox" key={1}>
                            <TableCell colSpan={10} align="center" key={2}>
                              {'No Data Found'}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {FARList && FARList?.length != 0 && FARList?.length > 5 && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={FARList.length}
                      rowsPerPage={rowsPerPage1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                  )}
                </Box>
              </div>
            </Grid>

            {/* new table  */}


          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {/* far dispose */}
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlignLast: "end" }}></Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Box mt={3}>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">FAR Master ID</TableCell>
                          <TableCell align="left">PO Master ID</TableCell>
                          <TableCell align="left">Category Name</TableCell>
                          <TableCell align="left">Sub-Category</TableCell>
                          <TableCell align="left">Item Name</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getDisposedDataFar && getDisposedDataFar.length != 0 ? (
                          getDisposedDataFar.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1
                          ).map((z, index) => {
                            return (
                              <TableRow hover>
                                <TableCell align="left">{z.farMasterID}</TableCell>
                                <TableCell align="left">{z.purchaseOrderMasterID}</TableCell>
                                <TableCell align="left">{z.categoryName}</TableCell>
                                <TableCell align="left">{z.subCategoryName}</TableCell>
                                <TableCell align="left">{z.name}</TableCell>
                                <TableCell align="left">{z.itemQuty}</TableCell>
                                <TableCell align="left">{z.isDisposed && z.isDisposed == 1 ? "Disposed" : "In Use"}</TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow hover role="checkbox" key={1}>
                            <TableCell colSpan={10} align="center" key={2}>
                              {'No Data Found'}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {getDisposedDataFar && getDisposedDataFar?.length != 0 && getDisposedDataFar?.length > 5 && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={getDisposedDataFar.length}
                      rowsPerPage={rowsPerPage1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                  )}
                </Box>
              </div>
            </Grid>
            {/* new table  */}
          </Grid>
        </TabPanel>


      </>
      {/* Far Table */}

      {/* Add Far */}
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...addSingleFAR,
          }}
          onSubmit={(values, resetForm) => {
            setIsLoading(true);

            const pay = {
              itemID: addSingleFAR.materialID,
              uniqueID: addSingleFAR.uniqueID,
              location: addSingleFAR.location,
              projectID: projectDetails.projectID,
              purchaseValue: addSingleFAR.purchaseValue,
              warrantyGranteeDetails: addSingleFAR.warrantyGranteeDetails,
              warrantyAttach: warrantyAttach,
              estimatedYears: addSingleFAR.estimatedYears,
              rateOfDepreciation: addSingleFAR.rateOfDepreciation,
              methodOfDepreciation: addSingleFAR.methodOfDepreciation,
              estimatedResidualValue: addSingleFAR.estimatedResidualValue,
              detailsOfDepreciation: addSingleFAR.detailsOfDepreciation,
              accumulatedDepreciation: addSingleFAR.accumulatedDepreciation,
              insuranceDate: addSingleFAR.insuranceDate,
              insuranceAttach: insuranceAttach,
              note: addSingleFAR.note,
              grnMasterID: addSingleFAR.grnMasterID,
              itemQty: addSingleFAR.itemQty,
              unitPrice: addSingleFAR.purchaseValue,
              totalPrice: addSingleFAR.purchaseValue * addSingleFAR.itemQty,
            };
            // console.log("lokesh",addSingleFAR.uniqueID);
            APIKit.post(RGCA_URLS.addFar, pay)
              .then((res) => {
                if (res.data.status === 200) {
                  setIsLoading(false);
                  setFARList(res.data.data);
                  toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  getFAR();
                  handleClose();
                  setAddSingleFAR({
                    materialList: [],
                    val: {},
                    materialID: "",
                    name: "",
                    uniqueID: "",
                    location: "",
                    projectID: null,
                    purchaseValue: "",
                    warrantyGranteeDetails: "",
                    warrantyAttach: "",
                    estimatedYears: "",
                    rateOfDepreciation: "",
                    methodOfDepreciation: "",
                    estimatedResidualValue: "",
                    detailsOfDepreciation: " ",
                    accumulatedDepreciation: "",
                    insuranceDate: "",
                    insuranceAttach: "",
                    note: "",
                  });
                } else {
                  setIsLoading(false);
                  toast.error(res.data.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }}
          validationSchema={Yup.object().shape({
            itemQty: Yup.string().required(MESSAGE.itemQty),
            uniqueID: Yup.string().required(MESSAGE.uniqueID),
            purchaseValue: Yup.string().required(MESSAGE.purchaseValue),
            insuranceDate: Yup.string().required(MESSAGE.insuranceDate),
            location: Yup.string().required('Location is required'),
            warrantyGranteeDetails: Yup.string().required(
              MESSAGE.warrantyGranteeDetails
            ),
            detailsOfDepreciation: Yup.string().required(
              " detailsOfDepreciation is required"
            ),
            methodOfDepreciation: Yup.string().required(
              " methodOfDepreciation is required"
            ),
            note: Yup.string().required(" note is required"),
            estimatedYears: Yup.date().required(" estimatedYears is required"),
            accumulatedDepreciation: Yup.string().required(
              " accumulatedDepreciation is required"
            ),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            resetForm,
          }) => (
            <MuiThemeProvider theme={formLabelsTheme}>
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={addFarItem}
                id="income"
                className={classes.root}
                contentStyle={{
                  width: "200%",
                  maxWidth: "none",
                }}
                maxWidth="lg"
                fullWidth
              >
                <Loader isLoading={isLoading} />
                <ToastContainer />
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={(e) => {
                      handleClose(resetForm);
                    }}
                    style={{ backgroundColor: "#394671", color: "#fff" }}
                  >
                    Fixed Asset Register
                  </DialogTitle>

                  <DialogContent
                    dividers
                    style={{ width: "1200px !important" }}
                  >
                    <Grid container spacing={3} className={classes.field}>
                      <Grid item md={4} xs={12}>
                        <CommonSelect
                          val={addSingleFAR.val}
                          onChange={(e) => onChangeInput(e, 0)}
                          options={addSingleFAR.materialList}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Category Name"
                          name="Category Name"
                          onBlur={handleBlur}
                          disabled={true}
                          value={addSingleFAR.categoryName}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="sub Category Name"
                          name="sub Category Name"
                          onBlur={handleBlur}
                          disabled={true}
                          shrink={true}
                          value={addSingleFAR.subCategoryName}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.uniqueID && errors.uniqueID)}
                          helperText={touched.uniqueID && errors.uniqueID}
                          onBlur={handleBlur}
                          label="Unique ID"
                          name="uniqueID"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.uniqueID = Number(e.target.value);
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          required
                          value={addSingleFAR.uniqueID}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 20 }}
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                          onBlur={handleBlur}
                          label="Location"
                          name="location"
                          type="text"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.location = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.location}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.itemQty && errors.itemQty)}
                          helperText={touched.itemQty && errors.itemQty}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 10 }}
                          label="Item Quantity"
                          name="itemQty"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.itemQty = Number(e.target.value);
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          required
                          value={addSingleFAR.itemQty}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.purchaseValue && errors.purchaseValue
                          )}
                          helperText={
                            touched.purchaseValue && errors.purchaseValue
                          }
                          onBlur={handleBlur}
                          label="Purchase value (Unit Price)"
                          name="purchaseValue"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.purchaseValue = Number(e.target.value);
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          required
                          value={addSingleFAR.purchaseValue}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.warrantyGranteeDetails &&
                            errors.warrantyGranteeDetails
                          )}
                          helperText={
                            touched.warrantyGranteeDetails &&
                            errors.warrantyGranteeDetails
                          }
                          onBlur={handleBlur}
                          label="Warranty / Guarantee details"
                          name="warrantyGranteeDetails"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.warrantyGranteeDetails = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.warrantyGranteeDetails}
                          required
                          variant="outlined"
                          inputProps={{ maxLength: 30 }}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: "85%", height: "80%" }}
                          variant="contained"
                          component="label"
                        >
                          {!warrantyAttach ? 'Warranty / Guarantee attachment *' : ''}
                          <div>
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleUploadClick(e, "Warranty", "add")
                              }
                            />
                          </div>
                          {warrantyAttach && (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                position: "absolute",
                                marginTop: "9px",
                              }}
                              href={warrantyAttach}
                              target="_blank"
                            >
                              {warrantyAttach?.substring(4, 25)}....
                              {warrantyAttach?.split(".").pop()}
                            </a>
                          )}
                        </Button>
                        {warrantyAttach && (
                          <ClearIcon onClick={() => {
                            setWarrantyAttach('');
                          }} style={{ cursor: 'pointer' }} />
                        )}
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Estimated Useful life in years"
                          name="estimatedYears"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.estimatedYears = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.estimatedYears}
                          variant="outlined"
                          required
                          error={Boolean(touched.estimatedYears && errors.estimatedYears)}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Rate of Depreciation"
                          name="purchaseValue"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            let p = addSingleFAR;
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              p.rateOfDepreciation = Number(e.target.value);
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.rateOfDepreciation}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Method Of Depreciation "
                          name="methodOfDepreciation"
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.methodOfDepreciation = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.methodOfDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(touched.methodOfDepreciation && errors.methodOfDepreciation)}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText="Estimated residual value at the end of the useful life"
                          label="Estimated Residual Value"
                          name="estimatedResidualValue"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            // handleChange(e)
                            if (regEx.numbersOnly.test(e.target.value)) {
                              let p = addSingleFAR;
                              p.estimatedResidualValue = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.estimatedResidualValue}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText="Details of Depreciation charged ,year on year"
                          label="Details of Depreciation "
                          name="detailsOfDepreciation"
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              // handleChange(e)
                              let p = addSingleFAR;
                              p.detailsOfDepreciation = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.detailsOfDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(touched.detailsOfDepreciation && errors.detailsOfDepreciation)}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Accumulated Depreciation"
                          helperText="Details of Depreciation charged ,year on year"
                          name="accumulatedDepreciation"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              // handleChange(e)
                              let p = addSingleFAR;
                              p.accumulatedDepreciation = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.accumulatedDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(touched.accumulatedDepreciation && errors.accumulatedDepreciation)}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid>
                            <KeyboardDatePicker
                              className={classes.calmar1}
                              // autoOk
                              required
                              InputProps={{ disabled: true }}
                              // onBlur={handleBlur}
                              inputVariant="outlined"
                              id="date-picker-dialog"
                              label="Insurance renewal date"
                              format="dd/MM/yyyy"
                              minDate={new Date()}
                              // disableFuture={true}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={Boolean(
                                touched.insuranceDate && errors.insuranceDate
                              )}
                              helperText={
                                touched.insuranceDate && errors.insuranceDate
                              }
                              name="insuranceDate"
                              onChange={(e) => {
                                const p = addSingleFAR;
                                p.insuranceDate = e;
                                setAddSingleFAR({
                                  ...p,
                                  insuranceDate: e,
                                });
                              }}
                              value={addSingleFAR.insuranceDate}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              // TextFieldComponent={TextFieldComponent}

                              inputValue=""
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: "85%", height: "80%" }}
                          variant="contained"
                          component="label"
                        >
                          {!insuranceAttach ? 'Insurance attachment *' : ''}
                          <div>
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleUploadClick(e, "Insurance", "add")
                              }
                            />
                          </div>
                          {insuranceAttach && (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                position: "absolute",
                                marginTop: "9px",
                              }}
                              href={
                                insuranceAttach
                              }
                              target="_blank"
                            >
                              {insuranceAttach?.substring(4, 25)}....
                              {insuranceAttach?.split(".").pop()}
                            </a>
                          )}
                        </Button>
                        {insuranceAttach && (
                          <ClearIcon onClick={() => {
                            setInsuranceAttach("");
                          }} style={{ cursor: 'pointer' }} />
                        )}

                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Note "
                          name="note"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = addSingleFAR;
                              p.note = e.target.value;
                              setAddSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={addSingleFAR.note}
                          variant="outlined"
                          required
                          error={Boolean(touched.note && errors.note)}

                        />
                      </Grid>

                      {addSingleFAR?.materialID != "" ? (
                        <Grid item md={12} xs={12}>
                          <div style={{ textAlign: "center" }}>
                            <App singleFAR={addSingleFAR} />
                          </div>
                        </Grid>
                      ) : null}

                      <Grid item md={12} xs={12}>
                        <div style={{ textAlign: "right" }}>
                          <Button
                            type="submit"
                            style={{
                              height: "55px",
                              marginLeft: "20px",
                              color: "white",
                              backgroundColor: "#394671",
                            }}
                            variant="contained"
                          >
                            Add
                          </Button>
                          <Button
                            onClick={handleClose}
                            style={{
                              height: "55px",
                              marginLeft: "20px",
                              backgroundColor: "#394671",
                              color: "white",
                            }}
                            variant="contained"
                          >
                            Close
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Form>
              </Dialog>
            </MuiThemeProvider>
          )}
        </Formik>
      </React.Fragment>
      {/* Add Far */}

      {/* More edit */}
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...singleFAR,
          }}
          onSubmit={(values) => {
            setIsLoading(true);
            console.error("hi");
            const pay = {
              farMasterID: singleFAR?.farMasterID,
              uniqueID: singleFAR?.uniqueID,
              location: singleFAR?.location,
              purchaseValue: singleFAR?.purchaseValue,
              warrantyGranteeDetails: singleFAR?.warrantyGranteeDetails,
              warrantyAttach: warrantyAttach,
              estimatedYears: singleFAR?.estimatedYears,
              rateOfDepreciation: singleFAR?.rateOfDepreciation,
              methodOfDepreciation: singleFAR?.methodOfDepreciation,
              estimatedResidualValue: singleFAR?.estimatedResidualValue,
              detailsOfDepreciation: singleFAR?.detailsOfDepreciation,
              accumulatedDepreciation: singleFAR?.accumulatedDepreciation,
              insuranceDate: singleFAR?.insuranceDate,
              insuranceAttach: insuranceAttach,
              note: singleFAR?.note,
              itemQty: singleFAR?.itemQty,
              unitPrice: singleFAR?.unitPrice,
              totalPrice: singleFAR?.totalPrice,
            };
            // console.error(pay);
            APIKit.put(RGCA_URLS.updateFar, pay)
              .then((res) => {
                if (res.data.status === 200) {
                  setIsLoading(false);
                  setFARList(res.data.data);
                  toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  getFAR();
                  handleMoreViewClose();
                } else {
                  setIsLoading(false);
                  toast.error(res.data.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              })
              .catch((e) => { });
          }}
          validationSchema={Yup.object().shape({
            location: Yup.string()
              .required('Location is required'),
            warrantyGranteeDetails: Yup.string()
              .required(MESSAGE.warrantyGranteeDetails)
              .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
            insuranceDate: Yup.date().required("insuranceDate is required"),
            detailsOfDepreciation: Yup.string().required(
              " detailsOfDepreciation is required"
            ),
            methodOfDepreciation: Yup.string().required(
              " methodOfDepreciation is required"
            ),
            note: Yup.string().required(" note is required"),
            estimatedYears: Yup.number().required(" estimatedYears is required"),
            accumulatedDepreciation: Yup.string().required(
              " accumulatedDepreciation is required"
            ),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
          }) => (
            <MuiThemeProvider theme={formLabelsTheme}>
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={moreView}
                id="income"
                className={classes.root}
                // contentStyle={{
                //   width: "200%",
                //   maxWidth: "none",

                // }}
                maxWidth="lg"
                fullWidth
              >
                <Loader isLoading={isLoading} />
                <ToastContainer />
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={(e) => {
                      handleMoreViewClose(resetForm);
                    }}
                    style={{ backgroundColor: "#394671", color: "#fff" }}
                  >
                    Fixed Asset Register
                  </DialogTitle>

                  <DialogContent
                    dividers
                    style={{ width: "1200px !important" }}
                  >
                    <Grid container spacing={3} className={classes.field}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Item ID"
                          onChange=""
                          required
                          disabled={true}
                          value={singleFAR.itemID}
                          variant="outlined"
                        />
                        {/* <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Item ID : 
                            </h5>
                          </Typography> */}
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Unique ID"
                          name="uniqueID"
                          onChange=""
                          required
                          disabled={true}
                          // value={singleFAR.uniqueID}
                          value={singleFAR.farMasterID}
                          variant="outlined"
                        />
                        {console.log(
                          "u id ",
                          singleFAR.uniqueID,
                          "aa",
                          singleFAR
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Received By"
                          name="ReceivedBy"
                          value={singleFAR?.receivedBy}
                          variant="outlined"
                          inputProps={{ maxLength: 8 }}
                          disabled={true}
                        />
                      </Grid>



                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 20 }}
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                          onBlur={handleBlur}
                          label="Location"
                          name="location"
                          type="text"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = singleFAR;
                              p.location = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.location}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Name"
                          name="Name"
                          onChange=""
                          required
                          disabled={true}
                          value={singleFAR.name}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Category"
                          name="Name"
                          onChange=""
                          required
                          disabled={true}
                          value={singleFAR.categoryName}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Purchase value (Unit Price)"
                          name="purchaseValue"
                          required
                          variant="outlined"
                          value={singleFAR.unitPrice}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.warrantyGranteeDetails &&
                            errors.warrantyGranteeDetails
                          )}
                          helperText={
                            touched.warrantyGranteeDetails &&
                            errors.warrantyGranteeDetails
                          }
                          onBlur={handleBlur}
                          label="Warranty / Guarantee details"
                          name="warrantyGranteeDetails"
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = singleFAR;
                              p.warrantyGranteeDetails = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.warrantyGranteeDetails}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: "85%", height: "80%" }}
                          variant="contained"
                          component="label"
                        >
                          {!warrantyAttach ? 'Warranty / Guarantee attachment *' : ''}
                          <div>
                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleUploadClick(e, "Warranty")}
                            />
                          </div>
                          {warrantyAttach && (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                position: "absolute",
                                marginTop: "9px",
                              }}
                              href={warrantyAttach}
                              target="_blank"
                            >
                              {warrantyAttach?.substring(4, 25)}....
                              {warrantyAttach?.split(".").pop()}
                            </a>
                          )}
                        </Button>
                        {warrantyAttach && (
                          <ClearIcon onClick={() => {
                            setWarrantyAttach('');
                          }} style={{ cursor: 'pointer' }} />
                        )}

                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Estimated Useful life in years"
                          name="estimatedYears"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            handleChange(e);
                            let p = singleFAR;
                            p.estimatedYears = e.target.value;
                            setSingleFAR({
                              ...p,
                            });
                          }}
                          value={singleFAR.estimatedYears}
                          variant="outlined"
                          required
                          error={Boolean(
                            touched.estimatedYears &&
                            errors.estimatedYears
                          )}
                          helperText={
                            touched.estimatedYears &&
                            errors.estimatedYears
                          }
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText=""
                          label="Rate of Depreciation"
                          name="purchaseValue"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            handleChange(e);
                            let p = singleFAR;
                            p.rateOfDepreciation = Number(e.target.value);
                            setSingleFAR({
                              ...p,
                            });
                          }}
                          value={singleFAR.rateOfDepreciation}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Method Of Depreciation "
                          name="methodOfDepreciation"
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = singleFAR;
                              p.methodOfDepreciation = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.methodOfDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(
                            touched.methodOfDepreciation &&
                            errors.methodOfDepreciation
                          )}
                          helperText={touched.methodOfDepreciation && errors.methodOfDepreciation}


                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          helperText="Estimated residual value at the end of the useful life"
                          label="Estimated Residual Value"
                          name="estimatedResidualValue"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            // handleChange(e)
                            if (regEx.numbersOnly.test(e.target.value)) {
                              let p = singleFAR;
                              p.estimatedResidualValue = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.estimatedResidualValue}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Details of Depreciation"
                          helperText="Details of Depreciation charged ,year on year"
                          name="detailsOfDepreciation"
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              // handleChange(e)
                              let p = singleFAR;
                              p.detailsOfDepreciation = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.detailsOfDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(
                            touched.detailsOfDepreciation &&
                            errors.detailsOfDepreciation
                          )}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Accumulated Depreciation"
                          helperText="Details of Depreciation charged ,year on year"
                          name="accumulatedDepreciation"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              // handleChange(e)
                              let p = singleFAR;
                              p.accumulatedDepreciation = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.accumulatedDepreciation}
                          variant="outlined"
                          required
                          error={Boolean(
                            touched.accumulatedDepreciation &&
                            errors.accumulatedDepreciation
                          )}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid>
                            <KeyboardDatePicker
                              // autoOk
                              InputProps={{ disabled: true }}
                              inputVariant="outlined"
                              id="date-picker-dialog"
                              label="Insurance renewal date"
                              format="dd/MM/yyyy"
                              // disableFuture={true}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="insuranceDate"
                              onChange={(e) => {
                                const p = singleFAR;
                                p.insuranceDate = e;
                                setSingleFAR({
                                  ...p,
                                  insuranceDate: e,
                                });
                              }}
                              value={singleFAR.insuranceDate}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              // TextFieldComponent={TextFieldComponent}
                              required
                              className={classes.calmar}
                              inputValue=""
                              error={Boolean(
                                touched.insuranceDate &&
                                errors.insuranceDate
                              )}
                              helperText={touched.insuranceDate && errors.insuranceDate}

                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: "85%", height: "80%" }}
                          variant="contained"
                          component="label"
                        >
                          {!insuranceAttach ? 'Insurance attachment *' : ''}
                          <div>
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleUploadClick(e, "Insurance")
                              }
                            />
                          </div>
                          {insuranceAttach && (
                            <a
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                position: "absolute",
                                marginTop: "9px",
                              }}
                              href={
                                insuranceAttach
                              }
                              target="_blank"
                            >
                              {insuranceAttach?.substring(4, 25)}....
                              {insuranceAttach?.split(".").pop()}
                            </a>
                          )}
                        </Button>
                        {insuranceAttach && (
                          <ClearIcon onClick={() => {
                            setInsuranceAttach("");
                          }} style={{ cursor: 'pointer' }} />
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Note"
                          name="note"
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let p = singleFAR;
                              p.note = e.target.value;
                              setSingleFAR({
                                ...p,
                              });
                            }
                          }}
                          value={singleFAR.note}
                          variant="outlined"
                          required
                          error={Boolean(touched.note && errors.note)}
                          helperText={touched.note && errors.note}

                        />
                      </Grid>
                      {Object.keys(singleFAR).length ? (
                        <Grid item md={12} xs={12}>
                          <div style={{ textAlign: "center" }}>
                            <App singleFAR={singleFAR} />
                          </div>
                        </Grid>
                      ) : null}

                      {/* <Grid item md={12} xs={12}>
                        <div style={{ textAlign: "right" }}>
                          <Button
                            type="submit"
                            style={{ height: "55px", marginLeft: "20px" }}
                            color="primary"
                            variant="contained"
                          >
                            Update
                          </Button>
                          <Button
                            onClick={handleMoreViewClose}
                            style={{ height: "55px", marginLeft: "20px" }}
                            color="primary"
                            variant="contained"
                          >
                            Close
                          </Button>
                        </div>
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions
                    style={{ backgroundColor: "#394671", color: "#fff" }}
                  >
                    <Grid item md={12} xs={12}>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#394671",
                            color: "#fff",
                            border: "1px solid",
                            marginRight: "15px",
                          }}
                          // color="primary"
                          variant="outlined"
                        >
                          Update
                        </Button>
                        <Button
                          onClick={handleMoreViewClose}
                          style={{
                            backgroundColor: "#394671",
                            color: "#fff",
                            border: "1px solid",
                          }}
                          // color="primary"
                          variant="outlined"
                        >
                          Close
                        </Button>
                      </div>
                    </Grid>
                  </DialogActions>
                </Form>
              </Dialog>
            </MuiThemeProvider>
          )}
        </Formik>
      </React.Fragment>
      {/*  More*/}

      {/* Single View Far */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openTest1}
        id="income"
        contentStyle={{
          width: "200%",
          maxWidth: "none",
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseModal1}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        >
          Fixed Asset Register View
        </DialogTitle>
        <DialogContent dividers
        // style={{ overflow: "hidden" }}
        >
          <Box
            mt={3}
            style={{
              backgroundColor: "#fff",
              boxShadow:
                "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
              borderRadius: "5px",
              padding: "20px",
              overflowWrap: 'break-word'
            }}
          >
            <Grid container spacing={3}>

              <Grid item md={6} xs={12}>
                <strong>Accumulated Depreciation : </strong>{farData.accumulatedDepreciation}
              </Grid>
              <Grid item md={6} xs={12} >
                <strong>Details of Depreciation : </strong>{farData.detailsOfDepreciation}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Estimated residual value : </strong>{farData.estimatedResidualValue}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Method of depreciation : </strong>{farData.methodOfDepreciation}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Purchase Value : </strong>
                {Number(farData.purchaseValue).toLocaleString("en-IN")}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Warranty Guarantee Details : </strong>{farData.warrantyGranteeDetails}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Rate of depreciation : </strong>{farData.rateOfDepreciation}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Location : </strong>{farData.location}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Insurance Date : </strong>
                {dateFormate(farData.insuranceDate)}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Note : </strong>{farData.note}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Estimated Useful life in year : </strong>{farData.estimatedYears}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Is Transform From Any Project  : {farData.istransferproject == 0 ? 'No' : 'Yes'} </strong>
              </Grid>
              <Grid item md={6} xs={12} >
                <strong>Project Transform From : </strong>{farData?.projectName}
              </Grid>
              <Grid item md={6} xs={12} >
                <strong>Goods Received By : </strong>{farData?.receivedBy}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Warranty Attach : </strong>
                {/* {farData?.warrantyAttach && <a href={farData?.warrantyAttach}>{farData?.warrantyAttach.substring(4, 25)} </a>} */}
                {farData?.warrantyAttach && <a href={farData?.warrantyAttach}>{farData?.warrantyAttach?.substring(4, 25)}....{farData?.warrantyAttach?.split(".").pop()}</a>}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Insurance Attach : </strong>
                {/* {farData?.insuranceAttach && <a href={farData?.insuranceAttach}>{farData?.insuranceAttach.substring(4, 25)}</a>} */}
                {farData?.insuranceAttach && <a href={farData?.insuranceAttach}>{farData?.insuranceAttach?.substring(4, 25)}....{farData?.insuranceAttach?.split(".").pop()}</a>}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Technical Specification Attach : </strong>
                {farData?.itemAttach && <a href={farData?.itemAttach}>{farData?.itemAttach?.substring(4, 25)}....{farData?.itemAttach?.split(".").pop()}</a>}
              </Grid>
              <Grid item md={6} xs={12}>
                <strong>Additional Terms and Condition Attach : </strong>
                {farData?.specificationAttach && <a href={farData?.specificationAttach}>{farData?.specificationAttach?.substring(4, 25)}....{farData?.specificationAttach?.split(".").pop()}</a>}
              </Grid>
              {farData?.gemAttach &&
                <Grid item md={6} xs={12}>
                  <strong>Gem Attach : </strong>
                  {farData?.gemAttach && <a href={farData?.gemAttach}>{farData?.gemAttach?.substring(4, 25)}....{farData?.gemAttach?.split(".").pop()}</a>}
                </Grid>
              }
            </Grid>
          </Box>
          {/* <br />
          <br /> */}
          <Grid container spacing={3} style={{ marginTop: '15px', paddingLeft: '18px' }}>

            <Grid item md={8} xs={12} ref={htmlContentRef} className='print-content' style={{ display: 'flex', justifyContent: 'end' }}>
              <App1 getsingleFAR={farData} />
            </Grid>
            <Grid item md={4} xs={12} style={{ display: 'flex', justifyContent: 'start', marginTop: '27px' }}>
              <PrintIcon style={{ cursor: 'pointer' }}
                onClick={() => { printOrder(); }}
              />
            </Grid>
          </Grid>

          {/* <div style={{ textAlign: "center" }}>
            <App1 getsingleFAR={getsingleFAR} />
          </div> */}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            onClick={handleCloseModal1}
            style={{
              backgroundColor: "#394671",
              color: "#fff",
              border: "1px solid",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Single View Far */}

      {/* BULK UPLOAD MODAL */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={bulkOpen}
        id="income"
        contentStyle={{
          width: "50%",
          maxWidth: "none",
        }}
        maxWidth="sm"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={(e) => {
            setBulkOpen(!bulkOpen);
          }}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        >
          Upload File
        </DialogTitle>
        <DialogContent>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ width: "50%", height: "80%" }}
                variant="contained"
                component="label"
              >
                Bulk Upload
                <input type="file" onChange={(e) => handleUpload(e)} hidden />
              </Button>
              <br />
              {bulk && (
                <>
                  <a style={{ color: "blue" }}>
                    {bulk.substring(0, 25)}....
                    {bulk.substring(bulk.lastIndexOf(".") + 1)}
                  </a>{" "}
                </>
              )}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            onClick={(e) => {
              setBulkOpen(!bulkOpen);
            }}
            style={{
              backgroundColor: "#394671",
              color: "#fff",
              border: "1px solid",
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={bulUploadDatas}
            style={{
              backgroundColor: "#394671",
              color: "#fff",
              border: "1px solid",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* END BULK UPLOAD MODAL */}

      <>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={isAssign}
          id="assign"
          contentStyle={{
            width: "50%",
            maxWidth: "none",
          }}
          maxWidth="md"
          fullWidth
        >
          <Loader isLoading={isLoading} />
          <DialogTitle
            id="scroll-dialog-title"
            onClose={(e) => {
              setIsAssign(!isAssign);
            }}
            style={{ backgroundColor: "#394671", color: "#fff" }}
          >
            Assign Item
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Projects*
                  </InputLabel>

                  <Select
                    defaultValue={assignedPayload.projectID}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="projectName"
                    onChange={e => {

                      const p = assignedPayload;
                      p.projectID = e.target.value;
                      getEmployeeBasedProjectsData(e.target.value)
                      setAssignedPayload({
                        ...assignedPayload
                      });
                    }}
                    value={assignedPayload.projectID}
                    label="Projects"
                  >
                    {allProjects &&
                      allProjects.length > 0 &&
                      allProjects.map(x => {
                        return (
                          <MenuItem
                            // disabled={empPayload.projects.findIndex(y => y.projectID == x.projectID) != -1}
                            value={x.projectID}
                            key={x.projectID}
                          >
                            {x.projectName}
                          </MenuItem>
                        )
                        // }
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Category Name"
                  name="CategoryName"
                  value={assignedPayload.CategoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 8 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Sub Category"
                  name="SubCategory"
                  value={assignedPayload.SubCategory}
                  variant="outlined"
                  inputProps={{ maxLength: 8 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Item Name"
                  name="itemName"
                  value={assignedPayload.itemName}
                  variant="outlined"
                  inputProps={{ maxLength: 8 }}
                  disabled={true}
                />
              </Grid>
              {/* <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Received By"
                  name="ReceivedBy"
                  value={assignedPayload.ReceivedBy}
                  variant="outlined"
                  inputProps={{ maxLength: 8 }}
                  disabled={true}
                />
              </Grid> */}
              <Grid item md={4} xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className={classes.formControl}
                >
                  <InputLabel required id="demo-simple-select-outlined-label">
                    Assigned To
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={assignedPayload.AssignedTo}
                    name="AssignedTo"
                    label="Assigned To"
                    required
                    onChange={(e) => {
                      const p = assignedPayload;
                      p.AssignedTo = e.target.value;
                      setAssignedPayload({
                        ...assignedPayload
                      });
                    }}
                  >
                    {emplyoees && emplyoees.map((x) => {
                      return <MenuItem value={x.empLoginID} key={x.empLoginID}>{x.empLoginID}</MenuItem>;
                    })}

                  </Select>
                </FormControl>
              </Grid>
              {assignedPayload.AssignedTo &&
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Assigned Comment"
                    variant="outlined"
                    onChange={e => {
                      const p = assignedPayload;
                      p.comment = e.target.value;
                      setAssignedPayload({
                        ...assignedPayload
                      });
                    }}
                  />
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
            <Button
              onClick={(e) => {
                setIsAssign(!isAssign);
              }}
              style={{
                backgroundColor: "#394671",
                color: "#fff",
                border: "1px solid",
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {

                setIsLoading(true);

                APIKit.put(RGCA_URLS.updateFarData, {
                  "farMasterID": assignedPayload?.farMasterID,
                  "assignedTo": assignedPayload?.AssignedTo,
                  "projectID": assignedPayload?.projectID,
                  "comment": assignedPayload?.comment
                })
                  .then(res => {
                    if (res.data.status === 200) {
                      setIsLoading(false);
                      toast.success("Successfully Assigned");
                      setIsAssign(false)
                      getFAR()
                    } else {
                      setIsLoading(false);
                    }
                  })
                  .catch(e => {
                    setIsLoading(false);
                  });
              }}
              style={{
                backgroundColor: "#394671",
                color: "#fff",
                border: "1px solid",
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={isDisposed}
          id="assign"
          contentStyle={{ width: "50%", maxWidth: "none", }}
          maxWidth="md"
          fullWidth
        >
          <Loader isLoading={isLoading} />
          <DialogTitle
            id="scroll-dialog-title"
            onClose={(e) => { setIsdisposed(false); }}
            style={{ backgroundColor: "#394671", color: "#fff" }}
          > Disposed Item</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Category Name"
                  name="CategoryName"
                  value={disposedData?.categoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Sub Category"
                  name="SubCategory"
                  value={disposedData?.subCategoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Item Name"
                  name="itemName"
                  value={disposedData?.name}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Item Name"
                  name="itemName"
                  value={disposedData?.itemQuty}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className={classes.formControl}
                >
                  <InputLabel required id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={disposedData?.status}
                    name="Status"
                    label="Status"
                    required
                    onChange={(e) => {
                      const p = disposedData;
                      p.status = e.target.value;
                      setDisposedData({ ...p });
                    }}
                  >
                    <MenuItem value={0} >In Use(Active)</MenuItem>
                    <MenuItem value={1} >Disposed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {disposedData?.status == 1 &&
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label=" Comment"
                    variant="outlined"
                    inputProps={{ maxLength: 200 }}
                    required
                    onChange={e => {
                      const p = disposedData;
                      p.comment = e.target.value;
                      setDisposedData({ ...p });
                    }}
                  />
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
            <Button onClick={(e) => { setIsdisposed(false); }}>Cancel</Button>
            <Button disabled={!disposedData?.status} onClick={(e) => { addDisposeFar(); }}> Submit</Button>
          </DialogActions>
        </Dialog>


        {/* bulkupload modal */}
        <Dialog
          open={openBulk}
          onClose={handleCloseBulk}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>{"Duplicate FAR records"}</DialogTitle>
          <DialogContent>

            <div ref={htmlContentRef}>
              <table class="table">
                <thead class='tableHead'>
                  <tr class="table-rows">
                    <th align="left">S.No</th>
                    <th align="left">Unique ID</th>
                  </tr>
                </thead>
                <tbody>
                  {errRecords
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((x, index) => (
                      <tr class="table-row">
                        <td align="left">{page * 10 + index + 1}</td>
                        <td align="left">{x?.uniqueID}</td>
                      </tr>
                    ))}
                </tbody>
                {errRecords && errRecords.length > 10 &&
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    // component="div"
                    count={errRecords.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} style={{ width: "1420px" }}
                  />
                }
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBulk} color="primary">
              close
            </Button>
            <Button onClick={printOrder} color="primary" autoFocus>
              Save PDF
            </Button>
          </DialogActions>
        </Dialog>
        <AssignHistory isAssignHistory={isAssignHistory} setIsAssignHistory={setIsAssignHistory}
          setAssignHistoryData={setAssignHistoryData} assignHistoryData={assignHistoryData} isLoading={isLoading} />
      </>
    </Page>
  );
};

export default FAR;
