import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import Page from 'src/components/Page';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Search as SearchIcon } from 'react-feather';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { MESSAGE } from 'src/utils/message';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getNavigationData } from '../../Redux/Common/action';
import { RGCA_URLS } from '../../utils/url';
import APIKit from '../../utils/APIKit';
import AddEmplyoee from '../AddEmplyoee/index.jsx';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'react-toast-notifications';
import { useReactToPrint } from 'react-to-print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FileSaver from 'file-saver';
import Loader from '../loader';
import HistoryIcon from '@material-ui/icons/History';
import * as XLSX from 'xlsx';
import {
  isFileAllowed,
  validateEmail,
  isFileSizeAllowed
} from 'src/views/Project/Common/fileUpload';
import './style.css';
import { checkPermission } from '../../utils/permission.jsx';
import { saveAs } from 'file-saver';
import xlx from '../../Assets/SampleEmployee.xlsx';
import { dateForm } from '../Project/Common/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  container: {
    tableContainer: {
      maxHeight: '100%',
      '@media (max-width: 500px)': {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    },
  },
  centertext: {
    marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
    '@media (max-width: 500px)': {
      marginLeft: "120px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "60px"
    },
  },
  button: {
    '@media (max-width: 800px)': {
      marginBottom: "5px"
    },
    '@media (max-width: 500px)': {
      marginLeft: "110px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "50px"
    },
  }
}));

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const Employee = props => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [emplyoees, setEmplyoees] = useState([]);
  const [openAddEmployee, setAddEmployee] = React.useState(false);
  const confirm = useConfirm();
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    let data = localStorage.getItem('userData');
    console.log("login data", JSON.parse(data));
    if (data)
      setUserData(JSON.parse(data))
  }, [])

  const [openBulk, setOpenBulk] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);

  const openHis = () => {
    setOpenHistory(true)
  }
  const closeHis = () => {
    setOpenHistory(false)
  }

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };


  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllEmplyoees();
  }, []);
  const handleAddEmployeeClose = () => {
    setAddEmployee(false);
    getAllEmplyoees();
  };
  const getAllEmplyoees = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getEmployee)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEmplyoees(res.data.data);
          setFilteredData(res.data.data)
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const openAddEmplyoee = async () => {
    const data = {};
    await dispatch(getNavigationData(data));
    setAddEmployee(true);
    // navigate('/app/AddEmplyoee', { replace: true })
  };

  const editEmp = async data => {
    console.log("vvvvvvv",data);
    await dispatch(getNavigationData(data));
    setAddEmployee(true);
  };

  const removeEmployee = data => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        remove(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const remove = data => {
    APIKit.delete(`${RGCA_URLS.deleteEmployee}/${data.employeeID}`)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.employeeDeleted, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          getAllEmplyoees();
        } else {
          addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.employeeNotDeleted, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };

  const [empInfo, setEmpInfo] = useState(false);
  const [errRecords, setErrRecords] = useState([])
  const [employeeData, setEmployeeData] = useState({});
  const [bulkOpen, setBulkOpen] = useState(false);
  const [bulk, setBulk] = useState('');
  const [bulkFIle, setBulkFile] = useState('');
  const bulkUpload = () => {
    setBulk('');
    setBulkOpen(!bulkOpen);
  };

  const handleUpload = async e => {

    if (!isFileAllowed(e.target.files[0]?.name, 'bckUpload')) {
      toast.error(MESSAGE.fileFormat, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (!isFileSizeAllowed(e.target.files[0]?.size)) {
      toast.error(MESSAGE.fileSze, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    let arrayBuffer;
    let parseExcelData;
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      parseExcelData = await XLSX.utils.sheet_to_json(worksheet, {
        raw: true
      });
    };
    setTimeout(() => {
      if (parseExcelData?.length > 0) {
        let errorCount = 0;
        let errorNameCount = 0;
        parseExcelData.forEach(data => {
          if (!validateEmail(data?.Email)) {
            errorCount++;
          }
          if (!data['First Name'] || data['First Name'] === '') {
            errorNameCount++;
          }
        });

        if (errorCount > 0) {
          toast.error(errorCount + ' ' + MESSAGE.wrong_data_entered_employee, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }

        if (errorNameCount > 0) {
          toast.error(
            errorNameCount + ' ' + MESSAGE.wrong_name_data_entered_employee,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          );
          return;
        }
        setBulk(file.name);
        setBulkFile(file);
      } else {
        toast.error(MESSAGE.file_empty, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }
    }, 1000);
  };

  const bulUploadDatas = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', bulkFIle);
    APIKit.post(RGCA_URLS.bulkUploadEmployee, formData)
      .then(res => {
        if (res.data.status == 200) {
          setIsLoading(false);
          setBulkOpen(!bulkOpen);
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          // setAppointmentAttach(res.data.data);
          getAllEmplyoees();
        } else if (res.data.status == 401) {
          setIsLoading(false);
          setErrRecords(res.data.error);
          setOpenBulk(true);
        }
        else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };


  const saveFile = () => {
    saveAs(xlx, 'Employee Template.xlsx');
  };

  const exportToCSV = () => {
    let name = 'Employee duplicate records'
    try {
      const fileName = name;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';
      const newCsvData = [];
      // Check if receiptList is an array and not empty
      if (!Array.isArray(errRecords) || errRecords.length === 0) {
        // throw new Error('Receipt list is empty or invalid.');
        toast.warning('Duplicate list is empty')
      }
      errRecords.forEach((x, index) => {
        const obj = {};
        obj['S No'] = { v: index + 1, t: 's' };
        obj['Employee Id'] = { v: x.empLoginID, t: 's' };
        obj['Email'] = { v: x.email, t: 's' };
        obj['Mobile Number'] = { v: x.phone, t: 's' };

        newCsvData.push(obj);
      });

      if (newCsvData.length === 0) {
        throw new Error('No data to export.');
      }
      const ws = XLSX.utils.json_to_sheet(newCsvData);

      ws['!cols'] = [
        { wch: 15 }, // Receipt ID
        { wch: 20 }, // Product Name
        { wch: 15 }, // Selling Date
        { wch: 15 }, // Rate Per Unit
        { wch: 10 }, // Quantity
        { wch: 10 }, // GST
        { wch: 15 }, // Amount
        { wch: 15 }, // Paid Amount
        { wch: 20 }, // Remaining Balance
        { wch: 20 }, // Mode of Payment
        { wch: 20 }, // Customer Name
        { wch: 20 }  // Purpose
      ];
      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: 0 };
        ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
      }
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.error('Error exporting CSV:', error.message);
    }
  };

  const [empHis, setEmpHis] = useState([])
  const getEmplyoeeHistory = (empId) => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getEmployeeprojectHistory + '/' + `${empId}`)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEmpHis(res.data.data);
          openHis()
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (event) => {
    
    const value = event.target.value.toLowerCase();
    setQuery(value);
    if (value) {
      const filtered = emplyoees.filter(item =>
        item.firstName.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value)
      );
       setFilteredData(filtered);
    } else {
      getAllEmplyoees()
    }
  };

  return (
    <Page className={classes.root} title="Employee">
      <ToastContainer />
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        &nbsp;
        <div>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.centertext}
              >
                Employee List
              </Grid>

              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                {
                  (checkPermission('create_employee') || userData?.isAdmin == 1) &&
                  (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={bulkUpload}
                        className={classes.button}
                      >
                        Bulk Upload
                      </Button>
                      &nbsp;
                      <Button color="primary" variant="contained" onClick={saveFile}>
                        download template
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={openAddEmplyoee}
                      >
                        Add
                      </Button>
                    </>
                  )}
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    value={query}
                    onChange={handleSearch}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3}>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  {/* <TableCell align="left">Employee Code</TableCell> */}
                  <TableCell align="left">Employee ID</TableCell>

                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left" style={{ width: '10%' }}>
                    Mobile No
                  </TableCell>

                  <TableCell align="left">Designation</TableCell>
                  <TableCell align="left">Gender</TableCell>
                  {(checkPermission('edit_employee') || userData?.isAdmin == 1 ||
                    checkPermission('Delete_employee')) && (
                      <TableCell align="left">Actions</TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((emplyoee, index) => (
                    <TableRow
                      hover
                      key={emplyoee.id}
                      selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                    >
                      <TableCell align="left">{page * 10 + index + 1}</TableCell>
                      {/* <TableCell align="left">{emplyoee.employeeCode}</TableCell> */}
                      <TableCell align="left">{emplyoee.empLoginID}</TableCell>

                      <TableCell align="left">
                        {emplyoee.firstName} {emplyoee.lastName}
                      </TableCell>
                      <TableCell align="left">{emplyoee.email}</TableCell>
                      <TableCell align="left" style={{ width: '10%' }}>
                        {emplyoee.phone}
                      </TableCell>

                      <TableCell align="left">
                        {emplyoee.designationName}
                      </TableCell>
                      <TableCell align="left">{emplyoee.genederName}</TableCell>

                      {(checkPermission('edit_employee') || userData?.isAdmin == 1 ||
                        checkPermission('Delete_employee')) && (
                          <TableCell align="left">
                            {(checkPermission('edit_employee') || userData?.isAdmin == 1) && (
                              <EditOutlinedIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => editEmp(emplyoee)}
                              />
                            )}
                            {(checkPermission('Delete_employee') || userData?.isAdmin == 1) &&
                              emplyoee.accessRoleID != 1 && (
                                <DeleteForeverIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    removeEmployee(emplyoee);
                                  }}
                                />
                              )}
                            <HistoryIcon onClick={() => {
                              getEmplyoeeHistory(emplyoee.employeeID)

                            }} />
                          </TableCell>
                        )}
                    </TableRow>
                  ))}

              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              // component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage} style={{ width: "1420px" }}
            />
          </TableContainer>
        </Box>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openAddEmployee}
          id="income"
          contentStyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
          scroll={'paper'}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleAddEmployeeClose}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '1500px !important'
            }}
          >
            Employee Details
          </DialogTitle>
          <AddEmplyoee
            handleAddEmployeeClose={handleAddEmployeeClose}
            getAllEmplyoees={getAllEmplyoees}
          />
        </Dialog>
      </Container>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={bulkOpen}
        id="income"
        contentStyle={{
          width: '50%',
          maxWidth: 'none'
        }}
        maxWidth="sm"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={e => {
            setBulkOpen(!bulkOpen);
          }}
          style={{ backgroundColor: '#394671', color: '#fff' }}
        >
          Upload File
        </DialogTitle>
        <DialogContent>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: '50%', height: '80%' }}
                variant="contained"
                component="label"
              >
                Bulk Upload
                <input type="file" onChange={e => handleUpload(e)} hidden />
              </Button>
              <br />
              {bulk && (
                <>
                  <a style={{ color: 'blue' }}>
                    {bulk.substring(0, 25)}....
                    {bulk.substring(bulk.lastIndexOf('.') + 1)}
                  </a>{' '}
                </>
              )}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          <Button
            onClick={e => {
              setBulkOpen(!bulkOpen);
            }}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={bulUploadDatas}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* bulkupload modal */}
      <Dialog
        open={openBulk}
        onClose={handleCloseBulk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>{"Duplicate employee records"}</DialogTitle>
        <DialogContent>

          <div ref={htmlContentRef}>
            <table class="table">
              <thead class='tableHead'>
                <tr class="table-rows">
                  <th align="left">S.No</th>
                  <th align="left">Employee ID</th>
                  <th align="left">Email</th>
                  <th align="left">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                {errRecords && errRecords
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((x, index) => (
                    <tr class="table-row">
                      <td align="left">{page * 10 + index + 1}</td>
                      <td align="left">{x?.empLoginID}</td>
                      <td align="left">{x.email}</td>
                      <td align="left">{x.phone}</td>
                    </tr>
                  ))}
              </tbody>
              {errRecords && errRecords.length > 10 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  // component="div"
                  count={errRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage} style={{ width: "1420px" }}
                />
              }
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBulk} color="primary">
            close
          </Button>
          <Button onClick={printOrder} color="primary" autoFocus>
            Save PDF
          </Button>
        </DialogActions>
      </Dialog>


      {/* History */}

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openHistory}
        id="income"
        contentStyle={{
          width: '100%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={e => {
            closeHis()
          }}
          style={{ backgroundColor: '#394671', color: '#fff' }}
        >
          History
        </DialogTitle>
        <DialogContent>
          <div>
            <TableContainer >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="left">Employee ID</TableCell>
                    <TableCell align="left">Employee Name</TableCell>
                    <TableCell align="left">Project Name</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Start Date</TableCell>
                    <TableCell align="left">End Date</TableCell>
                    <TableCell align="left">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empHis && empHis.length != 0 ? (
                    empHis && empHis.map((x, index) => {
                      return (
                        <TableRow hover key={index + 1}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">RGCA-{x.employeeID}</TableCell>
                          <TableCell align="left">{x.employeeName}</TableCell>
                          <TableCell align="left">{x.projectName}</TableCell>
                          <TableCell align="left">{x.accessRoleName}</TableCell>
                          <TableCell align="left">{dateForm(x.startDate)}</TableCell>
                          <TableCell align="left">{dateForm(x.endDate)}</TableCell>
                          <TableCell align="left">{x.Comments}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {'No Data Found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHis} color="primary">
            close
          </Button>

        </DialogActions>
      </Dialog>

    </Page>
  );
};
export default Employee;
