import React, { useState, useEffect } from 'react';
import { Container, makeStyles, Typography, Box } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Page from 'src/components/Page';
import { ToastContainer } from 'react-toastify';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StoreApproval from './StoreApproval';
import StoreNewIndent from './StoreNewIndent';
import StoreIndent from './StoreIndent';
import { checkPermission } from '../../../utils/permission.jsx';
import GINReport from './ginReport';
import AllApprovedStoreIndent from './allApprovedStoreIndent';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // icon: {
  //   fill: "green",
  // },
  text: {
    fill: 'white'
  },
  centerText: {
    textAlign: 'center',
    paddingTop:'2',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    }
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StoreIndentMain = () => {
  const classes = useStyles();
  // const [userData, setUserData] = useState({});

  // useEffect(() => {
  //   let data = localStorage.getItem('userData');
  //   console.log('login data', JSON.parse(data));
  //   if (data) setUserData(JSON.parse(data));
  // }, []);

  let userData = JSON.parse(localStorage.getItem('userData'));

  const allData = [
    {
      value: 'approval_storeIndent',
      name: 'Store Indent Pending Approvals'
    },
    {
      value: 'view_storeIndent',
      name: 'View My Store Indent'
    },
    {
      value: 'view&edit_storeIndent',
      name: 'Edit My Store Indent'
    },
    {
      value: 'create_storeIndent',
      name: 'Rise A New Store Indent'
    },
    {
      value: 'approved_storeIndent',
      name: '  Store Indent Already Approved'
    }
  ];
  var initialValue = {};

  initialValue = allData.find(data => {
    if (checkPermission(data.value) || userData?.isAdmin == 1) return data;
  });

  const [value, setValue] = React.useState(initialValue?.value); // first tab

  const tabList = allData.filter(
    x => checkPermission(x.value) || userData?.isAdmin == 1
  );

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    console.log(index);
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  return (
    <Page className={classes.root} title=" Store Indent" id="Indent">
      <ToastContainer />
      <Container maxWidth={false}>
        <Typography className={classes.centerText}>
          <h2 style={{ color: '#394671' }}> Store Indent</h2>
        </Typography>
        {/* <AppBar position="static" color="default" style={{ marginTop: '1em' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {checkPermission('view&edit_indent') && (
              <Tab label="My Store Indent" onClick={() => a11yProps(2)} />
            )}

            {checkPermission('approval_storeIndent') && (
              <Tab label="Store Indent Approval" onClick={() => a11yProps(0)} />
            )}
            {checkPermission('create_storeIndent') && (
              <Tab
                label="Rise A New Store Indent"
                onClick={() => a11yProps(1)}
              />
            )}
             <Tab
                label="All Approved Store Indent"
                onClick={() => a11yProps(3)}
              />
            <Tab label="Create A GIN Report" onClick={() => a11yProps(3)} /> 
          </Tabs>
        </AppBar>       */}

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                {tabList &&
                  tabList.length > 0 &&
                  tabList.map((data, index) => {
                    return (
                      <Tab
                        style={{ fontWeight: 'bold' }}
                        key={index}
                        label={data.name}
                        value={data.value}
                        onClick={() => a11yProps(data.value)}
                      />
                    );
                  })}
              </TabList>
            </Box>

            <>
              {value == 'approval_storeIndent' && (
                <TabPanel
                  key={1}
                  value="approval_storeIndent"
                  index="approval_storeIndent"
                >
                  <StoreApproval />
                </TabPanel>
              )}
               {value == 'view_storeIndent' && (
                <TabPanel
                  key={4}
                  value="view_storeIndent"
                  index="view_storeIndent"
                >
                  <StoreIndent permission= {"view_storeIndent"}/>
                </TabPanel>
              )}

              {value == 'view&edit_storeIndent' && (
                <TabPanel
                  key={0}
                  value="view&edit_storeIndent"
                  index="view&edit_storeIndent"
                >
                  <StoreIndent permission= {"view&edit_storeIndent"}/>
                </TabPanel>
              )}
             

              {value == 'create_storeIndent' && (
                <TabPanel
                  key={2}
                  value="create_storeIndent"
                  index="create_storeIndent"
                >
                  <StoreNewIndent />
                </TabPanel>
              )}
              {value == 'approved_storeIndent' && (
                <TabPanel
                  key={3}
                  value="approved_storeIndent"
                  index="approved_storeIndent"
                >
                  <AllApprovedStoreIndent />
                </TabPanel>
              )}
            </>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
};

export default StoreIndentMain;
