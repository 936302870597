import React, { useEffect, useState } from 'react';
import { Card, CardContent, makeStyles, FormControl, InputLabel, Select, MenuItem, Box } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    root: {},
    formControl: {
        minWidth: 120,
        marginBottom: '1rem'
    },
    selectEmpty: {
        marginTop: '1rem',
    },
}));

const SelectFinancialYear = ({ selectedYear, setSelectedYear }) => {
    const classes = useStyles();

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentFinancialYear = currentMonth >= 3 ? `${currentYear}-${currentYear + 1}` : `${currentYear - 1}-${currentYear}`;
    const [financialYears, setFinancialYears] = useState([]);

    useEffect(() => {
        yearSelection();
    }, []);

    const yearSelection = () => {
        const financialYeardata = [];
        for (let year = 2018; year <= currentYear; year++) {
            const nextYear = year + 1;
            financialYeardata.push(`${year}-${nextYear}`);
        }
        setFinancialYears(financialYeardata)
        setSelectedYear(currentFinancialYear);
    }
    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel id="financial-year-label">Financial Year</InputLabel>
                    <Select
                        labelId="financial-year-label"
                        value={selectedYear}
                        onChange={(e) => {
                            setSelectedYear(e.target.value)
                        }}
                        label="Financial Year"
                    >
                        {financialYears.map((year) => (
                            <MenuItem key={year} value={year} disabled={year > currentFinancialYear}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default SelectFinancialYear