import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import './notice.css';
import CakeIcon from '@mui/icons-material/Cake';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { ToastContainer, toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getProjectDetails } from '../Common/utilities';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function BirthdayNotification({ blist }) {
  // const [blist, setBlist] = useState([]);
  // const { project } = useParams();
  // const projectDetails = { ...getProjectDetails(project) };

  // useEffect(() => {
  //   getBirthdayList();
  // }, [projectDetails.projectID]);

  // const getBirthdayList = () => {
  //   APIKit.get(`${RGCA_URLS.getEmployeeBirthdayListBasedonprojectID + '/' + projectDetails.projectID}`)
  //     .then(res => {
  //       if (res.data.status === 200) {
  //         setBlist(res.data.data);
  //       } else {
  //         setBlist([]);
  //       }
  //     })
  //     .catch(e => {});
  // };

  return (
    <div>
      <Accordion defaultExpanded={false}  style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography >
            <b>  Greetings</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <List sx={{ height: 370, overflowY: 'auto' }}>
            {blist && blist.length > 0 && blist.map(x => {
              return (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" sx={{ color: '#394671' }}>
                        <CakeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={x.empName}
                      primaryTypographyProps={{ fontSize: '18px' }}
                      secondary={
                        <React.Fragment>
                          {`Celebrating ${x.empName} Birthday ${x.dateOfBirth != "" ? moment(
                            x.dateOfBirth
                          ).format('DD MMMM') : "01/01"} !! 🎉`}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>

      {/* <Card sx={{ minWidth: 500, minHeight: 370 }} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}>
        <Typography sx={{ textAlign: 'center', borderTopRightRadius: "12px", borderTopLeftRadius: "12px", fontSize: 15, backgroundColor: '#394671', padding: '5px', fontWeight: 'bold', color: 'white' }} gutterBottom>
          Greetings
        </Typography>
        <CardContent>

        </CardContent>
      </Card> */}
    </div>
  );
}
