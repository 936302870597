import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Paper,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import { Link as RouterLink, useNavigate, useLocation, BrowserRouter as Router,useParams } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useNavigate
} from "react-router-dom";

import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import { getNavigationData } from '../../Redux/Common/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useConfirm } from "material-ui-confirm";
import { Icon, InlineIcon } from '@iconify/react';
import currencyInr from '@iconify-icons/mdi/currency-inr';
import { numberFormat } from "../../utils/common";
import { capitalize } from '@material-ui/core';
import CapitalizedText from "../../utils/CapitalizedText";
import APIKit from "../../utils/APIKit";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useToasts } from 'react-toast-notifications';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import Indent from '../indent';
import Indentitems from '../indentItems';
import Capital from './capital';
import Recurring from './recurring';
import EditProject from '../EditProject';
import { useLocation } from 'react-router-dom'
import { getNavData } from 'src/Redux/Nav/action';
import { date } from '@hapi/joi';
// const location = useLocation() 
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    // flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  MuiTableHeadRoot: {
    backgroundColor: "#3f51b5 !important"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  input: {
    display: "none"
  },
  uploadRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SeabaseHatchery = (props) => {
  // let id = useRouteMatch({path:"seabasehatchery/:id",strict: true,
  // sensitive: true});
  const navigationData = useSelector(x => x.NavigationData.navigationData);
  const navData = useSelector(x => x.NavData.navData);
  const { addToast } = useToasts();
  let { id } = useParams();
  const location = useLocation();
  let query = window.location.pathname.split("/");
  const confirm = useConfirm();
  const classes = useStyles();
  const [openLoader, setOpenLoader] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [btn, setbtn] = React.useState('Save');
  const [expanded, setExpanded] = React.useState(false);
  const [DOB, setDOB] = React.useState(new Date('2014-08-18T21:11:54'));
  const [projectdata, setProjectdata] = React.useState({ "ProjectId": 0, "ProjectName": "" });
  const [breadcrumb, setBreadcrumb] = React.useState("PROFILE")
  const [open, setOpen] = React.useState(false);
  const [openrec, setRecOpen] = React.useState(false);
  const [openinc, setIncOpen] = React.useState(false);
  const [openincEdit, setIncEditOpen] = React.useState(false);
  const [openincPrint, setIncPrintOpen] = React.useState(false);

  const [openindent, setIndents] = React.useState(false);
  const [openCreateIndent, setCreateIndents] = React.useState(false);
  const [openIndentItems, setIndentsItems] = React.useState(false);
  const [resData, setResData] = useState({});
  const [indentItmes, setIndentItems] = useState([]);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [uploadCapitalInvestment, setUploadCapitalInvestment] = React.useState(false);
  const [LoaderView, SetLoaderView] = React.useState(false);
  const [categoryList, setcategoryList] = React.useState(
    [{ CategoryName: 'Cost of Land', id: 'panel2', CategoryID: 1004, ProjectId: null },
    { CategoryName: 'Construction of Civil Works & Building', id: 'panel3', CategoryID: 3, ProjectId: null },
    { CategoryName: 'Equipments & Machineries', id: 'panel4', CategoryID: 1003, ProjectId: null },
    { CategoryName: 'Computer & Printers', id: 'panel5', CategoryID: 7, ProjectId: null },
    { CategoryName: 'Furniture & Fittings', id: 'panel6', CategoryID: 8, ProjectId: null },
    { CategoryName: 'Vehicles', id: 'panel7', CategoryID: 9, ProjectId: null }]
  );
  const [recurringCategory, setRecurringCategory] = useState(
    [
      { CategoryName: 'Maintenance Expenditure', id: 'panel8', CategoryID: 10 },
      { CategoryName: 'Office & Administrative Expenditure', id: 'panel9', CategoryID: 11 },
      { CategoryName: 'Operating Expenditure', id: 'pane20', CategoryID: 12 },

    ]

  )
  const [lookup, setlookup] = React.useState([]);
  const [lookuprec, setlookupRec] = React.useState([]);
  const [capitalInvestmentDetails, setCapitalInvestmentDetails] = React.useState([]);
  const [RecurringDetails, setRecurringDetails] = React.useState([]);
  const dispatch = useDispatch();
  const [addCapitalInvestment, setAddCapitalInvestment] = React.useState(
    {
      "ProjectID": 0,
      "CategoryID": null,
      "TypeOfInvestment": null,
      "Quantity": null,
      "Amount": null,
      "InvoiceID": "",
      "IncomeTax": "",
      "CGST": null,
      "SGST": null,
      "IGST": null,
      "PurchaseOrderID": "",
      "VendorID": "",
      "CapitalInvestmentID": null,
      "ItemsInRepair": null,
      "ItemsInIdle": null
    })

  const [addRecurring, setAddRecurring] = React.useState(
    {
      "ParentSubCategoryId": null,
      "ProjectID": 0,
      "CategoryID": null,
      "TypeOfInvestment": null,
      "Quantity": null,
      "Amount": null,
      "InvoiceID": "",
      "IncomeTax": "",
      "CGST": null,
      "SGST": null,
      "IGST": null,
      "PurchaseOrderID": "",
      "VendorID": "",
      "RecurringExpenditureID": null,
      "ItemsInRepair": null,
      "ItemsInIdle": null
    })
  const [flag, setFlag] = useState('')
  const [indent, setIndent] = useState([{ "projectName": "" }])
  const getIndentItems = (IndentID) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.getIndentItems, {
      "IndentID": IndentID
    },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          setResData(res.data.data)
          res.data.data['approveView'] = "approveview"
          res.data.data['flag'] = "Inventory"
          setIndentItems(res.data.data.IndentItems)
          dispatch(getNavigationData(res.data.data))
          setTimeout(() => {
            handleCloseLoader()
            setInventoryShow(true)
          }, 3000)
        }
        else {
        }
      })
      .catch(function (e) {
      })

  }
  const [inventoryShow, setInventoryShow] = useState(false)
  const [biddingShow, setBiddingShow] = useState(false)

  const viewItems = (data) => {
    handleToggleLoader()
    dispatch(getNavData('Inventory Management'));
    getIndentItems(data.IndentID)
  }
  const getBiddingIndentItems = (IndentID) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.getIndentItems, {
      "IndentID": IndentID
    },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          setResData(res.data.data)
          res.data.data['approveView'] = "approveview"
          res.data.data['flag'] = "Bidding"
          setIndentItems(res.data.data.IndentItems)
          dispatch(getNavigationData(res.data.data, IndentID))
          setTimeout(() => {
            handleCloseLoader()
            setBiddingShow(true)
          }, 3000)
        }
        else {
        }
      })
      .catch(function (e) {
      })

  }
  const viewVendorItems = (data) => {
    dispatch(getNavData('Bidding Management'));
    getBiddingIndentItems(data.IndentID)
  }
  const gotoInventory = () => {
    getBiddingApproveIndents()
    setInventoryShow(false)
  }
  const gotoBidding = () => {
    getBiddingApproveIndents()
    setBiddingShow(false)
  }
  //   useEffect(() => {
  //     getIndentItems();
  //   }, [])
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const LoaderDisplay = (status) => {
    SetLoaderView(status)
  }
  const handleClickRecOpen = (cateID) => {
    setbtn('Save')
    addRecurring.CategoryID = cateID;
    // getRecurring(cateID)
    // setAddRecurring({});
    getSubCategorydataOfCategory(cateID)
    setRecOpen(true);
  };
  const handleDateChange = (date) => {
    setDOB(date);
  };
  const handleClickIncOpen = () => {
    setbtn("Save")
    setIncOpen(true);
  };
  const [openPdf, setOpenPdf] = useState(false)
  const handleClickIncPdf = () => {
    setOpenPdf(true);
  };
  const handleClickIncBackPdf = () => {
    setOpenPdf(false);
  };
  const handleIncClose = () => {
    setIncOpen(false);
    setbtn('Save')
    setAddIncome(
      {
        "ProjectID": null,
        "CategoryID": null,
        "SubCategoryId": null,
        "Rate": null,
        "Quantity": null,
        "DateOfSale": "",
        "DeliveryNote": "",
        "ModeOfPayment": "",
        "SupplierRef": "",
        "Name": "",
        "Email": "",
        "MobileNumber": null,
        "Address": "",
        "BookingOrderNumber": "",
        "DispatchDocumentNumber": "",
        "DispatchedThrough": "",
        "Destination": "",
        "TermsOfDelivery": "",
        "DeliveryNoteDate": ""
      }
    )
  };
  const handleIncEditClose = () => {
    //   setIncomeGenerationID('')
    setOpenLoader(false);
    setIncEditOpen(false);
    getIncome()
    // setbtn('Save')
    // setAddIncome({})
  };
  const handleIncPrintClose = () => {
    setOpenLoader(false);
    setIncPrintOpen(false);
    // setbtn('Save')
    // setAddIncome({})
  };
  const [indentId, setIndentId] = React.useState(0);
  const [approveandreject, setApproveandreject] = React.useState('');
  const [approvenote, setApproveNote] = React.useState('');
  const handleClickIndentOpen = (data, status) => {
    if (status == 4117) {
      setApproveNote('Approve')
    }
    if (status == 4119) {
      setApproveNote('Reject')
    }
    setIndentNote('')
    setIndents(true);
    setIndentId(data.IndentID)
    setApproveandreject(status)

    //indentApproveAndReject(data,status)
  };
  const handleIndentClose = () => {
    setIndents(false);

  };

  const handleCreateIndentClose = () => {
    setCreateIndents(false);

  };
  const handleIndentItemsClose = () => {
    getParticularProject();
    getIndents();
    setIndentsItems(false);

  };
  const handleClickOpen = (cateID) => {
    setbtn('Save')
    addCapitalInvestment.CategoryID = cateID;
    // setAddCapitalInvestment({})
    getSubCategorydataOfCategory(cateID)
    setOpen(true);
  };
  const handleClickEditOpen = (id) => {
    setbtn('Update')
    setAddCapitalInvestment(id)
    addCapitalInvestment.CategoryID = id.CategoryID;
    addCapitalInvestment.CapitalInvestmentID = id.CapitalInvestmentID;
    getSubCategorydataOfCategory(id.CategoryID)
    setOpen(true);
  };

  const handleClickRecEditOpen = (id) => {
    setbtn('Update')
    setAddRecurring(id)
    addRecurring.CategoryID = id.CategoryID;
    addRecurring.RecurringExpenditureID = id.RecurringExpenditureID;
    getSubCategorydataOfCategory(id.CategoryID)
    getParentSubCategory(id)
    setRecOpen(true);
  }
  const uploadCapitalInvestmentView = () => {
    setUploadCapitalInvestment(!uploadCapitalInvestment)
  }

  const handleClose = () => {
    setOpen(false);
    setAddCapitalInvestment({});
  };
  const handleRecClose = () => {
    setRecOpen(false);
    setAddRecurring({})
  };

  const handleChange1 = (panel, categoryID) => (isExpanded) => {
    getCategoryWiseInvestments(categoryID)
    if (panel === expanded) {
      setExpanded(isExpanded ? "Test123" : false);
    }
    else {
      setExpanded(isExpanded ? panel : false);
    }

  };
  const handleChange2 = (panel, categoryID) => (isExpanded) => {
    getRecurring(categoryID)
    // setExpanded(isExpanded ? panel : false);
    if (panel === expanded) {
      setExpanded(isExpanded ? "Test123" : false);
    }
    else {
      setExpanded(isExpanded ? panel : false);
    }

  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleChange = (event, newValue) => {

    setValue(newValue);
    setBreadcrumb(event.target.innerText)
  };

  const navigate = useNavigate();


  const getParticularProject = async () => {
    handleToggleLoader()
    await APIKit.get('/' + APP_URLS.getParticularProject + query[query.length - 1],
      // { headers: { 'Content-Type': 'application/json', 'Authorization':"bearer "+getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.status === 200) {
          handleCloseLoader()
          setProjectdata(res.data.data)

        }
        else {
          handleCloseLoader()
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const a11yProps = (index) => {
    if (index == 0) {
      getCategoryWiseAmonut();
      getrecurringCategorywiseAmonut()
    }
    if (index == 1) {
    }
    if (index == 2) {
      setExpanded(false)
      getCategoryWiseAmonut();
    }
    if (index == 3) {
      setExpanded(false)
      getrecurringCategorywiseAmonut();
    }
    if (index == 4) {
      setExpanded(false)
      getIncome();
    }
    if (index == 5) {
      setFlag('')
      getParticularProject();
      getIndents();
      setIndentsItems(false);
    }
    if (index == 7) {

      setFlag('')
      getParticularProject();
      getApproveIndents()
      setInventoryShow(false)
    }
    if (index == 6) {

      setFlag('')
      getParticularProject();
      getBiddingApproveIndents()
      setBiddingShow(false)
    }
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const [captitalInvestment, setCaptitalInvestment] = useState({ labels: [], datasets: [] });
  const [recurringCategorywiseAmonut, setRecurringCategorywiseAmonut] = useState({ labels: [], datasets: [] });

  const getCategoryWiseAmonut = () => {
    handleToggleLoader()
    let ProjectId = id;
    APIKit.get('/' + APP_URLS.getCategorywiseAmonut + ProjectId)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setlookup(res.data.data);
          let lables = [], data = []
          res.data.data.forEach(x => {
            lables.push(x.CategoryName);
            data.push(x.TotalAmount)
          })

          if (res.data && res.data.data && res.data.data != 0) {
            let panaelId = categoryList.length + 5;
            var newcategoryList = [];
            res.data.data.forEach(x => {
              let find = categoryList.find(y => y.CategoryID == x.CategoryID)
              if (!find) {
                newcategoryList.push({
                  CategoryName: x.CategoryName, id: 'panel' + panaelId, CategoryID: x.CategoryId, ProjectId: null
                })
                panaelId++
              }
            })
            setcategoryList(categoryList.concat(newcategoryList));
          }

          let chart = {
            labels: lables,
            datasets: [
              {
                label: 'Capital',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: data
              }
            ]
          }
          setCaptitalInvestment(chart)

        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const getrecurringCategorywiseAmonut = () => {
    handleToggleLoader()
    let ProjectId = id;
    //  APIKit.get('https://rgca.brugu.io/api/recurring-expenditure/getRecurringCategorywiseAmonut'+'/' + projectdata.ProjectId)
    // APIKit.get('https://rgca.brugu.io/api/recurring-expenditure/getRecurringCategorywiseAmonut' + '/' + ProjectId)
    APIKit.get('/api/recurring-expenditure/getRecurringCategorywiseAmonut' + '/' + ProjectId) //Ashok
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          // setcategoryList
          let lables = [], data = []
          setlookupRec(res.data.data);
          res.data.data.forEach(x => {
            lables.push(x.CategoryName);
            data.push(x.TotalAmount)
          })


          if (res.data && res.data.data && res.data.data != 0) {
            let panaelId = recurringCategory.length + 5;
            var newcategoryList = [];
            res.data.data.forEach(x => {
              let find = recurringCategory.find(y => y.CategoryID == x.CategoryID)
              if (!find) {
                newcategoryList.push({
                  CategoryName: x.CategoryName, id: 'panel' + panaelId, CategoryID: x.CategoryId, ProjectId: null
                })
                panaelId++
              }
            })
            setRecurringCategory(recurringCategory.concat(newcategoryList));
          }


          let chart = {
            labels: lables,
            datasets: [
              {
                label: 'Recurring',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: data
              }
            ]
          }
          setRecurringCategorywiseAmonut(chart)

        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }


  const getCategoryWiseInvestments = (CategoryID) => {
    handleToggleLoader()
    let payload = {
      "ProjectID": projectdata.ProjectId ? projectdata.ProjectId : id,
      "CategoryID": CategoryID
    }
    APIKit.post('/' + APP_URLS.getCapitalInvetment, payload)
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setCapitalInvestmentDetails(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const [subcategory, setSubCategory] = useState([]);

  const [recsubcategory, setRecSubCategory] = useState([]);

  const getSubCategorydataOfCategory = (CategoryID) => {
    handleToggleLoader()
    APIKit.get('/' + APP_URLS.getSubCategorydata + CategoryID)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setSubCategory(res.data.data);

        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const [incomeCat, setIncomeCat] = useState([]);
  const [incomeSubCat, setIncomeSubCat] = useState([]);
  const [AllIncome, setInCome] = useState([]);
  const [TotalIncome, setTotalInCome] = useState(null);
  const [indentNote, setIndentNote] = useState('');

  const [addIncome, setAddIncome] = useState(
    {
      "ProjectID": null,
      "CategoryID": null,
      "SubCategoryId": null,
      "Rate": null,
      "Quantity": null,
      "DateOfSale": "",
      "DeliveryNote": "",
      "ModeOfPayment": "",
      "SupplierRef": "",
      "Name": "",
      "Email": "",
      "MobileNumber": null,
      "Address": "",
      "BookingOrderNumber": "",
      "DispatchDocumentNumber": "",
      "DispatchedThrough": "",
      "Destination": "",
      "TermsOfDelivery": "",
      "DeliveryNoteDate": ""
    }

  );


  const getIncomeCategories = () => {
    handleToggleLoader()
    APIKit.get('/' + APP_URLS.getIncomeCategories,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setIncomeCat(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const getIncomeSubCategory = (incomeid) => {

    handleToggleLoader()
    APIKit.get('/' + APP_URLS.getIncomeSubCategorydata + (incomeid.CategoryId || incomeid.CategoryID))
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setIncomeSubCat(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const saveIncome = () => {
    handleToggleLoader()
    addIncome.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id;
    addIncome.DateOfSale = addIncome.DateOfSale ? addIncome.DateOfSale : new Date()
    APIKit.post(APP_URLS.addIncomeInfo, addIncome,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          // getIncomeSubCategory(res.data.data);
          getIncome();
          setAddIncome({})
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const updateIncome = () => {
    handleToggleLoader()
    addIncome.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id;
    APIKit.post('/' + APP_URLS.updateIncomeInfo, addIncome,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setbtn('Save')
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getIncomeSubCategory(res.data.data);
          getIncome();
          setAddIncome({})
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const getIncome = () => {
    let ProjectId
    if (id) {
      ProjectId = id
    } else {
      ProjectId = projectdata.ProjectId
    }
    setTotalInCome(0)
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.getIncomeInfo,
      {
        "ProjectID": ProjectId
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setInCome(res.data.data)
          setTotalInCome(res.data.data[0].TotalAmount)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const [incomeById, setIncomeById] = useState([])
  const getIncomeById = async (id) => {
    setIncomeGenerationID(id)
    setIncomeById([])
    handleToggleLoader()
    await APIKit.post('/' + APP_URLS.getIncomeInfoByID,
      {
        "IncomeGenerationID": id
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {

          setIncomeById(res.data.data)
          setTimeout(() => {
            setIncEditOpen(true);
            handleCloseLoader()
          }, 3000)

          // setTotalInCome(res.data.data[0].TotalAmount)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const getPrintViewData = async (id) => {

    setIncomeGenerationID(id)
    setIncomeById([])
    handleToggleLoader()
    await APIKit.post('/' + APP_URLS.getIncomeInfoByID,
      {
        "IncomeGenerationID": id
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {

          setIncomeById(res.data.data)


          // setTotalInCome(res.data.data[0].TotalAmount)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const [IncomeEachItem, setIncomeEachItem] = useState(
    {
      "IncomeGenerationID": "",
      "ProjectID": null,
      "CategoryID": null,
      "SubCategoryId": null,
      "Rate": null,
      "Quantity": "",
      "DateOfSale": "",
    }

  );
  const saveIncomeItem = () => {
    handleToggleLoader()
    IncomeEachItem.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id;
    IncomeEachItem.IncomeGenerationID = IncomeGenerationID
    IncomeEachItem.DateOfSale = addIncome.DateOfSale ? addIncome.DateOfSale : new Date()

    APIKit.post('/' + APP_URLS.addNewItem, IncomeEachItem,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getIncomeById(IncomeGenerationID)
          setaddIncomeRecord(false)
          setIncomeEachItem({})
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        //  handleCloseLoader()
      })
  }
  const updateIncomeEachItem = () => {
    APIKit.post('/' + APP_URLS.updateIncomeInfo, IncomeEachItem,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getIncomeById(IncomeGenerationID)
          setaddIncomeRecord(false)
          setbtn('Save')
          // handleIncEditClose()
          setIncomeEachItem({})
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        //  handleCloseLoader()
      })
  }

  const [IncomeGenerationID, setIncomeGenerationID] = useState('')
  const handleClickIncomEditOpen = (data) => {
    getIncomeById(data.IncomeGenerationID)

  }
  const handleClickChildIncomEditOpen = (data) => {
    getIncomeSubCategory(data)
    setIncomeEachItem({
      "IncomeGenerationItemsID": Number(data.IncomeGenerationItemsID),
      "IncomeGenerationID": Number(data.IncomeGenerationID),
      "ProjectID": Number(data.ProjectID),
      "CategoryID": Number(data.CategoryID),
      "SubCategoryId": Number(data.SubCategoryId),
      "Rate": Number(data.Rate),
      "Quantity": Number(data.Quantity),
      "DateOfSale": data.DateOfSale,
    })
    setbtn('Update')
    setaddIncomeRecord(true)
  }
  const printView = async (data) => {
    await getPrintViewData(data.IncomeGenerationID)
    setIncPrintOpen(true);
    //   printOrder()
  }
  const handleClickIncomPrintOpen = (id) => {

    setIncPrintOpen(true);
  }
  const handleClickIncRemove = (data) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteIncomeRecord,
      {
        "ProjectID": data.ProjectID,
        "IncomeGenerationID": data.IncomeGenerationID

      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          // setSubCategory(res.data.data)
          handleCloseLoader()
          getIncome();
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getIncomeSubCategory(res.data.data);
          getIncomeById(data.IncomeGenerationID)
          getIncome();
          setAddIncome({})
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const handleClickEachIncRemove = (data) => {

    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteIncomeInfo,
      {
        "IncomeGenerationItemsID": data.IncomeGenerationItemsID,
        "ProjectID": data.ProjectID,
        "IncomeGenerationID": data.IncomeGenerationID

      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          // setSubCategory(res.data.data)
          handleCloseLoader()
          getIncome();
          setOpen(false)
          setIncOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getIncomeById(data.IncomeGenerationID)
          getIncome();
          setAddIncome({})
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const updateStatus = () => {
    getBiddingApproveIndents()
    handleIndentItemsClose();
    getIndents()
    setInventoryShow(false)
    if (navData == 'Inventory Management') {
      a11yProps(7)
      setValue(7)

    } else {
      a11yProps(5)
      setValue(5)
    }


  }

  const handleClickCapRemove = (data) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteCapitalInvestment, {
      "ProjectId": data.ProjectID,
      "CapitalInvestmentId": data.CapitalInvestmentID

    })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getCategoryWiseInvestments(data.CategoryID);
          getCategoryWiseAmonut();
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const handleClickRecRemove = (data) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteCapitalInvestment, {
      "ProjectId": data.ProjectID,
      "RecurringExpenditureId": data.RecurringExpenditureID

    })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          handleCloseLoader()
          setOpen(false)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getCategoryWiseInvestments(data.CategoryID);
          getCategoryWiseAmonut();
        }
        else {
          handleCloseLoader()
          setOpen(false)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const deleteFun = (data, type) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        if (type == 'Capital') {
          handleClickCapRemove(data)
        }
        if (type == 'Recurring') {
          handleClickRecRemove(data)
        }
        if (type == 'Income') {
          handleClickIncRemove(data)
        }
        if (type == 'ChildIncomeItem') {
          handleClickEachIncRemove(data)
        }
        // activeOrInactive(status,id)
      })
      .catch(() => console.log("Deletion cancelled."));


  }
  const getParentSubCategory = (data) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.getParentSubCategorydata, {

      "CategoryId": data.CategoryId || data.CategoryID, // categoryd
      "ParentSubCategoryId": data.SubCategoryId || data.ParentSubCategoryId //subcategoryid

    })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setRecSubCategory(res.data.data)
        }
        else {
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const savecapitalInvestment = async () => {
    handleToggleLoader()
    addCapitalInvestment.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id
    // addCapitalInvestment.CategoryID = projectdata.ProjectId
    APIKit.post('/' + APP_URLS.addCapitalInvetment, addCapitalInvestment)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getCategoryWiseInvestments(res.data.data);
          getCategoryWiseAmonut();
          setAddCapitalInvestment({})
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }
  const updatecapitalInvestment = async () => {
    handleToggleLoader()
    addCapitalInvestment.ProjectID = projectdata.ProjectId
    // addCapitalInvestment.CategoryID = projectdata.ProjectId
    APIKit.post('/' + APP_URLS.UpdateCapitalInvestment, addCapitalInvestment)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(false);
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getCategoryWiseInvestments(res.data.data);
          getCategoryWiseAmonut()
          setAddCapitalInvestment({})
          a11yProps(2)
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const getRecurring = (CategoryID) => {
    handleToggleLoader()
    let payload = {
      "ProjectID": projectdata.ProjectId ? projectdata.ProjectId : id,
      "CategoryID": CategoryID
    }
    APIKit.post('/' + APP_URLS.getRecurringExpenditure, payload)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setRecurringDetails(res.data.data);

        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const saveRecurring = async () => {
    handleToggleLoader()
    addRecurring.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id;
    APIKit.post('/' + APP_URLS.addRecurringExpenditure, addRecurring)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setRecOpen(false)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getRecurring(res.data.data);
          getrecurringCategorywiseAmonut()
          getCategoryWiseAmonut();
          setAddRecurring({});
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const updateRecurring = async () => {
    handleToggleLoader()
    addRecurring.ProjectID = projectdata.ProjectId ? projectdata.ProjectId : id
    APIKit.post('/' + APP_URLS.updateRecurringExpenditure, addRecurring)
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setRecOpen(false)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getRecurring(res.data.data);
          getrecurringCategorywiseAmonut()
          getCategoryWiseAmonut();
          setAddRecurring({});
          a11yProps(3)
        }
        else {
          handleCloseLoader()
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
      })
  }

  const [openEditProject, setEditProject] = React.useState(false);

  const editProject = async (data) => {
    setProjectName(data.ProjectName)
    await dispatch(getNavigationData(data))
    setEditProject(true)
    //  navigate('/app/EditProject/' + data.ProjectId, { replace: true });

  }
  const handleEditProjectClose = () => {
    if (id) {
      getParticularProject();
      getIncomeCategories();
      getCategoryWiseAmonut();
      getrecurringCategorywiseAmonut();
      getBiddingApproveIndents()
      getIndents();
      getIncome();
      getApproveIndents();
      getRecurring();
      getCategoryWiseInvestments();
      setExpanded(false)

    } else {

    }
    setEditProject(false);

  };

  const [popdata, setPopData] = useState({})
  const handleClickCreateIndent = async (data, type) => {
    data['RaisedFrom'] = type;
    data['CreateIndent'] = 'New';
    setPopData(data)
    await dispatch(getNavigationData(data))
    setCreateIndents(true)
    // navigate('/app/Indent', { replace: true });
  }

  const handleClickIndentDetails = async (data) => {
    await dispatch(getNavigationData(data))
    navigate('/app/IndentDetails', { replace: true });
  }



  useEffect(() => {
    if (id) {
      getParticularProject();
      getIncomeCategories();
      getCategoryWiseAmonut();
      getrecurringCategorywiseAmonut();
      getBiddingApproveIndents()
      getIndents();
      getIncome();
      getApproveIndents();
      getRecurring();
      getCategoryWiseInvestments();
      setExpanded(false)
      setIndentsItems(false)
      setInventoryShow(false)
      setBiddingShow(false)

    } else {

    }

  }, [id])
  useEffect(() => {

    if (navData == 'Inventory Management') {
      getParticularProject();
      a11yProps(7)
      setValue(7)
      getApproveIndents()
    }
    if (navData == 'Indent') {
      getParticularProject();
      a11yProps(5)
      setValue(5)
      getIndents()
    }
  }, [navData])

  const test = () => {
    navigate('/app/TaxForm', { replace: true });
  }


  const handleUploadClick = event => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    handleToggleLoader()
    LoaderDisplay(true)
    var file = event.target.files[0];
    const formData = new FormData();
    formData.append('document', event.target.files[0], event.target.files[0].name);
    APIKit.post('/' + APP_URLS.uploadCapitalInvetment, formData)
      .then((res) => {
        LoaderDisplay(false)
        if (res.data.status == 200) {
          handleCloseLoader()
          addToast(res.data.message, { appearance: 'success' });
          getCategoryWiseAmonut()
        }
        else {
          handleCloseLoader()
          addToast(res.data.message, { appearance: 'error' });
        }

      })
      .catch(function (e) {
        handleCloseLoader()
        LoaderDisplay(false)
        addToast(e.message, { appearance: 'error' });
      })
  };

  const handleUploadClickRecuring = event => {
    handleToggleLoader()
    LoaderDisplay(true)
    var file = event.target.files[0];
    const formData = new FormData();
    formData.append('document', event.target.files[0], event.target.files[0].name);
    APIKit.post('/' + APP_URLS.uploadRecuring, formData)
      .then((res) => {
        LoaderDisplay(false)
        if (res.data.status == 200) {
          handleCloseLoader()
          addToast(res.data.message, { appearance: 'success' });
          getrecurringCategorywiseAmonut()
        }
        else {
          handleCloseLoader()
          addToast(res.data.message, { appearance: 'error' });
        }

      })
      .catch(function (e) {
        handleCloseLoader()
        LoaderDisplay(false)
        addToast(e.message, { appearance: 'error' });
      })
  };
  const getIndents = () => {
    handleToggleLoader()
    let ProjectId
    if (id) {
      ProjectId = id
    } else {
      ProjectId = projectdata.ProjectId
    }
    // if (projectdata.ProjectId == 0) {
    //     ProjectId = id
    // } else {
    //     ProjectId = projectdata.ProjectId
    // }
    APIKit.post('/' + APP_URLS.getIndents, {
      "ProjectID": ProjectId
    }
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          res.data.data.forEach((x) => {
            x['projectName'] = projectdata.ProjectName
          })

          setIndent(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
      })

  }

  const getApproveIndents = () => {
    let ProjectId
    if (id) {
      ProjectId = id
    } else {
      ProjectId = projectdata.ProjectId
    }
    // if (projectdata.ProjectId == 0) {
    //     ProjectId = id
    // } else {
    //     ProjectId = projectdata.ProjectId
    // }
    handleToggleLoader()
    APIKit.get('/' + APP_URLS.getIndents + '/' + ProjectId,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          res.data.data.forEach((x) => {
            x['projectName'] = projectdata.ProjectName
          })

          setIndent(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
      })

  }
  const getBiddingApproveIndents = () => {
    let ProjectId
    if (id) {
      ProjectId = id
    } else {
      ProjectId = projectdata.ProjectId
    }
    // if (projectdata.ProjectId == 0) {
    //     ProjectId = id
    // } else {
    //     ProjectId = projectdata.ProjectId
    // }
    handleToggleLoader()
    APIKit.get('/' + APP_URLS.getapprovedIndents + ProjectId,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          res.data.data.forEach((x) => {
            x['projectName'] = projectdata.ProjectName;
            let data = x.VendorName.filter(y => y !== null)
            x['VendorName'] = data.join(', ')

          })

          setIndent(res.data.data)
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
      })
  }
  const [projectName, setProjectName] = useState('');
  const handleClickIndentItems = async (data) => {
    setProjectName(data.projectName)
    await dispatch(getNavigationData(data))
    setIndentsItems(true)
    //   navigate('/app/IndentItems', { replace: true });
  }
  const changeStatus = (status, id) => {

    confirm({ description: "Do you want to remove indent ?" })
      .then(() => {
        activeOrInactive(status, id)
      })
      .catch(() => console.log("Deletion cancelled."));
  }

  const deleteForIndent = () => {

    confirm({ description: "Do you want to remove capital investment data ?" })
      .then(() => {
        indentDelete()
      })
      .catch(() => console.log("Deletion cancelled."));
  }

  const deleteForRecData = () => {
    confirm({ description: "Do you want to remove recurring expenditure data ?" })
      .then(() => {
        RecDataDelete()
      })
      .catch(() => console.log("Deletion cancelled."));
  }

  const indentDelete = () => {
    handleToggleLoader()

    APIKit.post('/' + APP_URLS.deleteCapitalInvestmentData,
      {
        "ProjectId": projectdata.ProjectId ? projectdata.ProjectId : id
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          getCategoryWiseAmonut();
          getrecurringCategorywiseAmonut()
          getIndents();


        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
        setAlertOpen(true)
        setAlertMeg('error');
        setErrorMeg('error');
      })
  }

  const RecDataDelete = () => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteRecurringExpenditureData,
      {
        "ProjectId": projectdata.ProjectId ? projectdata.ProjectId : id
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          getrecurringCategorywiseAmonut();
          getIndents();


        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
        setAlertOpen(true)
        setAlertMeg('error');
        setErrorMeg('error');
      })
  }
  const activeOrInactive = (status, id) => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.deleteIndent, {
      "IndentID": id.IndentID,
      "Status": status
    }
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          getIndents();

        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
        handleCloseLoader()
        setAlertOpen(true)
        setAlertMeg('error');
        setErrorMeg('error');
      })
  }

  const indentApproveAndReject = () => {
    handleToggleLoader()
    let payload = {
      "IndentID": indentId,
      "IndentStatus": approveandreject,
      "Note": indentNote
    }
    APIKit.post('/' + APP_URLS.updateIndentStatus, payload
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          getIndents();
          handleIndentClose();

        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          handleIndentClose();
        }
      })
      .catch(function (e) {
        handleCloseLoader()
        setAlertMeg('error');
        setErrorMeg('error');
      })
  }
  const statusBind = (item) => {

    if (item.IndentStatus === 21524) {
      return <span style={{ color: "orange" }}>{item.status}</span>
    }

    if (item.IndentStatus === 4117) {
      return <span style={{ color: "green" }}>{item.status}</span>
    }

    if (item.IndentStatus === 4118) {
      return <span style={{ color: "orange" }}>{item.status}</span>
    }

    if (item.IndentStatus === 4119) {
      return <span style={{ color: "red" }}>{item.status}</span>
    }
    if (item.IndentStatus === 23524) {
      return <span style={{ color: "orange" }}>{item.status}</span>
    }

  }
  const closeIndent = (data) => {
    handleToggleLoader()
    let payload = {
      "IndentID": data.IndentID,
    }
    APIKit.post('/' + APP_URLS.closeIndent, payload
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          getApproveIndents();
          handleIndentClose();

        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          handleIndentClose();
        }
      })
      .catch(function (e) {
        handleCloseLoader()
        setAlertMeg('error');
        setErrorMeg('error');
      })
  }
  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
        <style>
        
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    /* text-align: right; */
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
    text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
    /* text-align: left; */
}
        </style>
        </head><body>'${printableElements}'</body></html>`;
    // console.log(html)
    document.body.innerHTML = html
    window.print();
    document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    window.location.reload()
  }

  const exportPdf = () => {

    html2canvas(document.querySelector("#printme")).then(canvas => {
      document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save("download.pdf");
    });

  }
  const [addIncomeRecord, setaddIncomeRecord] = useState(false)
  const addIncomeItem = () => {
    setIncomeEachItem({
      "IncomeGenerationID": "",
      "ProjectID": null,
      "CategoryID": null,
      "SubCategoryId": null,
      "Rate": null,
      "Quantity": "",
      "DateOfSale": "",
    })
    setaddIncomeRecord(true)
    setbtn("Save")
  }
  const incomeClose = () => {
    setaddIncomeRecord(false)
  }
  let totalQuantity = 0;
  let totalSGST = 0;
  let totalCGST = 0;
  let totalIGST = 0;
  let totalPrice = 0;
  const price_in_words = (price) => {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (price += "", isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
      }
      str = words.reverse().join("")
    } else str = "";
    return str

  }
  return (
    <Page
      className={classes.root}
      title="Projects">

      <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
        <CircularProgress style={{ color: '#fff' }} />
      </Backdrop>

      <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
        <Alert onClose={handleAlertClose} severity={alertMeg} >
          {errorMeg}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        {projectdata && projectdata.ProjectName != undefined ?
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: '#000' }}  >
              Projects
            </Link>
            <Link style={{ color: '#000' }} >
              {projectdata.ProjectName ? projectdata.ProjectName : ''}
            </Link>
            <Link style={{ color: '#000' }}>
              {breadcrumb}

            </Link>

          </Breadcrumbs>
          : null}
        {/* <Toolbar /> */}
        <Box mt={3}>
          <Card>
            <CardContent>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Dashboard" onClick={() => a11yProps(0)} />

                  <Tab label="Profile" onClick={() => a11yProps(1)} />

                  <Tab label="Capital Investment" onClick={() => a11yProps(2)} />
                  <Tab label="Recurring Expenditure" onClick={() => a11yProps(3)} />
                  {/* <Tab label="Income Generation" onClick={() => a11yProps(4)} /> */}
                  <Tab label="Selling" onClick={() => a11yProps(4)} />
                  <Tab label="Indents" onClick={() => a11yProps(5)} />
                  <Tab label="Bidding Management" onClick={() => a11yProps(6)} />
                  {/* <Tab label="Inventory Management" onClick={() => a11yProps(7)} /> */}
                  <Tab label="Purchasing" onClick={() => a11yProps(7)} />

                </Tabs>
              </AppBar>
              {projectdata ?
                <TabPanel value={value} index={1}>
                  <Typography>
                    <h3 style={{ color: "orange" }}> <b>Introduction of the project</b> :</h3>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => editProject(projectdata)}
                      >
                        Edit
                      </Button>
                    </div>
                    <p>
                      {projectdata.ProjectInfo}
                    </p>
                  </Typography>
                  &nbsp;

                  <Grid
                    container
                    spacing={3}
                  >

                    <Grid md={12} item>
                      <Card className={classes.root} style={{ background: "#394671", color: "#fff" }}>
                        <CardHeader

                          action={
                            <IconButton aria-label="settings">
                              {/* <EditIcon /> */}
                            </IconButton>
                          }
                          title={projectdata.ProjectName}
                          style={{ color: "orange !important" }}
                        />

                        <CardContent style={{ marginLeft: "12px" }}>
                          <Grid container>
                            <Grid md={4}>
                              <Grid md={12} className="mb-8" style={{ color: "orange" }}> <b>Total area of the project</b> </Grid>
                              <Grid md={12} className="mb-8" style={{ color: "orange" }}><b> Ownership and details</b> </Grid>
                              <Grid md={12} className="mb-8" style={{ color: "orange" }}><b> Seed Production</b> </Grid>
                              <Grid md={12} className="mb-8" style={{ color: "orange" }}><b> Mobile Number</b> </Grid>
                              <Grid md={12} className="mb-8" style={{ color: "orange" }}><b> Address</b> </Grid>
                            </Grid>
                            <Grid md={8}>
                              <Grid md={12} className="mb-8"> : {projectdata.TotalLandArea} Acres </Grid>
                              <Grid md={12} className="mb-8"> : {projectdata.ProjectName} </Grid>

                              <Grid md={12}> :
                                {projectdata && projectdata.SeedProduction && projectdata.SeedProduction.map((item, index) => {
                                  return <span> {(index ? ', ' : '') + item.seedName}</span>
                                })}
                              </Grid>
                              <Grid md={12} className="mb-8"> : {projectdata.MobileNumber} </Grid>
                              <Grid md={12} className="mb-8"> : {projectdata.Address} </Grid>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
                : null}
              <TabPanel value={value} index={0}>
                <Container maxWidth={false}>
                  <Box>
                    <Card>
                      <CardContent>

                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            lg={6}
                            md={12}
                            xl={6}
                            xs={12}
                          >
                            <Capital CHART={captitalInvestment} />

                            {/* {captitalInvestment.labels.length != 0 && (  <Capital CHART={captitalInvestment} />  )} */}

                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={12}
                            xl={6}
                            xs={12}
                          >
                            <Recurring CHART={recurringCategorywiseAmonut} />
                          </Grid>


                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Container>
              </TabPanel>
              <TabPanel value={value} index={2}>

                {/* capital */}
                <Grid container spacing={3} style={{ textAlign: "right" }}>
                  <Grid md={9}>&nbsp;</Grid>
                  <Grid md={2}>
                    {getloggedInData()?.role == 'Admin' ?
                      <div style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" onClick={deleteForIndent} component="span">
                          Delete
                        </Button>
                      </div>
                      : null}
                  </Grid>
                  <Grid md={1}>
                    <div style={{ textAlign: "right" }}>

                      <div className={classes.uploadRoot}>
                        <input

                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleUploadClick}
                        />
                        <label htmlFor="contained-button-file">

                          <Button variant="contained" color="primary" component="span">
                            Upload
                          </Button>
                        </label>

                      </div>

                    </div>
                  </Grid>
                </Grid>




                &nbsp;
                <div>

                  {categoryList.length != 0 && categoryList.map(cat => {
                    let data = lookup.filter(x => x.CategoryName == cat.CategoryName);

                    if (data && data.length != 0) {
                      cat.CategoryID = data[0].CategoryID;
                      cat.ProjectId = projectdata.ProjectId ? projectdata.ProjectId : id
                    }
                    else {
                      cat.CategoryID = cat.CategoryID;
                      cat.ProjectId = projectdata.ProjectId ? projectdata.ProjectId : id
                    }

                    return <Accordion expanded={expanded === cat.id} onClick={handleChange1(cat.id, cat.CategoryID)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={cat.id + 'bh-content'}
                        id={cat.id + 'bh-header'}
                        aria-label="Expand"

                      >
                        <div style={{ width: "70%" }} onClick={handleChange1(cat.id, cat.CategoryID)}>

                          {data && data.length != 0 ?
                            <Grid container>
                              <Grid item xs={12} lg={8}>
                                <div className={classes.column}>
                                  <Typography className={classes.heading}> <b>{cat.CategoryName}</b> </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} lg={4}>
                                <div className={classes.column}>
                                  {/* <Typography className={classes.secondaryHeading}><span style={{color:"orange"}}><b>Total Amount</b> :</span>&nbsp; <Icon icon={currencyInr} /> <span style={{color:"green"}}>{numberFormat(data[0].TotalAmount)} </span></Typography> */}

                                  <Typography className={classes.secondaryHeading}><Icon icon={currencyInr} />
                                    <span style={{ color: "green" }}><b>{numberFormat(data[0].TotalAmount)}</b> </span></Typography>
                                </div>
                              </Grid>
                            </Grid>
                            : <Typography className={classes.heading}><b>{cat.CategoryName}</b>

                            </Typography>

                          }
                        </div>
                        <div style={{ width: "30%", textAlign: "right", alignItems: "end" }}>
                          {/* <Button
                                                        color="primary"
                                                        variant="contained" onClick={() => handleClickIndentDetails(cat)}>
                                                        Indent Details
                                               </Button> */}



                          &nbsp;
                          <Button
                            color="primary"
                            variant="contained" onClick={() => handleClickCreateIndent(cat, 'capital')}>
                            Create Indent
                          </Button>
                          &nbsp;
                          <Button
                            color="primary"
                            variant="contained" onClick={() => handleClickOpen(cat.CategoryID)}>
                            Add
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>




                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Types</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>InvoiceId</TableCell>
                                <TableCell>Purchase OrderId</TableCell>
                                <TableCell>Income Tax</TableCell>
                                <TableCell>SGST</TableCell>
                                <TableCell>CGST</TableCell>
                                <TableCell>IGST</TableCell>
                                <TableCell>Idle Items</TableCell>
                                <TableCell>Repair Items</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {capitalInvestmentDetails.length != 0 && capitalInvestmentDetails.map((row) => (
                                <TableRow >
                                  <TableCell>{row.SubCategoryName}</TableCell>
                                  <TableCell>
                                    <Icon icon={currencyInr} /> {numberFormat(row.Amount)}
                                  </TableCell>
                                  <TableCell>{row.InvoiceID}</TableCell>
                                  <TableCell>{row.PurchaseOrderID}</TableCell>
                                  <TableCell>{row.IncomeTax}</TableCell>
                                  <TableCell>{row.SGST}</TableCell>
                                  <TableCell>{row.CGST}</TableCell>
                                  <TableCell>{row.IGST}</TableCell>
                                  <TableCell>{row.ItemsInIdle}</TableCell>
                                  <TableCell>{row.ItemsInRepair}</TableCell>
                                  <TableCell>{row.Quantity}</TableCell>
                                  <TableCell>
                                    <EditIcon onClick={() => handleClickEditOpen(row)} />
                                    <DeleteOutlineIcon onClick={() => deleteFun(row, 'Capital')} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>


                      </AccordionDetails>
                    </Accordion>

                  })}

                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                {/* capital */}
                <Grid container spacing={3} style={{ textAlign: "right" }}>
                  <Grid md={9}>&nbsp;</Grid>
                  <Grid md={2}>
                    {getloggedInData()?.role == 'Admin' ?
                      <div style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" onClick={deleteForRecData} component="span">
                          Delete
                        </Button>
                      </div>
                      : null}
                  </Grid>
                  <Grid md={1}>
                    <div style={{ textAlign: "right" }}>
                      <div className={classes.uploadRoot}>
                        <input

                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleUploadClickRecuring}
                        />
                        <label htmlFor="contained-button-file">
                          <Button variant="contained" color="primary" component="span">
                            Upload
                          </Button>
                        </label>

                      </div>
                    </div>
                  </Grid>
                </Grid>

                &nbsp;
                <div>

                  {recurringCategory.length != 0 && recurringCategory.map(rec => {
                    let data = lookuprec.filter(x => x.CategoryName == rec.CategoryName);
                    if (data && data.length != 0) {
                      rec.CategoryID = data[0].CategoryID;
                      rec.ProjectId = projectdata.ProjectId ? projectdata.ProjectId : id;
                    }
                    else {
                      rec.CategoryID = rec.CategoryID;
                      rec.ProjectId = projectdata.ProjectId ? projectdata.ProjectId : id;
                    }

                    return <Accordion expanded={expanded === rec.id} onClick={handleChange2(rec.id, rec.CategoryID)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={rec.id + 'bh-content'}
                        id={rec.id + 'bh-header'}
                      >
                        <div style={{ width: "70%" }} onClick={handleChange2(rec.id, rec.CategoryID)}>


                          {data && data.length != 0 ?
                            <Grid container>
                              <Grid item xs={8}>
                                <div className={classes.column}>
                                  <Typography className={classes.heading}><b>{rec.CategoryName}</b></Typography>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <div className={classes.column}>
                                  {/* <Typography className={classes.secondaryHeading}><span style={{ color: "orange" }}><b>Total Amount</b> :</span>&nbsp; &nbsp; <Icon icon={currencyInr} /> <span style={{ color: "green" }}>{data[0].TotalAmount}</span> </Typography> */}
                                  <Typography className={classes.secondaryHeading}> <Icon icon={currencyInr} /> <span style={{ color: "green" }}><b>{numberFormat(data[0].TotalAmount)}</b></span> </Typography>
                                </div>
                              </Grid>
                            </Grid>
                            : <Typography className={classes.heading}><b>{rec.CategoryName}</b>

                            </Typography>

                          }
                        </div>
                        <div style={{ width: "30%", textAlign: "right", alignItems: "end" }}>
                          <Button
                            color="primary"
                            variant="contained" onClick={() => handleClickCreateIndent(rec, 'recurring')}>
                            Create Indent
                          </Button>
                          &nbsp;
                          <Button
                            color="primary"
                            variant="contained" onClick={() => handleClickRecOpen(rec.CategoryID)}>
                            Add
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>




                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Types</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>InvoiceId</TableCell>
                                <TableCell>Purchase OrderId</TableCell>
                                <TableCell>Income Tax</TableCell>
                                <TableCell>SGST</TableCell>
                                <TableCell>CGST</TableCell>
                                <TableCell>IGST</TableCell>
                                <TableCell>Idle Items</TableCell>
                                <TableCell>Repair Items</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {RecurringDetails && RecurringDetails.length != 0 && RecurringDetails.map((row) => (
                                <TableRow >
                                  {/* <TableCell>{row.SubCategoryName}</TableCell> */}
                                  <TableCell>{row.ParentSubCategoryName}</TableCell>
                                  <TableCell>
                                    <Icon icon={currencyInr} />  {numberFormat(row.Amount)}
                                  </TableCell>
                                  <TableCell>{row.InvoiceID}</TableCell>
                                  <TableCell>{row.PurchaseOrderID}</TableCell>
                                  <TableCell>{row.IncomeTax}</TableCell>
                                  <TableCell>{row.SGST}</TableCell>
                                  <TableCell>{row.CGST}</TableCell>
                                  <TableCell>{row.IGST}</TableCell>
                                  <TableCell>{row.ItemsInIdle}</TableCell>
                                  <TableCell>{row.ItemsInRepair}</TableCell>
                                  <TableCell>{row.Quantity}</TableCell>
                                  <TableCell>
                                    <EditIcon onClick={() => handleClickRecEditOpen(row)} />
                                    <DeleteOutlineIcon onClick={() => deleteFun(row, 'Recurring')} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>


                      </AccordionDetails>
                    </Accordion>

                  })}

                </div>

              </TabPanel>
              <TabPanel value={value} index={4}>
                {openPdf == false ?
                  <div>
                    <div>
                      <span style={{ color: "orange" }}><b> Total Income </b> </span> : <Icon icon={currencyInr} />  <span style={{ color: "green" }}>{numberFormat(TotalIncome ? TotalIncome : "0")} </span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        variant="contained" onClick={handleClickIncOpen}>
                        Add
                      </Button>

                    </div>
                    &nbsp;
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Types</TableCell>
                            <TableCell>CM</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>No's Supply</TableCell>
                            <TableCell>Amount(All Items)</TableCell>
                            <TableCell>Entry Date</TableCell>
                            <TableCell>Actions</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {AllIncome && AllIncome.length != 0 && AllIncome.map((x, index) => {
                            return <TableRow >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {x.CategoryName}
                              </TableCell>
                              <TableCell>{x.SubCategoryName}</TableCell>
                              <TableCell><Icon icon={currencyInr} /> {numberFormat(x.Rate)}.00</TableCell>
                              <TableCell>{numberFormat(x.Quantity)}</TableCell>
                              <TableCell><Icon icon={currencyInr} /> {numberFormat(x.Amount)}.00</TableCell>
                              <TableCell>{moment(x.DateOfSale).format('dd/MM/yyyy')}</TableCell>

                              <TableCell>
                                {/* <EditIcon onClick={() => handleClickIncomEditOpen(x)} /> */}
                                <AddCircleIcon style={{ cursor: "pointer" }} onClick={() => handleClickIncomEditOpen(x)} />&nbsp;
                                <VisibilityIcon style={{ cursor: "pointer" }} onClick={() => printView(x)} />&nbsp;
                                <DeleteOutlineIcon style={{ cursor: "pointer" }} onClick={() => deleteFun(x, 'Income')} />

                              </TableCell>
                            </TableRow>
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  : null}

              </TabPanel>
              <TabPanel value={value} index={5}>
                <div>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: 'orange', fontSize: '17px', fontWeight: 'bold' }}>
                      <Link onClick={handleIndentItemsClose}> Indent Details</Link>
                    </Grid>

                  </Grid>
                  <Box mt={3}>
                    {openIndentItems == false ?
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>

                              <TableCell>
                                S.No
                              </TableCell>
                              <TableCell>
                                Category Name
                              </TableCell>
                              <TableCell style={{ width: "30%" }}>
                                Description
                              </TableCell>
                              <TableCell>
                                Created Date
                              </TableCell>
                              <TableCell>
                                Status
                              </TableCell>
                              <TableCell>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {indent && indent.length != 0 && indent.map((ind, index) => {
                              return <TableRow
                                hover
                                key={index + 1}
                              >


                                <TableCell>
                                  {ind.UniqueIndentId}
                                </TableCell>
                                <TableCell>
                                  {ind.CategoryName ? ind.CategoryName : '-'}
                                </TableCell>

                                <TableCell>
                                  {ind.Description}

                                </TableCell>
                                <TableCell>
                                  {moment(ind.CreatedDate).format('dd/MM/yyyy hh:mm')}

                                </TableCell>
                                <TableCell>

                                  {statusBind(ind)}

                                </TableCell>
                                <TableCell>
                                  <Button
                                    // color="primary"
                                    variant="outlined"
                                    style={{
                                      "padding": "2px",
                                      "top": "-6px",
                                      "color": "#fff",
                                      "border": "1px solid #394671",
                                      "backgroundColor": "#394671"
                                    }}
                                    onClick={() => handleClickIndentItems(ind)}
                                  >
                                    View
                                  </Button>

                                </TableCell>
                              </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      : null}
                    {openIndentItems == true ?
                      <Indentitems updateStatus={updateStatus} handleIndentItemsClose={handleIndentItemsClose} gotoBidding={gotoBidding} gotoInventory={gotoInventory} />
                      : null}
                  </Box>

                </div>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <div>
                  {inventoryShow == false ?
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: 'orange', fontSize: '17px', fontWeight: 'bold' }}>
                        Inventory Management Details
                      </Grid>
                    </Grid>
                    : null}
                  {inventoryShow == false ?
                    <Box mt={3}>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>

                              <TableCell>
                                S.No
                              </TableCell>
                              <TableCell>
                                Category Name
                              </TableCell>
                              <TableCell style={{ width: "15%" }}>
                                Description
                              </TableCell>
                              <TableCell>
                                Created Date
                              </TableCell>
                              <TableCell>
                                Status
                              </TableCell>
                              <TableCell>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {indent && indent.length != 0 && indent.map((ind, index) => {
                              return <TableRow
                                hover
                              >
                                <TableCell>
                                  {ind.UniqueIndentId}
                                </TableCell>
                                <TableCell>
                                  {ind.CategoryName ? ind.CategoryName : '-'}
                                </TableCell>

                                <TableCell>
                                  {ind.Description}

                                </TableCell>
                                <TableCell>
                                  {moment(ind.CreatedDate).format('dd/MM/yyyy hh:mm')}

                                </TableCell>
                                <TableCell>
                                  {ind.status == 'Close' ?
                                    <span style={{ color: "orange" }}>{ind.status + 'd'}</span>
                                    : null}
                                  {ind.status == 'Pending' ?
                                    <span style={{ color: "orange" }}>{ind.status}</span>
                                    : null}
                                  {ind.status == 'Approved' ?
                                    <span style={{ color: "green" }}>{ind.status}</span>
                                    : null}
                                  {ind.status == 'Rejected' ?
                                    <span style={{ color: "red" }}>{ind.status}</span>
                                    : null}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    style={{
                                      "padding": "2px",
                                      "top": "-6px",
                                      "color": "#fff",
                                      "border": "1px solid #394671",
                                      "backgroundColor": "#394671"
                                    }}
                                    onClick={() => viewItems(ind)}
                                  >
                                    View Indent Items
                                  </Button>


                                </TableCell>
                              </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    : null}
                  <>
                    {inventoryShow == true ?
                      <Indent handleCreateIndentClose={handleCreateIndentClose} gotoInventory={gotoInventory} />
                      : null}
                  </>

                </div>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <div>
                  {biddingShow == false ?
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: 'orange', fontSize: '17px', fontWeight: 'bold' }}>
                        Bidding Management Details
                      </Grid>
                    </Grid>
                    : null}
                  {biddingShow == false ?
                    <Box mt={3}>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>

                              <TableCell>
                                S.No
                              </TableCell>
                              <TableCell>
                                Category Name
                              </TableCell>
                              <TableCell style={{ width: "15%" }}>
                                Description
                              </TableCell>
                              <TableCell style={{ width: "15%" }}>
                                Vendor
                              </TableCell>
                              <TableCell>
                                Created Date
                              </TableCell>
                              <TableCell>
                                Status
                              </TableCell>
                              <TableCell>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {indent && indent.length != 0 && indent.map((ind, index) => {
                              return <TableRow
                                hover
                              >
                                <TableCell>
                                  {ind.UniqueIndentId}
                                </TableCell>
                                <TableCell>
                                  {ind.CategoryName ? ind.CategoryName : '-'}
                                </TableCell>

                                <TableCell>
                                  {ind.Description}
                                </TableCell>
                                <TableCell>
                                  {ind.VendorName ? ind.VendorName : "-"}
                                </TableCell>
                                <TableCell>
                                  {moment(ind.CreatedDate).format('dd/MM/yyyy hh:mm')}

                                </TableCell>
                                <TableCell>
                                  {ind.status == 'Close' ?
                                    <span style={{ color: "orange" }}>{ind.status + 'd'}</span>
                                    : null}
                                  {ind.status == 'Pending' ?
                                    <span style={{ color: "orange" }}>{ind.status}</span>
                                    : null}
                                  {ind.status == 'Approved' ?
                                    <span style={{ color: "green" }}>{ind.status}</span>
                                    : null}
                                  {ind.status == 'Rejected' ?
                                    <span style={{ color: "red" }}>{ind.status}</span>
                                    : null}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    style={{
                                      "padding": "2px",
                                      "top": "-6px",
                                      "color": "#fff",
                                      "border": "1px solid #394671",
                                      "backgroundColor": "#394671"
                                    }}
                                    onClick={() => viewVendorItems(ind)}
                                  >
                                    allocate vendor
                                  </Button>


                                </TableCell>
                              </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    : null}
                  <>
                    {biddingShow == true ?
                      <Indent handleCreateIndentClose={handleCreateIndentClose} gotoBidding={gotoBidding} />
                      : null}
                  </>

                </div>
              </TabPanel>
            </CardContent>
          </Card>
        </Box>
      </Container>

      <Dialog aria-labelledby="customized-dialog-title" className="common" open={open}>

        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          Capital Investment
        </DialogTitle>
        <DialogContent dividers>
          {/* <div className={classes.root}> */}
          <div style={{ marginLeft: "0%" }}>
            <Grid container spacing={3} style={{ textAlign: "right" }}>
              <Grid item className="txt-left" xs={4}>
                Type :
              </Grid>
              <Grid item xs={8}>

                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"

                    name="TypeOfInvestment"
                    onChange={(e) => {
                      let p = addCapitalInvestment;
                      p.TypeOfInvestment = e.target.value
                      setAddCapitalInvestment({
                        ...p
                      })
                    }}
                    required
                    value={addCapitalInvestment.TypeOfInvestment}
                    label="TypeOfInvestment"
                  >
                    <MenuItem value="0">
                      <em>None</em>
                    </MenuItem>
                    {subcategory && subcategory.length > 0 && subcategory.map((sub, index) => {
                      return <MenuItem value={sub.SubCategoryId}>{sub.SubCategoryName}</MenuItem>
                    })}

                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={2}></Grid> */}

              <Grid item className="txt-left" xs={4}>
                Vendor :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Vendor"
                  name="VendorID"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.VendorID = e.target.value
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.VendorID}
                  required
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={2}></Grid> */}
              <Grid item className="txt-left" xs={4}>
                Amount :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Amount"
                  name="Amount"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.Amount = Number(e.target.value.replace(/\D/g, ""))
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.Amount}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                InvoiceId :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="InvoiceId"
                  name="InvoiceID"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.InvoiceID = e.target.value
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.InvoiceID}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Purchase OrderId :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Purchase OrderId"
                  name="PurchaseOrderID"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.PurchaseOrderID = e.target.value
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.PurchaseOrderID}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Income Tax :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Income Tax"
                  name="IncomeTax"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.IncomeTax = Number(e.target.value.replace(/\D/g, ""))           // e.target.value
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.IncomeTax}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                SGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="SGST"
                  name="SGST"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.SGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.SGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                CGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="CGST"
                  name="CGST"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.CGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.CGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                IGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="IGST"
                  name="IGST"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.IGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.IGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Quantity :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Quantity"
                  name="Quantity"
                  onChange={(e) => {
                    let p = addCapitalInvestment;
                    p.Quantity = Number(e.target.value.replace(/\D/g, ""))
                    setAddCapitalInvestment({
                      ...p
                    })
                  }}
                  value={addCapitalInvestment.Quantity}
                  required

                  variant="outlined"
                />
              </Grid>

              {btn != 'Save' ?
                <>
                  <Grid item className="txt-left" xs={4}>
                    Idle Items:
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ width: '100%' }}
                      helperText=""
                      label="Idle Items"
                      name="ItemsInIdle"
                      onChange={(e) => {
                        let p = addCapitalInvestment;
                        p.ItemsInIdle = Number(e.target.value.replace(/\D/g, ""))
                        setAddCapitalInvestment({
                          ...p
                        })
                      }}
                      value={addCapitalInvestment.ItemsInIdle}
                      required

                      variant="outlined"
                    />
                  </Grid>

                  <Grid item className="txt-left" xs={4}>
                    Repair Items:
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ width: '100%' }}
                      helperText=""
                      label="Repair Items"
                      name="ItemsInRepair"
                      onChange={(e) => {
                        let p = addCapitalInvestment;
                        p.ItemsInRepair = Number(e.target.value.replace(/\D/g, ""))
                        setAddCapitalInvestment({
                          ...p
                        })
                      }}
                      value={addCapitalInvestment.ItemsInRepair}
                      required

                      variant="outlined"
                    />
                  </Grid>
                </>
                : null}
            </Grid>


          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {btn == 'Save' ?
            <Button disabled={!addCapitalInvestment.Quantity ||
              !addCapitalInvestment.IGST ||
              !addCapitalInvestment.CGST ||
              !addCapitalInvestment.SGST ||
              !addCapitalInvestment.IncomeTax ||
              !addCapitalInvestment.PurchaseOrderID ||
              !addCapitalInvestment.InvoiceID ||
              !addCapitalInvestment.Amount ||
              !addCapitalInvestment.VendorID ||
              !addCapitalInvestment.TypeOfInvestment} onClick={savecapitalInvestment} className={classes.button2}>
              Save changes
            </Button>
            : <Button onClick={updatecapitalInvestment} className={classes.button2}>
              Update changes
            </Button>}
        </DialogActions>

      </Dialog>


      {/* Recurring Expendure */}

      <Dialog aria-labelledby="customized-dialog-title" open={openrec}>

        <DialogTitle id="customized-dialog-title" onClose={handleRecClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          Recurring Expendure
        </DialogTitle>
        <DialogContent dividers>
          {/* <div className={classes.root}> */}
          <div style={{ marginLeft: "5%" }}>
            <Grid container spacing={3} style={{ textAlign: "right" }}>

              <Grid item className="txt-left" xs={4}>
                Category :
              </Grid>
              <Grid item xs={8}>

                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"

                    name="ParentSubCategoryId"
                    onChange={(e) => {
                      let p = addRecurring;
                      p.ParentSubCategoryId = e.target.value
                      setAddRecurring({
                        ...p
                      })
                    }}
                    required
                    value={addRecurring.ParentSubCategoryId}
                    label="TypeOfInvestment"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {subcategory && subcategory.length > 0 && subcategory.map((sub, index) => {
                      return <MenuItem value={sub.SubCategoryId} onClick={() => getParentSubCategory(sub)}>{sub.SubCategoryName}</MenuItem>
                    })}

                  </Select>
                </FormControl>
              </Grid>


              <Grid item className="txt-left" xs={4}>
                Sub Category :
              </Grid>
              <Grid item xs={8}>

                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"

                    name="TypeOfInvestment"

                    onChange={(e) => {
                      let p = addRecurring;
                      p.TypeOfInvestment = e.target.value
                      setAddRecurring({
                        ...p
                      })
                    }}
                    required
                    value={addRecurring.TypeOfInvestment}
                    label="ParentSubCategory Id"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {recsubcategory && recsubcategory.length > 0 && recsubcategory.map((sub) => {
                      return <MenuItem value={sub.SubCategoryId}>{sub.SubCategoryName}</MenuItem>
                    })}

                  </Select>
                </FormControl>
              </Grid>

              <Grid item className="txt-left" xs={4}>
                Vendor :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Vendor"
                  name="VendorID"

                  onChange={(e) => {
                    let p = addRecurring;
                    p.VendorID = e.target.value
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.VendorID}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Amount :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Amount"
                  name="Amount"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.Amount = Number(e.target.value.replace(/\D/g, ""))
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.Amount}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                InvoiceId :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="InvoiceId"
                  name="InvoiceID"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.InvoiceID = e.target.value
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.InvoiceID}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Purchase OrderId :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Purchase OrderId"
                  name="PurchaseOrderID"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.PurchaseOrderID = e.target.value
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.PurchaseOrderID}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Income Tax :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Income Tax"
                  name="IncomeTax"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.IncomeTax = Number(e.target.value.replace(/\D/g, ""))     // e.target.value
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.IncomeTax}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                SGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="SGST"
                  name="SGST"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.SGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.SGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                CGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="CGST"
                  name="CGST"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.CGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.CGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                IGST :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="IGST"
                  name="IGST"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.IGST = Number(e.target.value.replace(/\D/g, ""))
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.IGST}
                  required

                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
                Quantity :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Quantity"
                  name="Quantity"
                  onChange={(e) => {
                    let p = addRecurring;
                    p.Quantity = Number(e.target.value.replace(/\D/g, ""))
                    setAddRecurring({
                      ...p
                    })
                  }}
                  value={addRecurring.Quantity}
                  required

                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={2}></Grid> */}
              {btn != 'Save' ?
                <>
                  <Grid item className="txt-left" xs={4}>
                    Idle Items:
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ width: '100%' }}
                      helperText=""
                      label="Idle Items"
                      name="ItemsInIdle"
                      onChange={(e) => {
                        let p = addRecurring;
                        p.ItemsInIdle = Number(e.target.value.replace(/\D/g, ""))
                        setAddRecurring({
                          ...p
                        })
                      }}
                      value={addRecurring.ItemsInIdle}
                      required

                      variant="outlined"
                    />
                  </Grid>

                  <Grid item className="txt-left" xs={4}>
                    Repair Items:
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ width: '100%' }}
                      helperText=""
                      label="Repair Items"
                      name="ItemsInRepair"
                      onChange={(e) => {
                        let p = addRecurring;
                        p.ItemsInRepair = Number(e.target.value.replace(/\D/g, ""))
                        setAddRecurring({
                          ...p
                        })
                      }}
                      value={addRecurring.ItemsInRepair}
                      required

                      variant="outlined"
                    />
                  </Grid>
                </>
                : null}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {btn == 'Save' ?
            <Button

              disabled={!addRecurring.Quantity || !addRecurring.CategoryID || !addRecurring.TypeOfInvestment || !addRecurring.Amount ||
                !addRecurring.InvoiceID || !addRecurring.IncomeTax || !addRecurring.CGST || !addRecurring.SGST ||
                !addRecurring.IGST || !addRecurring.PurchaseOrderID || !addRecurring.VendorID || !addRecurring.ParentSubCategoryId
              }
              onClick={saveRecurring} className={classes.button2}>
              Save changes
            </Button>
            : <Button onClick={updateRecurring} className={classes.button2}>
              Update changes
            </Button>}
        </DialogActions>

      </Dialog>

      {/* Income Generation */}

      <Dialog aria-labelledby="customized-dialog-title" open={openinc} id="income">
        <DialogTitle id="customized-dialog-title" onClose={handleIncClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          Income Generation
        </DialogTitle>
        <DialogContent dividers>

          <div style={{ marginLeft: "0%" }} >
            <br></br>
            <Grid container spacing={3} style={{ textAlign: "right" }}>
              <Grid item xs={6}>

                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"

                    name="CategoryID"
                    onChange={(e) => {
                      let p = addIncome;
                      p.CategoryID = e.target.value
                      setAddIncome({
                        ...p
                      })
                    }}
                    required
                    value={addIncome.CategoryID}
                    label="TypeOfInvestment"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {incomeCat && incomeCat.length > 0 && incomeCat.map((inc, index) => {
                      return <MenuItem value={inc.CategoryId} onClick={() => getIncomeSubCategory(inc)}>{inc.CategoryName}</MenuItem>
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Sub Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"

                    name="SubCategoryId"

                    onChange={(e) => {
                      let p = addIncome;
                      p.SubCategoryId = e.target.value
                      setAddIncome({
                        ...p
                      })
                    }}
                    required
                    value={addIncome.SubCategoryId}
                    label="ParentSubCategory Id"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {incomeSubCat && incomeSubCat.length > 0 && incomeSubCat.map((incsub) => {
                      return <MenuItem value={incsub.SubCategoryId}>{incsub.SubCategoryName}</MenuItem>
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Rate"
                  name="Rate"

                  onChange={(e) => {
                    let p = addIncome;
                    p.Rate = Number(e.target.value.replace(/\D/g, ""))  //e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Rate}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Quantity"
                  name="Quantity"

                  onChange={(e) => {
                    let p = addIncome;
                    p.Quantity = Number(e.target.value.replace(/\D/g, ""))
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Quantity}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Delivery Note"
                  name="DeliveryNote"

                  onChange={(e) => {
                    let p = addIncome;
                    p.DeliveryNote = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.DeliveryNote}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Mode of Payment"
                  name="ModeOfPayment"
                  onChange={(e) => {
                    let p = addIncome;
                    p.ModeOfPayment = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.ModeOfPayment}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Suppliers Ref"
                  name="SupplierRef"
                  onChange={(e) => {
                    let p = addIncome;
                    p.SupplierRef = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.SupplierRef}
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid item className="txt-left" xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      InputProps={{}}
                      disablePast="true"
                      minDate={new Date()}
                      margin="normal"
                      id="date-picker-dialog"
                      label="Select Date"
                      format="dd/MM/yyyy"
                      value={addIncome.DateOfSale}
                      onChange={(e) => {
                        let p = addIncome;
                        p.DateOfSale = e
                        setAddIncome({
                          ...p
                        })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }} className={classes.calmar}
                      error={false}
                      helperText={null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>



              {/* <Grid item xs={6}>
                                    <Button onClick={incomeClose} className={classes.button2}>
                                        Cancel
                                    </Button>
                                    &nbsp;
                                    <Button className={classes.button2}>
                                        Save
                                    </Button>
                                </Grid> */}

            </Grid>
            &nbsp;
            <hr />
            &nbsp;
            <h4>Buyer</h4>
            &nbsp;
            <Grid container spacing={3} style={{ textAlign: "right" }}>



              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Name"
                  name="Name"
                  onChange={(e) => {
                    let p = addIncome;
                    p.Name = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Name}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Email"
                  name="Email"
                  onChange={(e) => {
                    let p = addIncome;
                    p.Email = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Email}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Mobile Number"
                  name="MobileNumber"
                  onChange={(e) => {
                    let p = addIncome;
                    p.MobileNumber = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.MobileNumber}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Address"
                  name="Address"
                  onChange={(e) => {
                    let p = addIncome;
                    p.Address = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Address}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Booking Order No"
                  name="BookingOrderNumber"
                  onChange={(e) => {
                    let p = addIncome;
                    p.BookingOrderNumber = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.BookingOrderNumber}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Despatch Document No"
                  name="DispatchDocumentNumber"
                  onChange={(e) => {
                    let p = addIncome;
                    p.DispatchDocumentNumber = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.DispatchDocumentNumber}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      InputProps={{
                      }}
                      disablePast="true"
                      minDate={new Date()}
                      margin="normal"
                      id="date-picker-dialog"
                      label="Delivery Note Date"
                      format="dd/MM/yyyy"
                      name="DeliveryNoteDate"
                      value={addIncome.DeliveryNoteDate}
                      onChange={(e) => {
                        let p = addIncome;
                        p.DeliveryNoteDate = e
                        setAddIncome({
                          ...p
                        })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }} className={classes.calmar}
                      error={false}
                      helperText={null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>
                </MuiPickersUtilsProvider>
                {/* </div> */}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Despatched through"
                  name="DispatchedThrough"
                  onChange={(e) => {
                    let p = addIncome;
                    p.DispatchedThrough = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.DispatchedThrough}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Destination"
                  name="Destination"
                  onChange={(e) => {
                    let p = addIncome;
                    p.Destination = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.Destination}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  helperText=""
                  label="Terms of delivery"
                  name="TermsOfDelivery"
                  onChange={(e) => {
                    let p = addIncome;
                    p.TermsOfDelivery = e.target.value
                    setAddIncome({
                      ...p
                    })
                  }}
                  value={addIncome.TermsOfDelivery}
                  required
                  variant="outlined"
                />
              </Grid>

            </Grid>
            &nbsp;
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {btn == 'Save' ?
            <Button disabled={!addIncome.CategoryID || !addIncome.SubCategoryId || !addIncome.Rate || !addIncome.Quantity || !addIncome.DeliveryNote ||
              !addIncome.ModeOfPayment || !addIncome.SupplierRef || !addIncome.DateOfSale || !addIncome.Name || !addIncome.Email ||
              !addIncome.MobileNumber || !addIncome.Address || !addIncome.BookingOrderNumber || !addIncome.DispatchDocumentNumber ||
              !addIncome.DeliveryNoteDate || !addIncome.DispatchedThrough || !addIncome.Destination || !addIncome.TermsOfDelivery
            }
              onClick={saveIncome} className={classes.button2}>
              Save changes
            </Button>
            : <Button onClick={updateIncome} className={classes.button2}>
              Update changes
            </Button>}
        </DialogActions>

      </Dialog>


      {/* Income Generation Edit */}

      <Dialog aria-labelledby="customized-dialog-title" open={openincEdit} id="income">
        <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
          <CircularProgress style={{ color: '#fff' }} />
        </Backdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleIncEditClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          Income Generation List
        </DialogTitle>
        <DialogContent dividers>
          {/* <div className={classes.root}> */}
          <Link style={{ marginLeft: "89%" }} onClick={addIncomeItem}>Add new item</Link>
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Types</TableCell>
                    <TableCell>CM</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>No's Supply</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Entry Date</TableCell>
                    <TableCell>Actions</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {incomeById && incomeById.length != 0 && incomeById.map((x, index) => {
                    return <TableRow >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {x.CategoryName}
                      </TableCell>
                      <TableCell>{x.SubCategoryName}</TableCell>
                      <TableCell><Icon icon={currencyInr} />{numberFormat(x.Rate)}.00</TableCell>
                      <TableCell>{numberFormat(x.Quantity)}</TableCell>
                      <TableCell><Icon icon={currencyInr} /> {numberFormat(x.TotalPrice)}.00</TableCell>
                      <TableCell>{moment(x.DateOfSale).format('dd/MM/yyyy')}</TableCell>

                      <TableCell>
                        <EditIcon onClick={() => handleClickChildIncomEditOpen(x)} />
                        <DeleteOutlineIcon onClick={() => deleteFun(x, 'ChildIncomeItem')} />
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <br></br>

          {addIncomeRecord == true ?
            <div style={{ marginLeft: "0%" }} >
              <hr />
              <br></br>
              <Grid container spacing={3} style={{ textAlign: "right" }}>
                <Grid item xs={6}>

                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"

                      name="CategoryID"
                      onChange={(e) => {
                        let p = IncomeEachItem;
                        p.CategoryID = e.target.value
                        setIncomeEachItem({
                          ...p
                        })
                      }}
                      required
                      value={IncomeEachItem.CategoryID}
                      label="TypeOfInvestment"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {incomeCat && incomeCat.length > 0 && incomeCat.map((inc, index) => {
                        return <MenuItem value={inc.CategoryId} onClick={() => getIncomeSubCategory(inc)}>{inc.CategoryName}</MenuItem>
                      })}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Sub Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"

                      name="SubCategoryId"

                      onChange={(e) => {
                        let p = IncomeEachItem;
                        p.SubCategoryId = e.target.value // e.target.value
                        setIncomeEachItem({
                          ...p
                        })
                      }}
                      required
                      value={IncomeEachItem.SubCategoryId}
                      label="ParentSubCategory Id"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {incomeSubCat && incomeSubCat.length > 0 && incomeSubCat.map((incsub) => {
                        return <MenuItem value={incsub.SubCategoryId}>{incsub.SubCategoryName}</MenuItem>
                      })}

                    </Select>
                  </FormControl>
                </Grid>



                <Grid item xs={6}>
                  <TextField
                    style={{ width: '100%' }}
                    helperText=""
                    label="Rate"
                    name="Rate"

                    onChange={(e) => {
                      let p = IncomeEachItem;
                      p.Rate = Number(e.target.value.replace(/\D/g, ""))
                      setIncomeEachItem({
                        ...p
                      })
                    }}
                    value={IncomeEachItem.Rate}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: '100%' }}
                    helperText=""
                    label="Quantity"
                    name="Quantity"

                    onChange={(e) => {
                      let p = IncomeEachItem;
                      p.Quantity = Number(e.target.value.replace(/\D/g, ""))
                      setIncomeEachItem({
                        ...p
                      })
                    }}
                    value={IncomeEachItem.Quantity}
                    required
                    variant="outlined"
                  />
                </Grid>


                <Grid item className="txt-left" xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        InputProps={{}}
                        disablePast="true"
                        minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Select Date"
                        format="dd/MM/yyyy"
                        value={IncomeEachItem.DateOfSale}
                        onChange={(e) => {
                          let p = IncomeEachItem;
                          p.DateOfSale = e
                          setIncomeEachItem({
                            ...p
                          })
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }} className={classes.calmar}
                        error={false}
                        helperText={null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>


              </Grid>
              &nbsp;

            </div>
            : null}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {btn == 'Save' && addIncomeRecord == true &&


            <Button disabled={!IncomeEachItem.CategoryID || !IncomeEachItem.SubCategoryId || !IncomeEachItem.Rate
              || !IncomeEachItem.Quantity || !IncomeEachItem.DateOfSale
            }
              onClick={saveIncomeItem} className={classes.button2}>
              Save changes
            </Button>
          }
          {btn == 'Update' &&
            <Button onClick={updateIncomeEachItem} className={classes.button2}>
              Update changes
            </Button>
          }
          {addIncomeRecord == true &&
            <Button onClick={incomeClose} className={classes.button2}>
              Cancel
            </Button>
          }
        </DialogActions>

      </Dialog>

      {/* Income Generation Print view  narasimha*/}

      <Dialog aria-labelledby="customized-dialog-title" open={openincPrint} id="income">
        <DialogTitle id="customized-dialog-title" onClose={handleIncPrintClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          Income Generation Print View
        </DialogTitle>
        <DialogContent dividers>
          <div>


            <div style={{ marginLeft: "75%" }}>


              <PictureAsPdfIcon 
              // onClick={exportPdf} 
              onClick={printOrder} 
              /> &nbsp;
              <PrintIcon onClick={printOrder} />
            </div>
            <span id='printme'>
              <div className="invoice-box">
                <table cellpadding="0" cellspacing="0">
                  <tr className="top">
                    <td colspan="2">
                      <table>
                        <tr>
                          <td className="title">
                            <img src="/static/logo-rgca.jpg" style={{ width: "100%", maxWidth: "190px" }} />
                          </td>

                          <td style={{ position: "relative", left: "10%" }}>
                            Invoice #: {incomeById[0] && incomeById.length > 0 && incomeById[0].InvoiceID &&
                              <span>{incomeById[0].InvoiceID}</span>
                            }<br />
                            Created: {incomeById[0] && incomeById.length > 0 && incomeById[0].CreateDate &&
                              <span>{moment(incomeById[0].CreateDate).format('dd/MM/yyyy')}</span>
                            }
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr className="information">
                    <td colspan="2">
                      <table>
                        <tr className="tr">
                          <td style={{ width: "43%" }}>

                            Multi Spacies Aquaculture MPEDA-RGCA<br />
                            RGCA , Under MPEDA,<br />
                            Ministry of Commerce  & Industry,<br />
                            Government of India,<br />
                            Vallaepuram,Kochi,<br />
                            Emakalum District,Kerala

                            {/* Sparksuite, Inc.<br />
                                                                        12345 Sunny Road<br />
                                                                        Sunnyville, CA 12345 */}
                          </td>

                          <td style={{ position: "relative", left: "25%" }}>
                            <b>Invoice No :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].InvoiceID &&
                              <span>{incomeById[0].InvoiceID}</span>
                            }
                            <br />
                            <b>Delivery Note :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].DeliveryNote &&
                              <span>{incomeById[0].DeliveryNote}</span>
                            }

                            <br />
                            <b>Mode/Terms of Payment :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].ModeOfPayment &&
                              <span>{incomeById[0].ModeOfPayment}</span>
                            }
                            <br />
                            <b>Supplies Ref :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].SupplierRef &&
                              <span>{incomeById[0].SupplierRef}</span>
                            }

                            <br />

                          </td>
                        </tr>
                        <tr className="tr">
                          <td>
                            <b>Buyer :</b><br />
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].Name &&
                              <>{incomeById[0].Name}</>
                            }
                            <br />
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].Email &&
                              <>{incomeById[0].Email}</>
                            }<br />
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].MobileNumber &&
                              <>{incomeById[0].MobileNumber}</>
                            }
                          </td>

                          <td style={{ position: "relative", left: "25%" }}>
                            <b>Buyer's Order No :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].BookingOrderNumber &&
                              <span>{incomeById[0].BookingOrderNumber}</span>
                            }
                            <br />
                            <b>Dispatch Document No :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].DispatchDocumentNumber &&
                              <span>{incomeById[0].DispatchDocumentNumber}</span>
                            }<br />
                            <b>Dispatch Through :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].DispatchedThrough &&
                              <span>{incomeById[0].DispatchedThrough}</span>
                            }<br />
                            <b>Destination :</b>
                            {incomeById[0] && incomeById.length > 0 && incomeById[0].Destination &&
                              <span>{incomeById[0].Destination}</span>
                            }<br />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  {/* <tr className="heading">
                                        <td>Payment Method</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Net Amount(Including GST)</td>
                                    </tr> */}
                </table>
                <table>


                  <tr className="heading">
                    <td>Description of Goods</td>
                    <td>Quantity</td>
                    <td>Rate </td>
                    <td style={{ width: "0px" }}>Amount</td>
                    <td>SGST</td>
                    <td>CGST</td>
                    <td>IGST</td>
                    <td>Net Price</td>
                  </tr>
                  {incomeById && incomeById.length > 0 && incomeById.map((x, index) => {

                    return <tr className="item">

                      <td>{x.CategoryName}</td>
                      <td>{x.Quantity}</td>
                      <td>{numberFormat(x.Rate)}.00</td>
                      <td>{numberFormat(x.TotalPrice)}.00</td>
                      <td> {numberFormat(x.SGST)}.00</td>
                      <td>{numberFormat(x.CGST)}.00</td>
                      <td>{numberFormat(x.IGST)}.00</td>
                      <td>{numberFormat(x.TotalPriceWithTax)}.00</td>
                    </tr>
                  })}
                  <tr className="total">
                    <td><b>Total</b></td>
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalQuantity &&
                      <td><b>{incomeById[0].TotalQuantity}</b></td>
                    }
                    <td></td>
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].Amount &&
                      <td><b>{numberFormat(incomeById[0].Amount)}.00</b></td>
                    }
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalSGST &&
                      <td><b>{numberFormat(incomeById[0].TotalSGST)}.00</b></td>
                    }
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalCGST &&
                      <td><b>{numberFormat(incomeById[0].TotalCGST)}.00</b></td>
                    }
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalIGST &&
                      <td><b>{numberFormat(incomeById[0].TotalIGST)}.00</b></td>
                    }
                    {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalAmount &&
                      <td> <b>{numberFormat(incomeById[0].TotalAmount)}.00</b></td>
                    }
                  </tr>
                </table>
                <br />
                <table>
                  <tr className="details">
                    <td>Amount Chargeable (in words) <br />
                      {incomeById[0] && incomeById.length > 0 && incomeById[0].TotalAmount &&
                        <b> {price_in_words(incomeById[0].TotalAmount)} Only</b>
                      }

                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <br /><br /><br /><br /><br />
                  <tr className="details">
                    <td>Declaration : <br />
                      We declare that this invoice shows the actual price of the goods
                      described and that all particulars are true and correct.

                    </td>
                    <td></td>
                    <td></td>
                    <td style={{ border: "1px dotted #000", height: "100px" }}>
                      for Multi-Species Aquaculture Complex of MPEDA-RGCA
                    </td>
                  </tr>
                </table>

              </div>
            </span>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

        </DialogActions>

      </Dialog>

      {/* UPload CAPITAL INVESTMENT  */}

      <Dialog aria-labelledby="customized-dialog-title" open={uploadCapitalInvestment} id="income">
        <DialogTitle id="customized-dialog-title" onClose={uploadCapitalInvestmentView} style={{ backgroundColor: "#394671", color: "#fff" }}>
          CAPITAL INVESTMENT
        </DialogTitle>
        <DialogContent dividers>
          {/* <div className={classes.root}> */}
          <div style={{ marginLeft: "0%" }} >
            {/* Upload Tag */}

          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>



          <Button className={classes.button2}>
            Upload
          </Button>

        </DialogActions>

      </Dialog>




      {/* Indent Status */}

      <Dialog aria-labelledby="customized-dialog-title" open={openindent} id="income" style={{ width: "800px !important" }}>
        <DialogTitle id="customized-dialog-title" onClose={handleIndentClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
          {approvenote} Note
        </DialogTitle>
        <DialogContent dividers style={{ width: "800px !important" }}>
          {/* <div className={classes.root}> */}
          <div style={{ marginLeft: "0%" }} >
            <Grid container spacing={3} >



              <Grid item xs={12}>
                <TextField
                  fullWidth
                  helperText=""
                  label="Note"
                  name="indentNote"

                  onChange={(e) => {

                    setIndentNote(e.target.value)
                  }}
                  value={indentNote}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item className="txt-left" xs={4}>
              </Grid>
              <Grid item xs={8} >

              </Grid>

            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>



          <Button onClick={indentApproveAndReject} className={classes.button2}>
            Submit
          </Button>
          <Button onClick={handleIndentClose} className={classes.button2}>
            Cancel
          </Button>

        </DialogActions>

      </Dialog>



      {/* Create Indent */}

      <Dialog aria-labelledby="customized-dialog-title" open={openCreateIndent} id="income" style={{ maxWidth: "1100px !important", width: "1100px" }}>
        <DialogTitle id="customized-dialog-title" onClose={handleCreateIndentClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
          {popdata.CategoryName}
        </DialogTitle>
        <Indent handleCreateIndentClose={handleCreateIndentClose} />

      </Dialog>



      {/* Edit Project */}

      <Dialog aria-labelledby="customized-dialog-title" open={openEditProject} id="income" style={{ maxWidth: "1100px !important", width: "1100px" }}>
        <DialogTitle id="customized-dialog-title" onClose={handleEditProjectClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
          {projectName}
        </DialogTitle>
        <DialogContent dividers style={{ width: "800px !important" }}>
          <EditProject handleEditProjectClose={handleEditProjectClose} />
        </DialogContent>
        {/* <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                    <Button onClick={handleEditProjectClose} className={classes.button2}>
                        Cancel
                        </Button>

                </DialogActions> */}

      </Dialog>



    </Page>

  );

}
export default SeabaseHatchery;
