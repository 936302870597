import { Nav } from './action.type'
import axios from 'axios';

export const getNavData = (data) => {
    
   
    return dispatch => {
    // if(data){
        dispatch(datagettingSuccess(data))
    
                    
    };
}


export const datagettingSuccess = (result) => {
    
    return {
        type: Nav.NAVSUCESS,
        data: result
    }
}

export const datagettingFaliure = () => {
    return {
        type: Nav.NAVFAILURE,
        data: ""
    }
}