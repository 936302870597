import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import { useParams } from "react-router-dom";
import { RGCA_URLS } from './../../../utils/url';
import { getProjectDetails } from '../Common/utilities';
import APIKit from 'src/utils/APIKit';
import { useNavigate } from 'react-router-dom';
import SelectFinancialYear from '../Common/selectFinancialYear';


const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    minWidth: 120,
    marginBottom: '1rem'
  },
  selectEmpty: {
    marginTop: '1rem',
  },
}));

const Capital = ({ className, projectID, CHART, setCapData, ...rest }) => {
  const classes = useStyles();
  const { project } = useParams();
  const [capitalList, setCapitalList] = useState([])
  const projectDetails = projectID || { ...getProjectDetails(project) }
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState('');



  useEffect(() => {
    getCapitalProjectDashboardChart();
  }, [projectDetails.projectID, navigate, selectedYear])


  const getCapitalProjectDashboardChart = () => {
    let payload = {
      projectID: projectDetails.projectID,
      selectedYear: selectedYear
    }
    APIKit.post(RGCA_URLS.getCapitalProjectDashboardChart, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setCapitalList(res.data.data);
          setCapData(res.data.data)
        } else {
        }
      })
      .catch((e) => {
      });
  };
  const state =
  {
    labels: capitalList.map(da => { return da.name }),
    datasets: [
      {
        label: 'Capital',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: capitalList.map(da => { return da.value }),
      }
    ]
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}
    >

      <CardContent>
        <SelectFinancialYear selectedYear={selectedYear} setSelectedYear={setSelectedYear} />

        <div>
          {capitalList && capitalList.length > 0 ?
            <Bar
              data={state}
              options={{
                // maintainAspectRatio: false,
                title: {
                  display: true,
                  text: 'Capital Investment',
                  fontSize: 20
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                legend: {
                  display: true,
                  position: 'top'
                }
              }}
            />
            :
            <div style={{ textAlign: 'center' }}>
              <h3>Capital Investment</h3>
              <p>No data found</p>
            </div>
          }
        </div>
      </CardContent>


    </Card>
  );
};

Capital.propTypes = {
  className: PropTypes.string
};

export default Capital;




