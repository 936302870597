export const MESSAGE = {
    name: 'Name is required',
    currentPassword: 'Current password is required',
    newPasswor: 'New Password is required',
    firstName: 'First Name is required',
    lastName: 'Last Name is required',
    connewPasswor: 'Confirm Password is required',
    reference: 'Reference is required',
    fName: 'First Name is required',
    lName: 'Last Name is required',
    gstNumber: 'GSTN is required',
    gender: 'Gender is required',
    employmentType: 'Employment Type is required',
    dob: 'Date Of Birth is required',
    doj: 'Date Of Joining is required',
    password: 'Password is required',
    location: 'Location Info is required',
    type: 'Type is required',
    email: 'Email is required',
    validEmail: 'Must be a valid email',
    mobile: 'Mobile Number is required',
    address1: 'Address Line 1 is required',
    address2: 'Address Line 2 is required',
    pincode: 'Pincode is required',
    state: 'State is required',
    country: 'Country is required',
    panCardNumber: 'PAN Number is required',
    accountNumber: 'Account Number is required',
    ifsc: 'IFSC is required',
    bankName: 'Bank Name is required',
    branchName: 'Branch Name is required',
    aadhar: 'Aadhar Number is required',
    companyName: 'Company Name is required',
    typeOfAccountant: 'Type Of Accountant is required',
    accountHolderName: 'Account Holder Name is required',
    departmentId: 'Department ID is required',
    designationId: 'Designation ID is required',
    roleId: 'Role ID is required',
    employmentId: 'Employment Type ID is required',
    empLoginID:'Employee ID is required',
    projectArea:'Area Of The Project is Required',
    reportingTo: 'Reporting To is required',
    idCardNumber: 'ID Card Number is required',
    maritalStatus: 'Marital Status is required',
    joiningReport: 'Joining Report is required',
    physicallyDisabled: 'Physically Challenged is required',
    bloodGroup: 'Blood Group is required',
    detailsOfDependent: 'Details Of Dependent is required',
    numberOfYearsOfService: 'Number Of Years Of Service is required',
    numberOfYearsOfDeputation: 'Number Of Years Of Deputation is required',
    pensionCategory: 'Pension Category is required',
    epfid: 'EPFID is required',
    bloodGroup: 'ESIID is required',
    leavesAvailable: 'Leaves Available is required',
    leavesUsed: 'Leaves Used is required',
    district: 'District is required',
    fatherName: 'Father Name is required',
    statusOfPhysicallyChallenged: 'Status Of Physically Challenged is required',
    statusOfPhysicallyChallengedNote: 'Status Of Physically Challenged Note is required',
    employeeCode: 'Employee Code',
    projects: 'Projects is required',
    project:'Project is required',
    role : 'Access Role is required',
    
    category: 'Category is required',
    subCategory: 'Sub Category is required',
    hssn: 'HSSN / SAC Code is required',
    dateOfBirth:'Date of Production / Grading is required',
    uom: 'Unit Of Measurement is required',
    rpu: 'Rate Per Unit is required',
    gst: 'GST Taxability is required',
    gstRate: 'GST Rate is required',
    stocks: 'Stocks is required',
    itemDescription: 'Item Description is required',
    rca: 'Reverse Charge Applicability is required',
    comment: 'Description is required',
    qty: 'Quantity is required',
    estimatedPrice: 'Estimated Price is required',
    not0: 'This field does not allow 0',
    categoryAdded: 'Category added successfully',
    item: 'Item/Material/Assets is required',
    subCategoryAdded: 'Sub Category added successfully',
    projectAdded: 'Project added successfully',
    ShortName:'Project Name & Short Name  Must Be Unique',
    minAadhar: 'Aadhar Number must be 12 numbers',
    noe: 'Nature Of Expenditure is required',
    materials: 'Materials is required',
    tcv: 'Total Contract Value is required',
    taxDetails: 'Tax Details is required',
    paymentTerms: 'Payment Terms is required',
    projectDeleted: 'Project Deleted Successfully',
    blankSpaces: 'Not allowed only blankspaces',
    must10: 'Must be 10 characters',
    wrong_name_data_entered: "Vendor(s) first name is wrong",
    wrong_data_entered: "Vendor(s) email address is wrong",
    wrong_data_entered_employee: "Employee(s) email address is wrong",
    wrong_name_data_entered_employee: "Employee(s) first name is wrong",
    file_empty: "Selected file is empty",
    min10: 'Minimum should be 10 characters',
    min11: 'Minimum should be 11 characters',
    must6: 'Must be 6 characters',
    fileUpload: 'File Uploaded Successfully',
    fileFormat: 'This File Format Not Allowed',
    fileSze: 'This File Size Not Allowed',
    fileNotUpload: 'File Not Uploaded Successfully',
    projectUpdated: 'Project Details Updated Successfully',
    loginSuccess: 'Successfully Logged in',
    notAllocated: 'Projects are not allocated to you. Contact Admin',
    employeeUpdated: 'Employee Details Updated Successfully',
    employeeAdded: 'Employee Added Successfully',
    employeeDeleted: 'Employee Deleted Successfully',
    vendorUpdated: 'Vendor Updated Successfully',
    vendorUpdatedNOT: 'Vendor Not Updated',
    amount: 'Amount is required',
    mor: 'Mode Of Receipt is required',
    customer: 'Customer is required',
    receiptCreate: 'Receipt Created Successfully',
    purpose: 'Purpose is required',
    storeindentAdded: 'Store Indent Added Successfully',
    indentAdded: 'Indent Added Successfully',
    designationUpdated: 'Designation Updated Successfully',
    designationNotUpdated: 'Designation Not Updated',
    designationAdded: 'Designation Added Successfully',
    designationNotAdded: 'Designation  Already Exist ',
    designationDeleted: 'Designation Deleted Successfully',
    designationNotDeleted: 'Designation Not Deleted',
    RoleDeleted: 'Role Deleted Successfully',
    RoleNotDeleted: 'Role Not Deleted',
    material: 'Material is required',
    vendorDeleted: 'Vendor Deleted successfully',
    employeeUpdatedNOT: 'Employee not updated',
    workorderSuccessfully: 'Workorder Added Successfully',
    duplicateProjects: 'Please Select Different Projects',
    vendorAdded: 'Vendor Added Successfully',
    passwordChanged: 'Password Changed Successfully',
    vendorDeletedNot: 'Vendor Not Deleted',
    vendorNotAdded: 'Vendor Not Added',
    workorderDeleted: 'Workorder Deleted Successfully',
    workOrderNotDeleted: 'Workorder Not Deleted',
    workorderUpdated: 'Workorder Updated Successfully',
    workOrderNotUpdated: 'Workorder Not Updated',
    employeeNotDeleted: 'Employee Not Deleted',
    projectNotUpdated: 'Project Not Updated',
    projectNotDeleted: 'Project Not Deleted',
    itemAdded: 'Item Added Successfully',
    itemUpdate: 'Item Updated Successfully',
    salesItemUpdate: 'Sales Item Updated Successfully',
    salesItemNotUpdate: 'Sales Item Not Updated',
    salesItemAdded: 'Sales Item Added Successfully',
    salesItemDeleted: 'Sales Item Deleted Successfully',
    priceItemAdded: 'Production Price Added Successfully',
    priceItemUpdate: 'Production Price Updated Successfully',
    salesItemNotDeleted: 'Sales Item Not Deleted',
    customerAdded: 'Customer Added Successfully',
    customerUpdated: 'Customer Updated Successfully',
    customerNotUpdated: 'Customer Not Updated',
    customerDeleted: 'Customer Deleted Successfully',
    purchaseOrderApporoved: 'Purchase Order sent successfully',
    lessthan3mb: 'File must be less than 3MB',
    deleteIndentItem: 'Indent Item deleted successfully',
    note: 'Note is required',
    status: 'Status is required',
    vendor: 'Vendor is required',
    gstsno: 'GST is required',
    unitPrice: 'Unit Price is required',
    unit: 'Unit is required',
    interIntraUNion: 'Please select any one state type',
    roleAdded: 'Role Added Successfully',
    roleupdate:'Role updated Successfully',
    indentUpdate: 'Indent Updated Successfully',
    alreadyExist: 'Category Already Exist',
    grnAdded: 'GRN Added Successfully',
    returnGoods: 'Goods Returned Successfully',
    itemAttach: 'Item Attached Successfully',
    poiDmatch: 'PO ID does not match',
    materialItem: 'Please select the Material Item',
    doe: 'Date of expiry is required',
    invoiceID: 'Invoice ID is required',
    ivDate: 'Invoice Date is required',
    deprecrate: 'Depreciation Rate is required',
    attachment: 'Please attach Invoice',
    returnPurpose: 'Please Fill return purpose',
    qty0: 'Quantity Not be 0',
    est0: 'Estimated Price Not be 0',
    location1: 'Location is required',
    itemQty: 'Item Quantity is required',
    uniqueID: 'Unique ID is required',
    purchaseValue: 'Purchase Value is required',
    insuranceDate : 'Insurance Date Value is required',
    warrantyGranteeDetails: 'Waranty Grantee Details is required',
    salesCategory: 'Sales Category Added Successfully',
    cpdStatus: 'CPD approval status updated',
    paymentMethod: 'Payment Method is required',
    reference: 'Reference is required',
    isGstTds: 'GST TDS is required',
    value: 'Value is required',
    isItTaxTds: 'Tax TDS is required',
    balancePayable: 'Balance Payable is required',
    purposeAdvance: 'Purpose Advance is required',
    advancedReceived: 'Advance Received is required',
    date: 'Date is required',
    nou: 'Number of units is required',
    employee: 'Employee is required',
    qt0: 'Quantity must not be 0',
    IGST: 'IGST is required',
    CGST: 'CGST is required',
    SGST: 'SGST is require',
    UGST: 'UGST is required',
    notice: 'Post notification Success',
    updatenotice: 'Notification Updated ',
    deletenotice: 'Notification deleted ',
    ginPost: 'GIN created',
    returnGoods: "Return Goods Submitted",
    RolealreadyExist:"Role already Exist",
    IndentApproalQuantity:"Approved Quantity Need To Less Or Equal To Requested Quantity",
    BrokenItem:"Broken Item Quantity Need To Less Or Equal To Total Quantity"
};