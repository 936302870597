import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),

   
    sNo: '1',
    deptName: 'Department 1',
    createdDate: new Date(),
    createdBy:"ABC",
    

  },
  {
    id: uuid(),

   
    sNo: '2',
    deptName: 'Department 1',
    createdDate: new Date(),
    createdBy:"ABC",
    

  },
  {
    id: uuid(),

   
    sNo: '3',
    deptName: 'Department 1',
    createdDate: new Date(),
    createdBy:"ABC",
    

  },
  {
    id: uuid(),

   
    sNo: '4',
    deptName: 'Department 1',
    createdDate: new Date(),
    createdBy:"ABC",
    

  },
  {
    id: uuid(),

   
    sNo: '5',
    deptName: 'Department 1',
    createdDate: new Date(),
    createdBy:"ABC",
    

  },

];