
export const  ETTypes = {
  string: "string",
  boolean: "boolean",
  numeric: "numeric",
  date: "date",
  dateTime: "datetime",
  time: "time",
  currency: "currency",
  link: "link"
}

export const EEditable = {
  onEdit: "edit",
}

export const ETaction = {
  onView: "view",
  trackApprove:"Approve",
  onDelete: "delete",
  onEdit: "edit",
  onPrint: "print",
  onPayment: "onPayment",
  moveOnPurchase:'Purchase',
  onCancel:'onCancel'

}
