import { v4 as uuid } from 'uuid';

export default [
  {
    sno: 1,
    name: 'Ekaterina Tankova',
    city: 'Chennai',
    state: 'TamilNadu',
    address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
    zipcode : '600 002',
    phone : "7272492494"
  },
  {
    sno:2,
      name: 'Adam Denisov',
      city: 'Chennai',
      state: 'TamilNadu',
      address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
      zipcode : '600 002',
      phone : "9552492424"
    },
    {
        sno:3,
          name: 'Emilee Simchenko',
          city: 'Chennai',
          state: 'TamilNadu',
          address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
          zipcode : '600 002',
          phone : "9000492423"
        },
        {
            sno:4,
              name: 'Alexa Richardson',
              city: 'Chennai',
              state: 'TamilNadu',
              address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
              zipcode : '600 002',
              phone : "9450492423"
            },
            {
                sno:5,
                  name: 'Anje Keizer',
                  city: 'Chennai',
                  state: 'TamilNadu',
                  address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                  zipcode : '600 002',
                  phone : "954492423"
                },
                {
                    sno:6,
                      name: 'Clarke Gillebert',
                      city: 'Hyderabad',
                      state: 'TamilNadu',
                      address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                      zipcode : '600 002',
                      phone : "900002423"
                    },
                    {
                        sno:7,
                          name: 'Emilee Simchenko',
                          city: 'Chennai',
                          state: 'TamilNadu',
                          address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                          zipcode : '600 002',
                          phone : "8850492423"
                        },
                        {
                            sno:8,
                              name: 'Alexa Richardson',
                              city: 'Chennai',
                              state: 'TamilNadu',
                              address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                              zipcode : '600 002',
                              phone : "94950492423"
                            },
                            {
                              sno:9,
                                name: 'Alexa Richardson',
                                city: 'Chennai',
                                state: 'TamilNadu',
                                address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                                zipcode : '600 002',
                                phone : "9450492400"
                              },
                              {
                                sno:10,
                                  name: 'Alexa Richardson',
                                  city: 'Chennai',
                                  state: 'TamilNadu',
                                  address: "M/S.Associated Instruments and chemicals,No.125,langs Garden Road, Pudupet, Chennai -600 002",
                                  zipcode : '600 002',
                                  phone : "7890492423"
                                },
];

