import React, { useState } from 'react';
import {
  AppBar,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Box,
  Typography
} from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Page from 'src/components/Page';
import GRN from 'src/views/Project/GRN/index.jsx';
import GIN from 'src/views/Project/GIN/index.jsx';
import InventoryRegister from 'src/views/Project/inventryReg/index.jsx';
import FAR from 'src/views/Project/FAR/index.jsx';
import { checkPermission } from '../../../utils/permission.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // icon: {
  //   fill: "green",
  // },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Inventory = () => {
  const classes = useStyles();
  const allData = [
    {
      value: 'view_inventroyRegister',
      name: 'Inventory register'
    },
    {
      value: 'view_GRN',
      name: ' Create/View GRN'
    },
    {
      value: 'view&edit_GRN',
      name: 'Edit GRN'
    },
    {
      value: 'view_FAR',
      name: ' Create/View FAR'
    },
    {
      value: 'view&edit_FAR',
      name: 'Edit FAR'
    },
    {
      value: 'view_GIN',
      name: 'Create/View GIN'
    },
    {
      value: 'view&edit_GIN',
      name: 'Edit GIN'
    }
  ];

  let userData = JSON.parse(localStorage.getItem('userData'));

  var initialValue = {};
  initialValue = allData.find(data => {
    if (checkPermission(data.value)|| userData?.isAdmin == 1) return data;
  });
  const [value, setValue] = React.useState(initialValue?.value); // first tab

  const tabList = allData.filter(x => checkPermission(x.value)|| userData?.isAdmin == 1);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    console.log(index);
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };
  return (
    <Page className={classes.root} title="Indent" id="Indent">
      <Container maxWidth={false}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                {tabList &&
                  tabList.length > 0 &&
                  tabList.map((data, index) => {
                    return (
                      <Tab
                        style={{fontWeight: 'bold'}}
                        key={index}
                        label={data.name}
                        value={data.value}
                        onClick={() => a11yProps(data.value)}
                      />
                    );
                  })}
              </TabList>
            </Box>

            <>
              {value == 'view_inventroyRegister' && (
                <TabPanel
                  key={1}
                  value="view_inventroyRegister"
                  index="view_inventroyRegister"
                >
                  <InventoryRegister />
                </TabPanel>
              )}
              {value == 'view_GRN' && (
                <TabPanel key={4} value="view_GRN" index="view_GRN">
                  <GRN permission={"view_GRN"}/>
                </TabPanel>
              )}
               {value == 'view&edit_GRN' && (
                <TabPanel key={0} value="view_GRN" index="view_GRN">
                  <GRN permission= {"view&edit_GRN"}/>
                </TabPanel>
              )}

              {value == 'view_FAR' && (
                <TabPanel key={5} value="view_FAR" index="view_FAR">
                  <FAR permission={"view_FAR"}/>
                </TabPanel>
              )}
              {value == 'view&edit_FAR' && (
                <TabPanel key={2} value="view_FAR" index="view_FAR">
                  <FAR permission= {"view&edit_FAR"}/>
                </TabPanel>
              )}
              {value == 'view_GIN' && (
                <TabPanel key={3} value="view&edit_GIN" index="view&edit_GIN">
                  <GIN permission={"view_GIN"}/>
                </TabPanel>
              )}
              {value == 'view&edit_GIN' && (
                <TabPanel key={6} value="view&edit_GIN" index="view&edit_GIN">
                  <GIN permission = {"view&edit_GIN"}/>
                </TabPanel>
              )}
            </>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
};
export default Inventory;
