import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Card, Avatar, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl, MenuItem, FormHelperText, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import APIKit from '../../../utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
import './style.css';
import { currencyFormat1, dateOnlyRevReport } from '../Common/utilities';
import { MESSAGE } from 'src/utils/message';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { set } from 'nprogress';


const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
    },
    root: {

    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    alignForInventory: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    },

    tablerow: {

    }
}));

const NewSalesReport = ({ projectDetails, lookupData ,setTabName ,allProjects}) => {

    const classes = useStyles();
    const [receiptList, setReceiptList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const currentDates = new Date();
    const [salesPayload, setSalesPayload] = useState({
        typeID: '',
        categoryID: '',
        fromDate: currentDates.setDate(currentDates.getDate() - 30),
        toDate: new Date(),
        projectID: projectDetails.projectID,
        itemID: ''
    });
    const [isType, setIsType] = useState('all')
    const [isBatches, setIsBatches] = useState([]);
    const [isBatchId, setIsBatchId] = useState('');
    const [isCustomerId, setCustomerId] = useState(null);
    const [isStateName, setIsStateName] = useState("");
    const [allCustomer, setAllCustomer] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getItemsList()
        getListCustomer()
    }, [])

    let seenBatchNumbers = new Set();
    const getItemsList = () => {
        APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {

                    const finalData = res.data.data?.filter(item => {
                        if (seenBatchNumbers.has(item.batchNo)) {
                            return false;
                        } else {
                            seenBatchNumbers.add(item.batchNo);
                            return true;
                        }
                    });
                    setIsBatches(finalData)
                    console.log('final', finalData);
                } else {

                }
            })
            .catch((e) => {
            });
    };
    const getListCustomer = () => {
        APIKit.post(RGCA_URLS.listCustomer, {
            projectID: projectDetails.projectID
        })
            .then(res => {
                console.log('logggg:', res);
                if (res.data.status === 200) {
                    setAllCustomer(res.data.data);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const getCategories = type => {
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getSalesCategory, {
            typeID: type,
            projectID: projectDetails.projectID
        })
            .then(res => {
                if (res.data.status === 200) {
                    setCategoryList(res.data.data);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'success' });
                } else {
                    // toast.error(res.data.message);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'error' });
                }
            })
            .catch(e => { });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12} style={{ marginTop: '0' }}>
                    <Typography><h4 style={{ color: '#394671' }}>All Product Summary Report</h4></Typography>
                </Grid>
                <Grid item md={8} xs={12} style={{ marginTop: '0' }}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <FormControlLabel
                                value="all"
                                control={<Radio checked={isType == 'all'} color="primary" onChange={(e) => {
                                    setIsType('all')
                                }} />}
                                label="All"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="state"
                                control={<Radio checked={isType == 'state'} color="primary" onChange={(e) => {
                                    setIsType('state')
                                    setCustomerId(0)
                                    setIsBatchId("")
                                    setReceiptList([])
                                }} />}
                                label="State wise"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="batch"
                                control={<Radio checked={isType == 'batch'} color="primary" onChange={(e) => {
                                    setIsType('batch')
                                    setCustomerId(0)
                                    setIsStateName("")
                                    setReceiptList([])
                                }} />}
                                label="Batch wise"
                                labelPlacement="start"
                            />
                            {/* <FormControlLabel
                                value="framer"
                                control={<Radio checked={isType == 'framer'} color="primary" onChange={(e) => {
                                    setIsBatchId("")
                                    setIsStateName("")
                                    setIsType('framer')
                                    setReceiptList([])
                                }} />}
                                label="Framer wise"
                                labelPlacement="start"
                            /> */}

                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                            {isType === 'state' &&
                                <Grid item md={4} xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            required
                                            id="demo-simple-select-outlined-label"
                                        >
                                            State wise
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={isStateName}
                                            name="isStateName"
                                            label="State wise"
                                            onChange={e => {
                                                setIsStateName(e.target.value)
                                            }}
                                        >
                                            {allCustomer &&
                                                allCustomer.map(x => {
                                                    return (
                                                        <MenuItem value={x.stateName} key={x.stateName}>
                                                            {x.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {isType === 'batch' &&
                                <Grid item md={4} xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            required
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Batch wise
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Batch No."
                                            name="isBatchId"
                                            value={isBatchId}
                                            onChange={(e) => {
                                                setIsBatchId(e.target.value)
                                            }}
                                        >
                                            {isBatches && isBatches.map((x, index) => {
                                                return <MenuItem value={x.batchNo} key={index + 1}>
                                                    {x.batchNo}
                                                </MenuItem>
                                            })}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {isType === 'framer' &&
                                <Grid item md={4} xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            required
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Framer wise
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            // value={salesPayload.typeID}
                                            name="Framerwise"
                                            label="Framer wise"
                                            onChange={e => {
                                                setCustomerId(e.target.value)
                                            }}
                                        >
                                            {allCustomer &&
                                                allCustomer.map(x => {
                                                    return (
                                                        <MenuItem value={x.customerID} key={x.customerID}>
                                                            {x.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            {/* <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        required
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Product Item
                                    </InputLabel>
                                    <Select
                                        // defaultValue={lookupData[0]?.lookupID}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={salesPayload.typeID}
                                        name="typeID"
                                        label="Product Item"
                                        required
                                        onChange={e => {
                                            const p = salesPayload;
                                            p.typeID = e.target.value;
                                            setSalesPayload({
                                                ...salesPayload,
                                                typeID: e.target.value
                                            });
                                            getCategories(e.target.value);
                                        }}
                                    >
                                        {lookupData &&
                                            lookupData.map(x => {
                                                return (
                                                    <MenuItem value={x.lookupID} key={x.lookupID}>
                                                        {x.name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        required
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Category
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={salesPayload.categoryID}
                                        label="Category"
                                        name="categoryID"
                                        required
                                        onChange={e => {
                                            console.log("jjjjj", e);
                                            const p = salesPayload;
                                            p.categoryID = e.target.value;
                                            setSalesPayload({
                                                ...salesPayload,
                                                categoryID: e.target.value
                                            });
                                        }}
                                        disabled={!categoryList || categoryList.length === 0}
                                    >
                                        {categoryList &&
                                            categoryList.map(x => {
                                                return (
                                                    <MenuItem
                                                        value={x.categoryID}
                                                        key={x.categoryID}
                                                    >
                                                        {x.name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            // autoOk
                                            disableFuture
                                            // InputProps={{ disabled: true }}
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From Date"
                                            format="dd/MM/yyyy"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            name="fromDate"
                                            onChange={e => {

                                                const p = salesPayload;
                                                p.fromDate = e
                                                // moment(e).format("DD-MM-YYYY")
                                                setSalesPayload({
                                                    ...p
                                                    // fromDate: moment(e).format("DD-MM-YYYY")
                                                });
                                                setTabName(`Sales_Specific Product Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            value={salesPayload.fromDate ?? null}
                                            className={classes.calmar}
                                        // required

                                        />

                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            autoOk
                                            disableFuture
                                            // maxDate={new Date()}
                                            InputProps={{ disabled: true }}
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="To Date"
                                            format="dd/MM/yyyy"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            name="toDate"
                                            onChange={e => {
                                                const p = salesPayload;
                                                p.toDate = e;
                                                setSalesPayload({
                                                    ...p
                                                });
                                                setTabName(`Sales_Specific Product Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                            }}
                                            value={salesPayload.toDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            className={classes.calmar}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={`${isType == 'all' ? 2 : 4}`} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                {salesPayload.categoryID && salesPayload.typeID && salesPayload.projectID ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            setTabName(`Sales_Specific_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`);
                                            // getSalesReport();
                                        }}
                                        type="submit"
                                        size='large'
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled
                                        type="submit"
                                        size='large'
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>


            <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">S.No</TableCell >
                                        <TableCell align="left">DATE</TableCell>
                                        <TableCell align="left">FARMER NAME</TableCell>
                                        <TableCell align="left">CONTACT NUMBER</TableCell>
                                        <TableCell align="left">STATE </TableCell>
                                        <TableCell align="left">PRODUCT NAME</TableCell >
                                        <TableCell align="left">NO OF SEED</TableCell>
                                        <TableCell align="left">RATE PER SEED</TableCell>
                                        <TableCell align="left">NO OF POLY BAG</TableCell>
                                        <TableCell align="left">RATE PER BAGS </TableCell>
                                        <TableCell align="left">AMOUNT IN (RS)</TableCell>
                                        <TableCell align="left">REMARK/VECHLE NO </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                    <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                    <TableCell align="left">{moment(x?.sellingDate).format('DD/MM/YYYY')} </TableCell>
                                                    <TableCell align="left">{x.customer}</TableCell>
                                                    <TableCell align="left">{x?.contactNumber}</TableCell>
                                                    <TableCell align="left">{x.state}</TableCell>
                                                    <TableCell align="left">{x?.Productname}</TableCell>
                                                    <TableCell align="left">{x.quantity}</TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.ratePerUnit))}</TableCell>
                                                    <TableCell align="left">{x?.NoofBags}</TableCell>
                                                    <TableCell align="left">{x?.bagsPerUnit}</TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.totalAmount))}</TableCell>
                                                    <TableCell align="left">{''}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {receiptList && receiptList.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={receiptList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>

        </>
    )
}

export default NewSalesReport