import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import moment from 'moment';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import { useParams } from 'react-router-dom';
import { ETTypes, ETaction } from '../Common/Types';
import StepperBox from '../Common/StepperBox.jsx';
import './style.css';
import CommonTable from '../Common/CommonTable';
import { Constant } from '../Common/Constant';
import { getloggedInData } from 'src/utils/helpers';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'src/views/loader';
import { Formik, Form } from 'formik';
import { MESSAGE } from 'src/utils/message';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimeData from './Time.json'

const IndentInitialValues = {
  indentMasterID: '',
  ProjectID: null,
  statusID: null,
  UpdatedBy: null,
  CreatedBy: null,
  quoteUrl: '',
  CreateDate: '',
  UpdatedDate: null,
  projectName: '',
  CreatedByName: '',
  currentApprovals: {
    referOptionView: false,
    referSelected: false,
    name: '',
    indentApprovalSystemID: null,
    indentMasterID: '',
    orderID: null,
    accessRoleID: null,
    runningStatus: null,
    approvalID: null,
    editLock: true,
    CreatedBy: null,
    CreateDate: '',
    comment: '',
    approvalStatusID: null,
    status: ''
  },
  indentItem: [],
  Approvals: []
};
const customId = 'custom-id-yes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflow: 'auto'
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  }
}));

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ProjectIndents = () => {
  const { project } = useParams();
  const userData = getloggedInData();
  const { addToast } = useToasts();
  const projectDetails = { ...getProjectDetails(project) };
  const [currentApproveId, setCurrentApproveId] = useState(null);
  const [currentComment, setCurrentComment] = useState('')

  let userData2 = JSON.parse(localStorage.getItem('userData'));
  console.log("userData2", userData2);

  // const accessRoleID = JSON.parse(userData2?.project)?.find(
  //   end => end?.projectID == projectDetails?.projectID
  // )?.accessRoleID;

  const projectData = userData2?.project;
  const accessRoleID = projectData ? JSON.parse(projectData)?.find(
    end => end?.projectID == projectDetails?.projectID
  )?.accessRoleID : null;


  console.log('accessRoleID', accessRoleID);
  const classes = useStyles();
  const [indentView, setIndentView] = React.useState(false);
  const [statusApproval, setStatusApproval] = useState(false);
  const [refer, setRefer] = useState(false);
  const [indentListPayload, setIndentListPayload] = useState({
    indentMasterID: '',
    ProjectID: null,
    indentItem: [
      {
        Qty: null
      }
    ]
  });

  const [indentList, setIndentList] = useState([]);
  const [itemHis, setItemHis] = useState([]);
  const [originalApprovalStatusList, setOriginalApprovalStatusList] = useState([]);
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [direct, setDirect] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [dis, setdis] = useState(false)
  const [columns, setColumns] = useState([
    {
      title: 'Indent ID',
      field: 'indentMasterID',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Indented By',
      field: 'CreatedByName',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Indented date',
      field: 'CreateDate',
      align: 'center',
      type: ETTypes.date
    },
    {
      title: 'Description',
      field: 'indentComment',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      list: [ETaction.onEdit]
    }
  ]);

  const [indentItem, setIndentItem] = useState({ ...IndentInitialValues });
  const [timeHistroy, setTimeHistroy] = useState({
    totalAge: "",
    data: []
  })
  useEffect(() => {
    getIndentApprovalList();
  }, [navigate]);

  useEffect(() => {
    getLookup();
  }, [statusApproval, indentListPayload, navigate]);

  const handleIndentViewClose = () => {
    // setStatusEdit('')
    setIndentView(!indentView);
    getIndentApprovalList();
    setEdit(false);
  };

  const editItem = () => {
    setEdit(true);
  };

  const getIndentApprovalList = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getIndentApprovalList + '/' + projectDetails.projectID)
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              indentItem: parseData(x.indentItem)
            };
          });
          // console.log(data);
          setIndentListPayload(data);
          setIndentList(data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          console.log('list', res.data.data);
          setOriginalApprovalStatusList(res.data.data);
          let newData = [];
          const userProjects = JSON.parse(userData.project);
          let userAccessID = '';
          if (userProjects.length > 0) {
            userProjects.forEach(pro => {
              if (pro.projectName == project) {
                userAccessID = pro.accessRoleID;
              }
            });
          }

          if (indentListPayload && indentListPayload.length > 0) {
            indentListPayload.forEach(list => {
              if (list.Approvals && list.Approvals.length > 0) {
                const lastApproval = list.Approvals[list.Approvals.length - 1];
                if (lastApproval.accessRoleID == userAccessID) {
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 89 && x.lookupID != 90
                    )
                  );
                } else {
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 24 && x.lookupID != 25
                    )
                  );
                }
              }
            });
          }

          if (refer) {
            setApprovalStatusList(
              originalApprovalStatusList.filter(
                x => x.lookupID >= 89 && x.lookupID <= 91
              )
            );
          } else {
            setApprovalStatusList(
              originalApprovalStatusList.filter(
                x => x.lookupID != 89 && x.lookupID != 90
              )
            );
          }
        }
      })
      .catch(e => { });
  };

  const updateIndentItems = () => {
    console.log(indentItem.indentItem[0].Qty, "indentItem")
    if (indentItem.indentItem[0].Qty >= indentItem.indentItem[0].approvedQty) {
      const payload = {
        indentMasterID: indentItem.indentMasterID,
        items: indentItem.indentItem.map(x => {
          return {
            indentItemID: x.indentItemID,
            Qty: x.Qty,
            approvedQty: x.approvedQty,
            estimatedPrice: x.estimatedPrice
          };
        })
      };
      if (payload.items.some(e => e.approvedQty == 0)) {
        toast.error(MESSAGE.qty0, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }
      if (payload.items.some(e => e.estimatedPrice == 0)) {
        toast.error(MESSAGE.est0, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }
      APIKit.post(RGCA_URLS.indentItemUpdate, payload)
        .then(res => {
          if (res.data.status === 200) {
            //addToast(res.data.message, { appearance: 'success' });
            toast.success(MESSAGE.indentUpdate, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            setEdit(false);
            getIndentApprovalList();
          } else {
            addToast(res.data.message, { appearance: 'error' });
          }
        })
        .catch(function (e) { });
    }
    else {
      toast.error(MESSAGE.IndentApproalQuantity, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    };
  }

  const history = data => {
    const payload = {
      indentMasterID: data.indentMasterID,
      projectID: projectDetails.projectID
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getIndentHistory, payload)
      .then(res => {
        if (res.data.status === 200) {
          const temp = res.data.data.sort((a, b) => b.indentItemHistoryID - a.indentItemHistoryID);
          setItemHis(temp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const updateIndentApproval = () => {
    setIsLoading(true)
    let referOptionPayload = false;
    let referPayload = false;
    if (indentItem.currentApprovals.referOptionView == 0) {
      referOptionPayload = false;
    } else {
      referOptionPayload = true;
    }
    if (indentItem.currentApprovals.referSelected == 0) {
      referPayload = false;
    } else {
      referPayload = true;
    }

    const payload = {
      statusID: currentApproveId,
      indentMasterID: indentItem.indentMasterID,
      comment: currentApproveId == 24 ? '' : currentComment,
      referOption: referOptionPayload,
      refer: referPayload
    };

    if (payload.statusID > 0) {


      APIKit.post(RGCA_URLS.updateIndentApproval, payload)
        .then(res => {
          if (res.data.status === 200) {
    setIsLoading(false)
            handleIndentViewClose();
            toast.success(res.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: customId
            });

            getIndentApprovalList();
          } else {
    setIsLoading(false)
            toast.error(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(function (e) {
    setIsLoading(false)

         });
    };
  }

  const actions = {
    editRow: (index, field, value) => {
      let items = indentList;
      items[index][field] = value;
      setIndentList([...items]);
    },
    onView: (index, row) => {
      history(row);
    },
    onEdit: (index, row) => {
      console.log(row);
      setRefer(row.Approvals.some(e => e.refer === true));
      history(row);
      setDirect(false);
      let rowData = { ...row };
      rowData.currentApprovals = row.Approvals.find(
        app =>
          app.accessRoleID ===
          JSON.parse(getloggedInData().project).find(
            e => e.projectID == projectDetails.projectID
          ).accessRoleID
      );
      row.Approvals.forEach(e => {
        if (e.accessRoleID === 3) {
          setDirect(true);
        }
      });
      setCurrentApproveId(rowData.currentApprovals.approvalStatusID);
      setCurrentComment(rowData.currentApprovals.comment)
      setIndentItem({ ...rowData });
      console.log(rowData);
      handleIndentViewClose();
      getIndentApprovalListwithAge(row)
    },
    onDelete: (index, row) => { }
  };

  const regEx = {
    textOnly: /^[A-Za-z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9]*$/
  };

  const getIndentApprovalListwithAge = (Data) => {
    setIsLoading(true);
    let payload = {
      projectID: Data?.ProjectID,
      indentMasterID: Data?.indentMasterID,
    };
    APIKit.post(RGCA_URLS.getIndentApprovalListwithAge, payload)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          console.log("res.data", res.data);
          setTimeHistroy(res?.data?.data)
        }
      })
      .catch(function (e) { });
  };

  const [flag, setFlag] = useState(true)
  useEffect(() => {
    let firstAwaitingObject = {};
    for (let i = 0; i < indentItem.Approvals.length; i++) {
      if (indentItem.Approvals[i].status === "Awaiting") {
        firstAwaitingObject = indentItem.Approvals[i];

        if (accessRoleID == indentItem.Approvals[i].accessRoleID && firstAwaitingObject.editLock != 1) {
          setFlag(false)
        } else {
          setFlag(true)
        }
        break;
      }
      if (indentItem.Approvals[i].status === "Hold") {
        firstAwaitingObject = indentItem.Approvals[i];
        setCurrentApproveId(indentItem.Approvals[i].approvalStatusID)
        setCurrentComment(indentItem.Approvals[i].comment)
        if (accessRoleID == indentItem.Approvals[i].accessRoleID && firstAwaitingObject.editLock != 1) {
          setFlag(false)
        } else {
          setFlag(true)
        }
        break;
      }
      if (indentItem.Approvals[i].status === "Dropped") {
        firstAwaitingObject = indentItem.Approvals[i];
        setCurrentComment(indentItem.Approvals[i].comment)
        if (accessRoleID == indentItem.Approvals[i].accessRoleID && firstAwaitingObject.editLock != 1) {
          setFlag(false)
        } else {
          setFlag(true)
        }
        break;
      }
      if (indentItem.Approvals[i].status === "Rejected") {
        firstAwaitingObject = indentItem.Approvals[i];
        setCurrentApproveId(indentItem.Approvals[i].approvalStatusID)
        setCurrentComment(indentItem.Approvals[i].comment)
        if (accessRoleID == indentItem.Approvals[i].accessRoleID && firstAwaitingObject.editLock != 1) {
          setFlag(false)
        } else {
          setFlag(true)
        }
        break;
      }

      else {
        setCurrentApproveId(0)
        setCurrentComment("")
      }
    }
  }, [indentItem.Approvals])

  return (
    <div>
      <ToastContainer limit={1} />
      <Container
        maxWidth={true}
        style={{ paddingLeft: '0px', paddingRight: '0px' }}
      >
        <Loader isLoading={isLoading} />
        <Typography>
          <h2 style={{ color: '#394671' }}>Indent Approval</h2>
        </Typography>
        <Grid container spacing={3} className={classes.field}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <CommonTable
                className={classes.root}
                columns={columns}
                data={indentList}
                action={actions}
              />
            </div>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            Note:
              indentItem.currentApprovals.comment == 'undefined'
                ? ''
                : indentItem.currentApprovals.comment,
            status:
              indentItem.currentApprovals.approvalStatusID == 0
                ? ''
                : indentItem.currentApprovals.approvalStatusID
          }}
          onSubmit={(values, { resetForm }) => {
            updateIndentApproval();
            resetForm(values);
          }}
          validationSchema={
            Yup.object().shape({
              status: Yup.string().required(MESSAGE.status)
            })
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched
          }) => (
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={indentView}
              id="income"
              //  style={{ maxWidth: "1100px !important", width: "1100px" }}
              contentstyle={{
                width: '200%',
                maxWidth: 'none'
              }}
              scroll={'paper'}
              maxWidth="lg"
              fullWidth
            >
              <Loader isLoading={isLoading} />
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleIndentViewClose}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  width: '800px !important'
                }}
              >
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <h2>
                    Indent Approval Update
                  </h2>
                  <h3 onClick={() => {
                    handleIndentViewClose()
                  }}>
                    x
                  </h3>
                </div>
              </DialogTitle>
              <Form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <DialogContent dividers style={{ width: '800px !important' }}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Indent ID : {indentItem.indentMasterID}
                        </h5>
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Total Quantity:
                          {indentItem.indentItem ?
                            indentItem.indentItem.reduce((a, b) => a + b.approvedQty, 0) : 0}
                        </h5>
                      </div>
                    </Grid>

                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Date : {dateFormate(indentItem.CreateDate)}
                        </h5>
                      </div>
                    </Grid>
                    {/* ---------------------------------------------------------------------------------------- */}
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          Estimated Price:{' '}
                          {indentItem.indentItem
                            ? indentItem.indentItem
                              .reduce((a, b) => a + b.estimatedPrice, 0)
                              .toLocaleString('en-IN')
                            : 0}
                        </h5>
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          {' '}
                          Created By: {indentItem.CreatedByName}
                        </h5>
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>Total Age : {timeHistroy?.totalAge}</h5>
                      </div>
                    </Grid>
                    <Grid item lg={6} sm={6} xl={6} xs={6}></Grid>
                    <Grid
                      item
                      lg={6}
                      sm={6}
                      xl={6}
                      xs={6}
                      style={{ textAlign: 'end' }}
                    >
                      {!indentItem.currentApprovals.editLock && indentItem.currentApprovals.approvalStatusID != 89 && (
                        // <EditOutlinedIcon
                        // style={{ cursor: 'pointer' }} onClick={(e) =>  editItem} />
                        // <a
                        // className={classes.button}
                        //   href="javascript:void(0)"
                        //   onClick={() => {
                        //     editItem();
                        //   }}
                        // >
                        //   Edit
                        // </a>
                        <Button
                          className={classes.button}
                          onClick={() => {
                            editItem();
                          }}
                          type="submit"
                          disabled={dis}
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  Indent Item ID
                                </TableCell>
                                <TableCell align="left">
                                  Item / Asset Name
                                </TableCell>
                                <TableCell align="left">UOM</TableCell>
                                <TableCell align="left">
                                  Requested Quantity
                                </TableCell>
                                <TableCell align="left">
                                  {edit
                                    ? null
                                    : 'Approved Quantity'}
                                </TableCell>
                                {edit && (
                                  <TableCell align="left">
                                    Approved Quantity
                                  </TableCell>
                                )}
                                <TableCell align="left">Stock</TableCell>
                                <TableCell align="left">Attachment</TableCell>
                                <TableCell align="left">Item Note</TableCell>
                                <TableCell align="left">
                                  Estimated Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {indentItem.indentItem ?
                                indentItem.indentItem
                                  // .slice(
                                  //   page * rowsPerPage,
                                  //   page * rowsPerPage + rowsPerPage
                                  // )
                                  .map((item, index) => (
                                    <TableRow key={index}
                                      hover
                                    // key={emplyoee.id}
                                    // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                                    >
                                      <TableCell align="left">
                                        {item.indentItemID}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item.name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item.uom}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item.Qty}
                                      </TableCell>
                                      <TableCell align="left">
                                        {edit
                                          ? null
                                          : item.approvedQty}

                                      </TableCell>
                                      {edit && (
                                        <TableCell>
                                          <TextField
                                            fullWidth
                                            inputProps={{ maxLength: 10 }}
                                            label="Approved Quantity"
                                            name="qty"
                                            onChange={e => {
                                              if (
                                                e.target.value === '' ||
                                                regEx.numbersOnly.test(
                                                  e.target.value
                                                )
                                              ) {
                                                const p = indentItem;
                                                p.indentItem[
                                                  index
                                                ].approvedQty = Number(
                                                  e.target.value
                                                );
                                                setIndentItem({ ...p });
                                              }
                                            }}
                                            value={item.approvedQty}
                                            variant="outlined"
                                          />{' '}
                                        </TableCell>
                                      )}
                                      <TableCell align="left">
                                        {item.availabilityQty}
                                      </TableCell>
                                      <TableCell align="left">
                                        <a style={{ color: 'blue', cursor: 'pointer' }}
                                          href={item.itemAttach} target="_blank"
                                        >{item.itemAttach ? item.itemAttach.substring(4, 25) : ''}
                                        </a>
                                      </TableCell>
                                      <TableCell align="left">
                                        {item.comment == 'null'
                                          ? '-'
                                          : item.comment}
                                      </TableCell>
                                      <TableCell align="left">
                                        {Number(
                                          (item.estimatedPrice / item.Qty) *
                                          item.approvedQty
                                        ).toLocaleString('en-IN')}
                                      </TableCell>
                                    </TableRow>
                                  )) : 0}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <br />
                      <div style={{ textAlign: 'right' }}>
                        {edit && (
                          <>

                            <Button variant="contained" color="#394671"

                              className={classes.button2}

                              // style={{

                              //   color: '#394671',
                              //   border: '1px solid #394671',
                              //   margin: '5px '


                              // }}
                              type="submit"
                              onClick={e => {
                                updateIndentItems();
                              }}
                            >
                              Update
                            </Button>


                            <Button variant="contained" color="#394671"
                              className={classes.button2}
                              // style={{

                              //   color: '#394671',
                              //   border: '1px solid #394671',
                              //   margin: '5px '

                              // }}
                              onClick={() => {
                                getIndentApprovalList()
                                setEdit(false)
                                console.log("setOriginalApprovalStatusList", originalApprovalStatusList);

                              }
                              }


                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Box mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={2}>

                      {/* this is refer to directer we temparray commented dont remove */}

                      
                      {/* {indentItem.currentApprovals.referOptionView == 1 && !direct && (
                        <Grid
                          item
                          md={indentItem.currentApprovals.referOptionView ? 3 : 0}
                          xs={indentItem.currentApprovals.referOptionView ? 12 : 0}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    indentItem.currentApprovals.editLock == 1 ? true : false
                                  }
                                  checked={
                                    indentItem.currentApprovals.referSelected == 1 ? true : false
                                  }
                                  onChange={e => {
                                    let rowData = { ...indentItem };
                                    rowData.currentApprovals.referSelected = !rowData
                                      .currentApprovals.referSelected;

                                    if (e.target.checked) {
                                      setApprovalStatusList(
                                        originalApprovalStatusList.filter(
                                          x =>
                                            x.lookupID >= 89 && x.lookupID <= 91
                                        )
                                      );
                                    } else {
                                      setApprovalStatusList(
                                        originalApprovalStatusList.filter(
                                          x =>
                                            x.lookupID != 89 && x.lookupID != 90
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                              label="Refer to director"
                            />
                          </FormGroup>
                        </Grid>
                      )} */}
                      <Grid
                        item
                        md={indentItem.currentApprovals.referOptionView ? 3 : 4}
                        xs={12}
                      >

                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(touched.status && errors.status)}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Status
                          </InputLabel>
                          <Select
                            // disabled={indentItem.currentApprovals.editLock == 1 ? true : false}
                            disabled={flag}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onBlur={handleBlur}
                            required
                            onChange={e => {
                              handleChange(e);
                              // let rowData = { ...indentItem };
                              // rowData.currentApprovals.comment = "";
                              // rowData.currentApprovals.approvalStatusID =
                              //   e.target.value;
                              // setIndentItem({ ...rowData });
                              // if( e.target.value){
                              // setdis(true)
                              // }
                              let firstAwaitingObject = null;

                              for (let i = 0; i < indentItem.Approvals.length; i++) {
                                if (indentItem.Approvals[i].status === "Awaiting") {
                                  firstAwaitingObject = indentItem.Approvals[i];
                                  break;
                                }
                                if (indentItem.Approvals[i].status === "Hold") {
                                  firstAwaitingObject = indentItem.Approvals[i];
                                  break;
                                }
                              }

                              // indentItem.Approvals.find(obj => {
                              if (accessRoleID == firstAwaitingObject.accessRoleID && (firstAwaitingObject.status == "Awaiting" || firstAwaitingObject.status == "Hold" || firstAwaitingObject.status == "Dropped")) {
                                let rowData = { ...indentItem };
                                rowData.currentApprovals.comment = firstAwaitingObject.comment;
                                firstAwaitingObject.approvalStatusID = e.target.value;
                                if (firstAwaitingObject.approvalStatusID == 24) {
                                  setCurrentComment("")
                                } else {
                                  setCurrentComment(firstAwaitingObject.comment)
                                }
                                setCurrentApproveId(e.target.value)
                                setIndentItem({ ...rowData, firstAwaitingObject });
                                if (e.target.value) {
                                  setdis(true)
                                }
                              }
                              // }
                              // );

                            }}
                            name="status"
                            label="Status"
                            value={currentApproveId
                            }
                          >
                            <MenuItem value="" disabled>
                              <em>Select Status</em>
                            </MenuItem>
                            {approvalStatusList.map(li => (
                              <MenuItem key={li.lookupID} value={li.lookupID}>
                                {li.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors.status && touched.status && errors.status}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {[25, 26, 28, 90].findIndex(
                        x => x == currentApproveId
                      ) != -1 && (
                          <Grid
                            item
                            md={
                              indentItem.currentApprovals.referOptionView ? 6 : 8
                            }
                            xs={12}
                          >
                            <TextField
                              disabled={flag}
                              fullWidth
                              // error={Boolean(
                              //   [25, 26, 28, 90].some(
                              //     x =>
                              //       x ==
                              //       indentItem.currentApprovals.approvalStatusID
                              //   )
                              //     ? touched.Note && errors.Note
                              //     : 'false'
                              // )}
                              // helperText={
                              //   [25, 26, 28, 90].some(
                              //     x =>
                              //       x ==
                              //       indentItem.currentApprovals.approvalStatusID
                              //   )
                              //     ? touched.Note && errors.Note
                              //     : false
                              // }
                              label="Note"
                              name="Note"
                              inputProps={{ maxLength: 50 }}
                              onBlur={handleBlur}
                              value={
                                currentComment
                              }
                              onChange={e => {
                                handleChange(e);
                                let rowData = { ...indentItem };
                                setCurrentComment(e.target.value)
                                rowData.currentApprovals.comment = e.target.value;
                                setIndentItem({ ...rowData });
                              }}
                              variant="outlined"
                            />
                          </Grid>
                        )}

                    </Grid>
                  </Box>

                  <br />

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Approval Status:</b>
                      </Typography>
                      <StepperBox steps={indentItem.Approvals} currentApproveId={currentApproveId} />
                    </Grid>
                  </Grid>


                  {/* new change for time period */}
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.secondaryHeading}>
                              <b>Indent Approved Time History</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Indent Id</TableCell>
                                    <TableCell>Approval By</TableCell>
                                    <TableCell>Raised Date</TableCell>
                                    <TableCell>Approval Date</TableCell>
                                    <TableCell>Age</TableCell>
                                    <TableCell>Created By</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {timeHistroy?.data && timeHistroy?.data?.length != 0 ? (
                                    timeHistroy?.data?.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => (
                                      <TableRow>
                                        <TableCell> {x?.indentMasterID}</TableCell>
                                        <TableCell>{x?.roleName}</TableCell>
                                        <TableCell> {moment(x?.CreateDate).format(
                                          "DD/MM/yyyy"
                                        )}</TableCell>
                                        <TableCell>
                                          {moment(x?.UpdatedDate).format(
                                            "DD/MM/yyyy"
                                          )}
                                        </TableCell>
                                        <TableCell>{x?.hoursDifference}</TableCell>
                                        <TableCell>{x?.empLoginID}</TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                      <TableCell colSpan={6} align="center" key={2}>
                                        {"No Data Found"}
                                      </TableCell>{" "}
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.secondaryHeading}>
                              <b>Indent Partial-Edit-Registry-Approval-Item-History</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Indent Item Id</TableCell>
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Requested Quantity</TableCell>
                                    <TableCell>Approval Quantity</TableCell>
                                    <TableCell>Updated By</TableCell>
                                    <TableCell>Updated Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {itemHis && itemHis?.length != 0 ? (
                                    itemHis
                                      ?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                      .map((item, index) => (
                                        <TableRow>
                                          <TableCell>
                                            {item.indentItemID}
                                          </TableCell>
                                          <TableCell>{item.itemName}</TableCell>
                                          <TableCell>
                                            {item.requestQty}
                                          </TableCell>
                                          <TableCell>{item.newValue}</TableCell>
                                          <TableCell>
                                            {item.employeeName}
                                          </TableCell>
                                          <TableCell>
                                            {moment(item.updatedDate).format(
                                              'dd/MM/yyyy HH:mm:ss'
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                  ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                      <TableCell
                                        colSpan={6}
                                        align="center"
                                        key={2}
                                      >
                                        {'No Data Found'}
                                      </TableCell>{' '}
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >

                  <Button
                    className={classes.button2}
                    // disabled={indentItem.currentApprovals.editLock == 1 ? true : false}
                    disabled={flag}
                    type="submit"
                  >
                    Update
                  </Button>

                  <Button
                    className={classes.button2}
                    onClick={handleIndentViewClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default ProjectIndents;