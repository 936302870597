import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Card, Avatar, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl, MenuItem, FormHelperText
} from '@material-ui/core';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import APIKit from '../../../utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
import './style.css';
import { currencyFormat1, dateOnlyRevReport } from '../Common/utilities';
import { MESSAGE } from 'src/utils/message';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { set } from 'nprogress';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
    },
    root: {

    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    alignForInventory: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    },

    tablerow: {

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};





const PaymentReport = ({ lookupData, projectDetails, setIsLoading, setTabName, isTabName }) => {
    const classes = useStyles();
    const [receiptList, setReceiptList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const currentDates = new Date();
    const [salesPayload, setSalesPayload] = useState({
        typeID: '',
        categoryID: '',
        fromDate: currentDates.setDate(currentDates.getDate() - 30),
        toDate: new Date(),
        projectID: projectDetails.projectID,
        itemID: ''
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [print, setprint] = useState(false)
    const a11yProps = (index) => {
        if (index == 0) {
            setSalesPayload({
                typeID: '',
                categoryID: '',
                fromDate: null,
                toDate: null,
                projectID: projectDetails.projectID,
                itemID: ''
            });
            setReceiptList([])

        }
        if (index == 1) {
            setReceiptList([])
            setIsLoading(true)
            setSalesPayload({
                typeID: '',
                categoryID: '',
                fromDate: currentDates.setDate(currentDates.getDate() - 30),
                toDate: new Date(),
                projectID: projectDetails.projectID,
                itemID: ''
            });

            setTimeout(() => {
                setIsLoading(false);
                getSalesReport()
            })
        }
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getCategories = type => {
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getSalesCategory, {
            typeID: type,
            projectID: projectDetails.projectID
        })
            .then(res => {
                if (res.data.status === 200) {
                    setCategoryList(res.data.data);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'success' });
                } else {
                    // toast.error(res.data.message);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'error' });
                }
            })
            .catch(e => { });
    };

    const getSalesReport = () => {
        let changedDate = new Date(salesPayload?.fromDate);
        let formattedDate = changedDate.toISOString();

        setReceiptList([])
        setIsLoading(true)
        let payload = {
            projectID: projectDetails.projectID,
            typeID: salesPayload.typeID ? salesPayload.typeID : 0,
            categoryID: salesPayload.categoryID ? salesPayload.categoryID : 0,
            fromDate: formattedDate ? moment(formattedDate).format("YYYY-MM-DD") : '',
            toDate: salesPayload.toDate ? moment(salesPayload.toDate).format("YYYY-MM-DD") : ''
        };
        APIKit.post(RGCA_URLS.getSalesReport, payload)
            .then(res => {
                if (res.data.status === 200) {
                    const arr = res.data.data
                    const ids = arr.map(({ receiptID }) => receiptID);
                    const filtered = arr.filter(({ receiptID }, index) => !ids.includes(receiptID, index + 1));
                    setReceiptList(filtered)
                    setIsLoading(false)
                    RemoveDupilicate()
                    if (res?.data?.data.length > 0) {
                        toast.success("Successfully fetching data")
                    } else {
                        toast.warning('No data found.!.')
                    }

                } else {
                    toast.warn(res.data.message)
                    setIsLoading(false)
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };

    const RemoveDupilicate = () => {
        let SaleDate = receiptList

    }

    const exportToCSV = () => {
        let name = projectDetails.projectName + 'Payment_Report' + '_' + currentDate

        try {
            // const fileName = name;
            const fileName = `${projectDetails?.projectName}_${isTabName}`;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            // Check if receiptList is an array and not empty
            if (!Array.isArray(receiptList) || receiptList.length === 0) {
                // throw new Error('Receipt list is empty or invalid.');
                toast.warning('Sales list is empty')
            }
            receiptList.forEach(x => {
                const obj = {};
                obj['Project Name'] = x.projectName ? x.projectName : ''; // Setting default value to empty string if falsy
                obj['Total Amount'] = x.totalAmount ? x.totalAmount : ''; // Setting default value to empty string if falsy
                obj['Paid Amount'] = x.totalAmountPaid ? x.totalAmountPaid : ''; // Setting default value to empty string if falsy
                obj['Remaining Balance'] = x.remaningBalance ? x.remaningBalance : ''; // Setting default value to empty string if falsy
                newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 15 }, // Receipt ID
                { wch: 20 }, // Product Name
                { wch: 15 }, // Selling Date
                { wch: 15 }, // Rate Per Unit
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };

    const htmlContentRef = useRef(null);
    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });
    const currentDate = new Date().toLocaleDateString();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };



    return (
        <>
            <div>
                <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        color='red'
                    >
                        <Tab label="Specific Product Report" onClick={() => {
                            a11yProps(0);
                            setTabName(`Payment_Specific_Product_Report`)

                        }
                        } />
                        <Tab label="All Product Report" onClick={() => {
                            a11yProps(1)
                            // setTabName(`Payment_All_Product_Report`)
                            setTabName(`Payment_All_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)


                        }} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Typography><h2 style={{ color: '#394671' }}>Specific Product Report</h2></Typography>

                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Product Item
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={salesPayload.typeID}
                                                name="typeID"
                                                label="Product Item"
                                                required
                                                onChange={e => {
                                                    const p = salesPayload;
                                                    p.typeID = e.target.value;
                                                    setSalesPayload({
                                                        ...p,
                                                        typeID: e.target.value
                                                    });
                                                    getCategories(e.target.value);
                                                }}
                                            >
                                                {lookupData &&
                                                    lookupData.map(x => {
                                                        return (
                                                            <MenuItem value={x.lookupID} key={x.lookupID}>
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={salesPayload.categoryID}
                                                label="Category"
                                                name="categoryID"
                                                required
                                                onChange={e => {
                                                    const p = salesPayload;
                                                    p.categoryID = e.target.value;
                                                    setSalesPayload({
                                                        ...p,
                                                        categoryID: e.target.value
                                                    });
                                                }}
                                                disabled={!categoryList || categoryList.length === 0}
                                            >
                                                {categoryList &&
                                                    categoryList.map(x => {
                                                        return (
                                                            <MenuItem
                                                                value={x.categoryID}
                                                                key={x.categoryID}
                                                            >
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.fromDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Payment_Specific_Product_Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={salesPayload.fromDate ?? null}
                                                    className={classes.calmar}
                                                    required

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.toDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Payment_Specific_Product_Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    value={salesPayload.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={2} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {salesPayload.categoryID && salesPayload.typeID && salesPayload.projectID ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Payment_Specific_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography><h2 style={{ color: '#394671' }}>All Product Report</h2></Typography>
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center"  >
                                    <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Report Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={'All'}
                                                name="typeID"
                                                label="Product Item"
                                                disabled
                                            >

                                                <MenuItem value={'All'} >
                                                    All
                                                </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.fromDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Payment_All_Product_Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={salesPayload.fromDate ?? null}
                                                    className={classes.calmar}
                                                    required

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.toDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Payment_All_Product_Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    value={salesPayload.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={2} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {salesPayload.fromDate && salesPayload.toDate && salesPayload.projectID ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Payment_All_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`);
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
            </div>


            {receiptList && receiptList.length > 0 &&
                <>
                    <Box display="flex" justifyContent="flex-end">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} lg={2}
                                style={{
                                    marginTop: '10px',
                                    // color: '#3f51b5',
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    gap: '8px'

                                }}
                            >
                                <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <PrintIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    exportToCSV();
                                    setprint(true);

                                }} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">S.No</TableCell >
                                        {/* <TableCell align="left">Receipt ID</TableCell > */}
                                        {/* <TableCell align="left">Product Name</TableCell > */}
                                        <TableCell align="left">Project Name</TableCell >
                                        {/* <TableCell align="left">Selling Date</TableCell> */}
                                        {/* <TableCell align="left">Rate Per Unit</TableCell> */}
                                        {/* <TableCell align="left">Quantity</TableCell> */}
                                        {/* <TableCell align="left">GST</TableCell> */}
                                        {/* <TableCell align="left">Amount</TableCell> */}
                                        <TableCell align="left">Total Amount</TableCell>
                                        <TableCell align="left">Total Amount Paid</TableCell>
                                        <TableCell align="left">Remaning Balance</TableCell>
                                        {/* <TableCell align="left">Remaning Balance</TableCell> */}
                                        {/* <TableCell align="left">Mode of Payment</TableCell>
                                        <TableCell align="left">Customer Name</TableCell>
                                        <TableCell align="left">Purpose</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                    <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                    {/* <TableCell align="left">{x?.receiptID}</TableCell> */}
                                                    {/* <TableCell align="left">{x?.Productname}</TableCell> */}
                                                    <TableCell align="left">{x?.projectName}</TableCell>
                                                    {/* <TableCell align="left">
                                                        {moment(x?.sellingDate).format('DD/MM/YYYY')}
                                                    </TableCell> */}
                                                    {/* <TableCell align="left">{x.ratePerUnit}</TableCell> */}
                                                    {/* <TableCell align="left">{x.quantity}</TableCell> */}
                                                    {/* <TableCell align="left">{x.gstRate}</TableCell> */}
                                                    {/* <TableCell align="left">{x.totalAmount ? currencyFormat1(Math.round(x.totalAmount)) : ''}</TableCell> */}
                                                    <TableCell align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(x.totalAmount)) : ''}</TableCell>
                                                    <TableCell align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(Number(x.totalAmountPaid))) : ''}</TableCell>
                                                    <TableCell align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(Number(x.remaningBalance))) : ''}</TableCell>
                                                    {/* <TableCell align="left">{x.remaningBalance ? currencyFormat1(Math.round(x.remaningBalance)) : ''}</TableCell> */}
                                                    {/* <TableCell align="left">{x.modeofReceipt == 42 ? 'CASH' : x.modeofReceipt == 46 ? 'UPI' : 'BANK ACCOUNT'}</TableCell>
                                                    <TableCell align="left">{x.customer}</TableCell>
                                                    <TableCell align="left">{x.purpose}</TableCell> */}

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {receiptList && receiptList.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={receiptList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>

                    <div className="printViewBox" ref={htmlContentRef} id="myHtmlContent">
                        <div class="grid-container">
                            <div class="logo-container">
                                <img src="/static/logo-rgca.jpg" alt="Company logo" />
                            </div>
                            <div class="text-container">
                                <div>
                                    <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                                    <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                                    <h5>HEAD OFFICE / UNIT OFFICE</h5>
                                </div>
                            </div>
                        </div>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid item>
                                <Typography>
                                    Name :  Sales Report
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    From Date : {moment(receiptList[0]?.fromDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    To Date : {moment(receiptList[0]?.toDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Date : {currentDate}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Project Name :  {projectDetails?.projectName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <div class="">
                            <table class="table">
                                <thead class='tableHead'>
                                    <tr class="table-rows">
                                        <th align="left">Project Name</th >
                                        <th align="left">Total Amount</th>
                                        <th align="left">Total Amount Paid</th>
                                        <th align="left">Remaning Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.map((x, index) => {
                                            return (
                                                <tr class="table-row">
                                                    <td align="left">{x?.projectName}</td>
                                                    <td align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(x.totalAmount)) : ''}</td>
                                                    <td align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(Number(x.totalAmountPaid))) : ''}</td>
                                                    <td align="left">{x.totalAmountPaid ? currencyFormat1(Math.round(Number(x.remaningBalance))) : ''}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr class="table-row">
                                            <td align="left">
                                                {'No Data Found'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PaymentReport