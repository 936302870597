import moment from 'moment'
export const dateFormate = (date = null) => {
    if (!date) return "-"
    return moment(date).format("DD-MM-YYYY")

}
export const dateOnly = (date = null) => {
    if (!date) return "-"
    return moment(date).format("DD-MM-YYYY")

}
export const dateForm = (date = null) => {
    if (!date) return "-"
    return moment(date).format("DD-MM-YYYY")

}

export const dateOnlyRev = (date = null) => {
    if (!date) return "-"
    return moment(date).format("DD-MM-YYYY")

}

export const dateOnlyRevReport = (date = null) => {
    if (!date) return "-"
    return moment(date).format("DD-MM-YYYY")

}

export const dataSort = (list, field) => {
    list.sort(function(a, b) {
        var nameA = a[field];
        var nameB = b[field];
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });
    return list;

}
export const capitalLatter = (string) => {
    if (string) {
        if (string.length < 4) {
            return string;
        } else {
            return string.replace(/[A-Z][a-z]*/g, str => ' ' + str)
        }

    } else {
        return string;
    }
}
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const parseData = (data = null) => {
    if (data) {
        try {
            return JSON.parse(data)
        } catch (err) {
            console.log(err);
            return null;
        }
    }
    return null;

}

const projectInitialValues = {
    "projectID": 0,
    "projectName": "",
    "addressID": 0,
    "Description": "",
    "bankID": 0,
    "shortID": "",
    "mobile": "",
    "UpdatedBy": null,
    "CreatedBy": 0,
    "CreateDate": "",
    "UpdatedDate": null,
    "address": [],
    "bankDetails": []
}
export const getProjectDetails = (name) => {
    if (localStorage.getItem("projectList")) {
        let project = JSON.parse(localStorage.getItem("projectList")).find(x => x.projectName?.toLowerCase().trim() === name?.toLowerCase().trim())
        if (project) {
            return project
        }

    }
    return {...projectInitialValues }


}



export const convertToInternationalCurrencySystem = (value) => {
    // Quadrillion
    return Math.abs(Number(value)) >= 1.0e+15
      ? (Math.abs(Number(value)) / 1.0e+15).toFixed(2) + "Q"
      //Trillion
      : Math.abs(Number(value)) >= 1.0e+12
        ? (Math.abs(Number(value)) / 1.0e+12).toFixed(2) + "T"
        // Nine Zeroes for Billions
        : Math.abs(Number(value)) >= 1.0e+9
          ? (Math.abs(Number(value)) / 1.0e+9).toFixed(2) + "B"
          // Six Zeroes for Millions 
          : Math.abs(Number(value)) >= 1.0e+6
            ? (Math.abs(Number(value)) / 1.0e+6).toFixed(2) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(value)) >= 1.0e+3
              ? (Math.abs(Number(value)) / 1.0e+3).toFixed(2) + "K"
              : Math.abs(Number(value)).toFixed(4);
  }
  export const precision = (num, len) => {
    if (num !== null) {
      var num = num.toString();
      var s = num.split(".");
      if (s.length === 2) {
        if (Number(s[1]) === 0) {
          return s[0]
        }
        if (s[1].length > len) {
          if (Number(s[0] > 5)) {
            if (len < 4) {
              return parseFloat(s[0] + "." + s[1].slice(0, len).toLocaleString());
            }
            return parseFloat(s[0] + "." + s[1].slice(0, 4).toLocaleString());
          } else {
            return parseFloat(s[0] + "." + s[1].slice(0, len).toLocaleString());
          }
        }
        else {
          return parseFloat(num).toLocaleString()
        }
      }
      else {
        return parseFloat(num).toLocaleString()
      }
    } else {
      return null
    }
  }

  export function currencyFormat1(id) {
    var x;
    x = id.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return  res;
}