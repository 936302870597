// let baseUrl = "http://localhost:4500/api/v1/";

// let baseUrl = "http://45.79.140.99/api/";
let baseUrl = "api/"
// let baseUrl = "https://rgca.brugu.io/api/"

export const APP_URLS = {

  login: baseUrl + 'users/login',
  getLocation: baseUrl + 'location/getLocations',
  addLocation: baseUrl + 'location/addLocation',
  LandType: baseUrl + 'capital-investment/getSubCategorydata/2',
  seedProductionType: baseUrl + 'capital-investment/getSubCategorydata/1002',

  addProject: baseUrl + 'project-profile/addProjectDetails',
  getAllProjects: baseUrl + 'project-profile/getAllProjects',

  getParticularProject: baseUrl + 'project-profile/getProjectDetails/',
  search: baseUrl + 'location/searchLocation/',
  updateLocationStatus: baseUrl + 'location/updateLocationStatus',
  updateLocation: baseUrl + 'location/updateLocation',
  updateProjectDetails: baseUrl + 'project-profile/updateProjectDetails',
  getCategorywiseAmonut: baseUrl + 'capital-investment/getCategorywiseAmonut/',
  getCapitalInvetment: baseUrl + 'capital-investment/getCapitalInvetment',
  getSubCategorydata: baseUrl + 'capital-investment/getSubCategorydata/',
  addCapitalInvetment: baseUrl + 'capital-investment/addCapitalInvetment',
  UpdateCapitalInvestment: baseUrl + 'capital-investment/UpdateCapitalInvestment',
  getRecurringCategorywiseAmonut: 'recurring-expenditure/getRecurringCategorywiseAmonut/',
  getParentSubCategorydata: baseUrl + 'capital-investment/getParentSubCategorydata',
  addRecurringExpenditure: baseUrl + 'recurring-expenditure/addRecurringExpenditure',
  getRecurringExpenditure: baseUrl + 'recurring-expenditure/getRecurringExpenditure',
  updateRecurringExpenditure: baseUrl + 'recurring-expenditure/updateRecurringExpenditure',
  removeLandDetails: baseUrl + 'project-profile/removeLandDetails',
  removeSeedDetails: baseUrl + 'project-profile/removeSeedDetails',
  deleteCapitalInvestment: baseUrl + 'capital-investment/deleteCapitalInvestment',
  deleteRecurringExpenditure: baseUrl + 'recurring-expenditure/deleteRecurringExpenditure',

  addRole: baseUrl + 'role/addRole',
  getAllRoles: baseUrl + 'role/getAllRoles',
  updateRole: baseUrl + 'updateRole',
  deleteRole: baseUrl + 'role/deleteRole',
  searchRole: baseUrl + 'role/searchRole',

  addDepartment: baseUrl + 'addDepartment',
  getDepartment: baseUrl + 'getDepartment',
  updateDepartment: baseUrl + 'updateDepartment',
  deleteDepartment: baseUrl + 'deleteDepartment',
  searchDepartment: baseUrl + 'department/searchDepartment',

  getIncomeCategories: baseUrl + 'income/getIncomeCategories',
  getIncomeSubCategorydata: baseUrl + 'capital-investment/getSubCategorydata/',
  addIncomeInfo: 'https://rgca.brugu.io/api/income/addIncomeInfo',
  getIncomeInfo: baseUrl + 'income/getIncomeInfo',
  getIncomeInfoByID: baseUrl + 'income/getIncomeInfoByID',
  addNewItem: baseUrl + 'income/addNewItem',
  addIncomeGenrationChildItem: baseUrl + 'income/addIncomeGenrationChildItem',
  deleteIncomeGenerationChildItem: baseUrl + 'income/deleteIncomeGenerationChildItem',
  updateIncomeGenrationChildItem: baseUrl + 'income/updateIncomeGenrationChildItem',
  updateIncomeInfo: baseUrl + 'income/updateIncomeInfo',
  deleteIncomeInfo: baseUrl + 'income/deleteIncomeInfo',
  deleteIncomeRecord: baseUrl + 'income/deleteIncomeRecord',

  getDesignation: baseUrl + 'designation/getDesignation',
  addDesignations: baseUrl + 'designation/addDesignation',


  addIndent: baseUrl + 'indent/addIndent',
  getIndents: baseUrl + 'indent/getIndents',
  getIndentItems: baseUrl + 'indent/getIndentItems',
  updateIndent: baseUrl + 'indent/updateIndent',
  deleteIndent: baseUrl + 'indent/deleteIndent',


  getCategories: baseUrl + 'masterdata/getCategories',
  addLookupData: baseUrl + 'masterdata/addLookupData',
  updateLookupData: baseUrl + 'masterdata/updateLookupData',
  register: baseUrl + 'users/register',
  updateRegister: baseUrl + 'users/updateUser',
  searchCategories: baseUrl + 'masterdata/searchCategories',
  getAllUsers: baseUrl + 'users/getAllUsers',
  getEmployeeDetails: baseUrl + 'users/getEmployeeDetails',
  getEmployeeDropDowns: baseUrl + 'users/getEmployeeDropDowns',
  searchEmployee: baseUrl + 'users/searchEmployee',


  uploadCapitalInvetment: baseUrl + 'upload/file',
  uploadRecuring: baseUrl + 'upload/recurringFile',
  updateIndentStatus: baseUrl + 'indent/updateIndentStatus',
  deleteCapitalInvestmentData: baseUrl + 'capital-investment/deleteCapitalInvestmentData',
  deleteRecurringExpenditureData: baseUrl + 'recurring-expenditure/deleteRecurringExpenditureData',

  ItemCostForCapital: baseUrl + 'capital-investment/ItemCost',
  ItemCostForRecurring: baseUrl + 'recurring-expenditure/ItemCost',

  getIndentItemsdata: baseUrl + 'indent/getIndentItemsdata',
  indentUpload: '/' + baseUrl + 'upload/indentUpload',
  // indentUpload:'https://rgca.brugu.io/api/upload/indentUpload',

  updateIndentItem: baseUrl + 'indent/updateIndentItem',
  closeIndent: baseUrl + 'indent/closeIndent',
  getSubCategorydataDropDown: '/' + baseUrl + 'capital-investment/getSubCategorydata',
  // getSubCategorydataDropDown:'https://rgca.brugu.io/api/capital-investment/getSubCategorydata',


  // vendors
  getVendors: baseUrl + 'vendors/getVendors',
  addVendor: baseUrl + 'vendors/addVendor',
  removeVendor: baseUrl + 'vendors/removeVendor',
  updateVendor: baseUrl + 'vendors/updateVendor',
  searchVendor: baseUrl + 'vendors/searchVendor',
  getapprovedIndents: baseUrl + 'indent/getapprovedIndents/',
  assignIndentItem: baseUrl + 'indent/assignIndentItem',
  // deleteIndentItem:'https://rgca.brugu.io/api/indent/deleteIndentItem',
  deleteIndentItem: '/' + baseUrl + 'indent/deleteIndentItem',
  getAvailableLocations: baseUrl + 'location/getAvailableLocations',

  // Tax

  updateTax: baseUrl + 'tax/updateTax',
  getTax: baseUrl + 'tax/getTax',



  // new integration

  lookupData: baseUrl + 'commondata/getTypes/',

  addFar: baseUrl + 'addFar'



}

