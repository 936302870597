import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Select,
  Dialog,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  FormHelperText,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Page from 'src/components/Page';
import EditIcon from '@material-ui/icons/Edit';
import { ToastContainer, toast } from 'react-toastify';
import { useBarcode } from 'react-barcodes';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Loader from '../../loader';
import { useToasts } from 'react-toast-notifications';
import { dateFormate, dataSort, getProjectDetails } from '../Common/utilities';
import { MESSAGE } from 'src/utils/message';
import { createTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff'
    }
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff'
    }
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function App() {
  const { inputRef } = useBarcode({
    value: 'ITEM-10052021-0012',
    options: {
      text: 'ITEM-10052021-0012'
    }
  });

  return <svg ref={inputRef} />;
}

const Assets = () => {
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const { addToast } = useToasts();
  const classes = useStyles();
  const [seed, setSeed] = useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [moreView, setMoreView] = React.useState(false);
  const [openTest, setOpenTest] = React.useState(false);
  const [opUOM, setOpUOM] = React.useState(false);
  const [openMain, setOpenMain] = React.useState(false);
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [uomName, setUomName] = useState('');
  const [getItemsData, setGetItemsData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uomna, setUOMna] = useState([]);
  const [btn, setBtn] = useState(false);
  const projectData = useSelector(x => x);
  const [temp, setTemp] = useState([]);
  const [initialize, setInitialize] = useState(false)


  var proId = projectDetails.projectID;
  const navigate = useNavigate();
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  const [addItem, setAddItem] = useState({
    type: '',
    category: '',
    subCategory: '',
    item: '',
    uom: '',
    brand: '',
    catalogueNumber: '',
    threshold: '',
    count: ''
  });

  const [addCategory, setAddCategory] = useState({
    typeID: null,
    categoryName: '',
    projectID: proId
  });

  const [addSubCategory, setAddSubCategory] = useState({
    type: null,
    categoryID: null,
    subCategoryName: ''
  });

  useEffect(() => {
    getLookup();
    getItemsList();
    getUOM();
  }, []);

  const reset = () => {
    setSeed(Math.random());
  };

  const openModal = () => {
    setOpenTest(true);
    getLookup();
    getItemsList();
    getUOM();
  };

  const closeModal = (resetForm = null) => {
    setOpenTest(false);
    if (resetForm) {
      resetForm();
    }
  };

  const openSubModal = () => {
    setOpenSubCategory(true);
  };

  const openUOM = () => {
    setOpUOM(!opUOM);
    console.log(opUOM, "opUOM");
  };

  const closeSubModal = (resetForm = null) => {
    setOpenSubCategory(false);
    if (resetForm) {
      resetForm();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const getCategories = type => {
    setIsLoading(true);
    // APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
    //   typeID: type,
    //   projectID: proId
    // })
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, { typeID: type })
      .then(res => {
        if (res.data.status === 200) {
          setCategoryList(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  };

  const getSubCategories = categoryId => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
      categoryID: categoryId
    })
      .then(res => {
        if (res.data.status === 200) {
          setSubCategoryList(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  };

  const getUOM = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getUOM)
      .then(res => {
        if (res.data.status === 200) {
          setUOMna(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  };

  const saveItem = () => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addItemOrMaterial, {
      subCategoryID: addItem.subCategory,
      name: addItem.item,
      description: '', // optional
      brandOrCatalogue: addItem.brand, //optional
      uom: addItem.uom,
      catalogueNumber: addItem.catalogueNum
    })
      .then(res => {
        if (res.data.status === 200) {
          setTimeout(() => {
            setIsLoading(false);
            addToast(res.data.message, { appearance: 'success' });
          }, 3000);
          setAddItem({
            type: '',
            category: '',
            subCategory: '',
            item: '',
            uom: '',
            brand: ''
          });
        } else {
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      });
  };

  const cancelAddItem = (resetForm = null) => {
    setOpenMain(false);
    setBtn(false);
    setAddItem({
      type: '',
      category: '',
      subCategory: '',
      item: '',
      uom: '',
      brand: '',
      catalogueNumber: '',
      threshold: '',
      count: ''
    });
    if (resetForm) {
      resetForm();
    }
  };

  const saveCategory = () => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addCategoryORsubCategory, addCategory)
      .then(res => {
        if (res.data.status === 200) {
          //   setLookupData(res.data.data)
          closeModal();
          setTimeout(() => {
            setIsLoading(false);
            addToast(res.data.message, { appearance: 'success' });
          }, 3000);
        } else {
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      });
  };

  const saveSubCategory = () => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addCategoryORsubCategory, {
      categoryID: addSubCategory.categoryID,
      subCategoryName: addSubCategory.subCategoryName
    })
      .then(res => {
        if (res.data.status === 200) {
          closeSubModal();
          // setLookupData(res.data.data)
          setTimeout(() => {
            setIsLoading(false);
            addToast(res.data.message, { appearance: 'success' });
          }, 3000);
        } else {
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      });
  };

  // getitems
  const getItemsList = () => {
    // APIKit.post(RGCA_URLS.getItems, { projectID: projectDetails.projectID })
    APIKit.post(RGCA_URLS.getItems)
      .then(res => {
        if (res.data.status === 200) {
          // setGetItemsData(res.data.data)
          setGetItemsData(dataSort(res.data.data, 'CreateDate'));
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const addUOM = () => {
    const payload = {
      uomName: uomName
    };
    APIKit.post(RGCA_URLS.addUOM, payload)
      .then(res => {
        if (res.data.status === 200) {
          setOpUOM(!opUOM);
          setGetItemsData(res.data.data);
          getUOM();
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          // setAlertMeg('error');
          // setErrorMeg(res.data.message);
          // setAlertOpen(true);
        }
      })
      .catch(e => { });
  };

  const searchMaterial = e => {
    if (e) {
      setTemp(getItemsData.filter((item, i) => { return item.name.toLowerCase().includes(e.toLowerCase()) }))
    } else {
      getItemsList();
    }
    setGetItemsData(temp)


    // if (e) {
    //   const payload = {
    //     projectID: projectDetails.projectID,
    //     searchText: e
    //   };
    //   APIKit.post(RGCA_URLS.getItems, payload)
    //     .then(res => {
    //       if (res.data.status === 200) {
    //         // setAlertMeg('success');
    //         // setErrorMeg(res.data.message);
    //         //setAlertOpen(true);
    //         setGetItemsData(res.data.data);
    //       } else {
    //         // setAlertMeg('error');
    //         // setErrorMeg(res.data.message);
    //         // setAlertOpen(true);
    //       }
    //     })
    //     .catch(e => {});
    // } else {
    //   getItemsList();
    // }



  };
  // const searchMaterial = e => {

  //   if (e) { 
  //     const payload = {
  //             projectID: projectDetails.projectID,
  //             searchText: e}
  //     APIKit.post(RGCA_URLS.getItems, payload)

  //       .then(res => {
  //         if (res.data.status === 200) {
  //           setGetItemsData(res.data.data);
  //         } else {
  //         }
  //       })
  //       .catch(function(e) {});
  //   } else {
  //     getItemsList();
  //   }
  // };

  const editItem = data => {
    console.log("data", data);
    setOpenMain(true);
    getCategories(data.typeID);
    getSubCategories(data.categoryID);
    setBtn('Update');
    setBtn(true);
    setInitialize(true);
    setAddItem({
      ...addItem,
      itemID: data.itemID,
      type: data.typeID,
      category: data.categoryID,
      subCategory: data.subCategoryID,
      item: data.name,
      uom: data.uom,
      brand: data.brandOrCatalogue,
      catalogueNumber: data.catalogueNumber,
      threshold: data.IsThreshold,
      count: data.ThresholdCount
    });
  };

  const updateItem = () => {
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateItems, {
      itemID: addItem.itemID,
      subCategoryID: addItem.subCategory,
      name: addItem.item,
      brandOrCatalogue: addItem.brand, // optional
      uom: addItem.uom,
      catalogueNumber: addItem.catalogueNumber,
      IsThreshold: addItem.threshold == '1' ? true : false,
      ThresholdCount: addItem.count ? addItem.count : 0
    })
      .then(res => {
        if (res.data.status === 200) {
          setBtn(false);
          setIsLoading(false);
          setOpenMain(false);
          setAddItem({
            type: '',
            category: '',
            subCategory: '',
            item: '',
            uom: '',
            brand: '',
            threshold: '',
            count: ''
          });
          getItemsList();
          toast.success(MESSAGE.itemUpdate, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  });

  const [categoryDropdown, setCategoryDropdown] = useState(true);
  const [subCategoryDropdown, setSubCategoryDropdown] = useState(true);

  return (
    <Page className={classes.root} title="Material">
      <ToastContainer />
      <Loader isLoading={isLoading} />

      <Container maxWidth={false}>
        <Typography>
          <h2 style={{ color: '#394671' }}>Material / Item / Asset</h2>
        </Typography>
        <Box
          mt={3}
          style={{
            backgroundColor: '#fff',
            boxShadow:
              '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={e => {
                  searchMaterial(e.target.value);
                  getLookup();
                  // getItemsList();
                  getUOM();
                }}
                // placeholder="Search the item name here"
                placeholder='search by name'
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8} sm={8} style={{ textAlign: 'right' }}>
              <Button
                // style={{ marginRight: 10 }}
                color="primary"
                variant="contained"
                onClick={() => {
                  setInitialize(false)
                  setOpenMain(true)
                }}
              >
                Add Item
              </Button>
              
            </Grid>
          </Grid>
          <Formik
            enableReinitialize={initialize}
            initialValues={{
              type: addItem.type,
              category: addItem.category,
              subCategory: addItem.subCategory,
              item: addItem.item,
              uom: addItem.uom,
              brand: addItem.brand,
              catalogueNumber: addItem.catalogueNumber,
              threshold: addItem.threshold,
              count: addItem.count
            }}
            validationSchema={Yup.object().shape({
              type: Yup.string().required(MESSAGE.type),
              category: Yup.string().required(MESSAGE.category),
              subCategory: Yup.string().required(MESSAGE.subCategory),
              item: Yup.string().required(MESSAGE.item),
              uom: Yup.string()
                .required(MESSAGE.uom)
                .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
              brand: Yup.string()
            })}
            onSubmit={(values, { resetForm }) => {

              if (btn) {
                updateItem();
              }
              else {
                setIsLoading(true);
                APIKit.post(RGCA_URLS.addItemOrMaterial, {
                  projectID: projectDetails.projectID,
                  subCategoryID: addItem.subCategory,
                  name: addItem.item,
                  description: '', // optional
                  brandOrCatalogue: addItem.brand, // optional
                  uom: addItem.uom,
                  catalogueNumber: addItem.catalogueNumber,
                  IsThreshold: addItem.threshold == '1' ? true : false,
                  ThresholdCount: addItem.count ? addItem.count : 0
                })
                  .then(res => {
                    if (res.data.status === 200) {
                      setIsLoading(false);
                      setAddItem({
                        type: '',
                        category: '',
                        subCategory: '',
                        item: '',
                        uom: '',
                        brand: '',
                        threshold: '',
                        count: ''
                      });
                      setOpenMain(false);
                      getItemsList();
                      toast.success(MESSAGE.itemAdded, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                      });
                      resetForm(values);
                    } else {
                      setIsLoading(false);
                      toast.error(
                        res.data.message,
                        { appearance: 'error' },
                        {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined
                        }
                      );
                      // addToast(res.data.message, { appearance: 'error' });
                      // toast.error(res.data.message);
                    }
                  })
                  .catch(e => {
                    setIsLoading(false);
                  });

              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <MuiThemeProvider theme={formLabelsTheme}>
                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={openMain}
                  id="income"
                  contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                  }}
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogTitle
                    id="scroll-dialog-title"
                    onClose={e => {
                      cancelAddItem();
                    }}
                    style={{ backgroundColor: '#394671', color: '#fff' }}
                  >
                    {btn ? 'Edit' : 'Add'} Item
                  </DialogTitle>
                  <Form
                    noValidate
                    autoComplete="off"
                    key={seed}
                    onSubmit={handleSubmit}
                  >
                    <Loader isLoading={isLoading} />
                    <DialogContent>
                      <Box mt={3}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  error={Boolean(touched.type && errors.type)}
                                  className={classes.formControl}
                                >
                                  <InputLabel
                                    required
                                    id="demo-simple-select-outlined-label"
                                  >
                                    Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="type"
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      handleChange(e);
                                      const p = addItem;
                                      p.type = e.target.value;
                                      values.type = e.target.value;
                                      setAddItem({
                                        ...p,
                                        type: e.target.value,
                                        //new implemetation
                                        subCategory: "",
                                        category: "",
                                        //new implemetation
                                      });
                                      getCategories(e.target.value);
                                      setCategoryDropdown(false);
                                      getLookup();
                                      getItemsList();
                                      getUOM();

                                      setSubCategoryList([])
                                      setCategoryList([])
                                      addItem.category = ""
                                    }}
                                    required
                                    value={addItem.type}
                                    label="Type"
                                  >
                                    {/* <MenuItem value={addItem.type}>{addItem.type}</MenuItem> */}
                                    {lookupData &&
                                      lookupData.map(x => {
                                        return (
                                          <MenuItem
                                            value={x.lookupID}
                                            key={x.lookupID}
                                          >
                                            {x.name}
                                          </MenuItem>
                                        );
                                      })}
                                    {/* <MenuItem value="1">Recuring</MenuItem> */}
                                  </Select>
                                  <FormHelperText>
                                    {errors.type && touched.type && errors.type}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  error={Boolean(
                                    touched.category && errors.category
                                  )}
                                  style={{ width: '100%' }}
                                  className={classes.formControl}
                                >
                                  <InputLabel
                                    required
                                    id="demo-simple-select-outlined-label"
                                  >
                                    Category
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onBlur={handleBlur}
                                    disabled={!categoryList.length}
                                    onChange={e => {
                                      handleChange(e);
                                      setSubCategoryList([])
                                      const p = addItem;
                                      p.category = e.target.value;
                                      values.category = e.target.value;
                                      setAddItem({
                                        ...p,
                                        //new implemetation
                                        subCategory: "",
                                        //new implemetation
                                        category: e.target.value
                                      });
                                      getSubCategories(e.target.value);
                                      setSubCategoryDropdown(false);
                                    }}
                                    required
                                    value={addItem.category}
                                    name="category"
                                    label="Category"
                                  >
                                    {categoryList &&
                                      categoryList.map(x => {
                                        return (
                                          <MenuItem
                                            value={x.categoryID}
                                            key={x.categoryID}
                                          >
                                            {x.name}
                                          </MenuItem>
                                        );
                                      })}
                                    {/*
                                <MenuItem value="0">Live feed sales</MenuItem>
                                <MenuItem value="1">Scrap Materials</MenuItem>
                                <MenuItem value="1">Guest Room Rent</MenuItem> */}
                                  </Select>
                                  <FormHelperText>
                                    {errors.category &&
                                      touched.category &&
                                      errors.category}
                                  </FormHelperText>
                                </FormControl>
                                <a
                                  style={{ color: 'blue', cursor: 'pointer' }}
                                  onClick={openModal}
                                >
                                  + Add Category
                                </a>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  error={Boolean(
                                    touched.subCategory && errors.subCategory
                                  )}
                                  onBlur={handleBlur}
                                  className={classes.formControl}
                                >
                                  <InputLabel
                                    required
                                    id="demo-simple-select-outlined-label"
                                  >
                                    Sub Category
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    disabled={!subCategoryList.length}
                                    onChange={e => {
                                      handleChange(e);
                                      const p = addItem;
                                      p.subCategory = e.target.value;
                                      values.subCategory = e.target.value;
                                      setAddItem({
                                        ...p,
                                        subCategory: e.target.value
                                      });
                                    }}
                                    required
                                    value={addItem.subCategory}
                                    name="subCategory"
                                    label="Sub Category"
                                  >
                                    {subCategoryList &&
                                      subCategoryList.map(x => {
                                        return (
                                          <MenuItem
                                            value={x.subCategoryID}
                                            key={x.subCategoryID}
                                          >
                                            {x.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>
                                    {errors.subCategory &&
                                      touched.subCategory &&
                                      errors.subCategory}
                                  </FormHelperText>
                                </FormControl>
                                <a
                                  style={{ color: 'blue', cursor: 'pointer' }}
                                  onClick={openSubModal}
                                >
                                  + Add Sub Category
                                </a>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <TextField
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  error={Boolean(touched.item && errors.item)}
                                  helperText={touched.item && errors.item}
                                  onBlur={handleBlur}
                                  label="Item/Material/Assets"
                                  onChange={e => {
                                    const re = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
                                    if (re.test(e.target.value)) {
                                      handleChange(e);
                                      const p = addItem;
                                      p.item = e.target.value;
                                      values.item = e.target.value;
                                      setAddItem({
                                        ...p,
                                        item: e.target.value
                                      });
                                    }
                                  }}
                                  required
                                  value={addItem.item}
                                  name="item"
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  error={Boolean(touched.uom && errors.uom)}
                                  onBlur={handleBlur}
                                  className={classes.formControl}
                                >
                                  <InputLabel
                                    required
                                    id="demo-simple-select-outlined-label"
                                  >
                                    UOM
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    disabled={!uomna.length}
                                    onChange={e => {
                                      handleChange(e);
                                      const p = addItem;
                                      p.uom = e.target.value;
                                      values.uom = e.target.value;
                                      setAddItem({
                                        ...p,
                                        uom: e.target.value
                                      });
                                    }}
                                    required
                                    value={addItem.uom}
                                    name="uom"
                                    label="UOM"
                                  >
                                    {uomna &&
                                      uomna.map(x => {
                                        return (
                                          <MenuItem value={x.name} key={x.name}>
                                            {x.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>
                                    {errors.uom && touched.uom && errors.uom}
                                  </FormHelperText>
                                </FormControl>

                                <a
                                  style={{ color: 'blue', cursor: 'pointer' }}
                                  onClick={openUOM}
                                >
                                  + Add UOM
                                </a>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <TextField
                                  inputProps={{ maxLength: 20 }}
                                  fullWidth
                                  error={Boolean(touched.brand && errors.brand)}
                                  helperText={touched.brand && errors.brand}
                                  onBlur={handleBlur}
                                  label="Brand"
                                  onChange={e => {
                                    handleChange(e);
                                    const p = addItem;
                                    p.brand = e.target.value;
                                    values.brand = e.target.value;
                                    setAddItem({
                                      ...p,
                                      brand: e.target.value
                                    });
                                  }}
                                  value={addItem.brand}
                                  name="brand"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label="Catalogue / Model Number"
                                  onChange={e => {
                                    if (
                                      e.target.value.match(regEx.noSpecialChar)
                                    ) {
                                      handleChange(e);
                                      const p = addItem;
                                      p.catalogueNumber = e.target.value;
                                      values.catalogueNumber = e.target.value;
                                      setAddItem({
                                        ...p,
                                        catalogueNumber: e.target.value
                                      });
                                    }
                                  }}
                                  value={addItem.catalogueNumber}
                                  name="catalogueNumber"
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  // className={classes.formControl}
                                >
                                  <InputLabel
                                    required
                                    id="demo-simple-select-outlined-label"
                                  >
                                    Is Threshold Needed
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Is Threshold Needed"
                                    name="type"
                                    value={addItem.threshold}
                                    onChange={e => {
                                      let p = addItem
                                      p.threshold = e.target.value
                                      if (e.target.value == "2") {
                                        p.count = "0"
                                      }
                                      setAddItem({ ...p })
                                    }}
                                  >
                                    <MenuItem value="1">Yes</MenuItem>
                                    <MenuItem value="2">No</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {addItem && addItem.threshold == '1' &&
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    label="Count"
                                    onChange={e => {
                                      if (
                                        e.target.value.match(regEx.noSpecialChar)
                                      ) {
                                        let p = addItem
                                        p.count = e.target.value
                                        setAddItem({ ...p })
                                      }
                                    }}
                                    // value={addItem.catalogueNumber}
                                    name="catalogueNumber"
                                    variant="outlined"
                                    value={addItem.count}
                                  />
                                </Grid>
                              }
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    </DialogContent>
                    <DialogActions
                      style={{ backgroundColor: '#394671', color: '#fff' }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            border: '1px solid',
                            marginRight: '10px'
                          }}
                          color="primary"
                          variant="contained"
                          onClick={e => {
                            cancelAddItem(resetForm);
                          }}
                        >
                          Cancel
                        </Button>

                        {btn ? (
                          <Button
                            color="primary"
                            variant="contained"
                            style={{
                              backgroundColor: '#394671',
                              color: '#fff',
                              border: '1px solid'
                            }}
                            type="submit"
                          // onClick={updateItem}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            style={{
                              backgroundColor: '#394671',
                              color: '#fff',
                              border: '1px solid'
                            }}
                            type="submit"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </DialogActions>
                  </Form>
                </Dialog>
              </MuiThemeProvider>
            )}
          </Formik>
        </Box>

        <Grid container spacing={3} >
          &nbsp;
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center' }} >
              <TableContainer sx={{ maxHeight: 440 }} id="printme">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="left">S.No</TableCell> */}
                      <TableCell align="left">Type</TableCell>
                      {/* <TableCell align="left">Item Project</TableCell> */}
                      <TableCell align="left">Category</TableCell>
                      <TableCell align="left">Sub Category</TableCell>
                      <TableCell align="left">Item Name</TableCell>
                      <TableCell align="left">UOM</TableCell>
                      <TableCell align="left">Brand</TableCell>
                      <TableCell align="left">Catalogue/Model</TableCell>
                      <TableCell align="left">Is Threshold</TableCell>
                      <TableCell align="left">Count</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {getItemsData.length != 0 && getItemsData ? (
                      getItemsData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.itemID}
                            >
                              {/* <TableCell align="left">{((page) * 10) + index + 1}</TableCell> */}
                              <TableCell align="left">{row.typeName}</TableCell>
                              {/* <TableCell align="left">
                                {row.projectName}
                              </TableCell> */}
                              <TableCell align="left">
                                {row.categoryName}
                              </TableCell>
                              <TableCell align="left">
                                {row.subCategoryName}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="center">{row.uom}</TableCell>
                              <TableCell align="center">
                                {row.brandOrCatalogue}
                              </TableCell>
                              <TableCell align="left">{row.catalogueNumber} </TableCell>
                              <TableCell align="left">
                                {row.IsThreshold && row.IsThreshold == "1" ? "Yes" : (row.IsThreshold === null ? "" : "No")}
                              </TableCell>
                              <TableCell align="left">{row.ThresholdCount} </TableCell>
                              <TableCell align="left">
                                <EditIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => editItem(row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow hover role="checkbox" key={1}>
                        <TableCell colSpan={10} align="center" key={2}>
                          {'No Data Found'}
                        </TableCell>{' '}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {getItemsData.length != 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={getItemsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>

      {/* category */}
      <Formik
        initialValues={{
          typeID: '',
          categoryName: '',
          projectID: proId
        }}
        validationSchema={Yup.object().shape({
          typeID: Yup.string().required('Type is required'),
          categoryName: Yup.string().required('Category Name is required')
        })}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          APIKit.post(RGCA_URLS.addCategoryORsubCategory, values)
            .then(res => {
              if (res.data.status === 200) {
                //   setLookupData(res.data.data)
                getLookup();
                closeModal();
                // getCategories(addItem?.type);
                setIsLoading(false);
                resetForm(values);
                toast.success(MESSAGE.categoryAdded, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              } else {
                setIsLoading(false);
                toast.error(MESSAGE.alreadyExist, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                }); // toast.error(res.data.message);
              }
            })
            .catch(e => {
              setIsLoading(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openTest}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <MuiThemeProvider theme={formLabelsTheme}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={e => {
                    closeModal(resetForm);
                  }}
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  Create Category
                </DialogTitle>
                <DialogContent dividers>
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(touched.typeID && errors.typeID)}
                          className={classes.formControl}
                        >
                          <InputLabel required id="typeID">
                            Type
                          </InputLabel>
                          <Select
                            labelId="typeID"
                            id="typeID"
                            name="typeID"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = addCategory;
                              p.typeID = e.target.value;
                              setAddCategory({
                                ...p,
                                typeID: e.target.value
                              });
                            }}
                            required
                            value={values.typeID}
                            label="Type"
                          >
                            {lookupData &&
                              lookupData.map(x => {
                                return (
                                  <MenuItem value={x.lookupID} key={x.lookupID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                            {/* <MenuItem value="1">Recuring</MenuItem> */}
                          </Select>
                          <FormHelperText>
                            {errors.typeID && touched.typeID && errors.typeID}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Category"
                          name="categoryName"
                          error={Boolean(
                            touched.categoryName && errors.categoryName
                          )}
                          helperText={
                            touched.categoryName && errors.categoryName
                          }
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = addCategory;
                            p.categoryName = e.target.value;
                            values.categoryName = e.target.value;
                            setAddCategory({
                              ...p,
                              categoryName: e.target.value
                            });
                          }}
                          required
                          value={values.categoryName}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                    onClick={() => {
                      setAddItem({
                        type: '',
                        category: '',
                        subCategory: '',
                        item: '',
                        uom: '',
                        brand: '',
                        catalogueNumber: ''
                      });
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    onClick={e => {
                      closeModal(resetForm);
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {btn == false ?
                    <Button  className={classes.button2}>
                        Save
                    </Button>
                    : <Button  className={classes.button2}>
                        Update
                    </Button>} */}
                </DialogActions>
              </Form>
            </MuiThemeProvider>
          </Dialog>
        )}
      </Formik>

      {/* sub category */}
      <Formik
        initialValues={{
          type: '',
          categoryID: '',
          subCategoryName: ''
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required('Type is required'),
          categoryID: Yup.string().required('Category ID is required'),
          subCategoryName: Yup.string().required('Sub Category is required')
        })}
        onSubmit={(values, { resetForm }) => {
          //   if(addItem.category.trim().toLocaleLowerCase() == addItem.subCategory.trim().toLocaleLowerCase()){
          //   toast.error('category ans sub category not be match', {
          //     position: 'top-right',
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined
          //   });
          //   return
          // }
          setIsLoading(true);
          APIKit.post(RGCA_URLS.addCategoryORsubCategory, {
            categoryID: addSubCategory.categoryID,
            subCategoryName: addSubCategory.subCategoryName
          })
            .then(res => {
              if (res.data.status === 200) {
                closeSubModal();
                getLookup();
                // setLookupData(res.data.data)
                // getSubCategories(addItem?.category);
                setIsLoading(false);
                resetForm(values);
                toast.success(MESSAGE.subCategoryAdded, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              } else {
                setIsLoading(false);
                // addToast(res.data.message, { appearance: 'error' });
                // toast.error(res.data.message);
                toast.error(res.data.message, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              }
            })
            .catch(e => {
              setIsLoading(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openSubCategory}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
          >
            <MuiThemeProvider theme={formLabelsTheme}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle
                  onClose={e => {
                    closeSubModal(resetForm);
                  }}
                  id="customized-dialog-title"
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  Create Sub Category
                </DialogTitle>
                <DialogContent dividers>
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(touched.type && errors.type)}
                          className={classes.formControl}
                        >
                          <InputLabel
                            required
                            id="demo-simple-select-outlined-label"
                          >
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="type"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = addSubCategory;
                              p.type = e.target.value;
                              setAddSubCategory({
                                ...p,
                                type: e.target.value
                              });
                              getCategories(e.target.value);
                            }}
                            required
                            value={values.type}
                            label="Type"
                          >
                            {lookupData &&
                              lookupData.map(x => {
                                return (
                                  <MenuItem value={x.lookupID} key={x.lookupID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                            {/* <MenuItem value="1">Recuring</MenuItem> */}
                          </Select>
                          <FormHelperText>
                            {errors.type && touched.type && errors.type}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          error={Boolean(
                            touched.categoryID && errors.categoryID
                          )}
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <InputLabel
                            required
                            id="demo-simple-select-outlined-label"
                          >
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="categoryID"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = addSubCategory;
                              p.categoryID = e.target.value;
                              values.categoryID = e.target.value;
                              setAddSubCategory({
                                ...p,
                                categoryID: e.target.value
                              });
                            }}
                            required
                            value={values.categoryID}
                            label="Category"
                          >
                            {categoryList &&
                              categoryList.map(x => {
                                return (
                                  <MenuItem
                                    value={x.categoryID}
                                    key={x.categoryID}
                                  >
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                            {/* <MenuItem value="1">Recuring</MenuItem> */}
                          </Select>
                          <FormHelperText>
                            {errors.categoryID &&
                              touched.categoryID &&
                              errors.categoryID}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.subCategoryName && errors.subCategoryName
                          )}
                          helperText={
                            touched.subCategoryName && errors.subCategoryName
                          }
                          label="Sub Category"
                          name="subCategoryName"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = addSubCategory;
                            p.subCategoryName = e.target.value;
                            values.subCategoryName = e.target.value;
                            setAddSubCategory({
                              ...p,
                              subCategoryName: e.target.value
                            });
                          }}
                          required
                          value={values.subCategoryName}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                    onClick={() => {
                      if (addItem.category.trim() == addItem.subCategory.trim()) {

                      }
                      else {
                        setAddItem({
                          type: '',
                          category: '',
                          subCategory: '',
                          item: '',
                          uom: '',
                          brand: '',
                          catalogueNumber: ''
                        });
                      }
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    onClick={e => {
                      closeSubModal(resetForm);
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {btn == false ?
        <Button onClick={saveVendor} className={classes.button2}>
            Save
        </Button>
        : <Button onClick={updateVendor} className={classes.button2}>
            Update
        </Button>} */}
                </DialogActions>
              </Form>
            </MuiThemeProvider>
          </Dialog>
        )}
      </Formik>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={opUOM}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
      >
        <MuiThemeProvider theme={formLabelsTheme}>
          {/* <Form noValidate autoComplete="off" > */}

          <DialogTitle
            onClose={e => {
              setOpUOM(!opUOM);
            }}
            id="customized-dialog-title"
            style={{ backgroundColor: '#394671', color: '#fff' }}
          >
            Add UOM
          </DialogTitle>
          <DialogContent dividers>
            <Box
              mt={3}
              style={{
                backgroundColor: '#fff',
                boxShadow:
                  '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                borderRadius: '5px',
                padding: '20px'
              }}
            >
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="UOM"
                    name="subCategoryName"
                    inputProps={{ maxLength: 20 }}
                    // onBlur={handleBlur}
                    onChange={e => {
                      const re = /^[a-zA-Z0-9_.-]*$/;
                      if (re.test(e.target.value)) {
                        setUomName(e.target.value);
                      }
                    }}
                    required
                    // value={values.subCategoryName}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
            <Button
              onClick={addUOM}
              disabled={!uomName.length}
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
            >
              Save
            </Button>

            <Button
              onClick={e => {
                setOpUOM(!opUOM);
              }}
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
            >
              Cancel
            </Button>
          </DialogActions>
          {/* </Form> */}
        </MuiThemeProvider>
      </Dialog>
    </Page>
  );
};
export default Assets;
