import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import moment from 'moment';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import { useParams } from 'react-router-dom';
import { ETTypes, ETaction } from '../Common/Types';
import StepperBox from '../Common/StepperBox';
import 'src/views/AddProject/style.css';
import CommonTable from '../Common/CommonTable';
import { Constant } from '../Common/Constant';
import { getloggedInData } from 'src/utils/helpers';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'src/views/loader';
import { Formik, Form } from 'formik';
import { MESSAGE } from 'src/utils/message';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { disableCache } from '@iconify/react';

const IndentInitialValues = {
  storeIndentMasterID: '',
  ProjectID: null,
  statusID: null,
  UpdatedBy: null,
  CreatedBy: null,
  quoteUrl: '',
  CreateDate: '',
  UpdatedDate: null,
  projectName: '',
  CreatedByName: '',
  currentApprovals: {
    referOptionView: false,
    referSelected: false,
    name: '',
    indentApprovalSystemID: null,
    storeIndentMasterID: '',
    orderID: null,
    accessRoleID: null,
    runningStatus: null,
    approvalID: null,
    editLock: true,
    CreatedBy: null,
    CreateDate: '',
    comment: '',
    approvalStatusID: null,
    status: '',
    location: '',
    brandOrCatalogue: ''
  },
  indentItem: [],
  Approvals: []
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflow: 'auto'
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  Accordion:{
    [theme.breakpoints.down('xs')]: {
      width:"320px"
    }
  }
}));

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const StoreApproval = () => {
  const { project } = useParams();
  const { accessRoleID } = getloggedInData();
  const userData = getloggedInData();
  const { addToast } = useToasts();
  const projectDetails = { ...getProjectDetails(project) };
  const classes = useStyles();
  const [indentView, setIndentView] = React.useState(false);
  const [indentListPayload, setIndentListPayload] = useState({
    storeIndentMasterID: '',
    ProjectID: null,
    indentItem: [
      {
        Qty: null
      }
    ]
  });

  const [indentList, setIndentList] = useState([]);
  const [itemHis, setItemHis] = useState([]);
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [direct, setDirect] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [originalApprovalStatusList, setOriginalApprovalStatusList] = useState(
    []
  );
  const [statusApproval, setStatusApproval] = useState(false);
  const [updat, setupdat] = React.useState(false)
    const [dis,setdis]=useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    getIndentApprovalList();
    getLookup();
  }, [navigate]);
  useEffect(() => {
    getLookup();
  }, [statusApproval, indentListPayload]);
  const handleIndentViewClose = () => {
    // setStatusEdit('')
    setIndentView(!indentView);
    getIndentApprovalList();
    setEdit(false);
  };

  const [storeColumns, setStoreColumns] = useState([
    {
      title: 'Store Indent ID',
      field: 'storeIndentMasterID',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Store Indented By',
      field: 'CreatedByName',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Employee Name',
      field: 'employeeName',
      align: 'center',
      type: ETTypes.string
    },
    {
      title: 'Store Indented date',
      field: 'CreateDate',
      align: 'center',
      type: ETTypes.date
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      list: [ETaction.onEdit]
    }
  ]);

  const [indentItem, setIndentItem] = useState({ ...IndentInitialValues });
  
  console.log("{ ...IndentInitialValues }",{ ...IndentInitialValues });

  const getLookup = () => {
    setupdat(true)
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          setOriginalApprovalStatusList(res.data.data);
          let newData = [];
          const userProjects = JSON.parse(userData.project);
          let userAccessID = '';
          if (userProjects.length > 0) {
            userProjects.forEach(pro => {
              if (pro.projectName == project) {
                userAccessID = pro.accessRoleID;
              }
            });
          }

          if (indentListPayload && indentListPayload.length > 0) {
            indentListPayload.forEach(list => {
              if (list.Approvals && list.Approvals.length > 0) {
                const lastApproval = list.Approvals[list.Approvals.length - 1];
                console.log(
                  'accessRoleID',
                  lastApproval.accessRoleID,
                  userAccessID
                );
                if (lastApproval.accessRoleID == userAccessID) {
                  console.error('look', res.data.data);
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 89 && x.lookupID != 90
                    )
                  );
                } else {
                  setApprovalStatusList(
                    res.data.data.filter(
                      x => x.lookupID != 24 && x.lookupID != 25
                    )
                  );
                }
              }
            });
          }
          // if (statusApproval) {
          //   setApprovalStatusList(
          //     originalApprovalStatusList.filter(
          //       x => x.lookupID >= 89 && x.lookupID <= 91
          //     )
          //   );
          // } else {
          //   setApprovalStatusList(
          //     originalApprovalStatusList.filter(
          //       x => x.lookupID != 89 && x.lookupID != 90
          //     )
          //   );
          // }
        }
      })

      .catch(e => { });
  };

  const editItem = () => {
    setEdit(true);
  };

  const getIndentApprovalList = () => {
    setIsLoading(true);
    APIKit.get(
      RGCA_URLS.getStoreIndentApprovalList + '/' + projectDetails.projectID
    )
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              indentItem: parseData(x.storeIndentItem)
            };
          });
          setIndentListPayload(data);
          setIndentList(data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const updateIndentItems = () => {
    if (indentItem.indentItem[0].Qty>=indentItem.indentItem[0].approvedQty) {  

    const payload = {
      storeIndentMasterID: indentItem.storeIndentMasterID,
      items: indentItem.indentItem.map(x => {
        return {
          storeIndentItemID: x.storeIndentItemID,
          Qty: x.Qty,
          approvedQty: x.approvedQty,
          location: x.location,
          comment: x.comment
        };
      })
    };
    if (payload.items.some(e => e.Qty == 0)) {
      toast.error(MESSAGE.qty0, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    APIKit.post(RGCA_URLS.storeIndentItemUpdate, payload)
      .then(res => {
        if (res.data.status === 200) {
          //addToast(res.data.message, { appearance: 'success' });
          toast.success(MESSAGE.indentUpdate, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setEdit(false);
          getIndentApprovalList();
        } else {
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  }
else{
  toast.error(MESSAGE.IndentApproalQuantity, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

}}

  const history = data => {
    const payload = {
      storeIndentMasterID: data.storeIndentMasterID,
      projectID: projectDetails.projectID
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getStoreIndentHistory, payload)
      .then(res => {
        if (res.data.status === 200) {
          console.log('history:', res.data.data);
          setItemHis(res.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const updateIndentApproval = () => {
    setIsLoading(true)
    let referOptionPayload = false;
    let referPayload = false;
    if (indentItem.currentApprovals.referOptionView == 0) {
      referOptionPayload = false;
    } else {
      referOptionPayload = true;
    }
    if (indentItem.currentApprovals.referSelected == 0) {
      referPayload = false;
    } else {
      referPayload = true;
    }
    const payload = {
      statusID: indentItem.currentApprovals.approvalStatusID,
      storeIndentMasterID: indentItem.storeIndentMasterID,
      comment: indentItem.currentApprovals.comment,
      referOption: referOptionPayload,
      refer: referPayload
    };
    if (payload.statusID > 0) {
      APIKit.post(RGCA_URLS.updateStoreApproval, payload)
        .then(res => {
          if (res.data.status === 200) {
    setIsLoading(false)
            handleIndentViewClose();
            toast.success(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            getIndentApprovalList();
          } else {
    setIsLoading(false)

            toast.error(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(function (e) { });
    }
  };

  const actions = {
    editRow: (index, field, value) => {
      let items = indentList;
      items[index][field] = value;
      setIndentList([...items]);
    },
    onView: (index, row) => { },
    onEdit: (index, row) => {
      history(row);
      setDirect(false);
      let rowData = { ...row };
      rowData.currentApprovals = row.Approvals.find(
        app =>
          app.accessRoleID ===
          JSON.parse(getloggedInData().project).find(
            e => e.projectID == projectDetails.projectID
          ).accessRoleID
      );
      row.Approvals.forEach(e => {
        if (e.accessRoleID === 3) {
          setDirect(true);
        }
      });
      setIndentItem({ ...rowData });
      console.log("indentItem.currentApprovals.comment ", typeof (indentItem.currentApprovals));
      handleIndentViewClose();
    },
    onDelete: (index, row) => { }
  };

  const regEx = {
    textOnly: /^[A-Za-z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9]*$/
  };

  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <Loader isLoading={isLoading} />
      <Typography>
        <h2 style={{ color: '#394671' }}>Store Indent Pending Approvals</h2>
      </Typography>
      <Grid container spacing={3} className={classes.field}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <CommonTable
              className={classes.root}
              columns={storeColumns}
              data={indentList}
              action={actions}
            />
          </div>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        initialValues={{
          Note:
            indentItem.currentApprovals.comment == 'undefined'
              ? ''
              : indentItem.currentApprovals.comment,
          status:
            indentItem.currentApprovals.approvalStatusID == 0
              ? ''
              : indentItem.currentApprovals.approvalStatusID
        }}
        onSubmit={(values, { resetForm }) => {
          resetForm(values);
        }}
        validationSchema={
          Yup.object().shape({
            // Note: Yup.string().required(MESSAGE.note),
            status: Yup.string().required(MESSAGE.status)
          })
          // PanCardFrontpic: Yup.string().required(MESSAGE.gstNumber),
          // PanCardBackpic: Yup.string().required(MESSAGE.gstNumber),
          // GSTCertificatepic: Yup.string().required(MESSAGE.gstNumber),
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={indentView}
            id="income"
            //  style={{ maxWidth: "1100px !important", width: "1100px" }}
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            scroll={'paper'}
            maxWidth="lg"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleIndentViewClose}
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                width: '800px !important'
              }}
            >
              Store Indent Approval Update 
            </DialogTitle>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <DialogContent dividers style={{ width: '800px !important' }}>
                <Grid container spacing={3}>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Store Indent ID : {indentItem.storeIndentMasterID}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Total Quantity:{' '}
                          {indentItem.indentItem
                            ? indentItem.indentItem.reduce(
                              (a, b) => a + b.Qty,
                              0
                            )
                            : 0}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          Date : {dateFormate(indentItem.CreateDate)}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>
                  {/* ---------------------------------------------------------------------------------------- */}
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          {' '}
                          Created By: {indentItem.CreatedByName}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}>
                          {' '}
                          Employee Name: {indentItem.employeeName}
                        </h5>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <Typography>
                        <h5 style={{ color: '#394671' }}></h5>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={6}></Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                    style={{ textAlign: 'end' }}
                  >
                    {!indentItem.currentApprovals.editLock && indentItem?.indentItem && indentItem?.indentItem.length > 0 && (
                      // <EditOutlinedIcon
                      // style={{ cursor: 'pointer' }} onClick={(e) =>  editItem} />
                      <Button
                        className={classes.button}
                        onClick={() => {
                          editItem();
                        }}
                        disabled={dis}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Material Name</TableCell>
                              <TableCell align="center">UOM</TableCell>
                              <TableCell align="center">Req. Quantity</TableCell>
                              {!edit ? (
                                <TableCell align="center">
                                  Approved Quantity
                                </TableCell>
                              ) : (
                                <TableCell align="center">
                                  Approved Quantity
                                </TableCell>
                              )}
                              <TableCell align="center">Location</TableCell>
                              <TableCell align="center">Description</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            { (indentItem?.indentItem && indentItem?.indentItem.length > 0) ?
                            <>
                            {indentItem?.indentItem
                              // .slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              .map((item, index) => (
                                <TableRow
                                  hover
                                // key={emplyoee.id}
                                // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                                >
                                  <TableCell align="center">
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="center">{item.uom}</TableCell>
                                  <TableCell align="center">{item.Qty}</TableCell>
                                  {!edit ? (
                                    <TableCell align="center">
                                      {item.approvedQty}
                                    </TableCell>
                                  ) : (
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        label="Approved Quantity"
                                        name="qty"
                                        onChange={e => {
                                          if (
                                            (e.target.value === '' ||
                                            regEx.numbersOnly.test(
                                              e.target.value
                                            )&& Number(e.target.value) <= Number(item.Qty))
                                          ) {
                                            const p = indentItem;
                                            p.indentItem[
                                              index
                                            ].approvedQty = Number(
                                              e.target.value
                                            );
                                            setIndentItem({ ...p });
                                          }
                                        }}
                                        value=
                                        {item.approvedQty}
                                        variant="outlined"
                                      />{' '}
                                    </TableCell>
                                  )}
                                  <TableCell align="center">
                                    {item.location}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.comment == 'null'
                                      ? '-'
                                      : item.comment}
                                  </TableCell>
                                </TableRow>
                              ))
                                      }
                              </>
                              :
                              <>
                              <TableCell colSpan={6} align="center">No Data found</TableCell>
                              </>

                              }

                           
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <br />
                    <div style={{ textAlign: 'right' }}>
                      {edit && (
                        <>
                          <Button
                            // style={{
                            //   backgroundColor: '#394671',
                            //   color: '#fff',
                            //   border: '1px solid'
                            // }}
                            className={classes.button}
                            onClick={e => {

                              updateIndentItems();
                              getLookup();

                            }}
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            className={classes.button}
                            onClick={() => { setEdit(false) }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow:
                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >
                  <Grid container spacing={2}>
                    {/* {indentItem.currentApprovals.referOptionView == 1 && !direct && (
                      <Grid
                        item
                        md={indentItem.currentApprovals.referOptionView ? 3 : 0}
                        xs={
                          indentItem.currentApprovals.referOptionView ? 12 : 0
                        }
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={indentItem.currentApprovals.editLock}
                                checked={
                                  indentItem.currentApprovals.referSelected
                                }
                                onChange={e => {
                                  handleChange(e);
                                  // setStatusApproval(!statusApproval)
                                  let rowData = { ...indentItem };
                                  rowData.currentApprovals.referSelected = !rowData
                                    .currentApprovals.referSelected;
                                  setIndentItem({ ...rowData });
                                  if (e.target.checked) {
                                    setApprovalStatusList(
                                      originalApprovalStatusList.filter(
                                        x =>
                                          x.lookupID >= 89 && x.lookupID <= 91
                                      )
                                    );
                                  } else {
                                    setApprovalStatusList(
                                      originalApprovalStatusList.filter(
                                        x =>
                                          x.lookupID != 89 && x.lookupID != 90
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label="Refer to director"
                          />
                        </FormGroup>
                      </Grid>
                    )} */}
                    <Grid
                      item
                      md={indentItem.currentApprovals.referOptionView ? 3 : 4}
                      xs={12}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        className={classes.formControl}
                        error={Boolean(touched.status && errors.status)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Status
                        </InputLabel>
                        <Select
                          disabled={indentItem.currentApprovals.editLock}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onBlur={handleBlur}
                          required
                          onChange={e => {
                            handleChange(e);
                            let rowData = { ...indentItem };
                            rowData.currentApprovals.comment = '';
                            rowData.currentApprovals.approvalStatusID =
                              e.target.value;
                            setIndentItem({ ...rowData });
                            console.error('hello', indentItem);
                            if(e.target.value){
                              setdis(true)
                            }
                          }}
                          name="status"
                          label="Status"
                          value={
                            indentItem.currentApprovals.approvalStatusID == 0
                              ? ''
                              : indentItem.currentApprovals.approvalStatusID
                          }
                        >
                          {approvalStatusList.map(li => (
                            <MenuItem key={li.lookupID} value={li.lookupID}>
                              {li.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.status && touched.status && errors.status}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {[25, 26, 28, 90].findIndex(
                      x => x == indentItem.currentApprovals.approvalStatusID
                    ) != -1 && (
                        <Grid
                          item
                          md={indentItem.currentApprovals.referOptionView ? 6 : 8}
                          xs={12}
                        >
                          <TextField
                            disabled={indentItem.currentApprovals.editLock}
                            fullWidth
                            error={Boolean(touched.Note && errors.Note)}
                            helperText={touched.Note && errors.Note}
                            label="Approval Note"
                            name="Note"
                            type='text'
                            onBlur={handleBlur}
                            value={
                              indentItem.currentApprovals.comment == 'undefined'
                                ? ''
                                : indentItem.currentApprovals.comment
                            }
                            onChange={e => {
                              handleChange(e);
                              let rowData = { ...indentItem };
                              rowData.currentApprovals.comment = e.target.value;
                              setIndentItem({ ...rowData });
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                  </Grid>
                </Box>

                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>
                      <b>Approval Status:</b>
                    </Typography>
                    <StepperBox steps={indentItem.Approvals} />
                  </Grid>
                </Grid>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item lg={12} className={classes.Accordion}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.secondaryHeading}>
                            <b>Indent Partial-Edit-Registry-Approval-Item-History</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Store Indent Item Id</TableCell>
                                  <TableCell>Item Name</TableCell>
                                  <TableCell>Requested Quantity</TableCell>
                                  <TableCell>Approval Quantity</TableCell>
                                  <TableCell>Updated By</TableCell>
                                  <TableCell>Updated Date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {itemHis && itemHis?.length != 0 ? (
                                  itemHis
                                    ?.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((item, index) => (
                                      <TableRow>
                                        <TableCell>
                                          {item.storeIndentItemID}
                                        </TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.requestQty}</TableCell>
                                        <TableCell>{item.newValue}</TableCell>
                                        <TableCell>
                                          {item.employeeName}
                                        </TableCell>
                                        <TableCell>
                                          {moment(item.updatedDate).format(
                                            'dd/MM/yyyy'
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                ) : (
                                  <TableRow hover role="checkbox" key={1}>
                                    <TableCell
                                      colSpan={6}
                                      align="center"
                                      key={2}
                                    >
                                      {'No Data Found'}
                                    </TableCell>{' '}
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  // style={{
                  //   backgroundColor: '#394671',
                  //   color: '#fff',
                  //   border: '1px solid'
                  // }}
                  className={classes.button2}
                  disabled={indentItem.currentApprovals.editLock}
                  type="submit"
                  onClick={e => {
                    updateIndentApproval();
                  }}
                >
                  Update
                </Button>

                <Button
                  // style={{
                  //   backgroundColor: '#394671',
                  //   color: '#fff',
                  //   border: '1px solid'
                  // }}
                  className={classes.button2}
                  onClick={handleIndentViewClose}
                >
                  Close
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </Container>
  );
};
export default StoreApproval;
