import React, { useEffect } from 'react'
import {
    Typography,makeStyles,  TableContainer, Box, Table, TableBody, TableCell, TableHead, TableRow,TablePagination
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { RGCA_URLS } from './../../../utils/url';
import { getProjectDetails, parseData, dateFormate } from '../Common/utilities';
import APIKit from 'src/utils/APIKit';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {}
}));

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const LowStock = ({ lowStockData }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };




    return (
        <>
            <Accordion defaultExpanded={false} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >
                        <b>Low Stock Details (Current Project)</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>

                    <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">S.No</TableCell>
                                        <TableCell align="left">Item Id</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Available Stock</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lowStockData && lowStockData?.length != 0 ? (
                                        lowStockData.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                     <TableCell align="left">{index+1}</TableCell>
                                                    <TableCell align="left">{x?.ItemID}</TableCell>
                                                    <TableCell align="left">{x?.name}</TableCell>
                                                    <TableCell align="left">{x?.AvaQty}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {lowStockData && lowStockData?.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={lowStockData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default LowStock