import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, makeStyles, FormControl, InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import { getProjectDetails } from '../Project/Common/utilities';
import { useParams } from 'react-router-dom';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import SelectFinancialYear from '../Project/Common/selectFinancialYear';


const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    minWidth: 120,
    marginBottom: '1rem'
  },
  selectEmpty: {
    marginTop: '1rem',
  },
}));

const Sales = ({ salesGraph, className, testdata, saleYear, setSaleYear, ...rest }) => {
  const classes = useStyles();
  // const { project } = useParams();
  // const projectDetails = { ...getProjectDetails(project) };
  // const [salesGraph, setSalesGraph] = useState([])


  var data;
  var chartdata;



  if (salesGraph && salesGraph.length != 0 && salesGraph.length > 0) {
    let obj = {
      lable: [],
      targets: [],
      achive: []
    };
    chartdata = "ADF " + testdata.Location + "- Farms Yearly Achivement Based on Target (achievement as on date)";

    salesGraph.forEach(y => {
      obj.lable.push(y.itemName)
      obj.targets.push(y.totalSale)
      obj.achive.push(y.totalSale)
    })
    var finaldata = obj
    data = {
      data: {
        labels: finaldata.lable,
        datasets: [
          {
            label: "Sales",
            backgroundColor: "#6083cb",
            borderColor: "#2c387e",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: finaldata.targets
          }

          // {
          //   label: "Achievement",
          //   backgroundColor: "#f48540",
          //   borderColor: "#f46136",
          //   borderWidth: 1,
          //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
          //   hoverBorderColor: "rgba(255,99,132,1)",
          //   data: finaldata.achive
          // }
        ]
      }
    };
  }



  // const getSalesGraph = () => {
  //   APIKit.get(RGCA_URLS.getSalesGraphData + '/' + projectDetails.projectID)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setSalesGraph(res.data.data);

  //       } else {
  //       }
  //     })
  //     .catch((e) => {
  //     });
  // };

  const options = {
    title: {
      display: true,
      text: 'Sales',
      fontSize: 20
    },
    maintainAspectRatio: false,	// Don't maintain w/h ratio
    legend: {
      display: true
    },
    type: "bar"

  };

  return (

    <Card className={clsx(classes.root, className)}  {...rest} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}>
      <CardContent>
        <SelectFinancialYear selectedYear={saleYear} setSelectedYear={setSaleYear} />

        {salesGraph && salesGraph.length > 0 ?
          <>
            {data && (<Bar
              data={data?.data}
              width={null}
              height={null}
              options={options}
            />)}
          </>
          :
          <div style={{ textAlign: 'center' }}>
            <h3>Sales</h3>
            <p>No data found</p>
          </div>
        }
      </CardContent>

    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
