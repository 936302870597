import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout/index.jsx';
import MainLayout from 'src/layouts/MainLayout/index.jsx';
import AccountView from 'src/views/account/AccountView';
// import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView.jsx';
import NotFoundView from 'src/views/errors/NotFoundView';
// import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
// import SettingsView from 'src/views/settings/SettingsView';
// import Depositors from 'src/views/depositors/index';
// import Requests from 'src/views/requests/index';
// import TransactionsHistory from 'src/views/transactions_history';
// import ManageInterest from 'src/views/manage_interest/index';
import Dashboard from 'src/views/dashboard';
import Authentication from 'src/views/auth/authentication';
import AdminLayout from 'src/layouts/AdminLayout/index.jsx';
import Employee from 'src/views/Employee/index.jsx';
import Location from 'src/views/Location';
import Deportment from 'src/views/Deportment';
import Designation from 'src/views/Designation/index.jsx';
import Suppliers from 'src/views/Suppliers';
//  import Materials from 'src/views/Materials';
import AddLocation from 'src/views/AddLocation';
import Role from 'src/views/Role/index.jsx';
import AddEmplyoee from 'src/views/AddEmplyoee/index.jsx';
//  import AddSuppliers from 'src/views/AddSuppliers';
import AddMaterial from 'src/views/AddMaterial/index.jsx';
// import FarmersBeneficiary from 'src/views/FarmersBeneficiary';
// import FishermenBeneficiary from 'src/views/FishermenBeneficiary';
// import PurchaseForm from 'src/views/PurchaseForm';
import Reservoir from 'src/views/Reservoir';
import InlandSubsidyScheme from 'src/views/InlandSubsidyScheme';
// import FishSeedRearingFarms from 'src/views/FishSeedRearingFarms';
// import FishSeedProduction from 'src/views/Fish_seed_Production';
// import RearingCages from 'src/views/RearingCages';
import SeabaseHatchery from 'src/views/SeabaseHatchery/index.jsx';
import TaxForm from 'src/views/SeabaseHatchery/form';
import AddProject from 'src/views/AddProject/index.jsx';
import EditProject from 'src/views/EditProject';
// import ProjectDashboard from 'src/views/projactDashboard';

import Indent from 'src/views/indent/index.jsx';
import IndentDetails from 'src/views/indentDetails/index.jsx';
import IndentItems from 'src/views/indentItems/index.jsx';

import Vendor from 'src/views/vendor/index';
import Categories from 'src/views/Categories';
import AddCategories from 'src/views/AddCategories';
import Tax from 'src/views/Tax';
import { tsAsExpression } from '@babel/types';

import ProjectDashboard from 'src/views/Project/Dashboard';
import Profile from 'src/views/Project/Profile';
import Reports from 'src/views/Project/Reports';
import Capital from 'src/views/Project/Capital';
import Recurring from 'src/views/Project/Recurring';
import Selling from 'src/views/Project/Selling/index.jsx';
import ProjectIndents from 'src/views/Project/Indents/index.jsx';
import ListPurchaseOrder from 'src/views/Project/Purchasing/ListPurchaseOrder.jsx';
import ProjectIndentCreateOrEdit from 'src/views/Project/Indents/ProjectIndentCreateOrEdit.jsx';
import GRN from 'src/views/Project/GRN/index.jsx';
import GIN from 'src/views/Project/GIN/index.jsx';
import WorkOrderApprovalList from './views/Project/workorderapprovallist/index.jsx';
// project Master
import Materials from 'src/views/Project/Master/Materials';
import Assets from 'src/views/Project/Master/Assets.jsx';
import Sales from 'src/views/Project/Master/Sales';
import IndentEdit from 'src/views/Project/Indents/IndentEdits.jsx';
import StoreIndentMain from 'src/views/Project/Store/index.jsx';
import Customer from './views/Project/Customer';
import Receipts from './views/Project/receipts/index.jsx';
import WorkOrder from './views/Project/workOrder/index.jsx';
import InventoryRegister from './views/Project/inventryReg/index.jsx';
import Inventory from './views/Project/inventory/index.jsx';
import FAR from './views/Project/FAR/index.jsx';
import PaymentSystem from './views/Project/PaymentSystem/index.jsx';
import PaymentSystemApproval from './views/Project/PaymentSystem/PaymentSystemApproval.jsx';
import ApprovalWorkOrder from './views/Project/workOrder/Approval.jsx';
import Roles from './views/Project/Roles';
import ApprovalConfig from './views/Project/approvalConfig/index.jsx';
import ProjectList from './views/Project/ProjectList';
import PaymentGateway from './views/Project/PaymentSystem/PaymentGateway.jsx';
import DasboardNew from './views/Project/DashboardNew';
import ExpensesManagement from './views/Project/Expenses_Management';
import NewIndent from './views/Project/Indents/newIndent.jsx';
import LookUp from './views/LookupTable/lookup';
import NewWorkOrder from './views/Project/workOrder/NewWorkorder.jsx';
import ChangePassword from './views/auth/changePassword.jsx';
import ProfileChangePassword from './views/auth/profileChangePassword.jsx';
import UserProfile from './views/userProfile/UserProfile.jsx';
import NewRole from './views/Role/newRole.jsx';
import AddNotice from './views/AddNotice/index.jsx';
import Training from './views/Project/training/index.jsx';

const routes = [
  {
    path: 'app1',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      // { path: 'customers', element: <CustomerListView /> },
      // { path: 'depositors', element: <Depositors /> },
      // { path: 'requests', element: <Requests /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'transactionshistory', element: <TransactionsHistory /> },
      // { path: 'manageInterest', element: <ManageInterest /> },
      // { path: 'products', element: <ProductListView /> },
      // { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'app',
    element: <AdminLayout />,
    children: [
      { path: 'dashboard/:project', element: <ProjectDashboard /> },
      { path: 'maindashboard', element: <DasboardNew /> },
      { path: 'profile/:project', element: <Profile /> },
      { path: 'reports/:project', element: <Reports /> },
      { path: 'Capital/:project', element: <Capital /> },
      { path: 'Recurring/:project', element: <Recurring /> },
      { path: 'Selling/:project', element: <Selling /> },
      { path: 'Indent/:project', element: <ProjectIndents /> },
      { path: 'Purchases/:project', element: <ListPurchaseOrder /> },
      
      {
        path: 'WorkorderApprovalList/:project',
        element: <WorkOrderApprovalList />
      },
      { path: 'indentcreate/:project', element: <NewIndent /> },
      { path: 'Materials/:project', element: <Materials /> },
      { path: 'Assets/:project', element: <Assets /> },
      { path: 'Sales/:project', element: <Sales /> },
      { path: 'IndentEdit/:project', element: <IndentEdit /> },
      { path: 'StoreIndent/:project', element: <StoreIndentMain /> },
      { path: 'customer/:project', element: <Customer /> },
      { path: 'Training/:project', element: <Training /> },
      { path: 'receipt/:project', element: <Receipts /> },
      { path: 'inventory/:project', element: <Inventory /> },
      { path: 'GRN/:project', element: <GRN /> },
      { path: 'workorder/:project', element: <NewWorkOrder /> },
      { path: 'inventoryregister/:project', element: <InventoryRegister /> },
      { path: 'GIN/:project', element: <GIN /> },
      { path: 'FAR/:project', element: <FAR /> },
      { path: 'PaymentSystem/:project', element: <PaymentSystem /> },
      {
        path: 'PaymentSystemApproval/:project',
        element: <PaymentSystemApproval />
      },
      { path: 'PaymentGateway/:project', element: <PaymentGateway /> },
      { path: 'Expenses_Management/:project', element: <ExpensesManagement /> },
      { path: 'approvalworkOrder/:project', element: <ApprovalWorkOrder /> },
      { path: 'roles/:project', element: <Roles /> },
      { path: 'approvalconfig', element: <ApprovalConfig /> },
      { path: 'projectlist', element: <ProjectList /> },
      { path: 'updateproject/:id', element: <AddProject /> },
      { path: 'addnotice/:project', element: <AddNotice /> },

      { path: 'dashboard', element: <Dashboard /> },
      { path: 'Location', element: <Location /> },
      { path: 'Employee', element: <Employee /> },
      { path: 'Department', element: <Deportment /> },
      { path: 'LookUp', element: <LookUp /> },
      { path: 'Designation', element: <Designation /> },
      { path: 'Suppliers', element: <Suppliers /> },
      // { path : 'Materials', element: <Materials />},
      { path: 'AddLocation', element: <AddLocation /> },
      { path: 'Role', element: <NewRole /> },
      { path: 'AddRoles', element: <Role /> },
      { path: 'AddEmplyoee', element: <AddEmplyoee /> },
      // { path : 'AddSuppliers' , element: <AddSuppliers />},
      { path: 'AddMaterial', element: <AddMaterial /> },
      // {path : 'FarmersBeneficiary' , element: <FarmersBeneficiary />},
      // {path : 'FishermenBeneficiary' , element: <FishermenBeneficiary />},
      // { path: 'PurchaseForm', element: <PurchaseForm /> },
      { path: 'Reservoir', element: <Reservoir /> },
      { path: 'InlandSubsidyScheme', element: <InlandSubsidyScheme /> },
      // {path : 'FishSeedRearingFarms' , element: <FishSeedRearingFarms />},
      // {path : 'FishSeedProduction' , element: <FishSeedProduction />},
      // { path: 'RearingCages', element: <RearingCages /> },
      // { path : 'Projects/:id', element: <SeabaseHatchery/>},
      { path: 'TaxForm', element: <TaxForm /> },
      { path: 'AddProject', element: <AddProject /> },
      { path: 'EditProject/:id', element: <EditProject /> },
      { path: 'AddProject/:id', element: <AddProject /> },
      { path: '*', element: <Navigate to="/404" /> },
      // {path:'Projectdashboard', element: <ProjectDashboard />},
      { path: 'Indent', element: <Indent /> },
      { path: 'IndentDetails', element: <IndentDetails /> },
      { path: 'IndentItems', element: <IndentItems /> },
      // {path:'IndentEdit',element:<IndentEdit/>},
      { path: 'Vendor', element: <Vendor /> },
      { path: 'Categories', element: <Categories /> },
      { path: 'AddCategories', element: <AddCategories /> },
      { path: 'Tax', element: <Tax /> },
      { path: 'UserProfile', element: <UserProfile /> },
      { path: 'ProfileChangePassword', element: <ProfileChangePassword /> }
    ]
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: '', element: <LoginView /> },
      { path: '/login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'authentcation', element: <Authentication /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/changePassword', element: <ChangePassword /> }
    ]
  }
];

export default routes;
