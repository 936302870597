import React, { useEffect, useState, useRef } from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TableContainer,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/views/loader';
import { ToastContainer, toast } from 'react-toastify';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import StepperBox from '../Common/StepperBox';
import CommonTable from '../Common/CommonTable';
import { getloggedInData } from 'src/utils/helpers';
import { useParams } from 'react-router-dom';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import { MESSAGE } from 'src/utils/message';
import CommonTable1 from '../Common/commonTableIndent';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useReactToPrint } from 'react-to-print';
import { checkPermission } from 'src/utils/permission';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    // icon: {
    //   fill: "green",
    // },
    text: {
        fill: 'white'
    },
    stepIcon: {
        color: '#fff'
    },
    labelContainer: {
        '& $alternativeLabel': {
            marginTop: 0
        }
    },
    step: {
        '& $completed': {
            color: 'lightgreen'
        },
        '& $active': {
            color: 'pink'
        },
        '& $disabled': {
            color: 'red'
        }
    },
    alternativeLabel: {},
    active: {}, //needed so that the &$active tag works
    completed: {},
    disabled: {},
    labelContainer: {
        '& $alternativeLabel': {
            marginTop: 0
        }
    }
}));

const viewIndent = [
    {
        title: 'Store Indent Item ID',
        field: 'storeIndentItemID',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Item / Asset Name',
        field: 'name',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Uom',
        field: 'uom',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Quantity',
        field: 'Qty',
        align: 'center',
        type: ETTypes.numeric
    },
    {
        title: 'Location',
        field: 'location',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Description',
        field: 'comment',
        align: 'center',
        type: ETTypes.string
    }
];

const updateIndent = [
    {
        title: 'Store Indent Item ID',
        field: 'storeIndentItemID',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Item / Asset Name',
        field: 'name',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Uom',
        field: 'uom',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Quantity',
        field: 'Qty',
        align: 'center',
        type: ETTypes.numeric,
        editable: EEditable.onEdit
    },
    {
        title: 'Location',
        field: 'location',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Description',
        field: 'comment',
        align: 'center',
        type: ETTypes.string
    },
    {
        title: 'Action',
        field: 'action',
        align: 'center',
        list: [ETaction.onDelete]
    }
];

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const StoreIndentByProject = (props) => {
    const classes = useStyles();
    const { project } = useParams();
    const { addToast } = useToasts();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const projectDetails = { ...getProjectDetails(project) };

    const [indentView, setIndentView] = React.useState(false);
    const [statusEdit, setStatusEdit] = React.useState(false);
    const [indentList, setIndentList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [updateColumns, setUpdateColumns] = useState([]);
    const [itemHis, setItemHis] = useState([]);



    const [storeIndentItemList, setstoreIndentItemList] = useState(
        {
            "storeIndentMasterID": "",
            "ProjectID": null,
            "statusID": null,
            "UpdatedBy": null,
            "CreatedBy": null,
            "quoteUrl": "",
            "CreateDate": "",
            "UpdatedDate": null,
            "projectName": "",
            "CreatedByName": "",
            "storeIndentItem": [],
            "Approvals": [],
            "location": "",
            "comment": ""
        });

    const [storeColumns, setStoreColumns] = useState([
        {
            title: 'Indent ID',
            field: 'storeIndentMasterID',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'Status',
            field: 'indStatus',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'Project Name',
            field: 'projectName',
            align: 'left',
            type: ETTypes.string
        },
        {
            title: 'Create Date',
            field: 'CreateDate',
            align: 'left',
            type: ETTypes.dateOnly
        },
        {
            title: 'Actions',
            field: 'action',
            align: 'left',
            list: props?.permission == 'view_storeIndent' ? [ETaction.onView] : [ETaction.onEdit]
        }
    ]);

    const handleIndentViewClose = () => {
        if (indentView) {
            setStatusEdit(false);
        }
        setIndentView(!indentView);
        getUserIndentLists();
    };
    useEffect(() => {
        getUserIndentLists();
    }, []);

    const getUserIndentLists = () => {
        setIsLoading(true);
        APIKit.get(
            RGCA_URLS.getUserStoreIndentListforDashBoard + '/' + projectDetails.projectID
        )
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    if (res?.data?.data?.length > 0) {
                        console.log(res);
                        let data = res.data.data.map(x => {
                            return {
                                ...x,
                                Approvals: x.Approvals && parseData(x.Approvals),
                                storeIndentItem: x.storeIndentItem && parseData(x.storeIndentItem)
                            };
                        });

                        let filteredData = data.filter(x => x.indStatus == 'OPEN');

                        setIndentList(props?.permission == 'view&edit_storeIndent' ? filteredData : data);
                        if (statusEdit) {
                            let row = data.find(
                                x =>
                                    x.storeIndentMasterID ===
                                    storeIndentItemList.storeIndentMasterID
                            );
                            if (row) setstoreIndentItemList({ ...row });
                        }
                        props.setIsColour(true)

                    } else {
                    }
                } else {
                    props.setIsColour(false)
                    setIndentList([])
                }
            })
            .catch(function (e) { });
    };

    const totalQty = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + x.Qty;
        });
        return test;
        //  data['totalQty'] = test;
    };

    const actions = {
        onView: (index, row) => {
            setUpdateColumns([...viewIndent]);
            setstoreIndentItemList({ ...row });
            handleIndentViewClose();
        },

        onEdit: (index, row) => {
            history(row);
            setStatusEdit(true);
            setUpdateColumns([...updateIndent]);
            setstoreIndentItemList({ ...row });
            handleIndentViewClose();
        }
    };

    const history = data => {
        const payload = {
            storeIndentMasterID: data.storeIndentMasterID,
            projectID: projectDetails.projectID
        };
        setIsLoading(true);
        APIKit.post(RGCA_URLS.getStoreIndentHistory, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setItemHis(res.data.data);
                    // console.error(res.data.data)
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(e => {
                setIsLoading(false);
            });
    };

    const modelActions = {
        editRow: (index, field, value) => {
            let items = storeIndentItemList;
            items.storeIndentItem[index][field] = value;
            setstoreIndentItemList({ ...items });
            console.error(storeIndentItemList);
        },

        onDelete: (index, row) => {
            remove(row);
        }
    };

    const remove = data => {
        confirm({ description: 'You want to delete the record ?' })
            .then(() => {
                deleteIndentItem(data);
                // activeOrInactive(status,id)
            })
            .catch(() => console.log('Deletion cancelled.'));
    };

    const deleteIndentItem = row => {
        const payload = {
            storeIndentItemID: row.storeIndentItemID,
            storeIndentMasterID: row.storeIndentMasterID
        };
        APIKit.post(RGCA_URLS.deleteStoreIndentItem, payload)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(MESSAGE.deleteIndentItem, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    handleIndentViewClose()
                    getUserIndentLists();
                } else {
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) { });
    };

    const updateIndentItems = () => {
        const payload = {
            "storeIndentMasterID": storeIndentItemList.storeIndentMasterID,
            items: storeIndentItemList.storeIndentItem.map(x => {
                return {
                    "storeIndentItemID": x.storeIndentItemID,
                    "Qty": x.Qty,
                    "approvedQty": x.Qty,
                    "estimatedPrice": x.estimatedPrice
                };
            })
        };
        if (payload.items.some(e => e.Qty == 0)) {
            toast.error(MESSAGE.qty0, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            return;
        }

        APIKit.post(RGCA_URLS.storeIndentItemUpdate, payload)
            .then(res => {
                if (res.data.status === 200) {
                    getUserIndentLists();
                    //addToast(res.data.message, { appearance: 'success' });
                    toast.success(MESSAGE.indentUpdate, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    getUserIndentLists();
                    handleIndentViewClose();
                } else {
                    addToast(res.data.message, { appearance: 'error' });
                }
            })
            .catch(function (e) { });
    };

    const htmlContentRef = useRef(null);

    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });
    const [deletePermmsion, setDeletePermmsion] = useState(false);
    useEffect(() => {
        let deleteOption = checkPermission('delete_storeIndent');
        console.log("deleteOption", deleteOption);
        if (deleteOption) {
            setDeletePermmsion(true)
        }
    }, [])
    const [print, setprint] = useState(false)
    return (
        <Container
            maxWidth={true}
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
            <Loader isLoading={isLoading} />
            {/* <Typography>
                <h2 style={{ color: '#394671' }}>{props?.permission == 'view_storeIndent' ? 'View My Store Indent' : 'Edit My Store Indent'}</h2>
            </Typography> */}
            <Grid container spacing={3} className={classes.field}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                        <CommonTable
                            columns={storeColumns}
                            data={indentList}
                            action={actions}
                        />
                    </div>
                </Grid>
            </Grid>

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={indentView}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                }}
                maxWidth="lg"
                fullWidth
            // id="printme"
            >
                <Grid ref={htmlContentRef} id="myHtmlContent" /* id="income" */>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleIndentViewClose}
                        style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            width: '900px !important'
                        }}
                    >
                        Store Indent Items
                    </DialogTitle>
                    <DialogContent dividers style={{ width: '900px !important' }}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={3} xl={3} xs={3}>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography>
                                        <h5 style={{ color: '#394671' }}>
                                            Store Indent ID : {storeIndentItemList.storeIndentMasterID}
                                        </h5>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={3} xl={3} xs={3}>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography>
                                        <h5 style={{ color: '#394671' }}>
                                            Request By : {storeIndentItemList.employeeName}
                                        </h5>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={3} xl={3} xs={3}>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography>
                                        <h5 style={{ color: '#394671' }}>
                                            Total Quantity:{' '}
                                            {totalQty(storeIndentItemList.storeIndentItem || [])}
                                        </h5>
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item lg={3} sm={3} xl={3} xs={3}>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography>
                                        <h5 style={{ color: '#394671' }}>
                                            Date : {dateFormate(storeIndentItemList.CreateDate)}
                                        </h5>
                                    </Typography>
                                </div>
                            </Grid>

                            {/* ---------------------------------------------------------------------------------------- */}

                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <div style={{ textAlign: 'center' }}>
                                    <CommonTable1
                                        columns={updateColumns}
                                        data={storeIndentItemList.storeIndentItem}
                                        action={modelActions}
                                        editLock={storeIndentItemList.Approvals}
                                        isEnabled={props?.permission == 'view&edit_storeIndent'}
                                        isDeletedoption={deletePermmsion}
                                        isprint={print}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <br />
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <StepperBox
                                    steps={storeIndentItemList.Approvals}
                                    action={actions}
                                />
                            </Grid>
                        </Grid>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item lg={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={classes.secondaryHeading}>
                                                Store Indent Partial-Edit-Registry-Approval-Item-History
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table
                                                    className={classes.table}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Item Name</TableCell>
                                                            <TableCell>Requested Quantity</TableCell>
                                                            <TableCell>Approval Quantity</TableCell>
                                                            <TableCell>Updated By</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {itemHis && itemHis.length != 0 ? (
                                                            itemHis
                                                                .slice(
                                                                    page * rowsPerPage,
                                                                    page * rowsPerPage + rowsPerPage
                                                                )
                                                                .map((item, index) => (
                                                                    <TableRow>
                                                                        <TableCell>{item.itemName}</TableCell>
                                                                        <TableCell>{item.requestQty}</TableCell>
                                                                        <TableCell>{item.newValue}</TableCell>
                                                                        <TableCell>{item.employeeName}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                        ) : (
                                                            <TableRow hover role="checkbox" key={1}>
                                                                <TableCell colSpan={4} align="center" key={2}>
                                                                    {'No Data Found'}
                                                                </TableCell>{' '}
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>

                </Grid>


                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    {statusEdit ? (
                        <Button
                            type="submit"
                            style={{
                                backgroundColor: '#394671',
                                color: '#fff',
                                border: '1px solid'
                            }}
                            // disabled={
                            //   storeIndentItemList?.Approvals?.some(
                            //     e => e.status != 'Awaiting'
                            //   ) &&
                            //   storeIndentItemList?.Approvals?.every(
                            //     e => e.status != 'Modification'
                            //   )
                            // }
                            disabled={props?.permission !== 'view&edit_storeIndent'}
                            onClick={(e) => { updateIndentItems(); }}
                        >
                            Update
                        </Button>
                    )
                        :
                        <Button
                            // type="submit"
                            style={{
                                backgroundColor: '#394671',
                                color: '#fff',
                                border: '1px solid'
                            }}

                            // onClick={printOrder}
                            onClick={() => {
                                setprint(true)
                                setTimeout(() => {
                                    printOrder()
                                }, 2000)
                            }}
                        >
                            Print
                        </Button>
                    }

                    <Button
                        style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            border: '1px solid'
                        }}
                        onClick={() => { setprint(false); handleIndentViewClose() }}
                    >
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </Container>
    );
};
export default StoreIndentByProject;
