import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

import { currencyFormat1, dateOnly, dateOnlyRev, getProjectDetails, parseData, precision } from '../Common/utilities';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const ListSale = ({ salesListData }) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell>S.No</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Name of the Item</TableCell>
                                    <TableCell>Batch No</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Rate Per Unit</TableCell>
                                    <TableCell>GST</TableCell>
                                    <TableCell>Available Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salesListData && salesListData.length != 0 ? (
                                    salesListData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{x?.typeID == 17 ? 'Product' : 'Seed'}</TableCell>
                                                <TableCell>{x?.categoryName ? x?.categoryName : '-'}</TableCell>
                                                <TableCell>{x?.name}</TableCell>
                                                <TableCell>{x?.batchNo}</TableCell>
                                                <TableCell>{x?.uom}</TableCell>
                                                <TableCell>{x?.rpu}</TableCell>
                                                <TableCell>{x?.gstRate}</TableCell>
                                                <TableCell>{x?.stock}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>{' '}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {salesListData && salesListData.length != 0 && salesListData.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={salesListData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default ListSale