import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination, TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
// import data from './data';
import Page from 'src/components/Page';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CenterFocusStrong, SignalCellularNull, SignalCellularNullOutlined } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNavigationData } from '../../Redux/Common/action';
import { getNavData } from '../../Redux/Nav/action';

import { useDispatch } from 'react-redux';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './style.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let sigPad = React.createRef();

const trim = async (formData, changeFormData) => {
  // const trimmed = sigPad.current.getTrimmedCanvas().toDataURL("image/png");

  await changeFormData({
    ...formData,
    url: sigPad.current.getTrimmedCanvas().toDataURL("image/jpg")
  });

  // await console.log(formData);
};

const checkState = state => {
};


const Indent = (props) => {
  const [startMinDate, setStartDate] = useState(new Date())

  const dispatch = useDispatch();
  const classes = useStyles();
  const [createIndent, setCreateIndent] = useState({
    "Description": "",
    "Category": "",
    "SubcategoryId": null
  })
  const navigationData = useSelector(x => x.NavigationData.navigationData);
  const indentId = useSelector(x => x.NavigationData.id);
  const navData = useSelector(x => x.NavData.navData);
  const [subcategory, setSubCategory] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [indentInputList, setIndentInputList] = useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const [formData, changeFormData] = useState({ url: "" });
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [process, setProcess] = useState({
    MaterialName: "", Quantity: "", Purpose: "",
    EstimatedPrice: 0,
    IGST: 0,
    CGST: 0,
    SGST: 0,
    Brand: '',
    ProductDeliveredDate: moment(new Date()).format('dd/MM/yyyy').toString(),
    DeliveryChallan: "",
    InvoiceAttachment: "",
    TypeOfSign: "digital",
    SignatureAttachment: "",
    Amount: 0,
    InvoiceID: "",
    IncomeTax: "",
    IndentID: 0,
    IndentItemID: 0,
    PurchaseOrderID: "",
    VendorID: "",
    ProductDeliveryStatus: null

  })
  const [openAlertVendor, setOpenAlertVendor] = useState(false)
  const [flagVendor, setFlagVendor] = useState('')
  const [editRow, setEditRow] = useState(null)
  const handleClickOpenVendor = (id, data, type) => {
    //  alert('jjj')
    setEditRow(id)
    setFlagVendor(type)
    setProcess({
      MaterialName: data.MaterialName,
      Quantity: data.Quantity,
      Purpose: data.Purpose,
      EstimatedPrice: data.EstimatedPrice,
      Brand: data.Brand,
      IGST: data.IGST,
      CGST: data.CGST,
      SGST: data.SGST,
      InvoiceID: data.InvoiceID,
      IncomeTax: data.IncomeTax,
      PurchaseOrderID: data.PurchaseOrderID,
      VendorID: data.VendorID,
      IndentItemID: data.IndentItemID,
      IndentID: data.IndentID,
      ProductDeliveryStatus: data.ProductDeliveryStatus,
      TypeOfSign: 'digital',
      ProductDeliveredDate: moment(data.ProductDeliveredDate).format('dd/MM/yyyy').toString(),
      DeliveryChallan: data.DeliveryChallan,
      InvoiceAttachment: data.InvoiceAttachment,
      SignatureAttachment: data.SignatureAttachment,
      Amount: data.Amount
    })
    // setIndentInputList(data);
    // setOpenAlertVendor(true);
  };
  const [vendorflag, setVendorFlag] = useState(true)
  const handleClickOpen = (id, data) => {
    // alert('kkk',data.VendorName)
    if (data.VendorName) {
      setVendorFlag(true)
    } else {
      setVendorFlag(false)
    }
    setProcess({
      MaterialName: data.MaterialName,
      Quantity: data.Quantity,
      Purpose: data.Purpose
      , EstimatedPrice: data.EstimatedPrice,
      Brand: data.Brand,
      IGST: data.IGST,
      CGST: data.CGST,
      SGST: data.SGST,
      InvoiceID: data.InvoiceID,
      IncomeTax: data.IncomeTax,
      PurchaseOrderID: data.PurchaseOrderID,
      VendorID: data.VendorID,
      IndentItemID: data.IndentItemID,
      IndentID: data.IndentID,
      ProductDeliveryStatus: data.ProductDeliveryStatus,
      TypeOfSign: 'digital',
      ProductDeliveredDate: moment(data.ProductDeliveredDate).format('dd/MM/yyyy').toString(),
      DeliveryChallan: data.DeliveryChallan,
      InvoiceAttachment: data.InvoiceAttachment,
      SignatureAttachment: data.SignatureAttachment,
      Amount: data.Amount
    })
    // setIndentInputList(data);
    setOpenTest(true);
  };
  const handleCloseModal = () => {
    setOpenTest(false);
  };
  const handleCloseModalVendor = () => {
    setOpenAlertVendor(false);
  };
  const [indentForm, setIndentForm] = React.useState({
    ProjectID: null, Description: "", IndentItems: [], CategoryID: null, RaisedFrom: ''
  });



  const navigate = useNavigate();
  // handle input change
  const handleLandInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...indentInputList];
    list[index][name] = value;
    setIndentInputList(list);
  };
  const handleLandInputNumberChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...indentInputList];
    list[index][name] = value.replace(/\D/g, '');
    setIndentInputList(list);
  };

  // handle click event of the Remove button
  const handleLandRemoveClick = (index, data) => {
    if (indentInputList.length > 1) {
      if (data.IndentItemID) {
        const list = [...indentInputList];
        list.splice(index, 1);
        setIndentInputList(list);
        deleteIndentItems(data.IndentItemID)
      } else {
        const list = [...indentInputList];
        list.splice(index, 1);
        setIndentInputList(list);
      }
    } else {
      setOpen(true);
      setAlertMeg('error');
      setErrorMeg('Indent should have at least one record')
    }
  };

  // handle click event of the Add button
  const handleLandAddClick = () => {

    setFlag(true)
    setIndentInputList([...indentInputList, {
      ItemID: navigationData.CategoryID || null,
      MaterialID: null,
      SubMaterialID: null,
      Others: "",
      Quantity: null,
      EstimatedPrice: 0,
      Purpose: "",
      Brand: '',
      material: subcategory,
      submaterial: [],
    }]);
  };
  const [disable, setDisabled] = useState(false)
  useEffect(() => {
    getDropDownList()
    getDropDownListForm()
    setIndent(navigationData)
    let p = indentForm;
    p.RaisedFrom = navigationData.RaisedFrom ? navigationData.RaisedFrom : '';
    //   p.CreateIndent = navigationData.CreateIndent ? navigationData.CreateIndent : ''
    setIndentForm({
      ...p
    })
    let indent = p;
    indent.ProjectID = navigationData.ProjectId || navigationData.ProjectID;
    indent.CategoryID = navigationData.CategoryID;
    if (navigationData.approveView == 'approveview') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
    getSubCategorydataOfCategory();

  }, [navigationData])

  const [defaultData, setDefaulata] = useState(0)

  useEffect(() => {

    if (defaultData == 1) {
      handleLandAddClick()
      if (!navigationData.IsClosed) {
        setSatusClose({
          status: 0,
        })
      }
    }
  }, [defaultData])

  const CheckEmpty = () => {
    let myTest = false;
    indentInputList.map((x) => {
      if (x.Purpose == "" || x.Quantity == "" || x.MaterialID == 0) {
        if (x.SubMaterialID && x.SubMaterialID == null) {
          setOpen(true);
          setAlertMeg('error');
          setErrorMeg("Please enter all fields..")
          myTest = true
        }
        setOpen(true);
        setAlertMeg('error');
        setErrorMeg("Please enter all fields..")
        myTest = true
      }
    })
    return myTest
  }
  const handleIndentAddClick = async () => {
    let test = indentForm;
    test.ProjectID = navigationData.ProjectId || navigationData.ProjectID;
    test.CategoryID = navigationData.CategoryID;
    let check = await CheckEmpty()
    if (check == true) {
      return
    }
    else {
      handleToggleLoader()
      var payload = indentInputList.map((x) => {
        return {
          ItemID: x.ItemID,
          MaterialID: x.MaterialID,
          SubMaterialID: x.SubMaterialID,
          Others: x.Others,
          Quantity: x.Quantity,
          EstimatedPrice: x.EstimatedPrice,
          Purpose: x.Purpose,
          Brand: x.Brand,
          ProductDeliveryStatus: x.ProductDeliveryStatus
        }
      })
      test.IndentItems = payload;

      APIKit.post('/' + APP_URLS.addIndent,
        test,
        { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
        .then((res) => {
          if (res.data.status === 200) {
            handleCloseLoader()
            setOpen(true);
            setAlertMeg('success');
            setErrorMeg(res.data.message)

            props.handleCreateIndentClose();

          }
          else {
            handleCloseLoader()
            setOpen(true);
            setAlertMeg('error');
            setErrorMeg(res.data.message)
            props.handleCreateIndentClose();
          }
        })
        .catch(function (e) {
        })
    }

  }
  const Close = () => {
    props.handleCreateIndentClose();
  }
  const gotoInventory = () => {
    props.a11yProps();
  }
  const updateClick = () => {
    //handleToggleLoader()
    let test = {
      "ProjectID": indentForm.ProjectID,
      "IndentID": indentForm.IndentID,
      "IndentStatus": indentForm.IndentStatus,
      "DeliveryCompletedDate": indentForm.DeliveryCompletedDate,
      "Description": indentForm.Description,
      "RaisedFrom": navigationData.RaisedFrom,
      IndentItems: []
    };

    var payload = indentInputList.map((x) => {
      return {
        IndentItemID: x.IndentItemID,
        ItemID: x.ItemID,
        MaterialID: x.MaterialID,
        SubMaterialID: x.SubMaterialID,
        Others: x.Others,
        Quantity: x.Quantity,
        EstimatedPrice: x.EstimatedPrice,
        Brand: x.Brand,
        Purpose: x.Purpose,
        ProductDeliveryStatus: x.ProductDeliveryStatus
      }
    })
    test.IndentItems = payload;
    APIKit.post('/' + APP_URLS.updateIndent,
      test,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          setOpen(true);
          setAlertMeg('success');
          setErrorMeg(res.data.message)
          gotoProjectId()
          // setTimeout(() => {
          //   props.a11yProps(5)
          // }, 500)

        }
        else {
          handleCloseLoader()
          setOpen(true);
          setAlertMeg('error');
          setErrorMeg(res.data.message)
        }
      })
      .catch(function (e) {
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const [recsubcategory, setRecSubCategory] = useState([]);
  // material
  const getSubCategorydataOfCategory = async () => {
    handleToggleLoader()
    var categoryId = 0;
    if (navigationData.CategoryID) {
      categoryId = navigationData.CategoryID
    }
    if (navigationData.IndentItems && navigationData.IndentItems[0].ItemID) {
      categoryId = navigationData.IndentItems[0].ItemID
    }
    APIKit.get('/' + APP_URLS.getSubCategorydata + categoryId)
      .then(async (res) => {

        if (res.data.status === 200) {
          handleCloseLoader()
          setSubCategory(res.data.data, loadDefault)
          setDefaulata(1)
          if (navigationData.IndentItems) {
            handleCloseLoader()
            setUpdate(true)
            setGotoIndent(true)
            setIndentForm(navigationData)
            navigationData.IndentItems.forEach((x, i) => {
              x['material'] = res.data.data;
              x['submaterial'] = []
            })
            loadSubCategory(navigationData.IndentItems, res.data.data);

          }
        }
        else {
          handleCloseLoader()
        }
      })
      .catch(function (e) {
      })
  }
  // sub material
  const getParentSubCategory = async (data, row) => {
    handleToggleLoader()
    await APIKit.post('/' + APP_URLS.getParentSubCategorydata, {
      "CategoryId": data.CategoryId || data.CategoryID || data.ItemID, // categoryd
      "ParentSubCategoryId": data.SubCategoryId || data.ParentSubCategoryId || data.MaterialID //subcategoryid
    })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          let data1 = indentInputList;
          data1[row]['submaterial'] = res.data.data;
          setIndentInputList(data1);
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const [inlineEdit, setInlineEdit] = React.useState(0);
  const [itemCost, setItemCost] = useState('')
  const [gotoindtent, setGotoIndent] = React.useState(true)
  const gotoIndent = () => {
    setCostFlag(false)
    setGotoIndent(true)
  }
  const loadDefault = () => {
  }

  const gotoProjectId = () => {
    dispatch(getNavData('Indent'))
    navigate('/app/Projects/' + navigationData.ProjectID, { replace: true });
  }
  const loadSubCategory = (list, category) => {
    if (navigationData) {
      if (navigationData) {
        var promiseApis = [];
        if (list) {
          list.forEach(x => {
            x.material = [];
            x.submaterial = [];
            promiseApis.push(APIKit.post('/' + APP_URLS.getParentSubCategorydata, {
              "CategoryId": x.ItemID, // categoryd
              "ParentSubCategoryId": x.MaterialID //subcategoryid

            }))
          })
          // x['material'] = res.data.data;
          Promise.all(promiseApis).then(function (values) {
            values.forEach((x, index) => {
              if (x.data.status == 200) {
                list[index].material = category;
                list[index].submaterial = x.data.data;
              }
            })
            setIndentInputList(list)
          });
        }
      }
    }

  }
  const gotoProject = () => {
    props.gotoInventory();

  }
  const gotoBiddingTab = () => {
    props.gotoBidding()
  }
  const gotoProjectIndent = () => {
    setUpdate(false)
    dispatch(getNavData('Indent'))
    navigate('/app/Projects/' + navigationData.ProjectID, { replace: true });
  }
  const [itemCostData, setItemCostData] = useState([])
  const [costFlag, setCostFlag] = useState(false);
  const getItemCost = () => {
    handleToggleLoader()
    if ((navigationData.CategoryID == 10 || navigationData.CategoryID == 11 || navigationData.CategoryID == 12 ||
      ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 10)
      || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 11) ||
      ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 12))
    ) {
      APIKit.post('/' + APP_URLS.ItemCostForRecurring, {
        "CategoryId": navigationData.CategoryID,
        "ParentSubCategoryId": itemCost
      })
        .then((res) => {
          if (res.data.status == 200) {
            setCostFlag(true)
            handleCloseLoader()
            setItemCostData(res.data.data)
            setOpen(true);
            setAlertMeg('success');
            setErrorMeg(res.data.message)
          } else {
            handleCloseLoader()
            setOpen(true);
            setAlertMeg('error');
            setErrorMeg(res.data.message)
          }

        })
        .catch(function (e) {
        })
    } else {
      APIKit.post('/' + APP_URLS.ItemCostForCapital, {
        "CategoryId": navigationData.CategoryID,
        "ParentSubCategoryId": itemCost
      })
        .then((res) => {
          if (res.data.status == 200) {
            setCostFlag(true)
            handleCloseLoader()
            setItemCostData(res.data.data)
            setOpen(true);
            setAlertMeg('success');
            setErrorMeg(res.data.message)
          } else {
            handleCloseLoader()
            setOpen(true);
            setAlertMeg('error');
            setErrorMeg(res.data.message)
          }

        })
        .catch(function (e) {
        })
    }

  }
  const [openLoader, setOpenLoader] = React.useState(false);

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const [value, setValue] = React.useState('upload');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const saveIndent = () => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.updateIndentItem, process,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status == 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setTimeout(() => {
            gotoProject()
          }, 300)
        } else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const saveIndentVendor = (data) => {
    let payLoad = {
      "VendorID": process.VendorID,
      "IndentItemID": process.IndentItemID
    }
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.assignIndentItem, payLoad,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status == 200) {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setEditRow(null)
          getBiddingIndentItems()
          // handleCloseModalVendor()
          // setTimeout(() => {
          //   gotoProject()
          // }, 300)
        } else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }
  const getBiddingIndentItems = () => {
    handleToggleLoader()
    APIKit.post('/' + APP_URLS.getIndentItems, {
      "IndentID": indentId
    },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          res.data.data['approveView'] = "approveview"
          res.data.data['flag'] = "Bidding"
          dispatch(getNavigationData(res.data.data, indentId))

        }
        else {
        }
      })
      .catch(function (e) {
      })

  }
  
  const handleCapture = ({ target }) => {

    handleToggleLoader()
    const formData = new FormData();
    for (var i = 0; i < target.files.length; i++) {
      formData.append('upload', target.files[i], target.files[i].name)
    }
    axios.post(APP_URLS.indentUpload, formData,
      { headers: { 'Authorization': getloggedInData().token } }
    )
      .then((res) => {
        if (res && res.data && res.data.filename) {
          if (target.name == 'Delivery') {
            setAlertOpen(true)
            setAlertMeg('success');
            setErrorMeg('Delivery Challan upload successful');
            setProcess({
              ...process,
              DeliveryChallan: res.data.filename
            })
            handleCloseLoader()
          }
          if (target.name == 'Invoice') {
            setAlertOpen(true)
            setAlertMeg('success');
            setErrorMeg('Invoice upload successful');
            setProcess({
              ...process,
              InvoiceAttachment: res.data.filename
            })
            handleCloseLoader()
          }
          if (target.name == 'Signature') {
            setAlertOpen(true)
            setAlertMeg('success');
            setErrorMeg('Signature upload successful');
            setProcess({
              ...process,
              SignatureAttachment: res.data.filename
            })
            handleCloseLoader()
          }
        }

      })
      .catch(function (e) {
      })
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const status_data3 = [
    {
      Name: "Requested",
      ID: 4120
    },
    {
      Name: "Dispatched",
      ID: 4121
    },
    {
      Name: "Delivered",
      ID: 4122
    },
    {
      Name: "Shipped",
      ID: 4123
    }

  ]

  const deleteIndentItems = (IndentItemID) => {
    handleToggleLoader()
    axios.post(APP_URLS.deleteIndentItem, { "IndentItemID": IndentItemID },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          handleCloseLoader()
          //  getBiddingIndentItems()
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
        }
        else {
          handleCloseLoader()
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const getDropDownList = () => {
    axios.get(APP_URLS.getSubCategorydataDropDown + '/' + 1005,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          if (navigationData.Isclosable == 0) {
            let filteredPeople = res.data.data.filter((item) => item.SubCategoryId !== 23524);
            setSatusAction(filteredPeople)
          } else {
            setSatusAction(res.data.data)
          }

        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const getDropDownListForm = () => {
    axios.get(APP_URLS.getSubCategorydataDropDown + '/' + 1006,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          if (navigationData.Isclosable == 0) {
            let filteredPeople = res.data.data.filter((item) => item.SubCategoryId !== 23524);
            setSatusActionForm(filteredPeople)
          } else {
            setSatusActionForm(res.data.data)
          }

        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const intialData = {
    CreatedDate: "",
    DeliveryCompletedDate: null,
    DepartmentID: null,
    DepartmentName: "",
    Description: "",
    Designation: "",
    DesignationID: null,
    Firstname: "",
    Gender: "",
    IndentID: null,
    IndentItems: [],
    IndentStatus: 4117,
    IndentStatusName: "",
    IsActive: false,
    IsClosed: false,
    Isclosable: null,
    Lastname: "",
    ProjectID: null,
    UniqueIndentId: "",
    UpdatedBy: null,
    UpdatedDate: "",
    UpdatedEmployeeDepartmentID: null,
    UpdatedEmployeeDepartmentName: "",
    UpdatedEmployeeDesignation: "",
    UpdatedEmployeeDesignationID: null,
    UpdatedEmployeeFirstname: "",
    UpdatedEmployeeGender: "",
    UpdatedEmployeeLastname: "",
    approveView: ""
  }
  const [indent, setIndent] = useState(intialData)

  const [statuAction, setSatusAction] = useState();
  const [statuActionForm, setSatusActionForm] = useState();
  const [statuAction3, setSatusAction3] = useState(status_data3);

  const [indentStatus, setIndentStatus] = useState(0)

  const [statuClose, setSatusClose] = useState({
    status: null,
  })
  const handleChangeIndent = (event) => {
    setIndentStatus(event.target.value);
  };
  // const handleCloseChange = (event) => {
  //   setSatusClose({ status: event.target.value })
  //   if (event.target.value == 1) {
  //     closeIndent()
  //   }
  // }

  const handleChangeIndentStatus = (event) => {
    setIndentStatus(event.target.value);

  };


  const updateIndentStatus = () => {

    handleToggleLoader()
    const { IndentID, Note } = indent;
    const payload = {
      "IndentID": IndentID,
      "IndentStatus": indentStatus,
      "Note": Note
    }
    APIKit.post('/' + APP_URLS.updateIndentStatus, payload
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        handleCloseLoader()
        if (res.data.status === 200) {
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          if (navigationData.flag == 'Inventory' || navigationData.flag == 'Bidding') {
            props.gotoInventory();
            dispatch(getNavData('Inventory Management'))
            // props.updateStatus();

          }
          if (navigationData.typeEdit == 'Edit') {
            props.handleIndentItemsClose()
          }


          //DefaultLoad()

        }

        else {
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }

      })
      .catch(function (e) {
        handleCloseLoader()
      })

  }
  useEffect(() => {
    getVendor()
  }, [])
  const [allVendors, setAllVendors] = useState([])

  const getVendor = () => {
    APIKit.get('/' + APP_URLS.getVendors,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }

  return (
    <div id="indent">
      <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
        <Alert onClose={handleAlertClose} severity={alertMeg} >
          {errorMeg}
        </Alert>
      </Snackbar>
      <DialogContent dividers style={{ width: "800px !important" }}>
        <Page
          className={classes.root}
          title="Depositors">
          <Container maxWidth={false}>
            <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
              <CircularProgress style={{ color: '#fff' }} />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  >
              <Alert onClose={handleClose} severity={alertMeg} >
                {errorMeg}
              </Alert>
            </Snackbar>
            <div>

              {gotoindtent == true ?
                <>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={6} style={{ marginTop: '10px', fontSize: '17px', fontWeight: 'bold' }}>

                      {navigationData.typeEdit == 'Edit' ?

                        <Link onClick={gotoProjectIndent} style={{ cursor: "pointer", color: '#3f51b5' }}>Back To Indent Management Details</Link>
                        : null}

                      {navigationData.flag == 'Inventory' ?

                        <Link onClick={gotoProject} style={{ cursor: "pointer", color: '#3f51b5' }}>Back To Inventory Management Details</Link>
                        : null}

                      {navigationData.flag == 'Bidding' ?

                        <Link onClick={gotoBiddingTab} style={{ cursor: "pointer", color: '#3f51b5' }}>Back To Bidding Management Details</Link>
                        : null}




                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

                    </Grid>
                  </Grid>




                  <Box mt={3} style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px',
                    marginTop: "0px"
                  }}>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                        Indent Items
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                        {disable == false ?
                          <Link style={{ cursor: "pointer" }} onClick={handleLandAddClick}>Add indent item</Link>
                          : null}
                      </Grid>
                    </Grid>
                    {navigationData.flag != 'Bidding' ?
                      <Grid
                        container
                        spacing={3}
                        style={{ "textAlign": "left" }}
                      >
                        <Grid item lg={10} xs={10} className="fullWidth">
                          <TextField
                            fullWidth
                            style={{ "width": '100px' }}
                            onChange={(e) => {
                              let p = indentForm;
                              p.Description = e.target.value
                              setIndentForm({
                                ...p
                              })
                            }}
                            value={indentForm.Description}
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={2}
                            rowsMax={4}
                            disabled={disable}
                          />
                        </Grid>


                      </Grid>
                      : null}
                    &nbsp;

                    <TableContainer component={Paper}>
                      <Table id="table-to-xls" aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#f1d2d2" }}>
                          <TableRow>

                            <TableCell>
                              S.No
                            </TableCell>
                            <TableCell>
                              Material
                            </TableCell>
                            {(navigationData.CategoryID == 10 || navigationData.CategoryID == 11 || navigationData.CategoryID == 12 || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 10)
                              || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 11) || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 12)) ?
                              <TableCell style={{ width: "30%" }}>
                                Sub Material
                              </TableCell>
                              : null}
                            <TableCell>
                              Quantity
                            </TableCell>
                            <TableCell>
                              Brand
                            </TableCell>
                            <TableCell>
                              Estimated Price
                            </TableCell>
                            <TableCell>
                              Description
                            </TableCell>

                            {navigationData.flag == 'Bidding' ?
                              <TableCell>
                                Vendor Name
                              </TableCell>
                              : null}
                            <TableCell>
                              Status
                            </TableCell>

                            <TableCell>
                              Actions
                            </TableCell>

                          </TableRow>
                        </TableHead>
                        &nbsp;
                        {flag ?
                          <TableBody>
                            {indentInputList && indentInputList.length > 0 && indentInputList.map((land, row) => (
                              <>
                                <TableRow
                                  hover
                                // key={land.LandType}
                                >
                                  <TableCell>
                                    {/* {row + 1} */}
                                    {land.UniqueIndentItemID}
                                  </TableCell>
                                  <TableCell>

                                    <TextField
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      name="MaterialID"
                                      onChange={e => handleLandInputChange(e, row)}
                                      value={land.MaterialID}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      margin="normal"
                                      variant="outlined"
                                      label="Sub Category"
                                      select
                                      disabled={disable}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {land.material && land.material.length > 0 && land.material.map((sub, index) => {
                                        return <MenuItem value={sub.SubCategoryId} onClick={() => getParentSubCategory(sub, row)}>{sub.SubCategoryName}</MenuItem>
                                      })}
                                    </TextField>

                                  </TableCell>
                                  {(navigationData.CategoryID == 10 || navigationData.CategoryID == 11 || navigationData.CategoryID == 12 || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 10)
                                    || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 11) || ((navigationData.IndentItems && navigationData.IndentItems.length > 0) && navigationData.IndentItems[0].ItemID == 12)) ?
                                    <TableCell>
                                      <TextField
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="SubMaterialID"
                                        onChange={e => handleLandInputChange(e, row)}
                                        value={land.SubMaterialID}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        label="Sub Material"
                                        select
                                        disabled={disable}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {land.submaterial && land.submaterial.length > 0 && land.submaterial.map((sub, index) => {
                                          return <MenuItem value={sub.SubCategoryId}>{sub.SubCategoryName}</MenuItem>
                                        })}
                                      </TextField>

                                    </TableCell>
                                    : null}
                                  <TableCell className={classes.wordbreak}>

                                    <TextField

                                      helperText=""
                                      label="Quantity"
                                      name="Quantity"
                                      required
                                      // onChange={e => handleLandInputChange(e, row)}
                                      onChange={e => handleLandInputNumberChange(e, row)}

                                      InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                      value={land.Quantity}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      margin="normal"
                                      variant="outlined"
                                      disabled={disable}
                                    />



                                  </TableCell>

                                  <TableCell>
                                    
                                    <TextField

                                      helperText=""
                                      label="Brand"
                                      name="Brand"
                                      type="text"
                                      onChange={e =>
                                         handleLandInputChange(e, row)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={land.Brand}
                                      required
                                      margin="normal"
                                      variant="outlined"
                                      disabled={disable}
                                      inputProps={{maxLength:10}}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField

                                      helperText=""
                                      label="Estimated Price"
                                      name="EstimatedPrice"
                                      onChange={e => handleLandInputChange(e, row)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={land.EstimatedPrice}
                                      required
                                      margin="normal"
                                      variant="outlined"
                                      disabled={disable}
                                      inputProps={{maxLength:10}}

                                    />
                                  </TableCell>

                                  <TableCell>
                                    <TextField

                                      helperText=""
                                      label="Description"
                                      name="Purpose"
                                      onChange={e => handleLandInputChange(e, row)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={land.Purpose}
                                      required
                                      margin="normal"
                                      variant="outlined"
                                      disabled={disable}
                                    />
                                  </TableCell>
                                  {/* {navigationData.flag == 'Bidding' && flagVendor=='vendor' ? */}
                                  {navigationData.flag == 'Bidding' && row == editRow ?

                                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100px' }}>
                                      <InputLabel >Vendors</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        onChange={(e) => {
                                          let p = process;
                                          p.VendorID = e.target.value
                                          setProcess({
                                            ...p
                                          })
                                        }}
                                        value={process.VendorID}
                                      >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {allVendors && allVendors.map(x => {
                                          return <MenuItem key={x.VendorID} value={x.VendorID}>{x.VendorName}</MenuItem>
                                        })}

                                      </Select>

                                    </FormControl>
                                    : null
                                    //  <TableCell>
                                    //   {land.VendorName ? land.VendorName : "-"}
                                    // </TableCell>
                                  }
                                  {navigationData.flag == 'Bidding' && row != editRow ?
                                    <TableCell>
                                      {land.VendorName ? land.VendorName : "-"}
                                    </TableCell>
                                    : null}
                                  <TableCell>
                                    {land.ProductDeliveryStatusName}
                                  </TableCell>
                                  {navigationData.flag == 'Bidding' ?

                                    <TableCell>
                                      {row == editRow ?
                                        <SaveIcon onClick={() => saveIndentVendor(row, land)} />
                                        : null}
                                      {navigationData.IndentStatusName != 'Open' ?
                                        <>
                                          <EditIcon onClick={() => handleClickOpenVendor(row, land, 'vendor')} />
                                        </>
                                        : <EditIcon disable />}
                                    </TableCell>
                                    : <TableCell>
                                      {navigationData.flag == 'Inventory' ?
                                        <>
                                          {navigationData.IndentStatusName != 'Open' ?
                                            <>
                                              <EditIcon onClick={() => handleClickOpen(row, land)} />
                                            </>
                                            : <EditIcon disable />}
                                        </>
                                        :
                                        <DeleteForeverIcon onClick={() => handleLandRemoveClick(row, land)} />

                                      }

                                    </TableCell>}
                                </TableRow>
                                <br></br>
                              </>
                            ))}
                          </TableBody>
                          : <><span style={{ color: "orange" }}>Please add items</span></>}
                      </Table>
                    </TableContainer>
                  </Box>
                </>
                : null}

              {getloggedInData()?.role == 'Admin' || getloggedInData().role == 'Project Manager' ?
                <>
                  {navigationData.flag != 'Bidding' ?
                    <Box mt={3} style={{
                      backgroundColor: '#fff',
                      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                    }}>
                      {navigationData.CreateIndent != 'New' ?
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={2} style={{ marginLeft: '15px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginTop: "26px" }}>
                              Update Status
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginLeft: "-5%" }}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={indentStatus || indent.IndentStatus}
                                  onChange={handleChangeIndent}
                                >
                                  <MenuItem value="">
                                  </MenuItem>
                                  {statuAction && statuAction.map(x => {
                                    return <MenuItem key={x.SubCategoryId} value={x.SubCategoryId}>{x.SubCategoryName}</MenuItem>
                                  })}

                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} style={{ marginLeft: '53px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginTop: "26px" }}>
                              &nbsp;
                            </Grid>
                            <Grid item xs={12} sm={7} id="note" style={{ color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <TextField
                                  disabled={indentStatus === indent.IndentStatus || !indentStatus}
                                  id="outlined-basic"
                                  value={indent.Note}
                                  className="note" fullWidth label="Note" variant="outlined"
                                  onChange={(e) => {
                                    setIndent({
                                      ...indent,
                                      Note: e.target.value
                                    })
                                  }} />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{
                              color: '#3f51b5', fontSize: '17px', fontWeight: 'bold',
                              textAlign: 'right', marginTop: "15px", marginLeft: "-20%"
                            }}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Button

                                  disabled={indentStatus === indent.IndentStatus || !indentStatus}
                                  variant="contained" color="primary" onClick={updateIndentStatus}>Save</Button>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                        : null}
                    </Box>
                    : null}
                </>
                : null}

            </div>
          </Container>
        </Page>
      </DialogContent>
      {disable == false ?
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {gotoindtent == true ?
            <>
              {update ?
                <Button className={classes.button2}
                  onClick={updateClick}
                >
                  Update
                </Button>

                :
                <>
                  <Button className={classes.button2}
                    onClick={handleIndentAddClick}>
                    Save
                  </Button>
                  <Button onClick={Close} className={classes.button2}>
                    Cancel
                  </Button>
                </>}
            </>
            : null}
          {/* {!update ?
        
        :null} */}

        </DialogActions>
        : null}

      <div>


        <Dialog aria-labelledby="customized-dialog-title" open={openTest} id="income">
          <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
            <CircularProgress style={{ color: '#fff' }} />
          </Backdrop>
          <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
            Indent Management
          </DialogTitle>
          <DialogContent dividers>
            {/* <div className={classes.root}> */}
            <div style={{ marginLeft: "0%" }} >
              <Box mt={3} style={{
                backgroundColor: '#fff',
                boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                borderRadius: '5px',
                padding: '20px'
              }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Subcategory"
                      name="subcategory"
                      onChange=""
                      required
                      disabled
                      value={process.MaterialName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Quantity"
                      name="quantity"
                      onChange=""
                      required
                      disabled
                      value={process.Quantity}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Description"
                      name="description"
                      onChange=""
                      required
                      disabled
                      value={process.Purpose}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >

                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '320px' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          let p = process;
                          p.VendorID = e.target.value
                          setProcess({
                            ...p
                          })
                        }}
                        value={process.VendorID}
                        disabled={vendorflag}
                      >
                        <MenuItem value="">
                        </MenuItem>
                        {allVendors && allVendors.map(x => {
                          return <MenuItem key={x.VendorID} value={x.VendorID}>{x.VendorName}</MenuItem>
                        })}

                      </Select>

                    </FormControl>

                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >

                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="PurchaseOrderID"
                      name="PurchaseOrderID"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.PurchaseOrderID = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.PurchaseOrderID}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="InvoiceID"
                      name="InvoiceID"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.InvoiceID = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.InvoiceID}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="IncomeTax"
                      name="IncomeTax"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.IncomeTax = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.IncomeTax}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Amount"
                      name="Amount"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.Amount = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.Amount}
                      variant="outlined"
                    />

                  </Grid>


                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="CGST"
                      name="CGST"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.CGST = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.CGST}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="IGST"
                      name="IGST"
                      onChange={(e) => {
                        let p = process;
                        p.IGST = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      required
                      value={process.IGST}
                      variant="outlined"
                    />
                  </Grid>


                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="SGST"
                      name="SGST"
                      required
                      onChange={(e) => {
                        let p = process;
                        p.SGST = e.target.value
                        setProcess({
                          ...p
                        })
                      }}
                      value={process.SGST}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >

                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '320px' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth

                        value={process.ProductDeliveryStatus}
                        onChange={(e) => {
                          let p = process;
                          p.ProductDeliveryStatus = e.target.value
                          setProcess({
                            ...p
                          })
                        }}
                      >
                        <MenuItem value="">
                        </MenuItem>
                        {statuActionForm && statuActionForm.length != 0 && statuActionForm.map(x => {
                          return <MenuItem key={x.SubCategoryId} value={x.SubCategoryId}>{x.SubCategoryName}</MenuItem>
                        })}
                      </Select>

                    </FormControl>
                  </Grid>
                  {process.ProductDeliveryStatus == 4122 &&
                    <>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          helperText=""
                          label="Delivery Challan"
                          name="Delivery"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          variant="outlined"
                          className={classes.input}
                          type="file"
                          onChange={(e) => handleCapture(e)}
                        // value={process.DeliveryChallan}
                        />
                        <Link href={`https://amz-dev.ap-south-1.linodeobjects.com/${process.DeliveryChallan}`} target="_blank">{process.DeliveryChallan ? process.DeliveryChallan : ""}</Link>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          type="file"
                          fullWidth
                          helperText=""
                          label="Invoice Attach"
                          name="Invoice"
                          // value={process.InvoiceAttachment}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => handleCapture(e)}
                          required
                          variant="outlined"
                          className={classes.input}

                        />
                        <Link href={`https://amz-dev.ap-south-1.linodeobjects.com/${process.InvoiceAttachment}`} target="_blank">{process.InvoiceAttachment ? process.InvoiceAttachment : ''}</Link>
                      </Grid>
                    </>
                  }
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >


                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          InputProps={{
                            // disableUnderline: true,
                          }}
                          disablePast="true"
                          // minDate={new Date()}
                          margin="normal"
                          id="date-picker-dialog"
                          label="Select Date"
                          format='dd/MM/yyyy'
                          name="ProductDeliveredDate"
                          onChange={(e) => {
                            let p = process;
                            p.ProductDeliveredDate = e
                            setProcess({
                              ...p
                            })
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          value={process.ProductDeliveredDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          className={classes.calmar}
                          error={false}
                          helperText={null}
                        />

                      </Grid>
                    </MuiPickersUtilsProvider>

                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >

                  </Grid>
                  {/* {value == 'upload' ?
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        type="file"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText=""
                        label="Upload Signature"
                        name="Signature"
                        onChange=""
                        required
                        className={classes.input}
                        variant="outlined"
                        onChange={(e) => handleCapture(e)}
                      />
                      <Link href={`https://amz-dev.ap-south-1.linodeobjects.com/${process.SignatureAttachment}`} target="_blank">{process.SignatureAttachment ? process.SignatureAttachment : ""}</Link>

                    </Grid>
                    :
                    <>
                      <SignatureCanvas
                        penColor="black"
                        backgroundColor="white"
                        canvasProps={{ width: 500, height: 200, border: "1px sold #000", className: "signatureCanvas", }}
                        ref={sigPad}
                      />
                      <br></br><br></br>
                      <Button onClick={sigPad => trim(formData, changeFormData)}>Trim</Button>
                      <Button onClick={() => checkState(formData)}>CHECK STATE</Button>

                    </>
                  } */}

                </Grid>
              </Box>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

            <Button onClick={handleCloseModal} className={classes.button2}>
              Cancel
            </Button>
            {navigationData.IsClosed == false ?

              <Button onClick={saveIndent} className={classes.button2}>
                Save
              </Button>

              : null}

          </DialogActions>

        </Dialog>


        {/* Alert Vendor */}
        <Dialog aria-labelledby="customized-dialog-title" open={openAlertVendor} id="income">
          <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
            <CircularProgress style={{ color: '#fff' }} />
          </Backdrop>
          <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
            Alert Vendor
          </DialogTitle>
          <DialogContent dividers>
            {/* <div className={classes.root}> */}
            <div style={{ marginLeft: "0%" }} >
              <Box mt={3} style={{
                backgroundColor: '#fff',
                boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                borderRadius: '5px',
                padding: '20px'
              }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Subcategory"
                      name="subcategory"
                      onChange=""
                      required
                      disabled
                      value={process.MaterialName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Quantity"
                      name="quantity"
                      onChange=""
                      required
                      disabled
                      value={process.Quantity}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="text"
                      fullWidth
                      helperText=""
                      label="Description"
                      name="description"
                      onChange=""
                      required
                      disabled
                      value={process.Purpose}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                  >

                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '320px' }}>
                      <InputLabel >Vendors</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          let p = process;
                          p.VendorID = e.target.value
                          setProcess({
                            ...p
                          })
                        }}
                        value={process.VendorID}
                      >
                        <MenuItem value="">
                        </MenuItem>
                        {allVendors && allVendors.map(x => {
                          return <MenuItem key={x.VendorID} value={x.VendorID}>{x.VendorName}</MenuItem>
                        })}

                      </Select>

                    </FormControl>

                  </Grid>

                </Grid>
              </Box>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>

            <Button onClick={handleCloseModalVendor} className={classes.button2}>
              Cancel
            </Button>
            {navigationData.IsClosed == false ?

              <Button onClick={saveIndentVendor} className={classes.button2}>
                Save
              </Button>

              : null}

          </DialogActions>

        </Dialog>


      </div>

    </div>
  )

}
export default Indent;
