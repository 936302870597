import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  TableContainer,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import data from './data';
import Page from 'src/components/Page';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { MESSAGE } from 'src/utils/message';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';

import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import { RGCA_URLS } from '../../utils/url';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import { useConfirm } from "material-ui-confirm";
import Grid from '@material-ui/core/Grid';
import './style.css';
import Loader from '../loader'
import { useToasts } from 'react-toast-notifications';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  centertext:{
    marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
    '@media (max-width: 500px)': {
      marginLeft:"120px"
    },
    '@media (max-width: 350px)': {
      marginLeft:"70px"
    },
  },
  container:{
  
      maxHeight: '100%',
      '@media (max-width: 500px)': {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    },
  

}));

const Designation = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [allDesignation, setAllDesignations] = useState([])
  const [addDesignation, setAddDesination] = useState({ "name": "" })
  const [btn, setBtn] = React.useState({ "name": "Save" });

  useEffect(() => {
    getAllDesignation();
  }, [])


  const getAllDesignation = () => {

    APIKit.get(RGCA_URLS.getDesignations)
      .then((res) => {

        if (res.data.status === 200) {
          setAllDesignations(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }

  const addDesignations = () => {

    setIsLoading(true);
    APIKit.post(RGCA_URLS.addDesignation, {
      "name": addDesignation.name.trim()
    })//optional} addDesignation)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          getAllDesignation();
          //addToast(MESSAGE.designationAdded, { appearance: 'success' });
          toast.success(MESSAGE.designationAdded, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setAddDesination({
            "name": ""
          })
        }
        else {
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.designationNotAdded, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }
  const cancelDesignation = () => {
    setAddDesination({
      "name": ""
    })
    setBtn({ "name": "Save" })
  }

  const remove = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        deletedesignation(data)
      })
      .catch(() => console.log("Deletion cancelled."));

  }

  const deletedesignation = (data) => {
    setIsLoading(true);
    setAddDesination({
      "name": ""
    })
    APIKit.delete(RGCA_URLS.deleteDesignation + '/' + data.designationID)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(MESSAGE.designationDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setBtn({ "name": "Save" })
          getAllDesignation()
        }
        else {
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.designationNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })

        }
      })
      .catch(function (e) {
      })
  }

  const edit = (data) => {
    setBtn({ "name": "Update" })
    setAddDesination(
      { "designationID": data.designationID, "name": data.name }
    )
  }

  const updateDesignation = (data) => {
    const payload = {
      ...addDesignation
    }
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateDesignation, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setAddDesination(
            { "designationID": "", "name": '' }
          )
          setIsLoading(false);
          toast.success(MESSAGE.designationUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setBtn({ "name": "Save" })
          getAllDesignation()
        }
        else {
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.designationNotUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noNumbers:/^[^0-9()]+$/

  };
  return (
    <Page
      className={classes.root}
      title="Designation">
      <ToastContainer />
      <Container maxWidth={false}>
        <Loader isLoading={isLoading} />
        <div>

          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className={classes.centertext}>
                Designation
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

              </Grid>
            </Grid>

          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box >
                  <Grid container>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        helperText=""
                        label="Designation Name"
                        name="name"
                        autoComplete="off"
                        inputProps={{maxLength:20}}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.noNumbers.test(e.target.value)) {
                            let p = addDesignation;
                            p.name = e.target.value;
                            // p.name = e.target.value;
                            setAddDesination({
                              ...p
                            })
                          }
                        }}
                        value={addDesignation.name}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    ></Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {btn.name == 'Save' &&
                          <Button
                            color="primary"
                            variant="contained" style={{ marginTop: '14px' }}
                            onClick={addDesignations}
                            disabled={/^\s*\S[\s\S]*$/g.test(addDesignation.name) == ""}
                          >
                            Add
                          </Button>
                        }
                        {btn.name == 'Update' &&
                          <div>
                            <Button
                              color="primary"
                              variant="contained" style={{ marginTop: '14px' }}
                              onClick={updateDesignation}
                              disabled={/^\s*\S[\s\S]*$/g.test(addDesignation.name) == ""}
                            >
                              Update
                            </Button>
                            <Button
                              color="primary"
                              variant="contained" style={{ marginTop: '14px', marginLeft: '4px' }}

                              onClick={cancelDesignation}
                            >
                              Cancel
                            </Button>

                          </div>
                        }

                      </Box>
                    </Grid>


                  </Grid>

                </Box>
              </CardContent>
            </Card>
          </Box>


        </div>
        <Box mt={3}>
        <TableContainer  className={classes.container}>
          <Table className={classes.table}>
            <TableHead>


              <TableRow>

                <TableCell >
                  S.No
                </TableCell>
                <TableCell >
                  Designation
                </TableCell>

                <TableCell >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDesignation && allDesignation.length != 0 && allDesignation.sort((a, b) => a.name.localeCompare(b.name))&&
              allDesignation.map((des, index) => {
                return <TableRow
                  hover
                  key={des.DesignationID}
                >

                  <TableCell >
                    {index + 1}
                  </TableCell>
                  <TableCell >
                    {des.name}
                  </TableCell>

                  <TableCell style={{ textAlign: 'left', width: '20%' }}>
                    <EditOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => edit(des)} />
                    <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={() => remove(des)} />
                  </TableCell>
                </TableRow>
              })}

            </TableBody>
          </Table>
          </TableContainer>
        </Box>


      </Container>
    </Page>
  )

}
export default Designation;
