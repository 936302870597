import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { currencyFormat1, dateOnly, dateOnlyRev, getProjectDetails, parseData, precision } from '../Common/utilities';
import Tooltip from '@material-ui/core/Tooltip';
import LabTestServiceForm from './labtestseviceForm';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const LabTestServices = ({ packagedData }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLabTestServ, setIsLabTestServ] = useState(false);
    const [servicePayload,setServicePayload] =useState({
        serviceName:'',
        package:'',
        amount:'',
        description:''
    })

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} sm={12} xs={12}>
                    <Typography className={classes.head}>
                        <h2>Lab Test Services</h2>
                    </Typography>
                </Grid>
                <Grid item xl={8} lg={8} sm={12} xs={12} style={{ textAlign: 'end' }}>

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setIsLabTestServ(true)
                        }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell>S.No</TableCell>
                                    {/* <TableCell>Package ID</TableCell> */}
                                    <TableCell>Name of Test</TableCell>
                                    <TableCell>Packages</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packagedData && packagedData.length != 0 ? (
                                    packagedData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{index + 1}</TableCell>
                                                {/* <TableCell>{x?.packageMasterID}</TableCell> */}
                                                <TableCell>{x?.TestServiceName}</TableCell>
                                                <TableCell>{x?.packages}</TableCell>
                                                <TableCell>{x?.ServiceFee}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={x?.Description}>
                                                        <span>
                                                            {x?.Description ? x?.Description.length > 30 ? `${x?.Description.slice(0, 30)} ...` : x?.Description : '-'}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <EditIcon />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>{' '}
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {packagedData && packagedData?.length != 0 && packagedData?.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={packagedData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>



            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isLabTestServ}
                contentStyle={{ width: '200%', maxWidth: 'none' }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{ backgroundColor: '#394671', color: '#fff', width: '900px !important' }}
                >
                    Lab Testing Service
                </DialogTitle>

                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        serviceName:'',
                        package:'',
                        amount:'',
                        description:''
                    }}
                    validationSchema={Yup.object().shape({

                    })}
                    onSubmit={(values, { resetForm }) => {

                    }}

                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, resetForm
                    }) => (
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <DialogContent dividers style={{ width: '900px !important' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={4} sm={6} xl={6} xs={6} >
                                        <TextField
                                            label="Service Name"
                                            name="batchNo"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={6} xs={6} >
                                        <TextField
                                            label="Package"
                                            name="batchNo"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={4} sm={6} xl={6} xs={6} >
                                        <TextField
                                            label="Amount"
                                            name="batchNo"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={12} sm={6} xl={6} xs={6} >
                                        <TextField
                                            label="Description"
                                            name="batchNo"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                                <Button onClick={(e => { setIsLabTestServ(false) })}>Cancel</Button>
                                <Button type="submit">Save</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>

                {/* <LabTestServiceForm setIsLabTestServ={setIsLabTestServ} /> */}
            </Dialog>
        </>
    )
}

export default LabTestServices