import * as React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#3f51b5',
  },


}));

export default function Loader({ isLoading }) {
  // console.log(props);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: '#3f51b5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
