import React, { useState, useEffect } from 'react';
import {
  Box, Card, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, TextField, Divider, Select, InputLabel, FormControl, MenuItem, FormHelperText
} from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Loader from '../../loader';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'react-toast-notifications';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from "react-router-dom";
import { MESSAGE } from 'src/utils/message';
import { RGCA_URLS } from '../../../utils/url';
import { currencyFormat1, dateOnlyRev, getProjectDetails } from '../Common/utilities';
import DateFnsUtils from '@date-io/date-fns';
import HistoryIcon from '@material-ui/icons/History';
import { dateForm } from './../Common/utilities';
import moment from 'moment';
import ProductionHistory from './productionHistory.json'
import UpdateIcon from '@material-ui/icons/Update';
import AppBar from '@material-ui/core/AppBar';
import EditSales from './EditSales';
import EditProductionHistory from './editProductionHistory';
import { checkPermission } from 'src/utils/permission';
import VisibilityIcon from '@material-ui/icons/Visibility';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '0px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '0px'
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Sales = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const confirm = useConfirm();
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  const [productionHistory, setProductionHistory] = React.useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [getItemsData, setGetItemsData] = useState([]);
  const [prodHis, setProdHis] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [categoryList, setCategoryList] = useState([]);
  const [errorMeg, setErrorMeg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const userData = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const [openTest, setOpenTest] = React.useState(false);
  const [itemName, setItemName] = React.useState({
    name: '',
    totalStock: '',
    production: '',
    mortality: ''
  });
  const [scroll, setScroll] = React.useState('paper');

  const [btn, setBtn] = useState(false);
  const openSales = () => {
    setBtn(false);
    // setOpenTest(false);
    setOpenTest(true);
  };

  const [salesItem, setSalesItem] = useState(
    {
      typeID: '',
      name: '',
      gstTaxability: '',
      gstRate: 0,
      rpu: 0,
      uom: '',
      hssn: '',
      stock: '',
      description: '',
      rca: '',
      categoryID: null,
      productionDate: null,
      batchNo: ''
    }

  );
  const [addCategory, setAddCategory] = useState({
    typeID: null,
    name: '',
    projectID: projectDetails.projectID,
    description: 'hi',
  });
  const [allVendors, setAllVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesHistroy, setSalesHistroy] = useState(false)
  const [value, setValue] = React.useState(0);
  const [isEdit, setIsEdit] = useState(false)
  const [isViewprod, setIsViewProd] = useState(false)
  const [viewProdData, setIsViewProdData] = useState({})


  const handleChangeTab = (_event, newValue) => {
    setValue(newValue);
  };
  const handleCloseModal = (resetForm = null) => {
    setAddCategory({
      typeID: '',
      name: ''
    })
    setOpenSubCategory(false)
    if (resetForm) {
      resetForm();
    }
  };
  const closeSales = (resetForm = null) => {
    setOpenTest(false)
    if (resetForm) {
      resetForm();
    }
  }
  useEffect(() => {
    getItemsList();
    getLookup();
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/16`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  const getCategories = (type) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getSalesCategory, {

      typeID: type,
      projectID: projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryList(res.data.data);
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'success' });
        } else {
          // toast.error(res.data.message);
          setIsLoading(false);
          //addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch((e) => {
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };
  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const saveVendor = () => {
    APIKit.post(`/${APP_URLS.addVendor}`, salesItem,
      { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${getloggedInData()?.accessToken}` } })
      .then((res) => {
        if (res.data.status === 200) {
          getItemsList();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setSalesItem({
            typeID: '',
            name: '',
            gstTaxability: '',
            gstRate: '',
            rpu: 0,
            uom: '',
            hssn: '',
            stock: '',
            description: '',
            rca: '',
            categoryID: null,
            batchNo: ''
          });
          handleCloseModal();
        } else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch((e) => {
      });
  };
  const cancelSalesItem = (resetForm = null) => {
    setBtn(false);
    setOpenTest(false)
    setSalesItem({
      typeID: '',
      name: '',
      gstTaxability: '',
      gstRate: '',
      rpu: 0,
      uom: '',
      hssn: '',
      stock: '',
      description: '',
      rca: '',
      categoryID: null,
      productionDate: null,
      batchNo: ''

    });
    if (resetForm) {
      resetForm();
    }
  }
  const updateSalesItem = () => {
    const url = `${RGCA_URLS.updateSalesItem}`;
    APIKit.put(url, salesItem)
      .then((res) => {
        if (res.data.status === 200) {
          getItemsList();
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          setBtn(false);
          setSalesItem({
            typeID: '',
            name: '',
            gstTaxability: '',
            gstRate: '',
            rpu: 0,
            uom: '',
            hssn: '',
            stock: '',
            description: '',
            rca: '',
            categoryID: null,
            batchNo: ""
          });
          handleCloseModal();
        } else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch((e) => {
      });
  };

  const getItemsList = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          setGetItemsData(res.data.data);
          setIsLoading(false)

        } else {
          // toast.error(res.data.message);
          setIsLoading(false)

        }
      })
      .catch((e) => {
      });
  };
  const removeVendor = (data) => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        remove(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const editItem = (data) => {
    console.log("fffffff", data.gstTaxability);
    console.log("data", data);

    getCategories(data.typeID);
    // setBtn(true);
    // setOpenTest(true)
    setIsEdit(true)
    setSalesItem({
      ...salesItem,
      itemID: data.itemID,
      typeID: data.typeID,
      name: data.name,
      gstTaxability: data.gstTaxability,
      gstRate: data.gstRate.toString(),
      rpu: data.rpu,
      uom: data.uom,
      hssn: data.hssn,
      stock: data.stock,
      batchNo: data.batchNo,
      description: data.description,
      rca: data.rca,
      categoryID: data.categoryID,
      dateOfBirth: data.dateOfBirth,
      productionDate: data.productionDate ? moment(data?.productionDate).format("YYYY-MM-DD") : null
    });
  };
  const searchVendor = (e) => {
    if (e) {
      APIKit.post(`/${APP_URLS.searchVendor}`, { Name: e },
        { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${getloggedInData()?.accessToken}` } })
        .then((res) => {
          if (res.data.status === 200) {
            // setAlertMeg('success');
            // setErrorMeg(res.data.message);
            setAlertOpen(true);
            setAllVendors(res.data.data);
          } else {
            // setAlertMeg('error');
            // setErrorMeg(res.data.message);
            // setAlertOpen(true);
          }
        })
        .catch((e) => {
        });
    } else {
      getItemsList();
    }
  };

  // const editVendor = (data) => {
  //   setSalesItem(data);
  //   setBtn(true);
  //   setOpenTest(true);
  // };

  const remove = (data) => {
    APIKit.delete(`${RGCA_URLS.deleteSalesItem}/${data.itemID}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.salesItemDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getItemsList();
        } else {
          toast.error(MESSAGE.salesItemNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
      });
  };
  const openSubModal = () => {
    setOpenSubCategory(true);
  };
  const closeModal = () => {
    setOpenSubCategory(false);
  };


  const history = (data) => {
    setItemName({
      name: data?.name,
      totalStock: data?.stock,
      production: data?.totalProductionCount,
      mortality: data?.totalMortalityCount
    })

    const payload = {
      itemID: data.itemID,
      projectID: projectDetails.projectID,
      batchNo: data?.batchNo
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.listProduction, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setProductionHistory(true)
          setProdHis(res.data.data);
          setIsLoading(false);

        } else {
          setIsLoading(false);
          setProductionHistory(true)

        }
      })
      .catch((e) => {
        setIsLoading(false);
        setProductionHistory(true)

      });
  }


  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })


  const a11yProps = index => {
    if (index == 0) {
      getItemsList()
      // getInventoryList();
    }
    if (index == 1) {
      // getMaterialsForSelling();
    }
    if (index == 2) {
      // getPaymentList();
    }
    // if (index == 3) {
    //   getPaymentList()
    // }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const viewProdVlaues = (prodData) => {
    setIsViewProd(true)
    setIsViewProdData(prodData)
  }

  console.log("viewProdData", viewProdData);
  return (
    <Page
      className={classes.root}
      title="Production"
    >
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Container maxWidth={false}>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity={alertMeg}>
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                }}
              >
                {/* Production Details */}
                <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Production Details" onClick={() => a11yProps(0)} />
                    {/* {(checkPermission("view&edit_Sales") || userData?.isAdmin == 1) && ( */}
                    {(checkPermission("view_Sales") || userData?.isAdmin == 1) && (
                      <Tab label="Edit Production" onClick={() => a11yProps(0)} />)}
                  </Tabs>
                </AppBar>

              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openSales}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>

          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...salesItem
            }}
            validationSchema={
              Yup.object().shape({
                typeID: Yup.string().required(MESSAGE.type),
                // categoryID: Yup.string().required(MESSAGE.category),
                name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                hssn: Yup.string().required(MESSAGE.hssn),
                batchNo: Yup.string().required('Batch No. Required'),
                uom: Yup.string().required(MESSAGE.uom).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                // rpu: Yup.number().required(MESSAGE.rpu),
                gstTaxability: Yup.string().required(MESSAGE.gst),
                stock: Yup.number().required(MESSAGE.stocks),
                description: Yup.string().required(MESSAGE.itemDescription).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                rca: Yup.string().required(MESSAGE.rca),
                productionDate: Yup.date().required("Date Of Joining is required")
                // dateOfBirth: Yup.string().required(MESSAGE.dateOfBirth)
              })

            }
            onSubmit={(values, { resetForm }) => {
              console.log("valuesbbbbb", values)
              setIsLoading(true)
              if (btn) {
                const url = `${RGCA_URLS.updateSalesItem}`;
                const payload = {
                  ...salesItem,
                  // stock: Number(salesItem.stock1 ?? 0),
                  rpu: Number(salesItem.rpu),
                  gstRate: String(salesItem.gstRate),
                  projectID: projectDetails.projectID
                }
                // delete payload.stock1
                // console.log("pppppp",payload),
                APIKit.put(url, payload)
                  .then((res) => {
                    if (res.data.status === 200) {
                      setIsLoading(false)

                      getItemsList();
                      toast.success(MESSAGE.salesItemUpdate, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      setBtn(false);
                      setOpenTest(false)
                      setSalesItem({
                        typeID: '',
                        name: '',
                        gstTaxability: '',
                        gstRate: '',
                        rpu: 0,
                        uom: '',
                        hssn: '',
                        stock: '',
                        description: '',
                        batchNo: '',
                        rca: '',
                        categoryID: null,
                        productionDate: null
                        // stock1: ''
                      });
                      handleCloseModal();
                    } else {
                      setIsLoading(false)

                      toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    }
                  })
                  .catch((e) => {
                  });
              } else {
                const payload = {
                  ...salesItem,
                  projectID: projectDetails.projectID
                }
                setIsLoading(true);
                APIKit.post(RGCA_URLS.createSalesItem, payload)
                  .then((res) => {
                    if (res.data.status === 200) {
                      setTimeout(() => {
                        setIsLoading(false);
                        toast.success(MESSAGE.salesItemAdded, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        })
                        setOpenTest(false)
                        getItemsList();
                        resetForm(values);
                        setSalesItem({
                          typeID: '',
                          name: '',
                          gstTaxability: '',
                          gstRate: '',
                          rpu: 0,
                          uom: '',
                          hssn: '',
                          stock: '',
                          description: '',
                          rca: '',
                          categoryID: null,
                          batchNo: '',
                          productionDate: null

                        });
                      }, 3000);
                    } else {
                      setIsLoading(false);
                      toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                  });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              handleReset,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <MuiThemeProvider theme={formLabelsTheme}>
                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={openTest}
                  id="income"
                  contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                  }}
                  maxWidth="lg"
                  fullWidth
                >
                  <Loader isLoading={isLoading} />
                  <DialogTitle id="scroll-dialog-title" onClose={(e => {
                    cancelSalesItem(resetForm)
                  })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                    {/* {btn ? 'Edit' : 'Add'} */} Add Production Item
                  </DialogTitle>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                  }}>
                    <DialogContent dividers={scroll === 'paper'}>
                      <Box mt={3} style={{
                        backgroundColor: '#fff',
                        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                      }}>

                        <Grid
                          container
                          spacing={3}
                        >

                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <FormControl
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.formControl}
                              error={Boolean(touched.typeID && errors.typeID)}
                            >
                              <InputLabel required id="demo-simple-select-outlined-label">Product Item</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={salesItem.typeID}
                                // disabled={btn}
                                name="typeID"
                                label="Product Item"
                                required
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.typeID = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    typeID: e.target.value
                                  });
                                  getCategories(e.target.value);
                                }}
                              >
                                {lookupData && lookupData.map((x) => {
                                  return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                                })}
                              </Select>
                              <FormHelperText>{(errors.typeID && touched.typeID) && errors.typeID}</FormHelperText>
                            </FormControl>

                          </Grid>
                          {/* <Grid item md={4} xs={12}>
                            <FormControl
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.formControl}
                              error={Boolean(touched.categoryID && errors.categoryID)}
                            >
                              <InputLabel required id="demo-simple-select-outlined-label">Category</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={salesItem.categoryID}
                                label="Category"
                                name="categoryID"
                                required
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.categoryID = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    categoryID: e.target.value
                                  });
                                }}
                                disabled={!categoryList || categoryList.length === 0}
                              >
                                {categoryList && categoryList.map((x) => {
                                  return <MenuItem value={x.categoryID} key={x.categoryID}>{x.name}</MenuItem>;
                                })}
                              </Select>
                              <FormHelperText>{(errors.categoryID && touched.categoryID) && errors.categoryID}</FormHelperText>
                              <a style={{ color: 'blue', cursor: 'pointer' }} onClick={openSubModal}>+ Add Category</a>
                            </FormControl>
                          </Grid> */}

                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Name                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         "
                              name="name"
                              value={salesItem.name}
                              // disabled={btn}
                              inputProps={{ maxLength: 20 }}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                // if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.name = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    name: e.target.value
                                  });
                                // }
                              }
                              }
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              required
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.hssn = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    hssn: e.target.value
                                  });
                                }
                              }}
                              error={Boolean(touched.hssn && errors.hssn)}
                              helperText={touched.hssn && errors.hssn}
                              label="HSSN / SAC Code"
                              name="hssn"
                              value={salesItem.hssn}
                              required
                              variant="outlined"
                              disabled={btn}
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              label="Batch No"
                              name="batchNo"
                              onChange={(e) => {
                                handleChange(e);
                                const p = salesItem;
                                p.batchNo = e.target.value;
                                setSalesItem({
                                  ...p,
                                  batchNo: e.target.value
                                });
                              }}
                              variant="outlined"
                              value={salesItem.batchNo}
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              onBlur={handleBlur}
                              value={salesItem.uom}
                              onChange={(e) => {
                                if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.uom = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    uom: e.target.value
                                  });
                                }
                              }}
                              error={Boolean(touched.uom && errors.uom)}
                              helperText={touched.uom && errors.uom}
                              label="Unit of Measurement"
                              name="uom"
                              required
                              variant="outlined"
                            // disabled={btn}
                            />
                          </Grid>
                          {/* <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 9 }}
                              fullWidth
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (e.target.value === '' || /^[0-9.]*$/.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.rpu = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    rpu: e.target.value
                                  });
                                }
                              }}
                              value={salesItem.rpu}
                              error={Boolean(touched.rpu && errors.rpu)}
                              helperText={touched.rpu && errors.rpu}
                              label="Rate per Unit"
                              name="rpu"
                              // required
                              variant="outlined"
                            />
                          </Grid> */}
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <FormControl
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.formControl}
                              error={Boolean(touched.gstTaxability && errors.gstTaxability)}
                            >
                              <InputLabel required id="demo-simple-select-outlined-label">GST Taxability</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={salesItem.gstTaxability}
                                name="gstTaxability"
                                label="GST Taxability"
                                onBlur={handleBlur}
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.value === 'true') {
                                    console.log(salesItem);
                                    const p = salesItem;
                                    p.gstTaxability = true;
                                    setSalesItem({
                                      ...p,
                                      gstTaxability: true
                                    });
                                  } else {
                                    const p = salesItem;
                                    p.gstTaxability = false;
                                    setSalesItem({
                                      ...p,
                                      gstTaxability: false
                                    });
                                  }
                                }}
                              >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                              </Select>
                              <FormHelperText>{(errors.gstTaxability && touched.gstTaxability) && errors.gstTaxability}</FormHelperText>
                            </FormControl>
                          </Grid>
                          {salesItem.gstTaxability === true && (
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">GST Rate(%)</InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onBlur={handleBlur}
                                  value={salesItem.gstRate}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const p = salesItem;
                                    p.gstRate = e.target.value;
                                    setSalesItem({
                                      ...p,
                                      gstRate: e.target.value
                                    });
                                  }}
                                  label="GST Rate(%)"
                                  name="gstRate"
                                  required
                                >
                                  <MenuItem value={5}>5%</MenuItem>
                                  <MenuItem value={12}>12%</MenuItem>
                                  <MenuItem value={18}>18%</MenuItem>
                                  <MenuItem value={28}>28%</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <FormControl
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.formControl}
                              error={Boolean(touched.rca && errors.rca)}
                            >
                              <InputLabel required id="demo-simple-select-outlined-label">Reverse Charge Applicability</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={salesItem.rca}
                                label="Reverse Charge Applicability"
                                name="rca"
                                required
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.value === 'true') {
                                    const p = salesItem;
                                    p.rca = true;
                                    setSalesItem({
                                      ...p,
                                      rca: true
                                    });
                                  } else {
                                    const p = salesItem;
                                    p.rca = false;
                                    setSalesItem({
                                      ...p,
                                      rca: false
                                    });
                                  }
                                }}
                              >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                              </Select>
                              <FormHelperText>{(errors.rca && touched.rca) && errors.rca}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              onBlur={handleBlur}
                              type="number"
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                              }}
                              InputProps={{
                                inputProps: { min: 1 }
                              }}
                              onKeyPress={(event) => {
                                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.stock = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    stock: e.target.value
                                  });
                                }
                              }}
                              disabled={btn}
                              value={salesItem.stock}
                              error={Boolean(touched.stock && errors.stock)}
                              helperText={touched.stock && errors.stock}
                              label="Current Available Stock"
                              name="stock"
                              required
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 50 }}
                              fullWidth
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                const p = salesItem;
                                p.description = e.target.value;
                                setSalesItem({
                                  ...p,
                                  description: e.target.value
                                });
                              }}
                              value={salesItem.description}
                              error={Boolean(touched.description && errors.description)}
                              helperText={touched.description && errors.description}
                              label="Item Description"
                              name="description"
                              required
                              variant="outlined"
                            />
                          </Grid>

                          <Divider style={{ background: 'rgb(143 152 203)', width: "100%" }} />
                          <Grid
                            item
                            md={4}
                            xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  InputProps={{ disabled: true }}
                                  inputVariant="outlined"
                                  // margin="normal"
                                  variant="outlined"
                                  id="date-picker-dialog"
                                  label="Date of Production / Grading"
                                  format="dd/MM/yyyy"
                                  // format="yyyy/MM/dd"

                                  disableFuture={true}
                                  error={Boolean(touched.productionDate && errors.productionDate)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  name="productionDate"
                                  required
                                  onChange={(e) => {

                                    console.log("ooo", e);
                                    const p = salesItem;
                                    p.productionDate = moment(e).format("YYYY-MM-DD");
                                    setSalesItem({
                                      ...p,
                                      productionDate: moment(e).format("YYYY-MM-DD")
                                    });
                                  }}
                                  // error ={Boolean(touched.productionDate && errors.productionDate)}
                                  value={salesItem.productionDate ?? null}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                  // TextFieldComponent={TextFieldComponent}
                                  className={classes.calmar}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>

                          {/* {btn ? 
                          <>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              inputProps={{ maxLength: 20 }}
                              fullWidth
                              type="number"
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                              }}
                              InputProps={{
                                inputProps: { min: 1 }
                              }}
                              onKeyPress={(event) => {
                                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                  handleChange(e);
                                  const p = salesItem;
                                  p.stock1 = e.target.value;
                                  setSalesItem({
                                    ...p,
                                    stock1: e.target.value
                                  });
                                }
                              }}
                              value={salesItem.stock1}
                          
                              label="Produced Stock"
                              name="Produced stock"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                              <TextField
                                inputProps={{ maxLength: 20 }}
                                fullWidth
                                type="number"
                                onInput={(e) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                                }}
                                InputProps={{
                                  inputProps: { min: 1 }
                                }}
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                    const p = salesItem;
                                    p.mortalityCount = e.target.value;
                                    setSalesItem({
                                      ...p,
                                      mortalityCount: e.target.value
                                    });
                                  }
                                }}
                                value={salesItem.mortalityCount}
                                label="Mortality Stock"
                                name="Mortality stock"
                                variant="outlined"
                              />
                            </Grid>
                            </> 
                            : null} */}
                        </Grid>
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                      <Button onClick={(e => {
                        cancelSalesItem(resetForm)
                      })} className={classes.button2}>
                        Cancel
                      </Button>
                      {btn
                        ? (
                          <div>
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              className={classes.button2}
                            >
                              Update
                            </Button>
                          </div>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.button2}
                          >
                            Save
                          </Button>
                        )}
                    </DialogActions>
                  </Form>

                </Dialog>
              </MuiThemeProvider>
            )}
          </Formik>
        </div>

        <TabPanel value={value} index={0}>
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="left">Type</TableCell>
                    {/* <TableCell align="left">Category</TableCell> */}
                    <TableCell align="left">Name of the item</TableCell>
                    {/* <TableCell align="left">HSSN / SAC Code</TableCell> */}
                    <TableCell align="left">Batch No</TableCell>
                    {/* <TableCell align="left">UOM</TableCell> */}
                    {/* <TableCell align="left">Rate per Unit</TableCell> */}
                    {/* <TableCell align="left">GST</TableCell> */}
                    {/* <TableCell align="left">Reverse Charge</TableCell> */}
                    {/* <TableCell align="left">GST Rate</TableCell> */}
                    <TableCell align="left">Stocks</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getItemsData && getItemsData.length != 0 ? getItemsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.itemID}>
                          <TableCell align="left">{((page) * 10) + index + 1}</TableCell>
                          <TableCell align="left">{row.typeID === 17 ? 'Product' : 'Seed'}</TableCell>
                          {/* <TableCell align="left">{row?.categoryName}</TableCell> */}
                          <TableCell align="left">{row.name}</TableCell>
                          {/* <TableCell align="left">{row.hssn}</TableCell> */}
                          <TableCell align="left">{row.batchNo}</TableCell>
                          {/* <TableCell align="left">{row.uom}</TableCell> */}
                          {/* <TableCell align="left">₹ {currencyFormat1(row.rpu)}</TableCell> */}
                          {/* <TableCell align="left">{row.gstTaxability ? 'Yes' : 'No'}</TableCell> */}
                          {/* <TableCell align="left">{row.rca ? 'Yes' : 'No'}</TableCell> */}
                          {/* <TableCell align="left">{row.gstRate}</TableCell> */}
                          <TableCell align="left">{currencyFormat1(row.stock)}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="left" width={100} style={{ display: 'flex' }}>
                            <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => viewProdVlaues(row)} />
                            <HistoryIcon style={{ cursor: 'pointer' }} onClick={() => history(row)} />
                            {/* {row?.isSaleOn == false ? */}
                              <EditIcon style={{ cursor: 'pointer' }} onClick={() => editItem(row)} />
                              {/* : ''} */}
                            <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={() => { removeVendor(row) }} />
                          </TableCell>
                        </TableRow>
                      );
                    }) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
            {getItemsData && getItemsData.length != 0 && getItemsData.length > 10 &&
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={getItemsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            }
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditProductionHistory data={getItemsData} />
        </TabPanel>


      </Container>

      {/* categoryID */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...addCategory
        }}
        validationSchema={
          Yup.object().shape({
            typeID: Yup.string().nullable().required('Type is required'),
            name: Yup.string().required('Category Name is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
          })
        }
        onSubmit={(values) => {
          setIsLoading(true);
          addCategory.projectID = projectDetails.projectID
          APIKit.post(RGCA_URLS.addSalesCategory, addCategory)
            .then((res) => {
              if (res.data.status === 200) {
                //   setLookupData(res.data.data)
                setIsLoading(false);
                // addToast(res.data.message, { appearance: 'success' });
                toast.success(MESSAGE.salesCategory, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
                closeModal();
                setAddCategory({
                  typeID: '',
                  name: ''
                })
                getCategories(salesItem.typeID);
              } else {
                setIsLoading(false);
                //addToast(res.data.message, { appearance: 'error' });
                // toast.error(res.data.message);
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            })
            .catch((e) => {
              setIsLoading(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openSubCategory}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none',
            }}
            maxWidth="lg"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogTitle id="customized-dialog-title"
                onClose={(e => {
                  handleCloseModal(resetForm)
                })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                Create Category
              </DialogTitle>
              <DialogContent dividers>
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >

                  <Grid
                    container
                    spacing={3}
                  >

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >

                      <FormControl
                        variant="outlined"
                        style={{ width: '100%' }}
                        error={Boolean(touched.typeID && errors.typeID)}
                        className={classes.formControl}
                      >
                        <InputLabel id="typeID">Type</InputLabel>
                        <Select
                          labelId="typeID"
                          id="typeID"
                          name="typeID"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const p = addCategory;
                            p.typeID = e.target.value;
                            setAddCategory({
                              ...p,
                              typeID: e.target.value
                            });
                          }}
                          required
                          value={addCategory.typeID}
                          label="Location"
                        >
                          {lookupData && lookupData.map((x) => {
                            return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                          })}
                          {/* <MenuItem value="1">Recuring</MenuItem> */}
                        </Select>
                        <FormHelperText>{(errors.typeID && touched.typeID) && errors.typeID}</FormHelperText>
                      </FormControl>

                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Category"
                        name="name"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const p = addCategory;
                          p.name = e.target.value;
                          setAddCategory({
                            ...p,
                            name: e.target.value
                          });
                        }}
                        required
                        value={addCategory.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>

                <Button type="submit" className={classes.button2}>
                  Save
                </Button>

                <Button onClick={(e) => {
                  handleCloseModal(resetForm)
                }} className={classes.button2}>
                  Cancel
                </Button>
                {/* {btn == false ?
                    <Button onClick={saveVendor} className={classes.button2}>
                        Save
                    </Button>
                    : <Button onClick={updateVendor} className={classes.button2}>
                        Update
                    </Button>} */}

              </DialogActions>
            </Form>

          </Dialog>
        )}
      </Formik>
      <Dialog aria-labelledby="customized-dialog-title"
        contentStyle={{
          width: '100%',
          maxWidth: 'none',
        }}
        maxWidth="lg" fullWidth
        open={productionHistory} id="income">
        <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
          History
        </DialogTitle>
        <Container maxWidth={false}>

          <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Production History" onClick={() => a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Name :  <b>{itemName?.name?.toLocaleUpperCase()} </b></p>
              <p >Total Production : <b>{itemName?.production ? currencyFormat1(itemName?.production ): ''}</b>	</p>
              <p >Total Mortality : <b>{itemName?.mortality ? currencyFormat1(itemName?.mortality ): ''}</b>	</p>
              <p >Total Available Stock : <b>{itemName?.totalStock}</b>	</p>
            </div>

            <Box mt={3}>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">S.No</TableCell>
                      <TableCell align="left">Production Date</TableCell>
                      <TableCell align="left">Production Count</TableCell>
                      <TableCell align="left"> Mortality Count</TableCell>
                      <TableCell align="left">GST Rate</TableCell>
                      {/* <TableCell align="left"> Rate per Unit</TableCell> */}
                      <TableCell align="left">Batch No</TableCell>
                      <TableCell align="left"> Modified By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prodHis && prodHis.length != 0 ? prodHis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.itemID}>
                          <TableCell align="left">{((page) * 10) + index + 1}</TableCell>
                          <TableCell align="left">{dateForm(row.productionDate)}</TableCell>
                          <TableCell align="left">{row.productionCount ? currencyFormat1(row.productionCount):'0'}</TableCell>
                          <TableCell align="left">{row.mortalityCount ? currencyFormat1(row.mortalityCount):'0'}</TableCell>
                          <TableCell align="left">{row.gstRate}</TableCell>
                          <TableCell align="left">{row.batchNo}</TableCell>
                          {/* <TableCell align="left">{row.rpu}</TableCell> */}
                          <TableCell align="left">{row.createdByName}</TableCell>
                        </TableRow>
                      );
                    }) :
                      <TableRow hover role="checkbox" key={1}>
                        <TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
              {prodHis && prodHis.length != 0 && prodHis.length > 10 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={prodHis ? prodHis.length : 0}
                  rowsPerPage={rowsPerPage1}
                  page={page1}
                  onPageChange={handleChangePage1}
                  onRowsPerPageChange={handleChangeRowsPerPage1}
                />
              }
            </Box>
          </TabPanel>
        </Container>

        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
            onClick={() => {
              setProductionHistory(false)
            }}

          >Close</Button>
        </DialogActions>

      </Dialog>

      <Dialog aria-labelledby="customized-dialog-title"
        contentStyle={{
          width: '100%',
          maxWidth: 'none',
        }}
        maxWidth="md" fullWidth
        open={isViewprod} id="income">
        <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
          View
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Type : <span style={{ color: '#273238' }}>{viewProdData?.typeID === 17 ? 'Product' : 'Seed'}</span>
                </h5>
              </div>
            </Grid>
            {/* <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Category : <span style={{ color: '#273238' }}>{viewProdData?.categoryName}</span>
                </h5>
              </div>
            </Grid> */}
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Name of the item : <span style={{ color: '#273238' }}>{viewProdData?.name}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  HSSN / SAC Code : <span style={{ color: '#273238' }}>{viewProdData?.hssn}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Batch No : <span style={{ color: '#273238' }}>{viewProdData?.batchNo}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  UOM : <span style={{ color: '#273238' }}>{viewProdData?.uom}</span>
                </h5>
              </div>
            </Grid>
            {/* <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Rate per Unit : <span style={{ color: '#273238' }}>{viewProdData?.rpu}</span>
                </h5>
              </div>
            </Grid> */}
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  GST : <span style={{ color: '#273238' }}>{viewProdData?.gstTaxability ? 'Yes' : 'No'}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Reverse Charge : <span style={{ color: '#273238' }}>{viewProdData?.rca ? 'Yes' : 'No'}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  GST Rate : <span style={{ color: '#273238' }}>{viewProdData?.gstRate}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Available Stocks : <span style={{ color: '#273238' }}>{viewProdData?.stock}</span>
                </h5>
              </div>
            </Grid>
            <Grid item lg={3} sm={4} xl={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ color: '#394671' }}>
                  Description : <span style={{ color: '#273238' }}>{viewProdData?.description}</span>
                </h5>
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
            onClick={() => {
              setIsViewProd(false)
            }}
          >Close</Button>
        </DialogActions>
      </Dialog>




      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isEdit}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none',
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title" onClose={(e => {
          // cancelSalesItem(resetForm)
        })} style={{ backgroundColor: '#394671', color: '#fff' }}>
          Edit Production Item
        </DialogTitle>
        <EditSales salesItem={salesItem} setSalesItem={setSalesItem} setIsEdit={setIsEdit} setIsLoading={setIsLoading}
          projectDetails={projectDetails} lookupData={lookupData} categoryList={categoryList} getItemsList={getItemsList} isLoading={isLoading} />

      </Dialog>




    </Page>
  );
};
export default Sales;
