import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getloggedInData } from 'src/utils/helpers';
import APIKit, { setClientToken, deleteToken } from 'src/utils/APIKit';
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { APP_URLS } from 'src/utils/apis';
import { RGCA_URLS } from '../../../utils/url'
import { accessPermission } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getProjectData } from '../../../Redux/ProjectData/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Reports from '../Reports';
import Capital from '../Dashboard/capital';
import Recurring from '../Dashboard/recurring';

import Sales from '../../dashboard/Sales';
import Revenue from '../../dashboard/revenue';
import { ChartsData } from '../../dashboard/data';
import Farmer from '../../dashboard/Farmer';
import FisherMen from '../../dashboard/FisherMen';
import Portfolio from '../../dashboard/Portfolio';
import FingerLingsPieChart from '../../dashboard/FingerLings';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DashboardNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [projectsData, setProjectsData] = useState([])
  const [projectId, setProjectId] = useState(1)
  const [testdata, setTestdata] = React.useState([]);
  const [farmarData, setFarmarData] = React.useState([]);
  const [fisherMen, setFisherMen] = React.useState([]);
  const [location, setLocaton] = React.useState({ location: 'Tanjavur', year: "2019" });
  useEffect(() => {
    getAllProjects();

  }, [])

  const segrate = (location) => {
    let data = []
    ChartsData.AchivementData.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setTestdata(x)

      }
    })

    ChartsData.FarmarData.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setFarmarData(x)

      }
    })
    ChartsData.FisherMen.forEach((x, i) => {
      if (x.Location == location.location && x.year == location.year) {
        setFisherMen(x)

      }
    })

  }





  const getAllProjects = async () => {
    await APIKit.get(RGCA_URLS.getEmployeeProjects)
      .then((res) => {

        localStorage.setItem('projectList', JSON.stringify(res.data.data || []))
        let permission = JSON.parse(localStorage.getItem('projectList'))
        // navigate('/app/dashboard/' + permission[0]?.projectName);
        let list = res.data.data;
        setProjectsData(list)
      })
      .catch(function (e) {
      })
  }

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Typography><h2 style={{ color: '#394671' }}>Dashboard</h2></Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={3}
            xs={12}
          >
            <Box>
              {/* <Card>
                                <CardContent> */}
              <Grid
                item
                md={4}
                xs={12}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: '100%' }}
                  // error={Boolean(touched.companyType && errors.companyType)}
                  className={classes.formControl}
                >
                  <InputLabel required id="demo-simple-select-outlined-label">Projects</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={vendor.companyType}
                    name="companyType"
                    label="Projects"
                    value={projectId}
                    onChange={(e) => {
                      setProjectId(Number(e.target.value))
                      segrate(location)
                      // handleChange(e);
                      // navigate('/app/reports/'+e.target.value)
                      // const p = vendor;
                      // p.companyType = e.target.value;
                      // setVendor({
                      //     ...p,
                      //     companyType: e.target.value
                      // });
                    }}
                  >
                    {projectsData && projectsData.map((x) => {
                      return <MenuItem value={x.projectID} key={x.projectID}>{x.projectName}</MenuItem>;
                    })}

                  </Select>
                </FormControl>

              </Grid>
              {/* </CardContent>
                            </Card> */}
            </Box>
          </Grid>
          {/*  */}
          <Grid
            item
            lg={6}
            sm={6}
            xl={3}
            xs={12}
          >
            <Box>
              {projectId &&
                <Capital projectID={projectId} />
              }
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={3}
            xs={12}
          >
            <Box>
              {projectId &&
                <Recurring projectID={projectId} />
              }
            </Box>
          </Grid>

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            {projectId &&
              <FingerLingsPieChart projectID={projectId} />
            }
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            {projectId &&
              <Portfolio projectID={projectId} />
            }
            {/* <Revenue testdata={testdata} /> */}
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={3}
            xs={12}
          >
            {projectId &&
              <Box>
                {/* <Card>
                                <CardContent> */}
                <Reports projectID={projectId} />
                {/* </CardContent>
                            </Card> */}
              </Box>
            }
          </Grid>

        </Grid>
      </Container>
    </Page>
  )

}
export default DashboardNew;
