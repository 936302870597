import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Selectoption from 'react-select';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Modal,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useBarcode } from 'react-barcodes';
import { data } from './data';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { currencyFormat1, dateFormate, dateOnly, getProjectDetails } from '../Common/utilities';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { parseData, dateOnlyRev } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import { MESSAGE } from 'src/utils/message';
import CommonTable from '../Common/CommonTable';
import { ETTypes, ETaction } from '../Common/Types';
import './style.css';
import * as Yup from 'yup';
import CommonSelect from '../Common/CommonSelect';
import { Formik, Form, FieldArray, getIn } from 'formik';
import Address from '../Common/Address';
import { useConfirm } from 'material-ui-confirm';
import { checkPermission } from '../../../utils/permission.jsx';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import GrnlistTwo from './grnlistTwo';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddItem from './AddItem';
import GrnTable from './GrnTable';
import GrnPrint from './grnPrint';
import StoreTrasferOtherProject from './storeTrasferOtherProject'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%'
    // marginTop:"125px"
  },
  centertxt: {
    textTransform: 'none',

    [theme.breakpoints.down('xs')]: {
      marginLeft: '65px',
      height: '55px',
      marginTop: '5px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      height: '52px',
    }
  },

  manuval: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '60px',
      height: '55px',
      marginTop: '5px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      height: '55px',
    }
  },
  selectContainer: {
    width: '300px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      width: '300px',
      marginLeft: "70px"
    }
  },
  app: {
    [theme.breakpoints.up('md')]: {
      width: '170px !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    }
  },
  invoice: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '55px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1px',
    }
  },
  exp: {
    [theme.breakpoints.down('md')]: {
      width: '640px',
    },

    [theme.breakpoints.down('xs')]: {
      width: '230px'
    }
  },
  vendor: {
    [theme.breakpoints.up('md')]: {
      minWidth: '280px'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '600px'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '250px'
    }
  },
  type: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '230px'
    }
  },
  Attach: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '230px'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '280px'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '620px'
    },
  },
  common: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '150px',
      width: "230px"
    },
    // [theme.breakpoints.up('md')]: {
    //   minWidth:'280px'
    // },
    // [theme.breakpoints.down('md')]: {
    //   minWidth:'620px'
    // },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      minWidth: '280px'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '635px'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '80%'
    },
  }
}));

function App() {
  const { inputRef } = useBarcode({
    value: 'ITEM-10052021-0012',
    options: {
      text: 'ITEM-10052021-0012'
    }
  });

  return <svg ref={inputRef} />;
}

const GRNList = (props) => {
  // alert(props.permission)
  const classes = useStyles();
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0); // first tab
  const [page, setPage] = React.useState(0);
  const [grnList, setGrnList] = useState(data);
  const [arrayTableData, setArrayTableData] = useState([]);
  const [showContent, setShowContent] = useState(true);
  const [grnInvoiceID, setGrnInvoiceID] = useState({
    inVoiceID: ''
  });
  const [manuallyAddGRN, setManuallyAddGRN] = useState({
    vendorID: '',
    invoiceID: '',
    invoiceAttach: '',
    invoiceDate: ''
  });
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  var valuenumber;
  const [poData, setPoData] = React.useState('');
  const [freeItem, setFreeItem] = React.useState('');
  const [openincPrint, setOpenincPrint] = React.useState(false);
  const [addManually, setAddManually] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createView, setCreateView] = React.useState(false);
  const [moreView, setMoreView] = React.useState(false);
  const [returnView, setReturnView] = React.useState(false);
  const [singleReturnView, setSingleReturnVIew] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [paymentCompleted, setPaymentPaymentCompleted] = React.useState(false);
  const [POgrn, setPOgrn] = React.useState([]);
  const [POItemsNew, setPOItemsNew] = React.useState([])
  const [POgrnAdd, setPOgrnAdd] = React.useState([
    [{
      "qty": null,
      "uom": "",
      "CGST": null,
      "IGST": null,
      "SGST": null,
      "UGST": null,
      "name": "",
      "isGST": false,
      "status": 0,
      "lookUpID": null,
      "CreatedBy": null,
      "UpdatedBy": null,
      "unitPrice": null,
      "UpdatedDate": null,
      "createdDate": "",
      "description": "",
      "materialItem": null,
      "purchaseOrderItemID": null,
      "purchaseOrderMasterID": "",
      "itemAttach": "",
      "dateofExpiry": new Date(),
      "receivedDate": new Date(),
      "location": projectDetails?.location ? projectDetails?.location :' ',
      "warrantyGranteeDetails": '',
      "estimatedYears": '',
      "rateOfDepreciation": '',
      "methodOfDepreciation": '',
      "estimatedResidualValue": '',
      "detailsOfDepreciation": '',
      "accumulatedDepreciation": '',
      "insuranceDate": null,
      "insuranceAttach": '',
      "note": '',
      "grnStatus": null,
      "rejectedComment": '',
      "aprovedCount": null,
      "rejectedCount": null,
    }]
  ]
  );

  const confirm = useConfirm();
  const [POaddress, setPOaddress] = React.useState([]);
  const [POBank, setPOBank] = React.useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataInvoiceID, setTotalDataInvoiceID] = React.useState({});
  const [allGRN, setAllGRN] = React.useState([]);
  const [singleReturn, setSingleReturn] = React.useState({});
  const [allReturn, setAllReturn] = React.useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const [GRNView, setGRNView] = React.useState([]);
  const [GRNItem, setGRNItem] = React.useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [GSTTDS, setGSTTDS] = React.useState('NO');
  const [edit, setEdit] = React.useState(false);
  const [catOrRec, setCatOrRec] = useState([]);
  const [indexData, setIndexData] = useState(null);
  const [TAXTDS, setTAXTDS] = React.useState('NO');
  const [lookupData, setLookupData] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [poGST, setPOGSt] = useState({});
  const [returnGoods, setReturnGoods] = useState({});
  const [scroll, setScroll] = useState('paper');
  const [show, setShow] = useState(true);
  const [objAddData, setObjAddData] = useState({
    val: {},
    materialList: [],
    typeID: '',
    itemID: '',
    itemName: '',
    dateofExpiry: '',
    itemAttach: '',
    grnQty: '',
    isGST: '',
    gstType: '',
    unitPrice: '',
    totalPrice: '',
    comment: ''
  });
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorStatus, setErrorStatus] = useState(false);
  const [isStoreTrasferView, setIsStoreTrasferView] = useState('sameproject');

  const [poId, setPoId] = useState({
    purchaseList: [],
    val: ''
  });
  const [print, setprint] = useState(false)

  let userData = JSON.parse(localStorage.getItem('userData'));

  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    paymentMethod: '',
    projectID: null,
    vendorID: '',
    reference: '',
    value: '',
    isGstTds: '',
    gstTdsRate: '',
    gstTaxExemption: '',
    isItTaxTds: '',
    itTdsRate: '',
    itTaxExemption: '',
    purposeAdvance: '',
    balancePayable: ''
  });

  const paymentAdd = () => {
    if (Number(payload.value) == 0) {
      toast.error('Value must not be 0', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    payload.projectID = projectDetails.projectID;
    payload.GRNID = paymentData.grnMasterID;
    payload.GRNorWO = 1;
    let paymentUpdate = {
      projectID: payload.projectID,
      GRNID: payload.GRNID,
      workOrderID: payload.workOrderID,
      paymentMethod: payload.paymentMethod,
      vendorID: paymentData.vendorID,
      GRNorWO: 1,
      reference: Number(payload.reference),
      value: Number(payload.value),
      isGstTds: payload.isGstTds,
      gstTdsRate: payload.isGstTds ? Number(payload.gstTdsRate) : 0,
      gstTaxExemption: payload.isGstTds
        ? paymentData?.totalItemValueNoTax * (payload.gstTdsRate / 100)
        : 0,
      isItTaxTds: payload.isItTaxTds,
      itTdsRate: payload.isItTaxTds ? Number(payload.itTdsRate) : 0,
      itTaxExemption: payload.isItTaxTds
        ? paymentData?.totalItemValueNoTax * (payload.itTdsRate / 100)
        : 0,
      purposeAdvance: payload.purposeAdvance,
      balancePayable: Number(paymentData.pendingAmount) - Number(payload.value)
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addPaymentForGRNWO, paymentUpdate)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPayload({
            projectID: '',
            vendorID: '',
            reference: '',
            value: '',
            isGstTds: '',
            gstTdsRate: '',
            gstTaxExemption: '',
            isItTaxTds: '',
            itTdsRate: '',
            itTaxExemption: '',
            purposeAdvance: '',
            balancePayable: ''
          });
          getGRNList();
          setPayment(!payment);
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };

  const [GRN, setGRN] = useState({
    POID: '',
    gst: ''
  });

  const handlePayView = data => {
    setPaymentData(data);
    let gst = data?.grnItem?.map(e => {
      return {
        gstDetails: e.gstDetails
      };
    });
    setPOGSt(gst);
    if (data.pendingAmount == '0') {
      setPaymentPaymentCompleted(!paymentCompleted);
    } else {
      setPayment(!payment);
    }

    setPayload({
      projectID: '',
      vendorID: '',
      reference: '',
      value: '',
      isGstTds: '',
      gstTdsRate: '',
      gstTaxExemption: '',
      isItTaxTds: '',
      itTdsRate: '',
      itTaxExemption: '',
      purposeAdvance: '',
      balancePayable: ''
    });
  };

  const handlePayViewCompleted = () => {
    setPaymentPaymentCompleted(!paymentCompleted);
  };

  const handleMoreViewClose = () => {
    setMoreView(!moreView);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    a11yProps(0);
    getGRNList();
    getVendor();
    getReturnGoods();
    getPaymentMethod();
    getLookup();
    getIncompleteGRN();
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data);
        } else {
        }
      })
      .catch(function (e) { });
  };

  const a11yProps = index => {
    if (index == 0) {
      setCreateView(false);
    }
    if (index == 1) {
      getGRNList();
    }
    if (index == 2) {
      getReturnGoods();
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  const handleAddManually = () => {
    setAddManually(!addManually);
  };

  const POApprovalView = [
    {
      title: 'GRN Item ID',
      field: 'grnItemID',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Material Name',
      field: 'name',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Category',
      field: 'categoryName',
      align: 'left',
      type: ETTypes.string
    },
    // {
    //   title: 'Item Attach',
    //   field: 'itemAttach',
    //   align: 'left',
    //   type: ETTypes.link
    // },
    {
      title: 'Quantity',
      field: 'grnQty',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Total Price',
      field: 'totalPrice',
      align: 'left',
      type: ETTypes.numeric
    }
  ];

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };

  const getPaymentMethod = () => {
    APIKit.get(`${RGCA_URLS.lookup}/55`)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleReturn = data => {
    if (data != 0) {
      setReturnGoods({
        ...data,
        grnItem: data.grnItem.map(e => {
          return {
            ...e,
            returnQty: '',
            grnQty: e.grnQty,
            returnPurpose: '',
            totalGST: e?.gstDetails?.reduce(function (acc, b) {
              return acc + b.CGST + b.IGST + b.SGST + b.UGST;
            }, 0)
          };
        })
      });
    }

    setReturnView(!returnView);
  };

  const handleViewGrn = () => {
    setOpenincPrint(!openincPrint);
  };

  const handleGRNView = data => {
    setGRNView({
      ...data,
      venName: data.vendorDetails[0].name,
      venAadhar: data.vendorDetails[0].aadhar,
      venGstIn: data.vendorDetails[0].gstn,
      venLine1: data.vendorDetails[0]?.address[0]?.line_1,
      venLine2: data.vendorDetails[0]?.address[0]?.line_2,
      vendistrict: data.vendorDetails[0]?.address[0]?.district,
      venstate: data.vendorDetails[0]?.address[0]?.state,
      vencountry: data.vendorDetails[0]?.address[0]?.country,
      venpostalCode: data.vendorDetails[0]?.address[0]?.postalCode,
      venbankName: data.vendorDetails[0]?.bankDetails[0]?.bankName,
      venbankAccountNo: data.vendorDetails[0]?.bankDetails[0]?.bankAccountNo,
      venbankAccountType: data.vendorDetails[0]?.bankDetails[0]?.bankAccountType,
      venbankBranch: data.vendorDetails[0]?.bankDetails[0]?.bankBranch,
      venbankIFSC: data.vendorDetails[0]?.bankDetails[0]?.bankIFSC,
      venbankAccountHolderName:
        data.vendorDetails[0].bankDetails[0]?.bankAccountHolderName,
      venpan: data.vendorDetails[0]?.pan,
      prol1: data.projectDetails[0]?.address[0]?.line_1,
      proLine2: data.projectDetails[0]?.address[0]?.line_2,
      prodistrict: data.projectDetails[0]?.address[0]?.district,
      prostate: data.projectDetails[0]?.address[0]?.state,
      procountry: data.projectDetails[0]?.address[0]?.country,
      propostalCode: data.projectDetails[0]?.address[0]?.postalCode
    });
    setGRNItem(data.grnItem);
  };


  const exportPdf = () => {
    html2canvas(document.querySelector('#printme')).then(canvas => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('download.pdf');
    });
  };

  const [inter, setInter] = useState(false);
  const [intra, setIntra] = useState(false);
  const [union, setUnion] = useState(false);
  const [gstState, setGstState] = useState('');
  const [isAddDialog, setIsAddDialog] = useState(false);
  const [isRadioShow, setIsRadioShow] = useState(false);

  const handleState = e => {
    if (e.target.value === 'Interstate') {
      setInter(true);
      setIntra(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Intrastate') {
      setIntra(true);
      setInter(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Union') {
      setUnion(true);
      setInter(false);
      setIntra(false);
      setGstState(e.target.value);
    }
  };

  const getMaterials = (i, typeID, projectID) => {
    let payload = {
      // projectID: projectID,
      typeID: typeID
    };
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then(res => {
        if (res.data.status === 200) {
          objAddData.materialList = res.data.data;
          setObjAddData({ ...objAddData });
        } else {
        }
      })
      .catch(function (e) { });
    // console.log('text-text',payload)
  };

  const onChangeInput = (value, index) => {
    objAddData.val = value;
    setObjAddData({ ...objAddData });
    let item = { ...objAddData };
    item.itemID = value.value;
    item.itemName = value.label;
    let uo = objAddData.materialList.find(x => x.itemID == value.value);

    item.uom = uo.uom;
    item.lastPrice = Number(uo.lastPrice);
    item.brandOrCatalogue = uo.brandOrCatalogue;
    item.availabilityQty = uo.availabilityQty;
    setObjAddData({ ...item });
  };
  const [warrantyAttachItem, setWarrantyAttachItem] = useState("")
  const handleUploadClick = (event, i, type) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append(
      'files',
      event.target.files[0],
      event.target.files[0].name
    );
    if (type == 'invoice') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {

          if (res.data.status == 200) {

            console.log("grn invoice", res.data.data);
            setIsLoading(false);

            setTotalData({
              ...totalData,
              invoiceAttach: res.data.data
            });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch((e) => {
          console.log("fileUpload error", e);
        });
    }
    if (type == 'invoiceManual') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            let temp = manuallyAddGRN;
            temp.invoiceAttach = res.data.data;
            setManuallyAddGRN({ ...temp });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'itemAttachManual') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            let temp = objAddData;
            temp.itemAttach = res.data.data;
            setObjAddData({ ...temp });
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
    if (type == 'itemInvoice') {
      APIKit.post(RGCA_URLS.upload, formData)
        .then(res => {
          if (res.data.status == 200) {
            setIsLoading(false);
            setWarrantyAttachItem(res.data.data)
            let temp = POgrn;
            temp[i].itemAttach = res.data.data;
            setPOgrn([...temp]);
            toast.success(MESSAGE.itemAttach, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.fileNotUpload, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch(() => { });
    }
  };

  const actions = {
    onEdit: (index, row) => {
      setIndexData(index);
      setOpenTest(!openTest);
      setObjAddData({ ...row });
      setEdit(true);
    },
    onDelete: (index, row) => {
      console.log('delete:', index, row);
      remove(row, index);
    }
  };

  const remove = (data, i) => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        let item = arrayTableData;
        item.splice(i, 1);
        setArrayTableData([...item]);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const [columns, setColumns] = useState([
    {
      title: 'Item Name',
      field: 'itemName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Item Attach',
      field: 'itemAttach',
      align: 'left',
      type: ETTypes.link
    },
    {
      title: 'GRN Quantity',
      field: 'grnQty',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Total Price',
      field: 'totalPrice',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Date of Expiry',
      field: 'dateofExpiry',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onEdit, ETaction.onDelete]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  const addItem = () => {
    setOpenTest(!openTest);
    setObjAddData({
      val: {},
      materialList: [],
      typeID: '',
      itemID: '',
      itemName: '',
      dateofExpiry: '',
      itemAttach: '',
      isGST: '',
      gstType: '',
      grnQty: '',
      unitPrice: '',
      totalPrice: '',
      comment: ''
    });
  };

  const [items, setItems] = React.useState([data.items]);
  const returnGoodsFn = () => {
    const payload = {
      projectID: projectDetails.projectID,
      purchaseOrderMasterID: returnGoods.purchaseOrderMasterID,
      invoiceID: returnGoods.invoiceID,
      vendorID: returnGoods.vendorID,
      grnMasterID: returnGoods.grnMasterID,
      totalItemValueNoTax: returnGoods?.grnItem?.reduce(
        (a, b) => (b.grnQty - (b.returnQty ?? 0)) * b.unitPrice + a,
        0
      ),
      totalItemValue: returnGoods?.grnItem?.reduce(
        (a, b) =>
          (b.grnQty - (b.returnQty ?? 0)) * b.unitPrice +
          (b.grnQty - (b.returnQty ?? 0)) *
          b.unitPrice *
          ((b.totalGST ?? 0) / 100) +
          a,
        0
      ),
      items: returnGoods?.grnItem?.reduce((ids, e) => {
        if (e.returnQty != '' && e.returnQty != 0) {
          const obj = {
            materialID: e.materialID,
            grnQty: e.grnQty,
            grnItemID: e.grnItemID,
            unitPrice: e.unitPrice,
            returnQty: e.returnQty,
            returnPurpose: e.returnPurpose,
            totalPrice: (e.grnQty - e.returnQty) * e.unitPrice
          };
          ids.push(obj);
        }
        return ids;
      }, [])
    };
    if (payload.items.some(e => e.returnPurpose == '')) {
      toast.error(MESSAGE.returnPurpose, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.returnGoods, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setReturnGoods({});
          setReturnView(false);
          getGRNList();
          toast.success(MESSAGE.returnGoods, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.eroor(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const handleReturns = data => {
    setSingleReturnVIew(true);
    setSingleReturn(data);
  };

  const TotalPrice = () => {
    return POgrn?.reduce(
      (a, b) =>
        b.qty * b.unitPrice +
        b.qty * b.unitPrice * ((b.IGST + b.CGST + b.SGST + b.UGST) / 100) +
        a,
      0
    ); // 20;
  };
  const [isRefresh, setIsRefresh] = useState(false)
  const submitGrn = () => {
    const payload = {
      projectID: projectDetails.projectID,
      purchaseOrderMasterID: POgrnAdd[0]?.purchaseOrderMasterID,
      purchaseOrderItemID: POgrnAdd[0]?.purchaseOrderItemID,
      vendorID: totalData[0]?.vendorID,
      invoiceID: totalData?.inVoiceID ? totalData?.inVoiceID : totalData[0]?.inVoiceID,
      invoiceAttach: totalData?.invoiceAttach ? totalData?.invoiceAttach : totalData[0]?.invoiceAttach,
      invoiceDate: moment(totalData?.invoiceDate ? totalData?.invoiceDate : totalData[0]?.invoiceDate).format("YYYY-MM-DD"),
      totalItemValueNoTax: POgrnAdd?.reduce((a, b) => b.qty * b.unitPrice + a, 0),
      isOutSideProject: 0,
      totalItemValue: POgrnAdd?.reduce(
        (a, b) =>
          b.qty * b.unitPrice +
          b.qty * b.unitPrice * ((b.IGST + b.CGST + b.SGST + b.UGST) / 100) +
          a,
        0
      ),

      items: POgrnAdd.map(e => {
        return {
          materialID: e.materialItem,
          grnQty: e.lookUpID == 2 ? 1 : e.qty,
          unitPrice: e.unitPrice,
          dateofExpiry: e.dateofExpiry ? moment(e.dateofExpiry).format("YYYY-MM-DD") : "",
          itemAttach: warrantyAttachItem ? warrantyAttachItem : '',
          totalPrice: 1 * e.unitPrice,
          freeItem: e.freeItem ? e.freeItem : 0,
          lookUpID: e.lookUpID,
          receivedDate: e.receivedDate ? moment(e.receivedDate).format("YYYY-MM-DD") : e.createdDate,
          IGST: e.IGST,
          isGST: e.isGST == false ? false : e.isGST == true ? true : e.isGST == 1 ? true : false,
          oQty: e.oQty,
          location: e.location ? e.location : (projectDetails?.location ?? ''),
          purchaseValue: e.unitPrice ? Number(e.unitPrice) : '',
          warrantyGranteeDetails: e.warrantyGranteeDetails ? e.warrantyGranteeDetails : '',
          estimatedYears: e.estimatedYears ? e.estimatedYears : '',
          rateOfDepreciation: e.rateOfDepreciation ? Number(e.rateOfDepreciation) : null,
          methodOfDepreciation: e.methodOfDepreciation ? e.methodOfDepreciation : '',
          estimatedResidualValue: e.estimatedResidualValue ? Number(e.estimatedResidualValue) : null,
          detailsOfDepreciation: e.detailsOfDepreciation ? e.detailsOfDepreciation : '',
          accumulatedDepreciation: e.accumulatedDepreciation ? e.accumulatedDepreciation : '',
          insuranceDate: e.insuranceDate ? moment(e.insuranceDate).format("YYYY-MM-DD") : '',
          insuranceAttach: e.insuranceAttach ? e.insuranceAttach : '',
          note: e.note ? e.note : '',
          grnStatus: e.grnStatus ? e.grnStatus : (e.aprovedCount > 0 ? 24 : 25),
          rejectedComment: e.rejectedComment ? e.rejectedComment : '',
          aprovedCount: e.lookUpID == 2 ? 1 : (e.aprovedCount ?? null),
          rejectedCount: e.rejectedCount ? e.rejectedCount : (e.grnStatus == 25 && e.lookUpID == "2" ? 1 : 0),
          comment:'added grn quantity',
            TransferQty:null
        }
      }),
      statusID: 20
    };

    payload.items.forEach((item) => {
      if (item.lookUpID != 3) {
        // if (totalData[0]?.receivedqty + 1 == item.oQty) {
        if (POItemsNew.filter(item => item.isflag === 0).length == 1) {
          payload['statusID'] = 22;
        } else {
          payload['statusID'] = 20;
        }
      } else {
        if (POItemsNew.filter(item => item.isflag === 0).length == 1) {
          payload['statusID'] = 22;
        } else {
          payload['statusID'] = 20;
        }

      }
      delete item?.oQty;
    });

    console.log("PAYLOAD", payload);

    if (payload.invoiceAttach === undefined) {
      toast.error(MESSAGE.attachment, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }

    // if (payload.items[0].dateofExpiry == '') {
    //   setErrorMessage(true)
    //   return;
    // }
    // if (payload.items[0].grnStatus == '') {
    //   setErrorStatus(true)
    //   return;
    // }
    setIsLoading(true);
    // APIKit.post(RGCA_URLS.addGRNforItems, payload)
    APIKit.post(RGCA_URLS.addGRNforRecurring, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGRN({
            ...GRN,
            POID: ''
          });
          if (res.data.data[0].statusID == 22) {
            setCreateView(false);
            setShowContent(true)
            AddCloseModel();
            getGRNList();
            getIncompleteGRN();
            setIsRefresh(true);
            setIsAddDialog(false);
          } else {
            setCreateView(false);
            setIsAddDialog(false);
            setShowContent(true);
            AddCloseModel();
            searchPOID();
            getGRNList();
            getIncompleteGRN();
            setPOgrn([]);
            // setTotalDataInvoiceID({});
            setIsRefresh(true)
          }

          toast.success(MESSAGE.grnAdded, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const addGRNWithoutPO = () => {
    const payload = {
      projectID: projectDetails.projectID,
      vendorID: manuallyAddGRN.vendorID,
      invoiceID: manuallyAddGRN.invoiceID,
      purchaseOrderMasterID: 'NO Purchase Order ID',
      invoiceAttach: manuallyAddGRN.invoiceAttach,
      invoiceDate: dateOnlyRev(manuallyAddGRN.invoiceDate),
      totalItemValueNoTax: arrayTableData?.reduce(
        (a, b) => b.grnQty * b.unitPrice + a,
        0
      ),
      totalItemValue: arrayTableData?.reduce(
        (a, b) =>
          b.grnQty * b.unitPrice +
          b.grnQty * b.unitPrice * (b.gstType / 100) +
          a,
        0
      ),
      items: arrayTableData.map(e => {
        return {
          materialID: e.itemID,
          grnQty: e.grnQty,
          unitPrice: e.unitPrice,
          dateofExpiry: dateOnlyRev(e.dateofExpiry),
          itemAttach: e.itemAttach,
          totalPrice: e.totalPrice,
          lookUpID: e.lookUpID
        };
      })
    };
    console.error(payload)
    if (payload.invoiceAttach === undefined) {
      toast.error(MESSAGE.attachment, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addGRN, payload)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGRN({
            ...GRN,
            POID: ''
          });
          setCreateView(false);
          getGRNList();
          getIncompleteGRN();
          setPOgrn([]);

          setTotalDataInvoiceID({});
          toast.success(MESSAGE.grnAdded, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          setIsLoading(false);
          toast.eroor(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };


  const searchPOID = () => {

    const payload = {
      purchaseOrderMasterID: poData
    };
    payload.projectID = projectDetails.projectID;
    APIKit.post(RGCA_URLS.getPurchaseOrderForGRN, payload)
      .then(res => {
        if (res?.data?.data?.length) {
          // console.log("getPurchaseOrderForGRN",JSON.parse(res?.data?.data));
          let dataPurchaseItem = [];
          let dataaddress = [];
          let databankDetails = [];
          let data = res.data.data.map((x, i) => {
            dataPurchaseItem = parseData(x.purchaseOrderItem);
            console.log("dataPurchaseItem", dataPurchaseItem);
            console.error(dataPurchaseItem, "kiji")
            dataPurchaseItem = dataPurchaseItem.map(element => {
              if (element.IGST === null) {
                return {
                  ...element,
                  IGST: 0,
                  CGST: 0,
                  SGST: 0,
                  UGST: 0,
                  totalRate:
                    0,
                  oQty: element.qty,
                  qty: element.qty,
                  dateofExpiry: '',
                }
              } else
                return {
                  ...element,
                  totalRate:
                    element.IGST + element.CGST + element.SGST + element.UGST,
                  oQty: element.qty,
                  qty: element.qty,
                  dateofExpiry: ''
                };
            });
            dataaddress = parseData(x?.address);
            databankDetails = parseData(x?.bankDetails);
            return {
              ...x,
              address: parseData(x?.address),
              bankDetails: parseData(x?.bankDetails),
              purchaseOrderItem: parseData(x?.purchaseOrderItem),
              inVoiceDate: ''
            };
          });
          console.log("dataPurchaseItem", dataPurchaseItem);
          setTotalData(data);
          setPOaddress(dataaddress);
          setPOBank(databankDetails);
          setPOgrnAdd(dataPurchaseItem)
          setPOItemsNew(dataPurchaseItem)
          setPOgrn(dataPurchaseItem);
          setIsRadioShow(false);
          setCreateView(true);
        } else {
          toast.error(MESSAGE.poiDmatch, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };

  console.log("projectDetails?.location",projectDetails?.location);

  var num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

  function number2words(n) {
    if (n < 20) return num[n];
    var digit = n % 10;
    if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
    if (n < 1000)
      return (
        num[~~(n / 100)] +
        ' hundred' +
        (n % 100 == 0 ? '' : ' and ' + number2words(n % 100))
      );
    return (
      number2words(~~(n / 1000)) +
      ' thousand' +
      (n % 1000 != 0 ? ' ' + number2words(n % 1000) : '')
    );
  }

  const getIncompleteGRN = () => {
    APIKit.get(
      RGCA_URLS.getIncompleteGrn + '/' + projectDetails.projectID
    ).then(res => {
      if (res.data.status === 200) {
        if (res.data.data.length > 0) {
          poId.purchaseList = res.data.data.map(x => {
            x['itemID'] = x.purchaseOrderMasterID;
            x['name'] = x.purchaseOrderMasterID;
            return x;
          });
          setPoId({ ...poId });
        } else {
          setPoId([]);
        }
      }
    });
  };
  const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      minHeight: 55
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(#0000ff, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(#0000ff, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: base => ({
      ...base,
      zIndex: 100
    })
  };

  const getGRNList = () => {
    setIsLoading(true)
    APIKit.get(RGCA_URLS.getGRN + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setAllGRN(data);
          setIsLoading(false)
        } else {
          // toast.error(res.data.message);
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      });
  };

  const getReturnGoods = () => {
    APIKit.get(RGCA_URLS.getReturnGoods + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              returnItem: parseData(x.returnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails)
            };
          });
          setAllReturn(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.grnQty;
    });
    return test;
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9.]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  // const OnItemChange = () => {
  //   setPoId([poData])
  // };
  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });

  const handleClickIncOpen = () => {
    setIsAddDialog(true);
  };

  const AddCloseModel = () => {
    // setCreateView(false);
    let p = POgrnAdd;
    p[0].location = ""
    p[0].warrantyGranteeDetails = ""
    p[0].estimatedYears = ""
    p[0].rateOfDepreciation = ""
    p[0].methodOfDepreciation = ""
    p[0].detailsOfDepreciation = ""
    p[0].accumulatedDepreciation = ""
    p[0].inVoiceDate = ""
    p[0].insuranceAttach = ""
    p[0].note = ""
    setWarrantyAttachItem('');
    setPOgrnAdd([...p]);
    setIsAddDialog(false);
  }

  return (
    <Page className={classes.root} title="GRN">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <>
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props?.permission == "view_GRN" && (checkPermission('create_GRN') || userData?.isAdmin == 1) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowContent(!showContent);
                    setCreateView(false);
                    setPoData('');
                    setIsRadioShow(true);
                    setIsStoreTrasferView('sameproject')
                  }}
                >
                  {showContent ? 'Add GRN' : 'Back'}
                </Button>
                {createView && <Button
                  color="primary"
                  variant="contained"
                  disabled={totalData[0]?.totalQty == totalData[0]?.receivedqty}
                  style={{ marginRight: "40px", marginLeft: "30px" }}
                  onClick={handleClickIncOpen}
                >
                  Add
                </Button>
                }
              </>

            )}
          </Box>
          {showContent ? (
            <GrnTable allGRN={allGRN} permission={props.permission}
              handleViewGrn={handleViewGrn}
              getGRNList={getGRNList}
              projectDetails={projectDetails}
              setIsLoading={setIsLoading}
              handleReturn={handleReturn} handlePayView={handlePayView}
              page={page} rowsPerPage={rowsPerPage} handleGRNView={handleGRNView}
              handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            <>

              {isRadioShow &&
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl component="fieldset">
                      <RadioGroup row aria-label="position" name="position">
                        <FormControlLabel
                          value="sameproject"
                          control={<Radio checked={isStoreTrasferView === 'sameproject'} color="primary" name='isStoreTrasferView' onChange={(e) => {
                            setIsStoreTrasferView('sameproject');
                          }} />}
                          label="Same Project"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="otherproject"
                          control={<Radio checked={isStoreTrasferView === 'otherproject'} color="primary" name='isStoreTrasferView' onChange={(e) => {
                            setIsStoreTrasferView('otherproject')
                          }} />}
                          label="Other Project"
                          labelPlacement="start"
                        />
                      </RadioGroup>

                    </FormControl>
                  </Grid>
                </Grid>
              }
              {isStoreTrasferView == 'otherproject' ?
                <StoreTrasferOtherProject projectDetails={projectDetails} isLoading={isLoading} setShowContent={setShowContent}
                isRadioShow={isRadioShow} setIsRadioShow={setIsRadioShow} setIsLoading ={setIsLoading}
                getGRNList={getGRNList}/> : (

                  <Grid container spacing={3}>
                    {createView == false && (
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item style={{ marginTop: '10px' }}>
                          <Button
                            color="primary"
                            onClick={e => {
                              setAddManually(!addManually);
                            }}
                            variant="contained"
                            className={classes.manuval}
                          >
                            Manually Add GRN
                          </Button>
                        </Grid>
                        <Grid justify="center" >
                          <Grid item className={classes.selectContainer} >
                            <Selectoption
                              isDisabled={!poId.purchaseList?.length}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              placeholder="Search PO ID"
                              onChange={e => {
                                setPoData(e.value);
                                valuenumber = e.value;
                              }}
                              value={valuenumber}
                              options={poId?.purchaseList ? poId?.purchaseList?.map(e => {
                                return {
                                  value: e.itemID,
                                  label: e.name
                                };
                              }) : ""}
                            />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={!poData}

                            color="primary"
                            onClick={e => {
                              searchPOID();
                            }}
                            variant="contained"
                            className={classes.centertxt}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}


              <Grid>
                {createView == true && (

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      inVoiceID: totalData.inVoiceID ? totalData.inVoiceID : totalData[0].inVoiceID,
                      inVoiceDate: totalData.invoiceDate ? totalData.invoiceDate : totalData[0].invoiceDate,
                      POgrn: [...POgrn]
                    }}
                    onSubmit={(values, { resetForm }) => {
                      // submitGrn();
                    }}
                    validationSchema={Yup.object().shape({
                      POgrn: Yup.array().of(
                        Yup.object().shape({
                          qty: Yup.number().required(MESSAGE.qty),
                          // IGST: Yup.string().required(MESSAGE.IGST),
                          // CGST: Yup.string().required(MESSAGE.CGST),
                          //  SGST: Yup.string().required(MESSAGE.SGST),
                          //  UGST: Yup.string().required(MESSAGE.UGST)
                        })
                      ),
                      inVoiceID: Yup.string()
                        .required(MESSAGE.invoiceID)
                        .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                      inVoiceDate: Yup.string().required(MESSAGE.ivDate)
                    })}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      isInitialValid = false,
                      isValid,
                      touched,
                      values,
                      resetForm
                    }) => (
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <Grid container className={classes.root} spacing={1}>
                          <Grid item lg={3} sm={12} xl={4} xs={4} >
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                PO Master ID :{' '}
                                {totalData[0]?.purchaseOrderMasterID}{' '}
                              </h5>
                            </Typography>
                          </Grid>
                          <Grid item lg={3} sm={12} xl={4} xs={4} >
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Total Amount(Without Tax):{' '}
                                {POgrn?.reduce(function (a, b) {
                                  return b.qty * b.unitPrice + a;
                                }, 0)}
                              </h5>
                            </Typography>
                          </Grid>

                          <Grid item lg={3} sm={12} xl={4} xs={4} >
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                PO Raised Date : {dateOnly(totalData[0]?.CreateDate)}
                              </h5>
                            </Typography>
                          </Grid>
                          <Grid item lg={3} sm={12} xl={4} xs={4} >
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Request Quantity : {totalData[0]?.totalQty}
                              </h5>
                            </Typography>
                          </Grid>
                          <Grid item lg={3} sm={12} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Received Quantity : {totalData[0]?.receivedqty}
                              </h5>
                            </Typography>
                          </Grid>
                          <Grid item lg={3} sm={12} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Accepted Quantity : {totalData[0]?.aprovedCount}
                              </h5>
                            </Typography>
                          </Grid>
                          <Grid item lg={3} sm={12} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Rejected Quantity : {totalData[0]?.rejectedCount}
                              </h5>
                            </Typography>
                          </Grid>
                          {/* </Grid> */}
                          {/* <br /> */}
                          {/* <Grid container> */}
                          <Grid item lg={3} sm={4} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment></InputAdornment>
                                )
                              }}
                              error={Boolean(
                                touched.inVoiceID && errors.inVoiceID
                              )}
                              helperText={touched.inVoiceID && errors.inVoiceID}
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e);
                                setTotalData({
                                  ...totalData,
                                  inVoiceID: e.target.value
                                });
                                setTotalDataInvoiceID({
                                  ...totalDataInvoiceID,
                                  inVoiceID: e.target.value
                                });
                              }}
                              inputProps={{
                                maxLength: 20
                              }}
                              value={totalDataInvoiceID.inVoiceID ? totalDataInvoiceID?.inVoiceID : totalData[0]?.inVoiceID}
                              label="Invoice ID *"
                              name="inVoiceID"
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item lg={3} sm={4} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <Typography>Invoice Attachment</Typography>
                            <Button
                              style={{ width: '70%', height: '55px' }}
                              variant="contained"
                              component="label"
                            >
                              {(totalData[0]?.invoiceAttach || totalData?.invoiceAttach) ? '' : ' Attach Here '}
                              <input
                                type="file"
                                hidden
                                onChange={e =>
                                  handleUploadClick(e, 0, 'invoice')
                                }
                              />
                              {(totalData[0]?.invoiceAttach || totalData?.invoiceAttach) && (
                                <a
                                  style={{
                                    color: 'blue',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    marginTop: '5px'
                                  }}
                                  href={
                                    totalData[0].invoiceAttach ? totalData[0].invoiceAttach : totalData?.invoiceAttach
                                  }
                                  target="_blank"
                                >
                                  {totalData[0].invoiceAttach ? totalData[0].invoiceAttach.substring(4, 15) : totalData.invoiceAttach.substring(4, 15)}....
                                  {totalData[0].invoiceAttach ? totalData[0].invoiceAttach.split('.').pop() : totalData.invoiceAttach.split('.').pop()}
                                </a>
                              )}
                            </Button>
                            {totalData[0].invoiceAttach &&
                              <ClearIcon onClick={() => {
                                let p = totalData;
                                p[0].invoiceAttach = ""
                                setTotalData({
                                  ...p,
                                });

                              }} style={{ cursor: 'pointer' }} />
                            }
                            {totalData.invoiceAttach &&
                              <ClearIcon onClick={() => {
                                let p = totalData;
                                setTotalData({
                                  ...p,
                                  invoiceAttach: ""
                                });
                              }} style={{ cursor: 'pointer' }} />
                            }
                          </Grid>

                          <Grid item lg={3} sm={4} xl={4} xs={4} style={{ marginTop: "10px" }}>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              label="Invoice Date"
                              format="dd-MM-yyyy"
                              required
                              disableFuture
                              name="inVoiceDate"
                              onBlur={handleBlur}
                              InputAdornmentProps={{ position: 'end' }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              onChange={date => {
                                handleChange(String(date));
                                setTotalData({
                                  ...totalData,
                                  invoiceDate: date
                                });
                              }}
                              InputProps={{ disabled: true }}
                              value={totalData.invoiceDate ? totalData.invoiceDate : totalData[0].invoiceDate}
                              error={Boolean(
                                touched.inVoiceDate && errors.inVoiceDate
                              )}
                              helperText={
                                touched.inVoiceDate && errors.inVoiceDate
                              }
                            />
                          </Grid>
                        </Grid>

                        <br />

                        <Grid container>
                          <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <GrnlistTwo
                              rowsPerPage={rowsPerPage}
                              page={page}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                              po_id={totalData[0]?.purchaseOrderMasterID}
                              isRefresh={isRefresh}
                            />
                          </Grid>
                        </Grid>
                        <br />


                      </form>
                    )}
                  </Formik>
                )}
              </Grid>
            </>
          )}{' '}
        </>
      </>

      {/* print view start */}
      <GrnPrint openincPrint={openincPrint}
        handleViewGrn={handleViewGrn}
        printOrder={printOrder}
        GRNView={GRNView}
        POApprovalView={POApprovalView}
        print={print} htmlContentRef={htmlContentRef} />
      {/* End */}

      {/* More */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={moreView}
        id="income"
        //  style={{ maxWidth: "1100px !important", width: "1100px" }}
        contentStyle={{
          width: '200%',
          maxWidth: 'none'
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleMoreViewClose}
          style={{
            backgroundColor: '#394671',
            color: '#fff',
            width: '1200px !important'
          }}
        >
          Fixed Asset Management
        </DialogTitle>
        <DialogContent dividers style={{ width: '1200px !important' }}>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Item ID : ITEM-10052021-0012
                  </h5>
                </Typography>
              </div>
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Unique ID"
                name="Unique"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Location"
                name="Location"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Name"
                name="Name"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Category"
                name="Name"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Purchase value (Including GST)"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Warranty / Guarantee details"
                name="Warranty"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Button
                style={{ width: '100%', height: '100%' }}
                variant="contained"
                component="label"
              >
                Warranty / Guarantee
                <input type="file" hidden />
              </Button>
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Estimated Useful life in years"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Rate of Depreciation"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Method Of Depreciation"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText="Estimated residual value at the end of the useful life"
                label="Estimated Residual Value"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                label="Details of Depreciation"
                helperText="Details of Depreciation charged ,year on year"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                label="Accumulated Depreciation"
                helperText="Details of Depreciation charged ,year on year"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                label="Accumulated Depreciation"
                helperText="Details of Depreciation charged ,year on year"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Insurance renewal date"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Button
                style={{ width: '100%', height: '100%' }}
                variant="contained"
                component="label"
              >
                Insurance
                <input type="file" hidden />
              </Button>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <TextField
                fullWidth
                helperText=""
                label="Note"
                name="purchaseValue"
                onChange=""
                required
                value=""
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <App />
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  onClick={handleMoreViewClose}
                  style={{ height: '55px', marginLeft: '20px' }}
                  color="primary"
                  variant="contained"
                >
                  Add
                </Button>
                <Button
                  onClick={handleMoreViewClose}
                  style={{ height: '55px', marginLeft: '20px' }}
                  color="primary"
                  variant="contained"
                >
                  Close
                </Button>
              </div>
            </Grid>

            {/* <Grid
                            item
                            md={4}
                            xs={12}
                        >

                            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Location"
                                >
                                    <MenuItem value={10}>Approved</MenuItem>
                                    <MenuItem value={20}>Rejected</MenuItem>
                                    <MenuItem value={30}>Hold</MenuItem>
                                    <MenuItem value={40}>Modification</MenuItem>
                                    <MenuItem value={50}>Dropped</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
          </Grid>
        </DialogContent>
      </Dialog>
      {/*  More*/}

      {/* payment advice start */}
      {paymentData.pendingAmount != 0 ? (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...payload
            }}
            onSubmit={(values, { resetForm }) => {
              paymentAdd();

            }}
            validationSchema={Yup.object().shape({
              reference: Yup.string().required(MESSAGE.reference),
              paymentMethod: Yup.string().required(MESSAGE.paymentMethod),
              value: Yup.number().required(MESSAGE.value),
              isGstTds: Yup.string().required(MESSAGE.isGstTds),
              isItTaxTds: Yup.string().required(MESSAGE.isItTaxTds),
              purposeAdvance: Yup.string()
                .required(MESSAGE.purposeAdvance)
                .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces)

              // PanCardFrontpic: Yup.string().required(MESSAGE.gstNumber),
              // PanCardBackpic: Yup.string().required(MESSAGE.gstNumber),
              // GSTCertificatepic: Yup.string().required(MESSAGE.gstNumber),
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={payment}
                id="income"
                //  style={{ maxWidth: "1100px !important", width: "1100px" }}
                contentStyle={{
                  width: '200%',
                  maxWidth: 'none'
                }}
                scroll={'paper'}
                maxWidth="lg"
                fullWidth
              >
                <Loader isLoading={isLoading} />
                {/* <div style={{width: '100%',
                    display: 'flex',
                    flexDirection:'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'}}> */}
                <DialogTitle
                  onClose={handlePayView}
                  id="customized-dialog-title"
                  style={{
                    backgroundColor: '#394671',
                    width: '1200px !important',
                    color: '#fff'
                  }}
                >
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <h2>Payment</h2>
                    <h3 onClick={handlePayView}>x</h3>
                  </div>
                </DialogTitle>
                <Form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  style={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <DialogContent
                    dividers
                    style={{ width: '1200px !important' }}
                  >
                    <Grid container spacing={3}>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Invoice ID : {paymentData.invoiceID}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              GRN Master ID : {paymentData.grnMasterID}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Total Amount : {currencyFormat1(Math.round(paymentData.totalItemValue))}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Pending Amount : {currencyFormat1(Math.round(paymentData.pendingAmount))}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Purchaseorder Master ID :{' '}
                              {paymentData.purchaseOrderMasterID}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          <Typography>
                            <h5 style={{ color: '#394671' }}>
                              Total Amount (Tax Exclusive) :{' '}
                              {currencyFormat1(Math.round(paymentData?.totalItemValueNoTax))}
                            </h5>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          {/* <Typography><h5 style={{ color: '#394671' }}>GST Details : {poGST[0]?.gstDetails[0]?.CGST}</h5></Typography> */}
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                          {/* <Typography><h5 style={{ color: '#394671' }}>GST Details : {poGST[0]?.gstDetails[0]?.CGST}</h5></Typography> */}
                        </div>
                      </Grid>

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(
                            touched.paymentMethod && errors.paymentMethod
                          )}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Payment Method *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Payment Method"
                            value={payload.paymentMethod}
                            name="paymentMethod"
                            onBlur={handleBlur}
                            required
                            onChange={e => {
                              handleChange(e);
                              const p = payload;
                              p.paymentMethod = Number(e.target.value);
                              setPayload({
                                ...p
                              });
                              if (e.target.value == 57) {
                                const p = payload;
                                p.value = paymentData.pendingAmount;
                                setPayload({
                                  ...p,
                                  value: paymentData.pendingAmount
                                });
                              } else {
                                const p = payload;
                                p.value = '';
                                setPayload({
                                  ...p,
                                  value: ''
                                });
                              }
                            }}
                          >
                            {lookupData &&
                              lookupData.map(x => {
                                return (
                                  <MenuItem value={x.lookupID} key={x.lookupID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.paymentMethod &&
                              touched.paymentMethod &&
                              errors.paymentMethod}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <FormControl className={classes.app}
                          variant="outlined"
                          // style={{ width: '100%' }}
                          // className={classes.formControl}
                          error={Boolean(touched.vendorID && errors.vendorID)}

                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Vendor *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Location"
                            value={paymentData.vendorID}
                            name="vendorID"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = payload;
                              p.vendorID = Number(e.target.value);
                              setPayload({
                                ...p
                              });
                            }}
                            disabled={true}

                          >
                            {allVendors &&
                              allVendors.map(x => {
                                return (
                                  <MenuItem value={x.vendorID} key={x.vendorID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.vendorID &&
                              touched.vendorID &&
                              errors.vendorID}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} sm={6} xl={6} xs={6}>
                        {paymentData.vendorID ? (
                          <>
                            {' '}
                            <b> Supplier address : </b>
                            <Address
                              details={
                                allVendors?.filter(e => {
                                  if (e.vendorID === paymentData?.vendorID) {
                                    return {
                                      address: e?.address
                                    };
                                  }
                                })[0]?.address
                              }
                            />{' '}
                          </>
                        ) : null}
                      </Grid>

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <TextField
                          fullWidth
                          label="Reference"
                          error={Boolean(touched.reference && errors.reference)}
                          helperText={touched.reference && errors.reference}
                          value={payload.reference}
                          onBlur={handleBlur}
                          required
                          name="reference"
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = payload;
                              p.reference = Number(e.target.value);
                              setPayload({
                                ...p
                              });
                            }
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <TextField
                          fullWidth
                          label="Value"
                          required
                          error={Boolean(touched.value && errors.value)}
                          helperText={touched.value && errors.value}
                          onBlur={handleBlur}
                          value={payload.value}
                          disabled={payload.paymentMethod == 57 ? true : false}
                          InputLabelProps={{ shrink: true }}
                          name="value"
                          onChange={e => {
                            if (
                              (e.target.value === '' ||
                                regEx.numbersOnly.test(e.target.value)) &&
                              e.target.value < Number(paymentData.pendingAmount)
                            ) {
                              handleChange(e);
                              const p = payload;
                              p.value = e.target.value;
                              setPayload({
                                ...p,
                                value: e.target.value
                              });
                            }
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(touched.isGstTds && errors.isGstTds)}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            GST TDS*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Location"
                            required
                            value={payload.isGstTds}
                            name="isGstTds"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = payload;
                              p.isGstTds = e.target.value;
                              setGSTTDS(e.target.value);
                              setPayload({
                                ...p
                              });
                            }}
                          // onChange={(e) => setGSTTDS(e.target.value)}
                          >
                            <MenuItem value={true}>YES</MenuItem>
                            <MenuItem value={false}>NO</MenuItem>
                          </Select>
                          <FormHelperText>
                            {errors.isGstTds &&
                              touched.isGstTds &&
                              errors.isGstTds}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {payload.isGstTds === true && (
                        <Grid item lg={3} sm={3} xl={3} xs={3}>
                          <TextField
                            fullWidth
                            helperText="Percentage"
                            label="GST TDS rate(%)"
                            value={payload.gstTdsRate}
                            name="gstTdsRate"
                            required

                            inputProps={{
                              maxLength: 7
                            }}
                            onChange={e => {
                              if (
                                e.target.value === '' ||
                                regEx.numbersOnly.test(e.target.value)
                              ) {
                                const p = payload;
                                p.gstTdsRate = Number(e.target.value);
                                setPayload({
                                  ...p
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                      {payload.isGstTds === true && (
                        <Grid item lg={3} sm={3} xl={3} xs={3}>
                          <TextField
                            fullWidth
                            helperText="Enter Amount"
                            label="Tax Exemption"
                            inputProps={{
                              maxLength: 7
                            }}
                            disabled={true}
                            value={
                              paymentData?.totalItemValueNoTax *
                              (payload.gstTdsRate / 100)
                            }
                            name="gstTaxExemption"
                            onChange={e => {
                              if (
                                e.target.value === '' ||
                                regEx.numbersOnly.test(e.target.value)
                              ) {
                                const p = payload;
                                p.gstTaxExemption = Number(e.target.value);
                                setPayload({
                                  ...p
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      )}

                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                          error={Boolean(
                            touched.isItTaxTds && errors.isItTaxTds
                          )}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            TAX TDS *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Location"
                            required
                            value={payload.isItTaxTds}
                            name="isItTaxTds"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              const p = payload;
                              p.isItTaxTds = e.target.value;
                              setTAXTDS(e.target.value);
                              setPayload({
                                ...p
                              });
                            }}
                          // value={TAXTDS}
                          // onChange={(e) => setTAXTDS(e.target.value)}
                          >
                            <MenuItem value={true}>YES</MenuItem>
                            <MenuItem value={false}>NO</MenuItem>
                          </Select>
                          <FormHelperText>
                            {errors.isItTaxTds &&
                              touched.isItTaxTds &&
                              errors.isItTaxTds}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {payload.isItTaxTds === true && (
                        <Grid item lg={3} sm={3} xl={3} xs={3}>
                          <TextField
                            fullWidth
                            helperText="Percentage"
                            label="Tax TDS rate (%)"
                            value={payload.itTdsRate}
                            name="itTdsRate"
                            inputProps={{
                              maxLength: 7
                            }}
                            onChange={e => {
                              if (
                                e.target.value === '' ||
                                regEx.numbersOnly.test(e.target.value)
                              ) {
                                const p = payload;
                                p.itTdsRate = Number(e.target.value);
                                setPayload({
                                  ...p
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                      {payload.isItTaxTds === true && (
                        <Grid item lg={3} sm={3} xl={3} xs={3}>
                          <TextField
                            fullWidth
                            helperText="Enter Amount"
                            label="Tax Exemption"
                            disabled={true}
                            value={
                              paymentData?.totalItemValueNoTax *
                              (payload.itTdsRate / 100)
                            }
                            name="itTaxExemption"
                            inputProps={{
                              maxLength: 7
                            }}
                            onChange={e => {
                              if (
                                e.target.value === '' ||
                                regEx.numbersOnly.test(e.target.value)
                              ) {
                                const p = payload;
                                p.itTaxExemption = Number(e.target.value);
                                setPayload({
                                  ...p
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                      <Grid item lg={4} sm={4} xl={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Purpose"
                          value={payload.purposeAdvance}
                          required
                          name="purposeAdvance"
                          error={Boolean(
                            touched.purposeAdvance && errors.purposeAdvance
                          )}
                          helperText={
                            touched.purposeAdvance && errors.purposeAdvance
                          }
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = payload;
                            p.purposeAdvance = e.target.value;
                            setPayload({
                              ...p
                            });
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} sm={3} xl={3} xs={3}>
                        <TextField
                          fullWidth
                          label="Balance Payable"
                          onBlur={handleBlur}
                          value={paymentData.pendingAmount - payload.value}
                          disabled={true}
                          name="balancePayable"
                          onChange={e => {
                            const p = payload;
                            p.balancePayable = Number(e.target.value);
                            setPayload({
                              ...p
                            });
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            color="primary"
                            // onClick={handleAdvancePayModel}
                            // onClick={() => paymentAdd()}
                            type="submit"
                            variant="contained"
                          >
                            Create
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Button
                            onClick={handlePayView}
                            color="primary"
                            variant="contained"
                          >
                            Close
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Form>
              </Dialog>
            )}
          </Formik>
        </>
      ) : (
        <div>
          <Modal
            open={paymentCompleted}
            onClose={handlePayViewCompleted}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <h5 style={{ color: '#394671' }}>
                    Payment request is completed for this goods
                  </h5>
                  <Button
                    style={{ marginTop: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={handlePayViewCompleted}
                  >
                    Close
                  </Button>
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>
      )}
      {/* payment advice end */}

      {/* Add Manually GRN  */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          // ...itemInitialValues,
          ...manuallyAddGRN
        }}
        onSubmit={(values, { resetForm }) => {

          if (manuallyAddGRN.invoiceDate == '') {
            toast.error('Please attach Invoice', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            return;
          }
          addGRNWithoutPO();
          handleAddManually()
        }}
        validationSchema={Yup.object().shape({
          vendorID: Yup.string().required(MESSAGE.vendor),
          invoiceID: Yup.string().required(MESSAGE.invoiceID),
          invoiceDate: Yup.string().required(MESSAGE.ivDate)
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isInitialValid = false,
          isValid,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            contentStyle={{
              width: '100%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
            open={addManually}
            id="income"
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleAddManually}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              GRN
            </DialogTitle>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <DialogContent dividers style={{ overflowX: 'hidden' }}>
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow:
                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <FormControl
                        variant="outlined"
                        // style={{ width: '80%' }}
                        className={classes.vendor}
                        error={Boolean(touched.vendorID && errors.vendorID)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Vendor *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Vendor "
                          name="vendorID"
                          onChange={e => {
                            handleChange(e);
                            let item = { ...manuallyAddGRN };
                            item.vendorID = e.target.value;
                            setManuallyAddGRN({ ...item });
                          }}
                          value={manuallyAddGRN.vendorID}
                          onBlur={handleBlur}
                        >
                          {allVendors &&
                            allVendors.map((vendor, index) => {
                              return (
                                <MenuItem value={vendor.vendorID}>
                                  {vendor.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>
                          {errors.vendorID &&
                            touched.vendorID &&
                            errors.vendorID}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={8} sm={8} xl={8} xs={8}>
                      {manuallyAddGRN.vendorID ? (
                        <>
                          {' '}
                          <b> Supplier address : </b>
                          <Address
                            details={
                              allVendors?.filter(e => {
                                if (e.vendorID === manuallyAddGRN?.vendorID) {
                                  return {
                                    address: e?.address
                                  };
                                }
                              })[0]?.address
                            }
                          />{' '}
                        </>
                      ) : null}
                    </Grid>
                    <Grid item lg={3} sm={3} xl={3} xs={3} className={classes.invoice}>
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        error={Boolean(touched.invoiceID && errors.invoiceID)}
                        helperText={touched.invoiceID && errors.invoiceID}
                        label="Invoice ID"
                        name="invoiceID"
                        required

                        value={manuallyAddGRN.invoiceID}
                        onBlur={handleBlur}
                        onChange={e => {
                          if (e.target.value.match(regEx.noSpecialChar)) {

                            handleChange(e);
                            let item = { ...manuallyAddGRN };
                            item.invoiceID = e.target.value;
                            setManuallyAddGRN({ ...item });
                          }
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} sm={3} xl={3} xs={3}>
                      <Button
                        style={{ width: '100%', height: '80%' }}
                        variant="contained"
                        component="label"
                      >
                        Invoice Attach
                        <input
                          type="file"
                          onChange={e =>
                            handleUploadClick(e, 0, 'invoiceManual')
                          }
                          hidden
                        />
                      </Button>
                      {manuallyAddGRN.invoiceAttach && (
                        <a
                          style={{ color: 'blue', cursor: 'pointer' }}
                          href={
                            manuallyAddGRN.invoiceAttach
                          }
                          target="_blank"
                        >
                          {manuallyAddGRN.invoiceAttach.substring(4, 25)}
                        </a>
                      )}
                    </Grid>
                    <Grid item lg={3} sm={3} xl={3} xs={3}>
                      <KeyboardDatePicker

                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Expire Date "
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: 'end' }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={date => {
                          handleChange(String(date));
                          const p = manuallyAddGRN;
                          p.invoiceDate = date;
                          setManuallyAddGRN({
                            ...p
                          });
                        }}
                        value={manuallyAddGRN?.invoiceDate ?? null}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        onBlur={handleBlur}
                        name="invoiceDate"
                        error={Boolean(
                          touched.invoiceDate && errors.invoiceDate
                        )}
                        helperText={touched.invoiceDate && errors.invoiceDate}
                        InputProps={{ disabled: true }}
                        minDate={moment(new Date())}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '10px' }}>
                    <RadioGroup
                      aria-label="anonymous"
                      name="row-radio-buttons-group"
                      row
                      onChange={handleState}
                    >
                      <FormControlLabel
                        value="Interstate"
                        control={<Radio />}
                        label="Interstate"
                      />
                      <FormControlLabel
                        value="Intrastate"
                        control={<Radio />}
                        label="Intrastate"
                      />
                      <FormControlLabel
                        value="Union"
                        control={<Radio />}
                        label="Union Territory"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <div style={{ textAlign: 'right' }}>
                      <a
                        onClick={addItem}
                        style={{ color: 'blue', cursor: 'pointer' }}
                      >
                        + Add Item
                      </a>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                      <CommonTable
                        columns={columns}
                        data={arrayTableData}
                        action={actions}
                      />
                    </div>
                  </Grid>
                </Box>

                {/* </div> */}
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  color="primary"
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                  // onClick={handleGeneratePO}
                  type="submit"
                  disabled={!arrayTableData.length}
                  variant="contained"
                >
                  Add GRN
                </Button>
                <Button
                  color="primary"
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                  variant="contained"
                  onClick={handleAddManually}
                >
                  Close
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...objAddData
        }}
        onSubmit={(values, { resetForm }) => {
          if (Object.keys(objAddData.val).length === 0) {
            toast.error('Please select Material', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            return;
          }
          if (edit) {
            let item = arrayTableData;
            item[indexData] = {
              ...objAddData,
              totalPrice: objAddData.grnQty * objAddData.unitPrice
            };
            setArrayTableData(item);
            setOpenTest(!openTest);
            setEdit(true);
          } else {
            let item = arrayTableData;
            item.push({
              ...objAddData,
              totalPrice: objAddData.grnQty * objAddData.unitPrice
            });
            setArrayTableData(item);
            setOpenTest(!openTest);
          }
        }}
        validationSchema={Yup.object().shape({
          typeID: Yup.string().required(MESSAGE.type),
          grnQty: Yup.string().required(MESSAGE.qty),
          unitPrice: Yup.string().required(MESSAGE.unitPrice),
          dateofExpiry: Yup.string().required(MESSAGE.doe)
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openTest}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="md"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setOpenTest(!openTest);
                setEdit(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              {edit ? 'Edit' : 'Add'} Item
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3} className={classes.field}>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      error={Boolean(touched.typeID && errors.typeID)}
                      className={classes.type}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="typeID"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          objAddData.val = {};
                          objAddData.typeID = e.target.value;
                          setObjAddData({ ...objAddData });
                          getMaterials(
                            0,
                            objAddData.typeID,
                            projectDetails.projectID
                          ); // project Id hard coded0
                        }}
                        required
                        value={objAddData.typeID}
                        label="Type"
                      >
                        {catOrRec.map(l => {
                          return (
                            <MenuItem value={l.lookupID} key={l.lookupID}>
                              {l.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {errors.typeID && touched.typeID && errors.typeID}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}  >
                    <Grid className={classes.common}>
                      <CommonSelect
                        val={objAddData.val}
                        onChange={e => onChangeInput(e, 0)}
                        options={objAddData.materialList}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="GRN Quantity"
                      name="grnQty"
                      required
                      onBlur={handleBlur}
                      error={Boolean(touched.grnQty && errors.grnQty)}
                      helperText={touched.grnQty && errors.grnQty}
                      onChange={e => {
                        if (e.target.value.match(regEx.numbersOnly)) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.grnQty = Number(e.target.value);
                          setObjAddData({ ...item });
                        }
                      }}
                      value={objAddData.grnQty}
                      variant="outlined"
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Unit Price"
                      name="unitPrice"
                      required
                      onBlur={handleBlur}
                      error={Boolean(touched.unitPrice && errors.unitPrice)}
                      helperText={touched.unitPrice && errors.unitPrice}
                      onChange={e => {
                        if (e.target.value.match(regEx.numbersOnly)) {
                          handleChange(e);
                          let item = { ...objAddData };
                          item.unitPrice = Number(e.target.value);
                          setObjAddData({ ...item });
                        }
                      }}
                      value={objAddData.unitPrice}
                      variant="outlined"
                      inputProps={{ maxLength: 8 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      className={classes.type}
                      error={Boolean(touched.isGST && errors.isGST)}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        GST
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="GST"
                        name="isGST"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          let elem = { ...objAddData };
                          elem.isGST = e.target.value;
                          setObjAddData({ ...elem });
                          if (!e.target.value) {
                            let item = { ...objAddData };
                            item.gstType = 0;
                            item.isGST = e.target.value;
                            setObjAddData({ ...item });
                          }
                        }}
                      // value={e.GST}
                      >
                        <MenuItem value={true}>YES</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.isGST && touched.isGST && errors.isGST}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {objAddData.isGST === true && (
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        helperText=""
                        label="GST Value(%)"
                        onChange={e => {
                          if (
                            e.target.value === '' ||
                            regEx.numbersOnly.test(e.target.value)
                          ) {
                            let item = { ...objAddData };
                            item.gstType = Number(e.target.value);
                            setObjAddData({ ...item });
                          }
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  {inter && objAddData.gstType ? (
                    <>
                      <Grid item md={4} xs={12}>
                        <strong>IGST:</strong> {objAddData.gstType}
                      </Grid>
                    </>
                  ) : null}
                  {intra && objAddData.gstType ? (
                    <>
                      <Grid item md={4} xs={12}>
                        <strong>SGST:</strong> {objAddData.gstType / 2}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <strong>CGST:</strong> {objAddData.gstType / 2}
                      </Grid>
                    </>
                  ) : null}
                  {union && objAddData.gstType ? (
                    <>
                      <Grid item md={4} xs={12}>
                        <strong>UGST:</strong> {objAddData.gstType / 2}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <strong>CGST:</strong> {objAddData.gstType / 2}
                      </Grid>
                    </>
                  ) : null}

                  <Grid item lg={4} sm={4} xl={4} xs={4}  >
                    <Button
                      // style={{ width: '100%', height: '80%' }}
                      className={classes.button}
                      variant="contained"
                      component="label"
                    >
                      Invoice Attach
                      <input
                        type="file"
                        onChange={e =>
                          handleUploadClick(e, 0, 'itemAttachManual')
                        }
                        hidden
                        className={classes.Attach}
                      />
                    </Button>
                    {objAddData.itemAttach && (
                      <a
                        style={{ color: 'blue', cursor: 'pointer' }}
                        href={objAddData.itemAttach}
                        target="_blank"
                      >
                        {objAddData.itemAttach.substring(4, 25)}
                      </a>
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <KeyboardDatePicker
                      className={classes.exp}
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Expire Date  "
                      required
                      format="dd/MM/yyyy"
                      InputAdornmentProps={{ position: 'end' }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={date => {
                        handleChange(String(date));
                        let temp = objAddData;
                        temp.dateofExpiry = date;
                        setObjAddData({ ...temp });
                      }}
                      value={objAddData.dateofExpiry ?? null}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      onBlur={handleBlur}
                      name="dateofExpiry"
                      error={Boolean(
                        touched.dateofExpiry && errors.dateofExpiry
                      )}
                      helperText={touched.dateofExpiry && errors.dateofExpiry}
                      InputProps={{ disabled: true }}
                      minDate={moment(new Date())}
                    />
                  </Grid>
                  <br />
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  onClick={e => {
                    setEdit(false)
                    setOpenTest(!openTest);
                  }}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                >
                  Cancel
                </Button>

                {!edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Add
                  </Button>
                )}
                {edit && (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Update
                  </Button>
                )}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
      <Formik
        initialValues={{
          // ...itemInitialValues,
          ...manuallyAddGRN
        }}
        onSubmit={(values, { resetForm }) => {
          returnGoodsFn();
        }}
        validationSchema={Yup.object().shape({})}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isInitialValid = false,
          isValid,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            contentStyle={{
              width: '100%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
            open={returnView}
            id="income"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleAddManually}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Return Goods
            </DialogTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <DialogContent
                dividers={scroll === 'paper'}
                style={{ overflowX: 'hidden' }}
              >
                <Box
                  mt={3}
                  style={{
                    backgroundColor: '#fff',
                    boxShadow:
                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item md={3} lg={4} sm={12} xs={12}   >
                      <FormControl
                        variant="outlined"
                      // style={{ width: '60%' }}

                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Vendor
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Vendor"
                          name="vendorID"
                          onChange=""
                          disabled={true}
                          value={returnGoods.vendorID}

                        >
                          {allVendors &&
                            allVendors.map((vendor, index) => {
                              return (
                                <MenuItem value={vendor.vendorID}>
                                  {vendor.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={8} sm={12} xl={8} xs={12}>
                      {returnGoods.vendorID ? (
                        <>
                          {' '}
                          <b> Supplier address : </b>
                          <Address
                            details={
                              allVendors?.filter(e => {
                                if (e.vendorID === returnGoods?.vendorID) {
                                  return {
                                    address: e?.address
                                  };
                                }
                              })[0]?.address
                            }
                          />{' '}
                        </>
                      ) : null}
                    </Grid>
                    <Grid item xs={3} lg={3} sm={3} xl={3}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography>
                          <h5 style={{ color: '#394671' }}>
                            Invoice ID : {returnGoods.invoiceID}
                          </h5>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3} lg={3} sm={3} xl={3}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography>
                          <h5 style={{ color: '#394671' }}>
                            GRN Master ID : {returnGoods.grnMasterID}
                          </h5>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3} lg={3} sm={3} xl={3}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography>
                          <h5 style={{ color: '#394671' }}>
                            PurchaseOrder Master ID :{' '}
                            {returnGoods.purchaseOrderMasterID ==
                              'NO Purchase Order ID'
                              ? '-'
                              : returnGoods.purchaseOrderMasterID}
                          </h5>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3} lg={3} sm={3} xl={3}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography>
                          <h5 style={{ color: '#394671' }}>
                            Indent Master ID :{' '}
                            {returnGoods.indentMasterID ?? '-'}
                          </h5>
                        </Typography>
                      </div>
                    </Grid>
                    {returnGoods?.grnItem?.map((it, i) => (
                      <>
                        <Grid item xs={2} lg={2} sm={2} xl={2}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                GRNItemID Master ID : {it.grnItemID}
                              </h5>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2} lg={2} sm={2} xl={2}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Item Name : {it.name}
                              </h5>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2} lg={2} sm={2} xl={2}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography>
                              <h5 style={{ color: '#394671' }}>
                                Received Quantity : {it.grnQty}{' '}
                              </h5>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2} lg={2} sm={2} xl={2}>
                          <TextField
                            fullWidth
                            // error={Boolean(touched.Note && errors.Note)}
                            // helperText={touched.Note && errors.Note}
                            label="Return Quantity"
                            name="returnQty"
                            value={it.returnQty}
                            onBlur={handleBlur}
                            onChange={e => {
                              if (
                                e.target.value === '' ||
                                (regEx.numbersOnly.test(e.target.value) &&
                                  e.target.value <= Number(it.grnQty))
                              ) {
                                handleChange(e);
                                let item = { ...returnGoods };
                                item.grnItem[i].returnQty = Number(
                                  e.target.value
                                );
                                setReturnGoods({ ...item });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={4} lg={4} sm={4} xl={4}>
                          <TextField
                            fullWidth
                            // error={Boolean(touched.Note && errors.Note)}
                            // helperText={touched.Note && errors.Note}
                            label="Return Purpose"
                            name="returnPurpose"
                            value={it.returnPurpose}
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              let item = { ...returnGoods };
                              item.grnItem[i].returnPurpose = e.target.value;
                              setReturnGoods({ ...item });
                            }}
                            variant="outlined"
                            inputProps={{ maxLength: 40 }}
                          />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>

                {/* </div> */}
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  color="primary"
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                  // onClick={handleGeneratePO}
                  disabled={
                    !(
                      returnGoods?.grnItem?.some(e => e.returnQty != '') &&
                      returnGoods?.grnItem?.some(e => e.returnQty != 0)
                    )
                  }
                  type="submit"
                  variant="contained"
                >
                  Add Return Goods
                </Button>
                <Button
                  color="primary"
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                  variant="contained"
                  onClick={() => {
                    handleReturn(0);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>

      {/* AddItem Start */}
      {/* <Dialog aria-labelledby="customized-dialog-title" id="income"
        open={isAddDialog}
        contentStyle={{ width: '200%', maxWidth: 'none' }} maxWidth="lg" fullWidth> */}

      <Dialog
        aria-labelledby="customized-dialog-title" id="income"
        open={isAddDialog}
        contentStyle={{ width: '200%', maxWidth: 'none', }} maxWidth="lg" fullWidth>
        <DialogTitle
          id="customized-dialog-title"
          style={{ backgroundColor: '#394671', color: '#fff' }}>
          Add Item
        </DialogTitle>
        <Loader isLoading={isLoading} />
        <DialogContent dividers style={{ width: '900px !important' }}>
          <AddItem POItemsNew={POItemsNew} POgrnAdd={POgrnAdd} setErrorStatus={setErrorStatus} errorStatus={errorStatus}
            setPOgrnAdd={setPOgrnAdd} handleUploadClick={handleUploadClick} setWarrantyAttachItem={setWarrantyAttachItem}
            warrantyAttachItem={warrantyAttachItem} errorMessage={errorMessage} setErrorMessage={setErrorMessage} 
            projectDetails={projectDetails}/>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button className={classes.button2} onClick={() => submitGrn()}>Save</Button>
          <Button onClick={() => AddCloseModel()} className={classes.button2}>Close</Button>
        </DialogActions>

      </Dialog>
      {/* AddItem End */}

    </Page>
  );
};
export default GRNList;
