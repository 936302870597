import React, { useEffect, useState } from 'react';
import {
  Container, Grid, makeStyles, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Box, Checkbox, MenuItem, FormControl, InputLabel, Select, TextField, RadioGroup, FormControlLabel, Radio, FormGroup, FormHelperText
} from '@material-ui/core';

import { statusColor } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import './style.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RGCA_URLS } from 'src/utils/url';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { ETTypes, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import {
  parseData, dateFormate, getProjectDetails, capitalize
} from '../Common/utilities';
import StepperBox from '../Common/StepperBox.jsx';
import { useParams } from 'react-router-dom';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import { Constant } from '../Common/Constant';
import { getloggedInData } from 'src/utils/helpers';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import Loader from '../../loader/index';
import Address from '../Common/Address';
import DoneIcon from '@mui/icons-material/Done';
import { parseInt } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';



const IndentInitialValues = {
  indentMasterID: '',
  ProjectID: null,
  statusID: null,
  UpdatedBy: null,
  CreatedBy: null,
  quoteUrl: '',
  CreateDate: '',
  UpdatedDate: null,
  projectName: '',
  CreatedByName: '',
  currentApprovals: {
    referOptionView: false,
    referSelected: false,
    name: '',
    indentApprovalSystemID: null,
    indentMasterID: '',
    orderID: null,
    accessRoleID: null,
    runningStatus: null,
    approvalID: null,
    editLock: true,
    CreatedBy: null,
    CreateDate: '',
    comment: '',
    approvalStatusID: null,
    status: ''
  },
  indentItem: [],
  Approvals: []
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  step: {
    '& $completed': {
      color: 'lightgreen'
    },
    '& $active': {
      color: 'pink'
    },
    '& $disabled': {
      color: 'red'
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0
    }
  },
  vendor: {
    [theme.breakpoints.up('md')]: {
      width: "370px"
    },
    '@media (max-width: 1300px)': {
      width: "370px !important"
    },
    '@media (max-width: 800px)': {
      width: "600px"
    },
    '@media (max-width: 500px)': {
      width: "260px"
    },
    '@media (max-width: 350px)': {
      width: "150px"
    },
  },
  gst: {
    [theme.breakpoints.up('md')]: {
      width: "250px"
    },
    '@media (max-width: 800px)': {
      width: "560px"
    },
    '@media (max-width: 500px)': {
      width: "220px"
    },
    '@media (max-width: 350px)': {
      width: "130px"
    },
  }
}));

const RisePurchaseOrder = () => {
  const projectData = useSelector(x => x);
  var proId = projectData.ProjectInfo.project.projectID;
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [indentView, setIndentView] = React.useState(false);
  const [openincPrint, setOpenincPrint] = useState(false);
  const [generatepo, setGeneratePo] = React.useState(false);
  const [PoView, setPoView] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [allVendors, setAllVendors] = useState([]);
  const [allPurchaseOrder, setAllPurchaseOrder] = useState([]);
  const [allIndentList, setAllIndentList] = useState([]);
  const [cpd, setCpd] = useState([]);
  const [step, setStep] = useState([]);
  const [selectedIndent, setSelectedIndent] = useState([]);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) };
  const [item, setItem] = useState({});
  const [approvalStatusList, setApprovalStatusList] = useState([]);
  const [indentItem, setIndentItem] = useState({ ...IndentInitialValues });
  const { accessRoleID = null } = getloggedInData();
  const [direct, setDirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemAttachment, setItemAttachment] = useState('')
  const [gemAttachment, setGemAttachment] = useState('')
  const [allProjects, setAllProjects] = useState([]);
  const [isGem, setIsGem] = useState('vendor')
  const [isNewVendor, setIsNewVendor] = useState()
  const [isAddVendor, setIsAddVendor] = useState(false)
  const [vendor, setVendor] = useState({
    name: '',
    companyType: '',
    companyName: '',
    gstn: '',
    gstnAttach: '',
    panAttach: '',
    pan: '',
    aadharAttach: '',
    aadhar: '',
    email: '',
    mobile: '',
    // vendorID: "",
    address: {
      line_1: '',
      line_2: '',
      district: '',
      state: '',
      country: '',
      postalCode: ''
    },
    bankDetails: {
      bankName: '',
      bankAccountNo: '',
      bankBranch: '',
      bankIFSC: '',
      bankAccountType: '',
      bankAccountHolderName: '',
      cancelChequeAttach: ''
    }
  });
  const [lookupData, setLookupData] = useState([]);
  const [vendorDetailsGem, setVendorDetailsGem] = useState({})
  const [isVenderShow, setIsVenderShow] = useState(true)


  useEffect(() => {
    getIndentAllApprovedList();
    getVendor();
    getLookup();
    getAllProjects();
    getLookupForCompany();
  }, [navigate]);

  const [indentItemList, setIndentItemList] = useState({
    indentMasterID: '',
    ProjectID: null,
    statusID: null,
    UpdatedBy: null,
    CreatedBy: null,
    quoteUrl: '',
    CreateDate: '',
    UpdatedDate: null,
    projectName: '',
    CreatedByName: '',
    indentItem: [],
    Approvals: []
  });

  const [generatePO, setGeneratePO] = useState({
    projectID: '',
    vendorID: '',
    indentMasterID: '',
    stateType: '',
    totalQty: '',
    totalValue: '',
    items: [
      {
        materialItem: '',
        description: '',
        isGST: '',
        SGST: '',
        CGST: '',
        IGST: '',
        UGST: '',
        unitPrice: ''
      }
    ]
  });

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };

  const getListOfPurchaseOrder = () => {
    APIKit.get(
      RGCA_URLS.getPurchaseOrderForIndent + '/' + projectDetails.projectID
    )
      .then(res => {
        if (res.data.status === 200) {
          // setAllPurchaseOrder(res.data.data);
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              purchaseOrderItem: parseData(x.purchaseOrderItem),
              vendorDetails: parseData(x.vendorDetails),
              vendorAddress: parseData(x.vendorDetails)[0].address
            };
          });
          setAllPurchaseOrder(data);
        } else {
        }
      })
      .catch(e => { });
  };

  const handleIndentViewClose = () => {
    setIndentView(!indentView);
  };

  const handlePOViewClose = () => {
    setPoView(!PoView);
  };

  const handleIncPrintClose = () => {
    setOpenincPrint(!openincPrint);
  };

  const [payloadIndent, setPayloadIndent] = useState({});
  const handleGeneratePo = () => {
    setIndentView(false);
    setGeneratePo(!generatepo);
  };

  var obj = {};
  const handleGeneratePO = () => {

    let sumQty = 0;
    let sumUP = 0;

    for (let i = 0; i < selectedIndent.length; i++) {
      sumQty += selectedIndent[i].approvedQty;
    }
    for (let i = 0; i < selectedIndent.length; i++) {
      sumUP += parseInt(selectedIndent[i].unitPrice * selectedIndent[i].approvedQty);
    }

    let payload = {
      projectID: projectDetails.projectID,
      vendorID: payloadIndent.vendorID,
      taxes: payloadIndent.taxes,
      bills: payloadIndent.bills,
      delivery: payloadIndent.delivery,
      payment: payloadIndent.payment,
      contact: payloadIndent.contact,
      subject: payloadIndent.subject,
      reference: payloadIndent.reference,
      indentMasterID: indentItemList.indentMasterID,
      DeliveryProjectID: payloadIndent.DeliveryProjectID ? payloadIndent.DeliveryProjectID : projectDetails.projectID,
      stateType: gstState,
      totalQty: sumQty,
      totalValue: sumUP,
      specificationAttach: itemAttachment,
      gemOrder: isGem === "gem" ? true : false,
      contractNo: payloadIndent?.ContractNo ? payloadIndent?.ContractNo : '',
      generatedDate: payloadIndent?.GeneratedDate ? moment(payloadIndent?.GeneratedDate).format("YYYY-MM-DD") : '',
      gemSellerID: payloadIndent?.GeMSellerID ? payloadIndent?.GeMSellerID : '',
      MSMERegistration: payloadIndent?.MSMERegistrationnumber ? payloadIndent?.MSMERegistrationnumber : '',
      MSESocialCategory: payloadIndent?.MSESocialCategory ? payloadIndent?.MSESocialCategory : '',
      GSTIN: payloadIndent?.GSTIN ? payloadIndent?.GSTIN : '',
      gemAttach: gemAttachment ? gemAttachment : '',
      items: selectedIndent.map(x => {
        return (obj = {
          materialItem: x.itemID,
          description: x.desc,
          isGST: x.isGST,
          SGST: x.gstType / 2,
          CGST: x.gstType / 2,
          IGST: x.gstType / 2,
          UGST: x.gstType / 2,
          unitPrice: x.unitPrice,
          qty: x.approvedQty,
          itemAttach: x.itemAttach ? x.itemAttach : x.itemAttach == null ? '' : '',
          deliveryDate: x?.deliveryEndDate ? x.deliveryEndDate : ''
        });
      })
    };

    if (!inter && !intra && !union) {
      toast.error(MESSAGE.interIntraUNion, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (inter) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: 0,
            CGST: 0,
            IGST: e.gstType,
            UGST: 0,
            unitPrice: e.unitPrice,
            qty: e.approvedQty,
            itemAttach: e.itemAttach ? e.itemAttach : e.itemAttach == null ? '' : '',
            deliveryDate: e?.deliveryEndDate ? e.deliveryEndDate : ''
          };
        })
      };
    } else if (intra) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: e.gstType / 2,
            CGST: e.gstType / 2,
            IGST: 0,
            UGST: 0,
            unitPrice: e.unitPrice,
            qty: e.approvedQty,
            itemAttach: e.itemAttach ? e.itemAttach : e.itemAttach == null ? '' : '',
            deliveryDate: e?.deliveryEndDate ? e.deliveryEndDate : ''

          };
        })
      };
    } else if (union) {
      payload = {
        ...payload,
        items: selectedIndent.map(e => {
          return {
            materialItem: e.itemID,
            description: e.desc,
            isGST: e.isGST,
            SGST: 0,
            CGST: e.gstType / 2,
            IGST: 0,
            UGST: e.gstType / 2,
            unitPrice: e.unitPrice,
            qty: e.approvedQty,
            itemAttach: e.itemAttach ? e.itemAttach : e.itemAttach == null ? '' : '',
            deliveryDate: e?.deliveryEndDate ? e.deliveryEndDate : ''
          };
        })
      };
    }

    setIsLoading(true);
    console.log("payload", payload);
    APIKit.post(RGCA_URLS.addPurchaseOrderForIndentBasedonItems, payload)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.purchaseOrderApporoved, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            getIndentAllApprovedList();
            setIsLoading(false);
            setIndentView(false);
            setGeneratePo(!generatepo);
          }, 3000);
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) {
        setIsLoading(false);
      });
  };

  const [columns, setColumns] = useState([
    {
      title: 'Indent ID',
      field: 'indentMasterID',
      align: 'left',
      type: ETTypes.string
    },
    // {
    //     title: "Project Name",
    //     field: "projectName",
    //     align: 'left',
    //     type: ETTypes.string,
    // },
    // {
    //     title: "Quote",
    //     field: "quoteUrl",
    //     align: 'left',
    //     type: ETTypes.link,
    // },
    {
      title: 'Created By',
      field: 'CreatedByName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Created Date',
      field: 'CreateDate',
      align: 'left',
      type: ETTypes.date
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onView]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  var num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  });


  const POApprovalView = [
    {
      title: 'PO Item ID',
      field: 'purchaseOrderItemID',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Material Name',
      field: 'name',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'GST',
      field: 'isGST',
      align: 'left',
      type: ETTypes.boolean
    },
    {
      title: 'IGST',
      field: 'IGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'CGST',
      field: 'CGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'SGST',
      field: 'SGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UGST',
      field: 'UGST',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'UOM',
      field: 'uom',
      align: 'left',
      type: ETTypes.numeric
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Total Price(Inclusive GST)',
      field: 'totalAmount',
      align: 'left',
      type: ETTypes.currency
    },
    {
      title: 'Description',
      field: 'description',
      align: 'left',
      type: ETTypes.string
    }
  ];

  const totalQty = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + x.approvedQty;
    });
    return test;
    //  data['totalQty'] = test;
  };

  const totalPrice = (data = []) => {
    let test = 0;
    data.map(x => {
      test = test + (x.estimatedPrice * x.approvedQty);
    });
    return test.toLocaleString('en-IN');
  };

  const actions = {
    onView: (index, row) => {
      setIndentItemList({ ...row });
      handleIndentViewClose();
      setSelectedIndent([]);
      setPayloadIndent({});
      setInter(false);
      setIntra(false);
      setUnion(false);
    },
    onEdit: (index, row) => {
      handleIndentViewClose();
    }
  };

  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/` + Constant.indentStatus.lookupID)
      .then(res => {
        if (res.data.status === 200) {
          setApprovalStatusList(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
        <style>
        
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    
    vertical-align: top;
}



.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
    text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
    /* text-align: left; */
}
        </style>
        </head><body>'${printableElements}'</body></html>`;
    // console.log(html)
    // document.body.innerHTML = html
    window.print();
    // document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    // window.location.reload()
  };


  const getIndentAllApprovedList = () => {
    APIKit.get(
      `${RGCA_URLS.getIndentAllApprovedList}/${projectDetails.projectID}`
    )
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              // Approvals: parseData(x.Approvals),
              indentItem: parseData(x.indentItem)
            };
          });
          setAllIndentList(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };

  const handleCheckBoxClick = (event, selectedRow) => {
    if (event.target.checked) {
      const newArr = [...selectedIndent];
      selectedRow.isGST = '';
      selectedRow.unitPrice = '';
      newArr.push(selectedRow);
      setSelectedIndent(newArr);
    } else {
      const tempArr = [...selectedIndent];
      tempArr.filter((e, index) => {
        if (e.indentItemID === selectedRow.indentItemID) {
          tempArr.splice(index, 1);
          setSelectedIndent(tempArr);
        }
      });
    }
  };

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9\s.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/,
  };

  const [inter, setInter] = useState(false);
  const [intra, setIntra] = useState(false);
  const [union, setUnion] = useState(false);
  const [gstState, setGstState] = useState('');
  const [dis, setdis] = useState(true)
  const handleState = e => {
    if (e.target.value === 'Interstate') {
      setInter(true);
      setIntra(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Intrastate') {
      setIntra(true);
      setInter(false);
      setUnion(false);
      setGstState(e.target.value);
    } else if (e.target.value === 'Union') {
      setUnion(true);
      setInter(false);
      setIntra(false);
      setGstState(e.target.value);
    }
  };

  const updatePOApproval = e => {
    let referOptionPayload = false;
    let referPayload = false;
    if (indentItem.currentApprovals.referOptionView == 0) {
      referOptionPayload = false;
    } else {
      referOptionPayload = true;
    }
    if (indentItem.currentApprovals.referSelected == 0) {
      referPayload = false;
    } else {
      referPayload = true;
    }
    const payload = {
      statusID: indentItem.currentApprovals.approvalStatusID,
      purchaseOrderMasterID: indentItem.purchaseOrderMasterID,
      comment: indentItem.currentApprovals.comment,
      referOption: referOptionPayload,
      refer: referPayload
    };
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateApprovalForPurchaseOrder, payload)
      .then(res => {
        if (res.data.status === 200) {
          // getIndentApprovalList()
          toast.success(MESSAGE.cpdStatus, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            getListOfPurchaseOrder();
            handlePOViewClose();
            setIsLoading(false);
          }, 3000);
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (e) { });
  };

  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects)
      .then(res => {
        if (res.data.status === 200) {
          console.log('kkk', res.data.data);
          setAllProjects(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };


  const handleUploadAttach = (event, type, wat) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] === undefined) {
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("files", event.target.files[0], event.target.files[0].name);
    if (type == "technical") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setItemAttachment(res?.data?.data)
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    if (type == "GEM") {
      APIKit.post(RGCA_URLS.upload, formData)
        .then((res) => {
          if (res.data.status == 200) {
            setIsLoading(false);
            toast.success(MESSAGE.fileUpload, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setGemAttachment(res?.data?.data)
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

  };

  const getLookupForCompany = () => {
    APIKit.get(`${RGCA_URLS.lookup}/4`)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };
  // console.log("selectedIndent",selectedIndent);
  // console.log("indentItemList.indentItem",indentItemList.indentItem);


  const getNewVendor = () => {
    setIsLoading(true)
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          if (res.data.data.length > 0) {
            setAllVendors(res.data.data);

            let item = { ...payloadIndent };
            item.vendorID = res.data.data[0]?.vendorID
            setPayloadIndent({ ...item })
            const { companyName, mobile, email } = res.data.data[0];
            let vendorDetailsname = { companyName, mobile, email };
            setVendorDetailsGem(vendorDetailsname);
            setIsLoading(false)
          } else {
            setIsLoading(false)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch(e => {
        setIsLoading(false)
      });
  };

  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <ToastContainer />
      <Box>
        <Typography>
          <h2 style={{ color: '#394671' }}>Rise A New Purchase Order</h2>
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <CommonTable
                columns={columns}
                data={allIndentList}
                action={actions}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={indentView}
          id="income"
          contentstyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleIndentViewClose}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '800px !important'
            }}
          >
            View
          </DialogTitle>
          <DialogContent dividers style={{ width: '800px !important' }}>
            <Grid container spacing={3}>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    <Typography>
                      Indent Id : {indentItemList.indentMasterID}
                    </Typography>
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    <Typography>
                      Total Quantity:{' '}
                      {totalQty(indentItemList.indentItem || [])}
                    </Typography>
                  </h5>
                </div>
              </Grid>

              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    <Typography>
                      Date : {dateFormate(indentItemList.CreateDate)}
                    </Typography>
                  </h5>
                </div>
              </Grid>
              {/* ---------------------------------------------------------------------------------------- */}

              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: '#394671' }}>
                    <Typography>
                      Total Estimated Price:{' '}
                      {totalPrice(indentItemList.indentItem || [])}
                    </Typography>
                  </h5>
                </div>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ color: statusColor('PENDING') }}>
                    <Typography>
                      Status: {indentItemList.status}
                    </Typography>
                  </h5>
                </div>
              </Grid>

              <Grid item lg={4} sm={4} xl={4} xs={4}>
                {/* <div style={{ textAlign: "center" }}>
              <Typography><h5 style={{ color: '#394671' }}>Last Update : 10/05/2021 10:20AM</h5></Typography>
            </div> */}
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    {/* <CommonTable columns={updateColumns} data={indentItemList.indentItem} action={modelActions} /> */}

                    <TableContainer>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Material</TableCell>
                            <TableCell>UOM</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Estimated Item Price</TableCell>
                            <TableCell>Estimated Price for all Items</TableCell>
                            <TableCell>Attachment</TableCell>
                            <TableCell>Description</TableCell>
                            {/* <TableCell>
                          PO Status
                        </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {indentItemList.indentItem &&
                            indentItemList.indentItem.length > 0 ? (
                            indentItemList.indentItem.map((ind, index) => (
                              <TableRow key={index}
                                hover
                              // key={emplyoee.id}
                              // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                              >
                                <TableCell>
                                  {ind.POGenerated == 1 ? (
                                    <Checkbox
                                      onClick={e => {
                                        handleCheckBoxClick(e, ind);
                                      }}
                                    ></Checkbox>
                                  ) : (
                                    <DoneIcon />
                                  )}
                                </TableCell>
                                <TableCell>{ind.categoryName}</TableCell>
                                <TableCell>{ind.name}</TableCell>
                                <TableCell>{ind.uom}</TableCell>
                                <TableCell>{ind.approvedQty}</TableCell>
                                <TableCell>
                                  {ind.estimatedPrice.toLocaleString('en-IN')}
                                </TableCell>
                                <TableCell>
                                  {(ind.approvedQty * ind.estimatedPrice).toLocaleString('en-IN')}
                                </TableCell>
                                <TableCell> <a style={{ color: 'blue', cursor: 'pointer' }}
                                  href={ind.itemAttach} target="_blank"
                                >{ind.itemAttach ? ind.itemAttach.substring(4, 25) : ''}
                                </a></TableCell>
                                <TableCell>{ind.comment ? ind.comment : ' '}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow hover role="checkbox" key={1}>
                              <TableCell colSpan={7} align="center" key={2}>
                                {'Indent item is deleted by the creator'}
                              </TableCell>{' '}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>

              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Box>
                  {/* <StepperBox steps={indentItemList.Approvals} /> */}
                </Box>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleGeneratePo}
                    variant="contained"
                    disabled={!selectedIndent.length}
                  >
                    GO TO PO REQUEST
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleIndentViewClose}
                    variant="contained"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={generatepo}
          id="income"
          contentstyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
        >
          <Loader isLoading={isLoading} />
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleGeneratePo}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              width: '800px !important'
            }}
          >
            PO Request
          </DialogTitle>

          <Formik
            initialValues={{
              // ...itemInitialValues,
              vendor: payloadIndent.vendorID,
              taxes: payloadIndent.taxes,
              bills: payloadIndent.bills,
              delivery: payloadIndent.delivery,
              payment: payloadIndent.payment,
              contact: payloadIndent.contact,
              subject: payloadIndent.subject,
              reference: payloadIndent.reference,
              pogenerate: [...selectedIndent],
              ContractNo: payloadIndent.ContractNo,
              isGem: isGem,
              GeMSellerID: payloadIndent.GeMSellerID,
              GeneratedDate: payloadIndent.GeneratedDate,
              MSMERegistrationnumber: payloadIndent.MSMERegistrationnumber,
              MSESocialCategory: payloadIndent.MSESocialCategory,
              GSTIN: payloadIndent.GSTIN

            }}
            onSubmit={(values, { resetForm }) => {
              console.log("values", values);
              handleGeneratePO();
            }}
            validationSchema={Yup.object().shape({
              pogenerate: Yup.array().of(
                Yup.object().shape({
                  isGST: Yup.string().required(MESSAGE.gstsno),
                  unitPrice: Yup.string().required(MESSAGE.unitPrice),
                  // deliveryEndDate:Yup.date().required('Delivery End Date is required')
                })
              ),
              vendor: Yup.string().required(MESSAGE.vendor),
              isGem: Yup.string()
                .required('Please select a portal (MIS or GEM).')
                .oneOf(['vendor', 'gem'], 'Invalid portal selection.'),
              ContractNo: Yup.string().when('isGem', {
                is: (value) => value === 'gem',
                then: Yup.string().required('Contract No is required when GEM Portal is selected'),
              }),
              GeMSellerID: Yup.string().when('isGem', {
                is: (value) => value === 'gem',
                then: Yup.string().required('GeM Seller ID No is required when GEM Portal is selected'),
              }),
              // GeneratedDate: Yup.string().when('isGem', {
              //   is: (value) => value === 'gem',
              //   then: Yup.string().required('Contract No is required when GEM Portal is selected'),
              // }),
              MSMERegistrationnumber: Yup.string().when('isGem', {
                is: (value) => value === 'gem',
                then: Yup.string().required('MSME Registration Number is required when GEM Portal is selected'),
              }),
              MSESocialCategory: Yup.string().when('isGem', {
                is: (value) => value === 'gem',
                then: Yup.string().required('MSESocial Category is required when GEM Portal is selected'),
              }),
              GSTIN: Yup.string().when('isGem', {
                is: (value) => value === 'gem',
                then: Yup.string().required('GSTIN is required when GEM Portal is selected'),
              }),

            })}
          >
            {({
              errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
            }) => (
              <DialogContent dividers style={{ width: '800px !important' }}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <FormControl component="fieldset">
                          <RadioGroup row aria-label="position" name="position">
                            <FormControlLabel
                              value="vendor"
                              control={<Radio checked={isGem === 'vendor'} color="primary" name='isGem' onChange={(e) => {
                                setValues({ ...values, isGem: e.target.value });
                                setIsVenderShow(true)
                                setIsGem('vendor');
                              }} />}
                              label="MIS Portal"
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              value="gem"
                              control={<Radio checked={isGem === 'gem'} color="primary" name='isGem' onChange={(e) => {
                                setValues({ ...values, isGem: e.target.value });
                                setIsVenderShow(false)
                                setIsGem('gem');
                              }} />}
                              label="GEM Portal"
                              labelPlacement="start"
                            />
                          </RadioGroup>
                          <FormHelperText>
                            {errors.isGem && touched.isGem && errors.isGem}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {isGem == 'gem' &&
                        <>
                          <Grid item md={4} xs={12}>
                            <FormControl
                              variant="outlined"
                              // style={{ width: '100%' }}
                              className={classes.vendor}
                            // error={Boolean(touched.vendor && errors.vendor)}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                New Vendor / Existing Vendor
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="New Vendor / Existing Vendor"
                                name="vendor"
                                value={isNewVendor}
                                onChange={(e) => {
                                  setIsNewVendor(e.target.value)
                                  if (e.target.value == false) {
                                    setIsVenderShow(true)
                                  } else {
                                    setIsVenderShow(false)
                                  }
                                }}
                              >
                                <MenuItem value={false}>Existing Vendor</MenuItem>
                                <MenuItem value={true}>New Vendor</MenuItem>
                              </Select>
                              {/* <FormHelperText>
                                {errors.vendor && touched.vendor && errors.vendor}
                              </FormHelperText> */}
                            </FormControl>
                          </Grid>
                          {isNewVendor &&
                            <Grid item md={4} xs={12}>
                              <Button
                                style={{
                                  backgroundColor: '#394671',
                                  color: '#fff',
                                  border: '1px solid',
                                  marginTop: '10px'
                                }}
                                onClick={() => {
                                  setIsAddVendor(true)
                                }}
                              >
                                Add New Vendor
                              </Button>
                            </Grid>
                          }
                        </>
                      }
                    </Grid>

                    <Grid container spacing={3}>
                      {isVenderShow &&
                        <Grid item md={4} lg={4} xs={12}>
                          <FormControl
                            variant="outlined"
                            // style={{ width: '100%' }}
                            className={classes.vendor}
                            error={Boolean(touched.vendor && errors.vendor)}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Vendor *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Vendor*"
                              name="vendor"
                              inputProps={{ maxLength: 100 }}
                              defaultValue={payloadIndent?.vendorID}
                              value={payloadIndent?.vendorID}
                              onChange={e => {
                                handleChange(e);
                                let item = { ...payloadIndent };
                                item.vendorID = e.target.value;
                                setPayloadIndent({ ...item });
                                const selectedVendor = allVendors?.find(vendor => vendor.vendorID === e.target.value);
                                if (selectedVendor) {
                                  const { companyName, mobile, email } = selectedVendor;
                                  let vendorDetailsname = { companyName, mobile, email };
                                  setVendorDetailsGem(vendorDetailsname)
                                  console.log("Selected Vendor Details:", vendorDetailsname);
                                }

                              }}
                              onBlur={handleBlur}
                            >
                              {allVendors &&
                                allVendors.map((vendor, index) => {
                                  return (
                                    <MenuItem key={index} value={vendor.vendorID}>
                                      {vendor.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText>
                              {errors.vendor && touched.vendor && errors.vendor}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      }
                      {payloadIndent?.vendorID ? (
                        <Grid item md={3} lg={3} xs={12}>
                          <>
                            <b> Vendor address : </b>
                            <Address
                              details={
                                allVendors?.filter(e => {
                                  if (e.vendorID === payloadIndent?.vendorID) {
                                    return {
                                      address: e?.address
                                    };
                                  }
                                })[0]?.address
                              }
                            />
                           <p> <b>E-Mail : </b> <span>{vendorDetailsGem?.email}</span></p>
                          </>
                        </Grid>
                      ) : null}
                      <Grid item md={5} lg={5} xs={12} ps={5}>
                        <RadioGroup
                          aria-label="anonymous"
                          name="row-radio-buttons-group"
                          row
                          onChange={handleState}
                        >
                          <FormControlLabel
                            value="Interstate"
                            control={<Radio />}
                            label="Interstate"
                          />
                          <FormControlLabel
                            value="Intrastate"
                            control={<Radio />}
                            label="Intrastate"
                          />
                          <FormControlLabel
                            value="Union"
                            control={<Radio />}
                            label="Union Territory"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label="Subject"
                            name="subject"
                            inputProps={{ maxLength: 60 }}

                            onChange={(e) => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                let item = payloadIndent  //{ ...payloadIndent };
                                item.subject = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.subject}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label=" Reference : e-File Number (OR) Purchase Requisition Number"
                            name="reference"
                            inputProps={{ maxLength: 60 }}

                            onChange={(e) => {
                              // if (e.target.value.match(regEx.numbersOnly)) {
                              let item = payloadIndent  //{ ...payloadIndent };
                              item.reference = e.target.value;
                              setPayloadIndent({ ...item });
                              // }
                            }}
                            value={payloadIndent.reference}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {isGem == 'gem' &&
                      <>
                        <Box
                          mt={3}
                          style={{
                            backgroundColor: '#fff',
                            boxShadow:
                              '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                            borderRadius: '5px',
                            padding: '20px'
                          }}
                        >
                          <Grid container spacing={4}>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="Contract No"
                                  name="ContractNo"
                                  inputProps={{ maxLength: 60 }}
                                  variant="outlined"
                                  onChange={(e) => {
                                    handleChange(e);
                                    // if (e.target.value.match(regEx.noSpecialChar)) {
                                    let item = payloadIndent  //{ ...payloadIndent };
                                    item.ContractNo = e.target.value;
                                    setPayloadIndent({ ...item });

                                    // }
                                  }}
                                  value={payloadIndent?.ContractNo}
                                  error={Boolean(touched.ContractNo && errors.ContractNo)}
                                  helperText={touched.ContractNo && errors.ContractNo}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <KeyboardDatePicker
                                  className={classes.dat}
                                  autoOk
                                  InputProps={{ disabled: true }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  // maxDate={new Date}
                                  disablePast
                                  variant="inline"
                                  inputVariant="outlined"
                                  label="Generated Date"
                                  format="dd/MM/yyyy"
                                  name="GeneratedDate"
                                  InputAdornmentProps={{ position: 'end' }}
                                  onChange={(e) => {
                                    // handleChange(e);

                                    let item = payloadIndent  //{ ...payloadIndent };
                                    item.GeneratedDate = e;
                                    setPayloadIndent({ ...item });
                                  }}
                                  value={payloadIndent?.GeneratedDate ?? null}
                                  error={Boolean(touched.GeneratedDate && errors.GeneratedDate)}
                                  helperText={touched.GeneratedDate && errors.GeneratedDate}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <strong>Seller Details</strong>
                          </Typography>
                          <Grid container spacing={4}>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="GeM Seller ID"
                                  name="GeMSellerID"
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => {
                                    handleChange(e);

                                    if (e.target.value.match(regEx.noSpecialChar)) {
                                      let item = payloadIndent
                                      item.GeMSellerID = e.target.value;
                                      setPayloadIndent({ ...item });
                                    }
                                  }}
                                  value={payloadIndent?.GeMSellerID}
                                  variant="outlined"
                                  error={Boolean(touched.GeMSellerID && errors.GeMSellerID)}
                                  helperText={touched.GeMSellerID && errors.GeMSellerID}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="Company Name"
                                  name="CompanyName"
                                  inputProps={{ maxLength: 60 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // onChange={(e) => {
                                  //   if (e.target.value.match(regEx.numbersOnly)) {
                                  //     let item = payloadIndent 
                                  //     item.reference = e.target.value;
                                  //     setPayloadIndent({ ...item });
                                  //   }
                                  // }}
                                  value={vendorDetailsGem?.companyName}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="Contact No"
                                  name="ContactNo"
                                  inputProps={{ maxLength: 60 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // onChange={(e) => {
                                  //   if (e.target.value.match(regEx.numbersOnly)) {
                                  //     let item = payloadIndent 
                                  //     item.reference = e.target.value;
                                  //     setPayloadIndent({ ...item });
                                  //   }
                                  // }}
                                  value={vendorDetailsGem.mobile}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="Email ID"
                                  name="Email ID"
                                  inputProps={{ maxLength: 60 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // onChange={(e) => {
                                  //   if (e.target.value.match(regEx.numbersOnly)) {
                                  //     let item = payloadIndent 
                                  //     item.reference = e.target.value;
                                  //     setPayloadIndent({ ...item });
                                  //   }
                                  // }}
                                  value={vendorDetailsGem?.email}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="MSME Registration number"
                                  name="MSMERegistrationnumber"
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => {
                                    handleChange(e);

                                    // if (e.target.value.match(regEx.noSpecialChar)) {
                                    let item = payloadIndent
                                    item.MSMERegistrationnumber = e.target.value;
                                    setPayloadIndent({ ...item });

                                    // }
                                  }}
                                  error={Boolean(touched.MSMERegistrationnumber && errors.MSMERegistrationnumber)}
                                  helperText={touched.MSMERegistrationnumber && errors.MSMERegistrationnumber}
                                  value={payloadIndent?.MSMERegistrationnumber}

                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="MSE Social Category"
                                  name="MSESocialCategory"
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => {
                                    handleChange(e);

                                    if (e.target.value.match(regEx.noSpecialChar)) {

                                      let item = payloadIndent
                                      item.MSESocialCategory = e.target.value;
                                      setPayloadIndent({ ...item });
                                    }
                                  }}
                                  value={payloadIndent?.MSESocialCategory}
                                  error={Boolean(touched.MSESocialCategory && errors.MSESocialCategory)}
                                  helperText={touched.MSESocialCategory && errors.MSESocialCategory}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                              >
                                <TextField
                                  fullWidth
                                  label="GSTIN"
                                  name="GSTIN"
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.value.match(regEx.noSpecialChar)) {
                                      let item = payloadIndent
                                      item.GSTIN = e.target.value;
                                      setPayloadIndent({ ...item });
                                    }
                                  }}
                                  value={payloadIndent?.GSTIN}
                                  error={Boolean(touched.GSTIN && errors.GSTIN)}
                                  helperText={touched.GSTIN && errors.GSTIN}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={4} sm={12} xl={12} xs={12}>
                            <Typography><strong>GEM Attachment</strong></Typography>
                              <Button
                                style={{ width: "85%", height: "55px" }}
                                variant="contained"
                                component="label"
                              >
                                {!gemAttachment ? "Attach Here " : null}
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) =>
                                    handleUploadAttach(e, "GEM", "add")
                                  }
                                />
                                {gemAttachment && (
                                  <a
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      position: "absolute",
                                      marginTop: "9px",
                                    }}
                                    href={
                                      gemAttachment
                                    }
                                    target="_blank"
                                  >
                                    {gemAttachment?.substring(4, 25)}....
                                    {gemAttachment?.split(".").pop()}
                                  </a>
                                )}
                              </Button>
                              {gemAttachment &&
                                <ClearIcon onClick={() => {
                                  setGemAttachment('')
                                }} style={{ cursor: 'pointer' }} />
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    }

                    <FieldArray name="pogenerate">
                      {({ push, remove }) => (
                        <div>
                          {values.pogenerate.map((e, i) => {
                            const isGST = `pogenerate[${i}].isGST`;
                            const touchedGST = getIn(touched, isGST);
                            const errorGST = getIn(errors, isGST);
                            const unitPrice = `pogenerate[${i}].unitPrice`;
                            const touchedunitPrice = getIn(touched, unitPrice);
                            const errorunitPrice = getIn(errors, unitPrice);
                            return (
                              <div key={e.id}>
                                <Box
                                  mt={3}
                                  style={{
                                    backgroundColor: '#fff',
                                    boxShadow:
                                      '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                  }}
                                >
                                  <Grid container spacing={3}>
                                    <Grid item md={3} xs={12}>
                                      <strong>Category Name: </strong>
                                      {e.categoryName}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <strong>Material Name: </strong>{e.name}
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                      <strong>UOM: </strong>{e.uom}
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                      <strong>Quantity: </strong>{e.approvedQty}
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <strong>Estimated Price: </strong>{e.estimatedPrice}

                                      <Tooltip title="Estimated price given while raising indent">
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>

                                    </Grid>
                                  

                                    <Grid item md={3} xs={12}>
                                      <FormControl
                                        variant="outlined"
                                        // style={{ width: '100%' }}
                                        className={classes.gst}
                                        error={Boolean(touchedGST && errorGST)}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          GST *
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          label="GST*"
                                          name={isGST}
                                          onBlur={handleBlur}
                                          onChange={e => {
                                            handleChange(e);
                                            let elem = [...selectedIndent];
                                            elem[i].isGST = e.target.value;
                                            setSelectedIndent([...elem]);
                                            if (!e.target.value) {
                                              let item = [...selectedIndent];
                                              item[i].gstType = 0;
                                              setSelectedIndent([...item]);
                                            }
                                          }}
                                        // value={e.GST}
                                        >
                                          <MenuItem value={true}>YES</MenuItem>
                                          <MenuItem value={false}>NO</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                          {touchedGST && errorGST
                                            ? errorGST
                                            : ''}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    {selectedIndent[i].isGST && (
                                      <Grid item md={3} xs={12}>
                                        <TextField
                                          disabled={dis ? false : true}
                                          fullWidth
                                          helperText=""
                                          onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                          }}
                                          min={1}
                                          inputProps={{ maxLength: 3 }}
                                          label="GST Value(%)"
                                          required={isGST}
                                          type='number'
                                          max="3"
                                          onChange={e => {
                                            // const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                                            // if (onlyNums.length < 1) {
                                            //     this.setState({ value: onlyNums });
                                            // } else if (onlyNums.length === 10) {
                                            //     const number = onlyNums.replace(
                                            //         /(\d{3})(\d{3})(\d{4})/,
                                            //         '($1) $2-$3'
                                            //     );
                                            //     this.setState({ value: number });
                                            // }
                                            // if (e.target.value === '' && regEx.numbersOnly.test(e.target.value)) {                                             
                                            if (e.target.value.match(regEx.noSpecialChar || isNaN(e.target.value))) {
                                              const re = /^[0-9.\b]+$/;

                                              if (e.target.value === '' || re.test(e.target.value)) {
                                                if (parseInt(e.target.value) > 0) {

                                                  let item = [...selectedIndent];
                                                  item[i].gstType = Number(e.target.value);
                                                  setSelectedIndent([...item]);
                                                } else {
                                                  toast.error("Value is grater than zero", {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                  })
                                                }
                                              }
                                            }
                                          }}
                                          variant="outlined"
                                        />

                                      </Grid>

                                    )}
                                    {inter && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>IGST:</strong>{' '}
                                          {selectedIndent[i].gstType}
                                        </Grid>
                                      </>
                                    ) : null}
                                    {intra && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>SGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <strong>CGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                      </>
                                    ) : null}
                                    {union && selectedIndent[i].gstType ? (
                                      <>
                                        <Grid item md={3} xs={12}>
                                          <strong>UGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <strong>CGST:</strong>{' '}
                                          {selectedIndent[i].gstType / 2}
                                        </Grid>
                                      </>
                                    ) : null}
                                    <Grid item md={3} xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Unit Price"
                                        name={unitPrice}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 10 }}
                                        helperText={
                                          touchedunitPrice && errorunitPrice
                                            ? errorunitPrice
                                            : ''
                                        }
                                        error={Boolean(
                                          touchedunitPrice && errorunitPrice
                                        )}
                                        onChange={e => {

                                          if (e.target.value.match(regEx.noSpecialChar)) {
                                            const re = /^[0-9\b]+$/;
                                            if (
                                              e.target.value === '' ||
                                              re.test(e.target.value)
                                            ) {
                                              // if(Number(e.target.value) > 0){
                                              handleChange(e);
                                              let item = [...selectedIndent];
                                              item[i].unitPrice = e.target.value;
                                              setSelectedIndent([...item]);
                                              // }
                                            }
                                          }
                                        }}
                                        value={selectedIndent[i].unitPrice}
                                        required
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <TextField
                                        fullWidth
                                        helperText=""
                                        label="Description"
                                        name="price"
                                        inputProps={{ maxLength: 30 }}

                                        onChange={e => {
                                          let item = [...selectedIndent];
                                          item[i].desc = e.target.value;
                                          setSelectedIndent([...item]);

                                        }}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <KeyboardDatePicker
                                        className={classes.dat}
                                        autoOk
                                        InputProps={{ disabled: true }}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        // maxDate={new Date}
                                        disablePast
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Delivery End Date "
                                        format="dd/MM/yyyy"
                                        name="deliveryEndDate "
                                        value={selectedIndent[i].deliveryEndDate ?? null}
                                        InputAdornmentProps={{ position: 'end' }}
                                        onChange={date => {
                                          let item = [...selectedIndent];
                                          item[i].deliveryEndDate = date;
                                          setSelectedIndent([...item]);
                                        }}
                                        required
                                      // helperText={
                                      //   touchedunitdeliveryEndDate && errorunitdeliveryEndDate
                                      //     ? errorunitdeliveryEndDate
                                      //     : ''
                                      // }
                                      // error={Boolean(
                                      //   errorunitdeliveryEndDate && errorunitdeliveryEndDate
                                      // )}

                                      />
                                    </Grid>
                                    <Grid item md={6} xs={12} style={{marginTop:'10px'}}>
                                      <strong>Technical Specification Attachment : </strong>
                                      <a
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          marginTop: "9px",
                                        }}
                                        href={e.itemAttach}
                                        target="_blank"
                                      >
                                        {e.itemAttach?.substring(4, 25)}....
                                        {e.itemAttach?.split(".").pop()}
                                      </a>

                                    </Grid>
                                  </Grid>
                                </Box>
                                {/* </div> */}
                                {/* })} */}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                    <Box
                      mt={3}
                      style={{
                        backgroundColor: '#fff',
                        boxShadow:
                          '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                        borderRadius: '5px',
                        padding: '20px'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item lg={4} sm={12} xl={12} xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.vendor}
                          // error={Boolean(touched.vendor && errors.vendor)}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Delivery Location *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Delivery Location"
                              name="deliveryLocation"
                              inputProps={{ maxLength: 100 }}
                              defaultValue={projectDetails?.projectID}
                              onChange={e => {
                                let item = { ...payloadIndent };
                                item.DeliveryProjectID = e.target.value;
                                setPayloadIndent({ ...item });
                              }}
                              disabled={projectDetails?.projectID !== 140}
                            >
                              {allProjects &&
                                allProjects.map((Project, index) => {
                                  return (
                                    <MenuItem key={index} value={Project.projectID}>
                                      {Project.projectName}
                                    </MenuItem>
                                  );
                                })}
                            </Select>

                          </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={12} xl={6} xs={12}>
                          {payloadIndent?.DeliveryProjectID ? (
                            <>
                              <b> Delivery address : </b>
                              <Address
                                details={
                                  allProjects?.filter(e => {
                                    if (e.projectID === payloadIndent?.DeliveryProjectID) {
                                      return {
                                        address: e?.address
                                      };
                                    }
                                  })[0]?.address
                                }
                              />
                            </>
                          ) : (
                            <>
                              <b> Delivery address : </b>
                              <Address
                                details={
                                  allProjects?.filter(e => {
                                    if (e.projectID === projectDetails?.projectID) {
                                      return {
                                        address: e?.address
                                      };
                                    }
                                  })[0]?.address
                                }
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item lg={4} sm={12} xl={12} xs={12}>
                        <Typography>
                        <strong>specification and term & condition
                          </strong>
                        </Typography>
                          <Button
                            style={{ width: "85%", height: "55px" }}
                            variant="contained"
                            component="label"
                          >
                            {!itemAttachment ? "Attach Here" : null}
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleUploadAttach(e, "technical", "add")
                              }
                            />
                            {itemAttachment && (
                              <a
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  position: "absolute",
                                  marginTop: "9px",
                                }}
                                href={
                                  itemAttachment
                                }
                                target="_blank"
                              >
                                {itemAttachment?.substring(4, 25)}....
                                {itemAttachment?.split(".").pop()}
                              </a>
                            )}
                          </Button>
                          {itemAttachment &&
                            <ClearIcon onClick={() => {
                              setItemAttachment('')
                            }} style={{ cursor: 'pointer' }} />
                          }
                        </Grid>
                      </Grid>

                    </Box>

                    <div style={{ marginTop: '1rem' }}>
                      <strong>Terms & Conditions</strong>
                    </div>
                    <Grid container spacing={4}>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label="Taxes"
                            name="taxes"
                            multiline
                            inputProps={{ maxLength: 200 }}

                            minRows={5}
                            // onBlur={handleBlur}
                            onChange={(e) => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                let item = payloadIndent  //{ ...payloadIndent };
                                item.taxes = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.taxes}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label="Bills"
                            name="bills"
                            multiline
                            minRows={5}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 200 }}

                            onChange={e => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                // handleChange(e);
                                let item = payloadIndent   //{ ...payloadIndent };
                                item.bills = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.bills}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}>
                          <TextField
                            fullWidth
                            label="Delivery Instructions"
                            name="Delivery"
                            multiline
                            minRows={5}
                            inputProps={{ maxLength: 200 }}

                            onBlur={handleBlur}
                            onChange={e => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                // handleChange(e);
                                let item = payloadIndent;
                                item.delivery = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.delivery}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label="Payment"
                            name="Payment"
                            multiline
                            minRows={5}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 200 }}
                            onChange={e => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                let item = payloadIndent;
                                item.payment = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.payment}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          className={classes.formControl}
                        >
                          <TextField
                            fullWidth
                            label="Contact"
                            name="Contact"
                            multiline
                            minRows={5}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 200 }}

                            onChange={e => {
                              if (e.target.value.match(regEx.noSpecialChar)) {

                                // handleChange(e);
                                let item = payloadIndent;
                                item.contact = e.target.value;
                                setPayloadIndent({ ...item });
                              }
                            }}
                            value={payloadIndent.contact}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  &nbsp; &nbsp;
                  <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          color="primary"
                          // onClick={handleGeneratePO}
                          type="submit"
                          variant="contained"
                        >
                          Generate PO
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          onClick={handleGeneratePo}
                          variant="contained"
                        >
                          Close
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </DialogContent>
            )}
          </Formik>
        </Dialog>

        {/* po modal */}

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={PoView}
          id="income"
          contentstyle={{
            width: '200%',
            maxWidth: 'none'
          }}
          maxWidth="lg"
          fullWidth
        >
          <Loader isLoading={isLoading} />
          <Formik
            enableReinitialize={true}
            initialValues={{
              Note:
                indentItem.currentApprovals.comment == 'undefined'
                  ? ''
                  : indentItem.currentApprovals.comment,
              status:
                indentItem.currentApprovals.approvalStatusID == 0
                  ? ''
                  : indentItem.currentApprovals.approvalStatusID
            }}
            onSubmit={(values, { resetForm }) => {
              updatePOApproval();
              resetForm(values);
            }}
            validationSchema={Yup.object().shape({
              Note: Yup.string().required(MESSAGE.note),
              status: Yup.string().required(MESSAGE.status)
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={handlePOViewClose}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    width: '800px !important'
                  }}
                >
                  Po Requested Items View
                </DialogTitle>

                <DialogContent dividers style={{ width: '800px !important' }}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={4} xl={4} xs={4}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          <Typography>
                            PO Master ID : {indentItem.purchaseOrderMasterID}
                          </Typography>
                        </h5>
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={4} xl={4} xs={4}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          <Typography>
                            Total Quantity: {indentItem.totalQty}
                          </Typography>
                        </h5>
                      </div>
                    </Grid>

                    <Grid item lg={4} sm={4} xl={4} xs={4}>
                      <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: '#394671' }}>
                          <Typography>
                            Total Value : {indentItem.totalValue}
                          </Typography>
                        </h5>
                      </div>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <CommonTable columns={POApprovalView} data={cpd} />
                      </div>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <Box
                        mt={3}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow:
                            '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                          borderRadius: '5px',
                          padding: '20px'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={
                              indentItem.currentApprovals.referOptionView
                                ? 6
                                : 8
                            }
                            xs={12}
                          >
                            <TextField
                              disabled={indentItem.currentApprovals.editLock}
                              fullWidth
                              error={Boolean(touched.Note && errors.Note)}
                              helperText={touched.Note && errors.Note}
                              label="Note"
                              name="Note"
                              value={
                                indentItem.currentApprovals.comment ==
                                  'undefined'
                                  ? ''
                                  : indentItem.currentApprovals.comment
                              }
                              onBlur={handleBlur}
                              onChange={e => {
                                if (e.target.value.match(regEx.noSpecialChar)) {

                                  handleChange(e);
                                  let rowData = { ...indentItem };
                                  rowData.currentApprovals.comment =
                                    e.target.value;
                                  setIndentItem({ ...rowData });
                                }
                              }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={
                              indentItem.currentApprovals.referOptionView
                                ? 3
                                : 4
                            }
                            xs={12}
                          >
                            <FormControl
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.formControl}
                              error={Boolean(touched.status && errors.status)}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Status
                              </InputLabel>
                              <Select
                                disabled={indentItem.currentApprovals.editLock}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onBlur={handleBlur}
                                onChange={e => {
                                  if (e.target.value.match(regEx.noSpecialChar)) {

                                    handleChange(e);
                                    let rowData = { ...indentItem };
                                    rowData.currentApprovals.approvalStatusID =
                                      e.target.value;
                                    setIndentItem({ ...rowData });
                                  }
                                }}
                                label="Status"
                                name="status"
                                value={
                                  indentItem.currentApprovals
                                    .approvalStatusID == 0
                                    ? ''
                                    : indentItem.currentApprovals
                                      .approvalStatusID
                                }
                              >
                                {approvalStatusList.map(li => (
                                  <MenuItem
                                    key={li.lookupID}
                                    value={li.lookupID}
                                  >
                                    {li.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors.status &&
                                  touched.status &&
                                  errors.status}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          {indentItem.currentApprovals.referOptionView == 1
                            ? !direct && (
                              <Grid
                                item
                                md={
                                  indentItem.currentApprovals.referOptionView
                                    ? 3
                                    : 0
                                }
                                xs={
                                  indentItem.currentApprovals.referOptionView
                                    ? 12
                                    : 0
                                }
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={
                                          indentItem.currentApprovals.editLock
                                        }
                                        checked={
                                          indentItem.currentApprovals
                                            .referSelected
                                        }
                                        onChange={e => {
                                          if (e.target.value.match(regEx.noSpecialChar)) {

                                            let rowData = { ...indentItem };
                                            rowData.currentApprovals.referSelected = !rowData
                                              .currentApprovals.referSelected;
                                            setIndentItem({ ...rowData });
                                          }
                                        }}
                                      />
                                    }
                                    label="Refer to director"
                                  />
                                </FormGroup>
                              </Grid>
                            )
                            : null}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <StepperBox steps={step} />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  <Button
                    disabled={indentItem.currentApprovals.editLock}
                    // onClick={(e) => {
                    //   updatePOApproval();
                    // }}
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={handlePOViewClose}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>

        {/* Print */}

        <Dialog
          aria-labelledby="customized-dialog-title"
          contentstyle={{
            width: '100%',
            maxWidth: 'none'
          }}
          maxWidth="md"
          fullWidth
          open={openincPrint}
          id="income"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleIncPrintClose}
            style={{ backgroundColor: '#394671', color: '#fff' }}
          >
            PO Generation Print View
          </DialogTitle>
          <DialogContent dividers style={{ overflowX: 'hidden' }}>
            <div>
              {/* <div style={{ marginLeft: "75%" }}> */}
              <PictureAsPdfIcon
                // onClick={exportPdf}
                onClick={printOrder}
                style={{ cursor: 'pointer' }}
              />{' '}
              &nbsp;
              <PrintIcon onClick={printOrder} style={{ cursor: 'pointer' }} />
            </div>
            <div className="invoice-box" id="printme">
              <table cellpadding="0" cellspacing="0">
                <tr className="top_rw">
                  <td colspan="2">
                    <h2 style={{ marginBottom: '0px' }}>
                      {' '}
                      Tax invoice / Bill of Supply / Cash memo{' '}
                    </h2>
                    <span> Date: {item?.CreateDate} </span>
                  </td>
                  <td style={{ width: '30%', marginRight: '10px' }}>
                    Purchase Order Master Id:{' '}
                    {allPurchaseOrder[0]?.purchaseOrderMasterID}
                  </td>
                </tr>
                <tr className="top">
                  <td colspan="3">
                    <table>
                      <tr>
                        <td>
                          {/* <Address details={allPurchaseOrder[0]?.vendorDetails[0].address} /> */}
                          <b>
                            {' '}
                            Sold By:{' '}
                            {allPurchaseOrder[0]?.vendorDetails[0]?.name}{' '}
                          </b>{' '}
                          <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.line_1} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.line_2} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.district}
                          Pin Code :{' '}
                          {
                            allPurchaseOrder[0]?.vendorAddress[0]?.postalCode
                          }{' '}
                          <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.state} <br />
                          {allPurchaseOrder[0]?.vendorAddress[0]?.country}{' '}
                          <br />
                          PAN : {
                            allPurchaseOrder[0]?.vendorDetails[0]?.pan
                          }{' '}
                          <br />
                          GSTIN : {
                            allPurchaseOrder[0]?.vendorDetails[0]?.gstn
                          }{' '}
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className="information">
                  <td colspan="3">
                    <table>
                      <tr>
                        <td colspan="2">
                          <b> Shipping Address: </b> <br />
                          <Address
                            details={allPurchaseOrder[0]?.projectAddress}
                          />
                        </td>
                        <td>
                          {' '}
                          <b> Billing Address: </b>
                          <br />
                          <Address
                            details={allPurchaseOrder[0]?.projectAddress}
                          />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <td colspan="3">
                  {/* <div style={{ textAlign: "center" }}>
                <CommonTable columns={POApprovalView} data={cpd} />
              </div> */}
                </td>
                <tr className="totalleft">
                  <td colspan="3">
                    <div style={{ textAlign: 'center' }}>
                      <CommonTable columns={POApprovalView} data={cpd} />
                    </div>
                  </td>
                </tr>
                <tr className="total">
                  {/* <td colspan="3" align="right">  Total Amount in Words :  <b> {capitalize(number2words(cpd?.reduce(function (acc, b) { return acc + b.unitPrice }, 0)))} Rupees Only </b> </td> */}
                </tr>
                <tr>
                  <td colspan="3">
                    <table cellspacing="0px" cellpadding="2px">
                      <tr>
                        <td width="50%">
                          <b> Declaration: </b> <br />
                          We declare that this invoice shows the actual price of
                          the goods described above and that all particulars are
                          true and correct.The goods sold are intended for end
                          user consumption and not for resale.
                        </td>
                        <td>
                          * This is a computer generated invoice and does not
                          require a physical signature
                        </td>
                      </tr>
                      <tr>
                        <td width="50%"></td>
                        <td>
                          <b> Authorized Signature </b>
                          <br />
                          <br />
                          ...................................
                          <br />
                          <br />
                          <br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>

            {/* </div> */}
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
            <Button
              style={{
                backgroundColor: '#394671',
                color: '#fff',
                border: '1px solid'
              }}
              onClick={handleIncPrintClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Formik
        enableReinitialize={true}
        initialValues={{
          name: vendor.name,
          companyType: vendor.companyType,
          companyName: vendor.companyName,
          gstn: vendor.gstn,
          pan: vendor.pan,
          aadhar: vendor.aadhar,
          aadharAttach: vendor.aadharAttach,
          email: vendor.email,
          mobile: vendor.mobile,
          address: {
            line_1: vendor.address.line_1,
            line_2: vendor.address.line_2,
            district: vendor.address.district,
            state: vendor.address.state,
            country: vendor.address.country,
            postalCode: vendor.address.postalCode
          },
          bankDetails: {
            bankName: vendor.bankDetails.bankName,
            bankAccountNo: vendor.bankDetails.bankAccountNo,
            bankBranch: vendor.bankDetails.bankBranch,
            bankIFSC: vendor.bankDetails.bankIFSC,
            bankAccountType: vendor.bankDetails.bankAccountType,
            bankAccountHolderName: vendor.bankDetails.bankAccountHolderName
          }
        }}
        onSubmit={(values, { resetForm }) => {
          // if (btn) {
          // updateVendor();
          // } else {
          setIsLoading(true);
          APIKit.post(RGCA_URLS.addVendor, vendor)
            .then(res => {
              if (res.data.status === 200) {
                setIsLoading(false);
                getNewVendor();
                setIsVenderShow(true);
                toast.success(MESSAGE.vendorAdded, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
                // handleCloseModal();
                setIsAddVendor(false)
                setIsNewVendor(false)
                resetForm();
              }
              else {
                setIsLoading(false);
                toast.error(res.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch(e => {
              setIsLoading(false);
            });
          // }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
          email: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email),
          companyName: Yup.string().required('Company Name Required'),
          mobile: Yup.string().required('Mobile Number is Required')
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <MuiThemeProvider theme={formLabelsTheme}>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={isAddVendor}
              id="income"
              contentStyle={{
                width: '200%',
                maxWidth: 'none'
              }}
              maxWidth="lg"
              fullWidth
            >
              <Loader isLoading={isLoading} />
              <DialogTitle
                id="scroll-dialog-title"
                onClose={e => {
                  // handleCloseModal(resetForm);
                }}
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                {'Create Vendor'}
              </DialogTitle>
              <Form
                autoComplete="off"
                // noValidate
                // onSubmit={handleSubmit}
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <DialogContent >
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.name = e.target.value;
                              setVendor({
                                ...p,
                                name: e.target.value
                              });
                            }
                          }}
                          required
                          value={vendor.name}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = vendor;
                            p.email = e.target.value;
                            setVendor({
                              ...p,
                              email: e.target.value
                            });
                          }}
                          required
                          value={vendor.email}
                          variant="outlined"
                        // disabled={nameupdate}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="companyName"
                          required
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.companyName = e.target.value;
                              setVendor({
                                ...p,
                                companyName: e.target.value
                              });
                            }
                          }}
                          value={vendor.companyName}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="GSTIN(%)"
                          name="gstn"
                          error={Boolean(touched.gstn && errors.gstn)}
                          helperText={touched.gstn && errors.gstn}
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.gstn = e.target.value;
                              setVendor({
                                ...p,
                                gstn: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 15
                          }}
                          value={vendor.gstn}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.pan && errors.pan)}
                          helperText={touched.pan && errors.pan}
                          label="PAN"
                          name="pan"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.pan = e.target.value;
                              setVendor({
                                ...p,
                                pan: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          value={vendor.pan}
                          variant="outlined"
                        />
                      </Grid>



                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Aadhar Number"
                          name="aadhar"
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              const p = vendor;
                              p.aadhar = e.target.value;
                              setVendor({
                                ...p,
                                aadhar: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 12
                          }}
                          value={vendor.aadhar}
                          variant="outlined"
                        />
                      </Grid>


                      <Grid item md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(
                            touched.companyType && errors.companyType
                          )}
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={vendor.companyType}
                            name="companyType"
                            label="Type"
                            onChange={e => {
                              handleChange(e);
                              const p = vendor;
                              p.companyType = e.target.value;
                              setVendor({
                                ...p,
                                companyType: e.target.value
                              });
                            }}
                            onBlur={handleBlur}
                          >
                            {lookupData &&
                              lookupData.map(x => {
                                return (
                                  <MenuItem value={x.lookupID} key={x.lookupID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.companyType &&
                              touched.companyType &&
                              errors.companyType}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.mobile && errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          label="Mobile Number"
                          name="mobile"
                          required
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              const p = vendor;
                              p.mobile = e.target.value;
                              setVendor({
                                ...p,
                                mobile: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          value={vendor.mobile}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.line_1 && errors.address?.line_1
                          )}
                          helperText={
                            touched.address?.line_1 && errors.address?.line_1
                          }
                          label="Address Line1"
                          name="address.line_1"
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 50 }}

                          onChange={e => {
                            handleChange(e);
                            let address = { ...vendor.address };
                            address.line_1 = e.target.value;
                            setVendor({
                              ...vendor,
                              address: { ...address }
                              //  line_1: e.target.value
                            });
                          }}
                          value={vendor.address.line_1}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.line_2 && errors.address?.line_2
                          )}
                          helperText={
                            touched.address?.line_2 && errors.address?.line_2
                          }
                          label="Address Line2"
                          name="address.line_2"
                          inputProps={{ maxLength: 50 }}

                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            let address = { ...vendor.address };
                            address.line_2 = e.target.value;
                            setVendor({
                              ...vendor,
                              address: { ...address }
                            });
                          }}
                          value={vendor.address.line_2}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 25 }}
                          error={Boolean(
                            touched.address?.district &&
                            errors.address?.district
                          )}
                          helperText={
                            touched.address?.district &&
                            errors.address?.district
                          }
                          label="District"
                          name="address.district"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.district = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.district}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Pin code"
                          name="address.postalCode"
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.address?.postalCode &&
                            errors.address?.postalCode
                          )}
                          helperText={
                            touched.address?.postalCode &&
                            errors.address?.postalCode
                          }
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.postalCode = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          inputProps={{
                            minLength: 6,
                            maxLength: 6
                          }}
                          value={vendor.address.postalCode}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.state && errors.address?.state
                          )}
                          helperText={
                            touched.address?.state && errors.address?.state
                          }
                          label="State"
                          name="address.state"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.state = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.state}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.country && errors.address?.country
                          )}
                          helperText={
                            touched.address?.country && errors.address?.country
                          }
                          label="Country"
                          name="address.country"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.country = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.country}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountNo &&
                            errors.bankDetails?.bankAccountNo
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountNo &&
                            errors.bankDetails?.bankAccountNo
                          }
                          label="Account Number"
                          name="bankDetails.bankAccountNo"
                          onBlur={handleBlur}
                          inputProps={{
                            minLength: 6,
                            maxLength: 16
                          }}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankAccountNo = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankAccountNo}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankIFSC &&
                            errors.bankDetails?.bankIFSC
                          )}
                          helperText={
                            touched.bankDetails?.bankIFSC &&
                            errors.bankDetails?.bankIFSC
                          }
                          label="IFSC Code"
                          name="bankDetails.bankIFSC"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankIFSC = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 11
                          }}
                          value={vendor.bankDetails.bankIFSC}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountType &&
                            errors.bankDetails?.bankAccountType
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountType &&
                            errors.bankDetails?.bankAccountType
                          }
                          label="Type of Account"
                          name="bankDetails.bankAccountType"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);

                            let bankDetails = { ...vendor.bankDetails };
                            bankDetails.bankAccountType = e.target.value;
                            setVendor({
                              ...vendor,
                              bankDetails: { ...bankDetails }
                            });
                          }}
                          value={vendor.bankDetails.bankAccountType}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountHolderName &&
                            errors.bankDetails?.bankAccountHolderName
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountHolderName &&
                            errors.bankDetails?.bankAccountHolderName
                          }
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankAccountHolderName =
                                e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          label="Account Holder Name"
                          name="bankDetails.bankAccountHolderName"
                          value={vendor.bankDetails.bankAccountHolderName}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankName &&
                            errors.bankDetails?.bankName
                          )}
                          helperText={
                            touched.bankDetails?.bankName &&
                            errors.bankDetails?.bankName
                          }
                          label="Bank Name"
                          name="bankDetails.bankName"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankName = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankName}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankBranch &&
                            errors.bankDetails?.bankBranch
                          )}
                          helperText={
                            touched.bankDetails?.bankBranch &&
                            errors.bankDetails?.bankBranch
                          }
                          label="Branch Name"
                          name="bankDetails.bankBranch"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankBranch = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankBranch}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>

                      <Grid item md={12} xs={12}></Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  <Button
                    onClick={e => {
                      // handleCloseModal(resetForm);
                      resetForm()
                      setIsAddVendor(false)
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
          </MuiThemeProvider>
        )}
      </Formik>
    </Container >
  );
};
export default RisePurchaseOrder;
