import { ProjectData } from './action.type';

let initialState = {
    id:null,
    project:{}
}

export const ProjectInfo = (state=initialState, action) => {    
    switch (action.type) {
        case ProjectData.PROJECT_DATA_SUCESS:
            return {
                ...state, 
                project : action.data
            }
           
        case ProjectData.PROJECT_DATA_FAILURE :
            return {...state}
        default:
            return {...state};
    }
}




