import { EmplyoeeAction } from './action.type';
import data from '../../views/Employee/data';

let initialState = {
  
    emplyoeedata : data
   
}

export const Emplyoee = (state=initialState, action) => {

    // console.log("final data in reducer",action.data);
    // console.log("type is ...", action.type);
    
    switch (action.type) {
      
        case EmplyoeeAction.SUCESS:

            return {
                ...state, 
                emplyoeedata : action.data
            }
           
        case EmplyoeeAction.FAILURE :
            return {...state}
           
        default:
            return {...state};
    }

}

