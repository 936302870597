
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Button,

  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Divider,
  FormHelperText
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// import data from './data';
import Page from 'src/components/Page';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import AutoCompleteField from '../AutoCompleteField/index'
import AutoCompleteField2 from '../AutoCompleteField/autocomplete2'
import { RGCA_URLS } from '../../../utils/url';
import APIKit from "../../../utils/APIKit";
import { useToasts } from 'react-toast-notifications';
import Loader from '../../loader';
import { createTheme } from '@material-ui/core/styles'
import './style.css'
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { MESSAGE } from 'src/utils/message';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getProjectDetails } from '../Common/utilities';
import CommonSelect from '../Common/CommonSelect';


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    margin: theme.spacing(1)
  },
  field: {
    margin: theme.spacing(2),
    "@media (min-width: 320px)": {
      margin: theme.spacing(-1)
    }
  }

}));


const itemInitialValues = {
  "typeList": [],
  "materialList": [],
  "itemID": '',
  "subCategoryID": null,
  "name": "",
  "description": "",
  "uom": "",
  "brandOrCatalogue": "",
  "availabilityQty": 0,
  "lastPrice": 0,
  "subCategoryName": "",
  "categoryName": "",
  "typeName": "",
  "projectName": "",
  "typeID": '',
  "categoryID": 0,
  "Qty": '',
  "estimatedPrice": '',
  "comment": ""
}


const ProjectIndentCreateOrEdit = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { project } = useParams();
  const [quoteUrl, setQuoteUrl] = useState([])
  const [auto, setAuto] = useState([{
    "val": {}
  }]);
  const [quoteUrlData, setQuoteUrlData] = useState('')
  const [lookupData, setLookupData] = useState([]);
  const [IndentItemList, setIndentItemList] = useState([])
  const projectData = useSelector(x => x);
  const navigate = useNavigate();
  const projectDetails = { ...getProjectDetails(project) }
  var proId = projectData.ProjectInfo.project.projectID
  const [inventoryList, setinventoryList] = useState([])


  // const saveeee=()=>{
  //   // console.log('projectDetails',projectDetails.projectName);
  //   navigate('/app/IndentEdit/'+projectDetails.projectName, { replace: true });

  // }

  const upFile = []
  useEffect(() => {
    setIndentItemList([{ ...itemInitialValues }])
    getLookup()
    getInventoryList()
  }, [navigate])
  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData([...res.data.data])
          setIndentItemList([{ ...itemInitialValues, typeList: res.data.data }])
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      "projectID": projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };
  const handleAddItemClick = () => {
    let items = [...IndentItemList];
    items.push({ ...itemInitialValues, typeList: lookupData })
    setIndentItemList(items)
    let it = [...auto]
    it.push({ "val": {} })
    setAuto(it)

  }

  const handleUploadClick = (event) => {

    if (event !== undefined) {
      if (event[0].size > 3145728) {
        toast.error(MESSAGE.lessthan3mb, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        return
      };
      setIsLoading(true);
      const uploadFile = []
      for (let index = 0; index < event.length; index++) {
        const formData = new FormData();
        uploadFile[index] = event[index].name;
        formData.append('upload', event[index], uploadFile[index]);
        APIKit.post(RGCA_URLS.fileUpload, formData)
          .then((res) => {
            if (res.data.status == 200) {
              setIsLoading(false);
              //addToast(res.data.message, { appearance: 'success' });
              // setQuoteUrl(res.data.data.file)
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })

              upFile.push(res.data.data.file)
              setQuoteUrl(...quoteUrl, upFile)
              setQuoteUrlData(res.data.data.file)
            } else {
              setIsLoading(false);
              //addToast(res.data.message, { appearance: 'error' });
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
      return



    }
  };
  const onChangeInput = (value, index) => {
    auto[index].val = value
    setAuto([...auto])
    let item = [...IndentItemList];
    item[index].itemID = value.value;
    let uo = item[index].materialList.find(x => x.itemID == value.value)
    let inv = inventoryList.find(x => x.itemID == value.value)
    if (inv != undefined) {
      item[index].uom = inv.uom
      item[index].lastPrice = inv.averageRate
      item[index].brandOrCatalogue = uo.brandOrCatalogue
      item[index].availabilityQty = inv.itemQty
    } else {
      item[index].uom = uo.uom
      item[index].lastPrice = uo.lastPrice
      item[index].brandOrCatalogue = uo.brandOrCatalogue
      item[index].availabilityQty = uo.availabilityQty
    }
    setIndentItemList([...item])
  }
  const removeItem = (index) => {
    let items = [...IndentItemList];
    items.splice(index, 1)
    setIndentItemList(items)
  }
  const getMaterials = (i, typeID, projectID) => {
    let payload = {
      projectID: projectID,
      typeID: typeID
    }
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then((res) => {
        if (res.data.status === 200) {
          let item = [...IndentItemList];
          item[i].materialList = [...res.data.data];
          setIndentItemList([...item])
        }
        else {

        }
      })
      .catch(function (e) {
      })
    // console.log('text-text',payload)


  }
  function checkForDuplicates(array) {
    let valuesAlreadySeen = []

    for (let i = 0; i < array.length; i++) {
      let value = array[i]
      if (valuesAlreadySeen.indexOf(value.itemID) !== -1) {
        return true
      }
      valuesAlreadySeen.push(value.itemID)
    }
    return false
  }

  const regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })

  const [type, setType] = useState(2)

  return (
    <Page
      className={classes.root}
      title="Depositors">
      <ToastContainer />
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        <div className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex"
          >
            <Typography><h2 style={{ color: '#394671' }}>Indent Create</h2></Typography>

            {/* <Button
              color="primary"
              variant="contained"
              justifyContent="flex-end"
              style={{ marginLeft: '84%' }}
              onClick={handleAddItemClick}
            >
              Add Item
            </Button> */}
          </Box>
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                // ...itemInitialValues,
                indentItem: [
                  ...IndentItemList
                ]

              }}
              onSubmit={(values, { resetForm }) => {
                if (auto.some(e => Object.keys(e.val).length === 0)) {
                  toast.error('Please select Material', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                  return
                }
                let items = IndentItemList.map(i => {
                  return { itemID: i.itemID, Qty: i.Qty, estimatedPrice: i.estimatedPrice, comment: i.comment }
                })
                let temp = checkForDuplicates(items);
                if (temp) {
                  toast.error('Materials are same.If you needed increase the quantity', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                  return
                }
                let payload = {
                  "projectID": projectDetails.projectID,
                  "quoteUrl": quoteUrlData ? quoteUrlData : '',
                  items
                }
                if (payload.quoteUrl === '') {
                  toast.error('Upload Quotation', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                  return
                }

                //  setIsLoading(true)

                APIKit.post(RGCA_URLS.addIndent, payload)
                  .then((res) => {
                    if (res.data.status === 200) {
                      setIsLoading(false);
                      //addToast(MESSAGE.indentAdded, { appearance: 'success' });
                      toast.success(MESSAGE.indentAdded, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      setIndentItemList([])
                      setQuoteUrl([])
                      setQuoteUrlData('')
                      resetForm(values)
                      getLookup()
                      setIndentItemList([{ ...itemInitialValues, typeID: null }])
                      navigate('/app/IndentEdit/' + projectDetails.projectName, { replace: true });
                    }
                    else {
                      setIsLoading(false);
                      toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })

                    }
                  })
                  .catch(function (e) {
                    setIsLoading(false);
                  })
              }}

              validationSchema={
                Yup.object().shape({
                  indentItem: Yup.array().of(
                    Yup.object().shape({
                      Qty: Yup.string().required(MESSAGE.qty),
                      estimatedPrice: Yup.string().required(MESSAGE.estimatedPrice),
                      typeID: Yup.string().required(MESSAGE.type)
                    })
                  )
                })
              }

            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm
              }) => (
                <MuiThemeProvider theme={formLabelsTheme}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <FieldArray name="indentItem">
                      {({ push, remove }) => (
                        <div>


                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              md={12}
                              xs={12}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >

                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{ background: '#394671', color: '#fff' }}
                                  // style={{ marginLeft: '84%' }}
                                  onClick={() => {
                                    // push({ id: Math.random(), Qty: "", estimatedPrice: "", comment: "", uom: "", brandOrCatalogue: "", typeID: "" })
                                    handleAddItemClick()
                                  }
                                  }            >
                                  Add Item
                                </Button>
                              </Box>
                            </Grid>
                            {IndentItemList.map((p, index) => {
                              const estimatedPrice = `indentItem[${index}].estimatedPrice`;
                              const touchedEstimatedPrice = getIn(touched, estimatedPrice);
                              const errorEstimatedPrice = getIn(errors, estimatedPrice);

                              const name = `indentItem[${index}].name`;



                              const Qty = `indentItem[${index}].Qty`;
                              const touchedQty = getIn(touched, Qty);
                              const errorQty = getIn(errors, Qty);

                              const typeID = `indentItem[${index}].typeID`;
                              const touchedtypeID = getIn(touched, typeID);
                              const errortypeID = getIn(errors, typeID);

                              const itemID = `indentItem[${index}].itemID`;
                              const toucheditemID = getIn(touched, itemID);
                              const erroritemID = getIn(errors, itemID);



                              const comment = `indentItem[${index}].comment`;


                              return (<>
                                Indent {index + 1}
                                <Grid
                                  container
                                  spacing={3}
                                  className={classes.field}
                                >
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                  >

                                    <FormControl
                                      variant="outlined"
                                      style={{ width: '100%' }}
                                      error={Boolean(touchedtypeID && errortypeID)}
                                      className={classes.formControl}>
                                      <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name={typeID}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          auto[index].val = {}
                                          setAuto([...auto])
                                          setType(e.target.value)
                                          handleChange(e);
                                          let item = [...IndentItemList];
                                          item[index].typeID = e.target.value;
                                          setIndentItemList([...item])
                                          // values.indentItem[index].typeID = e.target.value;
                                          getMaterials(index, IndentItemList[index].typeID, projectDetails.projectID) // project Id hard coded0
                                        }}
                                        value={p.typeID}
                                        // value={values.indentItem[index].typeID}
                                        required
                                        // value={values.type}
                                        label="Type"
                                      >
                                        {p.typeList.map((l) => {
                                          return <MenuItem value={l.lookupID} key={l.lookupID}>{l.name}</MenuItem>;
                                        })}

                                      </Select>
                                      <FormHelperText>{touchedtypeID && errortypeID
                                        ? errortypeID
                                        : ""}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                  >
                                    <CommonSelect val={auto[index]?.val} onChange={(e) => onChangeInput(e, index)} options={p.materialList} />



                                  </Grid>


                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                  >
                                    <TextField
                                      disabled={true}
                                      fullWidth
                                      helperText=""
                                      label="Uom"
                                      name="uom"
                                      onChange=""
                                      value={p.uom}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                  >
                                    <TextField
                                      disabled={true}
                                      fullWidth
                                      helperText=""
                                      label="Brand/Catalogue"
                                      name="brandOrCatalogue"
                                      onChange=""
                                      value={p.brandOrCatalogue}
                                      variant="outlined"
                                    />
                                  </Grid>

                                </Grid>
                                <Grid
                                  container
                                  spacing={3}
                                  className={classes.field}
                                >

                                  <Grid
                                    item
                                    md={2}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Quantity"
                                      name={Qty}
                                      onBlur={handleBlur}
                                      required
                                      helperText={
                                        touchedQty && errorQty
                                          ? errorQty
                                          : ""
                                      }
                                      error={Boolean(touchedQty && errorQty)}
                                      onChange={(e) => {
                                        if (e.target.value.match(regEx.numbersOnly)) {
                                          let item = [...IndentItemList];
                                          item[index].Qty = e.target.value;
                                          setIndentItemList([...item])
                                          handleChange(e);
                                        }
                                      }
                                      }
                                      value={p.Qty}
                                      variant="outlined"
                                      inputProps={{ maxLength: 5 }}

                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Estimated Price"
                                      name={estimatedPrice}
                                      onBlur={handleBlur}
                                      required
                                      helperText={
                                        touchedEstimatedPrice && errorEstimatedPrice
                                          ? errorEstimatedPrice
                                          : ""
                                      }
                                      error={Boolean(touchedEstimatedPrice && errorEstimatedPrice)}
                                      onChange={(e) => {
                                        if (e.target.value.match(regEx.numbersOnly)) {
                                          let item = [...IndentItemList];
                                          item[index].estimatedPrice = e.target.value;
                                          setIndentItemList([...item])
                                          handleChange(e);
                                        }
                                      }}
                                      value={p.estimatedPrice}
                                      variant="outlined"
                                      inputProps={{ maxLength: 9 }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={12}
                                  >
                                    <TextField
                                      disabled={true}
                                      fullWidth
                                      helperText=""
                                      label="Last  price "
                                      name="Lastprice"
                                      onChange=""
                                      value={p.lastPrice}
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={12}
                                  >
                                    <TextField
                                      disabled={true}
                                      fullWidth
                                      helperText=""
                                      label="Stock Quantity"
                                      name="price"
                                      onChange=""
                                      value={p.availabilityQty}
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Description"
                                      name={comment}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        handleChange(e);
                                        let item = [...IndentItemList];
                                        item[index].comment = e.target.value;
                                        setIndentItemList([...item])

                                      }}
                                      value={p.comment}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={1}
                                    xs={12}
                                  >
                                    {IndentItemList.length !== 1 && (<div style={{ textAlign: "center" }}>
                                      <DeleteOutlineIcon onClick={() => removeItem(index)}
                                        style={{ marginTop: '15px', textAlign: "center", cursor: 'pointer' }} />
                                    </div>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    md={12}
                                    xs={12}
                                  >
                                    {IndentItemList.length !== 1 &&
                                      <Divider style={{ background: 'rgb(143 152 203)', width: "100%" }} />
                                    }
                                  </Grid>
                                </Grid>

                              </>)
                            })}
                            {/* <Grid
                              item
                              md={12}
                              xs={12}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >

                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleAddItemClick()
                                  }
                                  }            >
                                  Add Item
                                </Button>
                              </Box>
                            </Grid> */}

                            <Grid
                              item
                              md={8}
                              xs={12}
                            >
                              <DropzoneAreaBase showAlerts="false" dropzoneText='Upload Quotations'
                                acceptedFiles={['image/jpeg', 'image/png', '.PDF', '.xls', '.xlsx', '.txt', '.csv', '.doc', '.docx']}
                                maxFileSize={5000000}
                                filesLimit={10}
                                // acceptedFiles="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                                onDrop={(e) =>
                                  handleUploadClick(e)
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              {/* {quoteUrl.length && (<a style={{ color: 'blue', cursor: 'pointer' }}
                              href={quoteUrl} target="_blank"
                            >{quoteUrl.substring(4, 20)}
                            </a>
                            )} */}
                              {/* {quoteUrl} */}
                              {quoteUrlData && <a style={{ color: 'blue', cursor: 'pointer' }} href={quoteUrlData} target="_blank">{quoteUrlData.substring(4, 20)}....{quoteUrlData.substring(quoteUrlData.lastIndexOf('.') + 1)}</a>
                              }
                              <p style={{ marginTop: "100px" }}>
                                <strong>Format allowed:</strong> BMP, DOC, DOCX, GIF, JPEG, JPG, PDF, RTF, TIF, TIFF, TXT, XLS, XLSX, PPT, PPTX, PNG.
                                <br /><strong>Maximum</strong> upload size per document is <strong>3 MB</strong></p>
                              {/* {quoteUrl && quoteUrl.map((e) => {
                              return <div> <a style={{ color: 'blue', cursor: 'pointer' }} href={e} target="_blank">{e.substring(4, 20)}</a> <br /></div>
                            })} */}
                            </Grid>





                          </Grid>



                          {/* </div>
                          );
                        })} */}

                        </div>
                      )}

                    </FieldArray>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >

                        <Button
                          color="primary"
                          variant="contained" style={{ background: '#394671', color: '#fff', marginTop: '20px' }}
                          type="submit"
                        >
                          Save
                        </Button>

                      </Box>
                    </Grid>
                  </Form>
                </MuiThemeProvider>
              )}
            </Formik>
          </Box>
        </div>
        <Box mt={3}>

        </Box>

        {/* dialog */}




      </Container>
    </Page >
  )

}
export default ProjectIndentCreateOrEdit;
