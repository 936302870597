import { Nav } from './action.type';

let initialState = {
    navData : ""
}

export const NavData = (state=initialState, action) => {    
    switch (action.type) {
        case Nav.NAVSUCESS:
            return {
                ...state, 
                navData : action.data
            }
           
        case Nav.NAVFAILURE :
            return {...state}
        default:
            return {...state};
    }
}

