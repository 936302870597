
import React, { useState, useEffect } from 'react';
import Loader from 'src/views/loader';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination
} from '@material-ui/core';
import Page from 'src/components/Page';
import 'date-fns';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import APIKit from '../../../utils/APIKit';
import { Data } from './data';
import { RGCA_URLS } from 'src/utils/url';
import { ToastContainer, toast } from 'react-toastify';
import GrnData from './Grn.json';



const GrnlistTwo = ({rowsPerPage,page,po_id,handleChangePage=()=>{},
handleChangeRowsPerPage=()=>{},isRefresh}) => {
    const [tableData,setTableData] = useState([])
  useEffect(()=>{
    if(po_id){
        getLookupData()
    }
  },[isRefresh])
    const getLookupData = () => {
        APIKit.post(`${RGCA_URLS.getGRNInformationByQty}`,{ "purchaseOrderMasterID":po_id},)
            .then(res => {
                if (res.data.status === 200) {
                    
                    console.log('po_id',res.data.data);
                    setTableData(res.data.data)
                } else {
                }
            })
            .catch(function (e) { });
    };

    return (
        <Page title="Sales Report">
            {/* <Loader isLoading={isLoading} /> */}
            <ToastContainer />
            <Box mt={3}>
                <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">GRN ID</TableCell>
                                        <TableCell align="left">Acceptance Status</TableCell>
                                        <TableCell align="left"> Reason For Rejection</TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left" >Material</TableCell>
                                        <TableCell align="left">Qty</TableCell>
                                        <TableCell align="left">Price</TableCell>
                                        <TableCell align="left">GST</TableCell>
                                        <TableCell align="left">Amount</TableCell>
                                        <TableCell align="left">Date </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {tableData && tableData.length != 0 ? (
                                        tableData.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align="left">{x.grnMasterID}</TableCell>
                                                    <TableCell align="left">{x.grnMasterID}</TableCell>
                                                    <TableCell align="left">{x.rejectedComment}</TableCell>
                                                    <TableCell align="left">-</TableCell> 
                                                    {/* BASED ON THE STATUS */}
                                                    <TableCell align="left">{x.name}</TableCell>
                                                    <TableCell align="left">{x.grnQty}</TableCell>
                                                    <TableCell align="left">{x.unitPrice}</TableCell>
                                                    <TableCell align="left">{x.IGST}</TableCell>
                                                    <TableCell align="left">{x.totalPrice}</TableCell>
                                                    {/* <TableCell align="left">
                                                    {x?.invoiceAttach && <a href={x?.invoiceAttach}>{x?.invoiceAttach.substring(0, 25)}</a> }
                                                        </TableCell> */}
                                                    <TableCell align="left">{moment(x.receivedDate).format("DD-MM-YYYY")} </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {tableData && tableData.length != 0 && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* </Container> */}
            </Box>
        </Page>
    );
};

export default GrnlistTwo;
