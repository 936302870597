import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { APP_URLS } from '../../utils/apis';
import { RGCA_URLS } from '../../utils/url'
import { setClientToken } from '../../utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from '../../utils/message';
import APIKit from 'src/utils/APIKit';
import Loader from 'src/views/loader';
import { VisibilityOff, Visibility } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ChangePassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    newPassword: "",

  });
  // const skipPass = () => {
  //   let userData = JSON.parse(localStorage.getItem('userData'))
  //   let permission = JSON.parse(localStorage.getItem('projectList'))

  //   if (userData.accessRoleID == 11 || userData.accessRoleID == 1) {
  //     navigate('/app/maindashboard');
  //   } else {
  //     navigate('/app/dashboard/' + permission[0]?.projectName);
  //   }

  // };
  const getAllProjects = async (data) => {
    await APIKit.get(RGCA_URLS.getEmployeeProjects)
      .then((res) => {
        localStorage.setItem('projectList', JSON.stringify(res.data.data || []))
        let permission = JSON.parse(localStorage.getItem('projectList'))
        if (data.data.accessRoleID == 11 || data.data.accessRoleID == 1) {
          navigate('/app/maindashboard');
        } else {
          navigate('/app/dashboard/' + permission[0]?.projectName);
        }
      })
      .catch(function (e) {
      })
  }
  //Show & hide Password
  const [shownewPassword, showNewpassword] = useState(false)
  const [showconfirmnewPwd, setShowconfirmnewpwd] = useState(true)
  const showNew = () => {
    showNewpassword(!shownewPassword)
  }
  const showConfirmnewpwd = () => {
    setShowconfirmnewpwd(!showconfirmnewPwd)
  }
  return (
    <Page
      className={classes.root}
      title="Change Password"
    >
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"

      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: ''
            }}
            validationSchema={
              Yup.object().shape({
                newPassword: Yup.string()
                  .required(MESSAGE.newPasswor)
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    'Password is invalid'
                  ),
                confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required(MESSAGE.connewPasswor)
              })
            }
            onSubmit={() => {
              setIsLoading(true)
              let userData = JSON.parse(localStorage.getItem('userData'))
              let permission = JSON.parse(localStorage.getItem('projectList'))
              // console.error(payload)
              APIKit.post(RGCA_URLS.changePassword, {
                ...payload,
                employeeCode: userData.employeeCode
              })
                .then((res) => {
                  if (res.data.status === 200) {
                    setIsLoading(false)
                    toast.success(MESSAGE.passwordChanged, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    })
                    if (userData.accessRoleID == 11 || userData.accessRoleID == 1) {
                      navigate('/app/maindashboard');
                      addToast(MESSAGE.passwordChanged, { appearance: 'success' });
                    } else {
                      navigate('/app/dashboard/' + permission[0]?.projectName);
                      addToast(MESSAGE.passwordChanged, { appearance: 'success' });
                    }
                  } else {
                    setIsLoading(false)
                    toast.error(res.data.error, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    })
                  }
                })
                .catch((e) => {
                  setIsLoading(false)
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: "center" }}
                  >
                    Change Password
                  </Typography>

                </Box>
                <br />

                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    let p = payload;
                    p.newPassword = e.target.value
                    values.newPassword = e.target.value
                    setPayload({
                      ...payload,
                      newPassword: e.target.value,

                    })
                  }}
                  type={shownewPassword ? "text" :  "password"}
                  value={values.newPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={showNew}>
                          {shownewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm New Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    values.confirmPassword = e.target.value
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                  type={showconfirmnewPwd ? "password"  : "text"}
                  value={values.confirmPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={showConfirmnewpwd}>
                          {showconfirmnewPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Typography component='span' variant='caption' style={{ fontWeight: 'bold' }}>Note:</Typography>
                <Typography variant='caption' component='span'> Password must contains at least 1 letter, 1 Number, 1 Uppercase and 1 symbol. Minimum length is 8 characters.</Typography>
                <Box my={4}>
                  <Button
                    color="primary"
                    //  disabled={isSubmitting}

                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      "position": "absolute",
                      // "right": "25%"
                    }}
                  >
                    Submit
                  </Button>
                  &nbsp;

                </Box>
                {/* <Button
                  color="primary"
                  //  disabled={isSubmitting}

                  size="large"
                  onClick={skipPass}
                  variant="contained"

                >
                  Skip
                </Button> */}

              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ChangePassword;
