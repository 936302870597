import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    name:'KV',
    designation: 'Manager',
    createdAt: new Date(),
    createdBy: 'ekaterina.tankova',
 
  },
  {
    id: uuid(),
    name:'SV',
    designation: 'Manager',
    createdAt: new Date(),
    createdBy: 'ekaterina.tankova',
 
  },
  {
    id: uuid(),
    name:'CH',
    designation: 'Manager',
    createdAt: new Date(),
    createdBy: 'ekaterina.tankova',
 
  },
  {
    id: uuid(),
    name:'MV',
    designation: 'Manager',
    createdAt: new Date(),
    createdBy: 'ekaterina.tankova',
 
  },
  {
    id: uuid(),
    name:'KR',
    designation: 'Manager',
    createdAt: new Date(),
    createdBy: 'ekaterina.tankova',
 
  },
];

