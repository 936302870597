import React, { useEffect, useState } from 'react'
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Address from '../Common/Address';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import { currencyFormat1 } from '../Common/utilities';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));


const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const EditSelling = ({ AllCustomer, projectDetails, setIsEditDialog, lookupData, setIsLoading, getMaterialsForSelling,
    dataForEdit, setDataForEdit, isLoading }) => {
    const classes = useStyles();
    console.log("dataForEdit", dataForEdit);

    const [itemList, setItemList] = useState(dataForEdit?.sellingItem);
    const [payload, setPayload] = useState({
        projectID: null,
        customerID: dataForEdit?.customerID,
        sellingDate: dataForEdit?.sellingDate,
        items: itemList,
        sellingMasterID: dataForEdit?.sellingMasterID,
        bags: dataForEdit?.NoofBags,
        rateperbag: dataForEdit?.bagsPerUnit
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [numCol, setNumCol] = useState(10)
    const [isMaterials, setIsMaterials] = useState([]);
    const [isCategories, setIsCategories] = useState([]);
    const [isCategoryName, setIsCategoryName] = useState()
    const addItem = () => {
        //   const array = []
        let temp = itemList;
        temp.push({
            itemID: '',
            qty: '',
            total: '',
            advancedReceived: '',
            balance: '',
            materialList: [],
            categoryList: [],
            typeID: '',
            iqty: '',
            NoofBags: '',
            bagsPerUnit: ''
        });
        setItemList([...temp]);
    };

    const getMaterials = (i, typeID, projectID) => {
        let payload = {
            projectID: projectID,
            typeID: typeID,
            batchNo: dataForEdit.batchNo
        };
        APIKit.post(RGCA_URLS.getMaterialsFromSalesItemSelect, payload)
            .then(res => {
                if (res.data.status === 200) {
                    let item = [...itemList];
                    item[i].materialList = [...res.data.data];
                    // const cate = res.data.data.find(y => y.itemID == item[i].itemID);
                    // setIsCategories(cate?.categoryList)
                    // setIsCategoryName(item[i].categoryID)
                    setItemList([...item]);
                    setIsMaterials(res.data.data)
                } else {
                }
            })
            .catch(function (e) { });
    };

    const removeItem = index => {
        let items = itemList;
        items.splice(index, 1);
        setItemList([...items]);
    };
    const handleIncClose = () => {
        setItemList([])
        setItemList([
            {
                itemID: '',
                qty: '',
                total: '',
                advancedReceived: '',
                balance: '',
                materialList: [],
                categoryList: [],
                typeID: '',
                iqty: '',
                NoofBags: '',
                bagsPerUnit: ''
            }
        ]);
        setPayload({});
        setDataForEdit({})
        setIsEditDialog(false);
    };



    let regEx = {
        textOnly: /[a-zA-Z\s]+/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/
    };

    const fetchMaterialsForItems = () => {
        itemList.forEach((item, index) => {
            getMaterials(index, item.typeID, projectDetails.projectID);
        });
    };

    const getCateList = (index, data) => {
        let item = [...itemList];
        item[index].categoryList = [...data.categoryList];
        setItemList([...item])
    }

    useEffect(() => {
        fetchMaterialsForItems();

    }, [dataForEdit]);
    useEffect(() => {
        if (dataForEdit && dataForEdit?.sellingItem && dataForEdit?.sellingItem.length > 0) {
            setIsCategoryName(dataForEdit?.sellingItem[0]?.categoryID ?? null)
            if (isMaterials && isMaterials.length > 0 && dataForEdit?.sellingItem) {
                dataForEdit.sellingItem.map((sellingItem, index) => {
                    isMaterials.find((material, index) => {
                        if (material.itemID === sellingItem.itemID) {
                            getCateList(index, material)
                        }
                    });
                });
            }
        }
    }, [isMaterials]);


    const handleSubmit1 = () => {
        setIsLoading(true)
        let payload1 = {
            sellingMasterID: payload.sellingMasterID,
            customerID: Number(payload.customerID),
            projectID: projectDetails.projectID,
            sellingDate: moment(payload.sellingDate).format('YYYY-MM-DD'),
            totalAmount: totalAmount,
           
            items: itemList.map(e => {
                if (e?.sellingItemID) {
                    return {
                        itemID: e.itemID,
                        qty: Number(e.iqty),
                        advancedReceived: Number(e.advancedReceived),
                        total: e.rpu === undefined ? 0 : e.gstRate != 0
                            ? (Number(e.rpu) *
                                Number(e.iqty) *
                                Number(e.gstRate)) /
                            100 +
                            Number(e.rpu) * Number(e.iqty) + (Number(e.bagsPerUnit) * Number(e.NoofBags))
                            : Number(e.rpu) * Number(e.iqty) + (Number(e.bagsPerUnit) * Number(e.NoofBags)),
                        balance: 0,
                        salesItemID: e.sellingItemID,
                        categoryID: e.categoryID,
                        name: e?.materialList[0]?.name ? e?.materialList[0]?.name : '',
                        gstTaxability: e?.materialList[0]?.gstTaxability ? e?.materialList[0]?.gstTaxability : '',
                        gstRate: e?.gstRate,
                        NoofBags:e?.NoofBags ? Number(e?.NoofBags) : 0,
                        bagsPerUnit:e?.bagsPerUnit? e?.bagsPerUnit : ''
                    };
                } else {
                    return {
                        itemID: e.itemID,
                        qty: Number(e.iqty),
                        advancedReceived: Number(e.advancedReceived),
                        total: e.rpu === undefined ? 0 : e.gstRate != 0
                            ? (Number(e.rpu) *
                                Number(e.iqty) *
                                Number(e.gstRate)) /
                            100 +
                            Number(e.rpu) * Number(e.iqty) + (Number(e?.rateperbag) * (Number(e?.bags)))
                            : Number(e.rpu) * Number(e.iqty) + (Number(e?.rateperbag) * (Number(e?.bags))),
                        balance: 0,
                        salesItemID: 0,
                        categoryID: e.categoryID,
                        name: e?.materialList[0]?.name ? e?.materialList[0]?.name : '',
                        gstTaxability: e?.materialList[0]?.gstTaxability ? e?.materialList[0]?.gstTaxability : '',
                        gstRate: e?.gstRate,
                    };

                }

            })
        };
        console.log("payload1", payload1);
        setIsLoading(true);
        APIKit.put(RGCA_URLS.updateSelling, payload1)
            .then(res => {
                if (res.data.status === 200) {
                    setItemList([
                        {
                            itemID: '',
                            qty: '',
                            total: '',
                            advancedReceived: '',
                            balance: '',
                            materialList: [],
                            typeID: '',
                            iqty: '',
                            bags: '',
                            rateperbag: ''
                        }
                    ]);
                    setPayload({});
                    setIsEditDialog(false);
                    getMaterialsForSelling();
                    setIsLoading(false);
                    toast.success(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                } else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setTotalAmount(calculateTotalAmount());

    }, [itemList,payload?.bags,payload?.rateperbag]);

    const calculateTotalAmount = () => {
        let total = 0;
        itemList.forEach(item => {
            total += item.rpu === undefined
                ? 0
                : item.gstRate !== 0
                    ? (Number(item.rpu) *
                        Number(item.iqty) *
                        Number(item.gstRate)) /
                    100 +
                    Number(item.rpu) * Number(item.iqty) + (Number(item.bagsPerUnit) * Number(item.NoofBags))
                    : Number(item.rpu) * Number(item.iqty) + (Number(item.bagsPerUnit) * Number(item.NoofBags))
        });
        // const bagTotal = Number(payload.bags) * Number(payload.rateperbag);
        // total += bagTotal;
        return total;
    };
    return (
        <>
            <Loader isLoading={isLoading} />

            <Formik
                enableReinitialize={true}
                initialValues={{
                    customerID: payload.customerID,
                    date: payload.sellingDate,
                    Selling: [...itemList]
                }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit1();
                }}
                validationSchema={Yup.object().shape({
                    // Selling: Yup.array().of(
                    //     Yup.object().shape({
                    //         typeID: Yup.string().required(MESSAGE.type),
                    //         qty: Yup.string()
                    //             .required(MESSAGE.qty)
                    //             .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                    //         itemID: Yup.string().required(MESSAGE.materials)
                    //     })
                    // ),
                    // customerID: Yup.string().required(MESSAGE.customer),
                    // date: Yup.string().required(MESSAGE.date)
                })}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isInitialValid = false,
                    isValid,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <DialogContent dividers style={{ width: '900px !important' }}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    lg={4}
                                    sm={6}
                                    xl={6}
                                    xs={6}
                                    className={classes.custmr}
                                >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                        error={Boolean(touched.customerID && errors.customerID)}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            required
                                        >
                                            Customer
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Location"
                                            name="customerID"
                                            required
                                            onChange={e => {
                                                handleChange(e);
                                                const p = payload;
                                                p.customerID = Number(e.target.value);
                                                setPayload({
                                                    ...p
                                                });
                                            }}
                                            value={payload.customerID}
                                            onBlur={handleBlur}
                                        >
                                            {AllCustomer &&
                                                AllCustomer.map(x => {
                                                    return (
                                                        <MenuItem value={x.customerID} key={x.customerID}>
                                                            {x.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                        <FormHelperText>
                                            {errors.customerID &&
                                                touched.customerID &&
                                                errors.customerID}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} sm={12} xl={6} xs={12}>
                                    {payload.customerID ? (
                                        <>
                                            {' '}
                                            <b> Supplier address : </b>
                                            <Address
                                                details={
                                                    AllCustomer?.filter(e => {
                                                        if (e.customerID === payload?.customerID) {
                                                            return {
                                                                address: e?.address
                                                            };
                                                        }
                                                    })[0]?.address
                                                }
                                            />{' '}
                                        </>
                                    ) : null}
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6}>
                                    <KeyboardDatePicker
                                        className={classes.dat}
                                        autoOk
                                        InputProps={{ disabled: true }}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        maxDate={new Date}
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Date "
                                        format="dd/MM/yyyy"
                                        name="date "
                                        value={payload.sellingDate ?? null}
                                        InputAdornmentProps={{ position: 'end' }}
                                        onChange={date => {
                                            const p = payload;
                                            p.sellingDate = moment(date).format('YYYY-MM-DD');
                                            setPayload({
                                                ...p
                                            });
                                        }}
                                        required
                                        error={Boolean(touched.date && errors.date)}
                                        helperText={touched.date && errors.date}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6}>
                                    <TextField
                                        label="Batch No."
                                        name="batchNo"
                                        onBlur={handleBlur}
                                        value={dataForEdit?.batchNo} disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <Button onClick={addItem} className={classes.butt}>
                                        <a>+ Add Item </a>{' '}
                                    </Button>
                                </Grid>
                                {/* ---------------------------------------------------------------------------------------- */}

                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Type</TableCell>
                                                        <TableCell
                                                            align="left"
                                                            style={{ width: '200px' }}
                                                        >
                                                            Material
                                                        </TableCell>
                                                        <TableCell align="left">Category</TableCell>
                                                        <TableCell align="left">UOM</TableCell>
                                                        <TableCell align="left">
                                                            Stock Available
                                                        </TableCell>
                                                        <TableCell align="left">Quantity</TableCell>
                                                        <TableCell align="left">GST Rate</TableCell>
                                                        <TableCell align="left">Rate Per Unit</TableCell>
                                                        <TableCell align="left">Rate Per Bag</TableCell>
                                                        <TableCell align="left">No of Bags</TableCell>
                                                        <TableCell align="left">Total</TableCell>
                                                        {/* <TableCell align="left">Balance</TableCell> */}
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <FieldArray name="Selling">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {itemList.map((x, index) => {
                                                                    // console.log("xxxx", x);
                                                                    const typeID = `Selling[${index}].typeID`;
                                                                    const touchedtypeID = getIn(
                                                                        touched,
                                                                        typeID
                                                                    );
                                                                    const errortypeID = getIn(errors, typeID);
                                                                    const qty = `Selling[${index}].qty`;
                                                                    const touchedqty = getIn(touched, qty);
                                                                    const errorqty = getIn(errors, qty);
                                                                    const advancedReceived = `Selling[${index}].advancedReceived`;
                                                                    const touchedadvancedReceived = getIn(
                                                                        touched,
                                                                        advancedReceived
                                                                    );
                                                                    const erroradvancedReceived = getIn(
                                                                        errors,
                                                                        advancedReceived
                                                                    );
                                                                    const itemID = `Selling[${index}].itemID`;
                                                                    const toucheditemID = getIn(
                                                                        touched,
                                                                        itemID
                                                                    );
                                                                    const erroritemID = getIn(errors, itemID);
                                                                    return (
                                                                        <>
                                                                            <TableRow hover role="checkbox">
                                                                                <TableCell
                                                                                    align="left"
                                                                                    style={{ width: '200px' }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="outlined"
                                                                                        style={{ width: '100%' }}
                                                                                        className={classes.formControl}
                                                                                        error={Boolean(
                                                                                            touchedtypeID && errortypeID
                                                                                        )}
                                                                                    >
                                                                                        <InputLabel
                                                                                            required
                                                                                            id="demo-simple-select-outlined-label"
                                                                                        >
                                                                                            Sales Item
                                                                                        </InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-outlined-label"
                                                                                            id="demo-simple-select-outlined"
                                                                                            value={x.typeID}
                                                                                            name={typeID}
                                                                                            onBlur={handleBlur}
                                                                                            label="Sales Item"
                                                                                            required
                                                                                            fullWidth
                                                                                            onChange={e => {
                                                                                                console.log("eee", e);
                                                                                                handleChange(e);
                                                                                                let item = itemList;
                                                                                                item[index].typeID = e.target.value;
                                                                                                setItemList([...item]);
                                                                                                getMaterials(
                                                                                                    index,
                                                                                                    e.target.value,
                                                                                                    projectDetails.projectID
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {/* <MenuItem value={salesItem.type}>{salesItem.type}</MenuItem> */}
                                                                                            {lookupData &&
                                                                                                lookupData.map(x => {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            value={x.lookupID}
                                                                                                            key={x.lookupID}
                                                                                                        >
                                                                                                            {x.name}
                                                                                                        </MenuItem>
                                                                                                    );
                                                                                                })}
                                                                                        </Select>
                                                                                        <FormHelperText>
                                                                                            {touchedtypeID && errortypeID
                                                                                                ? errortypeID
                                                                                                : ''}
                                                                                        </FormHelperText>
                                                                                    </FormControl>{' '}
                                                                                </TableCell>
                                                                                <TableCell style={{ width: '200' }}>
                                                                                    <FormControl
                                                                                        variant="outlined"
                                                                                        error={Boolean(
                                                                                            toucheditemID && erroritemID
                                                                                        )}
                                                                                        style={{ width: '100%' }}
                                                                                        className={classes.formControl}
                                                                                    >
                                                                                        <InputLabel
                                                                                            required
                                                                                            id="demo-simple-select-outlined-label"
                                                                                        >
                                                                                            Materials
                                                                                        </InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-outlined-label"
                                                                                            id="demo-simple-select-outlined"
                                                                                            onBlur={handleBlur}
                                                                                            disabled={
                                                                                                !x?.materialList?.length
                                                                                            }
                                                                                            onChange={e => {
                                                                                                handleChange(e);
                                                                                                let item = [...itemList];
                                                                                                item[index].itemID =
                                                                                                    e.target.value;
                                                                                                let uo = item[
                                                                                                    index
                                                                                                ].materialList.find(
                                                                                                    x =>
                                                                                                        x.itemID == e.target.value
                                                                                                );
                                                                                                item[index].uom = uo.uom;
                                                                                                item[index].categoryName =
                                                                                                    uo.categoryName;
                                                                                                item[index].rpu = uo.rpu;
                                                                                                item[index].qty = uo.stock;
                                                                                                item[index].TotalAmount +=
                                                                                                    uo.rpu;
                                                                                                item[index].gstRate =
                                                                                                    uo.gstRate;
                                                                                                console.log("item", item);

                                                                                                values.Selling[index].qty =
                                                                                                    uo.stock;
                                                                                                // values.indentItem[index].itemID = e.target.value;
                                                                                                const cate = isMaterials.find(x => x.itemID == e.target.value);
                                                                                                console.log('cate', cate)
                                                                                                item[index].categoryList = cate?.categoryList;
                                                                                                setItemList([...item]);
                                                                                            }}
                                                                                            required
                                                                                            value={x.itemID}
                                                                                            name={itemID}
                                                                                            label="Materials"
                                                                                        >
                                                                                            {x.materialList &&
                                                                                                x.materialList.map(x => {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            value={x.itemID}
                                                                                                            key={x.itemID}
                                                                                                            disabled={itemList?.findIndex(y => y.itemID == x.itemID) != -1}
                                                                                                        >
                                                                                                            {x.name}
                                                                                                        </MenuItem>
                                                                                                    );
                                                                                                })}
                                                                                        </Select>
                                                                                        <FormHelperText>
                                                                                            {toucheditemID && erroritemID
                                                                                                ? erroritemID
                                                                                                : ''}
                                                                                        </FormHelperText>{' '}
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                {/* <TableCell align="left">
                                                                                    {x.categoryName}{' '}
                                                                                </TableCell> */}
                                                                                <TableCell style={{ width: '200px' }}>
                                                                                    <FormControl
                                                                                        variant="outlined"
                                                                                        // error={Boolean(
                                                                                        //     toucheditemID && erroritemID
                                                                                        // )}
                                                                                        style={{ width: '110%' }}
                                                                                        className={classes.formControl}
                                                                                    >
                                                                                        <InputLabel
                                                                                            required
                                                                                            id="demo-simple-select-outlined-label"
                                                                                        >
                                                                                            Categories
                                                                                        </InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-outlined-label"
                                                                                            id="demo-simple-select-outlined"
                                                                                            onChange={e => {
                                                                                                setIsCategoryName(e.target.value)
                                                                                                let item = [...itemList];
                                                                                                x.categoryList.filter((y) => {
                                                                                                    if (y.categoryID == e.target.value) {
                                                                                                        item[index].categoryName = y.categoryName;
                                                                                                        item[index].rpu = y.rpu;
                                                                                                        item[index].categoryID = y.categoryID
                                                                                                        setItemList([...item]);
                                                                                                    }
                                                                                                })

                                                                                            }}
                                                                                            required
                                                                                            value={x.categoryID}
                                                                                            label="Name of Categories"

                                                                                        >
                                                                                            {x.categoryList &&
                                                                                                x.categoryList.map(x => {

                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            value={x.categoryID}
                                                                                                            key={x.categoryName}>
                                                                                                            {x.categoryName}
                                                                                                        </MenuItem>
                                                                                                    );
                                                                                                })}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {x.uom}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {x.qty}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    style={{ width: '120px' }}
                                                                                >
                                                                                    <TextField
                                                                                        id="outlined-basic"
                                                                                        fullWidth
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        required
                                                                                        onChange={e => {
                                                                                            handleChange(e);
                                                                                            // const p = [...payload.items];
                                                                                            if (
                                                                                                e.target.value === '' ||
                                                                                                (regEx.numbersOnly.test(
                                                                                                    e.target.value
                                                                                                ) &&
                                                                                                    e.target.value <=
                                                                                                    Number(x.qty) &&
                                                                                                    e.target.value > 0)
                                                                                            ) {
                                                                                                let item = itemList;
                                                                                                item[index].iqty =
                                                                                                    e.target.value;
                                                                                                setItemList([...item]);
                                                                                            }
                                                                                        }}
                                                                                        name={qty}
                                                                                        helperText={
                                                                                            touchedqty && errorqty
                                                                                                ? errorqty
                                                                                                : ''
                                                                                        }
                                                                                        error={Boolean(
                                                                                            touchedqty && errorqty
                                                                                        )}
                                                                                        value={x.iqty}
                                                                                        label="Quantity"
                                                                                        variant="outlined"
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {/* {console.log("x.gstRate", x.gstRate)} */}
                                                                                    {x.gstRate}
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {x.rpu}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    style={{ width: '120px' }}
                                                                                >
                                                                                    <TextField
                                                                                        id="outlined-basic"
                                                                                        fullWidth
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        // required
                                                                                        onChange={e => {
                                                                                            handleChange(e);
                                                                                            if (e.target.value === '' || /^[0-9.]*$/.test(e.target.value)) {
                                                                                                let item = itemList;
                                                                                                item[index].bagsPerUnit = e.target.value;
                                                                                                setItemList([...item]);
                                                                                            }
                                                                                        }}
                                                                                        name='bagsPerUnit'
                                                                                        value={x.bagsPerUnit}
                                                                                        label="RatePerBag"
                                                                                        variant="outlined"
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    style={{ width: '120px' }}
                                                                                >
                                                                                    <TextField
                                                                                        id="outlined-basic"
                                                                                        fullWidth
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        // required
                                                                                        onChange={e => {
                                                                                            handleChange(e);
                                                                                            // const p = [...payload.items];
                                                                                            if (e.target.value === '' || (regEx.numbersOnly.test(e.target.value))) {
                                                                                                let item = itemList; 
                                                                                                item[index].NoofBags = e.target.value;
                                                                                                setItemList([...item]);
                                                                                            }
                                                                                        }}
                                                                                        name='NoofBags'
                                                                                        value={x.NoofBags}
                                                                                        label="Bags"
                                                                                        variant="outlined"
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    {x.rpu === undefined
                                                                                        ? 0
                                                                                        : x.gstRate != 0
                                                                                            ? (Number(x.rpu) *
                                                                                                Number(x.iqty) *
                                                                                                Number(x.gstRate)) /
                                                                                            100 +
                                                                                            Number(x.rpu) * Number(x.iqty) + (Number(x.bagsPerUnit) * Number(x.NoofBags))
                                                                                            : Number(x.rpu) * Number(x.iqty) + (Number(x.bagsPerUnit) * Number(x.NoofBags))}
                                                                                </TableCell>
                                                                                {/* <TableCell align="left">
                                                                                    {' '}
                                                                                    {(x.rpu === undefined
                                                                                        ? 0
                                                                                        : x.rpu * x.iqty -
                                                                                        x.advancedReceived
                                                                                    ).toFixed(2)}
                                                                                </TableCell> */}
                                                                                {itemList.length != 1 && (
                                                                                    <TableCell align="left">
                                                                                        <DeleteOutlineIcon
                                                                                            onClick={() =>
                                                                                                removeItem(index)
                                                                                            }
                                                                                        />
                                                                                    </TableCell>
                                                                                )}
                                                                            </TableRow>

                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                    <TableRow>
                                                    <TableCell colSpan={11} align="right">
                                                        <b> Total Amount {"  "}: ₹ {totalAmount ? currencyFormat1(Math.round(totalAmount)) : 0}</b>
                                                    </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            // onClick={handleSubmit}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                        &nbsp;
                                        <Button
                                            color="primary"
                                            onClick={handleIncClose}
                                            variant="contained"
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default EditSelling;