// import React from 'react';

// const Purchasing = () => {


//     return <h1>Purchasing</h1>

// }
// export default Purchasing;

import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Checkbox,
  MenuItem, FormControl, InputLabel, Select, TextField,
  RadioGroup,
  FormControlLabel,
  Radio, FormHelperText, Modal
} from '@material-ui/core';
import Page from 'src/components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { statusColor } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './style.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RGCA_URLS } from 'src/utils/url';
import { useSelector } from 'react-redux';
import APIKit from 'src/utils/APIKit';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import StepperBox from '../Common/StepperBox.jsx';
import { useParams } from "react-router-dom";
import Loader from 'src/views/loader';
import { ToastContainer, toast } from 'react-toastify';
import Address from '../Common/Address';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  icon: {
    fill: "green",
  },
  text: {
    fill: "white",
  },
  stepIcon: {
    color: "#fff"
  },
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
  step: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WorkOrderApprovalList = () => {
  useEffect(() => {
    a11yProps(0);
    getWorkOrderAllApprovedList()
    getVendor()
    getPaymentMethod()
  }, [])

  const a11yProps = (index) => {
    if (index == 0) {
    }
    if (index == 1) {
    }


    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [workOrderView, setWorkOrderView] = React.useState(false);
  const [openincPrint, setOpenincPrint] = useState(false);
  const [generatepo, setGeneratePo] = React.useState(false);
  const [PoView, setPoView] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const projectData = useSelector(x => x);
  var proId = projectData.ProjectInfo.project.projectID
  const [allWorkOrderList, setAllWorkOrderList] = useState([]);
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const [paymentCompleted, setPaymentPaymentCompleted] = React.useState(false);

  const [workOrderList, setWorkOrderList] = useState(
    {
      "indentMasterID": "",
      "ProjectID": null,
      "statusID": null,
      "UpdatedBy": null,
      "CreatedBy": null,
      "quoteUrl": "",
      "CreateDate": "",
      "UpdatedDate": null,
      "projectName": "",
      "CreatedByName": "",
      "workOrderItem": [],
      "Approvals": []
    }
  );

  const [payload, setPayload] = useState(
    {
      "paymentMethod": '',
      "projectID": '',
      "vendorID": '',
      "reference": '',
      "value": '',
      "isGstTds": '',
      "gstTdsRate": '',
      "gstTaxExemption": '',
      "isItTaxTds": '',
      "itTdsRate": '',
      "itTaxExemption": '',
      "purposeAdvance": "",
      "balancePayable": ''
    }
  )

  const handleIndentViewClose = () => {
    setWorkOrderView(!workOrderView)
  }

  const handlePOViewClose = () => {
    setPoView(!PoView)
  }

  const handleIncPrintClose = () => {
    setOpenincPrint(!openincPrint)
  }

  const handleGeneratePo = () => {
    //  setWorkOrderView(!workOrderView)
    setWorkOrderView(false)
    setGeneratePo(!generatepo)
  }

  const [columns, setColumns] = useState([
    {
      title: "Work Order ID",
      field: "workOrderMasterID",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Project Name",
      field: "projectName",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Quote",
      field: "dsa",
      align: 'center',
      type: ETTypes.link,
    },
    {
      title: "Created By",
      field: "CreatedByName",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Created Date",
      field: "CreateDate",
      align: 'center',
      type: ETTypes.date,
    },
    {
      title: "Action",
      field: "action",
      align: 'center',
      list: [ETaction.onPayment]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }

  ])

  const viewIndent = [
    {
      title: "",
      role: "checkbox",
      type: "checkbox"
    },
    {
      title: "indent Item ID",
      field: "indentItemID",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Item / Asset Name",
      field: "name",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Uom",
      field: "uom",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Quantity",
      field: "Qty",
      align: 'center',
      type: ETTypes.numeric,
    },
    {
      title: "Estimated Price",
      field: "estimatedPrice",
      align: 'center',
      type: ETTypes.numeric,
    },
    {
      title: "Stock",
      field: "availabilityQty",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Note",
      field: "comment",
      align: 'center',
      type: ETTypes.string,
    },
  ]

  const totalQty = (data = []) => {
    let test = 0
    data.map((x) => {
      test = test + x.Qty
    })
    return test;
    //  data['totalQty'] = test;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [GSTTDS, setGSTTDS] = React.useState("NO");
  const [TAXTDS, setTAXTDS] = React.useState("NO");
  const [lookupData, setLookupData] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [payment, setPayment] = React.useState(false);
  const [paymentData, setPaymentData] = useState({});

  const actions = {
    // onView: (index, row) => {
    //   console.log(row);
    //   setWorkOrderList({ ...row })
    //   handleIndentViewClose()
    // },
    onEdit: (index, row) => {
      // setStatusEdit(true)
      // setWorkOrderList({ ...row })
      handleIndentViewClose()

    },
    onPayment: (index, row) => {
      // setStatusEdit(true)
      // setWorkOrderList({ ...row })
      console.log("dataaaa",row);
      setPaymentData(
        { ...row }
      );
      if (row.pendingAmountWO == '0' || row.pendingAmountWO == '0.00') {
        setPaymentPaymentCompleted(!paymentCompleted)
      } else {
        setPayment(!payment)
      }

    }
  }

  const handlePayViewCompleted = () => {
    setPaymentPaymentCompleted(!paymentCompleted)
  }

  const handlePayView = (resetForm = null) => {
    setPayment(!payment)
    if (resetForm) {
      resetForm();
    }
  }

  const modelActions = {
    editRow: (index, field, value) => {
      let items = workOrderList
      items.workOrderItem[index][field] = value;
      setWorkOrderList({ ...items })
    },

    onDelete: (index, row) => {
      // console.log("delete:",index, row)

    }

  }

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then((res) => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };

  const getPaymentMethod = () => {
    APIKit.get(`${RGCA_URLS.lookup}/55`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };

  const rows = [
    {
      IndentID: "IND-CI-10052021-001",
      quantity: 15,
      estimated: 450000,
      value: 0,
      status: "APPROVED",
      createBy: "Ashok Reddy-Technician",
      date: "10/05/2021 10:20AM"

    },
    {
      IndentID: "IND-CI-10052021-002",
      quantity: 35,
      estimated: 450000,
      value: 0,
      status: "APPROVED",
      createBy: "Kiran-Technician",
      date: "12/05/2021 11:20AM"
    },
    {
      IndentID: "IND-CI-10052021-003",
      quantity: 25,
      estimated: 530000,
      value: 0,
      status: "APPROVED",
      createBy: "Kiran-Technician",
      date: "13/05/2021 9:20AM"
    },
    {
      IndentID: "IND-CI-10052021-004",
      quantity: 25,
      estimated: 540000,
      value: 0,
      status: "APPROVED",
      createBy: "Narasimha-Technician",
      date: "13/05/2021 12:00PM"
    },
    {
      IndentID: "IND-CI-10052021-005",
      quantity: 15,
      estimated: 50000,
      value: 0,
      status: "APPROVED",
      createBy: "Narasimha-Technician",
      date: "14/05/2021 01:00PM"
    }
  ]

  const steps = [
    {
      label: 'Accountant Approval',
      description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: 'Project Head Approval',
      description:
        'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
      label: 'Admin Approval',
      description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
    },
    {
      label: 'Project Director Approval',
      description: `Try out different ad text to see what brings in the most customers,
                      and learn how to enhance your ads using features like ad extensions.
                      If you run into any problems with your ads, find out how to tell if
                      they're running and how to resolve approval issues.`,
    },
  ];

  const printOrder = () => {
    const printableElements = document.getElementById('printme').innerHTML;
    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML;
    const html = `<html><head><title></title>
        <style>
        
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    /* text-align: right; */
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
    text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
    /* text-align: left; */
}
        </style>
        </head><body>'${printableElements}'</body></html>`;
    // console.log(html)
    document.body.innerHTML = html
    window.print();
    document.body.innerHTML = oldPage;
    //setIncPrintOpen(false);
    window.location.reload()
  }

  const paymentAdd = () => {
    if (Number(payload.value) == 0) {
      toast.error('Value must not be 0', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    payload.projectID = projectDetails.projectID
    payload.workOrderID = paymentData.workOrderMasterID
    payload.GRNorWO = 2
    let paymentUpdate = {
      projectID: payload.projectID,
      GRNID: payload.GRNID,
      workOrderID: payload.workOrderID,
      paymentMethod: payload.paymentMethod,
      vendorID: Number(paymentData.vendorID),
      GRNorWO: 2,
      reference: Number(payload.reference),
      value: Number(payload.value),
      isGstTds: payload.isGstTds,
      gstTdsRate: payload.isGstTds ? Number(payload.gstTdsRate) : 0,
      gstTaxExemption: payload.isGstTds ? Number(payload.gstTaxExemption) : 0,
      isItTaxTds: payload.isItTaxTds,
      itTdsRate: payload.isItTaxTds ? Number(payload.itTdsRate) : 0,
      itTaxExemption: payload.isItTaxTds ? Number(payload.itTaxExemption) : 0,
      purposeAdvance: payload.purposeAdvance,
      balancePayable: Number(paymentData.pendingAmountWO) - Number(payload.value)
    }
    setIsLoading(true);
    APIKit.post(RGCA_URLS.addPaymentForGRNWO, paymentUpdate)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPayload({
            "projectID": '',
            "vendorID": '',
            "reference": '',
            "value": '',
            "isGstTds": '',
            "gstTdsRate": '',
            "gstTaxExemption": '',
            "isItTaxTds": '',
            "itTdsRate": '',
            "itTaxExemption": '',
            "purposeAdvance": "",
            "balancePayable": ''
          })
          getWorkOrderAllApprovedList()
          setPayment(!payment)
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
      });
  }

  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  const exportPdf = () => {

    html2canvas(document.querySelector("#printme")).then(canvas => {
      document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save("download.pdf");
    });

  }

  const [value, setValue] = React.useState(0); // first tab

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const getWorkOrderAllApprovedList = () => {
    APIKit.get(`${RGCA_URLS.getWorkOrderAllApprovedList}/${projectDetails.projectID}`)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              // Approvals: parseData(x.Approvals),
              workOrderItem: parseData(x.workOrderItem),
            }
          });
          setAllWorkOrderList(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };

  return (
    <Container maxWidth={true} style={{ paddingLeft: '0px', paddingRight: '0px', }}>
      <ToastContainer />
      <Typography><h2 style={{ color: '#394671' }}>Approved Work Order List</h2></Typography>
      <Grid
        style={{ marginTop: '15px' }}
        item
        lg={12}
        sm={12}
        xl={12}
        xs={12}
      >
        <div style={{ textAlign: "center" }}>
          <CommonTable columns={columns} data={allWorkOrderList} action={actions} />
        </div>
      </Grid>
      <Dialog aria-labelledby="customized-dialog-title" open={workOrderView}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none',
        }}
        maxWidth="lg" fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleIndentViewClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
          View
        </DialogTitle>
        <DialogContent dividers style={{ width: "800px !important" }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>WorkOrder name : {workOrderList.workOrderItem[0]?.name}</h5></Typography>
              </div>

            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>WorkOrder ID : {workOrderList.workOrderItem[0]?.workOrderID}</h5></Typography>
              </div>

            </Grid>


            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Date : {dateFormate(workOrderList.CreateDate)}</h5></Typography>
              </div>

            </Grid>
            {/* ---------------------------------------------------------------------------------------- */}

            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Estimated Price: {workOrderList.workOrderItem[0]?.tcv}</h5></Typography>
              </div>

            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: statusColor('PENDING') }}> Status: {workOrderList.statusID}</h5></Typography>
              </div>
            </Grid>

            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Last Update : {workOrderList.UpdatedDate || 'No data'}</h5></Typography>
              </div>

            </Grid>
            <Grid item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <div style={{ textAlign: "center" }}>
                <div style={{ textAlign: "center" }}>
                  {/* <CommonTable columns={updateColumns} data={workOrderList.workOrderItem} action={modelActions} /> */}

                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>


                          <TableCell>
                            Nature Of Expenditure
                          </TableCell>
                          <TableCell>
                            Material
                          </TableCell>
                          <TableCell>
                            Total contract Value
                          </TableCell>
                          <TableCell >
                            Payment Terms
                          </TableCell>
                          <TableCell >
                            Tax Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workOrderList?.workOrderItem && workOrderList?.workOrderItem.length > 0 && workOrderList?.workOrderItem?.map((WOA, index) => (
                          <TableRow
                            hover
                          // key={emplyoee.id}
                          // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}

                          >


                            <TableCell>
                              {WOA.noe}
                            </TableCell>
                            <TableCell>
                              {WOA.itemName}
                            </TableCell>
                            <TableCell>
                              {WOA.tcv}
                            </TableCell>
                            <TableCell >
                              {WOA.paymentTerms}
                            </TableCell>
                            <TableCell>
                              {WOA.taxDetails}%
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Box >
                {/* <StepperBox steps={workOrderList.Approvals} /> */}

              </Box>

            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <div style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  onClick={handleGeneratePo}
                  variant="contained" >
                  GO TO PO REQUEST
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  onClick={handleIndentViewClose}
                  variant="contained" >
                  Close
                </Button>
              </div>
            </Grid>

          </Grid>

        </DialogContent>

      </Dialog>

      {/* po modal */}

      <Dialog aria-labelledby="customized-dialog-title" open={PoView}
        id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none',
        }}
        maxWidth="lg" fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handlePOViewClose} style={{ backgroundColor: "#394671", color: "#fff", width: "800px !important" }}>
          Po Requested Items View
        </DialogTitle>
        <DialogContent dividers style={{ width: "800px !important" }}>
          <Grid


            container
            spacing={3}
          >
            <Grid item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <div style={{ textAlign: "center" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell align="center">Sub Category</TableCell>
                        <TableCell align="right">Asset</TableCell>
                        <TableCell align="right">UOM</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Unit Price</TableCell>
                        <TableCell align="right">Description</TableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody>

                      <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell>
                        <TableCell align="right">5000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                      </TableRow>
                      <TableRow hover role="checkbox" >
                        <TableCell align="left">Lab Consumables</TableCell>
                        <TableCell align="right">Double Frosted microscopy</TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="right">Box</TableCell>
                        <TableCell align="right">50</TableCell>
                        <TableCell align="right">5000</TableCell>
                        <TableCell align="right">Entered text</TableCell>
                      </TableRow>


                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <div style={{ textAlign: "right" }}>

                <Button
                  color="primary"
                  onClick={handlePOViewClose}
                  variant="contained" >
                  Close
                </Button>
              </div>
            </Grid>

          </Grid>

        </DialogContent>

      </Dialog>

      {/* Print */}

      <Dialog aria-labelledby="customized-dialog-title"
        contentStyle={{
          width: '100%',
          maxWidth: 'none',
        }}
        maxWidth="md" fullWidth
        open={openincPrint} id="income">
        <DialogTitle id="customized-dialog-title" onClose={handleIncPrintClose} style={{ backgroundColor: "#394671", color: "#fff" }}>
          PO Generation Print View
        </DialogTitle>
        <DialogContent dividers style={{ overflowX: "hidden" }}>
          <div>


            {/* <div style={{ marginLeft: "75%" }}> */}


            <PictureAsPdfIcon 
            // onClick={exportPdf} 
            onClick={printOrder}
            /> &nbsp;
            <PrintIcon onClick={printOrder} />
          </div>
          <div className="invoice-box" id='printme'>
            <table cellpadding="0" cellspacing="0">
              <tr className="top_rw">
                <td colspan="2">
                  <h2 style={{ marginBottom: "0px" }}> Tax invoice/Bill of Supply/Cash memo </h2>
                  <span> Number: 27B00032991LQ354 Date: 21-11-2018 </span>
                </td>
                <td style={{ width: "30%", marginRight: "10px" }}>
                  PaytmMall Order Id: 6580083283
                </td>
              </tr>
              <tr className="top">
                <td colspan="3">
                  <table>
                    <tr>
                      <td>
                        <b> Sold By: Nova Enterprises </b> <br />
                        Delhivery Pvt. Ltd. Plot No. A5 Indian Corporation <br />
                        Warehouse Park Village Dive-anjur, Bhiwandi, Off <br />
                        Nh-3, Near Mankoli Naka, District Thane, Pin Code :
                        421302 <br />
                        Mumbai, Maharashtra - 421302 <br />
                        PAN: AALFN0535C <br />
                        GSTIN: 27AALFN0535C1ZK <br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className="information">
                <td colspan="3">
                  <table>
                    <tr>
                      <td colspan="2">
                        <b> Shipping Address: </b> <br />
                        Kokar, Ranchi <br />
                        +0651-908-090-009<br />
                        info@w3learnpoint.com<br />
                        www.w3learnpoint.com
                      </td>
                      <td> <b> Billing Address: </b><br />
                        Acme Corp.<br />
                        John Doe<br />
                        john@example.com
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <td colspan="3">
                <table cellspacing="0px" cellpadding="2px">
                  <tr className="heading">
                    <td style={{ width: "25%" }}>
                      ITEM
                    </td>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      QTY.
                    </td>
                    <td style={{ width: "10%", textAlign: "right" }}>
                      PRICE (INR)
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      TAX RATE & TYPE
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      TAX AMOUNT (INR)
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      TOTAL AMOUNT (INR)
                    </td>
                  </tr>
                  <tr className="item">
                    <td style={{ width: "25%" }}>
                      Johnson's Baby Skincare Wipes 80s
                      Pack Of 2 Rs.60 Off
                      HSN Code: 4818
                      novajj079
                    </td>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      2
                    </td>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      322.03
                    </td>
                    <td style={{ width: "15%", textAlign: "center" }}>
                      18% IGST
                    </td>
                    <td style={{ width: "15%", textAlign: "center" }}>
                      57.97
                    </td>
                    <td style={{ width: "15%", textAlign: "center" }}>
                      380
                    </td>
                  </tr>
                  <tr className="item">
                    <td style={{ width: "25%" }}> <b> Grand Total </b> </td>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      2
                    </td>
                    <td style={{ width: "10%", textAlign: "right" }}>
                      322.03
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      57.97
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      380
                    </td>
                  </tr>
                </table>
              </td>

              <tr className="total">
                <td colspan="3" align="right">  Total Amount in Words :  <b> Three Hundred Eighty Rupees Only </b> </td>
              </tr>
              <tr>
                <td colspan="3">
                  <table cellspacing="0px" cellpadding="2px">
                    <tr>
                      <td width="50%">
                        <b> Declaration: </b> <br />
                        We declare that this invoice shows the actual price of the goods
                        described above and that all particulars are true and correct.The
                        goods sold are intended for end user consumption and not for resale.
                      </td>
                      <td>
                        * This is a computer generated invoice and does not
                        require a physical signature
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                      </td>
                      <td>
                        <b> Authorized Signature </b>
                        <br />
                        <br />
                        ...................................
                        <br />
                        <br />
                        <br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          {/* </div> */}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleIncPrintClose}

          >Close</Button>
        </DialogActions>

      </Dialog>

      {
        paymentData.pendingAmountWO != 0 ?
          <>
            <Formik
              enableReinitialize={true}
              initialValues={{
                ...payload,
                vendorID: paymentData.vendorID
              }}
              onSubmit={(values, { resetForm }) => {
                paymentAdd()
              }}
              validationSchema={
                Yup.object().shape({
                  reference: Yup.number().required(MESSAGE.name),
                  paymentMethod: Yup.string().required(MESSAGE.paymentMethod),
                  value: Yup.number().required(MESSAGE.value),
                  isGstTds: Yup.string().required(MESSAGE.isGstTds),
                  isItTaxTds: Yup.string().required(MESSAGE.isItTaxTds),
                  purposeAdvance: Yup.string().required(MESSAGE.purposeAdvance).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),

                  // PanCardFrontpic: Yup.string().required(MESSAGE.gstNumber),
                  // PanCardBackpic: Yup.string().required(MESSAGE.gstNumber),
                  // GSTCertificatepic: Yup.string().required(MESSAGE.gstNumber),

                })
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                resetForm,
              }) => (
                <Dialog aria-labelledby="customized-dialog-title" open={payment} id="income"
                  //  style={{ maxWidth: "1100px !important", width: "1100px" }}
                  contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                  }}
                  maxWidth="lg" fullWidth
                >
                  <Loader isLoading={isLoading} />

                  <DialogTitle onClose={handlePayView} id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff", width: "1200px !important" }}>
                    Payment
                  </DialogTitle>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent dividers style={{ width: "1200px !important" }}>
                      <Grid

                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>WorkOrder ID : {paymentData.workOrderMasterID}</h5></Typography>
                          </div>


                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Date : {dateFormate(paymentData.CreateDate)}</h5></Typography>
                          </div>

                        </Grid>


                        {/* <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={3}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>Created By : {paymentData.CreatedByName}</h5></Typography>
                      </div>

                    </Grid> */}
                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Total Value: {paymentData?.tcv}</h5></Typography>
                          </div>

                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Typography><h5 style={{ color: '#394671' }}>Pending : {paymentData?.pendingAmountWO}</h5></Typography>
                          </div>

                        </Grid>


                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.paymentMethod && errors.paymentMethod)}>
                            <InputLabel id="demo-simple-select-outlined-label">Payment Method*</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Payment Method "
                              value={payload.paymentMethod}
                              name="paymentMethod"
                              onChange={(e) => {
                                const p = payload;
                                p.paymentMethod = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                                if (e.target.value == 57) {
                                  const p = payload;
                                  p.value = paymentData.pendingAmountWO;
                                  setPayload({
                                    ...p,
                                    value: paymentData.pendingAmountWO
                                  });
                                } else {
                                  const p = payload;
                                  p.value = '';
                                  setPayload({
                                    ...p,
                                    value: ''
                                  })
                                }
                              }}

                            >
                              {lookupData && lookupData.map((x) => {
                                return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                              })}
                            </Select>
                            <FormHelperText>{(errors.paymentMethod && touched.paymentMethod) && errors.paymentMethod}</FormHelperText>
                          </FormControl>

                        </Grid>

                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} >
                            <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Location"
                              value={paymentData.vendorID}
                              name="vendorID"
                              disabled={true}
                              onChange={(e) => {
                                const p = payload;
                                p.vendorID = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }}

                            >
                              {allVendors && allVendors.map((x) => {
                                return <MenuItem value={x.vendorID} key={x.vendorID}>{x.name}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xl={6}
                          xs={6}
                        >

                          {paymentData.vendorID ? <>     <b> Supplier address : </b>
                            <Address details={allVendors?.filter((e) => {
                              console.log("work order popup", e);
                              if (e?.vendorID === paymentData?.vendorID) {
                                return {
                                  address: e?.address
                                }
                              }
                            })[0]?.address} /> </> : null}
                          {/* <b> Supplier: Nova Enterprises </b> <br />
            Delhivery Pvt. Ltd. Plot No. A5 Indian Corporation <br />
            Warehouse Park Village Dive-anjur, Bhiwandi, Off <br />
            Nh-3, Near Mankoli Naka, District Thane, <br />
            Mumbai, Maharashtra - 421302 <br />
            PAN: AALFN0535C <br />
            GSTIN: 27AALFN0535C1ZK <br /> */}
                        </Grid>


                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label="Reference"
                            error={Boolean(touched.reference && errors.reference)}
                            helperText={touched.reference && errors.reference}
                            onBlur={handleBlur}
                            value={payload.reference}
                            name="reference"
                            required
                            onChange={(e) => {
                              if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                handleChange(e);
                                const p = payload;
                                p.reference = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"

                          />
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label="Value"
                            value={payload.value}
                            required
                            name="value"
                            error={Boolean(touched.value && errors.value)}
                            helperText={touched.value && errors.value}
                            onBlur={handleBlur}
                            disabled={payload.paymentMethod == 57 ? true : false}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              if ((e.target.value === '' || regEx.numbersOnly.test(e.target.value)) && e.target.value < Number(paymentData.pendingAmountWO)) {
                                handleChange(e);
                                const p = payload;
                                p.value = e.target.value;
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.isGstTds && errors.isGstTds)}>
                            <InputLabel id="demo-simple-select-outlined-label">GST TDS*</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Location"
                              value={payload.isGstTds}
                              name="isGstTds"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                const p = payload;
                                p.isGstTds = e.target.value;
                                setGSTTDS(e.target.value)
                                setPayload({
                                  ...p,
                                });
                              }}
                            // onChange={(e) => setGSTTDS(e.target.value)}
                            >
                              <MenuItem value={true}>YES</MenuItem>
                              <MenuItem value={false}>NO</MenuItem>
                            </Select>
                            <FormHelperText>{(errors.isGstTds && touched.isGstTds) && errors.isGstTds}</FormHelperText>
                          </FormControl>
                        </Grid>
                        {payload.isGstTds === true && (<Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            helperText="Percentage"
                            label="GST TDS rate(%)"
                            value={payload.gstTdsRate}
                            name="gstTdsRate"
                            onChange={(e) => {
                              if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                const p = payload;
                                p.gstTdsRate = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>)}
                        {payload.isGstTds === true && (<Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            helperText="Enter Amount"
                            label="Tax Exemption"
                            value={payload.gstTaxExemption}
                            name="gstTaxExemption"
                            onChange={(e) => {
                              if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                const p = payload;
                                p.gstTaxExemption = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>)}


                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.isItTaxTds && errors.isItTaxTds)}>
                            <InputLabel id="demo-simple-select-outlined-label">TAX TDS*</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Location"
                              value={payload.isItTaxTds}
                              name="isItTaxTds"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e)
                                const p = payload;
                                p.isItTaxTds = e.target.value;
                                setTAXTDS(e.target.value)
                                setPayload({
                                  ...p,
                                });
                              }}
                            // value={TAXTDS}
                            // onChange={(e) => setTAXTDS(e.target.value)}
                            >
                              <MenuItem value={true}>YES</MenuItem>
                              <MenuItem value={false}>NO</MenuItem>
                            </Select>
                            <FormHelperText>{(errors.isItTaxTds && touched.isItTaxTds) && errors.isItTaxTds}</FormHelperText>
                          </FormControl>
                        </Grid>
                        {payload.isItTaxTds === true && (<Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            helperText="Percentage"
                            label="Tax TDS rate(%)"
                            value={payload.itTdsRate}
                            name="itTdsRate"
                            onChange={(e) => {
                              if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                const p = payload;
                                p.itTdsRate = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>)}
                        {payload.isItTaxTds === true && (<Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            helperText="Enter Amount"
                            label="Tax Exemption"
                            value={payload.itTaxExemption}
                            name="itTaxExemption"
                            onChange={(e) => {
                              if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                const p = payload;
                                p.itTaxExemption = Number(e.target.value);
                                setPayload({
                                  ...p,
                                });
                              }
                            }}
                            variant="outlined"
                          />
                        </Grid>)}
                        <Grid
                          item
                          lg={4}
                          sm={4}
                          xl={4}
                          xs={4}
                        >
                          <TextField
                            fullWidth
                            label="Purpose"
                            required
                            value={payload.purposeAdvance}
                            name="purposeAdvance"
                            error={Boolean(touched.purposeAdvance && errors.purposeAdvance)}
                            helperText={touched.purposeAdvance && errors.purposeAdvance}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              const p = payload;
                              p.purposeAdvance = e.target.value;
                              setPayload({
                                ...p,
                              });
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={3}
                          xl={3}
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            helperText=""
                            label="Balance Payable"
                            disabled={true}
                            value={paymentData.pendingAmountWO - payload.value}
                            name="balancePayable"
                            onChange={(e) => {
                              const p = payload;
                              p.balancePayable = Number(e.target.value);
                              setPayload({
                                ...p,
                              });
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          sm={12}
                          xl={12}
                          xs={12}
                        >
                          <div style={{ textAlign: "right" }}>
                            <Button
                              color="primary"
                              // onClick={handleAdvancePayModel}
                              // onClick={() => paymentAdd()}
                              type='submit'
                              variant="contained" >
                              Create
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              onClick={(e) => {
                                handlePayView(resetForm)
                              }}
                              color="primary"
                              variant="contained" >
                              Close
                            </Button>
                          </div>
                        </Grid>
                      </Grid>

                    </DialogContent>
                  </Form>
                </Dialog>
              )}
            </Formik>
          </> :
          <div>
            <Modal
              open={paymentCompleted}
              onClose={handlePayViewCompleted}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}>
                   <div style={{ textAlign: "center" }}>
                  <Typography><h5 style={{ color: '#394671' }}>Payment request is completed for this goods</h5></Typography>
                  <Button
                  style={{marginTop:10}}
                  color="primary"
                  variant="contained"
                  onClick={handlePayViewCompleted}
                >
                  Close
                </Button>
                </div>
              </Box>
            </Modal>

          </div>
      }
    </Container>
  )

}
export default WorkOrderApprovalList;
