import { Locations } from './action.type';
import data from '../../views/Location/data'

let initialState = {
  
    locationList:data

}

export const Location = (state=initialState, action) => {

    // console.log("final data in reducer",action.data);
    // console.log("type is ...", action.type);
    
    switch (action.type) {
      
        case Locations.SUCESS:

            return {
                ...state, 
                locationdata : action.data
            }
           
        case Locations.FAILURE :
            return {...state}
           
        default:
            return {...state};
    }

}

