import Loader from 'src/views/loader';
import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Page from 'src/components/Page';
import PrintIcon from '@material-ui/icons/Print';
import { Search as SearchIcon } from 'react-feather';
import { KeyboardDatePicker } from '@material-ui/pickers'; import 'date-fns';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HistoryIcon from '@material-ui/icons/History';
import { Link as RouterLink, useNavigate, useLocation, BrowserRouter as Router, useParams } from 'react-router-dom';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import MuiAlert from '@material-ui/lab/Alert';
import { Icon } from '@iconify/react';
import currencyInr from '@iconify-icons/mdi/currency-inr';
import { numberFormat } from '../../../utils/common';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import APIKit from '../../../utils/APIKit';
import { Data } from './data';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1, dateOnly, dateOnlyRev, getProjectDetails, parseData, precision } from '../Common/utilities';
import AutoCompleteField from '../AutoCompleteField/index';
import { ToastContainer, toast } from 'react-toastify';
import Address from '../Common/Address';
import Bank from '../Common/Bank';
import { useConfirm } from 'material-ui-confirm';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { number } from 'prop-types';
import receiptLogo from '../../Project/Selling/receipt.png';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Tooltip } from '@mui/material';
import SellingData from './Sales.json';
import TableDataHistory from './payment.json';
import PaymentIcon from '@material-ui/icons/Payment';
import Addresss from './sellingAddress';
import AddSellings from './AddSellings';
import EditSelling from './editSelling';
import PaymentScreen from './PaymentScreen';
import PaymentHistory from './PaymentHistory';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useReactToPrint } from 'react-to-print';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AppBar from '@material-ui/core/AppBar';
import DialogContentText from '@mui/material/DialogContentText';
import LabTest from './labtest';
import EditLab from './editLab';
import LabTestPayment from './LabTestPayment';
import LabTestPaymentHistory from './LabTestPaymentHistory';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const LabInvoiceView = ({ setIsInvoiceLab, isInvoiceLab, setInvoiceLabData, invoiceLabData }) => {

    const htmlContentRef = useRef(null);
    const printOrderNew = useReactToPrint({
        content: () => htmlContentRef.current,
    });


    let totalQty = 0;
    let totalPrice = 0;
    let totalAmount = 0;
    let totalTax = 0
    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
                open={isInvoiceLab}
                id="income"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => { setIsInvoiceLab(false) }}
                    style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                    Lab Print View
                </DialogTitle>
                <DialogContent dividers style={{ overflowX: 'hidden' }}>
                    <div>
                        <PictureAsPdfIcon
                            onClick={printOrderNew}
                            style={{ cursor: 'pointer' }}
                        />{' '}
                        &nbsp;
                        <PrintIcon onClick={printOrderNew} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="invoice-box" ref={htmlContentRef} id="myHtmlContent" style={{ marginTop: '15px' }}>
                        <div className="row container" >
                            <div className="col-sm-3" style={{
                                display: "flex",
                                justifyContent: "end"
                            }}>
                                <img
                                    src="/static/logo-rgca.jpg"
                                    alt="Company logo"
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div className="col-sm-9">
                                <p>
                                    <h3 style={{ textAlign: 'center' }}>
                                        RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)
                                    </h3>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        (MPEDA,Ministry of Commerce & Industry Government of India)
                                    </h5>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        HEAD OFFICE / UNIT OFFICE
                                    </h5>
                                </p>
                            </div>
                        </div>
                        <table cellpadding="0" cellspacing="0" >
                            <tr style={{ marginTop: '15px', backgroundColor: "#f4f4f4" }}>
                                <td colspan="2">
                                    <h2 >
                                        Tax invoice/Bill of Supply/Cash memo
                                    </h2>
                                    <span>
                                        {/* Number: 27B00032991LQ354  */}
                                        Date:{moment(invoiceLabData?.createdDate).format('DD/MM/YYYY')}
                                    </span>
                                </td>
                                <td >
                                    Lab Test Id: {invoiceLabData?.labTestDataMasterID}
                                </td>
                            </tr>
                            {/* <tr className="top">
                                <td colspan="3">
                                    <table>
                                        <tr>
                                            <td>
                                                <strong>{invoiceLabData?.projectName}</strong>
                                                <Address details={invoiceLabData?.projectAddress} />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr> */}
                            <tr className="information">
                                <td colspan="3">
                                    <table>
                                        <tr>
                                            <td colspan="2">
                                                <b>  Address: </b> <br />
                                                {/* <p>{invoiceLabData?.customerName}</p> */}
                                                <br />
                                                <Address details={invoiceLabData?.customerAddress} />
                                            </td>
                                            <td colspan="2">
                                                
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <td colspan="3">
                                <table class="table">
                                    <thead class='tableHead'>
                                        <tr class="table-rows">
                                            <td align='left'>S.No</td>
                                            <td align='left'>Lab Test Id</td>
                                            <td align='left'>Test Service Name</td>
                                            <td align='left'>Package</td>
                                            <td align='left'>Received By</td>
                                            <td align='left' >Total Amount</td>
                                            <td align='left'>Date & Time</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="table-row">
                                            <td align='left'>{'1'}</td>
                                            <td align='left'>{invoiceLabData?.labTestDataMasterID}</td>
                                            <td align='left'>{invoiceLabData?.TestServiceName}</td>
                                            <td align='left'>{invoiceLabData?.packages}</td>
                                            <td align='left'>{invoiceLabData?.samplereceivedby}</td>
                                            <td align='left'>₹ {currencyFormat1(Math.round(invoiceLabData?.amount))}</td>
                                            <td align='left'>{invoiceLabData?.samplecollectionDateAndTime ? moment(invoiceLabData?.samplecollectionDateAndTime).format('DD-MM-yyyy') : ''}</td>
                                        </tr>
                                        {/* <tr className="item">
                                            <td style={{ width: '10%' }}>
                                                <b> Grand Total </b>
                                            </td>

                                            <td style={{ width: '10%', textAlign: 'left' }}>
                                                <b>{totalQty}</b>
                                            </td>
                                            <td style={{ width: '10%', textAlign: 'left' }}>
                                                <b>₹ {currencyFormat1(Math.round(totalPrice))}</b>
                                            </td>
                                            <td style={{ width: '15%', textAlign: 'left' }}></td>
                                            <td style={{ width: '15%', textAlign: 'left' }}> {Math.round(totalTax)}</td>
                                            <td style={{ width: '15%', textAlign: 'left' }}>
                                                <b>  ₹  {currencyFormat1(Math.round(invoiceLabData.totalAmount))}</b>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </td>

                            {/* <tr className="total">
                                <td colspan="3" align="right">
                                    {' '}
                                    Total Amount in Words :{' '}
                                    <b> {price_in_words(Math.round(invoiceLabData.totalAmount))} Rupees only </b>{' '}
                                </td>
                            </tr> */}
                            <tr>
                                <td colspan="3">
                                    <table cellspacing="0px" cellpadding="2px">
                                        <tr>
                                            <td width="50%">
                                                <b> Declaration: </b> <br />
                                                We declare that this invoice shows the actual price of
                                                the goods described above and that all particulars are
                                                true and correct.The goods sold are intended for end
                                                user consumption and not for resale.
                                            </td>
                                            <td>
                                                * This is a computer generated invoice and does not
                                                require a physical signature
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"></td>
                                            <td>
                                                <b> Authorized Signature </b>
                                                <br />
                                                <br />
                                                ...................................
                                                <br />
                                                <br />
                                                <br />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                </DialogContent>
                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setIsInvoiceLab(false);
                            setInvoiceLabData({})
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LabInvoiceView