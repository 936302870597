import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText, TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import mainlogo from 'src/Assets/cyptocurrency.png'
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIKit from "../../../utils/APIKit"
import { getloggedInData } from '../../../utils/helpers'
import { APP_URLS } from '../../../utils/apis';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
// import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from "material-ui-confirm";
import { Formik, Form, FieldArray, getIn } from 'formik';
import { MESSAGE } from 'src/utils/message';
import { useToasts } from 'react-toast-notifications';
import { RGCA_URLS } from 'src/utils/url';
import AutoCompleteField from '../AutoCompleteField'
import CommonTable from '../Common/CommonTable';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import { useParams } from "react-router-dom";
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loader from 'src/views/loader';
import Address from '../Common/Address';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CommonTable1 from '../Common/commonTableIndent';
import StepperBox from '../Common/StepperBox.jsx';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4', borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px', marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const WorkOrder = () => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const [alertopen, setAlertOpen] = React.useState(false);
  const [openTest, setOpenTest] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openTest1, setOpenTest1] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMeg, setErrorMeg] = React.useState('');
  const projectData = useSelector(x => x);
  var proId = projectData.ProjectInfo.project.projectID;
  const [open, setOpen] = React.useState(false);
  const [IndentItemList, setIndentItemList] = useState([])
  const [getMaterialsList, setGetMaterialsList] = useState([])
  const [lookupData, setLookupData] = useState([]);
  const [scroll, setScroll] = useState('paper');
  const [type, setType] = useState(2)
  const [woView, setWoView] = React.useState(false);
  const handleWOView = () => {
    setWoView(!woView)
    getWorkorder()
  }
  const [itemList, setItemList] = useState([
    {
      "noe": '',
      "qty": '',
      "unit": "",
      "rate": "",
      "isGst": "",
      "gstType": "",
      "CGST": "",
      "SGST": "",
      "IGST": "",
      "UGST": "",
      "totalValue": '',
      "itemDescription": ''
    }
  ])
  const [workOrder, setWorkOrder] = useState(
    {
      "projectID": 1,
      "vendorID": "",
      "name": "",
      "email": "",
      "mobile": "",
      "description": "",
      "noe": "",
      "materialItem": '',
      "dsa": "",
      "qty": "",
      "tcv": '',
      "taxDetails": "",
      "paymentTerms": "",
      "otherConditions": "",
      "address": {
        "line_1": "",
        "line_2": "",
        "line_3": "",
        "district": "",
        "state": "",
        "country": "",
        "postalCode": ''
      }
    }

  );


  const resetWorkOrder = () => {
    setAllVendors("")
  }
  const updateColumns = [
    {
      title: "WorkOrder ID",
      field: "workOrderID",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Nature",
      field: "noename",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Item Description",
      field: "itemDescription",
      align: 'center',
      type: ETTypes.string,
      editable: EEditable.onEdit
    },

    {
      title: "Quantity",
      field: "qty",
      align: 'center',
      type: ETTypes.numeric,
      editable: EEditable.onEdit
    },
    {
      title: "Rate",
      field: "rate",
      align: 'center',
      type: ETTypes.numeric,
      editable: EEditable.onEdit

    },
    {
      title: "Unit",
      field: "unit",
      align: 'center',
      type: ETTypes.string,
      editable: EEditable.onEdit
    },
    {
      title: "Total Value",
      field: "totalValue",
      align: 'center',
      type: ETTypes.numeric,

    },
    {
      title: "IsGST",
      field: "isGst",
      align: 'center',
      type: ETTypes.boolean,
    },
    {
      title: "CGST",
      field: "CGST",
      align: 'center',
      type: ETTypes.string,
    }, {
      title: "SGST",
      field: "SGST",
      align: 'center',
      type: ETTypes.string,
    }, {
      title: "IGST",
      field: "IGST",
      align: 'center',
      type: ETTypes.string,
    }, {
      title: "UGST",
      field: "UGST",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Action",
      field: "action",
      align: 'center',
      list: [ETaction.onDelete]
    }
  ]
  const [allVendors, setAllVendors] = useState([])
  const [btn, setBtn] = useState(false)
  const { state } = useLocation();
  const addItem = () => {
    let temp = itemList;
    temp.push({
      "noe": '',
      "qty": '',
      "unit": "",
      "rate": "",
      "isGst": "",
      "gstType": "",
      "CGST": "",
      "SGST": "",
      "IGST": "",
      "UGST": "",
      "totalValue": '',
      "itemDescription": ''
    })
    setItemList([...temp]);
  }
  const [payload, setPayload] = useState({
    "projectID": null,
    "vendorID": '',
    "gstType": '',
    "tcv": '',
    "dsa": '',
    "items": itemList
  });
  const navigate = useNavigate();
  const handleCloseModal = (resetForm = '') => {
    setOpenTest(false);
    setWorkOrder(
      {
        "projectID": 1,
        "vendorID": "",
        "name": "",
        "email": "",
        "mobile": "",
        "description": "",
        "noe": "",
        "materialItem": '',
        "dsa": "",
        "qty": "",
        "tcv": "",
        "taxDetails": "",
        "paymentTerms": "",
        "otherConditions": "",
        "address": {
          "line_1": "",
          "line_2": "",
          "line_3": "",
          "district": "",
          "state": "",
          "country": "",
          "postalCode": ""
        }
      }
    )
    if (resetForm) {
      resetForm();
    }
  };
  const [workOrderList, setWorkOrderList] = useState([]);
  const modelActions = {
    editRow: (index, field, value) => {
      let items = singleWO
      items.workOrder[index][field] = value;
      setSIngleWo({ ...items })
      if (field == 'qty') {
        let items = singleWO
        items.workOrder[index]['totalValue'] = value * items.workOrder[index]['rate'] + ((value * items.workOrder[index]['rate']) * (((items.workOrder[index]['inter'] > (items.workOrder[index]['intra'] + items.workOrder[index]['union'])) ? items.workOrder[index]['inter'] : (items.workOrder[index]['intra'] > (items.workOrder[index]['inter'] + items.workOrder[index]['union'])) ? items.workOrder[index]['intra'] : items.workOrder[index]['union']) / 100));;
        setSIngleWo({ ...items })
      } else if (field == 'rate') {
        let items = singleWO
        items.workOrder[index]['totalValue'] = value * items.workOrder[index]['qty'] + ((value * items.workOrder[index]['qty']) * (((items.workOrder[index]['inter'] > (items.workOrder[index]['intra'] + items.workOrder[index]['union'])) ? items.workOrder[index]['inter'] : (items.workOrder[index]['intra'] > (items.workOrder[index]['inter'] + items.workOrder[index]['union'])) ? items.workOrder[index]['intra'] : items.workOrder[index]['union']) / 100));
        setSIngleWo({ ...items })
      }
    },

    onDelete: (index, row) => {
      // console.log("delete:",index, row)
      removeWorkOrder(row)

    }

  }
  const [columns, setColumns] = useState([
    {
      title: "WorkOrder MasterID",
      field: "workOrderMasterID",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Gst Type",
      field: "gstType",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Created By",
      field: "CreatedByName",
      align: 'center',
      type: ETTypes.numeric,
    },
    {
      title: "Attachment",
      field: "dsa",
      align: 'center',
      type: ETTypes.link,
    },
    {
      title: "Total Contract Value",
      field: "tcv",
      align: 'center',
      type: ETTypes.string,
    },
    {
      title: "Pending Amount",
      field: "pendingAmountWO",
      align: 'center',
      type: ETTypes.numeric,
    },
    {
      title: "Action",
      field: "action",
      align: 'center',
      list: [ETaction.onEdit]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }

  ])
  const [singleWO, setSIngleWo] = useState([]);

  const actions = {

    onEdit: (index, row) => {
      setWoView(!woView)
      setScroll('paper');
      getMaterials(row.noe, projectDetails.projectID)
      setIsEdit(true);
      setBtn(true)
      setSIngleWo({ ...row })
      setWorkOrder({ ...row })
    },
    onDelete: (index, row) => {
      removeWorkOrder(row)
    }

  }

  useEffect(() => {
    getWorkorder();
    getLookup()
    getVendor()
  }, [navigate]);
  const handleRemoveField = (index) => {
    let items = itemList;
    items.splice(index, 1)
    setItemList([...items])
  };
  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then((res) => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };
  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData([...res.data.data])
          setIndentItemList([{ ...itemInitialValues, typeList: res.data.data }])
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const itemInitialValues = {
    "typeList": [],
    "materialList": [],
  }
  const totalQty = (data = []) => {
    let test = 0
    data.map((x) => {
      test = test + x.qty
    })
    return test;
    //  data['totalQty'] = test;
  }
  const getMaterials = (typeID, projectID) => {
    let payload = {
      projectID: projectID,
      typeID: Number(typeID)
    }

    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then((res) => {
        if (res.data.status === 200) {

          setGetMaterialsList([...res.data.data])
        }
        else {

        }
      })
      .catch(function (e) {
      })
    // console.log('text-text',payload)
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };


  const updateWorkOrder = () => {
    const payload = {
      workOrderMasterID: singleWO.workOrderMasterID,
      tcv: singleWO.workOrder.reduce((a, b) => ((b.qty * b.rate) + ((b.qty * b.rate) * (((b.inter > (b.intra + b.union)) ? b.inter : (b.intra > (b.inter + b.union)) ? b.intra : b.union) / 100))) + a, 0),
      items: singleWO.workOrder.map(e => {
       
        return {
          workOrderID: e.workOrderID,
          noe: e.noe,
          itemDescription: e.itemDescription,
          qty: e.qty,
          units: e.unit,
          rate: e.rate,
          totalValue: e.inter > (e.intra + e.union) ? (e.qty * e.rate) + ((e.qty * e.rate) * e.inter / 100) : e.intra > (e.inter + e.union) ? (e.qty * e.rate) + ((e.qty * e.rate) * e.intra / 100) : (e.qty * e.rate) + ((e.qty * e.rate) * e.union / 100),
          isGST: e.isGst,
          CGST: e.CGST,
          SGST: e.SGST,
          IGST: e.IGST,
          UGST: e.UGST
        }
      })

    }

    APIKit.put(RGCA_URLS.updateworkorder, payload)
      .then((res) => {
        if (res.data.status === 200) {
          getWorkorder()
          // addToast(res.data.message, { appearance: 'error' });
          toast.success(MESSAGE.workorderUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          handleWOView()

        }
        else {
          // addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.workorderNotUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }

  const getWorkorder = () => {
    
    const payload = {
      projectID: projectDetails.projectID
    }
    APIKit.post(RGCA_URLS.listworkOrder, payload)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              Approvals: parseData(x.Approvals),
              workOrder: parseData(x.workOrder).map((e) => {
                return {
                  ...e,
                  inter: e.IGST,
                  intra: e.SGST + e.CGST,
                  union: e.UGST + e.CGST
                }
              }),
            }
          })
          setWorkOrderList(data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const removeWorkOrder = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        remove(data)
        // activeOrInactive(status,id)
      })
      .catch(() => console.log("Deletion cancelled."));

  }

  const searchWorkOrder = (e) => {
    if (e) {
      const payload = {
        projectID: projectDetails.projectID,
        searchText: e
      }
      APIKit.post(RGCA_URLS.listworkOrder, payload)
        .then((res) => {
          if (res.data.status === 200) {
            setWorkOrderList(res.data.data)
          }
          else {
            // setAlertMeg('error');
            // setErrorMeg(res.data.message);
            // setAlertOpen(true);
          }
        })
        .catch(function (e) {
        })
    } else {
      getWorkorder();
    }
  }

  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx",,".xlsx",".xls",'.excel','.csv', "application/pdf","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0] !== undefined) {
      if (event.target.files[0].size > 3145728) {
        toast.error(MESSAGE.lessthan3mb, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        return
      };
      setIsLoading(true);
      const formData = new FormData();
      // formData.append('upload', event.target.files[0], event.target.files[0].name);
      formData.append('files', event.target.files[0], event.target.files[0].name);
console.log("formData is", formData);
      if (type == 'dsa') {
        // APIKit.post(RGCA_URLS.fileUpload, formData) 
        APIKit.post(RGCA_URLS.upload, formData)
          .then((res) => {
            console.log("fileUpload res",res.data);
            if (res.data.status == 200) {
              setIsLoading(false);
              // addToast(res.data.message, { appearance: 'success' });
              toast.success(MESSAGE.fileUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              setPayload({
                ...payload,
                dsa: res.data.data.file,
              });
            } else {
              setIsLoading(false);
              toast.error(MESSAGE.fileNotUpload, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          })
          .catch((e) => {

          });
      }

    }
  };



  const remove = (data) => {
    APIKit.delete(RGCA_URLS.deleteworkOrder + '/' + data.workOrderID)
      .then((res) => {
        if (res.data.status === 200) {
          // addToast(res.data.message, { appearance: 'success' });
          toast.success(MESSAGE.workorderDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          getWorkorder()
        }
        else {
          // addToast(res.data.message, { appearance: 'error' });
          toast.error(MESSAGE.workOrderNotDeleted, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch(function (e) {
      })
  }
  const [inter, setInter] = useState(false)
  const [intra, setIntra] = useState(false)
  const [union, setUnion] = useState(false)
  const [gstState, setGstState] = useState('')
  const handleState = (e) => {
    if (e.target.value === 'Interstate') {
      setInter(true)
      setIntra(false)
      setUnion(false)
      setGstState(e.target.value)
    } else if (e.target.value === 'Intrastate') {
      setIntra(true)
      setInter(false)
      setUnion(false)
      setGstState(e.target.value)
    } else if (e.target.value === 'Union') {
      setUnion(true)
      setInter(false)
      setIntra(false)
      setGstState(e.target.value)
    }
  }
  const openWorkOrder = () => {
    setBtn(false)
    setWorkOrder(
      {
        "projectID": 1,
        "vendorID": "",
        "name": "",
        "email": "",
        "mobile": "",
        "description": "",
        "noe": "",
        "materialItem": '',
        "dsa": "",
        "qty": "",
        "tcv": "",
        "taxDetails": "",
        "paymentTerms": "",
        "otherConditions": "",
        "address": {
          "line_1": "",
          "line_2": "",
          "line_3": "",
          "district": "",
          "state": "",
          "country": "",
          "postalCode": ""
        }
      }
    )
    setOpenTest(false);
    setOpenTest(true);
  }
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/
  };

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })
  return (
    <Page
      className={classes.root}
      title="Work Order">
      <Loader isLoading={isLoading} />
      <Container maxWidth={false}>
        <ToastContainer />

        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
          <Alert onClose={handleAlertClose} severity={alertMeg} >
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                Work Order List
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openWorkOrder}
                >
                  Create Work Order
                </Button>
              </Grid>
            </Grid>

          </Box>
          {/* <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      searchWorkOrder(e.target.value)
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box> */}
        </div>
        <Box mt={3}>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div style={{ textAlign: "center" }}>
              <CommonTable columns={columns} data={workOrderList} action={actions} />
            </div>
          </Grid>
        </Box>

      </Container>
      <Formik
        enableReinitialize={true}
        initialValues={{
          "vendorID": payload.vendorID,
          "gstType": gstState,
          "tcv": payload.tcv,
          "dsa": payload.dsa,
          WO: [
            ...itemList
          ],
        }}
        onSubmit={(values) => {

          setIsLoading(true);
          let pay = {
            projectID: projectDetails.projectID,
            vendorID: payload.vendorID,
            tcv: itemList.reduce((a, b) => ((b.qty * b.rate) + ((b.qty * b.rate) * (b.gstType / 100))) + a, 0),
            dsa: payload.dsa,
            gstType: gstState,
            items: itemList.map(e => {
              return {
                noe: e.noe,
                itemDescription: e.itemDescription,
                qty: e.qty,
                units: e.unit,
                rate: e.rate,
                totalValue: (e.qty * e.rate) + ((e.qty * e.rate) * (e.gstType / 100)),
                isGST: e.isGst,
              }
            })
          }
          if (inter) {
            pay = {
              ...pay,
              items: itemList.map((e) => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue: (e.qty * e.rate) + ((e.qty * e.rate) * (e.gstType / 100)),
                  isGST: e.isGst,
                  SGST: 0,
                  CGST: 0,
                  IGST: e.gstType,
                  UGST: 0
                }
              })
            };
          } else if (intra) {
            pay = {
              ...pay,
              items: itemList.map((e) => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue: (e.qty * e.rate) + ((e.qty * e.rate) * (e.gstType / 100)),
                  isGST: e.isGst,
                  SGST: e.gstType / 2,
                  CGST: e.gstType / 2,
                  IGST: 0,
                  UGST: 0
                }
              })
            };
          } else if (union) {
            pay = {
              ...pay,
              items: itemList.map((e) => {
                return {
                  noe: e.noe,
                  itemDescription: e.itemDescription,
                  qty: e.qty,
                  units: e.unit,
                  rate: e.rate,
                  totalValue: (e.qty * e.rate) + ((e.qty * e.rate) * (e.gstType / 100)),
                  isGST: e.isGst,
                  SGST: 0,
                  CGST: e.gstType / 2,
                  IGST: 0,
                  UGST: e.gstType / 2
                }
              })
            };
          }
          pay.projectID = projectDetails.projectID;
          APIKit.post(RGCA_URLS.addWorkOrder, pay)
            .then((res) => {
              if (res.data.status === 200) {
                setIsLoading(false);
                handleCloseModal();
                getWorkorder();
                toast.success(MESSAGE.workorderSuccessfully, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
                setItemList([{
                  "noe": '',
                  "qty": '',
                  "unit": "",
                  "rate": "",
                  "isGst": "",
                  "gstType": "",
                  "CGST": "",
                  "SGST": "",
                  "IGST": "",
                  "UGST": "",
                  "totalValue": '',
                  "itemDescription": ''
                }])
                setPayload({
                  "vendorID": '',
                  "gstType": '',
                  "tcv": '',
                  "dsa": '',
                  "items": []
                })
              } else {
                setIsLoading(false);
                toast.error(res.data.error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              }
            })
            .catch((e) => {
              setIsLoading(false);
            });
        }}
        validationSchema={
          Yup.object().shape({
            vendorID: Yup.string().required(MESSAGE.vendor),
            WO: Yup.array().of(
              Yup.object().shape({
                noe: Yup.string().required(MESSAGE.noe),
                qty: Yup.string().required(MESSAGE.qty),
                unit: Yup.string().required(MESSAGE.unit),
                rate: Yup.string().required(MESSAGE.rpu),
                isGst: Yup.string().required(MESSAGE.gstsno),
                itemDescription: Yup.string().required(MESSAGE.itemDescription)

              })
            ),

          })
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <MuiThemeProvider theme={formLabelsTheme}>
            <Dialog scroll='paper'
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description" open={openTest} id="income"

              contentStyle={{
                width: '200%',
                maxWidth: 'none',
              }}
              maxWidth="lg">
              <Loader isLoading={isLoading} />

              <DialogTitle
                onClose={(e => {
                  handleCloseModal(resetForm)
                })}
                id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff" }}>
                Work Order Creation

              </DialogTitle>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <DialogContent dividers={scroll === 'paper'}>
                  <Box mt={3} style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                  }}>
                    <Grid
                      container
                      spacing={3}
                    >

                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <FormControl variant="outlined" style={{ width: '100%' }} error={Boolean(touched.vendorID && errors.vendorID)} className={classes.formControl}>
                          <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Location"
                            value={payload.vendorID}
                            name="vendorID"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              const p = payload;
                              p.vendorID = Number(e.target.value);

                              setPayload({
                                ...p,
                              });
                            }}

                          >
                            {allVendors && allVendors.map((x) => {
                              return <MenuItem value={x.vendorID} key={x.vendorID}>{x.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText>{(errors.vendorID && touched.vendorID) && errors.vendorID}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        xs={12}
                      >
                        {payload.vendorID ? <>     <b> Vendor address : </b>
                          <Address details={allVendors?.filter((e) => {
                            if (e.vendorID === payload?.vendorID) {
                              return {
                                address: e.address
                              }
                            }
                          })[0].address} /> </> : null}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{padding:'0 !importnant'}}
                      >
                        <Button
                          variant="contained"
                          component="label"
                        >
                          Detailed Specification Attach kk<div><input
                            type="file"
                            hidden
                            onChange={(e) => handleUploadClick(e, 'dsa')}
                          />
                            {payload.dsa && (<a style={{ color: 'blue', cursor: 'pointer' }}
                              href={payload.dsa} target="_blank"
                            >:{payload.dsa.substring(4, 20)}
                            </a>
                            )}</div>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <RadioGroup aria-label="anonymous" name="row-radio-buttons-group" row onChange={handleState}>
                          <FormControlLabel value="Interstate" control={<Radio />} label="Interstate" />
                          <FormControlLabel value="Intrastate" control={<Radio />} label="Intrastate" />
                          <FormControlLabel value="Union" control={<Radio />} label="Union Territory" />
                        </RadioGroup>

                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}
                      >
                        <div style={{ textAlign: "right" }}>
                          <AddCircleIcon onClick={addItem} />



                        </div>
                      </Grid>

                      {/* <TextField
                                    fullWidth
                                    helperText=""
                                    label="Nature of the expenditure"
                                   
                                    
                                    variant="outlined"
                                /> */}
                      <div>
                        <Grid
                          container
                          spacing={3}
                        >
                          <FieldArray name="WO">
                            {({ push, remove }) => (
                              <>
                                {itemList.map((item, index) => {
                                  const noe = `WO[${index}].noe`;
                                  const touchednoe = getIn(touched, noe);
                                  const errornoe = getIn(errors, noe);

                                  const qty = `WO[${index}].qty`;
                                  const touchedqty = getIn(touched, qty);
                                  const errorqty = getIn(errors, qty);

                                  const unit = `WO[${index}].unit`;
                                  const touchedunit = getIn(touched, unit);
                                  const errorunit = getIn(errors, unit);

                                  const isGst = `WO[${index}].isGst`;
                                  const touchedisGst = getIn(touched, isGst);
                                  const errorisGst = getIn(errors, isGst);

                                  const totalValue = `WO[${index}].totalValue`;
                                  const touchedtotalValue = getIn(touched, totalValue);
                                  const errortotalValue = getIn(errors, totalValue);

                                  const itemDescription = `WO[${index}].itemDescription`;
                                  const toucheditemDescription = getIn(touched, itemDescription);
                                  const erroritemDescription = getIn(errors, itemDescription);

                                  const rate = `WO[${index}].rate`;
                                  const touchedrate = getIn(touched, rate);
                                  const errorrate = getIn(errors, rate);

                                  return (

                                    <>

                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <FormControl
                                          error={Boolean(touchednoe && errornoe)}
                                          variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                          <InputLabel required id="demo-simple-select-outlined-label">Nature Of Expenditure</InputLabel>
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              handleChange(e)
                                              let item = [...itemList];
                                              item[index].noe = e.target.value;
                                              setItemList([...item])
                                            }}
                                            value={item.noe}
                                            name={noe}
                                            required
                                            // value={values.type}
                                            label="Nature Of Expenditure"
                                          >
                                            {IndentItemList[0].typeList.map((l) => {
                                              return <MenuItem value={l.lookupID} key={l.lookupID}>{l.name}</MenuItem>;
                                            })}

                                          </Select>
                                          <FormHelperText>{touchednoe && errornoe
                                            ? errornoe
                                            : ""}</FormHelperText>                                        </FormControl>

                                      </Grid>

                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          onBlur={handleBlur}
                                          helperText={
                                            touchedqty && errorqty
                                              ? errorqty
                                              : ""
                                          }
                                          error={Boolean(touchedqty && errorqty)}
                                          onChange={(e) => {
                                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                              handleChange(e);
                                              let item = [...itemList];
                                              item[index].qty = e.target.value;
                                              setItemList([...item])
                                            }
                                          }}
                                          value={item.qty}
                                          label="Quantity"
                                          name={qty}

                                          variant="outlined"
                                          inputProps={{ maxLength: 5}}

                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          label="Unit"
                                          name={unit}
                                          helperText={
                                            touchedunit && errorunit
                                              ? errorunit
                                              : ""
                                          }
                                          error={Boolean(touchedunit && errorunit)}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                              handleChange(e);

                                              let item = [...itemList];
                                              item[index].unit = e.target.value;
                                              setItemList([...item])
                                            }
                                          }}
                                          inputProps={{ maxLength: 9 }}
                                          required
                                          value={item.unit}
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          label="Rate per unit"
                                          name={rate}
                                          helperText={
                                            touchedrate && errorrate
                                              ? errorrate
                                              : ""
                                          }
                                          error={Boolean(touchedrate && errorrate)}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                              handleChange(e);
                                              let item = [...itemList];
                                              item[index].rate = e.target.value;
                                              setItemList([...item])
                                            }
                                          }}
                                          inputProps={{ maxLength: 9 }}
                                          required
                                          value={item.rate}
                                          variant="outlined"
                                        />
                                      </Grid>




                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <FormControl
                                          variant="outlined"
                                          style={{ width: '100%' }}
                                          className={classes.formControl}
                                          error={Boolean(touchedisGst && errorisGst)}
                                        >
                                          <InputLabel id="demo-simple-select-outlined-label">GST</InputLabel>
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="GST"
                                            name={isGst}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              handleChange(e);
                                              let elem = [...itemList];
                                              elem[index].isGst = e.target.value;
                                              setItemList([...elem])
                                              if (!e.target.value) {
                                                let item = [...itemList];
                                                item[index].gstType = 0;
                                                setItemList([...item])
                                              }
                                            }}
                                            value={item.isGst}
                                          >

                                            <MenuItem value={true}>YES</MenuItem>
                                            <MenuItem value={false}>NO</MenuItem>
                                          </Select>
                                          <FormHelperText>{touchedisGst && errorisGst
                                            ? errorisGst
                                            : ""}</FormHelperText>
                                        </FormControl>
                                      </Grid>
                                      {itemList[index].isGst
                                        && (
                                          <Grid
                                            item
                                            md={3}
                                            xs={12}
                                          >

                                            <TextField
                                              fullWidth
                                              inputProps={{ maxLength: 2 }}
                                              onChange={(e) => {
                                                let item = [...itemList];
                                                item[index].gstType = Number(e.target.value);
                                                setItemList([...item])
                                              }}
                                              value={item.gstType}
                                              label="GST Rate(%)"
                                              name="gstValue"
                                              variant="outlined"
                                            />
                                          </Grid>
                                        )}
                                      {inter && itemList[index].gstType ? <><Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <strong>IGST:</strong>  {itemList[index].gstType} &nbsp;
                                        <strong>IGST Value:</strong>  {((item.qty * item.rate) * (itemList[index].gstType / 100)).toFixed(2)}
                                      </Grid></> : null}
                                      {intra && itemList[index].gstType ? <><Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <strong>SGST:</strong>  {itemList[index].gstType / 2} &nbsp;
                                        <strong>SGST Value:</strong>  {((item.qty * item.rate) * ((itemList[index].gstType / 2) / 100)).toFixed(2)}
                                        &nbsp;
                                        <strong>CGST:</strong>  {itemList[index].gstType / 2} &nbsp;
                                        <strong>CGST Value:</strong>  {((item.qty * item.rate) * ((itemList[index].gstType / 2) / 100)).toFixed(2)}
                                      </Grid></> : null}
                                      {union && itemList[index].gstType ? <>
                                        <Grid
                                          item
                                          md={3}
                                          xs={12}
                                        >
                                          <strong>UGST:</strong>  {itemList[index].gstType / 2} &nbsp;
                                          <strong>UGST Value:</strong>  {((item.qty * item.rate) * ((itemList[index].gstType / 2) / 100)).toFixed(2)}
                                          &nbsp;

                                          <strong>CGST:</strong>  {itemList[index].gstType / 2} &nbsp;
                                          <strong>CGST Value:</strong>  {((item.qty * item.rate) * ((itemList[index].gstType / 2) / 100)).toFixed(2)}
                                        </Grid></> : null}
                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          helperText={
                                            touchedtotalValue && errortotalValue
                                              ? errortotalValue
                                              : ""
                                          }
                                          error={Boolean(touchedtotalValue && errortotalValue)}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            if (e.target.value === '' || regEx.percentage.test(e.target.value)) {
                                              handleChange(e);
                                              let item = [...itemList];
                                              item[index].totalValue = e.target.value;
                                              setItemList([...item])
                                            }
                                          }}
                                          disabled={true}
                                          required
                                          value={(item.qty * item.rate) + ((item.qty * item.rate) * (itemList[index].gstType / 100))}
                                          label="Total Value"
                                          name={totalValue}
                                          variant="outlined"
                                          inputProps={{ maxLength: 5 }}

                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          helperText={
                                            toucheditemDescription && erroritemDescription
                                              ? erroritemDescription
                                              : ""
                                          }
                                          error={Boolean(toucheditemDescription && erroritemDescription)}
                                          onBlur={handleBlur}
                                          multiline
                                          rows={5}
                                          onChange={(e) => {
                                            handleChange(e);
                                            let item = [...itemList];
                                            item[index].itemDescription = e.target.value;
                                            setItemList([...item])
                                          }}
                                          required
                                          value={item.itemDescription}
                                          label="Item Description"
                                          name={itemDescription}
                                          variant="outlined"
                                          inputProps={{ maxLength: 500 }}

                                        />

                                      </Grid>
                                      <Grid
                                        item
                                        md={3}
                                        xs={12}
                                      >


                                      </Grid>
                                      <Grid
                                        item
                                        md={1}
                                        xs={12}
                                      >
                                        {itemList.length > 1 && <DeleteOutlineIcon onClick={(e) => {
                                          confirm({ description: "You want to delete the record ?" })
                                            .then(() => {
                                              // let temp = GINData;
                                              // temp.splice(i, 1)
                                              // setGINdata([...temp])
                                              handleRemoveField(index)
                                            })
                                            .catch(() => console.log("Deletion cancelled."));

                                        }} style={{ marginTop: '15px', textAlign: "center" }} />}

                                      </Grid>

                                    </>
                                  );
                                })}
                              </>
                            )}
                          </FieldArray>

                        </Grid>

                      </div>




                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                  {workOrder.editLock == 1 && <span style={{ color: "red" }}>Work Order is In Progress</span>}
                  &nbsp;
                  <Button onClick={(e => {
                    handleCloseModal(resetForm)
                    resetWorkOrder()
                  })} className={classes.button2}>
                    Cancel
                  </Button>
                  {btn == false ?
                    <Button type="submit" className={classes.button2}>
                      Save
                    </Button>
                    :
                    <>{workOrder.editLock == 1 ?
                      <></> : <Button onClick={updateWorkOrder} className={classes.button2}>
                        Update
                      </Button>} </>}

                </DialogActions>
              </Form>

            </Dialog>

          </MuiThemeProvider>
        )}
      </Formik>
      <Dialog aria-labelledby="customized-dialog-title" open={woView} id="income"
        contentStyle={{
          width: '200%',
          maxWidth: 'none',
        }}
        maxWidth="lg" fullWidth
      >

        <DialogTitle id="customized-dialog-title" onClose={handleWOView} style={{ backgroundColor: "#394671", color: "#fff", width: "900px !important" }}>
          Work Order
        </DialogTitle>
        <DialogContent dividers style={{ width: "900px !important" }}>
          <Grid



            container
            spacing={3}
          >

            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Indent ID : {singleWO.workOrderMasterID}</h5></Typography>
              </div>

            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Total Quantity: {totalQty(singleWO.workOrder || [])}</h5></Typography>
              </div>
            </Grid>

            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={4}
            >
              <div style={{ textAlign: "center" }}>
                <Typography><h5 style={{ color: '#394671' }}>Date : {dateFormate(singleWO.CreateDate)}</h5></Typography>
              </div>

            </Grid>
            {/* ---------------------------------------------------------------------------------------- */}


            <Grid item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <div style={{ textAlign: "center" }}>
                <CommonTable1 columns={updateColumns} data={singleWO.workOrder}
                  action={modelActions}
                  editLock={singleWO.Approvals} />

              </div>
            </Grid>

          </Grid>

          <br />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <StepperBox steps={singleWO.Approvals} />


            </Grid>

          </Grid>
          {/* <Box>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              lg={12}>
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >


                  <Typography className={classes.secondaryHeading}>WorkOrder Approved Item History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Item Name</TableCell>
                          <TableCell>Requested Quantity</TableCell>
                          <TableCell>Approval Quantity</TableCell>
                          <TableCell>Updated By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemHis && itemHis.length != 0 ? itemHis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (

                          <TableRow >
                            <TableCell>{item.itemName}</TableCell>
                            <TableCell>{item.requestQty}</TableCell>
                            <TableCell>{item.newValue}</TableCell>
                            <TableCell>{item.employeeName}</TableCell>
                          </TableRow>
                        )) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={4} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box> */}



        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>

          <Button
            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
            onClick={(e) => {
              updateWorkOrder()
            }}
            disabled={singleWO?.Approvals?.some(e => e.status != "Awaiting") && singleWO?.Approvals?.every(e => e.status != "Modification")}
          >
            Update
          </Button>


          <Button
            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
            onClick={handleWOView}
          >
            Close
          </Button>

        </DialogActions>

      </Dialog>


    </Page>
  )

}
export default WorkOrder;
