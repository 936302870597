import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Selectoption from 'react-select';
import {
    Container, Grid, Typography, Button, makeStyles, Box, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Select, InputLabel, FormControl, MenuItem, Modal, FormHelperText, RadioGroup, FormControlLabel, Radio, TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    formControl: {
        width: '100%'
        // marginTop:"125px"
    },
    centertxt: {
        textTransform: 'none',

        [theme.breakpoints.down('xs')]: {
            marginLeft: '65px',
            height: '55px',
            marginTop: '5px'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
            height: '52px',
        }
    },

    manuval: {
        textTransform: 'none',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '60px',
            height: '55px',
            marginTop: '5px'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
            height: '55px',
        }
    },
    selectContainer: {
        width: '300px',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
            width: '300px',
            marginLeft: "70px"
        }
    },
    app: {
        [theme.breakpoints.up('md')]: {
            width: '170px !important',
        },
        [theme.breakpoints.down('xs')]: {
            width: '200px',
        }
    },
    invoice: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '55px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '1px',
        }
    },
    exp: {
        [theme.breakpoints.down('md')]: {
            width: '640px',
        },

        [theme.breakpoints.down('xs')]: {
            width: '230px'
        }
    },
    vendor: {
        [theme.breakpoints.up('md')]: {
            minWidth: '280px'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '600px'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '250px'
        }
    },
    type: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '230px'
        }
    },
    Attach: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '230px'
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '280px'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '620px'
        },
    },
    common: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '150px',
            width: "230px"
        },
        // [theme.breakpoints.up('md')]: {
        //   minWidth:'280px'
        // },
        // [theme.breakpoints.down('md')]: {
        //   minWidth:'620px'
        // },
    },
    button: {
        [theme.breakpoints.up('md')]: {
            minWidth: '280px'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '635px'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '80%'
        },
    }
}));



const StoreTrasferOtherProject = ({ projectDetails, isRadioShow, setIsRadioShow, isLoading, setIsLoading, setShowContent,
    getGRNList = () => { } }) => {
    const classes = useStyles();
    const [searchData, setSearchData] = useState([]);
    const [isTableView, setIsTableView] = useState(false);
    const [searchId, setSearchId] = useState('');
    const [storeIndentData, setStoreIndentData] = useState([]);
    const [isEditStore, setIsEditStore] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);


    const customStyles = {
        control: base => ({
            ...base,
            height: 45,
            minHeight: 55
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? 'hsla(#0000ff, 64%, 42%, 0.5)'
                : isSelected
                    ? 'hsla(#0000ff, 64%, 42%, 1)'
                    : undefined,
            zIndex: 1
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    };

    useEffect(() => {
        getRecurringTransfer();
    }, [])

    const getRecurringTransfer = () => {
        setIsLoading(true);
        APIKit.get(RGCA_URLS.getRecurringTransferGrn + '/' + projectDetails.projectID)
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    console.log("lllll", res.data.data);
                    setSearchData(res.data.data);
                } else {
                    setIsLoading(false);
                    setSearchData([]);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };


    const getIndentdata = async () => {
        setIsLoading(true);
        const Id = projectDetails.projectID;
        const getPayLoad = {
            projectID: searchId?.ProjectID,
            storeIndentMasterID: searchId?.value
        }
        APIKit.post(RGCA_URLS.getStoreIndentForGIN, getPayLoad)
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    setIsRadioShow(false);
                    console.log("res.data", res.data.data);
                    if (res?.data?.data?.length > 0) {
                        const temp = JSON.parse(res?.data?.data[0]?.storeIndentItem);
                        setStoreIndentData(temp);
                    } else {
                        setStoreIndentData([]);
                    }
                    setIsTableView(true);
                } else {
                    setIsLoading(false);
                    setStoreIndentData([]);
                    toast.error(res.data.message);
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
    }

    const handleOpen = (item, index) => {
        setCurrentItem({ ...item, index });
        setIsEditStore(true);
    };

    const handleUpdate = () => {
        const updatedItems = [...storeIndentData];
        console.log("currentItem?.index", currentItem?.index);
        updatedItems[currentItem?.index] = {
            ...currentItem,
            index: undefined // Remove the index property before updating the state
        };
        setStoreIndentData(updatedItems);
        // handleClose();
        setIsEditStore(false);
    };

    const addGrnForRecurringTransfer = () => {
        const payload = {
            storeIndentMasterID: searchId?.value,
            FromProjectID: searchId.ProjectID ? Number(searchId.ProjectID) : '',
            ToProjectID: projectDetails.projectID,
            itemID: storeIndentData[0]?.itemID ? Number(storeIndentData[0]?.itemID) : '',
            grnQty: storeIndentData[0]?.Qty ? Number(storeIndentData[0]?.Qty) : '',
            aprovedCount: storeIndentData[0]?.approvedQty ? Number(storeIndentData[0]?.approvedQty) : '',
            rejectedCount: storeIndentData[0]?.rejectedCount ? storeIndentData[0]?.rejectedCount : 0,
            grnStatus: 24,
            statusID: 22,
            comment: storeIndentData[0]?.comment ? storeIndentData[0]?.comment :'',
            isOutSideProject:1
        };
        console.log("payload", payload);
        setIsLoading(true);
        APIKit.post(RGCA_URLS.addGrnForRecurringTransferData, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    setShowContent(true);
                    getGRNList();
                    toast.success(res.data.message);
                } else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
    };

    let regEx = {
        textOnly: /^[a-zA-Z\s]+$/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9.]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/,
        percentage: /^([0-9][0-9]?|100)%?$/,
    };

    return (
        <>

            {isRadioShow &&
                <Grid container spacing={3} >
                    <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center' }} >
                        <Grid item className={classes.selectContainer}>
                            <Selectoption
                                isDisabled={!searchData?.length}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                placeholder="Search PO ID"
                                onChange={(e) => {
                                    console.log("EEE", e);
                                    setSearchId(e)
                                }}
                                value={searchId}
                                options={searchData ? searchData.map(e => {
                                    return {
                                        value: e.storeIndentMasterID,
                                        label: e.storeIndentMasterID,
                                        ProjectID: e.ProjectID
                                    };
                                }) : ""}
                            />
                        </Grid>
                        <Grid style={{ padding: '12px', marginTop: '10px' }}>
                            <Button
                                disabled={!searchData}
                                color="primary"
                                onClick={e => {
                                    getIndentdata();
                                }}
                                variant="contained"
                                className={classes.centertxt}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {isTableView &&
                <Grid container spacing={3} style={{ marginTop: '10px' }}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Item Name</TableCell>
                                        <TableCell align="left">Total Quantity</TableCell>
                                        <TableCell align="left">Aproved Count</TableCell>
                                        <TableCell align="left">Rejected Count</TableCell>
                                        <TableCell align="left">UOM</TableCell>
                                        <TableCell align="left">Location</TableCell>
                                        <TableCell align="left">Description</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {storeIndentData &&
                                        storeIndentData.map((x, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align="left">{x.name}</TableCell>
                                                    <TableCell align="left">{x.Qty} </TableCell>
                                                    <TableCell align="left">{x.approvedQty} </TableCell>
                                                    <TableCell align="left">{x.rejectedCount ?? 0} </TableCell>
                                                    <TableCell align="left">{x.uom}</TableCell>
                                                    <TableCell align="left">{x.location}</TableCell>
                                                    <TableCell align="left">{x.comment}</TableCell>
                                                    <TableCell align="left">
                                                        <EditOutlinedIcon onClick={() => {
                                                            handleOpen(x, index)
                                                        }} style={{ cursor: "pointer" }} />

                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) => {
                                addGrnForRecurringTransfer();
                            }}
                            style={{ marginTop: "20px" }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            }

            {currentItem && (
                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={isEditStore}
                    id="income"
                    contentStyle={{
                        width: '200%',
                        maxWidth: 'none'
                    }}
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => { setIsEditStore(false) }}
                        style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            width: '900px !important'
                        }}
                    >
                        GRN From Other Project
                    </DialogTitle>
                    <DialogContent dividers style={{ width: '900px !important' }}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <div style={{ textAlign: 'center' }}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Material</TableCell>
                                                    <TableCell style={{ width: '8%' }} align="left">
                                                        UOM
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description of usages
                                                    </TableCell>
                                                    <TableCell align="left">Total Quantiy</TableCell>
                                                    <TableCell align="left">Aproved Count</TableCell>
                                                    <TableCell align="left">Rejected Count</TableCell>
                                                    <TableCell align="left">Location</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow hover role="checkbox">
                                                    <TableCell align="left">{currentItem?.name}</TableCell>
                                                    <TableCell align="left">{currentItem?.uom}</TableCell>
                                                    <TableCell align="left">{currentItem?.comment}</TableCell>
                                                    <TableCell align="left">{currentItem?.Qty}</TableCell>
                                                    <TableCell align="left">
                                                        <TextField
                                                            helperText=""
                                                            label="Aproved Count"
                                                            name="aprovedCount"
                                                            required
                                                            onChange={(e) => {
                                                                if (e.target.value === "" || regEx.numbersOnly.test(e.target.value)) {
                                                                    const newValue = currentItem.Qty <= e.target.value ? currentItem.Qty : e.target.value;
                                                                    const newrejectedCount = currentItem.Qty - newValue;
                                                                    setCurrentItem({ ...currentItem, approvedQty: newValue, rejectedCount: newrejectedCount });
                                                                }
                                                            }}
                                                            value={currentItem?.approvedQty}
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <TextField
                                                            helperText=""
                                                            label="Rejected Count"
                                                            name="rejectedCount"
                                                            required
                                                            disabled
                                                            onChange={(e) => {
                                                                if (e.target.value === "" || regEx.numbersOnly.test(e.target.value)) {
                                                                    let temp = currentItem;
                                                                    temp.rejectedCount = currentItem?.Qty - currentItem?.approvedQty;
                                                                    setCurrentItem({ ...temp });
                                                                }
                                                                // const newValue = currentItem.Qty <= e.target.value ? currentItem.Qty : e.target.value;
                                                                // setCurrentItem({ ...currentItem, approvedQty: newValue });
                                                            }}
                                                            value={currentItem?.rejectedCount ?? 0}
                                                            variant="outlined"
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <TextField
                                                            helperText=""
                                                            label="Location"
                                                            name="material"
                                                            disabled
                                                            onChange={(e) => setCurrentItem({ ...currentItem, location: e.target.value })}
                                                            required
                                                            value={currentItem?.location}
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                        <Button onClick={() => { setIsEditStore(false) }}> Cancel</Button>
                        <Button onClick={handleUpdate}>Update</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default StoreTrasferOtherProject