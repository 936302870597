import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import 'date-fns';
import * as Yup from 'yup';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'src/views/loader';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1 } from '../Common/utilities';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }, textarea: {
        resize: "both"
    }, centertext: {
        marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
        '@media (max-width: 500px)': {
            marginLeft: "110px"
        },
        '@media (max-width: 350px)': {
            marginLeft: "60px"
        },
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



const Programs = ({ isLoading, programData, setIsLoading, getProgramsData = () => { } }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isAddProgram, setIsAddProgram] = useState(false)
    const [payload, setPayload] = useState({
        NameoftheProgram: "",
        FromDate: null,
        ToDate: null,
        Location: "",
        Fee: "",
        Description: ""
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const createHandsOnPrograme = () => {
        setIsLoading(true)
        let payloadAdd = {
            NameoftheProgram: payload.NameoftheProgram,
            FromDate: payload.FromDate,
            ToDate: payload.ToDate,
            Location: payload.Location,
            Fee: payload.Fee,
            Description: payload.Description,
        }
        APIKit.post(RGCA_URLS.createHandsOnProgramme, payloadAdd)
            .then(res => {
                if (res.data.status === 200) {
                    setPayload({
                        NameoftheProgram: "",
                        FromDate: null,
                        ToDate: null,
                        Location: "",
                        Fee: "",
                        Description: ""
                    });
                    setIsAddProgram(false);
                    getProgramsData();
                    setIsLoading(false);
                    toast.success(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                } else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) {
                setIsLoading(false);
                console.log('kkk', e);
            });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setIsAddProgram(true)

                        }}
                    >
                        Add Program
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>S.No</TableCell>
                                    <TableCell align='left' style={{ width: '200px' }}>Programe ID</TableCell>
                                    <TableCell align='left'>Program Name</TableCell>
                                    <TableCell align='left' style={{ width: '120px' }}>From Date</TableCell>
                                    <TableCell align='left' style={{ width: '120px' }}>To Date</TableCell>
                                    <TableCell align='left'>Location</TableCell>
                                    <TableCell align='left'>Fee</TableCell>
                                    <TableCell align='left'>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {programData && programData.length != 0 ? (
                                    programData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell align='left'>{index + 1}</TableCell>
                                                <TableCell align='left'>{x?.HandsOnTrainingProgrammeID ? x?.HandsOnTrainingProgrammeID : ''}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.NameoftheProgram}>
                                                        <span>
                                                            {x?.NameoftheProgram ? x?.NameoftheProgram.length > 30 ? `${x?.NameoftheProgram.slice(0, 30)} ...` : x?.NameoftheProgram : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left' style={{ width: '120px' }}>{x?.FromDate ? moment(x?.FromDate).format('DD-MM-yyyy') : '-'}</TableCell>
                                                <TableCell align='left' style={{ width: '120px' }}>{x?.ToDate ? moment(x?.ToDate).format('DD-MM-yyyy') : '-'}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.Location}>
                                                        <span>
                                                            {x?.Location ? x?.Location.length > 20 ? `${x?.Location.slice(0, 20)} ...` : x?.Location : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left'> {x?.Fee ? currencyFormat1(Math.round(x?.Fee)) : ''}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.Description}>
                                                        <span>
                                                            {x?.Description ? x?.Description.length > 30 ? `${x?.Description.slice(0, 30)} ...` : x?.Description : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {programData && programData.length != 0 && programData.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={programData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isAddProgram}
                id="income"
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                }}
                maxWidth="lg"
                fullWidth
            >
                <Loader isLoading={isLoading} />
                <DialogTitle id="scroll-dialog-title" onClose={(e => {
                    setIsAddProgram(false);
                    setPayload({
                        NameoftheProgram: "",
                        FromDate: null,
                        ToDate: null,
                        Location: "",
                        Fee: "",
                        Description: ""
                    })
                })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                    Add Program
                </DialogTitle>

                <Formik
                    initialValues={{
                        NameoftheProgram: "",
                        FromDate: "",
                        ToDate: "",
                        Location: "",
                        Fee: "",
                        Description: "",
                    }}
                    validationSchema={
                        Yup.object().shape({
                            NameoftheProgram: Yup.string().required('Program Name Required'),
                            FromDate: Yup.string().required('From Date Required'),
                            ToDate: Yup.string().required('To Date Required'),
                            Location: Yup.string().required('Location Required'),
                            Fee: Yup.string().required('Fee Required'),
                            Description: Yup.string().required('Description Required'),
                        })
                    }
                    onSubmit={(values, { resetForm }) => { createHandsOnPrograme(); }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, handleReset, isSubmitting, touched, values, setValues, resetForm
                    }) => (
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <DialogContent>
                                <Box mt={3} style={{
                                    backgroundColor: '#fff',
                                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                }}>
                                    <Grid container spacing={3} >
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Program Name"
                                                name="NameoftheProgram"
                                                value={payload.NameoftheProgram}
                                                inputProps={{ maxLength: 20 }}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.NameoftheProgram = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.NameoftheProgram && errors.NameoftheProgram)}
                                                helperText={touched.NameoftheProgram && errors.NameoftheProgram}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="From Date"
                                                format="dd/MM/yyyy"
                                                disableFuture={true}
                                                error={Boolean(touched.FromDate && errors.FromDate)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="FromDate"
                                                required
                                                onChange={(e) => {
                                                    console.log("ooo", e);
                                                    const p = payload;
                                                    p.FromDate = e;
                                                    setPayload({ ...p });
                                                    setValues({ ...values, FromDate: e })
                                                }}
                                                value={payload.FromDate ?? null}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="To Date"
                                                format="dd/MM/yyyy"
                                                disablePast={true}
                                                error={Boolean(touched.ToDate && errors.ToDate)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="ToDate"
                                                required
                                                onChange={(e) => {

                                                    console.log("ooo", e);
                                                    const p = payload;
                                                    p.ToDate = e;
                                                    setPayload({ ...p });
                                                    setValues({ ...values, ToDate: e })
                                                }}
                                                value={payload.ToDate ?? null}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Location"
                                                name="Location"
                                                value={payload.Location}
                                                inputProps={{ maxLength: 20 }}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.Location = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.Location && errors.Location)}
                                                helperText={touched.Location && errors.Location}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Fee"
                                                name="Fee"
                                                value={payload.Fee}
                                                inputProps={{ maxLength: 20 }}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.Fee = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.Fee && errors.Fee)}
                                                helperText={touched.Fee && errors.Fee}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Description"
                                                name="Description"
                                                multiline
                                                value={payload.Description}
                                                inputProps={{ className: classes.textarea, maxLength: 1000 }}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.Description = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.Description && errors.Description)}
                                                helperText={touched.Description && errors.Description}
                                                required
                                                variant="outlined"
                                            />
                                            <small style={{ color: "gray" }}><code
                                                className="me-2">{payload?.Description?.length}/1000</code> characters</small>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                                <Button onClick={(e => {
                                    setIsAddProgram(false)
                                    setPayload({
                                        NameoftheProgram: "",
                                        FromDate: null,
                                        ToDate: null,
                                        Location: "",
                                        Fee: "",
                                        Description: ""
                                    })
                                })} className={classes.button2}>
                                    Cancel
                                </Button>
                                <Button type="submit" className={classes.button2}>Save</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

export default Programs