import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './notice.css'
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import moment from 'moment';
import { getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function NoticeBoard({ noticeData }) {


  // const [noticeData, setNoticeData] = useState([]);
  // const { project } = useParams();
  // const projectDetails = { ...getProjectDetails(project) };

  // const getNotificationsData = async () => {
  //   const res = await APIKit.get(RGCA_URLS.getNotificationsData);
  //   if (res.data.status === 200) {
  //     setNoticeData(res.data.data)
  //   }
  // }

  // const getNotificationsData = async () => {
  //   const res = await APIKit.get(RGCA_URLS.getNotificationsDataBasedonprojectID+ '/' + projectDetails.projectID);
  //   if (res.data.status === 200) {
  //     setNoticeData(res.data.data)
  //   }
  // }

  // useEffect(() => {
  //   getNotificationsData()
  // }, [projectDetails.projectID])

  return (
    <>
      <Accordion defaultExpanded={true}  style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography >
            <b> Notice Board</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        {/* <Card sx={{ minWidth: 500, minHeight: 370 }} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}>
        <Typography sx={{ textAlign: 'center', borderTopRightRadius: "12px", borderTopLeftRadius: "12px", fontSize: 15, backgroundColor: '#394671', padding: '5px', fontWeight: 'bold', color: 'white' }} gutterBottom>
          NOTICE BOARD
        </Typography>
        <CardContent> */}
          <marquee className="marq" direction="up" loop="" >
            <Typography variant="body2">
              News Updation Here
              <br /><br />
            </Typography>
            {noticeData.length > 0 ?
              noticeData.map((data, index) => (
                <Typography variant="body2" key={index} >
                  {data.Notification}
                  <br />
                  <br />
                </Typography>
              )) : ""}
          </marquee>
        {/* </CardContent>
      </Card> */}
        </AccordionDetails>
      </Accordion>
     
    </>

  );
}
