import React, { useState } from 'react';

const getCurrentYear = () => new Date().getFullYear();

const getQuarterDates = (year) => {
  return {
    Q1: { start: `${year}-01-01`, end: `${year}-03-31` },
    Q2: { start: `${year}-04-01`, end: `${year}-06-30` },
    Q3: { start: `${year}-07-01`, end: `${year}-09-30` },
    Q4: { start: `${year}-10-01`, end: `${year}-12-31` },
  };
};

const quarters = [
  { label: 'Q1 (Jan - Mar)', value: 'Q1' },
  { label: 'Q2 (Apr - Jun)', value: 'Q2' },
  { label: 'Q3 (Jul - Sep)', value: 'Q3' },
  { label: 'Q4 (Oct - Dec)', value: 'Q4' },
];

const QuarterSelector = () => {
  const [selectedQuarter, setSelectedQuarter] = useState('Q1');
  const currentYear = getCurrentYear();
  const quarterDates = getQuarterDates(currentYear);

  const handleChange = (event) => {
    setSelectedQuarter(event.target.value);
  };

  return (
    <div>
      <h1>Select a Quarter</h1>
      <select value={selectedQuarter} onChange={handleChange}>
        {quarters.map((quarter) => (
          <option key={quarter.value} value={quarter.value}>
            {quarter.label}
          </option>
        ))}
      </select>
      <div>
        <h2>Data for {selectedQuarter}</h2>
        <p>Start Date: {quarterDates[selectedQuarter].start}</p>
        <p>End Date: {quarterDates[selectedQuarter].end}</p>
      </div>
    </div>
  );
};

export default QuarterSelector;
