
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem, Modal,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { Search as SearchIcon } from 'react-feather';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { useBarcode } from 'react-barcodes';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import PaymentIcon from '@material-ui/icons/Payment'
import { data } from './data';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from "react-router-dom";
import { capitalize, dateFormate, dateOnly, getProjectDetails } from '../Common/utilities';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { parseData, dateOnlyRev } from '../Common/utilities';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import { MESSAGE } from 'src/utils/message';
import CommonTable from '../Common/CommonTable';
import { ETTypes, ETaction } from '../Common/Types';
import './style.css';
import { useConfirm } from 'material-ui-confirm';
//MUI TOGGLE
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%',
    // marginTop:"125px"
  },
  button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  },
  button2: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
    },
  }
}));


const ReturnGoods = () => {
  const classes = useStyles();
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0); // first tab
  const [page, setPage] = React.useState(0);
  const [grnList, setGrnList] = useState(data);
  const [arrayTableData, setArrayTableData] = useState([])
  const [showContent, setShowContent] = useState(true)
  const [grnInvoiceID, setGrnInvoiceID] = useState(
    {
      "inVoiceID": '',
    }
  )
  const [manuallyAddGRN, setManuallyAddGRN] = useState({
    "vendorID": '',
    "invoiceID": '',
    "invoiceAttach": '',
    "invoiceDate": ''
  })
  const [openincPrint, setOpenincPrint] = React.useState(false);
  const [addManually, setAddManually] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createView, setCreateView] = React.useState(false);
  const [moreView, setMoreView] = React.useState(false);
  const [returnView, setReturnView] = React.useState(false);
  const [singleReturnView, setSingleReturnVIew] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [paymentCompleted, setPaymentPaymentCompleted] = React.useState(false);
  const [POgrn, setPOgrn] = React.useState([]);
  const confirm = useConfirm();
  const [POaddress, setPOaddress] = React.useState([]);
  const [POBank, setPOBank] = React.useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataInvoiceID, setTotalDataInvoiceID] = React.useState({});
  const [allGRN, setAllGRN] = React.useState([]);
  const [singleReturn, setSingleReturn] = React.useState({});
  const [allReturn, setAllReturn] = React.useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const [GRNView, setGRNView] = React.useState([]);
  const [GRNItem, setGRNItem] = React.useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [GSTTDS, setGSTTDS] = React.useState("NO");
  const [edit, setEdit] = React.useState(false);
  const [catOrRec, setCatOrRec] = useState([])
  const [indexData, setIndexData] = useState(null)
  const [TAXTDS, setTAXTDS] = React.useState("NO");
  const [lookupData, setLookupData] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [poGST, setPOGSt] = useState({});
  const [returnGoods, setReturnGoods] = useState({});
  const [scroll, setScroll] = useState('paper');
  const [show, setShow] = useState(true)
  const [objAddData, setObjAddData] = useState({
    "val": {},
    "materialList": [],
    "typeID": "",
    "itemID": "",
    "itemName": "",
    "dateofExpiry": "",
    "itemAttach": "",
    "grnQty": "",
    "isGST": "",
    "gstType": "",
    "unitPrice": "",
    "totalPrice": "",
    "comment": ""
  })


  const navigate = useNavigate();

  const [payload, setPayload] = useState(
    {
      "paymentMethod": '',
      "projectID": null,
      "vendorID": '',
      "reference": '',
      "value": '',
      "isGstTds": '',
      "gstTdsRate": '',
      "gstTaxExemption": '',
      "isItTaxTds": '',
      "itTdsRate": '',
      "itTaxExemption": '',
      "purposeAdvance": "",
      "balancePayable": ''
    }
  )

  const [GRN, setGRN] = useState(
    {
      "POID": '',
      "gst": ''
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    a11yProps(0)
    getGRNList()
    getVendor()
    getReturnGoods()
    getPaymentMethod()
    getLookup()
  }, [navigate])

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }

  const a11yProps = (index) => {
    if (index == 0) {
      setCreateView(false)
    }
    if (index == 1) {
      getGRNList()
    }
    if (index == 2) {
      getReturnGoods()
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleAddManually = () => {
    setAddManually(!addManually)
  }

  const POApprovalView = [
    {
      title: "GRN Item ID",
      field: "grnItemID",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Material Name",
      field: "name",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Category",
      field: "categoryName",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Item Attach",
      field: "itemAttach",
      align: 'left',
      type: ETTypes.link,
    },
    {
      title: "Quantity",
      field: "grnQty",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Unit Price",
      field: "unitPrice",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Total Price",
      field: "totalPrice",
      align: 'left',
      type: ETTypes.numeric,
    }
  ];

  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then((res) => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };

  const getPaymentMethod = () => {
    APIKit.get(`${RGCA_URLS.lookup}/55`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };

  const [inter, setInter] = useState(false)
  const [intra, setIntra] = useState(false)
  const [union, setUnion] = useState(false)
  const [gstState, setGstState] = useState('')
  const handleState = (e) => {
    if (e.target.value === 'Interstate') {
      setInter(true)
      setIntra(false)
      setUnion(false)
      setGstState(e.target.value)
    } else if (e.target.value === 'Intrastate') {
      setIntra(true)
      setInter(false)
      setUnion(false)
      setGstState(e.target.value)
    } else if (e.target.value === 'Union') {
      setUnion(true)
      setInter(false)
      setIntra(false)
      setGstState(e.target.value)
    }
  }

  const [columns, setColumns] = useState([
    {
      title: "Item Name",
      field: "itemName",
      align: 'left',
      type: ETTypes.string,
    },
    {
      title: "Item Attach",
      field: "itemAttach",
      align: 'left',
      type: ETTypes.link,
    },
    {
      title: "GRN Quantity",
      field: "grnQty",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Unit Price",
      field: "unitPrice",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Total Price",
      field: "totalPrice",
      align: 'left',
      type: ETTypes.numeric,
    },
    {
      title: "Date of Expiry",
      field: "dateofExpiry",
      align: 'left',
      type: ETTypes.date,
    },
    {
      title: "Action",
      field: "action",
      align: 'left',
      list: [ETaction.onEdit, ETaction.onDelete]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }

  ])

  const handleReturns = (data) => {
    setSingleReturnVIew(true)
    setSingleReturn(data)
  }

  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const searchPOID = () => {
    const payload = {
      purchaseOrderMasterID: GRN.POID
    }
    payload.projectID = projectDetails.projectID;
    APIKit.post(RGCA_URLS.getPurchaseOrderForGRN, payload)
      .then((res) => {
        if (res?.data?.data?.length) {
          let dataPurchaseItem = []
          let dataaddress = []
          let databankDetails = []
          let data = res.data.data.map((x, i) => {
            dataPurchaseItem = parseData(x.purchaseOrderItem)
            dataPurchaseItem = dataPurchaseItem.map(element => {
              return {
                ...element,
                totalRate: element.IGST + element.CGST + element.SGST + element.UGST,
                oQty: element.qty,
                qty: element.qty,
                dateofExpiry: ''
              }
            });
            dataaddress = parseData(x.address)
            databankDetails = parseData(x.bankDetails)
            return {
              ...x,
              address: parseData(x.address),
              bankDetails: parseData(x.bankDetails),
              purchaseOrderItem: parseData(x.purchaseOrderItem),
              inVoiceDate: ''
            }
          })
          setTotalData(data)
          setPOaddress(dataaddress)
          setPOBank(databankDetails)
          setPOgrn(dataPurchaseItem)
          setCreateView(true)

        } else {
          toast.error(MESSAGE.poiDmatch, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
      });
  }

  var num = "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" ");
  var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

  const getGRNList = () => {
    APIKit.get(RGCA_URLS.getGRN + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails),
            }
          })
          setAllGRN(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };

  const getReturnGoods = () => {
    APIKit.get(RGCA_URLS.getReturnGoods + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              returnItem: parseData(x.returnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails),
            }
          })
          setAllReturn(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };


  return (
    <Page className={classes.root} title="GRN" >
      <>
        <Typography><h2 style={{ color: '#394671' }}>Return Goods</h2></Typography>
        <Grid container spacing={3}  >
          <Grid item lg={12} sm={12} xl={12} xs={12}  >
            <div style={{ textAlign: "center" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" >Return Master ID</TableCell>
                      <TableCell align="center">Indent ID</TableCell>
                      <TableCell align="center">Invoice ID</TableCell>
                      <TableCell align="center">PO ID</TableCell>
                      <TableCell align="center">Total Return Quantity</TableCell>
                      <TableCell align="center">Amount for Returned Quantity</TableCell>
                      <TableCell align="center">Created By</TableCell>
                      <TableCell align="center">Created Date</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allReturn && allReturn.length != 0 ?
                      (allReturn.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x, i) => {
                        return (
                          <TableRow hover role="checkbox" >
                            <TableCell align="center">{x.returnMaster}</TableCell>
                            <TableCell align="center">{x.indentMasterID}</TableCell>
                            <TableCell align="center">{x.invoiceID}</TableCell>
                            <TableCell align="center">{x.purchaseOrderMasterID}</TableCell>
                            <TableCell align="center">{x.returnItem?.reduce(function (a, b) { return b.returnQty + a }, 0)}</TableCell>
                            <TableCell align="center">{x.returnItem?.reduce(function (a, b) { return b.returnQty * b.unitPrice + a }, 0).toLocaleString('en-IN')}</TableCell>
                            <TableCell align="center">{x.CreatedByName}</TableCell>
                            <TableCell align="center">{dateFormate(x.createdDate)}</TableCell>
                            <TableCell align="center">
                              <VisibilityIcon style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => {
                                handleReturns(x)
                              }} />
                            </TableCell>
                          </TableRow>
                        );
                      })
                      ) : (
                        <TableRow hover role="checkbox" key={1}>
                          <TableCell colSpan={10} align="center" key={2}>
                            {'No Data Found'}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              {allReturn && allReturn.length > 10 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={allReturn.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              }
            </div>
          </Grid>
        </Grid>
      </>

      <Dialog aria-labelledby="customized-dialog-title"
        contentStyle={{ width: '100%', maxWidth: 'none', }}
        maxWidth="lg" fullWidth
        open={singleReturnView} id="income">
        <DialogTitle id="customized-dialog-title" onClose={() => setSingleReturnVIew(false)}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        >
          Return Goods
        </DialogTitle>

        <DialogContent dividers={scroll === 'paper'} style={{ overflowX: "hidden" }}>
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px',
            overflowWrap: 'break-word' 
          }}>
            <Grid container spacing={5}  >
              {singleReturn?.returnItem?.map((it, i) => (
                <>
                  <Grid item xs={4} lg={3} sm={4} xl={4}  >
                    <div style={{ textAlign: "center" }}>
                      <Typography><h5 style={{ color: '#394671' }}>Material Name : {it.name}</h5></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} lg={2} sm={2} xl={2}  >
                    <div style={{ textAlign: "center" }}>
                      <Typography><h5 style={{ color: '#394671', whiteSpace: "nowrap" }}>Category Name : {it.categoryName}</h5></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} lg={2} sm={2} xl={2}  >
                    <div style={{ textAlign: "center", }}>
                      <Typography><h5 style={{ color: '#394671', whiteSpace: "nowrap" }}>Received Quantity : {it.grnQty} </h5></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} lg={2} sm={2} xl={2}  >
                    <div style={{ textAlign: "center", }}>
                      <Typography><h5 style={{ color: '#394671', whiteSpace: "nowrap" }}>Returned Quantity : {it.returnQty} </h5></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} lg={3} sm={2} xl={2}  >
                    <div style={{ textAlign: "center" }}>
                      <Typography><h5 style={{ color: '#394671' }}>Return Purpose : {it.returnPurpose} </h5></Typography>
                    </div>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button
            color="primary"
            className={classes.button2}
            variant="contained"
            onClick={() => setSingleReturnVIew(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
    </Page >
  )
}
export default ReturnGoods;
