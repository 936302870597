import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Container,
    Button,
    CardContent,
    InputAdornment,
    TextField,
    SvgIcon,
    Select,
    InputLabel,
    FormControl,
    MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
// import data from './data';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

const useStyles1 = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px', marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    }
}));


const TextForm = () => {
    const classes = useStyles();
    const [depositors, setDepositors] = useState([])
    // const [depositors] = useState(data);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = React.useState(false);

    const [age, setAge] = React.useState('');

    const [emptype, setEmptype] = React.useState('');
    const [isActive, setIsActive] = React.useState('');
    const [updatedBy, setUpdatedBy] = React.useState('');
    const [designation, setDesignation] = React.useState('');
    const [department, setDepartment] = React.useState('');


    const [lastname, setLastname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobilenumber, setMobilenumber] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [createdBy, setCreatedBy] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [updatedby, setUpdatedby] = React.useState('');

    const top100Films = [
        { title: 'Contn.of 300MT3nos.of Seawater Reservoir', year: 1994 },
        { title: 'Contn.of Office & lab', year: 1972 },
        { title: 'BH Tank/covered shed,civil const.', year: 1974 },
        { title: 'Civil construction of New Resrvoir Tank', year: 2008 },
        { title: 'Electrical Fittgs. (Incl. UG Cable Etc)', year: 1957 },
        { title: "Formation of Road", year: 1993 },
        { title: 'Roofing for reservoirs', year: 1994 },
        { title: 'Circular Brooders Tank', year: 2003 },
        { title: 'Vehicle Shed', year: 1966 },
        { title: 'Rectangular Algal Tanks', year: 1999 },
        { title: '25 Ton Round BH Tank', year: 2001 },
        { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
        { title: 'Forrest Gump', year: 1994 },
        { title: 'Inception', year: 2010 },
        { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
        { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
        { title: 'Goodfellas', year: 1990 },
        { title: 'The Matrix', year: 1999 },
        { title: 'Seven Samurai', year: 1954 },
        { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
        { title: 'City of God', year: 2002 },
        { title: 'Se7en', year: 1995 },
        { title: 'The Silence of the Lambs', year: 1991 },
        { title: "It's a Wonderful Life", year: 1946 },
        { title: 'Life Is Beautiful', year: 1997 },
        { title: 'The Usual Suspects', year: 1995 },
        { title: 'Léon: The Professional', year: 1994 },
        { title: 'Spirited Away', year: 2001 },
        { title: 'Saving Private Ryan', year: 1998 },
        { title: 'Once Upon a Time in the West', year: 1968 },
        { title: 'American History X', year: 1998 },
        { title: 'Interstellar', year: 2014 },
        { title: 'Casablanca', year: 1942 },
        { title: 'City Lights', year: 1931 },
        { title: 'Psycho', year: 1960 },
        { title: 'The Green Mile', year: 1999 },
        { title: 'The Intouchables', year: 2011 },
        { title: 'Modern Times', year: 1936 },
        { title: 'Raiders of the Lost Ark', year: 1981 },
        { title: 'Rear Window', year: 1954 },
        { title: 'The Pianist', year: 2002 },
        { title: 'The Departed', year: 2006 },
        { title: 'Terminator 2: Judgment Day', year: 1991 },
        { title: 'Back to the Future', year: 1985 },
        { title: 'Whiplash', year: 2014 },
        { title: 'Gladiator', year: 2000 },
        { title: 'Memento', year: 2000 },
        { title: 'The Prestige', year: 2006 },
        { title: 'The Lion King', year: 1994 },
        { title: 'Apocalypse Now', year: 1979 },
        { title: 'Alien', year: 1979 },
        { title: 'Sunset Boulevard', year: 1950 },
        { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
        { title: 'The Great Dictator', year: 1940 },
        { title: 'Cinema Paradiso', year: 1988 },
        { title: 'The Lives of Others', year: 2006 },
        { title: 'Grave of the Fireflies', year: 1988 },
        { title: 'Paths of Glory', year: 1957 },
        { title: 'Django Unchained', year: 2012 },
        { title: 'The Shining', year: 1980 },
        { title: 'WALL·E', year: 2008 },
        { title: 'American Beauty', year: 1999 },
        { title: 'The Dark Knight Rises', year: 2012 },
        { title: 'Princess Mononoke', year: 1997 },
        { title: 'Aliens', year: 1986 },
        { title: 'Oldboy', year: 2003 },
        { title: 'Once Upon a Time in America', year: 1984 },
        { title: 'Witness for the Prosecution', year: 1957 },
        { title: 'Das Boot', year: 1981 },
        { title: 'Citizen Kane', year: 1941 },
        { title: 'North by Northwest', year: 1959 },
        { title: 'Vertigo', year: 1958 },
        { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
        { title: 'Reservoir Dogs', year: 1992 },
        { title: 'Braveheart', year: 1995 },
        { title: 'M', year: 1931 },
        { title: 'Requiem for a Dream', year: 2000 },
        { title: 'Amélie', year: 2001 },
        { title: 'A Clockwork Orange', year: 1971 },
        { title: 'Like Stars on Earth', year: 2007 },
        { title: 'Taxi Driver', year: 1976 },
        { title: 'Lawrence of Arabia', year: 1962 },
        { title: 'Double Indemnity', year: 1944 },
        { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
        { title: 'Amadeus', year: 1984 },
        { title: 'To Kill a Mockingbird', year: 1962 },
        { title: 'Toy Story 3', year: 2010 },
        { title: 'Logan', year: 2017 },
        { title: 'Full Metal Jacket', year: 1987 },
        { title: 'Dangal', year: 2016 },
        { title: 'The Sting', year: 1973 },
        { title: '2001: A Space Odyssey', year: 1968 },
        { title: "Singin' in the Rain", year: 1952 },
        { title: 'Toy Story', year: 1995 },
        { title: 'Bicycle Thieves', year: 1948 },
        { title: 'The Kid', year: 1921 },
        { title: 'Inglourious Basterds', year: 2009 },
        { title: 'Snatch', year: 2000 },
        { title: '3 Idiots', year: 2009 },
        { title: 'Monty Python and the Holy Grail', year: 1975 },
    ];

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleChangeDesignation = (event) => {
        setDesignation(event.target.value);
    };

    const { state } = useLocation();

    const navigate = useNavigate();

    //   useEffect(() => {
    //     setDepositors(data);
    //   }, depositors)

    useEffect(() => {
        if (state) {
            setEmptype("permanent");
            setIsActive(state.isactive);
            setDepartment(state.department);
            setDesignation(state.designation);
            setUpdatedby('admin');
            setAge(state.age)

        }

    });

    const [DOB, setDOB] = React.useState(new Date('2014-08-18T21:11:54'));

    const [DOJ, setDOJ] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = (date) => {
        setDOB(date);
    };

    const handleDateChangeDOB = (date) => {
        setDOJ(datePickerDefaultProps)
    }

    const handleSelectAll = () => {

    }

    const handleSelectOne = () => {

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const Toolbar = ({ className, ...rest }) => {
        const classes = useStyles1();

        const [firstname, setFirstname] = React.useState('');

        return (
            <div
                className={clsx(classes.root, className)}
                {...rest}
            >
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >

                    {/* <Button
                        color="primary"
                        variant="contained"
                    >
                        Add
              </Button> */}
                </Box>
                <Box mt={3} style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                    borderRadius: '5px',
                    padding: '20px'
                }}>
                    <Grid
                        container
                        spacing={3}
                    >

                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            {/* <TextField
                fullWidth
                helperText=""
                label="Type"
                name="firstName"
                onChange={(e)=>{
                  setFirstname(e.target.value)
                }}
                required
                value={firstname}
                variant="outlined"
              /> */}
                            <Autocomplete
                                id="combo-box-demo"
                                options={top100Films}
                                getOptionLabel={(option) => option.title}
                                style={{ width: 380 }}
                                renderInput={(params) => <TextField {...params} label="Type" variant="outlined" />}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Amount"
                                name="firstName"
                                onChange={(e) => { }}
                                required
                                value={lastname}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="InvoiceId"
                                name="age"
                                onChange=""
                                required
                                value={age}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Purchase OrderId"
                                name="firstName"
                                onChange=""
                                required
                                value=""
                                variant="outlined"
                            />
                        </Grid>



                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="Income Tax"
                                name="firstName"
                                onChange=""
                                required
                                value={email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="SGST"
                                name="firstName"
                                onChange=""
                                required
                                value={mobilenumber}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="CGST"
                                name="firstName"
                                onChange=""
                                required
                                value={photo}
                                variant="outlined"
                            />
                        </Grid>


                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText=""
                                label="IGST"
                                name="firstName"
                                onChange=""
                                required
                                value=""
                                variant="outlined"
                            />
                        </Grid>


                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                            >

                                <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    Save
              </Button>
                            </Box>
                        </Grid>

                    </Grid>




                </Box>
            </div>
        );
    };

    return (
        <Page
            className={classes.root}
            title="Depositors">
            <Container maxWidth={false}>
                <Toolbar />
                <Box mt={3}>

                </Box>

                {/* dialog */}




            </Container>
        </Page>
    )

}
export default TextForm;