import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { APP_URLS } from '../../utils/apis';
import { RGCA_URLS } from '../../utils/url'
import { setClientToken } from '../../utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from '../../utils/message';
import APIKit from 'src/utils/APIKit';
import Loader from 'src/views/loader';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  select:{
    [theme.breakpoints.down('xl')]: {
      width: '320px'
    },
    [theme.breakpoints.down('md')]: {
      width: '320px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '250px'
    },
  },
  buton:{
    [theme.breakpoints.down('md')]: {
      // marginRight: '132px',
      marginLeft: '190px',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '132px',
      marginLeft: '43px',
    },
  }
}));

const UserProfile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setGetData] = useState({});
  const [local, setLocal] = useState({});
  const [getUserProject, setGetUserProject] = useState([]);
  const [lookupData, setLookupData] = useState([]);

  let userData = JSON.parse(localStorage.getItem('userData'));


  const [payload, setPayload] = useState({
    newPassword: "",
    confirmPassword: ''

  });
  useEffect(() => {
    getLookup()
    getUserDetails();
    getLocal()
  }, []);
  const getLocal = () => {
    setLocal({ ...JSON.parse(localStorage.getItem('userData')), password: '' })
    setGetUserProject(JSON.parse(localStorage.getItem('projectList')))
    console.log({ ...JSON.parse(localStorage.getItem('userData')), password: null });
    console.log(JSON.parse(localStorage.getItem('projectList')));
  }
  const getUserDetails = () => {
    
    setIsLoading(true);
    APIKit.get(`${RGCA_URLS.getSingleEmployee}/` + JSON.parse(localStorage.getItem('userData')).employeeID)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGetData({ ...res.data.data[0], password: '' })
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.-\s]*$/
  };
  const updateProfile = () => {
    
    const payload = {
      "employeeID": getData.employeeID,
      "firstName": getData.firstName,
      "lastName": getData.lastName,
      "fatherName": getData.fatherName,
      "aadharNumber": getData.aadharNumber,
      "aadharAttchement": getData.aadharAttchement,
      "dateOfBirth": getData.dateOfBirth,
      "dateOfJoining": getData.dateOfJoining,
      "statusOfPhysicallyChallenged": getData.statusOfPhysicallyChallenged,
      "statusOfPhysicallyChallengedNote": getData.statusOfPhysicallyChallengedNote,
      "gender": getData.gender,
      "email": getData.email,
      "employeeCode": getData.employeeCode,
      "phone": getData.phone,
      "appointmentLetter": getData.appointmentLetter,
      "designationID": local.designationID,
      "roleID": local.roleID,
      "statusComment": getData.statusComment,
      "employeeCode": getData.employeeCode,
      "biometricImprints": getData.biometricImprints,
      "projects": getUserProject.map(function (e) {
        // return e.projectID
        return  {
          "projectID": e.projectID,
          "accessRoleID": e.accessRoleID
      }
      })
    };
    if (payload.phone === "" || payload.phone < 1000000000) {
    } else {
      setIsLoading(true);
      APIKit.put(RGCA_URLS.updateEmployee, payload)
        .then((res) => {
          if (res.data.status === 200) {
            console.error("hii")
            toast.success(MESSAGE.employeeUpdated, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            getUserDetails()
            setIsLoading(false);
            console.error(getData)
          } else {
            setIsLoading(false);
            toast.error(MESSAGE.employeeUpdatedNOT, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/10`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };

  return (
    <Page
      className={classes.root}
      title="My Profile"
    >
      <Loader isLoading={isLoading} />
      <ToastContainer />

      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"

        >
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h4"
              style={{ textAlign: "center" ,}}
            >
              My Profile
            </Typography>

          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...getData
            }}
            validationSchema={
              Yup.object().shape({
                // firstName: Yup.string().required('First Name is required'),
                // lastName: Yup.string().required(MESSAGE.lastName),
                fatherName: Yup.string().optional('Father Name is required'),
                // aadharNumber: Yup.string().min(12, 'Must be 12 characters').required(MESSAGE.aadhar),
                // gender: Yup.string().required(MESSAGE.gender),
                // statusOfPhysicallyChallengedNote: Yup.string().required(MESSAGE.statusOfPhysicallyChallengedNote),
                // email: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email),
                // password: Yup.string().required(MESSAGE.password),
                // phone: Yup.number().min(10, 'Must be 10 characters').max(10).required(MESSAGE.mobile),
                // appointmentLetter: Yup.string().required('Mobile Number is required'),
              })
            }

          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>

                <Grid container spacing={3}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      disabled
                      helperText={touched.firstName && errors.firstName}
                      label="First Name"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        handleChange(e)
                        let p = getData;
                        p.firstName = e.target.value
                        values.firstName = e.target.value
                        setGetData({
                          ...getData,
                          firstName: e.target.value,

                        })
                      }}
                      type="text"
                      value={JSON.parse(localStorage.getItem('userData')).firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      disabled
                      helperText={touched.lastName && errors.lastName}
                      label="Last Name"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        let p = getData;
                        p.lastName = e.target.value
                        values.lastName = e.target.value
                        setGetData({
                          ...getData,
                          lastName: e.target.value,

                        })
                      }}
                      type="text"
                      value={JSON.parse(localStorage.getItem('userData')).lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                    inputProps={{ maxLength: 20 }}
                      error={Boolean(touched.fatherName && errors.fatherName)}
                      fullWidth
                      // disabled
                      // {getData.fatherName == ""}
                      InputLabelProps={{ shrink: true }}
                      helperText={touched.fatherName && errors.fatherName}
                      label="Father Name"
                      margin="normal"
                      name="fatherName"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        let p = getData;
                        p.fatherName = e.target.value
                        values.fatherName = e.target.value
                        setGetData({
                          ...getData,
                          fatherName: e.target.value,

                        })
                      }}
                      type="text"
                      // value={JSON.parse(localStorage.getItem('userData')).fatherName}
                      value ={getData.fatherName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      value={getData.phone}
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      type="text"
                      label="Mobile Number"
                      // inputProps={{ maxLength: 10 }}
                      name="phone"
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                          const p = getData;
                          p.phone = e.target.value;
                          values.phone = e.target.value;
                          setGetData({
                            ...p,
                            phone: e.target.value,

                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="email"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        let p = getData;
                        p.email = e.target.value
                        values.email = e.target.value
                        setGetData({
                          ...getData,
                          email: e.target.value,

                        })
                      }}
                      type="text"
                      value={JSON.parse(localStorage.getItem('userData')).email}
                      disabled
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled
                      value={JSON.parse(localStorage.getItem('userData')).employeeCode}
                      onBlur={handleBlur}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      label="Employee Code"
                      name="employeeCode"
                      onChange={(e) => {
                        handleChange(e);
                        const p = getData;
                        p.employeeCode = e.target.value;
                        values.employeeCode = e.target.value;
                        setGetData({
                          ...p,
                          employeeCode: e.target.value
                        });
                      }}
                      variant="outlined"

                    />
                  </Grid> */}
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.select}
                  >
                    <FormControl
                      error={Boolean(touched.gender && errors.gender)}
                      variant="outlined"
                      style={{ width: '100%' }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label" required>Gender</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="gender"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const p = getData;
                          p.gender = e.target.value;
                          values.gender = e.target.value;
                          setGetData({
                            ...p,
                            gender: e.target.value
                          });
                        }}
                        required
                        // disabled
                        value={getData?.gender == 11 ? 11 : 12}
                        label="Gender"
                      >
                        {lookupData && lookupData.map((x) => {
                          return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText>{(errors.gender && touched.gender) && errors.gender}</FormHelperText>
                    </FormControl>

                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Aadhar Number"
                      name="aadharNumber"
                      error={Boolean(touched.aadharNumber && errors.aadharNumber)}
                      helperText={touched.aadharNumber && errors.aadharNumber}
                      // value={JSON.parse(localStorage.getItem('userData')).aadharNumber}
                      value ={getData.aadharNumber}
                      
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        maxLength: 12
                      }}
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          handleChange(e)
                          const p = getData;
                          p.aadharNumber = e.target.value;
                          values.aadharNumber = e.target.value;
                          setGetData({
                            ...p,
                            aadharNumber: e.target.value
                          });
                        }
                      }}
                      required
                      // disabled
                      variant="outlined"
                    />

                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Button
                    color="primary"
                     disabled={userData?.isAdmin == 1}
                        className={classes.buton}
                    // size="large"
                    type="submit"
                    variant="contained"
                    // style={{
                    //   "position": "absolute",
                    //   "right": "25%"
                    // }} 
                    onClick={() => { updateProfile() }}
                  >
                    Update Profile
                  </Button>
                  &nbsp;

                </Box>


              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </Page>
  );
};

export default UserProfile;
