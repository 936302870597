import React, { useState } from 'react'
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';
import {
    Box, Typography, makeStyles, Card, CardContent, Container, Button,
    TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel,
    MenuItem, FormHelperText, TablePagination, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Address from '../Common/Address';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { toast } from 'react-toastify';
import Loader from 'src/views/loader';
import ClearIcon from '@mui/icons-material/Clear';
import MultiSelectforSamples from './multiselect';
import MuiDialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));


const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const LabTestServiceForm = ({ setIsLabTestServ }) => {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                }}
                onSubmit={(values, { resetForm }) => {

                }}
                validationSchema={Yup.object().shape({

                })}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isInitialValid = false,
                    isValid,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <DialogContent dividers style={{ width: '900px !important' }}>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xl={6} xs={6} >
                                    <TextField
                                        label="Service Name"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} >
                                    <TextField
                                        label="Package"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={4} sm={6} xl={6} xs={6} >
                                    <TextField
                                        label="Amount"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={12} sm={6} xl={6} xs={6} >
                                    <TextField
                                        label="Description"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                            <Button onClick={(e => { setIsLabTestServ(false) })}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default LabTestServiceForm;