import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { APP_URLS } from '../../utils/apis';
import { RGCA_URLS } from '../../utils/url'
import { setClientToken } from '../../utils/APIKit';
import { useToasts } from 'react-toast-notifications';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from '../../utils/message';
import APIKit from 'src/utils/APIKit';
import Loader from 'src/views/loader';
import { VisibilityOff, Visibility } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button:{
    textAlign:"center",
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      marginLeft:"260px"

    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft:"260px"

    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginLeft:"10px"
    },
  }
}));

const ChangePasswordfromProfile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    password: "",
    newPassword: ""
  });
  //show & hide password
  const [showcurrentPassword, setShowcurrentpassword] = useState(false)
  const [showconfirmPassword, setShowconfirmpassword] = useState(false)
  const [shownewPassword, setshownewPassword] = useState(false)
  const showCurrent = () => {
    setShowcurrentpassword(!showcurrentPassword)
  }
  const showConfirm = () => {
    setShowconfirmpassword(!showconfirmPassword)
  }
  const showNew = () => {
    setshownewPassword(!shownewPassword)
  }

  return (
    <Page
      className={classes.root}
      title="Change Password"
    >
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"

      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: "",
              currentPassword: "",
              newPassword: ""
            }}
            validationSchema={
              Yup.object().shape({
                password: Yup.string().required(MESSAGE.currentPassword),
                currentPassword: Yup.string()
                  .required(MESSAGE.newPasswor)
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    'Password is invalid'
                  ),
                newPassword: Yup.string()
                  .oneOf([Yup.ref('currentPassword'), null], 'Passwords must match')
                  .required(MESSAGE.connewPasswor)
              })
            }
            onSubmit={() => {
              setIsLoading(true)
              let userData = JSON.parse(localStorage.getItem('userData'))
              let permission = JSON.parse(localStorage.getItem('projectList'))
              console.error(payload)
              APIKit.post(RGCA_URLS.changePasswordFromProfile, {
                ...payload,
                employeeCode: userData.employeeCode
              })
                .then((res) => {
                  if (res.data.status === 200) {
                    setIsLoading(false)
               
                    if (userData.accessRoleID == 11 || userData.accessRoleID == 1) {
                      navigate('/app/maindashboard');
                      addToast(MESSAGE.passwordChanged, { appearance: 'success' });
                      
                    } else {
                      navigate('/app/dashboard/' + permission[0]?.projectName);
                      addToast(MESSAGE.passwordChanged, { appearance: 'success' });
                    }
                  } else {
                    setIsLoading(false)
                    toast.error(res.data.error, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    })
                  }
                })
                .catch((e) => {
                  setIsLoading(false)
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: "center" }}
                  >
                    Change Password
                  </Typography>

                </Box>
                <br />

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Current Password"
                  margin="normal"
                  name="password"

                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    let p = payload;
                    p.password = e.target.value
                    values.password = e.target.value
                    setPayload({
                      ...payload,
                      password: e.target.value,

                    })
                  }}
                  type={showcurrentPassword ? "text" : "password"}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={showCurrent}>
                          {showcurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                />
                <TextField
                  error={Boolean(touched.currentPassword && errors.currentPassword)}
                  fullWidth
                  helperText={touched.currentPassword && errors.currentPassword}
                  label="New Password"
                  margin="normal"
                  name="currentPassword"

                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    values.currentPassword = e.target.value
                  }}
                  type={shownewPassword ? "text" : "password"}
                  value={values.currentPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={showNew}>
                          {shownewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                />
                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="Confirm Password"
                  margin="normal"
                  name="newPassword"

                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    values.newPassword = e.target.value
                    setPayload({
                      ...payload,
                      newPassword: e.target.value,

                    })
                  }}
                  type={showconfirmPassword ? "text" : "password"}
                  value={values.newPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={showConfirm}>
                          {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onInput = {(e) =>{
                    e.target.value = (0, (e.target.value) ).toString().slice(0,12)
                }}
                />
                <Typography component='span' variant='caption' style={{ fontWeight: 'bold' }}>Note:</Typography>
                <Typography variant='caption' component='span'> Password must contains at least 1 letter, 1 Number, 1 Uppercase and 1 symbol. Minimum length is 8 characters.</Typography>
                <Box mt={2}>
                  <Button
                    color="primary"
                    //  disabled={isSubmitting}

                    // size="medium"
                    type="submit"
                    variant="contained"
                    // style={{
                    //   "position": "absolute",
                    //   // "right": "25%"
                    // }}
                    className={classes.button}
                    align="center"
                  >
                    Submit
                  </Button>
                  &nbsp;

                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ChangePasswordfromProfile;
