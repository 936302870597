import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination, TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
import moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNavigationData } from '../../Redux/Common/action';
import { getNavData } from '../../Redux/Nav/action';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Indent from '../indent';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }

}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const intialData = {
  CategoryID: null,
  CreatedBy: null,
  CreatedDate: "",
  DeliveryCompletedDate: null,
  Description: "",
  IndentID: null,
  IndentItems: [],
  IndentStatus: null,
  IsActive: false,
  Note: "",
  ProjectID: null,
  RaisedFrom: null,
  UpdatedBy: null,
  UpdatedDate: "",
  CommentHistory: [],

}

const status_data = [
  {
    Name: "NONE",
    ID: 0
  },
  {
    Name: "OPEN",
    ID: 4118
  },
  {
    Name: "HOLD",
    ID: 21524
  },
  {
    Name: "APPROVE",
    ID: 4117
  },
  {
    Name: "REJECT",
    ID: 4119
  }

]

const IndentItems = (props) => {
  const classes = useStyles();
  const navigationData = useSelector(x => x.NavigationData.navigationData);
  const navigate = useNavigate();
  const [indentItmes, setIndentItems] = useState([]);
  const [resData, setResData] = useState({});
  const [itemCostData, setItemCostData] = useState([]);
  const [statuAction, setSatusAction] = useState(status_data);
  const [indentStatus, setIndentStatus] = useState(0)
  const [indent, setIndent] = useState(intialData)
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [openLoader, setOpenLoader] = React.useState(false);
  const [openCreateIndent, setCreateIndents] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getDropDownList()
    DefaultLoad()
  }, [])

  const DefaultLoad = () => {
    getIndentItems();
    getIndentAllData()
  }
  const getIndentAllData = () => {
    try {

      APIKit.post('/' + APP_URLS.getIndentItemsdata, {
        "IndentID": navigationData.IndentID
      }
        ,
        { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length) {
              // setIndent(res.data.data[0])
              let Note = "";
              if (res.data.data[0].CommentHistory) {
                Note = res.data.data[0].CommentHistory[0].Note
              }
              setIndent({ ...res.data.data[0], Note })
            }

            var list = [];
            res.data.data.forEach(x => {
              x.IndentItems.forEach(y => {
                if (y.CapitalInvestment) {
                  list = list.concat(y.CapitalInvestment)
                }

                if (y.RecurringExpenditure) {
                  list = list.concat(y.RecurringExpenditure)
                }
              })
            })

            setItemCostData(list)
            // setResData(res.data.data)
            // setIndentItems(res.data.data.IndentItems)
          }
          else {
          }
        })
        .catch(function (e) {
        })

    } catch (err) {

    }


  }

  const getIndentItems = () => {
    APIKit.post('/' + APP_URLS.getIndentItems, {
      "IndentID": navigationData.IndentID
    },
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        if (res.data.status === 200) {
          setResData(res.data.data)
          setIndentItems(res.data.data.IndentItems)
        }
        else {
        }
      })
      .catch(function (e) {
      })

  }

  const updateIndentStatus = () => {
    handleToggleLoader()
    const { IndentID, Note } = indent;
    const payload = {
      "IndentID": IndentID ? IndentID : navigationData.IndentID,
      "IndentStatus": indentStatus,
      "Note": Note
    }
    APIKit.post('/' + APP_URLS.updateIndentStatus, payload
      ,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } })
      .then((res) => {
        handleCloseLoader()
        if (res.data.status === 200) {
          setAlertOpen(true)
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          // props.gotoBidding()
          props.handleIndentItemsClose();

          //DefaultLoad()

        }

        else {
          setAlertOpen(true)
          setAlertMeg('error');
          setErrorMeg(res.data.message);
        }

      })
      .catch(function (e) {
        handleCloseLoader()
      })

  }

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleToggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const editItem = async () => {
    resData['typeEdit'] = 'Edit';
    await dispatch(getNavigationData(resData, navigationData.IndentID))
    setCreateIndents(true)
  }

  const gotoProject = () => {
    navigate('/app/Projects/' + navigationData.ProjectID, { replace: true });
  }
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setIndentStatus(event.target.value);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const getDropDownList = () => {

    axios.get(APP_URLS.getSubCategorydataDropDown + '/' + 1005,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          setSatusAction(res.data.data)
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  const handleCreateIndentClose = () => {
    setCreateIndents(false);

  };
  return (
    <Page
      className={classes.root}
      title="Depositors">
      <Backdrop className={classes.backdrop} open={openLoader} onClick={handleCloseLoader}>
        <CircularProgress style={{ color: '#fff' }} />
      </Backdrop>

      <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
        <Alert onClose={handleAlertClose} severity={alertMeg} >
          {errorMeg}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
              Supplies available at different locations
            </Grid>

          </Grid>
          {/*  INVESTMENT DATA */}
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}>

            <TableContainer component={Paper}>
              <Table id="table-to-xls" aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f1d2d2" }}>
                  <TableRow>

                    <TableCell>
                      S.No
                    </TableCell>
                    <TableCell>
                      Type
                    </TableCell>

                    <TableCell>
                      Item
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>
                      ProjectName
                    </TableCell>

                    <TableCell>
                      Address
                    </TableCell>

                    <TableCell>
                      Mobile
                    </TableCell>
                    <TableCell>
                      Repair
                    </TableCell>
                    <TableCell>
                      Used
                    </TableCell>
                    <TableCell>
                      Available
                    </TableCell>


                  </TableRow>
                </TableHead>
                {itemCostData && itemCostData.length != 0 ?
                  <TableBody>
                    {itemCostData.map((item, index) => {
                      return <TableRow
                        hover
                      // key={land.LandType}
                      >
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.type}
                        </TableCell>
                        <TableCell>
                          {item.CategoryName}
                        </TableCell>

                        <TableCell>
                          {item.ProjectName}
                        </TableCell>

                        <TableCell className={classes.wordbreak}>
                          {item.address}
                        </TableCell>

                        <TableCell>
                          {item.PhoneNumber}
                        </TableCell>
                        <TableCell>
                          {item.ItemsInRepair}
                        </TableCell>
                        <TableCell>
                          {item.ItemsInUse}
                        </TableCell>
                        <TableCell>
                          {item.ItemsInIdle}
                        </TableCell>
                      </TableRow>

                    })}
                  </TableBody>
                  : <TableBody><div style={{ color: "red" }}>No records found...</div></TableBody>}
              </Table>
            </TableContainer>



          </Box>
          {/*  INVESTMENT DATA */}
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
            padding: '20px'
          }}>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={9} style={{ color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                Indent Items
              </Grid>
              <Grid item xs={12} sm={3} style={{
                color: '#3f51b5', fontSize: '17px', fontWeight: 'bold',
                textAlign: 'right'
              }}>
                {/* <FormControl variant="outlined" className={classes.formControl}> */}
                <Button

                  variant="contained" color="primary" onClick={editItem} >Edit Indent Items</Button>
                {/* </FormControl> */}
              </Grid>
            </Grid>
            <Box mt={3}>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>

                      <TableCell>
                        S.No
                      </TableCell>
                      <TableCell>
                        Type
                      </TableCell>
                      <TableCell>
                        Material
                      </TableCell>
                      <TableCell>
                        Sub Material
                      </TableCell>
                      <TableCell>
                        Quantity
                      </TableCell>
                      <TableCell>
                        Purpose
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      {/* <TableCell>
                      Actions
                </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {indentItmes && indentItmes.length != 0 && indentItmes.map((item, index) => {
                      return <TableRow
                        hover
                        key={index + 1}
                      >


                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.ItemName}
                        </TableCell>
                        <TableCell>
                          {item.MaterialName}
                        </TableCell>
                        <TableCell>
                          {item.SubMaterialName ? item.SubMaterialName : "-"}
                        </TableCell>
                        <TableCell>
                          {item.Quantity}
                        </TableCell>
                        <TableCell>
                          {item.Purpose}
                        </TableCell>
                        <TableCell>
                          {item.status == 'Requested' ?
                            <span style={{ color: "orange" }}>{item.ProductDeliveryStatusName}</span>
                            : <span style={{ color: "green" }}>{item.ProductDeliveryStatusName}</span>}
                        </TableCell>
                        {/* <TableCell>
                        <EditIcon onClick={(e) => { editItem(item) }} />
                      </TableCell> */}

                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </div>
        {getloggedInData()?.role == 'Admin' || getloggedInData().role == 'Project Manager' ?
          <Box mt={3} style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            borderRadius: '5px',
          }}>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={2} style={{ marginLeft: '15px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginTop: "26px" }}>
                Update Status
              </Grid>
              <Grid item xs={12} sm={2} style={{ color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginLeft: "-5%" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={indentStatus || indent.IndentStatus}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                    </MenuItem>
                    {statuAction && statuAction.map(x => {
                      return <MenuItem key={x.SubCategoryId} value={x.SubCategoryId}>{x.SubCategoryName}</MenuItem>
                    })}

                  </Select>

                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={1} style={{ marginLeft: '53px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold', marginTop: "26px" }}>
                &nbsp;
              </Grid>
              <Grid item xs={12} sm={7} id="note" style={{ color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    disabled={indentStatus === indent.IndentStatus || !indentStatus}
                    id="outlined-basic" value={indent.Note} className="note" fullWidth label="Note" variant="outlined"
                    onChange={(e) => {
                      setIndent({
                        ...indent,
                        Note: e.target.value
                      })
                    }} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} style={{
                color: '#3f51b5', fontSize: '17px', fontWeight: 'bold',
                marginTop: "10px", marginLeft: "-20%"
              }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Button

                    disabled={indentStatus === indent.IndentStatus || !indentStatus}
                    variant="contained" color="primary" onClick={updateIndentStatus}>Save</Button>
                </FormControl>
              </Grid>
            </Grid>

          </Box>
          : null}
        {/* dialog */}
        <Dialog aria-labelledby="customized-dialog-title" open={openCreateIndent} id="income" style={{ maxWidth: "1100px !important", width: "1100px" }}>
          <DialogTitle id="customized-dialog-title" onClose={handleCreateIndentClose}
            style={{ backgroundColor: "#394671", color: "#fff" }}>
            Indent {/* {popdata.CategoryName} */}
          </DialogTitle>
          <Indent handleCreateIndentClose={handleCreateIndentClose} />

        </Dialog>
      </Container>
    </Page>
  )

}
export default IndentItems;
