import React, { useState, useEffect } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { useToasts } from 'react-toast-notifications';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  InputLabel,
  FormControl,
  MenuItem,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import moment from 'moment';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RemoveCircleTwoToneIcon from '@material-ui/icons/RemoveCircleTwoTone';
import mainlogo from 'src/Assets/cyptocurrency.png';
import { CenterFocusStrong } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIKit from '../../../utils/APIKit';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import { RGCA_URLS } from '../../../utils/url';
import '../GRN/style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import InventoryRep from './InventoryRep';
import ExpiryRep from './ExpiryRep';
import Loader from 'src/views/loader';
import Tooltip from '@material-ui/core/Tooltip';



const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#394671',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const InventoryRegister = () => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { project } = useParams();
  const { addToast } = useToasts();
  const projectDetails = { ...getProjectDetails(project) };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [inventoryExpiryList, setinventoryExpiryList] = useState([]);
  const [inventoryList, setinventoryList] = useState([]);
  const [filter, setFilter] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState(true);
  const [subCategoryDropdown, setSubCategoryDropdown] = useState(true);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [lookupData, setLookupData] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(true);


  const [addItem, setAddItem] = useState({
    type: '',
    category: '',
    subCategory: '',
    item: '',
    uom: '',
    brand: '',
    catalogueNumber: ''
  });

  const [addCategory, setAddCategory] = useState({
    typeID: null,
    categoryName: '',
    projectID: 1
  });

  const [addSubCategory, setAddSubCategory] = useState({
    type: null,
    categoryID: null,
    subCategoryName: ''
  });
  const [recurring, setRecurring] = useState([])
  const [value, setValue] = React.useState(0); // first tab
  const [disposedHistory, setDisposedHistory] = useState([])

  useEffect(() => {
    a11yProps(0);
  }, []);
  const a11yProps = index => {
    if (index == 0) {
      getInventoryList();
    }
    if (index == 1) {
      setPage1(0);
      getInventoryExpiryList();
      getInventoryRecurringReport()
    }
    if (index == 2) {
      setPage1(0);
      getAllBrokenHistory();
    }

    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleChangeType = e => {
    setFilter(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = event => {
    setRowsPerPage1(+event.target.value);

    setPage1(0);
  };
  useEffect(() => {
    getLookup();
    getInventoryRecurringReport()
  }, [navigate, projectDetails.projectID]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };
  const getCategories = type => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
      typeID: type,
      projectID: 1
    })
      .then(res => {
        if (res.data.status === 200) {
          setCategoryList(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  };
  const getInventoryExpiryList = () => {
    APIKit.get(RGCA_URLS.getExpiryListForIR + '/' + projectDetails.projectID)
      .then(res => {
        if (res.data.status === 200) {
          setinventoryExpiryList(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };
  const getSubCategories = categoryId => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
      categoryID: categoryId
    })
      .then(res => {
        if (res.data.status === 200) {
          setSubCategoryList(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: 'error' });
        }
      })
      .catch(function (e) { });
  };
  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch(e => { });
  };
  const searchIR = e => {
    if (e) {
      APIKit.post(RGCA_URLS.getListForIR, {
        projectID: projectDetails.projectID,
        searchText: e
      })
        .then(res => {
          if (res.data.status === 200) {
            setinventoryList(res.data.data);
          } else {
          }
        })
        .catch(function (e) { });
    } else {
      getInventoryList();
    }
  };

  const getInventoryRecurringReport = () => {
    setRecurring([]);
    setIsLoading(true);
    // APIKit.post(RGCA_URLS.getInventoryRecurringReport, {
    APIKit.post(RGCA_URLS.getInventoryRecurringData, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false)
          if (res.data.data.length == 0) {

          } else {
            console.log('responce getInventoryRecurringReport', res.data.data);
            setRecurring(res.data.data);
            // exportToCSV(res.data.data, `Inventory Report`);
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch(e => {
        setIsLoading(false)
      });
  };

  const getAllBrokenHistory = () => {
    setIsLoading(true)
    APIKit.post(RGCA_URLS.getAllBrokenHistoryData, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDisposedHistory(res.data.data)
        } else {
          setIsLoading(false);
          setDisposedHistory([]);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Loader isLoading={isLoading} />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Current Stock" onClick={() => a11yProps(0)} />
        <Tab label="Stock Register" onClick={() => a11yProps(1)} />
        <Tab label="Disposed History" onClick={() => a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Page className={classes.root} title="Inventory ">
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            {show ? <Typography><h2 style={{ color: '#394671' }}>INVENTORY</h2></Typography>
              : <Typography><h2 style={{ color: '#394671' }}>EXPIRY REPORT</h2></Typography>}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography className="toggleText" style={{ color: '#394671', fontWeight: 'bold' }}>
                INVENTORY
              </Typography>
              <FormControlLabel
                onChange={e => {
                  setShow(!show);
                }}
                control={<IOSSwitch sx={{ m: 1 }} />}
              />
              <Typography style={{ color: '#394671', fontWeight: 'bold' }}>
                EXPIRY REPORT
              </Typography>
            </Stack>
          </Box>

          <>
            <>{show ? <InventoryRep /> : <ExpiryRep />} </>
          </>
        </Page>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography><h2 style={{ color: '#394671' }}>Stock History</h2></Typography>
        {/* {recurring && recurring.length > 0 && */}
        <>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={2}
                style={{ marginTop: '10px', fontSize: '17px', fontWeight: 'bold', display: 'flex', gap: '8px' }}
              >
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="left">Item Id</TableCell>
                    <TableCell align="left">Item Category</TableCell>
                    {/* <TableCell align="left">Item Sub Category</TableCell> */}
                    <TableCell align="left">Item Name / Brand</TableCell>
                    {/* <TableCell align="left">Brand</TableCell> */}
                    <TableCell align="left">UOM</TableCell>
                    <TableCell align="left">Added Qty (+)</TableCell>
                    <TableCell align="left">Allocated Qty (-)</TableCell>
                    <TableCell align="left">Disposed Qty</TableCell>
                    <TableCell align="left">Sent / Received Qty</TableCell>
                    <TableCell align="left">Total Ava. Qty</TableCell>
                    <TableCell align="left">Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recurring && recurring.length != 0 ? (
                    recurring
                      .slice(
                        page1 * rowsPerPage1,
                        page1 * rowsPerPage1 + rowsPerPage1
                      )
                      .map((z, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{z.itemID}</TableCell>
                            <TableCell align="left">{z.cat}</TableCell>
                            {/* <TableCell align="left">{z.subCat}</TableCell> */}
                            <TableCell align="left">{z.itemName} / {z.brand ? z.brand : '-'}</TableCell>
                            {/* <TableCell align="left">{z.brand}</TableCell> */}
                            <TableCell align="left">{z.uom}</TableCell>
                            <TableCell align="left"> {z.grnQty ? z.grnQty : '0'}</TableCell>
                            <TableCell align="left"> {z.ginQty ? z.ginQty : '0'} </TableCell>
                            <TableCell align="left">{z.disposedQty ? z.disposedQty : '0'}</TableCell>
                            <TableCell align="left">{z.TransferQty ? z.TransferQty : '0'}</TableCell>
                            <TableCell align="left">{z.itemQty}</TableCell>
                            <TableCell align="left">
                              <Tooltip title={z?.comment}>
                                <span>
                                  {z.comment ? z?.comment?.length > 30 ? `${z.comment?.slice(0, 30)} ...` : z?.comment : '-'}
                                </span>
                              </Tooltip></TableCell>

                          </TableRow>
                        );
                      })) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {'No Data Found'}
                      </TableCell>{' '}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {recurring && recurring?.length > 10 &&
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={recurring.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
              />}
          </Box>
        </>
        {/* } */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography><h2 style={{ color: '#394671' }}>Disposed History</h2></Typography>
        {/* {recurring && recurring.length > 0 && */}
        <>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={2}
                style={{ marginTop: '10px', fontSize: '17px', fontWeight: 'bold', display: 'flex', gap: '8px' }}
              >
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="left">Item Id</TableCell>
                    <TableCell align="left">Item Category</TableCell>
                    <TableCell align="left">Item Sub Category</TableCell>
                    <TableCell align="left">Item Name</TableCell>
                    <TableCell align="left">UOM</TableCell>
                    <TableCell align="left">Disposed Qty</TableCell>
                    <TableCell align="left">Commet</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disposedHistory && disposedHistory.length != 0 ? (
                    disposedHistory.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                      .map((z, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{z.itemID}</TableCell>
                            <TableCell align="left">{z.categoryName}</TableCell>
                            <TableCell align="left">{z.subCategoryName}</TableCell>
                            <TableCell align="left">{z.name}</TableCell>
                            <TableCell align="left">{z.uom}</TableCell>
                            <TableCell align="left">{z.bQty}</TableCell>
                            <TableCell align="left">
                              <Tooltip title={z.comment}>
                                <span>
                                  {z.comment ? z?.comment?.length > 30 ? `${z.comment?.slice(0, 30)} ...` : z?.comment : '-'}
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {'No Data Found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {disposedHistory && disposedHistory?.length > 10 &&
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={disposedHistory?.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
              />}
          </Box>
        </>
        {/* } */}
      </TabPanel>
    </>
  );
};
export default InventoryRegister;
