import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import GlobalStyles from 'src/components/GlobalStyles';
import { ToastProvider } from 'react-toast-notifications';
import 'src/mixins/chartjs';
import theme from 'src/theme/index.jsx';
import routes from 'src/routes.jsx';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
    createStore, applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './Redux';
import { ConfirmProvider } from 'material-ui-confirm';
import { getloggedInData } from './utils/helpers';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';

const store = createStore(rootReducer, applyMiddleware(thunk));

const App = (props) => {
   console.log = console.warn = console.error = () => {};
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const routing = useRoutes(routes);

    let userData = JSON.parse(localStorage.getItem('userData'));
    useEffect(() => {
        
        if (userData == null) {
            navigate('/login', { replace: true });
        }
    }, [userData])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <React.StrictMode>
                <ConfirmProvider
                    defaultOptions={{
                        confirmationText: 'Yes',
                        cancellationText: 'No',
                        title: 'Are you sure ?'
                    }}>
                    <MuiThemeProvider theme={theme}>
                        <ToastProvider placement={'bottom-right'} autoDismissTimeout={5000} autoDismiss={true}>
                            <GlobalStyles />
                            <Provider store={store}>
                                {routing}
                            </Provider>
                        </ToastProvider  >
                    </MuiThemeProvider>
                </ConfirmProvider>
            </React.StrictMode>
        </MuiPickersUtilsProvider>

    );
};

export default App;
