import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    makeStyles,
    Avatar,
    Card,
    RadioGroup,
    FormControl,
    Radio,
    FormControlLabel,
    FormLabel,


} from '@material-ui/core';
import { sizing } from '@material-ui/system';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import mainlogo from 'src/Assets/cyptocurrency.png'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    logostyle: {
        height: 200,
        width: 200,
        justifyContent: 'center'
    },
    cardstyles: {
        background: '#fff',
        width: '100%',
        marginTop: 20,
        height: 230,
        borderRadius: 10,
        padding: 50,


    },
    radiostyles: {
        alignItems: 'center',
        // marginTop: 40,
        justifyContent:'center',
        textAlign:'center',
    }

}));

const Authentication = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [authtype, setAuthtype] = useState('')

    return (
        <Page
            className={classes.root}
            title="Authentication"
        >
            <Container maxWidth={false} style={{ background: '#c7cbce', height: '100%' }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={12}
                        xl={6}
                        xs={12}
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', margin: 'auto' }}
                    >

                        <Avatar
                            // alt="Remy Sharp"
                            src={mainlogo}
                            className={classes.logostyle}
                        />

                        {/* <Card className={classes.cardstyles}>
                            <FormControl component="fieldset" className={classes.radiostyles}>
                                <RadioGroup aria-label="gender" name="gender1"
                                    value={authtype}
                                    onChange={(event) => {
                                        setAuthtype(event.target.value)
                                    }}

                                >
                                    <FormControlLabel value="female" control={<Radio />} label={<span style={{ fontSize: '1.5rem' }}>Google Authenticator</span>} />
                                    <FormControlLabel value="male" control={<Radio />} label={<span style={{ fontSize: '1.5rem' }}>Two Factor Authenticator</span>} />
                                </RadioGroup>
                            </FormControl>
                            <Box my={2}>
                                <Button

                                    color="primary"
                                    // disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign in now
                                   </Button>
                            </Box>
                        </Card> */}



                    </Grid>
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={12}
                        xl={6}
                        xs={12}
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', margin: 'auto' }}
                    >

                       

                        <Card className={classes.cardstyles}>
                            <FormControl component="fieldset" className={classes.radiostyles}>
                                <RadioGroup aria-label="gender" name="gender1"
                                    value={authtype}
                                    onChange={(event) => {
                                        setAuthtype(event.target.value)
                                    }}

                                >
                                    <FormControlLabel value="female" control={<Radio />} label={<span style={{ fontSize: '1.8rem' }}>Google Authenticator</span>} />
                                    <FormControlLabel value="male" control={<Radio />} label={<span style={{ fontSize: '1.8rem' }}>Two Factor Authenticator</span>} />
                                </RadioGroup>
                            </FormControl>
                            <Box my={2}>
                                <Button

                                    color="primary"
                                    // disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign in now
                                   </Button>
                            </Box>
                        </Card>



                    </Grid>
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                    </Grid>

                </Grid>
            </Container>

        </Page>
    )

}

export default Authentication;

