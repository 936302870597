export const isFileAllowed = (fileName, type = '') => {
  let _isFileAllowed = false;
  let allowedFiles = [];
  if (type === 'bckUpload') {
    allowedFiles = ['.xls', '.xlsx', '.csv','.png'];
  } else {
    allowedFiles = [
      '.ppt',
      '.pptx',
      '.bmp',
      '.doc',
      '.docx',
      '.gif',
      '.jpeg',
      '.jpg',
      '.pdf',
      '.rtf',
      '.png',
      '.tif',
      '.tiff',
      '.txt',
      '.xls',
      '.xlsx',
      '.csv',
    ];
  }

  const regex = /(?:\.([^.]+))?$/;
  const extension = regex.exec(fileName);
  if (undefined !== extension && null !== extension) {
    for (const ext of allowedFiles) {
      if (ext === extension[0]) {
        _isFileAllowed = true;
      }
    }
  }
  return _isFileAllowed;
};

export const isFileSizeAllowed = (size) => {
  let _isFileSizeAllowed = false;
  if (size <= 5105344) {
    _isFileSizeAllowed = true;
  }
  return _isFileSizeAllowed;
};

export const validateEmail = (elementValue) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
};
