import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './theme-one.css'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon,
  MapPin as MapPinIcon,
  Layout as LayoutIcon,
  UserX as UserXIcon,
  Briefcase as BriefcaseIcon,
} from 'react-feather';
import NavItem from './NavItem';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import axios from 'axios';
import { getloggedInData } from '../../../utils/helpers'
import { APP_URLS } from '../../../utils/apis';
import APIKit,{setClientToken} from '../../../utils/APIKit';
import { useState } from 'react';
import AppMenuItem from './AppMenuItem';
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  // jobTitle: 'Senior Developer',
  name: 'RGCA'
};
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 350
  },
  desktopDrawer: {
    width: 350,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    // width: 500,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#535454',
    color: '#fff',
  }

}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const [url, setUrl] = React.useState("");
  const [itemData, setItemData] = useState([ ])

  const classes = useStyles();
  const location = useLocation();
  const handleChildClick = (data) => {
    getAllProjects();
  }
  const getAllProjects = async () => {
    let list = []
    await APIKit.get('/' + APP_URLS.getAllProjects)
      .then((res) => {
        if (res.status === 200) {
         if(getloggedInData().role == "Admin"){
           list = [{
            href: '/app/dashboard',
            icon: MapPinIcon,
            title: 'Dashboard'
          },
          {
            href: '',
            icon: BriefcaseIcon,
            title: 'Projects',
            items: [
              {
                href: '/app/AddProject',
                icon: BriefcaseIcon,
                title: 'Add Project'
              }


            ]
          },
          {
            href: '/app/Location',
            icon: MapPinIcon,
            title: 'Location'
          },
          {
            href: '/app/Employee',
            icon: UsersIcon,
            title: 'Employees'
          },
          {
            href: '/app/Role',
            icon: UsersIcon,
            title: 'Role'
          },

          {
            href: '/app/Department',
            icon: LayoutIcon,
            title: 'Department'
          },

          {
            href: '/app/Designation',
            icon: BarChartIcon,
            title: 'Designation'
          },

          // {
          //   href: '/app/Tax',
          //   icon: UsersIcon,
          //   title: 'Tax'
          // },
          
          // {
          //   href: '/app/Materials',
          //   icon: BriefcaseIcon,
          //   title: 'Materials'
          // },
          {
            href: '/app/Vendor',
            icon: BriefcaseIcon,
            title: 'Vendor'
          },

          {
            href: '/app/Categories',
            icon: BriefcaseIcon,
            title: 'Categories'
          },

          {
            href: '/login',
            icon: LogOutIcon,
            title: 'Logout'
          }];
          list.forEach((x) => {
            if (x.title == "Projects") {
              x.items = [];
              res.data.data.forEach((y) => {
                x.items.push(
                  {
                    href: '',
                    icon: UsersIcon,
                    title: y.ProjectName,
                    items:[    {
                      href: '/app/Projects/' + y.ProjectId,
                      icon: BriefcaseIcon,
                      title: 'DashBoard'
                    },
                    {
                      href: '/app/Location',
                      icon: MapPinIcon,
                      title: 'Profile'
                    },
                    {
                      href: '/app/Employee',
                      icon: UsersIcon,
                      title: 'Capital Investment'
                    },
                    {
                      href: '/app/Role',
                      icon: UsersIcon,
                      title: 'Recurring Expenditure'
                    },
          
                   ]
                  }
                )
              })
              if(getloggedInData().role != "Employee"){
              x.items.push(
                {
                  href: '/app/AddProject',
                  icon: BriefcaseIcon,
                  title: 'Add Project'
                }
              )
              }
              
            }
          })
          setItemData(list)
         }

        //  manager
        if(getloggedInData().role == "Project Manager"){
          list = [{
           href: '/app/dashboard',
           icon: MapPinIcon,
           title: 'Dashboard'
         },
         {
           href: '',
           icon: BriefcaseIcon,
           title: 'Projects',
           items: [
             {
               href: '/app/AddProject',
               icon: BriefcaseIcon,
               title: 'Add Project'
             }


           ]
         },
         {
           href: '/app/Location',
           icon: MapPinIcon,
           title: 'Location'
         },
         {
           href: '/app/Employee',
           icon: UsersIcon,
           title: 'Employees'
         },       
         {
           href: '/login',
           icon: LogOutIcon,
           title: 'Logout'
         }];

         list.forEach((x) => {
          if (x.title == "Projects") {
            x.items = [];
            res.data.data.forEach((y) => {
              x.items.push(
                {
                  href: '/app/Projects/' + y.ProjectId,
                  icon: UsersIcon,
                  title: y.ProjectName
                }
              )
            })
            if(getloggedInData().role != "Employee"){
            x.items.push(
              {
                href: '/app/AddProject',
                icon: BriefcaseIcon,
                title: 'Add Project'
              }
            )
            }
            
          }
        })
        setItemData(list)
        }

        //  employee
        if(getloggedInData().role == "Employee"){
          list = [
         {
           href: '',
           icon: BriefcaseIcon,
           title: 'Projects',
         
         },
        
        
         {
           href: '/login',
           icon: LogOutIcon,
           title: 'Logout'
         }];
         list.forEach((x) => {
          if (x.title == "Projects") {
            x.items = [];
            res.data.data.forEach((y) => {
              x.items.push(
                {
                  href: '/app/Projects/' + y.ProjectId,
                  icon: UsersIcon,
                  title: y.ProjectName
                }
              )
            })
            if(getloggedInData().role != "Employee"){
            x.items.push(
              {
                href: '/app/AddProject',
                icon: BriefcaseIcon,
                title: 'Add Project'
              }
            )
            }
            
          }
        })
        setItemData(list)
        }
         

          
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }

  useEffect(() => {
    let urlis = localStorage.getItem('urlis')
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if(localStorage && localStorage.userData){
      const TokenData = JSON.parse(localStorage.userData);
      setClientToken(TokenData.accessToken)
       getAllProjects();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);



const appMenuItems = [
  {
    name: 'Dashboard',
    link: '/',
    Icon: IconDashboard,
  },
  {
    name: 'Orders',
    link: '/orders',
    Icon: IconShoppingCart,
  },
  {
    name: 'Customers',
    link: '/customers',
    Icon: IconPeople,
  },
  {
    name: 'Reports',
    link: '/reports',
    Icon: IconBarChart,
  },
  {
    name: 'Nested Pages',
    Icon: IconLibraryBooks,
    items: [
      {
        name: 'Level 2',
      },
      {
        name: 'Level 2',
        items: [
          {
            name: 'Level 3',
          },
          {
            name: 'Level 3',
          },
        ],
      },
    ],
  },
]


  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/dashboard"
        />
        <Typography
          className={classes.name}
          // color="textPrimary"
          variant="h5"
          color="#fff"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
        {itemData.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
          {/* {itemData.map((item, index) => (
            <NavItem
              href={item.href}
              // key={item.title}
              title={item.title}
              icon={item.icon}
              {...item} key={index+1}
              onChildClick={handleChildClick} />
          ))} */}

        </List>
        </Drawer>
      </Box>

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
