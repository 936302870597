import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { RGCA_URLS } from "../../../utils/url";
import APIKit from "../../../utils/APIKit";
import { useToasts } from "react-toast-notifications";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CommonTable1 from "../Common/commonTableIndent";
import StepperBox from "../Common/StepperBox.jsx";
import { parseData, dateFormate, getProjectDetails } from "../Common/utilities";
import "./style.css";
import CommonTable from "../Common/CommonTable";
import { getloggedInData } from "src/utils/helpers";
import { useParams } from "react-router-dom";
import { ETTypes, EEditable, ETaction } from "../Common/Types";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import { MESSAGE } from "src/utils/message";
import { useReactToPrint } from "react-to-print";
import CommonTable2 from "../Common/commonTableIndent2";
import TimeData from "./Time.json";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  // icon: {
  //   fill: "green",
  // },
  text: {
    fill: "white",
  },
  stepIcon: {
    color: "#fff",
  },
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "pink",
    },
    "& $disabled": {
      color: "red",
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
}));

const viewIndent = [
  {
    title: "indent Item ID",
    field: "indentItemID",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Item / Asset Name",
    field: "name",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Uom",
    field: "uom",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Quantity",
    field: "Qty",
    align: "center",
    type: ETTypes.numeric,
  },
  {
    title: "Estimated Item Price",
    field: "estimatedPrice",
    align: "center",
    type: ETTypes.numeric,
  },
  {
    title: "total Estimated Price",
    field: "totalEstimatedPrice",
    align: "center",
    type: ETTypes.numeric,
  },
  // {
  //   title: "Stock",
  //   field: "availabilityQty",
  //   align: "center",
  //   type: ETTypes.string,
  // },
  {
    title: "Attachment",
    field: "itemAttach",
    align: "center",
    type: ETTypes.link,
  },
  {
    title: "Note",
    field: "comment",
    align: "center",
    type: ETTypes.string,
  },
];

const updateIndent = [
  {
    title: "Indent Item ID",
    field: "indentItemID",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Item / Asset Name",
    field: "name",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "UOM",
    field: "uom",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Quantity",
    field: "Qty",
    align: "center",
    type: ETTypes.numeric,
    editable: EEditable.onEdit,
  },
  {
    title: "Estimated Price",
    field: "estimatedPrice",
    align: "center",
    type: ETTypes.numeric,
    editable: EEditable.onEdit,
  },
  // {
  //   title: "Stock",
  //   field: "availabilityQty",
  //   align: "center",
  //   type: ETTypes.string,
  // },
  {
    title: "Attachment",
    field: "itemAttach",
    align: "center",
    type: ETTypes.link,
  },
  {
    title: "Note",
    field: "comment",
    align: "center",
    type: ETTypes.string,
  },
  {
    title: "Action",
    field: "action",
    align: "center",
    list: [ETaction.onDelete],
  },
];

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MyIndent = (props) => {
  console.log("props.permission", props?.permission);
  const classes = useStyles();
  const { project } = useParams();
  const { accessRoleID = null } = getloggedInData();
  const { addToast } = useToasts();
  const projectDetails = { ...getProjectDetails(project) };
  const [indentView, setIndentView] = React.useState(false);
  const [statusEdit, setStatusEdit] = React.useState(false);
  const [indentList, setIndentList] = useState([]);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0); // first tab
  const [indentItemList, setIndentItemList] = useState({
    indentMasterID: "",
    ProjectID: null,
    statusID: null,
    UpdatedBy: null,
    CreatedBy: null,
    quoteUrl: "",
    CreateDate: "",
    UpdatedDate: null,
    projectName: "",
    CreatedByName: "",
    indentItem: [],
    Approvals: [],
  });
  const [itemHis, setItemHis] = useState([]);
  const [timeHistroy,setTimeHistroy]=useState({
      totalAge: "",
      data: []
  })

  const [columns, setColumns] = useState([
    {
      title: "Indent ID",
      field: "indentMasterID",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Indent Status",
      field: "indStatus",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "PO Status",
      field: "POStatus",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Created Date",
      field: "CreateDate",
      align: "left",
      type: ETTypes.date,
    },
    {
      title: "Description",
      field: "indentComment",
      align: "left",
      type: ETTypes.string,
    },
    {
      title: "Actions",
      field: "action",
      align: "left",
      list:
        props?.permission == "view_indent"
          ? [ETaction.onView]
          : [ETaction.onEdit],
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    },
    // {
    //   title: "Approval",
    //   field: "action",
    //   align: 'left',
    //   list: [ETaction.trackApprove]
    // },
    // {
    //   title: "Raise Purchase",
    //   align: 'left',
    //   field: "action",
    //   align: 'left',
    //   list: [ETaction.moveOnPurchase]
    // }
  ]);

  const [updateColumns, setUpdateColumns] = useState([]);
  const [print, setprint] = useState(false);

  const handleIndentViewClose = () => {
    if (indentView) {
      setStatusEdit(false);
    }
    setIndentView(!indentView);
    getUserIndentLists();
  };

  useEffect(() => {
    getUserIndentLists();
  }, [navigate]);

  const getUserIndentLists = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getUserIndentList + "/" + projectDetails.projectID)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          let data = res.data.data.map((x) => {
            let indentItemData =
              x.indentItem &&
              parseData(x.indentItem).map((item) => {
                item["totalEstimatedPrice"] = item?.Qty * item?.estimatedPrice;
                return item;
              });

            return {
              ...x,
              Approvals: parseData(x.Approvals),
              indentItem: indentItemData,
            };
          });

          let filteredData = data.filter((x) => x.indStatus == "OPEN");
          console.log("kkkk", filteredData);

          setIndentList(
            props?.permission == "view&edit_indent" ? filteredData : data
          );
          if (statusEdit) {
            let row = data.find(
              (x) => x.indentMasterID === indentItemList.indentMasterID
            );
            if (row) {
              setIndentItemList({ ...row });
            }
          }
        } else {
        }
      })
      .catch(function(e) {});
  };

  const totalQty = (data = []) => {
    let test = 0;
    data.map((x) => {
      test = test + x.Qty;
    });
    return test;
    //  data['totalQty'] = test;
  };

  const actions = {
    
    onView: (index, row) => {
      setUpdateColumns([...viewIndent]);
      setIndentItemList({ ...row });
      handleIndentViewClose();
      history(row);
      getIndentApprovalListwithAge(row)
    },

    onEdit: (index, row) => {
      // if(props?.permission == 'view_indent'){
      //   toast.error("Don't have Permissions to edit", {
      //     position: "top-right",
      //     autoClose: 1000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      // }
      // else{
      history(row);
      setStatusEdit(true);
      setUpdateColumns([...updateIndent]);
      setIndentItemList({ ...row });
      handleIndentViewClose();
      // }
    },
  };

  const actions2 =
    props?.permission == "edit_indent"
      ? {
          onView: (index, row) => {
            setUpdateColumns([...viewIndent]);
            setIndentItemList({ ...row });
            handleIndentViewClose();
            history(row);
          },

          onEdit: (index, row) => {
            history(row);
            setStatusEdit(true);
            setUpdateColumns([...updateIndent]);
            setIndentItemList({ ...row });
            handleIndentViewClose();
          },
        }
      : {
          onView: (index, row) => {
            setUpdateColumns([...viewIndent]);
            setIndentItemList({ ...row });
            handleIndentViewClose();
            history(row);
          },
        };

  const history = (data) => {
    const payload = {
      indentMasterID: data.indentMasterID,
      projectID: projectDetails.projectID,
    };
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getIndentHistory, payload)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("IndentHistory", res?.data);
          setItemHis(res?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const modelActions = {
    editRow: (index, field, value) => {
      let items = indentItemList;
      items.indentItem[index][field] = value;
      setIndentItemList({ ...items });
    },

    onDelete: (index, row) => {
      // console.log("delete:",index, row)
      remove(row);
    },
  };

  const remove = (data) => {
    confirm({ description: "You want to delete the record ?" })
      .then(() => {
        deleteIndentItem(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log("Deletion cancelled."));
  };

  const deleteIndentItem = (row) => {
    const payload = {
      indentItemID: row.indentItemID,
      indentMasterID: row.indentMasterID,
    };
    APIKit.post(RGCA_URLS.deleteIndentItem, payload)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.deleteIndentItem, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getUserIndentLists();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function(e) {});
  };

  const updateIndentItems = () => {
    debugger
    const payload = {
      indentMasterID: indentItemList.indentMasterID,
      items: indentItemList.indentItem.map((x) => {
        return {
          approvedQty: x.Qty,
          indentItemID: x.indentItemID,
          Qty: x.Qty,
          estimatedPrice: x.estimatedPrice,
        };
      }),
    };
    console.error(payload);
    console.error(payload.items.some((e) => e.approvedQty === 0));
    if (payload.items.some((e) => e.approvedQty === 0)) {
      toast.error(MESSAGE.qty0, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (payload.items.some((e) => e.estimatedPrice == 0)) {
      toast.error(MESSAGE.est0, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    APIKit.post(RGCA_URLS.indentItemUpdate, payload)
      .then((res) => {
        if (res.data.status === 200) {
          //addToast(res.data.message, { appearance: 'success' });
          toast.success(MESSAGE.indentUpdate, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // getUserIndentLists()
          handleIndentViewClose();
        } else {
          addToast(res.data.message, { appearance: "error" });
        }
      })
      .catch(function(e) {});
  };

  const gotoCreation = () => {
    const projectN = window.sessionStorage.getItem("projectN");
    navigate("/app/indentcreate/" + projectN);
  };

  //   const printOrder = () => {
  //     const printableElements = document.getElementById('income').innerHTML;    // var orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
  //     const oldPage = document.body.innerHTML;
  //     const html = `<html><head><title></title>
  //         <style>

  // .invoice-box {
  //     max-width: 800px;
  //     margin: auto;
  //     padding: 30px;
  //     border: 1px solid #eee;
  //     box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  //     font-size: 16px;
  //     line-height: 24px;
  //     font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  //     color: #555;
  // }

  // .invoice-box table {
  //     width: 100%;
  //     line-height: inherit;
  //     text-align: left;
  // }

  // .invoice-box table td {

  //     vertical-align: top;
  // }

  // .invoice-box table tr.top table td {
  //     padding-bottom: 20px;
  // }

  // .invoice-box table tr.top table td.title {
  //     font-size: 45px;
  //     line-height: 45px;
  //     color: #333;
  // }

  // .invoice-box table tr.information table td {
  //     padding-bottom: 40px;
  // }

  // .invoice-box table tr.heading td {
  //     background: #eee;
  //     border-bottom: 1px solid #ddd;
  //     font-weight: bold;
  // }

  // .invoice-box table tr.details td {
  //     padding-bottom: 20px;
  // }

  // .invoice-box table tr.item td {
  //     border-bottom: 1px solid #eee;
  // }

  // .invoice-box table tr.item.last td {
  //     border-bottom: none;
  // }

  // .invoice-box table tr.total td:nth-child(2) {
  //     border-top: 2px solid #eee;
  //     font-weight: bold;
  // }

  // @media only screen and (max-width: 600px) {
  //     .invoice-box table tr.top table td {
  //         width: 100%;
  //         display: block;
  //         text-align: center;
  //     }

  //     .invoice-box table tr.information table td {
  //         width: 100%;
  //         display: block;
  //         text-align: center;
  //     }
  // }

  // /** RTL **/
  // .invoice-box.rtl {
  //     direction: rtl;
  //     font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  // }

  // .invoice-box.rtl table {
  //     text-align: right;
  // }

  // .invoice-box.rtl table tr td:nth-child(2) {
  //     /* text-align: left; */
  // }
  //         </style>
  //         </head><body>'${printableElements}'</body></html>`;
  //     // console.log(html)
  //     document.body.innerHTML = html
  //     window.print();
  //     document.body.innerHTML = oldPage;
  //     //setIncPrintOpen(false);
  //     window.location.reload()
  //   };

  const htmlContentRef = useRef(null);

  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });

  const getIndentApprovalListwithAge = (Data) => {
    console.log("fffffff",Data);
    
    setIsLoading(true);
    let payload = {
      projectID: Data?.ProjectID,
      indentMasterID: Data?.indentMasterID,
    };
    APIKit.post(RGCA_URLS.getIndentApprovalListwithAge ,payload)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          console.log("res.data",res.data);
          setTimeHistroy(res?.data?.data)
        }
      })
      .catch(function(e) {});
  };

  console.log("setTimeHistroy",timeHistroy);
  console.log("indentList",indentList);
  return (
    <div>
      <ToastContainer limit={1} />
      <Typography>
        <h2 style={{ color: "#394671" }}>
          {props?.permission == "view_indent"
            ? "View My Indent"
            : "Edit My Indent"}
        </h2>
      </Typography>
      <Grid container spacing={3} className={classes.field}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <CommonTable columns={columns} data={indentList} action={actions} />
          </div>
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={indentView}
        contentStyle={{
          width: "200%",
          maxWidth: "none",
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleIndentViewClose}
          style={{
            backgroundColor: "#394671",
            color: "#fff",
            width: "900px !important",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <h2>Indent Items</h2>
            <h3
              onClick={() => {
                setIndentView(false);
              }}
            >
              x
            </h3>
          </div>
        </DialogTitle>

        <Grid
          ref={htmlContentRef}
          id="myHtmlContent"
          /* id="income" */
        >
          <DialogContent dividers style={{ width: "900px !important" }}>
            <Grid container spacing={3}>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    <h5 style={{ color: "#394671" }}>
                      Indent ID : {indentItemList.indentMasterID}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    <h5 style={{ color: "#394671" }}>
                      Total Quantity:{" "}
                      {totalQty(indentItemList.indentItem || [])}
                    </h5>
                  </Typography>
                </div>
              </Grid>

              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    <h5 style={{ color: "#394671" }}>
                      Date : {dateFormate(indentItemList.CreateDate)}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    <h5 style={{ color: "#394671" }}>
                      Total Estimated Price :{" "}
                      {(indentItemList.indentItem
                        ? indentItemList.indentItem.reduce(
                            (a, b) => a + b.estimatedPrice * b.Qty,
                            0
                          )
                        : 0
                      ).toLocaleString("en-IN")}
                    </h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <div style={{ textAlign: "center" }}>
                  <Typography>
                    <h5 style={{ color: "#394671" }}>Total Age : {timeHistroy?.totalAge}</h5>
                  </Typography>
                </div>
              </Grid>
              {/* ---------------------------------------------------------------------------------------- */}

              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <div style={{ textAlign: "center" }}>
                  <CommonTable1
                    columns={updateColumns}
                    isDeletedoption={indentItemList.indentItem.length>1?true:false}
                    data={indentItemList.indentItem}
                    action={modelActions}
                    editLock={indentItemList.Approvals}
                    isEnabled={props?.permission == "view&edit_indent"}
                    isprint={print}
                  />
                </div>
              </Grid>
            </Grid>

            <br />
            <Grid container spacing={3}>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <StepperBox steps={indentItemList.Approvals} action={actions} />
              </Grid>
            </Grid>
           

            {/* new change for time period */}
            <Box>
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.secondaryHeading}>
                        <b>Indent Approved Time History</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Indent Id</TableCell>
                              <TableCell>Approval By</TableCell>
                              <TableCell>Raised Date</TableCell>
                              <TableCell>Approval Date</TableCell>
                              <TableCell>Age</TableCell>
                              <TableCell>Created By</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {timeHistroy?.data && timeHistroy?.data?.length != 0 ? (
                              timeHistroy?.data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              ).map((x, index) => (
                                <TableRow>
                                  <TableCell> {x?.indentMasterID}</TableCell>
                                  <TableCell>{x?.roleName}</TableCell>
                                  <TableCell>{moment(x?.CreateDate).format(
                                      "DD/MM/yyyy"
                                    )}</TableCell>
                                  <TableCell>{''}
                                    {moment(x?.UpdatedDate).format(
                                      "DD/MM/yyyy"
                                    )}
                                  </TableCell>
                                  <TableCell>{x?.hoursDifference}</TableCell>
                                  {/* item?.hoursDifference */}

                                  <TableCell>{x?.empLoginID}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow hover role="checkbox" key={1}>
                                <TableCell colSpan={6} align="center" key={2}>
                                  {"No Data Found"}
                                </TableCell>{" "}
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.secondaryHeading}>
                      Indent Partial-Edit-Registry-Approval-Item-History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Item Name</TableCell>
                              <TableCell>Requested Quantity</TableCell>
                              <TableCell>Approval Quantity</TableCell>
                              <TableCell>Updated By</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itemHis && itemHis.length != 0 ? (
                              itemHis
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((item, index) => (
                                  <TableRow>
                                    <TableCell>{item.itemName}</TableCell>
                                    <TableCell>{item.requestQty}</TableCell>
                                    <TableCell>{item.newValue}</TableCell>
                                    <TableCell>{item.employeeName}</TableCell>
                                  </TableRow>
                                ))
                            ) : (
                              <TableRow hover role="checkbox" key={1}>
                                <TableCell colSpan={4} align="center" key={2}>
                                  {"No Data Found"}
                                </TableCell>{" "}
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Grid>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          {statusEdit ? (
            <Button
              style={{
                backgroundColor: "#394671",
                color: "#fff",
                border: "1px solid",
              }}
              onClick={(e) => {
                updateIndentItems();
              }}
              disabled={
                indentItemList?.Approvals?.some(
                  (e) => e.status != "Awaiting"
                ) &&
                indentItemList?.Approvals?.every(
                  (e) => e.status != "Modification"
                )
              }
            >
              Update
            </Button>
          ) : (
            <></>
            // <Button
            //   style={{
            //     backgroundColor: "#394671",
            //     color: "#fff",
            //     border: "1px solid",
            //   }}
            //   onClick={() => {
            //     setprint(true);
            //     setTimeout(() => {
            //       printOrder();
            //     }, 2000);
            //   }}>
            //   Print
            // </Button>
          )}

          <Button
            style={{
              backgroundColor: "#394671",
              color: "#fff",
              border: "1px solid",
            }}
            onClick={() => {
              setprint(false);
              handleIndentViewClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default MyIndent;
