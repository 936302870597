import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  Container,
  Button,
  TextField,
  Typography,
  FormControl,
  MenuItem, FormHelperText, InputLabel, Select
} from '@material-ui/core';
import Page from 'src/components/Page';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import './style.css';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import { RGCA_URLS } from '../../utils/url';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import APIKit from '../../utils/APIKit';
import ErrorBoundaries from '../ErrorBoundaries';
import Loader from '../loader';
import { MESSAGE } from 'src/utils/message';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import StatesAndDis from "./states.json"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const AddProject = (props) => {
  console.log('props', props);
  const { addToast } = useToasts();
  const { params } = props;
  const classNamees = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stockEmail, setStockEmail] = useState([])
  const [isStockEmailAdd, setIsStockEmailAdd] = useState(false)
  const [purchaseEmail, setPurchaseEmail] = useState([])
  const [isPurchaseEmailAdd, setIsPurchaseEmailAdd] = useState(false)
  const [invoiceEmail, setInvoiceEmail] = useState([])
  const [isInvoiceEmailAdd, setIsInvoiceEmailAdd] = useState(false)
  const [paymentEmail, setPaymentEmail] = useState([])
  const [isPaymentEmailAdd, setIsPaymentEmailAdd] = useState(false)
  const [vendorEmail, setVendorEmail] = useState([])
  const [isVendorEmailAdd, setIsVendorEmailAdd] = useState(false)
  const [addMail, setAddMail] = useState([])
  const [test, setTest] = useState(['dirisalasrilaskhmi@gmail.com', 'dirisalasrilaskhmi5058@gmail.com'])

  const [addProject, setAddProject] = useState(
    {
      projectName: '',
      Description: '',
      shortID: '',
      mobile: '',
      projectArea: '',
      accessRoleID: 2,
      address: {
        line_1: '',
        line_2: '',
        district: '',
        state: '',
        country: 'India',
        postalCode: ''
      },
      bankDetails: {
        bankName: '',
        bankAccountNo: '',
        bankBranch: '',
        bankIFSC: '',
        bankAccountType: ''
      },
      email: [
        {
          emailID: [],
          AlertType: 1,
          alertName: "Stock Low Alert"
        },
        {
          emailID: [],
          AlertType: 2,
          alertName: "Purchase Order Alert"
        },
        {
          emailID: [],
          AlertType: 3,
          alertName: "Tax Invoice Bill Of Supply Alert"
        },
        {
          emailID: [],
          AlertType: 4,
          alertName: "Payment Receipt Alert"
        },
        {
          emailID: [],
          AlertType: 5,
          alertName: "VendorRFQAlert"
        }
      ]
    }

  );
  const [pro, setPro] = useState([])
  const [btn, setBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const query = window.location.pathname.split('/');
  const [districts, setDistricts] = useState([]);



  const [isEmailAdd, setIsEmailAdd] = useState(false)



  const [emails, setEmails] = useState([]);

  const [invalidEmails, setInvalidEmails] = useState([]);
  let _invalidEmails = []; // I need this

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const removeDuplicates = (list) => Array.from(new Set(list));

  useEffect(() => {

    if (query[3] != undefined) {
      getProfileData()
    } if (query[2] == "AddProject") {
      setBtn(false);
      setAddProject(
        {
          projectName: '',
          Description: '',
          shortID: '',
          mobile: '',
          projectArea: '',
          accessRoleID: 2,
          address: {
            line_1: '',
            line_2: '',
            district: '',
            state: '',
            country: 'India',
            postalCode: ''
          },
          bankDetails: {
            bankName: '',
            bankAccountNo: '',
            bankBranch: '',
            bankIFSC: '',
            bankAccountType: ''
          },
          email: [
            {
              emailID: [],
              AlertType: 1,
              alertName: "StockLowAlert"
            },
            {
              emailID: [],
              AlertType: 2,
              alertName: "PurchaseOrderAlert"
            },
            {
              emailID: [],
              AlertType: 3,
              alertName: "TaxinvoiceBillofSupplyAlert"
            },
            {
              emailID: [],
              AlertType: 4,
              alertName: "PaymentReceiptAlert"
            },
            {
              emailID: [],
              AlertType: 5,
              alertName: "VendorRFQAlert"
            }
          ]
        }
      )
    }

  }, [navigate]);
  const cancelProject = () => {
    setAddProject({
      projectName: '',
      Description: '',
      shortID: '',
      projectArea: '',
      mobile: '',
      accessRoleID: null,
      address: {
        line_1: ' ',
        line_2: '',
        district: '',
        state: '',
        country: 'India',
        postalCode: ''
      },
      bankDetails: {
        bankName: '',
        bankAccountNo: '',
        bankBranch: '',
        bankIFSC: '',
        bankAccountType: ''
      },
      email: [
        {
          emailID: [],
          AlertType: 1,
          alertName: "StockLowAlert"
        },
        {
          emailID: [],
          AlertType: 2,
          alertName: "PurchaseOrderAlert"
        },
        {
          emailID: [],
          AlertType: 3,
          alertName: "TaxinvoiceBillofSupplyAlert"
        },
        {
          emailID: [],
          AlertType: 4,
          alertName: "PaymentReceiptAlert"
        },
        {
          emailID: [],
          AlertType: 5,
          alertName: "VendorRFQAlert"
        }
      ]
    })
    setBtn(false);
    navigate('/app/projectlist')
  }
  const updateProject = () => {
    console.error('first')
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateProject, addProject)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          //addToast(MESSAGE.projectUpdated, { appearance: 'success' });
          toast.success(MESSAGE.projectUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setTimeout(() => {
            setBtn(false);
            navigate('/app/projectlist')
            setAddProject({
              projectName: '',
              Description: '',
              shortID: '',
              mobile: '',
              projectArea: '',
              address: {
                line_1: ' ',
                line_2: '',
                district: '',
                state: '',
                country: 'India',
                postalCode: ''
              },
              bankDetails: {
                bankName: '',
                bankAccountNo: '',
                bankBranch: '',
                bankIFSC: '',
                bankAccountType: ''
              }
            })
          }, 2000)
        } else {
          setIsLoading(false);
          toast.error(MESSAGE.projectNotUpdated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const removeSpaces = (email) => {
    return email.replace(/\s+/g, '');
  };

  const getProfileData = () => {
    setIsLoading(true);
    if (query[3] != undefined) {
      setBtn(true);
    }
    APIKit.get(RGCA_URLS.getProjectProfile + '/' + query[3])
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false)
          console.log('cccc', res.data.data[0])
          let data = res.data.data[0];
          let address = JSON.parse(data.address)
          let bankdetails = JSON.parse(data.bankDetails)
          let emailData = JSON.parse(data?.email)
          // Group the data by AlertType
          const groupedData = emailData.reduce((acc, curr) => {
            acc[curr.alertType] = acc[curr.alertType] || [];
            acc[curr.alertType].push(curr.emailID);
            return acc;
          }, {});
          console.log('groupedData', groupedData);
          // Transform the grouped data to the desired format
          const formattedData = Object.keys(groupedData).map(alertType => ({
            AlertType: parseInt(alertType), // Convert to integer if needed
            emailid: groupedData[alertType]
          }));

          // Now 'formattedData' contains the desired format based on AlertType
          console.log("formattedData", formattedData);
          const trimmedEmailData = emailData?.map(email => ({
            emailID: email?.emailID.filter(id => id.trim() !== ''),
            AlertType: email?.AlertType,
            alertName: email?.alertName
          }));
          console.log("emailData", trimmedEmailData);
          setAddProject(
            {
              projectID: data.projectID,
              projectName: data.projectName,
              Description: data.Description,
              shortID: data.shortID,
              projectArea: data.projectArea,
              mobile: data.mobile,
              address: {
                line_1: address[0].line_1,
                line_2: address[0].line_2,
                district: address[0].district,
                state: address[0].state,
                country: address[0].country,
                postalCode: address[0].postalCode

              },
              bankDetails: {
                bankName: bankdetails[0].bankName,
                bankAccountNo: bankdetails[0].bankAccountNo,
                bankBranch: bankdetails[0].bankBranch,
                bankIFSC: bankdetails[0].bankIFSC,
                bankAccountType: bankdetails[0].bankAccountType
              },
              email: trimmedEmailData
            }
          );
          const stateData = StatesAndDis.find((s) => s.state === address[0].state);
          setDistricts(stateData ? stateData.districts : []);

        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const handleToggle = () => {
    // props.handleToggle(true)
  };

  let regEx = {
    textAndSpecialChar: /^[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/,
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9.]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })
  const navi = () => {
    navigate(`/app/projectlist`, { replace: true });
  }

  console.log("addProjectllllllll", addProject);
  return (
    <ErrorBoundaries>
      <Page
        classNameName={classNamees.root}
        title="Project"
      >
        <ToastContainer />
        <Container maxWidth={isLoading}>
          <Loader isLoading={isLoading} />
          <Formik
            enableReinitialize={true}
            initialValues={
              { ...addProject }
            }

            onSubmit={(values) => {
              if (btn) {
                updateProject()
              } else {
                console.log("addProject", addProject);
                setIsLoading(true);
                APIKit.post(RGCA_URLS.addProjects, addProject)
                  .then((res) => {
                    if (res.data.status === 200) {
                      setIsLoading(false);
                      toast.success(MESSAGE.projectAdded, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      setTimeout(() => {
                        navigate('/app/projectlist')
                      }, 3000);
                    } else {
                      setIsLoading(false);
                      toast.success(MESSAGE.ShortName, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                  });
              }
            }}
            validationSchema={
              Yup.object().shape({
                projectName: Yup.string().required('Project Name is required').matches(/^[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/, "Numbers And Spaces Not allowed"),
                shortID: Yup.string().required('Short Project Name is required').matches(/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/, MESSAGE.blankSpaces).min(4, 'Must be 4 characters').max(4, 'Maximum 4 characters'),
                projectArea: Yup.number().required(MESSAGE.projectArea)
                // address: Yup.object().shape({
                // line_1: Yup.string().required('Address Line 1 is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                // line_2: Yup.string().required('Address Line 2 is required'),
                // district: Yup.string().required('District is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                // state: Yup.string().required('State is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                // country: Yup.string().required('Country is required').matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                // postalCode: Yup.string().min(6, MESSAGE.must6).required('Pincode is required'),
                // }),

              })
            }

          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              enableReintialize = true
            }) => (
              <MuiThemeProvider theme={formLabelsTheme}>

                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  />
                  <Grid container spacing={3}>
                    <Grid item lg={2}></Grid>
                    {btn == false
                      ? (
                        <Grid
                          item
                          lg={8}
                          style={{
                            marginTop: '30px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                          }}
                        >
                          Create Project
                        </Grid>
                      )
                      : (
                        <Grid
                          item
                          lg={8}
                          style={{
                            marginTop: '30px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                          }}
                        >
                          Edit Project
                        </Grid>
                      )}
                    <Grid style={{ display: "flex", alignItems: "center", marginLeft: '9rem', marginTop: '30px' }}>
                      <ArrowBackIcon onClick={navi} style={{ cursor: 'pointer' }} />
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>
                  <Grid container spacing={2} >
                    <Grid item lg={2}></Grid>
                    <Grid item lg={8}>
                      <Box
                        mt={3}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                          borderRadius: '5px',
                          padding: '20px'
                        }}
                      >
                        <Grid container spacing={3}>
                          {btn == false
                            ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                                }}
                              >
                                Add Project
                              </Grid>
                            )
                            : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                                }}
                              >
                                Edit Project
                              </Grid>
                            )}

                          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} />
                        </Grid>
                        <div>
                          <Grid
                            container
                            spacing={3}
                            style={{ textAlign: 'left' }}
                          >

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                label="Project Name"
                                name="projectName"
                                inputProps={{ maxLength: 150 }}
                                error={Boolean(touched.projectName && errors.projectName)}
                                helperText={touched.projectName && errors.projectName}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textAndSpecialChar.test(e.target.value)) {
                                    handleChange(e);
                                    const p = addProject;
                                    p.projectName = e.target.value;
                                    setAddProject({
                                      ...p,
                                      projectName: e.target.value
                                    });
                                  }
                                }}
                                required
                                value={addProject.projectName}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                inputProps={{ maxLength: 4 }}
                                fullWidth
                                label="Short Name"
                                name="shortID"
                                error={Boolean(touched.shortID && errors.shortID)}
                                helperText={touched.shortID && errors.shortID}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textAndSpecialChar.test(e.target.value)) {
                                    handleChange(e);
                                    const p = addProject;
                                    p.shortID = e.target.value;
                                    values.shortID = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      shortID: e.target.value,
                                    });
                                  }
                                }} zz
                                required
                                disabled={btn}
                                value={addProject.shortID}
                                variant="outlined"
                              />
                            </Grid>


                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                label="Phone Number"
                                error={Boolean(touched.mobile && errors.mobile)}
                                helperText={touched.mobile && errors.mobile}
                                name="mobile"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 13 }}
                                onBlur={handleBlur}
                                onChange={(e) => {

                                  if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                    handleChange(e);
                                    const p = addProject;
                                    p.mobile = e.target.value;
                                    values.mobile = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      mobile: e.target.value,

                                    });
                                  }
                                }}
                                value={addProject.mobile}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                label="Area Of The Project (Acres)"
                                name="projectArea"
                                error={Boolean(touched.projectArea && errors.projectArea)}
                                helperText={touched.projectArea && errors.projectArea}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                    handleChange(e);
                                    const p = addProject;
                                    p.projectArea = e.target.value;
                                    values.projectArea = e.target.value;
                                    if (parseFloat(e.target.value) > 0.1) {
                                      setAddProject({
                                        ...addProject,
                                        projectArea: e.target.value,
                                      });
                                    } else {
                                      toast.error("Value is grater than zero", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      })
                                    }
                                  }
                                }}
                                required
                                value={addProject.projectArea}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 500 }}
                                error={Boolean(touched.Description && errors.Description)}
                                helperText={touched.Description && errors.Description}
                                onBlur={handleBlur}
                                name="Description"
                                onChange={(e) => {
                                  handleChange(e);
                                  const p = addProject;
                                  p.Description = e.target.value;
                                  values.Description = e.target.value;
                                  setAddProject({
                                    ...addProject,
                                    Description: e.target.value,

                                  });
                                }}
                                value={addProject.Description}
                                label="Project Info"
                                variant="outlined"
                                multiline
                                rows={2}
                                rowsMax={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <small style={{ color: "gray" }}><code
                                className="me-2">{addProject.Description.length}/500</code> characters</small>

                            </Grid>
                            <Grid item lg={3} md={4} sm={6} />

                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>

                  <Grid container spacing={2} >
                    <Grid item lg={2}></Grid>
                    <Grid item lg={8}>
                      <Box
                        mt={3}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                          borderRadius: '5px',
                          padding: '20px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                            }}
                          >
                            Address
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} />
                        </Grid>
                        <div>

                          <Grid
                            container
                            spacing={3}
                            style={{ textAlign: 'left' }}
                          >

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                label="Address Line 1"
                                name="address.line_1"
                                error={Boolean(touched.address?.line_1 && errors.address?.line_1)}
                                helperText={touched.address?.line_1 && errors.address?.line_1}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  let address = { ...addProject.address };
                                  address.line_1 = e.target.value;
                                  setAddProject({
                                    ...addProject,
                                    address: { ...address }
                                    //  line_1: e.target.value
                                  });
                                }}
                                value={addProject.address.line_1}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                error={Boolean(touched.address?.line_2 && errors.address?.line_2)}
                                helperText={touched.address?.line_2 && errors.address?.line_2}
                                label="Address Line 2"
                                name="address.line_2"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  let address = { ...addProject.address };
                                  address.line_2 = e.target.value;
                                  setAddProject({
                                    ...addProject,
                                    address: { ...address }
                                    //  line_1: e.target.value
                                  });
                                }}
                                value={addProject.address.line_2}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                inputProps={{ maxLength: 30 }}
                                fullWidth
                                error={Boolean(touched.address?.country && errors.address?.country)}
                                helperText={touched.address?.country && errors.address?.country}
                                label="Country"
                                name="address.country"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                disabled
                                onChange={(e) => {
                                  // const re = /^[a-zA-Z\b]+$/;
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let address = { ...addProject.address };
                                    address.country = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      address: { ...address }
                                      //  line_1: e.target.value
                                    });
                                  }
                                }}
                                value={addProject.address.country}
                                variant="outlined"
                              />
                            </Grid>

                            {/* <Grid item lg={4} xs={12}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                name="address.state"
                                error={Boolean(touched.address?.state && errors.address?.state)}
                                helperText={touched.address?.state && errors.address?.state}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let address = { ...addProject.address };
                                    address.state = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      address: { ...address }
                                    });
                                  }
                                }}
                                value={addProject.address.state}
                                label="State"
                                variant="outlined"
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </Grid> */}

                            <Grid item lg={4} sm={6} xl={6} xs={6}>
                              <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  required
                                >
                                  State
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="State"
                                  name="address.state"
                                  required
                                  onChange={(e) => {
                                    debugger
                                    handleChange(e);
                                    let address = { ...addProject.address };
                                    address.state = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      address: { ...address }
                                    });

                                    const stateData = StatesAndDis.find((s) => s.state === e.target.value);
                                    console.log("lllll",stateData);
                                    setDistricts(stateData ? stateData.districts : []);
                                  }}
                                  value={addProject.address.state}
                                  onBlur={handleBlur}
                                >
                                  {StatesAndDis &&
                                    StatesAndDis.map(x => {
                                      return (
                                        <MenuItem value={x.state} key={x.state}>
                                          {x.state}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item lg={4} sm={6} xl={6} xs={6}>
                              <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  required
                                >
                                  District
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="District"
                                  name="address.district"
                                  required
                                  onChange={(e) => {
                                      handleChange(e);
                                      let address = { ...addProject.address };
                                      address.district = e.target.value;
                                      setAddProject({
                                        ...addProject,
                                        address: { ...address }
                                      });
                                  }}
                                  value={addProject.address?.district}
                                  onBlur={handleBlur}
                                >
                                  {districts &&
                                    districts.map(x => {
                                      return (
                                        <MenuItem value={x} key={x}>
                                          {x}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* <Grid item lg={4} xs={12}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                name="address.district"
                                error={Boolean(touched.address?.district && errors.address?.district)}
                                helperText={touched.address?.district && errors.address?.district}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let address = { ...addProject.address };
                                    address.district = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      address: { ...address }
                                    });
                                  }
                                }}
                                value={addProject.address.district}
                                label="District"
                                variant="outlined"
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </Grid> */}
                            <Grid item lg={4} xs={12}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 10 }}
                                name="address.postalCode"
                                error={Boolean(touched.address?.postalCode && errors.address?.postalCode)}
                                helperText={touched.address?.postalCode && errors.address?.postalCode}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let address = { ...addProject.address };
                                    address.postalCode = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      address: { ...address }
                                      //  line_1: e.target.value
                                    });
                                  }
                                }}
                                value={addProject.address.postalCode}
                                label="Pincode"
                                variant="outlined"
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </Grid>
                            <Grid item lg={3} md={4} sm={6} />

                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>

                  <Grid container spacing={2} >
                    <Grid item lg={2}></Grid>
                    <Grid item lg={8}>
                      <Box
                        mt={3}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                          borderRadius: '5px',
                          padding: '20px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                            }}
                          >
                            Bank Details
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} />
                        </Grid>
                        <div>

                          <Grid
                            container
                            spacing={3}
                            style={{ textAlign: 'left' }}
                          >

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                inputProps={{ maxLength: 16 }}
                                fullWidth
                                label="Account Number"
                                error={Boolean(touched.bankDetails?.bankAccountNo && errors.bankDetails?.bankAccountNo)}
                                helperText={touched.bankDetails?.bankAccountNo && errors.bankDetails?.bankAccountNo}
                                name="bankDetails.bankAccountNo"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {


                                  if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankAccountNo = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }

                                    });
                                  }
                                }}
                                value={addProject.bankDetails.bankAccountNo}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                label="Bank Name"
                                inputProps={{ maxLength: 25 }}
                                error={Boolean(touched.bankDetails?.bankName && errors.bankDetails?.bankName)}
                                helperText={touched.bankDetails?.bankName && errors.bankDetails?.bankName}
                                name="bankDetails.bankName"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankName = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }

                                    });
                                  }
                                }}
                                value={addProject.bankDetails.bankName}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                label="Bank Branch"
                                error={Boolean(touched.bankDetails?.bankBranch && errors.bankDetails?.bankBranch)}
                                helperText={touched.bankDetails?.bankBranch && errors.bankDetails?.bankBranch}
                                name="bankDetails.bankBranch"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankBranch = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }

                                    });
                                  }
                                }
                                }
                                value={addProject.bankDetails.bankBranch}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <TextField
                                fullWidth
                                name="bankDetails.bankIFSC"
                                error={Boolean(touched.bankDetails?.bankIFSC && errors.bankDetails?.bankIFSC)}
                                helperText={touched.bankDetails?.bankIFSC && errors.bankDetails?.bankIFSC}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.noSpecialChar.test(e.target.value)) {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankIFSC = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }

                                    });
                                  }
                                }}
                                inputProps={{ maxLength: 11 }}
                                value={addProject.bankDetails.bankIFSC}
                                label="IFSC Code"
                                variant="outlined"
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                            </Grid>
                            <Grid item lg={6} xs={12}>
                              {/* <TextField
                                fullWidth
                                error={Boolean(touched.bankDetails?.bankAccountType && errors.bankDetails?.bankAccountType)}
                                helperText={touched.bankDetails?.bankAccountType && errors.bankDetails?.bankAccountType}
                                name="bankDetails.bankAccountType"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankAccountType = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }

                                    });
                                  }
                                }}
                                value={addProject.bankDetails.bankAccountType}
                                label="Account Type"
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}


                              <FormControl
                                // error={Boolean(touched.bankDetails?.bankAccountType && errors.bankDetails?.bankAccountType)}
                                variant="outlined"
                                style={{ width: '100%' }}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Account Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  name="bankDetails.bankAccountType"
                                  onBlur={handleBlur}
                                  // disabled={isEdit}
                                  onChange={(e) => {
                                    handleChange(e);
                                    let bankDetails = { ...addProject.bankDetails };
                                    bankDetails.bankAccountType = e.target.value;
                                    setAddProject({
                                      ...addProject,
                                      bankDetails: { ...bankDetails }
                                    });
                                  }}
                                  value={addProject.bankDetails.bankAccountType}
                                  label="Account Type"
                                >
                                  <MenuItem value={'Savings'}>Savings</MenuItem>
                                  <MenuItem value={'Current'}>Current</MenuItem>

                                </Select>
                                {/* <FormHelperText style={{ color: 'red' }}>
                                  {errors.bankDetails.bankAccountType && touched.bankDetails.bankAccountType && errors.bankDetails.bankAccountType}
                                </FormHelperText> */}
                              </FormControl>

                            </Grid>

                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>

                  <Grid container spacing={2} >
                    <Grid item lg={2}></Grid>
                    <Grid item lg={8}>
                      <Box
                        mt={3}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                          borderRadius: '5px',
                          padding: '20px'
                        }}
                      >
                        <Grid container spacing={3} style={{ textAlign: 'left' }} >
                          <Grid item lg={12} sm={6} style={{
                            marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                          }}
                          >
                            Email Notification Configuration
                          </Grid>
                          {addProject && addProject.email && addProject.email.map((x, i) => {
                            console.log('cccc', x[i]?.emailID)
                            return <>
                              <Grid item lg={3} xs={12} >
                                <Typography>
                                  {x.alertName == "StockLowAlert" ? 'Low Stock Alert' : x.alertName == "PurchaseOrderAlert" ? 'Purchase Order Alert' : x.alertName == "TaxinvoiceBillofSupplyAlert" ? "Tax Invoice Bill Of Supply Alert" : x.alertName == "PaymentReceiptAlert" ? "Payment Receipt Alert" : "Vendor RFQ Alert"}</Typography>
                              </Grid>
                              <Grid item lg={8} xs={12} >
                                <ReactMultiEmail
                                  placeholder="Paste email addresses"
                                  emails={x?.emailID ? x.emailID : []}
                                  onChange={(e) => {
                                    // 
                                    // console.log("eeeeeeeeee", e);
                                    let p = addProject;
                                    if (p.email[i].AlertType == 1) {
                                      setStockEmail(e)
                                      p.email[i].emailID = e;
                                      setAddProject({ ...p })

                                    }
                                    if (p.email[i].AlertType == 2) {
                                      p.email[i].emailID = e;
                                      setAddProject({ ...p })
                                    }
                                    if (p.email[i].AlertType == 3) {
                                      p.email[i].emailID = e;
                                      setAddProject({ ...p })
                                    }
                                    if (p.email[i].AlertType == 4) {
                                      p.email[i].emailID = e;
                                      setAddProject({ ...p })
                                    }
                                    if (p.email[i].AlertType == 5) {
                                      p.email[i].emailID = e;
                                      setAddProject({ ...p })
                                    }

                                    // setStockEmail(e);
                                  }}
                                  getLabel={(email, index, removeEmail,) => {
                                    return (
                                      React.createElement("div", { "data-tag": true, key: index },
                                        email,
                                        React.createElement("span", { "data-tag-handle": true, onClick: () => removeEmail(index) }, "×")
                                      )
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item lg={1} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <AddCircleOutlineIcon onClick={() => {
                                  if (x.AlertType == 1) {
                                    setIsStockEmailAdd(true)
                                    setAddMail([])
                                  }
                                  if (x.AlertType == 2) {
                                    setIsPurchaseEmailAdd(true)
                                    setAddMail([])
                                  }
                                  if (x.AlertType == 3) {
                                    setIsInvoiceEmailAdd(true)
                                    setAddMail([])
                                  }
                                  if (x.AlertType == 4) {
                                    setIsPaymentEmailAdd(true)
                                    setAddMail([])
                                  }
                                  if (x.AlertType == 5) {
                                    setIsVendorEmailAdd(true)
                                    setAddMail([])
                                  }
                                }} style={{ cursor: 'pointer' }} />
                              </Grid>
                            </>
                          })}


                        </Grid>

                      </Box>
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>



                  <Box mt={3}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{ marginLeft: '-17%' }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {btn == false
                          ? (
                            <Button
                              style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                              variant="contained"
                              // onClick={() => saveProject()}
                              type="submit"
                            >
                              Save
                            </Button>
                          )
                          : (
                            <div>
                              <Button type="submit" style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}>
                                Update
                              </Button>
                              <Button onClick={cancelProject} style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}>
                                Cancel
                              </Button>
                            </div>
                          )}

                      </Box>
                    </Grid>
                  </Box>
                </Form>
              </MuiThemeProvider>
            )}
          </Formik>
          {/* dialog */}
          {/*  Add Stock Low Alert Email */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isStockEmailAdd}
            id="income"
            contentStyle={{
              width: '50%',
              maxWidth: 'none'
            }}
            maxWidth="sm"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setIsStockEmailAdd(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Add Low Stock Alert Email
            </DialogTitle>
            <Formik
              initialValues={{
                stocks: '',
              }}
              onSubmit={(values, { resetForm }) => {

                const combinedEmails = [...stockEmail, ...addMail].filter((email) => email && email.trim() !== '');
                // setStockEmail(combinedEmails);
                let p = addProject
                p.email[0].emailID = [...combinedEmails, ...p.email[0].emailID]
                setAddProject({ ...p })
                setIsStockEmailAdd(false);
                resetForm()
              }}
              validationSchema={Yup.object().shape({
                stocks: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email).test(
                  'is-valid-domain',
                  'Email must end with .com or .in',
                  (value) => /@[a-zA-Z0-9.-]+\.(com|in)$/.test(value)
                ),
              })}
            >
              {({
                errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
              }) => (
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <DialogContent>
                    <Grid item md={12} xs={12}>
                      <TextField
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Emails"
                        name="stocks"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e)
                          setAddMail([e.target.value])
                        }}
                        required
                        error={Boolean(touched.stocks && errors.stocks)}
                        helperText={touched.stocks && errors.stocks}
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button
                      onClick={e => {
                        setIsStockEmailAdd(false)
                      }}
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid',
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          {/*Add Purchase Order Email  */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isPurchaseEmailAdd}
            id="income"
            contentStyle={{
              width: '50%',
              maxWidth: 'none'
            }}
            maxWidth="sm"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setIsPurchaseEmailAdd(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Add Purchase Order Email
            </DialogTitle>
            <Formik
              initialValues={{
                PurchaseOrder: '',
              }}
              onSubmit={(values, { resetForm, setFieldError }) => {

                const newEmail = values.PurchaseOrder.trim();
                let p = addProject;

                // Check for duplicate email in p.email[1].emailID
                if (p.email[1].emailID.includes(newEmail)) {
                  setFieldError('PurchaseOrder', 'This email is already in the list.');
                  return;
                }
                const combinedEmails = [...purchaseEmail, ...addMail].filter((email) => email && email.trim() !== '');
                // setPurchaseEmail(combinedEmails);
                // let p = addProject
                p.email[1].emailID = [...combinedEmails, ...p.email[1].emailID]
                setAddProject({ ...p })
                setIsPurchaseEmailAdd(false);
                resetForm()
              }}
              validationSchema={Yup.object().shape({
                PurchaseOrder: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email).test(
                  'is-valid-domain',
                  'Email must end with .com or .in',
                  (value) => /@[a-zA-Z0-9.-]+\.(com|in)$/.test(value)
                ),
              })}
            >
              {({
                errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
              }) => (
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <DialogContent>
                    <Grid item md={12} xs={12}>
                      <TextField
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Emails"
                        name="PurchaseOrder"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e)
                          setAddMail([e.target.value])
                        }}
                        required
                        error={Boolean(touched.PurchaseOrder && errors.PurchaseOrder)}
                        helperText={touched.PurchaseOrder && errors.PurchaseOrder}
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button
                      onClick={e => {
                        // setBulkOpen(!bulkOpen);
                        setIsPurchaseEmailAdd(false)
                      }}
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      // onClick={() => {
                      //   const combinedEmails = [...purchaseEmail, ...addMail].filter((email) => email && email.trim() !== '');
                      //   let p = addProject
                      //   p.email[1].emailID = [...combinedEmails, ...p.email[1].emailID]
                      //   setAddProject({ ...p })
                      //   setIsPurchaseEmailAdd(false);
                      // }}
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid',
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          {/* Add Tax invoice/Bill of Supply Email */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isInvoiceEmailAdd}
            id="income"
            contentStyle={{
              width: '50%',
              maxWidth: 'none'
            }}
            maxWidth="sm"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setIsInvoiceEmailAdd(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Add Tax invoice/Bill of Supply Email
            </DialogTitle>
            <DialogContent>
              <Grid item md={12} xs={12}>
                <TextField
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                  label="Emails"
                  name="PurchaseOrder"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setAddMail([e.target.value])
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
              <Button
                onClick={e => {
                  // setBulkOpen(!bulkOpen);
                  setIsInvoiceEmailAdd(false)
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid'
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // Combine emails from both sources (if necessary)
                  const combinedEmails = [...invoiceEmail, ...addMail].filter((email) => email && email.trim() !== '');
                  // setInvoiceEmail(combinedEmails);
                  let p = addProject
                  p.email[2].emailID = [...combinedEmails, ...p.email[2].emailID]
                  setAddProject({ ...p })
                  setIsInvoiceEmailAdd(false);
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid',
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/*  Add Payment Receipt Email */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isPaymentEmailAdd}
            id="income"
            contentStyle={{
              width: '50%',
              maxWidth: 'none'
            }}
            maxWidth="sm"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setIsPaymentEmailAdd(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Add Payment Receipt Email
            </DialogTitle>
            <DialogContent>
              <Grid item md={12} xs={12}>
                <TextField
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                  label="Emails"
                  name="PurchaseOrder"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setAddMail([e.target.value])
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
              <Button
                onClick={e => {
                  // setBulkOpen(!bulkOpen);
                  setIsPaymentEmailAdd(false)
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid'
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // Combine emails from both sources (if necessary)
                  const combinedEmails = [...paymentEmail, ...addMail].filter((email) => email && email.trim() !== '');
                  // setPaymentEmail(combinedEmails);
                  let p = addProject
                  p.email[3].emailID = [...combinedEmails, ...p.email[3].emailID]
                  setAddProject({ ...p })
                  setIsPaymentEmailAdd(false);
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid',
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add Vendor RFQ Email */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isVendorEmailAdd}
            id="income"
            contentStyle={{
              width: '50%',
              maxWidth: 'none'
            }}
            maxWidth="sm"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setIsVendorEmailAdd(false)
              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              Add Vendor RFQ Email
            </DialogTitle>
            <DialogContent>
              <Grid item md={12} xs={12}>
                <TextField
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                  label="Emails"
                  name="PurchaseOrder"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setAddMail([e.target.value])
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
              <Button
                onClick={e => {
                  // setBulkOpen(!bulkOpen);
                  setIsVendorEmailAdd(false)
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid'
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // Combine emails from both sources (if necessary)
                  const combinedEmails = [...vendorEmail, ...addMail].filter((email) => email && email.trim() !== '');
                  // setVendorEmail(combinedEmails);
                  let p = addProject
                  p.email[4].emailID = [...combinedEmails, ...p.email[4].emailID]
                  setAddProject({ ...p })
                  setIsVendorEmailAdd(false);
                }}
                style={{
                  backgroundColor: '#394671',
                  color: '#fff',
                  border: '1px solid',
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Page>
    </ErrorBoundaries>
  );
};
export default AddProject;
