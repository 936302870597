import React, { useState, useEffect } from 'react';
import {
  Box, Card, Typography, makeStyles, Container, Button, CardContent, Select, InputLabel, FormControl, MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import APIKit from '../../../utils/APIKit';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { parseData, dateFormate, getProjectDetails } from '../Common/utilities';
import { useParams } from 'react-router-dom';
import { RGCA_URLS } from '../../../utils/url';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { dateOnlyRev } from '../Common/utilities';
import * as XLSX from 'xlsx';
import { MESSAGE } from 'src/utils/message';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'src/views/loader';
import SalesReport from './SalesReport';
import ProductionReport from './productionReport';
import InventoryReport from './inventoryReport';
import PaymentReport from './paymentReport';
import ProductionSalesReport from './productionSaleReport';
import AllProjectReports from './allProjectsReports';
import CurrentProjectReports from './currentProject';
import { checkPermission } from 'src/utils/permission';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    // maxWidth:"200px!important",
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  dropdownBorder: {
    border: '1px solid #c4c4c4'
  },
  root: {
    textAlign: 'start', // Default width for non-mobile devices
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center' // Custom width for mobile devices (adjust as needed)
    }
  },
  alignForInventory: {
    // Default width for non-mobile devices
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'end'
    }
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Reports = props => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { project } = useParams();
  const projectDetails = props.projectID || { ...getProjectDetails(project) };
  const [value, setValue] = React.useState(0); // first tab

  let userData = JSON.parse(localStorage.getItem('userData'));



  const a11yProps = index => {
    if (index == 0) {
    }
    if (index == 1) {
    }
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };
  const handleChange = (_event, newValue) => {

    setValue(newValue);
  };


  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <ToastContainer />
        <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >

            {(checkPermission("view_currentProjectReports_inventory") ||
              checkPermission("view_currentProjectReports_sales") ||
              checkPermission("view_currentProjectReports_production") ||
              checkPermission("view_currentProjectReports_payment") ||
              checkPermission("view_currentProjectReports_productionandsales") ||
              userData?.isAdmin == 1) && (
                <Tab label="Current Project"
                  onClick={() => {
                    a11yProps(0)
                  }}
                />
              )}
            {(checkPermission("view_allProjectReports_inventory") ||
              checkPermission("view_allProjectReports_sales") ||
              checkPermission("view_allProjectReports_production") ||
              checkPermission("view_allProjectReports_payment") ||
              checkPermission("view_allProjectReports_productionandsales") ||
              userData?.isAdmin == 1) && (
                <Tab label="All Projects" onClick={() => {
                  a11yProps(1)
                }} />
              )}
          </Tabs>
        </AppBar>


        {(checkPermission("view_currentProjectReports_inventory") ||
          checkPermission("view_currentProjectReports_sales") ||
          checkPermission("view_currentProjectReports_production") ||
          checkPermission("view_currentProjectReports_payment") ||
          checkPermission("view_currentProjectReports_productionandsales") ||
          userData?.isAdmin == 1) && (
            <TabPanel value={value} index={0}>
              <CurrentProjectReports />
            </TabPanel>
          )}
        {(checkPermission("view_allProjectReports_inventory") ||
          checkPermission("view_allProjectReports_sales") ||
          checkPermission("view_allProjectReports_production") ||
          checkPermission("view_allProjectReports_payment") ||
          checkPermission("view_allProjectReports_productionandsales") ||
          userData?.isAdmin == 1) && (
            <TabPanel value={value} index={1}>
              <AllProjectReports />
            </TabPanel>
          )}
      </Container>
    </Page>
  );
};
export default Reports;
