import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  makeStyles,
  Container,
  Button,
  CardContent,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import APIKit from "../../utils/APIKit"
import { getloggedInData } from '../../utils/helpers'
import { APP_URLS } from '../../utils/apis';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './style.css';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }
}));




const Tax = () => {
  const classes = useStyles();
  // const [depositors] = useState(data);
  const [limit] = useState(10);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [allTaxes, setAllTax] = useState([])
  const [taxes, setTaxes] = useState({ "name": "", "TaxValue": null, "TaxID": null });
  const [btn, setBtn] = React.useState({ "name": "Save" });

  useEffect(() => {
    getAllTaxes();
  }, [])

  const getAllTaxes = () => {

    APIKit.get('/' + APP_URLS.getTax,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          setAllTax(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }



  const editRole = (data) => {
    setBtn({ "name": "Update" })

    setTaxes({ "name": data.SubCategoryName, "TaxValue": data.TaxValue, "TaxID": data.TaxID })
  }
  const updateTaxes = () => {
    APIKit.post('/' + APP_URLS.updateTax, taxes,
      { headers: { 'Content-Type': 'application/json', 'Authorization': "bearer " + getloggedInData().accessToken } }
    )
      .then((res) => {

        if (res.data.status === 200) {
          setAlertMeg('success');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
          getAllTaxes()
          setTaxes({ "name": "", "TaxValue": "", "TaxID": "" })
          setBtn({ "name": "Save" })
        }
        else {
          setAlertMeg('error');
          setErrorMeg(res.data.message);
          setAlertOpen(true);
        }
      })
      .catch(function (e) {
      })
  }


  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };


  return (
    <Page
      className={classes.root}
      title="Tax">
      <Container maxWidth={false}>
        <div>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}  >
              <Alert onClose={handleAlertClose} severity={alertMeg} >
                {errorMeg}
              </Alert>
            </Snackbar>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold' }}>
                Tax
              </Grid>

            </Grid>

          </Box>
          {btn.name != 'Save' &&
            <Box mt={3}>

              <Card>
                <CardContent>
                  <Box >
                    <Grid container>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >

                        <TextField

                          helperText=""
                          label="Name"
                          name="role"
                          onChange={(e) => {
                            let p = taxes;
                            p.name = e.target.value
                            setTaxes({
                              ...p
                            })
                          }}
                          value={taxes.name}
                          required
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                        />

                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField

                          helperText=""
                          label="Percentage"
                          name="TaxValue"
                          // onChange={(e) => {
                          //   let p = taxes;
                          //   p.TaxValue = Number(e.target.value.replace(/\D/g, ""))
                          //   setTaxes({
                          //     ...p
                          //   })
                          // }}
                          onChange={event => {

                            if (event.target.value.match(/^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/)) {
                              let p = taxes;
                              p.TaxValue = event.target.value
                              setTaxes({
                                ...p
                              })
                            } else {
                              let p = taxes;
                              p.TaxValue = ""
                              setTaxes({
                                ...p
                              })
                            }
                          }}
                          value={taxes.TaxValue}
                          inputProps={{
                            maxLength: 8
                          }}
                          required
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </Grid>

                      <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ position: "relative", left: "-10%" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                        >
                          {btn.name == 'Save' ?
                            <></>
                            : <Button
                              color="primary"
                              variant="contained" style={{ marginTop: '14px' }}
                              onClick={updateTaxes}
                            >
                              Update
                            </Button>}
                          <Grid
                            item
                            md={3}
                            xs={12}
                          ></Grid>
                        </Box>
                      </Grid>


                    </Grid>

                  </Box>
                </CardContent>
              </Card>
            </Box>
          }
        </div>
        <Box mt={3}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>

                  <TableCell style={{ width: '30%' }}>
                    S.No
                  </TableCell>
                  <TableCell style={{ width: '40%' }}>
                    Name
                  </TableCell>
                  <TableCell style={{ width: '40%' }}>
                    Percentage
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTaxes && allTaxes.slice(0, limit).map((tax, index) => (
                  <TableRow
                    hover
                    key={tax.RoleID}
                  >
                    <TableCell style={{ width: '30%' }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ width: '40%' }}>
                      {tax.SubCategoryName}
                    </TableCell>
                    <TableCell style={{ width: '40%' }}>
                      {tax.TaxValue}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right', width: '30%' }}>
                      <EditOutlinedIcon onClick={() => editRole(tax)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* dialog */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>

            <div style={{ justifyContent: 'flex-end', alignItems: 'center', display: 'flex', cursor: 'pointer' }} onClick={handleClose}>X</div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '30px' }}>
                <Avatar
                  // alt="Remy Sharp"
                  src="/static/images/avatars/avatar_3.png"
                  className={classes.logostyle}
                />
              </div>


              <div style={{ textAlign: 'center' }}>

                <Grid container spacing={1} style={{ marginBottom: '30px' }}>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Name:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>Ekaterina Tankova</Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Email:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>ekaterina.tankova@devias.io</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Contact No:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>304-428-3097</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Registration Date:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>12/04/2019</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Balace:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>23,200 USDT</Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Button style={{ textAlign: 'center' }}
                      color="primary"
                      variant="contained" className={classes.mar10}
                    >
                      VIEW ALL TRANSACTIONS
                    </Button>
                  </Grid>

                </Grid>


              </div>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
          </Button>

          </DialogActions> */}
        </Dialog>




      </Container>
    </Page>
  )

}
export default Tax;
