import React, { useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import 'date-fns';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from 'src/views/loader';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { MESSAGE } from 'src/utils/message';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import RegistrationView from './registrationView';
import EditIcon from '@material-ui/icons/Edit';
import { currencyFormat1, dateOnly, dateOnlyRev, getProjectDetails, parseData, precision } from '../Common/utilities';
import InfoIcon from '@mui/icons-material/Info';
import HigherQualificationList from './higherQualification.json';
import StatesAndDis from "./states.json"




const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px',
    },
    textleftshadow: {
        textAlign: 'left', boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right', boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4', borderRadius: '4px'
    },
    calmar: {
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '0px'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff',
        },
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



const Registration = ({ isLoading, registrationData, programData, setIsLoading, getregistrationData = () => { } }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isRegisterAdd, setIsRegiterAdd] = useState(false);
    const [isImageAttach, setIsImageAttach] = useState('');
    const [ispaymentReceipt, setIsPaymentReceipt] = useState('');
    const [payload, setPayload] = useState({
        trainerName: "",
        InstitueAffiliation: "",
        email: "",
        gender: "",
        dateOfBirth: null,
        phone: "",
        line_1: "",
        line_2: "",
        line_3: "",
        district: "",
        state: "",
        country: "India",
        tranierDetails: "",
        HigherQualification: "",
        Subjects: "",
        YOP: null,
        ClassRank: "",
        Institution: "",
        HandsOnTrainingProgrammeID: "",
        ImageAttach: "",
        paymentReceipt: "",
        Modeofpayment: "",
        TranscationNo: "",
        BankName: "",
        TranscationDate: null,
        isPurchasingorCompleted: "Pursuing"
    });
    const [programDetails, setProgramDetails] = useState({})
    const [isRegView, setIsRegView] = useState(false)
    const [viewRegData, setViewRegData] = useState({})
    const [isEdit, setIsEdit] = useState(false);
    const [isPhotoError, setIsPhotoError] = useState(false);
    const [isPaymentError, setIsPaymentError] = useState(false);
    const [districts, setDistricts] = useState([]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleUploadAttach = (event, type, wat) => {
        // const filesFormats = [".png", ".jpg", '.jpeg', 'jfif', '.pjpeg', 'pjp', '.svg', '.webp', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        const filesFormats = [
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/jfif",
            "image/pjpeg",
            "image/pjp",
            "image/svg+xml",
            "image/webp",
            "application/pdf"
        ];
        console.log("filesFormats", filesFormats);
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        console.log("isRightFormat", isRightFormat);
        if (!isRightFormat) {
            toast.error("You can only upload pdf,png and jpg files");
            return;
        }
        if (event.target.files[0] === undefined) {
            return;
        }
        if (event.target.files[0].size > 3145728) {
            toast.error(MESSAGE.lessthan3mb, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append("files", event.target.files[0], event.target.files[0].name);
        if (type == "ImageAttach") {
            APIKit.post(RGCA_URLS.upload, formData)
                .then((res) => {
                    if (res.data.status == 200) {
                        setIsPhotoError(false)
                        setIsLoading(false);
                        toast.success(MESSAGE.fileUpload, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setIsImageAttach(res?.data?.data)
                    } else {
                        setIsLoading(false);
                        toast.error(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
        if (type == "PaymentReceipt") {
            APIKit.post(RGCA_URLS.upload, formData)
                .then((res) => {
                    if (res.data.status == 200) {
                        setIsPaymentError(false)
                        setIsLoading(false);
                        toast.success(MESSAGE.fileUpload, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setIsPaymentReceipt(res?.data?.data)
                    } else {
                        setIsLoading(false);
                        toast.error(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }

    };

    let regEx = {
        textOnly: /[a-zA-Z\s]+/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/
    };

    const EditRowData = (x) => {
        debugger
        setIsLoading(true)
        setPayload({
            trainerName: x?.TraineeName,
            InstitueAffiliation: x?.InstitueAffiliation,
            email: x?.email,
            gender: x?.gender,
            dateOfBirth: x?.dateOfBirth,
            phone: x?.phone,
            line_1: x?.line_1,
            line_2: x?.line_2,
            line_3: x?.line_3,
            district: x?.district,
            state: x?.state,
            country: x?.country,
            tranierDetails: x?.tranierDetails,
            HigherQualification: x.HigherQualification,
            Subjects: x?.Subjects,
            YOP: x?.YOP,
            ClassRank: x?.ClassRank,
            Institution: x?.Institution,
            HandsOnTrainingProgrammeID: x?.HandsOnTrainingProgrammeID,
            ImageAttach: x?.ImageAttach,
            paymentReceipt: x?.paymentReceipt,
            Modeofpayment: x?.Modeofpayment,
            TranscationNo: x?.TranscationNo,
            BankName: x?.BankName,
            TranscationDate: x?.TranscationDate
        });
        setIsImageAttach(x?.ImageAttach);
        setIsPaymentReceipt(x?.paymentReceipt)
        const selectedProgram = programData?.find(program => program.HandsOnTrainingProgrammeID === x?.HandsOnTrainingProgrammeID);
        if (selectedProgram) {
            const { FromDate, ToDate, Location, Fee, NameoftheProgram } = selectedProgram;
            let programDetailsname = { FromDate, ToDate, Location, Fee, NameoftheProgram };
            setProgramDetails(programDetailsname)
            console.log("Selected program Detailsname", programDetailsname);
        }
        setIsEdit(true);
        setTimeout(() => {
            setIsRegiterAdd(true);
            setIsLoading(false)

        }, 2000);

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setIsRegiterAdd(true)
                        }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>S.No</TableCell>
                                    <TableCell align='left' style={{ width: '160px' }}>Programme Name</TableCell>
                                    <TableCell align='left'>Full Name</TableCell>
                                    <TableCell align='left'>Trainee Type</TableCell>
                                    <TableCell align='left' style={{ width: '150px' }}>Institue Affiliation</TableCell>
                                    <TableCell align='left' style={{ width: '130px' }}>Email</TableCell>
                                    <TableCell align='left' >Phone No</TableCell>
                                    <TableCell align='left'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {registrationData && registrationData.length != 0 ? (
                                    registrationData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow key={index + 1}>
                                                <TableCell align='left'>{index + 1}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.NameoftheProgram}>
                                                        <span>
                                                            {x?.NameoftheProgram ? x?.NameoftheProgram.length > 20 ? `${x?.NameoftheProgram.slice(0, 20)} ...` : x?.NameoftheProgram : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left'>{x?.TraineeName ? x?.TraineeName : '-'} </TableCell>
                                                <TableCell align='left'>{x?.tranierDetails ? x?.tranierDetails : '-'} </TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title={x?.InstitueAffiliation}>
                                                        <span>
                                                            {x?.InstitueAffiliation ? x?.InstitueAffiliation.length > 20 ? `${x?.InstitueAffiliation.slice(0, 20)} ...` : x?.InstitueAffiliation : ''}
                                                            {' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left' >{x?.email ? x?.email : '-'} </TableCell>
                                                <TableCell align='left'>{x?.phone ? x.phone : '-'}</TableCell>
                                                <TableCell align='left'>
                                                    <Tooltip title="View">
                                                        <VisibilityIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setViewRegData(x);
                                                                setIsRegView(true)
                                                            }}
                                                        />

                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <EditIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => EditRowData(x)}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {registrationData && registrationData.length != 0 && registrationData.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={registrationData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>


            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isRegisterAdd}
                id="income"
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none',
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title" onClose={(e => {
                    setIsRegiterAdd(false)
                    setPayload({
                        trainerName: "",
                        InstitueAffiliation: "",
                        email: "",
                        gender: "",
                        dateOfBirth: null,
                        phone: "",
                        line_1: "",
                        line_2: "",
                        line_3: "",
                        district: "",
                        state: "",
                        country: "India",
                        tranierDetails: "",
                        HigherQualification: "",
                        Subjects: "",
                        YOP: null,
                        ClassRank: "",
                        Institution: "",
                        HandsOnTrainingProgrammeID: "",
                        ImageAttach: "",
                        paymentReceipt: "",
                        Modeofpayment: "",
                        TranscationNo: "",
                        BankName: "",
                        TranscationDate: null
                    });
                    setIsImageAttach('');
                    setIsPaymentReceipt('');
                })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                    Add Registration
                </DialogTitle>
                <Loader isLoading={isLoading} />
                <Formik
                    initialValues={{
                        trainerName: "",
                        InstitueAffiliation: "",
                        email: "",
                        gender: "",
                        dateOfBirth: "",
                        phone: "",
                        line_1: "",
                        line_2: "",
                        line_3: "",
                        district: "",
                        state: "",
                        country: "India",
                        tranierDetails: "",
                        HigherQualification: "",
                        Subjects: "",
                        YOP: "",
                        ClassRank: "",
                        Institution: "",
                        HandsOnTrainingProgrammeID: "",
                        ImageAttach: "",
                        paymentReceipt: "",
                        Modeofpayment: "",
                        TranscationNo: "",
                        BankName: "",
                        TranscationDate: ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            HandsOnTrainingProgrammeID: Yup.string().required('Program Name Required'),
                            email: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email),
                            HigherQualification: Yup.string().required('Higher Qualification Required'),
                            InstitueAffiliation: Yup.string().required('Institue Affiliation Required'),
                            trainerName: Yup.string().required('Trainee Required'),
                            line_1: Yup.string().required('Address line_1 Required'),
                            district: Yup.string().required('District Required'),
                            state: Yup.string().required('state Required'),
                            phone: Yup.string().required("Mobile Number is required"),
                            gender: Yup.string().required("Gender is required"),
                            dateOfBirth: Yup.string().required("Date of Birth is required"),
                            Modeofpayment: Yup.string().required("Mode of Payment is required"),
                            TranscationNo: Yup.string().required("Transcation No is required"),
                            BankName: Yup.string().required("Bank Name is required"),
                            TranscationDate: Yup.string().required("Transcation Date is required"),
                            YOP: Yup.string().required("Year of Passing is required"),
                        })
                    }
                    onSubmit={(values, { resetForm }) => {
                        if (isEdit) {
                            console.log("values", values);
                        } else {
                            let payloadAdd = {
                                TraineeName: payload?.trainerName,
                                InstitueAffiliation: payload?.InstitueAffiliation,
                                email: payload?.email,
                                gender: payload?.gender,
                                dateOfBirth: payload?.dateOfBirth,
                                phone: payload?.phone,
                                line_1: payload?.line_1,
                                line_2: payload?.line_2,
                                line_3: payload?.line_3,
                                district: payload?.district,
                                state: payload?.state,
                                country: payload?.country,
                                tranierDetails: payload?.tranierDetails,
                                HigherQualification: payload.HigherQualification,
                                Subjects: payload?.Subjects,
                                YOP: payload?.YOP,
                                ClassRank: payload.ClassRank,
                                Institution: payload?.Institution,
                                HandsOnTrainingProgrammeID: payload?.HandsOnTrainingProgrammeID,
                                ImageAttach: isImageAttach,
                                paymentReceipt: ispaymentReceipt,
                                Modeofpayment: payload?.Modeofpayment,
                                TranscationNo: payload?.TranscationNo,
                                BankName: payload?.BankName,
                                TranscationDate: payload?.TranscationDate
                            }
                            if (payloadAdd.ImageAttach == '') {
                                setIsPhotoError(true);
                                return;
                            }
                            if (payloadAdd.paymentReceipt == '') {
                                setIsPaymentError(true);
                                return;
                            }
                            setIsLoading(true)
                            console.log("payloadAdd", payloadAdd);
                            APIKit.post(RGCA_URLS.createTrainer, payloadAdd)
                                .then(res => {
                                    if (res.data.status === 200) {
                                        setPayload({
                                            trainerName: "",
                                            InstitueAffiliation: "",
                                            email: "",
                                            gender: "",
                                            dateOfBirth: null,
                                            phone: "",
                                            line_1: "",
                                            line_2: "",
                                            line_3: "",
                                            district: "",
                                            state: "",
                                            country: "India",
                                            tranierDetails: "",
                                            HigherQualification: "India",
                                            Subjects: "",
                                            YOP: null,
                                            ClassRank: "",
                                            Institution: "",
                                            HandsOnTrainingProgrammeID: "",
                                            ImageAttach: "",
                                            paymentReceipt: "",
                                            Modeofpayment: "",
                                            TranscationNo: "",
                                            BankName: "",
                                            TranscationDate: null
                                        });
                                        resetForm()
                                        setIsRegiterAdd(false);
                                        getregistrationData();
                                        setIsLoading(false);
                                        toast.success(res.data.message, {
                                            position: 'top-right',
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined
                                        });
                                    } else {
                                        setIsLoading(false);
                                        toast.error(res.data.message, {
                                            position: 'top-right',
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined
                                        });
                                    }
                                })
                                .catch(function (e) {
                                    setIsLoading(false);
                                    console.log('kkk', e);
                                });
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        handleReset,
                        isSubmitting,
                        touched,
                        values,
                        setValues,
                        resetForm
                    }) => (
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <DialogContent>
                                <Box mt={3} style={{
                                    backgroundColor: '#fff',
                                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                }}>
                                    <Grid container spacing={3} >
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                error={Boolean(touched.HandsOnTrainingProgrammeID && errors.HandsOnTrainingProgrammeID)}
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Select Programme
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="HandsOnTrainingProgrammeID"
                                                    onBlur={handleBlur}
                                                    disabled={isEdit}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.HandsOnTrainingProgrammeID = e.target.value;
                                                        setPayload({ ...p });
                                                        const selectedProgram = programData?.find(program => program.HandsOnTrainingProgrammeID === e.target.value);
                                                        if (selectedProgram) {
                                                            const { FromDate, ToDate, Location, Fee, NameoftheProgram } = selectedProgram;
                                                            let programDetailsname = { FromDate, ToDate, Location, Fee, NameoftheProgram };
                                                            setProgramDetails(programDetailsname)
                                                            console.log("Selected program Detailsname", programDetailsname);
                                                        }
                                                    }}
                                                    value={payload?.HandsOnTrainingProgrammeID}
                                                    label="Programme Name"
                                                >
                                                    {programData &&
                                                        programData.map(x => {
                                                            return (
                                                                <MenuItem value={x.HandsOnTrainingProgrammeID} key={x?.HandsOnTrainingProgrammeID}>
                                                                    {x.NameoftheProgram}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors.HandsOnTrainingProgrammeID && touched.HandsOnTrainingProgrammeID && errors.HandsOnTrainingProgrammeID}
                                                </FormHelperText>
                                            </FormControl>

                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            {payload?.HandsOnTrainingProgrammeID &&
                                                <Grid container spacing={3}>
                                                    <b>  Program Details -  </b>
                                                    <Typography variant="body1" gutterBottom style={{ paddingLeft: '10px' }}>
                                                        <span style={{ color: '#222A45', fontWeight: 'bold' }}>Program Name </span> : {programDetails?.NameoftheProgram} <br />
                                                        <span style={{ color: '#222A45', fontWeight: 'bold' }}>From Date </span>: {programDetails?.FromDate ? moment(programDetails?.FromDate).format('DD-MM-yyyy') : ''}
                                                        <span style={{ color: '#222A45', fontWeight: 'bold', paddingLeft: '10px' }}>To Date </span>: {programDetails?.ToDate ? moment(programDetails?.ToDate).format('DD-MM-yyyy') : ''}<br />
                                                        <span style={{ color: '#222A45', fontWeight: 'bold' }}>Location </span>: {programDetails?.Location}
                                                        <span style={{ color: '#222A45', fontWeight: 'bold', paddingLeft: '10px' }}>Fee </span>: ₹  {programDetails?.Fee ? currencyFormat1(Math.round(programDetails?.Fee)) : ''}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Full Name"
                                                name="trainerName"
                                                value={payload.trainerName}
                                                inputProps={{ maxLength: 100 }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.trainerName = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.trainerName && errors.trainerName)}
                                                helperText={touched.trainerName && errors.trainerName}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Institue Affiliation"
                                                name="InstitueAffiliation"
                                                value={payload.InstitueAffiliation}
                                                inputProps={{ maxLength: 200 }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.InstitueAffiliation = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                error={Boolean(touched.InstitueAffiliation && errors.InstitueAffiliation)}
                                                helperText={touched.InstitueAffiliation && errors.InstitueAffiliation}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Trainee Type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="tranierDetails"
                                                    disabled={isEdit}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.tranierDetails = e.target.value;
                                                        setPayload({ ...p });
                                                    }}
                                                    value={payload.tranierDetails}
                                                    label="Trainee Type"
                                                >
                                                    <MenuItem value="Farmer">Farmer</MenuItem>
                                                    <MenuItem value="Entrepreneur">Entrepreneur</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                inputProps={{ maxLength: 100 }}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.email = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                required
                                                value={payload.email}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                error={Boolean(touched.gender && errors.gender)}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Gender
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="gender*"
                                                    onBlur={handleBlur}
                                                    required
                                                    disabled={isEdit}
                                                    onChange={(e) => {
                                                        // handleChange(e);
                                                        const p = payload;
                                                        p.gender = e.target.value;
                                                        setValues({ ...values, gender: e.target.value })
                                                        setPayload({ ...p });
                                                    }}
                                                    value={payload.gender}
                                                    label="Gender"
                                                >
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>

                                                </Select>
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors.gender && touched.gender && errors.gender}
                                                </FormHelperText>
                                            </FormControl>

                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="Date Of Birth"
                                                format="dd/MM/yyyy"
                                                disableFuture={true}
                                                disabled={isEdit}
                                                maxDate={moment(new Date()).subtract(18, 'years')}
                                                error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="dateOfBirth"
                                                required
                                                onChange={(e) => {
                                                    console.log("ooo", e);
                                                    const p = payload;
                                                    p.dateOfBirth = e;
                                                    setPayload({ ...p });
                                                    setValues({ ...values, dateOfBirth: e })
                                                }}
                                                value={payload.dateOfBirth}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                            <FormHelperText style={{ color: 'red' }}>
                                                {errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
                                            </FormHelperText>
                                        </Grid>

                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                label="Address Line 1"
                                                name="line_1"
                                                required
                                                error={Boolean(touched.line_1 && errors.line_1)}
                                                helperText={touched.line_1 && errors.line_1}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.line_1 = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.line_1}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                label="Address Line 2"
                                                name="line_2"
                                                error={Boolean(touched.line_2 && errors.line_2)}
                                                helperText={touched.line_2 && errors.line_2}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.line_2 = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.line_2}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                label="Address Line 3"
                                                name="line_3"
                                                error={Boolean(touched.line_3 && errors.line_3)}
                                                helperText={touched.line_2 && errors.line_3}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.line_3 = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.line_3}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item lg={4} sm={6} xl={6} xs={6}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                error={Boolean(touched.state && errors.state)}
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    required
                                                >
                                                    State
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="State"
                                                    name="state"
                                                    disabled={isEdit}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        let p = payload;
                                                        p.state = e.target.value;
                                                        setPayload({ ...p });
                                                        const stateData = StatesAndDis.find((s) => s.state === e.target.value);
                                                        console.log("lllll", stateData);
                                                        setDistricts(stateData ? stateData.districts : []);
                                                    }}
                                                    required
                                                    value={payload?.state}
                                                >
                                                    {StatesAndDis &&
                                                        StatesAndDis.map(x => {
                                                            return (
                                                                <MenuItem value={x.state} key={x.state}>
                                                                    {x.state}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                <FormHelperText>
                                                    {errors.state &&
                                                        touched.state &&
                                                        errors.state}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={4} sm={6} xl={6} xs={6}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                error={Boolean(touched.district && errors.district)}
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    required
                                                >
                                                    District
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="District"
                                                    name="district"
                                                    disabled={isEdit}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        let p = payload;
                                                        p.district = e.target.value;
                                                        setPayload({ ...p });
                                                    }}
                                                    required
                                                    value={payload?.district}
                                                >
                                                    {districts &&
                                                        districts.map(x => {
                                                            return (
                                                                <MenuItem value={x} key={x}>
                                                                    {x}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                <FormHelperText>
                                                    {errors.district &&
                                                        touched.district &&
                                                        errors.district}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        {/* <Grid item lg={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 50 }}
                                                label="District"
                                                name="district"
                                                error={Boolean(touched.district && errors.district)}
                                                helperText={touched.district && errors.district}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                required
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.district = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.district}
                                                variant="outlined"
                                            />

                                        </Grid>

                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 50 }}
                                                label="State"
                                                name="state"
                                                error={Boolean(touched.state && errors.state)}
                                                helperText={touched.state && errors.state}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                required
                                                onBlur={handleBlur}
                                                disabled={isEdit}

                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.state = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.state}
                                                variant="outlined"
                                            />
                                        </Grid> */}

                                        <Grid item lg={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 50 }}
                                                label="Country"
                                                name="country"
                                                error={Boolean(touched.country && errors.country)}
                                                helperText={touched.country && errors.country}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={true}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.country = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.country}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.phone && errors.phone)}
                                                helperText={touched.phone && errors.phone}
                                                value={payload.phone}
                                                onBlur={handleBlur}
                                                label="Mobile Number"
                                                inputProps={{ maxLength: 10 }}
                                                disabled={isEdit}
                                                name="phone"
                                                onChange={e => {
                                                    const re = /^[0-9\b]+$/;
                                                    // if value is not blank, then test the regex
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.phone = e.target.value;
                                                        values.phone = e.target.value;
                                                        setPayload({ ...p });
                                                    }
                                                }}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item lg={4} sm={12} xl={12} xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={8} style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px', alignItems: 'center' }} >
                                                    <p>RECENT PHOTO Attachment</p>
                                                </Grid>
                                                <Grid item lg={4} style={{ padding: '0px' }}>
                                                    <Tooltip title="You can only upload pdf,png and jpg files">
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item lg={12} style={{ padding: '0px', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        style={{ width: "75%", height: "55px" }}
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        {!isImageAttach ? "Attach Here " : null}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => { handleUploadAttach(e, "ImageAttach", "add") }}
                                                        />
                                                        {isImageAttach && (
                                                            <a style={{
                                                                color: "blue",
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                marginTop: "9px",
                                                            }}
                                                                href={isImageAttach} target="_blank">
                                                                {isImageAttach?.substring(4, 25)}....{isImageAttach?.split(".").pop()}
                                                            </a>
                                                        )}
                                                    </Button>
                                                    {isImageAttach &&
                                                        <ClearIcon onClick={() => {
                                                            setIsImageAttach('')
                                                        }} style={{ cursor: 'pointer' }} />
                                                    }
                                                    {isPhotoError && (
                                                        <FormHelperText style={{ color: 'red' }}>
                                                            Please add Recent Photo
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                error={Boolean(touched.Modeofpayment && errors.Modeofpayment)}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Mode of payment
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="Modeofpayment"
                                                    onBlur={handleBlur}
                                                    required
                                                    disabled={isEdit}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.Modeofpayment = e.target.value;
                                                        p.BankName = '';
                                                        p.TranscationNo = '';
                                                        setPayload({ ...p });
                                                    }}
                                                    value={payload.Modeofpayment}
                                                    label="Mode of payment"
                                                >
                                                    <MenuItem value="UPI">UPI</MenuItem>
                                                    <MenuItem value="DD">On-line Transfer</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormHelperText style={{ color: 'red' }}>
                                                {errors.Modeofpayment && touched.Modeofpayment && errors.Modeofpayment}
                                            </FormHelperText>

                                        </Grid>
                                        {/* {payload.Modeofpayment == "UPI" || payload.Modeofpayment == "DD" && <> */}
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 40 }}
                                                label={payload?.Modeofpayment == 'UPI' ? "UPI Type" : "BankName"}
                                                name="BankName"
                                                disabled={isEdit}
                                                onChange={e => {
                                                    // if (
                                                    //     e.target.value === '' ||
                                                    //     regEx.numbersOnly.test(e.target.value)
                                                    // ) {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.BankName = e.target.value;
                                                    setPayload({ ...p });
                                                    // }
                                                }}
                                                value={payload.BankName}
                                                variant="outlined"
                                                required
                                                error={Boolean(touched.BankName && errors.BankName)}
                                                helperText={touched.BankName && errors.BankName}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 40 }}
                                                label={payload?.Modeofpayment == 'UPI' ? "Transaction Number" : "DD Number"}
                                                name="TranscationNo"
                                                disabled={isEdit}
                                                onChange={e => {
                                                    if (
                                                        e.target.value === '' ||
                                                        regEx.numbersOnly.test(e.target.value)
                                                    ) {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.TranscationNo = e.target.value;
                                                        setPayload({ ...p });
                                                    }
                                                }}
                                                value={payload.TranscationNo}
                                                required
                                                variant="outlined"
                                                error={Boolean(touched.TranscationNo && errors.TranscationNo)}
                                                helperText={touched.TranscationNo && errors.TranscationNo}
                                            />
                                        </Grid>
                                        {/* </>} */}

                                        <Grid item md={4} xs={12}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="Transcation Date"
                                                format="dd/MM/yyyy"
                                                disablePast={true}
                                                error={Boolean(touched.TranscationDate && errors.TranscationDate)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="TranscationDate"
                                                required
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    console.log("ooo", e);
                                                    const p = payload;
                                                    p.TranscationDate = e;
                                                    setPayload({ ...p });
                                                    setValues({ ...values, TranscationDate: e })
                                                }}
                                                value={payload.TranscationDate ?? null}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                            <FormHelperText style={{ color: 'red' }}>
                                                {errors.TranscationDate && touched.TranscationDate && errors.TranscationDate}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item lg={4} sm={12} xl={12} xs={12}>
                                            <Typography>Payment Receipt</Typography>
                                            <Button
                                                style={{ width: "85%", height: "55px" }}
                                                variant="contained"
                                                component="label"
                                            >
                                                {!ispaymentReceipt ? "Attach Here " : null}
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => { handleUploadAttach(e, "PaymentReceipt", "add") }}
                                                />
                                                {ispaymentReceipt && (
                                                    <a style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        marginTop: "9px",
                                                    }}
                                                        href={ispaymentReceipt} target="_blank">
                                                        {ispaymentReceipt?.substring(4, 25)}....{ispaymentReceipt?.split(".").pop()}
                                                    </a>
                                                )}
                                            </Button>
                                            {ispaymentReceipt &&
                                                <ClearIcon onClick={() => {
                                                    setIsPaymentReceipt('')
                                                }} style={{ cursor: 'pointer' }} />
                                            }
                                            {isPaymentError && (
                                                <FormHelperText style={{ color: 'red' }}>
                                                    Please add Payment Receipt
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    mt={3}
                                    style={{
                                        backgroundColor: '#fff',
                                        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                        borderRadius: '5px',
                                        padding: '20px'
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}
                                            style={{
                                                marginTop: '0px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold'
                                            }}
                                        >
                                            Education Details
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} />
                                    </Grid>
                                    <Grid container spacing={3} style={{ textAlign: 'left' }} >
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                error={Boolean(touched.HigherQualification && errors.HigherQualification)}
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Higher Qualification
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="HigherQualification"
                                                    onBlur={handleBlur}
                                                    disabled={isEdit}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        let p = payload;
                                                        p.HigherQualification = e.target.value;
                                                        setPayload({ ...p });
                                                    }}
                                                    value={payload?.HigherQualification}
                                                    label="Higher Qualification"
                                                >
                                                    {HigherQualificationList &&
                                                        HigherQualificationList.map(x => {
                                                            return (<MenuItem value={x.value} key={x?.value}>{x.name}</MenuItem>);
                                                        })}
                                                </Select>
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors.HigherQualification && touched.HigherQualification && errors.HigherQualification}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                label="Subjects"
                                                name="Subjects"
                                                error={Boolean(touched.Subjects && errors.Subjects)}
                                                helperText={touched.Subjects && errors.Subjects}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.Subjects = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.Subjects}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 10 }}
                                                label="Class Rank"
                                                name="ClassRank"
                                                error={Boolean(touched.ClassRank && errors.ClassRank)}
                                                helperText={touched.ClassRank && errors.ClassRank}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.ClassRank = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.ClassRank}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 50 }}
                                                label="Institution"
                                                name="Institution"
                                                error={Boolean(touched.Institution && errors.Institution)}
                                                helperText={touched.Institution && errors.Institution}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isEdit}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let p = payload;
                                                    p.Institution = e.target.value;
                                                    setPayload({ ...p });
                                                }}
                                                value={payload.Institution}
                                                variant="outlined"
                                            />

                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Is Pursuing / Completed
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="isPurchasingorCompleted"
                                                    onChange={e => {
                                                        handleChange(e);
                                                        const p = payload;
                                                        p.isPurchasingorCompleted = e.target.value;

                                                        setPayload({ ...p });
                                                    }}
                                                    value={payload.isPurchasingorCompleted}
                                                    label=" Is Pursuing / Completed"
                                                >
                                                    <MenuItem value="Pursuing">Pursuing </MenuItem>
                                                    <MenuItem value="Completed">Completed</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item lg={4} xs={12} style={{ marginTop: '0%' }}>
                                            <KeyboardDatePicker
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                variant="outlined"
                                                id="date-picker-dialog"
                                                label="Year Of Passing"
                                                format="yyyy"
                                                views={['year']}
                                                disableFuture={payload.isPurchasingorCompleted == 'Completed' ? true : false}
                                                maxDate={new Date('2028-12-31')}
                                                error={Boolean(touched.YOP && errors.YOP)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="YOP"
                                                required
                                                // disabled={isEdit}
                                                onChange={(e) => {
                                                    console.log("ooo", e);
                                                    const p = payload;
                                                    p.YOP = e;
                                                    setPayload({ ...p });
                                                    setValues({ ...values, YOP: e })
                                                }}
                                                value={payload?.YOP}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                            <FormHelperText style={{ color: 'red' }}>
                                                {errors.YOP && touched.YOP && errors.YOP}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                                <Button onClick={(e => {
                                    resetForm();
                                    setPayload({
                                        trainerName: "",
                                        InstitueAffiliation: "",
                                        email: "",
                                        gender: "",
                                        dateOfBirth: null,
                                        phone: "",
                                        line_1: "",
                                        line_2: "",
                                        line_3: "",
                                        district: "",
                                        state: "",
                                        country: "India",
                                        tranierDetails: "",
                                        HigherQualification: "",
                                        Subjects: "",
                                        YOP: null,
                                        ClassRank: "",
                                        Institution: "",
                                        HandsOnTrainingProgrammeID: "",
                                        ImageAttach: "",
                                        paymentReceipt: "",
                                        Modeofpayment: "",
                                        TranscationNo: "",
                                        BankName: "",
                                        TranscationDate: null
                                    });
                                    setIsImageAttach('');
                                    setIsPaymentReceipt('');
                                    setIsRegiterAdd(false);

                                })} className={classes.button2} >
                                    Cancel
                                </Button>
                                {isEdit ?
                                    <Button type="submit" className={classes.button2}>
                                        Update
                                    </Button> :
                                    <Button type="submit" className={classes.button2}>
                                        Save
                                    </Button>
                                }
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <RegistrationView setIsRegView={setIsRegView} isRegView={isRegView} viewRegData={viewRegData} />
        </>
    )
}

export default Registration