import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Container,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Box,
  FormHelperText
} from '@material-ui/core';
import Page from 'src/components/Page';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import { statusColor } from 'src/utils/helpers';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';

function generate(element) {
  return [0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value
    })
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  icon: {
    fill: 'green'
  },
  text: {
    fill: 'white'
  },
  stepIcon: {
    color: '#fff'
  },
  grid: {
    // border: "1px solid grey",
  },
  space: {
    '@media (max-width: 600px)': {
      marginLeft: "90px"
    },
    [theme.breakpoints.down('xs')]: {
      // paddingLeft:"100px"
    }
  }
}));

const ApprovalConfig = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [secondary, setSecondary] = React.useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [approvalTypes, setApprovalTypes] = useState([]);
  const [masterRoles, setMasterRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);
  const [stepsView, setStepsView] = useState([]);
  const [allDesignation, setAllDesignations] = useState([]);

  const [payLoad, setPayLoad] = useState({
    projectId: null,
    approvalID: null
  });

  useEffect(() => {
    getAllProjects();
    getAllAccessRoles();
    getAllDesignation();
  }, []);

  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects, {
      searchText: '' // optional
    })
      .then(res => {
        if (res.data.status === 200) {
          setAllProjects(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const typeOfApproval = projectID => {
    // console.log("-------",projectID)
    APIKit.get(RGCA_URLS.approvalMasterList + projectID)
      .then(res => {
        if (res.data.status === 200) {
          setApprovalTypes(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };
  const getAllAccessRoles = () => {
    
    APIKit.get(RGCA_URLS.getRoles)
      .then(res => {
        if (res.data.status === 200) {
          setMasterRoles(res.data.data);
          setCurrentRole(res.data.data)
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const getAllDesignation = () => {
    APIKit.get(RGCA_URLS.getDesignations)
      .then(res => {
        if (res.data.status === 200) {
          setAllDesignations(res.data.data);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const getSelectedRole = approvalID => {
    
    console.log(approvalID);
    APIKit.get(RGCA_URLS.getApprovalList + approvalID)
      .then(res => {
        if (res.data.status === 200) {
          console.log("eee", res.data.data);
          // let selected = res.data.data
          //   let selected = masterRoles.filter(x =>{
          //     return res.data.data.find(y => x.accessRoleID === y.accessRoleID)

          // });

          let selected = res.data.data.map((selectedObj) =>
            masterRoles.find((masterObj) => masterObj.accessRoleID === selectedObj.accessRoleID)
          );
          console.log('selected', selected);
          // selected = selected
          //   .map(x => {
          //     let OrderID = res.data.data.find(
          //       y => x.accessRoleID === y.accessRoleID
          //     ).OrderID;
          //     return { ...x, OrderID };
          //   })
          //   .sort((a, b) => {
          //     return a.OrderID - b.OrderID;
          //   });
         
          setSelectRoles([...selected]);
          // setCurrentRole([
          //   ...masterRoles.filter(
          //     x => !res.data.data.find(y => x.accessRoleID === y.accessRoleID)
          //   )
          // ]);
          // setCurrentRole([
          //   ...masterRoles
          // ]);
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  const showNumbers = index => {
    let obj = {
      1: '1st Person',
      2: '2nd Person',
      3: '3rd Person'
    };

    return obj[index] || `${index}th Person`;
  };
  const steps = () => {
    let results = selectRoles
    // .map((x, index) => {
    //   return {
    //     label: x.name,
    //     status: showNumbers(index + 1)
    //   };
    // });
    console.log('steps',results);
    return results;
  };
  const updateConfig = ({ approvalID, list }) => {
    let payload = {
      approvalID,
      list
    };
    APIKit.post(RGCA_URLS.addApproval, payload)
      .then(res => {
        if (res.data.status === 200) {
          addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) { });
  };

  return (
    <Page className={classes.root} title="Approvals">
      <ToastContainer />
      <Container maxWidth={false}>
        <Typography>
          <h2
            style={{
              marginTop: '10px',
              color: '#394671',
              fontSize: '17px',
              fontWeight: 'bold',

            }}
          >
            Approval Config{' '}
          </h2>
        </Typography>
        <Grid container spacing={3}>
          {/* header */}
          <Grid item md={3} xs={3}></Grid>
          <Grid item md={3} xs={3}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Project
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="designationID"
                onChange={e => {
                  typeOfApproval(e.target.value);
                  setApprovalTypes([]);
                  // setCurrentRole([]);
                  // setSelectRoles([]);
                  const p = payLoad;
                  p.projectId = e.target.value;
                  setPayLoad({
                    ...p
                  });
                }}
                required
                value={payLoad.projectId}
                label="Select Project"
              >
                {/* <MenuItem value="0">Yes</MenuItem>
                                <MenuItem value="1">No</MenuItem> */}
                {allProjects &&
                  allProjects.map(x => {
                    return (
                      <MenuItem value={x.projectID} key={x.projectID}>
                        {x.projectName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {/* <FormHelperText>Please Select Project</FormHelperText> */}
            </FormControl>
          </Grid>
          {payLoad.projectId && payLoad.projectId !== '' ? (
            <Grid item md={3} xs={3}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Approval Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="designationID"
                  onChange={e => {
                    // setApprovalType({ ...approvalTypes.find(x => x.approvalID === e.target.value) })
                    const p = payLoad;
                    p.approvalID = e.target.value;
                    setPayLoad({
                      ...p
                    });
                    // setMasterRoles([])
                    // setCurrentRole([]);
                    // setSelectRoles([]);
                    getSelectedRole(payLoad.approvalID);
                  }}
                  required
                  value={payLoad.approvalID}
                  label="approvaltype"
                >
                  {approvalTypes &&
                    approvalTypes.map(x => {
                      return (
                        <MenuItem value={x.approvalID} key={x.approvalID}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          <Grid item md={3} xs={3}></Grid>
          {((payLoad.projectId && payLoad.approvalID) ||
            selectRoles.length != 0) && (
              <>
                <Grid item xs={6} md={4} className={classes.grid}>
                  <Typography
                    sx={{ mt: 4, mb: 2 }}
                    align="center"
                    variant="h6"
                    component="div"
                  >
                    Role
                  </Typography>
                  <Demo>
                    <List>
                      {currentRole.map((row, index) => (
                        <>
                          {/* {!row.name.startsWith('Director') ? ( */}
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={row.name}
                              secondary={secondary ? 'Secondary text' : null}
                            />
                            <span className={classes.space}>
                              <IconButton edge="end" size="small" >
                                <Avatar >
                                  <ArrowForwardIosIcon
                                    onClick={e => {
                                      
                                      let items = currentRole;
                                      let selected = selectRoles;
                                      if (items[index].accessRoleID !== selectRoles[selectRoles.length - 1]?.accessRoleID) {
                                        selected.push(items[index]);
                                        // console.log(selected);
                                        // console.log('11',selectRoles[selectRoles.length - 2].accessRoleID);
                                        console.log('12', items[index].accessRoleID);
                                        // items = items.filter(
                                        //   (item, i) => index !== i
                                        // );
                                        setCurrentRole(items);
                                        setSelectRoles(selected);
                                        updateConfig({
                                          approvalID: payLoad.approvalID,
                                          list: selected.map(x => {
                                            return { accessRoleID: x.accessRoleID };
                                          })
                                        });

                                      } else {
                                        addToast("Please add another role instead of current role", { appearance: 'warning' });
                                      }
                                    }}
                                  />
                                </Avatar>
                              </IconButton>
                            </span>
                          </ListItem>
                          {/* ) : (
                          <></>
                        )
                        } */}
                        </>
                      ))}
                    </List>
                  </Demo>
                </Grid>

                {/*  */}
                <Grid item xs={6} md={4} className={classes.grid}>
                  <Typography
                    sx={{ mt: 4, mb: 2 }}
                    align="center"
                    variant="h6"
                    component="div"
                  >
                    Selected Role
                  </Typography>
                  {selectRoles.length != 0 ? (
                    <Demo>
                      <List>
                        {selectRoles.map((row, index) => (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ArrowBackIosIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    let items = currentRole;
                                    let selected = selectRoles;
                                    items.push(selected[index]);
                                    selected = selected.filter(
                                      (item, i) => index !== i
                                    );
                                    setCurrentRole(items);
                                    setSelectRoles(selected);
                                    updateConfig({
                                      approvalID: payLoad.approvalID,
                                      list: selected.map(x => {
                                        return { accessRoleID: x.accessRoleID };
                                      })
                                    });
                                  }}
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={row.name}
                              secondary={showNumbers(index + 1)}
                            />
                            <span className={classes.space}>
                              <IconButton edge="end" size="small">
                                {index !== 0 && (
                                  <Avatar>
                                    <ArrowUpwardIcon
                                      onClick={e => {
                                        
                                        let items = selectRoles;
                                        let temp = items[index - 1];
                                        items[index - 1] = items[index];
                                        items[index] = temp;
                                        setSelectRoles([...items]);
                                        updateConfig({
                                          approvalID: payLoad.approvalID,
                                          list: [...items].map(x => {
                                            return { accessRoleID: x.accessRoleID };
                                          })
                                        });
                                      }}
                                    />

                                    {/* <DeleteIcon /> */}
                                  </Avatar>
                                )}
                                {index !== selectRoles.length - 1 && (
                                  <Avatar>
                                    <ArrowDownwardIcon
                                      onClick={e => {
                                        
                                        let items = selectRoles;
                                        let temp = items[index + 1];
                                        items[index + 1] = items[index];
                                        items[index] = temp;
                                        setSelectRoles([...items]);
                                        updateConfig({
                                          approvalID: payLoad.approvalID,
                                          list: [...items].map(x => {
                                            return { accessRoleID: x.accessRoleID };
                                          })
                                        });
                                      }}
                                    />
                                    {/* <DeleteIcon /> */}
                                  </Avatar>
                                )}
                              </IconButton>
                            </span>
                          </ListItem>
                        ))}
                      </List>
                    </Demo>
                  ) : null}
                </Grid>
                {/*  */}

                <Grid item xs={6} md={4} className={classes.grid}>
                  <Typography
                    sx={{ mt: 4, mb: 2 }}
                    align="center"
                    variant="h6"
                    component="div"
                  >
                    Example
                  </Typography>
                  {steps().length != 0 ? (
                    <Demo>
                      <Box>
                        <Stepper
                          activeStep={steps().length}
                          orientation="vertical"
                        >
                          {steps().map((step, index) => (
                            <Step
                              key={index+1}
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active
                              }}
                            >
                              <StepLabel
                                optional={
                                  index < steps().length + 1 ? (
                                    <>
                                      {' '}
                                      <Typography
                                        style={{
                                          color: statusColor(showNumbers(index + 1))
                                        }}
                                        component="p"
                                        variant="caption"
                                      >
                                        {showNumbers(index + 1)}
                                      </Typography>
                                      <Typography component="p" variant="caption">
                                        {step.description}
                                      </Typography>
                                    </>
                                  ) : null
                                }
                                StepIconProps={{
                                  classes: {
                                    active: classes.icon,
                                    text: classes.text,
                                    completed: classes.icon
                                  }
                                }}
                              >
                                {step.name}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </Demo>
                  ) : null}
                </Grid>
              </>
            )}
          {/* header */}
        </Grid>
      </Container>
    </Page>
  );
};

export default ApprovalConfig;
