import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Button,
  TextField
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import Loader from '../../loader';
import { createTheme } from '@material-ui/core/styles';
import 'src/views/AddProject/style.css';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { MESSAGE } from 'src/utils/message';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProjectDetails } from '../Common/utilities';
import CommonSelect from '../Common/CommonSelect';
import CommonTable from '../Common/CommonTable';
import { ETTypes, ETaction } from '../Common/Types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { values } from 'lodash';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    margin: theme.spacing(1)
  },
  field: {
    margin: theme.spacing(2),
    '@media (min-width: 320px)': {
      margin: theme.spacing(-1)
    }
  },
  search:{
    [theme.breakpoints.down('xl')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop:'5px'
    },
  },
  select:{
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      // marginTop:'5px'
    }
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const StoreNewIndent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { project } = useParams();
  const [emplyoees, setEmplyoees] = useState([]);
  const [indexData, setIndexData] = useState(null);
  const navigate = useNavigate();
  const confirm = useConfirm();
  const projectDetails = { ...getProjectDetails(project) };
  const [inventoryList, setinventoryList] = useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [edit, setEdit] = React.useState(false);
  const [storeNewIndentData, setStoreNewIndentData] = useState([]);
  const [addItemBtn, setAddItemBtn] = useState(false);
  const [objAddData, setObjAddData] = useState({
    val: {},
    materialList: [],
    itemID: '',
    itemName: '',
    uom: '',
    Qty: '',
    lastPrice: '',
    availabilityQty: '',
    comment: '',
    location: projectDetails?.location ? projectDetails?.location : ''
  });

  const [estimatedPrice, setEstimatedPrice] = useState(0);

  // const [addItemLoad, setaddItemLoad] = useState({
  //   "projectID":1,
  //   "employeeID": 1,
  //   "items":[
  //      {
  //         "itemID":materialList.itemID,
  //         "Qty":objAddData.Qty,
  //         "location": objAddData.location,
  //         "comment":""
  //      },
  //      {
  //       "itemID":objAddData.itemID,
  //       "Qty":objAddData.Qty,
  //       "location": objAddData.location,
  //       "comment":""
  //      }
  //   ]
  // })
  const [payload, setPayload] = useState({
    projectID: '',
    employeeID: '',
    accessRoleID: '',
    accessRoleName: '',
    val: {}
  });
  const [columns, setColumns] = useState([
    {
      title: 'Item Name',
      field: 'itemName',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Quantity',
      field: 'Qty',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Uom',
      field: 'uom',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Location',
      field: 'location',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Description',
      field: 'comment',
      align: 'left',
      type: ETTypes.string
    },
    {
      title: 'Action',
      field: 'action',
      align: 'left',
      list: [ETaction.onEdit, ETaction.onDelete]
      // list:[ETaction.onView,ETaction.onDelete,ETaction.onEdit]
    }
  ]);

  useEffect(() => {
    getLookup();
    // getAllEmplyoees();
    getInventoryList();
    getMasterData();
    getMaterials(3, projectDetails.projectID); // project Id hard coded0
  }, [navigate]);

  const getLookup = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData([...res.data.data]);
          console.error('lookup', lookupData);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const getAllEmplyoees = () => {
    APIKit.get(RGCA_URLS.getEmployee)
      .then(res => {
        if (res.data.status === 200) {
          let data = res.data.data.map(e => {
            return {
              itemID: e.employeeID,
              name: e.firstName + ' ' + e.lastName
            };
          });
          setEmplyoees(data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const getMaterials = (typeID, projectID) => {
    let payload = {
      // projectID: projectID,
      typeID: typeID
    };
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload)
      .then(res => {
        if (res.data.status === 200) {
          objAddData.materialList = res.data.data;
          setObjAddData({ ...objAddData });
          console.error(objAddData.materialList.itemID);
          console.error('first', objAddData);
        } else {
        }
      })
      .catch(function(e) {});
  };

  const onEmployeeChange = value => {
    payload.val = value;
    payload.projectID = '';
    payload.employeeID = value.value;
    setPayload({ ...payload });
    setAddItemBtn(true);
  };

  const getMasterData = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if(userData != null){
      console.log("userData kk",userData);
      payload.val = {
           value: userData.employeeID,
      label: userData.firstName + ' '+ userData.lastName
      };
    payload.projectID = '';
    payload.employeeID = userData.employeeID
    console.log("payloaddd",payload);
    setPayload({ ...payload });
    }
  }

  const OnItemChange = (value, index) => {
    objAddData.val = value;
    setObjAddData({ ...objAddData });
    let item = { ...objAddData };
    item.itemID = value.value;
    item.itemName = value.label;
    let uo = objAddData.materialList.find(x => x.itemID == value.value);
    let inv = inventoryList.find(x => x.itemID == value.value);
    if (inv != undefined) {
      item.uom = inv.uom;
      item.lastPrice = Number(inv.averageRate);
      item.brandOrCatalogue = uo.brandOrCatalogue;
      item.availabilityQty = Number(inv.grnQty);
    } else {
      item.uom = uo.uom;
      item.lastPrice = Number(uo.lastPrice);
      item.brandOrCatalogue = uo.brandOrCatalogue;
      item.availabilityQty = uo.availabilityQty;
    }
    setObjAddData({ ...item });
  };

  const getInventoryList = () => {
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID
    })
      .then(res => {
        if (res.data.status === 200) {
          console.error(res.data.data[0].itemID);
          setinventoryList(res.data.data);
        } else {
        }
      })
      .catch(e => {});
  };

  const submitIndent = () => {
   

    let StoreIndent = [];
    let NewIndent = [];

    storeNewIndentData.forEach(x => {
      if (x.estimatedPrice && Number(x.estimatedPrice) > 0) {
        NewIndent.push(x);
      } else {
        StoreIndent.push(x);
      }
    });

    console.log('NewIndent', NewIndent);
    console.log('StoreIndent', StoreIndent);

    if(StoreIndent.length > 0){
    addStoreIndentCall(StoreIndent);
    }

    if(NewIndent.length > 0){
    addIndentCall(NewIndent);
    }
    setStoreNewIndentData([]);
  };

  const addStoreIndentCall = async(StoreIndent) => {
    setIsLoading(true);
    let items = StoreIndent.map(i => {
      return {
        itemID: i.itemID,
        Qty: i.Qty,
        comment: i.comment,
        location: i.location
      };
    });

    let temp = checkForDuplicates(items);
    if (temp) {
      toast.error('Materials are same.If you needed increase the quantity', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    let addItemPayload = {
      projectID: projectDetails.projectID,
      employeeID: payload.employeeID,
      items
    };

    await APIKit.post(RGCA_URLS.addStoreIndent, addItemPayload)
      .then(res => {
       
        if (res.data.status === 200) {
          console.log("addStoreIndent",res.data);

          setIsLoading(false);
          toast.success(MESSAGE.storeindentAdded, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          // setStoreNewIndentData([]);
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function(e) {
        setIsLoading(false);
      });
    console.error('api call payload', storeNewIndentData);
  };

  const addIndentCall = async(NewIndent) => {
    
    let items = NewIndent.map(i => {
      return {
        itemID: Number(i.itemID),
        Qty: i.Qty,
        comment: i.comment,
        estimatedPrice: Number(i.estimatedPrice)
      };
    });

    let temp = checkForDuplicates(items);
    if (temp) {
      toast.error('Materials are same.If you needed increase the quantity', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    let Payload = {
      // projectID: projectDetails.projectID,
      // employeeID: payload.employeeID,
      "projectID": projectDetails.projectID,
      "quoteUrl": '',
      items
    };

   await APIKit.post(RGCA_URLS.addIndent, Payload)
      .then(res => {
        // console.error('super', payload);
        if (res.data.status === 200) {
          console.log("addIndent",res.data);
          setIsLoading(false);
          toast.success(MESSAGE.indentAdded, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          // setStoreNewIndentData([]);
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function(e) {
        setIsLoading(false);
      });
    console.error('api call payload', storeNewIndentData);

  };

  const onSubmit = value => {
    console.log("value",value);
    if (value.availabilityQty > 0) {
      if (value.Qty > null) {
        if (Object.keys(objAddData.val).length === 0) {
          toast.error('Please select Material', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }
        if (edit) {
          let item = storeNewIndentData;
          item[indexData] = objAddData;
          setStoreNewIndentData(item);
          setOpenTest(!openTest);
          setEdit(true);
        } else {
          let item = storeNewIndentData;
          // if(estmatedPrice > 0){
          //   let data = {...objAddData}
          //   data.estimatedPrice =
          // }
          item.push(objAddData);
          setStoreNewIndentData(item);
          setOpenTest(!openTest);
        }
      }
    } else {
      
      if(objAddData.Qty != null && Number(objAddData.Qty) > 0) {

      if (Number(estimatedPrice) > 0){
      
        if (Object.keys(objAddData.val).length === 0) {
          toast.error('Please select Material', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }
        if (edit) {
          let item = storeNewIndentData;
          item[indexData] = objAddData;
          setStoreNewIndentData(item);
          setOpenTest(!openTest);
          setEdit(true);
        } else {
          let item = [...storeNewIndentData];

          let data = { ...objAddData };
          data.estimatedPrice = estimatedPrice;

          item.push(data);
          setStoreNewIndentData(item);
          setOpenTest(!openTest);
        }
      } else {
        toast.error('Please enter Estimated Price');
      }
    }
      else {
        toast.error('Please enter Quantity');
      }
    }
  };

  const addItem = () => {
    setOpenTest(false);

    setObjAddData({
      val: {},
      materialList: objAddData.materialList,
      itemID: '',
      uom: '',
      brandOrCatalogue: '',
      Qty: '',
      availabilityQty: '',
      comment: '',
      location:  projectDetails?.location ? projectDetails?.location : ''
    });
    setOpenTest(true);
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];
    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen.indexOf(value.itemID) !== -1) {
        return true;
      }
     
      valuesAlreadySeen.push(value.itemID);
      
    }
    return false;
  }

  function checkForDuplicates2(val) {
    
   let isDuplicated =  storeNewIndentData.find(o => o?.val?.value === val.value)
console.log("isDuplicated",isDuplicated);
   if(isDuplicated){
    return true
   }
    return false;
  }

  const actions = {
    onEdit: (index, row) => {
      setIndexData(index);
      setOpenTest(false);
      setObjAddData({ ...row });
      setOpenTest(true);
      setEdit(true);
    },
    onDelete: (index, row) => {
      remove(row, index);
    }
  };

  const remove = (data, i) => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        let item = storeNewIndentData;
        item.splice(i, 1);
        setStoreNewIndentData([...item]);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };


  console.log("projectDetails",projectDetails);
  return (
    <Container
      maxWidth={true}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <ToastContainer />
      <Loader isLoading={isLoading} />
      <Box display="flex" justifyContent="space-between">
        <Typography component="div">
          <h2 style={{ color: '#394671' }}> Rise A New Store Indent</h2>
        </Typography>
        <Button
          onClick={addItem}
          variant="outlined"
          // disabled={addItemBtn === false}
          style={{ color: 'blue', cursor: 'pointer' }}
        >
          + Add Item
        </Button>
      </Box>
      {/* <Box sx={{ width: 350, marginTop: 5 }}>
        <CommonSelect
          val={payload.val}
          onChange={e => {
            onEmployeeChange(e);
            setAddItemBtn(true);
          }}
          options={emplyoees}
          labelName={'Search Employee'}
        />
      </Box> */}

      <Box>
        <Grid container spacing={3} className={classes.field}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ marginTop: '15px' }}>
              <CommonTable
                columns={columns}
                data={storeNewIndentData}
                action={actions}
              />
            </div>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <div style={{ textAlign: 'right' }}>
              {storeNewIndentData.length != 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    background: '#394671',
                    color: '#fff',
                    marginTop: '20px'
                  }}
                  onClick={submitIndent}
                >
                  Submit
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Formik
        enableReinitialize={true}
        initialValues={{
          ...objAddData
        }}
        onSubmit={(values, { resetForm }) => {
          // console.error("hello")
        }}
        validationSchema={Yup.object().shape({
          typeID: Yup.string().required(MESSAGE.type),
          Qty: Yup.string()
            .notOneOf(['0'], MESSAGE.not0)
            .required(MESSAGE.qty),
          estimatedPrice: Yup.string()
            .notOneOf(['0'], MESSAGE.not0)
            .required(MESSAGE.estimatedPrice)
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openTest}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="md"
            fullWidth
          >
            <Loader isLoading={isLoading} />
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setEdit(false);
                setOpenTest(!openTest);

              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              {edit ? 'Edit' : 'Add'} Item
            </DialogTitle>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3} className={classes.field}>
                  <Grid item md={4} xs={12} className={classes.select}>
                    <CommonSelect
                      val={objAddData.val}
                      required
                      onChange={e => {
                       let result = checkForDuplicates2(e);
                       if(result){
                        toast.error('This material already added to the List', {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined
                        });
                       }else{
                      OnItemChange(e, 0)
                       }
                      }}
                      options={objAddData.materialList}
                      // options={objAddData.materialList.filter(y => !storeNewIndentData.find(o => o.itemName === y.val))}

                      // disabled={objAddData.materialList.filter(y => y.val !== objAddData.val)}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="UOM"
                      name="uom"
                      onChange=""
                      value={objAddData.uom}
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Brand/Catalogue"
                      name="brandOrCatalogue"
                      onChange={e => {
                        let item = { ...objAddData };
                        item.brandOrCatalogue = e.target.value;
                        setObjAddData({ ...item });
                      }}
                      value={objAddData.brandOrCatalogue}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      helperText=""
                      label="Stock Quantity"
                      name="price"
                      onChange=""
                      value={objAddData.availabilityQty == null ? 0 : objAddData.availabilityQty }
                      variant="outlined"
                    />
                  </Grid>
                  {(objAddData.availabilityQty == 0  || objAddData.availabilityQty == null || objAddData.availabilityQty == "" ) ? (
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Quantity"
                        name="Qty"
                        required
                        onBlur={handleBlur}
                        error={Boolean(touched.Qty && errors.Qty)}
                        helperText={touched.Qty && errors.Qty}
                        onChange={e => {
                          if (e.target.value.match(regEx.numbersOnly)) {
                            handleChange(e);
                            let item = { ...objAddData };
                            // if (objAddData.availabilityQty <= e.target.value) {
                            //   item.Qty = objAddData.availabilityQty;
                            // } else {
                            //   item.Qty = e.target.value;
                            // }
                            item.Qty = e.target.value;

                            setObjAddData({ ...item });
                          }
                        }}
                        value={objAddData.Qty}
                        variant="outlined"
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                  ) : (
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Quantity"
                        name="Qty"
                        required
                        onBlur={handleBlur}
                        error={Boolean(touched.Qty && errors.Qty)}
                        helperText={touched.Qty && errors.Qty}
                        onChange={e => {
                          if (e.target.value.match(regEx.numbersOnly)) {
                            handleChange(e);
                            let item = { ...objAddData };
                            if (objAddData.availabilityQty <= e.target.value) {
                              item.Qty = objAddData.availabilityQty;
                            } else {
                              item.Qty = e.target.value;
                            }

                            setObjAddData({ ...item });
                          }
                        }}
                        value={objAddData.Qty}
                        variant="outlined"
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                  )}

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Location *"
                      name="location"
                      disabled
                      // onChange={e => {
                      //   let item = { ...objAddData };
                      //   item.location = e.target.value;
                      //   setObjAddData({ ...item });
                      // }}
                      // value={objAddData.location}
                      value={projectDetails?.location}
                      variant="outlined"
                      inputProps={{ maxLength:30}}

                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="comment"
                      onChange={e => {
                        let item = { ...objAddData };
                        item.comment = e.target.value;
                        setObjAddData({ ...item });
                      }}
                      value={objAddData.comment}
                      variant="outlined"
                      inputProps={{ maxLength: 50}}

                    />
                  </Grid>
                  {(objAddData.availabilityQty == 0 || objAddData.availabilityQty == '' || !objAddData.availabilityQty)  && (
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Estimated Price"
                        name="comment"
                        required
                        onChange={e => {
                          setEstimatedPrice(e.target.value);
                          // let item = { ...objAddData };
                          // item.comment = e.target.value;
                          // setObjAddData({ ...item });
                        }}
                        value={estimatedPrice}
                        variant="outlined"
                        inputProps={{ maxLength: 10}}

                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                <Button
                  onClick={e => {
                    setOpenTest(!openTest);
                    setEdit(false);
                  }}
                  style={{
                    backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid'
                  }}
                >
                  Cancel
                </Button>

                {!edit && (
                  <Button
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                    // type='Submit'
                    onClick={() => {
                      onSubmit(values);
                    }}
                  >
                    Add
                  </Button>
                )}
                {edit && (
                  <Button
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                    onClick={() => {
                      onSubmit(values);
                    }}
                  >
                    Update
                  </Button>
                )}
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </Container>
  );
};
export default StoreNewIndent;
