import React, { useEffect, useState, useRef } from 'react';
import {
    Avatar, Box, Card, Checkbox, TableContainer, Table, TableBody, TableCell,
    TableHead, TablePagination, TableRow, Typography, makeStyles, Container,
    Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel,
    MenuItem
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import xmljs from 'xml-js';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Loader from 'src/views/loader';
import Paper from '@material-ui/core/Paper';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Input from '@material-ui/core/Input';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { Padding } from '@mui/icons-material';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1, dateOnlyRevReport, getProjectDetails } from '../../Common/utilities';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial'
    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        // margin: theme.spacing(1),
        // maxWidth:"200px!important",
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start', // Default width for non-mobile devices
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center' // Custom width for mobile devices (adjust as needed)
        }
    },
    alignForInventory: {
        // Default width for non-mobile devices
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const AllProjectInventoryReport = (props) => {
    console.log('FAR_Props', props);
    const classes = useStyles();
    const [allDepartment, setAllDepartment] = useState([]);
    const [emplyoees, setEmplyoees] = useState([]);
    const tableRef = useRef(null);
    const tableRef2 = useRef(null);
    const { project } = useParams();
    const [FARList, setFARList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [selectType, setSelectType] = useState("0")
    const [allProjects, setAllProjects] = useState([]);
    const [projectName, setProjectName] = useState('001');
    const [projectIds, setProjectIds] = useState([]);
    const [inventoryList, setinventoryList] = useState([]);
    const [isDepId, setIsDepId] = useState(null)
    const [isEmpId, setIsEmpId] = useState(null)
    const [isProId, setIsProId] = useState(null)
    const projectDetails = props.projectID || { ...getProjectDetails(project) };
    const currentDates = new Date();
    // const thirtyDaysAgo = new Date(currentDates);

    const [reportQuery, setReportQuery] = useState({
        projectID: projectName,
        fromDate: currentDates.setDate(currentDates.getDate() - 30),
        toDate: new Date()
    });
    const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
    const [page1, setPage1] = React.useState(0);
    const [recurring, setRecurring] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [print, setprint] = useState(false)
    const htmlContentRef = useRef(null);
    const htmlContentRefFar = React.useRef();
    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });
    const [isType, setIsType] = useState("all")

    const printOrderFar = useReactToPrint({
        content: () => htmlContentRefFar.current,
    });

    const a11yProps = index => {
        if (index == 0) {
            setFARList([])
            setReportQuery({
                projectID: projectName,
                fromDate: currentDates.setDate(currentDates.getDate() - 30),
                toDate: new Date()
            })
            getFAR()
        }
        if (index == 1) {
            setRecurring([]);
            setReportQuery({
                projectID: projectName,
                fromDate: currentDates.setDate(currentDates.getDate() - 30),
                toDate: new Date()
            })
        }
        if (index == 2) {
            //   getPaymentList();
        }
        // if (index == 3) {
        //   getPaymentList()
        // }

        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    let userData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {
        if (userData?.isAdmin == 1) {
            getAllProjects();
        } else {
            getEmpProjects();
        }
        getEmpProjects();
        a11yProps(0);
        // props.setTabName('Inventory_Far_Report')
        props.setTabName(`Inventory_Far_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`)

    }, []);

    useEffect(() => {
        getAllDepartment()
        getAllEmplyoees()
        getFAR()
       
    }, [])

    const handleChangePage1 = (event, newPage) => {
        setPage1(newPage);
    };

    const handleChangeRowsPerPage1 = event => {
        setRowsPerPage1(+event.target.value);
        setPage1(0);
    };

    const getEmpProjects = async () => {
        await APIKit.get(RGCA_URLS.getEmployeeProjects)
            .then(res => {
                if (res?.data.status === 200) {
                    console.log('Emp Projects', res.data);

                    setAllProjects(res.data.data);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const getInventoryRecurringReport = () => {
        let changedDate = new Date(reportQuery?.fromDate);
        let formattedDate = changedDate?.toISOString();

        setRecurring([])
        setIsLoading(true)
        reportQuery.projectID = projectName;
        APIKit.post(RGCA_URLS.getInventoryRecurringReport, {
            projectID: projectName,
            // projectDetails.projectID,
            fromDate: formattedDate,
            toDate: reportQuery?.toDate,
            "departmentID": isDepId,
            "employeeID": isEmpId
        })
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false)
                    if (res?.data?.data.length > 0) {
                        setIsLoading(false)
                        setRecurring(res.data.data);
                        toast.success("Successfully fetching data")
                    } else {
                        toast.warning('No data found.!.')
                    }
                   
                } else {
                    setIsLoading(false)
                }
            })
            .catch(e => { setIsLoading(false) });
    };
    const getFAR = () => {
        setFARList([])
        let changedDateFar = new Date(reportQuery?.fromDate);
        let formattedDateFar = changedDateFar?.toISOString();

        const payload = {
            "projectID": projectName,
            "fromDate": formattedDateFar,
            "toDate": reportQuery?.toDate
        }
        setIsLoading(true);
        APIKit.post(`${RGCA_URLS.getFAR}`, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    let data = res.data.data.filter((x) => {
                        return {
                            ...x,
                        };
                    });
                    console.log("getFAR", data);
                    setFARList(data);
                    if (res?.data?.data.length > 0) {
                        // toast.success("Successfully fetching data")
                    } else {
                        toast.warning('No data found.!.')
                    }
                } else {
                    setIsLoading(false);
                    // toast.error(res.data.message);
                }
            })
            .catch((e) => { });
    };

    const currentDate = new Date().toLocaleDateString();

    const exportToCSV = () => {
        let name = `${projectDetails.projectName}_Inventory_Far_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`
        try {
            const fileName = name;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            // Check if productionList is an array and not empty
            if (!Array.isArray(FARList) || FARList.length === 0) {
                // throw new Error('Receipt list is empty or invalid.');
                toast.warning('FAR List list is empty')
            }
            FARList.forEach((x, index) => {
                const obj = {};
                obj['S.No'] = index + 1;
                obj['Far Id'] = { v: x.farMasterID, t: 's' }; // Setting type as string
                obj['PO Id'] = { v: x.purchaseOrderMasterID, t: 's' }; // Setting type as string
                obj['Category Name'] = x.categoryName ? x.categoryName : ''; // Setting default value to empty string if falsy
                obj['Sub Category Name'] = x.subCategoryName ? x.subCategoryName : ''; // Setting default value to empty string if falsy
                obj['Name'] = x.name ? x.name : ''; // Setting default value to empty string if falsy
                obj['Item Quantity'] = { v: x.itemQuty, t: 's' };
                obj['Received Date'] = { v: x.createDate, t: 's' };
                obj['Received By'] = { v: x.receivedBy, t: 's' };
                obj['Assigned To'] = { v: x.assignedTo, t: 's' };
                newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 5 },
                { wch: 15 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 10 },
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };
    const exportToCSVRecurring = () => {
        let name = `${projectDetails.projectName}_Inventory_Recurring_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`
        try {
            const fileName = name;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            // Check if productionList is an array and not empty
            if (!Array.isArray(recurring) || recurring.length === 0) {
                // throw new Error('Receipt list is empty or invalid.');
                toast.warning('Recurring List list is empty')
            }
            recurring.forEach((x, index) => {
                const obj = {};
                obj['S.No'] = index + 1;
                obj['Item Id'] = { v: x.itemID, t: 's' }; // Setting type as string
                obj['Category Name'] = x.cat ? x.cat : ''; // Setting default value to empty string if falsy
                obj['Sub Category Name'] = x.subCat ? x.subCat : ''; // Setting default value to empty string if falsy
                obj['Name'] = x.itemName ? x.itemName : ''; // Setting default value to empty string if falsy
                obj['Brand'] = { v: x.brand, t: 's' };
                obj['Total Quantity'] = { v: x.grnQty, t: 's' };
                obj['Average Rate'] = { v: x.averageRate, t: 's' };
                obj['UOM'] = { v: x.uom, t: 's' };
                newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 5 },
                { wch: 15 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 10 },
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };


    const getAllDepartment = () => {

        APIKit.get(RGCA_URLS.getDepartment)
            .then((res) => {

                if (res.data.status === 200) {
                    setAllDepartment(res.data.data)
                }
                else {
                    //toast.error(res.data.message);
                }
            })
            .catch(function (e) {
            })
    }

    const getAllProjects = () => {
        APIKit.post(RGCA_URLS.getProjects)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('kkk', res.data.data);
                    setAllProjects(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(() => { });
    };

    const getAllEmplyoees = () => {
        APIKit.get(RGCA_URLS.getEmployee)
            .then((res) => {
                if (res.data.status === 200) {
                    setEmplyoees(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch((e) => {
            });
    };
    console.log("allProjects", allProjects);

    return (
        <>
            <Loader isLoading={isLoading} />
            <AppBar position="static" color="default" style={{ marginTop: '20px', Padding: '0px' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="FAR Report" onClick={() => {
                        a11yProps(0)
                        setFARList([]);
                        props.setTabName(`Inventory_Far_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`)

                    }} />
                    <Tab label="Recurring Report" onClick={() => {
                        a11yProps(1)
                        setRecurring([]);
                        getInventoryRecurringReport()
                        props.setTabName(`Inventory_Recurring_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`)
                    }} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Box mt={3}>
                    <Typography><h2 style={{ color: '#394671' }}>FAR Report</h2></Typography>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            required
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Select Project
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Select Project"
                                            name="SelectProject"
                                            inputProps={{ maxLength: 100 }}
                                            value={projectName}
                                            onChange={e => {
                                                setProjectName(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={"001"}>All</MenuItem>
                                            {allProjects &&
                                                allProjects.map((Project, index) => {
                                                    return (
                                                        <MenuItem key={index} value={Project.projectID}>
                                                            {Project.projectName}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                maxDate={new Date()}
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="From Date"
                                                format="dd/MM/yyyy"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="fromDate"
                                                onChange={e => {
                                                    const p = reportQuery;
                                                    console.log('date',);
                                                    p.fromDate = e;
                                                    console.log('date', p.fromDate);
                                                    setReportQuery({
                                                        ...p
                                                    });
                                                    props.setTabName(`Inventory_Far_Report_${reportQuery.fromDate}to${reportQuery.toDate}`)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                value={reportQuery.fromDate}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                maxDate={new Date()}
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="To Date"
                                                format="dd/MM/yyyy"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="toDate"
                                                onChange={e => {
                                                    const p = reportQuery;
                                                    p.toDate = e;
                                                    setReportQuery({
                                                        ...p
                                                    });
                                                    props.setTabName(`Inventory_Far_Report_${reportQuery.fromDate}to${reportQuery.toDate}`)
                                                }}
                                                value={reportQuery.toDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ marginTop: '25px' }}>
                                    {reportQuery.fromDate &&
                                        reportQuery.toDate ? (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                props.setTabName(`Inventory_Far_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`);
                                                getFAR();
                                            }}
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled
                                            type="submit"
                                            className={classes.dropdownBorder}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
                {FARList && FARList.length > 0 &&
                    <>
                        <Box display="flex" justifyContent="flex-end">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} lg={2}
                                    style={{
                                        marginTop: '10px',
                                        // color: '#3f51b5',
                                        fontSize: '17px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        gap: '8px'

                                    }}
                                >
                                    <PictureAsPdfIcon style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            printOrderFar();
                                            setprint(true);

                                        }}
                                    />
                                    <PrintIcon style={{ cursor: 'pointer' }}
                                        // content={() => htmlContentRefFar.current}
                                        onClick={() => {
                                            printOrderFar();
                                            setprint(true);

                                        }}
                                    />
                                    <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                        exportToCSV();
                                        setprint(true);

                                    }} />

                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Box mt={3}>
                                    <TableContainer ref={tableRef}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">FAR ID</TableCell>
                                                    <TableCell align="left">PO ID</TableCell>
                                                    <TableCell align="left">Category Name</TableCell>
                                                    <TableCell align="left">Sub-Category</TableCell>
                                                    <TableCell align="left">Item Name</TableCell>
                                                    <TableCell align="left">Quantity</TableCell>
                                                    <TableCell align="left">Received Date</TableCell>
                                                    <TableCell align="left">Received by</TableCell>
                                                    <TableCell align="left">Assigned To</TableCell>
                                                    {/* <TableCell align="left">Action</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {FARList &&
                                                    FARList.slice(
                                                        page1 * rowsPerPage1,
                                                        page1 * rowsPerPage1 + rowsPerPage1
                                                    ).map((z, index) => {
                                                        return (
                                                            <TableRow hover>
                                                                <TableCell align="left">
                                                                    {z.farMasterID}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {z.purchaseOrderMasterID}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {z.categoryName}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {z.subCategoryName}
                                                                </TableCell>
                                                                <TableCell align="left">{z.name}</TableCell>
                                                                <TableCell align="left">{z.itemQuty}</TableCell>
                                                                <TableCell align="left">
                                                                    {moment(z?.createDate).format("DD/MM/YYYY")}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {z.receivedBy}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {z.assignedTo}
                                                                </TableCell>

                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={FARList.length}
                                        rowsPerPage={rowsPerPage1}
                                        page={page1}
                                        onPageChange={handleChangePage1}
                                        onRowsPerPageChange={handleChangeRowsPerPage1}
                                    />
                                </Box>
                            </div>
                        </Grid>
                    </>
                }
                <div className="printViewBox" ref={htmlContentRefFar}>
                    <div class="grid-container">
                        <div class="logo-container">
                            <img src="/static/logo-rgca.jpg" alt="Company logo" />
                        </div>
                        <div class="text-container">
                            <div>
                                <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                                <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                                <h5>HEAD OFFICE / UNIT OFFICE</h5>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Grid item>
                            <Typography>
                                Name :  Inventory Report
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                From Date :
                                {moment(FARList[0]?.fromDate).format("DD/MM/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                To Date :
                                {moment(FARList[0]?.toDate).format("DD/MM/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Date :
                                {new Date().toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Project Name :  {projectDetails?.projectName}
                            </Typography>
                        </Grid>
                    </Grid>

                    <div class="box">
                        <table class="table">
                            <thead class='tableHead'>
                                <tr class="table-rows">

                                    <th align="left">FAR ID</th>
                                    <th align="left">PO ID</th>
                                    <th align="left">Category Name</th>
                                    <th align="left">Sub-Category</th>
                                    <th align="left">Item Name</th>
                                    <th align="left">Quantity</th>
                                    <th align="left">Received Date</th>
                                    <th align="left">Received by</th>
                                    <th align="left">Assigned To</th>
                                </tr>
                            </thead>
                            <tbody>

                                {FARList && FARList.length != 0 ? (
                                    FARList &&
                                    FARList.slice(
                                        page1 * rowsPerPage1,
                                        page1 * rowsPerPage1 + rowsPerPage1
                                    ).map((x, index) => {
                                        return (
                                            <tr class="table-row">
                                                <td align="left">{x?.farMasterID}</td>
                                                <td align="left">{x?.purchaseOrderMasterID}</td>
                                                <td align="left">{x?.categoryName}</td>
                                                <td align="left">{x?.subCategoryName}</td>
                                                <td align="left">{x?.name}</td>
                                                <td align="left">{x?.itemQuty}</td>
                                                <td align="left">{moment(x?.createDate).format("DD/MM/YYYY")}</td>
                                                <td align="left">{x?.receivedBy}</td>
                                                <td align="left">{x?.assignedTo}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr class="table-row">
                                        <td align="left">
                                            {'No Data Found'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box mt={3}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12} style={{ marginTop: '0' }}>
                            <Typography>
                                <h2 style={{ color: '#394671' }}>Recurring Report</h2>
                            </Typography>
                        </Grid>
                        
                    </Grid>
                    <br /> <br />
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            required
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Select Project
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Select Project"
                                            name="SelectProject"
                                            inputProps={{ maxLength: 100 }}
                                            value={projectName}
                                            onChange={e => {
                                                setProjectName(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={"001"}>All</MenuItem>
                                            {allProjects &&
                                                allProjects.map((Project, index) => {
                                                    return (
                                                        <MenuItem key={index} value={Project.projectID}>
                                                            {Project.projectName}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                             
                                {selectType == 1 &&
                                    <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                        <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-outlined-label">Departments</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={isDepId}
                                                onChange={(e) => {
                                                    setIsDepId(e.target.value)
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                label="Department"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {allDepartment.map((x, index) => {
                                                    return <MenuItem value={x.departmentID} >{x.departmentName}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {selectType == 3 &&
                                    <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Projects*
                                            </InputLabel>

                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={isProId}
                                                onChange={(e) => {
                                                    setIsProId(e.target.value)
                                                }}
                                                label="Projects"
                                            >
                                                {allProjects &&
                                                    allProjects.length > 0 &&
                                                    allProjects.map(x => {
                                                        return (
                                                            <MenuItem
                                                                value={x.projectID}
                                                                key={x.projectID}
                                                            >
                                                                {x.projectName}
                                                            </MenuItem>
                                                        )
                                                        // }
                                                    })}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                }
                                {selectType == 2 &&
                                    <Grid item lg={3} sm={3} xl={3} xs={3} style={{ marginTop: '16px' }}>
                                        <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-outlined-label">Employee Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Employee"
                                                value={isEmpId}
                                                onChange={(e) => {
                                                    setIsEmpId(e.target.value)
                                                }}

                                            >
                                                {emplyoees && emplyoees.map((x) => {
                                                    return <MenuItem value={x.employeeID} key={x.employeeID}>{x.firstName}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                }
                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                maxDate={new Date()}
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="From Date"
                                                format="dd/MM/yyyy"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="fromDate"
                                                onChange={e => {
                                                    const p = reportQuery;
                                                    p.fromDate = e;
                                                    setReportQuery({
                                                        ...p
                                                    });
                                                    props.setTabName(`Inventory_Recurring_Report_${reportQuery.fromDate} to to ${reportQuery.toDate}`)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                value={reportQuery.fromDate}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                maxDate={new Date()}
                                                InputProps={{ disabled: true }}
                                                inputVariant="outlined"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="To Date"
                                                format="dd/MM/yyyy"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name="toDate"
                                                onChange={e => {
                                                    const p = reportQuery;
                                                    p.toDate = e;
                                                    setReportQuery({
                                                        ...p
                                                    });
                                                    props.setTabName(`Inventory_Recurring_Report_${reportQuery.fromDate} to ${reportQuery.toDate}`)
                                                }}
                                                value={reportQuery.toDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                className={classes.calmar}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item md={2} xs={12} style={{ marginTop: '25px' }}>
                                    {reportQuery.fromDate &&
                                        reportQuery.toDate ? (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                props.setTabName(`Inventory_Recurring_Report_${moment(reportQuery.fromDate).format("DD/MM/YYYY")}to${moment(reportQuery.toDate).format("DD/MM/YYYY")}`)
                                                getInventoryRecurringReport()
                                            }}
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled
                                            type="submit"
                                            className={classes.dropdownBorder}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

                {recurring && recurring.length > 0 &&
                    <>
                        <Box display="flex" justifyContent="flex-end">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} lg={2}
                                    style={{
                                        marginTop: '10px',
                                        // color: '#3f51b5',
                                        fontSize: '17px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        gap: '8px'

                                    }}
                                >
                                    <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => {
                                        printOrder();
                                        setprint(true);

                                    }} />
                                    <PrintIcon style={{ cursor: 'pointer' }} onClick={() => {
                                        printOrder();
                                        setprint(true);

                                    }} />
                                    <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                        exportToCSVRecurring();
                                        setprint(true);

                                    }} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={3}>
                            <TableContainer ref={tableRef2}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">S.No</TableCell>
                                            <TableCell align="left">Department Name</TableCell>
                                            <TableCell align="left">Employee Name</TableCell>
                                            <TableCell align="left">Item Id</TableCell>
                                            <TableCell align="left">Item Category</TableCell>
                                            <TableCell align="left">Item Sub Category</TableCell>
                                            <TableCell align="left">Item Name</TableCell>
                                            <TableCell align="left">Brand</TableCell>
                                            <TableCell align="left">Added Quantity (+)</TableCell>
                                            <TableCell align="left">Allocated Quantity (-)</TableCell>
                                            <TableCell align="left">Total Ava. Qty</TableCell>
                                            <TableCell align="left">Average Rate</TableCell>
                                            <TableCell align="left">UOM</TableCell>
                                            <TableCell align="left">Project Name</TableCell>
                                            {/* add deparment name emp name */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recurring &&
                                            recurring
                                                .slice(
                                                    page1 * rowsPerPage1,
                                                    page1 * rowsPerPage1 + rowsPerPage1
                                                )
                                                .map((z, index) => {
                                                    return (
                                                        <TableRow hover>
                                                            <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                            <TableCell align="left">{z.departmentName}</TableCell>
                                                            <TableCell align="left">{z.employeeName}</TableCell>
                                                            <TableCell align="left">{z.itemID}</TableCell>
                                                            <TableCell align="left">{z.cat}</TableCell>
                                                            <TableCell align="left">{z.subCat}</TableCell>
                                                            <TableCell align="left">{z.itemName}</TableCell>
                                                            <TableCell align="left">{z.brand}</TableCell>
                                                            <TableCell align="left">{z.grnQty ? z.grnQty : '0'}</TableCell>
                                                            <TableCell align="left">{z.ginQty}</TableCell>
                                                            <TableCell align="left">{z.itemQty}</TableCell>
                                                            <TableCell align="left">{currencyFormat1(Math.round(z?.averageRate))}</TableCell>
                                                            <TableCell align="left">{z.uom}</TableCell>
                                                            <TableCell align="left">{z.projectName}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={recurring.length}
                                rowsPerPage={rowsPerPage1}
                                page={page1}
                                onPageChange={handleChangePage1}
                                onRowsPerPageChange={handleChangeRowsPerPage1}
                            />
                        </Box>
                    </>
                }

                <div className="printViewBox" ref={htmlContentRef} id="myHtmlContent">
                    <div class="grid-container">
                        <div class="logo-container">
                            <img src="/static/logo-rgca.jpg" alt="Company logo" />
                        </div>
                        <div class="text-container">
                            <div>
                                <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                                <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                                <h5>HEAD OFFICE / UNIT OFFICE</h5>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Grid item>
                            <Typography>
                                Name :  Recurring Report
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                From Date :
                                {moment(recurring[0]?.fromDate).format("DD/MM/YYYY")}
                                {/* {recurring[0]?.fromDate} */}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                To Date :
                                {moment(recurring[0]?.toDate).format("DD/MM/YYYY")}
                                {/* {recurring[0]?.toDate} */}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Date :
                                {new Date().toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Project Name :  {projectDetails?.projectName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div class="box">
                        <table class="table">
                            <thead class='tableHead'>
                                <tr class="table-rows">

                                    <th align="left">Item Id</th>
                                    <th align="left">Item Category</th>
                                    <th align="left">Item Sub Category</th>
                                    <th align="left">Item Name</th>
                                    <th align="left">Brand</th>
                                    <th align="left">Added Quantity</th>
                                    <th align="left">Allocated Quantity</th>
                                    <th align="left">Total Ava. Qty</th>
                                    <th align="left">Average Rate</th>
                                    <th align="left">UOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recurring && recurring.length != 0 ? (
                                    recurring.map((x, index) => {
                                        return (
                                            <tr class="table-row">
                                                <td align="left">{x?.itemID}</td>
                                                <td align="left">{x?.cat}</td>
                                                <td align="left">{x?.subCat}</td>
                                                <td align="left">{x?.itemName}</td>
                                                <td align="left">{x?.brand}</td>
                                                <td align="left">{x?.grnQty}</td>
                                                <td align="left">{x?.ginQty}</td>
                                                <td align="left">{x?.itemQty}</td>
                                                <td align="left">{currencyFormat1(Math.round(x?.averageRate))}</td>
                                                <td align="left">{x?.uom}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr class="table-row">
                                        <td align="left">
                                            {'No Data Found'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>


            </TabPanel>



        </>
    )
}

export default AllProjectInventoryReport