export const getloggedInData = () => {
    let userDetails = {};
    userDetails.Isactive = false;
    
    if (JSON.parse(localStorage.getItem('userData'))) {
        userDetails = JSON.parse(localStorage.getItem('userData'));
        userDetails.Isactive = true;
    }
    console.log(";;;",userDetails);
    return userDetails;
}

export const convertExponentialToDecimal = (exponentialNumber) => {
    if (exponentialNumber) {
        const str = exponentialNumber.toString();
        if (str.indexOf('e') !== -1) {
            const exponent = parseInt(str.split('-')[1], 10);
            const result = exponentialNumber.toFixed(exponent);
            return result;
        } else {
            return exponentialNumber;
        }
    }
    else {
        return '-'
    }
}

export const accessPermission = (type) =>{

    if(localStorage.getItem('access'))
    {
        let permission = JSON.parse(localStorage.getItem('access'))
        if(permission[type])
        {
            return permission[type];
        }
        else {
            return  {
                create:true,
                view:true,
                edit:true,
                delete:true
            }
        }
    }
    else{
        return  {
            create:true,
            view:true,
            edit:true,
            delete:true
        }
    }

}


export const statusColor = (status) =>{
    if(status == "Modification"){
        return '#FFA500'
    }
    else if (status == "Approved")
    {
        return '#008000'
    }
    else if (status == 'Hold')
    {
        return 'rgb(144 134 45)';// '#FFD700'
    }
    else if (status == 'Rejected')
    {
        return '#FF0000'
    }
    else if (status == 'Dropped')
    {
        return '#FF7F7F'
    }
    else 
    {
        return '#263238'
    }

}




