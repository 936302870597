import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import HistoryIcon from "@mui/icons-material/History";
import {
  Box, Card, Dialog, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Button, CardContent, InputAdornment, TextField, SvgIcon, InputLabel, FormControl, MenuItem, Switch, DialogTitle, DialogContent, DialogActions, OutlinedInput, Select,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import Page from "src/components/Page";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import { useNavigate } from "react-router-dom";
import APIKit from "../../../utils/APIKit";
import { getProjectDetails } from "../Common/utilities";
import { useParams } from "react-router-dom";
import { RGCA_URLS } from "../../../utils/url";
import "../GRN/style.css";
import { styled } from "@mui/material/styles";
import Loader from "src/views/loader";
import { Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { MESSAGE } from "src/utils/message";
import { Tooltip } from '@mui/material';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EditIcon from '@material-ui/icons/Edit';




const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: "Arial",
  },
  productCard: {
    height: "100%",
  },
  logostyle: {
    height: "100px",
    width: "100px",
  },
  textleftshadow: {
    textAlign: "left",
    boxShadow: "none",
  },
  textrightshadow: {
    textAlign: "right",
    boxShadow: "none",
  },
  table: {
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    borderRadius: "5px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },

  dateborder: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
  },
  calmar: {
    marginTop: "6px",
    marginBottom: "0px",
    width: "100%",
    marginLeft: "10px",
  },
  txt: {
    margin: 2,
    [theme.breakpoints.down("xs")]: {
      // width:'160px',
      minWidth: "150",
      marginLeft: "1%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",

      // marginLeft:'1%'
    },
  },
}));

const InventoryRep = () => {
  const classes = useStyles();
  const { project } = useParams();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const projectDetails = { ...getProjectDetails(project) };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [inventoryList, setinventoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [damageModalOpen, setDamageModalOpen] = useState(false);
  const [modalData, setDamageModalData] = useState([]);
  const [isDamageHistory, isSetDamageHistory] = useState(true);
  const [damageHistoryList, setDamageHistoryList] = useState([]);
  const textInput = React.useRef(null);

  const [addItem, setAddItem] = useState({
    type: "",
    category: "",
    subCategory: "",
    item: "",
    uom: "",
    brand: "",
    catalogueNumber: "",
  });

  const [brokenItem, setBrokenItem] = useState({
    itemID: "",
    brokenItemQuantity: "",
  });

  const [addCategory, setAddCategory] = useState({
    typeID: null,
    categoryName: "",
    projectID: projectDetails.projectID,
  });

  const [addSubCategory, setAddSubCategory] = useState({
    type: null,
    categoryID: null,
    subCategoryName: "",
  });
  const [isTransfer, setIsTransfer] = useState(false)
  const [inventoryTrsData, setInventoryTrsData] = useState({})
  const [allProjects, setAllProjects] = useState([]);
  const [emplyoees, setEmplyoees] = useState([]);
  const [disposedPayload, setDisposedPayload] = useState({
    itemId: '',
    categoryName: '',
    subCategoryName: '',
    name: '',
    itemQuty: '',
    comment: '',
    disposedQty: '',
    Qty: ''
  });
  const [transferPayload, setTransferPayload] = useState({
    projectID: projectDetails.projectID,
    CategoryName: '',
    SubCategory: '',
    itemName: '',
    availableQuantity: '',
    transferTo: "",
    AssignedDate: null,
    itemID: "",
    comment: '',
    transferQty: ''
  });
  const [isThresholdModel, setIsThresholdModel] = useState(false);
  const [thresholdPayload, setThresholdPayload] = useState({
    projectID: projectDetails.projectID,
    CategoryName: '',
    SubCategory: '',
    itemName: '',
    availableQuantity: '',
    itemID: "",
    count: '',
  })



  const subCategoryValues = {
    type: "",
    category: "",
    subCategory: "",
    item: "",
    uom: "",
    brand: "",
    catalogueNumber: "",
  };

  useEffect(() => {
    getCategories(3, projectDetails.projectID);
    getInventoryList();
    getAllProjects();
  }, [navigate]);

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const getCategories = (type) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
      typeID: type,
      // projectID: projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryList(res.data.data);
          setIsLoading(false);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: "error" });
        }
      })
      .catch(function (e) { });
  };

  const getSubCategories = (categoryId) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getCategoryORsubCategory, {
      categoryID: categoryId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setSubCategoryList(res.data.data);
          setIsLoading(false);
          getInventoryList(categoryId);
          // addToast(res.data.message, { appearance: 'success' });
        } else {
          //toast.error(res.data.message);
          setIsLoading(false);
          addToast(res.data.message, { appearance: "error" });
        }
      })
      .catch(function (e) { });
  };

  const handleChangeSubCat = (e) => {
    getInventoryList(addCategory.categoryID, e.target.value);
  };

  const getInventoryList = (catId = "", subCatId = "") => {
    setIsLoading(true);
    setinventoryList([]);
    APIKit.post(RGCA_URLS.getListForIR, {
      projectID: projectDetails.projectID,
      catId: catId,
      subCatId: subCatId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          console.error(res.data.data[0].itemID);
          if (res.data.data.length > 0) {
            setinventoryList(res.data.data);
          } else {
            console.log(inventoryList);
            setinventoryList([]);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const searchIR = (e) => {
    if (e) {
      APIKit.post(RGCA_URLS.getListForIR, {
        projectID: projectDetails.projectID,
        searchText: e,
      })
        .then((res) => {
          if (res.data.status === 200) {
            setinventoryList(res.data.data);
          } else {
          }
        })
        .catch(function (e) { });
    } else {
      getInventoryList();
    }
  };

  const filterClear = () => {
    console.log("dfg");
    setAddCategory({
      typeID: null,
      categoryName: "Category*",
      projectID: projectDetails.projectID,
    });

    setAddSubCategory({
      type: null,
      categoryID: null,
      subCategoryName: "",
    });

    setAddItem({
      type: "",
      category: "",
      subCategory: "",
      item: "",
      uom: "",
      brand: "",
      catalogueNumber: "",
    });

    textInput.current.value = "";
    getInventoryList();
  };

  const damageModal = (id) => {
    setIsLoading(true);
    setDisposedPayload({
      itemId: id?.itemID,
      categoryName: id?.cat,
      subCategoryName: id?.subCat,
      name: id?.itemName,
      itemQuty: id?.grnQty,
      comment: '',
      disposedQty: ''
    });
    // setDamageModalData(inventoryList.filter((data) => data.itemID === id));
    setTimeout(() => {
      setDamageModalOpen(!damageModalOpen);
      setIsLoading(false);
    }, 2000);
  };

  const getBrokenHistory = (id) => {
    setIsLoading(true)
    setDamageModalOpen(!damageModalOpen);
    setDamageHistoryList([]);
    APIKit.post(RGCA_URLS.getBrokenHistory, {
      projectID: projectDetails.projectID,
      itemId: id,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          console.log(res.data.data);
          if (res.data.data.length > 0) {
            setDamageHistoryList(res.data.data);
          } else {
            console.log(inventoryList);
            setDamageHistoryList([]);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const addBrokenItem = () => {
    setIsLoading(true);
    if (
      disposedPayload?.Qty <= disposedPayload?.itemQuty &&
      disposedPayload?.Qty > 0
    ) {
      // console.error(brokenItem);
      APIKit.post(RGCA_URLS.addBrokenQty, {
        projectID: projectDetails.projectID,
        // itemId: brokenItem.itemID,
        itemId: disposedPayload?.itemId ?? '',
        brokenItemQty: disposedPayload?.Qty ?? '',
        comment: disposedPayload?.comment ? disposedPayload?.comment : ''
      })
        .then((res) => {
          if (res.data.status === 200) {
            setIsLoading(false);
            setDamageModalOpen(!damageModalOpen);
            setBrokenItem({
              itemId: "",
              brokenItemQty: "",
            });
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // getListForIR();
            getInventoryList();
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      toast.error(MESSAGE.BrokenItem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  let regEx = {
    textOnly: /^[a-zA-Z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/,
    percentage: /^([0-9][0-9]?|100)%?$/,
  };

  const getAllProjects = () => {
    APIKit.post(RGCA_URLS.getProjects)
      .then(res => {
        if (res.data.status === 200) {
          console.log('kkk', res.data.data);
          setAllProjects(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => { });
  };

  const getEmployeeBasedProjectsData = (id) => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.getEmployeeBasedProjects, {
      "projectID": id
    })
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEmplyoees(res.data.data);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const OpenTransferModel = (Itemdata) => {
    setIsLoading(true)
    console.log("Itemdata", Itemdata);
    setTransferPayload({
      projectID: Itemdata?.assignedProjectID || projectDetails.projectID,
      CategoryName: Itemdata?.cat,
      SubCategory: Itemdata?.subCat,
      itemName: Itemdata?.itemName,
      availableQuantity: Itemdata?.grnQty,
      transferTo: Itemdata?.transferTo,
      itemID: Itemdata?.itemID,
      comment: Itemdata?.comment,
      transferQty: ''

    });
    getEmployeeBasedProjectsData(Itemdata?.assignedProjectID || projectDetails.projectID)
    setTimeout(() => {
      setIsTransfer(true);
      setIsLoading(false);
    }, 2000);
  };


  const OpenThresholdModel = (Itemdata) => {
    setIsLoading(true)
    console.log("Itemdata", Itemdata);
    setThresholdPayload({
      projectID: projectDetails.projectID,
      CategoryName: Itemdata?.cat,
      SubCategory: Itemdata?.subCat,
      itemName: Itemdata?.itemName,
      availableQuantity: Itemdata?.grnQty,
      itemID: Itemdata?.itemID,
      count: Itemdata?.ThresholdCount,
    });
    setTimeout(() => {
      setIsThresholdModel(true);
      setIsLoading(false);
    }, 2000);
  };

  const submitThresholdCount = () => {
    setIsLoading(true);
    let payload = {
      projectID: projectDetails?.projectID,
      itemID: thresholdPayload?.itemID ? Number(thresholdPayload.itemID) : '',
      ThresholdCount: thresholdPayload?.count ? Number(thresholdPayload.count) : ''
    }
    APIKit.post(RGCA_URLS.addorUpdateThresholdCount, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setIsThresholdModel(false);
          toast.success(res.data.message);
          getInventoryList();
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const submitTrasferRecurring = () => {
    setIsLoading(true);
    let payload = {
      FromProjectID: transferPayload?.projectID ? Number(transferPayload?.projectID) : '',
      ItemID: transferPayload?.itemID ? Number(transferPayload?.itemID) : '',
      TransferQty: transferPayload?.transferQty ? Number(transferPayload?.transferQty) : '',
      ToProjectID: transferPayload?.transferTo ? Number(transferPayload?.transferTo) : '',
      comment: transferPayload?.comment ? transferPayload?.comment : ''
    }
    console.log("payload", payload);
    APIKit.post(RGCA_URLS.raiseStoreIndentforRecurringTransfer, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setIsTransfer(false);
          toast.success(res.data.message);
          getInventoryList();
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }



  return (
    <Page className={classes.root} title="Inventory Register">
      <>
        <Loader isLoading={isLoading} />
        <ToastContainer />
        <>
          <div>
            <Box>
              <Grid spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    marginTop: "10px",
                    color: "#3f51b5",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                ></Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <div>
                    <div className="row">
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            searchIR(e.target.value);
                          }}
                          inputRef={textInput}
                          placeholder="Search with name, category ...etc"
                          variant="outlined"
                          className={classes.txt}
                        />
                        <Box display="flex" justifyContent="flex-end">
                          <Box sx={{ minWidth: 150, margin: 2 }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Category
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                onChange={(e) => {
                                  const p = addSubCategory;
                                  p.categoryID = e.target.value;
                                  setAddCategory({
                                    categoryID: e.target.value,
                                  });
                                  getSubCategories(e.target.value);
                                }}
                                value={addSubCategory.categoryID}
                                autoWidth
                                disabled={!categoryList.length}
                                label="Category"
                              >
                                {categoryList &&
                                  categoryList.map((x) => {
                                    return (
                                      <MenuItem
                                        className="selct-mui"
                                        value={x.categoryID}
                                        key={x.categoryID}
                                      >
                                        {""}
                                        {x.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ minWidth: 150, margin: 2 }}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                disabled={addSubCategory.categoryID === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => {
                                  const p = addItem;
                                  p.subCategory = e.target.value;
                                  subCategoryValues.subCategory =
                                    e.target.value;
                                  setAddItem({
                                    ...p,
                                    subCategory: e.target.value,
                                  });
                                  handleChangeSubCat(e);
                                }}
                                required
                                value={addItem.subCategory}
                                name="subCategory"
                                label="Sub Category"
                              >
                                {subCategoryList &&
                                  subCategoryList.map((x) => {
                                    return (
                                      <MenuItem
                                        value={x.subCategoryID}
                                        key={x.subCategoryID}
                                      >
                                        {x.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ minWidth: 70, margin: 2 }}>
                            <Button
                              color="primary"
                              style={{
                                backgroundColor: "#394671",
                                color: "#fff",
                                border: "1px solid",
                                marginTop: '8px'
                              }}
                              // onClick={handleGeneratePO}
                              type="submit"
                              variant="contained"
                              onClick={() => filterClear()}
                            >
                              Reset
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>
          <Box mt={3}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Item Id</TableCell>
                    <TableCell align="left">Item Category</TableCell>
                    <TableCell align="left">Item Sub Category</TableCell>
                    <TableCell align="left">Item Name</TableCell>
                    <TableCell align="left">Brand</TableCell>
                    <TableCell align="left">Available Quantity</TableCell>
                    <TableCell align="left">Last Price</TableCell>
                    <TableCell align="left">Average Rate</TableCell>
                    <TableCell align="left">UOM</TableCell>
                    <TableCell align="left">Threshold Count</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryList && inventoryList.length != 0 ? (
                    inventoryList
                      .slice(
                        page1 * rowsPerPage1,
                        page1 * rowsPerPage1 + rowsPerPage1
                      )
                      .map((z, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">{z.itemID}</TableCell>
                            <TableCell align="left">{z.cat}</TableCell>
                            <TableCell align="left" style={{ overflowWrap: 'break-word' }}>{z.subCat}</TableCell>
                            <TableCell align="left">{z.itemName}</TableCell>
                            <TableCell align="left">{z.brand}</TableCell>
                            <TableCell align="left">{z.grnQty}</TableCell>
                            <TableCell align="left">{z.lastPrice ? parseFloat(z?.lastPrice)?.toFixed(2) : "-"}</TableCell>
                            <TableCell align="left">{z.averageRate ? parseFloat(z?.averageRate)?.toFixed(2) : "-"}</TableCell>
                            <TableCell align="left">{z.uom}</TableCell>
                            <TableCell align="left">{z.ThresholdCount ? z.ThresholdCount : '-'}</TableCell>
                            <TableCell align="left">
                              <div className="row" style={{ display: 'flex', gap: '2px' }} >
                                <Tooltip title="Add Dispose">
                                  <RemoveCircleIcon
                                    onClick={() => {
                                      damageModal(z);
                                      isSetDamageHistory(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                                {/* <Tooltip title="Damage History">
                                  <HistoryIcon
                                    onClick={() => {
                                      getBrokenHistory(z.itemID);
                                      isSetDamageHistory(false);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip> */}
                                <Tooltip title="Add Threshold">
                                  <EditIcon onClick={() => { OpenThresholdModel(z); }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                                <Tooltip title="Transfer">
                                  <AssignmentIndIcon
                                    onClick={() => {
                                      OpenTransferModel(z);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow hover role="checkbox" key={1}>
                      <TableCell colSpan={10} align="center" key={2}>
                        {"No Data Found"}
                      </TableCell>{" "}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {inventoryList && inventoryList.length > 10 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={inventoryList.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
              />
            )}
          </Box>
        </>
      </>

      {/* BROKEN ITEM MODAL */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={damageModalOpen}
        id="income"
        contentStyle={{
          width: "50%",
          maxWidth: "none",
        }}
        maxWidth="md"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={(e) => { setDamageModalOpen(!damageModalOpen); }}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        >
          {isDamageHistory ? "ADD DISPOSE ITEMS" : "DISPOSE HISTORY"}
        </DialogTitle>
        {isDamageHistory ? (
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Category Name"
                  name="CategoryName"
                  value={disposedPayload?.categoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Sub Category"
                  name="SubCategory"
                  value={disposedPayload?.subCategoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Item Name"
                  name="itemName"
                  value={disposedPayload?.name}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Available Quantity"
                  name="availableQuantity"
                  value={disposedPayload?.itemQuty}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Enter QTY"
                  name="enterqty"
                  value={disposedPayload?.Qty}
                  variant="outlined"
                  required
                  // inputProps={{ maxLength: 200 }}
                  onChange={e => {
                    let item = disposedPayload;
                    const inputValue = e.target.value;
                    if (inputValue === '' || regEx.numbersOnly.test(inputValue)) {
                      if (Number(inputValue) >= item.itemQuty) {
                        item.Qty = item.itemQuty;
                      } else {
                        item.Qty = Number(inputValue);
                      }
                      setDisposedPayload({ ...item });
                    }
                  }}
                // disabled={true}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label=" Comment"
                  variant="outlined"
                  required
                  inputProps={{ maxLength: 200 }}
                  value={disposedPayload?.comment}
                  onChange={e => {
                    const p = disposedPayload;
                    p.comment = e.target.value;
                    setDisposedPayload({ ...p });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid item md={12} xs={12}>
              <Box>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">S.No</TableCell>
                      <TableCell align="left">Quantity</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Disposed By</TableCell>
                      <TableCell align="left">Comment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {damageHistoryList && damageHistoryList.length > 0 ? (
                      damageHistoryList.slice(
                        page * rowsPerPage1,
                        page1 * rowsPerPage1 + rowsPerPage1
                      ).map((xx, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{xx.bQty}</TableCell>
                            <TableCell align="left">{moment(xx.createdDate).format("DD-MM-yyyy")}</TableCell>
                            <TableCell align="left">{xx.empName}</TableCell>
                            <TableCell align="left">{xx?.comment}</TableCell>
                          </TableRow>
                        );
                      })) : (
                      <TableRow hover role="checkbox" key={1}>
                        <TableCell colSpan={10} align="center" key={2}>{'No Data Found'}</TableCell>
                      </TableRow>)}
                  </TableBody>
                </Table>
                {damageHistoryList && damageHistoryList?.length > 10 &&
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={damageHistoryList.length}
                    rowsPerPage={rowsPerPage1}
                    page={page1}
                    onPageChange={handleChangePage1}
                    onRowsPerPageChange={handleChangeRowsPerPage1}
                  />
                }
              </Box>
            </Grid>
          </DialogContent>
        )}
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button onClick={(e) => { setDamageModalOpen(!damageModalOpen); }}
            style={{ backgroundColor: "#394671", color: "#fff", border: "1px solid", }}
          >Cancel</Button>
          {isDamageHistory ? (
            <Button
              style={{ backgroundColor: "#394671", color: "#fff", border: "1px solid", }}
              disabled={!disposedPayload?.comment || !disposedPayload?.Qty}
              onClick={addBrokenItem}
            > Update</Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>

      {/* END BROKEN ITEM MODAL  */}


      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isTransfer}
        id="assign"
        contentStyle={{ width: "50%", maxWidth: "none", }}
        maxWidth="md"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={(e) => {
            setIsTransfer(false);
          }}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        >
          Transfer Item
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Projects*
                </InputLabel>
                <Select
                  defaultValue={transferPayload.projectID}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="projectName"
                  onChange={(e) => {
                    getEmployeeBasedProjectsData(e.target.value)
                  }}
                  value={transferPayload.projectID}
                  label="Projects"
                  disabled
                >
                  {allProjects &&
                    allProjects.length > 0 &&
                    allProjects.map(x => {
                      return (
                        <MenuItem value={x.projectID} key={x.projectID} >
                          {x.projectName}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Item Category"
                name="itemCategory"
                value={transferPayload.CategoryName}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Item Sub Category"
                name="SubCategory"
                value={transferPayload.SubCategory}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Item Name"
                name="itemName"
                value={transferPayload.itemName}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Available Quantity	"
                name="availableQuantity	"
                value={transferPayload.availableQuantity}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Enter Quantity	"
                name="enterQuantity	"
                value={transferPayload.transferQty}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                required
                onChange={(e) => {
                  let item = transferPayload;
                  const inputValue = e.target.value;
                  if (inputValue === '' || regEx.numbersOnly.test(inputValue)) {
                    if (Number(inputValue) >= item.availableQuantity) {
                      item.transferQty = item.availableQuantity;
                    } else {
                      item.transferQty = Number(inputValue);
                    }
                    setTransferPayload({ ...item });
                  }
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                className={classes.formControl}
              >
                <InputLabel required id="demo-simple-select-outlined-label">
                  Transfer To
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="transferTo"
                  onChange={(e) => {
                    let item = transferPayload
                    item.transferTo = e.target.value
                    setTransferPayload({ ...item })
                  }}
                  value={transferPayload.transferTo}
                  label="Transfer To"
                >
                  {allProjects && allProjects.length > 0 && allProjects.map(x => {
                    return (
                      <MenuItem value={x.projectID} key={x.projectID} >{x.projectName}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                label="Transfer Comment"
                variant="outlined"
                onChange={(e) => {
                  let item = transferPayload
                  item.comment = e.target.value
                  setTransferPayload({ ...item })
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button onClick={(e) => { setIsTransfer(false); }}>Cancel</Button>
          <Button disabled={!transferPayload.transferQty || !transferPayload?.comment || !transferPayload.transferTo}
           onClick={() => { submitTrasferRecurring(); }}>Submit</Button>
        </DialogActions>
      </Dialog>


      {/* Threshold Model */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isThresholdModel}
        id="income"
        contentStyle={{ width: "50%", maxWidth: "none", }}
        maxWidth="md"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle id="scroll-dialog-title"
          onClose={(e) => { setIsThresholdModel(false); }}
          style={{ backgroundColor: "#394671", color: "#fff" }}
        > Add / Update Threshold Count
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Item Id"
                name="ItemId"
                value={thresholdPayload?.itemID}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Category Name"
                name="CategoryName"
                value={thresholdPayload?.CategoryName}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Sub Category"
                name="SubCategory"
                value={thresholdPayload?.SubCategory}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Item Name"
                name="itemName"
                value={thresholdPayload?.itemName}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Available Quantity"
                name="availableQuantity"
                value={thresholdPayload?.availableQuantity}
                variant="outlined"
                inputProps={{ maxLength: 200 }}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Enter Threshold Count"
                name="enterqty"
                value={thresholdPayload?.count}
                variant="outlined"
                required
                inputProps={{ maxLength: 10 }}
                onChange={e => {
                  let item = thresholdPayload;
                  const inputValue = e.target.value;
                  if (inputValue === '' || regEx.numbersOnly.test(inputValue)) {
                    item.count = e.target.value
                    setThresholdPayload({ ...item });
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
          <Button onClick={(e) => { setIsThresholdModel(false); }}
          >Cancel</Button>
          <Button disabled={!thresholdPayload?.count}
            onClick={() => { submitThresholdCount(); }} >Submit</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default InventoryRep;
