import * as React from 'react';
import {Typography,Box} from '@material-ui/core';
import Address from './Address';

const  AddressSingleLine= ({details = null}) => {
    if(!details){
        return null;
    }
    const {   line_1,
    line_2,
    line_3,
    district,
    state,
    country,
    postalCode} = JSON.parse(details)[0];

    // let first = line_1;
      // if(line_2) first+= first?`, ${line_2}`:"";
      // if(line_3) first+= first?`, ${line_3}`:"";
      //  if(district) first+= first
      
    return (
      <Box sx={{ width: '100%', maxWidth: 500 }}>
       <Typography variant="body1" gutterBottom style={{color:'#ba88c3'}}>
     <b>Address :</b> {line_1}
      </Typography>
      {/* <Typography variant="body2" gutterBottom>
          {second}
      </Typography> */}
      </Box>
    );
  }

  export default AddressSingleLine;