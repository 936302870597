import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button
} from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import { ETTypes, EEditable, ETaction } from './Types';
import { currencyFormat1, dateFormate } from './utilities';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { RGCA_URLS } from '../../../utils/url';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const CommonTable1 = ({ columns, data = [], action, editLock,isEnabled, isDeletedoption,isprint }) => {
  
  console.log("editLock",data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const render = (head, row, index, editLocked) => {
    if (head.field === 'Action' || head.field === 'action') {
      let onView = head.list.find(x => x === ETaction.onView)
      let onDelete = head.list.find(x => x === ETaction.onDelete)
      let onEdit = head.list.find(x => x === ETaction.onEdit)
      let onPrint = head.list.find(x => x === ETaction.onPrint)
      return <>
        {onView && (<VisibilityIcon style={{ margin: "5px", cursor: "pointer" }}
          onClick={(e) => {
            if (action && action.onView) {
              action.onView(index, row)
            }
          }} />)}
        {onEdit && (<EditIcon style={{ margin: "5px", cursor: "pointer" }} 
        onClick={(e) => {
          if (action && action.onEdit) {
            action.onEdit(index, row)
          }
        }} />)}
        {/* {onDelete &&
          // !(editLock?.some(e => e.status != "Awaiting") && editLock.every(e => e.status != "Modification")) && 

          (onDelete && !(editLock?.some(e => e.status != "Awaiting") && editLock.every(e => e.status != "Modification")) && data.length > 0 ? */}
          {(isDeletedoption ?
           <DeleteOutlineIcon style={{ margin: "5px", cursor: "pointer" }}
              onClick={(e) => {
                if (action && action.onDelete) {
                  action.onDelete(index, row)
                }
              }} /> :   <Tooltip title="Disabled"><DeleteOutlineIcon style={{ margin: "5px", cursor: "pointer" }} disabled/></Tooltip>)}
        {onPrint && (<Button style={{ margin: "5px", cursor: "pointer" }}
          color="primary"
          variant="contained"
          onClick={(e) => {
            if (action && action.onPrint) {
              action.onPrint(index, row)
            }
          }} >Print View</Button>)}
      </>

    }
    else if (head.editable && head.editable === EEditable.onEdit) {
      return (
        <TextField
          fullWidth
          helperText={head.helperText || ""}
          label={head.title}
          name={head.title}
          inputProps={{ maxLength: head.type === ETTypes.numeric? 15 :  25}}
          onChange={(e) => {
            if (action && action.editRow) {
              if (head.type === ETTypes.numeric) {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  action.editRow(index, head.field, e.target.value ? Number(e.target.value) : 0);
                }
              }
              else {
                action.editRow(index, head.field, e.target.value);
              }
            }

          }}
          required
          disabled = {!isEnabled}
          // disabled={editLock?.some(e => e.status != "Awaiting") && editLock?.every(e => e.status != "Modification")}
          value={row[head.field]}
          variant="outlined"
        />
      )
    }
    else {
      if (head.type == ETTypes.string) {
        return row[head.field]
      }
      else if (head.type == ETTypes.numeric) {
        return row[head.field] ? currencyFormat1(row[head.field]) :''
      }
      else if (head.type == ETTypes.date) {
        return row[head.field] ? dateFormate(row[head.field]) : ""
      }
      // else if (head.type === ETTypes.link) {
      //   return <a style={{ color: 'blue', cursor: 'pointer' }}
      //     href={row[head.field]} target="_blank"
      //   >{(row[head.field]).substring(4, 25)}
      //   </a>
      // }
      else if (head.type === ETTypes.link) {
        return (row[head.field] !== undefined && row[head.field] !== null && row[head.field] !== '') ? (
            <a style={{ color: 'blue', cursor: 'pointer' }} href={row[head.field]} target="_blank">
                {(row[head.field] && row[head.field].substring(4, 25)) || ''}
            </a>
        ) : (
            '-'
        );
    }
      else if (head.type == ETTypes.boolean) {
        return row[head.field] ? 'Yes' : 'No'
      }

    }
  }

  return <> <TableContainer sx={{ maxHeight: 440 }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {columns.map((head, index) => <TableCell key={index} align={head.align ? head.align : "left"}>{head.title}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {data.map((row, i) => <TableRow hover role="checkbox" key={i + 1}> */}
        { !isprint?data?.length != 0 && data ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => <TableRow hover role="checkbox" key={i + 1}>
          {columns.map((head) => <TableCell key={i + head.field} align={head.align ? head.align : "left"}>{render(head, row, i)}</TableCell>)}
        </TableRow>) : <TableRow hover role="checkbox" key={1}><TableCell colSpan={columns.length} align="center" key={2}>{'No Data Found'}</TableCell>  </TableRow>:<>
        { data?.length != 0 && data.map((row, i) => <TableRow hover role="checkbox" key={i + 1}>
          {columns.map((head) => <TableCell key={i + head.field} align={head.align ? head.align : "left"}>{render(head, row, i)}</TableCell>)}
        </TableRow>)}</>}



      </TableBody>
    </Table>
  </TableContainer>

    { !isprint    && data?.length > 5 ?
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={data ? data.length : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />:""}
  </>

}

export default CommonTable1;
