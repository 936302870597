import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography, Card, CardContent, makeStyles, Grid, Button, Dialog, DialogTitle, DialogContent, TableContainer, Box, Table, TableBody, TableCell, TableHead, TableRow, TablePagination
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { ETTypes, EEditable, ETaction } from '../Common/Types';
import CommonTable from '../Common/CommonTable';
import { RGCA_URLS } from './../../../utils/url';
import { getProjectDetails, parseData, dateFormate } from '../Common/utilities';
import APIKit from 'src/utils/APIKit';
import { useParams } from "react-router-dom";
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import CommonTable1 from '../Common/commonTableIndent';
import StepperBox from '../Common/StepperBox.jsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(() => ({
    root: {}
}));

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const Assigned = ({ assigned }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const { project } = useParams();

    // const projectDetails = { ...getProjectDetails(project) }
    // const [assigned, setAssigned] = useState([])

    // useEffect(() => {
    //     getAssignedFarData()
    // }, [projectDetails?.projectID])

    // const getAssignedFarData = () => {
    //     let userData = JSON.parse(localStorage.getItem('userData'));
    //     console.log("userData", userData);

    //     let payload = {
    //         projectID: projectDetails?.projectID,
    //         assignedTo: userData?.empLoginID
    //     }
    //     APIKit.post(
    //         RGCA_URLS.getAssignedFarData, payload
    //     )
    //         .then(res => {
    //             if (res.data.status === 200) {
    //                 console.log("getAssignedFarData", res.data.data);
    //                 setAssigned(res.data.data)
    //             } else {
    //             }
    //         })
    //         .catch(e => { });
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <Accordion defaultExpanded={false} style={{ borderRadius: "12px", boxShadow: "0 0 10px #3979c3" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >
                        <b>My Assets Assigned</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>

                    <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Id</TableCell >
                                        <TableCell align="left">Item Name</TableCell >
                                        <TableCell align="left">Assigned By</TableCell >
                                        <TableCell align="left">Assigned Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assigned && assigned.length != 0 ? (
                                        assigned.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                    <TableCell align="left">{x?.farMasterID}</TableCell>
                                                    <TableCell align="left">{x?.name}</TableCell>
                                                    <TableCell align="left">
                                                        {x?.receivedBy ?
                                                            <>
                                                                {x?.receivedBy} <br /> ({x?.employeeName})
                                                            </>
                                                            : ""} </TableCell>
                                                    <TableCell align="left">
                                                        {moment(x?.assignedDate).format('DD-MM-YYYY')}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {assigned && assigned.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={assigned?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default Assigned