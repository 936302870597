import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Container,

} from '@material-ui/core';

import Page from 'src/components/Page';
import { ToastContainer, toast } from 'react-toastify';


import APIKit from "../../utils/APIKit"
import { RGCA_URLS } from '../../utils/url';

import { useConfirm } from "material-ui-confirm";
import Grid from '@material-ui/core/Grid';
import Loader from '../loader'
import { useToasts } from 'react-toast-notifications';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }
}));

const LookUp = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [lookUps, setLookUps] = useState([])
  const [addDesignation, setAddDesination] = useState({ "name": "", "fullName": "" })
  const [btn, setBtn] = React.useState({ "name": "Save" });

  useEffect(() => {
    getLookups();
  }, [])


  const getLookups = () => {

    APIKit.get(RGCA_URLS.getLookups)
      .then((res) => {

        if (res.data.status === 200) {
          setLookUps(res.data.data)
        }
        else {
          //toast.error(res.data.message);
        }
      })
      .catch(function (e) {
      })
  }


  return (
    <Page
      className={classes.root}
      title="Designation">
      <ToastContainer />
      <Container maxWidth={false}>
        <Loader isLoading={isLoading} />
        <div>

          <Box
            display="flex"
            justifyContent="flex-end"
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold' }}>
                Lookup ID
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>

              </Grid>
            </Grid>

          </Box>



        </div>
        <Box mt={3}>
          <Table className={classes.table}>
            <TableHead>


              <TableRow>

                <TableCell >
                  S.No
                </TableCell>
                <TableCell >
                  Type
                </TableCell>
                <TableCell >
                  Lookup ID
                </TableCell>
                <TableCell >
                  Name
                </TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {lookUps && lookUps.length != 0 && lookUps.map((l, index) => {
                return <TableRow
                  hover
                  key={l.lookupID}
                >

                  <TableCell >
                    {index + 1}
                  </TableCell>
                  <TableCell >
                    {l.type}
                  </TableCell><TableCell >
                    {l.lookupID}
                  </TableCell>
                  <TableCell >
                    {l.name}
                  </TableCell>


                </TableRow>
              })}

            </TableBody>
          </Table>
        </Box>


      </Container>
    </Page>
  )

}
export default LookUp;
