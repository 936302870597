import Loader from 'src/views/loader';
import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Page from 'src/components/Page';
import PrintIcon from '@material-ui/icons/Print';
import { Search as SearchIcon } from 'react-feather';
import { KeyboardDatePicker } from '@material-ui/pickers'; import 'date-fns';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HistoryIcon from '@material-ui/icons/History';
import { Link as RouterLink, useNavigate, useLocation, BrowserRouter as Router, useParams } from 'react-router-dom';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import MuiAlert from '@material-ui/lab/Alert';
import { Icon } from '@iconify/react';
import currencyInr from '@iconify-icons/mdi/currency-inr';
import { numberFormat } from '../../../utils/common';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import APIKit from '../../../utils/APIKit';
import { Data } from './data';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1, dateOnly, dateOnlyRev, getProjectDetails, parseData, precision } from '../Common/utilities';
import AutoCompleteField from '../AutoCompleteField/index';
import { ToastContainer, toast } from 'react-toastify';
import Address from '../Common/Address';
import Bank from '../Common/Bank';
import { useConfirm } from 'material-ui-confirm';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { number } from 'prop-types';
import receiptLogo from '../../Project/Selling/receipt.png';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Tooltip } from '@mui/material';
import SellingData from './Sales.json';
import TableDataHistory from './payment.json';
import PaymentIcon from '@material-ui/icons/Payment';
import Addresss from './sellingAddress';
import AddSellings from './AddSellings';
import EditSelling from './editSelling';
import PaymentScreen from './PaymentScreen';
import PaymentHistory from './PaymentHistory';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useReactToPrint } from 'react-to-print';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AppBar from '@material-ui/core/AppBar';
import DialogContentText from '@mui/material/DialogContentText';
import LabTest from './labtest';
import EditLab from './editLab';
import LabTestPayment from './LabTestPayment';
import LabTestPaymentHistory from './LabTestPaymentHistory';
import LabInvoiceView from './labInvoice';
import ListSale from './listSale';
import LabTestServices from './labtestservices';
import PriceManagement from './pricemangement';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const Selling = () => {
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (_event, newValue) => {
        setValue(newValue);
    };
    const a11yProps = index => {
        if (index == 0) {
            getItemsList()
            // getInventoryList();
        }
        if (index == 1) {
            // getMaterialsForSelling();
        }
        if (index == 2) {
            // getPaymentList();
        }
        // if (index == 3) {
        //   getPaymentList()
        // }

        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const classes = useStyles();
    const confirm = useConfirm();
    const [openStart, setOpenStart] = React.useState(false);
    const [openEnd, setOpenEnd] = React.useState(false);

    const [isBatches, setIsBatches] = useState([]);
    const [isStartSale, setIsStartSale] = useState([]);
    const [isEndSale, setIsEndSale] = useState([]);
    const [isBatchId, setIsBatchId] = useState('')
    const [isCategory, setIsCategory] = useState('')
    const [isDate, setIsDate] = useState(new Date())
    const [alertopen, setAlertOpen] = useState(false);
    const [openTest, setOpenTest] = useState(false);
    const [isPayment, setIsPayment] = useState(false);
    const [isLabPayment, setIsLabPayment] = useState(false);
    const [openTest1, setOpenTest1] = useState(false);
    const [alertMeg, setAlertMeg] = useState('');
    const [errorMeg, setErrorMeg] = useState('');
    const [open, setOpen] = useState(false);
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) };
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({});
    const [amountError, setAmountError] = useState('');
    const [amount, setAmount] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modeOfReciept, setModeOfReciept] = useState(0);
    const [modeOfRecieptError, setModeOfRecieptError] = useState();
    const [customer, setCustomer] = useState(0);
    const [customerError, setCustomerError] = useState('');
    const [purpose, setPurpose] = useState(0);
    const [purposeError, setPurposeError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lookupData, setLookupData] = useState([]);
    const [lookupData41, setLookupData41] = useState([]);
    const [lookData, setLookData] = useState([]);
    const [allCustomer, setAllCustomer] = useState([]);
    const [morAttach, setMorAttach] = useState({});
    const [paymentHistry, setPaymentHistry] = useState(false);
    const [receiptList, setReceiptList] = useState([]);
    const [receipt, setReceipt] = useState({
        sellingMasterID: null,
        amount: null,
        mor: null,
        customer: null,
        purpose: null,
        others: '',
        morAttach: '',
        transferNo: '',
        bank: '',
        receiptDate: '',
        receiptNotes: '',
        receiptFrom: '',
        bankAccount: "",
        bankName: "",
        TranscationID: "",
        ifscCode: ""
    });
    const [labReceipt, setLabReceipt] = useState({
        labTestDataMasterID: "",
        projectID: null,
        amount: null,
        mor: null,
        totalAmountPaid: null,
        morAttach: "",
        customerID: null,
        purpose: null,
        transferNo: "",
        receiptNotes: "",
        upiType: "",
        bankAccount: "",
        bankName: "",
        ifscCode: "",
        Type: '',
        LabTestreceiptsDataID: ''
    })
    const [allVendors, setAllVendors] = useState([]);
    const [btn, setBtn] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [isLabHistory, setIsLabHistory] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openinc, setIncOpen] = useState(false);
    const [allSelling, setAllSelling] = React.useState([]);
    const [sellingItems, setSellingItems] = useState({});
    const [openincPrint, setOpenincPrint] = useState(false);
    const [receiptPrintViewObj, setReceiptPrintViewObj] = React.useState({});
    const [dataForEdit, setDataForEdit] = useState([]);
    const [isAddDialog, setIsAddDialog] = useState(false)
    const [isAddDialoglab, setIsAddDialoglab] = useState(false)
    const [isLabTestServ, setIsLabTestServ] = useState(false)
    const [isSaleStart, setIsSaleStart] = useState(false)
    const [isSaleEnd, setIsSaleEnd] = useState(false)
    const [isEditDialog, setIsEditDialog] = useState(false)
    const [isEditDialogLab, setIsEditDialogLab] = useState(false)
    const [isViewDialogLab, setIsViewDialogLab] = useState(false)
    const [receiptID, setReceiptID] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false)
    const [receiptIDHistory, setReceiptIDHistory] = useState('')
    const [histryData, setHistryData] = useState([])
    const htmlContentRef = useRef(null);
    const [allProjects, setAllProjects] = useState([]);
    const [labHistoryData, setLabHistoryData] = useState([]);
    const [isInvoiceLab, setIsInvoiceLab] = useState(false);
    const [invoiceLabData, setInvoiceLabData] = useState({});
    const [salesListData, setSalesListData] = useState([]);
    const [packagedData, setPackagedData] = useState([])


    useEffect(() => {
        getcustomer();
        getItemsList();
        getSaleEndList();
        getSaleStartList();
        getLookup();
        getMaterialsForSelling();

        getLookupData();
        getAllProjects();
    }, []);

    const getLookup = () => {
        APIKit.get(`${RGCA_URLS.lookup}/16`)
            .then(res => {
                if (res.data.status === 200) {
                    setLookupData(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(e => { });
    };

    const handleClickIncOpen = () => {
        setIsAddDialog(true);
    };

    const searchSelling = e => {
        if (e) {
            const payload = {
                projectID: projectDetails.projectID,
                searchText: e
            };
            APIKit.post(RGCA_URLS.getMaterialsForSelling, payload)
                .then(res => {
                    if (res.data.status === 200) {
                        setAllSelling(res.data.data);
                    } else {
                    }
                })
                .catch(e => { });
        } else {
            getMaterialsForSelling();
        }
    };
    const searchSelling2 = e => {
        if (e) {
            const payload = {
                projectID: projectDetails.projectID,
                searchText: e
            };
            APIKit.post(RGCA_URLS.getLabTestQuery, payload)
                .then(res => {
                    if (res.data.status === 200) {
                        setAllSelling(res.data.data);
                    } else {
                    }
                })
                .catch(e => { });
        } else {
            getMaterialsForSelling2();
        }
    };

    const getMaterialsForSelling = () => {

        setIsLoading(true);
        let payload = {
            projectID: projectDetails.projectID
        };
        APIKit.post(RGCA_URLS.getMaterialsForSelling, payload)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('getSellingQuery', res.data.data);
                    if (res?.data?.data) {
                        let data = res?.data?.data.map((x, i) => {
                            return {
                                ...x,
                                sellingItem: parseData(x.sellingItem)
                            };
                        });
                        setAllSelling(data);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }

                } else {
                    setIsLoading(false);
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
        // console.log('text-text',payload)
    };
    const getMaterialsForSelling2 = () => {
        setIsLoading(true);
        let payload = {
            projectID: projectDetails.projectID
        };
        APIKit.post(RGCA_URLS.getLabTestQuery, payload)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('getSellingQuery', res.data.data);
                    let data = res.data.data.map((x, i) => {
                        return {
                            ...x,
                            sellingItem: parseData(x.sellingItem)
                        };
                    });
                    setAllSelling(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
        // console.log('text-text',payload)
    };

    const handleIncPrintClose = data => {

        console.log('datadatadatadata', data);
        setSellingItems(data);
        setOpenincPrint(!openincPrint);
    };

    const openAddVendor = x => {
        // console.log('receipts', JSON.parse(x?.receipts));
        let receiptID = JSON.parse(x?.receipts)
        // console.log('cccc', receiptID[0]?.receiptID);
        setReceipt({
            sellingMasterID: x.sellingMasterID,
            amount: x?.totalAmount,
            mor: '',
            customer: x.customerID,
            purpose: '',
            others: '',
            morAttach: '',
            transferNo: '',
            bank: '',
            receiptDate: x.createdDate,
            receiptNotes: '',
            receiptFrom: '',
            upiType: '',
            bankAccount: "",
            totalAmountPaid: '',
            receiptID: receiptID[0]?.receiptID,
            remaningBalance: x?.remaningBalance,
            totalAmountPaid: ''

        });
        setIsPayment(false);
        setIsPayment(true);
        setBtn(false);
    };

    const OpenHistryTable = x => {
        console.log('data', x);
        setHistryData([])
        getsalesHistoryData(x?.sellingMasterID)
        setTimeout(() => {
            setIsHistory(true);
        }, 1000);
    };

    const EditRowData = (x) => {
        console.log("xxxx",x);
        setDataForEdit(x);
        setTimeout(() => {
            setIsEditDialog(true);
        }, 1000);
    };
    // console.log("dataForEdit", dataForEdit);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getcustomer = () => {
        APIKit.post(RGCA_URLS.listCustomer, {
            projectID: projectDetails.projectID
        })
            .then(res => {
                console.log('logggg:', res);
                if (res.data.status === 200) {
                    setAllCustomer(res.data.data);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const getReceipts = () => {
        const payload = {
            projectID: projectDetails.projectID
        };
        APIKit.post(RGCA_URLS.getReceipts, payload)
            .then(res => {
                if (res.data.status === 200) {
                    let data = res.data.data.map(x => {
                        return {
                            ...x
                        };
                    });
                    console.log(data);
                    setReceiptList(data);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const getLookupData = () => {
        APIKit.get(`${RGCA_URLS.lookup}/47`)
            .then(res => {
                if (res.data.status === 200) {
                    setLookData([...res.data.data]);
                    //setIndentItemList([{ ...itemInitialValues, typeList: res.data.data }])
                    console.log(res.data.data);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const price_in_words = price => {
        var sglDigit = [
            'Zero',
            'One',
            'Two',
            'Three',
            'Four',
            'Five',
            'Six',
            'Seven',
            'Eight',
            'Nine'
        ],
            dblDigit = [
                'Ten',
                'Eleven',
                'Twelve',
                'Thirteen',
                'Fourteen',
                'Fifteen',
                'Sixteen',
                'Seventeen',
                'Eighteen',
                'Nineteen'
            ],
            tensPlace = [
                '',
                'Ten',
                'Twenty',
                'Thirty',
                'Forty',
                'Fifty',
                'Sixty',
                'Seventy',
                'Eighty',
                'Ninety'
            ],
            handle_tens = function (dgt, prevDgt) {
                return 0 == dgt
                    ? ''
                    : ' ' + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
            },
            handle_utlc = function (dgt, nxtDgt, denom) {
                return (
                    (0 != dgt && 1 != nxtDgt ? ' ' + sglDigit[dgt] : '') +
                    (0 != nxtDgt || dgt > 0 ? ' ' + denom : '')
                );
            };

        var str = '',
            digitIdx = 0,
            digit = 0,
            nxtDigit = 0,
            words = [];
        if (((price += ''), isNaN(parseInt(price)))) str = '';
        else if (parseInt(price) > 0 && price.length <= 10) {
            for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
                switch (
                ((digit = price[digitIdx] - 0),
                    (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
                    price.length - digitIdx - 1)
                ) {
                    case 0:
                        words.push(handle_utlc(digit, nxtDigit, ''));
                        break;
                    case 1:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 2:
                        words.push(
                            0 != digit
                                ? ' ' +
                                sglDigit[digit] +
                                ' Hundred' +
                                (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                                    ? ' and'
                                    : '')
                                : ''
                        );
                        break;
                    case 3:
                        words.push(handle_utlc(digit, nxtDigit, 'Thousand'));
                        break;
                    case 4:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 5:
                        words.push(handle_utlc(digit, nxtDigit, 'Lakh'));
                        break;
                    case 6:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 7:
                        words.push(handle_utlc(digit, nxtDigit, 'Crore'));
                        break;
                    case 8:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 9:
                        words.push(
                            0 != digit
                                ? ' ' +
                                sglDigit[digit] +
                                ' Hundred' +
                                (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                                    ? ' and'
                                    : ' Crore')
                                : ''
                        );
                }
            str = words.reverse().join('');
        } else str = '';
        return str;
    };

    let totalQty = 0;
    let totalPrice = 0;
    let totalAmount = 0;
    let totalTax = 0

    const getsalesHistoryData = (sellingMasterID) => {
        setIsLoading(true)
        const payload = {
            projectID: projectDetails.projectID,
            sellingMasterID: sellingMasterID
        };
        APIKit.post(RGCA_URLS.getsalesHistoryData, payload)
            .then(res => {
                if (res.data.status === 200) {
                    // let data = res.data.data.map(x => {
                    //     return {
                    //         ...x
                    //     };
                    // });
                    console.log("res.data.", res.data.data);
                    setHistryData(res?.data?.data)
                    setIsLoading(false)

                    // setReceiptList(data);
                } else {
                    setIsLoading(false)

                }
            })
            .catch(function (e) { });
    };


    const printOrderNew = useReactToPrint({
        content: () => htmlContentRef.current,
    });

    let seenBatchNumbers = new Set();
    const getItemsList = () => {
        setIsLoading(true)
        // APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
        APIKit.get(RGCA_URLS.listSalesData + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {
                    setSalesListData(res?.data?.data)
                    setIsLoading(false)
                    const finalData = res.data.data?.filter(item => {
                        if (seenBatchNumbers.has(item.batchNo)) {
                            return false;
                        } else {
                            seenBatchNumbers.add(item.batchNo);
                            return true;
                        }
                    });
                    setIsBatches(finalData)
                    console.log('final', finalData);
                } else {
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                setIsLoading(false)

            });
    };

    const getSaleStartList = () => {
        APIKit.get(RGCA_URLS.saleStart + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {

                    const finalData = res.data.data?.filter(item => {
                        if (seenBatchNumbers.has(item.batchNo)) {
                            return false;
                        } else {
                            seenBatchNumbers.add(item.batchNo);
                            return true;
                        }
                    });
                    setIsStartSale(finalData)
                } else {

                }
            })
            .catch((e) => {
            });
    };

    const getSaleEndList = () => {
        APIKit.get(RGCA_URLS.saleEnd + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {

                    // const finalData = res.data.data?.filter(item => {
                    //     if (seenBatchNumbers.has(item.batchNo)) {
                    //         return false;
                    //     } else {
                    //         seenBatchNumbers.add(item.batchNo);
                    //         return true;
                    //     }
                    // });
                    setIsEndSale(res.data.data)
                } else {

                }
            })
            .catch((e) => {
            });
    };

    const saleStartOn = () => {
        let payload = {
            "projectID": projectDetails.projectID,
            "batchNo": isBatchId,
            "isSaleOn": true,
            "salesStartDate": isDate,
            "categoryID": isCategory


        }
        APIKit.put(RGCA_URLS.addSaleOn, payload)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success("Successfully sales Start.")
                    console.log(res.data.data);
                    getItemsList()
                    setIsSaleEnd(false)
                    setIsSaleStart(false)
                    setIsCategory("")
                    setIsBatchId("")
                    getSaleStartList();
                    getSaleEndList();
                    setOpenStart(false)
                } else {
                    toast.error("Error")
                }
            })
            .catch(e => { });

    };
    const saleStartEnd = () => {
        let payload = {
            "projectID": projectDetails.projectID,
            "batchNo": isBatchId,
            "isSalesCompleted": true,
            "salesEndDate": isDate,
            "categoryID": isCategory


        }
        APIKit.put(RGCA_URLS.addSaleEnd, payload)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success("Successfully sales ended.")
                    console.log(res.data.data);
                    getItemsList()
                    setIsSaleEnd(false)
                    setIsSaleStart(false)
                    setIsCategory("")
                    setIsBatchId("")
                    getSaleStartList();
                    getSaleEndList();
                    setOpenEnd(false)
                } else {
                    toast.error("Error")
                }
            })
            .catch(e => { });

    };

    const [saleStartCat, setSaleStartCat] = useState([])
    const [saleEndCat, setSaleEndCat] = useState([])

    const getsaleForsaleStart = (batchid) => {
        let payload = {
            "projectID": projectDetails.projectID,
            "batchNo": batchid,
        }
        APIKit.post(RGCA_URLS.getsalesForsaleStart, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setSaleStartCat(res.data.data);

                } else {
                    toast.error("Error")
                }
            })
            .catch(e => { });
    }
    const getsaleForsaleEnd = (batchid) => {
        let payload = {
            "projectID": projectDetails.projectID,
            "batchNo": batchid,
        }
        APIKit.post(RGCA_URLS.getsalesForsaleEnd, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setSaleEndCat(res.data.data);

                } else {
                    toast.error("Error")
                }
            })
            .catch(e => { });
    }
    const [isViewData, setIsViewData] = useState({})
    const viewData = (data) => {
        setIsViewDialogLab(true)
    }

    const getLabTestDataDetailsData = () => {
        setIsLoading(true);
        APIKit.get(RGCA_URLS.getLabTestDataDetails)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('getSellingQuery', res.data.data);
                    let data = res.data.data.map((x, i) => {
                        return {
                            ...x,
                            sellingItem: parseData(x.sellingItem)
                        };
                    });
                    setAllSelling(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
        // console.log('text-text',payload)
    };


    const openLabPayment = x => {

        setLabReceipt({
            labTestDataMasterID: x?.labTestDataMasterID,
            projectID: x?.projectID,
            amount: x?.amount,
            mor: null,
            totalAmountPaid: null,
            morAttach: "",
            customerID: x?.customerID,
            receiptDate: x?.createdDate,
            purpose: null,
            transferNo: "",
            receiptNotes: "",
            upiType: "",
            bankAccount: "",
            remaningBalance: x?.remaningBalance,
            bankName: "",
            ifscCode: "",
            Type: x?.Type,
            LabTestreceiptsDataID: x?.LabTestreceiptsDataID
        });
        setIsLabPayment(true)
        // setIsPayment(false);
        // setIsPayment(true);

        setBtn(false);
    };
    const getAllProjects = () => {
        APIKit.post(RGCA_URLS.getProjects)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('kkk', res.data.data);
                    setAllProjects(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(() => { });
    };

    const OpenLabHistryTable = x => {
        console.log('data', x);
        setHistryData([])
        getLabPaymentHistoryData(x?.labTestDataMasterID)
        setTimeout(() => {
            setIsLabHistory(true);
        }, 1000);
    };

    const getLabPaymentHistoryData = (labTestDataMasterID) => {
        setIsLoading(true)
        const payload = {
            labTestDataMasterID: labTestDataMasterID
        };
        APIKit.post(RGCA_URLS.getLabTestHistoryData, payload)
            .then(res => {
                if (res.data.status === 200) {
                    console.log("res.data.", res.data.data);
                    // setHistryData(res?.data?.data)
                    setLabHistoryData(res?.data?.data)
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };


    const getPackage = () => {
        setIsLoading(true)
        APIKit.get(RGCA_URLS.getPackageData)
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    console.log('kkk', res.data.data);
                    setPackagedData(res?.data?.data)
                } else {
                    setIsLoading(false);
                    // toast.error(res.data.message);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };


    return (
        <Page className={classes.root} title="Sales">
            <Loader isLoading={isLoading} />
            <ToastContainer />
            <Box mt={3}>

                <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Sales" onClick={() => {
                            setPage(0);
                            setAllSelling([]);
                            a11yProps(0);
                            getMaterialsForSelling();
                        }
                        } />
                        <Tab label="List Sale" onClick={() => {
                            setAllSelling([]);
                            // getMaterialsForSelling2()
                            setPage(0);
                            getLabTestDataDetailsData();
                            a11yProps(1);
                        }
                        } />
                        <Tab label="Lab Test" onClick={() => {
                            setAllSelling([]);
                            setSalesListData([])
                            a11yProps(2);
                            getItemsList();
                            getLabTestDataDetailsData();
                        }
                        } />

                        <Tab label="Lab Test Services" onClick={() => {
                            setAllSelling([]);
                            setPage(0);
                            getPackage();
                            a11yProps(3);
                        }
                        } />
                        <Tab label="Price Management" onClick={() => {
                            setAllSelling([]);
                            setPage(0);
                            a11yProps(4);
                        }
                        } />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                    <Container maxWidth={false}>
                        <Grid container spacing={2}>
                            <Grid item xl={5} lg={5} sm={12} xs={12}>
                                <Typography className={classes.head}>
                                    <h2>Sale - Invoice / Bill generation</h2>
                                </Typography>
                            </Grid>
                            <Grid item xl={7} lg={7} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setIsSaleStart(true)
                                    }}
                                >
                                    Start Sale
                                </Button>
                                &nbsp;
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setIsSaleEnd(true)
                                    }}
                                >
                                    End Sale
                                </Button>
                                &nbsp;
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleClickIncOpen}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <Box mt={3}>
                                    <Card>
                                        <CardContent>
                                            <Box maxWidth={500}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon fontSize="small" color="action">
                                                                    <SearchIcon />
                                                                </SvgIcon>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={e => {
                                                        searchSelling(e.target.value);
                                                    }}
                                                    placeholder="Search for Customer Name"
                                                    variant="outlined"
                                                    required
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>Sale Id</TableCell>
                                                <TableCell>Customer Name</TableCell>
                                                {/* <TableCell>Project Name</TableCell> */}
                                                <TableCell>Created By</TableCell>
                                                <TableCell>Sale Date</TableCell>
                                                <TableCell>Total Amount</TableCell>
                                                <TableCell>Balance</TableCell>
                                                <TableCell >Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {allSelling && allSelling.length != 0 ? (
                                                allSelling.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                ).map((x, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{x?.sellingMasterID}</TableCell>
                                                            <TableCell>
                                                                {x?.customerName ? x?.customerName : '-'}
                                                            </TableCell>
                                                            {/* <TableCell>{x?.projectName}</TableCell> */}
                                                            <TableCell>{x?.createdByName}</TableCell>
                                                            <TableCell>
                                                                {moment(x?.sellingDate).format('DD-MM-yyyy')}
                                                            </TableCell>
                                                            <TableCell>₹ {currencyFormat1(Math.round(x?.totalAmount))}</TableCell>
                                                            <TableCell>₹ {currencyFormat1(Math.round(x?.remaningBalance))}</TableCell>
                                                            <TableCell
                                                                style={{
                                                                    display: 'flex',
                                                                    marginTop: '14px',
                                                                    // justifyContent: 'space-between'
                                                                    gap: '20px',
                                                                    marginLeft: '10px'
                                                                }}
                                                            >
                                                                <Tooltip title="InVoice">
                                                                    <InsertDriveFileOutlinedIcon
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleIncPrintClose(x)}
                                                                    />
                                                                </Tooltip>
                                                                {x?.remaningBalance > 0 &&
                                                                    <>
                                                                        {x.receipts == null || x.receipts == '[]' ? (
                                                                            <Tooltip title="Payment">
                                                                                <PaymentIcon
                                                                                    onClick={() => openAddVendor(x)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Payment">
                                                                                <PaymentIcon
                                                                                    onClick={() => {
                                                                                        openAddVendor(x);
                                                                                        setIsUpdate(true);
                                                                                    }}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                }
                                                                <Tooltip title="Payment History">
                                                                    <HistoryIcon
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => OpenHistryTable(x)}
                                                                    />
                                                                </Tooltip>


                                                                {x.receipts == null || x.receipts == '[]' ? (
                                                                    <Tooltip title="Edit">
                                                                        <EditIcon
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => EditRowData(x)}
                                                                        />
                                                                    </Tooltip>) : ("")}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow hover role="checkbox" key={1}>
                                                    <TableCell colSpan={10} align="center" key={2}>
                                                        {'No Data Found'}
                                                    </TableCell>{' '}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {allSelling && allSelling.length != 0 && allSelling.length > 10 && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={allSelling.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ListSale salesListData={salesListData} />

                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Container maxWidth={false}>
                        <Grid container spacing={2}>
                            <Grid item xl={4} lg={4} sm={12} xs={12}>
                                <Typography className={classes.head}>
                                    <h2>Lab Test</h2>
                                </Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} sm={12} xs={12} style={{ textAlign: 'end' }}>

                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setIsAddDialoglab(true)
                                    }}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <Box mt={3}>
                                    <Card>
                                        <CardContent>
                                            <Box maxWidth={500}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon fontSize="small" color="action">
                                                                    <SearchIcon />
                                                                </SvgIcon>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={e => {
                                                        searchSelling2(e.target.value);
                                                    }}
                                                    placeholder="Search for Test Service Name"
                                                    variant="outlined"
                                                    required
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>S.No</TableCell>
                                                <TableCell align='left'>Lab Test Id</TableCell>
                                                <TableCell align='left'>Test Service Name</TableCell>
                                                <TableCell align='left'>Package</TableCell>
                                                {/* <TableCell align='left'>Samples</TableCell> */}
                                                <TableCell align='left'>Received By</TableCell>
                                                <TableCell align='left' >Total Amount</TableCell>
                                                <TableCell>Balance</TableCell>
                                                {/* <TableCell align='left' >Attached File</TableCell> */}
                                                <TableCell align='left'>Collection Date & Time</TableCell>
                                                <TableCell align='left'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {allSelling && allSelling.length != 0 ? (
                                                allSelling.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                ).map((x, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell align='left'>{index + 1}</TableCell>
                                                            <TableCell align='left'>{x?.labTestDataMasterID}</TableCell>
                                                            <TableCell align='left'>
                                                                <Tooltip title={x?.TestServiceName}>
                                                                    <span>
                                                                        {x?.TestServiceName ? x?.TestServiceName.length > 15 ? `${x?.TestServiceName.slice(0, 15)} ...` : x?.TestServiceName : ''}
                                                                        {' '}
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align='left'>{x?.packages}</TableCell>
                                                            {/* <TableCell align='left'>{x?.sampleDetails}</TableCell> */}
                                                            <TableCell align='left'>{x?.samplereceivedby}</TableCell>
                                                            <TableCell align='left'>₹ {currencyFormat1(Math.round(x?.amount))}</TableCell>
                                                            <TableCell align='left'>₹ {currencyFormat1(Math.round(x?.remaningBalance))}</TableCell>
                                                            {/* <TableCell align='left'>
                                                                {x?.fileAttach ? (
                                                                    <a href={x.fileAttach} target='_blank'>
                                                                        {x?.fileAttach && x?.fileAttach.substring(0, 25)}
                                                                    </a>
                                                                ) : (<span>-</span>)}
                                                            </TableCell> */}
                                                            <TableCell align='left'>{x?.samplecollectionDateAndTime ? moment(x?.samplecollectionDateAndTime).format('DD-MM-yyyy') : ''}</TableCell>
                                                            <TableCell align='left'
                                                                style={{
                                                                    display: 'flex',
                                                                    marginTop: '14px',
                                                                    gap: '2px',
                                                                    marginLeft: '10px'
                                                                }}
                                                            >
                                                                <Tooltip title="InVoice">
                                                                    <InsertDriveFileOutlinedIcon
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            setIsInvoiceLab(true);
                                                                            setInvoiceLabData(x);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                <Tooltip title="View">
                                                                    <HistoryIcon
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {

                                                                            OpenLabHistryTable(x)
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                {x?.remaningBalance > 0 &&
                                                                    <>
                                                                        {x.LabTestreceiptsDataID == null || x.LabTestreceiptsDataID == '' || x.LabTestreceiptsDataID == 'null' ? (
                                                                            <Tooltip title="Payment">
                                                                                <PaymentIcon
                                                                                    onClick={() => openLabPayment(x)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Payment">
                                                                                <PaymentIcon
                                                                                    onClick={() => {
                                                                                        openLabPayment(x);
                                                                                        setIsUpdate(true);
                                                                                    }}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                }
                                                                {/* <Tooltip title="Edit">
                                                                    <EditIcon
                                                                        style={{ cursor: 'pointer' }}
                                                                    onClick={() => EditRowData(x)}
                                                                    />
                                                                </Tooltip> */}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow hover role="checkbox" key={1}>
                                                    <TableCell colSpan={10} align="center" key={2}>
                                                        {'No Data Found'}
                                                    </TableCell>{' '}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {allSelling && allSelling.length != 0 && allSelling.length > 10 && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={allSelling.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <LabTestServices packagedData={packagedData} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <PriceManagement />
                </TabPanel>
            </Box>

            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isAddDialog}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Add Sales
                </DialogTitle>
                <AddSellings
                    AllCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsAddDialog={setIsAddDialog}
                    lookupData={lookupData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    isLoading={isLoading} />
            </Dialog>

            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isEditDialog}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Edit Selling
                </DialogTitle>
                <EditSelling
                    AllCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsEditDialog={setIsEditDialog}
                    lookupData={lookupData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    dataForEdit={dataForEdit}
                    setDataForEdit={setDataForEdit}
                    isLoading={isLoading} />

            </Dialog>
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isPayment}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Payment
                </DialogTitle>
                <PaymentScreen
                    allCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsPayment={setIsPayment}
                    lookData={lookData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    getReceipts={getReceipts}
                    receipt={receipt}
                    setReceipt={setReceipt}
                    setReceiptID={setReceiptID}
                    receiptID={receiptID}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    isLoading={isLoading}
                />

            </Dialog>
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isHistory}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Payment History
                </DialogTitle>
                <PaymentHistory
                    setIsHistory={setIsHistory}
                    projectDetails={projectDetails}
                    receiptIDHistory={receiptIDHistory}
                    histryData={histryData}
                />

            </Dialog>

            <Dialog
                aria-labelledby="customized-dialog-title"
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
                open={openincPrint}
                id="income"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleIncPrintClose}
                    style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                    Selling Print View
                </DialogTitle>
                <DialogContent dividers style={{ overflowX: 'hidden' }}>
                    <div>
                        <PictureAsPdfIcon
                            onClick={printOrderNew}
                            style={{ cursor: 'pointer' }}
                        />{' '}
                        &nbsp;
                        <PrintIcon onClick={printOrderNew} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="invoice-box" ref={htmlContentRef} id="myHtmlContent" style={{ marginTop: '15px' }}>
                        <div className="row container" >
                            <div className="col-sm-3" style={{
                                display: "flex",
                                justifyContent: "end"
                            }}>
                                <img
                                    src="/static/logo-rgca.jpg"
                                    alt="Company logo"
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div className="col-sm-9">
                                <p>
                                    <h3 style={{ textAlign: 'center' }}>
                                        RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)
                                    </h3>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        (MPEDA,Ministry of Commerce & Industry Government of India)
                                    </h5>
                                </p>
                                <p>
                                    <h5 style={{ textAlign: 'center' }}>
                                        HEAD OFFICE / UNIT OFFICE
                                    </h5>
                                </p>
                            </div>
                        </div>
                        <table cellpadding="0" cellspacing="0" >
                            <tr style={{ marginTop: '15px', backgroundColor: "#f4f4f4" }}>
                                <td colspan="2">
                                    <h2 >
                                        Tax invoice/Bill of Supply/Cash memo
                                    </h2>
                                    <span>
                                        {/* Number: 27B00032991LQ354  */}
                                        Date:{moment(sellingItems?.createdDate).format('DD/MM/YYYY')}
                                    </span>
                                </td>
                                <td colspan="2">
                                    Selling Id: {sellingItems?.sellingMasterID}
                                </td>
                            </tr>
                            <tr className="top">
                                <td colspan="3">
                                    <table>
                                        <tr>
                                            <td>
                                                <strong>{sellingItems?.projectName}</strong>
                                                <Address details={sellingItems?.projectAddress} />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} sm={12} xl={12} xs={12}>
                                        <td colspan="3" align='left'>
                                            <b> Billing Address: </b> <br />
                                            <p>{sellingItems?.customerName}</p>
                                            <Address details={sellingItems?.customerAddress} />
                                        </td>
                                    </Grid>
                                    {/* <Grid item lg={6} sm={12} xl={12} xs={12} >
                                        <td colspan="3" align='right'>
                                            <b> Billing Address: </b>
                                            <p>{sellingItems.customerName}</p>
                                            <Address details={sellingItems?.customerAddress} />
                                        </td>
                                    </Grid> */}
                                </Grid>
                            </tr>
                            {/* <tr className="information">
                                <td colspan="3">
                                    <table>
                                        <tr>
                                            <td colspan="2">
                                                <b> Shipping Address: </b> <br />
                                                <p>{sellingItems?.customerName}</p>
                                                <Address details={sellingItems?.customerAddress} />
                                            </td>
                                            <td>
                                                {' '}
                                                <b> Billing Address: </b>
                                                <p>{sellingItems.customerName}</p>
                                                <br />
                                                <Address details={sellingItems?.customerAddress} />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr> */}
                            <td colspan="3">
                                <table class="table">
                                    <thead class='tableHead'>
                                        <tr class="table-rows">
                                            <th style={{ textAlign: 'left' }}>ITEM</th>
                                            <th style={{ textAlign: 'left' }}>QTY.</th>
                                            <th style={{ textAlign: 'left' }}>PRICE (INR)</th>
                                            <th style={{ textAlign: 'left' }}>TAX RATE & TYPE</th>
                                            <th style={{ textAlign: 'left' }}>TAX AMOUNT (INR)</th>
                                            <th style={{ textAlign: 'left' }}>Rate Per Bag</th>
                                            <th style={{ textAlign: 'left' }}>No of Bags</th>
                                            <th style={{ textAlign: 'left' }}>TOTAL AMOUNT (INR)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sellingItems && sellingItems?.sellingItem && sellingItems?.sellingItem?.length != 0 &&
                                            sellingItems?.sellingItem?.map(z => {
                                                totalQty = totalQty + z.iqty;
                                                totalPrice = totalPrice + z.rpu;
                                                totalAmount = totalAmount + z.qty * z.price;
                                                totalTax = totalTax + z.taxRate;
                                                return (
                                                    <tr className="table-row">
                                                        <td style={{ textAlign: 'left' }}>{z.itemName}</td>
                                                        <td style={{ textAlign: 'left' }}>{z.iqty}</td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            ₹ {z.rpu ? currencyFormat1(Math.round(z.rpu)) : '-'}
                                                        </td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            {z.gstRate} % IGST
                                                        </td>
                                                        <td style={{ textAlign: 'left' }}>{z.taxRate}</td>
                                                        <td style={{ textAlign: 'left' }}>{z.bagsPerUnit}</td>
                                                        <td style={{ textAlign: 'left' }}>{z.NoofBags}</td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            ₹ {currencyFormat1(Math.round(z?.totalAmount))}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        <tr className="item">
                                            <td style={{ width: '10%' }}>
                                                <b> Grand Total </b>
                                            </td>
                                            <td style={{ width: '10%', textAlign: 'left' }}>
                                                <b>{totalQty}</b>
                                            </td>
                                            <td style={{ width: '10%', textAlign: 'left' }}>
                                                {/* <b>₹ {currencyFormat1(Math.round(totalPrice))}</b> */}
                                               {/* <b> No of Bags :</b> {sellingItems.NoofBags}  */}
                                            </td>
                                            <td style={{ width: '15%', textAlign: 'left' }}> {/* <b>Bags Per Unit :</b> ₹ {sellingItems?.bagsPerUnit} */}</td>
                                            <td style={{ width: '15%', textAlign: 'left' }}> {Math.round(totalTax)}</td>
                                            <td style={{ textAlign: 'left' }}>{''}</td>
                                            <td style={{ textAlign: 'left' }}>{''}</td>
                                            <td style={{ width: '15%', textAlign: 'left' }}>
                                                <b>  ₹  {currencyFormat1(Math.round(sellingItems.totalAmount))}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                            <tr className="total">
                                <td colspan="3" align="right">
                                    {' '}
                                    Total Amount in Words :{' '}
                                    <b> {price_in_words(Math.round(sellingItems.totalAmount))} Rupees only </b>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <table cellspacing="0px" cellpadding="2px">
                                        <tr>
                                            <td width="50%">
                                                <b> Declaration: </b> <br />
                                                We declare that this invoice shows the actual price of
                                                the goods described above and that all particulars are
                                                true and correct.The goods sold are intended for end
                                                user consumption and not for resale.
                                            </td>
                                            <td>
                                                * This is a computer generated invoice and does not
                                                require a physical signature
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td width="50%"></td>
                                            <td>
                                                <b> Authorized Signature </b>
                                                <br />
                                                <br />
                                                ...................................
                                                <br />
                                                <br />
                                                <br />
                                            </td>
                                        </tr> */}
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                </DialogContent>
                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                    <Button onClick={handleIncPrintClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* labtest invoive view */}
            <LabInvoiceView setIsInvoiceLab={setIsInvoiceLab} isInvoiceLab={isInvoiceLab}
                setInvoiceLabData={setInvoiceLabData} invoiceLabData={invoiceLabData} />

            {/* lab payment */}
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isLabPayment}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Payment
                </DialogTitle>
                <LabTestPayment
                    allCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsLabPayment={setIsLabPayment}
                    lookData={lookData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    getReceipts={getReceipts}
                    labReceipt={labReceipt}
                    setLabReceipt={setLabReceipt}
                    setReceiptID={setReceiptID}
                    receiptID={receiptID}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    isLoading={isLoading}
                    allProjects={allProjects}

                />

            </Dialog>

            {/* lab payment history */}

            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isLabHistory}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Payment History
                </DialogTitle>
                <LabTestPaymentHistory

                    setIsLabHistory={setIsLabHistory}
                    projectDetails={projectDetails}
                    labHistoryData={labHistoryData}
                    setLabHistoryData={setLabHistoryData}
                />

            </Dialog>
            {/* end */}

            {/* sale start */}
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isSaleStart}
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                    onClose={() => {
                        setIsSaleStart(false)
                    }}
                >
                    Sale Start
                </DialogTitle>
                <DialogContent dividers style={{ width: '900px !important' }}>
                    <Grid container spacing={2}>

                        <Grid
                            item
                            lg={4}
                            sm={6}
                            xl={6}
                            xs={6}
                            className={classes.custmr}
                        >
                            <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                            >
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    required
                                >
                                    Batch No.
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Batch No."
                                    name="isBatchId"
                                    value={isBatchId}
                                    onChange={(e) => {
                                        getsaleForsaleStart(e.target.value)
                                        setIsBatchId(e.target.value)
                                    }}
                                >
                                    {isStartSale && isStartSale.map((x, index) => {
                                        return <MenuItem value={x.batchNo} key={index + 1}>
                                            {x.batchNo}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            sm={6}
                            xl={6}
                            xs={6}
                            className={classes.custmr}
                        >
                            <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                            >
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    required
                                >
                                    Name of Product
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Name of Product"
                                    name="isBatchId"
                                    value={isCategory}
                                    onChange={(e) => {
                                        setIsCategory(e.target.value)
                                    }}
                                >
                                    {saleStartCat && saleStartCat.map((x, index) => {
                                        return <MenuItem value={x.categoryID} key={index + 1}>
                                            {x.categoryName}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={6} xl={6} xs={6}>
                            <KeyboardDatePicker
                                className={classes.dat}
                                autoOk
                                fullWidth
                                // InputProps={{ disabled: true }}
                                // onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                // maxDate={new Date}
                                // disableFuture
                                variant="inline"
                                inputVariant="outlined"
                                label="Date"
                                format="dd/MM/yyyy"
                                name="isDate"
                                value={isDate ?? null}
                                InputAdornmentProps={{ position: 'end' }}
                                onChange={date => {
                                    let p = moment(date).format('YYYY-MM-DD');
                                    setIsDate(p)
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    color="primary"
                                    // onClick={handleSubmit}
                                    onClick={() => {
                                        setOpenStart(true)

                                    }}
                                    type="submit"
                                    variant="contained"
                                    disabled={(isDate == "" || isCategory == "" || isBatchId == "")}
                                >
                                    Submit
                                </Button>
                                &nbsp;
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setIsSaleStart(false)
                                        setIsCategory("")
                                        setIsBatchId("")
                                    }}
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* end */}

            {/* sale end */}
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isSaleEnd}
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none'
                }}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                    onClose={() => {
                        setOpenStart(false)

                    }}
                >
                    End Sale
                </DialogTitle>
                <DialogContent dividers style={{ width: '900px !important' }}>
                    <Grid container spacing={2}>

                        <Grid
                            item
                            lg={4}
                            sm={6}
                            xl={6}
                            xs={6}
                            className={classes.custmr}
                        >
                            <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                            >
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    required
                                >
                                    Batch No.
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Batch No."
                                    name="isBatchId"
                                    value={isBatchId}
                                    onChange={(e) => {
                                        getsaleForsaleEnd(e.target.value)
                                        setIsBatchId(e.target.value)
                                    }}
                                >
                                    {isEndSale && isEndSale.map((x, index) => {
                                        return <MenuItem value={x.batchNo} key={index + 1}>
                                            {x.batchNo}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            sm={6}
                            xl={6}
                            xs={6}
                            className={classes.custmr}
                        >
                            <FormControl
                                variant="outlined"
                                style={{ width: '100%' }}
                                className={classes.formControl}
                            >
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    required
                                >
                                    Name of Product
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Name of Product"
                                    name="isBatchId"
                                    value={isCategory}
                                    onChange={(e) => {

                                        setIsCategory(e.target.value)
                                    }}
                                >
                                    {saleEndCat && saleEndCat.map((x, index) => {
                                        return <MenuItem value={x.categoryID} key={index + 1}>
                                            {x.categoryName}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={6} xl={6} xs={6}>
                            <KeyboardDatePicker
                                className={classes.dat}
                                autoOk
                                fullWidth
                                InputProps={{ disabled: true }}
                                // onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                // maxDate={new Date}
                                // disableFuture
                                variant="inline"
                                inputVariant="outlined"
                                label="Date "
                                format="dd/MM/yyyy"
                                name="isDate"
                                value={isDate}
                                InputAdornmentProps={{ position: 'end' }}
                                onChange={date => {
                                    let p = moment(date).format('YYYY-MM-DD');
                                    setIsDate(p)
                                }}

                            />
                        </Grid>



                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    disabled={(isCategory == "" || isBatchId == "")}
                                    color="primary"
                                    onClick={() => {
                                        setOpenEnd(true)
                                    }}
                                    type="submit"
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                                &nbsp;
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setIsSaleEnd(false)
                                        setIsCategory("")
                                        setIsBatchId("")
                                    }}
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* end */}


            {/* confirm dialog */}
            <Dialog
                open={openStart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure, you want start sale now ?"}
                </DialogTitle>

                <DialogActions>
                    <Button onClick={() => {
                        setOpenStart(false)
                    }}>No</Button>
                    <Button onClick={() => {
                        saleStartOn()
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* confirm dialog */}
            <Dialog
                open={openEnd}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure, you want sale end now ?"}
                </DialogTitle>

                <DialogActions>
                    <Button onClick={() => {
                        setOpenEnd(false)
                    }}>No</Button>
                    <Button onClick={() => {
                        saleStartEnd()
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


            {/* lab test service */}


            {/* end */}


            {/* lab */}


            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isAddDialoglab}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Lab Testing
                </DialogTitle>
                <LabTest
                    AllCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsAddDialoglab={setIsAddDialoglab}
                    lookupData={lookupData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    getLabTestDataDetailsData={getLabTestDataDetailsData}
                    isLoading={isLoading} />
            </Dialog>

            {/* edit lab */}
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isEditDialogLab}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    Edit Lab Testing
                </DialogTitle>
                <EditLab
                    AllCustomer={allCustomer}
                    projectDetails={projectDetails}
                    setIsEditDialogLab={setIsEditDialogLab}
                    lookupData={lookupData}
                    setIsLoading={setIsLoading}
                    getMaterialsForSelling={getMaterialsForSelling}
                    dataForEdit={dataForEdit}
                    setDataForEdit={setDataForEdit}
                    isLoading={isLoading} />

            </Dialog>


            {/* View lab */}
            <Dialog aria-labelledby="customized-dialog-title" id="income"
                open={isViewDialogLab}
                contentStyle={{
                    width: '200%',
                    maxWidth: 'none'
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        width: '900px !important'
                    }}
                >
                    View Lab Testing
                </DialogTitle>
                <DialogContent dividers style={{ width: '800px !important' }}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} sm={4} xl={4} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#394671' }}>
                                    Create Date : {isViewData.createdDate}
                                </h5>
                            </div>
                        </Grid>
                        <Grid item lg={4} sm={4} xl={4} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#394671' }}>
                                    From Name : {isViewData.FormName}
                                </h5>
                            </div>
                        </Grid>
                        <Grid item lg={4} sm={4} xl={4} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#394671' }}>
                                    Batch No : {isViewData.batchNo}
                                </h5>
                            </div>
                        </Grid>
                        {/* ---------------------------------------------------------------------------------------- */}
                        <Grid item lg={4} sm={4} xl={4} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#394671' }}>
                                    Lab Id: {isViewData.LabTestMasterID}
                                </h5>
                            </div>
                        </Grid>
                        <Grid item lg={4} sm={4} xl={4} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#394671' }}>
                                    File: {isViewData?.fileAttach && <a href={isViewData.fileAttach} target='_blank'>{isViewData?.fileAttach.substring(0, 20)}</a>}

                                </h5>
                            </div>
                        </Grid>


                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Type</TableCell>
                                                <TableCell align="left"> Material Name</TableCell>
                                                <TableCell align="left">Category</TableCell>
                                                <TableCell align="left">UOM</TableCell>
                                                <TableCell align="left">Stock Available</TableCell>
                                                <TableCell align="left">Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isViewData.LabTestItem && JSON.parse(isViewData.LabTestItem).map((lab, index) => {
                                                return <TableRow hover>
                                                    <TableCell align="left">{lab.typeID}</TableCell>
                                                    <TableCell align="left">{lab.itemName}</TableCell>
                                                    <TableCell align="left">{lab.categoryName}</TableCell>
                                                    <TableCell align="left">{lab.uom}</TableCell>
                                                    <TableCell align="left">{lab.qty}</TableCell>
                                                    <TableCell align="left">{lab.iqty}</TableCell>

                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }} >
                    <Button className={classes.button2}
                        onClick={() => {
                            setIsViewDialogLab(false)
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </Page>
    );
};

export default Selling;
