import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Card, Avatar, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl, MenuItem, FormHelperText, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import APIKit from '../../../utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
import './style.css';
import { currencyFormat1, dateOnlyRevReport } from '../Common/utilities';
import { MESSAGE } from 'src/utils/message';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { set } from 'nprogress';
import SalesSummaryReport from './salesSummaryReport'
import NewSalesReport from './newSalesReport';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
    },
    root: {

    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    alignForInventory: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    },

    tablerow: {

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};





const SalesReport = ({ lookupData, projectDetails, setIsLoading, setTabName, isTabName, allProjects }) => {
    console.log('dasd', lookupData);
    const classes = useStyles();
    const [receiptList, setReceiptList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);


    const getFinancialYearStartDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        if (month >= 3) { // April (3) or later
            return new Date(year, 3, 1); // April 1st of the current year
        } else {
            return new Date(year - 1, 3, 1); // April 1st of the previous year
        }
    };
    const currentDates = new Date();
    const [salesPayload, setSalesPayload] = useState({
        typeID: '',
        categoryID: '',
        // fromDate: currentDates.setDate(currentDates.getDate() - 30),
        fromDate: getFinancialYearStartDate(currentDates),
        toDate: new Date(),
        projectID: projectDetails.projectID,
        itemID: ''
    });



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [print, setprint] = useState(false)
    const [isType, setIsType] = useState('all')
    const [isBatches, setIsBatches] = useState([]);
    const [isBatchId, setIsBatchId] = useState('');
    const [isCustomerId, setCustomerId] = useState(null);
    const [isStateName, setIsStateName] = useState("");
    const a11yProps = (index) => {
        if (index == 0) {
            setSalesPayload({
                typeID: '',
                categoryID: '',
                // fromDate: '01-04-2024',
                fromDate: getFinancialYearStartDate(currentDates),
                toDate: null,
                projectID: projectDetails.projectID,
                itemID: ''
            });
            setReceiptList([])

        }
        if (index == 1) {
            setIsLoading(true)
            setReceiptList([])
            setSalesPayload({
                typeID: '',
                categoryID: '',
                // fromDate: currentDates.setDate(currentDates.getDate() - 30),
                fromDate: getFinancialYearStartDate(currentDates),
                toDate: new Date(),
                projectID: projectDetails.projectID,
                itemID: ''
            });
            setTimeout(() => {
                setIsLoading(false)
                getSalesReport()
            }, 3000)

        }
        if (index == 2) {
            // setIsLoading(true)
            setReceiptList([])
            setSalesPayload({
                typeID: '',
                categoryID: '',
                // fromDate: currentDates.setDate(currentDates.getDate() - 30),
                fromDate: getFinancialYearStartDate(currentDates),
                toDate: new Date(),
                projectID: projectDetails.projectID,
                itemID: ''
            });
            // setTimeout(() => {
            //     setIsLoading(false)
            //     getSalesReport()
            // }, 3000)
        }
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getCategories = type => {
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getSalesCategory, {
            typeID: type,
            projectID: projectDetails.projectID
        })
            .then(res => {
                if (res.data.status === 200) {
                    setCategoryList(res.data.data);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'success' });
                } else {
                    // toast.error(res.data.message);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'error' });
                }
            })
            .catch(e => { });
    };

    const getSalesReport = () => {
        let changedDate = new Date(salesPayload?.fromDate);
        let formattedDate = changedDate.toISOString();

        setReceiptList([])
        setIsLoading(true)
        let payload = {
            projectID: projectDetails.projectID,
            typeID: salesPayload.typeID ? salesPayload.typeID : 0,
            categoryID: salesPayload.categoryID ? salesPayload.categoryID : 0,
            fromDate: formattedDate ? moment(formattedDate).format("YYYY-MM-DD") : '',
            toDate: salesPayload.toDate ? moment(salesPayload.toDate).format("YYYY-MM-DD") : '',
            "batchNo": isBatchId ?? "",
            "state": isStateName ?? "",
            "customerID": isCustomerId ?? 0
        };
        APIKit.post(RGCA_URLS.getSellingReportData, payload)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('getSalesReport', res.data.data);
                    const arr = res.data.data;
                    setReceiptList(arr)
                    setIsLoading(false)
                    RemoveDupilicate()
                    if (res.data.data.length > 0) {
                        toast.success("Successfully fetching data")
                    } else {
                        toast.warn("No data found.!")
                    }

                } else {
                    toast.error(res.data.message)
                    setIsLoading(false)
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };

    const RemoveDupilicate = () => {
        let SaleDate = receiptList

    }

    const exportToCSV = () => {
        let name = projectDetails.projectName + 'Sales_Report' + '_' + currentDate
        try {
            // const fileName = name;
            const fileName = `${projectDetails?.projectName}_${isTabName}`;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            // Check if receiptList is an array and not empty
            if (!Array.isArray(receiptList) || receiptList.length === 0) {
                // throw new Error('Receipt list is empty or invalid.');
                toast.warning('Sales list is empty')
            }
            receiptList.forEach(x => {
                const obj = {};
                obj['Sale ID'] = { v: x.sellingMasterID, t: 's' }; // Setting type as string
                obj['Product Name'] = { v: x.Productname, t: 's' }; // Setting type as string
                obj['Sale Date'] = { v: moment(x.sellingDate).format('DD/MM/YYYY'), t: 's' }; // Setting type as string
                obj['Rate Per Unit'] = x.ratePerUnit ? x.ratePerUnit : ''; // Setting default value to empty string if falsy
                obj['Quantity'] = x.quantity ? x.quantity : ''; // Setting default value to empty string if falsy
                obj['GST'] = x.gstRate ? x.gstRate : ''; // Setting default value to empty string if falsy
                obj['Amount'] = x.totalAmount ? x.totalAmount : ''; // Setting default value to empty string if falsy
                // obj['Paid Amount'] = x.totalAmountPaid ? x.totalAmountPaid : ''; 
                // obj['Remaining Balance'] = x.remaningBalance ? x.remaningBalance : ''; 
                obj['Mode of Payment'] = { v: x.modeofReceipt == 42 ? 'CASH' : 'UPI', t: 's' }; // Setting type as string
                obj['Customer Name'] = { v: x.customer, t: 's' }; // Setting type as string
                // obj['Purpose'] = { v: x.purpose, t: 's' }; 
                newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 15 }, // Receipt ID
                { wch: 20 }, // Product Name
                { wch: 15 }, // Selling Date
                { wch: 15 }, // Rate Per Unit
                { wch: 10 }, // Quantity
                { wch: 10 }, // GST
                { wch: 15 }, // Amount
                { wch: 15 }, // Paid Amount
                { wch: 20 }, // Remaining Balance
                { wch: 20 }, // Mode of Payment
                { wch: 20 }, // Customer Name
                { wch: 20 }  // Purpose
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };

    const htmlContentRef = useRef(null);
    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });
    const currentDate = new Date().toLocaleDateString();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        getItemsList()
        getListCustomer()
    }, [])
    let seenBatchNumbers = new Set();
    const getItemsList = () => {
        APIKit.get(RGCA_URLS.listSalesItem + '/' + projectDetails.projectID)
            .then((res) => {
                if (res.data.status === 200) {

                    const finalData = res.data.data?.filter(item => {
                        if (seenBatchNumbers.has(item.batchNo)) {
                            return false;
                        } else {
                            seenBatchNumbers.add(item.batchNo);
                            return true;
                        }
                    });
                    setIsBatches(finalData)
                    console.log('final', finalData);
                } else {

                }
            })
            .catch((e) => {
            });
    };

    const [allCustomer, setAllCustomer] = useState([]);
    const getListCustomer = () => {
        APIKit.post(RGCA_URLS.listCustomer, {
            projectID: projectDetails.projectID
        })
            .then(res => {
                console.log('logggg:', res);
                if (res.data.status === 200) {
                    const mapFromColors = new Map(
                        res.data?.data.map(c => [c.stateName, c])
                    );
                    const uniqueColors = [...mapFromColors.values()];
                    console.log("uniqueColors", uniqueColors);
                    setAllCustomer(uniqueColors);
                } else {
                }
            })
            .catch(function (e) { });
    };

    const totalQty = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + Number(x.quantity);
        });
        return test;
    };
    const totalAmount = (data = []) => {
        let test = 0;
        data.map(x => {
            test = test + Number(x.totalAmount);
        });
        return currencyFormat1(Math.round(test));
    };

    return (
        <>
            <div>
                <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        color='red'
                    >
                        <Tab label="Specific Product Report" onClick={() => {
                            a11yProps(0);
                            setTabName(`Sales_Specific_Product_Report`)

                        }
                        } />
                        <Tab label="All Product Report" onClick={() => {
                            a11yProps(1)
                            // setTabName(`Sales_All_Product_Report`)
                            setTabName(`Sales_All_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)


                        }} />
                        <Tab label="Sales Summary Report" onClick={() => {
                            a11yProps(2)
                            setTabName(`Sales_Summary_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)
                        }} />
                        {/* <Tab label="Sales Report" onClick={() => {
                            a11yProps(3)
                            setTabName(`Sales_Summary_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)
                        }} /> */}
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12} style={{ marginTop: '0' }}>
                            <Typography><h2 style={{ color: '#394671' }}>Specific Product Report</h2></Typography>
                        </Grid>
                        <Grid item md={8} xs={12} style={{ marginTop: '0' }}>
                            <FormControl component="fieldset">
                                {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio checked={isType == 'all'} color="primary" onChange={(e) => {
                                            setIsType('all')
                                        }} />}
                                        label="All"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="state"
                                        control={<Radio checked={isType == 'state'} color="primary" onChange={(e) => {
                                            setIsType('state')
                                            setCustomerId(0)
                                            setIsBatchId("")
                                            setReceiptList([])
                                        }} />}
                                        label="State wise"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="batch"
                                        control={<Radio checked={isType == 'batch'} color="primary" onChange={(e) => {
                                            setIsType('batch')
                                            setCustomerId(0)
                                            setIsStateName("")
                                            setReceiptList([])
                                        }} />}
                                        label="Batch wise"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="framer"
                                        control={<Radio checked={isType == 'framer'} color="primary" onChange={(e) => {
                                            setIsBatchId("")
                                            setIsStateName("")
                                            setIsType('framer')
                                            setReceiptList([])
                                        }} />}
                                        label="Framer wise"
                                        labelPlacement="start"
                                    />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                                    {isType === 'state' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    State wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={isStateName}
                                                    name="isStateName"
                                                    label="State wise"
                                                    onChange={e => {
                                                        setIsStateName(e.target.value)
                                                    }}
                                                >
                                                    {allCustomer &&
                                                        allCustomer.map(x => {
                                                            return (
                                                                <MenuItem value={x.stateName} key={x.stateName}>
                                                                    {x.stateName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {isType === 'batch' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    Batch wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Batch No."
                                                    name="isBatchId"
                                                    value={isBatchId}
                                                    onChange={(e) => {
                                                        setIsBatchId(e.target.value)
                                                    }}
                                                >
                                                    {isBatches && isBatches.map((x, index) => {
                                                        return <MenuItem value={x.batchNo} key={index + 1}>
                                                            {x.batchNo}
                                                        </MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {isType === 'framer' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    Framer wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    // value={salesPayload.typeID}
                                                    name="Framerwise"
                                                    label="Framer wise"
                                                    onChange={e => {
                                                        setCustomerId(e.target.value)
                                                    }}
                                                >
                                                    {allCustomer &&
                                                        allCustomer.map(x => {
                                                            return (
                                                                <MenuItem value={x.customerID} key={x.customerID}>
                                                                    {x.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Product Item
                                            </InputLabel>
                                            <Select
                                                // defaultValue={lookupData[0]?.lookupID}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={salesPayload.typeID}
                                                name="typeID"
                                                label="Product Item"
                                                required
                                                onChange={e => {
                                                    const p = salesPayload;
                                                    p.typeID = e.target.value;
                                                    setSalesPayload({
                                                        ...salesPayload,
                                                        typeID: e.target.value
                                                    });
                                                    getCategories(e.target.value);
                                                }}
                                            >
                                                {lookupData &&
                                                    lookupData.map(x => {
                                                        return (
                                                            <MenuItem value={x.lookupID} key={x.lookupID}>
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Product Name
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={salesPayload.categoryID}
                                                label="Category"
                                                name="categoryID"
                                                required
                                                onChange={e => {
                                                    console.log("jjjjj", e);
                                                    const p = salesPayload;
                                                    p.categoryID = e.target.value;
                                                    setSalesPayload({
                                                        ...salesPayload,
                                                        categoryID: e.target.value
                                                    });
                                                }}
                                                disabled={!categoryList || categoryList.length === 0}
                                            >
                                                {categoryList &&
                                                    categoryList.map(x => {
                                                        return (
                                                            <MenuItem
                                                                value={x.categoryID}
                                                                key={x.categoryID}
                                                            >
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    // autoOk
                                                    disableFuture
                                                    // InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {

                                                        const p = salesPayload;
                                                        p.fromDate = e
                                                        // moment(e).format("DD-MM-YYYY")
                                                        setSalesPayload({
                                                            ...p
                                                            // fromDate: moment(e).format("DD-MM-YYYY")
                                                        });
                                                        setTabName(`Sales_Specific Product Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={salesPayload.fromDate ?? null}
                                                    className={classes.calmar}
                                                // required

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={`${isType == 'all' ? 3 : 4}`} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.toDate = e;
                                                        setSalesPayload({
                                                            ...p
                                                        });
                                                        setTabName(`Sales_Specific Product Report_${salesPayload.fromDate} to to ${salesPayload.toDate}`)
                                                    }}
                                                    value={salesPayload.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={`${isType == 'all' ? 2 : 4}`} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {salesPayload.categoryID && salesPayload.typeID && salesPayload.projectID ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Sales_Specific_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`);
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12} style={{ marginTop: '0' }}>
                            <Typography><h2 style={{ color: '#394671' }}>All Product Report</h2></Typography>
                        </Grid>
                        <Grid item md={8} xs={12} style={{ marginTop: '0' }}>
                            <FormControl component="fieldset">
                                {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio checked={isType == 'all'} color="primary" onChange={(e) => {
                                            setIsType('all')
                                        }} />}
                                        label="All"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="state"
                                        control={<Radio checked={isType == 'state'} color="primary" onChange={(e) => {
                                            setIsType('state')
                                            setCustomerId(0)
                                            setIsBatchId("")
                                            setReceiptList([])
                                        }} />}
                                        label="State wise"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="batch"
                                        control={<Radio checked={isType == 'batch'} color="primary" onChange={(e) => {
                                            setIsType('batch')
                                            setCustomerId(0)
                                            setIsStateName("")
                                            setReceiptList([])
                                        }} />}
                                        label="Batch wise"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="framer"
                                        control={<Radio checked={isType == 'framer'} color="primary" onChange={(e) => {
                                            setIsBatchId("")
                                            setIsStateName("")
                                            setIsType('framer')
                                            setReceiptList([])
                                        }} />}
                                        label="Framer wise"
                                        labelPlacement="start"
                                    />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center"  >
                                    {/* <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Report Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={'All'}
                                                name="typeID"
                                                label="Product Item"
                                                disabled
                                            >

                                                <MenuItem value={'All'} >
                                                    All
                                                </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                    {isType === 'state' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    State wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={isStateName}
                                                    name="isStateName"
                                                    label="State wise"
                                                    onChange={e => {
                                                        setIsStateName(e.target.value)
                                                    }}
                                                >
                                                    {allCustomer &&
                                                        allCustomer.map(x => {
                                                            return (
                                                                <MenuItem value={x.stateName} key={x.stateName}>
                                                                    {x.stateName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {isType === 'batch' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    Batch wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Batch No."
                                                    name="isBatchId"
                                                    value={isBatchId}
                                                    onChange={(e) => {
                                                        setIsBatchId(e.target.value)
                                                    }}
                                                >
                                                    {isBatches && isBatches.map((x, index) => {
                                                        return <MenuItem value={x.batchNo} key={index + 1}>
                                                            {x.batchNo}
                                                        </MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {/* {isType === 'framer' &&
                                        <Grid item md={4} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                            >
                                                <InputLabel
                                                    required
                                                    id="demo-simple-select-outlined-label"
                                                >
                                                    Framer wise
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="Framerwise"
                                                    label="Framer wise"
                                                    onChange={e => {
                                                        setCustomerId(e.target.value)
                                                    }}
                                                >
                                                    {allCustomer &&
                                                        allCustomer.map(x => {
                                                            return (
                                                                <MenuItem value={x.customerID} key={x.customerID}>
                                                                    {x.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    } */}
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.fromDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Sales_All_Product_Report_${salesPayload.fromDate}to${salesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={salesPayload?.fromDate}
                                                    className={classes.calmar}
                                                    required

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = salesPayload;
                                                        p.toDate = e;
                                                        setSalesPayload({ ...p });
                                                        setTabName(`Sales_All_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`)
                                                    }}
                                                    value={salesPayload?.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={2} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {salesPayload.fromDate && salesPayload.toDate && salesPayload.projectID ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Sales_All_Product_Report_${moment(salesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(salesPayload?.toDate).format('DD/MM/YYYY')}`);
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SalesSummaryReport setTabName={setTabName} projectDetails={projectDetails} allProjects={allProjects} />
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                    <NewSalesReport setTabName={setTabName} projectDetails={projectDetails} lookupData={lookupData} allProjects={allProjects}/>
                </TabPanel> */}

            </div>


            {receiptList && receiptList.length > 0 &&
                <>
                    <Box display="flex" justifyContent="flex-end">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} lg={2}
                                style={{
                                    marginTop: '10px',
                                    // color: '#3f51b5',
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    gap: '8px'

                                }}
                            >
                                <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <PrintIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    exportToCSV();
                                    setprint(true);

                                }} />
                            </Grid>
                            <Grid item lg={10} style={{ marginTop: '10px' }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6}>
                                        Total Quantity : {currencyFormat1(totalQty(receiptList))}
                                    </Grid>
                                    <Grid item lg={6}>
                                        Total Amount : ₹ {totalAmount(receiptList)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="left">S.No</TableCell >
                                        <TableCell align="left">Sale ID</TableCell >
                                        <TableCell align="left">Product Name</TableCell >
                                        <TableCell align="left">Selling Date</TableCell>
                                        <TableCell align="left">Rate Per Unit</TableCell>
                                        <TableCell align="left">Quantity</TableCell>
                                        <TableCell align="left">GST</TableCell>
                                        <TableCell align="left">Amount</TableCell>
                                        <TableCell align="left">State Name</TableCell>
                                        <TableCell align="left">Batch No.</TableCell>
                                        <TableCell align="left">Framer Name</TableCell>
                                        <TableCell align="left">Contact Number</TableCell> */}

                                        <TableCell align="left">S.No</TableCell >
                                        <TableCell align="left">DATE</TableCell>
                                        <TableCell align="left">FARMER NAME</TableCell>
                                        <TableCell align="left">CONTACT NUMBER</TableCell>
                                        <TableCell align="left">STATE </TableCell>
                                        <TableCell align="left">PRODUCT NAME</TableCell >
                                        <TableCell align="left">NO OF SEED</TableCell>
                                        <TableCell align="left">RATE PER SEED</TableCell>
                                        <TableCell align="left">NO OF POLY BAG</TableCell>
                                        <TableCell align="left">RATE PER BAGS </TableCell>
                                        <TableCell align="left">AMOUNT IN (RS)</TableCell>
                                        <TableCell align="left">REMARK/VECHLE NO </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                    {/* <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                    <TableCell align="left">{x?.sellingMasterID}</TableCell>
                                                    <TableCell align="left">{x?.Productname}</TableCell>
                                                    <TableCell align="left">
                                                        {moment(x?.sellingDate).format('DD/MM/YYYY')}
                                                    </TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.ratePerUnit))}</TableCell>
                                                    <TableCell align="left">{x.quantity}</TableCell>
                                                    <TableCell align="left">{x.gstRate}</TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.totalAmount))}</TableCell>
                                                    <TableCell align="left">{x.state}</TableCell>
                                                    <TableCell align="left">{x.batchNo}</TableCell>
                                                    <TableCell align="left">{x.customer}</TableCell>
                                                    <TableCell align="left">{x?.contactNumber}</TableCell> */}

                                                    <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                    <TableCell align="left">{moment(x?.sellingDate).format('DD/MM/YYYY')} </TableCell>
                                                    <TableCell align="left">{x.customer}</TableCell>
                                                    <TableCell align="left">{x?.contactNumber}</TableCell>
                                                    <TableCell align="left">{x.state}</TableCell>
                                                    <TableCell align="left">{x?.Productname}</TableCell>
                                                    <TableCell align="left">{x.quantity}</TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.ratePerUnit))}</TableCell>
                                                    <TableCell align="left">{x?.NoofBags}</TableCell>
                                                    <TableCell align="left">{x?.bagsPerUnit}</TableCell>
                                                    <TableCell align="left">{currencyFormat1(Math.round(x.totalAmount))}</TableCell>
                                                    <TableCell align="left">{''}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {receiptList && receiptList.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={receiptList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>

                    <div className="printViewBox" ref={htmlContentRef} id="myHtmlContent">
                        <div class="grid-container">
                            <div class="logo-container">
                                <img src="/static/logo-rgca.jpg" alt="Company logo" />
                            </div>
                            <div class="text-container">
                                <div>
                                    <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                                    <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                                    <h5>HEAD OFFICE / UNIT OFFICE</h5>
                                </div>
                            </div>
                        </div>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid item>
                                <Typography>
                                    Name :  Sales Report
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    From Date : {moment(receiptList[0]?.fromDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    To Date : {moment(receiptList[0]?.toDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Date : {currentDate}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Project Name :  {projectDetails?.projectName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={6}>
                                Total Quantity : {currencyFormat1(totalQty(receiptList))}
                            </Grid>
                            <Grid item lg={6}>
                                Total Amount : ₹ {totalAmount(receiptList)}
                            </Grid>
                        </Grid>
                        <div class="">
                            <table class="table">
                                <thead class='tableHead'>
                                    <tr class="table-rows">
                                        <th align="left">Sell ID</th>
                                        <th align="left">Product Name</th>
                                        <th align="left">Selling Date</th>
                                        <th align="left">Rate Per Unit</th>
                                        <th align="left">Quantity</th>
                                        <th align="left">GST</th>
                                        <th align="left">Amount</th>
                                        {/* <th align="left">Paid Amount</th> */}
                                        <th align="left">State Name</th>
                                        <th align="left">Batch No</th>
                                        <th align="left">Customer Name</th>
                                        {/* <th align="left">Purpose</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.map((x, index) => {
                                            return (
                                                <tr class="table-row">
                                                    <td align="left">{x?.sellingMasterID}</td>
                                                    <td align="left">{x?.Productname}</td>
                                                    <td align="left">{moment(x?.sellingDate).format('DD/MM/YYYY')}</td>
                                                    <td align="left">{currencyFormat1(Math.round(x.ratePerUnit))}</td>
                                                    <td align="left">{x.quantity}</td>
                                                    <td align="left">{x.gstRate}</td>
                                                    <td align="left">{currencyFormat1(Math.round(x.totalAmount))}</td>
                                                    <td align="left">{x.state}</td>
                                                    <td align="left">{x.batchNo}</td>
                                                    {/* <td align="left">{x.modeofReceipt == 42 ? 'CASH' : 'UPI'}</td> */}
                                                    <td align="left">{x.customer}</td>
                                                    {/* <td align="left">{x.purpose}</td> */}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr class="table-row">
                                            <td align="left">
                                                {'No Data Found'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default SalesReport