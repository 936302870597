import React, { useState } from 'react'
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from 'src/utils/message';
import {
    Box, Typography, makeStyles, Card, CardContent, Container, Button,
    TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel,
    MenuItem, FormHelperText, TablePagination, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Address from '../Common/Address';
import { RGCA_URLS } from 'src/utils/url';
import APIKit from 'src/utils/APIKit';
import { toast } from 'react-toastify';
import Loader from 'src/views/loader';
import ClearIcon from '@mui/icons-material/Clear';
import MultiSelectforSamples from './multiselect';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));


const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const LabTest = ({ AllCustomer, projectDetails, setIsAddDialoglab, lookupData,
    setIsLoading, getMaterialsForSelling, isLoading, getLabTestDataDetailsData = () => { } }) => {
    const classes = useStyles();
    const [isTestNameServices, setTestNameServices] = useState([]);
    const [isPackageData, setPackageData] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [isType, setIsType] = useState("farmer")
    const [isPackageDesc, setIsPackageDesc] = useState('')
    const [isPackageAmount, setIsPackageAmount] = useState('')
    const [labFile, setLabFile] = useState("")
    const [isForm, setIsForm] = useState('')
    const [itemList, setItemList] = useState([
        {
            itemID: '',
            qty: '',
            total: '',
            advancedReceived: '',
            balance: '',
            materialList: [],
            typeID: '',
            iqty: ''
        }
    ]);
    const [payload, setPayload] = useState({
        // projectID: null,
        // customerID: '',
        // sellingDate: '',
        // items: itemList
        Type: isType,
        customerID: null,
        projectID: null,
        packageMasterID: "",
        sampleDetails: [],
        code: "",
        noofsamples: null,
        samplereceivedby: "",
        samplecollectionstatusID: null,
        samplecollectionDateAndTime: "",
        specimenCaseNo: "",
        amount: null,
        fileAttach: "",
        note:""
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedSample, setSelectedSample] = useState(null);
    const [emplyoees, setEmplyoees] = useState([]);



    React.useEffect(() => {
        setTotalAmount(calculateTotalAmount());
        getTestNameServicesData();
        getAllProjects();
        getAllEmplyoees();
    }, [itemList]);

    const calculateTotalAmount = () => {
        let total = 0;
        itemList.forEach(item => {
            total += item.rpu === undefined
                ? 0
                : item.gstRate !== 0
                    ? (Number(item.rpu) *
                        Number(item.iqty) *
                        Number(item.gstRate)) /
                    100 +
                    Number(item.rpu) * Number(item.iqty)
                    : Number(item.rpu) * Number(item.iqty)
        });
        return total;
    };

    const getTestNameServicesData = () => {
        APIKit.get(RGCA_URLS.getTestNameServices)
            .then((res) => {
                if (res.data.status === 200) {
                    setTestNameServices(res.data.data)
                } else {

                }
            })
            .catch((e) => {
            });
    };
    const getpackagesDetailsData = (isServiceName) => {
        APIKit.post(RGCA_URLS.getpackagesDetails, {
            "TestServiceName": isServiceName

        })
            .then((res) => {
                if (res.data.status === 200) {
                    setPackageData(res.data.data)
                } else {

                }
            })
            .catch((e) => {
            });
    };
    const handleIncClose = () => {
        setIsAddDialoglab(false);
        setItemList([
            {
                itemID: '',
                qty: '',
                total: '',
                advancedReceived: '',
                balance: '',
                materialList: [],
                typeID: '',
                iqty: ''
            }
        ]);
        setPayload({});
    };


    const handleSubmit1 = () => {
        let payload1 = {
            // customerID: Number(payload.customerID),
            projectID: projectDetails.projectID,
            batchNo: '',
            FormName: isForm,
            fileAttach: labFile,
            // sellingDate: dateOnlyRev(payload.sellingDate),
            LabTestDate: payload.sellingDate,
            totalAmount: totalAmount,
            items: itemList.map(e => {
                return {
                    itemID: e.itemID,
                    qty: Number(e.iqty),
                    // total: Number(e.qty * e.rpu),
                    advancedReceived: Number(e.advancedReceived),
                    balance: Number(e.iqty * e.rpu/* - e.advancedReceived */),
                    total: e.rpu === undefined ? 0 : e.gstRate != 0
                        ? (Number(e.rpu) *
                            Number(e.iqty) *
                            Number(e.gstRate)) /
                        100 +
                        Number(e.rpu) * Number(e.iqty)
                        : Number(e.rpu) * Number(e.iqty),

                    // balance: Number(total - e.advancedReceived),
                };
            })
        };
        setIsLoading(true);
        APIKit.post(RGCA_URLS.createLabTest, payload1)
            .then(res => {
                if (res.data.status === 200) {
                    setItemList([
                        {
                            itemID: '',
                            qty: '',
                            total: '',
                            advancedReceived: '',
                            balance: '',
                            materialList: [],
                            typeID: '',
                            iqty: ''
                        }
                    ]);
                    setPayload({});
                    setIsAddDialoglab(false);
                    getMaterialsForSelling();
                    setIsLoading(false);
                    toast.success(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                } else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            })
            .catch(function (e) {
                setIsLoading(false);
            });
    };


    const handleUploadClick = (event, i, type) => {
        const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        if (!isRightFormat) {
            toast.error("You can only upload pdf , csv,excel, and doc files");
            return;
        }
        if (event.target.files[0] === undefined) {
            return;
        }
        if (event.target.files[0].size > 3145728) {
            toast.error(MESSAGE.lessthan3mb, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append(
            'files',
            event.target.files[0],
            event.target.files[0].name
        );
        if (type == 'lab') {
            APIKit.post(RGCA_URLS.upload, formData)
                .then(res => {

                    if (res.data.status == 200) {
                        setIsLoading(false);
                        setLabFile(res.data.data)
                        // setTotalData({
                        //   ...totalData,
                        //   invoiceAttach: res.data.data
                        // });
                        toast.success(MESSAGE.itemAttach, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    } else {
                        setIsLoading(false);
                        toast.error(MESSAGE.fileNotUpload, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                })
                .catch((e) => {
                    console.log("fileUpload error", e);
                });
        }

    };
    const getAllProjects = () => {
        APIKit.post(RGCA_URLS.getProjects)
            .then(res => {
                if (res.data.status === 200) {
                    console.log('kkk', res.data.data);
                    setAllProjects(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch(() => { });
    };

    const getAllEmplyoees = () => {
        setIsLoading(true);
        APIKit.get(RGCA_URLS.getEmployee)
            .then(res => {
                if (res.data.status === 200) {
                    setIsLoading(false);
                    setEmplyoees(res.data.data);
                } else {
                    setIsLoading(false);
                    // toast.error(res.data.message);
                }
            })
            .catch(e => {
                setIsLoading(false);
            });
    };

    let regEx = {
        textOnly: /[a-zA-Z\s]+/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/
    };
    return (
        <>
            <Loader isLoading={isLoading} />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Type: isType,
                    customerID: null,
                    projectID: null,
                    packageMasterID: "",
                    sampleDetails: [],
                    code: "",
                    noofsamples: null,
                    samplereceivedby: "",
                    samplecollectionstatusID: null,
                    samplecollectionDateAndTime: "",
                    specimenCaseNo: "",
                    amount: null,
                    fileAttach: "",
                    note:""
                }}
                onSubmit={(values, { resetForm }) => {
                    console.log("ppppp", payload);
                    // handleSubmit1();
                    setIsLoading(true)
                    const payloadadd = {
                        Type: isType,
                        customerID: payload?.customerID,
                        projectID: payload?.projectID,
                        packageMasterID: payload?.packageMasterID?.packageMasterID,
                        sampleDetails: selectedSample,
                        code: payload?.code,
                        noofsamples: payload?.noofsamples,
                        samplereceivedby: payload?.samplereceivedby,
                        samplecollectionstatusID: payload?.samplecollectionstatusID,
                        samplecollectionDateAndTime: payload?.samplecollectionDateAndTime,
                        specimenCaseNo: payload?.specimenCaseNo,
                        amount: payload?.amount,
                        fileAttach: labFile,
                        note:payload.note
                    };
                    console.log("payloadadd", payloadadd);
                    APIKit.post(RGCA_URLS.createLabTestData, payloadadd)
                        .then(res => {
                            if (res.data.status === 200) {
                                setIsLoading(false);
                                setIsAddDialoglab(false);
                                getLabTestDataDetailsData();
                                toast.success(MESSAGE.receiptCreate, {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            } else {
                                setIsLoading(false);
                                toast.error(res.data.error, {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            }
                        })
                        .catch(e => {
                            setIsLoading(false);
                        });
                }}
                validationSchema={Yup.object().shape({
                    // Selling: Yup.array().of(
                    //     Yup.object().shape({
                    //         typeID: Yup.string().required(MESSAGE.type),
                    //         qty: Yup.string()
                    //             .required(MESSAGE.qty)
                    //             .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                    //         itemID: Yup.string().required(MESSAGE.materials)
                    //     })
                    // ),
                    // customerID: Yup.string().required(MESSAGE.customer),
                    // date: Yup.string().required(MESSAGE.date)
                })}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isInitialValid = false,
                    isValid,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <DialogContent dividers style={{ width: '900px !important' }}>
                            <FormControl>
                                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        setIsType(e.target.value)
                                        if (e.target.value == 'project') {
                                            const p = payload;
                                            p.customerID = null;
                                            setPayload({
                                                ...p
                                            });
                                        } else {
                                            const p = payload;
                                            p.projectID = null;
                                            setPayload({
                                                ...p
                                            });
                                        }
                                        console.log('aaaaaaaa', e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="farmer" checked={isType == 'farmer'} control={<Radio />} label="Farmer" />
                                    <FormControlLabel value="project" checked={isType == 'project'} control={<Radio />} label="Project" />
                                </RadioGroup>
                            </FormControl>

                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    {isType == 'farmer' &&
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                            error={Boolean(touched.customerID && errors.customerID)}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-outlined-label"
                                                required
                                            >
                                                Customer
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Location"
                                                name="customerID"
                                                required
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.customerID = Number(e.target.value);
                                                    setPayload({
                                                        ...p
                                                    });
                                                }}
                                                value={payload.customerID}
                                                onBlur={handleBlur}
                                            >
                                                {AllCustomer &&
                                                    AllCustomer.map(x => {
                                                        return (
                                                            <MenuItem value={x.customerID} key={x.customerID}>
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            <FormHelperText>
                                                {errors.customerID &&
                                                    touched.customerID &&
                                                    errors.customerID}
                                            </FormHelperText>
                                        </FormControl>
                                    }
                                    {isType == 'project' &&

                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Projects
                                            </InputLabel>

                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // name={projects}
                                                required
                                                onChange={e => {
                                                    handleChange(e);
                                                    const p = payload;
                                                    p.projectID = Number(e.target.value);
                                                    setPayload({ ...p });
                                                }}
                                                value={payload?.projectID}
                                                label="Projects"
                                            >
                                                {allProjects &&
                                                    allProjects.length > 0 &&
                                                    allProjects.map(x => {
                                                        return (
                                                            <MenuItem value={x.projectID} key={x.projectID}>
                                                                {x.projectName}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            required
                                        >
                                            Test Service Name
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Test Service Name"
                                            name=""
                                            onChange={(e) => {
                                                // setIsForm(e.target.value)
                                                setIsPackageDesc('')
                                                getpackagesDetailsData(e.target.value)
                                            }}
                                        >
                                            {isTestNameServices && isTestNameServices.map((item, index) => {
                                                return <MenuItem value={item.TestServiceName} key={index + 1}>{item.TestServiceName}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr} >
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            required
                                        >
                                            Packages
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Name of the form"
                                            name="packageMasterID"
                                            value={payload?.packageMasterID}
                                            onChange={e => {
                                                let p = payload
                                                p.packageMasterID = e.target.value
                                                setPayload({ ...p });
                                                setIsPackageDesc(e.target.value.Description);
                                                setIsPackageAmount(e.target.value.ServiceFee);
                                            }}
                                        >
                                            {isPackageData && isPackageData.map((pack, index) => {
                                                return <MenuItem value={pack} key={index + 1}>{pack.packages}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr} >
                                    <MultiSelectforSamples setSelectedSample={setSelectedSample} selectedSample={selectedSample} />
                                </Grid>

                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <TextField
                                        label="No Of Samples"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => {
                                            if (e.target.value === '' ||
                                                regEx.numbersOnly.test(e.target.value)
                                            ) {
                                                let p = payload
                                                p.noofsamples = Number(e.target.value)
                                                setPayload({ ...p });
                                            }
                                        }}
                                        value={payload?.noofsamples}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <TextField
                                        fullWidth
                                        label="Code"
                                        name="batchNo"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            let p = payload
                                            p.code = e.target.value
                                            setPayload({ ...p });
                                        }}
                                        value={payload?.code}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            required
                                        >
                                            Sample Received By
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={payload?.samplereceivedby}
                                            name="samplereceivedby"
                                            label="Sample Received By"
                                            required
                                            onChange={(e) => {
                                                const p = payload;
                                                p.samplereceivedby = e.target.value;
                                                setPayload({ ...p })
                                            }}
                                        >
                                            {emplyoees && emplyoees.map((x) => {
                                                return <MenuItem value={x.empLoginID} key={x.empLoginID}>{x.empLoginID}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            required
                                        >
                                            Sample Collection Status </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Sample Collection Status"
                                            name="samplecollectionstatusID"
                                            value={payload?.samplecollectionstatusID}
                                            onChange={(e) => {
                                                setIsForm(e.target.value)
                                                let p = payload
                                                p.samplecollectionstatusID = e.target.value
                                                setPayload({ ...p });
                                            }}
                                        >
                                            <MenuItem value={'1'}>Satisfactory</MenuItem>
                                            <MenuItem value={'2'}>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {payload?.samplecollectionstatusID == '2' &&
                                    <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                        <TextField
                                            label="Reason for Rejection"
                                            name="specimenCaseNo"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => {
                                                setIsForm(e.target.value)
                                                let p = payload
                                                p.note = e.target.value
                                                setPayload({ ...p });
                                            }}
                                            inputProps={{ maxLength: 250 }}
                                            value={payload?.note}
                                        />
                                    </Grid>
                                }
                                <Grid item lg={4} sm={6} xl={6} xs={6}>
                                    <KeyboardDatePicker
                                        className={classes.dat}
                                        autoOk
                                        fullWidth
                                        InputProps={{ disabled: true }}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        // maxDate={new Date}
                                        disableFuture
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Sample Collection Date "
                                        format="dd/MM/yyyy"
                                        name="date "
                                        value={payload.samplecollectionDateAndTime ?? null}
                                        InputAdornmentProps={{ position: 'end' }}
                                        onChange={date => {
                                            const p = payload;
                                            p.samplecollectionDateAndTime = moment(date).format('YYYY-MM-DD');
                                            setPayload({ ...p });
                                        }}
                                        required
                                        error={Boolean(touched.date && errors.date)}
                                        helperText={touched.date && errors.date}
                                    />
                                </Grid>

                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <TextField
                                        label="Specimen Case No"
                                        name="specimenCaseNo"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => {
                                            setIsForm(e.target.value)
                                            let p = payload
                                            p.specimenCaseNo = e.target.value
                                            setPayload({ ...p });
                                        }}
                                        value={payload?.specimenCaseNo}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xl={6} xs={6} className={classes.custmr}>
                                    <TextField
                                        label="Amount"
                                        name="batchNo"
                                        fullWidth
                                        variant="outlined"
                                        value={payload?.amount}
                                        onChange={(e) => {
                                            // setIsForm(e.target.value)
                                            if (e.target.value === '' ||
                                                regEx.numbersOnly.test(e.target.value)
                                            ) {
                                                let p = payload
                                                p.amount = Number(e.target.value)
                                                setPayload({ ...p });
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={4} sm={12} xl={6} xs={12}>
                                    {/* <Typography>Attachment</Typography> */}
                                    <Button
                                        style={{ width: '70%', height: '55px' }}
                                        variant="contained"
                                        component="label"
                                    >
                                        {/* {!totalData[0].invoiceAttach ? 'Invoice Attachment *' : null} */}
                                        {'File Attachment *'}
                                        <input
                                            type="file"
                                            hidden
                                            onChange={e =>
                                                handleUploadClick(e, 0, 'lab')
                                            }
                                        />
                                        {labFile && (
                                            <a
                                                style={{
                                                    color: 'blue',
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    marginTop: '35px'
                                                }}
                                                href={labFile}
                                                target="_blank"
                                            >
                                                {labFile ? labFile.substring(4, 15) : labFile.substring(4, 15)}....
                                                {labFile ? labFile.split('.').pop() : labFile.split('.').pop()}
                                            </a>
                                        )}
                                    </Button>
                                    {/* {labFile &&
                                        <ClearIcon onClick={() => {
                                            setLabFile("")

                                        }} style={{ cursor: 'pointer' }} />
                                    } */}
                                    {labFile &&
                                        <ClearIcon onClick={() => {
                                            setLabFile("")
                                        }} style={{ cursor: 'pointer' }} />
                                    }
                                </Grid>



                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12} sm={12} xl={6} xs={12}>
                                    {payload.customerID ? (
                                        <>
                                            {' '}
                                            <b> Customer address : </b>
                                            <Address
                                                details={
                                                    AllCustomer?.filter(e => {
                                                        if (e.customerID === payload?.customerID) {
                                                            return {
                                                                address: e?.address
                                                            };
                                                        }
                                                    })[0]?.address
                                                }
                                            />{' '}
                                        </>
                                    ) : null}
                                    {payload.projectID ? (
                                        <>
                                            {' '}
                                            <b> Project address : </b>
                                            <Address
                                                details={
                                                    allProjects?.filter(e => {
                                                        if (e.projectID === payload?.projectID) {
                                                            return {
                                                                address: e?.address
                                                            };
                                                        }
                                                    })[0]?.address
                                                }
                                            />{' '}
                                        </>
                                    ) : null}
                                </Grid>
                                <Grid item lg={12} sm={12} xl={6} xs={12}>
                                    {isPackageDesc ?
                                        <>
                                            {' '}
                                            <b> Test Description : </b>
                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    {isPackageDesc}
                                                </Typography>
                                            </Box>
                                        </>
                                        : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item lg={12} sm={12} xl={12} xs={12}>
                                    {/* <div style={{ textAlign: 'Left' }}>
                                        <b> Total Purchase Amount : 2000</b>
                                    </div> */}
                                    <div style={{ textAlign: 'right' }}>


                                        <Button
                                            color="primary"
                                            onClick={handleIncClose}
                                            variant="contained"
                                        >
                                            Close
                                        </Button>
                                        &nbsp;
                                        <Button
                                            color="primary"
                                            // onClick={handleSubmit}
                                            type="submit"
                                            variant="contained"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default LabTest;