import React, { useState } from 'react';
import Select from 'react-select';
// import {
//   Box,
//   Typography,
//   makeStyles,
//   Container,
//   Button,

//   TextField,
//   Select,
//   InputLabel,
//   FormControl,
//   MenuItem,
//   Divider,
//   FormHelperText
// } from '@material-ui/core';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

export default function CommonSelect({ options, onChange, val, labelName = null }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      minHeight: 55
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background:'#fff',
      // background: isFocused
      //   ? 'hsla(#0000ff, 64%, 42%, 0.5)'
      //   : isSelected
      //     ? 'hsla(#0000ff, 64%, 42%, 1)'
      //     : undefined,
      zIndex: 1
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: base => ({
      ...base,
      zIndex: 100
    })
  }
  return (
    <div className="App">
      <Select
        // isDisabled={!options?.length}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        value={Object.keys(val)?.length === 0 ? null : val}
        placeholder={labelName ?? 'Search Material*'}
        onChange={onChange}
        options={options?.map(e => {
          return {
            value: e.itemID,
            label: e.name
          }
        })}
      />
       
    </div>
  );
}
