import * as React from 'react';
import { Typography, Box } from '@material-ui/core';

const Address = ({ details = null }) => {
  if (!details) {
    return null;
  }
  const { line_1,
    line_2,
    line_3,
    district,
    state,
    country,
    postalCode } = JSON.parse(details)[0];

  // let first = line_1;
  // if (line_2) first += first ? `, ${line_2}` : "";
  // if (line_3) first += first ? `, ${line_3}` : "";
  // if (district) first += first ? `, ${district} Dist` : "";
  // let second = state;
  // if (country) second += second ? `, ${country}` : "";

  // if (postalCode) second += second ? `, ${postalCode}` : "";
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="body1" gutterBottom>
      {/* <b> Address </b> : {line_1} <br />  <b> Address Line 2 </b> : {line_2},<br /> <b> Address Line 3 </b> : {line_3} <br /> <b> District</b> :{district}<br /> <b> State</b> :{state}<br /> <b> Country</b> :{country}<br /> <b> Postal Code</b> :{postalCode}<br /> */}
      {/* <b> Address </b> : {line_1} <br />  <b> Address Line 2 </b> : {line_2},<br /> <b> Address Line 3 </b> : {line_3} <br /> <b> District</b> :{district}<br /> <b> State</b> :{state}<br /> <b> Country</b> :{country}<br /> <b> Postal Code</b> :{postalCode}<br /> */}
      {line_1}  {line_2} {line_3} {district},{' '}{state},{' '}{country},{' '}{postalCode}
      </Typography>
      {/* <Typography variant="body2" gutterBottom>
        {second}
      </Typography> */}
    </Box>
  );
}

export default Address;
