import React, { useEffect, useState } from 'react'
import {
    Box, Card, TableContainer, Table, Grid, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl,
    MenuItem, FormHelperText, TextareaAutosize, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddressSingleLine from '../Project/Common/Address_singleline';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Address from '../Project/Common/Address';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import Loader from '../loader';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { MESSAGE } from 'src/utils/message';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    textarea: {
        resize: "both"
    }, centertext: {
        marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
        '@media (max-width: 500px)': {
            marginLeft: "110px"
        },
        '@media (max-width: 350px)': {
            marginLeft: "60px"
        },
    },

}));

const VendorRfq = ({ allRFQQuotation, setIsLoading, isLoading, allVendors, getRFQQuotation = () => { } }) => {

    const classes = useStyles();
    const [isSendMail, setIsSendMail] = useState(false);
    const [payload, setPayload] = useState({
        vendor: '',
        quotation: '',
        attachment: '',
        email: '',
        name: ''
    })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [vendor, setVendor] = useState({
        name: '',
        companyType: '',
        companyName: '',
        gstn: '',
        gstnAttach: '',
        panAttach: '',
        pan: '',
        aadharAttach: '',
        aadhar: '',
        email: '',
        mobile: '',
        address: {
            line_1: '',
            line_2: '',
            district: '',
            state: '',
            country: '',
            postalCode: ''
        },
        bankDetails: {
            bankName: '',
            bankAccountNo: '',
            bankBranch: '',
            bankIFSC: '',
            bankAccountType: '',
            bankAccountHolderName: '',
            cancelChequeAttach: ''
        }
    });
    const [isVendor, setIsVendor] = useState('ext');
    const [isAddVendor, setIsAddVendor] = useState(false)
    const [defaultValue, setDefaultValue] = useState(null);
    const [isVendorError, setIsVendorError] = useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleUploadClick = (event, type, wat) => {
        const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        if (!isRightFormat) {
            toast.error("You can only upload pdf , csv,excel, and doc files");
            return;
        }
        if (event.target.files[0] === undefined) {
            return;
        }
        if (event.target.files[0].size > 3145728) {
            toast.error(MESSAGE.lessthan3mb, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append("files", event.target.files[0], event.target.files[0].name);
        if (type == "Quotation") {
            APIKit.post(RGCA_URLS.upload, formData)
                .then((res) => {
                    if (res.data.status == 200) {
                        setIsLoading(false);
                        toast.success(MESSAGE.fileUpload, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        let p = payload
                        p.attachment = res?.data?.data
                        setPayload({ ...p })
                    } else {
                        setIsLoading(false);
                        toast.error(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };


    // useEffect(() => {
    //     if (allVendors && allVendors.length > 0) {
    //         const defaultVendor = { value: allVendors[0].vendorID, label: allVendors[0].name };
    //         setDefaultValue(defaultVendor);
    //         setPayload((prevPayload) => ({
    //             ...prevPayload,
    //             vendor: defaultVendor.value,
    //             email: allVendors[0].email,
    //             name: allVendors[0].name,
    //         }));
    //     }
    // }, [allVendors]);

    const addRFQQuotation = () => {
        setIsLoading(true);

        if (isVendorError == true && payload.vendor == '') {
            setIsVendorError(true)
            return;
        }
        let payloads = {
            vendorID: payload.vendor,
            Quotation: payload.quotation,
            specificAttach: payload.attachment
        }
        APIKit.post(RGCA_URLS.addRFQQuotation, payloads)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message)
                    setIsSendMail(false);
                    setIsLoading(false);
                    getRFQQuotation();
                    setPayload({
                        vendor: '',
                        quotation: '',
                        attachment: '',
                        email: '',
                        name: ''
                    });
                } else {
                    toast.warn(res.data.message);
                    setIsLoading(false);
                }
            })
            .catch(e => { setIsLoading(false); });
    }
    const getVendor = () => {
        setIsLoading(true);
        APIKit.post(RGCA_URLS.listVendor)
            .then(res => {
                if (res.data.status === 200) {
                    console.log("resssss", res.data.data);
                    if (res?.data?.data?.length > 0) {
                        const lastIndex = res?.data?.data?.length - 1;
                        setPayload((prevPayload) => ({
                            ...prevPayload,
                            vendor: res?.data?.data[0]?.vendorID,
                            email: res?.data?.data[0]?.email,
                            name: res?.data?.data[0]?.name
                        }));
                        setIsVendorError(false)
                    } else {

                    }
                    setIsLoading(false);
                    //   setAllVendors(res?.data?.data);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(e => {
                setIsLoading(false);
            });
    };

    const regEx = {
        textOnly: /^[A-Za-z\s]+$/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9]*$/
    };

    console.log("allRFQQuotation", allRFQQuotation);
    return (
        <>
            <Loader isLoading={isLoading} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={12} lg={6} className={classes.centertext} >
                    RFQ History
                </Grid>
                <Grid item xs={6} sm={12} lg={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setPayload({
                                vendor: '',
                                quotation: '',
                                attachment: '',
                                email: '',
                                name: ''
                            });
                            setIsVendor('ext');
                            setIsSendMail(true);
                        }}
                    >
                        Send Email For RFQ
                    </Button>
                </Grid>
            </Grid>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">S.No</TableCell>
                                        <TableCell align="left">Vendor Name</TableCell>
                                        <TableCell align="left">Email Address</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">RFQ Description</TableCell>
                                        <TableCell align="left">Specifications Attachment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allRFQQuotation && allRFQQuotation.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((vendor, index) => (
                                        <TableRow hover >
                                            <TableCell align="left">
                                                {page * 10 + index + 1}
                                            </TableCell>
                                            <TableCell align="left">{vendor?.name}</TableCell>
                                            <TableCell align="left">{vendor?.email}</TableCell>
                                            <TableCell align="left">{vendor?.CreatedDate ? moment(vendor.CreatedDate).format('DD-MM-YYYY') : ''}</TableCell>
                                            <TableCell align="left">
                                                <Tooltip title={vendor?.Quotation}>
                                                    <Typography>{vendor.Quotation ? vendor.Quotation.slice(0, 15) : ''}</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{vendor?.specificAttach &&
                                                <a href={vendor?.specificAttach}>{vendor?.specificAttach.substring(0, 25)}
                                                </a>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {allRFQQuotation && allRFQQuotation.length > 10 &&
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={allRFQQuotation.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>


            <Dialog
                open={isSendMail}
                // onClose={() => { setIsSendMail(false) }}
                id="customized-dialog-title"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title"
                    onClose={() => { setIsSendMail(false) }}
                    style={{ backgroundColor: "#394671", color: "#fff", }}>
                    RFQ & Quotation
                </DialogTitle>
                <Loader isLoading={isLoading} />
                <Formik
                    initialValues={{
                        vendor: '',
                        quotation: '',
                        attachment: '',
                        isVendor: isVendor,
                        newVendor: ''
                    }}
                    onSubmit={(values, { resetForm }) => {
                        setIsLoading(true);
                        console.log("values", values);
                        addRFQQuotation()
                    }}
                    validationSchema={Yup.object().shape({
                        // vendor: Yup.string().required(MESSAGE.vendor),
                        quotation: Yup.string().required('Quotation is Required'),
                        isVendor: Yup.string()
                            .required('Please select a portal (MIS or GEM).')
                            .oneOf(['ext', 'new'], 'Invalid portal selection.'),
                        vendor: Yup.string().when('isVendor', {
                            is: (value) => value === 'ext',
                            then: Yup.string().required('Vendor is required'),
                        }),
                        // newVendor: Yup.string().when('isVendor', {
                        //     is: (value) => value === 'gem',
                        //     then: Yup.string().required('Vendor is required'),
                        // }),
                    })}
                >
                    {({
                        errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
                    }) => (
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <DialogContent style={{ overflowX: 'hidden' }}>
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="position" name="position">
                                                <FormControlLabel
                                                    value="ext"
                                                    control={<Radio checked={isVendor === 'ext'} color="primary" name='isVendor' onChange={(e) => {
                                                        setValues({ ...values, isVendor: e.target.value });
                                                        setIsVendor('ext');
                                                        setPayload({
                                                            vendor: '',
                                                            quotation: '',
                                                            attachment: '',
                                                            email: '',
                                                            name: ''
                                                        });
                                                        setIsVendorError(false);
                                                    }} />}
                                                    label="Existing Vendor"
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="new"
                                                    control={<Radio checked={isVendor === 'new'} color="primary" name='isVendor' onChange={(e) => {
                                                        setValues({ ...values, isVendor: e.target.value });
                                                        setIsVendor('new');
                                                        setPayload({
                                                            vendor: '',
                                                            quotation: '',
                                                            attachment: '',
                                                            email: '',
                                                            name: ''
                                                        });
                                                        setIsVendorError(true);
                                                    }} />}
                                                    label="New Vendor"
                                                    labelPlacement="start"
                                                />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {errors.isVendor && touched.isVendor && errors.isVendor}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {isVendor == 'new' &&
                                        <>
                                            <Grid item md={4} xs={12}>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#394671',
                                                        color: '#fff',
                                                        border: '1px solid',
                                                        marginTop: '10px'
                                                    }}
                                                    onClick={() => {
                                                        setVendor({
                                                            name: '',
                                                            companyType: '',
                                                            companyName: '',
                                                            gstn: '',
                                                            gstnAttach: '',
                                                            panAttach: '',
                                                            pan: '',
                                                            aadharAttach: '',
                                                            aadhar: '',
                                                            email: '',
                                                            mobile: '',
                                                            address: {
                                                                line_1: '',
                                                                line_2: '',
                                                                district: '',
                                                                state: '',
                                                                country: '',
                                                                postalCode: ''
                                                            },
                                                            bankDetails: {
                                                                bankName: '',
                                                                bankAccountNo: '',
                                                                bankBranch: '',
                                                                bankIFSC: '',
                                                                bankAccountType: '',
                                                                bankAccountHolderName: '',
                                                                cancelChequeAttach: ''
                                                            }
                                                        })
                                                        setIsAddVendor(true);
                                                    }}
                                                >
                                                    Add New Vendor
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <Grid container spacing={3} style={{ marginTop: '30px' }}>
                                    {isVendor == 'ext' &&
                                        <>
                                            <Grid item md={4} xs={12}>
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-2-demo"
                                                    disableClearable
                                                    options={allVendors && allVendors?.map((option) => ({ value: option?.vendorID, label: option?.name }))}
                                                    value={payload?.name}
                                                    onChange={(event, newValue) => {
                                                        console.log('New value selected:', newValue);
                                                        if (newValue) {
                                                            setValues({ ...values, vendor: newValue.value });
                                                            let item = payload;
                                                            item.vendor = newValue.value;
                                                            const selectedVendor = allVendors.find(vendor => vendor.vendorID === newValue.value);
                                                            if (selectedVendor) {
                                                                item.email = selectedVendor?.email;
                                                                item.name = selectedVendor?.name;
                                                            }
                                                            setPayload({ ...item });
                                                        }
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        if (newInputValue === '') {
                                                            setValues({ ...values, vendor: '' });
                                                            setPayload({ ...payload, vendor: '', email: '', name: '' });
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Search Vendors"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                            }}
                                                            error={Boolean(touched.vendor && errors.vendor)}
                                                            fullWidth
                                                            helperText={touched.vendor && errors.vendor}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            {payload?.vendor ? (
                                                <Grid item lg={4} sm={12} xl={6} xs={12}>
                                                    <>
                                                        <b> Vendor Address : </b>
                                                        <Address
                                                            details={
                                                                allVendors && allVendors?.filter(e => {
                                                                    if (e.vendorID === payload?.vendor) {
                                                                        return {
                                                                            address: e?.address
                                                                        };
                                                                    }
                                                                })[0]?.address
                                                            }
                                                        />
                                                    </>
                                                </Grid>
                                            ) : null}
                                            {payload?.email &&
                                                <Grid item md={4} xs={12}>
                                                    <b>Email Address : </b>{payload.email}
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {isVendor == 'new' &&
                                        <>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    id="outlined-textarea"
                                                    fullWidth
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 200 }}
                                                    label="Vendor"
                                                    name="Vendor"
                                                    required
                                                    value={payload.name}
                                                    disabled
                                                    // onChange={(e) => {''}}
                                                    // error={Boolean(touched.quotation && errors.quotation)}
                                                    // helperText={touched.quotation && errors.quotation}
                                                    helperText={isVendorError ? 'Please add new vendor' : ''}
                                                    FormHelperTextProps={{
                                                        style: { color: 'red' },
                                                    }}
                                                />
                                            </Grid>
                                            {payload?.email &&
                                                <Grid item md={4} xs={12}>
                                                    <b>Email Address : </b>{payload.email}
                                                </Grid>
                                            }
                                        </>
                                    }
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            id="outlined-textarea"
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                            inputProps={{ className: classes.textarea, maxLength: 1000 }}
                                            label="RFQ & Quotation"
                                            name="quotation"
                                            required
                                            value={payload.quotation}
                                            onChange={(e) => {
                                                handleChange(e)
                                                let p = payload
                                                p.quotation = e.target.value
                                                setPayload({ ...p })
                                            }}
                                            error={Boolean(touched.quotation && errors.quotation)}
                                            helperText={touched.quotation && errors.quotation}
                                        />
                                        <small style={{ color: "gray" }}><code
                                            className="me-2">{payload?.quotation?.length}/1000</code> characters</small>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            style={{ width: "65%", height: "130%" }}
                                            variant="contained"
                                            component="label"
                                        >
                                            {!payload.attachment ? 'Specifications Attachment ' : ''}
                                            <div>
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) =>
                                                        handleUploadClick(e, "Quotation", "add")
                                                    }
                                                />
                                            </div>
                                            {payload.attachment && (
                                                <a
                                                    style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        marginTop: "9px",
                                                    }}
                                                    href={payload.attachment}
                                                    target="_blank"
                                                >
                                                    {payload.attachment?.substring(4, 25)}....
                                                    {payload.attachment?.split(".").pop()}
                                                </a>
                                            )}
                                        </Button>
                                        {payload?.attachment && (
                                            <ClearIcon onClick={() => {
                                                let p = payload
                                                p.attachment = ''
                                                setPayload({ ...p })
                                            }} style={{ cursor: 'pointer' }} />
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                                <Button onClick={() => {
                                    setIsSendMail(false);
                                    setPayload({
                                        vendor: '',
                                        quotation: '',
                                        attachment: '',
                                        email: ''
                                    });
                                    setIsVendor('ext');
                                }}>Close</Button>
                                <Button type='submit' autoFocus>Submit</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                open={isAddVendor}
                // onClose={() => { setIsSendMail(false) }}
                id="customized-dialog-title"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title"
                    onClose={() => { setIsAddVendor(false) }}
                    style={{ backgroundColor: "#394671", color: "#fff", }}>
                    Create Vendor
                </DialogTitle>
                <Formik
                    initialValues={{
                        name: vendor.name,
                        email: vendor.email,
                    }}
                    onSubmit={(values, { resetForm }) => {
                        setIsLoading(true);
                        console.log("values", values);
                        APIKit.post(RGCA_URLS.addVendor, vendor)
                            .then(res => {
                                if (res.data.status === 200) {
                                    setIsLoading(false);
                                    getVendor();
                                    setIsAddVendor(false)
                                    toast.success(MESSAGE.vendorAdded, {
                                        position: 'top-right',
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                    resetForm(values);
                                } else if (res.data.status == 401) {
                                    setIsLoading(false);
                                }
                                else {
                                    setIsLoading(false);
                                    toast.error(res.data.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                            })
                            .catch(e => {
                                setIsLoading(false);
                            });
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                        email: Yup.string().email(MESSAGE.validEmail).max(255).required(MESSAGE.email)
                    })}
                >
                    {({
                        errors, handleBlur, handleChange, handleSubmit, isSubmitting, isInitialValid = false, isValid, touched, values, setValues, resetForm
                    }) => (
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <DialogContent style={{ overflowX: 'hidden' }}>
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            inputProps={{ maxLength: 50 }}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={e => {
                                                if (
                                                    e.target.value === '' ||
                                                    regEx.textOnly.test(e.target.value)
                                                ) {
                                                    handleChange(e);
                                                    const p = vendor;
                                                    p.name = e.target.value;
                                                    setVendor({ ...p });
                                                }
                                            }}
                                            required
                                            value={vendor.name}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            inputProps={{ maxLength: 50 }}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            onBlur={handleBlur}
                                            onChange={e => {
                                                handleChange(e);
                                                const p = vendor;
                                                p.email = e.target.value;
                                                setVendor({ ...p });
                                            }}
                                            required
                                            value={vendor.email}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
                                <Button onClick={() => {
                                    setIsAddVendor(false);
                                    resetForm()
                                }}>Close</Button>
                                <Button type='submit' autoFocus>Submit</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

export default VendorRfq