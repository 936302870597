import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import data from './data';
import Page from 'src/components/Page';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link as useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px',
  },
  textleftshadow: {
    textAlign: 'left', boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right', boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }
}));

const useStyles1 = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  }

}));


const Suppliers = () => {
  const classes = useStyles();
  const [depositors, setDepositors] = useState([])
  // const [depositors] = useState(data);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit] = useState(10);
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setDepositors(data);
  }, depositors)



  const openAddSuppliers = () => {
    navigate('/app/AddSuppliers', { replace: true })
  }





  const handleClose = () => {
    setOpen(false);
  };

  const Toolbar = ({ className, ...rest }) => {
    const classes = useStyles1();

    return (
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
        >

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} style={{ marginTop: '10px', color: '#3f51b5', fontSize: '17px', fontWeight: 'bold' }}>
              SUPPLIERS
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={openAddSuppliers}
              >
                Add
              </Button>
            </Grid>
          </Grid>

        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box maxWidth={500}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search"
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>
    );
  };

  return (
    <Page
      className={classes.root}
      title="Suppliers">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>

                  <TableCell>
                    Supplier Name
                  </TableCell>
                  <TableCell>
                    GST No
                  </TableCell>
                  <TableCell>
                    Reg. No
                  </TableCell>
                  <TableCell>
                    Address
                  </TableCell>
                  <TableCell style={{ width: '130px' }}>
                    Contact No
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {depositors.slice(0, limit).map((depositor) => (
                  <TableRow
                    hover
                    key={depositor.id}
                    selected={selectedCustomerIds.indexOf(depositor.id) !== -1}
                  // onClick={handleClickOpen}
                  >

                    <TableCell>
                      {depositor.name}
                    </TableCell>
                    <TableCell>
                      {depositor.gst}
                    </TableCell>
                    <TableCell>
                      {depositor.reg}
                    </TableCell>
                    <TableCell>
                      {depositor.address.country}, {depositor.address.state}, {depositor.address.city},<br />
                      {depositor.address.street}
                    </TableCell>

                    <TableCell style={{ width: '130px' }}>
                      {depositor.phone}
                    </TableCell>
                    <TableCell>
                      {depositor.email}
                    </TableCell>
                    {/* <TableCell>
                    {moment(depositor.createdAt).format('DD/MM/YYYY')}
                  </TableCell> */}
                    <TableCell>
                      <EditOutlinedIcon onClick={openAddSuppliers} />
                      <DeleteForeverIcon />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* dialog */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
            <div style={{ justifyContent: 'flex-end', alignItems: 'center', display: 'flex', cursor: 'pointer' }} onClick={handleClose}>X</div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '30px' }}>
                <Avatar
                  // alt="Remy Sharp"
                  src="/static/images/avatars/avatar_3.png"
                  className={classes.logostyle}
                />
              </div>


              <div style={{ textAlign: 'center' }}>

                <Grid container spacing={1} style={{ marginBottom: '30px' }}>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Name:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>Ekaterina Tankova</Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Email:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>ekaterina.tankova@devias.io</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Contact No:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>304-428-3097</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Registration Date:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>12/04/2019</Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textrightshadow}`}><strong>Balace:</strong></Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={`${classes.paper} ${classes.textleftshadow}`}>23,200 USDT</Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Button style={{ textAlign: 'center' }}
                      color="primary"
                      variant="contained" className={classes.mar10}
                    >
                      VIEW ALL TRANSACTIONS
                    </Button>
                  </Grid>

                </Grid>


              </div>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
          </Button>

          </DialogActions> */}
        </Dialog>




      </Container>
    </Page>
  )

}
export default Suppliers;
