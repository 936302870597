import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from '../../utils/message';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from 'src/views/loader';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createTheme } from '@material-ui/core/styles';
import {
  Box, Button, Grid, Dialog, Modal, Container, InputAdornment, TextField, Typography, makeStyles, TablePagination, TableCell, TableHead, TableRow, TableBody, TableContainer, Table, FormControl
} from '@material-ui/core';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { Data } from '../Project/Selling/data';
import { checkPermission } from 'src/utils/permission';
import { Point } from 'signature_pad';
import { getProjectDetails } from '../Project/Common/utilities';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     width: '100%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   },

//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3),
//     fontFamily: 'Arial'
//   },
//   productCard: {
//     height: '100%'
//   },
//   logostyle: {
//     height: '100px',
//     width: '100px'
//   },
//   textleftshadow: {
//     textAlign: 'left',
//     boxShadow: 'none'
//   },
//   textrightshadow: {
//     textAlign: 'right',
//     boxShadow: 'none'
//   },
//   table: {
//     backgroundColor: '#fff',
//     boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
//     borderRadius: '5px'
//   },
//   formControl: {
//     // margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   },
//   importButton: {
//     marginRight: theme.spacing(1)
//   },
//   exportButton: {
//     marginRight: theme.spacing(1)
//   },

//   dateborder: {
//     border: '1px solid #c4c4c4',
//     borderRadius: '4px'
//   },
//   calmar: {
//     marginTop: '6px',
//     marginBottom: '0px',
//     width: '100%',
//     marginLeft: '10px'
//   },
//   button: {
//     backgroundColor: '#394671',
//     color: '#fff',
//     border: '1px solid',
//     '&:hover': {
//       backgroundColor: '#1b233d',
//       color: '#fff'
//     }
//   },
//   button2: {
//     backgroundColor: '#394671',
//     color: '#fff',
//     border: '1px solid',
//     '&:hover': {
//       backgroundColor: '#1b233d',
//       color: '#fff'
//     }
//   }
// }));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const AddNotice = () => {
  const classes = useStyles();
  const { project } = useParams();
  const [openMain, setOpenMain] = React.useState(false);
  const projectDetails = { ...getProjectDetails(project) };
  console.log('projectDetails', projectDetails);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [noticeData, setNoticeData] = useState([]);
  const [datezz, setDate] = useState('');
  const [notice, setNotice] = useState('');
  const [Id, setId] = useState('');
  const [edit, setEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [index, setIndex] = useState('');
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [payload, setPayload] = useState({
    FromDate: "",
    ToDate: "",
    Notification: "",
    EmployeeId: null,
    projectID: projectDetails?.projectID
  })
  // const [payload,setPayload]=useState({
  //   date: "",
  //   notice: ""
  // })

  let userData = JSON.parse(localStorage.getItem('userData'));

  const getNotice = async () => {
    // let userData = JSON.parse(localStorage.getItem('userData'))
    const res = await APIKit.get(RGCA_URLS.getNotifications, {
      params: { EmployeeId: userData.employeeCode }
    });
    if (res.data.status === 200) {
      setNoticeData(res.data.data);
    }
  };


  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = event => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const editNotice = data => {
    console.log("data", data);
    setEdit(true);
    setDate(moment(data.date).format('YYYY-MM-DD'))
    setNotice(data.Notification);
    setId(data?.Id);
    setOpenMain(true);
    setPayload({
      FromDate: data?.FromDate ? moment(data?.FromDate).format('YYYY-MM-DD') : null,
      ToDate: data?.ToDate ? moment(data?.ToDate).format('YYYY-MM-DD') : null,
      Notification: data?.Notification,
      EmployeeId: null,
      projectID: projectDetails?.projectID
    })

  };

  const deleteNotice = async index => {
    await APIKit.delete(RGCA_URLS.deleteNotifications + '/' + index.Id)
      .then(res => {
        if (res.data.status === 200) {
          getNotice();
          toast.success(MESSAGE.deletenotice, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
    setIndex('');
    setDate('')
    setNotice('')
  };

  useEffect(() => {
    getNotice();
  }, []);

  // END DATE VALIDATION
  console.log("projectDetails", projectDetails);
  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  });
  return (
    <Page className={classes.root} title="Add Notice">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Box height="100%" justifyContent="center">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Typography color="textPrimary" variant='h4' >
                Notice
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} style={{ textAlign: 'end' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpenMain(true);
                  setPayload({
                    FromDate: "",
                    ToDate: "",
                    Notification: "",
                    EmployeeId: null,
                    projectID: projectDetails?.projectID
                  })
                }}
              >
                Add Item
              </Button>

            </Grid>
          </Grid>

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openMain}
            id="income"
            contentStyle={{
              width: '200%',
              maxWidth: 'none'
            }}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle
              id="scroll-dialog-title"
              onClose={e => {
                setDate("")
                setNotice("");
                setId("");
                setOpenMain(false);
                setEdit(false);
                setPayload({
                  FromDate: "",
                  ToDate: "",
                  Notification: "",
                  EmployeeId: null,
                  projectID: projectDetails?.projectID
                })

              }}
              style={{ backgroundColor: '#394671', color: '#fff' }}
            >
              {edit ? "Edit" : 'Add'} Notice
            </DialogTitle>
            <Loader isLoading={isLoading} />
            <Formik
              enableReinitialize={true}
              initialValues={{
                FromDate: payload?.FromDate,
                ToDate: payload?.ToDate,
                notice: payload?.Notification
              }}
              validationSchema={Yup.object().shape({
                FromDate: Yup.string().required('From Date is required'),
                ToDate: Yup.string().required('To Date is required'),
                notice: Yup.string().min(5, 'min 5 characters required').max(200, 'max 200 characters only allowed').required()
              })}

              onSubmit={(values, { resetForm }) => {
                console.log("values", values);
                setIsLoading(true);
                let userData = JSON.parse(localStorage.getItem('userData'));
                // const payload = {
                //   Date: values.date,
                //   Notification: values.notice

                // };
                if (edit) {
                  let payloadEdit = {
                    FromDate: payload?.FromDate,
                    ToDate: payload?.ToDate,
                    Notification: payload?.Notification,
                    Id: Id
                  }

                  APIKit.put(RGCA_URLS.updateNotification, payloadEdit)
                    .then(res => {
                      if (res.data.status === 200) {
                        resetForm();
                        setIsLoading(false);
                        setDate('');
                        setNotice('');
                        setId('');
                        getNotice();
                        setPayload({
                          FromDate: "",
                          ToDate: "",
                          Notification: "",
                          EmployeeId: null,
                          projectID: projectDetails?.projectID
                        });
                        setOpenMain(false);
                        toast.success(MESSAGE.updatenotice);
                        setEdit(false);
                      } else {
                        setIsLoading(false);
                        toast.error(res.data.error);
                      }
                    })
                    .catch(e => {
                      setIsLoading(false);
                    });
                } else {
                  let payloadnotice = {
                    FromDate: payload.FromDate,
                    ToDate: payload?.ToDate,
                    Notification: payload?.Notification,
                    EmployeeId: userData.employeeCode,
                    projectID: payload?.projectID
                  }

                  // addNotification
                  APIKit.post(RGCA_URLS.notificationBasedonprojectID, payloadnotice)
                    .then(res => {
                      if (res.data.status === 200) {
                        resetForm();
                        setEdit(false);
                        setIsLoading(false);
                        getNotice();
                        setOpenMain(false);
                        setPayload({
                          FromDate: "",
                          ToDate: "",
                          Notification: "",
                          EmployeeId: null,
                          projectID: projectDetails?.projectID
                        })
                        toast.success(MESSAGE.notice);
                      } else {
                        setIsLoading(false);
                        toast.error(res.data.error);
                      }
                    })
                    .catch(e => {
                      setIsLoading(false);
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                resetForm,
                handleSubmit,
                touched,
                values,
                setValues
              }) => (
                <>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <DialogContent>
                      <Grid container spacing={2}>

                        <Grid item lg={6} sm={6} xl={6} xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                            className={classes.formControl}
                          >
                            <KeyboardDatePicker
                              className={classes.dat}
                              autoOk
                              InputProps={{ disabled: true }}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true
                              }}
                              // maxDate={new Date}
                              disablePast
                              variant="inline"
                              inputVariant="outlined"
                              label="From Date"
                              format="dd/MM/yyyy"
                              name="FromDate"
                              value={payload.FromDate ?? null}
                              InputAdornmentProps={{ position: 'end' }}
                              onChange={(e) => {
                                console.log("3333", e);
                                let item = payload;
                                item.FromDate = moment(e).format('YYYY-MM-DD');
                                setPayload({ ...item });
                                setValues({ ...values, FromDate: e });
                              }}
                              required
                              error={Boolean(touched.FromDate && errors.FromDate)}
                              helperText={touched.FromDate && errors.FromDate}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                            className={classes.formControl}
                          >
                            <KeyboardDatePicker
                              className={classes.dat}
                              autoOk
                              InputProps={{ disabled: true }}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true
                              }}
                              // maxDate={new Date}
                              disablePast
                              variant="inline"
                              inputVariant="outlined"
                              label="To Date"
                              format="dd/MM/yyyy"
                              name="ToDate"
                              value={payload.ToDate ?? null}
                              InputAdornmentProps={{ position: 'end' }}
                              onChange={(e) => {
                                setValues({ ...values, ToDate: e });
                                console.log("eeee", e);
                                let item = payload;
                                item.ToDate = moment(e).format('YYYY-MM-DD');
                                setPayload({ ...item });
                              }}
                              required
                              error={Boolean(touched.ToDate && errors.ToDate)}
                              helperText={touched.ToDate && errors.ToDate}
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={6} sm={6}>
                          <TextField
                            error={Boolean(touched.FromDate && errors.FromDate)}
                            fullWidth
                            helperText={touched.FromDate && errors.FromDate}
                            margin="normal"
                            // InputProps={{inputProps: { min: new Date()} }}
                            name="FromDate"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              let item = payload
                              item.FromDate = e
                              setPayload({ ...item })
                            }}
                            // onChange={(e)=>{console.log( "date", e.target.value)}}
                            type="date"
                            value={values.FromDate}
                            variant="outlined"
                            placeholderText="Click to select a date"
                            InputProps={{
                              endAdornment: <InputAdornment>From Date</InputAdornment>
                            }} />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            error={Boolean(touched.ToDate && errors.ToDate)}
                            fullWidth
                            helperText={touched.ToDate && errors.ToDate}
                            margin="normal"
                            name="ToDate"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              let item = payload
                              item.ToDate = e
                              setPayload({ ...item })
                            }}
                            type="date"
                            value={values.ToDate}
                            variant="outlined"
                            placeholderText="Click to select a date"
                            InputProps={{
                              endAdornment: <InputAdornment>To Date</InputAdornment>
                            }}
                          />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            error={Boolean(touched.notice && errors.notice)}
                            fullWidth
                            helperText={touched.notice && errors.notice}
                            label="Enter the notification"
                            inputProps={{ maxLength: 200 }}
                            margin="normal"
                            name="notice"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              let item = payload
                              item.Notification = e.target.value
                              setPayload({ ...item })
                            }}
                            type="text"
                            value={values.notice}
                            variant="outlined"
                            InputProps={{
                              endAdornment: <InputAdornment></InputAdornment>
                            }}
                          />
                          <small style={{ color: "gray" }}><code
                            className="me-2">{values?.notice?.length}/200</code> characters</small>
                        </Grid>
                      </Grid>
                    </DialogContent>


                    <DialogActions
                      style={{ backgroundColor: '#394671', color: '#fff' }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          style={{
                            backgroundColor: '#394671',
                            color: '#fff',
                            border: '1px solid',
                            marginRight: '10px'
                          }}
                          color="primary"
                          variant="contained"
                          onClick={e => {
                            setDate("")
                            setNotice("");
                            setId("");
                            setOpenMain(false);
                            setEdit(false);
                            setPayload({
                              FromDate: "",
                              ToDate: "",
                              Notification: "",
                              EmployeeId: null,
                              projectID: projectDetails?.projectID
                            })
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          color="primary"
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                        &nbsp;
                      </div>
                    </DialogActions>
                  </Form>
                </>
              )}
            </Formik>
            {/* </Form> */}
          </Dialog>



        </Container>


        <Box m={3} sx={12}>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Form Date</TableCell>
                  <TableCell>To Date</TableCell>
                  <TableCell>Notification</TableCell>
                  {/* {(checkPermission('edit_notice') ||
                      checkPermission('delete_notice') ||
                      userData?.isAdmin == 1) && <TableCell>Actions</TableCell>} */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {console.log(page1)}
              <TableBody >
                {noticeData.length > 0 ? noticeData.
                  slice(
                    page1 * rowsPerPage1,
                    page1 * rowsPerPage1 + rowsPerPage1
                  ).map((data, indexes) => (
                    <TableRow hover key={indexes}>
                      <TableCell>{((page1) * 10) + indexes + 1}</TableCell>
                      <TableCell>{moment(data?.FromDate).format('DD-MM-YYYY')}</TableCell>
                      <TableCell>{moment(data?.ToDate).format('DD-MM-YYYY')}</TableCell>
                      <TableCell>
                        <Tooltip title={data.Notification}>
                          <span>
                            {data?.Notification ? data.Notification?.length > 80 ? `${data?.Notification.slice(0, 80)} ...` : data?.Notification : '-'}
                          </span>
                        </Tooltip>
                      </TableCell>


                      {(checkPermission('edit_notice') ||
                        checkPermission('delete_notice') ||
                        userData?.isAdmin == 1) && <TableCell>
                          {(checkPermission('edit_notice') ||
                            userData?.isAdmin == 1) &&
                            <EditOutlinedIcon
                              className={classes.iconButton}
                              onClick={() => {
                                editNotice(data);

                              }}
                            />}
                          {(
                            checkPermission('delete_notice') ||
                            userData?.isAdmin == 1) &&
                            <DeleteIcon
                              className={classes.iconButton}
                              onClick={() => {
                                setModalOpen(true);
                                console.log(indexes, 'indexes');
                                setIndex(data);
                              }}
                            />}
                        </TableCell>
                      }
                    </TableRow>

                  )) : (
                  <TableRow hover role="checkbox" key={1}>
                    <TableCell colSpan={10} align="center" key={2}>
                      {'No Data Found'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {noticeData && noticeData.length != 0 && noticeData.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={noticeData.length}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />
          )}
        </Box>

        {modalOpen && (
          <Dialog
            open={setModalOpen}
            onClose={() => { editNotice(); }}
            style={{ background: 'transparent' }}
          >
            <DialogTitle>Are you sure you want to Delete </DialogTitle>
            <DialogActions sx={{ pr: 2.5 }}>
              <Button
                variant="contained"
                size="small"
                onClick={data => { setModalOpen(false); }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  deleteNotice(index);
                  setModalOpen(false);
                }}
                color="secondary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </Page>
  );
};

export default AddNotice;
