import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  SvgIcon,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import * as Yup from 'yup';
import { Formik, Form, validateYupSchema, FormikValues } from 'formik';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useReactToPrint } from 'react-to-print';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import APIKit from '../../utils/APIKit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import { MESSAGE } from 'src/utils/message';
import { RGCA_URLS } from 'src/utils/url';
import { useToasts } from 'react-toast-notifications';
import Loader from '../loader';
import Address from '../Project/Common/Address';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@material-ui/core/Tooltip';
import AddressSingleLine from '../Project/Common/Address_singleline';
import BankSingleline from '../Project/Common/Bank_singleline';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Bank from '../Project/Common/Bank';
import {
  isFileAllowed,
  validateEmail,
  isFileSizeAllowed
} from './../Project/Common/fileUpload';
import xlx from '../../Assets/KYC-Vendor-details.xlsx';
import * as XLSX from 'xlsx';
import { checkPermission } from 'src/utils/permission';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VendorRfq from './vendorRfq';
import StatesAndDis from "./states.json"


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: 'Arial'
  },
  productCard: {
    height: '100%'
  },
  logostyle: {
    height: '100px',
    width: '100px'
  },
  textleftshadow: {
    textAlign: 'left',
    boxShadow: 'none'
  },
  textrightshadow: {
    textAlign: 'right',
    boxShadow: 'none'
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    borderRadius: '5px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },

  dateborder: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px'
  },
  calmar: {
    marginTop: '6px',
    marginBottom: '0px',
    width: '100%',
    marginLeft: '10px'
  },
  centertext: {
    marginTop: '10px', color: '#394671', fontSize: '17px', fontWeight: 'bold',
    '@media (max-width: 500px)': {
      marginLeft: "110px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "60px"
    },
  },
  button: {
    '@media (max-width: 800px)': {
      marginBottom: "5px"
    },
    '@media (max-width: 500px)': {
      marginLeft: "100px"
    },
    '@media (max-width: 350px)': {
      marginLeft: "40px"
    },
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Vendor = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [openBulk, setOpenBulk] = React.useState(false);
  const [errRecords, setErrRecords] = useState([])
  const confirm = useConfirm();
  const [alertopen, setAlertOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [openTest, setOpenTest] = React.useState(false);
  const { project } = useParams();
  // const projectDetails = { ...getProjectDetails(project) }
  const [alertMeg, setAlertMeg] = React.useState('');
  const [errorMeg, setErrorMeg] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [vendor, setVendor] = useState({
    name: '',
    companyType: '',
    companyName: '',
    gstn: '',
    gstnAttach: '',
    panAttach: '',
    pan: '',
    aadharAttach: '',
    aadhar: '',
    email: '',
    mobile: '',
    // vendorID: "",
    address: {
      line_1: '',
      line_2: '',
      district: '',
      state: '',
      country: 'India',
      postalCode: ''
    },
    bankDetails: {
      bankName: '',
      bankAccountNo: '',
      bankBranch: '',
      bankIFSC: '',
      bankAccountType: '',
      bankAccountHolderName: '',
      cancelChequeAttach: ''
    }
  });
  const [allVendors, setAllVendors] = useState([]);
  const [allRFQQuotation, setAllRFQQuotation] = useState([]);
  const [lookupData, setLookupData] = useState([]);
  const [btn, setBtn] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [bulk, setBulk] = useState('');
  const [bulkFIle, setBulkFile] = useState('');
  const [nameupdate, setNameupdate] = useState(false);
  const [value, setValue] = React.useState(0); // first tab
  const [districts, setDistricts] = useState([]);


  const { state } = useLocation();

  let userData = JSON.parse(localStorage.getItem('userData'));

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const htmlContentRef = useRef(null);
  const printOrder = useReactToPrint({
    content: () => htmlContentRef.current,
  });


  const saveFile = () => {
    saveAs(xlx, 'Vendor Template.xlsx');
  };
  const bulkUpload = () => {
    setBulk('');
    setBulkOpen(!bulkOpen);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const navigate = useNavigate();
  const handleCloseModal = (resetForm = "") => {
    setOpenTest(false);
    setVendor({
      name: '',
      companyType: '',
      companyName: '',
      gstn: '',
      gstnAttach: '',
      panAttach: '',
      aadharAttach: '',
      pan: '',
      aadhar: '',
      email: '',
      mobile: '',
      address: {
        line_1: '',
        line_2: '',
        district: '',
        state: '',
        country: 'India',
        postalCode: ''
      },
      bankDetails: {
        bankName: '',
        bankAccountNo: '',
        bankBranch: '',
        bankIFSC: '',
        bankAccountType: '',
        bankAccountHolderName: '',
        cancelChequeAttach: ''
      }
    });
    if (resetForm) {
      resetForm();
    }
  };

  useEffect(() => {
    getVendor();
    getLookup();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  // file uploda

  const handleUploadClick = (event, type) => {
    const filesFormats = [".doc", ".docx", , ".xlsx", ".xls", '.excel', '.csv', "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const isRightFormat = filesFormats.includes(event.target.files[0].type);
    if (!isRightFormat) {
      toast.error("You can only upload pdf , csv,excel, and doc files");
      return;
    }
    if (event.target.files[0].size > 3145728) {
      toast.error(MESSAGE.lessthan3mb, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (event.target.files[0] !== undefined) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        'files',
        event.target.files[0],
        event.target.files[0].name
      );
      if (type == 'aadhar') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then(res => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              // setAadharAttach(res.data.data);
              setVendor({
                ...vendor,
                aadharAttach: res.data.data
              });
            } else {
              setIsLoading(false);
              toast.error(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
          })
          .catch(e => { });
      }
      if (type == 'gstn') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then(res => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              // setAppointmentAttach(res.data.data);
              setVendor({
                ...vendor,
                gstnAttach: res.data.data
              });
            } else {
              setIsLoading(false);
              toast.error(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
          })
          .catch(e => { });
      }
      if (type == 'pan') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then(res => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              // setAppointmentAttach(res.data.data);
              setVendor({
                ...vendor,
                panAttach: res.data.data
              });
            } else {
              setIsLoading(false);
              toast.error(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
          })
          .catch(e => { });
      }
      if (type == 'cheque') {
        APIKit.post(RGCA_URLS.upload, formData)
          .then(res => {
            if (res.data.status == 200) {
              setIsLoading(false);
              toast.success(MESSAGE.fileUpload, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              // setAppointmentAttach(res.data.data);
              let bankDetails = { ...vendor.bankDetails };
              bankDetails.cancelChequeAttach = res.data.data;
              setVendor({
                ...vendor,
                bankDetails: { ...bankDetails }
              });
            } else {
              setIsLoading(false);
              toast.error(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
          })
          .catch(e => { });
      }
    }
  };
  const getLookup = () => {
    APIKit.get(`${RGCA_URLS.lookup}/4`)
      .then(res => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(e => { });
  };
  const saveVendor = () => { };
  const updateVendor = () => {
    setIsLoading(true);
    APIKit.put(RGCA_URLS.updateVendor, vendor)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          getVendor();
          toast.success(MESSAGE.vendorUpdated, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          handleCloseModal();
        } else {
          setIsLoading(false);
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const getVendor = () => {
    setIsLoading(true);
    APIKit.post(RGCA_URLS.listVendor)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          // console.log("lll1",res.data.data);
          // console.log("lll1",JSON.parse(res.data.data[0].address));
          setAllVendors(res?.data?.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => { });
  };

  const getRFQQuotation = () => {
    setIsLoading(true);
    APIKit.get(RGCA_URLS.getRFQQuotation)
      .then(res => {
        if (res.data.status === 200) {
          setIsLoading(false);
          // console.log("lll1",res.data.data);
          // console.log("lll1",JSON.parse(res.data.data[0].address));
          setAllRFQQuotation(res?.data?.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => { });
  };

  const removeVendor = data => {
    confirm({ description: 'You want to delete the record ?' })
      .then(() => {
        remove(data);
        // activeOrInactive(status,id)
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const searchVendor = e => {
    if (e) {
      const payload = {
        searchText: e
      };
      APIKit.post(RGCA_URLS.listVendor, payload)
        .then(res => {
          if (res.data.status === 200) {
            // setAlertMeg('success');
            // setErrorMeg(res.data.message);
            //setAlertOpen(true);
            setAllVendors(res.data.data);
          } else {
            // setAlertMeg('error');
            // setErrorMeg(res.data.message);
            // setAlertOpen(true);
          }
        })
        .catch(e => { });
    } else {
      getVendor();
    }
  };
  var vendorData = {};
  const defaultLoad = () => {
    vendorData.name = 'hi';
  };

  const editVendor = data => {
    console.log("aaaa", JSON.parse(data.address));
    let address = JSON.parse(data.address)[0];
    console.error(address)
    let bankdetails = JSON.parse(data.bankDetails)[0];
    console.log(JSON.parse(data.bankDetails), "JSON.parse(data.bankDetails)[0]");
    vendorData.name = 'hi';
    vendorData.companyType = 'hi';

    let test = {
      name: data.name,
      vendorID: data.vendorID,
      companyType: data.companyType,
      companyName: data.companyName,
      gstn: data.gstn,
      gstnAttach: data.gstnAttach,
      panAttach: data.panAttach,
      pan: data.pan,
      aadharAttach: data.aadharAttach,
      aadhar: data.aadhar,
      email: data.email,
      mobile: data.mobile,
      address: {
        line_1: address.line_1,
        line_2: address.line_2,
        district: address.district,
        state: address.state,
        country: address.country,
        postalCode: address.postalCode

      },
      bankDetails: {
        bankName: bankdetails.bankName,
        bankAccountNo: bankdetails.bankAccountNo,
        bankBranch: bankdetails.bankBranch,
        bankIFSC: bankdetails.bankIFSC,
        bankAccountType: bankdetails.bankAccountType,
        bankAccountHolderName: bankdetails.bankAccountHolderName,
        cancelChequeAttach: bankdetails.cancelChequeAttach

      }
    }

    const stateData = StatesAndDis.find((s) => s.state === address.state);
    console.log("lllll", stateData);
    setDistricts(stateData ? stateData.districts : []);

    console.log("kkk", test);

    setVendor(test);
    setBtn(true);
    setOpenTest(true);
  };

  const remove = data => {
    const url = `${RGCA_URLS.deleteVendor}/${data.vendorID}`;
    APIKit.delete(url)
      .then(res => {
        if (res.data.status === 200) {
          toast.success(MESSAGE.vendorDeleted, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          getVendor();
        } else {
          toast.error(MESSAGE.vendorDeletedNot, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(e => { });
  };
  // const [scroll, setScroll] = React.useState('paper');
  const [scroll, setScroll] = React.useState('paper');

  const handleUpload = async e => {

    if (!isFileAllowed(e.target.files[0]?.name, 'bckUpload')) {
      toast.error(MESSAGE.fileFormat, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }
    if (!isFileSizeAllowed(e.target.files[0]?.size)) {
      toast.error(MESSAGE.fileSze, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }

    let arrayBuffer;
    let parseExcelData;
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      parseExcelData = await XLSX.utils.sheet_to_json(worksheet, {
        raw: true
      });
    };
    setTimeout(() => {
      if (parseExcelData?.length > 0) {
        let errorCount = 0;
        let errorNameCount = 0;
        parseExcelData.forEach(data => {
          if (!validateEmail(data?.email)) {
            errorCount++;
          }
          if (!data?.name || data?.name === '') {
            errorNameCount++;
          }
        });

        if (errorCount > 0) {
          toast.error(errorCount + ' ' + MESSAGE.wrong_data_entered, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }

        if (errorNameCount > 0) {
          toast.error(errorNameCount + ' ' + MESSAGE.wrong_name_data_entered, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }
        setBulk(file.name);
        setBulkFile(file);
      } else {
        toast.error(MESSAGE.file_empty, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }
    }, 1000);
  };
  const bulUploadDatas = () => {

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', bulkFIle);
    APIKit.post(RGCA_URLS.bulkUploadVendor, formData)
      .then(res => {
        if (res.data.status == 200) {
          setIsLoading(false);
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          // setAppointmentAttach(res.data.data);
          getVendor();
          setBulkOpen(!bulkOpen);
        } else {
          setIsLoading(false);
          setErrRecords(res.data.error);
          setOpenBulk(true)
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };
  const openAddVendor = scrollType => () => {
    setScroll(scrollType);
    setBtn(false);
    // setOpenTest(false);
    setOpenTest(true);
    setNameupdate(false);

  };
  const regEx = {
    textOnly: /^[A-Za-z\s]+$/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9]*$/
  };

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  });
  const a11yProps = index => {
    if (index == 0) {
      getVendor()
    }
    if (index == 1) {
      getRFQQuotation()
    }
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page className={classes.root} title="Vendors">
      <Loader isLoading={isLoading} />
      <ToastContainer />
      <Container maxWidth={false}>
        <Snackbar
          open={alertopen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity={alertMeg}>
            {errorMeg}
          </Alert>
        </Snackbar>
        {/* dialog */}


        <AppBar position="static" color="default" style={{ marginTop: '20px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Vendor"
              onClick={() => {
                a11yProps(0)
              }}
            />
            <Tab label="RFQ & Quotation" onClick={() => {
              a11yProps(1)
            }
            } />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <Box display="flex" justifyContent="flex-end">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.centertext}
                >
                  Vendor List
                </Grid>
                {(checkPermission('create_vendor') || userData?.isAdmin == 1) && <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={bulkUpload}
                  >
                    Bulk Upload
                  </Button>
                  &nbsp;
                  <Button color="primary" variant="contained" onClick={saveFile}>
                    download template
                  </Button>
                  &nbsp;
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openAddVendor('paper')}
                    className={classes.button}
                  >
                    Create Vendor
                  </Button>
                </Grid>}
              </Grid>
            </Box>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <Box maxWidth={500}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        searchVendor(e.target.value);
                      }}
                      placeholder="Search"
                      variant="outlined"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </div>
          <Box mt={3}>
            <Card>
              <CardContent>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">S.No</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Mobile Number</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="left">Bank Details</TableCell>
                        <TableCell align="left">PAN Card</TableCell>
                        {(checkPermission('edit_vendor') || userData?.isAdmin == 1 ||
                          checkPermission('delete_vendor')) && (
                            <TableCell align="left">Actions</TableCell>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allVendors
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((vendor, index) => (
                          <TableRow
                            hover
                          // key={emplyoee.id}
                          // selected={selectedCustomerIds.indexOf(emplyoee.id) !== -1}
                          >
                            <TableCell align="left">
                              {page * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">{vendor.name}</TableCell>
                            <TableCell align="left">{vendor.email}</TableCell>
                            <TableCell align="left">
                              {vendor.mobile == 0 ? '-' : vendor.mobile}
                            </TableCell>
                            <TableCell align="left">
                              {/* {vendor.address} */}
                              <Tooltip
                                title={<Address details={vendor.address} />}
                              >
                                <Typography>
                                  <AddressSingleLine details={vendor.address} />
                                </Typography>
                              </Tooltip>{' '}
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip
                                title={<Bank details={vendor.bankDetails} />}
                              >
                                <Typography>
                                  <BankSingleline details={vendor.bankDetails} />
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">{vendor.pan}</TableCell>

                            {(checkPermission('edit_vendor') || userData?.isAdmin == 1 ||
                              checkPermission('delete_vendor')) && (<TableCell align="left">
                                {(checkPermission('edit_vendor') || userData?.isAdmin == 1) && (<EditOutlinedIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    editVendor(vendor)
                                    setNameupdate(true);
                                  }}
                                />)}
                                {(checkPermission('delete_vendor') || userData?.isAdmin == 1) && (<DeleteForeverIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    removeVendor(vendor);
                                  }}
                                />
                                )}
                              </TableCell>)
                            }
                          </TableRow>
                        ))}
                    </TableBody>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      // component="div"
                      count={allVendors.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <VendorRfq allRFQQuotation={allRFQQuotation} allVendors={allVendors}
            setIsLoading={setIsLoading} isLoading={isLoading}
            getRFQQuotation={getRFQQuotation} />
        </TabPanel>
      </Container>

      <Formik
        enableReinitialize={true}
        initialValues={{
          name: vendor.name,
          companyType: vendor.companyType,
          companyName: vendor.companyName,
          gstn: vendor.gstn,
          pan: vendor.pan,
          aadhar: vendor.aadhar,
          aadharAttach: vendor.aadharAttach,
          email: vendor.email,
          mobile: vendor.mobile,
          address: {
            line_1: vendor.address.line_1,
            line_2: vendor.address.line_2,
            district: vendor.address.district,
            state: vendor.address.state,
            country: vendor.address.country,
            postalCode: vendor.address.postalCode
          },
          bankDetails: {
            bankName: vendor.bankDetails.bankName,
            bankAccountNo: vendor.bankDetails.bankAccountNo,
            bankBranch: vendor.bankDetails.bankBranch,
            bankIFSC: vendor.bankDetails.bankIFSC,
            bankAccountType: vendor.bankDetails.bankAccountType,
            bankAccountHolderName: vendor.bankDetails.bankAccountHolderName
          }
        }}
        onSubmit={(values, { resetForm }) => {
          if (btn) {
            updateVendor();
          } else {
            setIsLoading(true);
            APIKit.post(RGCA_URLS.addVendor, vendor)
              .then(res => {
                if (res.data.status === 200) {
                  setIsLoading(false);
                  getVendor();
                  toast.success(MESSAGE.vendorAdded, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                  });
                  handleCloseModal();
                  resetForm(values);
                } else if (res.data.status == 401) {
                  setIsLoading(false);
                  setErrRecords(res.data.error);
                  setOpenBulk(true);
                }
                else {
                  setIsLoading(false);
                  toast.error(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              })
              .catch(e => {
                setIsLoading(false);
              });
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(MESSAGE.name)
            .matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),

          email: Yup.string()
            .email(MESSAGE.validEmail)
            .max(255)
            .required(MESSAGE.email)
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm
        }) => (
          <MuiThemeProvider theme={formLabelsTheme}>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={openTest}
              scroll={scroll}
              id="income"
              contentStyle={{
                width: '200%',
                maxWidth: 'none'
              }}
              maxWidth="lg"
              fullWidth
            >
              <Loader isLoading={isLoading} />
              <DialogTitle
                id="scroll-dialog-title"
                onClose={e => {
                  handleCloseModal(resetForm);
                }}
                style={{ backgroundColor: '#394671', color: '#fff' }}
              >
                {nameupdate ? 'Update Vendor' : 'Create Vendor'}
              </DialogTitle>
              <Form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <DialogContent dividers={scroll === 'paper'}>
                  <Box
                    mt={3}
                    style={{
                      backgroundColor: '#fff',
                      boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                      borderRadius: '5px',
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.name = e.target.value;
                              setVendor({
                                ...p,
                                name: e.target.value
                              });
                            }
                          }}
                          required
                          value={vendor.name}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            const p = vendor;
                            p.email = e.target.value;
                            setVendor({
                              ...p,
                              email: e.target.value
                            });
                          }}
                          required
                          value={vendor.email}
                          variant="outlined"
                          disabled={nameupdate}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="companyName"
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.companyName = e.target.value;
                              setVendor({
                                ...p,
                                companyName: e.target.value
                              });
                            }
                          }}
                          value={vendor.companyName}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="GSTIN(%)"
                          name="gstn"
                          error={Boolean(touched.gstn && errors.gstn)}
                          helperText={touched.gstn && errors.gstn}
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.gstn = e.target.value;
                              setVendor({
                                ...p,
                                gstn: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 15
                          }}
                          value={vendor.gstn}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: '100%', height: '80%' }}
                          variant="contained"
                          component="label"
                        >
                          GSTIN Attach Certificate{' '}
                          <div>
                            <input
                              type="file"
                              onChange={e => handleUploadClick(e, 'gstn')}
                              hidden
                            />
                          </div>
                        </Button>
                        {vendor.gstnAttach && (
                          <a
                            style={{ color: 'blue', cursor: 'pointer' }}
                            href={vendor.gstnAttach}
                            target="_blank"
                          >
                            {vendor.gstnAttach.substring(4, 20)}....
                            {vendor.gstnAttach.substring(
                              vendor.gstnAttach.lastIndexOf('.') + 1
                            )}
                          </a>
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.pan && errors.pan)}
                          helperText={touched.pan && errors.pan}
                          label="PAN"
                          name="pan"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              const p = vendor;
                              p.pan = e.target.value;
                              setVendor({
                                ...p,
                                pan: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          value={vendor.pan}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: '100%', height: '80%' }}
                          variant="contained"
                          component="label"
                        >
                          PAN Attach{' '}
                          <input
                            type="file"
                            onChange={e => handleUploadClick(e, 'pan')}
                            hidden
                          />
                        </Button>
                        {vendor.panAttach && (
                          <a
                            style={{ color: 'blue', cursor: 'pointer' }}
                            href={vendor.panAttach}
                            target="_blank"
                          >
                            {vendor.panAttach.substring(4, 25)}....
                            {vendor.panAttach.substring(
                              vendor.panAttach.lastIndexOf('.') + 1
                            )}
                          </a>
                        )}
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Aadhar Number"
                          name="aadhar"
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              const p = vendor;
                              p.aadhar = e.target.value;
                              setVendor({
                                ...p,
                                aadhar: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 12
                          }}
                          value={vendor.aadhar}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: '100%', height: '80%' }}
                          variant="contained"
                          component="label"
                        >
                          aadhar Attach{' '}
                          <input
                            type="file"
                            hidden
                            onChange={e => handleUploadClick(e, 'aadhar')}
                          />
                        </Button>
                        {vendor.aadharAttach && (
                          <a
                            style={{ color: 'blue', cursor: 'pointer' }}
                            href={vendor.aadharAttach}
                            target="_blank"
                          >
                            {vendor.aadharAttach.substring(4, 25)}....
                            {vendor.aadharAttach.substring(
                              vendor.aadharAttach.lastIndexOf('.') + 1
                            )}
                          </a>
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          error={Boolean(
                            touched.companyType && errors.companyType
                          )}
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={vendor.companyType}
                            name="companyType"
                            label="Type"
                            onChange={e => {
                              handleChange(e);
                              const p = vendor;
                              p.companyType = e.target.value;
                              setVendor({
                                ...p,
                                companyType: e.target.value
                              });
                            }}
                            onBlur={handleBlur}
                          >
                            {lookupData &&
                              lookupData.map(x => {
                                return (
                                  <MenuItem value={x.lookupID} key={x.lookupID}>
                                    {x.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {errors.companyType &&
                              touched.companyType &&
                              errors.companyType}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.mobile && errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          label="Mobile Number"
                          name="mobile"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              const p = vendor;
                              p.mobile = e.target.value;
                              setVendor({
                                ...p,
                                mobile: e.target.value
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          value={vendor.mobile}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.line_1 && errors.address?.line_1
                          )}
                          helperText={
                            touched.address?.line_1 && errors.address?.line_1
                          }
                          label="Address Line1"
                          name="address.line_1"
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 50 }}

                          onChange={e => {
                            handleChange(e);
                            let address = { ...vendor.address };
                            address.line_1 = e.target.value;
                            setVendor({
                              ...vendor,
                              address: { ...address }
                              //  line_1: e.target.value
                            });
                          }}
                          value={vendor.address.line_1}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.line_2 && errors.address?.line_2
                          )}
                          helperText={
                            touched.address?.line_2 && errors.address?.line_2
                          }
                          label="Address Line2"
                          name="address.line_2"
                          inputProps={{ maxLength: 50 }}

                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            let address = { ...vendor.address };
                            address.line_2 = e.target.value;
                            setVendor({
                              ...vendor,
                              address: { ...address }
                            });
                          }}
                          value={vendor.address.line_2}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item lg={4} sm={6} xl={6} xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                            >
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="State"
                              name="address.state"
                              
                              onChange={e => {
                                  handleChange(e);
                                  let address = { ...vendor.address };
                                  address.state = e.target.value;
                                  setVendor({
                                    ...vendor,
                                    address: { ...address }
                                  });
                                  const stateData = StatesAndDis.find((s) => s.state === e.target.value);
                                  console.log("lllll", stateData);
                                  setDistricts(stateData ? stateData.districts : []);
                              }}
                              value={vendor?.address?.state}
                              onBlur={handleBlur}
                            >
                              {StatesAndDis &&
                                StatesAndDis.map(x => {
                                  return (
                                    <MenuItem value={x.state} key={x.state}>
                                      {x.state}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item lg={4} sm={6} xl={6} xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              
                            >
                              District
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="District"
                              name="address.district"
                              onChange={e => {
                                  handleChange(e);
                                  let address = { ...vendor.address };
                                  address.district = e.target.value;
                                  setVendor({
                                    ...vendor,
                                    address: { ...address }
                                  });
                              }}
                              value={vendor?.address?.district}
                              onBlur={handleBlur}
                            >
                              {districts &&
                                districts.map(x => {
                                  return (
                                    <MenuItem value={x} key={x}>
                                      {x}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                      {/* <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 25 }}
                          error={Boolean(
                            touched.address?.district &&
                            errors.address?.district
                          )}
                          helperText={
                            touched.address?.district &&
                            errors.address?.district
                          }
                          label="District"
                          name="address.district"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.district = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.district}
                          variant="outlined"
                        />
                      </Grid> */}

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Pin code"
                          name="address.postalCode"
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.address?.postalCode &&
                            errors.address?.postalCode
                          )}
                          helperText={
                            touched.address?.postalCode &&
                            errors.address?.postalCode
                          }
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.postalCode = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          inputProps={{
                            minLength: 6,
                            maxLength: 6
                          }}
                          value={vendor.address.postalCode}
                          variant="outlined"
                        />
                      </Grid>


                      {/* <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.state && errors.address?.state
                          )}
                          helperText={
                            touched.address?.state && errors.address?.state
                          }
                          label="State"
                          name="address.state"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.state = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.state}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}

                        />
                      </Grid> */}
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.address?.country && errors.address?.country
                          )}
                          helperText={
                            touched.address?.country && errors.address?.country
                          }
                          label="Country"
                          name="address.country"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let address = { ...vendor.address };
                              address.country = e.target.value;
                              setVendor({
                                ...vendor,
                                address: { ...address }
                              });
                            }
                          }}
                          value={vendor.address.country}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}

                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountNo &&
                            errors.bankDetails?.bankAccountNo
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountNo &&
                            errors.bankDetails?.bankAccountNo
                          }
                          label="Account Number"
                          name="bankDetails.bankAccountNo"
                          onBlur={handleBlur}
                          inputProps={{
                            minLength: 6,
                            maxLength: 16
                          }}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.numbersOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankAccountNo = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankAccountNo}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankIFSC &&
                            errors.bankDetails?.bankIFSC
                          )}
                          helperText={
                            touched.bankDetails?.bankIFSC &&
                            errors.bankDetails?.bankIFSC
                          }
                          label="IFSC Code"
                          name="bankDetails.bankIFSC"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.noSpecialChar.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankIFSC = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          inputProps={{
                            maxLength: 11
                          }}
                          value={vendor.bankDetails.bankIFSC}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountType &&
                            errors.bankDetails?.bankAccountType
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountType &&
                            errors.bankDetails?.bankAccountType
                          }
                          label="Type of Account"
                          name="bankDetails.bankAccountType"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);

                            let bankDetails = { ...vendor.bankDetails };
                            bankDetails.bankAccountType = e.target.value;
                            setVendor({
                              ...vendor,
                              bankDetails: { ...bankDetails }
                            });
                          }}
                          value={vendor.bankDetails.bankAccountType}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankAccountHolderName &&
                            errors.bankDetails?.bankAccountHolderName
                          )}
                          helperText={
                            touched.bankDetails?.bankAccountHolderName &&
                            errors.bankDetails?.bankAccountHolderName
                          }
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankAccountHolderName =
                                e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          label="Account Holder Name"
                          name="bankDetails.bankAccountHolderName"
                          value={vendor.bankDetails.bankAccountHolderName}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankName &&
                            errors.bankDetails?.bankName
                          )}
                          helperText={
                            touched.bankDetails?.bankName &&
                            errors.bankDetails?.bankName
                          }
                          label="Bank Name"
                          name="bankDetails.bankName"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankName = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankName}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.bankDetails?.bankBranch &&
                            errors.bankDetails?.bankBranch
                          )}
                          helperText={
                            touched.bankDetails?.bankBranch &&
                            errors.bankDetails?.bankBranch
                          }
                          label="Branch Name"
                          name="bankDetails.bankBranch"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (
                              e.target.value === '' ||
                              regEx.textOnly.test(e.target.value)
                            ) {
                              handleChange(e);
                              let bankDetails = { ...vendor.bankDetails };
                              bankDetails.bankBranch = e.target.value;
                              setVendor({
                                ...vendor,
                                bankDetails: { ...bankDetails }
                              });
                            }
                          }}
                          value={vendor.bankDetails.bankBranch}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}

                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Button
                          style={{ width: '100%', height: '80%' }}
                          variant="contained"
                          component="label"
                        >
                          cancelled Cheque attachment
                          <input
                            type="file"
                            onChange={e => handleUploadClick(e, 'cheque')}
                            hidden
                          />
                        </Button>
                        {vendor.bankDetails.cancelChequeAttach && (
                          <a
                            style={{ color: 'blue', cursor: 'pointer' }}
                            href={
                              RGCA_URLS.filePath +
                              vendor.bankDetails.cancelChequeAttach
                            }
                            target="_blank"
                          >
                            {vendor.bankDetails.cancelChequeAttach.substring(
                              4,
                              25
                            )}
                            ....
                            {vendor.bankDetails.cancelChequeAttach.substring(
                              vendor.bankDetails.cancelChequeAttach.lastIndexOf(
                                '.'
                              ) + 1
                            )}
                          </a>
                        )}
                      </Grid>
                      {/* <a>{vendor.bankDetails.cancelChequeAttach ? vendor.bankDetails.cancelChequeAttach : ''}</a> */}
                      <Grid item md={12} xs={12}></Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: '#394671', color: '#fff' }}
                >
                  <Button
                    onClick={e => {
                      handleCloseModal(resetForm);
                    }}
                    style={{
                      backgroundColor: '#394671',
                      color: '#fff',
                      border: '1px solid'
                    }}
                  >
                    Cancel
                  </Button>
                  {btn == false ? (
                    <Button
                      type="submit"
                      onClick={saveVendor}
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: '#394671',
                        color: '#fff',
                        border: '1px solid'
                      }}
                    >
                      Update
                    </Button>
                  )}
                </DialogActions>
              </Form>
            </Dialog>
          </MuiThemeProvider>
        )}
      </Formik>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={bulkOpen}
        id="income"
        contentStyle={{
          width: '50%',
          maxWidth: 'none'
        }}
        maxWidth="sm"
        fullWidth
      >
        <Loader isLoading={isLoading} />
        <DialogTitle
          id="scroll-dialog-title"
          onClose={e => {
            setBulkOpen(!bulkOpen);
          }}
          style={{ backgroundColor: '#394671', color: '#fff' }}
        >
          Upload File
        </DialogTitle>
        <DialogContent>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: '50%', height: '80%' }}
                variant="contained"
                component="label"
              >
                Bulk Upload
                <input type="file" onChange={e => handleUpload(e)} hidden />
              </Button>
              <br />
              {bulk && (
                <>
                  <a style={{ color: 'blue' }}>
                    {bulk.substring(0, 25)}....
                    {bulk.substring(bulk.lastIndexOf('.') + 1)}
                  </a>{' '}
                </>
              )}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#394671', color: '#fff' }}>
          <Button
            onClick={e => {
              setBulkOpen(!bulkOpen);
            }}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={bulUploadDatas}
            disabled={bulkFIle == '' || !bulkFIle}
            style={{
              backgroundColor: '#394671',
              color: '#fff',
              border: '1px solid'
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>


      {/* bulkupload modal */}
      <Dialog
        open={openBulk}
        onClose={handleCloseBulk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>{"Duplicate Vendor records"}</DialogTitle>
        <DialogContent>

          <div ref={htmlContentRef}>
            <table class="table">
              <thead class='tableHead'>
                <tr class="table-rows">
                  <th align="left">S.No</th>
                  <th align="left">PAN</th>
                  <th align="left">Email</th>
                  <th align="left">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                {errRecords &&
                  errRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((x, index) => (
                      <tr class="table-row">
                        <td align="left">{page * 10 + index + 1}</td>
                        <td align="left">{x?.pan}</td>
                        <td align="left">{x.email}</td>
                        <td align="left">{x.mobile}</td>
                      </tr>
                    ))}
              </tbody>
              {errRecords && errRecords.length > 10 &&
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  // component="div"
                  count={errRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage} style={{ width: "1420px" }}
                />
              }
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBulk} color="primary">
            close
          </Button>
          <Button onClick={printOrder} color="primary" autoFocus>
            Save PDF
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default Vendor;
