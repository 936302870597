import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Card, Avatar, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, Button, CardContent, InputAdornment, TextField, SvgIcon, Select, InputLabel, FormControl, MenuItem, FormHelperText
} from '@material-ui/core';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import { MESSAGE } from 'src/utils/message';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { set } from 'nprogress';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from 'src/utils/url';
import { currencyFormat1, dateOnlyRevReport } from '../../Common/utilities';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },

    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        fontFamily: 'Arial',
    },
    root: {

    },
    productCard: {
        height: '100%'
    },
    logostyle: {
        height: '100px',
        width: '100px'
    },
    textleftshadow: {
        textAlign: 'left',
        boxShadow: 'none'
    },
    textrightshadow: {
        textAlign: 'right',
        boxShadow: 'none'
    },
    table: {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: '5px'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },

    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    calmar: {
        marginTop: '6px',
        marginBottom: '0px',
        width: '100%',
        marginLeft: '10px'
    },
    dropdownBorder: {
        border: '1px solid #c4c4c4'
    },
    root: {
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    alignForInventory: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end'
        }
    },

    tablerow: {

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};





const AllProjectProductionSalesReport = ({ lookupData, projectDetails, allProjects, setIsLoading, setTabName, isTabName }) => {
    console.log('dasd', lookupData);
    const classes = useStyles();
    const [receiptList, setReceiptList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const currentDates = new Date();
    const [productionSalesPayload, setProductionSalesPayload] = useState({
        typeID: '',
        categoryID: '',
        fromDate: currentDates.setDate(currentDates.getDate() - 30),
        toDate: new Date(),
        projectID: '',
        itemID: ''
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [print, setprint] = useState(false)
    const [projectName, setProjectName] = useState('001');
    let userData = JSON.parse(localStorage.getItem('userData'));

    const a11yProps = (index) => {
        if (index == 0) {
            setProductionSalesPayload({
                typeID: '',
                categoryID: '',
                fromDate: null,
                toDate: null,
                projectID: projectName,
                itemID: ''
            });
            setReceiptList([])

        }
        if (index == 1) {
            setIsLoading(true)
            setReceiptList([])
            setProductionSalesPayload({
                typeID: '',
                categoryID: '',
                fromDate: currentDates.setDate(currentDates.getDate() - 30),
                toDate: new Date(),
                projectID: projectName,
                itemID: ''
            });
            setTimeout(() => {
                setIsLoading(false)
                getSalesReport()
            }, 3000)

        }
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getCategories = type => {
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getSalesCategory, {
            typeID: type,
            projectID: projectDetails.projectID
        })
            .then(res => {
                if (res.data.status === 200) {
                    setCategoryList(res.data.data);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'success' });
                } else {
                    // toast.error(res.data.message);
                    //   setIsLoading(false);
                    //addToast(res.data.message, { appearance: 'error' });
                }
            })
            .catch(e => { });
    };


    useEffect(() => {
        getSalesReport();

    }, [])

    const getSalesReport = () => {
        let changedDate = new Date(productionSalesPayload?.fromDate);
        let formattedDate = changedDate.toISOString();
        console.log('formattedDate', formattedDate);

        setReceiptList([])
        // setIsLoading(true)
        let payload = {
            projectID: projectName,
            typeID: productionSalesPayload.typeID ? productionSalesPayload.typeID : 0,
            categoryID: productionSalesPayload.categoryID ? productionSalesPayload.categoryID : 0,
            fromDate: formattedDate ? formattedDate : '',
            toDate: productionSalesPayload.toDate ? productionSalesPayload.toDate : ''
        };
        APIKit.post(RGCA_URLS.getSalesandProductReport, payload)
            .then(res => {
                if (res.data.status === 200) {
                    setReceiptList(res?.data?.data)
                    setIsLoading(false)
                    if (res?.data?.data.length > 0) {
                        toast.success("Successfully fetching data")
                    } else {
                        toast.warning('No data found.!.')
                    }
                } else {
                    toast.warn(res.data.message)
                    setIsLoading(false)
                }
            })
            .catch(function (e) {
                setIsLoading(false)
            });
    };


    const exportToCSV = () => {
        let name = projectDetails.projectName + 'Sales_Report' + '_' + currentDate
        try {
            // const fileName = name;
            const fileName = `${projectDetails?.projectName}_${isTabName}`;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const newCsvData = [];
            // Check if receiptList is an array and not empty
            if (!Array.isArray(receiptList) || receiptList.length === 0) {
                // throw new Error('Receipt list is empty or invalid.');
                toast.warning('Sales list is empty')
            }
            receiptList.forEach(x => {
                const obj = {};
                if (x?.productionDate) {
                    obj['Date'] = { v: moment(x?.productionDate).format('DD-MM-YYYY'), t: 's' }; // Setting type as string
                }
                if (x?.saleDate) {
                    obj['Date'] = { v: moment(x?.saleDate).format('DD-MM-YYYY'), t: 's' }; // Setting type as string
                }
                obj['Product Name'] = x.Productname ? x.Productname : ''; // Setting default value to empty string if falsy
                obj['Sale ID'] = x.sellingMasterID ? x.sellingMasterID : ''; // Setting type as string

                // obj['Rate Per Unit'] = x.ratePerUnit ? x.ratePerUnit : ''; // Setting default value to empty string if falsy
                if (x?.stock) {
                    obj['Prod Quantity'] = x.stock ? x.stock : ''; // Setting default value to empty string if falsy
                }
                if (x?.sale) {
                    obj['Sale Quantity'] = x.sale ? x.sale : ''; // Setting default value to empty string if falsy
                } newCsvData.push(obj);
            });

            if (newCsvData.length === 0) {
                throw new Error('No data to export.');
            }
            const ws = XLSX.utils.json_to_sheet(newCsvData);

            ws['!cols'] = [
                { wch: 15 }, // Receipt ID
                { wch: 20 }, // Product Name
                { wch: 15 }, // Selling Date
                { wch: 15 }, // Rate Per Unit
                { wch: 10 }, // Quantity
                { wch: 10 }, // GST
                { wch: 15 }, // Amount
                { wch: 15 }, // Paid Amount
                { wch: 20 }, // Remaining Balance
                { wch: 20 }, // Mode of Payment
                { wch: 20 }, // Customer Name
                { wch: 20 }  // Purpose
            ];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = { c: C, r: 0 };
                ws[XLSX.utils.encode_cell(cell_address)].s = { alignment: { horizontal: 'center' } }; // Setting horizontal alignment to center
            }
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting CSV:', error.message);
        }
    };

    const htmlContentRef = useRef(null);
    const printOrder = useReactToPrint({
        content: () => htmlContentRef.current,
    });
    const currentDate = new Date().toLocaleDateString();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };



    return (
        <>
            <div>
                <AppBar position="static" color="white">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        color='red'
                    >
                        {/* <Tab label="Specific Product Report" onClick={() => {
                            a11yProps(0);
                            setTabName(`Production_Sales_Specific_Product_Report`)
                        }
                        } /> */}
                        <Tab label="All Product Report" onClick={() => {
                            a11yProps(0)
                            setTabName(`Production_Sales_All_Product_Report_${moment(productionSalesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(productionSalesPayload?.toDate).format('DD/MM/YYYY')}`)
                        }} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={1}>
                    <Typography><h2 style={{ color: '#394671' }}>Specific Product Report</h2></Typography>
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                                    <Grid item md={3} xs={12} >
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Select Project
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Delivery Location"
                                                name="deliveryLocation"
                                                inputProps={{ maxLength: 100 }}
                                                onChange={e => {
                                                    setProjectName(e.target.value)
                                                }}
                                            >
                                                {allProjects &&
                                                    allProjects.map((Project, index) => {
                                                        return (
                                                            <MenuItem key={index} value={Project.projectID}>
                                                                {Project.projectName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Product Item
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={productionSalesPayload.typeID}
                                                name="typeID"
                                                label="Product Item"
                                                required
                                                onChange={e => {
                                                    const p = productionSalesPayload;
                                                    p.typeID = e.target.value;
                                                    setProductionSalesPayload({
                                                        ...productionSalesPayload,
                                                        typeID: e.target.value
                                                    });
                                                    getCategories(e.target.value);
                                                }}
                                            >
                                                {lookupData &&
                                                    lookupData.map(x => {
                                                        return (
                                                            <MenuItem value={x.lookupID} key={x.lookupID}>
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={productionSalesPayload.categoryID}
                                                label="Category"
                                                name="categoryID"
                                                required
                                                onChange={e => {
                                                    console.log("jjjjj", e);
                                                    const p = productionSalesPayload;
                                                    p.categoryID = e.target.value;
                                                    setProductionSalesPayload({
                                                        ...productionSalesPayload,
                                                        categoryID: e.target.value
                                                    });
                                                }}
                                                disabled={!categoryList || categoryList.length === 0}
                                            >
                                                {categoryList &&
                                                    categoryList.map(x => {
                                                        return (
                                                            <MenuItem
                                                                value={x.categoryID}
                                                                key={x.categoryID}
                                                            >
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={2} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {
                                                        const p = productionSalesPayload;
                                                        p.fromDate = dateOnlyRevReport(e);
                                                        setProductionSalesPayload({
                                                            ...p,
                                                            fromDate: dateOnlyRevReport(e)
                                                        });
                                                        setTabName(`Production_Sales_Specific Product Report_${productionSalesPayload.fromDate} to to ${productionSalesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={productionSalesPayload.fromDate ?? null}
                                                    className={classes.calmar}
                                                    required

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = productionSalesPayload;
                                                        p.toDate = dateOnlyRevReport(e);
                                                        setProductionSalesPayload({
                                                            ...p,
                                                            toDate: dateOnlyRevReport(e)
                                                        });
                                                        setTabName(`Production_Sales_Specific Product Report_${productionSalesPayload.fromDate} to to ${productionSalesPayload.toDate}`)
                                                    }}
                                                    value={productionSalesPayload.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={2} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {productionSalesPayload.categoryID && productionSalesPayload.typeID && productionSalesPayload.projectID ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Production_Sales_Specific_Product_Report_${moment(productionSalesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(productionSalesPayload?.toDate).format('DD/MM/YYYY')}`);
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={0}>
                    <Typography><h2 style={{ color: '#394671' }}>All Product Report</h2></Typography>
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center"  >
                                    <Grid item md={3} xs={12} >
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Select Project
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Delivery Location"
                                                name="deliveryLocation"
                                                inputProps={{ maxLength: 100 }}
                                                value={projectName}
                                                onChange={e => {
                                                    setProjectName(e.target.value)
                                                }}
                                            >
                                                <MenuItem value={"001"}>All</MenuItem>
                                                {allProjects &&
                                                    allProjects.map((Project, index) => {
                                                        return (
                                                            <MenuItem key={index} value={Project.projectID}>
                                                                {Project.projectName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item md={3} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                required
                                                id="demo-simple-select-outlined-label"
                                            >
                                                Report Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={'All'}
                                                name="typeID"
                                                label="Product Item"
                                                disabled
                                            >

                                                <MenuItem value={'All'} >
                                                    All
                                                </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="fromDate"
                                                    onChange={e => {
                                                        const p = productionSalesPayload;
                                                        p.fromDate = e;
                                                        setProductionSalesPayload({ ...p });
                                                        setTabName(`Production_Sales_All_Product_Report_${productionSalesPayload.fromDate}to${productionSalesPayload.toDate}`)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    value={productionSalesPayload?.fromDate}
                                                    className={classes.calmar}
                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    autoOk
                                                    disableFuture
                                                    // maxDate={new Date()}
                                                    InputProps={{ disabled: true }}
                                                    inputVariant="outlined"
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    name="toDate"
                                                    onChange={e => {
                                                        const p = productionSalesPayload;
                                                        p.toDate = e;
                                                        setProductionSalesPayload({ ...p });
                                                        setTabName(`Production_Sales_All_Product_Report_${moment(productionSalesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(productionSalesPayload?.toDate).format('DD/MM/YYYY')}`)
                                                    }}
                                                    value={productionSalesPayload?.toDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    className={classes.calmar}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={3} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {productionSalesPayload.fromDate && productionSalesPayload.toDate && projectName ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setTabName(`Production_Sales_All_Product_Report_${moment(productionSalesPayload?.fromDate).format('DD/MM/YYYY')}to${moment(productionSalesPayload?.toDate).format('DD/MM/YYYY')}`);
                                                    getSalesReport();
                                                }}
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled
                                                type="submit"
                                                size='large'
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Box>
                </TabPanel>
            </div>

            {receiptList && receiptList.length > 0 &&
                <>
                    <Box display="flex" justifyContent="flex-end">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} lg={2}
                                style={{
                                    marginTop: '10px',
                                    // color: '#3f51b5',
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    gap: '8px'

                                }}
                            >
                                <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <PrintIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    printOrder();
                                    setprint(true);

                                }} />
                                <SaveAltIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    exportToCSV();
                                    setprint(true);

                                }} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={3}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="left">S.No</TableCell >
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Product Name</TableCell >
                                        <TableCell align="left">Sale ID</TableCell >
                                        {/* <TableCell align="left">Rate Per Unit</TableCell> */}
                                        <TableCell align="left">Production Quantity</TableCell>
                                        <TableCell align="left">Mortality Quantity</TableCell>
                                        <TableCell align="left">Sale Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((x, index) => {
                                            return (
                                                <TableRow hover key={index + 1}>
                                                    <TableCell align="left">{page * 10 + index + 1}</TableCell>
                                                    {x?.productionDate && <TableCell align="left">{x?.productionDate ? moment(x?.productionDate).format('DD-MM-YYYY') : ''}</TableCell>}
                                                    {x?.saleDate && <TableCell align="left">{x?.saleDate ? moment(x?.saleDate).format('DD-MM-YYYY') : ''}</TableCell>}
                                                    <TableCell align="left">{x?.Productname}</TableCell>
                                                    <TableCell align="left">{x?.sellingMasterID}</TableCell>
                                                    {/* <TableCell align="left">{x.ratePerUnit}</TableCell> */}
                                                    <TableCell align="left">{x?.stock ? x.stock :''}</TableCell>
                                                   <TableCell align="left">{x?.mortalityCount ? x.mortalityCount :''}</TableCell>
                                                    <TableCell align="left">{x.sale ? x?.sale : ''}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow hover role="checkbox" key={1}>
                                            <TableCell colSpan={10} align="center" key={2}>
                                                {'No Data Found'}
                                            </TableCell>{' '}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {receiptList && receiptList.length > 10 &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={receiptList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Box>

                    <div className="printViewBox" ref={htmlContentRef} id="myHtmlContent">
                        <div class="grid-container">
                            <div class="logo-container">
                                <img src="/static/logo-rgca.jpg" alt="Company logo" />
                            </div>
                            <div class="text-container">
                                <div>
                                    <h3>RAJIV GANDHI CENTER FOR AQUACULTURE (RGCA)</h3>
                                    <h5>(MPEDA, Ministry of Commerce & Industry Government of India)</h5>
                                    <h5>HEAD OFFICE / UNIT OFFICE</h5>
                                </div>
                            </div>
                        </div>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid item>
                                <Typography>
                                    Name :  Sales Report
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    From Date : {moment(receiptList[0]?.fromDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    To Date : {moment(receiptList[0]?.toDate).format("MM/DD/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Date : {currentDate}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Project Name :  {projectDetails?.projectName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <div class="box">
                            <table class="table">
                                <thead class='tableHead'>
                                <tr class="table-rows">
                                        <th align="left">Date</th>
                                        <th align="left">Product Name</th>
                                        <th align="left">Sale ID</th>
                                        {/* <th align="left">Rate Per Unit</th> */}
                                        <th align="left">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receiptList && receiptList.length != 0 ? (
                                        receiptList.map((x, index) => {
                                            return (
                                                <tr class="table-row">
                                                    {x?.productionDate && <TableCell align="left">{x?.productionDate ? moment(x?.productionDate).format('DD-MM-YYYY') : ''}</TableCell>}
                                                    {x?.saleDate && <TableCell align="left">{x?.saleDate ? moment(x?.saleDate).format('DD-MM-YYYY') : ''}</TableCell>}
                                                    <td align="left">{x?.Productname}</td>
                                                    <td align="left">{x?.sellingMasterID}</td>
                                                    {/* <td align="left">{x.ratePerUnit}</td> */}
                                                    {x.stock && <TableCell align="left">{x.stock}</TableCell>}
                                                    {x.sale && <TableCell align="left">{x.sale}</TableCell>}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr class="table-row">
                                            <td align="left">
                                                {'No Data Found'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AllProjectProductionSalesReport