
import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box, Modal,
  FormHelperText,
  MenuItem, FormControl, InputLabel, Select, TextField
} from '@material-ui/core';
import Page from 'src/components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { statusColor } from 'src/utils/helpers';
import { withStyles } from '@material-ui/core/styles';
import APIKit from 'src/utils/APIKit';
import { RGCA_URLS } from '../../../utils/url';
import Address from "../Common/Address";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { MESSAGE } from 'src/utils/message';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/views/loader';
import { parseData, dateFormate, getProjectDetails, currencyFormat1 } from '../Common/utilities';
import MuiDialogActions from '@material-ui/core/DialogActions';
import StepperBox from '../Common/StepperBox.jsx';
import CommonSelect from '../Common/CommonSelect';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark, minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }, button: {
    backgroundColor: '#394671',
    color: '#fff',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#1b233d',
      color: '#fff',
  },
},
button2: {
   backgroundColor: '#394671',
                    color: '#fff',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#1b233d',
                      color: '#fff',
                  },
}
}));
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PaymentSystem = () => {
  const classes = useStyles();
  const { project } = useParams();
  const projectDetails = { ...getProjectDetails(project) }
  const [GSTTDS, setGSTTDS] = React.useState("NO");
  const [TAXTDS, setTAXTDS] = React.useState("NO");
  const [page, setPage] = React.useState(0);
  const [allVendors, setAllVendors] = useState([]);
  const [newObj, setNewObj] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [advancePayModel, setAdvancePayModel] = React.useState(false);
  const [model, setModel] = React.useState(false);
  const [advancePayModelView, setAdvancePayModelView] = React.useState(false);
  const [paymentList, setPaymentList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState([]);
  const [allGRN, setAllGRN] = React.useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [paymentCompleted, setPaymentPaymentCompleted] = React.useState(false);
  const [allWorkOrderList, setAllWorkOrderList] = useState([]);
  const [catOrRec, setCatOrRec] = useState([])

  const [payload, setPayload] = useState(
    {
      "paymentMethod": '',
      "projectID": null,
      "vendorID": '',
      "materialList": [],
      "val": {},
      "isOther": false,
      "noe": "",
      "itemID": "",
      "itemName": "",
      "subCategoryName": "",
      "categoryName": "",
      "Qty": "",
      "reference": '',
      "value": '',
      "isGstTds": '',
      "gstType": null,
      "gstTdsRate": '',
      "gstTaxExemption": '',
      "isItTaxTds": '',
      "itTdsRate": '',
      "itTaxExemption": '',
      "purposeAdvance": "",
      "balancePayable": ''
    }
  )
  const getGRNList = () => {
    APIKit.get(RGCA_URLS.getGRN + '/' + projectDetails.projectID)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map((x, i) => {
            return {
              ...x,
              grnItem: parseData(x.grnItem),
              projectDetails: parseData(x.projectDetails),
              vendorDetails: parseData(x.vendorDetails),
            }
          })
          setAllGRN(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };


  const handleAdvancePayModel = (data) => {
    console.log(data);
    if (data != 0) {
      setPayload({ ...data, approvals: parseData(data.approvals) })
    }

    setAdvancePayModel(!advancePayModel)
  }
  const handleAdvancePayModelView = () => {
    setAdvancePayModelView(!advancePayModelView)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const getWorkOrderAllApprovedList = () => {
    APIKit.get(`${RGCA_URLS.getWorkOrderAllApprovedList}/${projectDetails.projectID}`)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data.map(x => {
            return {
              ...x,
              // Approvals: parseData(x.Approvals),
              workOrderItem: parseData(x.workOrderItem),
            }
          });
          setAllWorkOrderList(data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
      });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getVendor = () => {
    APIKit.post(RGCA_URLS.listVendor)
      .then((res) => {
        if (res.data.status === 200) {
          setAllVendors(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };
  const getPaymentMethod = () => {
    APIKit.get(`${RGCA_URLS.lookup}/55`)
      .then((res) => {
        if (res.data.status === 200) {
          setLookupData(res.data.data);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch(() => {
      });
  };
  const handlePayViewCompleted = () => {
    setPaymentPaymentCompleted(!paymentCompleted)
  }

  const getPaymentList = () => {
    APIKit.post(RGCA_URLS.getUserPaymentSystemList, {
      "projectID": projectDetails.projectID
    })
      .then((res) => {
        if (res.data.status === 200) {
          setPaymentList(res.data.data);
        } else {
        }
      })
      .catch((e) => {
      });
  };
  const handleModel = () => {
    setPayload({
      "paymentMethod": '',
      "itemID": "",
      "itemName": "",
      "subCategoryName": "",
      "categoryName": "",
      "Qty": "",
      "projectID": null,
      "vendorID": '',
      "reference": '',
      "value": '',
      "noe": "",
      "isGstTds": '',
      "materialList": [],
      "val": {},
      "gstType": null,
      "gstTdsRate": '',
      "gstTaxExemption": '',
      "isItTaxTds": '',
      "itTdsRate": '',
      "itTaxExemption": '',
      "purposeAdvance": "",
      "balancePayable": ''
    })
    setPaymentData({})
    setNewObj({})
    setModel(!model)
  }
  const paymentAdd = () => {
    if (Number(payload.value) == 0) {
      toast.error('Value must not be 0', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (newObj.WOorPO == 'Other') {
      if (Object.keys(payload.val).length === 0) {
        toast.error('Please select Material', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        return
      }
    }
    payload.projectID = projectDetails.projectID
    payload.GRNID = paymentData.grnMasterID
    payload.GRNorWO = 1
    let paymentUpdate = {
      projectID: payload.projectID,
      ...(newObj.GRNID != '') && { GRNID: newObj.GRNID },
      ...(newObj.WoID != '') && { workOrderID: newObj.WoID },
      ...(newObj.WOorPO != 'Other') && {
        paymentMethod: payload.paymentMethod,
      },
      vendorID: paymentData.vendorID,
      GRNorWO: newObj.GRNID != '' ? 1 : newObj.WoID != '' ? 2 : 3,
      reference: Number(payload.reference),
      value: newObj.WOorPO != 'Other' ? Number(payload.value) : Number(payload.value) + Number(payload.value) * Number(payload.gstType / 100),
      isGstTds: payload.isGstTds,
      gstTdsRate: payload.isGstTds ? Number(payload.gstTdsRate) : 0,
      gstTaxExemption: payload.isGstTds ? paymentData?.totalItemValueNoTax ?? payload?.value * (payload.gstTdsRate / 100) : 0,
      isItTaxTds: payload.isItTaxTds,
      itTdsRate: payload.isItTaxTds ? Number(payload.itTdsRate) : 0,
      itTaxExemption: payload.isItTaxTds ? paymentData?.totalItemValueNoTax ?? payload?.value * (payload.itTdsRate / 100) : 0,
      purposeAdvance: payload.purposeAdvance,
      balancePayable: newObj.WOorPO != 'Other' ? Number(paymentData.pendingAmount ?? paymentData.pendingAmountWO) - Number(payload.value) : 0,
      ...(newObj.WOorPO == 'Other') && {
        noe: payload.noe,
        materialID: payload.itemID,
        grnQty: payload.Qty,
        unitPrice: Number(payload.value) / Number(payload.Qty),
        totalPrice: Number(payload.value)
      }
    }

    setIsLoading(true);
    APIKit.post(RGCA_URLS.addPaymentForGRNWO, paymentUpdate)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPayload({
            "paymentMethod": '',
            "projectID": null,
            "vendorID": '',
            "noe": "",
            "reference": '',
            "itemID": "",
            "itemName": "",
            "subCategoryName": "",
            "categoryName": "",
            "Qty": "",
            "materialList": [],
            "val": {},
            "value": '',
            "isGstTds": '',
            "gstType": null,
            "gstTdsRate": '',
            "gstTaxExemption": '',
            "isItTaxTds": '',
            "itTdsRate": '',
            "itTaxExemption": '',
            "purposeAdvance": "",
            "balancePayable": ''
          })
          getPaymentList()
          getGRNList()
          setModel(!model)
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
      .catch((e) => {
      });
  }
  const getLookupData = () => {
    APIKit.get(RGCA_URLS.lookup + '/' + 1)
      .then((res) => {
        if (res.data.status === 200) {
          setCatOrRec(res.data.data)
        }
        else {

        }
      })
      .catch(function (e) {
      })
  }
  const onChangeInput = (value, index) => {

    let item = { ...payload };
    item.val = value
    item.itemID = value.value;
    item.itemName = value.label;
    let uo = payload.materialList.find(x => x.itemID == value.value)
    item.categoryName = uo.categoryName
    item.subCategoryName = uo.subCategoryName
    item.uom = uo.uom
    item.lastPrice = Number(uo.lastPrice)
    item.brandOrCatalogue = uo.brandOrCatalogue
    item.availabilityQty = uo.availabilityQty
    setPayload({ ...item })
  }
  useEffect(() => {
    getLookupData()
    getVendor()
    getPaymentList();
    getWorkOrderAllApprovedList()
    getPaymentMethod()
    getGRNList()
  }, [])
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: 'Accountant',
      status: 'Created',
      description: 'Accountant comment'
    },
    {
      label: 'Project Head Approval',
      status: "AWAITING",
      description: 'Project Head comment'
    }

  ];

  const getMaterials = (i, typeID, projectID) => {
    let payload1 = {
      projectID: projectID,
      typeID: typeID
    }
    APIKit.post(RGCA_URLS.getProductsForIndentSelect, payload1)
      .then((res) => {
        if (res.data.status === 200) {
          let item = { ...payload };
          item.materialList = res.data.data;
          setPayload({ ...item })
        }
        else {

        }
      })
      .catch(function (e) {
      })
    // console.log('text-text',payload)


  }
  let regEx = {
    textOnly: /[a-zA-Z\s]+/,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    numbersOnly: /^[0-9]*$/,
    noSpecialChar: /^[a-zA-Z0-9.]*$/
  };

  return <Page
    className={classes.root}
    title="Payment System"
  >
    <Loader isLoading={isLoading} />
    <ToastContainer />
    <Container maxWidth={false}>
      <Typography><h2 style={{ color: '#394671' }}>Payment System List</h2></Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={handleModel}
              color="primary"
              variant="contained" >
              Add Payment
            </Button>
          </div>
        </Grid>


        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <div style={{ textAlign: "center" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Payment ID</TableCell>
                    <TableCell align="center">GRN Master ID</TableCell>
                    <TableCell align="center">WorkOrder Master ID</TableCell>
                    <TableCell align="center">Project Name</TableCell>
                    <TableCell align="center">Vendor Name</TableCell>
                    <TableCell align="center">Reference ID</TableCell>
                    <TableCell align="center">Value</TableCell>
                    <TableCell align="center">GST TDS</TableCell>
                    <TableCell align="center">TAX TDS</TableCell>
                    <TableCell align="center">Purpose</TableCell>
                    <TableCell align="center">Pending Amount</TableCell>

                    <TableCell align="center">Action</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>

                  {paymentList && paymentList.map((x) => {
                    return <TableRow hover role="checkbox" tabIndex={-1} >
                      <TableCell align="center">{x.paymentSystemMasterID}</TableCell>
                      <TableCell align="center">{x.GRNID ?? '-'}</TableCell>
                      <TableCell align="center">{x.workOrderID ?? '-'}</TableCell>
                      <TableCell align="center">{x.projectName}</TableCell>
                      <TableCell align="center">{x.vendorName}</TableCell>
                      <TableCell align="center">{x.reference}</TableCell>
                      <TableCell align="center">{x.value}</TableCell>
                      <TableCell align="center">{x.gstTdsRate}</TableCell>
                      <TableCell align="center">{x.itTdsRate}</TableCell>
                      <TableCell align="center">{x.purposeAdvance}</TableCell>
                      <TableCell align="center">{x.pendingAmount ?? x.workOrderItemValue ?? 0}</TableCell>

                      <TableCell align="center">

                        {/* <VisibilityIcon style={{ cursor: "pointer",marginRight:"10px" }} /> */}
                        <VisibilityIcon style={{ cursor: "pointer" }} onClick={() => { handleAdvancePayModel(x) }} />
                      </TableCell>
                    </TableRow>
                  })}




                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paymentList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </div>
        </Grid>

      </Grid>
    </Container>

    {/* More */}
    <Dialog aria-labelledby="customized-dialog-title" open={advancePayModel} id="income"
      //  style={{ maxWidth: "1100px !important", width: "1100px" }}
      contentStyle={{
        width: '200%',
        maxWidth: 'none',
      }}
      maxWidth="lg" fullWidth
    >
      <Loader isLoading={isLoading} />

      <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff", width: "1200px !important" }}>
        Payment
      </DialogTitle>
      <DialogContent dividers style={{ width: "1200px !important" }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <div style={{ textAlign: "center" }}>
              <Typography><h5 style={{ color: '#394671' }}>ID : {payload?.workOrderID ?? payload?.GRNID ?? 'Other than PO and WO'}</h5></Typography>
            </div>

          </Grid>


          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <div style={{ textAlign: "center" }}>
              <Typography><h5 style={{ color: '#394671' }}>Date : {payload?.createdDate}</h5></Typography>
            </div>

          </Grid>
          {/* ---------------------------------------------------------------------------------------- */}

          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <div style={{ textAlign: "center" }}>
              <Typography><h5 style={{ color: '#394671' }}>Total Price: {payload?.totalAmountWO ?? payload?.totalAmountGRN ?? payload?.value}</h5></Typography>
            </div>

          </Grid>
          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <div style={{ textAlign: "center" }}>
              <Typography><h5> Status: {payload?.statusName}</h5></Typography>
            </div>
          </Grid>
          {payload.GRNorWO != 3 && <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Payment Method *</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Payment Method"
                value={payload.paymentMethod}
                name="paymentMethod"
                disabled={true}
                onChange={(e) => {
                  const p = payload;
                  p.paymentMethod = Number(e.target.value);
                  setPayload({
                    ...p,
                  });
                  if (e.target.value == 57) {
                    const p = payload;
                    p.value = payload.balancePayable;
                    setPayload({
                      ...p,
                      value: payload.balancePayable
                    });
                  } else {
                    const p = payload;
                    p.value = '';
                    setPayload({
                      ...p,
                      value: ''
                    })
                  }
                }}

              >
                {lookupData && lookupData.map((x) => {
                  return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                })}
              </Select>
            </FormControl>

          </Grid>}


          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Location"
                value={payload.vendorID}
                name="vendorID"
                disabled={true}
                onChange={(e) => {
                  const p = payload;
                  p.vendorID = Number(e.target.value);
                  setPayload({
                    ...p,
                  });
                }}

              >
                {allVendors && allVendors.map((x) => {
                  return <MenuItem value={x.vendorID} key={x.vendorID}>{x.name}</MenuItem>;
                })}
              </Select>
            </FormControl>

          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
          >
            {payload.vendorID ? <>     <b> Supplier address : </b>
              <Address details={allVendors?.filter((e) => {
                if (e.vendorID === payload?.vendorID) {
                  return {
                    address: e?.address
                  }
                }
              })[0].address} /> </> : null}
          </Grid>


          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="If any Reference"
              label="Reference"
              value={payload.reference}
              disabled={true}
              name="reference"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.reference = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"

            />
          </Grid>

          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="Value"
              label="Value"
              value={payload.value}
              disabled={true}
              name="value"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                if ((e.target.value === '' || regEx.numbersOnly.test(e.target.value)) && e.target.value < (payload.pendingAmount != null ? Number(payload.totalAmountGRN) - payload.value : Number(payload.totalAmountWO) - + payload.value)) {
                  const p = payload;
                  p.value = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">GST TDS</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="GST TDS"
                required
                value={payload.isGstTds}
                disabled={true}
                name="isGstTds"
                onChange={(e) => {
                  const p = payload;
                  p.isGstTds = e.target.value;
                  setGSTTDS(e.target.value)
                  setPayload({
                    ...p,
                  });
                }}
              // onChange={(e) => setGSTTDS(e.target.value)}
              >
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {payload.isGstTds === true && (<Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="Percentage"
              label="GST TDS rate"
              value={payload.gstTdsRate}
              disabled={true}
              name="gstTdsRate"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.gstTdsRate = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>)}
          {payload.isGstTds === true && (<Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="Enter Amount"
              label="Tax Exemption"
              disabled={true}
              value={payload.gstTaxExemption}
              name="gstTaxExemption"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.gstTaxExemption = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>)}


          <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">TAX TDS</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Location"
                value={payload.isItTaxTds}
                disabled={true}
                name="isItTaxTds"
                onChange={(e) => {
                  const p = payload;
                  p.isItTaxTds = e.target.value;
                  setTAXTDS(e.target.value)
                  setPayload({
                    ...p,
                  });
                }}
              // value={TAXTDS}
              // onChange={(e) => setTAXTDS(e.target.value)}
              >
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {payload.isItTaxTds === true && (<Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="Percentage"
              label="Tax TDS rate"
              value={payload.itTdsRate}
              disabled={true}
              name="itTdsRate"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.itTdsRate = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>)}
          {payload.isItTaxTds === true && (<Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText="Enter Amount"
              label="Tax Exemption"
              value={payload.itTaxExemption}
              disabled={true}
              name="itTaxExemption"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.itTaxExemption = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>)}
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={4}
          >
            <TextField
              fullWidth
              helperText=""
              label="Purpose"
              required
              value={payload.purposeAdvance}
              name="purposeAdvance"
              disabled={true}
              onChange={(e) => {
                const p = payload;
                p.purposeAdvance = e.target.value;
                setPayload({
                  ...p,
                });
              }}
              variant="outlined"
            />
          </Grid>
          {payload.GRNorWO != 3 && <Grid
            item
            lg={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <TextField
              fullWidth
              helperText=""
              label="Balance Payable"
              value={payload.pendingAmount != null ? Number(payload.totalAmountGRN) - payload.value : Number(payload.totalAmountWO) - payload.value}
              disabled={true}
              name="balancePayable"
              onChange={(e) => {
                if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                  const p = payload;
                  p.balancePayable = e.target.value;
                  setPayload({
                    ...p,
                  });
                }
              }}
              variant="outlined"
            />
          </Grid>}

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Box >
              <StepperBox steps={payload.approvals} />
            </Box>
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div style={{ textAlign: "right" }}>
              {/* <Button
                color="primary"
                // onClick={handleAdvancePayModel}
                onClick={() => paymentUpdate()}
                disabled={payload?.approvals?.some(e => e.status != "Awaiting") && (payload?.approvals?.every(e => e.status != "Modification") || payload?.approvals?.every(e => e.status != "Rejected") || payload?.approvals?.every(e => e.status != "Hold") || payload?.approvals?.every(e => e.status != "Dropped"))}
                variant="contained" >
                Update
              </Button> */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                onClick={() => handleAdvancePayModel('0')}
                color="primary"
                variant="contained" >
                Close
              </Button>
            </div>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>

    {/* Create Model */}
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...payload
      }}
      onSubmit={(values, { resetForm }) => {
        paymentAdd()
      }}
      validationSchema={
        Yup.object().shape({
          reference: Yup.string().required(MESSAGE.reference),
          paymentMethod: Yup.string().when('isOther', {
            is: false,
            then: Yup.string()
              .required(MESSAGE.paymentMethod),
            otherwise: Yup.string(),
          }),
          value: Yup.string().required(MESSAGE.value),
          isGstTds: Yup.string().required(MESSAGE.isGstTds),
          isItTaxTds: Yup.string().required(MESSAGE.isItTaxTds),
          purposeAdvance: Yup.string().required(MESSAGE.purposeAdvance).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
          Qty: Yup.string().when('isOther', {
            is: true,
            then: Yup.string()
              .required(MESSAGE.qty),
            otherwise: Yup.string(),
          }),
          noe: Yup.string().when('isOther', {
            is: true,
            then: Yup.string()
              .required(MESSAGE.noe),
            otherwise: Yup.string(),
          }),
          gstType: Yup.string().when('isOther', {
            is: true,
            then: Yup.string()
              .required(MESSAGE.gstRate).nullable(),
            otherwise: Yup.string(),
          }),
          // PanCardFrontpic: Yup.string().required(MESSAGE.gstNumber),
          // PanCardBackpic: Yup.string().required(MESSAGE.gstNumber),
          // GSTCertificatepic: Yup.string().required(MESSAGE.gstNumber),

        })
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
      }) => (
        <Dialog aria-labelledby="customized-dialog-title" open={model} id="income"
          //  style={{ maxWidth: "1100px !important", width: "1100px" }}
          contentStyle={{
            width: '200%',
            maxWidth: 'none',
          }}
          maxWidth="md" fullWidth
        >
          <Loader isLoading={isLoading} />

          <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#394671", color: "#fff", width: "1200px !important" }}>
         
          <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
              <h2> 
             Payment
           </h2>
                  <h3 onClick={()=>{
                    setModel(false)
                  }}>
                x
                  </h3>
                  </div>
          </DialogTitle> 

         
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent dividers style={{ width: "1200px !important" }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={3}
                  sm={3}
                  xl={3}
                  xs={3}
                >
                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Choose the Payment</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Choose the Payment"
                      value={newObj.WOorPO}
                      onChange={(e) => {
                        const p = newObj;
                        p.WOorPO = e.target.value;
                        p.GRNID = '';
                        p.WoID = '';
                        setNewObj({
                          ...p,
                        });
                        if (e.target.value == 'Other') {
                          payload.isOther = true
                        } else {
                          payload.isOther = false
                        }
                        setPayload({ ...payload })
                        setPaymentData({})
                      }}

                    >
                      <MenuItem value="WorkOrder">For WorkOrder</MenuItem>
                      <MenuItem value="PurchaseOrder" >For PurchaseOrder</MenuItem>
                      <MenuItem value="Other" >Other than WO or PO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {newObj.WOorPO == "PurchaseOrder" && <Grid
                  item
                  lg={3}
                  sm={3}
                  xl={3}
                  xs={3}
                >
                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Choose GRN</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Choose GRN"
                      value={newObj.GRNID}
                      onChange={(e) => {
                        const p = newObj;
                        p.GRNID = e.target.value;
                        p.WoID = '';
                        setNewObj({
                          ...p,
                        });
                        allGRN.filter(e => {
                          if (e.grnMasterID == newObj?.GRNID) {
                            setPaymentData(e)
                          }
                        })
                      }}

                    >
                      {allGRN?.map(li => <MenuItem key={li.grnMasterID} value={li.grnMasterID}>{li.grnMasterID}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>}
                {newObj.WOorPO == "WorkOrder" && <Grid
                  item
                  lg={3}
                  sm={3}
                  xl={3}
                  xs={3}
                >
                  <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Choose WorkOrder</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Choose WorkOrder"
                      value={newObj.WoID}
                      onChange={(e) => {
                        const p = newObj;
                        p.WoID = e.target.value;
                        p.GRNID = '';
                        setNewObj({
                          ...p,
                        });
                        allWorkOrderList.filter(e => {
                          if (e.workOrderMasterID == newObj?.WoID) {
                            setPaymentData({
                              ...e,
                              totalItemValueNoTax: e.workOrderItem.reduce((a, b) => (b.qty * b.rate) + a, 0)
                            })
                          }
                        })
                      }}

                    >
                      {allWorkOrderList?.map(li => <MenuItem key={li.workOrderMasterID} value={li.workOrderMasterID}>{li.workOrderMasterID}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>}
                {(Object.keys(paymentData).length != 0 && paymentData.pendingAmount != 0 || (newObj.WOorPO == 'Other')) && <Grid
                  container
                  spacing={3}
                >
                  {newObj.WOorPO != 'Other' && <>{newObj.GRNID != '' && <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={12}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Typography><h5 style={{ color: '#394671' }}>Invoice ID : {paymentData.invoiceID}</h5></Typography>
                    </div>


                  </Grid>}



                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      {newObj.GRNID != '' ? <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>GRN Master ID : {paymentData.grnMasterID}</h5></Typography>
                      </div> : <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>WorkOrder Master ID : {paymentData.workOrderMasterID}</h5></Typography>
                      </div>}



                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>Total Amount :{currencyFormat1(Math.round(paymentData.totalItemValue ?? paymentData.tcv))} </h5></Typography>
                      </div>

                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>Pending Amount : {currencyFormat1(Math.round(paymentData.pendingAmount ?? paymentData.pendingAmountWO))}</h5></Typography>
                      </div>

                    </Grid>
                    {newObj.GRNID != '' &&
                      <Grid
                        item
                        lg={3}
                        sm={3}
                        xl={3}
                        xs={12}
                      >
                        <div style={{ textAlign: "center" }}>
                          <Typography><h5 style={{ color: '#394671' }}>Purchaseorder Master ID : {paymentData.purchaseOrderMasterID}</h5></Typography>
                        </div> 

                      </Grid>}
                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>Total Amount (Tax Exclusive) : {currencyFormat1(Math.round(paymentData?.totalItemValueNoTax))}</h5></Typography>
                      </div>

                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        {/* <Typography><h5 style={{ color: '#394671' }}>GST Details : {poGST[0]?.gstDetails[0]?.CGST}</h5></Typography> */}
                      </div>

                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        {/* <Typography><h5 style={{ color: '#394671' }}>GST Details : {poGST[0]?.gstDetails[0]?.CGST}</h5></Typography> */}
                      </div>

                    </Grid>
                    {newObj.GRNID == '' && <><Grid
                      item
                      lg={3}
                      sm={3}
                      xl={3}
                      xs={12}
                    >
                      {/* <div style={{ textAlign: "center" }}>
                      <Typography><h5 style={{ color: '#394671' }}>Invoice ID : {paymentData.invoiceID}</h5></Typography>
                    </div> */}


                    </Grid>
                      <Grid
                        item
                        lg={3}
                        sm={3}
                        xl={3}
                        xs={12}
                      >
                        {/* <div style={{ textAlign: "center" }}>
                    <Typography><h5 style={{ color: '#394671' }}>Invoice ID : {paymentData.invoiceID}</h5></Typography>
                  </div> */}


                      </Grid></>}</>}


                  {newObj.WOorPO != "Other" && <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.paymentMethod && errors.paymentMethod)}>
                      <InputLabel id="demo-simple-select-outlined-label">Payment Method</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Payment Method"
                        value={payload.paymentMethod}
                        name="paymentMethod"
                        onBlur={handleBlur}
                        required
                        onChange={(e) => {
                          handleChange(e);
                          const p = payload;
                          p.paymentMethod = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                          if (e.target.value == 57) {
                            const p = payload;
                            p.value = paymentData.pendingAmount;
                            setPayload({
                              ...p,
                              value: paymentData.pendingAmount
                            });
                          } else {
                            const p = payload;
                            p.value = '';
                            setPayload({
                              ...p,
                              value: ''
                            })
                          }
                        }}

                      >
                        {lookupData && lookupData.map((x) => {
                          return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText>{(errors.paymentMethod && touched.paymentMethod) && errors.paymentMethod}</FormHelperText>
                    </FormControl>

                  </Grid>}


                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.vendorID && errors.vendorID)}>
                      <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Location"
                        value={paymentData.vendorID}
                        name="vendorID"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const p = paymentData;
                          p.vendorID = Number(e.target.value);
                          setPaymentData({
                            ...p,
                          });
                        }}
                        disabled={!(newObj.WOorPO == 'Other')}
                      >
                        {allVendors && allVendors.map((x) => {
                          return <MenuItem value={x.vendorID} key={x.vendorID}>{x.name}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText>{(errors.vendorID && touched.vendorID) && errors.vendorID}</FormHelperText>
                    </FormControl>

                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >

                    {paymentData.vendorID ? <>     <b> Supplier address : </b>
                      <Address details={allVendors?.filter((e) => {
                        if (e.vendorID === paymentData?.vendorID) {
                          return {
                            address: e?.address
                          }
                        }
                      })[0]?.address} /> </> : null}

                  </Grid>


                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      label="Reference"
                      error={Boolean(touched.reference && errors.reference)}
                      helperText={touched.reference && errors.reference}
                      value={payload.reference}
                      onBlur={handleBlur}
                      required
                      name="reference"
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          handleChange(e);
                          const p = payload;
                          p.reference = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                        }
                      }}
                      variant="outlined"
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      label="Value"
                      required
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && errors.value}
                      onBlur={handleBlur}
                      value={payload.value}
                      disabled={payload.paymentMethod == 57 ? true : false}
                      InputLabelProps={{ shrink: true }}
                      name="value"
                      onChange={(e) => {
                        if ((e.target.value === '' || regEx.numbersOnly.test(e.target.value)) && (newObj.WOorPO !== 'Other' ? e.target.value < Number(paymentData.pendingAmount ?? paymentData.pendingAmountWO) : true)) {
                          handleChange(e);
                          const p = payload;
                          p.value = e.target.value;
                          setPayload({
                            ...p,
                            value: e.target.value
                          });
                        }
                      }}

                      variant="outlined"
                    />
                  </Grid>
                  {newObj.WOorPO == "Other" &&
                    <><Grid
                      item
                      md={3}
                      xs={12}
                    >

                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 2 }}
                        error={Boolean(touched.gstType && errors.gstType)}
                        helperText={touched.gstType && errors.gstType}
                        onChange={(e) => {
                          if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                            let item = { ...payload };
                            item.gstType = Number(e.target.value);
                            setPayload({ ...item })
                          }
                        }}
                        required
                        value={payload.gstType}
                        label="GST Rate (%)"
                        name="gstType"
                        variant="outlined"
                      />
                    </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 2 }}
                          onChange={(e) => {
                            if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                              let item = { ...payload };
                              item.gstType = Number(e.target.value);
                              setPayload({ ...item })
                            }
                          }}
                          disabled={true}
                          value={Number(payload.value) + Number(payload.value) * Number(payload.gstType / 100)}
                          label="Gross Bill"
                          name="gstValue"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <FormControl
                          error={Boolean(touched.noe && errors.noe)}
                          variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                          <InputLabel required id="demo-simple-select-outlined-label">Nature</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              payload.val = {}
                              payload.categoryName = ''
                              payload.subCategoryName = ''
                              payload.noe = Number(e.target.value);
                              setPayload({ ...payload })
                              getMaterials(0, payload?.noe, projectDetails.projectID) // project Id hard coded0
                            }}
                            value={payload?.noe}
                            name="noe"
                            required
                            label="Nature"
                          >
                            {catOrRec.map((l) => {
                              return <MenuItem value={l.lookupID} key={l.lookupID}>{l.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText>{(errors.noe && touched.noe) && errors.noe}</FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <CommonSelect val={payload?.val} onChange={(e) => onChangeInput(e, 0)} options={payload?.materialList} />
                      </Grid>
                      {payload.categoryName && <>
                        <Grid
                          item
                          md={3}
                          xs={12}
                        >
                          <Typography><h5 style={{ color: '#394671' }}>Ledger Head : {payload.categoryName}</h5></Typography>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          xs={12}
                        >
                          <Typography><h5 style={{ color: '#394671' }}>Group : {payload.subCategoryName}</h5></Typography>
                        </Grid></>}
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Quantity"
                          name="Qty"
                          onBlur={handleBlur}
                          error={Boolean(touched.Qty && errors.Qty)}
                          helperText={touched.Qty && errors.Qty}
                          required
                          onChange={(e) => {
                            if (e.target.value.match(regEx.numbersOnly)) {
                              handleChange(e)
                              let item = { ...payload };
                              item.Qty = e.target.value;
                              setPayload({ ...item })
                            }
                          }
                          }
                          value={payload.Qty}
                          variant="outlined"
                          inputProps={{ maxLength: 5 }}

                        />
                      </Grid>

                    </>}

                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.isGstTds && errors.isGstTds)}>
                      <InputLabel id="demo-simple-select-outlined-label">GST TDS</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Location"
                        value={payload.isGstTds}
                        name="isGstTds"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const p = payload;
                          p.isGstTds = e.target.value;
                          setGSTTDS(e.target.value)
                          setPayload({
                            ...p,
                          });
                        }}
                      // onChange={(e) => setGSTTDS(e.target.value)}
                      >
                        <MenuItem value={true}>YES</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                      <FormHelperText>{(errors.isGstTds && touched.isGstTds) && errors.isGstTds}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {payload.isGstTds === true && (<Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      helperText="Percentage"
                      label="GST TDS rate (%)"
                      value={payload.gstTdsRate}
                      name="gstTdsRate"
                      inputProps={{
                        maxLength: 7
                      }}
                      
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          const p = payload;
                          p.gstTdsRate = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>)}
                  {payload.isGstTds === true && (<Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      helperText="Enter Amount"
                      label="Tax Exemption"
                      inputProps={{
                        maxLength: 7
                      }}
                      disabled={true}
                      value={paymentData?.totalItemValueNoTax ?? payload.value * (payload.gstTdsRate / 100)}
                      name="gstTaxExemption"
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          const p = payload;
                          p.gstTaxExemption = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>)}
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl} error={Boolean(touched.isItTaxTds && errors.isItTaxTds)}>
                      <InputLabel id="demo-simple-select-outlined-label">TAX TDS</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Location"
                        
                        value={payload.isItTaxTds}
                        name="isItTaxTds"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e)
                          const p = payload;
                          p.isItTaxTds = e.target.value;
                          setTAXTDS(e.target.value)
                          setPayload({
                            ...p,
                          });
                        }}
                      // value={TAXTDS}
                      // onChange={(e) => setTAXTDS(e.target.value)}
                      >
                        <MenuItem value={true}>YES</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                      <FormHelperText>{(errors.isItTaxTds && touched.isItTaxTds) && errors.isItTaxTds}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {payload.isItTaxTds === true && (<Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      helperText="Percentage"
                      label="Tax TDS rate"
                      value={payload.itTdsRate}
                      name="itTdsRate"
                      inputProps={{
                        maxLength: 7
                      }}
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          const p = payload;
                          p.itTdsRate = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>)}
                  {payload.isItTaxTds === true && (<Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      helperText="Enter Amount"
                      label="Tax Exemption"
                      disabled={true}
                      value={paymentData?.totalItemValueNoTax ?? payload.value * (payload.itTdsRate / 100)}
                      name="itTaxExemption"
                      inputProps={{
                        maxLength: 7
                      }}
                      onChange={(e) => {
                        if (e.target.value === '' || regEx.numbersOnly.test(e.target.value)) {
                          const p = payload;
                          p.itTaxExemption = Number(e.target.value);
                          setPayload({
                            ...p,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Grid>)}
                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={4}
                  >
                    <TextField
                      fullWidth
                      label="Purpose"
                      value={payload.purposeAdvance}
                      required
                      name="purposeAdvance"
                      error={Boolean(touched.purposeAdvance && errors.purposeAdvance)}
                      helperText={touched.purposeAdvance && errors.purposeAdvance}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                        const p = payload;
                        p.purposeAdvance = e.target.value;
                        setPayload({
                          ...p,
                        });
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  {newObj.WOorPO != 'Other' && <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3}
                  >
                    <TextField
                      fullWidth
                      label="Balance Payable"
                      onBlur={handleBlur}
                      value={(paymentData.pendingAmount ?? paymentData.pendingAmountWO) - payload.value}
                      disabled={!(newObj.WOorPO == 'Other')}
                      name="balancePayable"
                      onChange={(e) => {
                        const p = payload;
                        p.balancePayable = Number(e.target.value);
                        setPayload({
                          ...p,
                        });
                      }}
                      variant="outlined"
                    />
                  </Grid>}


                </Grid>}
                {paymentData.pendingAmount == 0 &&
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography><h5 style={{ color: '#394671' }}>Payment request is completed for this goods</h5></Typography>
                        {/* <Button
                  style={{marginTop:10}}
                  color="primary"
                  variant="contained"
                  onClick={handlePayViewCompleted}
                >
                  Close
                </Button> */}
                      </div>


                    </Grid>
                  </Grid>

                }



              </Grid>


            </DialogContent>
            <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
              <Button
                color="primary"
                // onClick={handleAdvancePayModel}
                className={classes.button2}
                // onClick={() => paymentAdd()}
                disabled={!(Object.keys(paymentData).length != 0 && paymentData.pendingAmount != 0 || (newObj.WOorPO == 'Other'))}
                type='submit'
                variant="contained" >
                Create
              </Button>
              <Button
                onClick={() => {
                  handleModel();
                  resetForm()
                }}
                color="primary"
                className={classes.button2}
                variant="contained" >
                Close
              </Button>
            </DialogActions>
          </Form>

        </Dialog>
      )}
    </Formik>


  </Page>

}

export default PaymentSystem
