import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),

   
    name: 'Ekaterina Tankova',
    phone: '304-428-3097',
    employeeId:"E001",
    designation:"Manager",
    gender:"Male",
    department:"D001",
    location:"Chennai",
    email: 'ekaterina.tankova@devias.io',
    flag: '/static/images/avatars/avatar_3.png',

  },
  {
    id: uuid(),

   
    name: 'Ekaterina Tankova',
    phone: '304-428-3097',
    employeeId:"E001",
    designation:"Manager",
    gender:"Male",
    department:"D001",
    location:"Chennai",
    email: 'ekaterina.tankova@devias.io',
    flag: '/static/images/avatars/avatar_3.png',

  },
  {
    id: uuid(),

   
    name: 'Ekaterina Tankova',
    phone: '304-428-3097',
    employeeId:"E001",
    designation:"Manager",
    gender:"Male",
    department:"D001",
    location:"Chennai",
    email: 'ekaterina.tankova@devias.io',
    flag: '/static/images/avatars/avatar_3.png',

  },
  {
    id: uuid(),

   
    name: 'Ekaterina Tankova',
    phone: '304-428-3097',
    employeeId:"E001",
    designation:"Manager",
    gender:"Male",
    department:"D001",
    location:"Chennai",
    email: 'ekaterina.tankova@devias.io',
    flag: '/static/images/avatars/avatar_3.png',

  },
  {
    id: uuid(),

   
    name: 'Ekaterina Tankova',
    phone: '304-428-3097',
    employeeId:"E001",
    designation:"Manager",
    gender:"Male",
    department:"D001",
    location:"Chennai",
    email: 'ekaterina.tankova@devias.io',
    flag: '/static/images/avatars/avatar_3.png',

  },

];

