import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, Divider, Dialog, TableContainer, TableRow, Typography, makeStyles, Card, CardContent, Container, Button, TextField, Paper, Select, InputAdornment, SvgIcon, InputLabel, FormControl, MenuItem, FormHelperText, TablePagination,
    Tabs
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { ToastContainer, toast } from 'react-toastify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { RGCA_URLS } from '../../../utils/url';
import APIKit from '../../../utils/APIKit';
import { getloggedInData } from '../../../utils/helpers';
import { APP_URLS } from '../../../utils/apis';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { MESSAGE } from 'src/utils/message';
import { getProjectDetails } from '../Common/utilities';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from 'src/views/loader';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
        // flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    MuiTableHeadRoot: {
        backgroundColor: '#3f51b5 !important'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    dateborder: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px'
    },
    input: {
        display: 'none'
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    button2: {
        backgroundColor: '#394671',
        color: '#fff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#1b233d',
            color: '#fff'
        }
    },
    dat: {
        [theme.breakpoints.down('md')]: {
            width: '320px'
        }
    },
    butt: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '89%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '5%',
            color: 'white',
            backgroundColor: '#394671'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '',
            color: 'white',
            backgroundColor: '#394671'
        },
        '&:hover': {
            color: 'white',
            backgroundColor: '#394671'
        }
    },
    custmr: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '333px'
        }
    },
    head: {
        color: '#394671',
        [theme.breakpoints.down('xs')]: {
            color: '#394671',
            marginRight: '-40px'
        }
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PriceManagement = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [btn, setBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openTest, setOpenTest] = React.useState(false);
    const [productionPriceMasterID, setProductionPriceMasterID] = useState(null)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page1, setPage1] = React.useState(0);
    const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
    const [categoryList, setCategoryList] = useState([]);
    const [lookupData, setLookupData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const { project } = useParams();
    const projectDetails = { ...getProjectDetails(project) }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [salesItem, setSalesItem] = useState(
        {
            TypeID: '',
            CategoryName: '',
            Price: '',
            categoryID: '',
        }

    );
    let regEx = {
        textOnly: /^[a-zA-Z\s]+$/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        numbersOnly: /^[0-9]*$/,
        noSpecialChar: /^[a-zA-Z0-9.]*$/,
        percentage: /^([0-9][0-9]?|100)%?$/
    };
    useEffect(() => {
        // getItemsList();
        getLookup();
        getPrices()
    }, [navigate, projectDetails.projectID]);

    const getLookup = () => {
        APIKit.get(`${RGCA_URLS.lookup}/16`)
            .then((res) => {
                if (res.data.status === 200) {
                    setLookupData(res.data.data);
                } else {
                    // toast.error(res.data.message);
                }
            })
            .catch((e) => {
            });
    };
    const getCategories = (type) => {
        // setIsLoading(true);
        APIKit.post(RGCA_URLS.getSalesCategory, {

            typeID: type,
            projectID: projectDetails.projectID
        })
            .then((res) => {
                if (res.data.status === 200) {
                    setCategoryList(res.data.data);
                    //   setIsLoading(false);
                } else {

                }
            })
            .catch((e) => {
            });
    };
    const getPrices = (type) => {
        setIsLoading(true);
        APIKit.get(`${RGCA_URLS.getProductionPriceData}/${projectDetails.projectID}`)
            .then((res) => {
                if (res.data.status === 200) {
                    setPriceData(res.data.data);
                      setIsLoading(false);
                } else {

                }
            })
            .catch((e) => {
                setIsLoading(false);
            });
    };

    const formLabelsTheme = createTheme({
        overrides: {
          MuiFormLabel: {
            asterisk: {
              color: '#db3131',
              '&$error': {
                color: '#db3131'
              },
            }
          }
        }
      })
    return (
        <>

<Loader isLoading={isLoading} />
            <Container maxWidth={false}>
                <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Typography className={classes.head}>
                            <h2>Price Management</h2>
                        </Typography>
                    </Grid>
                    <Grid item xl={8} lg={8} sm={12} xs={12} style={{ textAlign: 'end' }}>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setOpenTest(true)
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>

            </Container>
            <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell>S.No</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Material</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {priceData && priceData.length != 0 ? (
                                    priceData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((x, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{x?.TypeName}</TableCell>
                                                <TableCell>{x?.ProductName}</TableCell>
                                                <TableCell>{x?.CategoryName}</TableCell>
                                                <TableCell>{x?.Price}</TableCell>
                                                <TableCell><EditIcon onClick={(e) => {
                                                    setBtn(true)
                                                    getCategories(x.TypeID);
                                                    setProductionPriceMasterID(x.ProductionPriceMasterID)
                                                    setSalesItem(x)
                                                    setOpenTest(true)
                                                }} /></TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow hover role="checkbox" key={1}>
                                        <TableCell colSpan={10} align="center" key={2}>
                                            {'No Data Found'}
                                        </TableCell>{' '}
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {priceData && priceData.length != 0 && priceData.length > 10 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={priceData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Grid>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...salesItem
                }}
                validationSchema={
                    Yup.object().shape({
                        TypeID: Yup.string().required(MESSAGE.type),
                        categoryID: Yup.string().required(MESSAGE.category),
                        CategoryName: Yup.string().required(MESSAGE.name).matches(/^\s*\S[\s\S]*$/g, MESSAGE.blankSpaces),
                        Price: Yup.string().required('Price required'),

                    })

                }
                onSubmit={(values, { resetForm }) => {
                    console.log("valuesbbbbb", values);
                    if (btn == false) {
                        const payload = {
                            "TypeID": values.TypeID,
                            "categoryID": values.categoryID,
                            "CategoryName": values.CategoryName,
                            "Price": values.Price,
                            projectID: projectDetails.projectID
                        }
                          setIsLoading(true);
                        APIKit.post(RGCA_URLS.addproductionPriceData, payload)
                            .then((res) => {
                                if (res.data.status === 200) {
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        toast.success(MESSAGE.priceItemAdded, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        })
                                        setOpenTest(false)
                                        getPrices();
                                        resetForm(values);
                                        setSalesItem({
                                            TypeID: '',
                                            CategoryName: '',
                                            Price: '',
                                            categoryID: '',

                                        });
                                    }, 3000);
                                } else {
                                    setIsLoading(false);
                                    toast.error(res.data.error, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    })
                                }
                            })
                            .catch((e) => {
                                setIsLoading(false);
                            });
                    } else {
                        const payload = {
                            "TypeID": values.TypeID,
                            "categoryID": values.categoryID,
                            "CategoryName": values.CategoryName,
                            "Price": values.Price,
                            "projectID": projectDetails.projectID,
                            "ProductionPriceMasterID": productionPriceMasterID

                        }
                          setIsLoading(true);
                        APIKit.put(RGCA_URLS.updateproductionPriceData, payload)
                            .then((res) => {
                                if (res.data.status === 200) {
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        toast.success(MESSAGE.priceItemUpdate, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        })
                                        setOpenTest(false)
                                        getPrices();
                                        setBtn(false)
                                        resetForm(values);
                                        setSalesItem({
                                            TypeID: '',
                                            CategoryName: '',
                                            Price: '',
                                            categoryID: '',
                                        });
                                    }, 3000);
                                } else {
                                    setIsLoading(false);
                                    toast.error(res.data.error, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    })
                                }
                            })
                            .catch((e) => {
                                setIsLoading(false);
                            });
                    }

                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    handleReset,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                      <MuiThemeProvider theme={formLabelsTheme}>
                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        open={openTest}
                        id="income"
                        contentStyle={{
                            width: '200%',
                            maxWidth: 'none',
                        }}
                        maxWidth="lg"
                        fullWidth
                    >
                        <Loader isLoading={isLoading} />
                        <DialogTitle id="scroll-dialog-title" onClose={(e => {
                            setBtn(false)
                            setOpenTest(false)
                            resetForm(values);
                            setSalesItem({
                                TypeID: '',
                                CategoryName: '',
                                Price: '',
                                categoryID: '',
                            });
                        })} style={{ backgroundColor: '#394671', color: '#fff' }}>
                            {btn ? 'Edit' : 'Add'} Price
                        </DialogTitle>
                        {/* <Loader isLoading={isLoading}/> */}
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <DialogContent>
                                <Box mt={3} style={{
                                    backgroundColor: '#fff',
                                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                                    borderRadius: '5px',
                                    padding: '20px'
                                }}>

                                    <Grid
                                        container
                                        spacing={3}
                                    >

                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                                error={Boolean(touched.TypeID && errors.TypeID)}
                                            >
                                                <InputLabel required id="demo-simple-select-outlined-label">Product Item</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={salesItem.TypeID}
                                                    // disabled={btn}
                                                    name="TypeID"
                                                    label="Product Item"
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        const p = salesItem;
                                                        p.TypeID = e.target.value;
                                                        setSalesItem({
                                                            ...p,
                                                            TypeID: e.target.value
                                                        });
                                                        getCategories(e.target.value);
                                                    }}
                                                >
                                                    {lookupData && lookupData.map((x) => {
                                                        return <MenuItem value={x.lookupID} key={x.lookupID}>{x.name}</MenuItem>;
                                                    })}
                                                </Select>
                                                <FormHelperText>{(errors.TypeID && touched.TypeID) && errors.TypeID}</FormHelperText>
                                            </FormControl>

                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                className={classes.formControl}
                                                error={Boolean(touched.categoryID && errors.categoryID)}
                                            >
                                                <InputLabel required id="demo-simple-select-outlined-label">Material</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={salesItem.categoryID}
                                                    label="Category"
                                                    name="categoryID"
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        const p = salesItem;
                                                        p.categoryID = e.target.value;
                                                        setSalesItem({
                                                            ...p,
                                                            categoryID: e.target.value
                                                        });
                                                    }}
                                                    disabled={!categoryList || categoryList.length === 0 /* || btn */}
                                                >
                                                    {categoryList && categoryList.map((x) => {
                                                        return <MenuItem value={x.categoryID} key={x.categoryID}>{x.name}</MenuItem>;
                                                    })}
                                                </Select>
                                                <FormHelperText>{(errors.categoryID && touched.categoryID) && errors.categoryID}</FormHelperText>
                                                {/* {!btn && */}
                                                {/* <a style={{ color: 'blue', cursor: 'pointer' }}
                                                  onClick={openSubModal}
                                                >+ Add Material</a> */}
                                                {/* } */}
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Category Name"
                                                name="CategoryName"
                                                value={salesItem.CategoryName}
                                                // disabled={btn}
                                                inputProps={{ maxLength: 250 }}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    // if (e.target.value === '' || regEx.textOnly.test(e.target.value)) {
                                                    handleChange(e);
                                                    const p = salesItem;
                                                    p.CategoryName = e.target.value;
                                                    setSalesItem({
                                                        ...p,
                                                        CategoryName: e.target.value
                                                    });
                                                    // }
                                                }
                                                }
                                                error={Boolean(touched.CategoryName && errors.CategoryName)}
                                                helperText={touched.CategoryName && errors.CategoryName}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>




                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <TextField
                                                inputProps={{ maxLength: 9 }}
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    if (e.target.value === '' || /^[0-9.]*$/.test(e.target.value)) {
                                                        handleChange(e);
                                                        const p = salesItem;
                                                        p.Price = e.target.value;
                                                        setSalesItem({
                                                            ...p,
                                                            Price: e.target.value
                                                        });
                                                    }
                                                }}
                                                value={salesItem.Price}
                                                error={Boolean(touched.Price && errors.Price)}
                                                helperText={touched.Price && errors.Price}
                                                label="Price"
                                                name="Price"
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: "#394671", color: "#fff" }}>
                                <Button onClick={(e => {
                                    setOpenTest(false)
                                    setBtn(false)
                                    resetForm(values);
                                    setSalesItem({
                                        TypeID: '',
                                        CategoryName: '',
                                        Price: '',
                                        categoryID: '',
                                    });
                                })} className={classes.button2}>
                                    Cancel
                                </Button>
                                {btn
                                    ? (
                                        <div>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                                className={classes.button2}
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            className={classes.button2}
                                        >
                                            Submit
                                        </Button>
                                    )}
                            </DialogActions>
                        </Form>

                    </Dialog>
                </MuiThemeProvider>
                )}
            </Formik>
        </>
    )
}

export default PriceManagement